import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlResolver, OnlineStatusService, LastListReportStorageService } from '@softools/softools-core';
import { RouteParams } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { CardReportController } from 'app/mvc/reports/card-report.controller';
import { CardReportModel } from 'app/mvc/reports/card-report.model';
import { AppService } from 'app/services/app.service';
import { IGroupStarts } from 'app/services/indexes/app-data-index';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { BehaviorSubject } from 'rxjs';
import { WSReportBase } from '../../ws-base/ws-reportbase';
import { WsReportComponent } from '../ws-report/ws-report.component';

export interface IColumn {
  ungrouped: boolean;
  position: number;
  rows: number;
  starts: IGroupStarts;
}

@Component({
  selector: 'app-ws-card-report',
  templateUrl: './ws-card-report.component.html',
  styleUrls: ['./ws-card-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsCardReportComponent extends WSReportBase implements OnInit {

  public cardModel: CardReportModel;

  @ViewChild(WsReportComponent, { static: true })
  public reportComponent: WsReportComponent;

  constructor(
    router: Router,
    navigationService: NavigationService,
    indexedDbService: IndexedDbService,
    appService: AppService,
    urlresolver: UrlResolver,
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    permissionsService: PermissionsService,
    models: GlobalModelService,
    protected lastListReportStorageService: LastListReportStorageService,
  ) {
    super(indexedDbService, appService, router, urlresolver, onlineStatus, route, navigationService, permissionsService, models);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  protected override async routed(params: RouteParams) {
    await super.routed(params);
    await this.lastListReportStorageService.storeLastRecordsReportAsync(params.appIdentifiers.visibleAppIdentifier, params.reportIdentifier);
  }

  protected override createReportModel() {
    this.cardModel = new CardReportModel(this.appModel, this.filterModel, this.models.pageModel);
    return this.cardModel;
  }

  protected override createReportController() {
    return new CardReportController(this.reportModel);
  }

  public cardLayoutExtent = 0;

  public groupedCardsPerColumn$ = new BehaviorSubject(0);

  public ungroupedCardsPerColumn$ = new BehaviorSubject(0);

  public scrolled(pos: { x: number, y: number }) {
    this.cardModel.scrollPosition.value = pos;
  }

  public zoomChanged(level: number) {
    this.cardModel.zoom.value = level;
  }
}
