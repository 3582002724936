import { Homepage, Report, Enums } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { UrlMatcher, UrlSegment, UrlMatchResult } from '@angular/router';
import { APP_IDENTIFIER, REPORT_IDENTIFIER, HOMEPAGE_IDENTIFIER, RECORD_ID, PARENT_APP_IDENTIFIER, ANCESTOR_APP_IDENTIFIER_PREFIX, PARENT_RECORD_ID, FORM_IDENTIFIER, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER, RECORD_CURSOR } from 'app/_constants';
import { IdentifiersService } from 'app/services/identifiers.service';

export const APP_ID_SEQUENCE = 'appIdentifiers';

interface ExtraInfo {

  /** Required report type if matching a report */
  reportType?: Enums.ReportTypes;

  /** Max number of app ids (including active app) for a child app */
  maxAppDepth?: number;

  /** If specified, indicates whether app must be or must not be a singleton.  If undefined any app matches */
  singleton?: boolean;

}

const CREATE = 'create';

export class RootRouteMatcher {

  public constructor(
    private identifiersService: IdentifiersService,
  ) {
  }

  public appHomeMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER], { singleton: false });
  }

  public childAppHomeMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER], { singleton: false });
  }

  public singletonMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER], { singleton: true });
  }


  public listReportDirectMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.List });
  }

  public listReportWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.List });
  }

  public chartReportDirectMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Chart });
  }

  public chartReportWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Chart });
  }

  public matrixReportDirectMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Matrix });
  }

  public matrixReportWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Matrix });
  }

  public dashboardReportDirectMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Dashboard });
  }

  public dashboardReportWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Dashboard });
  }

  public tableReportDirectMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Table });
  }

  public tableReportWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Table });
  }

  public cardReportDirectMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Card });
  }

  public cardReportWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Card });
  }

  public homepageMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [HOMEPAGE_IDENTIFIER]);
  }

  public createRecordMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, CREATE]);
  }

  public recordWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, RECORD_ID, FORM_IDENTIFIER]);
  }

  public recordListCursorWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER, RECORD_CURSOR], { reportType: Enums.ReportTypes.List });
  }

  public childListRecordCursorWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER, RECORD_CURSOR], { reportType: Enums.ReportTypes.List });
  }

  public recordTableCursorWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_IDENTIFIER, REPORT_IDENTIFIER, RECORD_CURSOR], { reportType: Enums.ReportTypes.Table });
  }

  public childTableRecordCursorWithAppMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER, RECORD_CURSOR], { reportType: Enums.ReportTypes.Table });
  }

  public childAppCreateRecordMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CREATE], { maxAppDepth: 9 });
  }

  public childAppRecordMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, CHILD_APP_IDENTIFIER, RECORD_ID, FORM_IDENTIFIER], { maxAppDepth: 9 });
  }

  public childListReportMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.List });
  }

  public childTableReportMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Table });
  }

  public childChartReportMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Chart });
  }

  public childMatrixReportMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Matrix });
  }

  public childDashboardReportMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Dashboard });
  }

  public childCardReportMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
    return this.match(segments, [APP_ID_SEQUENCE, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER, CHILD_REPORT_IDENTIFIER], { reportType: Enums.ReportTypes.Card });
  }

  public match(segments: UrlSegment[], pattern: string[], extras?: ExtraInfo): UrlMatchResult {

    const reportType = extras && extras.reportType;

    // Clone segments as we will consume it during matching
    const matchSegments = [...segments];

    const params = {};

    let app: Application;
    let childApp: Application;
    let ancestors: Application[];
    let report: Report;
    let homepage: Homepage;
    let recordId: string;
    let parentRecordId: string;
    let recordCursor: string;
    let formId: string;

    while (pattern.length > 0) {
      const type = pattern.shift();
      switch (type) {
        case APP_IDENTIFIER: {
          const matchedApp = this.matchApp(matchSegments);
          if (!matchedApp) {
            return null;
          }

          // Check singleton flag if specified

          if (extras?.singleton !== undefined) {
            const matchedIsSingleton = matchedApp.IsSingletonApp || false;
            if (extras.singleton !== matchedIsSingleton) {
              return null;
            }
          }

          app = matchedApp;
          break;
        }

        case CHILD_APP_IDENTIFIER: {
          const matchedChildApp = this.matchApp(matchSegments);
          if (!matchedChildApp) {
            return null;
          }
          childApp = matchedChildApp;
          break;
        }

        case REPORT_IDENTIFIER: {
          const reportInfo = this.matchReport(app, matchSegments, reportType);
          if (!reportInfo) {
            return null;
          }
          report = reportInfo.report;
          app = reportInfo.app;
          break;
        }

        case CHILD_REPORT_IDENTIFIER: {
          const reportInfo = this.matchReport(childApp, matchSegments, reportType);
          if (!reportInfo) {
            return null;
          }
          report = reportInfo.report;
          childApp = reportInfo.app;
          break;
        }

        case HOMEPAGE_IDENTIFIER: {
          homepage = this.matchHomepage(matchSegments);
          if (!homepage) {
            return null;
          }
          break;
        }
        case RECORD_ID: {
          if (matchSegments.length === 0) {
            return null;
          }

          recordId = matchSegments.shift().path;
          // Check app accepts the id.  Must be exactly true, truthy can be an updated id.
          if (app) {
            const valid = recordId === 'create' || app.validateRecordId(recordId);
            if (valid !== true && valid !== recordId) {
              return null;
            }
          }
          break;
        }

        case PARENT_RECORD_ID: {
          if (matchSegments.length === 0) {
            return null;
          }

          parentRecordId = matchSegments.shift().path;
          // Check app accepts the id.  Must be exactly true, truthy can be an updated id.
          if (app) {
            const valid = app.validateRecordId(parentRecordId);
            if (valid !== true && valid !== parentRecordId) {
              return null;
            }
          }
          break;
        }

        case RECORD_CURSOR: {
          if (matchSegments.length === 0) {
            return null;
          }

          recordCursor = matchSegments.shift().path;
          if (!Number.parseInt(recordCursor)) {
            return null;
          }

          break;
        }

        case CREATE: {
          if (matchSegments.length > 0 && matchSegments[0].path === CREATE) {
            recordId = CREATE;
            matchSegments.shift();
            break;
          }

          return null;
        }

        case APP_ID_SEQUENCE: {
          const apps = this.matchAppSequence(matchSegments);
          if (!apps || apps.length === 0) { return null; }

          // Only match if ancenstor list is short enough
          // Allows match for single parent until we've implemented arbitrary depth
          if (extras && extras.maxAppDepth && apps.length > extras.maxAppDepth) { return null; }

          // Look ahead, if the next token is a child app id then it will have been consumed
          // as part of the sequence so pull it out seperately
          if (pattern.length > 0 && pattern[0] === CHILD_APP_IDENTIFIER) {
            pattern.shift();
            // Extract child app and make focus app, rest are still ancestors
            app = childApp = apps.pop();
          } else {
            // keep all ancenstors, take last as focus app
            app = apps[apps.length - 1];
          }

          ancestors = apps;
          break;
        }

        case FORM_IDENTIFIER: {
          // Match form - optional so don't fail if absent but must match if specified
          if (matchSegments.length > 0) {
            const form = this.matchForm(app, matchSegments);
            if (form) {
              formId = form.Identifier;
            } else {
              return null;
            }
          }
          break;
        }

        default:
          return null;
      }
    }

    if (pattern.length > 0 || matchSegments.length > 0) {
      // Didn't match complete URL so reject
      return null;
    }

    if (app) {
      params[APP_IDENTIFIER] = new UrlSegment(app.Identifier, {});
    }

    if (childApp) {
      params[CHILD_APP_IDENTIFIER] = new UrlSegment(childApp.Identifier, {});
    }

    if (ancestors && ancestors.length > 0) {
      // last ancestor is parent, so record that in the traditional way
      params[PARENT_APP_IDENTIFIER] = new UrlSegment(ancestors[ancestors.length - 1].Identifier, {});
      // Record all ancestors with a numeric suffix
      while (ancestors.length > 0) {
        const param = `${ANCESTOR_APP_IDENTIFIER_PREFIX}${ancestors.length}`;
        const ancestor = ancestors.shift();
        params[param] = new UrlSegment(ancestor.Identifier, {});
      }
    }

    if (report) {
      params[REPORT_IDENTIFIER] = new UrlSegment(report.Identifier, {});
    }

    if (formId) {
      params[FORM_IDENTIFIER] = new UrlSegment(formId, {});
    }

    if (homepage) {
      params[HOMEPAGE_IDENTIFIER] = new UrlSegment(homepage.Identifier, {});
    }

    if (recordId) {
      params[RECORD_ID] = new UrlSegment(recordId, {});
    }

    if (parentRecordId) {
      params[PARENT_RECORD_ID] = new UrlSegment(parentRecordId, {});
    }

    if (recordCursor) {
      params[RECORD_CURSOR] = new UrlSegment(recordCursor, {});
    }

    return { consumed: segments, posParams: params };
  }

  public matchAppSequence(segments: UrlSegment[]): Array<Application> {

    // Sequence of apps starting with "oldest" ancestor
    const applications: Array<Application> = [];

    while (segments.length > 0) {
      const app = this.matchApp(segments);
      if (app) {
        applications.push(app);
      } else {
        if (applications.length > 0) {
          return applications;
        }
        break;
      }
    }

    return null;
  }

  private matchApp(segments: UrlSegment[]): Application {
    if (segments.length >= 2 && segments[0].path === 'App') {
      const app = this.identifiersService.getApp(segments[1].path);
      if (app) {
        segments.shift();
        segments.shift();
        return app;
      }
    } else if (segments.length >= 1) {
      const app = this.identifiersService.getUnambiguousApp(segments[0].path);
      if (app) {
        segments.shift();
        return app;
      }
    }

    return null;
  }

  private matchReport(app: Application, segments: UrlSegment[], reportType: Enums.ReportTypes) {

    let len = 0;
    let id: string;

    const reportTypeName = Enums.ReportTypes[reportType];
    if (segments.length >= 2 && segments[0].path === reportTypeName) {
      len = 2;
      id = segments[1].path;
    } else if (segments.length >= 1) {
      len = 1;
      id = segments[0].path;
    }

    let reportInfo: { app: Application, report: Report };
    if (app) {
      reportInfo = { app, report: app.Reports.find(rep => rep.Identifier === id) };
    } else {
      reportInfo = this.identifiersService.getUnambiguousReport(id);
    }

    if (reportInfo && reportInfo.report && reportInfo.report.Type === reportType) {
      while (len--) {
        segments.shift();
      }

      return reportInfo;
    }

    return null;
  }

  private matchForm(app: Application, segments: UrlSegment[]) {

    let len = 0;
    let id: string;

    if (segments.length >= 2 && segments[0].path === 'Form') {
      len = 2;
      id = segments[1].path;
    } else if (segments.length >= 1) {
      len = 1;
      id = segments[0].path;
    }

    const form = app.Forms && app.Forms.find(f => f.Identifier === id);
    if (form) {
      while (len--) {
        segments.shift();
      }

      return form;
    }

    return null;
  }

  private matchHomepage(segments: UrlSegment[]) {
    if (segments.length >= 2 && segments[0].path === 'Homepage') {
      const homepage = this.identifiersService.getHomepage(segments[1].path);
      if (homepage) {
        segments.shift();
        segments.shift();
        return homepage;
      }
    } else if (segments.length === 1 && segments[0].path === 'Homepage') {
      segments.shift();
      // may be null if no default defined = will not match in this case
      return this.identifiersService.getDefaultHomepage();
    } else if (segments.length >= 1) {
      const homepage = this.identifiersService.getUnambiguousHomepage(segments[0].path);
      if (homepage) {
        segments.shift();
        return homepage;
      }
    } else if (segments.length === 0) {
      // may be null if no default defined = will not match in this case
      return this.identifiersService.getDefaultHomepage();
    }

    return null;
  }
}
