
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayImgComponent } from 'app/softoolsui.module/overlay-img.component/overlay-img.component';
import { PipeModule } from 'app/pipes/pipe.module';

@NgModule({
  declarations: [OverlayImgComponent],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [OverlayImgComponent],
  providers: []
})
export class OverlayImgModule { }
