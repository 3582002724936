<div class="w-100 mb-4" [ngStyle]="fieldStyle"  >
  <div
    style="display: grid; grid-auto-columns: auto; grid-auto-rows: auto; overflow-y: auto; align-items: end;"
    class="mb-4"
    [style.grid-template-columns]="gridColumns"
  >
    <ng-container *ngFor="let sub of columnFields; let col = index; trackBy: columnFieldsTrackByFn">
      <div
        class="d-flex p-2"
        [style.grid-column]="col + 1"
        [class.justify-content-center]="isHeaderCenterd(sub)"
        [class.justify-content-start]="!isHeaderCenterd(sub)"
        [class.pointer]="isSortSupported(sub)"
        style="grid-row: 1;"
        (click)="sort(sub)"
      >
        <b class="st-text-theme-primary me-2">{{ sub?.Label }}</b>
        <fa-icon [icon]="['fal', 'sort-down']" class="text-muted" *ngIf="isSortSupported(sub) && sortFieldIdentifer === sub.Identifier && sortFieldAsc"></fa-icon>
        <fa-icon [icon]="['fal', 'sort-up']" class="text-muted" *ngIf="isSortSupported(sub) && sortFieldIdentifer === sub.Identifier && !sortFieldAsc"></fa-icon>
      </div>
    </ng-container>

    <mat-divider style="grid-row: 2; grid-column: 1/-1;" class="mb-2"></mat-divider>

    <ng-container *ngFor="let sr of subRecordsPage; let row = index; trackBy: tracker">

      <div class="w-100 h-100" [style.grid-row]="row*2 + 3" [style.grid-column]="'1/-1'" [ngStyle]="rowStyle(row+skip)" ></div>

      <ng-container *ngFor="let sub of columnFields; let col = index; trackBy: columnFieldsTrackByFn">
        <sof-list-cell-field *ngIf="!isHidden(row + skip, col)"
          class="mx-2 h-100 w-100"
          style="align-items: flex-end; display: flex;"
          [style.grid-column]="col + 1"
          [style.grid-row]="row*2 + 3"
          [ngStyle]="subFieldStyle(sub, row + skip)"
          [appModel]="appModel"
          [application]="application"
          [template]="template"
          [appIdentifiers]="appIdentifiers"
          [fieldModel]="sub"
          [form]="form"
          [containerField]="fieldModel"
          [containingList]="this"
          [listRow]="row + skip"
          [record]="record"
          [ruleState]="ruleState"
          [elementStyles]="elementStyles"
          [containerType]="listFieldContainerType"
          [forReport]="true"
          [inline]="true" >
        </sof-list-cell-field>
      </ng-container>

      <ng-container *ngIf="isRowDeleteEnabled">
        <!-- Trash icons in each row -->
        <div
          class="d-flex justify-content-center align-content-center pointer h-100 ms-1"
          [style.grid-column]="trashColumn"
          [style.grid-row]="row*2 + 3"
          (click)="deleteRecordClicked($event, row + skip)"
        >
          <fa-icon
            size="1x"
            [icon]="['fas', 'times']"
            class="st-text-theme-primary align-self-center"
            title="Remove Item"
            i18n-title
          ></fa-icon>
          &nbsp;
        </div>
      </ng-container>

      <mat-divider style="grid-column: 1/-1;" [style.grid-row]="row*2 + 4" class="my-2"></mat-divider>

      <div *ngFor="let sub of columnFields; let col = index;"
        class="px-3"
        [style.grid-column]="col + 1"
        [style.grid-row]="top + 1"
      >
        <app-field-footer [field]="sub" 
          [readonly]="!(enabled$|async)"
          [form]="form"
          [template]="template"
          [record]="record"
          [editErrors]="fieldErrors(editValidationErrors$|async, sub)"
        ></app-field-footer>
      </div>

    </ng-container>
  </div>
  <div *ngIf="isRowAddEnabled" class="d-flex justify-content-start">
    <div
      class="bg-st-theme-primary rounded-circle shadow d-flex justify-content-center align-items-center pointer"
      style="width: 3.6rem; height: 3.6rem;"
      (click)="addRecordClicked($event)"
    >
      <span class="text-white"><sof-plus-icon size="2x"></sof-plus-icon></span>
    </div>
  </div>
</div>

<app-pagination *ngIf="listFieldPagination"
  [count]="subRecords.length"
  [skip]="skip"
  [isBusy]="false"
  [top]="top"
  (nextClicked)="nextPage()"
  (prevClicked)="previousPage()"
  (firstRecordClicked)="firstPage()"
  (lasttRecordClicked)="lastPage()"
  (onChangeTopSize)="changeTop($event)"
  >
</app-pagination>
