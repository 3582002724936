import { BehaviorSubject } from 'rxjs';
import { Record } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { BusyModel } from '../busy.model';

export abstract class AppDataAccessor {

  /** If true the accessor is no longer in use.  Any results returned should be ignored */
  public closed = false;

  /** Count of records modified by last filter, search, etc.   */
  public count$ = new BehaviorSubject<number>(null);

  /** Busy flag for count$ value  */
  public countBusy$ = new BehaviorSubject(true);

  protected constructor(public busy: BusyModel, public app: Application) {
  }

  public abstract initialise(): Promise<void>;

  public resetIndex() { }

  /** Mark the accessor as closed when it is no longer attached to an active report  */
  public close() {
    // We should cancel outstaning operations here but can't do that yet
    // Callers should check the closed flag when operations complete
    this.closed = true;
  }

  public async recordChanged(record: Record) {
  }
}
