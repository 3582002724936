import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, Router } from '@angular/router';

import { Record, ReportViewService, SearchTermStorageService, logError } from '@softools/softools-core';
import { RECORD_CURSOR, REPORT_IDENTIFIER, PARENT_APP_IDENTIFIER, PARENT_RECORD_ID } from 'app/_constants/constants.route';
import { AppService } from 'app/services/app.service';
import { AppContextService } from 'app/services/app-context.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { RecordSelection } from 'app/types/record-selection';
import { ReportFilter } from 'app/filters/types';
// import { SetRecordData } from 'app/workspace.module/actions/record.actions';
// import { ActionDispatcherService } from 'app/store/action-dispatcher.service';
import { OverlayService } from 'app/workspace.module/services/overlay.service';

@Injectable({ providedIn: 'root' })
export class RecordCursorResolver implements Resolve<Record> {
  constructor(
    private appService: AppService,
    private appContextService: AppContextService,
    private reportViewService: ReportViewService,
    private searchTermStorageService: SearchTermStorageService,
    private router: Router,
    // private actionDispatcher: ActionDispatcherService,
    private overlayService: OverlayService
  ) {}

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record> {
    // Set the globel spinner.
    // This prevents further user clicks until this functon resolves.
    // Slow internet causes will cause lots race condtions.
    // If we can stop this a better way then we should.
    // If removing, test on a slow connect that you can keep clicking the next record button without issues.
    this.overlayService.openSpinner();

    const params: Params = route.params;
    const appIdentifiers = new AppIdentifiers(params);
    const appIdentifier = appIdentifiers.visibleAppIdentifier;
    const reportIdentifier: string = params[REPORT_IDENTIFIER] as string;
    const cursor: string = params[RECORD_CURSOR] as string;
    const parentAppIdentifier = route.params[PARENT_APP_IDENTIFIER];
    const parentRecordIdentifier = route.params[PARENT_RECORD_ID];

    try {
      const app = this.appService.application(appIdentifier);
      const report = this.appService.getReport(appIdentifier, reportIdentifier);
      const view = await this.reportViewService.getViewDataAsync(app.Identifier, reportIdentifier);

      let filter = new ReportFilter();
      filter.filterString = view.filterQuery;
      filter.OrderBy = view.orderBy;
      filter.IsOrderDescending = view.orderDescending;
      filter.Group = view.groupBy;
      filter.IsGroupDescending = view.groupDescending;

      // If a search is in force, add that into the filtter
      const hierarchy = parentAppIdentifier && parentRecordIdentifier ? `${parentAppIdentifier}|${parentRecordIdentifier}` : '';
      const search = await this.searchTermStorageService.getSearchTermAsync(appIdentifier, reportIdentifier, hierarchy);
      if (search) {
        const searchFilter = new ReportFilter();
        searchFilter.filterString = `substringof('${search}',QuickFilterSearchText)`;
        filter = filter.append(searchFilter);
      }

      const selection = new RecordSelection();
      selection.filter = filter;
      selection.report = report;
      selection.showArchived = this.appContextService.ShowArchived;
      if (params['parentRecordId'] && params['parentAppIdentifier']) {
        selection.hierarchy = `${params['parentAppIdentifier']}|${params['parentRecordId']}`;
      }
      const record = await app.getRecordCursor(selection, cursor);

      if (record) {
        if (record.AppIdentifier !== appIdentifier) {
          logError(new Error(`Selected a ${record.AppIdentifier} record running ${appIdentifier}`), '');
          // Could do with an error place
          this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
        }

        // this.actionDispatcher.dispatch(new SetRecordData(record));
        this.overlayService.close();
        return record;
      }
    } catch (error) {
      console.log(`Could not load ${appIdentifier}:${reportIdentifier} @ ${cursor}`, error);
    }

    this.overlayService.close();
    // Record not found, navigate back to root
    this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
    return null;
  }
}
