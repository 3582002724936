import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryExpressionComponent } from './summary-expression.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SummaryExpressionComponent],
  exports: [SummaryExpressionComponent]
})
export class SummaryExpressionModule { }
