import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { AppService } from 'app/services/app.service';
import { AppIdentifier } from 'app/types/application';
import { APP_IDENTIFIER, REPORT_IDENTIFIER, CHILD_APP_IDENTIFIER } from 'app/_constants';
import { IdentifiersService } from 'app/services/identifiers.service';
import { AppContextService } from 'app/services/app-context.service';
import { logError } from '@softools/softools-core';

/**
 * This guard loads the complete App list (including built-in ones) and initialise the app list in state.
 * If the acive route includes an app, it checks it exists, and if it includes a report, it checks that it
 * is present on the selected app.
 */
@Injectable({ providedIn: 'root' })
export class AppsExistsGuard implements CanActivate {

  constructor(
    private appService: AppService,
    private identifiersService: IdentifiersService,
    private appContextService: AppContextService,
    private injector: Injector
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      // Initialise AppService
      const apps = await this.appService.refresh();
      if (apps && apps.length > 0) {

        await this.identifiersService.initialise(apps);

        const params: Params = route.params;
        const appIdentifier = params[APP_IDENTIFIER] as AppIdentifier;
        const childAppIdentifier = params[CHILD_APP_IDENTIFIER] as AppIdentifier;
        const reportIdentifier = route.params[REPORT_IDENTIFIER] as string;

        if (appIdentifier) {
          const app = this.appService.application(appIdentifier);
          if (!app) {
            console.warn(`AppsExistsGuard rejected navigation as app ${appIdentifier} not found`);
            return false;
          }

          if (!app.permitted && !app.Anonymous) {
            console.warn(`AppsExistsGuard rejected navigation as user has no access to app ${appIdentifier}`);
            return false;
          }

          this.appContextService.SelectedApplication = app;

          if (childAppIdentifier) {
            const childApp = this.appService.application(childAppIdentifier);
            this.appContextService.SelectedChildApplication = childApp;
          }

          await app.initialise();

          if (reportIdentifier) {
            const reportAppIdentifier = childAppIdentifier || appIdentifier;
            const report = this.appService.getReport(reportAppIdentifier, reportIdentifier);
            this.appContextService.SelectedReport = report;
            if (!report) {
              console.warn(`AppsExistsGuard rejected navigation as report ${reportIdentifier} of app ${reportAppIdentifier} not found`);
              return false;
            }
          }
        }

      } else {
        console.warn('AppsExistsGuard rejected navigation as no apps found');
        return false;
      }

      // Look for any defined child guards and call them in parallel
      const rejection = await this.runChildGuards('AppsExistsGuard', route, state);
      if (rejection) {
        return rejection;
      }

      return true;

    } catch (error) {
      logError(error, '');
      throw error;
    }
  }

  /**
   * Run child guards that are dependent on this guard completing.
   * See https://github.com/SofTools/Workspace-App/wiki/Guard-Dependencies
   *
   * @param name    This guard name.  Don't look up from type as this changes when minified.
   * @param route   Navigation route
   * @param state   Navigation state
   */
  protected async runChildGuards(name: string, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const childGuards = route.data['childGuards'] && route.data['childGuards'][name];
    if (childGuards) {
      const promises: Array<Promise<any>> = [];
      for (let index = 0, length = childGuards.length; index < length; index++) {
        const guardToken = childGuards[index];
        const other: CanActivate = this.injector.get(guardToken);
        promises.push(other.canActivate(route, state) as any);
      }

      const results = await Promise.all(promises);
      const rejection = results.find(r => r !== true);
      return rejection;
    }
  }
}
