
<div id="video-report-value" *ngIf="forReport && title" class="text-start">
  <span (click)="onReportClickHandler($event)">
    <fa-icon [id]="uiIdentifier" [icon]="['fal', 'video']" aria-hidden='true'></fa-icon>
    <span id="report-value">{{title}}</span>
  </span>
</div>

<div id="video-record-value" *ngIf="!forReport && editMode && title && descriptionValue && urlValue">
  <div class="video" [id]="uiIdentifier">
    <div class="title">{{title}}</div>
    <pre class="description">{{descriptionValue}}</pre>
    <iframe class="iframe-video" allowfullscreen="" [src]="urlValue | safe: 'resourceUrl'"></iframe>
    <ng-container *ngIf="enabled$|async">
      <fa-icon [icon]="['fal', 'pen-square']" class="pointer icon edit-icon" *ngIf="hasEdit" (click)="onAddEditEmbeddedVideoClickHandler($event, false)"></fa-icon>
      <fa-icon [icon]="['fal', 'trash']" class="pointer icon delete-icon" *ngIf="hasDelete" (click)="onDeleteEmbeddedVideoClickHandler()"></fa-icon>
    </ng-container>
  </div>
</div>

<div id="video-record-no-value" *ngIf="!forReport && editMode && (enabled$|async) && (!title || !descriptionValue || !urlValue)">
  <div class="pointer" (click)="onAddEditEmbeddedVideoClickHandler($event)">
    <span i18n="@@Add Video">Add Video</span>
    <sof-plus-icon class="add-icon" ></sof-plus-icon>
  </div>
</div>
