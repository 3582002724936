import { Component, Input } from '@angular/core';

@Component({
  selector: 'sof-drop-zone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropZoneComponent {
  @Input() dragText = '';

  constructor() { }
}
