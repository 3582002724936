import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoldersComponent } from './folders.component';
import { FoldericonComponent } from './foldericon.component/foldericon.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofPlusIconModule, SofSpinnerModule } from '@softools/design/icons';
import { AssetImagesModule } from '../asset-image/asset-images.module';
import { MatRippleModule } from '@angular/material/core';
import { IconModule } from '../icon/icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';

@NgModule({
  declarations: [FoldersComponent, FoldericonComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, AssetImagesModule,
    SofPlusIconModule, SofSpinnerModule, MatRippleModule, IconModule, MatTooltipModule,
    MvcDirectivesModule
  ],
  exports: [FoldersComponent, FoldericonComponent],
  providers: [],
})
export class FoldersModule {}
