<div
(click)="open()"

>
  <fa-layers i18n-title title="Notifications"
    size="2x" class="me-1"
    cdkOverlayOrigin #overlayOriginEl="cdkOverlayOrigin"
    [@jiggle]="jiggling$|async"
    >
    <fa-icon
      id="bellIcon"
      class="pointer noselect"
      *ngIf="notificationsModel.notifications.$|async"
      [classes]="['mt-2', 'me-1']"
      [icon]="['fal', 'bell']"
      [border]="false"
    ></fa-icon>
    <fa-layers-counter *ngIf="!(notificationsModel.busy.busyDelayed$|async)"
       style="font-size: 1.5em" class="noselect pointer" [classes]="['mt-2']" content="{{ notificationsModel.countDisplay.$|async }}"></fa-layers-counter>
    <fa-icon *ngIf="notificationsModel.busy.busyDelayed$|async"
      style="font-size: 0.5em;"
      [icon]="['fas', 'spinner-third']"  [classes]="['mt-2', 'me-1']" [spin]="true"></fa-icon>
  </fa-layers>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  [cdkConnectedOverlayOrigin]="overlayOriginEl"
  [cdkConnectedOverlayOpen]="showNotifications"
  [cdkConnectedOverlayBackdropClass]="'no-background'"
  (backdropClick)="close(false)"
>
  <div id="notificationsContainer" class="notifications-container theme">
    <ng-container *ngIf="(notificationsModel.count.$|async) === 0">
      <div class="p-2"><span i18n>You have no notifications.</span></div>
    </ng-container>

    <div *ngFor="let nt of notificationsModel.notifications.$|async">
      <div [@flyInflyOutRight]="nt.slideout ? 'in' : 'out'" (@flyInflyOutRight.done)="nt.slideout && onClearNotificationClick.emit(nt.Id)">
        <app-notification-message
          [notificationMessage]="nt"
          [isOnline]="isOnline"
          (slideout)="nt.slideout = $event"
          (onDownloadClick)="onDownloadClick.emit($event)"
        ></app-notification-message>
      </div>
    </div>
    <ng-container *ngIf="(notificationsModel.count.$|async) > 0 && isOnline">
      <div class="p-2 d-flex justify-content-between">
        <button
          id="clearAllButton"
          class="btn btn-primary btn-theme-reverse"
          (click)="close(true)"
          i18n-title="@@ClearAll"
          title="Clear All"
        >
          <span i18n>Clear All</span>
          <fa-icon [icon]="['fal', 'trash']" size="1x"></fa-icon>
        </button>
        <button
          id="moreButton"
          class="btn btn-outline-secondary"
          (click)="more()"
          i18n-title="@@More"
          title="More Notifications"
        >
          <span class="me-2" i18n>More</span>
          <fa-icon [icon]="['fal', 'ellipsis-h']" size="1x"></fa-icon>
        </button>

      </div>
    </ng-container>
  </div>
</ng-template>
