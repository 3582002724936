import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModelBase } from './model-base';
import { ModelProperty } from './model-property';

/**
 * Project a property into a read-only view
 */
export class ProjectionModelPoperty<TWrapped, T> extends ModelBase {

  constructor(private prop: ModelProperty<TWrapped>, private func: (t: TWrapped) => T) {
    super();
  }

  public get value(): T {
    return this.func(this.prop.value);
  }

  public get $(): Observable<T> {
    return this.prop.$.pipe(
      map((t) => this.func(t))
    );
  }
}
