import { Injectable } from '@angular/core';
import { TeamsRepository } from '../repos';
import { Team } from '../types';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, TEAMS_STORE } from '../indexedDb';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TeamsStorageService extends StorageServiceBase<Team, string> {

  constructor(
    dbContext: DatabaseContextService<Team>,
    private repository: TeamsRepository) {
    super(TEAMS_STORE, dbContext);
  }

  public async getTeams() {
    return await this.getAll();
  }

  /**
   * Synchronise users from server into local storage.
   * @param retryPolicy     Optional policy for retrying when connections are poor.
   */
  public async syncSortedTeamsAsync(retryPolicy: IRetryPolicy = null): Promise<Array<Team>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const teams = await policy.execute(() => lastValueFrom<Array<Team>>(this.repository.getSorted()));
    await this.saveTeams(teams);
    return teams;
  }

  public async getTeam(id: string) {
    return this.get(id);
  }

  public async replaceTeam(team: Team) {
    await this.save(team.Id, team);
  }

  /** Reload teams and store  */
  public async refreshTeamsAsync(): Promise<Array<Team>> {
    const teams = await lastValueFrom<Array<Team>>(this.repository.getSorted());
    await this.clear();
    await this.saveTeams(teams);
    return teams;
  }

  public async removeTeamsAsync(ids: Array<string>) {
    for (let i = 0; i < ids.length; ++i) {
      await this.delete(ids[i]);
    }
  }

  private async saveTeams(teams: Array<Team>): Promise<Array<Team>> {
    await this.dbContext.putData(this.store, teams, 'Id');
    return teams;
  }
}
