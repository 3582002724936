<div class="container-fluid mt-4">
  <div class="jumbotron" *ngIf="!isLoading">

    <ng-container *ngIf="isArchived">
      <h1 class="display-3" i18n="@@NoArchivedRecordFound" *ngIf="!app?.TextResources?.NoArchivedRecordsFound">
        No archived records found
      </h1>
      <h1 class="display-3" *ngIf="app?.TextResources?.NoArchivedRecordsFound" >
        {{app.TextResources.NoArchivedRecordsFound}}
      </h1>
    </ng-container>

    <ng-container *ngIf="!isArchived">
      <h1 class="display-3" i18n="@@NoRecordFound" *ngIf="!app?.TextResources?.NoRecordsFound">
        No records found
      </h1>
      <h1 class="display-3" *ngIf="app?.TextResources?.NoRecordsFound">
        {{app.TextResources.NoRecordsFound}}
      </h1>
    </ng-container>

    <div *ngIf="showReportFilterMsg$|async" class="reason-row" >
      <span class="reason-text" i18n="@@NoRecsFilter"  >
        The current filter might not match any records
      </span>
      <button color="primary" class="primary-line" role="button" (click)="clearFilterClicked($event)" i18n>
        Clear Filter
      </button>
    </div>

    <div *ngIf="showSearchFilterMsg$|async" class="reason-row" >
      <span class="reason-text" i18n="@@NoRecsSearch">
        The current search term might not match any records
      </span>
      <button color="primary" class="primary-line" role="button" (click)="clearSearchClicked($event)" i18n>
        Clear Search
      </button>
    </div>

    <div *ngIf="showBaseFilterMsg$|async" class="reason-row">
      <span class="reason-text" i18n="@@NoRecsBase">
        This report has a base filter which may be excluding records
      </span>
    </div>

    <div class="reason-row">
      <span class="reason-text" i18n="@@NoRecsPerms">
        There might be no records in this app that you have permission to access
      </span>
    </div>


  </div>
</div>
