import { IStyleName, Record, Rule } from '@softools/softools-core';
import { IRuleState } from './rule-state.interface';
import { ExecutableRule, ExecutableMultiRowRule } from './executable-rule';
import { Application } from 'app/types/application';
import { InjectService } from 'app/services/locator.service';
import { NamedStylesService } from 'app/services/named-styles.service';

/**
 * Model/Controller for handling rule exection.
 * @see recordChange is called when the app data is updated.  This updates a set of
 * rule mappings that describe the state of the app (e.g. is a field hidden or readonly).
 * These should be merged with any other logic and used to control the visible app state.
 */
export class RulesEngine implements IRuleState {

  private executableRules: Array<ExecutableRule> = [];

  private styles = new Map<string, any>();

  @InjectService(NamedStylesService)
  private readonly namedStylesService: NamedStylesService;

  /**
   * Model representing the current state of fields affected by rules.
   * Properties named by  field id, with values representing any rule state e.g.
   *    { Text: { disabled: true, style: 'blueish'  } }
   * For list fields, the value is an array per row, with subfields contained within
   * ... or it will be eventually
   */
  private model = {};

  public constructor(public app: Application) {
    this.executableRules = app?.Rules?.map((rule) => this.createExecutableRule(app, rule));

    const styles = this.namedStylesService.getAppStyles(app);
    styles?.forEach(style => {
      const styles = {};
      if (style.BackgroundColour) {
        styles['background-color'] = style.BackgroundColour;
      }
      this.styles.set(style.Name, styles);
    });
  }

  public recordChange(record: Record, staticRecord?: Record) {
    this.model = {};
    // Combine record & static values so rule can access all values
    const merged = staticRecord ? { ...record, ...staticRecord } : record;
    this.executableRules?.forEach((rule) => {
      rule.updateModel(merged, this.model);
    });
  }

  // Rules that can apply to top level field or list field

  public isDisabled(fieldIdentifier: string, row?: number): boolean {
    return this.getRule(fieldIdentifier, row)?.['readonly'] || false;
  }

  public isHidden(fieldIdentifier: string, row?: number): boolean {
    return this.getRule(fieldIdentifier, row)?.['hidden'] || false;
  }

  public getStyleNames(fieldIdentifier: string, row?: any): Array<IStyleName> {
    return this.getRule(fieldIdentifier, row)?.['style'];
  }

  public getStyle(fieldIdentifier: string, row?: any): string {
    const styleName = this.getStyleNames(fieldIdentifier, row);
    // temp todo just use first
    if (styleName?.length > 0) {
      const tempName = styleName[0].StyleName;
      return this.styles.get(tempName);
    } else {
      return null;
    }
  }

  // Rules that can onl;y apply to top level field
  public isAddHidden(fieldIdentifier: string): boolean {
    return this.getRule(fieldIdentifier)?.['hideadd'] || false;
  }

  public isDeleteHidden(fieldIdentifier: string): boolean {
    return this.getRule(fieldIdentifier)?.['hidedelete'] || false;
  }

  private getRule(fieldIdentifier: string, row?: any) {
    return (row === undefined) ?
      this.model[fieldIdentifier] :
      this.model[fieldIdentifier]?._rows?.[row];
  }

  private createExecutableRule(app: Application, rule: Rule) {
    if (rule.MultiRow) {
      return new ExecutableMultiRowRule(app, rule);
    } else {
      return new ExecutableRule(app, rule);
    }
  }
}
