import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { FieldBase } from 'app/softoolsui.module/fields';
import { Enums, logError } from '@softools/softools-core';
import { AppIdentifiers } from 'app/services/record/app-info';
import { ContainerType, FieldContext } from '../../field-base';
import { WorkflowTriggerService } from 'app/services/workflow-trigger.service';
import { TriggeredWorkflow } from 'app/services/workflow-queue.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ButtonAppField } from 'app/types/fields/button-app-field';

@Component({
  selector: 'app-button-field',
  templateUrl: './button-field.component.html',
  styleUrls: ['./button-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonFieldComponent extends FieldBase<void, ButtonAppField> implements OnChanges, OnInit, OnDestroy {

  public imageActionButtonTypes: typeof Enums.ImageActionButtonType = Enums.ImageActionButtonType;
  public imgTitle: string;
  public workflowQueued = false;

  /** Indicates that the button is in a "clicked" state  */
  public clicked$ = new BehaviorSubject(false);

  /** If true clicked state persists until a reload; otherwise resets after a delay */
  public stickyClicked = false;

  private workflowQueueSunscription: Subscription;

  @Input() buttonSize: Enums.ImageActionButtonSize;
  @Input() imageUri: string;
  @Input() buttonType: Enums.ImageActionButtonType;
  @Input() buttonUrl: string;
  @Input() buttonUrlNewTab: boolean;
  @Input() buttonChildAppIdentifier: string;
  @Input() appIdentifier: string;
  @Input() inListReportView = false;
  @Input() inDashboardReportView = false;
  @Input() override appIdentifiers: AppIdentifiers;

  @ViewChild('button') buttonDiv: ElementRef<HTMLDivElement>;

  /** If set, display as text button with this css class */
  public textClass: string;

  constructor(
    private workflowTriggerService: WorkflowTriggerService,
    private changeDetetctorRef: ChangeDetectorRef
  ) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    const styles = this.appModel?.getNamedStyles({ target: 'field', names: this.fieldModel.NamedStyles });
    const appearances = styles?.component.Appearance?.split('|');
    if (appearances?.includes('text')) {
      if (appearances.includes('primary-button')) {
        this.textClass = 'primary-line';
      } else if (appearances.includes('secondary-button')) {
        this.textClass = 'secondary';
      } else {
        this.textClass = 'st-text-theme-primary-force';
      }
    } else {
      this.textClass = null;
    }

    if (this.buttonType === this.imageActionButtonTypes.TriggerWorkflow) {
      // Workflow buttons default to sticking in clicked state
      this.stickyClicked = true;

      this.checkTriggered();

      this.workflowQueueSunscription = this.workflowTriggerService.workflowsTriggered$.subscribe(() => {
        this.checkTriggered();
      });
    }

    this.subscribe(this.clicked$, (clicked) => {
      if (clicked && !this.stickyClicked) {
        setTimeout(() => {
          this.clicked$.next(false);
        }, 1000);
      }
    });
  }

  public override ngOnDestroy(): void {

    super.ngOnDestroy();

    this.workflowQueueSunscription?.unsubscribe();

    this.clicked$.complete();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    // Reset clicked state when record changes
    if (changes['record']) {
      this.clicked$.next(false);
    }

    this.checkTriggered();
  }

  public get isDisabled() {
    switch (this.buttonType) {
      case Enums.ImageActionButtonType.Submit: {

        // Disable if record is not editable for current user
        if (this.recordModel?.editable.value === false) {
          return true;
        }

        // Enable if no validation errors on the record
        // This is only set up on form fields; in the unlikely event of putting
        // a submit button on a list or grid field, more plumbing will be needed
        const errors = this.record._errs;
        return errors?.length > 0;
      }

      default:
        return false;
    }
  }

  public override async activate() {
    this.buttonDiv.nativeElement.focus();
  }

  public override onKeyPress($event: KeyboardEvent) {
    if ($event.code === 'Space') {
      this.performAction().catch(error => logError(error, 'Perform'));
      return true;
    }

    return super.onKeyPress($event);
  }


  private checkTriggered() {
    const key = `${this.appIdentifier}-${this.fieldModel.Identifier}${this.record?._id ? '-' + this.record._id : ''}`;
    this.workflowTriggerService.getTrigger(key).then(trigger => {
      if (trigger) {
        this.workflowQueued = true;
      } else {
        this.workflowQueued = false;
      }
      this.changeDetetctorRef.detectChanges();
    }).catch(error => logError(error, 'Failed to get trigger'));
  }

  public override initialise(context: FieldContext) {
    super.initialise(context);

    if (this.containerType === ContainerType.Form) {
      this.buttonSize = context.field.ImageActionButtonSize;
    } else {
      this.buttonSize = Enums.ImageActionButtonSize.Small;    // override size when created in list
    }

    this.appIdentifiers = context.appIdentifiers;
    this.imageUri = context.field.ImageActionButtonImageUri;
    this.buttonType = context.field.ImageActionButtonType;
    this.buttonUrl = context.field.ImageActionButtonUrl;
    this.buttonUrlNewTab = context.field.ImageActionButtonUrlNewTab;
    this.buttonChildAppIdentifier = context.field.ImageActionButtonChildAppIdentifier;
    if (context.record) {
      this.appIdentifier = context.record.AppIdentifier;
      this.record._id = context.record._id;
    }
  }

  public getTitle(): string {

    if (this.stickyClicked && this.clicked$.value) {
      return $localize`Action completed - Reload to perform  again`;
    }

    // Todo - i18
    let title = 'Click Button';
    if (this.buttonType == null) {
      return title;
    }

    switch (this.buttonType) {
      case this.imageActionButtonTypes.AddChildRecord:
        title = $localize`Add Child ${this.buttonChildAppIdentifier} Record`;
        break;
      case this.imageActionButtonTypes.CloseRecord:
        title = $localize`Close Record`;
        break;
      case this.imageActionButtonTypes.GoToChildApp:
        title = $localize`Go To Child ${this.buttonChildAppIdentifier} App`;
        break;
      case this.imageActionButtonTypes.GoToParentRecord:
        title = $localize`Go To Parent Record`;
        break;
      case this.imageActionButtonTypes.OpenUrl:
        title = $localize`Open Url`;
        break;
      case this.imageActionButtonTypes.TriggerWorkflow:
        title = $localize`Trigger Workflow`;
        break;
      case this.imageActionButtonTypes.CopyRecord:
      case this.imageActionButtonTypes.CopyRecordAsTemplate:
        title = $localize`Copy Record`;
        break;
    }
    title = this.label && this.label.length > 0 ? `${this.label} - (${title})` : title;
    return title;
  }

  public getWidth(): number {

    if (this.inListReportView) {
      return 3;
    }

    switch (this.buttonSize) {
      case Enums.ImageActionButtonSize.Small:
        return 3;
      case Enums.ImageActionButtonSize.Large:
      case Enums.ImageActionButtonSize.Full:  // size will be overriden by fill
        return 12;
      case Enums.ImageActionButtonSize.Medium:
      default:
        return 6;
    }
  }

  public getFill(): boolean {
    return !this.inListReportView &&
      this.buttonSize === Enums.ImageActionButtonSize.Full;
  }

  public get showPointer() {
    return true;
  }

  public getOverlayIconSize() {
    const native = this.buttonDiv.nativeElement;
    return Math.min(native.offsetHeight, native.offsetWidth);
  }

  public onButtonClickHandler($event: MouseEvent): void {

    if (!this.getIsDisabled()) {
      $event.stopPropagation();

      this.componentClicked$.next(this);
      this.performAction().catch(error => logError(error, 'Perform'));
    }
  }

  /** Perform the action associated with the button */
  private async performAction() {
    try {
      switch (this.buttonType) {
        case this.imageActionButtonTypes.TriggerWorkflow: {
          if (!this.workflowQueued) {
            await this.triggerWorkflow();
          }
          break;
        }

        default: {
          await this.fieldModel.perform(this);
        }
      }

    } catch (error) {
      logError(error, `btn push ${this.buttonType} ${this.buttonUrl}`);
    }
  }

  private async triggerWorkflow() {
    if (this.record && !this.record._new) {
      this.clicked$.next(true);
      this.workflowQueued = await this.fieldModel.perform(this);
      this.changeDetetctorRef.detectChanges();
    } else {
      this.globalModel.showErrorToasty({ message: $localize`Cannot trigger workflow for a new record` });
    }
  }

  public cancelWorkflow() {
    const trigger = {
      appIdentifier: this.appIdentifier,
      fieldId: this.fieldModel.Identifier
    } as TriggeredWorkflow;
    if (this.record._id) {
      trigger.recordId = this.record._id;
    }
    this.workflowTriggerService.cancelWorkflow(trigger).then(() => {
      this.workflowQueued = false;
      this.checkTriggered();
    }).catch(error => logError(error, 'Failed to trigger workflow'));
  }
}
