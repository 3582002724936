import { getCurrentUser, stringCompare } from '@softools/softools-core';
import { getChartType } from 'app/folders.module/effects';
import { AppIdentifiers } from 'app/services/record/app-info';
import { FolderGroup, FolderType, NavigationItem, RootFolders } from 'app/softoolsui.module/folders.component/folder';
import { Application } from 'app/types/application';
import { distinctUntilChanged } from 'rxjs';
import { AppModel } from '../app.model';
import { AppContextModel } from '../app/app-context.model';
import { ReportModel } from '../reports/report.model';
import { AppFolderController } from './app-folder.controller';
import { FolderModel } from './folder.model';

export class ReportFolderController extends AppFolderController {

  constructor(appModel: AppModel, public reportModel: ReportModel, folderModel: FolderModel) {
    super(appModel, folderModel);
  }

  /** Initialise the controller.  Call after construction */
  public override initialise() {
    super.initialise();

    this.subscribe(this.appModel.appContext.$, (apps: AppContextModel) => {
      // Check apps has been initialised
      if (apps) {
        try {
          this.folderModel.loading.value = true;
          this.configure(apps);
        } finally {
          this.folderModel.loading.value = false;
        }
      }
    });

    if (this.reportModel) {
      this.subscribe(this.reportModel.report.$.pipe(
        distinctUntilChanged((p, c) => p?.Identifier === c?.Identifier)), (report) => {
          if (report) {
            this.selectReport(report.Identifier);
          }
        });
    }
  }

  protected configure(apps: AppContextModel) {
    const app = apps.app.value;
    const appIdentifiers = apps.appIdentifiers.value;

    const root = new RootFolders();

    if (app) {
      this.addApplication(root, app, appIdentifiers);
    }

    this.folderModel.batch(() => {
      this.folderModel.folderGroups.value = root.FolderGroups;
    });
  }

  private addApplication(root: RootFolders, app: Application, appIdentifiers: AppIdentifiers) {
    const reportFolderGroup = new FolderGroup(FolderType.Application);
    reportFolderGroup.NewItemUrl = this.navigationService.getParentRecordUrl(appIdentifiers, 'create');
    reportFolderGroup.RecordType = app.Name;

    this.addReports(app, reportFolderGroup);

    root.FolderGroups.push(reportFolderGroup);
  }

  protected addReports(app: Application, appFolderGroup: FolderGroup): void {

    // Remove existing items
    appFolderGroup.Items = [];
    appFolderGroup.SubFolders = [];

    const reports = app?.Reports?.filter(o => !o.IsHidden)
      .filter(o => !o.InAppDataSourceFieldIndentifier)
      .sort((report1, report2) => (report1.DisplayOrder - report2.DisplayOrder) || (stringCompare(report1.Title, report2.Title)))
      || [];

    const user = getCurrentUser();

    reports.forEach(report => {
      if (app.isReportVisibleToUser(user, report)) {
        const item: NavigationItem = {
          Identifier: report.Identifier,
          Title: report.Title,
          Type: report.Type,
          SecondaryType: getChartType(report),
          AppIdentifier: app.Identifier,
          Url: this.navigationService.getReportUrl(app.Identifier, report.Identifier, report.Type),
          MustBeOnline: !app.isOfflineReport(report.Identifier)
        };

        if (report.Category) {
          // Named category so install in subfolder
          const subfolder = appFolderGroup.subfolder(report.Category);
          subfolder.Items.push(item);
        } else {
          // No category so add to root
          appFolderGroup.Items.push(item);
        }
      }
    });
  }

  protected selectReport(selectedReporidentifier: string) {
    const groups = this.folderModel.folderGroups.value;
    if (groups) {
      groups.forEach(group => {
        group.SubFolders?.forEach((sub, index) => {
          const expanded = !!sub.Items.find(item => item.Identifier === selectedReporidentifier);
          if (sub.Expanded !== expanded) {
            sub.Expanded = expanded;
          }
        })
      });

      this.folderModel.folderGroups.value = [...groups];
    }
  }
}
