import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupContainerComponent } from './popup-container.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalOverlayModule } from '../cdk-global-overlay.directive/cdk-global-overlay.module';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    GlobalOverlayModule,
  ],
  declarations: [
    PopupContainerComponent,
  ],
  exports: [
    PopupContainerComponent,
  ]
})
export class PopupContainerModule { }
