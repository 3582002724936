import { BaseChartDataService, ChartFieldEx } from './base-chart-data.service';
import { Report, ChartField, Record, IndexedAppData } from '@softools/softools-core';
import { Injectable } from '@angular/core';
import { ChartEnums } from '../chart.enums';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class NetworkChartDataService extends BaseChartDataService {
  private root: string;

  public async getNetworkChartDataOptions(app: Application, appData: IndexedAppData, report: Report, hierarchy: string, record?: Record) {

    const levelFields = this.getChartFieldsEx(app, report, ChartEnums.ChartFieldType.NetworkLevel);
    const hoverTextFields = this.getChartFieldsEx(app, report, ChartEnums.ChartFieldType.NetworkAggregate);
    const colourField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Colour);

    switch (levelFields.length) {
      // case 1:
      //   if (levelFields[0].BaseField.Type === Enums.FieldType.ListField) {
      //     // Will need to make v14 changes and app studio changes for bvuilding from list field
      //     return;
      //     // this.getListSeriesData(appData, titleField, colourField, levelFields[0], recordId);
      //   }
      default: {
        const series = await this.getMultipleFieldSeriesData(app, appData, report, colourField, levelFields, hoverTextFields, record);
        return { Series: [series] };
      }
    }

  }

  private async getMultipleFieldSeriesData(app: Application, appData: IndexedAppData, report: Report,
    colourField: ChartFieldEx, levelFields: ChartFieldEx[], hoverTextFields: ChartFieldEx[], record?: Record) {

    const networkData = new Array<NetworkNode>();
    let depth = 0;

    this.root = app.Title;

    const recordCallbackFunction = (rec: Record) => {
      const levels = levelFields
        .map(field => field.BaseAppField.getRawRecordValue(rec))
        .filter(value => value)
        .map(value => value.toString());

      let colour: any;
      if (colourField) {
        colour = colourField.BaseAppField.getRawRecordValue(rec);
      }


      if (levels.length === levelFields.length) {
        const point: NetworkNode = {
          parents: levels,
          id: levels.length ? `${this.root}-${levels.join('-')}` : `${this.root}`,
          name: levels[levels.length - 1],
          colour: colour ? colour : 'grey', hoverText: new Array<any>(),
          recordId: undefined
        };
        if (hoverTextFields?.length) {
          hoverTextFields.forEach(hoverTextField => {
            const val = hoverTextField.BaseAppField.getRawRecordValue(rec);
            point.hoverText.push({ name: hoverTextField.Label || hoverTextField.BaseField.Label, value: val });
          });
        }
        point.recordId = rec._id;
        networkData.push(point);
      }



      if (depth < levels?.length) {
        depth = levels?.length;
      }
    };


    if (record) {
      const dataSource = this.getInAppRecordData(app, report, record);
      dataSource?.forEach(r => recordCallbackFunction(r));
    } else {
      await appData.eachRecord(recordCallbackFunction);
    }

    const series = this.hierarchicalNodesToHighcartsNodes(networkData, hoverTextFields, depth);

    return {
      data: series.links,
      nodes: series.nodes
    };
  }

  private hierarchicalNodesToHighcartsNodes(networkData: Array<NetworkNode>, hoverTextFields: ChartFieldEx[], depth: number) {
    const highchartsDataArray = Array<Array<HighchartsData>>(depth);
    const highchartsData: { [key: string]: HighchartsData } = {};
    for (let i = 0; i < depth; i++) {
      highchartsDataArray[i] = new Array<HighchartsData>();
    }
    const aggregateValues: { [key: string]: { value: Array<Array<number>>, name: Array<string>, count: number } } = {};
    const rootNode: HighchartsNetworkNode = { parents: undefined, id: this.root, name: this.root, color: 'grey', marker: { radius: 40 }, hoverText: new Array<any>(hoverTextFields.length) };

    networkData.forEach(node => {
      const id = node.parents.length ? `${this.root}-${node.parents.join('-')}` : `${this.root}`;
      const parentId = node.parents.length - 1 ? `${this.root}-${node.parents.slice(0, depth - 1).join('-')}` : `${this.root}`;
      const tempHighchartNode: HighchartsData = {
        link: {
          from: parentId,
          to: id
        },
        node: {
          id: id,
          name: node.name,
          color: node.colour || 'grey',
          marker: { radius: 40 / (depth + 1) },
          hoverText: node.hoverText,
          recordId: node.recordId,
          parents: node.parents
        }
      };
      highchartsDataArray[depth - 1].push(tempHighchartNode);
      if (highchartsData[id]) {
        let done = false;
        let index = 2;
        while (!done) {
          if (highchartsData[`${id}_${index}`]) {
            index++;
          } else {
            tempHighchartNode.link.to = `${id}_${index}`;
            tempHighchartNode.node.id = `${id}_${index}`;
            highchartsData[`${id}_${index}`] = tempHighchartNode;
            done = true;
          }
        }
      } else {
        highchartsData[id] = tempHighchartNode;
      }
      this.addToAggregate(aggregateValues, node.hoverText, parentId, hoverTextFields.length);
      for (let i = node.parents.length - 2; i >= 0; i--) {
        const parents = node.parents.slice(0, i + 1);
        const child = node.parents[i];
        const cId = parents.length ? `${this.root}-${parents.join('-')}` : `${this.root}`;
        const pId = parents.length !== 1 ? `${this.root}-${parents.slice(0, i).join('-')}` : `${this.root}`;
        if (highchartsData[cId]) {
          continue;
        }
        const tempNode: HighchartsData = {
          link: {
            from: pId,
            to: cId
          },
          node: {
            id: cId,
            name: child,
            color: 'grey',
            marker: { radius: 40 / (i + 2) },
            hoverText: new Array<any>(),
            parents: parents
          }
        };
        highchartsDataArray[i].push(tempNode);
        highchartsData[cId] = tempNode;
      }
    });

    for (let i = depth - 2; i >= 0; i--) {
      highchartsDataArray[i].forEach(data => {
        this.aggregateFunction(aggregateValues, data, hoverTextFields);
      });
    }

    this.aggregateFunction(aggregateValues, { link: undefined, node: rootNode }, hoverTextFields);

    const flattenedNodes = highchartsDataArray.reduce((agg, level) => agg.concat(level));
    const links = flattenedNodes.map(node => node.link);
    const nodes = flattenedNodes.map(node => node.node);
    nodes.push(rootNode);

    return { nodes: nodes, links: links };
  }

  private addToAggregate(aggregateValues: AggregateValues, hoverText: any, parentId: string, hoverTextFieldCount: number) {
    let hoverTextVal = aggregateValues[parentId];
    if (!hoverTextVal) {
      aggregateValues[parentId] = { name: new Array<string>(hoverTextFieldCount), value: new Array<Array<number>>(hoverTextFieldCount) };
      for (let i = 0; i < hoverTextFieldCount; i++) {
        aggregateValues[parentId].value[i] = new Array<number>();
      }
      hoverTextVal = aggregateValues[parentId];
    }
    hoverText.forEach((hoverVal, k) => {
      hoverTextVal.value[k].push(hoverVal.value);
      if (!hoverTextVal.name[k]) {
        hoverTextVal.name[k] = hoverVal.name;
      }
    });
  }

  private aggregateFunction(aggregateValues: AggregateValues, node: HighchartsData, hoverTextFields: ChartField[]) {
    let aggregate = aggregateValues[node.node.id];
    if (!aggregate) {
      aggregateValues[node.node.id] = { name: new Array<string>(hoverTextFields.length), value: new Array<Array<number>>(hoverTextFields.length) };
      for (let i = 0; i < hoverTextFields.length; i++) {
        aggregateValues[node.node.id].value[i] = new Array<number>();
      }
      aggregate = aggregateValues[node.node.id];
    }
    aggregate.value.forEach((value, j) => {
      if (value.length > 0) {
        switch (hoverTextFields[j].Calculation) {
          case ChartEnums.Calculation.Average:
            node.node.hoverText[j] = {
              name: aggregate.name[j],
              value: value.reduce((agg, val) => agg += val) / value.length
            };
            break;
          case ChartEnums.Calculation.Maximum:
            node.node.hoverText[j] = {
              name: aggregate.name[j],
              value: value.reduce((agg, val) => val > agg ? val : agg)
            };
            break;
          case ChartEnums.Calculation.Minimum:
            node.node.hoverText[j] = {
              name: aggregate.name[j],
              value: value.reduce((agg, val) => val < agg ? val : agg)
            };
            break;
          case ChartEnums.Calculation.Total:
            node.node.hoverText[j] = {
              name: aggregate.name[j],
              value: value.reduce((agg, val) => agg += val)
            };
            break;
        }
      }
    });
    if (node.node.id !== this.root) {
      this.addToAggregate(aggregateValues, node.node.hoverText, node.link.from, hoverTextFields.length);
    }
  }
}

interface HighchartsData {
  link: HighchartsNetworkLink;
  node: HighchartsNetworkNode;
}

interface HighchartsNetworkLink {
  to: string;
  from: string;
}

interface HighchartsNetworkNode {
  id: string;
  name: string;
  marker?: {
    radius: number
  };
  color?: string;
  hoverText: Array<any>;
  parents: Array<string>;
  recordId?: string;
}

interface NetworkNode {
  parents: Array<string>;
  id: string;
  recordId: string;
  name: string;
  colour: string;
  hoverText: Array<any>;
}

interface AggregateValues {
  [key: string]: {
    value: Array<Array<number>>,
    name: Array<string>
  };
}
