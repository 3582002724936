import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { DashboardCell, QueryParams, SavedFilter, Enums, SelectOption, Report, HomePageDashboardFieldsStorageService, HomePageDashboardReportStorageService, HomepageDashboardReport, ReportClickThroughInfo, OnlineStatusService, logError, Template, Record, isDefined, AlignmentTypeAlias } from '@softools/softools-core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/services/app.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Application } from 'app/types/application';
import { AppModel } from 'app/mvc/app.model';
import { AppField } from 'app/types/fields/app-field';
import { ChartReportModel, FilterModel, RouteParams } from 'app/mvc';
import { ReportFilter } from 'app/filters/types';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { ButtonAppField } from 'app/types/fields/button-app-field';
import { ComponentBase } from 'app/softoolsui.module';

@Component({
  selector: 'app-dashboardreportcell',
  templateUrl: './dashboardreportcell.component.html',
  styleUrls: ['./dashboardreportcell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardReportCellComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() cell: DashboardCell;
  @Input() appModel: AppModel;
  @Input() appIdentifiers: AppIdentifiers;
  @Input() parentAppId: string;
  @Input() parentRecordId: string;

  /** Overlay trigger */
  public trigger;

  /** Filter model when the cell is configured for a report */
  public filterModel: FilterModel;

  /** Chart report model when the cell is configured for a report */
  public chartReportModel: ChartReportModel;

  public icon: IconName;
  public chartContainerId: string;
  public showFilters: boolean;
  public chartType: Enums.ChartType;
  public reportType: Enums.ReportTypes;
  public alignments: typeof Enums.DashboardCellAlignment = Enums.DashboardCellAlignment;
  public dashboardCellTypeEnums: typeof Enums.DashboardCellTypeEnum = Enums.DashboardCellTypeEnum;
  public title: string;
  public subTitle: string;
  // public records: any;
  // public chartConfig: any;
  public isLoading = true;
  public isErrored = false;
  public globalFilters: Array<SavedFilter>;
  public mappedGlobalFilters: Array<SelectOption>;
  public selectedGlobalFilterId: string = null;
  public buttonField: ButtonAppField;

  public app: Application;
  public template: Template;

  private _destroyed = false;

  constructor(
    private _appService: AppService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _homePageDashboardFieldsService: HomePageDashboardFieldsStorageService,
    private _homePageDashboardReportsService: HomePageDashboardReportStorageService,
    private models: GlobalModelService,
    public sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupCell().catch(error => logError(error, 'setupCell'));
  }

  public override ngOnDestroy(): void {
    this._destroyed = true;
    this._changeDetectorRef.detach();
  }

  public onFilterIconClickHandler(trigger): void {
    this.showFilters = !this.showFilters;
    this.trigger = trigger;
  }

  public onFiltersChangeHandler(changedValue): void {
    this.selectedGlobalFilterId = changedValue;
    this.showFilters = false;

    if (this.filterModel) {
      const savedFilter = changedValue && this.globalFilters.find(f => f.Id === changedValue);
      if (savedFilter) {
        const filter = ReportFilter.fromSavedFilter(savedFilter);
        this.filterModel.setFilter(filter);
      } else {
        // Clear filter - use and empty filter not null so it is detected as a change
        this.filterModel.setFilter(new ReportFilter());
      }
    }
  }

  public cellAlignment(): AlignmentTypeAlias {
    if (isDefined(this.cell?.Alignment)) {
      return this.alignments[this.cell.Alignment] as AlignmentTypeAlias;
    }

    return 'left';
  }

  private setCellType(): void {
    if (this.cell.CellType != null) {
      return;
    }

    // Legacy configuration will have null CellType, set relevant CellType value here
    if (this.cell.AssetImageUri) {
      this.cell.CellType = Enums.DashboardCellTypeEnum.ImageCell;
    } else if (this.cell.AppIdentifier && this.cell.ReportIdentifier) {
      this.cell.CellType = Enums.DashboardCellTypeEnum.ReportCell;
    } else if (this.cell.AppIdentifier && this.cell.FieldIdentifier) {
      this.cell.CellType = Enums.DashboardCellTypeEnum.ButtonFieldCell;
    }
  }

  private async setupCell(): Promise<void> {
    this.setCellType();

    switch (this.cell.CellType) {
      case Enums.DashboardCellTypeEnum.ImageCell:
        this._setupImageCell();
        return;

      case Enums.DashboardCellTypeEnum.ReportCell:
        await this._setupReportChartCell();
        return;

      case Enums.DashboardCellTypeEnum.ButtonFieldCell:
        await this._setupImageActionButtonFieldCell();
        return;

      case Enums.DashboardCellTypeEnum.IFrameCell:
        this._setIsLoading(false);
        return;

      case Enums.DashboardCellTypeEnum.StaticTemplate:
        await this.setupTemplateCell();
        break;

      default:
        this._setIsLoading(false);
        return;
    }
  }

  private _setupImageCell(): void {
    this.title = this.cell.AssetImageUri ? (this.cell.AssetImageTitle && this.cell.AssetImageTitle.length > 0 ? this.cell.AssetImageTitle : $localize`Asset Image`) : '';
    this.icon = 'image';
    this._setIsLoading(false);
  }

  private async _setupImageActionButtonFieldCell() {
    let field: any;
    // Check appIdentifiers is defined to determine if homepage or in app
    if (!this.appIdentifiers) {
      field = await this._homePageDashboardFieldsService.getHomepageDashboardField(this.cell.AppIdentifier, this.cell.FieldIdentifier);
    } else {
      field = this._getAppDashboardField(this.cell.AppIdentifier, this.cell.FieldIdentifier);
    }
    if (field) {
      this.title = field.Label;
      this.icon = 'mouse-pointer';
      this.buttonField = new ButtonAppField(field);
    }
    this._setIsLoading(false);
  }

  private _getAppDashboardField(appIdentifier: string, fieldIdentifier: string) {
    const app = this._appService.application(appIdentifier);
    const field = app.getField(fieldIdentifier);
    return field;
  }

  private async _setupReportChartCell() {
    this.chartContainerId = 'chart_' + this.cell.ReportIdentifier;
    this.showFilters = this.cell.ShowFilterOnLoad;

    const report = this._appService.getReport(this.cell.AppIdentifier, this.cell.ReportIdentifier);
    const dash = report ? null : await this._homePageDashboardReportsService.getHomepageDashboardReport(this.cell.AppIdentifier, this.cell.ReportIdentifier);
    const reportOrDash: Report | HomepageDashboardReport = report || dash;

    if (!reportOrDash) {
      return;
    }

    if (report) {
      this.filterModel = new FilterModel(this.appModel);
      this.chartReportModel = new ChartReportModel(this.appModel, this.filterModel, this.models.pageModel);

      const routeParams: RouteParams = {
        appIdentifiers: this.appModel.appIdentifiers.value,
        appIdentifier: this.appModel.app.value.Identifier,
        reportIdentifier: this.cell.ReportIdentifier
      };

      this.filterModel.initialise();
      this.chartReportModel.initialise();

      await this.filterModel.routed(routeParams);
      await this.chartReportModel.routed(routeParams);

      const dual = this.chartReportModel.dualReportModel$.value;
      if (dual) {
        const dualParams = { ...routeParams, reportIdentifier: dual.report.value?.Identifier };
        await dual.routed(dualParams);
      }

      // Model reload not being seen
      await this.refresh();
    }

    const chartType = report?.Chart.ChartType;
    this.reportType = reportOrDash.Type;
    this.chartType = chartType;
    this.title = reportOrDash.Title;
    this.subTitle = reportOrDash.SubTitle;
    if (!this.appIdentifiers) {
      this.appIdentifiers = new AppIdentifiers({ appIdentifier: this.cell.AppIdentifier });
    }

    await this.getChartConfigAndData(true);
  }

  private async setupTemplateCell() {

    this.app = this._appService.application(this.cell.AppIdentifier);
    // temp fallback as not being set in config
    const templateId = this.cell.TemplateIdentifier || 'Dashboard';
    this.template = this.app?.Templates.find(t => t.Identifier === templateId);

    this.title = this.template?.Title;

    this._setIsLoading(false);
  }

  private async getChartConfigAndData(firstLoad?: boolean) {
    const app = this.appModel.app.value;

    let report: Report | HomepageDashboardReport = this._appService.getReport(this.cell.AppIdentifier, this.cell.ReportIdentifier);
    let chartType: any;
    if (report != null) {
      chartType = (<Report>report).Chart.ChartType;
    } else {
      report = await this._homePageDashboardReportsService.getHomepageDashboardReport(this.cell.AppIdentifier, this.cell.ReportIdentifier);
      chartType = report != null ? report.ChartType : null;
    }
    if (!report) {
      return;
    }

    if (firstLoad) {
      if (app != null) {
        this.globalFilters = this.appModel.savedFilters.value.filter(f => f.AccessLevel === 2);
      } else {
        this.globalFilters = (<HomepageDashboardReport>report).GlobalFiltersForApp;
      }

      this.mappedGlobalFilters = this.globalFilters.map(o => ({ Text: o.Description, Value: o.Id }));
      this.selectedGlobalFilterId = this.cell.DefaultGlobalFilterId ? this.cell.DefaultGlobalFilterId.toString() : null;
    }

    this._setIsLoading(false);
  }

  private _setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
    if (!this._destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  private _getSelectedGlobalFilter(): SavedFilter {
    return this.selectedGlobalFilterId ? this.globalFilters.filter(f => f.Id === this.selectedGlobalFilterId)[0] : { Filter: '', Group: '', OrderBy: '' } as SavedFilter;
  }

  private _getChartDataQueryParams() {
    const selectedGlobalFilter = this._getSelectedGlobalFilter();
    const report = this._appService.getReport(this.cell.AppIdentifier, this.cell.ReportIdentifier);
    const baseFilter = report?.BaseFilter?.replace('$filter=', '');
    let filter = selectedGlobalFilter ? (selectedGlobalFilter.Filter ? selectedGlobalFilter.Filter.replace('$filter=', '') : '') : '';
    const groupBy = selectedGlobalFilter ? (selectedGlobalFilter.Group ? selectedGlobalFilter.Group : '') : '';
    const orderBy = selectedGlobalFilter ? (selectedGlobalFilter.OrderBy ? selectedGlobalFilter.OrderBy : '') : '';
    const queryParams = {} as QueryParams;
    if (baseFilter) {
      filter = filter ? `${filter} and ${baseFilter}` : baseFilter;
    }
    if (filter) {
      queryParams.$filter = filter;
    }
    if (groupBy) {
      queryParams.$groupby = groupBy;
    }
    if (orderBy) {
      queryParams.$orderby = orderBy;
    }
    return queryParams;
  }
}
