import { Record, OnlineStatusService, App, Failure, Field, TrackChange, Enums, PermissionEnums, ValidationError, isDefined, OdataExpressionType, IFormatting } from '@softools/softools-core';
import { LocatorService } from 'app/services/locator.service';
import { RecordPatch } from 'app/workspace.module/types';
import { IApplication } from '../app-field-source';
import { BackingField } from './backing-field';
import { isNumberField } from 'app/_constants';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { IRuleState } from 'app/mvc/rules/rule-state.interface';
import { AppFieldBase } from './app-field-base';
import { ExecutableRule } from 'app/mvc/rules/executable-rule';
import { IUpgradeData } from 'app/services/upgrade-data-storage.service';
import { AppModel, RecordModel, RecordUpdateController, ReportModel } from 'app/mvc';
import { Application } from '../application';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';

/**
 * Concrete implementation of an application field.
 * This is responsible for implementing the behaviour of the field as described by the
 * app config, and for managing the associated data held in a record.
 *
 * Data Format.
 * App Data is stored in a record  in a prooperty matching the identifier.
 *
 * Simple fields just store the value in the property.
 *
 * If the field has backing fields, this changes to a structured record.
 * The actual field value is stored in a val member; other derived or
 * backing values have associated properties e.g.
 *  { val: 1, fmt: '100%'  }
 *
 * List and Grid fields store the values of their elements as an array.
 * The elements of the array are individual field values as above.
 *
 * The TData type argument is the format of the field's stored data.
 */
export class AppField<TData = any> extends AppFieldBase implements Field {

  protected showWhenValueTrueField: AppField = null;

  public backingFields: Array<BackingField> = [];

  /**
   * If true the field does not persist values to the server.  It may have no
   * value (e.g. a button) or store local values only.
   */
  public isEmphermeral?: boolean;

  protected appModel: AppModel;

  public informationText(record: any): string {
    return null;
  }

  constructor(field?: Field, public application?: App & IApplication) {
    super();
    if (field) {
      Object.assign(this, field);
    }
  }

  public initialise(app: Application) {
    if (this.ShowWhenValueTrue) {
      this.showWhenValueTrueField = app.getField(this.ShowWhenValueTrue);
    }
  }

  public attachModel(appModel: AppModel) {
    this.appModel = appModel;
  }

  public addBackingField(backing: BackingField) {
    this.backingFields.push(backing);
  }

  public getBackingField(key: string) {
    return this.backingFields.find((backing) => backing.key === key);
  }

  /**
   * Compact record data for this field.
   * If the field has associated backing data, remove the individual backing
   * values and convert to a structure containg all related values.  Grid and
   * list fields convert data to their own compact format.
   *
   * The caller is responsible for storing the updated value in the record
   * and removing any old value for the field itself.
   *
   * @param record    Record to compcat
   * @returns         New value, or null to make no changes
   */
  public compactRecord(record: Record): any | null {
    const value = this.getInternalRecordValue(record);

    // If the field has associated backing fields, build & populate a full value structure
    if (this.backingFields.length > 0) {
      const compound = {};
      let hasBackingValue = false;
      this.backingFields.forEach((backing) => {
        // Look up backing value directly
        const backingValue = record[backing.Identifier];
        if (backingValue !== undefined) {
          compound[backing.key] = backingValue;
          delete record[backing.Identifier];
          hasBackingValue = true;
        }
      });

      // If we found any backing values, add the owning field value
      if (hasBackingValue) {
        if (value instanceof Object && value.hasOwnProperty('val')) {
          return { ...value, ...compound };
        } else {
          return { val: value, ...compound };
        }
      }
    }

    return value;
  }

  /**
   * Get formatted value for this field from a record if possible, otherwise returns raw value
   * @param record Data record
   * @param listRow Row number for a list field; ignored for scalar fields
   */
  public getDisplayRecordValue(record: Record, listRow?: number) {
    // No value if no record (probably only happens in test cases)
    if (!record) {
      return undefined;
    }

    const value = record[this.DataIdentifier];

    if (this.DisplayFormatted) {
      if (value && value.hasOwnProperty('fmt')) {
        return value.fmt;
      }

      // Fall back to old format, should be safe to delete
      const formattedName = `${this.Identifier}_Formatted`;
      if (record && record.hasOwnProperty(formattedName)) {
        return record[formattedName];
      }
    }

    if (value?.hasOwnProperty('txt')) {
      return value.txt;
    }

    return this.getRawRecordValue(record, listRow);
  }

  /**
   * Get formatted value for this field from a record if possible, otherwise returns raw value
   * @param record Data record
   * @param listRow Row number for a list field; ignored for scalar fields
   */
  public getRecordValue(record: Record, listRow?: number) {
    // No value if no record (probably only happens in test cases)
    if (!record) {
      return undefined;
    }

    if (this.Local) {
      // Local field.  Currently only used for conceled text in my profile/API key which is managed
      // by the component so we don't need to do anything.  If this is used more wideley we'll need
      // plumbing to obtain its persisted value (if Persist is true) which may well be via the record,
      // just get/set from a different place.
      return undefined;
    }

    const value = record[this.DataIdentifier];

    if (this.DisplayFormatted) {
      if (value && value.hasOwnProperty('fmt')) {
        return value.fmt;
      }

      // Fall back to old format, should be safe to delete
      const formattedName = `${this.Identifier}_Formatted`;
      if (record && record.hasOwnProperty(formattedName)) {
        return record[formattedName];
      }
    }

    return this.getRawRecordValue(record, listRow);
  }

  /**
   * Extract value from record in a form suitable for its UI components
   * @param record
   * @param listRow
   */
  public getComponentRecordValue(record: Record, listRow?: number) {
    const value = this.getInternalRecordValue(record, listRow);
    return this.adaptInternalValue(value);
  }

  /** Convert the field internal value into the format required by the field component */
  public adaptInternalValue(value: any) {
    return value;
  }

  /**
   * Get value for this field from a record without applying
   * formating.  This is the simple value for the field, any
   * secondary values are discarded.
   *
   * @param record Data record
   * @param _listRow Row number for a list field; ignored for scalar fields
   */
  public getRawRecordValue(record: Record, _listRow?: number) {
    let value: any;

    if (record && record.hasOwnProperty(this.DataIdentifier)) {
      value = record[this.DataIdentifier];
    } else if (record && record.hasOwnProperty(this.Identifier)) {
      // Fall back to full id - probably not needed now
      value = record[this.Identifier];
    } else {
      return null;
    }

    return this.simpleValue(value);
  }

  public simpleValue(value: any) {
    if (value?.hasOwnProperty('val')) {
      return value.val;
    } else {
      return value;
    }
  }

  /**
   * Get the internal data value for this field from the supplied record.
   * If the field has associated backing field values, this is a structure
   * containing them all.  If there are not backing values, it is a simple
   * value.
   */
  public getInternalRecordValue(record: Record, _listRow?: number) {
    const value = record[this.DataIdentifier];
    return value;
  }

  public getRecordTextBackingFieldValue(record: Record): string {
    const textBackingFieldIdentifier = `${this.Identifier}_Text`;
    if (record.hasOwnProperty(textBackingFieldIdentifier)) {
      return record[textBackingFieldIdentifier];
    } else {
      const recordValue = this.getInternalRecordValue(record);
      if (recordValue?.hasOwnProperty('txt')) {
        return recordValue.txt;
      }
      return null;
    }
  }

  /**
   * Convert a field value into a display string 
   * @param value       Field value
   * @param formatting  Optional additional formatting to apply
   * 
   * The formatting parameter is currently unused - formatting is
   * taken from the Formatting property. If there's a need to merge
   * in some fixed formatting these should be merged; otherwise we 
   * can remove the parameter.
  */
  public formatDisplayValue(value: any, formatting?: IFormatting) {
    return value;
  }

  /** Convert a field value into a string suitable for text editing  */
  public editableValue(value: any): string {
    return value?.toString();
  }

  public updateRecord(record: Record, value: any) {
    const current = this.getRawRecordValue(record);
    const adapted = this.adaptValue(value, current, record);
    this.storeToRecord(record, adapted);
    this.setBacking(record, adapted);
  }

  /**
   * Modify a value to be correct for the field type.
   * This is called to process values entered by the user or returned by the
   * server.  Fields that can convert non-standard formats can use this to
   * force them to their internal format.  The source parameter allows the
   * accepted formats to be different in these scenarios.
   *
   * @param value     New vakue
   * @param current   Value before change (can be null)
   * @param record    Active record when called in a record context
   * @param _source   If specified, indicates the source of the value
   */
  public adaptValue(value: any, _current: any, _record?: Record, _source?: 'input' | 'server'): any {
    return value;   // override if needed
  }

  /** Convert a string value to the correct value format for this field. */
  public adaptStringValue(value: string): any {
    return value;   // override if needed
  }

  public storeToRecord(record: Record, value: any, _listRow?: number) {
    const internal = this.getInternalRecordValue(record);
    const internalStructured = internal?.hasOwnProperty('val');
    const valueStructured = value?.hasOwnProperty('val');

    if (internalStructured && valueStructured) {
      // both are structured values, merge
      Object.assign(internal, value);
    } else if (internalStructured) {
      // simple value set to structured, val
      internal.val = value;
      record[this.DataIdentifier] = internal;
    } else {
      // replace with value (could be simple or structured)
      record[this.Identifier] = value;
    }
  }

  public setBacking(_record: Record, _value: any) {
    // nop - override for backing
  }

  /**
   * Update patch from record
   * @param patch
   * @param record
   * @param local
   */
  public updatePatch(patch: RecordPatch, record: Record, local = false) {
    const value = this.getRawRecordValue(record);
    if (local) {
      patch.addLocalChange(this.Identifier, value);
    } else {
      patch.addChange(this.Identifier, value);
    }

    this.backingFields.forEach((backing) => {
      backing.updatePatch(patch, record, local);
    });
  }

  public updatePatchWithDefault(patch: RecordPatch) {
    if (this.DefaultValue !== undefined && !this.DefaultValueIsExpression) {
      // Default is always a string
      const adapted = this.adaptStringValue(this.DefaultValue);
      patch.addChange(this.Identifier, adapted);
    }
  }

  /**
   * Store a value into the patch in a suitable format for the field.
   * This is called using externally supplied values, so concrete field types should
   * overload to validate and convert external values to a correct internal format.
   * @param patch
   * @param value
   */
  public updatePatchFromStringValue(patch: RecordPatch, value: string) {
    patch.addChange(this.Identifier, value);
  }

  /**
  * Create a patch to update this field.
  *
  * @param record    record or patch to configure new patch
  * @param value     new field value
  * @param listRow   row key or index (not required for top level fields)
  * @param options   optional mofifiers.  Set local to update local changes.
   */
  public createPatch(record: Record | RecordPatch, value: any, _listRow: number | string, options?: { local?: boolean }): RecordPatch {
    const patch = new RecordPatch(record._id, record.AppIdentifier, record.Hierarchy);
    if (record._new) {
      patch._new = true;
    }

    // Set value into the patch
    if (options?.local) {
      if (value instanceof TrackChange) {
        // Supplied a trackde change so add it direcly
        patch.addLocalTrackedChange(this.DataIdentifier, value);
      } else {
        patch.addLocalChange(this.DataIdentifier, value).setValid();
      }
    } else {
      if (value instanceof TrackChange) {
        patch.addTrackedChange(this.DataIdentifier, value);
      } else {
        patch.addChange(this.DataIdentifier, value).setValid();
      }
    }

    return patch;
  }

  public getPatchValue(patch: RecordPatch, _rowKey?: string, local = false) {
    return local ? patch.localChanges[this.DataIdentifier] : patch.changes[this.DataIdentifier];
  }

  public cloneValue(record: Record) {
    // nop, complex value fields should override to update value in record with deep copy
  }

  public compareValues(_val1: any, _val2: any, _isDescending: boolean): number {
    // Don't sort unless overridden
    return 0;
  }

  /**
   * Compare field values. The supplied values must be in the internal format.
   */
  public compareInternalValues(val1: any, val2: any, isDescending: boolean): number {

    if (this.DisplayFormatted) {
      const formatted1 = val1?.['fmt'] ?? '';
      const formatted2 = val2?.['fmt'] ?? '';
      return isDescending ? formatted2.localeCompare(formatted1) : formatted1.localeCompare(formatted2);
    }

    val1 = this.simpleValue(val1);
    val2 = this.simpleValue(val2);
    return this.compareValues(val1, val2, isDescending);
  }

  /** Return true if the field value is indentical in both records */
  public isEqual(record1: Record, record2: Record, listRow?: number): boolean {
    // Simple value comparison unless overriden
    return this.getRecordValue(record1, listRow) === this.getRecordValue(record2, listRow);
  }

  public validateRecord(record: Record, listRow?: number): Array<Failure> {
    const errors = [] as Array<Failure>;

    const value = this.getRawRecordValue(record, listRow);
    this.validate(value, errors);

    return errors;
  }

  public validate(value: any, errors: Array<Failure>, listRow?: number): void {
    // Check required flag - available on all field types but implementation can be overridden
    this.validateRequired(value, errors, listRow);
    this.validatePattern(value, errors, listRow);
  }

  public validatePattern(value: any, errors: Array<Failure>, listRow?: number) {
    // Check pattern - available on all field types but implementation can be overridden
  }

  /**
   * Validate whether the field has required validation errors.
   * The check is applied if the field is configured for required validation, or if
   * @param forceCheck is true.
   */
  public validateRequired(value: any, errors: Array<Failure>, listRow?: number, forceCheck = false): void {
    if (forceCheck || this.Required) {
      if (!this.isSuitableValue(value)) {
        errors.push({ error: ValidationError.Required, fieldId: this.Identifier, listRow });
      }
    }
  }

  public getValidityStateErrors(validity: ValidityState, listRow: number): Array<Failure> {
    if (validity.badInput) {
      const error: Failure = {
        error: ValidationError.Invalid,
        fieldId: this.Identifier,
        listRow,
        message: $localize`Invalid Value`
      };
      return [error];
    }

    return [];
  }

  public isSuitableValue(value: any) {
    return isDefined(value) && value !== '';
  }

  public isDisabled(record: Record, ruleState?: IRuleState): boolean {
    const user = this.appModel?.globalModel.tryGetUser();
    const permission =
      user?.Permissions.find(p => p.Code === PermissionEnums.Records.Update || p.Code === PermissionEnums.Records.All) ||
      this.application?.capabilities?.impliedPermissions?.find(p => p.Code === PermissionEnums.Records.Update || p.Code === PermissionEnums.Records.All);

    // Check reasons for disabling. Exact boolean checks because we get partial field data from
    // homepage buttons
    const editableRecordAccessForUser = !record || record.EditableAccessForUser;
    const fieldEditable = (this.IsEditable !== false) && !this.AutoNumber && !this.IsExpression;
    const immutable = (this.IsImmutable === true) && !record?._new;
    const ruleDisabled = ruleState?.isDisabled(this.Identifier) || false;
    const disabled = !permission || immutable || !editableRecordAccessForUser || !fieldEditable || ruleDisabled;
    return disabled;
  }

  public isEditable() {
    return this.IsEditable;
  }

  public get DataIdentifier() {
    return this.Identifier;
  }

  /** Value to display in value tooltip */
  public titleValue(record: Record) {
    const value = this.getDisplayRecordValue(record);
    return value || '';
  }

  public get IsExpression() {
    return this.Expression && this.Expression.length > 0;
  }

  public get IconTooltip() {
    if (this.IsExpression) {
      const onlineStatusService = LocatorService.get(OnlineStatusService);
      if (onlineStatusService.isConnected) {
        return 'This is an expression field';
      } else {
        return 'You\'re offline, expressions will run when you\'re next online';
      }
    }

    return '';
  }

  public get Icon(): IconName {
    if (this.IsExpression) {
      const onlineStatusService = LocatorService.get(OnlineStatusService);
      if (onlineStatusService.isConnected) {
        return 'function';
      } else {
        return 'exclamation-triangle';
      }
    }

    return null;
  }

  public get IconColor(): string {
    return 'st-text-theme-primary';
  }

  public isVisible(record: Record) {
    if (this.showWhenValueTrueField) {
      return this.showWhenValueTrueField.getRecordValue(record);
    }

    return true;
  }

  public get IsNumberField(): boolean {
    return isNumberField(this.Type);
  }

  public get Align() {
    if (this.IsNumberField && (!this.IsEditable || this.IsReadOnly)) {
      return 'right';
    }

    return 'left';
  }

  public defaultIcon() {
    return 'square';
  }

  public showHeaderOnTemplate(appModel: AppModel) {
    return !this.DisplayOptions?.ShowAsIcon;
  }

  public rowKey(record: Record, listRow: number) {
    return undefined;
  }

  public match(rule: ExecutableRule, record: Record, callback: (matched: boolean, key: any) => void): void {
    const match = rule.filter.isMatch(record);
    callback(match, null);
  }

  /** Format a value suitable for insertion in a filter string */
  public constantForFilter(value: any) {
    // default as quoted, fields that have primitive values should override
    return `'${value}'`;
  }


  public getUpgradeInformation(newAppConfig: App, upgradeData: IUpgradeData): boolean {
    return false;
  }

  public upgradeRecord(record: Record, upgradeData: IUpgradeData) {
  }

  public async calculateAggregate(appModel: AppModel) {
    // nop/override as needed
  }

  /**
   * Perform a default action for the field e.g. when clicked on in icon view
   * @param true if action performed
   */
  public async perform(context: IPerformContext): Promise<boolean> {
    // nop unless overriden
    return false;
  }

  public isFilterable() {
    // Some (settings) fields have filtering completely disabled
    if (this.Unfilterable) {
      return false;
    }

    // Unless overriden can filter any scalar field
    return this.isScalar();
  }

  public isSortable() {
    // Unless overriden can sort any scalar field that has the Sortable flag
    // This is only used in list fields, should combine with isFieldSortable()
    return this.Sortable && this.isScalar();
  }

  public isFieldSortable(): boolean {
    return this.Sortable;
  }

  /** Is the field scalar i.e. does it have a simple single value */
  protected isScalar() {
    switch (this.Type) {
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Bit:
      case Enums.FieldType.Literal:
      case Enums.FieldType.Number:
      case Enums.FieldType.Money:
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Notes:
      case Enums.FieldType.Email:
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Time:
      case Enums.FieldType.Period:
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Range:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
      case Enums.FieldType.Team:
      case Enums.FieldType.Barcode:
      case Enums.FieldType.CommentsCount:
      case Enums.FieldType.AttachmentsCount:
      case Enums.FieldType.Reference: // will need to be smarter if they support 1:many
        return true;

      case Enums.FieldType.Selection:
        return (this.SelectListType === Enums.SelectListType.Radio || this.SelectListType === Enums.SelectListType.Select);
      // The following types are not supported by the filter UI because...
      case Enums.FieldType.GridField: // Not suitable for filtering
      case Enums.FieldType.Gantt: // Field type Not implemented / Not suitable for filtering
      case Enums.FieldType.InAppChart: // Not suitable for filtering
      case Enums.FieldType.Lookup: // Field type not in list report so not implemented yet
      case Enums.FieldType.ImageActionButton: // Not suitable for filtering
      case Enums.FieldType.UrlDownloadField: // Field type Not implemented / Not suitable for filtering
      case Enums.FieldType.ListField: // Field type Not implemented / Not suitable for filtering
      case Enums.FieldType.Image: // Not suitable for filtering - new UI needed
      case Enums.FieldType.EmbeddedVideo: // Not suitable for filtering
      default:
        return false;
    }
  }

  public get clickToEdit() {
    return this.ClickToEdit ?? false;
  }

  /** If the field has a small set of possible values, returns them.
   * If the field is unconstrained, return null.
   * @param includeNull include null value if appropriate for the type
    */
  public finiteValues(options?: { includeNull?: boolean, reverse?: boolean }): Array<TData> | null {
    return null;
  }

  public filterOperations(): Array<OdataExpressionType> {
    return [
      OdataExpressionType.Equals,
      OdataExpressionType.NotEquals,
      OdataExpressionType.OneOf,
      OdataExpressionType.NoneOf
    ];
  }

  public filterOperationName(op: OdataExpressionType) {
    return {
      1: $localize`Equals`,
      2: $localize`Not equal`,
      3: $localize`Greater than`,
      4: $localize`Greater than or Equal`,
      5: $localize`Less than`,
      6: $localize`Less than or Equal`,
      14: $localize`Starts with`,
      15: $localize`Ends with`,
      16: $localize`Contains`,
      17: $localize`One of`,
      18: $localize`None of`,
      19: $localize`Contains one of`,
      20: $localize`Contains none of`,
      21: $localize`Between`,
    }[op];
  }

  /** Does this field type have a multi-value component for this expression type? */
  public isMultiValue(expressionType: OdataExpressionType) {
    return false;
  }

  /** Get text to display below field, null if none */
  public subtext() {
    if (this.Type === Enums.FieldType.FileDrop) {
      // renders its own footer
      // todo subclass
      return null;
    } else {
      return this.SubText;
    }
  }
}

export interface IPerformContext {
  value: any;
  record: Record;
  listRow?: number;

  appModel: AppModel;

  /** Record model if the field is on a record page */
  recordModel?: RecordModel;

  /** Record update controller when on a record form */
  recordUpdateController?: RecordUpdateController;

  /** Report model if the field is on a report */
  reportModel?: ReportModel;

  generalController?: IGeneralController;
}
