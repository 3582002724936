
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNotSupportedComponent } from 'app/softoolsui.module/customnotsupported.component/customnotsupported.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [CustomNotSupportedComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [CustomNotSupportedComponent],
  providers: [],
})
export class CustomNotSupportedModule { }
