import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { QueryParams, QueryParameters, PendingUser } from '../types/interfaces';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PendingUsersRepository extends RepositoryBase {

  /**
   * Get pending user records.
   *
   * @param count         Number of summaries requested
   * @param skip          First record number to request
   * @param queryParams   Query params for filter, sort etc. (top & skip taken from arguments)
   */
  public async getPendingUsersAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<PendingUser>> {

    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveV2Url('PendingUsers');
    url = this.urlResolver.appendQueryParams(url, params);
    return lastValueFrom<Array<PendingUser>>(this.get(url));
  }

  public getAllPendingUsersAsync(): Observable<Array<PendingUser>> {
    const url = this.urlResolver.resolveV2Url('PendingUsers');
    return this.get(url);

  }

  public async getPendingUserAsync(id: string | number): Promise<PendingUser> {
    const url = this.urlResolver.resolveV2Url('PendingUsers', id.toString());
    return lastValueFrom<PendingUser>(this.get(url));
  }

  /**
   * get total count of pending users
   */
  public async getCountAsync(queryParams?: QueryParams): Promise<number> {
    let url = this.urlResolver.resolveV2Url('PendingUsers', '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }

  /**
   * Resend the user's invitation email
   * @param id          Pending user id
   * @param address     Email address to send to
   */
  public async resend(id: string | number, address: string): Promise<void> {
    const url = this.urlResolver.resolveV2Url('PendingUsers', id.toString(), 'ResendSignUpEmail');
    const data = { email: address };
    return this.post(url, data).toPromise();
  }

  public async deleteAsync(id: string | number): Promise<any> {
    const url = this.urlResolver.resolveV2Url('PendingUsers', id.toString());
    return this.delete(url).toPromise();
  }

  public async pendingUsersChangedSince(when: number): Promise<Array<PendingUser>> {
    const url = this.urlResolver.resolveV2Url('PendingUsers', 'since', when.toString());
    return this.get(url).toPromise();
  }
}
