/** Types of odata expression node */
export enum OdataExpressionType {
  Filter,
  Equals,
  NotEquals,
  GreaterThan,
  GreaterThanOrEqual,
  LessThan,
  LessThanOrEqual,
  And,
  Or,
  Not,    // todo
  Has,    // todo
  Property,
  Literal,
  Group,  // ()
  StartsWith,
  EndsWith,
  Substring,

  // Pseudo-operations with multiple values
  OneOf,
  NoneOf,
  ContainsOneOf,
  ContainsNoneOf,
  Between
}
