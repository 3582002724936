import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { TextAreaComponent } from '../text-area/text-area.component';

@Component({
  selector: 'app-long-text-field',
  templateUrl: './long-text-field.component.html',
  styleUrls: ['./long-text-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongTextFieldComponent extends EditableFieldBase<string> implements AfterViewInit {

  @ViewChild(TextAreaComponent, { static: false })
  private textAreaComponent: TextAreaComponent;

  private activeWhenReady = false;

  public override async activate() {
    this.activeWhenReady = true;
  }

  public override async deactivate() {
    await this.textAreaComponent.updateCurrentValue();
  }

  ngAfterViewInit() {
    if (this.activeWhenReady) {
      this.activeWhenReady = false;
      this.textAreaComponent?.activate();
    }
  }

  public override onToggleExpandedFormTemplate() {
    this.textAreaComponent.onToggleExpandedFormTemplate();
  }
}
