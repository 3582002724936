import { Injectable } from '@angular/core';
import { Enums, Record, Report, ChartField, IndexedAppData, EpochDate } from '@softools/softools-core';
import * as moment from 'moment';
import { BaseChartDataService, ChartFieldEx } from './base-chart-data.service';
import { Field } from '@softools/softools-core';
import { ChartEnums } from '../chart.enums';
import { AppField } from 'app/types/fields/app-field';
import { isNumberField, isDateField, isDecimalField, extractDecimal } from 'app/_constants';
import { ChartErrorException } from 'app/exceptions';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class BasicChartDataService extends BaseChartDataService {

  public async getBasicChartDataOptions(app: Application, appData: IndexedAppData, report: Report, hierarchy: string, record?: Record) {

    const xField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.X);
    const yField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Y);

    const recordCountSeries = (!!xField && !yField) || (!xField && !!yField);

    const series = recordCountSeries ?
      await this.buildRecordCountSeries(app, appData, report, xField, yField, record) :
      await this.buildFieldSeries(app, appData, report, xField, yField, record);

    const categories = series?.length > 0 ? series[0].columns : new Array<string>();

    if (series?.length === 0) {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoRecords);
    }

    return { Series: series, Categories: categories, Hierarchy: hierarchy, LegendDisabled: recordCountSeries };
  }

  private async buildRecordCountSeries(app: Application, appData: IndexedAppData, reportChartConfig: Report,
    xField: ChartFieldEx, yField: ChartFieldEx, record?: Record) {

    const field = yField ? yField : xField;
    if (field) {
      const values = await this.getData(app, appData, reportChartConfig, field, record?._id);
      return [{
        name: $localize`Record Count - ${field.Label || field.BaseField.Label}`,
        data: values.data,
        columns: values.columns
      }];
    }
    return null;
  }

  private async buildFieldSeries(app: Application, appData: IndexedAppData, report: Report, xField: ChartFieldEx, yField: ChartFieldEx, record?: Record) {

    const hoverText = (report.Chart.ChartFields).filter((field) => field.Type === ChartEnums.ChartFieldType.HoverText);
    hoverText.forEach(hoverTextField => hoverTextField.BaseField = app.getField(hoverTextField.BaseFieldIdentifier));

    const hasMultiStateAxis = report.Chart.ChartType === Enums.ChartType.bar && xField.BaseField.Type === Enums.FieldType.MultiState
      || report.Chart.ChartType !== Enums.ChartType.bar && yField.BaseField.Type === Enums.FieldType.MultiState;

    // Build data, swapping axes if needed
    if (report.Chart.ChartType === Enums.ChartType.bar) {
      const series = await this.buildData(app, report, appData,
        hasMultiStateAxis,
        hoverText,
        xField,
        yField,
        record);
      return series;
    } else {
      const series = await this.buildData(app, report, appData,
        hasMultiStateAxis,
        hoverText,
        yField,
        xField,
        record);

      return series;
    }
  }

  private async buildData(
    app: Application, report: Report, appDataIndex: IndexedAppData, hasMultiStateAxis: boolean,
    hoverText: ChartField[], seriesField: ChartFieldEx, columnField: ChartFieldEx,
    record?: Record) {

    const columnAppField = columnField.BaseAppField;
    const seriesAppField = seriesField.BaseAppField;

    let columns: Array<string | number> = [];
    const seriesValues = new Array<any>();
    const intermediateData = new Array<{
      columnnName: string | number,
      seriesName: string,
      hoverText?: Array<any>
    }>();

    let seriesHasUnset = false;
    let columnHasUnset = false;

    // Apply this to each data item to process it
    const recordCallbackFunction = (rec) => {
      let seriesValue = seriesAppField.getRawRecordValue(rec);
      let columnValue = columnAppField.getRawRecordValue(rec);

      if (seriesValue == null || seriesValue === '') {
        seriesValue = 'Unset';
        seriesHasUnset = true;
      } else {
        const existing = seriesValues.findIndex(s => seriesAppField.compareValues(s, seriesValue, false) === 0);
        if (existing < 0) {
          seriesValues.push(seriesValue);
        }
      }

      if (columnValue == null || columnValue === '') {
        columnValue = 'Unset';
        columnHasUnset = true;
      } else {
        const existing = columns.findIndex(s => columnAppField.compareValues(s, columnValue, false) === 0);
        if (existing < 0) {
          columns.push(columnValue);
        }
      }

      intermediateData.push({
        columnnName: columnValue,
        seriesName: seriesValue,
        hoverText: this.getHoverTextValues(rec, hoverText)
      });
    };

    // Apply the callback function to the chart data (inapp source rows or report records)
    if (record) {
      const dataSource = this.getInAppRecordData(app, report, record);
      dataSource?.forEach(r => recordCallbackFunction(r));
    } else {
      await appDataIndex.eachRecord(recordCallbackFunction);
    }

    // Sort columns and series
    if (report.Chart.SortFieldOrder) {
      columns.sort((a, b) => columnAppField.compareValues(a, b, false));
      seriesValues.sort((a, b) => seriesAppField.compareValues(a, b, false));
    } else {
      columns.sort((a, b) =>
        columnAppField.compareValues(columnAppField.formatDisplayValue(a), columnAppField.formatDisplayValue(b), false));
      seriesValues.sort((a, b) =>
        seriesAppField.compareValues(seriesAppField.formatDisplayValue(a), seriesAppField.formatDisplayValue(b), false));
    }

    const columnNames = columns.map(column => this.getSeriesName(column, columnAppField));
    const seriesNames = seriesValues.map(value => this.getSeriesName(value, seriesAppField));

    let unsetSeriesIndex = -1;
    let unsetColumnIndex = -1;

    if (seriesHasUnset) {
      unsetSeriesIndex = seriesValues.push('Unset') - 1;
      seriesNames.push('Unset');
    }
    if (columnHasUnset) {
      unsetColumnIndex = columns.push('Unset') - 1;
      columnNames.push('Unset');
    }

    const colorKeyValueStyles = this.chartColourService.getColorKeyValueStylesAsync(app, report.NamedStyles);

    const output = new Array<{
      name: string | number | EpochDate,
      color?: string,
      data: Array<{ y: number, hoverText?: Array<any> }>,
      columns: Array<string | number | EpochDate>
    }>(seriesValues.length);

    for (let i = 0; i < seriesNames.length; i++) {
      output[i] = {
        name: seriesNames[i],
        data: new Array<{ y: number, hoverText?: Array<any> }>(),
        columns: columnNames,
        color: this.chartColourService.getColourHexcode(seriesValues[i], colorKeyValueStyles, hasMultiStateAxis)
      };
      for (let j = 0; j < columns.length; j++) {
        output[i].data[j] = {
          y: 0,
          hoverText: new Array<any>(hoverText.length)
        };
      }
    }

    // Reduce the collected data generating a count for each series value
    intermediateData.forEach(value => {

      const columnIndex = (value.columnnName === 'Unset') ?
        unsetColumnIndex :
        columns.findIndex(column => columnAppField.compareValues(value.columnnName, column, false) === 0);

      const seriesNameIndex = (value.seriesName === 'Unset') ?
        unsetSeriesIndex :
        seriesValues.findIndex(column => seriesAppField.compareValues(value.seriesName, column, false) === 0);

      if (columnIndex >= 0 && seriesNameIndex >= 0) {
        const series = output[seriesNameIndex];
        series.data[columnIndex].y++;
        series.data[columnIndex].hoverText = hoverText.map((hoverTextValue, i) => this.adjustHoverTextValues(series.data[columnIndex].hoverText[i], value.hoverText[i], hoverTextValue.BaseField));
      }
    });

    return output;
  }

  private getHoverTextValues(record: Record, hoverText: Array<ChartField>) {
    return hoverText.map((field, i) => {
      let value = (<AppField>field.BaseField).getRawRecordValue(record);
      value = value ? value : '';
      return {
        name: field.Label || field.BaseField.Label,
        value: value
      };
    });
  }

  private adjustHoverTextValues(currentHoverTextValue: any, newHoverTextValue: any, field: Field) {
    let value: { name: string, value: any };
    if (currentHoverTextValue) {
      value = {
        name: newHoverTextValue.name,
        value: newHoverTextValue?.value && $localize`Multiple values`
      };
    } else {
      value = {
        name: newHoverTextValue.name,
        value: newHoverTextValue?.value ? this.formatHoverText(newHoverTextValue?.value, field) : ''
      };
    }
    return value;
  }

  protected override formatHoverText(value: any, field: Field): string {
    switch (field.Type) {
      case Enums.FieldType.Period:
        return (moment(value)).format('MM YYYY');
      case Enums.FieldType.Date:
        return (moment(value)).format('dddd, MMMM DD, YYYY');
      case Enums.FieldType.Time:
        return (moment(value)).format('dddd, MMMM DD, YYYY h:mm A');
      default:
        return value.toString();
    }
  }

  private getHoverTextValue(matchingRecords: Record[], field: ChartField, baseFields: Field[]): any {
    if (matchingRecords.length > 0) {
      return matchingRecords.length === 1 ? this.formatHoverText(matchingRecords[0], <AppField>baseFields.find((baseField) => field.BaseFieldIdentifier === baseField.Identifier)) : $localize`Multiple values`;
    }
    return '';
  }

  private async getData(app: Application, appData: IndexedAppData, report: Report, field: ChartFieldEx, recordId?: string) {
    let columns = new Array<any>();
    const columnCount: { [key: string]: number } = {};

    const isDecimal = isDecimalField(field.BaseField.Type);
    const isNumeric = isNumberField(field.BaseField.Type);
    const isDateType = isDateField(field.BaseField.Type);

    const recordCallbackFunction = (record) => {
      let value: any;
      value = (<AppField>field.BaseField).getRawRecordValue(record);

      if (value != null) {
        if (isDateType) {
          value = value.$date ? value.$date : value;
        } else if (isDecimal) {
          value = extractDecimal(value)?.toString();
        } else if (!isNumeric) {
          value = value?.toString();
        }

        const columnIndex = columns.findIndex(column => column === value);
        if (columnIndex > -1) {
          columnCount[value]++;
        } else {
          columns.push(value);
          columnCount[value] = 1;
        }
      }
    };

    if (recordId) {
      const matchingRecords = await this.getRecordData(app, !!recordId, appData, report, recordId);
      await matchingRecords.forEach(recordCallbackFunction);
    } else {
      await appData.eachRecord(recordCallbackFunction);
    }

    if (isDateType || isNumeric) {
      columns = columns.sort((a, b) => a - b);
    } else {
      columns = columns.sort();
    }
    const columnNames = columns.map(column => this.getSeriesName(column, field.BaseAppField));

    const output = columns.map((column) => columnCount[column]);
    return { columns: columnNames, data: output };
  }

  private getSeriesName(dataPoint: any, field: AppField): string | number {

    const fieldType = field.Type;

    let seriesName = dataPoint ? dataPoint : 'Unset';

    const dateFieldType = isDateField(fieldType);
    const decimalField = isDecimalField(fieldType);
    const numberField = isNumberField(fieldType);

    if (dateFieldType) {
      switch (fieldType) {
        case Enums.FieldType.Date:
        case Enums.FieldType.DateTime:
          seriesName = field.formatDisplayValue(dataPoint);
          // seriesName = (moment(dataPoint)).format('D.MMM.YYYY');
          break;
        case Enums.FieldType.Period:
          seriesName = field.formatDisplayValue(dataPoint);
          // seriesName = (moment(dataPoint)).format('MMM.YYYY');
      }
    } else if (decimalField) {
      seriesName = extractDecimal(dataPoint) ?? 0;
    } else if (numberField) {
      seriesName = dataPoint ? dataPoint : 0;
    }

    return seriesName;
  }
}
