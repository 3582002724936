import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { logError, OnlineStatusService, SiteStorageService } from '@softools/softools-core';
import { VERSION_NUMBER } from 'app-version';
import { getRedirect, getScopes } from 'app/auth.module';
import { TenantService } from 'app/services/tenant.service';

@Component({
  selector: 'app-idp-callback',
  templateUrl: './auht0-callback.component.html',
  styleUrls: ['./auht0-callback.component.css'],
})
export class IdpCallbackComponent implements OnInit {
  public appVersionNumber = VERSION_NUMBER;

  public crossCookieError = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private onlineStatusService: OnlineStatusService,
    private siteStorage: SiteStorageService,
    private tenantService: TenantService
  ) { }

  ngOnInit() {
    // Stop api calls
    this.onlineStatusService.isServerReachable = false;

    const code = this.route.snapshot.queryParams?.['code'];

    if (code) {
      const tenant = this.tenantService.tenant();
      this.siteStorage.updateSiteLoginDataResourceAsync(tenant)
        .then((site) => {
          if (site) {
            // Auth0 idp initiated sso via SAML does not support an audiance so we have to initiate a new login on the SAML callback
            // This flow initiates a new authorize and discards the code from auth0
            // The window location will redirect the user to auth0 then back to the standard /Account/Callback with the correct code containing the audiance info
            window.location.href = `https://${environment.auth0Domain}/authorize?response_type=code&client_id=${site.Auth0ClientId}&redirect_uri=${getRedirect()}&scope=${getScopes()}&audience=${environment.apiAudiance}`;
          } else {
            this.redirectToLogin();
          }
        }).catch((error) => logError(error, 'Failed to update site login'));
    } else {
      this.redirectToLogin();
    }
  }

  public closeAndRedirect() { }

  private redirectToLogin() {
    setTimeout(() => {
      this.router.navigate(['Account/Login']).catch(error => logError(error, 'Failed to route'));
    }, 1500);
  }
}
