import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { RecordId } from '../types';
import { AppIdentifier } from 'app/types/application';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowItemsRepository extends RepositoryBase {

  /** Activate workflow assocaited with a field */
  public triggerFieldWorkflow(appIdentifier: AppIdentifier, fieldId: string, recordId: RecordId): Promise<Array<string>> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'WorkflowItems', `TriggerForField/${recordId}/${fieldId}`);
    return lastValueFrom(this.post(url));
  }

}
