import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSetGridTemplateColumnsDirective } from 'app/cssgrid.module/directives/setgridtemplatecolumns.directive';
import { SetGridColumnStartDirective } from 'app/cssgrid.module/directives/setgridcolumnstart.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AppSetGridTemplateColumnsDirective,
    SetGridColumnStartDirective
  ],
  exports: [
    AppSetGridTemplateColumnsDirective,
    SetGridColumnStartDirective
  ]
}) export class CssGridModule { }
