import { Injectable } from '@angular/core';
import { SelectListOption } from '@softools/softools-core';
import { ILanguage, SelectOptionsComponent } from 'app/softoolsui.module/select-options/select-options.component';

@Injectable({ providedIn: 'root' })
export class TranslationsService {

  public notificationOptions: Array<SelectListOption>;
  public languageOptions: Array<SelectListOption>;
  public languageList: Array<ILanguage>;

  constructor() { }

  public setSelectOptionsTranslations(component: SelectOptionsComponent) {
    this.notificationOptions = component.getNotificationOptions();
    this.languageOptions = component.getLanguageOptions();
    this.languageList = component.getLanguageList();
  }

  public get options() {
    return {
      notificationOptions: this.notificationOptions,
      languageOptions: this.languageOptions,
      languageList: this.languageList
    };
  }
}
