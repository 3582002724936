<div class="h-100 d-flex flex-column" cdkOverlayOrigin #formOrigin="cdkOverlayOrigin">
  <ng-container *ngFor="let filter of flattened$|async; let i = index">
    <div *ngIf="i > 0">
      <i i18n class="text-muted mx-4 mt-2">and</i>
    </div>
    <div class="bg-st-theme-primary text-white shadowed mx-2 p-1 pointer">
      <div class="filter-block px-4 align-items-center" (click)="editFilterClause($event, filter); editFilterIndex = i">
        <span class="field-id" [title]="filter.FieldIdentifier" >{{ filter.FieldIdentifier }} </span>
        <span class="operator px-1">
          <i *ngIf="filter.Operator === 1" i18n>equals</i>
          <i *ngIf="filter.Operator === 2" i18n>not equal to</i>
          <i *ngIf="filter.Operator === 3" i18n>greater than</i>
          <i *ngIf="filter.Operator === 4" i18n>greater than or equal to</i>
          <i *ngIf="filter.Operator === 5" i18n>less than</i>
          <i *ngIf="filter.Operator === 6" i18n>less than or equal to</i>
          <i *ngIf="filter.Operator === 14" i18n>starts with</i>
          <i *ngIf="filter.Operator === 15" i18n>ends with</i>
          <i *ngIf="filter.Operator === 16" i18n>contains</i>
        </span>
        <span class="operand" [title]="filter.displayOperand">{{ filter.displayOperand }}</span>
        <fa-icon class="trash"
          [icon]="['fal', 'trash']"
          class="ps-2"
          style="margin-left: auto"
          title="Remove this filter term"
          i18n-title
          (click)="clearFilterClause($event, filter)"
        ></fa-icon>
      </div>

      <ng-container
        *ngIf="showFilter === 'edit' && editFilterIndex === i"
        [ngTemplateOutlet]="filterPanel"
      ></ng-container>
    </div>
  </ng-container>
  <div class="bg-st-theme-primary text-white shadowed mx-2 my-4 p-1" style="margin-top: auto !important">
    <div class="px-4 d-flex align-items-center justify-content-center pointer" (click)="createFilterClause($event)">
      <sof-plus-icon class="pe-2"></sof-plus-icon>
      <span i18n>Add Filter</span>
    </div>

    <ng-container *ngIf="showFilter === 'add'" [ngTemplateOutlet]="filterPanel"></ng-container>
  </div>
</div>

<ng-template #filterPanel>
  <div class="overflow-hidden" style="margin-top: auto !important">
    <sof-filter-edit
      [appIdentifier]="appIdentifier"
      [filterModel]="filterModel"
      [filterSpec]="filterSpec"
      [filterEditorUi]="filterEditorUi"
      [showSortGroup]="false"
      (termUpdated)="filterTermUpdated($event)"
      (cancelled)="filterTermCancelled()"
    >
    </sof-filter-edit>
  </div>
</ng-template>
