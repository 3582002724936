import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, empty, of, throwError, firstValueFrom } from 'rxjs';
import { UrlResolver } from '../utils';
import { map, catchError } from 'rxjs/operators';
import { MulticastHttpService } from './multicast-http.service';

export function RequestHeaders(cachable = false): HttpHeaders {
  return new HttpHeaders({
    'Content-Type': 'application/json',
    'cache-control': cachable ? 'private' : 'no-cache',
  });
}

@Injectable({ providedIn: 'root' })
export class RepositoryBase {
  public pageSize = 25;
  public fetchMorePageSize = 25;
  public recordsRead = 0;
  public skip = 0;
  public pageNumber = 1;
  public filter = '';
  public orderby = '';
  public groupby = '';
  public dataurl = '';
  public hierarchy: string;
  public search: string;
  public reportId: string;
  public rootNestedReportId: string; // Nested charts should use root list report nested config for base filters
  public random = 0;

  constructor(protected urlResolver: UrlResolver, protected http: HttpClient, private requestSchedulerService: MulticastHttpService) {}

  protected getOptions() {
    return { headers: RequestHeaders() };
  }

  protected getOptionsForBlob() {
    return { headers: RequestHeaders(), responseType: 'blob' as 'blob', observe: 'response' as 'response' };
  }

  protected getOptionsForText() {
    return { headers: RequestHeaders(), responseType: 'text' as 'text' };
  }

  public get(url: string, responseType?: 'text' | 'json' | 'blob'): Observable<any> {

    // Don't perform operation if http not hooked up - should only be in tests that haven't
    // injected a real HttpClient
    if (!this.http) {
      return null;
    }

    let options;
    switch (responseType) {
      case 'text':
        options = this.getOptionsForText();
        break;
      case 'blob':
        options = this.getOptionsForBlob();
        break;
      case 'json':
      default:
        options = this.getOptions();
        break;
    }

    return this.requestSchedulerService.queueOrGet(url, () => this.http?.get(url, options));
  }

  public post(url: string, postData?: any) {
    // beforeSend: core.setTenantXhrHeaders,
    return this.http.post(url, postData, this.getOptions()).pipe(catchError((err: HttpErrorResponse) => this.catchValidationError(err)));
  }

  public postWithType(url: string, responseType: 'text' | 'json' | 'blob', postData?: any) {
    const options = this.getPayloadOptions(responseType);
    return this.http.post(url, postData, options).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (responseType) {
          case 'text':
            return this.catchTextValidationError(err);
          default:
            // todo blob?
            return this.catchValidationError(err)
        }
      }
      ));
  }

  public patch(url: string, postData?: any) {
    return this.http.patch(url, postData, this.getOptions()).pipe(catchError((err: HttpErrorResponse) => this.catchValidationError(err)));
  }

  /**
   * Patch data asynchronously
   * @param url 
   * @param patchData 
   * @returns 
   * @throws HttpErrorResponse if the sever returns an error response.
   */
  public async patchAsync(url: string, patchData?: any) {
    return firstValueFrom(this.http.patch(url, patchData, this.getOptions()));
  }

  public patchWithType(url: string, responseType: 'text' | 'json' | 'blob', postData?: any) {
    const options = this.getPayloadOptions(responseType);
    return this.http.patch(url, postData, options).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (responseType) {
          case 'text':
            return this.catchTextValidationError(err);
          default:
            // todo blob?
            return this.catchValidationError(err)
        }
      }
      ));
  }

  public catchValidationError(err: HttpErrorResponse): Observable<any> {
    if (err && err.error && err.error.Message && err.error.ValidationErrors) {
      return of(err.error);
    }

    return throwError(err);
  }

  public catchTextValidationError(err: HttpErrorResponse): Observable<any> {
    if (err.error) {
      const e = { ...err, error: JSON.parse(err.error) };
      return this.catchValidationError(e);
    }

    return throwError(err);
  }

  public patchNoResponse(url: string, postData?: any) {
    return this.http.patch(url, postData, this.getOptionsForText());
  }

  /** Send a delete request  */
  public del(url: string) {
    return this.http.delete(url, this.getOptions()).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          return of(error);
        } else {
          throwError(error);
        }

        return empty();
      })
    );
  }

  /** Send a delete request and return error response on failure */
  public delete(url: string) {
    return this.http.delete(url, this.getOptions()).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          return of(error);
        } else {
          throwError(error);
        }

        return empty();
      })
    );
  }

  private getPayloadOptions(responseType: 'text' | 'json' | 'blob') {
    switch (responseType) {
      case 'text':
        return this.getOptionsForText();
      case 'blob':
        return this.getOptionsForBlob();
      case 'json':
      default:
        return this.getOptions();
    }
  }
}
