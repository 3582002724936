import { NgZone } from '@angular/core';
import { App, AppDataStorageService, AppsRepository, AppsService, ChangedRecord, IAppVersions, ImageList, ImageListsStorageService, ImagesListAssetStorageService, IRetryPolicy, logError, logMessage, Record, RecordId, SelectList, SelectListsStorageService, SiteSettings } from '@softools/softools-core';
import { ArrayModelProperty, MapModelProperty, Model, ModelEvent, ModelProperty, ModelTimer } from '@softools/vertex';
import { Throttle } from 'app/core/utils/throttle';
import { SavedFiltersApplication } from 'app/embedded.apps/settings.apps/saved-filters/saved-filters.app';
import { AppService } from 'app/services/app.service';
import { BackgroundDataSyncService } from 'app/services/background-data-sync.service';
import { ChangeQueueStorageService } from 'app/services/change-queue.service';
import { ChangedRecordsStorageService } from 'app/services/changed-records-storage.service';
import { IdentifiersService } from 'app/services/identifiers.service';
import { InjectService } from 'app/services/locator.service';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { SelectListsService } from 'app/services/select-lists.service';
import { ZoneService } from 'app/services/zone.service';
import { AppIdentifier, Application } from 'app/types/application';
import { AppZone } from 'app/types/enums';
import { Subscription, timer } from 'rxjs';
import { GlobalModel } from '..';

export class SiteModel extends Model<SiteModel> {

  public readonly settings = new ModelProperty<SiteSettings>(this).withLogging('Site Props');

  public readonly apps = new MapModelProperty<AppIdentifier, Application>(this).withLogging('Apps', false);

  public readonly selectLists = new ArrayModelProperty<SelectList>(this).withLogging('Select Lists', false);

  public readonly priorityApps = new ArrayModelProperty<AppIdentifier>(this).withLogging('Priority Apps');

  /** Event fired when a change has been detected. For an offline app this fires after the record
   * has been refreshed (@see onRecordUpdated fires first); an online app fires this without 
   * refreshing (@see onRecordUpdated does not fire)
   */
  public readonly onRecordChangeDetected = new ModelEvent<{ appIdentifier: AppIdentifier, id: RecordId }>();

  public readonly onRecordUpdated = new ModelEvent<Record>();

  private readonly dataTimer = new ModelTimer(this, 10_000, 5_000);

  /** Most recently seen change id for overall changes  */
  private lastChangeid: string;

  /** Most recently seen change ids for individual apps  */
  private priorityChanges = new Map<string, string>();

  private readonly batchSize = 1000;

  @InjectService(NgZone)
  private readonly ngZone: NgZone;

  @InjectService(AppsService)
  private _appsService: AppsService;

  @InjectService(AppService)
  private appService: AppService;

  @InjectService(AppsRepository)
  private _appsRepository: AppsRepository;

  @InjectService(ZoneService)
  private readonly zoneService: ZoneService;

  @InjectService(SelectListsStorageService)
  private _selectListService: SelectListsStorageService;

  @InjectService(ImagesListAssetStorageService)
  private _imageListAssetService: ImagesListAssetStorageService;

  @InjectService(ImageListsStorageService)
  private _imageListService: ImageListsStorageService;

  @InjectService(SavedFiltersApplication)
  private savedFiltersApp: SavedFiltersApplication;

  @InjectService(BackgroundDataSyncService)
  private readonly backgroundDataSyncService: BackgroundDataSyncService;

  @InjectService(ChangeQueueStorageService)
  private changeQueueStorageService: ChangeQueueStorageService;

  @InjectService(RecordQueueService)
  private recordQueueService: RecordQueueService;

  @InjectService(AppDataStorageService)
  private readonly appDataStorageService: AppDataStorageService;

  @InjectService(ChangedRecordsStorageService)
  private changedRecordsStorageService: ChangedRecordsStorageService;

  @InjectService(IdentifiersService)
  private identifiersService: IdentifiersService;

  private dataSub: Subscription;
  private changeSub: Subscription;
  private appsSub: Subscription;

  constructor(private globalModel: GlobalModel) {
    super();

    // clear any new polling flag (it was written as false if not present)
    // we can remove this eventually
    localStorage.removeItem('use-new-polling');

    this.subscribe(this.globalModel.appInit.$, () => {
      this.initialise();
    });
  }

  public initialise() {
    // Refresh ids service when apps change
    this.subscribe(this.apps.$, (apps) => {
      if (apps) {
        const appList = Array.from(apps.values());
        this.identifiersService.initialise(appList).catch(error => logError(error, 'e'));
      }
    });
  }

  public async initialiseAsync(selectListService: SelectListsService) {
    this.apps.value = await this.appService.applicationMapAsync();
    this.selectLists.value = await selectListService.refresh('init');
    this.start();

    this.subscribe(this.backgroundDataSyncService.recordUpdated$, (record) => {
      if (record) {
        this.recordUpdated(record);
      }
    });
  }

  public start() {
    this.startBackgroundSync();
  }

  public startBackgroundSync() {
    this.ngZone.runOutsideAngular(async () => {

      // Resync apps every 2 mins if the version has changed.
      this.appsSub = this.subscribe(timer(120000, 120000), () => {
        this.pollChangedApps();
      });

      // Sync app data in backgroun
      await this.startSyncApplicationData();

      // Set timer for polling data
      // Values chosen to start later than change poll (so there's no delay on first batch)
      // and run more frequently as it doesn't hit the server unless there are changes
      // but we want to get them reasonably quickly if there are some. But not too quick
      // to avoid load... tweak to optimise. 
      // Bit of a nasty loose binding but it's not dependent on these numbers
      const throttle = new Throttle(5000);
      this.dataSub = this.subscribe(timer(5500, 5000), async () => {
        const run = throttle.start();
        if (run) {
          try {
            await this.pollChangedAppData();
          } catch (error) {
            logError(error, 'Data change Timer');
          } finally {
            throttle.end();
          }
        }
      });
    });
  }

  public stopBackgroundSync() {
    // stop timers running
    if (this.changeSub) {
      this.unsubscribe(this.changeSub);
      this.changeSub = null;
    }

    if (this.dataSub) {
      this.unsubscribe(this.dataSub);
      this.dataSub = null;
    }

    if (this.appsSub) {
      this.unsubscribe(this.appsSub);
      this.appsSub = null;
    }
  }

  public getApp(id: AppIdentifier): Application {
    return this.apps.get(id);
  }

  /** Reload a single application config */
  public async syncApplicationAsync(appIdentifier: string) {
    const app = await this.syncApp(appIdentifier);
    if (app) {
      this.replaceApps(app);
    }
    return app;
  }

  /**
   * Synchrnoize all apps replacing their config
   */
  public async syncApps(retryPolicy: IRetryPolicy = null) {
    const apps = await this._appsService.syncApps(retryPolicy);
    const map = new Map<AppIdentifier, Application>(this.apps.value);

    for (let i = 0; i < apps.length; i++) {
      const app = apps[i];
      await this.upgradeApp(app);
      const application = await this.appService.update(app);
      map.set(application.Identifier, application);
    }

    this.apps.value = map;
    return apps;
  }

  private replaceApps(...updated: Application[]) {
    const cloneMap = new Map<AppIdentifier, Application>(this.apps.value);
    updated.forEach(app => {
      cloneMap.set(app.Identifier, app);
    });
    this.apps.value = cloneMap;
  }

  private async pollChangedApps() {
    try {
      if (this.zoneService.getCurrentZone() !== AppZone.sync && this.zoneService.getCurrentZone() !== AppZone.login) {
        const user = this.globalModel.tryGetUser();

        if (user && this.globalModel.online.value) {
          const appVersions = await this._appsRepository.getLatestAppVersionNumbers();

          const apps = this.apps.value;

          // Apps that have changed
          const updatedApps: Array<Application> = [];

          for (let i = 0; i < appVersions.length; i++) {
            try {
              const appVersion = appVersions[i];
              const app = apps.get(appVersion.appIdentifier);
              if (app) {
                const appChanged = await this.trySyncApp(app, appVersion);
                if (appChanged) {
                  updatedApps.push(appChanged);
                }
              } else {
                // new app
                const newApp = await this.syncApp(appVersion.appIdentifier);
                if (newApp) {
                  updatedApps.push(newApp);
                }
              }
            } catch (ex) {
              logError(ex, `Error syncing app ${appVersions[i]?.appIdentifier} in the background`);
            }
          }

          if (updatedApps.length > 0) {
            this.replaceApps(...updatedApps);
          }
        }
      }
    } catch (error) {
      logError(error, `Error syncing apps in the background`);
    }
  }

  /** Synchronize an app config if it has changed since it was las downloaded */
  private async trySyncApp(app: Application, appVersion: IAppVersions): Promise<Application> {
    if (!app || !this.globalModel.online.value) {
      return null;
    }

    if (app && !app.IsEmbedded) {
      // const appVersion = appVersions.find((av) => av.appIdentifier === app.Identifier);
      if (appVersion) {
        const latestVerionNumberFromServer = appVersion.version;

        if (app.AppVersionNumber !== latestVerionNumberFromServer) {
          // Resync app in store
          const updatedApp: Application = await this.syncApp(app.Identifier);
          return updatedApp;
        }
      }
    }

    return null;
  }

  /**
   * Synchronize an app by downloading its config to local storage along with
   * other dependent objects
   *
   * @param appIdentifier App to sync
   */
  private async syncApp(appIdentifier: string): Promise<Application> {
    const updatedApp: App = await this._appsService.syncApp(appIdentifier);
    await this.upgradeApp(updatedApp);

    await this._selectListService.syncForApp(appIdentifier);

    const imageLists: ImageList[] = await this._imageListService.syncForApp(appIdentifier);
    await this._imageListAssetService.syncImageListAssets(imageLists);

    // Reload saved filters for the app to keep in sync
    await this.savedFiltersApp.synchronizeApplicationFilters(appIdentifier);

    return await this.appService.update(updatedApp);
  }

  private async upgradeApp(app: App) {
    const existing = this.getApp(app.Identifier);
    if (existing && existing.AppVersionNumber < app.AppVersionNumber) {
      await existing.storeUpgradeInformation(app);
    }
  }

  /** Process record updated by background sync */
  private async recordUpdated(record: Record) {
    const app = this.apps.get(record.AppIdentifier);
    if (app) {
      // tidy up record. More of this needs to be delegated to the app
      // when the mechanism is used to handle settings updates etc
      if (record._deleted) {
        // Clear any pending patches
        await this.changeQueueStorageService.deleteForRecord(record._id);
        await this.recordQueueService.delete(record._id);
        // Remove from storage
        await this.appDataStorageService.removeRecordAsync(record._id);
      } else if (record.IsArchived) {
        // Remove from storage
        await this.appDataStorageService.removeRecordAsync(record._id);
      } else if (!record._new) {
        // update storage if offline mode
        if (app.isOfflineApp) {
          await app.storeAsync(record);
        }
      }
    }

    this.onRecordUpdated.fire(record);
  }

  private async startSyncApplicationData() {

    const dataThrottle = new Throttle(6000);
    this.changeSub = this.subscribe(this.dataTimer.$, async () => {
      const run = dataThrottle.start();
      try {
        if (run) {

          // If we've not get the last change id(s) yet load now.
          // We'll try on each tick until it works.
          if (!this.lastChangeid) {
            await this.loadHorizons();
          }

          if (this.lastChangeid) {
            await this.getChanges();
          }
        }
      } catch (error) {
        logError(error, 'Data Sync Timer');
      } finally {
        if (run) {
          dataThrottle.end();
        }
      }
    });

    this.dataTimer.start();
  }  

  private async pollChangedAppData() {

    // Process priority app records
    const priority = this.priorityApps.value;
    if (priority?.length > 0) {
      const promises = priority.map(pri => this.changedRecordsStorageService.getAllChangesAsync(50, pri))
      const all = await Promise.all(promises);
      const changed = all.flat();
      if (changed?.length > 0) {
        await this.backgroundDataSyncService.processChangedRecords(changed);
        return;
      }
    }

    // no priority changes so update other apps
    const changed = await this.changedRecordsStorageService.getAllChangesAsync(50);
    if (changed?.length) {
      await this.backgroundDataSyncService.processChangedRecords(changed);
    }
  }

  private async getChanges() {
    // Scan for changes in any app
    const changes = await this.appDataStorageService.getChangesAfter(this.lastChangeid, this.batchSize);
    const count = changes?.length;
    if (count > 0) {
      await this.processChanges(changes);
      const lastChange = changes.pop();
      this.lastChangeid = lastChange.Id;
    }

    // If we have prioritised foreground apps, check whether the generic check has overtaken
    // them in which case we don't need to prioritise them any more
    this.removeUnwantedAppChanges();

    // If we got a full page of changes we could be missing important ones because we're being
    // swamped by a big update in other apps so turn on priority processing
    if (count === this.batchSize) {
      this.watchPriorityApps();
    }

    // If there are priority apps, check them
    if (this.priorityChanges) {
      await this.getPriorityChanges();
    }

    // Store horizon data to storage so we pick up after a restart
    this.storeHorizons();
  }

  /** Remove priority app changes if they've been overtaken by the main global change check */
  private removeUnwantedAppChanges() {
    if (this.priorityChanges) {
      // clone as we'll be modifying as we go
      const priorities = Array.from(this.priorityChanges.entries());

      priorities.forEach(([appIdentifier, changeIdentifier]) => {
        if (this.lastChangeid >= changeIdentifier) {
          this.priorityChanges.delete(appIdentifier);
        }
      });

      if (this.priorityChanges.size === 0) {
        this.priorityChanges = null;
      }
    }
  }

  /** Get changes for any configured priority apps */
  private async getPriorityChanges() {
    if (this.priorityApps.value) {
      // todo parallel?
      for (let index = 0; index < this.priorityApps.value.length; index++) {
        const appIdentifier = this.priorityApps.value[index];
        const changeIdentifier = this.priorityChanges.get(appIdentifier);
        if (changeIdentifier) {
          const changes = await this.appDataStorageService.getAppChangesAfter(appIdentifier, changeIdentifier);
          if (changes?.length > 0) {
            await this.processChanges(changes);
            const lastChange = changes.pop();
            this.priorityChanges.set(appIdentifier, lastChange.Id);
          }
        }
      }
    }

    this.storeHorizons();
  }

  /** Process change records */
  private async processChanges(changes: Array<ChangedRecord>) {
    for (const change of changes) {
      const app = this.appService.tryGetApplication(change.AppIdentifier);
      if (app) {
        // Only update records in offline apps, or if we already have a local copy
        if (app?.isOfflineApp || await this.appDataStorageService.get(change.Id)) {
          // todo skip if priority change has done this change
          await this.changedRecordsStorageService.saveChangeAsync(change);
        }

        this.onRecordChangeDetected.fire({ appIdentifier: change.AppIdentifier, id: change.ObjectId });
      } else {
        // Log as error. This previously would have thrown and so not processed
        // the change. This is no worse and gives more information. If we see this
        // in sentry we probably need to take more action e.g. store the change but
        // don't process it until we know about the app (which might not have been synced yet)
        logMessage(`Change ${change.Id} for unknown app ${change.AppIdentifier}`);
      }
    }
  }

  /** Load change info from storage */
  private async loadHorizons() {

    this.priorityChanges = null;

    let changes = localStorage.getItem('latest-change-id');
    if (changes) {

      const parts = changes.split(';');
      const globalPart = parts.shift();
      this.lastChangeid = globalPart;

      if (parts.length > 0) {
        this.priorityChanges = new Map<string, string>();
        parts.forEach(part => {
          const [appId, changeId] = part.split(':');
          this.priorityChanges.set(appId, changeId);
        });
      }
    } else {
      const latestChange = await this.appDataStorageService.getLatestChange();
      if (latestChange) {
        this.lastChangeid = latestChange.Id;
      } else {
        logMessage('Not polling, no change id');
      }
    }
  }

  /** Update change info to storage */
  private storeHorizons() {
    if (this.lastChangeid && this.priorityChanges) {
      const appChanges = Array.from(this.priorityChanges.entries())
        .map(([key, value]) => `${key}:${value}`)
        .join(';');
      localStorage.setItem('latest-change-id', `${this.lastChangeid};${appChanges}`);
    } else if (this.lastChangeid) {
      localStorage.setItem('latest-change-id', this.lastChangeid);
    } else {
      localStorage.removeItem('latest-change-id');
    }
  }

  /** Add the current priority apps into individual change map */
  private watchPriorityApps() {
    if (this.priorityApps.value) {
      if (!this.priorityChanges) {
        this.priorityChanges = new Map<string, string>();
      }

      this.priorityApps.forEach((value) => {
        if (!this.priorityChanges.has(value)) {
          this.priorityChanges.set(value, this.lastChangeid);
        }
      });
    }

  }
}
