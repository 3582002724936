<ng-container #inner></ng-container>

<!-- Fields not handled internally by app-field -->
<app-list-field *ngIf="fieldModel.Type === Enums.FieldType.ListField"
  [appModel]="appModel"
  [appIdentifiers]="appIdentifiers"
  [application]="application"
  [template]="template"
  [record]="record"
  [fieldModel]="fieldModel"
  [form]="form"
  [ruleState]="ruleState"
  [elementStyles]="elementStyles" 
  [isEditable]="fieldModel.IsEditable && (recordModel?.editable.$|async)"
>
</app-list-field>

<div *ngIf="fieldModel.subtext() as subtext">
  <span class="st-text-theme-primary">{{subtext}}</span>
</div>

<app-field-footer 
  [field]="fieldModel"
  [readonly]="form?.ReadOnly || template?.IsReadOnly"
  [editErrors]="editValidationErrors$|async"
  [record]="record">
</app-field-footer>
