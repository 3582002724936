import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { IWatcher, ModelBase, MvcBase, MvcContainer } from '@softools/vertex';

/**
 * Angular directive that manages component redraw.
 * 
 * Usage: Attach the vxWatch selector to an element. The parameter is
 * one or an arry of models, model properties or model events which 
 * cause a redraw check when any of the watched properties change.
 * 
 * Example:
 *    <!-- Triggers redraw check if any property of heroModel changes -->
 *    <app-hero [vxWatch]="heroModel" >
 * 
 *      <!-- Use property.value for a typed value -->
 *      <app-cowl [color]="heroModel.cowlColor.value" ></app-cowl>
 * 
 *      <!-- Triggers redraw check if specific  properties change -->
 *      <app-utility-belt [vxWatch]="[beltModel.size, beltModel.slots]">
 * 
 *        <!-- Use properties directly (without .value) to get string form -->
 *        <span>A {{beltModel.size}} belt with {{beltModel.slots}} slots</span>
 *      </app-utility-belt>
 *    <app-hero>
 */
@Directive({
  selector: '[vxWatch]'
})
export class WatchDirective extends MvcBase implements IWatcher, OnDestroy, OnChanges {

  @Input() vxWatch: MvcContainer | Array<MvcContainer>;

  private properties: Array<MvcContainer>;

  constructor(private element: ElementRef, private changeDetector: ChangeDetectorRef) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Initialise watchers. Needs to be here because directive can be recycled.
    // Don't initialise in ngOnInit to avoid unecessary reset
    if (changes['vxWatch']) {
      if (this.properties) {
        this.unwatch(this.properties);
      }

      if (this.vxWatch) {
        this.storeProperties(this.vxWatch);
        this.watch(this.properties);
      }
    }
  }

  public ngOnDestroy(): void {
    this.dispose();

    if (this.properties) {
      this.unwatch(this.properties);
    }

  }

  public notify(property: ModelBase) {
    this.changeDetector.markForCheck();
  }

  private storeProperties(properties: MvcContainer | Array<MvcContainer>) {
    if (Array.isArray(properties)) {
      this.properties = properties;
    } else {
      this.properties = [properties];
    }
  }

  private watch(properties: Array<MvcContainer>) {
    properties.forEach((property) => {
      property?.watch(this);
    });
  }

  private unwatch(properties: Array<MvcContainer>) {
    properties.forEach((property) => {
      property?.unwatch(this);
    });
  }
}
