import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, Router } from '@angular/router';

import { Record, logError } from '@softools/softools-core';
import { APP_IDENTIFIER, RECORD_ID } from 'app/_constants/constants.route';
import { AppService } from 'app/services/app.service';
import { AppContextService } from 'app/services/app-context.service';
import { GetRecordOptions } from 'app/types/application';

@Injectable()
export class RecordResolver implements Resolve<Record> {

  constructor(
    private appService: AppService,
    private appContextService: AppContextService,
    private router: Router,
  ) { }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record> {

    const params: Params = route.params;
    const appIdentifier: string = params[APP_IDENTIFIER] as string;
    let recordId = route.params[RECORD_ID] as string;

    try {
      const app = this.appService.application(appIdentifier);

      if (!recordId && app.IsSingletonApp) {
        recordId = app.StaticRecordId;
      }

      // Get record from storage or API depending on off/online state and apply any pending changes
      const options: GetRecordOptions = { applyPatch: true, fallbackRemote: true };
      if (this.appContextService.ShowArchived) {
        options.forceRemote = true;
      }

      const record: Record = await app.getRecordByIdAsync(recordId, options);

      if (!record) {
        // Don't redirect to home page on error, the UI needs to show a record not found message if there is a 500
        return null;
      } else if (record.AppIdentifier !== appIdentifier) {
        logError(new Error(`Selected a ${record.AppIdentifier} record running ${appIdentifier}`), '');
        // Could do with an error place
        this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
      } else {
        return record;
      }
    } catch (error) {
      console.log(`Could not load ${appIdentifier} record ${recordId}`, error);
    }

    return null;
  }
}
