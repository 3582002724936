import { Input, Directive } from '@angular/core';

export type SizeType = '1x' | '2x' | '3x' | '4x';

@Directive()
export class BaseIconComponent {
  @Input() size: SizeType = '1x';
  @Input() spin = false;

  constructor(public iconType) {}
}
