import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FieldBase } from '../fields/field-base';

/**
 * Displays a field in a large rounded rectangle.
 * Suitable for emphasising values.  Always treated as read-only.
 */
@Component({
  selector: 'app-cartouche-field',
  templateUrl: './cartouche-field.component.html',
  styleUrls: ['./cartouche-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartoucheFieldComponent extends FieldBase implements OnInit, OnChanges {

  public text: string;

  constructor() { super(); }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  public override ngOnChanges(changes: SimpleChanges) {
    if (changes['record'] && this.record) {
      this.text = this.fieldModel.getDisplayRecordValue(this.record);
    }
  }

}
