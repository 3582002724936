import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SavedFilter, Enums, ReportField, stringCompare, Report, tryGetCurrentUser, PermissionEnums, logError } from '@softools/softools-core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FilterSaveComponent, FilterSaveData } from '../filter-save/filter-save.component';
import { ReportFilter } from '../types/report-filter';
import { FilterSpecification, SavedFilterSpecification } from 'app/filters/types';
import { Field } from '@softools/softools-core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { MessageDialogData, MessageType } from '../../softoolsui.module/message-dialog/message-dialog.component';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppField } from 'app/types/fields/app-field';
import { AppIdentifier } from 'app/types/application';
import { AppService } from 'app/services/app.service';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { AppModel, FilterModel } from 'app/mvc';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { ToastyService } from 'app/services/toasty.service';
import { ComponentBase } from 'app/softoolsui.module';
import { FilterEditorUi } from '../types/filter-editor-ui';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';

@Component({
  selector: 'app-filter-management-panel',
  templateUrl: './filter-management-panel.component.html',
  styleUrls: ['./filter-management-panel.component.scss']
})
export class FilterManagementPanelComponent extends ComponentBase implements OnChanges, OnInit {

  @Input() appModel: AppModel;

  @Input() filterModel: FilterModel;

  @Input() isOnline = true;

  @Input() appIdentifier: AppIdentifier;

  @Input() selectedFilter: ReportFilter;

  /** Currently selected report */
  @Input() report: Report;

  /** The fields that are displayed on the current report */
  @Input() reportFields = [] as Array<ReportField>;

  /** The full set of fields in the application */
  @Input() appFields = [] as Array<AppField>;

  @Input() selectOnly = false;

  @Input() isEmbeddedApp: boolean;

  @Input() showFilterManagement: IShowFilterManagement;

  @Output() panelClosed = new EventEmitter<void>();

  public selectedFilterId: string;

  public nullPseudoId = 'null-filter';

  public customPseudoId = '';

  /** If not null, the filter being edited; if null displaying the filter list */
  public editFilterSpec: FilterSpecification = null;

  // Sort field current selection
  public sortNoField = '';
  public selectedSortField: string = this.sortNoField;

  // Sort options and current selection
  public sortAscending = 'asc';
  public sortDescending = 'desc';
  public sortNoOrder = 'none';
  public selectedSortOrder = this.sortNoOrder;

  public selectedGroupField = '';

  public selectedGroupOrder = this.sortNoOrder;

  public filteredFields: Observable<Array<Field>>;
  public filteredGroups: Observable<Array<Field>>;
  private subSort = new Subject<string>();
  private subGroup = new Subject<string>();

  private user = tryGetCurrentUser();

  // Filter popup support.  Will need to push up to state
  public filterEditorUi = new FilterEditorUi();

  public popupPosition = [new ConnectionPositionPair({ originX: 'center', originY: 'center' }, { overlayX: 'center', overlayY: 'center' })];

  constructor(
    private appService: AppService,
    private overlayService: OverlayService,
    private toasties: ToastyService
  ) {
    super();
  }

  ngOnInit(): void {
    try {
      this.filteredFields = this.subSort.pipe(
        startWith(''),
        map((field) => {
          const data = field ? this.filterFieldsForSort(field) : [...this.sortFields()];
          data.sort((a, b) => stringCompare(a.Label, b.Label));
          return data;
        })
      );
      this.filteredGroups = this.subGroup.pipe(
        startWith(''),
        map((field) => {
          const data = field ? this.filterFieldsForGroup(field) : [...this.groupFields()];
          data.sort((a, b) => stringCompare(a.Label, b.Label));
          return data;
        })
      );
    } catch (error) {
      logError(error, '');
    }
  }

  filterFieldsForGroup(field: string): any {
    return this.groupFields().filter((appField) => {
      if (appField.Label && appField.Label.toLowerCase().indexOf(field.toLowerCase()) >= 0) {
        return true;
      }

      if (appField.Identifier && appField.Identifier.toLowerCase().indexOf(field.toLowerCase()) >= 0) {
        return true;
      }

      return false;
    });
  }

  filterFieldsForSort(field: string): any {
    return this.sortFields().filter((appField) => {
      if (appField.Label && appField.Label.toLowerCase().indexOf(field.toLowerCase()) >= 0) {
        return true;
      }

      if (appField.Identifier && appField.Identifier.toLowerCase().indexOf(field.toLowerCase()) >= 0) {
        return true;
      }

      return false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['selectedFilter']) {
        if (this.selectedFilter) {
          if (this.selectedFilter.Id) {
            this.selectedFilterId = this.selectedFilter.Id;
          } else if (this.selectedFilter.Filter || this.selectedFilter.Group || this.selectedFilter.OrderBy) {
            // Custom filter
            this.selectedFilterId = this.customPseudoId;
          } else {
            // No filter
            this.selectedFilterId = this.nullPseudoId;
          }
        }
      }
    } catch (error) {
      logError(error, '');
    }
  }

  public personalFilters(): Array<SavedFilter> {
    const savedFilters = this.appModel.savedFilters.value;
    return savedFilters
      ? savedFilters
          .filter((f) => f.AccessLevel === Enums.FilterAccessLevel.Personal)
          .sort((filter1, filter2) => stringCompare(filter1.Description, filter2.Description))
      : [];
  }

  public teamFilters(): Array<SavedFilter> {
    const savedFilters = this.appModel.savedFilters.value;
    return savedFilters
      ? savedFilters
          .filter((f) => f.AccessLevel === Enums.FilterAccessLevel.Team)
          .sort((filter1, filter2) => stringCompare(filter1.Description, filter2.Description))
      : [];
  }

  public globalFilters(): Array<SavedFilter> {
    const savedFilters = this.appModel.savedFilters.value;
    return savedFilters
      ? savedFilters
          .filter((f) => f.AccessLevel === Enums.FilterAccessLevel.Global)
          .sort((filter1, filter2) => stringCompare(filter1.Description, filter2.Description))
      : [];
  }

  public iconForFilter(filter: FilterSpecification): IconName {
    switch (filter.AccessLevel) {
      case Enums.FilterAccessLevel.Personal:
        return 'user';
      case Enums.FilterAccessLevel.Team:
        return 'users';
      case Enums.FilterAccessLevel.Global:
        return 'globe-americas';
      default:
        return 'filter';
    }
  }

  public isBaseFilterSelectionEnabled(filter: FilterSpecification): boolean {
    const app = this.appService.application(this.appIdentifier);

    return (
      !this.selectOnly &&
      filter.AccessLevel === Enums.FilterAccessLevel.Global &&
      app.IsEditable &&
      this.user &&
      this.user.hasPermission([PermissionEnums.AppStudio.All, PermissionEnums.AppStudio.Reports])
    );
  }

  public isSelectedFilter(id: string): boolean {
    return this.selectedFilterId === id;
  }

  public filterClicked(id: string) {
    this.selectedFilterId = id;
    this.submit();
  }

  public editFilter = (id: string) => {
    if (this.isOnline) {
      switch (id) {
        case this.nullPseudoId:
          // Create an empty filer spec as a basis for creating a new filter
          this.editFilterSpec = new FilterSpecification(new ReportFilter(), this.appFields, this.reportFields);
          break;
        case this.customPseudoId:
          // Use copy of current filter
          const reportFilter = new ReportFilter(this.filterModel.filterSpec.reportFilter);
          this.editFilterSpec = new FilterSpecification(reportFilter, this.appFields, this.reportFields);
          break;
        default: {
          // lookup saved filter
          const editFilter = this.appModel.savedFilters.value?.find((filt) => filt.Id === id);
          this.editFilterSpec = new SavedFilterSpecification(editFilter, this.appFields, this.reportFields);
          break;
        }
      }

      this.setSelectedSortField();

      this.selectedSortOrder = !this.editFilterSpec.sortField
        ? this.sortNoOrder
        : this.editFilterSpec.sortAscending
        ? this.sortAscending
        : this.sortDescending;

      this.setSelectedGroupField();

      this.selectedGroupOrder = !this.editFilterSpec.groupField
        ? this.sortNoOrder
        : this.editFilterSpec.groupAscending
        ? this.sortAscending
        : this.sortDescending;
    }
  }

  private setSelectedSortField() {
    const field = this.appFields.find((f) => f.Identifier === this.editFilterSpec.sortField);
    this.selectedSortField = field ? field.Label : this.sortNoField;
  }

  private setSelectedGroupField() {
    const field = this.appFields.find((f) => f.Identifier === this.editFilterSpec.groupField);
    this.selectedGroupField = field ? field.Label : this.sortNoField;
  }

  public submit() {
    if (this.selectedFilterId && this.selectedFilterId !== this.nullPseudoId) {
      // apply currently selected filter
      const savedFilter = this.appModel.savedFilters.value?.find((filt) => filt.Id === this.selectedFilterId);
      const filter = ReportFilter.fromSavedFilter(savedFilter);
      this.filterModel.updateCurrentFilter(filter).catch(error => logError(error, 'Failed to current update filter'));
    } else if (this.selectedFilterId !== this.customPseudoId) {
      this.filterModel.updateCurrentFilter(null).catch(error => logError(error, 'Failed to current update filter'));
    }

    // Close popup
    this.close();
  }

  // Get the filter selected in the filter list, or null if none
  public get filter(): SavedFilter {
    return this.selectedFilterId ? this.appModel.savedFilters.value?.find((f) => f.Id === this.selectedFilterId) : null;
  }

  public delete(event: Event) {
    event.stopPropagation();

    if (this.editFilterSpec) {
      const confirmData: MessageDialogData = {
        Type: MessageType.ConfirmDeleteFilter,
        Value: this.editFilterSpec.Description,
      };

      this.appModel.globalModel.showMessageDialogAsync(confirmData).then(async (ok) => {
        if (ok) {
          await this.appModel.deleteSavedFilterAsync(this.editFilterSpec.Id);

          // Back to selection view as we can't look at this filter any more
          this.editFilterSpec = null;
          this.refresh();
        }
      }).catch(error => logError(error, 'Failed to show delete message confirm'));
    }
  }

  public async saveAs(event: Event): Promise<void> {
    try {
      event.stopPropagation();


      // Should only be possible in edit mode
      // Sone logs show it's ;ossible to reenter (how?) e.g. V18-652
      if (this.editFilterSpec) {
        const saveData: FilterSaveData = {
          app: this.appModel.app.value,
          savedFilters: this.appModel.savedFilters.value,
          filterSpec: this.editFilterSpec,
        };

        const result = await this.appModel.globalModel.dialogAsync(FilterSaveComponent, saveData, {});
        if (result) {
          const savedFilter = result.savedFilter;
          if (savedFilter) {
            await this.appModel.saveFilterAsync(savedFilter);
          }

          // Back to selection view
          this.editFilterSpec = null;
          this.refresh();
        }
      }
    } catch (error) {
      logError(error, 'FilterManagement saveAs');
    }
  }

  public cancel(event: Event): void {
    event.stopPropagation();

    if (this.editFilterSpec) {
      this.editFilterSpec = null;
      this.refresh();
    } else {
      this.close();
    }
  }

  public sortFieldChanged = (e: MatAutocompleteSelectedEvent) => {
    if (e.option.value === this.sortNoField) {
      this.editFilterSpec.sortField = null;
      this.selectedSortOrder = this.sortNoOrder;
    } else {
      this.editFilterSpec.sortField = e.option.value;
      if (this.selectedSortOrder === this.sortNoOrder) {
        this.selectedSortOrder = this.sortAscending;
      }
    }

    this.setSelectedSortField();
  }

  public sortOrderChanged = (_) => {
    this.editFilterSpec.sortAscending = !this.editFilterSpec.sortAscending;
  }

  public groupChanged = (e: MatAutocompleteSelectedEvent) => {
    if (e.option.value === this.sortNoField) {
      this.editFilterSpec.groupField = null;
      this.selectedGroupOrder = this.sortNoOrder;
    } else {
      this.editFilterSpec.groupField = e.option.value;
      if (this.selectedGroupOrder === this.sortNoOrder) {
        this.selectedGroupOrder = this.sortAscending;
      }
    }

    this.setSelectedGroupField();
  }

  public groupOrderChanged = (_) => {
    this.editFilterSpec.groupAscending = !this.editFilterSpec.groupAscending;
  }

  /**
   * Update the filter being edited
   *
   * If the edit filter is a saved filter, update it.  If it is not
   * active, activate it.  Close the panel.
   *
   * If the edit filter is a custom filter, update it.
   *
   * Close the panel
   */
  public updateFilter = () => {
    if (this.editFilterSpec) {
      const reportFilter = this.editFilterSpec.updateFilter();
      if (reportFilter) {
        if (!reportFilter.Description) {
          reportFilter.Description = 'Custom';
        }

        this.filterModel.updateCurrentFilter(reportFilter).catch(error => logError(error, 'Failed to current update filter'));
      }
    }

    // Close popup
    this.close();
  }

  private close(): void {
    this.showFilterManagement?.showFilterManagementPanel(false);

    if (this.panelClosed.observers.length) {
      this.panelClosed.emit();
    }
  }

  public sortFields(): Array<Field> {
    return this.appFields
      .filter((field) => field.isFilterable())
      .filter((field) => field.isFieldSortable())
      .sort((field1, field2) => (field1.Label > field2.Label ? 1 : -1));
  }

  public groupFields(): Array<Field> {
    return this.appFields
      .filter((field) => field.isFilterable())
      .filter((field) => field.isFieldSortable())
      .sort((field1, field2) => (field1.Label > field2.Label ? 1 : -1));
  }

  public searchFields(val) {
    this.subSort.next(val.target.value);
    if (!val) {
      this.sortFieldChanged(<MatAutocompleteSelectedEvent>{ option: { value: this.sortNoField } });
    }
  }

  public searchGroups(val) {
    this.subGroup.next(val.target.value);
    if (!val.target.value) {
      this.groupChanged(<MatAutocompleteSelectedEvent>{ option: { value: this.sortNoField } });
    }
  }

  public isBaseFilter(report: Report, filter: FilterSpecification): boolean {
    try {
      return report?.BaseFilterId && report.BaseFilterId === filter.Id;
      // const saved = this.savedFilters.find(s => s.Id === filter.Id);
      // return saved && saved.BaseFilterReportIds && saved.BaseFilterReportIds.includes(report.Identifier);
    } catch (error) {
      logError(error, '');
      return false;
    }
  }

  public setBaseFilter(event: Event, filter: FilterSpecification = null) {
    try {
      event.stopPropagation();
      const filterId = filter ? filter.Id : '';
      const confirmData: MessageDialogData = {
        Type: filterId ? MessageType.ConfirmSetBaseFilter : MessageType.ConfirmClearBaseFilter,
      };

      this.appModel.globalModel.showMessageDialogAsync(confirmData).then(async (ok) => {
        if (ok) {
          // Store base filter id in active report so it's actioned immediately
          this.report.BaseFilterId = filterId;

          // Persist changes
          const changes = { BaseFilterId: filterId };

          // Takes a few seconds to save the filter.
          // This creates a lag
          try {
            this.overlayService.openSpinner();

            await this.appModel.updateReportAsync(this.report.Id, changes);
            this.filterModel.updateBaseFilter();

            if (filterId) {
              this.toasties.success($localize`Updated report base filter`);
            } else {
              this.toasties.success($localize`Cleared report base filter`);
            }
          } catch (erroe) {
            this.toasties.error($localize`Failed to set filter on report`);
          } finally {
            this.overlayService.close();
          }
        }
      }).catch(error => logError(error, 'Failed to set base filter'));

      this.close();
    } catch (error) {
      logError(error, '');
    }
  }
}
