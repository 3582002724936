
<section class="home-wrapper">

  <app-folders
    #leftAside
    class="left-aside-area"
    [folderModel]="folderModel"
    [title]="currentApp?.Title"
    [showAddNewRecord]="canCreate"
    [appIdentifier]="appIdentifier"
    [appIdentifiers]="appIdentifiers"
    [pinnedStateVal]="showAside$ | async">
  </app-folders>

  <div class="main-content-area home-container">

    <ng-container *ngIf="!(appHomeModel.loadError.$| async)">

      <div class="app-header-area p-2">
        <app-form-header
          [showAppIcon]="true"
          [showAppTitle]="true"
          [showAppDescription]="showAppHelpDescription$|async"
          [showHelpIcon]="false"
          [appTitle]="currentApp?.Title"
          [appImageUrl]="currentApp?.LogoImageUri"
          [iconBase64]="currentApp?.IconBase64"
          [appDescription]="currentApp?.Description" >
        </app-form-header>
      </div>

      <div class="help-icon m-2 pointer d-flex"

        (click)="showInfoOverlay()">
        <fa-icon *ngIf="!(showAppHelpPage$|async)"
          class="mt-3"
          [icon]="['fal', 'info-circle']" size="2x"
        ></fa-icon>
      </div>

      <div *ngIf="appTitleFieldIdentifier && isOfflineApp && !appModel.app.value?.IsEmbedded" class="app-search-area p-4">
        <app-search-autocomplete
          [searchText]="searchText"
          [options]="appHomeModel.searchOptions.$|async"
          [searchPending]="appHomeModel.searchPending.$|async"
          [appIdentifier]="appIdentifier"
          [hasFooterAction]="appDefaultListReportIdentifier?.length > 0"
          [footerActionImgSrc]="'/assets/app-icons/v2/List.svg'"
          (onSearch)="onAppSearchHandler($event)"
          (onSearchEnter)="onAppSearchOpenDefaultListReportHandler($event)"
          (onSearchEscape)="onAppSearchEscHandler()"
          (onOptionSelected)="onAppSearchOptionSelectedHandler($event)"
          (onFooterActionClick)="onAppSearchOpenDefaultListReportHandler($event)"
        >
        </app-search-autocomplete>
      </div>

      <app-home-record-cards *ngIf="!appModel.app.value?.IsEmbedded" class="record-cards-area p-2"
        [appHomeModel]="appHomeModel"
        [generalController]="generalController"
        [appIdentifiers]="appIdentifiers"
        [records]="appHomeModel.records.$|async"
        [items]="appHomeModel.items.$|async" >
      </app-home-record-cards>
    </ng-container>
  </div>
</section>

<app-new-record-button [canCreate]="canCreate" [createUrl]="createUrl"></app-new-record-button>

<app-floating-support-widget></app-floating-support-widget>
