
import { Enums } from '../enums/enums';
import {
  initialModalComponentContentOptions,
  ModalComponentContentOptions
} from '../interfaces/modalcomponentcontenttypeoptions.interface';
import { ModalComponentContentData, initialModalComponentContentData } from '../interfaces/modalcomponentcontentdata.interface';

export enum ModalMessageType {
  UseContent,
  MakeAvailableOffline,
  MakUnavailableOffline,
}

export interface ModalOptions {
  Show?: boolean;
  Type: Enums.ModalType;
  Title?: string;
  TextContent?: string;
  Component?: any;
  ComponentContentOptions?: ModalComponentContentOptions;
  ComponentContentData?: ModalComponentContentData;
  HideConfirm?: boolean;
  MessageType?: ModalMessageType;

  onConfirmClickHandler?: any;
  onRejectClickHandler?: any;
}

export const initialModalOptions: ModalOptions = {
  Show: false,
  Type: Enums.ModalType.info,
  Title: '',
  TextContent: '',
  Component: null,
  ComponentContentOptions: Object.assign({}, initialModalComponentContentOptions),
  ComponentContentData: Object.assign({}, initialModalComponentContentData),
  HideConfirm: false,
  MessageType: ModalMessageType.UseContent,

  onConfirmClickHandler: () => { }
};

