import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { empty } from 'rxjs';

import { ChartData } from '@softools/softools-core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable()
export class ChartDataResolver implements Resolve<ChartData> {

  constructor(
    private models: GlobalModelService,
  ) { }

  private async _syncAppData(_route: ActivatedRouteSnapshot): Promise<ChartData> {
    // todo this is no longer working as a resolver
    // Refactor to a guard if it's not removed as part of mvctodo
    return null;
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<ChartData> {
    try {
      return await this._syncAppData(route);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Unauthorised so user must login again
        // Build return URL excluding query params (which the guards will put back)
        const returnUrl = `/${route.url.map(seg => seg.path)
          .map(path => path.includes('?') ? path.substring(0, path.indexOf('?')) : path)
          .join('/')}/`;
        await this.models.globalModel.logout(returnUrl);
      }

      // Return empty observable to indicate failed resolution
      return empty();
    }
  }
}
