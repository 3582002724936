import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageRepository } from '../repos/image.repository';
import { Enums } from '../types/enums/enums';
import { App } from '../types/interfaces/app.interface';
import { Field } from '../types/interfaces/field.interface';
import { logError, LogLevel } from '../utils';
import { AppDataStorageService } from './services.appdata';


@Injectable({ providedIn: 'root' })
export class ImageStorageService {

  /**
   * Collection of local image blobs.
   */
  private blobs = new Map<string, SafeUrl>();

  constructor(
    private appDataService: AppDataStorageService,
    private imageRepository: ImageRepository,
    private domSanitizer: DomSanitizer) {
  }

  public async syncImageFieldAssets(apps: Array<App>) {

    // Get all images fields set to available offline
    const imageFieldIdentifiers = new Map<string, Array<Field>>();
    apps.filter(app => app.OfflineAvailability?.AvailableOffline)
      .forEach(app => {
        imageFieldIdentifiers.set(app.Identifier, app.Fields.filter(field => field.Type === Enums.FieldType.Image && field.AvailableOffline));
      });

    const imageFieldAssetsToStore = new Map<string, Array<string>>();

    // Store the image for offline use.
    for (const [appIdentifier, fields] of imageFieldIdentifiers) {
      for (const field of fields) {
        await this.appDataService.eachRecord(appIdentifier, record => {
          if (!imageFieldAssetsToStore.has(appIdentifier) || imageFieldAssetsToStore.get(appIdentifier).length !== 1000) {
            if (record[field.Identifier]?.length > 0) {
              const assetIds = imageFieldAssetsToStore.get(appIdentifier) ?? [];
              const assetId = record[field.Identifier];
              if (!assetIds.find(f => f === assetId)) {
                assetIds.push(assetId);
              }
              imageFieldAssetsToStore.set(appIdentifier, assetIds);
            }
          }
        });
      }
    }

    // Fetch and store images
    for (const [_, urls] of imageFieldAssetsToStore) {
      for (const url of urls) {
        try {
          await this.getImageAssetUrlAsync(`${url}`, true, 'tiny');
          await this.getImageAssetUrlAsync(`${url}`, true, 'medium');
        } catch (error) {
          logError(error, 'Failed to store image assert', LogLevel.warning);
        }
      }
    }
  }


  /**
   * Load an image and return a local blob URL to represent it
   *
   * @param id          Image or asset id
   * @param isImage     If true id is an image id, false for asset id
   */
  public async getImageAssetUrlAsync(id: string, isImage = true, size: 'tiny' | 'medium' | 'full') {
    var storeId = `${id}-${size}`
    try {
      if (this.blobs.has(storeId)) {
        return this.blobs.get(storeId);
      } else {
        const blob = isImage ?
          await this.imageRepository.getImageByImageId(id, size) :
          await this.imageRepository.getImageByAssetId(id);

        if (blob) {
          // Mark URL as safe from XSS as we will have used auth token
          const url = this.sanitise(URL.createObjectURL(blob));
          this.blobs.set(storeId, url);
          return url;
        } else {
          // no stored blob, save null in map so we don't keep looking
          this.blobs.set(storeId, null);
          return null;
        }
      }
    } catch (error) {
      logError(error, 'Failed to get image asset');
      return null;
    }
  }

  public sanitise(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
}
