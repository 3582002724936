import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { UrlResolver, OnlineStatusService } from '@softools/softools-core';
import { Router, ActivatedRoute } from '@angular/router';

import { WSReportBase } from 'app/workspace.module/components/ws-base/ws-reportbase';
import { contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation, REPORT_IDENTIFIER } from 'app/_constants';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { MatrixReportModel } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { filterMgtAnimation, quickFiltersAnimation } from 'app/workspace.module/animations/ws-animations';

@Component({
  selector: 'app-ws-matrixreport',
  templateUrl: './ws-matrixreport.component.html',
  styleUrls: ['./ws-matrixreport.component.scss'],
  animations: [
    contentPinnedStateAnimation,
    rightbarToggleContentStateAnimation,
    leftAsideStateAnimation,
    quickFiltersAnimation,
    filterMgtAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSMatrixReportComponent extends WSReportBase implements OnInit, OnDestroy {

  public matrixReportModel: MatrixReportModel;

  constructor(
    router: Router,
    indexedDbService: IndexedDbService,
    appService: AppService,
    urlresolver: UrlResolver,
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    navigationService: NavigationService,
    permissionsService: PermissionsService,
    models: GlobalModelService,
  ) {
    super(indexedDbService, appService, router, urlresolver, onlineStatus, route, navigationService, permissionsService, models);
  }

  public get clickThroughId() {
    return this.reportModel.report.value?.TargetClickThroughReportId;
  }

  protected override createReportModel() {
    this.matrixReportModel = new MatrixReportModel(this.appModel, this.filterModel, this.models.pageModel);
    return this.matrixReportModel;
  }

}
