import { Component, OnInit, ViewChild } from '@angular/core';
import { logError, LogicBlock } from '@softools/softools-core';
import { LogicBlockExecutorService } from 'app/services/logic-block-executor.service';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { BehaviorSubject } from 'rxjs';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-concealed-text-field',
  templateUrl: './concealed-text-field.component.html',
  styleUrls: ['./concealed-text-field.component.scss', '../input.scss']
})
export class ConcealedTextFieldComponent extends EditableFieldBase<string> implements OnInit {

  @ViewChild(InputComponent, { static: false }) public inputComponent: InputComponent;

  public concealed$ = new BehaviorSubject<boolean>(true);

  private lazyLoadLogic: LogicBlock;

  /** API key, undefined means not loaded yet */
  public hiddenValue: string = undefined;

  constructor(private executor: LogicBlockExecutorService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const loadId = this.fieldModel.Local?.LazyGetValueLogicBlock;
    if (loadId) {
      this.lazyLoadLogic = this.application.LogicBlocks?.find(block => block.Identifier === loadId);
    } else {
      this.hiddenValue = this.value;
    }
  }

  public override async activate() {
    this.inputComponent?.activate();
  }

  public iconClicked($event: MouseEvent) {
    $event.stopPropagation();

    if (this.hiddenValue === undefined) {
      this.loadAndShowValue().catch(error => logError(error, 'Failed to load show value'));    // async
    }

    this.concealed$.next(!this.concealed$.value);
  }

  private async loadAndShowValue() {
    const key = await this.executor.execute(this.lazyLoadLogic, { record: this.record, listRow: this.listRow });
    if (key) {
      this.hiddenValue = key;
      this.concealed$.next(false);
    }
  }
}
