<div class="outer-container mb-4">
  <div class="field-frame shadowed p-2 my-2" [ngStyle]="elementStyles?.component?.css">
    <div class="value-display" [ngStyle]="elementStyles?.['text']?.css">
      <span>
        {{text}}
      </span>
    </div>
    <div class="label-display" [ngStyle]="elementStyles?.['label']?.css">
      <span>
        {{fieldModel.Label}}
      </span>
    </div>
  </div>
</div>
