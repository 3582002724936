import { Injectable } from '@angular/core';
import { Report, Record, Enums, ChartField, IndexedAppData } from '@softools/softools-core';
import * as moment from 'moment';
import { PieLevel, PieData } from '../chart-utilities';
import { ChartEnums } from '../chart.enums';
import { BaseChartDataService } from './base-chart-data.service';
import { AppField } from 'app/types/fields/app-field';
import { isDateField } from 'app/_constants';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class PieChartDataService extends BaseChartDataService {

  public async getPieChartDataOptions(app: Application, appDataIndex: IndexedAppData, report: Report, record?: Record) {
    const pieSeries = await this.buildPieLevels(app, appDataIndex, report, record);

    return { Series: pieSeries };
  }

  private async buildPieLevels(app: Application, appDataIndex: IndexedAppData, reportChartConfig: Report, record?: Record) {
    const chartFields = (reportChartConfig.Chart.ChartFields).filter(field => field.Type === ChartEnums.ChartFieldType.PieLevel);
    chartFields.forEach(field => {
      field.BaseField = app.getField(field.BaseFieldIdentifier);
      if (reportChartConfig.Chart.HasValueFields) {
        field.ValueField = app.getField(field.ValueFieldIdentifier);
      }
    });
    chartFields.sort((a, b) => a.Order - b.Order);

    const pieLevel: PieLevel = { chartField: chartFields[0], chartDataValue: [], numberOfChildren: chartFields.length };

    const recordCallbackFunction = (rec) => {
      this.getPieData(reportChartConfig.Chart.HasValueFields, rec, pieLevel, chartFields);
    };

    if (record) {
      const records = await this.getRecordData(app, !!record, appDataIndex, reportChartConfig, record?._id, record);
      await records.forEach(recordCallbackFunction);
    } else {
      await appDataIndex.eachRecord(recordCallbackFunction);
    }

    this.sortPieData(pieLevel, reportChartConfig.Chart.HasValueFields);

    let colourIndex = 0;

    let i = 0;
    let workingLevel = pieLevel;
    const parentLevels = new Array<{ level: PieLevel, index: number }>();
    let done = false;
    do {
      if (i < workingLevel.chartDataValue.length) {
        workingLevel.chartDataValue[i].colourIndex = colourIndex;
        if (parentLevels.length === 0) {
          if (colourIndex === 8) {
            colourIndex = 0;
          } else {
            colourIndex++;
          }
        } else {
          workingLevel.chartDataValue[i].colourIndex = colourIndex === 0 ? 8 : colourIndex - 1;
        }
      }

      if (workingLevel.chartDataValue[i] && workingLevel.chartDataValue[i].childLevel) {
        parentLevels.push({ level: workingLevel, index: i + 1 });
        workingLevel = workingLevel.chartDataValue[i].childLevel;
        i = 0;
      } else if (i + 1 < workingLevel.chartDataValue.length) {
        i++;
      } else {
        if (parentLevels.length !== 0) {
          const tempLevel = parentLevels.pop();
          workingLevel = tempLevel.level;
          i = tempLevel.index;
        } else {
          done = true;
        }
      }
    } while (!done);

    const output = new Array<any>(pieLevel.numberOfChildren);
    this.getPieSeriesData(output, app, reportChartConfig, pieLevel, pieLevel.numberOfChildren);
    return output;
  }

  private setPieColourIndex(pieLevel: PieLevel, parentIndex: number) {
    pieLevel.chartDataValue.forEach(chartData => {
      chartData.colourIndex = parentIndex;
      if (chartData.childLevel) { this.setPieColourIndex(pieLevel, parentIndex); }

    });
  }

  private getPieSeriesData(output: Array<any>, app: Application, reportChartConfig: Report, chartLevel: PieLevel, levels: number, index: number = 0) {

    let hasValueFields: boolean = reportChartConfig.Chart.HasValueFields;
    let level1Width: number;
    let levelXWidth: number;

    switch (levels) {
      case 1:
        level1Width = 100;
        levelXWidth = 100;
        break;
      case 2:
        level1Width = 70;
        levelXWidth = 30;
        break;
      case 3:
        level1Width = 60;
        levelXWidth = 20;
        break;
      default:
        level1Width = 50;
        levelXWidth = 50 / (levels - 1.0);
        break;
    }

    if (!output[index]) {
      output[index] = {
        data: new Array<any>(),
        seriesBaseFieldIdentifier: chartLevel.chartField.BaseFieldIdentifier,
        seriesBaseFieldType: chartLevel.chartField.BaseField.Type
      };

      if (index === levels - 1) {
        output[index].innerSize = `${100 - levelXWidth}%`;
        output[index].size = `${level1Width + (index) * levelXWidth}%`;
      } else if (index) {
        output[index].innerSize = `${(level1Width + (index - 1) * levelXWidth) / (level1Width + (index) * levelXWidth) * 100}%`;
        output[index].size = `${level1Width + (index) * levelXWidth}%`;
      } else {
        output[index].size = `${level1Width}%`;
      }

      if (!reportChartConfig.Chart.ShowInLegend) {
        output[index].dataLabels = index !== levels - 1 ? { enabled: false } : {};
      }
    }

    const colorKeyValueStyles = this.chartColourService.getColorKeyValueStylesAsync(app, reportChartConfig.NamedStyles);

    output[index].data = output[index].data.concat(chartLevel.chartDataValue.map(chartData => {
      const series: any = {
        y: hasValueFields ? chartData.recordValue : chartData.recordCount,
        name: this.getSeriesDataName(chartData.value, chartLevel.chartField.BaseField.Type),
        color: this.chartColourService.getColourHexcode(chartData.value, colorKeyValueStyles)
      };
      if (index + 1 < levels) {
        this.getPieSeriesData(output, app, reportChartConfig, chartData.childLevel, levels, index + 1);
      }

      return series;
    }));
  }

  private getPieData(hasValueFields: boolean, record: Record, pieLevel: PieLevel, chartFields: ChartField[], index: number = 0, parentFilter?: { [key: string]: string }) {
    let filter: { [key: string]: string };
    let parentData: PieData;
    if (pieLevel.chartDataValue.some(chartData => chartData.value === (<AppField>pieLevel.chartField.BaseField).getRawRecordValue(record))) {
      parentData = pieLevel.chartDataValue.find(chartData => chartData.value === (<AppField>pieLevel.chartField.BaseField).getRawRecordValue(record));
      this.applyCalculation(hasValueFields, pieLevel.chartField, record, parentData);
      filter = parentData.filters;
    } else {
      filter = { ...parentFilter, [`${pieLevel.chartField.BaseFieldIdentifier}`]: (<AppField>pieLevel.chartField.BaseField).getRawRecordValue(record) };
      pieLevel.chartDataValue.push({ value: (<AppField>pieLevel.chartField.BaseField).getRawRecordValue(record), recordCount: 0, filters: filter, colourIndex: -1 });
      parentData = pieLevel.chartDataValue[pieLevel.chartDataValue.length - 1];
      this.applyCalculation(hasValueFields, pieLevel.chartField, record, parentData);
    }
    if (index + 1 < chartFields.length) {
      if (!parentData.childLevel) { parentData.childLevel = { chartField: chartFields[index + 1], chartDataValue: [] }; }
      this.getPieData(hasValueFields, record, parentData.childLevel, chartFields, index + 1, filter);
    }
  }

  private sortPieData(pieLevel: PieLevel, hasValueFields: boolean) {
    if (pieLevel.chartDataValue.some(pieData => !!pieData.childLevel)) {
      pieLevel.chartDataValue.forEach(pieData => {
        this.sortPieData(pieData.childLevel, hasValueFields);
        // Complete average calculation
        if (hasValueFields && pieLevel.chartField.Calculation === ChartEnums.Calculation.Average) {
          pieData.recordValue /= pieData.recordCount;
        }
      });
    }
    pieLevel.chartDataValue.sort((a, b) => b.recordCount - a.recordCount);
  }

  private getSeriesDataName(value: string, type: number): string {
    let name = value;
    const date: moment.Moment = moment(value);


    if (isDateField(type) && date.isValid()) {
      switch (type) {
        case Enums.FieldType.Period:
          name = date.format('MMM.YYYY');
          break;

        case Enums.FieldType.Date:
        case Enums.FieldType.DateTime:
          name = date.format('DD.MMM.YYYY');
          break;
      }
    }

    if (!name) { name = ''; }

    return name;
  }


  private applyCalculation(hasValueFields: boolean, chartField: ChartField, record: Record, parentData: PieData) {
    let value: number;
    if (hasValueFields) {
      value = (<AppField>chartField.ValueField).getRawRecordValue(record);
    }
    if (value != null) {
      parentData.recordCount++;
      if (parentData.recordValue != null) {
        switch (chartField.Calculation) {
          case ChartEnums.Calculation.Maximum:
            parentData.recordValue = Math.max(parentData.recordValue, value);
            break;
          case ChartEnums.Calculation.Minimum:
            parentData.recordValue = Math.min(parentData.recordValue, value);
            break;
          default:
            parentData.recordValue += value;
        }
      } else {
        parentData.recordValue = value;
      }
    } else {
      parentData.recordCount++;
    }
  }
}
