import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { IndexedAppData, FileAttachment, RecordId, logError } from '@softools/softools-core';

import { RecordPatch } from '../../../workspace.module/types';
import { LocatorService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { Router } from '@angular/router';
import { NavigationService } from 'app/services/navigation.service';
import { TranslationsService } from 'app/services/translations.service';
import { AppModel, AttachmentsModel, RecordUpdateController, RecordUpdateModel } from 'app/mvc';
import { ComponentBase } from 'app/softoolsui.module';

/**
 * Top level ("smart") component for editing or viewing a record
 */
@Component({
  selector: 'app-record-update',
  templateUrl: './record-update.component.html',
  styleUrls: ['./record-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordUpdateComponent extends ComponentBase implements OnInit {

  @Input() appModel: AppModel;

  @Input() recordModel: RecordUpdateModel;

  @Input() recordUpdateController: RecordUpdateController;

  @Input() attachmentsModel: AttachmentsModel;

  // @Input() public settingsUi: ISettingsUserInterface;

  @Input() public parentRecordId: RecordId;

  /** Index specifying the records available to update and their order */
  @Input() public recordIndex: IndexedAppData;

  @Input() public reportIdentifier: string;

  @Input() public files: Array<FileAttachment>;
  @Input() public isLoading: boolean;
  @Input() public isOnline: boolean;
  @Input() public cursor?: number;
  @Input() public cursorReportIdentifier?: string;

  /** True if the record is being viewed as a child record */
  @Input() public asChild: boolean;

  @Output() onFieldUpdated = new EventEmitter<{ patch: RecordPatch, recordIsValid: boolean }>();
  @Output() onImageDelete = new EventEmitter<{ payload: any, recordIsValid: boolean }>();

  protected _appService = LocatorService.get(AppService);

  protected router = LocatorService.get(Router);

  protected translationService = LocatorService.get(TranslationsService);

  constructor(
    private navigationService: NavigationService
  ) {
    super();
  }

  // todo appears unused
  public isSelectListUsed(name: string) {
    const fields = this.appModel.app.value?.AppFields;
    return fields?.find(field => field.UseRecordSelectListOptions && field.SelectListIdentifier === name);
  }

  public ngOnInit(): void {
    this.subscribe(this.recordModel.record.$, async (id) => {
      if (id) {
        await this.recordChanged();
      }
    });

    this.subscribe(this.recordModel.context.$, (context) => {
      if (context?.form) {
        this.setUrl();
      }
    });
  }

  protected initialiseRecord() {
    const application = this.appModel.app.value;
    const record = this.recordModel.record.value;
    if (application && record) {
      application.initRecord(record, this.translationService);
    }
  }

  public fieldUpdated(patch: RecordPatch) {
    this.onFieldUpdated.emit({ patch, recordIsValid: true });
  }

  public toggleExpandedFormTemplate(template: { Identifier: string }) {
    this.recordModel.toggleTemplateExpansion(template.Identifier);
  }

  public toggleExpandedFormTemplateHelp(template: { Identifier: string }) {
    this.recordModel.toggleTemplateHelpExpandion(template.Identifier);
  }

  public imageDelete(payload: any) {
    this.onImageDelete.emit({ payload, recordIsValid: true });
  }

  private async recordChanged() {
    try {
      const record = this.recordModel.record.value;
      const form = this.recordModel.form.value;
      if (record && form) {
        this.setUrl();
      }

      this.initialiseRecord();

    } catch (error) {
      logError(error, 'Failed to get visible forms');
    }
  }

  /** As we step through records, update the URL to change to track this */
  private setUrl() {
    const formPart = this.getFormUrlFragment();

    // NB These URLs must match the ones in Navigation Actions and workspace module.
    // todo should bring them togther
    const record = this.recordModel.record.value;
    const application = this.appModel.app.value;
    const appIdentifiers = this.appModel.appIdentifiers.value;

    if (record) {
      if (application.Identifier && this.cursorReportIdentifier && this.cursor) {
        const cursorReport = this._appService.getReport(application.Identifier, this.cursorReportIdentifier);
        const url = this.navigationService.getRecordCursorUrl(appIdentifiers, this.parentRecordId, cursorReport, this.cursor);
        this.router.navigateByUrl(url, { replaceUrl: true }).catch(error => logError(error, 'Failed to route'));
      } else if (this.asChild && record.Hierarchy && record.AppIdentifier === application.Identifier) {
        const url = this.navigationService.getChildRecordUrl(application.Identifier, appIdentifiers, record._id, formPart);
        this.router.navigateByUrl(url, { replaceUrl: true }).catch(error => logError(error, 'Failed to route'));
      } else if (application.IsSingletonApp && !application.StaticRecordId) {
        // signgletons look like a homepage
        const url = this.navigationService.getAppHomeUrl2(appIdentifiers);
        this.router.navigateByUrl(url, { replaceUrl: true }).catch(error => logError(error, 'Failed to route'));
      } else if (record.AppIdentifier === application.Identifier) {
        const url = this.navigationService.getRecordUrl2(appIdentifiers, record._id, formPart);
        this.router.navigateByUrl(url, { replaceUrl: true }).catch(error => logError(error, 'Failed to route'));
      }
    }
  }

  private getFormUrlFragment(): string {
    const application = this.appModel.app.value;
    const form = this.recordModel.form.value;
    if (form && application) {
      const formIndex = application.Forms.findIndex(f => f.Identifier === form.Identifier);
      if (formIndex > 0) {
        // Form matched and is not the default (first) so create URL fragment
        return form.Identifier;
        // return `/Form/${form.Identifier}`;
      }
    }

    // Default form or not found so empty fragment selects default
    return '';
  }

  public extensionPopupClosed() {
  }

}
