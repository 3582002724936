import { InjectionToken } from '@angular/core';
import { App, Field } from '@softools/softools-core';
import { IApplication } from 'app/types/app-field-source';
import { AppField } from 'app/types/fields/app-field';
import { BackingField } from 'app/types/fields/backing-field';

export interface IAppFieldFactoryService {
  createField(field: Field, app: App & IApplication): AppField;
  createGridCell(field?: Field, containedField?: AppField, app?: App & IApplication): AppField;
  createListItem(field?: Field, containedField?: AppField, app?: App & IApplication): AppField;
  createBackingField(field?: Field, containedField?: AppField, application?: App & IApplication): BackingField;
}

export const appFieldFactoryToken = new InjectionToken<IAppFieldFactoryService>('IAppFieldFactoryService');
