import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofSpinnerModule } from '@softools/design/icons';

@NgModule({
  declarations: [SearchComponent],
  imports: [CommonModule, FontAwesomeModule, SofSpinnerModule],
  exports: [SearchComponent],
  providers: [],
})
export class SearchModule {}
