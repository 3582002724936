import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AppsRepository, tryGetCurrentUser } from '../repos';
import { App, Report, Form, Enums } from '../types';
import { Field } from '../types/interfaces/field.interface';
import { first } from 'rxjs/operators';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { STORE_APPS } from '../indexedDb/database-stores';
import { DatabaseService } from '../indexedDb/database.service';
import { OnlineStatusService } from './online-status-service';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { patchApp } from 'app/types/app-tweaks';
import { StorageMode } from 'app/types/enums';
import { logMessage, OdataExpressionType } from '../utils';

const idField: Field = {
  Identifier: '_id',
  DisplayFormatted: false,
  ExcludeFromTemplateCopy: true,
  IncludeInSearch: false,
  IsEditable: false,
  IsReadOnly: true,
  Label: $localize`Identifier`,
  SystemLabel: 'Identifier',
  Type: Enums.FieldType.Text,
};

@Injectable({ providedIn: 'root' })
export class AppDatabaseContextService extends DatabaseContextService<App> {
  constructor(database: DatabaseService, onlineStatus: OnlineStatusService) {
    super(database, onlineStatus);
  }
}

@Injectable({ providedIn: 'root' })
export class AppsService {
  constructor(public appsRepository: AppsRepository, private service: AppDatabaseContextService) { }

  public async syncApp(appIdentifier: string): Promise<App> {
    const app = await this.appsRepository.getApp(appIdentifier).pipe(first()).toPromise();
    this.applyTweaks(app);
    await this.service.save(STORE_APPS, app.Identifier, app);
    return app;
  }

  /**
   * Synchronise application specifications from server into local storage.
   *
   * @param retryPolicy     Optional policy for retrying when connections are poor.
   */
  public async syncApps(retryPolicy: IRetryPolicy = null): Promise<Array<App>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const apps = await policy.execute(() => this.appsRepository.getApps().toPromise());

    const txn = await this.service.transaction(STORE_APPS, 'readwrite');
    for (let i = 0; i < apps.length; ++i) {
      const app = apps[i];
      app.OfflineAvailability = app.OfflineAvailability || { AvailableOffline: true };
      app.storageMode = app.OfflineAvailability.AvailableOffline ? StorageMode.Offline : StorageMode.Online;
      this.applyTweaks(app);

      if (app.Identifier) {
        await this.service.save(STORE_APPS, app.Identifier, app, txn);
      } else {
        // We are seeing occasional missing ids - log to try to capture cause (V18-38K)
        logMessage(`App ${i} ${app.Name} of ${tryGetCurrentUser()?.Tenant} ${app.Id}`);
      }
    }

    return apps;
  }

  public async getAppAsync(appIdentifier: string): Promise<App> {
    return this.service.get(STORE_APPS, appIdentifier);
  }

  public getApp(appIdentifier: string): Observable<App> {
    return from(this.getAppAsync(appIdentifier));
  }

  public async getApps(): Promise<Array<App>> {
    return this.service.getAll(STORE_APPS);
  }

  public async putAsync(app: App): Promise<boolean> {
    return this.service.save(STORE_APPS, app.Identifier, app);
  }

  public async getReportsAsync(appIdentifier: string): Promise<Array<Report>> {
    const app = await this.getAppAsync(appIdentifier);
    const reports = app && app.Reports;
    return reports || [];
  }

  public async getReportAsync(appIdentifier: string, reportIdentifier: string): Promise<Report> {
    const app = await this.getAppAsync(appIdentifier);
    return app && app.Reports.find((report) => report.Identifier === reportIdentifier);
  }

  public async getFormsAsync(appIdentifier: string): Promise<Array<Form>> {
    const app = await this.getAppAsync(appIdentifier);
    if (app && app.Forms) {
      return app.Forms.sort((a, b) => (a.DisplayOrder < b.DisplayOrder ? 0 : 1));
    }

    return null;
  }

  public async clear(): Promise<boolean> {
    return this.service.clear(STORE_APPS);
  }

  public async getFieldAsync(appIdentifier: string, fieldIdentifier: string): Promise<Field> {
    const app = await this.getAppAsync(appIdentifier);
    return app && app.Fields.find((f) => f.Identifier === fieldIdentifier);
  }

  /** @deprecated use getReportAsync */
  public getReport(appIdentifier: string, reportIdentifier: string): Observable<Report> {
    return from(this.getReportAsync(appIdentifier, reportIdentifier));
  }

  /** @deprecated use getReportsAsync */
  public getReports(appIdentifier: string): Observable<Array<Report>> {
    return from(this.getReportsAsync(appIdentifier));
  }

  /** @deprecated use getFormsAsync */
  public getForms(appIdentifier: string): Observable<Array<Form>> {
    return from(this.getFormsAsync(appIdentifier));
  }

  /** @deprecated use getFieldAsync */
  public getField(appIdentifier: string, fieldIdentifier: string): Observable<Field> {
    return from(this.getFieldAsync(appIdentifier, fieldIdentifier));
  }

  /**
   * Apply hardcoded changes to the app
   * @param app
   */
  private applyTweaks(app: App) {

    // Reformat filter operands (c# structure is messy)
    app.Reports?.forEach((report) => {
      report.Filter?.forEach((filter) => {
        if (filter.Operands.length === 1) {
          filter.Operand = filter.Operands[0].Operand;
        } else {
          filter.Operand = filter.Operands.map(o => o.Operand);
        }
        delete filter.Operands;

        // AS doesn't use the pseudo-ops, but just supplies multiple values to
        // the normal opearators. Map to the WS equivalent
        // todo we also need a mapping for ContainsNoneOf but there's no AS equivalent
        if (Array.isArray(filter.Operand)) {
          switch (filter.Operator) {
            case OdataExpressionType.Equals:
              filter.Operator = OdataExpressionType.OneOf;
              break;
            case OdataExpressionType.NotEquals:
              filter.Operator = OdataExpressionType.NoneOf;
              break;
            case OdataExpressionType.Substring:
              filter.Operator = OdataExpressionType.ContainsOneOf;
              break;
          }
        }
      });
    });

    const user = tryGetCurrentUser();
    if (user) {
      // todo incorrect reference from core to ws
      patchApp(user.Tenant, app);
    }

    // Add a hidden id field to all apps, unless they have one already
    // Used to display id on app home, for example
    if (app.Fields && !app.Fields.find(field => field.Identifier === '_id')) {
      app.Fields.push(idField);
    }
  }
}
