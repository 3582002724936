import { OnInit, Injectable, OnDestroy } from '@angular/core';
import { Enums, OnlineStatusService, RecordId, PermissionEnums, logError } from '@softools/softools-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EffectActionEnums } from 'app/softoolscore.module/types/enums/effectaction.enums';

import { RecordPatch } from 'app/workspace.module/types';

import { AppService } from 'app/services/app.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppIdentifiers } from 'app/services/record/app-info';
import { NavigationService } from 'app/services/navigation.service';
import { PARENT_RECORD_ID } from 'app/_constants';
import { PermissionsService } from 'app/services/permissions.service';
import { GlobalModel, RouteParams } from 'app/mvc/global.model';
import { AppModel, AttachmentsModel, PageModel } from 'app/mvc';
import { ComponentBase } from 'app/softoolsui.module/component-base';
import { ChangeDetectionService } from 'app/services/change-detection.service';
import { InjectService } from 'app/services/locator.service';
import { ToastyService } from 'app/services/toasty.service';
import { CommentsModel } from 'app/mvc/comments-model';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { LaunchpadModel } from 'app/mvc/headers/launchpad.model';

@Injectable()
export class WSBase extends ComponentBase implements OnInit, OnDestroy {

  public showAside$: Observable<string>;

  public showBackButton = false;

  public EffectActionEnums: typeof EffectActionEnums = EffectActionEnums;

  /**  */
  public appIdentifiers?: AppIdentifiers;

  public parentRecordId: RecordId;

  public globalModel: GlobalModel;
  public appModel: AppModel;
  public pageModel: PageModel;
  public launchpadModel: LaunchpadModel;

  public generalController?: IGeneralController;

  /** Comments */
  public readonly commentsModel: CommentsModel;

  /** Attachments */
  public readonly attachmentsModel: AttachmentsModel;

  @InjectService(ChangeDetectionService)
  private changeDetectionService: ChangeDetectionService;

  @InjectService(ToastyService)
  protected readonly toasties: ToastyService;

  constructor(
    public onlineStatus: OnlineStatusService,
    public appService: AppService,
    protected route: ActivatedRoute,
    protected navigationService: NavigationService,
    protected permissionsService: PermissionsService,
    protected readonly models: GlobalModelService
  ) {
    super();

    this.globalModel = this.models.globalModel;
    this.pageModel = this.models.pageModel;
    this.launchpadModel = this.models.launchpadModel;
    this.appModel = new AppModel(this.globalModel, this.launchpadModel, this.models.siteModel);
    this.commentsModel = new CommentsModel(this.appModel);
    this.attachmentsModel = new AttachmentsModel(this.appModel);

    this.appModel.initialise();

    this.showAside$ = this.pageModel.folderBarState.$;
  }

  ngOnInit(): void {

    this.launchpadModel.search.value = '';

    this.subscribe(this.globalModel.routeParams.$, (params) => {
      // todo handle param change here - params has the relevant stuff
      // ... except query params, if we really need them
      this.routed(params).catch(error => logError(error, 'Failed to route on model'));
    });

    this.routeChanged(this.route.snapshot.params);

    // Load notifications in background
    this.models.notificationsController.loadNotifications().catch(e => logError(e, 'loadNotifications'));

    if (this.route.snapshot.data['zone']) {
      this.parentRecordId = this.route.snapshot.params[PARENT_RECORD_ID] as string;
    }

    this.changeDetectionService.start();
  }

  override ngOnDestroy(): void {
    this.changeDetectionService.stop();
    this.appModel.dispose();
    this.commentsModel.dispose();
    this.attachmentsModel.dispose();
    super.ngOnDestroy();
  }

  protected async routed(params: RouteParams) {
    // update app model if not overriden (most do)
    await this.appModel.routed(params);
  }

  protected routeChanged(params: Params) {
    this.appIdentifiers = new AppIdentifiers(params);
    // this.appModel.appIdentifiers.value = this.appIdentifiers;
  }

  public get currentApp() {
    const appIdentifier = this.appIdentifiers.visibleAppIdentifier;
    if (appIdentifier) {
      const app = this.appService.application(appIdentifier);
      return app;
    }

    return undefined;
  }

  public get appIdentifier() {
    const app = this.currentApp;
    return app && app.Identifier;
  }

  public get appDefaultListReportIdentifier() {
    const app = this.currentApp;
    return app && app.DefaultListReportIdentifier;
  }

  public get appFields() {
    const app = this.currentApp;
    return app && app.AppFields;
  }

  /** If true the current app has searchable fields  */
  public get appSearchable() {
    const app = this.currentApp;

    // Treat app as searchable if any fields have the IncludeInSearch flag set
    // The HasIncludeInSearchFields psuedo field should be set with the same logic but is
    // currently always returning true.
    return !!app.Fields.find((field) => field.IncludeInSearch);
  }

  public onFieldUpdateHandler(patch: RecordPatch) {
    // value[RECORD_VALID] = recordIsValid;
    this.appModel.patchRecordValue(patch).catch(e => logError(e, 'onChange'));
  }

  public onOpenFullImageHandler(payload: { src: string }) {
    // unused?
    this.globalModel.previewModel.openFullImage(payload.src);
  }

  public get createUrl() {
    return this.navigationService.getListCreateRecordUrl(this.appIdentifiers, this.parentRecordId);
  }

  public get canCreate() {
    const app = this.appService.application(this.appIdentifier);
    return (
      app?.capabilities.canCreate &&
      (this.permissionsService.hasPermission(PermissionEnums.Records.Create) ||
        this.permissionsService.hasPermission(PermissionEnums.Records.All))
    );
  }

  public get isSingletonApp() {
    const app = this.appService.application(this.appIdentifier);
    return app.IsSingletonApp || false;
  }

  public get isOfflineApp() {
    const app = this.appService.application(this.appIdentifier);
    return app.isOfflineApp;
  }
}
