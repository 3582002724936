import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataStorageService, AppsService, Record, SavedFilter, SavedFiltersRepository, TypedRecord } from '@softools/softools-core';
import { AppService } from 'app/services/app.service';
import { InjectService } from 'app/services/locator.service';
import { AppIdentifier } from 'app/types/application';
import { RecordPatch } from 'app/workspace.module/types';
import { EmbeddedApplication } from '../../embedded-application';
import { savedFilterAppConfig } from './saved-filters.config';

export interface SavedFilterRecord extends TypedRecord<SavedFilter> {
}

/**
 * Manages saved filters.
 * The saved filter data is stored on the associated App record, not in its
 * own storage.  It is implemented as a settings app so it can particpate in
 * sync (it MUST sync after apps), including new style change detection when
 * that is fully implemented.
 * 
 * This can also be accessed directly to use as a general service for accessing
 * saved filters.  The filters are cached from storage by a guard, so can be
 * efficiently accessed.
 */
@Injectable({ providedIn: 'root' })
export class SavedFiltersApplication extends EmbeddedApplication<SavedFilter> {

  private filtersByApp = new Map<AppIdentifier, Array<SavedFilter>>();

  @InjectService(SavedFiltersRepository)
  private repository: SavedFiltersRepository;

  @InjectService(AppService)
  private readonly appService: AppService;

  @InjectService(AppsService)
  private readonly appStorageService: AppsService;

  @InjectService(AppDataStorageService)
  private appDataStorageService: AppDataStorageService;

  constructor() {
    super(savedFilterAppConfig);
  }

  public toRecord(value: SavedFilter): SavedFilterRecord {
    const record: SavedFilterRecord = value && {
      _id: value.Id,
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      IsArchived: false,
      Values: value
    };

    return record;
  }

  public toSavedFilter(record: SavedFilterRecord): SavedFilter {
    return record.Values;
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  /** 
   * Synchronize app data from the server.
   * Saved filters are stored on the App rather than using its own storage.
   */
  public override async synchronizeAppData(): Promise<void> {

    const filters = await this.repository.getAllSavedFilters();

    // Convert to records and store as appdata
    const promises = filters
      .map(filter => this.toRecord(filter))
      .map(record => this.appDataStorageService.storeRecordAsync(this, record, false));
    await Promise.all(promises);
  }

  /** Resynchronise filters for a single app */
  public async synchronizeApplicationFilters(appIdentifier: AppIdentifier): Promise<Array<SavedFilter>> {

    const existingFilters = [...this.getAppSavedFilters(appIdentifier)];

    const filters = await this.repository.getSavedFilters(appIdentifier);
    this.filtersByApp.set(appIdentifier, filters);

    // Store retutned filters; remove existing ones from list so we know what we have to delete
    for (let i = 0; i < filters.length; ++i) {
      const filter = filters[i];
      const existingIndex = existingFilters.findIndex(f => f.Id === filter.Id);
      if (existingIndex >= 0) {
        existingFilters.splice(existingIndex, 1);
      }

      const record = this.toRecord(filter);
      await this.appDataStorageService.storeRecordAsync(this, record);
    }

    // Remove leftovers
    for (let i = 0; i < existingFilters.length; ++i) {
      await this.appDataStorageService.removeRecordAsync(existingFilters[i].Id);
    }

    return filters;
  }

  /** Get all saved filters for an app */
  public getAppSavedFilters(appIdentifier: AppIdentifier) {
    return this.filtersByApp.get(appIdentifier) ?? [];
  }

  public async saveFilter(appIdentifier: string, filter: SavedFilter) {
    const filters = this.getAppSavedFilters(appIdentifier);
    const existingAt = filters.findIndex(f => f.Id === filter.Id);
    if (existingAt < 0) {
      filters.push(filter);
      await this.repository.saveFilter(appIdentifier, filter);
    } else {
      filters[existingAt] = filter;
      await this.repository.updateFilter(appIdentifier, filter);
    }

    // If we saved a default filter, no other filter can be default
    // As the patch only returns the patched filter, update the local copy
    if (filter.IsDefault) {
      filters.forEach(f => {
        if (f.Id !== filter.Id) {
          f.IsDefault = false;
        }
      });
    }

    // Update storage
    const record = this.toRecord(filter);
    await this.appDataStorageService.storeRecordAsync(this, record);

    return filters;
  }

  public async deleteFilter(appIdentifier: string, filter: SavedFilter): Promise<Array<SavedFilter>> {
    const result = await this.repository.deleteFilter(appIdentifier, filter);
    if (result instanceof HttpErrorResponse) {
      throw new Error('Failed to delete a filter');
    }

    // Update filters to remove it and save to storage (todo)
    const filters = this.getAppSavedFilters(appIdentifier).filter(f => f.Id !== filter.Id);
    this.filtersByApp.set(appIdentifier, filters);
    return filters;
  }

  /** Load all saved filters into storage from a cache.  Intended for use by guard that preloads filters */
  public async loadAll(force = false) {

    if (force) {
      this.filtersByApp.clear();
    }

    // Only reload if we don't have filters
    // Very slight inefficiency if site has no filters at all but that's pretty unusual
    if (this.filtersByApp.size === 0) {
      await this.appDataStorageService.eachRecord(this.Identifier, (record: SavedFilterRecord) => {
        const filter = this.toSavedFilter(record);

        if (!filter.IsHidden) {
          if (!this.filtersByApp.has(filter.AppIdentifier)) {
            this.filtersByApp.set(filter.AppIdentifier, [filter]);
          } else {
            const array = this.filtersByApp.get(filter.AppIdentifier);
            array.push(filter);
          }
        }
      });
    }
  }
}
