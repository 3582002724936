import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SiteService } from 'app/services/site-service.service';

@Injectable({ providedIn: 'root' })
export class SiteGuard implements CanActivate {
  constructor(private siteService: SiteService) {}

  public async canActivate(): Promise<boolean> {
    await this.siteService.refresh();
    return true;
  }
}
