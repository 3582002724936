import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Application } from 'app/types/application';
import { StorageMode } from 'app/types/enums';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './InfoDialog.component.html',
  styleUrls: ['./InfoDialog.component.scss']
})
export class InfoDialogComponent {

  public storageModes = StorageMode;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Application) {
  }
}
