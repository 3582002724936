import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridFieldComponent } from './grid-field/grid-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofInputDirective } from './sof-input.directive';
import { SelectionFieldComponent } from './selection-field/selection-field.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { SelectionRadioFieldComponent } from './selection-radio-field/selection-radio-field.component';
import { SelectionCheckFieldComponent } from './selection-check-field/selection-check-field.component';
import { SofValueFormaterDirective } from './sofValueFormater.directive';
import { ScalarFieldsModule } from '../fields/scalar-fields/scalar-fields.module';
import { BitFieldComponent } from './bit-field/bit-field.component';
import { LookupComponent } from './lookup/lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldFooterComponent } from './field-footer/field-footer.component';
import { DateFieldComponent } from './date-field/date-field.component';
import { PersonFieldComponent } from './person-field/person-field.component';
import { PersonTeamFieldComponent } from './person-field/person-team-field.component';
import { NotesFieldComponent } from './notes-field/notes-field.component';
import { PeriodFieldComponent } from './period-field/period-field.component';
import { TeamFieldComponent } from './team-field/team-field.component';
import { DateTimeFieldComponent } from './date-time-field/date-time-field.component';
import { TextDisplayFieldComponent } from './text-display-field/text-display-field.component';
import { SelectionListFieldComponent } from './selection-list-field/selection-list-field.component';
import { DropDownModule } from '../dropdown.component/dropdown.module';
import { PopupContainerModule } from '../popup-container/popup-container.module';
import { ImageListFieldComponent } from './image-list-field/image-list-field.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { LongTextFieldComponent } from './long-text-field/long-text-field.component';
import { EmailFieldComponent } from './email-field/email-field.component';
import { UrlFieldComponent } from './url-field/url-field.component';
import { IconDisplayFieldComponent } from './icon-display-field/icon-display-field.component';
import { DisplayFieldDirective } from './display-field.directive';
import { RangeFieldComponent } from './range-field/range-field.component';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MultiStateField2Component } from './multi-state-field/multi-state-field.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { DownloadIconFieldComponent } from './download-icon-field/download-icon-field.component';
import { SofTimesIconModule, SofPlusIconModule } from '@softools/design/icons';
import { PipeModule } from 'app/pipes/pipe.module';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FieldComponent } from './field/field.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TimeFieldModule } from './time-field/time-field.module';
import { InputIconModule } from './input-icon/input-icon.module';
import { InputModule } from './input/input.module';
import { FileDropComponent } from './file-drop/file-drop.component';
import { ReferenceComponent } from './reference/reference.component';
import { ConcealedTextFieldComponent } from './concealed-text-field/concealed-text-field.component';
import { LookupDialogServiceModule } from '../lookup-dialog-service/lookup-dialog-service.module';
import { DocumentFieldComponent } from './document-field/document-field.component';
import { AggregateFieldComponent } from './aggregate-field/aggregate-field.component';
import { CartoucheFieldComponent } from '../cartouche-field/cartouche-field.component';
import { ValueTextFieldComponent } from './value-text-field/value-text-field.component';
import { MultiStateMultiSelectorFieldComponent, MultiStateSelectorFieldComponent } from './multi-state-field/multi-state-selector-field.component';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';
import { GridCellFieldComponent } from './grid-field/grid-cell-field.component';
import { MultiLineTextDisplayFieldComponentComponent } from './text-display-field/multi-line-text-display-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    OverlayModule,
    MatButtonModule,
    MatListModule,
    MatRadioModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    ScalarFieldsModule,
    DropDownModule,
    PopupContainerModule,
    ReactiveFormsModule,
    MatTooltipModule,
    SofTimesIconModule,
    SofPlusIconModule,
    PipeModule,
    TextFieldModule,
    TimeFieldModule,
    InputModule,
    InputIconModule,
    LookupDialogServiceModule,
    MvcDirectivesModule,
  ],
  declarations: [
    GridFieldComponent,
    GridCellFieldComponent,
    SofInputDirective,
    SelectionFieldComponent,
    SelectionRadioFieldComponent,
    SelectionCheckFieldComponent,
    SofValueFormaterDirective,
    SofValueFormaterDirective,
    BitFieldComponent,
    LookupComponent,
    FieldFooterComponent,
    DateFieldComponent,
    PersonFieldComponent,
    PersonTeamFieldComponent,
    NotesFieldComponent,
    PeriodFieldComponent,
    TeamFieldComponent,
    DateTimeFieldComponent,
    TextDisplayFieldComponent,
    MultiLineTextDisplayFieldComponentComponent,
    SelectionListFieldComponent,
    ImageListFieldComponent,
    TextFieldComponent,
    LongTextFieldComponent,
    EmailFieldComponent,
    UrlFieldComponent,
    IconDisplayFieldComponent,
    DisplayFieldDirective,
    RangeFieldComponent,
    MultiStateField2Component,
    MultiStateSelectorFieldComponent,
    MultiStateMultiSelectorFieldComponent,
    DownloadIconFieldComponent,
    FieldComponent,
    TextAreaComponent,
    FileDropComponent,
    ReferenceComponent,
    DocumentFieldComponent,
    ConcealedTextFieldComponent,
    AggregateFieldComponent,
    CartoucheFieldComponent,
    ValueTextFieldComponent
  ],
  exports: [
    GridFieldComponent,
    GridCellFieldComponent,
    SofInputDirective,
    SofValueFormaterDirective,
    DisplayFieldDirective,
    SelectionFieldComponent,
    SelectionRadioFieldComponent,
    SelectionCheckFieldComponent,
    BitFieldComponent,
    DateFieldComponent,
    DateTimeFieldComponent,
    PeriodFieldComponent,
    FieldFooterComponent,
    ImageListFieldComponent,
    PersonFieldComponent,
    TeamFieldComponent,
    FieldComponent,
    FileDropComponent,
    CartoucheFieldComponent,
    ValueTextFieldComponent
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: { ...MAT_MOMENT_DATE_FORMATS, display: { ...MAT_MOMENT_DATE_FORMATS.display, dateInput: 'L' } },
    }
  ],
})
export class Fields2Module { }
