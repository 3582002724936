
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartGoogleComponent } from './chart-google.component';

@NgModule({
  declarations: [
    ChartGoogleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ChartGoogleComponent],
  providers: [],
})
export class ChartGoogleModule { }
