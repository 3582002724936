<div class="sof-modal-container">
  <div class="cardv2-popup sof-modal">
    <div class="cardv2-header">
      <div i18n>Record Access Rights</div>
    </div>

    <mat-dialog-content>
      <table mat-table [dataSource]="config.dataRows">
        <ng-container matColumnDef="AccessType">
          <th mat-header-cell *matHeaderCellDef class="header-cell">
            <span class="st-text-theme-primary noselect header-cell-value" i18n>Access</span>
          </th>
          <td mat-cell *matCellDef="let row" class="data-cell pointer">
            <mat-form-field>
              <mat-select
                i18n-placeholder
                placeholder="Access Type"
                [value]="row.AccessType"
                [disabled]="getRowIsDisabled(row)"
                (selectionChange)="onCellUpdateHandler(row, 'AccessType', $event)"
              >
                <mat-option *ngFor="let option of selectLists['AccessTypes']" [value]="option.Value">
                  {{ option.Text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef colspan="5">
            <button type="button" class="primary-line" (click)="onAddRowClickHandler()" i18n>Add Row</button>
            <button type="button" class="primary" [mat-dialog-close]="returnValue" i18n>Save</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="SourceType">
          <th mat-header-cell *matHeaderCellDef class="header-cell">
            <span class="st-text-theme-primary noselect header-cell-value" i18n>Source</span>
          </th>
          <td mat-cell *matCellDef="let row" class="data-cell pointer">
            <mat-form-field>
              <mat-select i18n-placeholder placeholder="Access Type" [value]="row.SourceType" [disabled]="true">
                <mat-option *ngFor="let option of selectLists['SourceTypes']" [value]="option.Value">
                  {{ option.Text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="ActorType">
          <th mat-header-cell *matHeaderCellDef class="header-cell">
            <span class="st-text-theme-primary noselect header-cell-value" i18n>Type</span>
          </th>
          <td mat-cell *matCellDef="let row" class="data-cell pointer">
            <mat-form-field>
              <mat-select
                i18n-placeholder
                placeholder="Type"
                [(ngModel)]="row.ActorType"
                [disabled]="getRowIsDisabled(row)"
                (selectionChange)="onCellUpdateHandler(row, 'ActorType', $event)"
              >
                <ng-container *ngIf="row.ActorType === actorTypeEnums.Owner">
                  <mat-option *ngFor="let option of selectLists['OwnerActorTypes']" [value]="option.Value">
                    {{ option.Text }}
                  </mat-option>
                </ng-container>

                <ng-container *ngIf="row.ActorType !== actorTypeEnums.Owner">
                  <mat-option *ngFor="let option of selectLists['ActorTypes']" [value]="option.Value">
                    {{ option.Text }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="ActorId">
          <th mat-header-cell *matHeaderCellDef class="header-cell">
            <span class="st-text-theme-primary noselect header-cell-value" i18n>User/ Team</span>
          </th>
          <td mat-cell *matCellDef="let row" class="data-cell pointer">
            <mat-form-field class="user-dropdown">
              <mat-select
                i18n-placeholder
                placeholder="User / Team"
                [value]="row.ActorId"
                [disabled]="getRowIsDisabled(row) || getUserTeamIsDisabled(row)"
                (selectionChange)="onCellUpdateHandler(row, 'ActorId', $event)"
              >
                <ng-container *ngIf="row.ActorType === actorTypeEnums.User || row.ActorType === actorTypeEnums.Owner">
                  <mat-option *ngFor="let option of selectLists['Users']" [value]="option.Value">
                    <span>{{ option.Text }}</span>
                    <small> {{ option.Email }}</small>
                  </mat-option>
                </ng-container>

                <ng-container *ngIf="row.ActorType === actorTypeEnums.Team">
                  <mat-option *ngFor="let option of selectLists['Teams']" [value]="option.Value">
                    {{ option.Text }}
                  </mat-option>
                </ng-container>

                <ng-container
                  *ngIf="
                    row.ActorType !== actorTypeEnums.User && row.ActorType !== actorTypeEnums.Team && row.ActorType !== actorTypeEnums.Owner
                  "
                >
                  <mat-option [value]="''">
                    <span i18n>All</span>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="RemoveColumn">
          <th mat-header-cell *matHeaderCellDef class="header-cell"></th>
          <td mat-cell *matCellDef="let row" class="data-cell">
            <fa-icon
              *ngIf="!getRowIsDisabled(row)"
              class="st-text-theme-primary pointer"
              [icon]="['fal', 'trash']"
              size="1x"
              (click)="onRemoveRowClickHandler(row)"
            ></fa-icon>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row #matrow *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
      </table>
    </mat-dialog-content>
    <div class="cardv2-footer mt-4 w-100 d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary border-0" [mat-dialog-close]="null" i18n>Close</button>
    </div>
  </div>
</div>
