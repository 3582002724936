<div class="container-fluid text-center">
  <div class="d-flex justify-content-center align-items-center">
    <ng-container *ngIf="!isBusy; else busyTemplate">
      <ng-container *ngIf="skip > 0">
        <div class="nav-button pointer d-none d-sm-block" (click)="firstClicked($event)">
          <fa-icon [icon]="['fal', 'step-backward']" size="1x" class="theme-dark-text"></fa-icon>
        </div>
        <div *ngIf="skip > 0" class="pointer nav-button" (click)="leftClicked($event)">
          <fa-icon [icon]="['fal', 'chevron-left']" size="1x" class="theme-dark-text"></fa-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="skip === 0">
        <div class="nav-button d-none d-sm-block">
          <fa-icon [icon]="['fal', 'step-backward']" size="1x" class="theme-dark-text unavailable"></fa-icon>
        </div>
        <div class="nav-button">
          <fa-icon [icon]="['fal', 'chevron-left']" size="1x" class="theme-dark-text unavailable"></fa-icon>
        </div>
      </ng-container>

      <div class="range theme-dark-text">
        <span>{{ rangeText }}</span>
      </div>

      <ng-container *ngIf="!isAtEnd">
        <div class="nav-button pointer" (click)="rightClicked($event)">
          <fa-icon [icon]="['fal', 'chevron-right']" size="1x" class="theme-dark-text"></fa-icon>
        </div>
        <div class="nav-button pointer d-none d-sm-block" (click)="lastClicked($event)">
          <fa-icon [icon]="['fal', 'step-forward']" size="1x" class="pointer theme-dark-text"></fa-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="isAtEnd">
        <div *ngIf="isAtEnd" class="nav-button">
          <fa-icon [icon]="['fal', 'chevron-right']" class="theme-dark-text unavailable"></fa-icon>
        </div>
        <div class="nav-button d-none d-sm-block">
          <fa-icon [icon]="['fal', 'step-forward']" size="1x" class="theme-dark-text unavailable"></fa-icon>
        </div>
      </ng-container>

      <div class="ps-4" *ngIf="!isBusy && !singleRecord && !fixedPageSize">
        <select class="form-select" (change)="onChangeTopSize.emit($event.target['value'])">
          <option value="25" [selected]="top <= 20">25</option>
          <option value="50" [selected]="top === 50">50</option>
          <option value="100" [selected]="top >= 100">100</option>
        </select>
      </div>
    </ng-container>
  </div>

  <ng-template #busyTemplate>
    <sof-spinner-icon></sof-spinner-icon>
  </ng-template>
</div>
