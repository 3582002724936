import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { FieldsModule } from '../fields.component/fields.module';
import { FormHeaderModule } from './form-header/form-header.module';
import { CssGridModule } from 'app/cssgrid.module/cssgrid.module';
import { CustomNotSupportedModule } from '../customnotsupported.component/customnotsupported.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Fields2Module } from '../fields2/fields2.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { AssetImagesModule } from '../asset-image/asset-images.module';
import { ListFieldModule } from '../fields2/list-field/list-field.module';
import { MaterialFieldComponent } from '../material-field/material-field.component';
import { TemplateModule } from 'app/workspace.module/components/template/template.module';
import { IconModule } from '../icon/icon.module';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    FormComponent,
    MaterialFieldComponent,
  ],
  imports: [
    CommonModule,
    FieldsModule,
    ListFieldModule,
    AssetImagesModule,
    FormHeaderModule,
    CssGridModule,
    CustomNotSupportedModule,
    FontAwesomeModule,
    Fields2Module,
    MatTooltipModule,
    OverlayModule,
    TemplateModule,
    IconModule,
    ClipboardModule
  ],
  exports: [
    FormComponent,
  ],
  providers: [],
})
export class FormModule { }
