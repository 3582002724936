<div class="d-flex justify-content-start align-items-center h-100">
  <app-input-icon icon="calendar" 
    [clickable]="!getIsDisabled()" 
    [elementStyles]="elementStyles"
    (iconClicked)="openPicker($event)">
    <input #dateinput
      [matDatepicker]="datepicker"
      [ngModel]="dateTime"
      [ngStyle]="textStyle"
      #date="ngModel"
      [readonly]="true"
      [id]="uiIdentifier"
      (click)="openPicker($event)"
      (ngModelChange)="onDateChangedHandler($event)"
      (keydown)="onKeyDown($event)"
      (keypress)="onKeyPress($event)"
      (keyup)="onKeyUpHandler($event, dateinput)"
      (focus)="hasFocus = true"
      (blur)="hasFocus = false"
      class="pe-2 overflow-hidden form-control  form-control-o"
      [class.inline-control]="inline"
      [class.readonly-normal-appearance]="!getIsDisabled()"
      [disabled]="getIsDisabled()"
    />
    <mat-datepicker #datepicker></mat-datepicker>
    <!-- adding touchUi causes a modal issue. DO NOT ENABLE! -->
  </app-input-icon>

  <app-input-icon icon="clock"
    [clickable]="!getIsDisabled() && enablePicker && !getIsDisabled()"
    [elementStyles]="elementStyles"
    (iconClicked)="$event.stopPropagation(); picker.open()" >

  <!-- Displayed input handles manual entry -->

    <app-input
      [appModel]="appModel"
      [elementStyles]="elementStyles"
      [textStyle]="textStyle"
      [reportModel]="reportModel"
      [field]="timePseduoField"
      [template]="template"
      [form]="form"
      [value]="displayTime$|async"
      [listRow]="listRow"
      [ruleState]="ruleState"
      class="w-100"
      [class.inline-control]="inline"
      [inputType]="'text'"
      [id]="uiIdentifier + '_time'"
      (valueChanged)="timeChanged($event)"
      [disabled]="getIsDisabled()"
    >
    </app-input>

  </app-input-icon>

    <!-- Hidden input for time picker attachment -->
    <input hidden [ngxTimepicker]="picker" [format]="format" [value]="(displayTime$|async) || ''" >
    <ngx-material-timepicker #picker
      (timeSet)="timeChanged($event)"
      [theme]="theme"
    ></ngx-material-timepicker>

    <fa-icon *ngIf="fieldModel.ShowCalendarEvent"
      class="pointer mx-1 clear-button st-text-theme-primary"
      [icon]="['fas', 'arrow-circle-down']"
      title="Download iCal file" i18n-title
      (click)="downloadiCalClicked($event)"
    >
    </fa-icon>

  <div class="clear-button">
    <fa-icon *ngIf="showClear && !getIsDisabled()"
      class="pointer mx-1 text-muted"
      [icon]="['fal', 'times-circle']"
      title="Clear Date"
      i18n-title
      [hidden]="required"
      (click)="clearDate(); $event.stopPropagation()"
      [style.opacity]="hasFocus || true ? 1: 0"
    >
    </fa-icon>
  </div>

</div>
