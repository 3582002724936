import { MvcBase } from '@softools/vertex';
import { IWatcher } from '../common/watcher.interface';

/**
 * Base for types that  host vertext properties etc. An MVC container
 * can be watched via its $ observable or the vxWatch directive, which
 * fires whan any of the contained properties change.
 */
export abstract class MvcContainer extends MvcBase {

  /** Changes are logged to console using this label if set */
  public logChanges: string;

  /** Count of active batches */
  private _batches = 0;

  protected notify(): void {
    this.watchers.forEach((watcher: IWatcher) => {
      watcher.notify(this);
    });
  }

  public changed() {

    // Only fire when no batches are active
    // This should only be 0 or 1 unless a batch contains async methods in which case it
    // is possible more could be active at once.  We notify when all are complete.
    if (this._batches < 1) {
      this.notify();
    }
  }

  /**
   * Makes multiple updates with a single change notification at the end, rather than
   * notifying for each change.
   *
   * @param updates code to update properties
   *
   * The updates parameter is a function that shoudl only perform the updates.
   * Avoid code that could throw an exception as that would leave the model in an
   * inconsistent state, or async code which could reuslt in race conditions.
   *
   *
   * @example pointModel.batch( () => {
   *    pointModel.x.value = 0;
   *    pointModel.y.value = 0;
   *  });
   *
   */
  public batch(updates: () => void) {

    this._batches++;
    try {
      updates();
    }
    finally {
      this._batches--;
      this.changed();
    }
  }

  /**
   * Batch changes as @see batch but with an async callback.
   * Use with caution as the order of execution is not so well defined
   * and state could change while executing the updates. The batch will
   * still not complete until all batches are done (sync or async).
   * Be sure to await the result!
   */
  public async batchAsync(updates: () => Promise<void>) {

    this._batches++;
    try {
      await updates();
    }
    finally {
      this._batches--;
      this.changed();
    }
  }

  private watchers = new Set<IWatcher>();

  public watch(watcher: IWatcher) {
    this.watchers.add(watcher);
  }

  public unwatch(watcher: IWatcher) {
    this.watchers.delete(watcher);
  }
}
