
<div *ngIf="homepageType === Enums.HomepageType.image" class="text-center">
  <app-asset-image 
  [assetUrl]="homepageData.AssetImageUri"
   >
</app-asset-image>

</div>

<ng-container *ngIf="homepageType === Enums.HomepageType.dashboard && dashboard"> 
  <app-dashboardreport
   [dashboard]="dashboard"
   [dashboardModel]="dashboardModel"
   >
  </app-dashboardreport>
</ng-container>

<ng-container *ngIf="homepageType === Enums.HomepageType.custom">
  <app-custom-not-supported
    headerText="Custom homepage is not supported"
    description="(Please contact support to migrate this enhanced custom homepage to standard)">
  </app-custom-not-supported>
</ng-container>
