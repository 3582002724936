import { Injectable } from '@angular/core';
import { SiteRepository } from '../repos';
import { SiteLoginDataResource, SiteGlobalBasicDataResource } from '../types';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, SITE_GLOBAL_STORE } from '../indexedDb';
import { environment } from 'environments/environment';

export const BASIC_DATA_KEY = 'BasicData';
export const LOGIN_DATA_RESOURCE_KEY = 'LoginDataResource';

@Injectable({ providedIn: 'root' })
export class SiteStorageService extends StorageServiceBase<SiteGlobalBasicDataResource | SiteLoginDataResource, string> {

  constructor(
    dbContext: DatabaseContextService<SiteGlobalBasicDataResource>,
    public repository: SiteRepository) {
    super(SITE_GLOBAL_STORE, dbContext);
  }

  /**
   * Gets from storage if exists, otherwise fetches from server using the supplied creator.
   */
  public async getSiteGlobalBasicDataAsync(): Promise<SiteGlobalBasicDataResource> {
    const creator = () => this.repository.getSiteGlobalBasicData().toPromise();
    return await this.getOrCreateItemAsync(BASIC_DATA_KEY, creator) as SiteGlobalBasicDataResource;
  }

  /**
   * Update latest Site Global Basic Data from server
   */
  public async updateSiteGlobalBasicDataAsync(): Promise<SiteGlobalBasicDataResource> {
    const item = await this.repository.getSiteGlobalBasicData().toPromise();
    await this.save(BASIC_DATA_KEY, item);
    return item;
  }

  /**
   * Gets from storage if exists, otherwise fetches from server using the supplied creator.
   * @param tenant the tenant name for the site.
   */
  public async getOrCreateSiteLoginDataResourceAsync(tenant: string): Promise<SiteLoginDataResource> {
    if (!tenant?.length) {
      throw new Error('tenant name is required.');
    }

    const creator = () => this.repository.getSiteLoginDataResource(tenant).toPromise();
    return await this.getOrCreateItemAsync(`${LOGIN_DATA_RESOURCE_KEY}-${tenant}`, creator) as SiteLoginDataResource;
  }

  /**
   * Update latest Site Login Data from server
   * @param tenant the tenant name for the site.
   */
  public async updateSiteLoginDataResourceAsync(tenant: string): Promise<SiteLoginDataResource> {
    if (!tenant?.length) {
      throw new Error('tenant name is required.');
    }

    const item = await this.repository.getSiteLoginDataResource(tenant).toPromise();
    await this.save(`${LOGIN_DATA_RESOURCE_KEY}-${tenant}`, item);
    return item;
  }

  public async getAuth0Domain(tenant: string) {
    // We always want to get latest SiteLoginDataResource here.  SiteResyncGuard will then take care of subsequent site version updates for settings etc.
    const site = await this.updateSiteLoginDataResourceAsync(tenant);
    const auth0Domain = site.OverriddenAuth0Domain ? site.OverriddenAuth0Domain : environment.auth0Domain;
    return site.OverriddenAuth0CustomDomain ? site.OverriddenAuth0CustomDomain : auth0Domain;
  }
}
