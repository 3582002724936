import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseIconComponent } from '../iconbase';

@Component({
  selector: 'sof-plus-icon',
  templateUrl: '../icon.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlusComponent extends BaseIconComponent {
  constructor() {
    super('plus');
  }
}
