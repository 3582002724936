import { Injectable } from '@angular/core';
import { ChartData, Enums, IndexedAppData, Record, Report } from '@softools/softools-core';
import { GanttChartDataService } from './gantt-chart-data.service';
import { BasicChartDataService } from './basic-chart-data.service';
import { BubbleChartDataService } from './bubble-chart-data.service';
import { CumulativeChartDataService } from './cumulative-chart-data.service';
import { MapChartDataService } from './map-chart-data.service';
import { MonteCarloChartDataService } from './montecarlo-chart-data.service';
import { PieChartDataService } from './pie-chart-data.service';
import { PolarChartDataService } from './polar-chart-data.service';
import { ScatterChartDataService } from './scatter-chart-data.service';
import { GaugeChartDataService } from './gauge-chart-data.service';
import { NetworkChartDataService } from './network-chart-data.service';
import { ChartEnums } from '../chart.enums';
import { ReportFilter } from 'app/filters/types';
import { SummarySeriesChartDataService } from './summary-series-chart-data.service';
import { ChartErrorException } from 'app/exceptions';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {

  constructor(
    private basicChartDataService: BasicChartDataService,
    private bubbleChartDataService: BubbleChartDataService,
    private cumulativeChartDataService: CumulativeChartDataService,
    private ganttChartDataService: GanttChartDataService,
    private mapChartDataService: MapChartDataService,
    private monteCarloChartDataService: MonteCarloChartDataService,
    private pieChartDataService: PieChartDataService,
    private polarChartDataService: PolarChartDataService,
    private scatterChartDataService: ScatterChartDataService,
    private gaugeChartDataService: GaugeChartDataService,
    private networkChartDataService: NetworkChartDataService,
    private summarySeriesChartDataService: SummarySeriesChartDataService
  ) { }


  /**
   * Get chart data for an embedded chart based on field data in the sepecified record
   * @param app
   * @param report
   * @param record
   * @returns
   */
  public async getRecordChartData(app: Application, report: Report, record: Record) {

    switch (report.Chart.ChartType) {
      case Enums.ChartType.area:
      case Enums.ChartType.areaspline:
      case Enums.ChartType.bar:
      case Enums.ChartType.column:
      case Enums.ChartType.line:
      case Enums.ChartType.spline:
        return await this.basicChartDataService.getBasicChartDataOptions(app, null, report, null, record);
      case Enums.ChartType.cumulativematrix:
        return await this.cumulativeChartDataService.getCumulativeChartDataOptions(app, null, report, record);
      case Enums.ChartType.map:
        return await this.mapChartDataService.getMapChartDataOptions(app, null, report, record);
      case Enums.ChartType.bubble:
        return await this.bubbleChartDataService.getBubbleChartDataOptions(app, null, report, record);
      case Enums.ChartType.montecarlo:
        return await this.monteCarloChartDataService.getMonteCarloChartDataOptions(app, null, report, record);
      case Enums.ChartType.pie:
        return await this.pieChartDataService.getPieChartDataOptions(app, null, report, record);
      case Enums.ChartType.scatter:
        return await this.scatterChartDataService.getScatterChartDataOptions(app, null, report, record);
      case Enums.ChartType.polar:
        return await this.polarChartDataService.getPolarChartDataOptions(app, null, report, null, record);
      case Enums.ChartType.gantt:
        return await this.ganttChartDataService.getGanttChartDataOptions(app, null, report, null, record);
      case Enums.ChartType.gauge:
        return await this.gaugeChartDataService.getGaugeChartDataOptions(app, null, report, record);
      case Enums.ChartType.network:
        return await this.networkChartDataService.getNetworkChartDataOptions(app, null, report, null, record);
      case Enums.ChartType.summaryseries:
        return await this.summarySeriesChartDataService.getSummarySeriesChartDataOptions(app, null, null, report, record);
      default:
        return null;
    }

    // return await this.getChartDataFromIndex(app, report, undefined, null, null, record);
  }

  /**
   * Get chart data for an app level chart using the supplied index
   * @param app
   * @param report
   * @param reportFilter
   * @param hierarchy
   * @param dataIndex
   * @param record
   * @returns
   */
  public async getChartDataFromIndex(app: Application, report: Report, reportFilter: ReportFilter, hierarchy?: string, dataIndex?: IndexedAppData, record?: Record): Promise<ChartData> {

    if (!dataIndex?.length) {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoRecords);
    }

    switch (report.Chart.ChartType) {
      case Enums.ChartType.area:
      case Enums.ChartType.areaspline:
      case Enums.ChartType.bar:
      case Enums.ChartType.column:
      case Enums.ChartType.line:
      case Enums.ChartType.spline:
        return await this.basicChartDataService.getBasicChartDataOptions(app, dataIndex, report, hierarchy, record);
      case Enums.ChartType.cumulativematrix:
        return await this.cumulativeChartDataService.getCumulativeChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.map:
        return await this.mapChartDataService.getMapChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.bubble:
        return await this.bubbleChartDataService.getBubbleChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.montecarlo:
        return await this.monteCarloChartDataService.getMonteCarloChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.pie:
        return await this.pieChartDataService.getPieChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.scatter:
        return await this.scatterChartDataService.getScatterChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.polar:
        return await this.polarChartDataService.getPolarChartDataOptions(app, dataIndex, report, hierarchy, record);
      case Enums.ChartType.gantt:
        return await this.ganttChartDataService.getGanttChartDataOptions(app, dataIndex, report, hierarchy, record);
      case Enums.ChartType.gauge:
        return await this.gaugeChartDataService.getGaugeChartDataOptions(app, dataIndex, report, record);
      case Enums.ChartType.network:
        return await this.networkChartDataService.getNetworkChartDataOptions(app, dataIndex, report, hierarchy, record);
      case Enums.ChartType.summaryseries:
        return await this.summarySeriesChartDataService.getSummarySeriesChartDataOptions(app, reportFilter, dataIndex, report, record);
      default:
        return null;
    }
  }
}
