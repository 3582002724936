import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { Enums, QueryParams } from '../types';

@Injectable({ providedIn: 'root' })
export class TemplatedReportsRepository extends RepositoryBase {
  public getGlobalTemplatedReports() {
    const url = this.urlResolver.resolveDefaultApiUrl(
      'TemplatedReports',
      'GetGlobalTemplatedReports'
    );
    return this.get(url);
  }

  public getAppTemplatedReports(appIdentifier: string) {
    const url =
      this.urlResolver.resolveDefaultApiUrl(
        'TemplatedReports',
        'GetAppTemplatedReports/'
      ) + appIdentifier;
    return this.get(url);
  }

  public getRecordTemplatedReports(appIdentifier: string) {
    const url =
      this.urlResolver.resolveDefaultApiUrl(
        'TemplatedReports',
        'GetRecordTemplatedReports/'
      ) + appIdentifier;
    return this.get(url);
  }

  public exportAppLevel(
    appIdentifier: string,
    reportIdentifier: string,
    templatedReportId: number,
    templatedReportTitle: string,
    templateReportIsSystemDefaultReport: boolean,
    selectedRows: Array<any>,
    query: QueryParams,
    asPdf: boolean,
    subType: Enums.TemplatedReportSubType,
    searchTerm: string = ''
  ) {
    const model = {
      TemplatedReportId: templatedReportId,
      Title: templatedReportTitle,
      AppIdentifier: appIdentifier,
      SubType: subType,
      IsSystemDefaultReport: templateReportIsSystemDefaultReport,
      ReportIdentifier: reportIdentifier,
      SelectedRows: selectedRows,
      AsPdf: asPdf,
      RecordId: null
    };

    delete query['$top'];
    delete query['$skip'];
    const queryString = this.urlResolver.getQueryString(query);
    const url = this.urlResolver.resolveDefaultApiUrl(
      'TemplatedReports',
      `Export/${queryString}`
    );
    return this.post(url, JSON.stringify(model));
  }

  public exportHomeLevel(
    templatedReportId: number,
    templatedReportTitle: string,
    asPdf: boolean
  ) {
    const model = {
      TemplatedReportId: templatedReportId,
      Title: templatedReportTitle,
      AsPdf: asPdf
    };
    const url = this.urlResolver.resolveDefaultApiUrl(
      'TemplatedReports',
      'Export'
    );
    return this.post(url, JSON.stringify(model));
  }

  public exportRecordLevel(
    appIdentifier: string,
    templatedReportId: number,
    templatedReportTitle: string,
    templateReportIsSystemDefaultReport: boolean,
    recordId: string,
    asPdf: boolean
  ) {
    const model = {
      TemplatedReportId: templatedReportId,
      Title: templatedReportTitle,
      AppIdentifier: appIdentifier,
      Subtype: Enums.TemplatedReportSubType.Record,
      IsSystemDefaultReport: templateReportIsSystemDefaultReport,
      ReportIdentifier: null,
      SelectedRows: [],
      RecordId: recordId,
      AsPdf: asPdf
    };
    const url = this.urlResolver.resolveDefaultApiUrl(
      'TemplatedReports',
      'Export'
    );
    return this.post(url, JSON.stringify(model));
  }
}
