import { Router, ActivatedRoute, Params } from '@angular/router';
import { OnInit, OnDestroy, Directive } from '@angular/core';

import { QueryParams, UrlResolver, OnlineStatusService, Enums, logError } from '@softools/softools-core';

import { WSBase } from '../../../workspace.module/components/ws-base/ws-base';
import { APP_IDENTIFIER, REPORT_IDENTIFIER, PARENT_RECORD_ID, CHILD_APP_IDENTIFIER } from 'app/_constants';
import { IndexedDbService } from '../../../workspace.module/services/indexeddb.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FilterTermUpdates } from '../../../filters/filter-simple-popup/filter-simple-popup.component';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { ReportModel, FilterModel, TableReportModel, ReportFolderController, ChildReportFolderController } from 'app/mvc';
import { AppIdentifiers } from 'app/services/record/app-info';
import { RouteParams } from 'app/mvc/global.model';
import { RecordClickEvent } from 'app/softoolsui.module/form.component/form-base.component';
import { ReportPanelZoom } from 'app/workspace.module/types/report-panel-zoom';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { ReportController } from 'app/mvc/reports/report.controller';

@Directive()
export class WSReportBase extends WSBase implements OnInit, OnDestroy, IShowFilterManagement {

  public isFilterManagementPanelVisible$ = new BehaviorSubject(false);

  public isQuickFilterVisible$ = new BehaviorSubject(false);

  public isOfflineReport$ = new BehaviorSubject(true);

  private routerParamsSub: Subscription;
  private filterSub: Subscription;

  public filterModel = new FilterModel(this.appModel);
  public reportModel: ReportModel | TableReportModel;
  public reportController: ReportController;

  public folderController: ReportFolderController | ChildReportFolderController;

  public quickFilterOpenState$ = new BehaviorSubject('inactive');

  public filterManageOpenState$ = new BehaviorSubject('inactive');

  constructor(
    public indexedDbService: IndexedDbService,
    public override appService: AppService,
    public router: Router,
    public urlresolver: UrlResolver,
    onlineStatus: OnlineStatusService,
    route: ActivatedRoute,
    navigationService: NavigationService,
    permissionsService: PermissionsService,
    models: GlobalModelService,
  ) {
    super(onlineStatus, appService, route, navigationService, permissionsService, models);

    this.filterModel.initialise();

    this.reportModel = this.createReportModel();
    this.generalController = this.reportController = this.createReportController();

    // While we only support a single active report, set all reports to foreground
    this.reportModel.foreground.value = true;
    this.reportModel.initialise();
    this.reportController.initialise();
  }

  /** Create model for report, override for more specific types */
  protected createReportModel() {
    return new ReportModel(this.appModel, this.filterModel, this.models.pageModel);
  }

  protected createReportController() {
    return new ReportController(this.reportModel);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.routerParamsSub = this.route.params.subscribe(params => {
      this.routeChanged(params);
    });

    this.subscribe(this.isQuickFilterVisible$, (visble) => {
      this.quickFilterOpenState$.next(visble ? 'active' : 'inactive');
      setTimeout(() => this.globalModel.layoutChanged(), 400);
    });

    this.subscribe(this.isFilterManagementPanelVisible$, (visble) => {
      this.filterManageOpenState$.next(visble ? 'active' : 'inactive');
      setTimeout(() => this.globalModel.layoutChanged(), 400);
    });

    const reportApp$ = combineLatest([this.appModel.app.$, this.reportModel.report.$]);
    this.subscribe(reportApp$, ([app, report]) => {
      if (app && report) {
        const initiallyShowQuickFilters = app.isFilterAdvanced &&
          this.reportController?.quickFiltersPinned.value;
        this.showQuickFilters(initiallyShowQuickFilters);
      }
    });
  }

  public override ngOnDestroy(): void {

    super.ngOnDestroy();

    this.routerParamsSub.unsubscribe();

    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }

    this.reportModel?.dispose();
    this.folderController?.dispose();

    this.reportModel = null;
    this.folderController = null;
  }

  protected override async routed(params: RouteParams) {

    // Set up folder controller early so it tracks model changes
    this.folderController?.dispose();
    this.folderController = params.parentAppIdentifier ?
      new ChildReportFolderController(this.appModel, this.reportModel, this.pageModel.folderModel, this.reportModel.parentRecord, this.reportModel.parentApplication) :
      new ReportFolderController(this.appModel, this.reportModel, this.pageModel.folderModel);

    await this.appModel.routed(params);
    await this.filterModel.routed(params);

    if (this.reportModel) {
      await this.reportModel.routed(params);
    }

    this.folderController?.initialise();

    // todo move into model?
    if (this.reportModel) {
      const dual = this.reportModel.dualReportModel$.value;
      if (dual) {
        const dualParams = { ...params, reportIdentifier: dual.report.value?.Identifier };
        await dual.routed(dualParams);
        // Make the dual report the foreground object
        dual.setFocus();
      } else {
        // Make this the foreground object
        this.reportModel.setFocus();
      }
    }

    // Model reload not being seen
    await this.refresh();
  }

  /**
   * Initialise from route parameters
   * Copied from previous royte observer - should be removed when mvc done
   */
  protected processRouteParams() {
    const qparams = this.route.snapshot.queryParams;
    this.initialLoadReport(this.route, qparams, this.globalModel.archived.value);
  }

  protected override routeChanged(params: Params) {
    super.routeChanged(params);
    this.appIdentifiers = new AppIdentifiers(params);
  }

  public onArchivedRecordsBadgeClickHandler() {
    this.reportModel.appModel.globalModel.archived.value = false;
  }

  public onFilterByClickHandler(url: string) {
    if (url.indexOf('?') === -1) {
      this.filterModel.reportFilter.value = null;
    } else {
      this.router.navigateByUrl(url).catch(e => logError(e, 'Failed to navigate'));
    }
  }

  public showFilterManagementPanel(show: boolean) {
    this.isFilterManagementPanelVisible$.next(show);
  }

  public toggleFilterManagementPanel() {
    const current = this.isFilterManagementPanelVisible$.value;
    this.showFilterManagementPanel(!current);
  }

  public showQuickFilters(show: boolean) {
    this.isQuickFilterVisible$.next(show);
  }

  public toggleQuickFilters() {
    const current = this.isQuickFilterVisible$.value;
    this.isQuickFilterVisible$.next(!current);
  }

  public quickFilterClosed() {
    this.showQuickFilters(false);
  }

  public filterManagementClosed() {
    this.showFilterManagementPanel(false);
  }

  public filterTermUpdated(updates: FilterTermUpdates) {
    this.filterModel.updateFilterTerms(updates);
  }

  public filterEditCancelled(): void {
    this.reportModel.closeFilterEditor();
  }

  public onSearchEnterClickHandler(searchTerm: string): void {
    this.setSearch(searchTerm).catch(error => logError(error, 'Failed to set search term'));
  }

  public onSearchEscClickHandler(): void {
    this.setSearch(null).catch(error => logError(error, 'Failed to clear search term'));
  }

  public onRecordClickHandler(event: RecordClickEvent) {
    this.reportModel.navigateToRecord(event.record, event.row);
  }

  public initialLoadReport(route: ActivatedRoute, params: QueryParams, showArchived: boolean) {
    const appIdentifier = route.snapshot.params[APP_IDENTIFIER] as string;
    const childAppIdentifier = route.snapshot.params[CHILD_APP_IDENTIFIER] as string;
    const parentRecordId = route.snapshot.params[PARENT_RECORD_ID] as string;
    let reportIdentifier = route.snapshot.params[REPORT_IDENTIFIER] as string;
    reportIdentifier = this._getReportIdentifier(reportIdentifier);

    if (childAppIdentifier && parentRecordId) {
      const app = this.appService.application(childAppIdentifier);
      this.isOfflineReport$.next(app.isOfflineReport(reportIdentifier));
    } else {
      const app = this.appService.application(appIdentifier);
      this.isOfflineReport$.next(app.isOfflineReport(reportIdentifier));
    }
  }

  public get showFooter() {
    const report = this.reportModel.report.value;
    return report ? report.Type === Enums.ReportTypes.List : false;
  }

  private _getReportIdentifier(reportIdentifier: string): string {
    // Query params are added on forChild modules, create a simple test app and raise with angular.
    let reportIdentifierVal = reportIdentifier;
    if (reportIdentifierVal) {
      reportIdentifierVal = reportIdentifier.split('?')[0];
    }
    return reportIdentifierVal;
  }

  public onToggleRightAsideHandler($event?: MouseEvent) {
    $event?.stopPropagation();
    this.pageModel.toggleSidebar();
  }

  public setZoomPane(pane?: ReportPanelZoom) {
    this.reportModel.setZoomPane(pane);
  }

  private async setSearch(searchTerm: string): Promise<void> {
    try {
      await this.reportModel.setSearchTerm(searchTerm);

      const dual = this.reportModel.dualReportModel$.value;
      if (dual) {
        await dual.setSearchTerm(searchTerm);
      }
    } catch (error) {
      logError(error, 'Failed to set search term');
    }
  }

  public quickToFilterManagement() {
    this.showQuickFilters(false);
    // Slight kludge to wait until quick filters is closed
    // Without this the panel appears at the old edge of the window
    // At least use animation events, or preferably  position the panel correctly
    // without waiting
    // Keeping it simple as we may go another way e.g. embedding panel
    setTimeout(() => this.showFilterManagementPanel(true), 250);
  }

  public toggleEditMode() {
    this.reportModel.editMode.toggle();
  }

  public setEditMode(enable: boolean) {
    this.reportModel.editMode.value = enable;
  }
}
