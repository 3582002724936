import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  Record,
  Report,
  logError,
  SearchTermStorageService,
  RecordId,
  logMessage,
  ReportViewService,
  ReportViewParams,
  Enums,
  QueryParams,
  AppDataRepository,
  TemplatedReport,
  TemplatedReportsRepository,
  ReportDataRepository,
  BackgroundProcessStorageService,
  SelectedRowsRequest,
  BulkAddToHierarchyRequest,
  IRecordSelection,
  ReportClickThroughInfo,
  ModalMessageType,
  DualReportPositionEnum,
  ReportDataExportFlags,
  LookupCaptionStyle,
  LookupOptions,
} from '@softools/softools-core';
import { BooleanModelProperty, MapModelProperty, Model, ModelEvent, ModelProperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { ReportFilter } from 'app/filters/types';
import { AppModel } from '../app.model';
import { FilterModel } from '../filter.model';
import { SelectionModel } from './selection.model';
import { RecordPatch } from 'app/workspace.module/types';
import { GlobalModel, RouteParams } from '../global.model';
import { AppIdentifier, Application, DeleteRecordOptions } from 'app/types/application';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { NavigationService } from 'app/services/navigation.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { BusyModel, Causes } from './busy.model';
import { ChangeDetectionService } from 'app/services/change-detection.service';
import { FilteredViewModel } from './filtered-view.model';
import { MessageDialogData, MessageType } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { ExportComponentConfig, ExportComponentResult } from 'app/softoolsui.module/export.component';
import { UsersService } from 'app/services/users.service';
import { TeamsService } from 'app/services/teams.service';
import { SecurityComponent, SecurityComponentConfig } from 'app/softoolsui.module/security.component/security.component';
import { AppService } from 'app/services/app.service';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { AppDataAccessor } from './accessors/app-data-accessor';
import { FilterEditorUi } from 'app/filters/types/filter-editor-ui';
import { StorageModeService } from 'app/services/storage-mode.service';
import { PageModel } from '../page/page.model';
import { IFocusableModel } from '../page/focusable-model.interface';
import { ModelFactory } from '../services/model-factory.';
import { SelectionMode } from 'app/softoolsui.module/table-report/table-report-types';
import { ILookupDialog, lookupServiceToken } from 'app/softoolsui.module/lookup-dialog-service/lookup-dialog.interface';
import { ReportPanelZoom } from 'app/workspace.module/types/report-panel-zoom';
import { RecordsReportModel } from './records-report.model';

export interface IExpandedField {
  recordId: RecordId;
  fieldIdentifier: string;
  inAppChartReportIdentifier?: string;
}

/**
 * Representation of the state of a viewed report.  This includes both the
 * report definition and the runtime view (active filter, current row range,
 * selections etc.)
 */
export class ReportModel<TAccessor extends AppDataAccessor = AppDataAccessor> extends Model<ReportModel> implements IFocusableModel {

  public constructor(public appModel: AppModel, public filterModel: FilterModel, public pageModel: PageModel, container?: Model<any>) {
    super(container);
    this.globalModel = appModel.globalModel;
  }

  @InjectService(lookupServiceToken)
  private readonly lookup: ILookupDialog;

  public readonly selectionModel = new SelectionModel(this);

  public globalModel: GlobalModel;

  /** Used for list reports edit mode */
  public readonly inEditMode = new BooleanModelProperty(this, false).withLogging("INEDITMODE")

  /** If true this is the main report which is refrelcted menus etc */
  // currently a simple flag so e.g. record selectors don't set headers
  // If we allow multiple reports on a page, need to track active report in global model
  public readonly foreground = new BooleanModelProperty(this, false);

  /** Hierarchy string for a child report, '' for top level report */
  public readonly hierarchy = new ModelProperty<string>(this, '');

  /** Parent record, null for top level report */
  public readonly parentRecord = new ModelProperty<Record>(this).withLogging('Parent Rec');

  public readonly parentApplication = new ModelProperty<Application>(this).withLogging('Parent App');

  /** Parent record id for a child report, '' for top level report */
  public readonly parentRecordId = new ModelProperty<string>(this, '');

  /** Parent app id for a child report, '' for top level report */
  public readonly parentAppIdentifier = new ModelProperty<AppIdentifier>(this, '');

  public readonly report = new ModelProperty<Report>(this).withLogging('REPORT');

  public readonly filteredView = new FilteredViewModel(this, this.filterModel);

  public readonly reportIdentifier = new ModelProperty<string>(this);

  /** Report id qualified with app name e.g. Widgets-WidgetList */
  public readonly qualifiedReportIdentifier = new ModelProperty<string>(this);

  public readonly searchTerm = new ModelProperty<string>(this);

  public readonly searchFilter = new ModelProperty<ReportFilter>(this);

  /** Expanded field info.  Currenlty only used for list report in-app charts */
  public readonly expandedFields = new MapModelProperty<RecordId, IExpandedField>(this);

  public readonly autoLayout = new BooleanModelProperty(this, false);

  public readonly activated$ = new ModelEvent();

  /** Fired when toolbar needs regenerating */
  public readonly updateToolbar = new ModelEvent();

  /**
   * Data access implementation.  The concrete type is set when we know what storage mode
   * we are in, and may be changed dynamically (e.g. if archive mode changes)
   */
  public accessor: TAccessor;

  /** Record number of first record being viewed (0 based)  */
  public readonly topIndex = new ModelProperty<number>(this).withLogging('Top Index');;

  /** Number of records in current page  */
  public readonly pageSize = new ModelProperty<number>(this).withLogging('PAGE SIZE');

  /** Total number of records in current view (considering filtering) */
  public readonly totalCount = new ModelProperty<number>(this, 0).withLogging('VIEW COUNT');

  public readonly selectedCount = new ModelProperty<number>(this, 0);

  /** Collection of records in the current view */
  // todo can we make this more granular?  Like a model containing
  // and array of model props, so you can watch an individual
  // record or the whole collection?
  public readonly records = new ModelProperty<Array<Record>>(this).withLogging('Records');

  /** UI state for filter edit in this report */
  public readonly filterEditorUi = new ModelProperty<FilterEditorUi>(this);


  /** True if an error occured loading report data  */
  // This is currently unused, placed here to replace LoadingError state which isn't currently being set
  // See SOF-10552
  public readonly loadError = new BooleanModelProperty(this, false);

  /** Toolbar actions for the main (ellipsis) menu */
  public readonly toolbarActions = new ModelProperty<Array<ToolbarAction>>(this).withLogging('TOOLBAR ACTIONS');

  /** Report selection mode (none/single/multi) */
  public readonly selectionMode = new ModelProperty<SelectionMode>(this, SelectionMode.None).withLogging('Selection Mode');

  public recordChanged$ = new Subject<Record>();

  public readonly busy = new BusyModel(this);

  /** Observable fires when the range of viewed records changes */
  public viewChanged$ = new BehaviorSubject<{ top: number; count: number }>(null);

  /** true if showing a dual report */
  public isDual = new BooleanModelProperty(this, false);

  /** Dual model associated with this model */
  public dualReportModel$ = new BehaviorSubject<RecordsReportModel>(null);

  public dualReportStyle = new ModelProperty(this, 'none').withLogging('Dual Report Style');

  public readonly zoomPane = new ModelProperty<ReportPanelZoom>(this, 'none').withLogging('Zoom pane');

  public readonly editMode = new BooleanModelProperty(this, false).withLogging('Edit Mode');

  // @InjectService(AppService)
  // private readonly appService: AppService;

  @InjectService(OverlayService)
  private readonly overlayService: OverlayService;

  @InjectService(AppDataRepository)
  protected readonly _appDataRepository: AppDataRepository;

  @InjectService(SearchTermStorageService)
  private readonly searchTermStorageService: SearchTermStorageService;

  @InjectService(RecordQueueService)
  private readonly recordQueueService: RecordQueueService;

  @InjectService(RecordPersistService)
  protected readonly recordPersistService: RecordPersistService;

  @InjectService(NavigationService)
  private readonly navigationService: NavigationService;

  @InjectService(ReportViewService)
  private readonly reportViewService: ReportViewService;

  @InjectService(ReportDataRepository)
  protected readonly reportDataRepository: ReportDataRepository;

  @InjectService(ChangeDetectionService)
  private readonly changeDetectionService: ChangeDetectionService;

  @InjectService(TemplatedReportsRepository)
  private readonly templatedReportsRepository: TemplatedReportsRepository;

  @InjectService(BackgroundProcessStorageService)
  private readonly backgroundProcessStorageService: BackgroundProcessStorageService;

  @InjectService(UsersService)
  private readonly usersService: UsersService;

  @InjectService(TeamsService)
  private teamsService: TeamsService;

  @InjectService(AppService)
  private readonly appService: AppService;

  @InjectService(StorageModeService)
  private readonly storageModeService: StorageModeService;

  @InjectService(ModelFactory)
  private readonly modelFactory: ModelFactory;

  // @InjectService(GlobalModelService)
  // public readonly globalModelService: GlobalModelService;

  /** Initialise the model.  Call after construction */
  public initialise() {
    this.observeProperties();
    this.selectionModel.initialise();
    this.filteredView.initialise();
  }

  // todo -> app model?
  protected async setParentRecord(params: RouteParams) {

    if (params.parentAppIdentifier && params.parentRecordId) {
      const parentApp = this.appService.application(params.parentAppIdentifier);
      const parent = await parentApp.getRecordByIdAsync(params.parentRecordId);

      this.parentApplication.value = parentApp;
      this.parentAppIdentifier.value = params.parentAppIdentifier;
      this.parentRecord.value = parent;
      this.parentRecordId.value = params.parentRecordId;
    } else {
      this.parentRecord.value = null;
      this.parentApplication.value = null;
      this.parentRecordId.value = '';
      this.parentAppIdentifier.value = '';
    }
  }

  /** Initialise the model for a new route */
  public async routed(params: RouteParams) {
    try {
      this.busy.setLoading(true);

      const app = this.appModel.app.value;

      if (app) {
        const report = this.appService.getReport(app.Identifier, params.reportIdentifier);

        this.resetAccessor(app, report);

        // Reset to empty
        await this.batchAsync(async () => {
          this.totalCount.value = undefined;
          this.records.value = [];
          this.reportIdentifier.value = params.reportIdentifier;
          this.qualifiedReportIdentifier.value = `${app.Identifier}-${params.reportIdentifier}`;
          await this.setParentRecord(params);
          this.report.value = report;
        });

        this.hierarchy.value = params.hierarchy;

        const term = await this.searchTermStorageService.getSearchTermAsync(
          params.appIdentifiers.appIdentifier,
          params.reportIdentifier,
          params.hierarchy
        );


        // store report identifier for last accessed
        if (this.foreground.value) {
          app.setLastAccessedReport(params.reportIdentifier);
        }

        const view: ReportViewParams = await this.reportViewService.getViewDataAsync(app.Identifier, params.reportIdentifier);

        await this.batchAsync(async () => {

          this.searchTerm.value = term;

          this.topIndex.value = 0;
          if (this.report.value.Type === Enums.ReportTypes.List) {
            this.pageSize.value = view?.pageSize || 25;
          }
        });

        if (this.foreground.value) {
          await this.setHeaderModel();
        }

        // Set accessor for this app/report
        await this.setAccessor(app, report, this.filterModel);

        await this.activated();

        if (view?.autoLayout !== undefined) {
          this.autoLayout.value = view.autoLayout;
        } else {
          this.autoLayout.value = !report.AutoLayoutOffByDefault;
        }

        // Load data - don't await as this can be time consuming
        this.loadIndex().then(() => {
          if (this.filteredView?.ready) {
            this.filteredView.ready.value = true;
          }
        }).catch(e => logError(e, 'loading'));

      }
    } finally {
      this.busy.setLoading(false);
    }
  }

  public setFocus() {
    this.pageModel.focus.value = this;
  }

  /**
   * Toggle the state of an expandable field.
   * The default behaviour allows for a single expanded field per record.
   */
  public toggleExpandedField(expanded: IExpandedField) {
    const id = expanded.recordId;
    const current = this.expandedFields.get(id);
    if (!current || current.fieldIdentifier !== expanded.fieldIdentifier) {
      this.expandedFields.set(id, expanded);
    } else {
      this.expandedFields.delete(id);
    }
  }

  protected resetAccessor(app: Application, report: Report) { }

  protected async setAccessor(app: Application, report: Report, filterModel: FilterModel): Promise<void> { }

  /** Called when the report has been initialised and is ready to display  */
  protected async activated() {
    this.activated$.fire();
  }

  protected async setHeaderModel() {
    const app = this.appModel.app.value;
    const report = this.report.value;
    const appTitle = app.Title || '';
    const reportTitle = report?.Title;
    const header = this.globalModel.header;

    let title: string;
    if (reportTitle) {
      // We'll need to handle parent part when visit child apps
      // See AppHeader.parentTitle
      // need parent record in model somewhere
      const parts: Array<string> = [];
      const parentApp = this.appModel.appContext.parentApp.value;
      const parentRecordId = this.appModel.appContext.parentRecordId.value;

      if (parentApp && parentRecordId) {

        const record = await parentApp.getRecordByIdAsync(parentRecordId);

        const parentRecordTitle = await this.recordTitle(parentApp, record);

        if (parentRecordTitle) {
          parts.push(parentRecordTitle);
        }
      }

      parts.push(appTitle);

      parts.push(reportTitle);

      title = parts.join(' > ');
    } else {
      title = appTitle;
    }

    header.batch(() => {
      const isChild = !!this.parentAppIdentifier.value;
      header.showBackButton.value = isChild;
      header.app.value = app;
      header.title.value = title;

      if (this.foreground.value) {
        this.setToolbarActions();
      }

      header.loading.value = false;
    });
  }

  public isInlineEditSupported() {
    // Override to enable
    return false;
  }

  /** Does it make sense to sort the field on thes report? */
  public isFieldSortable(fieldId: string): boolean {

    const field = this.appModel.app.value?.getField(fieldId);
    if (field?.Sortable) {
      return true;
    }

    // Fall back to report fields while migrating to SOF-11434
    if (this.report.value.ListReportFields?.find(f => f.FieldIdentifier === fieldId)?.Sortable) {
      return true;
    }

    const dual = this.dualReportModel$.value;
    if (dual) {
      return dual.isFieldSortable(fieldId);
    }

    return false;
  }

  protected async recordTitle(app: Application, record: Record): Promise<string> {
    if (app.TitleFieldIdentifier) {
      const titleField = app.getField(app.TitleFieldIdentifier);
      if (titleField) {
        const recordTitleFieldText = titleField.getRecordValue(record);
        const patch = this.recordQueueService.getPatch(record._id);
        const changeRecordTitleFieldText = patch?.getChange(app.TitleFieldIdentifier);
        return changeRecordTitleFieldText || recordTitleFieldText;
      }
    }

    return '';
  }

  protected setToolbarActions() {
    // If we're in dual mode, only build the menu for the
    // secondary report.  This is a bit arbitrary, as the record model is
    // more useful - we could generalise this
    if (!this.report.value?.DualReportIdentifier) {
      this.updateToolbar.fire();
    }
  }

  private async goBack() {
    if (this.hierarchy.value) {
      // Navigate up from child app to parent record
      const parentIds = this.appModel.appContext.appIdentifiers.value.pop();
      const [, parentRecordId] = this.hierarchy.value.split('|');
      await this.appModel.globalModel.navigation.navigateChildRecordAsync({
        appIdentifiers: parentIds,
        recordId: parentRecordId
      });

      // Or if we want to go to child home instead...
      // this.globalModel.navigation.navigateChildAppHome({
      //   appIdentifiers: this.appModel.currentApp.appIdentifiers.value,
      //   parentRecordId: this.appModel.currentApp.parentRecordId.value
      // });
    }
  }

  public get displayChart() {
    const report = this.report.value;
    if (report?.Chart) {
      switch (report.Chart.ChartType) {
        case Enums.ChartType.matrix:
          return false;
        case Enums.ChartType.cumulativematrix: {
          const displayChart = report.Chart.DisplayChart != null ? report.Chart.DisplayChart : false;
          return displayChart;
        }
        default:
          return true;
      }
    }

    return false;
  }

  public get displayMatrixTable() {
    const report = this.report.value;
    if (report && report.Chart) {
      const displayTable = report.Chart.DisplayTable != null ? report.Chart.DisplayTable : false;
      return report.Chart.ChartType === Enums.ChartType.matrix || (displayTable && report.Chart.ChartType === Enums.ChartType.cumulativematrix || report.Chart.ChartType === Enums.ChartType.montecarlo || displayTable && report.Chart.ChartType === Enums.ChartType.summaryseries);
    }

    return false;
  }

  public showFilterEditor(ui: FilterEditorUi) {
    this.filterEditorUi.value = ui;
  }

  public closeFilterEditor() {
    const ui = this.filterEditorUi.value;
    if (ui) {
      this.filterEditorUi.value = { ...ui, showPopup: false };
    }
  }

  public updateFilterTerms(updates: FilterTermUpdates) {
    this.filterModel.updateFilterTerms(updates);
    this.closeFilterEditor();
  }

  /**
   * Set the active search term
   * @param term
   * @param persist If true the value is persisted into storage so will be reused for this report
   */
  public async setSearchTerm(term: string, persist = true) {
    const appIdentifier = this.appModel.app.value.Identifier;
    const reportIdentifer = this.reportIdentifier.value;

    if (persist) {
      const hierarchy = '';
      await this.searchTermStorageService.storeSearchTermAsync(term, appIdentifier, reportIdentifer, hierarchy);
    }

    this.searchTerm.value = term;
  }

  /**
   * Clear the active search term
   * @param persist If true the value is reset in storage so will be reused for this report
   */
  public clearSearchTerm(persist = true) {
    const appIdentifier = this.appModel.app.value.Identifier;
    const reportIdentifer = this.reportIdentifier.value;

    if (persist) {
      const hierarchy = '';
      this.searchTermStorageService.storeSearchTermAsync(null, appIdentifier, reportIdentifer, hierarchy)
        .catch(error => logError(error, 'Failed to store search term'));
    }

    this.searchTerm.value = null;
  }

  public onFieldKeyDown($event: KeyboardEvent) {
    if ($event.code === 'Escape') {
      // cancel modal operation
      $event.stopImmediatePropagation();
      this.appModel?.globalModel.cancelMode();
      return false;
    } else {
      return true;
    }
  }

  public onFieldKeyPress($event: KeyboardEvent) {
    return true;
  }

  public async reportClickThroughAsync(clickInfo: ReportClickThroughInfo) {
    if (clickInfo.ClickThroughToRecord) {
      await this.globalModel.navigation.navigateUrlAsync({ url: clickInfo.DestinationUrl });
    } else {
      const viewData = await this.reportViewService.getViewDataAsync(clickInfo.AppIdentifier, clickInfo.ReportIdentifier);

      const updated: ReportViewParams = viewData ? {
        ...viewData,
        filterId: '',
        firstRecordIndex: 0,
        groupBy: clickInfo.QueryParams.$groupby,
        orderBy: clickInfo.QueryParams.$orderby,
        reportId: clickInfo.ReportIdentifier
      } : {
        filterId: '',
        showArchived: false,
        firstRecordIndex: 0,
        groupBy: clickInfo.QueryParams.$groupby,
        groupDescending: false,
        orderBy: clickInfo.QueryParams.$orderby,
        orderDescending: false,
        pageSize: 25,
        reportId: clickInfo.ReportIdentifier,
        groupValuesCollapsed: [],
        expandDetailsFields: []
      };

      if (this.appModel.app.value.isFilterAdvanced) {
        updated.additionalFilter = clickInfo.QueryParams.$filter;
      } else {
        updated.filterQuery = clickInfo.QueryParams.$filter;
      }

      await this.reportViewService.setViewDataAsync(clickInfo.AppIdentifier, updated);

      await this.globalModel.navigation.navigateUrlAsync({ url: clickInfo.DestinationUrl });
    }
  }

  /** Archive selected records */
  public async archive() {
    const selection = await this.promptForSelection({ all: MessageType.ConfirmArchiveAll, some: MessageType.ConfirmArchive });
    if (selection) {
      const queryParams = selection.AllRecordsSelected ? this.filterQueryParams() : {};

      await this._appDataRepository.bulkArchive(
        { SelectedRows: selection.AllRecordsSelected ? [] : selection.SelectedRecordIds },
        this.appModel.appContext.app.value.Identifier,
        queryParams,
        this.report.value.Id,
        this.hierarchy.value
      ).toPromise();
    }
  }

  /** Unarchive selected records */
  public async unarchive() {
    const selection = await this.promptForSelection({ all: MessageType.ConfirmUnarchiveAll, some: MessageType.ConfirmUnarchive });
    if (selection) {
      const queryParams = selection.AllRecordsSelected ? this.filterQueryParams() : {};

      await this._appDataRepository.bulkUnarchive(
        { SelectedRows: selection.AllRecordsSelected ? [] : selection.SelectedRecordIds },
        this.appModel.appContext.app.value.Identifier,
        queryParams,
        this.report.value.Id,
        this.hierarchy.value
      ).toPromise();
    }
  }

  public async link() {
    const childApp = this.appModel.appContext.childApp.value;
    const parentApp = this.appModel.appContext.parentApp.value;

    if (childApp && parentApp) {
      const linkReportId = childApp?.LinkPickerReportIdentifier ?? childApp.preferredReport(true, true)?.Identifier;
      if (linkReportId) {
        const linkReport = childApp.getReport(linkReportId);

        if (linkReport) {
          const options: LookupOptions = {
            appIdentifier: childApp.Identifier,
            reportIdentifier: linkReportId,
            report: linkReport,
            searchLookupAppField: '',
            searchValue: '',
            multiSelect: true,
            selectedIds: [],
            uiState: null,
            captionStyle: LookupCaptionStyle.MultiSelectWithAppRecordName,
          };

          const selection = await this.lookup.lookup(options);
          if (selection) {
            console.warn({ options, selection });

            const ids = Array.from(selection.ids.values());

            // Start with no filter
            const reportFilter = new ReportFilter();

            const request: BulkAddToHierarchyRequest = {
              ChildAppIdentifier: childApp.Identifier,
              ParentAppIdentifier: parentApp.Identifier,
              ParentRecordId: this.appModel.appContext.parentRecordId.value,
              Ids: ids,
              IgnoreAccessRights: false,
              RemovedIds: [],
              CopyRecords: false,   // link, not copy
              AllSelected: selection.all ?? false,
              RecordCopyInfo: {
                CopyNotes: childApp.LinkPickerDefaultIncludeNotes,
                CopyComments: childApp.LinkPickerDefaultIncludeComments,
                CopyAttachments: childApp.LinkPickerDefaultIncludeAttachments,
                CopyHistory: childApp.LinkPickerDefaultIncludeHistory,
                TemplateCopy: childApp.LinkPickerDefaultTemplatedCopy
              },
              Filter: reportFilter.QueryParameters.$filter
            };

            await firstValueFrom(this._appDataRepository.linkRecordsToParent(request));
          }

          return;
        }
      }

      this.globalModel.showErrorToasty({
        message: $localize`No suitable report is available to link this app`
      });
    }
  }

  public async unlink() {
    const selection = await this.promptForSelection({ all: MessageType.ConfirmUnlinkAll, some: MessageType.ConfirmUnlink });
    if (selection) {
      const queryParams = selection.AllRecordsSelected ? this.filterQueryParams() : {};

      const requestData = {
        ChildAppIdentifier: this.appModel.appContext.app.value.Identifier,
        Hierarchy: this.hierarchy.value,
        SelectedRows: selection.AllRecordsSelected ? [] : selection.SelectedRecordIds
      };

      await this._appDataRepository.sendBulkHierarchyUnlinkUpdate(
        requestData,
        this.appModel.appContext.parentApp.value.Identifier,
        queryParams
      ).toPromise();
    }
  }

  /** Delete selected archived records */
  public async delete(selection?: IRecordSelection) {

    // Prompt for selection if not supplied
    if (!selection) {
      selection = await this.promptForSelection({ all: MessageType.ConfirmDeleteAll, some: MessageType.ConfirmDeleteAppData });
    }

    if (selection) {
      if (true /* this.accessor*/) {
        try {
          this.busy.start(Causes.deleting);
          const queryParams = selection.AllRecordsSelected ? this.filterQueryParams() : {};
          if (this.hierarchy.value) {
            queryParams.hierarchy = this.hierarchy.value;
          }

          const options: DeleteRecordOptions = {
            selection,
            queryParams,
            reportId: this.report.value.Id,
          };

          const app = this.appModel.app.value;
          await app.deleteRecordsAsync(options);

          if (selection.AllRecordsSelected) {
            // todo full reset
          } else {
            selection.SelectedRecordIds.forEach(id => {
              this.appModel.reindexRecord(id);
            });
          }

        } catch (error) {
          logError(error, 'Deleting');
          return;
        } finally {
          this.busy.finish(Causes.deleting);
        }

        try {
          this.setIndexing();
          // await this.loadIndex();
          await this.reload();
        } catch (error) {
          logError(error, '');
        } finally {
          this.setIndexing(false);
        }
      }
    }
  }

  /** Set security on selected records */
  public async security() {

    const appIdentifier = this.appModel.appContext.app.value.Identifier;
    const reportId = this.report.value.Id;

    const selection = await this.promptForSelection();
    if (selection) {
      const data: MessageDialogData = {
        Type: MessageType.ConfirmBulkSecurityOverride
      };
      const proceed = await this.globalModel.showMessageDialogAsync(data);
      if (proceed) {

        const queryParams = selection.AllRecordsSelected ? this.filterQueryParams() : {};
        const mappedUsers = this.usersService.getAllMapped();
        const mappedPendingUsers = this.usersService.getPendingMapped();
        const mappedTeams = this.teamsService.getAllMappedTeams();

        const mappedMerged = [...mappedUsers, ...mappedPendingUsers];
        const config = new SecurityComponentConfig(mappedMerged, mappedTeams, []);

        const result = await SecurityComponent.openAsync(this.globalModel, config);
        if (result?.patch?.trackedChanges?.size > 0) {
          const patch = result.patch;
          const accessRights = patch.getDelta().AccessRights;
          const added = accessRights ? accessRights.added : [];

          if (added.length > 0) {
            const requestData: SelectedRowsRequest = {
              SelectedRows: selection.SelectedRecordIds,
              AccessRights: added
            };

            const hierarchy = this.hierarchy.value;
            await this._appDataRepository.bulkAccessRightUpdates(
              requestData,
              appIdentifier,
              queryParams,
              reportId,
              hierarchy
            );
          }
        }
      }
    }
  }

  /** Change report offline status */
  public async makeOffline(available: boolean) {
    const messageType = available ? ModalMessageType.MakeAvailableOffline : ModalMessageType.MakUnavailableOffline;
    if (await this.globalModel.confirmModalAsync(messageType)) {
      if (this.storageModeService.setReportAvailableOffline(this.appModel.app.value.Identifier,
        this.report.value.Identifier, available)) {
        this.storageModeService.store();

        // update toolbar to reflect change
        if (this.foreground.value) {
          this.setToolbarActions();
        }
      }
    }
  }

  /**
   * Navigate to record details, in the context of this report
   * @param record Record to open
   * @param row    0 based row number relative to view
   */
  public navigateToRecord(record: Record, row?: number) {
    const app = this.appModel.app.value;
    const appIdentifiers = this.appModel.appIdentifiers.value;

    if (row !== undefined && !app.isOfflineReport(this.reportIdentifier.value)) {
      // // Offline report so use cursor url (1 based)
      // const cursor = this.topIndex.value + row + 1;
      // const parentRecordId = this.parentRecordId();
      // const url = this.navigationService.getRecordCursorUrl(appIdentifiers, parentRecordId, this.report.value, cursor);

      // Using the cursor does not work due to the data changing
      // For example, users are sorted by last access this changes often
      // Opening record by cusor id will open incorrect records
      const url = this.navigationService.getRecordUrl2(appIdentifiers, record._id);
      this.globalModel.navigation.navigateUrlAsync({ url }).catch(error => logError(error, 'navigate'));

    } else {
      // offline mode, can use simple navigation without report context
      this.appModel.navigateToRecord(record)
        .catch(error => logError(error, 'navRecord'));
    }
  }

  /** Set and persist page size for this report  */
  public async updatePageSize(size: number) {
    await this.reportViewService.setPageSizeAsync(this.appModel.app.value.Identifier, this.reportIdentifier.value, size);
    this.pageSize.value = size;
  }

  public async storeAutoLayout() {
    await this.reportViewService.setAutoLayoutAsync(this.appModel.app.value.Identifier, this.reportIdentifier.value, this.autoLayout.value);
  }

  public setIndexing(indexing = true) {
    this.busy.setIndexing(indexing);
  }

  // End controller methods

  /**
   * Configure dependent properties by observing changes and
   * updating related props.
   */
  protected observeProperties() {

    const header = this.globalModel.header;
    this.subscribe(header.back.$, () => {
      this.goBack().catch(error => logError(error, 'report back'));
    });

    const changed$ = combineLatest([
      this.filterModel.combinedFilter.$.pipe(
        distinctUntilChanged((f1, f2) => ReportFilter.equals(f1, f2))
      ),
      this.searchFilter.$.pipe(
        // distinctUntilChanged()
        distinctUntilChanged((f1, f2) => ReportFilter.equals(f1, f2))
      )
    ]).pipe(debounceTime(100));

    this.subscribe(changed$, ([filter, search]) => {
      if (filter !== undefined || search !== undefined) {
        this.filterChanged().catch(error => logError(error, 'ReportModel searchFilter$'));
      }
    });

    // When search term has changed, create associated filter
    const search$ = combineLatest([
      this.searchTerm.$.pipe(distinctUntilChanged()),
      this.appModel.app.$
    ]);

    this.subscribe(search$, ([term, app]) => {
      if (term && app) {
        const searchFilter = app.createSearchFilter(term);
        this.searchFilter.value = searchFilter;
      } else {
        this.searchFilter.value = null;
      }
    });

    this.subscribe(this.appModel.updatedRecord$, (records: Array<Record>) => {
      this.recordsChanged(records);
    });

    this.subscribe(this.appModel.siteModel.onRecordUpdated.$, async (record) => {
      await this.recordsChanged([record]);
    });

    this.subscribe(this.appModel.recordPatched$, (patch: RecordPatch) => {
      this.recordPatched(patch);
    });

    this.subscribe(
      combineLatest([
        this.report.$.pipe(
          filter((id) => !!id),
          distinctUntilChanged()
        ),
        this.globalModel.archived.$.pipe(distinctUntilChanged()),
      ]),
      ([rep, arch]) => {
        this.selectionModel.deselectAll();
        this.setToolbarActions();
        this.initialiseEditMode();
      }
    );

    this.subscribe(this.globalModel.archived.$, async () => {
      const app = this.appModel.app.value;
      const report = this.report.value;
      if (app && report) {
        this.resetAccessor(app, report);
        await this.setAccessor(app, report, this.filterModel);
      }
    });

    this.subscribe(this.recordPersistService.recordUpdated$, (record: Record) => {
      // todo review this, going to be getting double calls via appmodel
      this.recordModified(record);
    });

    this.subscribe(this.changeDetectionService.recordChanged$, (records: Record[]) => {
      records.forEach((record) => {
        this.recordModified(record);
      });
    });

    this.subscribe(combineLatest([this.topIndex.$, this.pageSize.$]), ([top, count]) => {
      if (top !== undefined && count !== undefined) {
        this.viewChanged$.next({ top, count });
      }
    });

    // Track selection count
    this.subscribe(combineLatest([this.selectionModel.$, this.totalCount.$]), () => {
      if (this.selectionModel.all.value) {
        this.selectedCount.value = this.totalCount.value;
      } else {
        this.selectedCount.value = this.selectionModel.count;
      }
    });

    this.subscribe(this.report.$, (report) => {
      if (report) {
        this.trackDualReport(report);
      }
    });

    this.subscribe(combineLatest([
      this.globalModel.isSmallDisplay.$,
      this.isDual.$]), ([small, dual]) => {
        this.trackZoomPane(small, dual);
    });

    // Delegate some properties down to dual report
    this.subscribe(this.zoomPane.$, (zoom) => {
      if (this.dualReportModel$.value) {
        this.dualReportModel$.value.zoomPane.value = zoom;
      }
    });

    this.subscribe(this.appModel.deletedRecord$, (id) => {
      const records = this.records.value;
      if (records) {
        const updated = records.filter(r => r._id !== id);
        if (updated.length !== records.length) {
          this.records.value = updated;
          this.accessor?.resetIndex();
        }
      }
    });
  }

  protected initialiseEditMode() {
    this.editMode.reset();
  }

  protected async filterChanged() {
  }

  protected async loadIndex(): Promise<void> {
    await this.accessor?.initialise();
  }

  public async reload(): Promise<void> {
    await this.loadIndex();
    this.viewChanged$.next({ top: this.topIndex.value, count: this.pageSize.value });
  }

  /**
   * Refresh report data
   */
  public async refresh() {
    const app = this.appModel.app.value;
    if (await app?.refresh()) {
      await this.filterChanged();
      await this.reload();
    }
  }

  protected recordsChanged(records: Array<Record>) {
    const view = this.records.value;
    if (view) {
      let anyChanged = false;

      records.forEach((updated, i) => {
        if (updated) {
          const index = view.findIndex((r) => r?._id === updated._id);
          if (index >= 0) {
            view[index] = updated;
            anyChanged = true;
          }
        } else {
          logMessage(`recordsChanged ${i} of ${records.length} undefined`);
        }
      });

      if (anyChanged) {
        this.records.changed();
      }
    }
  }

  private recordPatched(patch: RecordPatch) {
    const app = this.appModel.app.value;
    const view = this.records.value;
    const index = view?.findIndex((r) => r?._id === patch._id);
    if (index >= 0) {
      const record = view[index];
      // SOF-7174 check that the patch app matches the record app
      if (record.AppIdentifier === app.Identifier) {
        patch.updateRecord(record, app);
        this.recordModified(record);
      } else {
        logError(new Error(`Mistmatched app id rec '${record.AppIdentifier}' patch '${patch.AppIdentifier}'`), '');
      }
    }
  }

  public recordModified(record: Record) {
    const existing = this.records.value?.findIndex((r) => r._id === record._id);
    if (existing >= 0) {
      this.records.value[existing] = record;
      this.records.changed();
      this.changed(); // dnci

      this.recordChanged$.next(record);
    }
  }

  public isDisabledByReport(fieldIdentifier: string): boolean {
    return false;
  }
  /**
   * Prompt the user for a selection based action, return the captured selection if the
   * operation should continue. The async method only completes when the user dismisses
   * the UI elements.
   *
   * @param messages       If specified prompts the user before continuing.  all is a message to use when all selected, otherwise some if used. If you don't want to allow operation on whole list set disallowAll to true and set all message accordingly.
   * @returns             Selection to use, or null if no selection or user cancels operation
   */
  public async promptForSelection(messages?: { all: MessageType, some: MessageType, disallowAll?: boolean }): Promise<IRecordSelection> {

    // Capture selection (we will clear before we use it)
    const selection: IRecordSelection = {
      AllRecordsSelected: this.selectionModel.all.value,
      SelectedRecordIds: this.selectionModel.selectedIds,
    };

    if (!selection.AllRecordsSelected && !selection.SelectedRecordIds?.length) {
      await this.globalModel.showMessageDialogAsync({
        Type: MessageType.NoSelection,
        Application: this.appModel.app.value
      });

      return null;
    }

    if (messages?.disallowAll && selection.AllRecordsSelected) {
      await this.globalModel.showMessageDialogAsync({
        Type: messages.all,
        Application: this.appModel.app.value
      });

      this.selectionModel.deselectAll();
      return null;
    }


    // const selection = await this.getSelection();
    if (selection) {
      const ok = !messages || await this.globalModel.showMessageDialogAsync({
        Type: selection.AllRecordsSelected ? messages.all : messages.some,
        Application: this.appModel.appContext.app.value,
        Count: selection.AllRecordsSelected ? this.totalCount.value : (selection.SelectedRecordIds?.length || 0)
      });

      if (ok) {
        // Clear selection
        this.selectionModel.deselectAll();
        return selection;
      }
    }

    return null;
  }

  public selectAll() {
    this.selectionModel.selectAll();
  }

  // Copied from toolbar effects, was simplified
  public filterQueryParams(includeSort = false) {

    const filterFromModel = this.mergeFilterAndSearchTerm(this.filterModel.combinedFilter.value, this.searchFilter.value);
    const queryParams: QueryParams = Object.assign(
      {},
      filterFromModel ? filterFromModel.QueryParameters : this.filterModel.combinedFilter.value.QueryParameters
    );

    delete queryParams.$top;
    delete queryParams.$skip;

    if (!includeSort) {
      delete queryParams.$orderby;
      delete queryParams.$groupby;
    }

    // Combined filter has IsArchived eq false regardless of Show Archived value, so use regex to change it.
    const archived = this.globalModel.archived.value;
    queryParams.$filter = queryParams?.$filter.replace(/(.*IsArchived eq )(true|false)(.*)/, `$1${archived || 'false'}$3`);

    // Add search term into query params if specified
    if (this.searchTerm.value) {
      queryParams.$search = this.searchTerm.value;
    }

    return queryParams;
  }

  /** Get full query parameters for the active filter */
  public queryParameters() {
    const activeFilter = this.mergeFilterAndSearchTerm(this.filterModel.combinedFilter.value, this.searchFilter.value);
    return activeFilter ? activeFilter.QueryParameters : {};
  }

  private mergeFilterAndSearchTerm(reportFilter: ReportFilter, searchFilter: ReportFilter) {
    let filterFromModel: ReportFilter;
    if (reportFilter && searchFilter) {
      filterFromModel = reportFilter.append(searchFilter);
    } else if (reportFilter) {
      filterFromModel = reportFilter;
    } else {
      filterFromModel = searchFilter;
    }

    return filterFromModel;
  }

  public async getExportConfig(): Promise<ExportComponentConfig> {

    try {
      this.overlayService.openSpinner();

      const appIdentifier = this.appModel.appContext.app.value.Identifier;
      const templatedReports: Array<TemplatedReport> = await this.templatedReportsRepository
        .getAppTemplatedReports(appIdentifier)
        .toPromise();
      const templatedReportsForAppCombinedRecord: Array<TemplatedReport> = await this.templatedReportsRepository
        .getRecordTemplatedReports(appIdentifier)
        .toPromise();

      templatedReportsForAppCombinedRecord.forEach(record => {
        record.SubType = Enums.TemplatedReportSubType.AppCombinedRecord;
        record.SubTypeString = 'AppCombinedRecord';
      });

      // Are we in a list-type report?  todo Could use polymorphic function in RecordsReportModel.
      const listReportContext = this.report.value.Type === Enums.ReportTypes.List || this.report.value.Type === Enums.ReportTypes.Table;
      const exportTypes = [] as Array<Enums.ExportType>;

      if (listReportContext) {
        exportTypes.push(Enums.ExportType.csv);
      }

      if (templatedReports.length > 0) {
        exportTypes.push(Enums.ExportType.templatedReportExport);
        exportTypes.push(Enums.ExportType.templatedReportExportAsPdf);
      }

      if (listReportContext && templatedReportsForAppCombinedRecord.length > 0) {
        exportTypes.push(Enums.ExportType.templatedReportExportCombinedRecord);
        exportTypes.push(Enums.ExportType.templatedReportExportAsPdfCombinedRecord);
      }

      const result = new ExportComponentConfig(
        exportTypes,
        templatedReports,
        templatedReportsForAppCombinedRecord,
        !!listReportContext
      );

      return result;

    } finally {
      this.overlayService.close();
    }
  }

  public async exportAppData(result: ExportComponentResult, config: ExportComponentConfig, selection: IRecordSelection) {

    const appIdentifier = this.appModel.appContext.app.value.Identifier;
    const reportId = this.report.value.Id;
    const reportIdentifier = this.report.value.Identifier;

    const queryParams = this.filterQueryParams(true);
    if (this.hierarchy.value) {
      queryParams.hierarchy = this.hierarchy.value;
    }
    if (this.searchTerm.value) {
      queryParams.$search = this.searchTerm.value;
    }

    const ids = selection.AllRecordsSelected ? [] : selection.SelectedRecordIds;

    const flags = (result.reportFieldsOnly ? ReportDataExportFlags.ReportFieldsOnly : ReportDataExportFlags.None) |
      (result.excludeReadOnlyFields ? ReportDataExportFlags.ExcludeReadOnlyFields : ReportDataExportFlags.None);
    const selectedExportType = result.selectedExportType;

    const templatedReport = config.templatedReports
      .concat(config.templatedReportsForAppCombinedRecord)
      .find(r => r.Id === result.selectedTemplatedReportId);

    const filename = result.filename;
    let response = null;

    switch (selectedExportType) {
      case Enums.ExportType.templatedReportExport:
      case Enums.ExportType.templatedReportExportAsPdf:
      case Enums.ExportType.templatedReportExportCombinedRecord:
      case Enums.ExportType.templatedReportExportAsPdfCombinedRecord:
        const asPdf =
          selectedExportType ===
          Enums.ExportType.templatedReportExportAsPdf ||
          selectedExportType ===
          Enums.ExportType.templatedReportExportAsPdfCombinedRecord;
        const isAppCombinedRecordType =
          selectedExportType ===
          Enums.ExportType.templatedReportExportCombinedRecord ||
          selectedExportType ===
          Enums.ExportType.templatedReportExportAsPdfCombinedRecord;
        const subType = isAppCombinedRecordType
          ? Enums.TemplatedReportSubType.AppCombinedRecord
          : Enums.TemplatedReportSubType.App;
        response = await this.templatedReportsRepository
          .exportAppLevel(
            appIdentifier,
            reportIdentifier,
            templatedReport.Id,
            templatedReport.Title,
            templatedReport.IsSystemDefaultReport,
            ids,
            queryParams,
            asPdf,
            subType
          )
          .toPromise();
        break;

      case Enums.ExportType.csv:
        const excludedColumns = result.excludeReadOnlyFields
          ? this.appModel.appContext.app.value.AppFields
            .filter(f => f?.IsReadOnly)
            .map(f => f.Identifier)
          : [];

        response = await this.reportDataRepository
          .exportAppLevel(
            appIdentifier,
            reportId,
            ids,
            excludedColumns,
            flags,
            queryParams
          )
          .toPromise();
        break;
    }

    if (response?.ProcessId && filename?.length > 0) {
      await this.backgroundProcessStorageService.save(response.ProcessId, { filename });
    }
  }

  private trackDualReport(report: Report) {

    // Report is dual if id specified and represents a valid report
    const dual = report.DualReportIdentifier && this.appModel.app.value.Reports.find(r => r.Identifier === report.DualReportIdentifier);

    // Look up and set up dual report if it changes
    if (report.DualReportIdentifier !== this.dualReportModel$.value?.report.value?.Identifier) {
      if (dual) {
        const dualModel = this.modelFactory.createDualReportModel(dual, this.appModel, this.filterModel, this.pageModel);
        dualModel.report.value = dual;
        dualModel.foreground.value = false;
        dualModel.initialise();
        this.dualReportModel$.next(dualModel);
      } else {
        this.dualReportModel$.next(null);
        this.dualReportStyle.value = 'none';
      }

      this.isDual.value = !!dual;
    }

    this.trackZoomPane(this.globalModel.isSmallDisplay.value, !!dual);
  }

  private trackZoomPane(small: boolean, dual: boolean) {

    if (small && dual) {

      if (!this.zoomPane.value) {
        this.zoomPane.value = 'zoom-chart';
      }

      this.dualReportStyle.value = this.zoomPane.value;
    } else {
      this.zoomPane.value = null;

      const report = this.report.value;
      if (report) {
        const style = DualReportPositionEnum[report?.DualReportPosition]?.toLowerCase() || 'none';
        this.dualReportStyle.value = style;
      }
    }
  }

  public setZoomPane(pane: ReportPanelZoom) {
    this.zoomPane.value = pane;
    this.dualReportStyle.value = pane;
  }

  public toggleAutoLayout() {
    // nop, override for reports that implement auto layout
  }

  public filter() {
    const combined = this.filterModel.combinedFilter.value;
    const search = this.searchFilter.value;
    const mergedFilter = (search && combined) ? ReportFilter.merge([combined, search]) : (combined || search);

    if (mergedFilter) {
      mergedFilter.Group = this.filterModel.groupBy.value;
      mergedFilter.IsGroupDescending = this.filterModel.groupDescending.value;
    }

    return mergedFilter;
  }
}
