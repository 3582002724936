import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDeleteComponent {

  @Output() confirmDelete = new EventEmitter();
  @Output() cancelDelete = new EventEmitter();

  public confirm = () => { this.confirmDelete.emit(); };
  public cancel = () => { this.cancelDelete.emit(); };
}
