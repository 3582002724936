import { logError, Record, RecordAccessedStorageService, RecordId } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { IPerformContext } from 'app/types/fields/app-field';
import { AppModel } from '../app.model';
import { HierarchyModelProperty } from '../common/hierarchy-model-property';
import { RouteParams } from '../global.model';
import { IFocusableModel } from '../page/focusable-model.interface';
import { PageModel } from '../page/page.model';
import { HomeItem } from './home-item.interface';

export class AppHomeModel extends Model<AppHomeModel> implements IFocusableModel {

  /** Hierarchy string for child context, '' for top level */
  public readonly hierarchy = new HierarchyModelProperty(this, '');

  /** True if an error occured loading report data  */
  // This is currently unused, placed here to replace LoadingError state which isn't currently being set
  // See SOF-10552
  public readonly loadError = new BooleanModelProperty(this, false);

  public readonly items = new ArrayModelProperty<HomeItem>(this).withLogging('ITEMS');

  public readonly records = new ArrayModelProperty<Record>(this).withLogging('RECORDS');

  /** Current search results formatter for display in dropdown */
  public readonly searchOptions = new ArrayModelProperty<{ text: string, value: string }>(this).withLogging('SEARCH OPTS');

  public readonly searchPending = new BooleanModelProperty(this, false).withLogging('SEARCH PENDING');

  /** Toolbar actions for the main (ellipsis) menu */
  public readonly toolbarActions = new ModelProperty<Array<ToolbarAction>>(this).withLogging('TOOLS');

  @InjectService(RecordQueueService)
  private readonly queue: RecordQueueService;

  @InjectService(RecordAccessedStorageService)
  private recordAccessedStorageService: RecordAccessedStorageService;

  @InjectService(RecordPersistService)
  private _recordPersistService: RecordPersistService;

  public constructor(public appModel: AppModel, public pageModel: PageModel) {
    super();
  }

  /** Initialise the model.  Call after construction */
  public initialise() {
  }

  public async routed(params: RouteParams) {
    this.pageModel.focus.value = this;
    await this.configure(params.hierarchy);
  }

  public async configure(hierarchy: string) {
    this.hierarchy.value = hierarchy;

    const app = this.appModel.app.value;

    const actions: Array<ToolbarAction> = [];
    const performContext: IPerformContext = { value: null, record: null, appModel: this.appModel };
    app.addToolbarActions(actions, 'apphome', performContext);
    // app.getAppHomeToolbarActions(this.appModel, actions);
    this.toolbarActions.value = actions;

    const header = this.appModel.globalModel.header;

    header.batch(() => {

      const parts: Array<string> = [];
      const parentApp = this.appModel.appContext.parentApp.value;
      if (parentApp) {
        parts.push(parentApp.Title);
      }

      parts.push(this.appModel.app.value.Title);

      header.app.value = app;
      header.title.value = parts.join(' > ');
      header.showBackButton.value = !!hierarchy;

      this.subscribe(header.back.$, () => {
        this.goBack().catch(error => logError(error, 'apphome back'));
      });

      header.loading.value = false;
    });

    const items: Array<HomeItem> = [];

    const patches = this.queue.getPatchesForApp(app.Identifier);
    const creates = patches.filter(patch => patch._new && patch._id !== app.StaticRecordId).map(patch => patch._id);
    creates.forEach((id) => {
      items.push({ _id: id, title: null, kind: 'create', icon: 'plus-square' });
    });

    const updates = patches.filter(patch => !patch._new && patch._id !== app.StaticRecordId).map(patch => patch._id);
    updates.forEach((id) => {
      items.push({ _id: id, title: null, kind: 'change', icon: 'edit' });
    });

    const accessedRecords = await this.recordAccessedStorageService.get(app.Identifier);
    if (accessedRecords) {
      accessedRecords.forEach((item) => {
        // Add item unless we already have a create/update entry for it
        if (items.findIndex((existing) => existing._id === item._id) < 0) {
          items.push({ ...item, kind: 'recent', icon: 'square' });
        }
      });
    }

    const filtered: Array<HomeItem> = [];
    const records: Array<Record> = [];

    for (let i = 0; i < items.length; ++i) {
      try {
        const record: any = await this._recordPersistService.getModifiedRecord(app, items[i]._id);
        if (record && !record.IsArchived) {
          records.push(record);
          filtered.push(items[i]);
        }
      } catch (error) {
        logError(error, 'record error in prepareCardList.tap');
      }
    }

    this.records.value = records;
    this.items.value = filtered;
  }

  public async removeItem(recordId: RecordId) {

    const app = this.appModel.app.value;

    const recordsAccessed = await this.recordAccessedStorageService.get(app.Identifier);
    const recordAccessed = recordsAccessed?.find((r) => r._id === recordId);
    if (recordAccessed) {
      await this.recordAccessedStorageService.delete(app.Identifier);
      await this.recordAccessedStorageService.save(app.Identifier, recordsAccessed.filter((r) => r._id !== recordId));
    }

    const patch = this.queue.getPatch(recordId);
    if (patch) {
      await this.queue.delete(recordId);
    }

    // Clear from item list
    const index = this.items.value.findIndex(item => item._id === recordId);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  private async goBack() {
    if (this.hierarchy.value) {
      // Child app home, go to parent record
      const parentIds = this.appModel.appContext.appIdentifiers.value.pop();
      const parentRecordId = this.hierarchy.parentRecordId.value;
      await this.appModel.globalModel.navigation.navigateChildRecordAsync({
        appIdentifiers: parentIds,
        recordId: parentRecordId
      });
    }
  }
}
