import { Injectable, ElementRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InputChangedTrackService {
  private dirtyInputs = new Map<string, ElementRef>();

  public setInputDirty(el: ElementRef) {
    this.dirtyInputs.set(el.nativeElement.Id, el);
  }

  public triggerBlur() {
    this.dirtyInputs.forEach((element) => {
      element.nativeElement.blur();
    });

    this.dirtyInputs.clear();
  }
}
