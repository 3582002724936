import { Component, OnInit } from '@angular/core';
import { logError } from '@softools/softools-core';
import { FieldBase } from 'app/softoolsui.module/fields';
import { IAppExtension } from 'app/types/app-extension';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent extends FieldBase implements OnInit {

  /** If set, defines extension to call to handle file drop */
  public fileDroppedExtension: IAppExtension = null;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Allow app to change our behaviour
    this.application?.initialiseFieldComponent(this);
  }

  public draggedover(event: any) {
    event.preventDefault();
  }

  public dropped($event: DragEvent) {
    $event.preventDefault();

    const dataTransfer = $event.dataTransfer;
    if (dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < dataTransfer.items.length; i++) {
        if (dataTransfer.items[i].kind === 'file') {
          const f = dataTransfer.items[i].getAsFile();
          this.uploadFile(f);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < dataTransfer.files.length; i++) {
        this.uploadFile(dataTransfer.files[i]);
      }
    }
  }

  public fileSelected($event: any) {
    const files: FileList = $event.target.files;
    for (let i = 0; i < files.length; ++i) {
      this.uploadFile(files[i]);
    }
  }

  private uploadFile(file: File) {
    if (this.fileDroppedExtension) {
      // App has hooked drop function, call to process
      this.fileDroppedExtension.executeAsync(this.appModel, this.record, file).catch(error => {
        logError(error, 'fileDroppedExtension.executeAsync');
      });
    } else {
      // todo hook to add attachment here if we want to expose it
    }
  }
}
