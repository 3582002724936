import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { isDefined, logError, logMessage } from '@softools/softools-core';
import { TeamsService } from 'app/services/teams.service';

/**
 * Check whether teams have been loaded
 * This should normally succeed after being initailsed by an APP_INITIALIZER 
 */
@Injectable({ providedIn: 'root' })
export class TeamsGuard implements CanActivate {

  constructor(
    private teamsService: TeamsService,
  ) { }

  public async canActivate(): Promise<boolean> {

    try {
      const teams = await this.teamsService.getAllTeams();

      // Note - Sites can legally have no teams so array might be empty
      if (!isDefined(teams)) {
        logMessage('teams not initialised');
        return false;
      }

      return true;
    } catch (error) {
      logError(error, 'Check teams loaded');
      return false;
    }
  }
}
