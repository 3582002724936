import { Component, Input } from '@angular/core';
import { ModalMessageType, ModalOptions } from '@softools/softools-core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss', './modal.component.scss']
})
export class ModalHeaderComponent {

  public messageTypes = ModalMessageType;

  @Input() public modalOptions: ModalOptions;

  constructor() { }
}
