<ng-container *ngIf="isCentred">
  <ng-template
    *ngIf="isCentred"
    cdkGlobalOverlay
    [cdkOverlayOpen]="isOpen"
    cdkCenterHorizontally
    cdkCenterVertically
    [cdkOverlayHasBackdrop]="hasBackdrop"
    (attach)="onAttached()"
    (detach)="detached()"
    (backdropClick)="backdropClicked()"
  >
    <div
      [style.height.px]="maximizedHeight()"
      [style.width.px]="maximizedWidth()"
    >
      <ng-container [ngTemplateOutlet]="template"></ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="!isCentred">
  <ng-template
    *ngIf="!dock"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="connectedOrigin"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPositions]="connectedPositions"
    [cdkConnectedOverlayOffsetX]="offsetX"
    [cdkConnectedOverlayOffsetY]="offsetY"
    [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
    [cdkConnectedOverlayBackdropClass]="
      transparentBackdrop ? 'see-through' : ''
    "
    flexibleDimensions="true"
    (attach)="onAttached()"
    (detach)="detached()"
    (backdropClick)="backdropClicked()"
  >
    <div #popupContent>
      <ng-container [ngTemplateOutlet]="template"></ng-container>
    </div>
  </ng-template>

  <ng-template
    *ngIf="dock === 'right'"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="connectedOrigin"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPositions]="dockRightPosition"
    [cdkConnectedOverlayHeight]="connectedOrigin.elementRef.nativeElement.clientHeight"
    [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
    (attach)="onAttached()"
    (detach)="detached()"
    (backdropClick)="backdropClicked()"
  >
    <div #popupContent class="h-100 w-100">
      <ng-container [ngTemplateOutlet]="template"></ng-container>
    </div>
  </ng-template>

  <ng-container *ngIf="dock === 'left'"> </ng-container>
</ng-container>
