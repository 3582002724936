<div *ngIf="errorType.length === 0">
  <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'cookieError' }"></ng-container>
</div>
<div *ngIf="errorType === 'hostname'">
    <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'hostnameError' }"></ng-container>
</div>
<div *ngIf="errorType === 'reportidentifiererror'">
    <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'reportIdentifierError' }"></ng-container>
</div>
<div *ngIf="errorType === 'homepages'">
  <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'homepagesError' }"></ng-container>
</div>
<div *ngIf="errorType === 'users'">
  <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'usersError' }"></ng-container>
</div>
<div *ngIf="errorType === 'badInvitationKey'">
  <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'badInvitationKey' }"></ng-container>
</div>
<div *ngIf="errorType === 'indexedDb'">
  <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'indexedDb' }"></ng-container>
</div>
<div *ngIf="errorType === 'signupWhenLoggedIn'">
  <ng-container *ngTemplateOutlet="errorMessage; context: {type: 'signupWhenLoggedIn' }"></ng-container>
</div>

<ng-template #errorMessage let-type="type">
  <div class="container text-center mt-5 message-block display-4 w-100 d-flex justify-content-center align-items-center">
    <fa-icon class="m-2" [icon]="['fal', 'exclamation-circle']"></fa-icon>
    <h1 class="display-4">
      <span *ngIf="type === 'cookieError'" i18n>Please enable cookies for this site to work </span> 
      <span *ngIf="type === 'hostnameError'" i18n>Invalid hostname </span>
      <span *ngIf="type === 'reportIdentifierError'" i18n>Invalid report identifier </span> 
      <span *ngIf="type === 'homepagesError'" i18n>Invalid homepages </span> 
      <span *ngIf="type === 'usersError'" i18n>Invalid users </span> 
      <span *ngIf="type === 'badInvitationKey'" i18n>This link has expired and cannot be used. This may mean you have already actioned the request or cancelled it. </span> 
      <span *ngIf="type === 'indexedDb'" i18n>Browser storage error</span> 
      <span *ngIf="type === 'signupWhenLoggedIn'" i18n>Sign up</span> 
    </h1>
  </div>

  <div class="p-3 text-center" *ngIf="detail">
    <span>{{detail}}</span>
  </div>

  <div class="p-3 text-center" *ngIf="type === 'signupWhenLoggedIn'">
    <span i18n>You must log out from Softools before signing up to a new account.</span>
  </div>

  <div class="p-3 text-center" i18n>
    Please contact your system administrator.
  </div>

</ng-template>