
import { Injectable, Injector } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

@Injectable()
export class BaseGuard {

  constructor(private _router: Router, private injector: Injector) { }

  public getErrorUrlTree(type: string = null): UrlTree {
    const errorUrlTree = this._router.parseUrl('/Error');
    if (type) {
      errorUrlTree.queryParams = { type };
    }
    return errorUrlTree;
  }

  // copied from AppsExistsGuard - should make common

  /**
   * Run child guards that are dependent on this guard completing.
   * See https://github.com/SofTools/Workspace-App/wiki/Guard-Dependencies
   *
   * @param name    This guard name.  Don't look up from type as this changes when minified.
   * @param route   Navigation route
   * @param state   Navigation state
   */
  protected async runChildGuards(name: string, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const childGuards = route.data['childGuards'] && route.data['childGuards'][name];
    if (childGuards) {
      const promises: Array<Promise<any>> = [];

      for (let index = 0, length = childGuards.length; index < length; index++) {
        const guardToken = childGuards[index];
        const other: CanActivate = this.injector.get(guardToken);
        promises.push(other.canActivate(route, state) as any);
      }

      const results = await Promise.all(promises);
      const rejection = results.find(r => r !== true);
      return rejection;
    }
  }
}
