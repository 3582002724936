import * as moment from 'moment';
import { Failure, Record, ValidationError } from '@softools/softools-core';
import { AppField } from './app-field';

/**
 * Time field represents a time of day.
 *
 * Value is number of seconds since midnight; the value for display
 * or entry is a time string (some parts of "HH:MM:SS.mmmmm AM|PM").
 * The representation may use 12 or 24 hour clock depdening on the
 * @see TimeOptions.use12hourClock setting
 */
export class TimeAppField extends AppField {

  public override adaptValue(value: any, _current: any, _record?: Record, source?: 'input' | 'server'): any {

    // Check no value as this will look like 0 below
    if (value === '' || value === null /*|| value === undefined*/) {
      return value;
    }

    // A number is number of seconds if from the server
    // If entered into field it's a shorthand, so 1234 means 12:34
    if (Number.isSafeInteger(+value)) {
      if (source === 'input') {
        return this.convertShorthandNumberToSeconds(+value);
      } else {
        return +value;
      }
    }

    if (typeof (value) === 'string') {
      value = this.adaptStringValue(value);
    }

    return value;
  }

  public override adaptStringValue(value: string): any {
    if (isValidTime(value)) {
      return parseTime(value);
    }

    return super.adaptStringValue(value);
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    super.validate(value, errors, listRow);

    if (value !== null && value !== undefined) {
      if (typeof (value) === 'string') {
        if (!isValidTime(value)) {
          errors.push({ error: ValidationError.InvalidTime, fieldId: this.Identifier, listRow });
        }
      } else {
        const seconds = +value;
        if (!Number.isSafeInteger(seconds) || seconds < 0 || seconds > 86400) {
          errors.push({ error: ValidationError.InvalidTime, fieldId: this.Identifier, listRow });
        }
      }
    }
  }

  public override getDisplayRecordValue(record: Record, listRow?: number) {
    const value = super.getRawRecordValue(record, listRow);
    return this.formatDisplayValue(value);
  }

  /** Convert a field value into a display string */
  public override formatDisplayValue(value: any) {

    // Any nothingish value is an empty string
    if (value === null || value === undefined || value === '' || Number.isNaN(value)) {
      return '';
    }

    // Convert number of seconds to time string
    if (Number.isSafeInteger(value)) {
      // Use a temp moment set to requested time today to format time
      const dateTime = moment.utc().hours(0).minutes(0).seconds(0).milliseconds(0).seconds(value);
      const use24hourClock = !this.TimeOptions?.use12hourClock;
      return dateTime.format(use24hourClock ? 'HH:mm' : 'hh:mm A');
    }

    return value;
  }

  public override compareValues(val1: number, val2: number, isDescending: boolean): number {
    const ms1: number = val1 || 0;
    const ms2: number = val2 || 0;
    return isDescending ? ms2 - ms1 : ms1 - ms2;
  }

  /**
   * Convert a time entered without : characters into seconds
   */
  private convertShorthandNumberToSeconds(num: number): number {

    if (num > 0 && num <= 99) {
      // 1/2 digit time, treat as (H)H
      if (num <= 23) {
        return (num * 60 * 60);
      }
    } else if (num > 99 && num <= 9999) {
      // 3/4 digit time, treat as (H)HMM
      const hours = Math.floor(num / 100);
      const mins = num % 100;
      if (hours <= 23 && mins <= 60) {
        return (hours * 60 * 60) + (mins * 60);
      }

    } else if (num > 9999 && num <= 999999) {
      // 5/6 digit time, treat as (H)HMMSS
      const hours = Math.floor(num / 10000);
      const mins = Math.floor(num / 100) % 100;
      const secs = num % 100;
      if (hours <= 23 && mins <= 60 && secs <= 60) {
        return (hours * 60 * 60) + (mins * 60) + secs;
      }
    }

    // invalid
    return NaN;
  }

  public override constantForFilter(value: any) {
    return `${value}`;
  }
}

export function parseTime(value: string): number {

  if (value === null || value === undefined || value === '') {
    return null;
  }

  // convert to seconds
  const [time, suffix] = value.split(' ', 2);
  const [hours, minutes] = time.split(':');
  const duration = moment.duration({
    // previous code was hours: hours === '12' ? 0 : +hours
    // may need to restore that when we put 12 hour clocks support back
    hours: +hours,
    minutes: +minutes
  });

  if (suffix === 'PM') {
    duration.add(12, 'h');
  }

  const seconds = duration.asSeconds();
  return seconds;
}

/** Checks whether the value is a valid time input value */
export function isValidTime(value: any) {

  // variants on no-value are acceptable (required checked elsewhere)
  if (value === null || value === undefined || value === '') {
    return true;
  }

  // Accept numbers - need to split so not during input validation - todo refine
  if (!Number.isNaN(+value)) {
    if (86000 > +value && +value >= 0) {
      return true;
    }
  }

  if (typeof (value) === 'string') {
    const match = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(value);
    return match;
  }

  return false;
}
