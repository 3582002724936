import { Model } from './model';
import { ModelProperty } from './model-property';

export class ArrayModelProperty<T> extends ModelProperty<Array<T>> {

  constructor(container: Model<any> = null) {
    super(container, new Array<T>());
  }

  public get length() {
    return this.value?.length;
  }

  public push(...items: T[]) {
    const length = this.value.push(...items);
    // Array is modified in place so fire changed
    this.changed();
    return length;
  }

  public unshift(...items: T[]) {
    const length = this.value.unshift(...items);
    // Array is modified in place so fire changed
    this.changed();
    return length;
  }

  /** Perform an array splice on the cuurent value  */
  public splice(start: number, deleteCount?: number) {
    const deleted = this.value.splice(start, deleteCount);
    // Array is modified in place so clone to see
    if (deleted?.length > 0) {
      this.value = [...this.value];
    }
    return deleted;
  }

  /** Perform the specified action for each element in an array. */
  public forEach(callback: (value: T, index: number, array: T[]) => void) {
    this.value?.forEach(callback);
  }
}
