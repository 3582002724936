<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <div class="cardv2-header">
      <div>{{ displayLabel }}</div>
    </div>

    <mat-dialog-content>
      <div class="pb-4 list-group list-group-flush">
        <ng-container *ngIf="!(onlineService.isServerReachable$ | async)">This feature is only available online</ng-container>
        <ng-container *ngIf="!recordId">Notes can only be added once the record is created</ng-container>

        <ng-container *ngIf="onlineService.isServerReachable$ | async">
          <sof-spinner-icon *ngIf="loading"></sof-spinner-icon>
          <div *ngFor="let note of notesData" class="list-group-item mb-2 rounded border note">
            <div class="note-date small mb-2">{{ note.Date | date: 'medium' }}</div>
            <div>{{ note.Text }}</div>
          </div>
          <div *ngIf="!loading && notesData && notesData.length === 0" class="card-body">
            <div><span i18n>No Notes Found</span></div>
          </div>
          <ng-container *ngIf="addingNote">
            <div class="card-body">
              <div>
                <mat-form-field floatLabel="always" class="w-100">
                  <textarea
                    matInput
                    [(ngModel)]="newNoteText"
                    rows="3"
                    id="noteText"
                    name="noteText"
                    #noteText
                    (click)="$event.stopPropagation()"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </mat-dialog-content>
    <div class="cardv2-footer">
      <button *ngIf="addingNote && recordId" (click)="SaveNew()" tabindex="-1" class="primary" i18n>Save Note</button>
      <button *ngIf="addingNote && recordId" (click)="CancelNew()" tabindex="-1" class="primary-line" i18n>Cancel Note</button>
      <button
        i18n
        *ngIf="!addingNote && editable && (onlineService.isServerReachable$ | async) && recordId"
        (click)="AddNew()"
        tabindex="-1"
        class="primary"
      >
        Add New Note
      </button>
      <button *ngIf="!addingNote" (click)="Close()" tabindex="-1" class="close" i18n>Close</button>
    </div>
  </div>
</div>
