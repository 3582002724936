import { QueryParams, Asset } from '../types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlResolver } from '../utils/utils.urlresolver';
import { StandardRepository } from './standard-repository';
import { MulticastHttpService } from './multicast-http.service';

@Injectable({ providedIn: 'root' })
export class AssetRepository extends StandardRepository<Asset> {
  public controller = 'Assets';

  constructor(urlResolver: UrlResolver, http: HttpClient, multicastHttpService: MulticastHttpService) {
    super(urlResolver, http, multicastHttpService);
  }

  public async getAssetsAsync(count: number, skip: number, queryParams: QueryParams) {
    return this.getItemsAsync(count, skip, queryParams);
  }

  public async getAssetAsync(id: string) {
    return this.getItemAsync(id);
  }

  public createAssetAsync(file: File) {
    const url = this.resolveUrl('Create');
    const result = this.toBase64(file).then((data) => {
      const createBody = {
        Filename: file.name,
        Data: data,
      };

      return this.post(url, createBody).toPromise();
    });
    return result;
  }

  private toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    })
}
