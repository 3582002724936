import { Record, logError, SelectionListChange, TrackChange, TrackChangeList, TrackedRow } from '@softools/softools-core';
import { RECORD_VALID } from 'app/_constants';
import { Application } from 'app/types/application';

export interface LegacyRecordPatch {
  _id: string;
  appIdentifier: string;
  hierarchy?: any;
  fieldIsValid?: boolean;
  newRecord?: boolean;
  changes: {};
  localChanges: {};
  trackedChanges: Map<string, TrackChange>;
  localTrackedChanges: Map<string, TrackChange>;
}

/** Record of changes made to a record */
export class RecordPatch {

  // Bag of changed values keyed by field id
  changes = {};

  // Bag of changed values keyed by field id that should be applied to the
  // local record but not sent to the server
  localChanges = {};

  // Bag of tracked changes keyed by field id
  trackedChanges = new Map<string, TrackChange>();

  /** Bag of tracked changes keyed by field id that should be applied to the
      local record but not sent to the server  */
  localTrackedChanges = new Map<string, TrackChange>();

  /** If set and true, indicates a new record (not succesfully sent to server) */
  public _new?: boolean;

  public static copy(src: RecordPatch, localChangesOnly = false): RecordPatch {
    if (src) {
      try {
        const clone = new RecordPatch(src._id, src.AppIdentifier, src.Hierarchy, src.fieldIsValid);
        clone[RECORD_VALID] = src[RECORD_VALID];

        if (src._new) {
          clone._new = src._new;
        }

        if (!localChangesOnly) {
          clone.changes = src.changes;

          if (src.trackedChanges) {
            src.trackedChanges.forEach((value, key) => {
              if ('changes' in value) {
              clone.trackedChanges.set(key, TrackChangeList.copy(value));
              } else {
                clone.trackedChanges.set(key, SelectionListChange.copy(value));
              }
            });
          }
        }

        clone.localChanges = src.localChanges;

        if (src.localTrackedChanges) {
          src.localTrackedChanges.forEach((value, key) => {
            if ('changes' in value) {
              clone.localTrackedChanges.set(key, TrackChangeList.copy(value));
            } else {
              clone.localTrackedChanges.set(key, SelectionListChange.copy(value));
            }
          });
        }

        return clone;
      } catch (ex) {
        logError(ex, 'Error RecordPatch copy');
      }
    }
    return null;
  }

  constructor(
    public _id: string,   // record id
    public AppIdentifier: string,
    public Hierarchy?: any,
    public fieldIsValid?: boolean,
  ) { }

  public setValid(): RecordPatch {
    // this[RECORD_VALID] = valid;
    return this;
  }

  public addChange(fieldId: string, value: any): RecordPatch {
    this.changes[fieldId] = value;
    return this;
  }

  public getChange(fieldId: string): any {
    return this.changes[fieldId];
  }

  public addLocalChange(fieldId: string, value: any): RecordPatch {
    this.localChanges[fieldId] = value;
    return this;
  }

  public addLocalChanges(values: any): RecordPatch {
    this.localChanges = { ...this.localChanges, ...values };
    return this;
  }

  public addTrackedChange(fieldId: string, changes: TrackChange): RecordPatch {
    try {
      this.trackedChanges.set(fieldId, changes);
      return this;
    } catch (ex) {
      logError(ex, '');
      throw ex;
    }
  }

  public addLocalTrackedChange(fieldId: string, changes: TrackChange): RecordPatch {
    try {
      this.localTrackedChanges.set(fieldId, changes);
      return this;
    } catch (ex) {
      logError(ex, '');
      throw ex;
    }
  }

  public get hasPersistableChanges(): boolean {
    return (Object.getOwnPropertyNames(this.changes)?.length > 0 || this.trackedChanges?.size > 0);
  }

  /**
   * Remove local changes where they have been replaced by a persistent change.
   * This is a destructive change.
   */
  public removeSupercededLocalChanges() {
    Object.getOwnPropertyNames(this.changes).forEach(name => delete this.localChanges[name]);

    this.trackedChanges.forEach((list, fieldName) => {
      const local = this.localTrackedChanges.get(fieldName);
      if (local) {
        if (list instanceof TrackChangeList) {
          // for each key in tracked changes
          Object.getOwnPropertyNames(list.changes).forEach(rowKey => {
            const row = list.changes[rowKey];
            const localRow = (<TrackChangeList>local).changes[rowKey];
            if (row && localRow) {
              Object.getOwnPropertyNames(row).forEach(name => delete localRow[name]);
            }
          });
        } else {
          local.added = (<SelectionListChange>local).added.filter(localAdded => !list.added.includes(localAdded));
          local.removed = (<SelectionListChange>local).removed.filter(localRemoved => !list.removed.includes(localRemoved));
        }
      }
    });
  }

  /** Get an object containing the full set of changes including tracked changes, suitable for sending as a PATCH request */
  public getDelta(flattenTracked = false): any {
    try {
      const bag = { ...this.changes };

      if (flattenTracked) {

        this.trackedChanges.forEach((tracked: TrackChange, key) => {
          const added = [];
          tracked.added.forEach((row: TrackedRow) => { added.push(row); });
          // todo changed, removed
          bag[key] = added;
        });

      } else {
        this.trackedChanges.forEach((change, key) => {
          bag[key] = change;
        });
      }

      return bag;
    } catch (ex) {
      logError(ex, '');
      throw ex;
    }
  }

  /** Merge the changes in another record patch with this one */
  public merge(other: RecordPatch) {
    try {
      // Copy simple changes from other patch
      this.changes = { ...this.changes, ...other.changes };
      this.localChanges = { ...this.localChanges, ...other.localChanges };

      if (other._new) {
        this._new = true;
      }

      other.trackedChanges.forEach((change, key) => {
        const thisChange = this.trackedChanges.get(key);
        if (thisChange) {
          thisChange.merge(change);
        } else if (change instanceof TrackChangeList || change instanceof SelectionListChange) {
          this.trackedChanges.set(key, change);
        } else {
          if ('changes' in change) {
            this.trackedChanges.set(key, TrackChangeList.copy(change));
          } else {
            this.trackedChanges.set(key, SelectionListChange.copy(change));
          }
        }
      });

      other.localTrackedChanges.forEach((change, key) => {
        const thisChange = this.localTrackedChanges.get(key);
        if (thisChange) {
          thisChange.merge(change);
        } else if (change instanceof TrackChangeList || change instanceof SelectionListChange) {
          this.localTrackedChanges.set(key, change);
        } else {
          if ('changes' in change) {
            this.localTrackedChanges.set(key, TrackChangeList.copy(change));
          } else {
            this.localTrackedChanges.set(key, SelectionListChange.copy(change));
          }
        }
      });


      this[RECORD_VALID] = other[RECORD_VALID];
    } catch (ex) {
      logError(ex, '');
      throw ex;
    }
  }

  /**
   * Apply patch changes to a record
   * @param record  Record to update
   * @param app     If specified the, performs the update
   * @param clone   If true update and return a cloned record, otherwise update in place
   */
  public updateRecord(record: Record, app: Application, clone = false): Record {
    if (record._id !== this._id) {
      throw new Error(`Record patch ${this._id} is trying to update to the incorrect record ${record._id}`);
    }

    if (clone) {
      record = app.cloneRecord(record);
    }

    return this.updateObject(record, app);
  }

  /**
   * Apply patch changes to an object of any type
   * @param record  Record to update
   * @param app     application being updated
   */
  public updateObject(obj: any, app: Application): Record {
    try {
      // Apply changes
      Object.getOwnPropertyNames(this.changes).forEach(name => {
        try {
          app.updateRecord(obj, name, this.changes[name]);
        } catch (error) {
          logError(error, `updateObject changes[${name}] => ${this.changes[name]} `);
        }
      });

      // Apply local changes
      Object.getOwnPropertyNames(this.localChanges).forEach(name => {
        try {
          app.updateRecord(obj, name, this.localChanges[name]);
        } catch (error) {
          logError(error, `updateObject localchanges[${name}] => ${this.changes[name]} `);
        }
      });

      // Apply tracked changes
      this.trackedChanges.forEach((change, key) => {
        try {
          app.updateRecord(obj, key, change);
          // obj[key] = change.update(obj[key]);
        } catch (error) {
          logError(error, `updateObject tracked[${obj[key]}]`);
        }
      });

      return obj;
    } catch (ex) {
      logError(ex, 'updateObject');
      throw ex;
    }
  }

  public toRecord(app: Application): Record {

    const record: Record = {
      _id: this._id,
      AppIdentifier: this.AppIdentifier,
      Hierarchy: this.Hierarchy,
      IsArchived: false,
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: null,
      UpdatedDate: null,
      UpdatedByUser: null,
      QuickFilterSearchText: ''
    };

    this.updateRecord(record, app);

    if (this._new) {
      (<any>record)._new = true;
    }

    return record;
  }
}

