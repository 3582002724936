import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation } from 'app/_constants';
import { WSReportBase } from 'app/workspace.module/components/ws-base/ws-reportbase';
import { UrlResolver, OnlineStatusService, logError, ReportClickThroughInfo } from '@softools/softools-core';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { ChartReportModel, RouteParams } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { filterMgtAnimation, quickFiltersAnimation } from 'app/workspace.module/animations/ws-animations';

@Component({
  selector: 'app-ws-chartreport',
  templateUrl: './ws-chartreport.component.html',
  styleUrls: ['./ws-chartreport.component.scss'],
  animations: [
    contentPinnedStateAnimation,
    rightbarToggleContentStateAnimation,
    leftAsideStateAnimation,
    quickFiltersAnimation,
    filterMgtAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSChartReportComponent extends WSReportBase implements OnInit, OnDestroy {

  public chartReportModel: ChartReportModel;

  constructor(
    router: Router,
    indexedDbService: IndexedDbService,
    urlresolver: UrlResolver,
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    public override appService: AppService,
    navigationService: NavigationService,
    permissionsService: PermissionsService,
    models: GlobalModelService,
  ) {
    super(indexedDbService, appService, router, urlresolver, onlineStatus, route, navigationService, permissionsService, models);
  }

  public override ngOnInit() {
    super.ngOnInit();
  }

  protected override async routed(params: RouteParams) {
    await super.routed(params);

    this.processRouteParams();

    // Model reload not being seen
    await this.refresh();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onGoogleChartClickthroughHandler(payload: string) {
    this.router.navigateByUrl(payload).catch(error => logError(error, 'Failed to navigate'));
  }

  protected override createReportModel() {
    this.chartReportModel = new ChartReportModel(this.appModel, this.filterModel, this.models.pageModel);
    return this.chartReportModel;
  }

}
