import { App, IndexedAppData, PermissionEnums, QueryParameters, QueryParams } from '@softools/softools-core';
import { Application, IGetIndexOptions } from 'app/types/application';
import { InjectService, LocatorService } from 'app/services/locator.service';
import { PermissionsService } from 'app/services/permissions.service';
import { DataIndexService2 as DataIndexService } from 'app/services/indexes/data-index-service';
import { ReportFilter } from 'app/filters/types';
import { OnlineDataIndex } from 'app/services/indexes/online-data-index';
import { OnlineEmbeddedIndex } from './indexing/online-embedded-index';
import { patchApp } from 'app/types/app-tweaks';

/**
 * Describes an embedded application (a Softools app that is based on code and metadata rather than
 * the usual zero-code metadata).
 */
export abstract class EmbeddedApplication<T = any> extends Application<T> {

  public override IsEmbedded = true;

  // Assume all embedded apps are settings apps unless/until we define more
  public override IsSettingsApp = true;

  protected constructor(app: App) {
    // Apply app tweaks - useful where JSON doesn't like typed fields
    patchApp('*', app);
    super(app);
  }

  public get permissions(): Array<number> {
    if (this.IsSettingsApp) {
      // Override to check specific permissions
      return [PermissionEnums.Settings.All];
    } else {
      // No permission required unless overridden
      return [];
    }
  }

  public override get visible() {
    const permissionService = LocatorService.get(PermissionsService);
    return permissionService.hasPermission(...this.permissions);
  }

  /**
   * Create a filter to perform a search.
   * If any of the fields of the embedded app have the IncludeInSearch
   * flag set to true, a filter is built for them. Otherwise null is
   * returned to indicate search should not be performed.
   * @param term    Search string
   * @returns       A configured filter or null to skip search
   */
  public override createSearchFilter(term: string): ReportFilter {
    const santisedTerm = term.replace(/\'/g, '\'\'');
    const expressions = this.AppFields.filter(field => field.IncludeInSearch)
      .map(field => `substringof('${santisedTerm}',${field.Identifier})`);

    if (expressions.length > 0) {
      const searchFilter = new ReportFilter();
      searchFilter.Filter = expressions.join(' or ');
      return searchFilter;
    }

    return null;
  }
}

/**
 * An embedded app that uses an online model for its data
 */
export abstract class OnlineEmbeddedApp<T = any> extends EmbeddedApplication<T> {

  public override get isOfflineApp(): boolean {
    return false;
  }

  public override async getIndex(reportIdentifier: string, options: IGetIndexOptions): Promise<IndexedAppData> {
    const filters: Array<ReportFilter> = [];

    if (options.filter) {
      filters.push(options.filter);
    }

    if (options.searchTerm) {
      filters.push(this.createSearchFilter(options.searchTerm));
    }

    const query = filters.length > 0 ?
      ReportFilter.merge(filters).QueryParameters :
      new QueryParameters(null);

    const index = new OnlineEmbeddedIndex(this, reportIdentifier, query, options);
    await index.indexAll();
    return index;
  }
}

/**
 * An embedded app that uses an offline model for its data
 */
export abstract class OfflineEmbeddedApp<T = any> extends EmbeddedApplication<T> {

  @InjectService(DataIndexService)
  private readonly dataIndexService: DataIndexService;

  public override get isOfflineApp(): boolean {
    return true;
  }

  public override async getIndex(reportIdentifier: string, options: IGetIndexOptions) {

    const query = options.filter ? options.filter.QueryParameters : new QueryParameters(null);

    const index = await this.dataIndexService.getIndex(this, query, null, options?.hierarchy);
    return index;
  }
}
