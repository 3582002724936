import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { logError, LookupCaptionStyle, LookupOptions, Record, Report } from '@softools/softools-core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { ILookupDialog, lookupServiceToken } from 'app/softoolsui.module/lookup-dialog-service/lookup-dialog.interface';
import { Application } from 'app/types/application';
interface ReferenceItem {
  Text: string;
  Id: string;
}

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss', '../input.scss', '../selection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceComponent extends EditableFieldBase<string> implements OnInit {

  @Input() public iconName: IconName = 'caret-down';

  /** Optional app identifier, overrides specification in field config */
  @Input() public appIdentifier: string;

  /** Optional report identifier, overrides specification in field config */
  @Input() public reportIdentifier: string;

  /** Optional title field identifier, overrides specification in field config */
  @Input() public titleField: string;

  private targetApp: Application;

  public options: Array<ReferenceItem> = [];

  public overlayOpen = false;

  public selected: ReferenceItem;

  public lookupReport: Report;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    @Inject(lookupServiceToken) private dialog: ILookupDialog
  ) {
    super();
  }

  override ngOnInit(): void {

    try {
      super.ngOnInit();
      const appIdentifier = this.appIdentifier || this.fieldModel.ReferenceOptions.TargetAppIdentifier;
      const reportIdentifier = this.reportIdentifier || this.fieldModel.ReferenceOptions.ReportIdentifier;
      this.targetApp = this.appService.application(appIdentifier);
      if (this.targetApp && reportIdentifier) {
        this.lookupReport = this.appService.getReport(appIdentifier, reportIdentifier);
      }

      if (this.record) {
        this.setValueFromRecord(this.record);
      } else {
        this.loadRecord(this.value);
      }

    } catch (error) {
      logError('ReferenceComponent.ngOnInit', error);
    }
  }

  protected override onValueChanged(value: any) {
    super.onValueChanged(value);
    if (value?.val && value?.txt) {
      // We've been passed a reference item
      this.value = value.val;
      this.selected = {
        Id: value.val,
        Text: value.txt
      };
    } else {
      this.loadRecord(value as string).catch(error => logError(error, 'Failed to load record'));
    }
  }

  public async loadRecord(id: string) {
    if (id) {
      const record = await this.targetApp?.getRecordByIdAsync(id);
      this.setSelectionFromRecord(record);
    } else {
      this.setSelectionFromRecord(null);
    }
  }

  public async openMenu($event: MouseEvent) {

    try {
      $event.stopPropagation();

      if (this.lookupReport) {

        const options: LookupOptions = {
          appIdentifier: this.targetApp.Identifier,
          reportIdentifier: this.lookupReport.Identifier,
          report: this.lookupReport,
          searchLookupAppField: '',
          searchValue: '',
          multiSelect: false,
          selectedIds: [],
          uiState: null,
          captionStyle: LookupCaptionStyle.SelectWithAppRecordName,
        };

        const selection = await this.dialog.lookup(options);
        if (selection?.ids?.size === 1) {
          const id = Array.from(selection.ids)[0];
          await this.loadRecord(id);
          // Just patch the id for now as we don't have a real reference field
          // Should be { val: _id, txt: display-value}
          // and do the secondary fields too
          await this.updateValueAsync(id);
        }
      }
    } catch (error) {
      logError(error, 'ref openMenu');
    }
  }

  public displayText() {
    return this.selected?.Text || '';
  }

  onSelection(newValue: string) {
    this.input.nativeElement.value = newValue;
    this.overlayOpen = false;
    this.dispatchChangeAsync(newValue).catch(e => logError(e, 'Failed to dispatch change'));
  }

  private setSelectionFromRecord(record: Record) {
    if (record) {
      const titleField = this.titleField || this.targetApp.AppFields.find(f => f.IsTitleField)?.Identifier || '_id';
      this.value = record._id;
      this.selected = { Text: record[titleField], Id: record._id };
    } else {
      this.value = null;
      this.selected = null;
    }

    this.refresh();
  }
}
