import { Component, OnInit, OnDestroy } from '@angular/core';
import { CookiesService } from '../../services/cookies.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { getHomepageUrl } from 'app/_constants/constants.route';
import { logError } from '@softools/softools-core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  public errorType = '';

  public detail: string;

  private routerQuerySub: Subscription;

  constructor(
    private cookiesService: CookiesService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.routerQuerySub = this.route.queryParams.subscribe((qp) => {

      if (qp['type']) {
        this.errorType = qp['type'];
      }

      this.detail = qp['detail'] ?? '';

      // hack, this should work off the error type
      if (!qp['type']) {
        if (this.cookiesService.IsCompatible) {
          this.router.navigate([getHomepageUrl()]).catch(error => logError(error, 'Failed to navigate'));
        }
      }
    });

  }

  ngOnDestroy() {
    if (this.routerQuerySub) {
      this.routerQuerySub.unsubscribe();
    }
  }
}
