import { Injectable } from '@angular/core';
import { ReportClickThroughInfo, Enums, QueryParams, AppsService, logError, isDefined } from '@softools/softools-core';
import { ChartEnums } from '.';
import * as moment from 'moment';
import { EpochConverter } from 'app/types/epoch-converter';
import { NavigationService } from '../navigation.service';
import { AppIdentifiers } from '../record/app-info';
import { extractDecimal, isNumberField } from 'app/_constants';
import { MatrixTableData } from 'app/types/matrix/matrix-table-data';
import { ChartReportModel } from 'app/mvc';
import { HighChartConfig } from './chart-config.service';

export interface ReportClickThroughParams {
  appIdentifiers: AppIdentifiers;
  targetClickThroughReportIdentifier: string;
  xFieldIdentifier?: string;
  xFieldValue?: any;
  xFieldType?: any;
  yFieldIdentifier?: string;
  yFieldValue?: any;
  yFieldType?: any;
  labelsFieldName?: string;
  labelsFieldValue?: any;
  labelsFieldType: any;
  pieLevelName?: Array<string>;
  pieLevelValue?: Array<any>;
  pieLevelType?: Array<any>;
  geographyFieldName?: string;
  geographyValue?: any;
  geographyFieldType?: any;
  columnFieldIdentifier?: string;
  columnFieldValue?: any;
  columnFieldType?: number;
  cumulativeFields?: Array<{ cumulativeFieldIdentifier: string, cumulativeFieldValue?: any, cumulativeCalculationType: number, cumulativeFieldType: number }>;
  networkFields?: Array<{ fieldIdentifier: string, fieldType: number, fieldValue?: any }>;
}

@Injectable({
  providedIn: 'root'
})
export class ChartClickthroughService {

  constructor(
    private _appsService: AppsService,
    private navigationService: NavigationService,
  ) { }


  public getMatrixClickThroughInfo = async (
    matrixTableData: MatrixTableData,
    appIdentifiers: AppIdentifiers,
    recordId: string,
    params: any,
    hierarchy: string
  ): Promise<ReportClickThroughInfo> => {
    const reportIdentifier = matrixTableData.TargetClickThroughReportIdentifier;

    // We either need a recordId or axis info with a target click through report in order to process a click through
    if (recordId || (reportIdentifier && (params.xFieldIdentifier || params.yFieldIdentifier))) {
      const reportClickThroughInfo = await this.getReportClickThroughInfo(appIdentifiers, reportIdentifier, recordId, params, hierarchy);
      if (reportClickThroughInfo == null) { return null; }
      return reportClickThroughInfo;
    }

    return null;
  }

  public setupCumulativeClickthrough(config: HighChartConfig, data: any, model: ChartReportModel) {
    const cumulativeClickthrough = async (e: any) => {
      const point = e.point;
      const params = config.plotOptions.clickEventParams;

      if (params && params.appIdentifiers && point.options) {
        if (!isNumberField(params.columnFieldType)) {
          params.columnFieldValue = point.series.xAxis.categories[point.x];
        } else {
          params.columnFieldValue = point.x;
        }
        const chartIndex = point.series.chart.series.findIndex(chartSeries => {
          return chartSeries.yData === point.series.yData;
        });

        const seriesLabel = config.series[chartIndex].name;

        const matrixIndex = data.MatrixTableData.Rows.findIndex((row) => {
          return row.Label === seriesLabel;
        });

        params.cumulativeFields[matrixIndex].cumulativeFieldValue = point.y;

          const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, params.targetClickThroughReportIdentifier, null, params, config.hierarchy);
          if (reportClickThroughInfo == null) { return null; }
          if (reportClickThroughInfo.DestinationUrl.length > 0) {
            model?.reportClickThroughAsync(reportClickThroughInfo)
              .catch(error => logError(error, 'reportClickThrough'));
          }
      }
    };
    if (config.plotOptions.scatter) {
      config.plotOptions.scatter.point = { events: { click: cumulativeClickthrough } };
    } else {
      config.plotOptions.scatter = { point: { events: { click: cumulativeClickthrough } } };
    }

    if (config.plotOptions.line) {
      config.plotOptions.line.point = { events: { click: cumulativeClickthrough } };
    } else {
      config.plotOptions.line = { point: { events: { click: cumulativeClickthrough } } };
    }

    if (config.plotOptions.spline) {
      config.plotOptions.spline.point = { events: { click: cumulativeClickthrough } };
    } else {
      config.plotOptions.spline = { point: { events: { click: cumulativeClickthrough } } };
    }

    if (config.plotOptions.column) {
      config.plotOptions.column.point = { events: { click: cumulativeClickthrough } };
    } else {
      config.plotOptions.column = { point: { events: { click: cumulativeClickthrough } } };
    }
  }

  public setupScatterClickthrough(config: HighChartConfig, model: ChartReportModel) {
    config.plotOptions.scatter = {
      point: {
        events: {
          click: async (e: any) => {
            const point = e.point;
            const params = config.plotOptions.clickEventParams;

            if (params && params.appIdentifiers && point.options && point.options.id) {
              const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, null, point.options.id, params, config.hierarchy);
              if (reportClickThroughInfo == null) { return null; }
              if (reportClickThroughInfo.DestinationUrl.length > 0) {
                model?.reportClickThroughAsync(reportClickThroughInfo)
                  .catch(error => logError(error, 'reportClickThrough'));
              }
            }
          }
        }
      }
    };
  }

  public setupBubbleClickthrough(config: HighChartConfig, model: ChartReportModel) {
    config.plotOptions.scatter = {
      point: {
        events: {
          click: async (e: any) => {
            const point = e.point;
            const params = config.plotOptions.clickEventParams;

            if (params && params.appIdentifiers && point.options && point.options.id) {
              const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, null, point.options.id, params, config.hierarchy);
              if (reportClickThroughInfo == null) { return null; }
              if (reportClickThroughInfo.DestinationUrl.length > 0) {
                model?.reportClickThroughAsync(reportClickThroughInfo)
                  .catch(error => logError(error, 'reportClickThrough'));
              }
            }
          }
        }
      }
    };
  }

  public setupGanttClickthrough(config: HighChartConfig, data: any, model: ChartReportModel) {
    config.yAxis[0].min = data.GanttEarliestDate;

    if (config.chart.height == null
      && data.Series[0].data.length > 5) {
      const height = 245 + ((data.Series[0].data.length - 5) * 40);
      config.chart.height = height.toString();
    }

    config.plotOptions.series = {
      point: {
        events: {
          click: async (e: any) => {
            const point = e.point;
            const params = config.plotOptions.clickEventParams;

            if (params && params.appIdentifiers && point.options && point.options.id) {
              const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, null, point.options.id, params, config.hierarchy);
              if (reportClickThroughInfo == null) { return null; }
              if (reportClickThroughInfo.DestinationUrl.length > 0) {
                model?.reportClickThroughAsync(reportClickThroughInfo)
                  .catch(error => logError(error, 'reportClickThrough'));
              }
            }
          }
        }
      }
    };
  }

  public setupPolarClickthrough(config: HighChartConfig, model: ChartReportModel) {
    config.plotOptions.series = {
      point: {
        events: {
          click: async (e: any) => {
            const point = e.point;
            const params = config.plotOptions.clickEventParams;

            if (params && params.appIdentifiers && params.targetClickThroughReportIdentifier) {
              const description = point.series.name;
              let seriesSettings = null;

              for (let i = 0; i < config.series.length; i++) {
                if (config.series[i].name === description) {
                  seriesSettings = config.series[i];
                }
              }

              if (seriesSettings) {
                params.labelsFieldValue = point.category;

                const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, params.targetClickThroughReportIdentifier, null, params, config.hierarchy);
                if (reportClickThroughInfo == null) { return null; }
                if (reportClickThroughInfo.DestinationUrl.length > 0) {
                  model?.reportClickThroughAsync(reportClickThroughInfo)
                    .catch(error => logError(error, 'reportClickThrough'));
                }
              }
            }
          }
        }
      }
    };
  }

  public setupPieClickthrough(config: HighChartConfig, model: ChartReportModel) {
    if (config.plotOptions.pie) {
      config.plotOptions.pie.point = {
        events: {
          click: async (e: any) => {
            const params = config.plotOptions.clickEventParams;
            const point = e.point;

            if (params && params.appIdentifiers && params.targetClickThroughReportIdentifier) {
              let pieSeries = point.series.options;
              const pieLevels = point.series.chart.series;
              let pieLevelIndex = pieLevels.findIndex(level => level.options === pieSeries);
              let pointX = point.x;

              params.pieLevelType = [];
              params.pieLevelName = [];
              params.pieLevelValue = [];

              do {
                params.pieLevelType.push(pieSeries.seriesBaseFieldType);
                params.pieLevelName.push(pieSeries.seriesBaseFieldIdentifier);
                params.pieLevelValue.push(pieSeries.data[pointX].name);

                let cumulativeValue = 0;
                for (let i = 0; i <= pointX; i++) {
                  cumulativeValue += pieLevels[pieLevelIndex].data[i].y;
                }

                pieLevelIndex--;

                if (pieLevelIndex >= 0) {
                  pointX = pieLevels[pieLevelIndex].options.data.findIndex(value => {
                    if (value.y > cumulativeValue) {
                      return true;
                    } else {
                      cumulativeValue -= value.y;
                      return false;
                    }
                  });

                  pieSeries = pieLevels[pieLevelIndex].options;
                }
              } while (pieLevelIndex > -1);

              const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, params.targetClickThroughReportIdentifier, null, params, config.hierarchy);
              if (reportClickThroughInfo == null) { return null; }
              if (reportClickThroughInfo.DestinationUrl.length > 0) {
                model?.reportClickThroughAsync(reportClickThroughInfo)
                  .catch(error => logError(error, 'reportClickThrough'));
              }
            }
          }
        }
      };
    }

  }

  public setupMapClickthroughs(config: HighChartConfig, model: ChartReportModel) {
    config.plotOptions.map = {
      point: {
        events: {
          click: async (e: any) => {
            const point = e.point;
            const params = config.plotOptions.clickEventParams;

            if (params && params.appIdentifiers && params.targetClickThroughReportIdentifier &&
              params.geographyFieldName && params.geographyFieldType
              && point['hc-key']) {

              params.geographyValue = point['hc-key'];

              const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, params.targetClickThroughReportIdentifier, null, params, config.hierarchy);
              if (reportClickThroughInfo == null) { return null; }
              if (reportClickThroughInfo.DestinationUrl.length > 0) {
                model?.reportClickThroughAsync(reportClickThroughInfo)
                  .catch(error => logError(error, 'reportClickThrough'));
              }
            }
          }
        }
      }
    };
  }

  public setupNetworkClickthroughs(config: HighChartConfig, model: ChartReportModel) {
    if (config.plotOptions.networkgraph) {
      config.plotOptions.networkgraph.point = {
        events: {
          click: async (e: any) => {
            const point = e.point;
            const params = config.plotOptions.clickEventParams;

            if (params && params.appIdentifiers && params.targetClickThroughReportIdentifier &&
              params.networkFields && params.networkFields.length) {

              if (!point.recordId) {
                let i: number;
                for (i = 0; i < point.parents.length; i++) {
                  params.networkFields[i].fieldValue = point.parents[i];
                }
              }

              const reportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, params.targetClickThroughReportIdentifier, point.recordId, params, config.hierarchy);
              if (reportClickThroughInfo == null) { return null; }
              if (reportClickThroughInfo.DestinationUrl.length > 0) {
                model?.reportClickThroughAsync(reportClickThroughInfo)
                  .catch(error => logError(error, 'reportClickThrough'));
              }
            }
          }
        }
      };
    }
  }

  public setupDefaultClickthroughs(config: HighChartConfig, chartType: Enums.ChartType, reportType: any, model: ChartReportModel) {
    if (chartType !== Enums.ChartType.cumulativematrix && chartType !== Enums.ChartType.montecarlo
      && chartType !== Enums.ChartType.matrix && reportType !== Enums.ReportTypes.Matrix
      && chartType !== Enums.ChartType.map) {

      const clickthrough = async (e: any) => {
        const point = e.point;
        const params = config.plotOptions.clickEventParams;

        if (params && params.appIdentifiers && params.targetClickThroughReportIdentifier) {
          params.xFieldValue = chartType !== Enums.ChartType.bar
            ? point.series.options.columns[point.x]
            : point.series.name;

          params.yFieldValue = chartType !== Enums.ChartType.bar
            ? point.series.name
            : point.series.options.columns[point.x];

          const reportClickThroughInfo: ReportClickThroughInfo = await this.getReportClickThroughInfo(params.appIdentifiers, params.targetClickThroughReportIdentifier, null, params, config.hierarchy);
          if (reportClickThroughInfo == null) { return null; }
          if (reportClickThroughInfo.DestinationUrl.length > 0) {
            model?.reportClickThroughAsync(reportClickThroughInfo)
              .catch(error => logError(error, 'reportClickThrough'));
          }
        }
      };

      if (config.plotOptions) {
        if (config.plotOptions.series) {
          config.plotOptions.series.point = {
            events: {
              click: clickthrough
            }
          };
        } else {
          config.plotOptions.series = {
            point: {
              events: {
                click: clickthrough
              }
            }
          };
        }
      }
    }
  }



  public async getReportClickThroughInfo(appIdentifier: AppIdentifiers, reportIdentifier: any, recordId: any, params: ReportClickThroughParams, hierarchy: any): Promise<ReportClickThroughInfo> {
    const queryParams = this.getQueryParams(params);
    if (queryParams == null) { return null; }
    const reportClickThroughInfo: ReportClickThroughInfo = {
      AppIdentifier: appIdentifier.visibleAppIdentifier,
      ReportIdentifier: reportIdentifier,
      DestinationUrl: await this.getUrl(appIdentifier, reportIdentifier, recordId, hierarchy),
      QueryParams: queryParams,
      ClickThroughToRecord: recordId != null && recordId.length > 0
    };

    return reportClickThroughInfo;
  }

  private getQueryParams(params: any): QueryParams {
    const filters = this.getFiltersFromParams(params);
    let query = '';

    if (filters && filters.length > 0) {
      const existingFilters = this.getQueryVariable('filter');
      query = existingFilters ? existingFilters : '';

      for (let i = 0; i < filters.length; i++) {
        if (filters[i].Name === 'Unset' || filters[i].Value === 'Unset') {
          continue;
        }
        const filterItem = filters[i];

        if (existingFilters || query !== '') {
          query += ' and ';
        }

        const filter = '[' + filterItem.Name + '] eq ' + filterItem.Value;
        query += filter;
      }
      if (query === '') {
        return null;
      }
    }

    if (params.BaseFilter) {
      const basefilter = params.BaseFilter.replace(/\$filter=/, '');
      query += query.length === 0 ? basefilter : ` and ${basefilter}`;
    }

    // Todo - use hierarchy
    // Note - condition: if (hierarchy & !recordId & reportIdentifier) then DO NOT apply hierarchy to queryparams

    const queryParams: QueryParams = { $skip: 0, $top: 25, $search: '', $filter: query, $orderby: '', $groupby: '', hierarchy: '' };
    return queryParams;
  }

  private async getUrl(appIdentifiers: AppIdentifiers, reportIdentifier: any, recordId: any, hierarchy: any): Promise<string> {
    let url = '';

    // "/App/Update/{appIdentifier}/{recordId}"    example click through to single record
    // "/App/Main/{appIdentifier}/{reportIdentifier}{filter}"    example click through to list report

    // Hierarchy
    // "/App/Update/{appIdentifier}/{recordId}{hierarchy}"  example click through to single record via child app
    // "/App/Main/{parentAppIdentifier}/{parentRecordId}/{appIdentifier}/{reportIdentifier}{filter}"
    //    example click through to child list report

    if (!appIdentifiers) {
      return url;
    }

    // No hierarchy route
    if (!hierarchy && !appIdentifiers.parentAppIdentifier && !appIdentifiers.childAppIdentifier) {
      if (recordId) {
        url = this.navigationService.getRecordUrl(appIdentifiers.visibleAppIdentifier, recordId);
      } else if (reportIdentifier) {
        const reportType = (await this._appsService.getReport(appIdentifiers.visibleAppIdentifier, reportIdentifier).toPromise()).Type;
        url = this.navigationService.getReportUrl(appIdentifiers.visibleAppIdentifier, reportIdentifier, reportType);
      }
      return url;
    }

    // Hierarchy route
    let parts;
    let parentAppIdentifier;
    let parentRecordId;
    if (hierarchy) {
      parts = hierarchy.split('|');
      parentAppIdentifier = parts[0];
      parentRecordId = parts[1];
    } else if (!parts && appIdentifiers.appIdentifier && appIdentifiers.childAppIdentifier) {
      parts = [appIdentifiers.appIdentifier, appIdentifiers.childAppIdentifier];
    }
    if (recordId) {
      url = this.navigationService.getChildRecordUrl(appIdentifiers.visibleAppIdentifier, appIdentifiers, recordId);
    } else if (reportIdentifier && parentRecordId) {
      // Todo - this didn't have hierarchy on url in previous version, will it affect child report loading if show hierarchy
      const reportType = (await this._appsService.getReport(appIdentifiers.visibleAppIdentifier, reportIdentifier).toPromise()).Type;
      url = this.navigationService.getChildAppReportFolderUrl(parentAppIdentifier, parentRecordId, appIdentifiers.visibleAppIdentifier, reportIdentifier, reportType);
    }
    return url;
  }

  private getFiltersFromParams(params: ReportClickThroughParams) {
    const filters: Array<{ 'Name': string, 'Value': string | number }> = [];

    if (params) {
      if (params.xFieldIdentifier != null && isDefined(params.xFieldValue) && params.xFieldType != null) {
        const xFilterValue = this.getFilterValue(params.xFieldType, params.xFieldValue);
        filters.push({ 'Name': params.xFieldIdentifier, 'Value': xFilterValue });
      }

      if (params.yFieldIdentifier != null && isDefined(params.yFieldValue) && params.yFieldType != null) {
        const yFilterValue = this.getFilterValue(params.yFieldType, params.yFieldValue);
        filters.push({ 'Name': params.yFieldIdentifier, 'Value': yFilterValue });
      }

      if (params.labelsFieldName != null && isDefined(params.labelsFieldValue)) {
        const labelsFieldValue = this.getFilterValue(params.labelsFieldType, params.labelsFieldValue);
        filters.push({ 'Name': params.labelsFieldName, 'Value': labelsFieldValue });
      }

      if (params.pieLevelName != null && isDefined(params.pieLevelValue) && params.pieLevelType != null) {
        for (let i = 0; i < params.pieLevelName.length; i++) {
          const pieLevelValue = this.getFilterValue(params.pieLevelType[i], params.pieLevelValue[i]);
          filters.push({ 'Name': params.pieLevelName[i], 'Value': pieLevelValue });
        }
      }

      if (params.geographyFieldName != null && isDefined(params.geographyValue) && params.geographyFieldType != null) {
        const geographyValue = this.getFilterValue(params.geographyFieldType, params.geographyValue);
        filters.push({ 'Name': params.geographyFieldName, 'Value': geographyValue });
      }

      if (params.columnFieldIdentifier != null && isDefined(params.columnFieldValue) && params.columnFieldType != null) {
        const columnValue = this.getFilterValue(params.columnFieldType, params.columnFieldValue);
        filters.push({ 'Name': params.columnFieldIdentifier, 'Value': columnValue });
      }

      if (params.cumulativeFields) {
        params.cumulativeFields.forEach(fields => {
          if (fields.cumulativeCalculationType === ChartEnums.Calculation.Minimum || fields.cumulativeCalculationType === ChartEnums.Calculation.Maximum) {
            const fieldValue = this.getFilterValue(fields.cumulativeFieldType, fields.cumulativeFieldValue);
            filters.push({ 'Name': fields.cumulativeFieldIdentifier, 'Value': fieldValue });
          }
        });
      }

      if (params.networkFields) {
        params.networkFields.forEach(field => {
          if (field.fieldValue) {
            const fieldValue = this.getFilterValue(field.fieldType, field.fieldValue);
            filters.push({ 'Name': field.fieldIdentifier, 'Value': fieldValue });
          }
        });
      }
    }
    return filters.filter(filter => filter.Value !== 'datetime\'Unset\'');
  }

  private getQueryVariable(name: any) {
    return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' +
      name.replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
  }

  private getFilterValue(fieldType: any, fieldValue: any): string | number {
    let filterValue = fieldValue;

    switch (fieldType) {
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Number:
      case Enums.FieldType.Range:
      case 'Integer':
      case 'Long':
      case 'Number':
      case 'Range':
        return filterValue;

      case Enums.FieldType.Money:
      case 'Money':
        return extractDecimal(filterValue);

      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
      case 'Date':
      case 'DateTime':
      case 'Period': {
        // If we have an Epoch, convert to string format
        if (filterValue.$date) {
          filterValue = EpochConverter.toDateString(fieldValue);
        } else if (typeof filterValue === 'number') {
          filterValue = moment.utc(fieldValue).toISOString();
        }

        if (filterValue === 'Invalid date') {
          return 'Unset';
        }

        if (fieldType === Enums.FieldType.Period) {
          const parsed = moment.utc(filterValue, 'MMM.YYYY');
          if (parsed.isValid()) {
            filterValue = parsed.toISOString();
          }
        } else {
          const parsed = moment.utc(filterValue, 'D.MMM.YYYY');
          if (parsed.isValid()) {
            filterValue = parsed.toISOString();
          }
        }

        // Remove trailing trailing ":00Z" to be a compliant filter. 2014-05-01T00:00:00Z
        let suffixToRemoveIndex = filterValue.indexOf(':00Z');
        if (suffixToRemoveIndex !== -1) {
          filterValue = filterValue.toString().substring(0, suffixToRemoveIndex);
        } else {
          suffixToRemoveIndex = filterValue.indexOf(':00.000Z');
          if (suffixToRemoveIndex !== -1) {
            filterValue = filterValue.toString().substring(0, suffixToRemoveIndex);
          }
        }

        filterValue = 'datetime\'' + filterValue + '\'';
        return filterValue;
      }
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Email:
      case Enums.FieldType.Literal:
      case Enums.FieldType.UrlField:
      case 'Text':
      case 'LongText':
      case 'Email':
      case 'Literal':
      case 'UrlField':
        filterValue = '\'' + this.escape(fieldValue) + '\'';
        return filterValue;
      case Enums.FieldType.Bit:
        return typeof filterValue === 'string' ? filterValue.toLowerCase() : filterValue;
      default:
        filterValue = '\'' + fieldValue + '\'';
        return filterValue;
    }
  }

  private escape(s: string): string {
    return s.replace(`'`, `''`);
  }
}
