import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { logError } from '@softools/softools-core';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshComponent {

  constructor(private updates: SwUpdate) { }

  public onReloadClickHandler(): void {
    this.updates.activateUpdate().then(() => document.location.reload()).catch(e => logError(e, 'Failed to activate update'));
  }
}
