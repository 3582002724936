import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular';
import { currentUser$, User } from '@softools/softools-core';
import { VERSION_NUMBER } from 'app-version';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Offline as OfflineIntegration } from "@sentry/integrations";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

// Infinite loop
// https://github.com/getsentry/sentry-javascript/issues/1800
// https://github.com/getsentry/sentry-javascript/issues/1883
// const integrations = environment.enableTelemetry ? [
//   // new CaptureConsole({
//   //   levels: ['error'],
//   // }),
// ] : [];

const supportLanguages = ['en', 'fr', 'ja', 'zh', 'de', 'es', 'tr'];

function lang() {
  const part = navigator?.language?.slice(0, 2);
  if (supportLanguages.includes(part)) { return part; }
  return 'en';
}

Sentry.init({
  release: `${VERSION_NUMBER}`,
  environment: `${environment.build}`,
  dist: `${environment.build}-${lang()}`,
  dsn: 'https://eed44c6ceb4d496eaabb156bc79808e7@sentry.io/1859616',
  enabled: environment.enableTelemetry,
  // integrations: [],
  integrations: [
    new OfflineIntegration({
      maxStoredEvents: 30,
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
  ],
  // tracesSampleRate: 0.25,
  ignoreErrors: [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
  ],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  errorSubject = new Subject();

  constructor() {
    // listen for current user and keep sentry configured
    currentUser$.pipe(
      tap((user) => {
        this.setUser(user);
      })
    ).subscribe();

    this.errorSubject.pipe(
      debounceTime(250),
      distinctUntilChanged((prev: Error, curr) => prev.message === curr.message)
    )
      .subscribe((error: Error | any) => {
        Sentry.captureException(error.originalError || error);
      });
  }

  handleError(error) {
    if (environment.enableTelemetry) {
      this.errorSubject.next(error);
    }
  }

  public setUser(user: User) {
    if (environment.enableTelemetry) {
      if (user) {
        Sentry.configureScope(function (scope) {
          scope.setUser({ email: user.Email });
        });
      }
    }
  }
}
