import * as moment from 'moment';
import { OdataExpressionType } from './odata-expression-type';
import { IRootQuery } from './root-query';

/** Base class with generic functionality for all odata expression types */
export abstract class OdataExpression {

  public id: number;

  protected constructor(
    public type: OdataExpressionType,
    public root: IRootQuery,
    public parent: OdataExpression,
  ) {
    root.addExpression(this);
  }

  protected walk(onode: any): OdataExpression {
    const expressionType = (onode.type) as string;
    return this.root.createExpression(onode, expressionType, this);
  }

  // Expressions stop the filter from being simple by default.  Override to allow simplicity
  public isSimple = () => false;

  /**
   * Textual representation of the expression node.  This may be used to
   * render a display format but values are not localised.
   */
  public text(): string {
    // The name of the operation by default
    return OdataExpressionType[this.type];
  }

  /** Call the specified function on every node in the query subtree */
  public each(callback: (node: OdataExpression) => OdataExpression | boolean | null) {
    return callback(this);
  }

  public eachChild(callback: (node: OdataExpression) => OdataExpression | boolean | null): OdataExpression | boolean | null {
    return true;
  }

  public replace(_nodeId: number, _newNode: OdataExpression) {
    throw new Error('replace not supported');
  }

  public removeChild(_nodeId: number) {
    throw new Error(`Expression node doesn't know how to remove children`);
  }

  /**
   * Ensure tree consistency, e.g. after a node has been removed
   */
  public repair() {
    if (this.parent) {
      this.parent.repair();
    }
  }

  public canReduceTo(): OdataExpression {
    return null;
  }

  public expressionText() {
    return this.text();
  }

  /**
   * Check whether the expression matches the supplied values
   * @param _values   Value collection, a Record or similar value bag
   * @param listRow   Row number to lookup values in list fields
   */
  public isMatch(_values: {}, listRow?: number): boolean {
    // concrete clases should override
    return false;
  }

  public lookupValue(_values: {}, listRow?: number): any {
    return null;
  }

  protected _normalizeValue(value: any): any {
    if (value === true || value === false) {
      return value;
    } else if (Number.isFinite(value)) {
      return value;
    } else if (value && value.$date) {
      // This is the format we use in record data (EpochDate)
      // Return the numeric format of the date so comparisons work
      // Date below must return a consistent value
      return value.$date;
    } else if (value instanceof Date) {
      // The Odata parser creates a Date using the string constructor
      // but this is always a local date.  We want it to be UTC so
      // extract the elements and create a UTC date from it
      // Then return the epoch value so comparisons work
      const parts = {
        year: value.getFullYear(),
        month: value.getMonth(),
        d: value.getDate(),
        h: value.getHours(),
        m: value.getMinutes(),
      };

      return moment.utc(parts).valueOf();
    } else if (Array.isArray(value) && value.length === 2 && value[0] === 'null') {
      // Null constants are parsed into an array with a null string and a type hint
      return null;
    } else if (value === undefined || value === null) {
      // treat no value as empty (does this need to be field specific?)
      // Existing filters rely on this match even though it's not entirely correct (see SOF-12578)
      return '';
    } else {
      // Just return tha value
      // If there any more cases where our data formats don't match the ones supplied as
      // literals by the odata parser we will need to add cases for them (as date values above)
      return value;
    }
  }
}


export class OdataLiteralExpression extends OdataExpression {

  value: any;

  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.Literal, root, parent);
    this.value = this._normalizeValue(onode.value);
  }

  /** text format of a literal is its value */
  public override text(): string {
    return this.value && this.value.toString();
  }

  public override expressionText() {
    if (Number.isFinite(this.value)) {
      return `${this.text()}`;
    } else {
      return `'${this.text()}'`;
    }
  }

  public override lookupValue(_values: {}, listRow?: number): any {
    return this.value;
  }
}

