import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent {

  @Input() title: string;
  @Input() imageUrl: string;
  @Input() iconBase64?: string;
  @Input() color: string;
  @Input() description: string;
  @Input() category: string;

  @Output() click = new EventEmitter();
}
