import { Injectable } from '@angular/core';
import { SaveImageDetails, SaveCameraDetails } from 'app/workspace.module/services/pending.service';
import { FileImagesRepository, UploadImagesStorageService, OnlineStatusService, ImageData, FieldKey, logMessage, logError, LogLevel, toBase64 } from '@softools/softools-core';
import { AppService } from '../app.service';
import { LocalValuesStorageServiceService } from '../local-values-storage-service.service';
import { RecordPatch } from 'app/workspace.module/types';
import { RecordPersistService } from '../record/record-persist.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PendingChangesModel } from 'app/mvc/common/pending.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagePersistService {
  /**
   * Collection of local image blobs.
   */
  private blobs = new Map<string, string>();

  public readonly pendingChangesModel = new PendingChangesModel();

  /** Observable fires when it's time to check patch queues  */
  public persistQueueAwoken$ = new Subject();

  constructor(
    private uploadImagesStorageService: UploadImagesStorageService,
    private fileImageRepository: FileImagesRepository,
    private recordPersistService: RecordPersistService,
    private localValuesStorageServiceService: LocalValuesStorageServiceService,
    private appService: AppService,
    private onlineStatus: OnlineStatusService
  ) { }

  public persistQueuedChanges() {
    this.persistQueueAwoken$.next(null);
  }

  public async queueChange(details: ImageData, file: File) {
    await this.uploadImagesStorageService.storeImageAsync(details, file);
    await this.updateUploadImageCount();
  }

  public async queueCameraChange(details: SaveCameraDetails) {
    await this.uploadImagesStorageService.storeCameraAsync(details, details.imageId, await details.blob.arrayBuffer());
    await this.updateUploadImageCount();
  }

  public async queueDelete(details: SaveImageDetails) {
    await this.uploadImagesStorageService.storeDeleteImageAsync(details, details.imageId);
    await this.updateUploadImageCount();
  }

  public clearImage(details: SaveImageDetails): void {
    const blobKey = `${details.appIdentifier}-${details.fieldIdentifier}-${details.recordId}`;
    this.blobs.set(blobKey, null);
  }

  public async persistChanges(): Promise<boolean> {
    if (this.onlineStatus.isConnected) {
      const keys = await this.uploadImagesStorageService.keys();
      let queueSize = keys.length;

      if (queueSize > 0) {
        for (let index = 0; index < keys.length; index++) {
          const imageKey = keys[index];
          const imageData = await this.uploadImagesStorageService.getImageDataAsync(imageKey);

          if (imageData?.image) {
            const file = await this.uploadImagesStorageService.getImageAsync(null, imageKey);
            if (file) {
              const details: SaveImageDetails = {
                appIdentifier: imageData.appIdentifier,
                fieldIdentifier: imageData.fieldIdentifier,
                recordId: imageData.recordId,
                imageId: imageData.imageId,
                file: file,
                rowKey: imageData.rowKey
              };

              if (await this.postItem(details)) {
                queueSize--;
              }
            }
          } else {
            await this.deleteItem(imageData);
          }
        }

        // Say there's more to do, as we will have either posted something
        // so we'll need to process the patch that finalises the field value,
        // or we failed to post everything in which case we try again
        return true;
      }

      const uploadCount = await this.updateUploadImageCount();
      return uploadCount > 0;
    } else {
      return false;
    }
  }

  public async updateUploadImageCount(): Promise<number> {
    const finalKeys = await this.uploadImagesStorageService.keys();
    this.pendingChangesModel.pendingSaveImagesCount.value = finalKeys.length;
    return finalKeys.length;
  }

  public async getImageUrl(imageId?: string): Promise<string> {
    if (imageId) {
      if (!this.blobs.has(imageId)) {
        // not seen before - look up and store (including null if we don't find)
        const file = await this.uploadImagesStorageService.getImageAsync(null, imageId);
        if (file) {
          const url = URL.createObjectURL(file);
          this.blobs.set(imageId, url);
          return url;
        } else {
          this.blobs.set(imageId, null);
          return null;
        }
      }
      return this.blobs.get(imageId);
    }

    return null;
  }

  public storeImage(details: ImageData, file: File): string {
    const url = URL.createObjectURL(file);
    this.blobs.set(details.imageId, url);
    return url;
  }

  public storeCameraImage(details: SaveCameraDetails): string {
    const urlCreator = window.URL || window.webkitURL;
    const url = urlCreator.createObjectURL(details.blob);
    this.blobs.set(details.imageId, url);
    return url;
  }

  private async postItem(item: SaveImageDetails): Promise<boolean> {
    try {

      if (item.file.size > 0) {
        // Save image to server
        const data = await toBase64(item.file);
        const response: string = await this.fileImageRepository
          .saveImageFileAsync(item.appIdentifier, item.recordId, item.imageId, item.file.name, data);

        // Get the queue item.  If it matches the one we just posted remove it
        // otherwise another image has been queued so leave it for later processing
        await this.complete(item, item.imageId, false);
        return true;
      } else {
        logMessage(`postItem: Invalid image file ${item.file.name} ${item.file.type}`);
      }

    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 0:   // no network
          case 502: // gateway error - likely temp server condition
            // Can retry so leave in queue
            logError(error, 'post image leaving queued', LogLevel.warning);
            return false;
          case 415: // bad mime type, invalid file data can trigger too
          default:
            // consider anything else a permanent error
            logError(error, 'post image fatal');
            break;
        }
      } else {
        logError(error, 'post image');
      }
    }

    // If we get here we failed to process the image - remove invalid data from the queue
    await this.uploadImagesStorageService.remove(item.imageId);

    return false;
  }

  private async deleteItem(item: ImageData) {
    try {
      const resp = await this.fileImageRepository.deleteImageFileAsync(item.appIdentifier, item.recordId, item.imageId);
      // no error handling in delete - check here when we can
      // const imageData = await this.uploadImagesStorageService.getImageDataAsync(item.imageId);
      await this.complete(item, item.imageId, true);
    } catch (error) {
      logError(error, 'Failed to delete image');
      throw error;
    }
  }

  private async complete(item: FieldKey, imageId: string, forDelete: boolean) {
    const imageData = await this.uploadImagesStorageService.getImageDataAsync(imageId);
    if (imageData) {
      // The image has been uploaded or deleted so we no longer need the locally stored copy
      await this.uploadImagesStorageService.remove(imageData.imageId);

      const local = await this.localValuesStorageServiceService.getValueAsync(item);
      const same = forDelete ? (!local) : (local === imageData.imageId);
      if (same) {
        const app = this.appService.application(imageData.appIdentifier);
        const field = app.getField(imageData.fieldIdentifier);
        const newId = forDelete ? '' : imageData.imageId;

        // Patch real update
        // todo this is kludge - field create patch needs record or patch, also no hierarchy
        const temp = new RecordPatch(item.recordId, item.appIdentifier);
        const patch = field.createPatch(temp, newId, imageData.rowKey);
        await this.recordPersistService.queueChange(patch);

        this.persistQueuedChanges();
      }
    } else {
      logMessage(`Image id ${imageData} not in storage`, LogLevel.warning);
    }
  }
}
