import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { SyncStatusService } from 'app/services/sync-status.service';

@Injectable({ providedIn: 'root' })
export class ForceSyncGuard implements CanActivate {
  constructor(private router: Router, private syncStatus: SyncStatusService) { }

  canActivate(): UrlTree | boolean {
    const version = localStorage.getItem('current-version');
    if (version) {
      const [, , , build] = version.split('.');
      if (+build < 4430) {
        this.syncStatus.reset();
      }
    }

    // If Sync has never run force a Sync.
    const status = this.syncStatus.syncStatus;
    if (!this.syncStatus.hasRun || status === 'reset' || status === 'none') return this.router.parseUrl('/Sync');

    return true;
  }
}
