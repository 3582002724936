import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OnlineStatusService {

  /**
   * The most up to date state of server connectivity.  This can be set by code that has
   * detected that communications are down provided it can also detect when the connection
   * has been restored (typically polling code).
   */
  public _isServerReachable: boolean;

  /**
   * Observable of the online state
   */
  public isServerReachable$: BehaviorSubject<boolean>;

  constructor() {
    this._isServerReachable = this.isOnline;
    this.isServerReachable$ = new BehaviorSubject(this._isServerReachable);
  }


  public get isServerReachable() {
    return this._isServerReachable;
  }

  public set isServerReachable(reachable: boolean) {
    if (this._isServerReachable !== reachable) {
      this._isServerReachable = reachable;
      this.isServerReachable$.next(reachable);
    }
  }

  /**
   * Gets our best indication of whether we are online.  This method should
   * normally be used by code that wishes to only perform an action when
   * connected.  Note that it is not a guarantee (for example we may have lost
   * connectivity but not yet detected the fact) so checking this is not a
   * substitute for error checking.
   */
  public get isConnected() {
    return this.isServerReachable && this.isOnline;
  }

  /**
   * Get an indication of whether the browser thinks we are online.
   * This is not a definitive result as VMs or VPNs can appear as functional
   * network connections, so an Internet connection is not guaranteed.
   *
   * @see isConnected provides a more reliable check in most circumstances.
   */
  public get isOnline() {
    return navigator && navigator.onLine;
  }
}
