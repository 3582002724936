<app-input-icon 
  icon="external-link-square-alt"
  [elementStyles]="elementStyles"
  [url]="urlValue" 
  >
  <a *ngIf="!editMode && checkUrlValid()" target="_blank" (click)="$event.stopPropagation()" [href]="urlValue">{{value}}</a>
  <span *ngIf="!editMode && !checkUrlValid()">{{value}}</span>
  <app-input *ngIf="editMode"
    class="d-block pe-0"
    [class.inline-control]="inline"
    [appModel]="appModel"
    [reportModel]="reportModel"
    [field]="fieldModel"
    [template]="template"
    [record]="record"
    [value]="value"
    [listRow]="listRow"
    [ruleState]="ruleState"
    [textStyle]="textStyle"
    [containerField]="containerField"
    [form]="form"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"
  >
  </app-input>
</app-input-icon>
