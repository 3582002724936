import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Enums, GridDataSet } from '@softools/softools-core';
import { FieldBase, ContainerType } from 'app/softoolsui.module/fields';
import { GridAppField } from 'app/types/fields/grid-app-field';

@Component({
  selector: 'app-grid-field',
  templateUrl: './grid-field.component.html',
  styleUrls: ['./grid-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridFieldComponent extends FieldBase<GridAppField> {

  /** If set, displays a grid orientation icon */
  @Input() cornerIcon = false;

  public gridContainerType = ContainerType.Grid;

  constructor() { super(); }


  public getFieldLabel(identifier: string) {
    try {
      return this.getField(identifier).Label;
    } catch (err) {
      return '';
    }
  }

  public getField(identifier: string) {
    const app = this.appService.application(this.record.AppIdentifier);
    if (app) {
      return app.getField(identifier);
    }

    return null;
  }

  public rowHeaders(): Array<string> {
    return this.fieldModel.SubFieldsIdentifiers && this.fieldModel.SubFieldsIdentifiers
      .map(i => i.Identifier)
      .map(id => this.getField(id))
      .map(field => field && field.HeaderText);
  }

  gridDataSetTrackByFn(_, item: GridDataSet) {
    return item.Identifier;
  }

  public gridFullRowEnd(): number {
    let end: number;
    if (this.fieldModel.Orientation === Enums.Orientation.Horizontal) {
      end = this.fieldModel.SubFieldsIdentifiers?.length + 1;
    } else {
      end = this.fieldModel.GridDataSets?.length + 1;
    }

    if (!this.fieldModel.HideGridRowTitle) {
      end++;
    }
    return end;
  }

  onActivateComponent($event) {
    this.componentFocussed$.next($event);
  }
}
