import { OnInit, Directive, SimpleChanges, OnChanges } from '@angular/core';
import { SelectList, Enums, logError, LogLevel, Record } from '@softools/softools-core';
import { EditableFieldBase } from '../fields';
import { SelectListsService } from 'app/services/select-lists.service';
import { InjectService } from 'app/services/locator.service';
import { AppField } from 'app/types/fields/app-field';
import { BehaviorSubject } from 'rxjs';

@Directive()
export class FieldSelectionBase<T, TAppField extends AppField = AppField>
  extends EditableFieldBase<T, TAppField>
  implements OnInit, OnChanges {

  public selectList: SelectList;

  public selectListType = Enums.SelectListType;

  @InjectService(SelectListsService)
  private readonly selectListsService: SelectListsService;

  public displayValue: string;

  public displayValue$ = new BehaviorSubject('');

  public constructor(/* private selectListsService: SelectListsService */) {
    super();
  }

  override ngOnInit() {
    try {
      super.ngOnInit();
      this.initSelectList();
      this.application?.initialiseFieldComponent(this);
    } catch (error) {
      logError(error, `select field ${this.fieldModel?.Identifier} onInit`, LogLevel.error);
    }
  }

  public override async activate() {
    // todo make consistent
    if (this.input) {
      if (this.input.nativeElement) {
        this.input.nativeElement.focus();
      } else if ((<any>this.input).element) {
        (<any>this.input).element.nativeElement.focus();
      }
    }
  }

  public override ngOnChanges(changes: SimpleChanges) {

    // process field model first as it will reset things on init
    // needed when we select a new field in same component
    if (changes['fieldModel']) {
      this.fieldModel.attachModel(this.appModel);
      this.initSelectList();
      // this.value = null;
      // this.onValueChanged(this.value);
    }

    super.ngOnChanges(changes);
  }

  protected override onValueChanged(value: T) {
    super.onValueChanged(value);

    const text = this.getOptionText(value);

    this.displayValue = text;
    this.displayValue$.next(text ?? '');
  }

  protected override setValueFromRecord(record: Record) {

    super.setValueFromRecord(record);

    // Look up selection from value
    this.initSelectList();
    const raw = this.fieldModel.getRawRecordValue(record, this.listRow);
    const text = this.getOptionText(raw);

    // Fall back to backing field if no selection matches
    if (this.record) {
      this.displayValue = text || this.fieldModel.getDisplayRecordValue(record, this.listRow);
    }
  }

  public isCompact() {
    const threshold = this.fieldModel.CompactItemsThreshold || 7;
    return this.selectList && this.selectList.SelectListOptions && this.selectList.SelectListOptions.length >= threshold;
  }

  public displayText() {
    return this.displayValue || '';
  }

  protected getOptionText(value: any) {
    if (this.fieldModel.SelectListSubType === Enums.SelectListSubType.Numeric) {
      return this.selectList?.SelectListOptions?.find(opt => +opt.Value === +value)?.Text;
    } else {
      return this.selectList?.SelectListOptions?.find(opt => opt.Value === value)?.Text;
    }
  }

  /**
   * initialise the select list info
   */
  protected initSelectList() {
    if (this.fieldModel.UseRecordSelectListOptions) {
      const options = (<any>this.record).SelectListOptions;

      if (this.record && options) {
        this.selectList = {
          SelectListOptions: options[this.fieldModel.SelectListIdentifier],
        } as SelectList;

      } else {
        this.selectList = {
          SelectListOptions: [],
        } as SelectList;
      }
    } else {
      // Use app definfed list if any, otherwise lookup from site config
      const appOptions = this.application && this.application.selectionListOptions(this.fieldModel.Identifier);
      if (appOptions) {
        this.selectList = {
          SelectListOptions: appOptions,
        } as SelectList;
      } else {
        this.selectList = this.selectListsService.get(this.fieldModel.SelectListIdentifier);
      }
    }
  }
}


@Directive()
export class SingleSelectionFieldBase extends FieldSelectionBase<string | number> {

  protected override onValueChanged(value) {
    super.onValueChanged(value);
    value = this.fieldModel.simpleValue(value);
    this.initSelectList();
    const text = this.getOptionText(value);
    this.displayValue = text;
    this.displayValue$.next(text ?? '');

  }
}
