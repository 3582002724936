import { Enums, logError, Record, RecordCopyInfo } from '@softools/softools-core';
import { AppModel } from 'app/mvc';
import { NavigationController } from 'app/mvc/navigation/navigation.controller';
import { AppService } from 'app/services/app.service';
import { InjectService } from 'app/services/locator.service';
import { LogicBlockExecutorService } from 'app/services/logic-block-executor.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { TriggeredWorkflow } from 'app/services/workflow-queue.service';
import { WorkflowTriggerService } from 'app/services/workflow-trigger.service';
import { RecordCopyComponent, RecordCopyParameters } from 'app/workspace.module/components/ws-record/record-copy/record-copy.component';
import { Application } from '../application';
import { AppField, IPerformContext } from './app-field';

export class ButtonAppField extends AppField {

  @InjectService(RecordPersistService)
  private readonly recordPersistService: RecordPersistService;

  @InjectService(LogicBlockExecutorService)
  private readonly executor: LogicBlockExecutorService;

  @InjectService(WorkflowTriggerService)
  private readonly workflowTriggerService: WorkflowTriggerService;

  @InjectService(AppService)
  private readonly appService: AppService;

  public override showHeaderOnTemplate(appModel: AppModel) {
    const styles = appModel?.getNamedStyles({ target: 'field', names: this.NamedStyles });
    if (styles) {
      const appearances = styles.component.Appearance?.split('|');
      if (appearances?.includes('text')) {
        return false;
      }
    }

    return super.showHeaderOnTemplate(appModel);
  }

  public override async perform(context: IPerformContext): Promise<boolean> {

    // can we remove ?? - yes if appModel always in context (should be)
    const navigation = context.appModel?.globalModel.navigation ?? this.appModel.globalModel.navigation;
    const app = context.appModel?.app.value ?? this.appModel.app.value;
    const globalModel = this.appModel.globalModel;

    switch (this.ImageActionButtonType) {
      case Enums.ImageActionButtonType.AddChildRecord: {
        const ids = this.appModel.appContext.appIdentifiers.value.push(this.ImageActionButtonChildAppIdentifier);
        await navigation.navigateRecordCreateAsync({
          appIdentifiers: ids,
          parentRecordId: context.record._id
        });

        break;
      }

      case Enums.ImageActionButtonType.CloseRecord: {
        await navigation.navigateAppHome({ appIdentifier: app.Identifier });
        break;
      }

      case Enums.ImageActionButtonType.GoToChildApp: {
        // The name of the button suggests we should go to app home but
        // we go to the child app's default list report for consistency
        // with v14 (See SOF-5991)
        const ids = this.appModel.appContext.appIdentifiers.value.push(this.ImageActionButtonChildAppIdentifier);
        const childApp = this.appService.application(this.ImageActionButtonChildAppIdentifier);
        const report = childApp.preferredReport(true);
        if (report) {
          await navigation.navigateReportAsync({
            appIdentifiers: ids,
            parentRecordId: context.record._id,
            report: report
          });
        } else {
          // No suitable report, fall back to homepage
          await navigation.navigateAppHome({ appIdentifier: app.Identifier });
        }
        break;
      }


      case Enums.ImageActionButtonType.GoToParentRecord: {
        await this.gotoParent(navigation, context.record);
        break;
      }

      case Enums.ImageActionButtonType.OpenUrl: {
        if (this.ImageActionButtonUrl.startsWith('/')) {
          if (this.ImageActionButtonUrlNewTab) {
            window.open(this.ImageActionButtonUrl, '_blank');
          } else {
            await navigation.navigateUrlAsync({ url: this.ImageActionButtonUrl });
          }
        } else {
          window.open(this.ImageActionButtonUrl, this.ImageActionButtonUrlNewTab ? '_blank' : '_self');
        }
        break;
      }

      case Enums.ImageActionButtonType.TriggerWorkflow: {
        return this.triggerWorkflow(app, context.record);
      }

      case Enums.ImageActionButtonType.CopyRecord: {
        await this.copyRecord(context.record, false);
        break;
      }
      case Enums.ImageActionButtonType.CopyRecordAsTemplate: {
        await this.copyRecord(context.record, true);
        break;
      }

      case Enums.ImageActionButtonType.Submit: {
        await this.submit(context.record);
        break;
      }

      case Enums.ImageActionButtonType.Logout: {
        await globalModel.logout();
        break;
      }

      case Enums.ImageActionButtonType.LogicBlock: {
        await this.executeLogicBlock(app, context.record, context.listRow);
        break;
      }

      case Enums.ImageActionButtonType.ResyncApp: {
        await this.appModel.resyncConfig();
        break;
      }

      case Enums.ImageActionButtonType.Archive: {
        await this.archive(context);
        break;
      }

      case Enums.ImageActionButtonType.Unarchive: {
        await this.unarchive(context);
        break;
      }

      case Enums.ImageActionButtonType.Copy: {
        await this.copy(context);
        break;
      }

      case Enums.ImageActionButtonType.Delete: {
        await this.delete(context);
        break;
      }

      case Enums.ImageActionButtonType.Security: {
        await this.security(context);
        break;
      }

      case Enums.ImageActionButtonType.Import: {
        await this.import(context);
        break;
      }

      case Enums.ImageActionButtonType.Export: {
        await this.export(context);
        break;
      }

      case Enums.ImageActionButtonType.History: {
        await this.history(context);
        break;
      }

      case Enums.ImageActionButtonType.Refresh: {
        await this.refresh(context);
        break;
      }

      case Enums.ImageActionButtonType.ShowArchived: {
        this.showArchived(context, true);
        break;
      }

      case Enums.ImageActionButtonType.HideArchived: {
        this.showArchived(context, false);
        break;
      }

      case Enums.ImageActionButtonType.Link: {
        await this.link(context);
        break;
      }

      case Enums.ImageActionButtonType.Unlink: {
        await this.unlink(context);
        break;
      }

      case Enums.ImageActionButtonType.MakeAvailableOffline: {
        await this.makeOffline(context, true);
        break;
      }

      case Enums.ImageActionButtonType.MakeUnavailableOffline: {
        await this.makeOffline(context, false);
        break;
      }

      case Enums.ImageActionButtonType.DiscardChanges: {
        await this.discardChanges(context);
        break;
      }

      case Enums.ImageActionButtonType.OpenRecord: {
        await this.openRecord(context);
        break;
      }

      default: {
        console.warn('unknown action', this.ImageActionButtonType);
        return false;
      }
    }

    // Fall through here for actions that always happen
    return true;
  }

  public override defaultIcon() {
    switch (this.ImageActionButtonType) {
      case Enums.ImageActionButtonType.ResyncApp:
        return 'sync';
      case Enums.ImageActionButtonType.Archive:
      case Enums.ImageActionButtonType.Unarchive:
        return 'archive';
      case Enums.ImageActionButtonType.Copy:
        return 'copy';
      case Enums.ImageActionButtonType.Delete:
      case Enums.ImageActionButtonType.DiscardChanges:
        return 'trash';
      case Enums.ImageActionButtonType.Security:
        return 'lock';
      case Enums.ImageActionButtonType.Import:
        return 'cloud-upload';
      case Enums.ImageActionButtonType.Export:
        return 'share-square';
      case Enums.ImageActionButtonType.History:
        return 'history';
      case Enums.ImageActionButtonType.Refresh:
        return 'sync-alt';
      case Enums.ImageActionButtonType.SyncChildData:
        return 'sync-alt';
      case Enums.ImageActionButtonType.ShowArchived:
        return 'eye';
      case Enums.ImageActionButtonType.HideArchived:
        return 'eye-slash';
      case Enums.ImageActionButtonType.Link:
        return 'link';
      case Enums.ImageActionButtonType.Unlink:
        return 'unlink';
      case Enums.ImageActionButtonType.MakeAvailableOffline:
        return 'cloud-download-alt';
      case Enums.ImageActionButtonType.MakeUnavailableOffline:
        return 'network-wired';
      default:
        return super.defaultIcon();
    }
  }

  private async copyRecord(record: Record, templateCopy: boolean) {

    const copyInfo: RecordCopyInfo = {
      TemplateCopy: templateCopy,
      CopyNotes: this.ImageActionButtonConfig?.CopyNotes ?? false,
      CopyComments: this.ImageActionButtonConfig?.CopyComments ?? false,
      CopyAttachments: this.ImageActionButtonConfig?.CopyAttachments ?? false,
      CopyHistory: this.ImageActionButtonConfig?.CopyHistory ?? false,
    };

    if (this.ImageActionButtonConfig?.ShowCopyOptionsDialogue) {
      const params: RecordCopyParameters = {
        appModel: this.appModel,
        id: record._id,
        hierarchy: record.Hierarchy,
        appIdentifier: this.appModel.app.value.Identifier,
        appIdentifiers: this.appModel.appIdentifiers.value,
        RecordCopyInfo: copyInfo
      };

      await this.appModel.globalModel.dialogAsync(RecordCopyComponent, params);
    } else {
      await this.appModel.copyRecord(record._id, copyInfo, null);
    }
  }

  private async triggerWorkflow(app: Application, record: Record): Promise<boolean> {
    if (record) {
      // this.workflowItemsRepository.triggerFieldWorkflow(this.appIdentifier, this.fieldModel.Identifier, this.record._id);
      const trigger: TriggeredWorkflow = {
        appIdentifier: app.Identifier,
        fieldId: this.Identifier
      };
      if (record._id) {
        trigger.recordId = record._id;
      }
      return await this.workflowTriggerService.queueWorkflow(trigger);
    }

    return false;
  }

  public async cancelWorkflow(app: Application, record: Record) {
    const trigger: TriggeredWorkflow = {
      appIdentifier: app.Identifier,
      fieldId: this.Identifier
    };
    if (record._id) {
      trigger.recordId = record._id;
    }

    await this.workflowTriggerService.cancelWorkflow(trigger);
  }

  private async submit(record: Record) {
    const globalModel = this.appModel.globalModel;
    if (globalModel.online.value) {
      const app = this.appModel.app.value;
      const updated = await this.recordPersistService.persistRecord(app, record._id);
      if (updated) {
        globalModel.showSuccessToasty({
          message: $localize`${app.Name} updated`
        });
      } else {
        globalModel.showWarningToasty({
          title: $localize`Could not update record`,
          message: $localize`Please try again later.`
        });
      }
    } else {
      globalModel.showWarningToasty({
        title: $localize`No connection to Server`,
        message: $localize`Please try to update when you are online.  Your changes will be stored until you reconnect.`
      });
    }
  }

  private async executeLogicBlock(app: Application, record: Record, listRow?: number) {
    const blockId = this.LogicBlocks?.Default;
    const block = app.getLogicBlock(blockId);
    if (block) {
      try {
        await this.executor.execute(block, { record, listRow });
      } catch (error) {
        logError(error, 'TextButton exec logic block failed');
      }
    } else {
      throw new Error(`Logic button ${this.Identifier} must specify a valid logic block id=${blockId}`);
    }
  }

  private async archive(context: IPerformContext) {
    if (context.generalController) {
      await context.generalController.archive();
    }
  }

  private async unarchive(context: IPerformContext) {
    if (context.generalController) {
      await context.generalController.unarchive();
    }
  }

  private async copy(context: IPerformContext) {
    if (context.recordUpdateController) {
      await context.recordUpdateController.copy();
    }
  }

  private async delete(context: IPerformContext) {
    if (context.generalController) {
      await context.generalController.delete();
    }
  }

  private async security(context: IPerformContext) {
    if (context.generalController) {
      await context.generalController.security();
    }
  }

  private async import(context: IPerformContext) {
    await context.generalController?.import();
  }

  private async export(context: IPerformContext) {
    await context.generalController?.export();
  }

  private async history(context: IPerformContext) {
    if (context.recordUpdateController) {
      await context.recordUpdateController.history();
    }
  }

  private async refresh(context: IPerformContext) {
    if (context.recordModel) {
      await context.recordModel.reload();
    }
  }

  public async link(context: IPerformContext) {
    if (context.reportModel) {
      await context.reportModel.link();
    }
  }

  public async unlink(context: IPerformContext) {
    if (context.reportModel) {
      await context.reportModel.unlink();
    }
  }

  public async makeOffline(context: IPerformContext, available: boolean) {
    if (context.reportModel) {
      await context.reportModel.makeOffline(available);
    }
  }

  private async syncChildData(context: IPerformContext) {
    if (context.recordUpdateController) {
      await context.recordUpdateController.syncChildData();
    }
  }

  private showArchived(context: IPerformContext, show: boolean) {
    const appModel = context.reportModel?.appModel ?? context.recordModel?.appModel;
    if (appModel) {
      appModel.globalModel.archived.value = show;
    }
  }

  private async discardChanges(context: IPerformContext) {
    await context.generalController?.discardChanges(context.record);
  }

  private async openRecord(context: IPerformContext) {
    const globalModel = context.appModel.globalModel;

    const appIdentifiers = this.appModel?.appIdentifiers.value;
    if (appIdentifiers && context.record.Hierarchy) {
      await globalModel.navigation.navigateChildRecordAsync({
        appIdentifiers,
        recordId: context.record._id
      });
    } else {
      await globalModel.navigation.navigateRecordAsync({
        appIdentifier: context.appModel.app.value.Identifier,
        recordId: context.record._id
      });
    }
  }

  private async gotoParent(navigation: NavigationController, record: Record) {

    const globalModel = this.appModel.globalModel;
    const siteModel = this.appModel.siteModel;

    if (record.Hierarchy) {
      const [parentAppId, parentRecordId] = record.Hierarchy.split('|');

      // Check the record is real and accessible
      const parentApp = siteModel.getApp(parentAppId);
      const parentRecord = await parentApp?.getRecordByIdAsync(parentRecordId);
      if (parentRecord) {
        const ids = this.appModel.appContext.appIdentifiers.value.pop();
        if (ids.appIdentifier !== parentAppId) {
          // We're not in a parent context so use a default
          ids.ancestorAppIds = [];
          ids.parentAppIdentifier = null;
          ids.appIdentifier = parentAppId;
        }

        await navigation.navigateRecordAsync({
          appIdentifiers: ids,
          recordId: parentRecordId
        });
      } else {
        globalModel.showErrorToasty({
          message: $localize`Cannot access parent record`
        });
      }
    } else {
      globalModel.showWarningToasty({
        message: $localize`No parent record defined`
      });
    }

  }

}
