import { EmbeddedApplication } from '../embedded-application';
import { Record, RecordId, UserStorageService, UserInvitationInfo, SiteRepository, logError } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserVerificationAppConfig } from './user-verification-app.config';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

interface VerificationModel {
  key: string;
}

@Injectable({ providedIn: 'root' })
export class UserVerificationApp extends EmbeddedApplication<VerificationModel> {

  private verificationModel: VerificationModel;

  /** Theme colour - loaded when we know the invited tenant */
  private themeColour: string;

  constructor(
    private usersService: UserStorageService,
    private siteRepository: SiteRepository,
    private models: GlobalModelService
  ) {
    super(UserVerificationAppConfig);
  }

  public override get permitted() {
    return true;
  }

  public toRecord(value: VerificationModel): Record {

    const record = value && {
      ...value,
      _id: value.key,
      IsArchived: false,
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',

    } as Record;

    return record;
  }

  public fromRecord(record: any): UserInvitationInfo {
    return record && {

      Key: record._id,
      Username: record.Username,
      FirstName: record.FirstName,
      LastName: record.LastName,
      Password: record.Password,
      ConfirmPassword: record.ConfirmPassword,
      UsernameNotRequired: record.UsernameNotRequired,

    } as UserInvitationInfo;
  }

  public override async eachRecord(callback: (record: Record) => any): Promise<void> {
    callback(this.toRecord(this.verificationModel));
  }

  public override async getRecordByIdAsync(id: RecordId): Promise<Record> {

    try {

      if (!this.verificationModel) {
        await this.usersService.confirmEmail(id);
        this.verificationModel = { key: id };
      }

      // Can do this is we return tenant from the API
      // if (!this.themeColour) {
      //   const site = await this.siteRepository.getSiteLoginDataResource(this.verificationModel.Tenant).toPromise();
      //   this.themeColour = site.DefaultTheme;
      //   document.documentElement.style.setProperty('--primary', this.themeColour);
      // }

      return this.toRecord(this.verificationModel);
    } catch (error) {
      logError(error, '');
      if (error instanceof HttpErrorResponse) {
        // navigate to error page
        this.models.globalModel.navigation.navigateErrorAsync('badInvitationKey').catch(error => logError(error, ''));

        // Need to return something or resolver will also navigate
        return this.toRecord({ key: id });
      }
    }

    return null;
  }

  public async upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    return null;    // nop, we never update
  }
}
