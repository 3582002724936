
<div class="search-container" cdkOverlayOrigin #overlayOriginEl="cdkOverlayOrigin">
  <app-search
    #searchEl
    [searchText]="searchText"
    [searchPending]="searchPending"
    (onSearch)="onSearch.emit($event)"
    (onEnter)="onSearchEnterHandler($event)"
    (onEscape)="onSearchEscape.emit()">
  </app-search>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOriginEl"
  [cdkConnectedOverlayOpen]="options.length > 0"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'no-background'"
  (backdropClick)="onBackdropClickHandler($event)">

  <div class="options-container card bg-white">
    <cdk-virtual-scroll-viewport itemSize="35" [style.height]="getOptionsHeight()">
      <div *cdkVirtualFor="let option of options" class="option-item pointer p-2" (click)="onOptionSelected.emit(option)">
        <div class="option-value">{{option.text}}</div>
        <fa-icon [icon]="['fal', 'external-link']" [border]="false" size="sm" class="float-end"></fa-icon>
      </div>
    </cdk-virtual-scroll-viewport>

    <div *ngIf="options.length > 0" class="card-block px-2 text-muted d-flex options-footer-bar bg-dark">
      <img *ngIf="hasFooterAction" (click)="onFooterActionClickHandler()" class="pointer mt-2 mb-2" [src]="footerActionImgSrc" />
      <small class="ms-auto mt-3 float-end matches" i18n>{{options.length}} matches</small>
    </div>
  </div>
</ng-template>
