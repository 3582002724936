import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotesService, Note, RecordId, OnlineStatusService, logError } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';
import { AppIdentifier } from 'app/types/application';
import { Subscription } from 'rxjs';

export interface NotesComponentData {
  appIdentifier: AppIdentifier;
  recordId: RecordId;
  field: AppField;
  fieldIdentifier: string;
  editable: boolean;
}

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  // push disabled as much of this is async and not based on component inputs
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent implements OnInit, OnDestroy {

  public appIdentifier = this.data.appIdentifier;
  public recordId = this.data.recordId;
  public fieldIdentifier = this.data.fieldIdentifier;
  public editable = this.data.editable;

  public loading = true;
  public offline: boolean;
  public addingNote = false;
  public notesData: Array<Note> = [];

  public newNoteText;

  private backdropClick: Subscription;

  @ViewChild('noteText') textArea: ElementRef<HTMLTextAreaElement>;

  constructor(
    private notesService: NotesService,
    public onlineService: OnlineStatusService,
    public dialogRef: MatDialogRef<NotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotesComponentData,
  ) {
    this.backdropClick = dialogRef.backdropClick().subscribe(_ => this.Close());
  }

  ngOnDestroy(): void {
    this.backdropClick.unsubscribe();
  }

  async ngOnInit() {
    this.dialogRef.afterOpened().subscribe(async () => {
      if (this.recordId) {
        this.loading = true;

        // if (this.ui.IsOnline) {
        this.notesData = await this.setNotesData();
        // }
      }
    });
  }

  private async setNotesData() {
    try {
      const notes = await this.notesService.getNotes(this.appIdentifier, this.recordId, this.fieldIdentifier);
      return notes;
    } catch (error) {
      logError(error, '');
    } finally {
      this.loading = false;
    }
  }

  AddNew(): void {
    this.addingNote = true;
    setTimeout(() => {
      this.textArea.nativeElement.focus();
    }, 0);
  }

  async SaveNew() {
    this.addingNote = false;
    const newNote: Note = {
      AppIdentifier: this.appIdentifier,
      Date: new Date().toString(),
      Id: null,
      ParentFieldIdentifier: this.fieldIdentifier,
      ParentRecordId: this.recordId,
      Text: this.newNoteText
    };

    // Push to our notes model so we don't need to get fresh from server
    this.notesData.push(newNote);


    this.newNoteText = null;

    // Push update to server.
    await this.notesService.patchNotes(this.appIdentifier, this.recordId, this.fieldIdentifier, this.notesService.createPatchChanges(this.fieldIdentifier, newNote.Text));

  }

  CancelNew(): void {
    this.addingNote = false;
    this.newNoteText = null;
  }

  Close(): void {
    const lastNote = this.notesData && this.notesData[this.notesData.length - 1] ? this.notesData[this.notesData.length - 1].Text : null;
    this.dialogRef.close(lastNote);
  }

  public get displayLabel() {
    return this.data.field && this.data.field.Label;
  }
}
