<div #container floatLabel="always"
  tabindex="-1"
  class="w-100 multi-header d-flex"
  [ngClass]="alignment"
  (mouseenter)="hasFocus = true"
  (mouseleave)="hasFocus = false"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
>
    <input [id]="fieldId()" [name]="fieldModel?.Identifier" matInput style="display: none!important;" readonly>
    <div #multistate class='multistate' [class.pointer]="enabled$|async"  (click)="onClickHandler($event)" [ngClass]="className$|async"></div>
</div>
