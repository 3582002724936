import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-zoom-widget',
  templateUrl: './zoom-widget.component.html',
  styleUrls: ['./zoom-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomWidgetComponent implements OnChanges {

  @Input() public value = 1;

  @Output() public zoomed = new EventEmitter<number>();

  public zoomerOpen$ = new BehaviorSubject(false);

  public preview$ = new BehaviorSubject('');

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const value = Math.round(this.value * 100);
      this.preview$.next(`${value}%`);
    }
  }

  public openZoomer(open = true) {
    this.zoomerOpen$.next(open);
  }

  public finished($event: MouseEvent) {
    this.zoomed.emit(this.value);
    this.openZoomer(false);
  }

  public reset(event: MouseEvent) {
    this.value = 1;
    this.preview$.next('100%');
    this.finished(event);
  }

  public updated($event) {
    const value = Math.round(+$event.target.value);
    this.value = value / 100;
    this.preview$.next(`${value}%`);
  }
}
