
import { Enums, QueryParams } from '@softools/softools-core';
import { BehaviorSubject } from 'rxjs';

// export interface Folder {
//   Title: string,
//   Identifier: string,
//   Type: Enums.ReportTypes | string,
//   SecondaryType: Enums.ChartType | string,
//   AppIdentifier?: string,
//   Url: string,
//   QueryParams?: QueryParams,
//   SubFolders?: Array<Folder>,
//   HasAdd: boolean
// }

/**
 * This is the root of the folder structure used to structure the left hand navigation bar.
 * Each of these groups contains a seperate class of navigation items
 *
 * It contains zero or more (usually one or more) sets of navigation item
 **/
export class RootFolders {
  FolderGroups = new Array<FolderGroup>();
}

export enum FolderType {
  Application,
  LinkedApplication,
  Homepages,
  SubFolder,
}

export class FolderGroup {
  HeaderItem?: NavigationItem;
  NewItemUrl?: string;
  RecordType?: string;
  Items = new Array<NavigationItem>();
  SubFolders: Array<FolderGroup>;
  Expanded: boolean;
  DataModel: { Identifier: string };

  constructor(
    public Type: FolderType,
    public Title = '',
    public Identifier = ''
  ) { }

  public subfolder(category: string): FolderGroup {
    if (!this.SubFolders) {
      this.SubFolders = new Array<FolderGroup>();
    }

    let subfolder = this.SubFolders.find(f => f.Identifier === category);
    if (!subfolder) {
      subfolder = new FolderGroup(FolderType.SubFolder, category, category);
      this.SubFolders.push(subfolder);
    }

    return subfolder;
  }
}

export interface NavigationItem {
  Type?: Enums.ReportTypes | string;
  SecondaryType?: Enums.ChartType | string;
  AppIdentifier?: string;
  ChildAppIdentifier?: string;
  Identifier?: string;
  Url?: string;
  ChildAppHomeUrl?: string;
  ChildAppDefaultReport?: string;
  QueryParams?: QueryParams;
  HasAdd?: boolean;
  ChildName?: string;
  ChildNamePlural?: string;
  ChildCount?: number;
  childCount$?: BehaviorSubject<number>;
  ParentRecordId?: string;
  ParentAppIdentifier?: string;
  MustBeOnline?: boolean;
  Title?: string;
}
