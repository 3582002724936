<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <div class="cardv2-header">
      <div>
        <span *ngIf="isConfirmDelete" i18n>Confirm Delete</span>
        <span *ngIf="isConfirmSetBaseFilter" i18n>Set base filter</span>
        <span *ngIf="isConfirmClearBaseFilter" i18n>Clear base filter</span>
        <span *ngIf="isFilterDeleted" i18n>Filter Deleted</span>
        <span *ngIf="isComplexFilter" i18n>Complex Filter</span>
        <span *ngIf="isConfirmBulkUnlink" i18n>Confirm Unlink</span>
        <span *ngIf="isConfirmBulkSecurityOverride" i18n>Confirm Override</span>
        <span *ngIf="isDisplayBaseFilter" i18n>Base Filter</span>
        <span *ngIf="isAppDataDeleted" i18n>Deleted</span>
        <span *ngIf="isNoSelection" i18n>No Records Selected</span>
        <span *ngIf="isConfirmArchive" i18n>Confirm Archive</span>
        <span *ngIf="isConfirmArchiveAll" i18n>Confirm Archive All</span>
        <span *ngIf="isConfirmUnarchive" i18n>Confirm Unarchive</span>
        <span *ngIf="isConfirmUnarchiveAll" i18n>Confirm Unarchive All</span>
        <span *ngIf="isConfirmUnlink" i18n>Confirm Unlink</span>
        <span *ngIf="isConfirmConfirmDeleteAll" i18n>Confirm Delete All</span>
        <span *ngIf="isConfirmDeactivate" i18n>Confirm Deactivate</span>
        <span *ngIf="isConfirmReactivate" i18n>Confirm Reactivate</span>
        <span *ngIf="isConfirmReactivateAll" i18n>Confirm Reactivate All</span>
        <span *ngIf="isDisallowAll" i18n>All Records Selected</span>
      </div>
    </div>
    <div class="cardv2-body">
      <p>
        <span *ngIf="isConfirmDeleteFilter" i18n>Are you sure you want to delete filter '{{ value }}'?</span>

        <!-- <span *ngIf="isConfirmDeleteOther" i18n>Are you sure you want to delete '{{value}}'?</span> -->

        <span *ngIf="isFilterDeleted" i18n>Filter '{{ value }}' has been deleted</span>
        <span *ngIf="isComplexFilter" i18n>Filter has multiple terms so must be edited in side bar</span>
        <span *ngIf="isConfirmBulkUnlink" i18n>Are you sure you want to unlink '{{ value }}' record(s)?</span>
        <span *ngIf="isConfirmBulkSecurityOverride" i18n>Please note existing access rights will be overridden</span>
        <span *ngIf="isDisplayBaseFilter" i18n>This report uses the following base filter to select records:</span>

        <span *ngIf="isConfirmSetBaseFilter" i18n>Do you want to change the base filter for this report? This will affect all users.</span>
        <span *ngIf="isConfirmClearBaseFilter" i18n
          >Do you want to remove the base filter for this report? This will affect all users.</span
        >

        <ng-container *ngIf="isConfirmDeleteAppData">
          <span *ngIf="dialogData.Count === 1" i18n>Are you sure you want to delete the selected {{ dialogData.Application.Name }}?</span>
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to delete the {{ dialogData.Count }} selected {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <span *ngIf="isAppDataDeleted" i18n>{{ dialogData.Application?.NamePlural }} have been deleted</span>

        <span *ngIf="isNoSelection" i18n>Please select at least one record</span>

        <ng-container *ngIf="isConfirmArchive">
          <span *ngIf="dialogData.Count === 1" i18n>Are you sure you want to archive the selected {{ dialogData.Application.Name }}?</span>
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to archive these {{ dialogData.Count }} {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmArchiveAll">
          <span i18n *ngIf="dialogData.Count !== 1"
            >Are you sure you want to archive all {{ dialogData.Count }} selected {{ dialogData.Application.NamePlural }}?</span
          >
          <span i18n *ngIf="dialogData.Count === 1">Are you sure you want to archive the selected {{ dialogData.Application.Name }}?</span>
        </ng-container>

        <ng-container *ngIf="isConfirmUnarchive">
          <span *ngIf="dialogData.Count === 1" i18n
            >Are you sure you want to unarchive the selected {{ dialogData.Application.Name }}?</span
          >
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to unarchive these {{ dialogData.Count }} {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmUnarchiveAll">
          <span i18n *ngIf="dialogData.Count !== 1"
            >Are you sure you want to unarchive all {{ dialogData.Count }} selected {{ dialogData.Application.NamePlural }}?</span
          >
          <span i18n *ngIf="dialogData.Count === 1"
            >Are you sure you want to unarchive the selected {{ dialogData.Application.Name }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmDeactivate">
          <span *ngIf="dialogData.Count === 1" i18n
            >Are you sure you want to deactivate the selected {{ dialogData.Application.Name }}?</span
          >
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to deactivate these {{ dialogData.Count }} {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmReactivate">
          <span *ngIf="dialogData.Count === 1" i18n
            >Are you sure you want to reactivate the selected {{ dialogData.Application.Name }}?</span
          >
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to reactivate these {{ dialogData.Count }} {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmReactivateAll">
          <span i18n *ngIf="dialogData.Count !== 1"
            >Are you sure you want to reactivate all {{ dialogData.Count }} selected {{ dialogData.Application.NamePlural }}?</span
          >
          <span i18n *ngIf="dialogData.Count === 1"
            >Are you sure you want to reactivate the selected {{ dialogData.Application.Name }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmConfirmDelete">
          <span *ngIf="dialogData.Count === 1" i18n>Are you sure you want to delete the selected {{ dialogData.Application.Name }}?</span>
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to delete these {{ dialogData.Count }} {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmConfirmDeleteAll">
          <span i18n *ngIf="dialogData.Count !== 1"
            >Are you sure you want to delete all {{ dialogData.Count }} selected {{ dialogData.Application.NamePlural }}?</span
          >
          <span i18n *ngIf="dialogData.Count === 1">Are you sure you want to delete the selected {{ dialogData.Application.Name }}?</span>
        </ng-container>

        <ng-container *ngIf="isDisallowAll">
          <span i18n>All {{ dialogData.Application.NamePlural }} would be deactivated. Please select individual record to deactivate.</span>
        </ng-container>

        <ng-container *ngIf="isConfirmUnlink">
          <span *ngIf="dialogData.Count === 1" i18n>Are you sure you want to unlink the selected {{ dialogData.Application.Name }}?</span>
          <span *ngIf="dialogData.Count > 1" i18n
            >Are you sure you want to unlink these {{ dialogData.Count }} {{ dialogData.Application.NamePlural }}?</span
          >
        </ng-container>

        <ng-container *ngIf="isConfirmUnlinkAll">
          <span i18n *ngIf="dialogData.Count !== 1"
            >Are you sure you want to unlink all {{ dialogData.Count }} selected {{ dialogData.Application.NamePlural }}?</span
          >
          <span i18n *ngIf="dialogData.Count === 1">Are you sure you want to unlink the selected {{ dialogData.Application.Name }}?</span>
        </ng-container>

        <span i18n *ngIf="isConfirmCancelImport">Are you sure you want to cancel this import?</span>
      </p>
      <p *ngIf="isDisplayBaseFilter" class="alert alert-secondary">
        <span>{{ value }}</span>
      </p>
    </div>
    <div class="cardv2-footer">
      <div *ngIf="isYesNo">
        <button class="primary" type="submit" (click)="submit($event)" i18n="@@Yes">Yes</button>
        <button type="button" class="close" mat-dialog-close i18n="@@No">No</button>
      </div>
      <div *ngIf="isMessage">
        <button type="button" mat-dialog-close class="close pointer" i18n="@@Close">Close</button>
      </div>
    </div>
  </div>
</div>
