import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { LOGIN_RETURN_URL } from 'app/_constants/constants.keys';

@Injectable({ providedIn: 'root' })
export class ReturnUrlGuard implements CanActivate {
  constructor(private router: Router) {}

  public async canActivate(): Promise<UrlTree | boolean> {
    const returnUrl = localStorage.getItem(LOGIN_RETURN_URL);

    // Delete the return URL so its not used on next login
    localStorage.removeItem(LOGIN_RETURN_URL);

    if (returnUrl && returnUrl.length > 0) {
      return this.router.parseUrl(returnUrl);
    }

    return true;
  }
}
