<div class="position-fixed w-100 h-100 text-center bg-white" style="padding-top: 10%; top: 0">
  <h1 class="display-1" i18n>Browser Incompatibility
      <fa-icon [icon]="['fal', 'exclamation-circle']"></fa-icon></h1>

  <div class="p-3" i18n>Please use a modern browser to access the offline first
    <a href="https://www.softools.net" target="_blank">Softools</a> Web Application
    <!-- <span class="badge badge-pill badge-light">
      <span><a [href]="baseUrl" target="_blank">Click here for compatible version</a></span>
    </span> -->
  </div>



  <div class="p-3">
    <span *ngIf="!hasCssGrid" i18n="@@CssGridIsNotSupported">CSS Grid is not Supported</span><br />
    <span *ngIf="!hasIndexedDb" i18n="@@IndexedDbIsNotSupported">IndexedDb is not Supported</span>
  </div>

  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column p-3">
      <a href="https://www.google.com/chrome/"><img width=48 height=48 src="/assets/browser-icons/chrome-logo.svg"></a>
      86+
    </div>
    <div class="d-flex flex-column p-3">
      <a href="https://www.mozilla.org/en-GB/firefox/"><img width=48 height=48 src="/assets/browser-icons/Firefox_Logo_2017.svg"></a>
      83+
    </div>
    <div class="d-flex flex-column p-3">
      <a href="https://www.microsoft.com/en-gb/windows/microsoft-edge"><img width=48 height=48 src="/assets/browser-icons/Microsoft_Edge_logo.svg"></a>
      86+
    </div>
    <div class="d-flex flex-column p-3">
      <a href="https://support.apple.com/en-us/HT204416"><img width=48 height=48 src="/assets/browser-icons/Safari_browser_logo.svg"></a>
      IOS 13.2+<br>
      Mac 12+
    </div>
  </div>
</div>
