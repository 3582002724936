import { Record } from '../types';

export interface IRecordConcretizer {
  concreteRecord(record: Record): Record;
}

// Acts as interface for service and as injection token
export class RecordConcretizerService implements IRecordConcretizer {

  public concreteRecord(record: Record): Record {
    return record;
  }
}
