import { Injectable } from '@angular/core';
import { HomepagesRepository } from '../repos';
import { HomepageDashboardField } from '../types/interfaces';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { HOMEPAGE_DASH_FIELDS } from '../indexedDb/database-stores';
import { DatabaseContextService } from '../indexedDb/database-context.service';

@Injectable({ providedIn: 'root' })
export class HomePageDashboardFieldsStorageService extends StorageServiceBase<HomepageDashboardField, [string, string]> {

  constructor(
    public repository: HomepagesRepository,
    dbContext: DatabaseContextService<HomepageDashboardField>) {
    super(HOMEPAGE_DASH_FIELDS, dbContext);
  }

  public async syncHomepageDashboardFields(retryPolicy: IRetryPolicy = null): Promise<Array<HomepageDashboardField>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const homepageDashboardFields = await policy.execute(() => this.repository.getHomepageDashboardFields().toPromise());
    await Promise.all(homepageDashboardFields.map(async (field: HomepageDashboardField) => {
      const key = this._getKey(field.AppIdentifier, field.Identifier);
      await this.save(key, field);
    }));

    return homepageDashboardFields;
  }

  public getHomepageDashboardField(appIdentifier: string, fieldIdentifier: string): Promise<HomepageDashboardField> {
    const key = this._getKey(appIdentifier, fieldIdentifier);
    return this.get(key);
  }

  private _getKey(appIdentifier: string, fieldIdentifier: string): [string, string] {
    return [appIdentifier, fieldIdentifier];
  }
}
