import { logError } from '@softools/softools-core';
import { Subject } from 'rxjs';

/** */
export interface QueueFunctionOptions {
  priority?: boolean;
}

/** */
export class QueueFunction {
  public promise: Promise<boolean>;
  private _resolve: (value: boolean | PromiseLike<boolean>) => void;

  constructor(private func: () => Promise<boolean>, private options?: QueueFunctionOptions) {
    this.promise = new Promise<boolean>(resolve => {
      this._resolve = resolve;
    });
  }

  public async run() {
    const result = await this.func();
    this._resolve(result);
  }
}

/** */
export class FunctionQueue {

  public available$ = new Subject<void>();

  private functions: Array<QueueFunction> = [];

  public constructor() {
    this.available$.subscribe(() => {
      let entry: QueueFunction;
      while (entry = this.functions.shift()) {
        entry.run().catch(e => logError(e, 'run function'));
      }
    });
  }

  public async run(func: () => Promise<boolean>, options?: QueueFunctionOptions): Promise<boolean> {
    const entry = new QueueFunction(func);
    if (options?.priority) {
      this.functions.unshift(entry);
    } else {
      this.functions.push(entry);
    }

    this.available$.next();
    const complete = await entry.promise;
    return complete;
  }
}
