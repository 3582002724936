<aside #leftAside *ngIf="showFoldersBar && !isSmallDevice"
  [@leftAsideState]="pinnedStateVal"
  (@leftAsideState.done)="sideChanged()"

  >
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</aside>

<div #leftAside *ngIf="showFoldersBar && isSmallDevice"
  [@flyInOut]="pinnedStateVal === 'active' ? 'in' : 'out'"
  class="fixed-bottom content-wrapper bg-dark"
  (click)="$event.stopPropagation();">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>

<div *ngIf="isSmallDevice && pinnedStateVal === 'active'" id="backdrop" class="fixed-top fixed-bottom" (click)="closeAside()"></div>

<ng-template #itemrow let-folder="item">
  <div [attr.title]="folder.Title" role="button" class="menu-item" [ngClass]="{active: isFolderActive(folder)}">

    <ng-container *ngIf="isFormSelectItem(folder)">
        <div class="pointer form-folder" (click)="selectForm($event, folder)">
          <ng-container *ngTemplateOutlet="folderItem; context: { folder: folder }" ></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="!isFormSelectItem(folder)">

        <a *ngIf="!folder.HasAdd" routerLink="{{folder.Url}}" [queryParams]="folder.QueryParams">
          <ng-container *ngTemplateOutlet="folderItem; context: { folder: folder }" ></ng-container>
        </a>

        <div *ngIf="folder.HasAdd" (click)="toggleSubFolders($event, folder)">
            <ng-container *ngTemplateOutlet="folderItem; context: { folder: folder }" ></ng-container>
        </div>

    </ng-container>

  </div>
</ng-template>

<ng-template #folderItem let-folder="folder">
    <div class="d-flex p-1 ps-2">
        <div style="width: 27px;">
          <app-foldericon [FolderType]="folder.Type" [SecondaryFolderType]="folder.SecondaryType"></app-foldericon>
        </div>
        <div role="button" class="text-nowrap text-truncate" style="max-width: 14rem;"
          [class.text-muted]="folder.MustBeOnline && !(onlineStatusService.isServerReachable$|async)">
          {{folder.Title}}
        </div>
        <div *ngIf="folder.MustBeOnline && !(onlineStatusService.isServerReachable$|async)">
          <fa-icon class="pt-1 pe-1 text-muted" [icon]="['fal', 'wifi-slash']" ></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #content>
  <!-- <div *ngIf="loading" class="container text-center pt-4">
    <fa-icon [icon]="['fal', 'spinner']" size="2x" [spin]=true class="text-white"></fa-icon>
  </div> -->

  <div [vxWatch]="folderModel?.folderGroups" class="folders-wrapper">
    <div style="overflow-y: auto;">
      <div *ngFor="let group of folderModel?.folderGroups.value; let index = index;">
        <div class="d-flex lead p-2 font-weight-normal menu-header">

          <!-- <div *ngIf="group.Type === folderTypes.LinkedApplication" style="flex: 1" class="text-truncate pe-1" title="{{group.Title||title}}">
            <span class="ps-1" i18n>Linked Apps</span>
          </div> -->
          <div *ngIf="group.Type !== folderTypes.LinkedApplication" style="flex: 1" class="text-truncate pe-1" title="{{group.Title||title}}">
            <div [vxWatch]="folderModel.headerApp" *ngIf="(folderModel?.appHomeUrl?.$|async)?.length > 0" i18n-title="@@AppHome" title="App Home" role="button">
                <a matRipple [routerLink]="getAppHomeUrl()" class="d-flex align-items-center p-1 ps-2">
                  <app-icon [iconBase64]="folderModel.headerApp.value?.IconBase64" size="1.7rem"></app-icon>
                  <div role="button" class="text-nowrap ps-2 me-2 text-truncate">{{group.Title||title}}</div>
                </a>
            </div>
            <div *ngIf="!((folderModel?.appHomeUrl?.$|async)?.length) && (group?.Title ?? title).length > 0" class="d-flex p-1 ps-2">
              <fa-icon [icon]="['fal', 'home']" size="2x" class="icon" style="font-size: 0.85rem;"></fa-icon>
              <div role="button" class="text-nowrap ps-2 me-2 text-truncate">{{group?.Title ?? title}}</div>
            </div>
          </div>

          <!-- Show count on app (simple or parent) -->
          <ng-container *ngIf="group.Type === folderTypes.Application">
            <ng-container *ngIf="folderModel?.count.$|async as count"  >
              <div *ngIf="group.Type === folderTypes.Application && !(folderModel?.countBusy.$|async) && count >= 0" class="pt-0 pe-1"
                title="{{count}}">
                <small class="rounded text-white p-1">{{count}}</small>
              </div>
            </ng-container>
            <div *ngIf="folderModel?.countBusy.$|async" class="pt-0 pe-0">
              <small class="rounded text-white p-1">
                <sof-spinner-icon></sof-spinner-icon>
              </small>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="group.Type === folderTypes.Application">
          <div *ngIf="!(globalModel.archived.$|async) && isNewRecordVisible && group.NewItemUrl" matRipple title="Add New Record" i18n-title="@@AddNewRecord" role="button" class="menu-item">
            <a routerLink="{{group.NewItemUrl}}"
            class="d-flex p-1 ps-2">
              <sof-plus-icon size="1x" class="icon"></sof-plus-icon>

              <div *ngIf="!createPrompt" role="button" class="text-nowrap me-2 text-truncate" i18n="@@NewRecord">
                New Record
              </div>
              <div *ngIf="createPrompt" role="button" class="text-nowrap ps-2 me-2 text-truncate">
                {{createPrompt}}
              </div>
              <!-- Following is better but need to check existing apps have correct record names.  Some are corrupt. -->
              <!-- <div role="button" class="text-nowrap ps-2 me-2 text-truncate" i18n="@@NewRecord">
                New {{group.RecordType}}
              </div> -->
            </a>
          </div>
        </ng-container>


        <!-- Directly contained items -->
        <div *ngFor="let item of group.Items; let itemIndex = index; " attr.id="folderItem{{itemIndex}}">
          <ng-container *ngTemplateOutlet="itemrow; context: {item: item}"></ng-container>
        </div>

        <!-- Sub-folders -->
        <ng-container *ngIf="group.SubFolders">
          <ng-container *ngFor="let subfolder of group.SubFolders; let subfolderIndex = index; ">
            <ng-container *ngTemplateOutlet="sub; context: { subfolder: subfolder, index: index + subfolderIndex }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div  class="m-3">
      <a href="https://www.softools.net/" target="_blank">
        <img src="/assets/icons/softools-inverted.png" [matTooltip]="'Softools version ' + appVersion" style="width: 10rem" />
      </a>
    </div>
  </div>
</ng-template>

<!-- Render a sub-folder.  Called recursively for child report folders etc. -->
<ng-template #sub let-subfolder="subfolder" let-index="index">
  <div attr.id="folderGroup{{index}}" [ngClass]="{active: isFolderActive(subfolder.HeaderItem)}"
    *ngIf="subfolder.HeaderItem" class="d-flex p-3 pointer menu-header">
    <a [routerLink]="subfolder.HeaderItem.ChildAppDefaultReport" class="text-truncate flex-grow-1">
      <span class="text-nowrap">{{subfolder.HeaderItem.Title}}</span>
    </a>

    <!-- ngif to cause update when count changes  -->
    <span *ngIf="(folderModel?.childAppUpdatecount.$|async) !== -1">
      <span *ngIf="(subfolder.HeaderItem.childCount$|async) !== undefined"
        class="text-white ">{{(subfolder.HeaderItem.childCount$|async)}}</span>
      <span *ngIf="(subfolder.HeaderItem.childCount$|async) === undefined" class="text-white">
          <sof-spinner-icon></sof-spinner-icon>
        </span>
    </span>

    <a *ngIf="subfolder.HeaderItem.HasAdd && showAddNewRecord"
    routerLink="{{subfolder.HeaderItem.Url}}" [queryParams]="subfolder.HeaderItem.QueryParams"
    title="Add a new linked {{subfolder.HeaderItem.ChildName}}"
    class="ps-2 text-white">
      <sof-plus-icon></sof-plus-icon>
    </a>

    <a routerLink="{{subfolder.HeaderItem.ChildAppHomeUrl}}" [queryParams]="subfolder.HeaderItem.QueryParams" i18n-title
      title="{{subfolder.HeaderItem.Title}} Home" class="ps-2">
      <fa-icon [icon]="['fal', 'home']" size="1x"></fa-icon>
    </a>
  </div>

  <div attr.id="folderGroup{{index + 1}}" *ngIf="!subfolder.HeaderItem" class="d-flex p-3 pointer text-white"
    (click)="toggleSubFolders($event, subfolder)">
    <span class="text-truncate text-nowrap" style="text-overflow: ellipsis">{{subfolder.Title}}</span>
    <fa-icon *ngIf=" isSubFolderExpanded(subfolder)" class="pt-1 pe-1 ms-auto" [icon]="['fal', 'angle-double-up']"></fa-icon>
    <fa-icon *ngIf="!isSubFolderExpanded(subfolder)" class="pt-1 pe-1 ms-auto" [icon]="['fal', 'angle-double-down']"></fa-icon>
  </div>

  <div [@compactedSubfolderState]="compactSubFolderState(subfolder)" class="overflow-hidden" >
    <div attr.id="folderGroup{{index + 1}}Item{{subfolderItemIndex}}" *ngFor="let item of subfolder.Items; let subfolderItemIndex = index;">
      <ng-container *ngTemplateOutlet="itemrow; context: {item: item}"></ng-container>
    </div>
  </div>

  <ng-container *ngIf="isSubFolderExpanded(subfolder)" >
    <ng-container *ngFor="let subsub of subfolder.SubFolders">
      <ng-container *ngTemplateOutlet="sub; context: { subfolder: subsub, index: index * 1000 }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
