import { Injectable } from '@angular/core';
import { Report, Enums, ChartField, IndexedAppData, Record } from '@softools/softools-core';
import { SeriesData, PlotPoint } from '../chart-utilities';
import { BaseChartDataService, ChartFieldEx } from './base-chart-data.service';
import { ChartEnums } from '../chart.enums';
import { AppField } from 'app/types/fields/app-field';
import { ChartErrorException } from 'app/exceptions';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class BubbleChartDataService extends BaseChartDataService {
  public async getBubbleChartDataOptions(app: Application, appDataIndex: IndexedAppData, reportChartConfig: Report, record?: Record) {
    // const app = this.appService.application(appDataIndex.app.Identifier);
    const fields = this.setFieldsFromParameters(app, reportChartConfig);
    const xField = fields.xField;
    const yField = fields.yField;
    const zData = fields.zField;
    const labelField = fields.labelField;

    const plotPoints = await this.buildBubblePlotPoints(app, appDataIndex, reportChartConfig, xField, yField, zData, labelField, record);
    const minZ = plotPoints?.length ? plotPoints.reduce((min, value) => min.zVal < value.zVal ? min = min : min = value).zVal : 0;
    const maxZ = plotPoints?.length ? plotPoints.reduce((max, value) => max.zVal > value.zVal ? max = max : max = value).zVal : 0;

    const fixPointSize = minZ === maxZ;

    const data = await this.buildBubbleSeriesData(plotPoints, fixPointSize, minZ, maxZ, reportChartConfig.Chart.HideSeries, labelField);
    const series = new Array<any>({ data });
    return { Series: series, Hierarchy: reportChartConfig['Hierarchy'] };
  }

  private async buildBubblePlotPoints(app: Application, appDataIndex: IndexedAppData, report: Report,
    xField: ChartFieldEx, yField: ChartFieldEx, zData: ChartFieldEx, labelField: ChartFieldEx, record?: Record) {

    const colourData = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Colour);
    const plotPoints = new Array<PlotPoint>();

    // Base fields defined here as the values change for some reason after getRecordData call.
    const xBaseField = xField?.BaseAppField;
    const yBaseField = yField?.BaseAppField;
    const zBaseField = zData?.BaseAppField;
    const labelBaseField = labelField?.BaseAppField;

    const data = !!zData
      ? (await this.getRecordData(app, !!record, appDataIndex, report, record?._id, record)).sort((a, b) => zBaseField.getRawRecordValue(a) - zBaseField.getRawRecordValue(b))
      : (await this.getRecordData(app, !!record, appDataIndex, report, record?._id, record)).sort((a, b) => xBaseField.getRawRecordValue(a) - xBaseField.getRawRecordValue(b));

    data.forEach(row => {
      const pointData: PlotPoint = { id: row._id } as any;

      let xVal = 0, yVal = 0, zVal = 0;
      const xRawVal = (<AppField>xBaseField).getRawRecordValue(row);
      const yRawVal = (<AppField>yBaseField).getRawRecordValue(row);
      xVal = parseFloat(xRawVal ? xRawVal.toString() : xRawVal);
      xVal = isNaN(xVal) ? 0 : xVal;
      yVal = parseFloat(yRawVal ? yRawVal.toString() : yRawVal);
      yVal = isNaN(yVal) ? 0 : yVal;
      if (zData) {
        const zRawVal = (<AppField>zBaseField).getRawRecordValue(row);
        zVal = parseFloat((zRawVal ? zRawVal.toString() : zRawVal));
        zVal = isNaN(zVal) ? 0 : zVal;
      }

      pointData.xVal = xVal;
      pointData.yVal = yVal;
      pointData.zVal = zVal;

      if (labelField) {
        pointData.label = (<AppField>labelBaseField).getRawRecordValue(row)
          ? (<AppField>labelBaseField).getRawRecordValue(row).toString()
          : 'undefined';
      }

      if (colourData != null && colourData.BaseField) {
        const colourRawData = (<AppField>colourData.BaseField).getRawRecordValue(row);
        pointData.colour = colourRawData ? colourRawData.toString() : '';
      }

      if (!pointData.colour) {
        pointData.colour = '';
      }

      switch (pointData.colour.toLowerCase()) {
        case 'red':
          pointData.colour2 = 'rgb(255, 0, 0)';
          pointData.colour3 = 'rgb(178, 34, 34)';
          break;

        case 'amber':
          pointData.colour2 = 'rgb(255, 215, 0)';
          pointData.colour3 = 'rgb(255, 140, 0)';
          break;

        case 'green':
          pointData.colour2 = 'rgb(124, 252, 0)';
          pointData.colour3 = 'rgb(50, 205, 50)';
          break;

        case 'blue':
          pointData.colour2 = 'rgb(100, 149, 237)';
          pointData.colour3 = 'rgb(0, 0, 255)';
          break;

        case 'black':
          pointData.colour2 = 'rgb(211, 211, 211)';
          pointData.colour3 = 'rgb(0, 0, 0)';
          break;

        default:
          pointData.colour2 = 'rgb(255, 255, 255)';
          pointData.colour3 = 'rgb(211, 211, 211)';
          break;
      }

      const hoverText = (report.Chart.ChartFields).filter(f => f.Type === ChartEnums.ChartFieldType.HoverText).map(chartField => ({ ...chartField, BaseField: app.getField(chartField.BaseFieldIdentifier) }));
      pointData.HoverText = hoverText.map(o => ({
        name: o.Label ? o.Label : o.BaseField.Label,
        value: this.formatHoverText(row, <AppField>o.BaseField)
      }));

      plotPoints.push(pointData);
    });

    return plotPoints;
  }

  private buildBubbleSeriesData(plotPoints: PlotPoint[], fixPointSize: boolean, minZ: number, maxZ: number, hideSeries: boolean, labelField: ChartField) {
    const minRadius = 5;
    let maxRadius = 50;
    const minMaxVariance = maxZ - minZ;

    if (fixPointSize) {
      maxRadius = minRadius;
    }

    const seriesData = new Array<SeriesData>();

    if (plotPoints && !hideSeries) {
      plotPoints.forEach(pt => {
        let pointRadius = maxRadius;
        if (!fixPointSize) {
          const percentile = (100 * ((pt.zVal - minZ) / minMaxVariance));
          pointRadius = (minRadius + ((percentile / 100.0) * (maxRadius - minRadius)));
        }

        const data: SeriesData = {
          x: pt.xVal,
          y: pt.yVal,
          z: pt.zVal,
          color: pt.colour,
          marker: {
            radius: pointRadius,
            fillColor: {
              radialGradient: { cx: 0.4, cy: 0.4, r: 0.5 },
              stops: new Array<any>(Array<string>('0', pt.colour2), Array<string>('1', pt.colour3))
            }
          },
        } as any;


        if (pt.id != null) {
          data.id = pt.id;
        }

        if (labelField != null) {
          data.label = pt.label;
        }

        data.hoverText = pt.HoverText;

        seriesData.push(data);

      });
    }

    return seriesData;
  }

  private setFieldsFromParameters(app: Application, report: Report) {
    const xField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.X);
    const yField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Y);
    const zField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Z);
    const labelField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Label);

    if (xField == null || yField == null) {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoXorYField);
    }

    return { xField, yField, zField, labelField };
  }

}
