import { IRecordSelection, RecordId } from '@softools/softools-core';
import { BooleanModelProperty, Model, ModelEvent, ModelProperty, SetModelProperty } from '@softools/vertex';

export class SelectionModel extends Model<SelectionModel> {

  public readonly selected = new SetModelProperty<RecordId>(this).withLogging('SELECTED');

  public readonly single = new ModelProperty<RecordId>(this, null).withLogging('SINGLE');

  public readonly all = new BooleanModelProperty(this, false).withLogging('ALL');

  public readonly reset = new ModelEvent();

  public singleSelection = false;

  public initialise() {
    // this.all.logChanges = 'SELECT ALL';
  }

  public selectAll() {
    this.all.value = true;
  }

  /** Reset selections so nothing is selected */
  public deselectAll() {
    this.clearSelected();
    this.all.value = false;
    this.reset.fire();
  }

  public toggleAll() {
    const all = this.all.value;
    this.all.value = !all;
  }

  public select(id: RecordId) {

    if (this.singleSelection) {
      this.single.value = id;
    } else {
      this.selected.set(id);
    }
  }

  public deselect(id: RecordId) {
    if (this.singleSelection) {
      if (this.single.value === id) {
        this.single.value = null;
      }
    } else {
      this.selected.delete(id);
    }
  }

  public toggle(id: RecordId) {
    if (this.isSelected(id)) {
      this.deselect(id);
    } else {
      this.select(id);
    }
  }

  /** Clear the selected record list.
   *  NB this does not affect the All selected flag, use @see deselectAll 
   * if that is required */
  public clearSelected() {
    this.single.value = null;
    this.selected.clear();
  }

  public get selectedIds() {
    if (this.singleSelection) {
      const s = this.single.value;
      return s ? [s] : [];
    } else {
      return Array.from(this.selected.value.keys());
    }
  }

  /** Get single selected id */
  public get selectedId() {
    if (this.singleSelection) {
      return this.single.value;
    } else {
      return null;
    }
  }

  public get count() {
    if (this.singleSelection) {
      return this.single.value ? 1 : 0;
    } else {
      return this.selected.size;
    }
  }

  public isSelected(id: RecordId) {
    if (this.singleSelection) {
      return this.single.value === id;
    } else {
      return this.selected.has(id);
    }
  }

  public getRecordSelection(): IRecordSelection {
    return {
      AllRecordsSelected: this.all.value,
      SelectedRecordIds: this.selected.values,
    };
  }
}
