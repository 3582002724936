import { Injectable } from '@angular/core';
import { StorageServiceBase } from '@softools/softools-core';
import { LOCAL_VALUE_STORE, DatabaseContextService, RecordId, FieldKey } from '@softools/softools-core';
import { Subject } from 'rxjs';

// Key is [record id, field id, row  ]
export type LocalValueKey = [string, string, string];

export interface LocalValueChange {
  key: FieldKey;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class LocalValuesStorageServiceService extends StorageServiceBase<any, LocalValueKey> {

  /**
   * Observable that fires when a value is changed
   */
  public changed$ = new Subject<LocalValueChange>();

  constructor(
    database: DatabaseContextService<any>,
  ) {
    super(LOCAL_VALUE_STORE, database);
  }

  public async setValueAsync(imageKey: FieldKey, value: any) {
    const key = this.makeKey(imageKey);
    await this.save(key, value);

    const change = { key: imageKey, value };
    this.changed$.next(change);
  }

  public async getValueAsync(imageKey: FieldKey) {
    const key = this.makeKey(imageKey);
    return await this.get(key);
  }

  public async deleteValueAsync(imageKey: FieldKey) {
    const key = this.makeKey(imageKey);
    await this.delete(key);

    const change = { key: imageKey, value: undefined };
    this.changed$.next(change);
  }

  private makeKey(imageKey: FieldKey): LocalValueKey {
    return [imageKey.recordId, imageKey.fieldIdentifier, imageKey.rowKey || ''];
  }

}
