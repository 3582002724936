/** Parameter name for the current app identifier */
export const APP_IDENTIFIER = 'appIdentifier';

/** Parameter name for the current application record id (when a single record is selected) */
export const RECORD_ID = 'recordId';

/** Parameter name for the parent app identifier when the app is running as a child app */
export const PARENT_APP_IDENTIFIER = 'parentAppIdentifier';

/**
 * Parameter name for the child app identifier.
 * This applies when we are running in the context of an app but displaying details
 * (e.g. a report) from the child app.
 */
export const CHILD_APP_IDENTIFIER = 'childAppIdentifier';

/** Parameter prefix for anscestor app ids  when the app is running as a child app.
 * ancsetorAppIdentifier-1 is the same as parentAppIdentifier
 * ancsetorAppIdentifier-2..n are the grandparent, great grandparens etc
 */
export const ANCESTOR_APP_IDENTIFIER_PREFIX = 'ancestorAppIdentifier-';

/** Parameter name for the parent record id when the app is running as a child app */
export const PARENT_RECORD_ID = 'parentRecordId';

export const REPORT_IDENTIFIER = 'reportIdentifier';

/** Report id for a child report shown in parent context */
export const CHILD_REPORT_IDENTIFIER = 'childReportIdentifier';

/** Record cursor within a report as a 1 based numeric index */
export const RECORD_CURSOR = 'recordCursor';

export const FORM_IDENTIFIER = 'formIdentifier';

export const TEMPLATE_IDENTIFIER = 'templateIdentifier';

export const HOMEPAGE_IDENTIFIER = 'homepageIdentifier';

// only in spec now, copy and delete
export const getUpdateChildRecordUrl = (appIdentifier: string, parentAppIdentifier: string, recordId?: string) => {
  const recordIdPart = recordId || 'create';
  return `/${parentAppIdentifier}/${appIdentifier}/${recordIdPart}`;
};

export const getRecordFormUrl = (
  appIdentifier: string,
  recordId: string,
  formIdentifier: string = null,
  forNewRecord: boolean = false
): string => {
  if (forNewRecord) {
    const formIdentifierPart = formIdentifier ? `/${formIdentifier}` : '';
    return `/${appIdentifier}/${recordId}${formIdentifierPart}`;
  }

  const formIdentifierPartForUpdate = formIdentifier ? `/Form/${formIdentifier}` : '';
  return `/${appIdentifier}/${recordId}${formIdentifierPartForUpdate}`;
};

export const getHomepageUrl = (homepageIdentifier?: string) => {
  const identifierPart = homepageIdentifier ? `/${homepageIdentifier}` : '';
  return `/Homepage${identifierPart}`;
};

export const LOGIN_ROUTE = 'Account/Login';
