<div class="d-flex">
<app-input-icon icon="clock"
  [clickable]="fieldModel.IsEditable && enablePicker && !getIsDisabled()"
  [elementStyles]="elementStyles"
  (iconClicked)="picker.open()" >

  <!-- Displayed input handles manual entry -->
    <app-input *ngIf="!inDateTime"
      [appModel]="appModel"
      [reportModel]="reportModel"
      [field]="fieldModel"
      [template]="template"
      [form]="form"
      [record]="record"
      [value]="value"
      [listRow]="listRow"
      [ruleState]="ruleState"
      class="w-100"
      [class.inline-control]="inline"
      [elementStyles]="elementStyles"
      [textStyle]="textStyle"
      [inputType]="'text'"
      (valueChanged)="timeChanged($event)"
      (keydown)="onKeyDown($event)"
      (keypress)="onKeyPress($event)"
      (keyup)="onKeyUp($event)"
    >
    </app-input>

    <app-input *ngIf="inDateTime"
      [appModel]="appModel"
      [reportModel]="reportModel"
      [field]="fieldModel"
      [template]="template"
      [value]="value"
      [listRow]="listRow"
      [ruleState]="ruleState"
      class="w-100"
      [class.inline-control]="inline"
      [elementStyles]="elementStyles"
      [textStyle]="textStyle"
      [inputType]="'text'"
      (valueChanged)="timeChanged($event)"
    >
    </app-input>

</app-input-icon>

    <!-- Hidden input for time picker attachment -->
    <input hidden [ngxTimepicker]="picker" [format]="format" [value]="displayTime || ''" >
    <ngx-material-timepicker #picker
      [theme]="theme"
      (timeSet)="timeChanged($event)"
    ></ngx-material-timepicker>

    <fa-icon *ngIf="showClear && !inDateTime && !getIsDisabled()"
    class="pointer mx-1 clear-button"
    [icon]="['fal', 'times-circle']"
    title="Clear Time"
    i18n-title
    [hidden]="required"
    (click)="clearTime(); $event.stopPropagation()"
   >
  </fa-icon>
</div>