import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';

import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { VERSION_NUMBER } from 'app-version';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActionsBottomSheetComponent } from '../actions-bottom-sheet/actions-bottom-sheet.component';
import { IconPrefix } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-header-actions',
  templateUrl: 'actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsComponent {
  public version = VERSION_NUMBER;
  public showActions = false;

  public get isSmallScreen() {
    return this.breakpointObserver.isMatched([Breakpoints.Handset, Breakpoints.Tablet]);
  }

  @Input() showVersion = false;
  @Input() icon;
  @Input() iconWeight: IconPrefix = 'fal';
  @Input() actions: Array<ToolbarAction> = [];
  @Input() isOnline;
  @Input() disableWhenOffline = true;

  @Output() onActionClick = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver, private bottomSheet: MatBottomSheet, private cd: ChangeDetectorRef) { }

  public openActions() {

    this.showActions = true;
    this.cd.markForCheck();

    if (this.isSmallScreen) {
      const overlayRef = this.bottomSheet.open(ActionsBottomSheetComponent, {
        data: {
          acts: this.actions,
          ver: this.showVersion,
          verNo: VERSION_NUMBER,
          disableOff: this.disableWhenOffline,
          isOnline: this.isOnline,
          close: () => {
            overlayRef.dismiss();
          }
        }
      });
    }
  }

  public closeActions() {
    this.showActions = false;
    this.cd.markForCheck();
  }
}
