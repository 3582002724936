<div class="lookup-container card" cdkOverlayOrigin #filterOrigin="cdkOverlayOrigin">
  <div cdkOverlayOrigin #reportOrigin="cdkOverlayOrigin" #scroller class="records py-2 w-100 h-100"
    style="position: relative;">
    <ng-container *ngIf="initialised$|async">
      <div class="record-report w-100 h-100" >
        <app-table-report *ngIf="(reportModel.report.$|async)?.Type === 6" #table class="w-100 h-100" [appModel]="appModel"
          [reportModel]="tableReportModel"
          [controller]="tableReportController"
          [showRecordEdit]="false"
          [selectionMode]="tableSelectionMode()"
          (acceptClicked)="acceptClicked()">
        </app-table-report>

        <app-listreport *ngIf="listReportModel && (reportModel.report.$|async)?.Type === 1" cdkOverlayOrigin
          #rightAsideContainer="cdkOverlayOrigin" [scroller]=scroller [appModel]="appModel"
          class="w-100 h-100"
          [reportModel]="listReportModel" [filterModel]="filterModel" [showColumn]="" [hideColumn]="" [editMode]="false"
          [detailsFields]="listReportModel.listReportDetailFields.$|async" [showDetailsField]="false"
          [allowMultiSelect]="multiSelectionMode()"
          [fields]="(appModel.app.$|async)?.AppFields"
          [parentRecordId]="appModel.appContext.parentRecordId.$|async"
          [reportIdentifier]="reportModel.reportIdentifier.$|async"
          [enableAutoLayout]="false"
          [autoLayoutOffByDefault]="true"
          [headerSummaryExpressions]="listReportModel.summaryExpressions.$|async"
          [showFilterManagement]="this"
          [selectionMode]="tableSelectionMode()">
        </app-listreport>
        <!--
          (onRecordClick)="onRecordClickHandler($event)"
        -->
      </div>

      <ng-container *ngIf="(appModel.app.$|async)?.isFilterAdvanced; then quickFilterPopup; else legacyFilter"  ></ng-container>
    </ng-container>
  </div>

  <div class="caption card-header d-flex align-items-center">
    <div *ngIf="captionStyle === captionStyles.Lookup">
      <div class="caption-text" i18n>Look up values from {{ appModel.app.value?.Title }}</div>
    </div>
    <div *ngIf="captionStyle === captionStyles.SelectWithAppRecordName">
      <div class="caption-text" i18n>Select {{ appModel.app.value?.Name }}</div>
    </div>
    <div *ngIf="captionStyle === captionStyles.MultiSelectWithAppRecordName">
      <div class="caption-text" i18n>Select {{ appModel.app.value?.NamePlural }}</div>
    </div>
  </div>

  <div *ngIf="showSearch$ | async"
    class="search pe-2 d-flex flex-nowrap align-items-center justify-content-end card-header">
    <app-search class="d-none d-md-block" [searchText]="reportModel.searchTerm.$ | async"
      [isLoading]="reportModel.busy.busy$ | async" [takeFocus]="true" [expandOnFocus]="true"
      (onEnter)="setSearch($event)" (onEscape)="clearSearch()">
    </app-search>
  </div>

  <div class="filter d-flex align-items-center card-header">
    <div class="pointer" (click)="filterButtonClicked($event)">
      <sof-filter-icon size="1x"></sof-filter-icon>
    </div>
  </div>

  <div class="footer py-2 modal-footer">
    <div *ngIf="(reportModel.report.$|async)?.Type === 1"
      class="paginator d-flex justify-content-center align-items-center">
      <app-list-report-paginator [reportModel]="listReportModel" [isBusy]="reportModel.accessor?.countBusy$|async"
        [count]="reportModel.totalCount.$|async" [skip]="reportModel.topIndex.$|async"
        [top]="reportModel.pageSize.$|async">
      </app-list-report-paginator>
    </div>
    <div class="buttons d-flex align-items-end">
      <button class="primary ms-2" [disabled]="!(ready$ | async)" [mat-dialog-close]="result()"
        [class.bg-st-theme-primary]="ready$ | async" [class.text-white]="ready$ | async" i18n>
        Select
      </button>
      <button mat-dialog-close class="close me-2" i18n>Cancel</button>
    </div>
    </div>
</div>

<app-popup-container [isOpen]="(reportModel.filterEditorUi.$ | async)?.showPopup" [connectedPositions]="popupPosition"
  [connectedOrigin]="filterOrigin" [connectedOffsetX]="filterPopupX()"
  [connectedOffsetY]="(reportModel.filterEditorUi.$ | async)?.popupY + 48" (closed)="filterEditCancelled()">
  <ng-template>
    <sof-filter-edit [filterModel]="filterModel" [reportModel]="reportModel" [filterSpec]="filterModel.filterSpec"
      [filterEditorUi]="reportModel.filterEditorUi.$ | async" [appIdentifier]="appModel.app.value?.Identifier"
      (termUpdated)="filterTermUpdated($event)" (cancelled)="filterEditCancelled()">
    </sof-filter-edit>
  </ng-template>
</app-popup-container>

<!-- Filter management sidebars -->
<ng-template #legacyFilter>
  <div [@filterMgtNarrow]="filterOpenState$|async" class="filter-panel h-100 overflow-auto">
    <ng-container *ngIf="(showFilterManagement$|async)" >
      <app-filter-management-panel
        [appModel]="appModel"
        [filterModel]="filterModel"
        [selectOnly]="true"
        [isOnline]="globalModel.online.$ | async"
        [selectedFilter]="filterModel.reportFilter.$ | async"
        [appIdentifier]="(appModel.app.$ | async)?.Identifier"
        [appFields]="(appModel.app.$ | async)?.AppFields"
        [report]="reportModel.report.$ | async"
        [reportFields]="(reportModel.report.$ | async)?.ListReportFields"
        (panelClosed)="filterManagementClosed()"
        [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
      </app-filter-management-panel>
    </ng-container>
  </div>

</ng-template>

<ng-template #quickFilterPopup>
  <div [@quickFilters]="filterOpenState$|async" class="filter-panel h-100">
    <app-quick-filter *ngIf="showFilterManagement$ | async"
      [reportModel]="reportModel"
      [filterModel]="filterModel"
      [reportController]="reportController"
      (close)="filterManagementClosed()"
    ></app-quick-filter>
  </div>
</ng-template>
