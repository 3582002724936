import { Output, Input, EventEmitter, Directive } from '@angular/core';
import { _DataSetIdentifier } from 'app/_constants';
import { Record, logError } from '@softools/softools-core';
import { Enums } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';
import { LocatorService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { AppField } from 'app/types/fields/app-field';
import { Application } from 'app/types/application';
import { PermissionsService } from 'app/services/permissions.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { AlignmentTypeAlias } from '@softools/softools-core';
import { ComponentBase } from '../component-base';
import { AppModel, AttachmentsModel } from 'app/mvc';

export interface RecordClickEvent {
  event: MouseEvent;
  record: Record;
  row?: number;
}

@Directive()
export class FormBase extends ComponentBase {

  public fieldTypes = Enums.FieldType;

  protected appService = LocatorService.get(AppService);
  protected permissionsService = LocatorService.get(PermissionsService);

  @Input() appModel: AppModel;

  @Input() application: Application;
  @Input() appIdentifiers: AppIdentifiers;
  @Input() parentFieldIdentifier: string = null;
  @Input() selectLists = [];
  @Input() fields: Array<AppField> = [];

  @Input() attachmentsModel: AttachmentsModel;

  @Output() onFieldUpdate = new EventEmitter<RecordPatch>();
  @Output() onRecordClick = new EventEmitter<RecordClickEvent>();

  public getFieldLabel(fieldIdentifier: string): string {
    const field = this.getField(fieldIdentifier);
    return field && field.Label;
  }

  public getFieldType(fieldIdentifier: string): Enums.FieldType {
    const field = this.getField(fieldIdentifier);
    return field && field.Type;
  }

  public getField(fieldIdentifier: string): AppField {
    try {
      const field = this.application?.getField(fieldIdentifier);
      return field;
    } catch (error) {
      logError(error, '');
      throw error;
    }
  }

  public getHeaderAlignment(fieldType: Enums.FieldType, alignment: AlignmentTypeAlias): AlignmentTypeAlias {
    return headerAlignment(fieldType, alignment);
  }
}

export function headerAlignment(fieldType: Enums.FieldType, alignment: AlignmentTypeAlias): AlignmentTypeAlias {
  if (alignment != null) {
    return alignment;
  }

  // Defaults should apply
  switch (fieldType) {
    case Enums.FieldType.Integer:
    case Enums.FieldType.Long:
    case Enums.FieldType.Number:
    case Enums.FieldType.Money:
    case Enums.FieldType.Range:
      // Numbers in ListReports align right by default
      return 'right';

    case Enums.FieldType.Gantt:
    case Enums.FieldType.Notes:
    case Enums.FieldType.Image:
    case Enums.FieldType.InAppChart:
    case Enums.FieldType.ImageActionButton:
    case Enums.FieldType.Bit:
    case Enums.FieldType.MultiState:
    case Enums.FieldType.ImageList:
      return 'center';

    default:
      return 'left';
  }
}
