
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayTextComponent } from 'app/softoolsui.module/overlay-text.component/overlay-text.component';
import { PipeModule } from 'app/pipes/pipe.module';

@NgModule({
  declarations: [OverlayTextComponent],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [OverlayTextComponent],
  providers: []
})
export class OverlayTextModule { }
