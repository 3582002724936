<span class="st-text-theme-primary">{{ placeholder }}</span>
<div class="d-flex mb-3">
  <fa-icon
    [icon]="['fal', 'sort-amount-up']"
    class="pointer me-2"
    [ngClass]="{ 'st-text-theme-primary': selected === orderAscending }"
    [border]="true"
    (click)="selectionChange(orderAscending)"
  ></fa-icon>
  <fa-icon
    [icon]="['fal', 'sort-amount-down']"
    class="pointer me-2"
    [ngClass]="{ 'st-text-theme-primary': selected === orderDescending }"
    [border]="true"
    (click)="selectionChange(orderDescending)"
  ></fa-icon>
  <fa-icon
    *ngIf="orderNone !== selected"
    [icon]="['fal', 'times-circle']"
    class="pointer me-2"
    [border]="true"
    (click)="selectionChange(orderNone)"
  ></fa-icon>
</div>
