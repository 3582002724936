import { IconName } from '@fortawesome/pro-light-svg-icons';

/** An action that can be performed by selection from the toolbar */
export class ToolbarAction {
  constructor(public text: string, public icon: IconName, private impl: () => void) { }

  public perform() {
    this.impl();
  }
}

// Prior to mvc migration all toolbar actions used this class
/** @deprecated use ToolbarAction directly */
export class CallbackToolbarAction extends ToolbarAction {
}
