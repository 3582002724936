import { Record, IRecordConcretizer } from '@softools/softools-core';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable()
export class RecordConcretizer implements IRecordConcretizer {

  public constructor(private appsService: AppService) {
  }

  public concreteRecord(record: Record): Record {

    const app = this.appsService.application(record.AppIdentifier);

    if (!app) {
      // throw new Error(`Unknown App ${record.AppIdentifier} in record ${record._id}`);
      // for now return unchanged as some records on staging saved as 'testapp' not 'TestApp'
      // need to work out how they got that way and clean up or sancify case insensitive ids.
      return record;
    }

    return app.compactRecord(record);
  }
}
