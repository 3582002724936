import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Record, IndexedAppData, OnlineStatusService, FileAttachment, logError, ErrorMap, Failure } from '@softools/softools-core';
import { leftAsideStateAnimation } from '../../../../_constants';
import { WSRecordBase } from '../../ws-base/ws-recordbase';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Component({
  selector: 'app-ws-recordupdate',
  templateUrl: './ws-recordupdate.component.html',
  styleUrls: ['./ws-recordupdate.component.scss'],
  animations: [leftAsideStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSRecordUpdateComponent extends WSRecordBase implements OnInit, OnDestroy {

  constructor(
    route: ActivatedRoute,
    public element: ElementRef,
    onlineStatus: OnlineStatusService,
    appService: AppService,
    navigationService: NavigationService,
    permissionsService: PermissionsService,
    models: GlobalModelService
  ) {
    super(route, onlineStatus, appService, navigationService, permissionsService, models);
    this.recordModel.initialise();
    this.recordUpdateController.initialise();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  public validationErrors(record: Record, errors: ErrorMap): Array<Failure> {
    return errors && errors.has(record._id) && errors.get(record._id);
  }

  // public recordPosition(index: IndexedAppData, record: Record) {

  //   if (index && record) {
  //     return index.positionOf(record._id);
  //   }

  //   return null;
  // }

  public recordCount(index: IndexedAppData) {
    return index ? index.length : 0;
  }

  // public nextRecord(index: IndexedAppData, record: Record) {

  //   if (index && record) {
  //     const adjecent = index.adjacentIds(record._id);
  //     return adjecent && adjecent.next;
  //   }

  //   return null;
  // }

  // public prevRecord(index: IndexedAppData, record: Record) {

  //   if (index && record) {
  //     const adjecent = index.adjacentIds(record._id);
  //     return adjecent && adjecent.prev;
  //   }

  //   return null;
  // }

  public onPreviewAttachment(attachment: FileAttachment): void {
    this.appModel.globalModel.previewModel.previewAttachment(attachment).catch(error => logError(error, 'onPreviewAttachment'));
  }
}
