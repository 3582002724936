import * as signalR from '@microsoft/signalr';
import ObjectID from 'bson-objectid';

import { Enums, logError, LogLevel, NotificationMessage, NotificationStorageService, RawNotificationMessage, tryGetCurrentUser } from '@softools/softools-core';
import { ArrayModelProperty, Model, ModelEvent, ProjectionModelPoperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { ACCESS_TOKEN_KEY } from 'app/_constants/constants.keys';
import { environment } from 'environments/environment';
import { merge, Subject } from 'rxjs';
import { BusyModel } from '../reports/busy.model';
import { GlobalModel } from '../global.model';
import { RefreshBarSuffix } from 'app/notifications.module/types';
import { WindowRef } from 'app/WindowRef';
import { showDownload } from 'app/_constants';
import { NotificationService } from 'app/notifications.module/effects/notification.service';
import { debounceTime } from 'rxjs/operators';
import { WorkflowTriggerService } from 'app/services/workflow-trigger.service';
import { WorkflowRun } from 'app/services/workflow-runner-service';
import { AuthorisationService } from 'app/auth.module/services/authorisation.service';

/**
 * This model manages notifications.
 * It acts as a notification hub, recieving messages from the server, and managing local
 * changes.
 */
export class NotificationsModel extends Model<NotificationsModel> {

  /** Notification messages */
  public readonly notifications = new ArrayModelProperty<NotificationMessage>(this).withLogging('NOTIFICATIONS');

  /** Number of Notification messages formatted for display */
  public readonly count = new ProjectionModelPoperty<Array<NotificationMessage>, number>(this.notifications, (n) => n?.length ?? 0);

  /** Number of Notification messages formatted for display */
  public readonly countDisplay = new ProjectionModelPoperty<Array<NotificationMessage>, string>(this.notifications,
    (n) => {
      const num = n?.length ?? 0;
      return (num > 99) ? '99+' : num.toString();
    });

  /** Indicator for when notifications are being loaded */
  public readonly busy = new BusyModel(this);

  /** Event fired when a message arrives.  Not fired e.g. on initial load */
  public notified = new ModelEvent();

  private desktopNotification$ = new Subject<NotificationMessage>();

  @InjectService(NotificationStorageService)
  private notificationsService: NotificationStorageService;

  @InjectService(NotificationService)
  private notificationService: NotificationService;

  @InjectService(WindowRef)
  private windowRef: WindowRef;

  @InjectService(WorkflowTriggerService)
  private workflowTriggerService: WorkflowTriggerService;

  @InjectService(AuthorisationService)
  private authorisationService: AuthorisationService;

  private hubConnections: signalR.HubConnection;

  constructor(private globalModel: GlobalModel) {
    super();

    this.subscribe(merge(this.globalModel.appInit.$, this.globalModel.accessTokenSetEvent.$), async () => {
      await this.initialise();
    });

    this.subscribe(this.desktopNotification$.pipe(debounceTime(250)), (notification) => {
      if (notification) {
        this.dispatchDesktopNotification(notification);
      }
    });

    this.subscribe(this.globalModel.online.$, async (online) => {
      if (!online) {
        await this.hubConnections?.stop();
      }
    })
  }

  public async initialise() {
    try {
      await this.connect();
    } catch (err) {
      logError(err, "Failed to initialise signalR");
    }
  }

  public async clearNotifications() {
    await this.notificationsService.removeAllNotifications();
    this.notifications.value = [];
  }

  public async removeNotification(notificationId: string) {
    await this.notificationsService.removeNotificationById(notificationId);

    const index = this.notifications.value.findIndex(n => n.Id === notificationId);
    if (index >= 0) {
      this.notifications.splice(index, 1);
    }
  }

  public async upsertNotificationMessage(message: NotificationMessage) {
    const notifications = this.notifications.value;
    const index = notifications.findIndex(n => n.Id === message.Id);
    if (index < 0) {
      this.notifications.unshift(message);
    } else {
      const update = [...notifications];
      update[index] = message;
      this.notifications.value = [...update];
    }

    this.notificationService.send(message);
    await this.notificationsService.addNotification(message);

    this.notified.fire();
  }

  public async stopHub() {
    try {
      await this.hubConnections?.stop();
    } catch (err) {
      logError(err, "Failed to stop signalR");
    }
  }

  /** Connect to server SignalR hub */
  private async connect() {
    const user = tryGetCurrentUser();
    if (user && this.globalModel.online.value && this.authorisationService.isValidAuthToken()) {

      // access token refresh starts a new hub. stop the old connection first!
      await this.stopHub();

      this.hubConnections = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.baseUrl}/notifier?Softools-Authorization=${localStorage.getItem(ACCESS_TOKEN_KEY)}&Softools-Tenant=${user.Tenant}`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect()
        .build()

      this.hubConnections.on('broadcastMessage', async (data: 'Background' | 'User', messageString: string) => {
        // TODO SOF-11625: refactor to use NotificationMessage
        const message: NotificationMessage | RawNotificationMessage = JSON.parse(messageString);
        switch (data) {
          case 'Background':
            await this.onBackgroundMessage(message);
            break;
          case 'User':
            if ((<RawNotificationMessage>message).Notification) {
              await this.onUserMessage((<RawNotificationMessage>message).Notification);
            } else {
              await this.onUserMessage(<NotificationMessage>message);
            }
            break;
        }
      });

      this.hubConnections.start()
        .then(() => { })
        .catch(err => logError(err, 'Error starting SignalR', LogLevel.warning));
    }
  }

  // TODO SOF-11625: refactor to use NotificationMessage
  private async onBackgroundMessage(message: NotificationMessage | RawNotificationMessage) {
    if (message) {
      if ((<NotificationMessage>message).Tag?.indexOf(RefreshBarSuffix) < 0) {
        await this.upsertNotificationMessage((<NotificationMessage>message));
        this.desktopNotification$.next((<NotificationMessage>message));
      }

      if ((<RawNotificationMessage>message).Notification?.Tag?.indexOf(RefreshBarSuffix) < 0) {
        await this.upsertNotificationMessage((<RawNotificationMessage>message).Notification);
        this.desktopNotification$.next((<RawNotificationMessage>message).Notification);
      }
    }
  }

  private async onUserMessage(message: NotificationMessage) {

    if (message?.Tag === 'workflow_notification') {
      // ?? had to add string convs ... - review
      message.Id = message.Id ?? new ObjectID().toHexString();  // TODO: SOF-10046 Remove when the api has the last updated date set.
      message.LastUpdated = message.LastUpdated ?? new Date().toISOString(); // TODO: SOF-10046 Remove when the api has the last updated date set.

      // notifyMessageAction
      // We don't persist these messages (see SOF-12106) but just show toast
      // We previously did this, but if we decide to persist we will likely need new storage
      // await this.upsertNotificationMessage(message);

      this.globalModel.showInfoToasty({
        title: message.Title ?? $localize`Workflow Message`,
        message: message.Message
      });

    } else if (message?.Tag === 'workflowV2_notification' && message.Message.startsWith('button_')) {
      this.workflowTriggerService.updateWorkflowStatus({
        workflowId: message.Data,
        running: message.Message === 'button_started' ? true : false
      } as WorkflowRun);
    }
  }

  private dispatchDesktopNotification(payload: NotificationMessage) {
    let message = payload.Title;
    switch (payload.Status) {
      case Enums.BackgroundTaskStatus.Failed:
      case Enums.BackgroundTaskStatus.Cancelled:
      case Enums.BackgroundTaskStatus.Initialising:
        message = message + '\n';
        break;

      case Enums.BackgroundTaskStatus.Running:
        const runningMessage = message + '\n' + (payload.Maximum > 0 ? payload.Current + ' of ' + payload.Maximum + ' completed' : (payload.Current > 0 ? payload.Current + ' completed' : 'Running'));
        const errorMessage = payload.ErrorCount > 0 ? ', with ' + payload.ErrorCount + (payload.ErrorCount > 1 ? ' errors' : ' error') : '';
        message = runningMessage + errorMessage;
        break;

      case Enums.BackgroundTaskStatus.Completed:
        message = message + '\n' + (payload.ErrorCount > 0 ? 'Completed with ' + payload.ErrorCount + (payload.ErrorCount > 1 ? ' errors' : ' error') : 'Completed');
        break;

      default:
        if (payload.Message && payload.Message.length > 0) {
          message = message + '\n' + payload.Message;
        }
        break;
    }

    this._processDesktopNotification(payload, message);
  }

  private _processDesktopNotification(notification: NotificationMessage, messageOverride: string = null) {
    if (!('Notification' in window)) {
      console.warn('This browser does not support desktop notification');
    } else if (this.windowRef.nativeWindow.Notification.permission === 'granted') {
      this._showDesktopNotificationMessage(notification, messageOverride);
    } else if (this.windowRef.nativeWindow.Notification.permission !== 'denied' || this.windowRef.nativeWindow.Notification.permission === 'default') {
      this.windowRef.nativeWindow.Notification.requestPermission(() => {
        this._showDesktopNotificationMessage(notification, messageOverride);
      });
    }
  }

  private _showDesktopNotificationMessage(notification: NotificationMessage, messageOverride: string) {
    if (this.windowRef.nativeWindow.Notification.permission === 'granted') {
      try {
        const tag = (notification.Tag || notification.Id);
        let message = messageOverride ? messageOverride : notification.Message;
        const showDownloadMessage = showDownload(notification);
        if (showDownloadMessage) {
          message = message + '\n' + 'Click to download...';
        }

        const notify = new this.windowRef.nativeWindow.Notification('Softools Notification', {
          body: message,
          icon: '/assets//android-chrome-144x144.png',
          tag: tag
        });

        notify.onclick = () => {
          const uri = notification.Message;
          const processId = notification.Id;
          if (showDownloadMessage && uri?.length > 0 && processId?.length > 0) {
            this.globalModel.downloadFileAsync(processId, message).catch(e => logError(e, 'downloadFile'));
          }
        };

        setTimeout(notify.close.bind(notify), 4000);
      } catch (e) {
        // Muzzle
      }
    }
  }

}
