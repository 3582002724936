<app-input-icon icon="users"
  [elementStyles]="elementStyles"
  >
  <input #input
    [(ngModel)]="name"
    class="form-control  form-control-o pe-4"
    [matAutocomplete]="auto"
    [disabled]="getIsDisabled()"
    [class.validation-error]="!isValid"
    [ngStyle]="textStyle"
    [class.inline]="inline"
    (keydown)="onKeyDown($event)"
    (keyup)="checkCleared($event); onKeyUp($event)"
    (keypress)="onKeyPress($event)"
    (click)="$event.stopPropagation()"
    />
</app-input-icon>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="autoCompleteItemSelected($event)">
  <mat-option *ngFor="let team of filteredTeams; trackBy: filteredTeamsTrackByFn" [value]="team.Text">
    <span>{{team.Text}}</span>
  </mat-option>
</mat-autocomplete>
