
import { Component, OnInit, ChangeDetectionStrategy, Renderer2, ViewChild, Input } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { DateFieldComponent } from '../date-field/date-field.component';
import { EpochDate, Enums, logError } from '@softools/softools-core';

import * as moment from 'moment';
import { EpochConverter } from 'app/types/epoch-converter';
import { AppField } from 'app/types/fields/app-field';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { isValidTime } from 'app/types/fields/time-app-field';
import { BehaviorSubject } from 'rxjs';

/**
 * Combined date and time selection component.
 * The date field is extended to include an embedded time picker control and the
 * values are merged to provide a full date/time.
 */
@Component({
  selector: 'app-date-time-field-2',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeFieldComponent extends DateFieldComponent implements OnInit {

  public seconds: number;

  public timePseduoField: AppField;

  @ViewChild('datepicker', { static: true }) override datePicker: MatDatepicker<null>;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.use24hourClock = !this.fieldModel.TimeOptions?.use12hourClock;
    this.format = this.use24hourClock ? 24 : 12;
    // this.displayTime = this.time ?? this.getCurrentTime();

    // Clone field type as a time field.  Bit rough...
    this.timePseduoField = new AppField(this.fieldModel);
    this.timePseduoField.Type = Enums.FieldType.Time;
  }

  protected override onValueChanged(value: EpochDate) {
    super.onValueChanged(value);

    if (value === undefined || value === null) {
      this.displayTime$.next(null);
    } else {
      const epoch = (value['val'] as EpochDate) ?? value;
      this.seconds = this.timeSeconds(epoch);
      this.displayTime$.next(this.time);
    }
  }

  public onDateChangedHandler(date: moment.Moment) {
    // Ignore time here, from this point on we will manage time via seconds to avoid timeZone issues
    const utcForUpdatedDate = Date.UTC(date.year(), date.month(), date.date(), 0, 0, 0);
    this.value = { '$date': utcForUpdatedDate } as EpochDate;
    this.dateChanged();
  }

  public override openPicker($event?: MouseEvent) {
    $event.stopPropagation();
    this.datePicker.open();
  }

  /** Get the full date/time based on the combination of date and time elements */
  protected override getTrueDateTime(): EpochDate {
    const dateEpoch = this.value;

    if (!dateEpoch) {
      // Both date and time are unset to return null date/time value
      return null;
    } else {
      // Get date and time, defaulting each if unset. Default date is today, default time is midnight
      const date = dateEpoch ? moment.utc(dateEpoch.$date) : moment.utc();

      // If no time set, default to current time
      if (this.seconds === undefined) {
        const now = EpochConverter.toEpoch(moment.utc());
        this.seconds = this.timeSeconds(now);
      }

      // We maintain time separately so clear time then combine time value
      date.hours(0).minutes(0).seconds(0).milliseconds(0).seconds(this.seconds);

      return EpochConverter.toEpoch(date);
    }
  }

  protected override sanitiseMoment(when: moment.Moment) {
    // Override DateFieldComponent's removal of time part as we need it
    return when;
  }

  public override clearDate() {
    this.seconds = 0;
    super.clearDate();
  }

  private timeSeconds(date: EpochDate): number {

    // Handle date being passed as number (for filters)
    if (date && !date.$date && Number.isFinite(date)) {
      date = { $date: +date };
    }

    if (!date?.$date) {
      return undefined;
    }

    let when = EpochConverter.toMoment(date);

    // If we have display local set convert to a local date format
    if (this.fieldModel.DisplayAsLocal) {
      when = when.local();
    }

    const seconds = ((((when.hour() * 60) + when.minute()) * 60) + when.second()) + (when.millisecond() / 1000);
    return seconds;
  }


  // Originally copied from time field

  /** Use 24 (default) or 12 hour clock representation  */
  public use24hourClock = true;

  public format = 24;

  public displayTime$ = new BehaviorSubject('');

  public enablePicker = true;

  // Adjust theme to be more like date picker.  See stylesheet also.
  public get theme(): NgxMaterialTimepickerTheme {
    return {
      container: {
        bodyBackgroundColor: 'white',
        buttonColor: 'black'
      },
      clockFace: {
        clockFaceBackgroundColor: '#FCFCFC',
        clockHandColor: 'black'
      },
      dial: {
        dialBackgroundColor: 'white',
        dialActiveColor: 'black',
        dialInactiveColor: 'lightgrey'
      }
    };
  }

  /** Get the time part of the attached value */
  public get time(): string {
    const value = this.seconds;
    if (value === null || value === undefined || (value as any) === '') { return null; }
    if (!isValidTime(value)) { return value as any; }
    // Use a temp moment set to requested time today to format time
    const dateTime = moment.utc().hours(0).minutes(0).seconds(0).milliseconds(0).seconds(Math.round(+value));
    return dateTime.format(this.use24hourClock ? 'HH:mm' : 'hh:mm A');
  }

  private getCurrentTime() {
    return moment().format(this.use24hourClock ? 'HH:mm' : 'hh:mm A');
  }

  /**
   * Time has been updated
   * @param value
   */
  public timeChanged(value: string | number) {
    try {
      let seconds: number;

      if (value === '') {
        seconds = null;
      } else if (Number.isSafeInteger(+value)) {
        seconds = +value;
      } else if (typeof value === 'string') {
        // convert to seconds
        const [time, suffix] = value.split(' ', 2);
        const [hours, minutes] = time.split(':');
        const duration = moment.duration({
          // previous code was hours: hours === '12' ? 0 : +hours
          // may need to restore that when we put 12 hour clocks support back
          hours: +hours,
          minutes: +minutes
        });

        if (suffix === 'PM') {
          duration.add(12, 'h');
        }

        seconds = duration.asSeconds();
      }

      this.seconds = seconds;

      if (!this.value) {
        const now = moment.utc();
        const utcForUpdatedDate = Date.UTC(now.year(), now.month(), now.date(), 0, 0, 0);
        this.value = { '$date': utcForUpdatedDate } as EpochDate;
      }

      this.dateChanged();

    } catch (error) {
      logError(error, 'timeChanged');
    }
  }

  protected override formatiCalDateTime() {
    return moment.utc(this.value.$date).format('YYYYMMDD[T]hhmmss[Z]');
  }
}
