import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { QueryParams, QueryParameters, ExportSummary, ExportSummaryDownloads } from '../types/interfaces';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportSummariesRepository extends RepositoryBase {

  /**
   * get total count of error summaries
   */
  public async getCountAsync(queryParams?: QueryParams ): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('ExportSummaries', '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }

  /**
   * Get summary records.
   *
   * @param count         Number of summaries requested
   * @param skip          First record number to request
   * @param queryParams   Query params for filter, sort etc. (top & skip taken from arguments)
   */
  public async getErrorSummariesAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<ExportSummary>> {

    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveDefaultApiUrl('ExportSummaries');
    url = this.urlResolver.appendQueryParams(url, params);
    return lastValueFrom<Array<ExportSummary>>(this.get(url));
  }

  public async getExportSummaryAsync(id: string): Promise<ExportSummary> {
    const url = this.urlResolver.resolveDefaultApiUrl('ExportSummaries', id);
    return lastValueFrom<ExportSummary>(this.get(url));
  }


  public async getDownloadsAsync(summaryId: string): Promise<Array<ExportSummaryDownloads>> {
    const url = this.urlResolver.resolveDefaultApiUrl('ExportSummaryDownloads', summaryId);
    return lastValueFrom<Array<ExportSummaryDownloads>>(this.get(url));
  }

}
