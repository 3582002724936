import { Component, Input, ContentChild, TemplateRef, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { logError } from '@softools/softools-core';

@Component({
  selector: 'app-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupContainerComponent {

  @Input() isOpen = false;

  /** Element that the popup should be aligned to.  If not set the popup is always centred */
  @Input() connectedOrigin: CdkOverlayOrigin;

  @Input() connectedPositions: ConnectionPositionPair[] = [];

  /** Minimum width in pixels to be considered a large device */
  @Input() largeDeviceMinWidth = 599;

  /** Horizontal pixel offset from default connected position */
  @Input() connectedOffsetX = 0;

  /** Vertical pixel offset from default connected position */
  @Input() connectedOffsetY = 0;

  @Input() hasBackdrop = true;

  @Input() public transparentBackdrop = false;

  @Input() dock: 'left' | 'right' | null = null;

  /** If set, when the popup is centred (explicitly or because the screen is small), the popup takes up
   * all available space bar a narrow marging round each edge.  This is the best option for large
   * components on small displays. The content must be defined to be able to fill an arbitrary area.
   */
  @Input() maximizeWhenCentred = false;

  /**
   * The scroller parent element to attach the offsets too.
   */
  @Input() scroller: ElementRef | HTMLElement;

  /** Fired when the popup is closed.  Argument is true if the background was clicked  */
  @Output() closed = new EventEmitter<boolean>();

  @Output() attached = new EventEmitter<TemplateRef<any>>();

  /** Content container.  Must be wrapped in an ng-template */
  @ContentChild(TemplateRef) template;

  /** The contents of the popup  */
  @ViewChild('popupContent') popupContent;

  public dockRightPosition = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'end', overlayY: 'top' }
    ),
  ];

  @HostListener('window:resize')
  onResize() {
    this.changeDetectorRef.detectChanges();
  }


  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public maximizedWidth() {
    return this.maximizeWhenCentred ? window.innerWidth - 12 : null;
  }

  public maximizedHeight() {
    return this.maximizeWhenCentred ? window.innerHeight - 12 : null;
  }

  public get isCentred(): boolean {
    // Centred if no origin element specified or if
    // the device is small (e.g. a phone)
    return !this.connectedOrigin || this.isSmallDevice;
  }

  public get isSmallDevice(): boolean {
    const expression = `(max-width: ${this.largeDeviceMinWidth}px)`;
    return this.breakpointObserver.isMatched(expression);
  }

  public backdropClicked(): void {
    this.closed.emit(true);
  }

  public detached(): void {
    this.closed.emit(false);
  }

  public onAttached(): void {
    this.attached.emit(this.template);
  }

  /** Popup x position  */
  public get offsetX() {

    const x = this.connectedOffsetX;

    let popupLeft: number;
    // popupContent div height hasn't always adjusted for content yet, so gets height of content directly.
    // if first child ihas height of zero it is likely an angular component therefore the child after should be the whole content.
    let popupWidth: number;

    if (!this.scroller) {
      if (this.popupContent) {
        popupLeft = this.connectedOrigin.elementRef.nativeElement.getBoundingClientRect().left + x;
        // popupContent div height hasn't always adjusted for content yet, so gets height of content directly.
        // if first child ihas height of zero it is likely an angular component therefore the child after should be the whole content.
        popupWidth = this.popupContent.nativeElement.offsetWidth !== 0 ? this.popupContent.nativeElement.offsetWidth : this.popupContent.nativeElement.children[0].offsetWidth;
        return this.checkWindowBounds(x, popupLeft, popupWidth, window.innerWidth);
      } else {
        return x;
      }
    }

    try {

      let scrollLeftOffset = 0;

      // If we're in connected mode and have child content
      if (this.popupContent && this.connectedOrigin && this.scroller) {
        popupWidth = this.popupContent.nativeElement.offsetWidth;
        popupLeft = this.connectedOrigin.elementRef.nativeElement.offsetLeft;
        // Locate scrollable parent
        // NB for this to work the ancestor that is doing the scrolling for the connected element must be
        // the first one with any overflow attributes set.  For autolayout this is actually the parenet of
        // the autolayout table.
        let scroller: HTMLElement;
        if (this.scroller instanceof HTMLElement) {
          scroller = (this.scroller as HTMLElement);
        } else {
          scroller = (this.scroller as ElementRef).nativeElement;
        }
        scrollLeftOffset = scroller.scrollLeft;
        const scrollerClientWidth = scroller.clientWidth;
        return this.checkScrollBounds(x, popupLeft, popupWidth, scrollLeftOffset, scrollerClientWidth);
      }

    } catch (error) {
      // Any error, log and return unmodified value
      logError(error, '');
    }

    return x;
  }

  /** Popup y position  */
  public get offsetY() {

    let popupTop: number;
    let popupHeight: number;

    const y = this.connectedOffsetY;

    if (!this.scroller) {
      if (this.popupContent) {
        popupTop = this.connectedOrigin.elementRef.nativeElement.getBoundingClientRect().Top + y;
        // popupContent div height hasn't always adjusted for content yet, so gets height of content directly.
        // if first child ihas height of zero it is likely an angular component therefore the child after should be the whole content.

        if (this.popupContent.nativeElement.offsetHeight === 0 || this.popupContent.nativeElement.offsetHeight === 1) {
          if (this.popupContent.nativeElement.children[0].offsetHeight === 0 || this.popupContent.nativeElement.children[0].offsetHeight === 1) {
            popupHeight = this.popupContent.nativeElement.children[0].children[0].offsetHeight;
          } else {
            popupHeight = this.popupContent.nativeElement.children[0].offsetHeight;
          }
        } else {
          popupHeight = this.popupContent.nativeElement.offsetHeight;
        }

        return this.checkWindowBounds(y, popupTop, popupHeight, window.innerHeight);
      } else {
        return y;
      }
    }

    try {

      let scrollTopOffset = 0;

      // If we're in connected mode and have child content
      if (this.popupContent && this.connectedOrigin && this.scroller) {

        // Locate scrollable parent
        // NB for this to work the ancestor that is doing the scrolling for the connected element must be
        // the first one with any overflow attributes set.  For autolayout this is actually the parenet of
        // the autolayout table.
        let scroller: HTMLElement;
        if (this.scroller instanceof HTMLElement) {
          scroller = (this.scroller as HTMLElement);
        } else {
          scroller = (this.scroller as ElementRef).nativeElement;
        }

        popupTop = this.connectedOrigin.elementRef.nativeElement.offsetTop;
        // popupContent div height hasn't always adjusted for content yet, so gets height of content directly.
        // if first child ihas height of zero it is likely an angular component therefore the child after should be the whole content.
        if (this.popupContent.nativeElement.offsetHeight === 0 || this.popupContent.nativeElement.offsetHeight === 1) {
          if (this.popupContent.nativeElement.children[0].offsetHeight === 0 || this.popupContent.nativeElement.children[0].offsetHeight === 1) {
            popupHeight = this.popupContent.nativeElement.children[0].children[0].offsetHeight;
          } else {
            popupHeight = this.popupContent.nativeElement.children[0].offsetHeight;
          }
        } else {
          popupHeight = this.popupContent.nativeElement.offsetHeight;
        }

        scrollTopOffset = scroller.scrollTop;
        const scrollerClientHeight = scroller.clientHeight;

        return this.checkScrollBounds(y, popupTop, popupHeight, scrollTopOffset, scrollerClientHeight) || y;
      }

    } catch (error) {
      // Any error, log and return unmodified value
      logError(error, '');
    }

    return y;
  }

  private checkScrollBounds(currentVal: number, popupLower: number, popupRange: number, scrollLowerOffset: number, scrollerClientRange: number) {
    let out = currentVal;
    const visibleUpper = scrollLowerOffset + scrollerClientRange;
    const popupUpper = popupLower + popupRange;
    // If the popup extends past the visible area, pull back into view
    if (popupUpper > visibleUpper) {
      out = visibleUpper - popupUpper;
    } else if (popupLower < scrollLowerOffset) {
      out = scrollLowerOffset - popupLower;
    }
    return out;
  }

  private checkWindowBounds(currentVal: number, popupLower: number, popupRange: number, clientRange: number) {
    let out = currentVal;
    const popupUpper = popupLower + popupRange;
    if (popupUpper > clientRange) {
      out += (clientRange - (popupUpper));
    } else if (popupLower < 0) {
      out = currentVal - popupLower;
    }
    return out;
  }

}
