import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ImageStorageService, logError, LogLevel } from '@softools/softools-core';
import { ImageListAssetService } from 'app/services/images/image-list-assets.service';
import { ImagePersistService } from 'app/services/images/image-persist.service';
import { Subscription, from } from 'rxjs';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'app-asset-image',
  templateUrl: './asset-image.component.html',
  styleUrls: ['./asset-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetImageComponent extends ComponentBase implements OnChanges, OnDestroy, OnInit {
  @Input() public uiIdentifier: string;

  /** true for an uplaoded image, false for a configured asset  */
  @Input() public isImageField = false;

  @Input() public assetUrl: string;

  @Input() public title: string;

  /** If set > 0 defines the image width in rems */
  @Input() public width = 0;

  /** If set true, stretches the image to fill parent element */
  @Input() public fill = false;

  @Output() public clicked = new EventEmitter<{ $event: MouseEvent; url: SafeUrl }>();

  @Input() public srcUrl: SafeUrl = null;

  @Input() public imageNotFound = false;

  @Input() public loading = false;

  @Input() public size: 'tiny' | 'medium' | 'full' = 'tiny';

  constructor(
    private imageStorageService: ImageStorageService,
    private imagePersistService: ImagePersistService,
    private imageListAssetService: ImageListAssetService) {
    super();
  }

  loadImageBlobSub1: Subscription;
  loadImageBlobSub2: Subscription;

  ngOnInit(): void {
    if (!this.assetUrl && !this.loading) {
      this.noImageFound();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadImageBlobSub1?.unsubscribe();
    this.loadImageBlobSub2?.unsubscribe();

    if (changes?.['assetUrl']?.currentValue) {
      this.tryLoadImage();
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.loadImageBlobSub1?.unsubscribe();
    this.loadImageBlobSub2?.unsubscribe();
  }

  public get imageWidth(): string {
    if (this.fill) {
      return '100%';
    } else if (this.width) {
      return `${this.width}rem`;
    } else {
      return 'auto';
    }
  }

  public imageClicked($event: MouseEvent) {
    this.clicked.emit({ $event, url: this.srcUrl });
  }

  public imageError($event: Event) {
    this.noImageFound();
  }

  private tryLoadImage() {
    try {
      this.loading = true;
      if (this.assetUrl.startsWith('/Asset/')) {
        const id = this.assetUrl.substr(7);
        /// this.isImageField = false; revert, breaks template icons
        this.loadImageBlobSub1 = this.loadImageTask(id);
      } else if (/^([a-f0-9]{24})$/.test(this.assetUrl)) {
        // this.isImageField = true; revert, breaks template icons
        this.loadImageBlobSub2 = this.loadImageTask(this.assetUrl);
      } else {
        // Any other format, leave unchanged
        this.srcUrl = this.imageStorageService.sanitise(this.assetUrl);
        this.imageFound();
      }
    } catch (error) {
      this.noImageFound();
      logError(error, `loading image ${this.assetUrl}`, LogLevel.error);
    }
  }

  private loadImageTask(id) {
    return from(this.loadImageBlob(id)).subscribe(
      (url) => {
        this.srcUrl = url;
        if (url) {
          this.imageFound();
        } else {
          this.noImageFound();
        }
        this.refresh();
      },
      (e) => {
        this.noImageFound();
        logError(e, `Error updating asset id ${id}`);
      }
    );
  }

  private loadImageBlob(id: string) {
    return new Promise(async (resolve) => {
      const url2 = await this.imagePersistService.getImageUrl(id);
      if (url2) {
        resolve(url2);
        return;
      }

      // try to load from blob first
      if (this.isImageField) {
        const blob = await this.imageListAssetService.getImageUrlAsync(id);
        if (blob) {
          resolve(blob);
          return;
        }
      }

      // load from the server is not in blob
      const url = await this.imageStorageService.getImageAssetUrlAsync(id, this.isImageField, this.size);
      resolve(url);
    });
  }

  private imageFound() {
    this.imageNotFound = false;
    this.loading = false;
  }

  private noImageFound() {
    this.imageNotFound = true;
    this.loading = false;
  }
}
