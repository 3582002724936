<form style="user-select: none" class="card-popup">
  <div class="card-header">
    <div i18n>Save Filter</div>
    <button type="button" class="btn-close" aria-label="Close" [mat-dialog-close]="null"></button>
  </div>
  <mat-dialog-content>
    <div class="filter-card">
      <mat-form-field class="save-as mx-1">
        <mat-select [(value)]="selectedFilter" 
          (selectionChange)="filterChanged($event.value)"
          placeholder="Save filter as ..." 
          i18n-placeholder="Save Filters prompt@@SaveFilters">
          <mat-option [value]="newFilter" i18n>New Filter</mat-option>
          <mat-option *ngFor="let filter of savedFiltersForCurrentAccessLevel()" [value]="filter">
            {{ filter.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="name mx-1">
        <input
          matInput
          cdkFocusInitial
          name="description"
          [(ngModel)]="description"
          placeholder="Filter name"
          i18n-placeholder
          [disabled]="isExistingFilter"
        />
      </mat-form-field>
      <mat-form-field class="access mx-1">
        <mat-select [(value)]="accessLevel" placeholder="Accessibility" i18n-placeholder (selectionChange)="accessLevelChanged()">
          <mat-option value="0" i18n>Personal</mat-option>
          <mat-option value="1" i18n>Team</mat-option>
          <mat-option value="2" i18n>Global</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox class="def-filter mx-1" 
        name="set-default-checkbox"
        [hidden]="accessLevel !== '2'"
        [(ngModel)]="isDefault" >
         <span i18n>Default for all users</span>
      </mat-checkbox>
  </div>
    <div class="modal-footer">
      <button class="primary" (click)="submit()" type="submit" title="Accept updates (Enter)" i18n-title="AcceptUpdatesTitle" i18n>Save</button>
      <button class="close" (click)="cancel()" title="Cancel Updates (Esc)" i18n-title="CancelUpdatesTitle" i18n>Close</button>
    </div>
  </mat-dialog-content>
</form>
