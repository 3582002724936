<img *ngIf="srcUrl && !loading;"
  class="aimage"
  [attr.id]="uiIdentifier"
  [src]="srcUrl"
  [title]="title || ''"
  [style.width]="imageWidth"
  (error)="imageError($event)"
  (click)="imageClicked($event)"
>

<div *ngIf="loading" class="blank-placeholder"
  (click)="imageClicked($event)"
  >
  <img src="assets/icons/loader.gif"
  [style.width]="imageWidth"
  style="max-height: 50px; object-fit: scale-down;"
  alt="Loading image" i18n-alt />
</div>

<div *ngIf="imageNotFound && !loading" class="blank-placeholder"
  (click)="imageClicked($event)"
  >
  <img src="assets/icons/not-found.png"
  [style.width]="imageWidth"
  style="max-height: 50px; object-fit: scale-down;"
  alt="Image not found" i18n-alt />
</div>
