import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompatibilityGuard, AuthGuard, ThemeGuard, CookieCheckGuard, AppsExistsGuard, SiteGuard } from 'app/guards';
import { SiteSettingsGuard } from '../settings/settings.guard';
import { LicensingAgreedGuard } from './licensing.guard';
import { GlobalResetGuard } from 'app/guards/globalreset.guard';
import { ForceSyncGuard } from '../force-sync.guard';
import { ReturnUrlGuard } from '../return-url.guard';
import { PatchQueueCacheGuard } from '../patch-queue-cache.guard';
import { NamedStylesGuard } from '../named-styles.guard';
import { SavedFiltersGuard } from '../saved-filters.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    LicensingAgreedGuard,
    CookieCheckGuard,
    CompatibilityGuard,
    AuthGuard,
    ThemeGuard,
    SiteSettingsGuard,
    GlobalResetGuard,
    ForceSyncGuard,
  ],
})
export class GlobalGuardsModule {}

/** Guards that are applied to all pages */
export const globalGuards = [
  AppsExistsGuard,
  CookieCheckGuard,
  CompatibilityGuard,
  ThemeGuard,
  SiteSettingsGuard, // should be a new basic settings guard.
  LicensingAgreedGuard,
  GlobalResetGuard,
  ForceSyncGuard,
  ReturnUrlGuard,
  PatchQueueCacheGuard,
  SiteGuard,
  NamedStylesGuard,
  SavedFiltersGuard
];
