import { IFilterTerm } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { ReportFilter } from './report-filter';
import { FilterTerm } from './filter-term';

export class FilterTerms {

  public static combineTerms(app: Application, terms: Array<IFilterTerm>,
    operation: 'and' | 'or' = 'and'
  ): ReportFilter {
    const clauses: Array<string> = [];
    terms.forEach(term => {
      const expr = new FilterTerm(term).format(app.AppFields);
      if (expr) {
        clauses.push(expr);
      }
    });

    const reportFilter = new ReportFilter();
    reportFilter.Filter = clauses.join(` ${operation} `);
    return reportFilter;;
  }
}
