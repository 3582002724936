import { Component, Inject, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FileUploaderSoftoolsOptions, AppDataRepository, NotificationMessage, logError } from '@softools/softools-core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { NotificationService } from 'app/notifications.module/effects/notification.service';
import { GlobalModel } from 'app/mvc';

export const fileToText = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

@Component({
  selector: 'app-dialog-file-uploader',
  templateUrl: './dialog-file-uploader.component.html',
  styleUrls: ['./dialog-file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default // this can be default as its a dialog and should not cause to much perf overhead
})
export class DialogFileUploaderComponent implements OnInit, OnDestroy {

  public hasFileOver = false;
  public uploading = false;
  public uploaded = false;
  public filename = '';
  public error = false;
  public errorMessage = '';
  public notificationsSub: Subscription;
  public notificationMessage: NotificationMessage;
  public processId = '';

  public static async openAsync(globalModel: GlobalModel, config: FileUploaderSoftoolsOptions) {
    return await globalModel.dialogAsync(DialogFileUploaderComponent, config);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogFileUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public options: FileUploaderSoftoolsOptions,
    private appDataRepository: AppDataRepository,
    private notificationService: NotificationService
  ) { }

  ngOnDestroy(): void {
    this.notificationsSub.unsubscribe();
  }

  ngOnInit(): void {
    this.notificationsSub = this.notificationService.messages.subscribe((message) => {
      if (message) {
        this.notificationMessage = message;
      }
    });
  }

  public fileOverHandler(e: any): void {
    this.hasFileOver = e;
  }

  public fileHandler(file: File) {
    this.uploading = true;
    this.uploaded = false;
    this.error = false;
    this.errorMessage = '';
    this.filename = file.name;
    try {
      if (!this.filename.toLocaleLowerCase().endsWith('.csv')) { throw new Error('CSV'); }

      // Happens async
      this.upload(file).catch(error => logError(error, 'Failed to upload'));

    } catch (ex) {
      this.error = true;
      this.errorMessage = ex.message;
      this.uploaded = false;
    }
  }

  private async upload(file: File) {

    try {
      const rawCsvText = await fileToText(file);
      const hierarchy = this.options.parentAppIdentifier && this.options.parentRecordIdentifier ? `${this.options.parentAppIdentifier}|${this.options.parentRecordIdentifier}` : '';

      let task: any;
      if (this.options.importer) {
        task = await this.options.importer(rawCsvText);
      } else {
        task = await this.appDataRepository.importWithData(this.options.appIdentifier, hierarchy, rawCsvText).toPromise();
      }

      if (task?.ProcessId) {
        this.processId = task.ProcessId;
      }
      this.uploading = false;
      this.uploaded = true;

    } catch (error) {
      logError(error, 'uploader');
      this.error = true;
      this.errorMessage = error.message;
      this.uploaded = false;
    }
    finally {
      this.uploading = false;
    }
  }
}
