import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {

  @Input() accept = '';
  @Input() enablePasting = true;
  @Input() dragText = '';

  @Output() fileOutput = new EventEmitter<File>();

  @ViewChild('fileSelector', { static: true })
  private fileSelector: ElementRef<HTMLInputElement>;

  constructor() { }

  public dropped(event: any) {
    event.preventDefault();

    const dt = event.dataTransfer;
    if (dt.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < dt.items.length; i++) {
        if (dt.items[i].kind === 'file') {
          const f = dt.items[i].getAsFile();
          this.fileOutput.emit(f);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < dt.files.length; i++) {
        this.fileOutput.emit(dt.files[i]);
      }
    }
  }

  public draggedover(event: any) {
    event.preventDefault();
  }

  public dragend_handler() { }


  public pasteAttachment(event: ClipboardEvent) {
    for (let i = 0; i < event.clipboardData.items.length; ++i) {
      const item = event.clipboardData.items[i];
      if (item.type === 'image/png') {
        const file = item.getAsFile();
        this.fileOutput.emit(file);
      }
    }

    // Reset paste target field to invisble
    (<any>event.target).style.display = 'none';

    // Stop default handling so we don't paste into the field
    event.preventDefault();
  }

  public fileSelected(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0] as File;    // todo consider allowing multiple files
      this.fileOutput.emit(file);

      // Clear selector, otherwise reselecting same file not seen as a change
      this.fileSelector.nativeElement.value = '';
    }
  }

}
