import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DateTimeFieldComponent, ImageFieldComponent,
  EmbeddedVideoFieldComponent,
  PersonFieldComponent, ButtonFieldComponent
} from '../..';

import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { FieldsHelperModule } from '../fields-helper/fields-helper.module';
import { DropDownModule } from '../../dropdown.component/dropdown.module';
import { CheckboxModule } from '../../checkbox.component/checkbox.module';
import { PipeModule } from 'app/pipes/pipe.module';
import { DateFieldModule } from '../date-field.component/date-field.module';
import { BarcodeFieldComponent } from './barcode-field/barcode-field.component';
import { PopupContainerModule } from '../../popup-container/popup-container.module';
import { CameraModule } from './camera/camera.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { SofPlusIconModule } from '@softools/design/icons';
import { AssetImagesModule } from 'app/softoolsui.module/asset-image/asset-images.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSliderModule,
    FieldsHelperModule,
    DropDownModule,
    CheckboxModule,
    AssetImagesModule,
    PipeModule,
    DateFieldModule,
    PopupContainerModule,
    CameraModule,
    FontAwesomeModule,
    MatMomentDateModule,
    MatDatepickerModule,
    SofPlusIconModule
  ],
  declarations: [
    DateTimeFieldComponent,
    PersonFieldComponent,
    ImageFieldComponent,
    ButtonFieldComponent,
    EmbeddedVideoFieldComponent,
    BarcodeFieldComponent,
  ],
  exports: [
    DateTimeFieldComponent,
    PersonFieldComponent,
    ImageFieldComponent,
    ButtonFieldComponent, /* ButtonFieldComponent field is needed in homepage dashboard */
    EmbeddedVideoFieldComponent, /* EmbeddedVideo field is needed in video overlay */
    BarcodeFieldComponent,
  ]
})
export class ScalarFieldsModule { }
