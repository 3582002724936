import {
  Directive,
  HostListener,
  Renderer2,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnDestroy,
} from '@angular/core';
import { Record, Enums, logError } from '@softools/softools-core';

import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { SofValueFormaterDirective } from './sofValueFormater.directive';
import { LocatorService } from 'app/services/locator.service';
import { UsersService } from 'app/services/users.service';
import { isNumberField } from 'app/_constants';
import { InputChangedTrackService } from 'app/services/input-changed-track.service';

const ATTR_VALUE = 'value';
const ATTR_CHECKED = 'checked';
const ATTR_MIN = 'min';
const ATTR_MAX = 'max';
const ATTR_ROWS = 'rows';
const ATTR_MAXLENGTH = 'maxlength';
const ATTT_DISABLED = 'disabled';

export const VALIDATION_CLASS = 'validation-error';

@Directive({
  selector: 'input[appSofInput], textarea[appSofInput]',
  exportAs: 'appSofInput',
})
export class SofInputDirective extends SofValueFormaterDirective implements OnChanges, OnInit {
  private _hasFocus = false;

  private _previousValue;

  private recordValidationService: RecordValidationService;

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.field) {
      if (this.field.Type === Enums.FieldType.Literal) {
        this._setReadonlyAttribute();
      }

      if (this.field.Align === 'right') {
        this.renderer.addClass(this.element.nativeElement, `align-right`);
      }

      this._setDisabledAttribute();

      if (this.field.Type === Enums.FieldType.LongText && this.field.Rows > 0) {
        this._setAttributeFromFieldValue(ATTR_ROWS, this.field.Rows);
      }

      if (isNumberField(this.field.Type)) {
        this._setAttributeFromFieldValue(ATTR_MIN, this.field.MinValue);
        this._setAttributeFromFieldValue(ATTR_MAX, this.field.MaxValue);
      }

      this._setAttributeFromFieldValue(ATTR_MAXLENGTH, this.field.Length);

      this._setAttribute('data-identifier', this.field.Identifier);
      this._setAttribute('type', this._getTypeString());

      const autocomplete = (this.field.TextFieldOptions && this.field.TextFieldOptions.AutoCompleteType) || 'off';
      this._setAttribute('autocomplete', autocomplete);

      this.renderer.addClass(this.element.nativeElement, `field-${this.field.Type}`);

      /**
       * Firefox does not have a period or datetime field.
       */
      if (this.field.Type === Enums.FieldType.Period) {
        this._setAttribute('placeholder', 'yyyy-mm');
      }

      if (this.field.Type === Enums.FieldType.DateTime) {
        this._setAttribute('placeholder', 'yyyy-mm-dd HH:mm:ss');
      }
    }

    // this.recordChangesSub = this.recordPersistService.recordChanges$.pipe(
    //   tap((deltas) => {
    //     try {
    //       if (deltas && deltas._id === this.record._id) {
    //         Object.keys(deltas).forEach((key) => {
    //           if (key === this.field.Identifier) {

    //             this.element.nativeElement.classList.add('bottom-border-success')

    //             setTimeout(() => {
    //               this.element.nativeElement.classList.remove('bottom-border-success')
    //             }, 3000)
    //           }
    //         });
    //       }
    //     } catch (err) {
    //       logError(err, '');
    //     }
    //   })
    // ).subscribe();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    /**
     * we dont want to update the value if focus is on the element
     *
     * This stops the flash
     */
    if (this._hasFocus) {
      return;
    }

    /** Dont display un supported fields */
    if (this._unsupported()) {
      this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
      return;
    }

    if (this.field && this.record) {
      /** TODO: Should be set form validation service input */
      if (changes['record']) {
        // Update validation unless on a readonly form
        if (!this.form?.ReadOnly) {
          const errorMapsForRecord = this.record._errs;
          const errorList = errorMapsForRecord && errorMapsForRecord.filter((err) => err.fieldId === this.field.Identifier);
          // console.warn(errorMaps)
          errorList && errorList.length > 0 ? this._addValidationClass() : this._removeValidationClass();
        }
      }

      if (changes['record']) {
        this._setDisabledAttribute();
      }

      if (changes['record']) {
        this._setInputValueAttribute();
        this._setOrRemoveInputCheckedAttribute();
        // this._setTitleAttribute();
        this._previousValue = this._getFieldValue();
      }
    }
  }

  constructor(
    usersService: UsersService,
    private renderer: Renderer2,
    private element: ElementRef,
    viewContainerRef: ViewContainerRef,
    componentFactoryResolver: ComponentFactoryResolver,
    private inputChangedTrackService: InputChangedTrackService
  ) {
    super(usersService, viewContainerRef, componentFactoryResolver);

    this.recordValidationService = LocatorService.get(RecordValidationService);
  }

  @HostListener('focus')
  _focus() {
    this._hasFocus = true;
  }

  @HostListener('click', ['$event'])
  _click(event: MouseEvent) {
    event.stopPropagation();
  }

  @HostListener('keyup')
  _keyUp() {
    this.inputChangedTrackService.setInputDirty(this.element);
  }

  /** Callback for the cases where   the blur is triggered. */
  @HostListener('blur')
  _blur() {
    try {
      this._hasFocus = false;

      const newValue = this._getNativeElementInputValue();

      if (this._previousValue !== newValue) {
        this._previousValue = newValue;

        // Validate field
        const recordToValidate = { ...this.record };
        this.field.storeToRecord(recordToValidate, newValue, this.listRow);
        const isValid = this._isValid(recordToValidate);

        if (isValid) {
          this._removeValidationClass();
        } else {
          this._addValidationClass();
        }

        const patch = this.field.createPatch(this.record, newValue, this.listRow);

        this.appModel.patchRecordValue(patch)?.catch(e => logError(e, 'onChange'));
      }
    } catch (error) {
      logError(error, `Error in field blur handler for ${this.field.Identifier}`);
    }
  }

  _removeValidationClass() {
    this.renderer.removeClass(this.element.nativeElement, VALIDATION_CLASS);
  }

  _addValidationClass() {
    this.renderer.addClass(this.element.nativeElement, VALIDATION_CLASS);
  }

  _isValid(record: Record) {
    return this.recordValidationService.isFieldValid(this.field, record, this.listRow);
  }

  private _setDisabledAttribute() {
    const readonlyForm = this.form?.ReadOnly;
    const isDisabled = readonlyForm || this.field.isDisabled(this.record);
    if (isDisabled) {
      this._setAttribute(ATTT_DISABLED, '');
    } else {
      this.renderer.removeAttribute(this.element.nativeElement, ATTT_DISABLED);
    }
  }

  _setReadonlyAttribute() {
    this._setAttribute('readonly', '');
  }

  _getNativeElementInputValue() {
    switch (this._getInputType()) {
      case ATTR_VALUE: {
        const value = this.element.nativeElement.value;
        return this._parseValue(value);
      }

      case ATTR_CHECKED:
        return this.element.nativeElement.checked;

      default:
        /** Leave to throw so we catch missing inputs */
        throw new Error('type not set');
    }
  }

  private _parseValue(value: any) {
    switch (this.field.Type) {
      case Enums.FieldType.Long:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Range: {
        if (value === '') {
          return ''; // pass through blank so we can clear records
        } else {
          const num = Number.parseInt(value);
          return Number.isNaN(num) ? undefined : num;
        }
      }

      case Enums.FieldType.Number:
      case Enums.FieldType.Money: {
        if (value === '') {
          return ''; // pass through blank so we can clear records
        } else {
          const num = Number.parseFloat(value);
          return Number.isNaN(num) ? undefined : num;
        }
      }

      default:
        return value;
    }
  }

  _getInputType(): 'value' | 'checked' {
    switch (this.field.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Literal:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Email:
      case Enums.FieldType.Long:
      case Enums.FieldType.Money:
      case Enums.FieldType.Number:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Date:
      case Enums.FieldType.Period:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Range:
      case Enums.FieldType.Selection:
        return ATTR_VALUE;
      case Enums.FieldType.Bit:
        return ATTR_CHECKED;

      default:
        /** Leave to throw so we catch missing inputs */
        throw new Error('type not set');
    }
  }

  private _getTextTypeString() {
    if (this.field.TextFieldOptions && this.field.TextFieldOptions.PasswordField) {
      return 'password';
    } else {
      return 'text';
    }
  }

  _getTypeString() {
    switch (this.field.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
        return this._getTextTypeString();
      case Enums.FieldType.Literal:
      case Enums.FieldType.Selection:
        return 'text';
      case Enums.FieldType.UrlField:
        return 'url';
      case Enums.FieldType.Email:
        return 'email';
      case Enums.FieldType.Long:
      case Enums.FieldType.Money:
      case Enums.FieldType.Number:
      case Enums.FieldType.Integer:
        return 'number';
      case Enums.FieldType.Date:
        return 'text'; // currently using unformatted input with date picker
      case Enums.FieldType.Period:
        return 'month';
      case Enums.FieldType.DateTime:
        return 'datetime-local';
      case Enums.FieldType.Bit:
        return 'checkbox';
      case Enums.FieldType.Range:
        return 'range';

      default:
        console.warn('type not set', this.field);
        return null;
    }
  }

  /**
   * Set/Remove the HTML input checked attribute to the field value
   * if it's a bit field
   */
  _setOrRemoveInputCheckedAttribute() {
    if (this._getInputType() === ATTR_CHECKED) {
      if (this._getFieldValue() === true) {
        this._setAttribute(ATTR_CHECKED, '');
      } else {
        this.renderer.removeAttribute(this.element.nativeElement, ATTR_CHECKED);
      }
    }
  }

  /**
   * Set the HTML input value to the current field value.
   * This is set on change.
   */
  _setInputValueAttribute() {
    if (this._getInputType() === ATTR_VALUE) {
      const newValue = this._getFieldValue();
      //      this.renderer.setValue(this.el.nativeElement, this._getFieldValue().toString());
      /** TODO: Needed for tests to pass, refactor those tests to use nativeElement.value
       * Or should we set?
       */
      this.element.nativeElement.value = newValue;
    }
  }

  _setAttributeFromFieldValue(attr: string, value: string | number) {
    /**
     * Autolayout for a text area used zero, dont set the row height if its in auto layout mode.
     */
    if (value) {
      this._setAttribute(attr, value.toString());
    }
  }

  // private _setTitleAttribute() {
  //   switch (this.field.Type) {
  //     case Enums.FieldType.Text:
  //     case Enums.FieldType.LongText:
  //     case Enums.FieldType.Email:
  //     case Enums.FieldType.UrlField: {
  //       const value = this._getFieldValue();
  //       this._setAttributeFromFieldValue('title', value as string);
  //       break;
  //     }
  //   }
  // }

  _setAttribute(attr: string, value: any) {
    this.renderer.setAttribute(this.element.nativeElement, attr, value);
  }

  private _unsupported(): boolean {
    try {
      return this._getInputType() === null;
    } catch {
      return true;
    }
  }
}
