import { Injectable } from '@angular/core';
import { AppsService, Report, logError, App } from '@softools/softools-core';
import { Application, AppIdentifier } from 'app/types/application';
import { StandardApplication } from 'app/types/standard-application';
import { RecordPersistService } from './record/record-persist.service';
import { IApplications } from './applications.interface';
import { AppService } from './app.service';
import { EmbeddedAppsService } from 'app/embedded.apps/embedded-apps.service';

/**
 * Service that provides access to App specifications.
 */
@Injectable()
export class AppServiceImpl implements AppService, IApplications {

  private appsByIdentifier: Map<AppIdentifier, Application>;

  // A promise to hold the result of the refresh function
  // Once the promise is resolved it will return the same result
  private refreshPromise: Promise<Array<Application>>;

  constructor(
    private appsService: AppsService,
    private recordPersistService: RecordPersistService,
    private embeddedAppsService: EmbeddedAppsService) {
  }

  public async forceRefresh() {
    this.refreshPromise = null;
    return this.refresh();
  }

  public async refresh(): Promise<Array<Application>> {

    if (!this.refreshPromise) {
      this.refreshPromise = new Promise(async (r) => {
        const appsByIdentifier = new Map<AppIdentifier, Application>();

        // Add all apps held in storage
        const standardApps = await this.appsService.getApps();
        standardApps.forEach(app => {
          const application = new StandardApplication(this.recordPersistService, app);
          appsByIdentifier.set(app.Identifier, application);
        });

        // Add hard coded embedded apps
        const embedded = this.embeddedAppsService.getEmbeddedApps();
        embedded.forEach(application => {
          appsByIdentifier.set(application.Identifier, application);
        });

        this.appsByIdentifier = appsByIdentifier;

        r(this.applications());
      });
    }

    const apps = await this.refreshPromise;

    // If no standard apps exist then we have not synced them
    // These get synced via the sync component.
    if (apps.filter(a => !a.IsEmbedded).length === 0) {
      this.refreshPromise = null;
    }

    return apps;
  }

  public async update(app: App): Promise<Application> {
    const application = new StandardApplication(this.recordPersistService, app);
    this.appsByIdentifier.set(app.Identifier, application);
    return application;
  }

  /**
   * Get an application given an application id.
   * Throw error if not found
   * @param identifier app identifier
   */
  public application(identifier: AppIdentifier): Application {
    const app = this.tryGetApplication(identifier);
    if (!app && identifier) {
      logError(new Error(`Couldn't find ${identifier} in ${this.appsByIdentifier.size} apps`), '');
    }

    return app;
  }

  /**
   * Get an application given an application id.
   * Return null if not found
   * @param identifier app identifier
   */
  public tryGetApplication(identifier: AppIdentifier): Application {

    if (!this.appsByIdentifier) {
      throw new Error('Apps not loaded.   Use AppsExistsGuard to prime service');
    }

    // No identifier may be possible e.g. if not in an app context
    const app = identifier && this.appsByIdentifier.get(identifier);
    return app;
  }


  public getReport(appIdentifier: AppIdentifier, reportIdentifier: string): Report {
    const app = this.application(appIdentifier);
    if (app && app.Reports) {
      return app.Reports.find(rep => rep.Identifier === reportIdentifier);
    }

    return null;
  }

  /**
   * Get all applications
   */
  public applications(): Array<Application> {
    if (!this.appsByIdentifier) {
      throw new Error('Apps not loaded.   Use AppsExistsGuard to prime service');
    }

    return Array.from(this.appsByIdentifier.values());
  }

  /**
   * Get all applications.
   * Unlike @see applications this can be called without priming the collection, as it
   * loade the collection if needed, but it can only be called from an aysnc context
   */
  public async applicationsAsync(): Promise<Array<Application>> {
    if (!this.appsByIdentifier) {
      return await this.refresh();
    }

    return this.applications();
  }

  /** */
  public async applicationMapAsync(): Promise<Map<AppIdentifier, Application>> {
    if (!this.appsByIdentifier) {
      await this.refresh();
    }

    return this.appsByIdentifier;
  }

  public isAppAvailableAnonymously(appIdentifier: AppIdentifier): boolean {

    let app: Application;
    if (this.appsByIdentifier) {
      app = this.appsByIdentifier.get(appIdentifier);
    } else {
      app = this.embeddedAppsService.getEmbeddedApps().find(a => a.Identifier === appIdentifier);
    }

    return (app && app.Anonymous) || false;
  }
}
