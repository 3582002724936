<div [vxWatch]="filterModel"
  class="st-text-theme-primary noselect h-100 d-flex"
  [class.text-nowrap]="singleLineLabel"
  [class.multiline]="!singleLineLabel"
  style="overflow: hidden;"
  [class.justify-content-end]="alignment === 'right'"
  [class.justify-content-center]="alignment === 'center'"
  [class.pointer]="allowFiltering"
  [attr.title]="simpleFilter?.field.ShortLabel || simpleFilter?.appField.Label"
  [class.hidden]="simpleFilter?.field.Hidden"
  (click)="clicked($event)"
  >
  <span class="mx-1">
    {{ simpleFilter?.field.ShortLabel || simpleFilter?.appField.Label }}
  </span>
  <fa-icon
    [icon]="['fal', simpleFilter?.appField.Icon]"
    size="xs"
    class="text-muted"
    [matTooltip]="simpleFilter?.appField.IconTooltip"
    *ngIf="simpleFilter?.appField.IsExpression"
  ></fa-icon>
  <fa-icon [icon]="['fal', sortImage(simpleFilter)]" size="xs" class="text-muted" *ngIf="isSortable(simpleFilter)"></fa-icon>
  <fa-icon [icon]="['fal', 'filter']" size="xs" class="text-muted" *ngIf="isFilterParticipant(simpleFilter)"></fa-icon>

  <ng-content></ng-content>

</div>
