import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UrlResolver, OnlineStatusService, LastListReportStorageService, logError } from '@softools/softools-core';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation } from 'app/_constants';
import { WSListReportComponent } from 'app/workspace.module/components/ws-reports/ws-listreport/ws-listreport.component';
import { AppService } from 'app/services/app.service';
import { RecordClickEvent } from 'app/softoolsui.module/form.component/form-base.component';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Component({
  selector: 'app-ws-childlistreport',
  templateUrl: '../ws-listreport/ws-listreport.component.html',
  styleUrls: ['../ws-listreport/ws-listreport.component.scss'],
  animations: [contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSChildListReportComponent extends WSListReportComponent {

  constructor(
    public override router: Router,
    navigationService: NavigationService,
    public override indexedDbService: IndexedDbService,
    public override appService: AppService,
    public override urlresolver: UrlResolver,
    public override route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    permissionsService: PermissionsService,
    lastListReportStorageService: LastListReportStorageService,
    overlayService: OverlayService,
    models: GlobalModelService,
  ) {
    super(router, navigationService, indexedDbService, appService, urlresolver, route, onlineStatus, permissionsService, lastListReportStorageService, overlayService, models);
    this.forChildListReport = true;
    this.showBackButton = true;
  }

  public override onRecordClickHandler(event: RecordClickEvent) {
    const record = event.record;
    if (record.Hierarchy) {
      this.globalModel.navigation.navigateRecordAsync({
        appIdentifiers: this.appIdentifiers,
        recordId: record._id
      }).catch(error => logError(error, 'RecordClick'));
    } else {
      super.onRecordClickHandler(event);
    }
  }

}
