import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedFooterComponent } from './shared-footer.component';
import { CommentWidgetComponent } from '..';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ConfirmDeleteComponent } from '../../confirm-delete.component/confirm-delete.component';
import { PopupContainerModule } from '../../popup-container/popup-container.module';
import { FloatingSupportWidgetModule } from '../../floatingsupportwidget/floatingSupportWidget.module';
import { IndexPaginatorModule } from 'app/softoolsui.module/index-paginator/index-paginator.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileAttachmentPopupComponent } from '../attachment-popup.component/file-attachment-popup.component/file-attachment-popup.component';
import { CommentPopupComponent } from '../attachment-popup.component/comment-popup.component/comment-popup.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ValidationModule } from 'app/softoolsui.module/validation/validation.module';
import { SofDropZoneModule } from '@softools/design';
import { SofEditIconModule, SofSpinnerModule } from '@softools/design/icons';
import { FileUploaderModule } from 'app/softoolsui.module/file-uploader/file-uploader.module';

@NgModule({
  declarations: [
    SharedFooterComponent,
    CommentWidgetComponent,
    FileAttachmentPopupComponent,
    CommentPopupComponent,
    ConfirmDeleteComponent /* moved to is own module when needed */,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    IndexPaginatorModule,
    PopupContainerModule,
    FloatingSupportWidgetModule,
    FontAwesomeModule,
    OverlayModule,
    ValidationModule,
    SofEditIconModule,
    SofDropZoneModule,
    FileUploaderModule,
    SofSpinnerModule
  ],
  exports: [SharedFooterComponent],
})
export class SharedFooterModule {}
