<div #templateContainer class="w-100" >
  <app-form-template
    [formTemplate]="template"
    [showTemplateHeader]="!hideHeader"
    [expanded]="expanded"
    [expandedHelp]="expandedHelp"
    (onToggleExpandedFormTemplate)="toggleExpandedFormTemplate($event)"
    (onToggleExpandedFormTemplateHelp)="onToggleExpandedFormTemplateHelp.emit($event)"
  >

  <div [style.margin-top]="template.TopMargin" [style.margin-bottom]="template.BottomMargin" style="max-width: 100vw" >
    <div class="container-fluid px-4 py-2" style="margin-bottom: 4px;"  *ngFor="let rowGroup of templateRowGroups; trackBy: templateRowGroupsTrackByFn">
      <div style="display: grid; grid-gap: 1.2rem;"
        appSetGridTemplateColumns
        [maxColAnyRow]="getMaxColumnsForTemplate(template.Layout)"
        [container]="templateContainer">

        <div *ngFor="let layout of rowGroup; trackBy: rowGroupTrackByFn" [id]="layout.FieldIdentifier"
            appSetGridColumnStart
            [ngClass]="{'table-scroll': isScrollable(layout.FieldIdentifier) }"
            [ngClass]="layout.NamedStyle"
            [ngStyle]="componentCss(layout)"
            [currentCol]="layout.ColumnId"
            [mode]="layout.Mode">
            <ng-container *ngTemplateOutlet="field; context: { field: getField(layout.FieldIdentifier), layout: layout }" >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </app-form-template>
</div>

<ng-template #field  let-field="field" let-layout="layout">
  <ng-container *ngIf="field; else nofield"  >
    <div *ngIf="isFieldVisible(field, record)" class="pb-2 d-flex flex-column" >
      <ng-container *ngIf=" isCartouche(layout)" >
        <ng-container *ngTemplateOutlet="cartouche; context: {field: field, layout: layout}" ></ng-container>
      </ng-container>

      <ng-container *ngIf=" !isCartouche(layout)" >
        <ng-container *ngTemplateOutlet="normalField; context: {field: field, layout: layout}" ></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #cartouche let-field="field" let-layout="layout">
  <app-cartouche-field
    [application]="application"
    [appModel]="appModel"
    [fieldModel]="field"
    [record]="record"
    [elementStyles]="fieldElementStyles(layout)" >
  </app-cartouche-field>
</ng-template>

<ng-template #normalField  let-field="field" let-layout="layout">

  <small *ngIf="!layout.HideLabel" class="field-label st-text-theme-primary noselect"
    [ngClass]="{ 'required': isInvalid(field) }"
    [ngStyle]="labelCss(layout)">
    <span *ngIf="showFieldHeader(field)" [title]="titleValue(field, record)" >
      {{layout.ShortLabel ?? field.Label}}
    </span>
    <span *ngIf="field.Icon?.length > 0"[matTooltip]="field.IconTooltip" class="theme-dark-text pe-1">
      <fa-icon
      [icon]="['fal', field.Icon]"
      size="1x"
      ></fa-icon>
    </span>

    <span
      *ngIf="hasAppStudioAccess || field.Description"
      class="info-icon pointer theme-dark-text d-none"
      cdkOverlayOrigin #el="cdkOverlayOrigin"
      (click)="infoIconClicked.emit({ field: field,  origin: el})"
      >
      <fa-icon
      [icon]="['fal', 'info-circle']"
      size="1x"
      ></fa-icon>
    </span>
  </small>

  <!-- missing:
          [isAttachmentListLoading]="isAttachmentListLoading"
          [files]="files"
   -->
  <sof-template-field
    (activateComponent)="onActivateComponent($event)"
    [appModel]="appModel"
    [commentsModel]="commentsModel"
    [attachmentsModel]="attachmentsModel"
    [recordModel]="recordModel"
    [recordUpdateController]="recordUpdateController"
    [application]="application"
    [appIdentifiers]="appIdentifiers"
    [fieldModel]="field"
    [record]="record"
    [template]="template"
    [containerType]="formContainerType"
    [form]="form"
    [containerReadOnly]="form?.ReadOnly || template?.IsReadOnly"
    [isOnline]="appModel?.globalModel.online.$|async"
    [forReport]="false"
    [inline]="false"
    [elementStyles]="fieldElementStyles(layout)" >
    [ruleState]="rulesEngine" >
  </sof-template-field>
</ng-template>

<ng-template #nofield>
  <div>No Field</div>
</ng-template>
