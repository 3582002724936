import { Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, Directive, OnDestroy } from '@angular/core';
import { Enums, Record, Form, logError, Template, ElementStyles, Failure } from '@softools/softools-core';
import { AlignmentTypeAlias } from '@softools/softools-core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { LocatorService } from 'app/services/locator.service';
import { AppField, IPerformContext } from 'app/types/fields/app-field';
import { AppService } from 'app/services/app.service';
import { Application } from 'app/types/application';
import { AppIdentifiers } from 'app/services/record/app-info';
import { IRuleState } from 'app/mvc/rules/rule-state.interface';
import { AppModel, AttachmentsModel, GlobalModel, RecordUpdateController, RecordUpdateModel, ReportModel } from 'app/mvc';
import { ComponentBase } from '../component-base';
import { RecordPatch } from 'app/workspace.module/types';
import { CommentsModel } from 'app/mvc/comments-model';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { FieldComponent } from '../fields2/field/field.component';

/** Indicates where the field is being used */
export enum ContainerType {
  /** Top level field on a form */
  Form = 1,
  /**  On a list report in inline edit mode */
  Inline,
  /**  On a list report in view mode */
  List,
  /**  Inside a grid field */
  Grid,
  /**  Inside a list field */
  ListField,
  /** Enteriing value for a filter */
  FilterEdit,
  /** On a table report */
  TableReport,
}

export interface FieldContext {
  appIdentifiers: AppIdentifiers;
  field: AppField;
  record?: Record;
  value?: any;
  alignmentOverride?: AlignmentTypeAlias;
}

export interface IFieldAppearance {
  normal?: boolean;
  cartouche?: boolean;
  image?: boolean;
  text?: boolean;
  icon?: boolean;
  primaryButton?: boolean;
  secondaryButton?: boolean;
}

/**
 * Base class for display/edit fields.
 * TValue is the type of the data managed by the field.
 */
@Directive()
export class FieldBase<TValue = any, TAppField extends AppField = AppField>
  extends ComponentBase
  implements OnInit, OnChanges, IPerformContext, OnDestroy {

  protected previousValue: TValue;

  public fieldTypes = Enums.FieldType;

  public selectListTypes = Enums.SelectListType;

  public containerTypes = ContainerType;

  public uiIdentifier: string;

  /** 
   * Ephemeral validation errors set during edit for invalid values.
   * Subtypes that support editing can set this. If the value contains
   * entries the field is currently invalid so should not be accepted.
   */
  public editValidationErrors$ = new BehaviorSubject<Array<Failure>>(null);

  /** If true or false, forces the field to be editable or not regardless of other
   * concerns. No effect if undefined.
   */
  @Input() isEditable: boolean;

  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;

  @Input() public appModel: AppModel;

  /** Report model (if the field is on a report) */
  @Input() public reportModel?: ReportModel;

  /** Record model (if the field is on a record view) */
  @Input() public recordModel?: RecordUpdateModel;

  /** Record update controller when on a record form */
  @Input() recordUpdateController?: RecordUpdateController;

  @Input() public commentsModel: CommentsModel;

  @Input() public attachmentsModel: AttachmentsModel;

  @Input() public generalController?: IGeneralController;

  @Input() application: Application;

  @Input() appIdentifiers: AppIdentifiers;

  @Input() public value: TValue;
  @Input() public record: Record;
  @Input() public forReport = false;
  @Input() public label: string;
  @Input() public disableLabel = false;
  @Input() public hideUnderline = false;
  @Input() public searchLookupField: any;
  @Input() public isOnline: boolean;

  /** Use to override the appearance of the material form fields */
  @Input() public appearance: MatFormFieldAppearance = 'standard';

  /** If true the field is embedded in another field (e.g. a grid) so should display in a compact mode */
  @Input() public isEmbedded = false;

  /** The form containing the field (not set if on a report etc.) */
  @Input() public form?: Form;

  /** The template containing the field (not set if on a report etc.) */
  @Input() public template: Template;

  /** true if the container state implies the field should be readonly */
  @Input() public containerReadOnly: boolean;

  /**
   * Field metadata.
   */
  @Input() public fieldModel: TAppField;

  /** If set, the field (e.g. list or grid field) that contains this field  */
  @Input() public containerField: AppField;

  /** The row number if the field is contained inside a list field */
  @Input() public listRow: number;

  /**
   * If the field is used in an inline template i.e Grid or List Report
   * @deprecated Use containerType and delete this when fully implemented
   */
  @Input() inline = false;

  /** Context where the field is contained
   * NB THis is currently set correctly in a couple of places to get long text formatted
   * correctly.  It should replace all uses of @see inline.
   */
  @Input() containerType = ContainerType.Form;

  /** Alignment string; left/ center/ right. No value will be default alignment.
   * ListReportField/ ListReportDetailField can have configured Alignment property via AppStudio.
   * Pass in alignment value as override here for these entity types.
   */
  @Input() alignmentOverride?: AlignmentTypeAlias;

  @Input() public ruleState: IRuleState;

  @Input() public elementStyles: ElementStyles;

  /** Set to true if field is not yet visible to the user. */
  @Input() public isFieldHidden = false;

  @Input() public padding = true;

  /** Emits when a component is clicked on
   * Currently only fired by components that need to be deactivated in a
   * table report - could ensure all components do so if needed
   */
  public componentClicked$ = new Subject<FieldBase>();

  public requestActivation$ = new Subject<FieldBase>();

  public componentFocussed$ = new Subject<FieldComponent>();

  protected appService = LocatorService.get(AppService);

  public get globalModel(): GlobalModel {
    return this.appModel?.globalModel;
  }

  ngOnInit(): void {
    try {
      this.fieldModel?.attachModel(this.appModel);

      this.previousValue = this.value;
      this.label = this.disableLabel === true ? '' : this.label;

      // Changed from randomstring so appForm.valid is accurate when multiple of the same field exists within form/ templates
      // Side effect is only the first validatable field element will show the red validation message on load, duplicate field references will show on focus
      // (ticket SOF-5769 created for this)
      // If forReport we should use random as more than one record can be expanded, so field should be unique
      this.uiIdentifier = this.forReport ? `${this.identifier}-${this.getRandomString()}` : this.identifier;
    } catch (error) {
      logError(error, '');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      // If record changes, reset prev value (we don't want the one from the old record)
      if (changes['record'] && changes['record'].currentValue !== changes['record'].previousValue) {
        delete this.previousValue;
      }

      // If we are given a value and prev is not set (n.b. null counts as set as it's a valid value)
      // then use it to initialise the prev value.  Combined with the record reset above this should
      // set the correct value without making assumptions about what order changes occur in.
      if (changes['value'] && this.previousValue === undefined) {
        this.previousValue = this.value;
      }

      if (changes['record'] || changes['listRow']) {
        if (this.record) {
          this.setValueFromRecord(this.record);
        }
      }

      if (changes['value']) {
        this.onValueChanged(this.value);
      }

    } catch (error) {
      logError(error, '');
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.componentClicked$?.unsubscribe();
  }

  public initialise(context: FieldContext) {
    this.fieldModel = context.field as TAppField;
    this.label = context.field.Label;
    this.alignmentOverride = context.alignmentOverride;
    this.appIdentifiers = context.appIdentifiers;

    if (context.record) {
      this.record = context.record;
      this.setValueFromRecord(context.record);
    } else {
      this.value = context.value;
    }

    this.fieldModel?.attachModel(this.appModel);
  }

  public fieldId() {

    if (!this.fieldModel) {
      // console.warn('no fieldModel', this);
      return 'unidentified';
    }

    switch (this.containerType) {
      case ContainerType.TableReport:
        return `${this.fieldModel.Identifier}-${this.record?._id}`;
      default:
        return this.fieldModel.Identifier;
    }
  }

  /*
    Fields2 usage
  */
  public getIsDisabled(): boolean {

    if (this.isEditable !== undefined) {
      return !this.isEditable;
    }

    if (this.containerReadOnly) {
      return true;
    }

    return this.fieldModel?.isDisabled(this.record, this.ruleState);
  }

  protected setValueFromRecord(record: Record) {
    this.value = this.fieldModel.getComponentRecordValue(record, this.listRow) as TValue;
    this.onValueChanged(this.value);
  }

  protected onValueChanged(value: TValue) {
    // nop, override to update component
  }

  public get recordValue(): TValue {
    return this.fieldModel && (this.fieldModel.getRecordValue(this.record, this.listRow) as TValue);
  }

  public get recordTextBackingValue(): string {
    return this.fieldModel && this.fieldModel.getRecordTextBackingFieldValue(this.record);
  }

  public getRandomString() {
    return (Math.floor(Math.random() * (100000 - 0 + 1)) + 0).toString();
  }

  public attrTitle() {
    return `${this.label || ''}${this.titleSeparator()}${this.value || ''}`;
  }

  public get filterValue(): any {
    return this.value;
  }

  public async activate() {
    // override to activate the field when required
    // (take focus and change display state as needed)
  }

  public async deactivate() {
  }

  public refreshValue() {

  }

  public onToggleExpandedFormTemplate() {

  }

  protected titleSeparator() {
    return this.value && this.value.toString().length > 0 && this.label ? ': ' : '';
  }

  public onKeyDown($event: KeyboardEvent) {
    return this.reportModel?.onFieldKeyDown($event);
  }

  public onKeyPress($event: KeyboardEvent) {
    return this.reportModel?.onFieldKeyPress($event);
  }

  public onKeyUp(_event) {
  }

  /*
   * Get alignment for field/ listreportfield/ listreportdetailfield; representing left/ center/ right alignment.
   * Field type will either use;
   * text-align style ('left' | 'center' | 'right') OR flex css align-items classes ('align-items-start' | 'align-items-center' | 'align-items-end').
   * AS configuration is available for Field/ ListReportField/ ListReportDetailField.
   * When alignment configuration is set, we pass in as alignmentOverride.
   * Otherwise fallback to defaults when unset.
   */
  public get alignment():
    | AlignmentTypeAlias
    | 'justify-content-start align-items-start'
    | 'justify-content-center align-items-center'
    | 'justify-content-end align-items-end' {
    if (this.alignmentOverride) {
      // ListReportField/ ListReportDetailField level
      const overrideAlignmentValue = this.getAlignmentOverrideValue();
      return overrideAlignmentValue;
    }

    if (!this.fieldModel) {
      return null;
    }

    if (this.elementStyles) {
      const styleAlign = this.elementStyles['text']?.Alignment ?? this.elementStyles['component']?.Alignment;
      switch (styleAlign) {
        case 'left':
          return 'justify-content-start align-items-start';
        case 'center':
          return 'justify-content-center align-items-center';
        case 'right':
          return 'justify-content-end align-items-end';
        default:
          break;
      }
    }

    // // Todo - field level, sof-input etc (Separate D3 ticket)
    // if (this.fieldModel.Alignment) {
    //   // Field level
    //   return this.fieldModel.Alignment;
    // }

    const reportMode = this.forReport || this.containerType === ContainerType.Inline
      || this.containerType === ContainerType.List || this.containerType === ContainerType.TableReport;

    // Defaults should apply
    switch (this.fieldModel.Type) {
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Number:
      case Enums.FieldType.Money:
      case Enums.FieldType.Range:
        // Numbers in ListReports align right by default
        return reportMode ? 'right' : 'left';

      case Enums.FieldType.Gantt:
      case Enums.FieldType.Notes:
      case Enums.FieldType.Image:
      case Enums.FieldType.InAppChart:
      case Enums.FieldType.ImageActionButton:
        return reportMode ? 'center' : 'left';

      case Enums.FieldType.Bit:
      case Enums.FieldType.MultiState:
      case Enums.FieldType.ImageList:
        return reportMode ? 'justify-content-center align-items-center' : 'justify-content-start align-items-start';

      default:
        return 'left';
    }
  }

  private getAlignmentOverrideValue():
    | AlignmentTypeAlias
    | 'justify-content-start align-items-start'
    | 'justify-content-center align-items-center'
    | 'justify-content-end align-items-end' {
    if (!this.fieldModel) {
      return null;
    }

    switch (this.fieldModel.Type) {
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Bit:
      case Enums.FieldType.ImageList:
        // bootstrap flex css align-items classes used
        switch (this.alignmentOverride) {
          case 'left':
            return 'justify-content-start align-items-start';
          case 'center':
            return 'justify-content-center align-items-center';
          case 'right':
            return 'justify-content-end align-items-end';
          default:
            return 'justify-content-start align-items-start';
        }

      default:
        // text-align style used
        return this.alignmentOverride;
    }
  }

  public get isIconDisplay(): boolean {
    return this.fieldModel.DisplayOptions && !!this.fieldModel.DisplayOptions.ShowAsIcon;
  }

  protected getAppearance(): IFieldAppearance {

    const styles = this.appModel?.getNamedStyles({
      target: 'field',
      names: this.fieldModel.NamedStyles,
      additionalStyles: [this.elementStyles?.component]
    });
    const appearances = styles?.component.Appearance?.split('|');

    const appearance: IFieldAppearance = appearances ? {
      normal: appearances.includes('normal'),
      icon: this.fieldModel.DisplayOptions?.ShowAsIcon || appearances.includes('icon'),
      text: appearances.includes('text')
    } : {
      normal: true,
      icon: this.fieldModel.DisplayOptions?.ShowAsIcon
    }

    return appearance;
  }

  public get identifier() {
    return this.fieldModel?.Identifier;
  }

  /**
   * Gets the value for  the field.  If the value is a compound value including backing
   * or other secondary values, the main value is extracted.  If the value is a simple
   * scalar, this is returned directly.
   */
  public get fieldValue(): TValue {
    if (this.value?.hasOwnProperty('val')) {
      return this.value['val'] as TValue;
    }
    return this.value;
  }

  public set fieldValue(value: TValue) {
    if (this.value?.hasOwnProperty('val')) {
      this.value['val'] = value;
    } else {
      this.value = value;
    }
  }

  protected async dispatchPatchAsync(patch: RecordPatch) {
    await this.appModel.patchRecordValue(patch);
  }
}
