import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { Observable, Subject } from 'rxjs';
import { OnlineStatusService } from '../services/online-status-service';
import { DatabaseTransaction } from './database-transaction';
import { logError } from '../utils';

function logDbError(err) {
  logError(err, 'Failed in indexed Db');
}

@Injectable({
  providedIn: 'root',
})
export class DatabaseContextService<T> {
  constructor(private database: DatabaseService, private onlineStatus: OnlineStatusService) { }

  /**
   * Create a database transaction
   * @param store
   * @param mode
   */
  public async transaction(store: string, mode: IDBTransactionMode = 'readwrite'): Promise<DatabaseTransaction> {
    const txn = new DatabaseTransaction(this.database, store, mode);
    return txn;
  }

  /**
   * Open a cursor and get records based on the keypath
   *
   * @param store the store for the database
   * @param index the index for the store
   * @param key the keypath to query the store
   * @param callback a callback for each record found, the promise will complete when the cursor does.
   */
  public async openKeyCursorAsync(store: string, index: string, key: string, callback: (key: string) => any): Promise<void> {
    try {
      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return await new Promise<void>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.index(index).openKeyCursor(key);

        request.onerror = (ev: Event) => {
          reject(ev);
        };

        request.onsuccess = function (event: any) {
          const cursor = event.target.result;
          if (cursor) {
            callback(cursor.key);
            cursor.continue();
          } else {
            // no more results
            resolve();
          }
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction for store: ${store}`);
        });
      });
    } catch (error) {
      logError(error, `Opening ${store} ${index} cursor`);
      throw error;
    }
  }

  /**
   * Open a cursor and get records based on the keypath
   *
   * @param store the store for the database
   * @param index the index for the store
   * @param key the key to query the store
   * @param callback a callback for each record found, the promise will complete when the cursor does.
   *  If the callback returns false, the cursor is terminated.
   */
  public async openCursorAsync(
    store: string,
    index: string,
    key: string | Array<string>,
    callback: (value: T, cursor: IDBCursor) => void | boolean
  ): Promise<void> {
    try {
      this._checkKey('openCursorAsync', store, key);

      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return new Promise<void>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.index(index).openCursor(key);

        request.onerror = (ev: Event) => {
          reject(ev);
        };

        request.onsuccess = function (event: any) {
          const cursor = event.target.result;
          if (cursor) {
            const result = callback(cursor.value, cursor);
            if (result === false) {  // exact, not falsy
              resolve();
            } else {
              cursor.continue();
            }
          } else {
            // no more results
            resolve();
          }
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction for store: ${store}`);
        });
      });
    } catch (error) {
      logError(error, `Opening ${store} ${index} cursor`);
      throw error;
    }
  }

  public async nativeGetAll(store: string, index: string, key: string | Array<string>): Promise<Array<T>> {
    try {
      this._checkKey('nativeGetAll', store, key);

      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return await new Promise<Array<T>>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.index(index).getAll(key);

        request.onerror = (ev: Event) => {
          reject(ev);
        };

        request.onsuccess = function (event: any) {
          resolve(event.target.result);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction for store: ${store}`);
        });
      });

    } catch (error) {
      logError(error, `Native GetAll from ${store} ${index}`);
      throw error;
    }
  }


  /**
   * Get all objects in the store.
   * @param store the name of the database object store
   * @param count if specified and non-zero, max number to return
   */
  public async getAll(store: string, count?: number): Promise<Array<T>> {
    try {
      const all: Array<T> = [];

      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return new Promise<Array<T>>((resolve, reject) => {

        const dataObjectStore = transaction.store();

        // Edge 42 does not support IDBObjectStore.getAll()
        // Code equivalent to...
        // const request = dataObjectStore.getAll();
        // request.onsuccess = () => resolve(request.result);
        const request = dataObjectStore.openCursor();

        let complete = false;
        request.onsuccess = function (event: any) {
          const cursor = event.target.result;
          if (cursor) {
            all.push(cursor.value);
            if (!count || all.length < count) {
              cursor.continue();
            } else {
              complete = true;
            }
          } else {
            // no more results
            complete = true;
          }

          if (complete) {
            resolve(all);
          }
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction for store: ${store}`);
        });
      });
    } catch (error) {
      logError(error, `Error in getting all data from store: ${store}`);
      throw error;
    }
  }


  /**
   * Get all records by the app identifier for a given store
   *
   * @param store the name of the database object store
   * @param appIdentifier
   */
  // public async getAllByAppIdenitifier(store: string, appIdentifier: string): Promise<Array<T>> {
  //   return this.getAllByIndex(store, INDEX_APPIDENTIFIER, appIdentifier);
  // }

  /**
   * This is a low level function to get by index and key path, use @function getAllByAppIdenitifier
   *
   * @param store the name of the database object store
   * @param index the index name
   * @param key the key path to the record
   */
  // private async getAllByIndex(store: string, index: string, key: string): Promise<Array<T>> {

  //   const db = await this.database.getStoreAsync();

  //   return new Promise<Array<T>>((resolve, reject) => {

  //     const dataObjectStore = db.transaction(store, 'readonly').objectStore(store);

  //     // remove any in ts 3.1+ or angular 7 updates
  //     const request = (<any>dataObjectStore.index(index)).getAll(key);

  //     request.onsuccess = () => resolve(request.result);

  //     request.onerror = (ev: Event) => reject(ev);

  //   });
  // }

  /**
   * Get an object from the given store.
   *
   * @param store the name of the database object store
   * @param key the primary key of the object
   */
  public async get(store: string, key: string | Array<string>, transaction?: DatabaseTransaction): Promise<T> {
    try {
      this._checkKey('get', store, key);

      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store);
      }

      await transaction.open();

      const result = await new Promise<T>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.get(key);

        request.onsuccess = () => {
          resolve(request.result);
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });

      return result;
    } catch (error) {
      logError(error, `Get ${store} '${key}'`);
      throw error;
    }
  }

  /**
   * Get an object from the given store looked up by index.
   *
   * @param store
   * @param index
   * @param key
   * @param transaction
   */
  public async getByIndex(store: string, index: string, key: string | Array<string>, transaction?: DatabaseTransaction): Promise<T> {
    try {
      this._checkKey('get', store, key);

      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store, 'readonly');
      }

      await transaction.open();

      return await new Promise<T>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.index(index).get(key);

        request.onsuccess = () => {
          resolve(request.result);
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Get '${key}' from ${store} ${index}`);
      throw error;
    }
  }


  /**
   * @param store the name of the database object store
   * @param appIdentifier
   * @param key
   * @param value the value to put
   * @param transaction optional transaction to update through
   *
   * If multiple objects are to be stored, they should be grouped using a transaction as this
   * improves performance
   */
  public async put(store: string, appIdentifier: string, key: string, value: Object, transaction?: DatabaseTransaction): Promise<boolean> {
    try {
      this._checkKey('put', store, key);

      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store, 'readwrite');
      }

      await transaction.open();

      return await new Promise<boolean>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.put({ ...value, AppIdentifier: appIdentifier }, key);

        request.onsuccess = () => {
          resolve(true);
        };
        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Put  ${key} to ${store}`);
      throw error;
    }
  }

  /**
   * Using this over PUT has a huge performance increase
   * Pending users storage time reduced by 30s
   * @param store
   * @param values
   */
  public async putData(store: string, values: Object[], key: string): Promise<boolean> {
    try {
      const transaction = await this.transaction(store);
      await transaction.open();
      return await new Promise<boolean>((resolve, reject) => {
        const s = transaction.store();
        this.putObject(values, s, 0, resolve, key);
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      throw error;
    }
  }

  private putObject(data, objectStore, index, resolve, key: string) {
    if (index < data.length) {
      const obj = data[index];
      const req = objectStore.put(data[index], obj[key]);
      req.onsuccess = (_) => {
        index += 1;
        this.putObject(data, objectStore, index, resolve, key);
      };
      req.onerror = () => {
        index += 1;
        this.putObject(data, objectStore, index, resolve, key);
      };
    } else {
      resolve(true);
    }
  }

  public async save(store: string, key: string | Array<string>, value: T, transaction?: DatabaseTransaction): Promise<boolean> {
    try {
      this._checkKey('save', store, key);

      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store, 'readwrite');
      }

      await transaction.open();

      return await new Promise<boolean>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.put(value, key);

        request.onsuccess = () => {
          resolve(true);
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Save  ${key} to ${store}`);
      throw error;
    }
  }

  /**
   * Write a value to a store with autogenerated key
   *
   * @param store
   * @param value
   * @param transaction
   */
  public async saveAuto(store: string, value: T, transaction?: DatabaseTransaction): Promise<boolean> {
    try {
      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store, 'readwrite');
      }

      await transaction.open();

      return await new Promise<boolean>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.put(value);

        request.onsuccess = () => {
          resolve(true);
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Save auto to ${store}`);
      throw error;
    }
  }

  /**
   * Delete a record from the data store
   * @param store the name of the database object store
   * @param key     Primary key
   */
  public async delete(store: string, key: string | Array<string>, transaction?: DatabaseTransaction): Promise<boolean> {
    try {
      this._checkKey('delete', store, key);

      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store);
      }

      await transaction.open();
      const dataObjectStore = transaction.store();
      await dataObjectStore.delete(key);
      return true;
    } catch (error) {
      logError(error, `Save  ${key} to ${store}`);
      throw error;
    } finally {
      if (!transaction) {
        transaction?.close().catch(logDbError);
      }
    }
  }

  /**
   * Get an object from the data store and remove it in a single operation.
   * As the database is asynchronous, there is a window between getting
   * the value and deleting it; do not rely on this being atomic.
   *
   * @param store the name of the database object store
   * @param key     Primary key
   */
  public async extract(store: string, key: string): Promise<T> {
    try {
      this._checkKey('extract', store, key);

      const transaction = await this.transaction(store, 'readwrite');
      const item = await this.get(store, key, transaction);
      if (item) {
        await this.delete(store, key, transaction);
      }
      return item;
    } catch (error) {
      logError(error, `Extract ${key} from ${store}`);
      throw error;
    }
  }

  /**
   * Get all key values in the store
   *
   * @param store
   */
  public async getAllKeys(store: string, transaction?: DatabaseTransaction): Promise<Array<string | Array<string>>> {
    try {
      // If no transaction specified create one just for this operation
      if (!transaction) {
        transaction = await this.transaction(store, 'readonly');
      }

      await transaction.open();

      return await new Promise<Array<string | Array<string>>>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        if (dataObjectStore.getAllKeys) {
          const request = dataObjectStore.getAllKeys();
          request.onsuccess = () => {
            resolve(request.result as any);
          };

          request.onerror = (ev: Event) => {
            reject(ev);
          };
        } else {
          // Edge 17.x does not support getAllKeys so need to fake it in a less efficient way
          const request = dataObjectStore.openCursor();
          const keys: Array<string | Array<string>> = [];
          request.onsuccess = (event: any) => {
            const cursor = event.target.result;
            if (cursor) {
              keys.push(cursor.key);
              cursor.continue();
            } else {
              resolve(keys);
            }
          };
          request.onerror = (ev: Event) => {
            reject(ev);
          };
        }
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Get keys from ${store}`);
      throw error;
    }
  }

  /**
   * Get all keys by index
   *
   * @param store the name of the database object store
   * @param index the index for the store
   * @param key the item key
   */
  // public async getAllKeysByIndex(store: string, index: string, key?: string): Promise<Array<string>> {

  //   const db = await this.database.getStoreAsync();

  //   return new Promise<Array<string>>((resolve, reject) => {

  //     const dataObjectStore = db.transaction(store, 'readwrite').objectStore(store);

  //     const request = (<any>dataObjectStore.index(index)).getAllKeys(key);

  //     request.onsuccess = () => resolve(request.result as any);

  //     request.onerror = (ev: Event) => reject(ev);

  //   });
  // }

  /**
   * Clear the object store by index and key
   *
   * @param store the name of the database object store
   * @param index the index to clear
   * @param key key
   */
  public async clearIndex(store: string, index: string, key: string) {
    try {
      this._checkKey('clearIndex', store, key);

      const transaction = await this.transaction(store, 'readwrite');
      await transaction.open();

      const deletePromises = [];

      await new Promise<void>((resolve, reject) => {

        const dataObjectStore = transaction.store();

        const request = dataObjectStore.index(index).openCursor(key);

        request.onerror = (ev: Event) => {
          reject(ev);
        };

        request.onsuccess = (event: any) => {
          const cursor = event.target.result as IDBCursor;
          if (cursor) {
            deletePromises.push(
              new Promise((r, rj) => {
                const req = cursor.delete();
                req.onsuccess = () => r(null);
                req.onerror = (ev: Event) => rj(ev);

                cursor.continue();
              })
            );
          } else {
            // no more results
            resolve();
          }
        };
      });

      await Promise.all(deletePromises).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });

      return true;
    } catch (error) {
      logError(error, `Clear ${key} index ${index} from ${store}`);
      throw error;
    }
  }

  /**
   * Clear the object store
   *
   * @param store the name of the database object store
   */
  public async clear(store: string): Promise<boolean> {
    try {
      const transaction = await this.transaction(store, 'readwrite');
      await transaction.open();

      return await new Promise<boolean>((resolve, reject) => {

        const dataObjectStore = transaction.store();

        const request = dataObjectStore.clear();

        request.onsuccess = () => {
          resolve(true);
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Clear ${store}`);
      throw error;
    }
  }

  /**
   * Get the count by for the index
   *
   * @param store the name of the database object store
   * @param index the index for the store
   * @param key the key path for the store
   */
  public async countByIndex(store: string, index: string, key?: string | Array<string>): Promise<number> {
    try {
      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return await new Promise<number>((resolve, reject) => {
        const dataObjectStore = transaction.store();

        const request = dataObjectStore.index(index).count(key);

        request.onsuccess = () => {
          resolve(request.result);
        };

        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Count ${store} ${index}`);
      throw error;
    }
  }

  /**
   * Get count of all records in the store
   * @param store the name of the database object store
   */
  public async count(store: string): Promise<number> {
    try {
      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return await new Promise<number>((resolve, reject) => {

        const dataObjectStore = transaction.store();
        const request = dataObjectStore.count();
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = (ev: Event) => {
          reject(ev);
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Count ${store}`);
      throw error;
    }
  }

  /**
   * Open a cursor and search records based on the object property and searc term
   *
   * @param store the store
   * @param index the index of the store
   * @param search the search term to query
   * @param searchProp the property to search i.e 'QuickFilterSearchText' for the App Data Store
   * @param lowerKeyPath the lower keypath i.e ' ['app1',  ''] ' for the App Data Store
   * @param upperKeyPath the upper keypath i.e ' ['app2', 'sea'] ' for the App Data Store
   * @param callback the call back for each record found
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/IDBObjectStore/openCursor
   */
  public async openCursorForSearchAsync(
    store: string,
    index: string,
    search: string,
    searchProp: string,
    keyPath: string,
    callback: (value: T) => any
  ): Promise<void> {
    try {
      const transaction = await this.transaction(store, 'readonly');
      await transaction.open();

      return await new Promise<void>((resolve, reject) => {

        const dataObjectStore = transaction.store();

        const request = dataObjectStore.index(index).openCursor(keyPath);

        request.onerror = function (ev: Event) {
          reject(ev);
        };

        request.onsuccess = function (event: any) {
          const cursor = event.target.result;
          if (cursor) {
            // cursor.value contains the current record being iterated through
            // this is where you'd do something with the result
            if (cursor.value && cursor.value[searchProp]) {
              const value = (<string>cursor.value[searchProp]).toLowerCase();

              if (value.indexOf(search.toLowerCase()) !== -1) {
                callback(cursor.value);
              }
            }
            cursor.continue();
          } else {
            // no more results
            resolve();
          }
        };
      }).finally(() => {
        transaction.close().catch(error => {
          logError(error, `Error while closing transaction`);
        });
      });
    } catch (error) {
      logError(error, `Search ${store} ${index}`);
      throw error;
    }
  }

  /**
   * Get the object from store if it exists or the server if not
   * If the object is got from store a refresh call is still made to the server
   *
   * THIS COULD BE MODIFIED IN THE FUTURE TO ALWAYS GET FROM SERVER
   * UNIT TEST ANY CHANGES TO THIS FUNCTION
   *
   * @param store the indexedDb store
   * @param appIdentifier the app identifier
   * @param keyPath the keypath to the object in store
   * @param repoFunc the refresh function
   * @param mapperFunc mapper function if you need to map the refresh function to a new object
   */
  public getAndBackgroundRefresh(
    store: string,
    appIdentifier: string,
    keyPath: string,
    repoFunc: () => Observable<T>,
    mapperFunc: Function = (data: T) => data
  ): Promise<{ value: T; source: 'store' | 'server' }> {
    return Observable.create((observer: Subject<{ value: T; source: 'store' | 'server' }>) => {
      /**
       * If the object is already in store get it and emit it.
       */
      this.get(store, keyPath).then((storeData) => {
        if (storeData) {
          observer.next({ value: storeData, source: 'store' });
          observer.complete();
        }
      }).catch(logDbError);

      /**
       * If online call the repo function to refresh the store data
       * Set the store with the new object
       */
      if (this.onlineStatus.isConnected) {
        repoFunc().subscribe(
          async (data: T) => {
            if (data) {
              try {
                const mappedValue = mapperFunc(data);
                await this.put(store, appIdentifier, keyPath, mappedValue);
                observer.next({ value: mappedValue, source: 'server' });
                observer.complete();
              } catch (err) {
                observer.error(err);
                observer.complete();
              }
            }
          },
          (err: any) => {
            observer.error(err);
            observer.complete();
          }
        );
      }
    }).toPromise();
  }

  private _checkKey(method: string, store: string, key: string | Array<string> | number) {

    if (key == null || key === undefined) {
      throw new Error(`${method} on ${store}: DB key not specified`);
    } else if (typeof key === 'string' || key instanceof String) {
      // string key must not be empty
      if (!key) {
        throw new Error(`${method} on ${store}: DB key not specified`);
      }
    } else if (typeof key === 'number' || key instanceof Number) {
      // allow any number (even 0)
    } else if (Array.isArray(key)) {
      // Array key must have items
      if (key.length === 0) {
        throw new Error(`${method} on ${store}: DB key not specified`);
      }
    } else {
      throw new Error(`${method} on ${store}: DB key ${key} not valid`);
    }
  }
}
