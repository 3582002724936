import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TableReportModel } from 'app/mvc';
import { RecordsReportModel } from 'app/mvc/reports/records-report.model';
import { ComponentBase } from 'app/softoolsui.module/component-base';

/**
 * Paginator for table report, driven by report model.
 * Can probably merge with list report when it uses report model too
 */
@Component({
  selector: 'app-table-report-paginator',
  templateUrl: './table-report-paginator.component.html',
  styleUrls: ['./table-report-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableReportPaginatorComponent extends ComponentBase implements OnInit {

  @Input() public reportModel: RecordsReportModel;

  ngOnInit(): void {
    // Track change to records model so we connect to correct observable
    this.subscribe(this.reportModel?.records.$, (recordsModel) => {
      if (recordsModel) {
        this.refresh();
      }
    });
  }

  public onFirstClicked() {
    this.reportModel.topIndex.value = 0;
  }

  public onLastClicked() {
    // const count = this.reportModel.recordsModel.value.count.value;
    const count = this.reportModel.totalCount.value;
    const top = this.reportModel.pageSize.value;
    const first = Math.max(count - top, 0);
    this.reportModel.topIndex.value = first;
  }

  public onPrevClicked() {
    const skip = this.reportModel.topIndex.value;
    const top = this.reportModel.pageSize.value;
    const first = Math.max(skip - top, 0);
    this.reportModel.topIndex.value = first;
  }

  public onNextClicked() {
    const skip = this.reportModel.topIndex.value;
    const top = this.reportModel.pageSize.value;
    // const count = this.reportModel.recordsModel.value.count.value;
    const count = this.reportModel.totalCount.value;
    const first = Math.min(skip + top, count - top);
    this.reportModel.topIndex.value = first;
  }
}
