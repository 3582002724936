
import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { Homepage, HomepageDashboardField, HomepageDashboardReport } from '../types';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomepagesRepository extends RepositoryBase {
  public getHomepages(): Observable<Homepage[]> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('v2/Homepages'));
  }

  public getHomepageDashboardFields(): Observable<HomepageDashboardField[]> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('v2/Homepages/GetHomepageDashboardFields'));
  }

  public getHomepageDashboardReports(): Observable<HomepageDashboardReport[]> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('v2/Homepages/GetHomepageDashboardReports'));
  }

}
