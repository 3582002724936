import { Record, Enums, isDefined, OdataExpressionType } from '@softools/softools-core';
import { TextualAppField } from './textual-app-field';

const RagOrder = {
  'Unset': 0,
  'unset': 0,
  'Red': 1,
  'red': 1,
  'Amber': 2,
  'amber': 2,
  'Green': 3,
  'green': 3,
  'Blue': 4,
  'blue': 4,
  'Black': 5,
  'black': 5,
  '': 0,
};

const valuesByType = {
  // Enums.MultiStateType.RAG
  0: ['Red', 'Amber', 'Green', 'Unset'],

  // Enums.MultiStateType.RAGBB
  1: ['Red', 'Amber', 'Green', 'Blue', 'Black', 'Unset'],

  // Enums.MultiStateType.HarveyBall
  2: ['0%', '25%', '50%', '75%', '100%'],

  // Enums.MultiStateType.HarveyBallTriState
  3: ['0%', '50%', '100%'],

  // Enums.MultiStateType.HarveyBallTwoState 
  4: ['0%', '100%'],
}

export class MultiStateAppField extends TextualAppField {

  public override getRecordValue(record: Record, listRow?: number) {
    const value = super.getRecordValue(record, listRow);
    switch (this.SubType) {
      case Enums.MultiStateType.HarveyBall:
      case Enums.MultiStateType.HarveyBallTwoState:
      case Enums.MultiStateType.HarveyBallTriState: {
        if (!value) {
          return '0%';
        }
        break;
      }

      case Enums.MultiStateType.RAG:
      case Enums.MultiStateType.RAGBB: {
        if (!value) {
          return 'Unset';
        }
        break;
      }
    }

    return value;
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {

    switch (this.SubType) {
      case Enums.MultiStateType.HarveyBall:
      case Enums.MultiStateType.HarveyBallTwoState:
      case Enums.MultiStateType.HarveyBallTriState: {
        // Parse values and strip off trailing %s
        const num1 = isDefined(val1) && val1 !== '' ? Number.parseInt(val1) : 0;
        const num2 = isDefined(val2) && val2 !== '' ? Number.parseInt(val2) : 0;
        return isDescending ? num1 - num2 : num2 - num1;
      }

      case Enums.MultiStateType.RAG:
      case Enums.MultiStateType.RAGBB: {
        const order1: number = RagOrder[val1 ?? 'Unset'];
        const order2: number = RagOrder[val2 ?? 'Unset'];
        return isDescending ? order2 - order1 : order1 - order2;
      }
    }

    return super.compareValues(val1, val2, isDescending);
  }

  public override finiteValues(options?: { includeNull?: boolean, reverse?: boolean }): Array<string> | null {

    const values = valuesByType[this.SubType];
    if (values) {
      if (options?.reverse) {
        [...values].reverse();  // spread as reverse is inplace
      }

      if (options?.includeNull) {
        // not adding nulls because we make it look like Unset
        // see SOF-11329
      }

      return values;
    }

    return null;
  }

  public override filterOperations(): Array<OdataExpressionType> {
    return [
      OdataExpressionType.Equals,
      OdataExpressionType.NotEquals,
      OdataExpressionType.OneOf,
      OdataExpressionType.NoneOf,
    ];
  }

  public override filterOperationName(op: OdataExpressionType) {
    return {
      1: $localize`Is`,
      2: $localize`Is Not`,
      17: $localize`One of`,
      18: $localize`None of`,
    }[op] ?? super.filterOperationName(op);
  }

  /** Does this field type have a multi-value component for this expression type? Yes! */
  public override isMultiValue(expressionType: OdataExpressionType): boolean {
    return expressionType === OdataExpressionType.OneOf || expressionType === OdataExpressionType.NoneOf;
  }

}
