import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { GanttFieldComponent, InappChartFieldComponent } from '..';
import { CheckboxModule } from '../checkbox.component/checkbox.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { DropDownModule } from '../dropdown.component/dropdown.module';
import { FieldsHelperModule } from '../fields/fields-helper/fields-helper.module';
import { PersonFieldFilterPipe } from '../fields/scalar-fields/person-field.component/pipes/person-field-filter-pipe';
import { ChartModule } from '../chart.component/chart.module';
import { DateFieldModule } from '../fields/date-field.component/date-field.module';
import { PeriodFieldModule } from '../fields/period-field.component/period-field.module';
import { PipeModule } from 'app/pipes/pipe.module';
import { AttachmentListFieldComponent } from '../fields/attachment-list-field/attachment-list-field.component';
import { DrawFieldModule } from '../fields/draw-field.component/draw-field.module';
import { ScalarFieldsModule } from '../fields/scalar-fields/scalar-fields.module';
import { PopupContainerModule } from '../popup-container/popup-container.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalOverlayModule } from '../cdk-global-overlay.directive/cdk-global-overlay.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Fields2Module } from '../fields2/fields2.module';
import { SofPlusIconModule, SofSpinnerModule } from '@softools/design/icons';
import { CameraDialogComponent } from '../camera/camera-dialog/camera-dialog.component';

@NgModule({
  declarations: [
    // FieldsComponent,
    GanttFieldComponent,
    InappChartFieldComponent,
    AttachmentListFieldComponent,
    PersonFieldFilterPipe,
    CameraDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
    ChartModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    FieldsHelperModule,
    ScalarFieldsModule,
    DateFieldModule,
    PeriodFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    DropDownModule /* should dd be its own html in the selection, touch is not needed in th dd on for selection */,
    PipeModule,
    DrawFieldModule,
    PopupContainerModule,
    OverlayModule,
    GlobalOverlayModule,
    FontAwesomeModule,
    Fields2Module,
    SofPlusIconModule,
    SofSpinnerModule,
  ],
  exports: [
    // FieldsComponent,
    InappChartFieldComponent /* In app chart is needed on list report */],
  providers: [],
})
export class FieldsModule {}
