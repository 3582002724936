import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, Params } from '@angular/router';

import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { QueryParams, ReportViewService, ReportViewParams, Report, ReportDataStorageService, logError } from '@softools/softools-core';
import { REPORT_IDENTIFIER, RECORD_ID, PARENT_RECORD_ID, APP_IDENTIFIER } from 'app/_constants';
import { QueryGuardBase } from './queryguardbase';
import { defaultReportViewParams } from '../../_constants/constants.reports';
import { ReportFilter } from '../../filters/types';
import { AppService } from 'app/services/app.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable()
export class ReportQueryGuard extends QueryGuardBase implements CanActivate {

  constructor(
    indexedDbService: IndexedDbService,
    appService: AppService,
    reportStateService: ReportDataStorageService,
    private _reportViewService: ReportViewService,
    protected router: Router,
    private models: GlobalModelService
  ) {
    super(indexedDbService, appService, reportStateService);
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const reportIdentifier = route.params[REPORT_IDENTIFIER] as string;
    const isChild = route.data['isChild'] || false;
    const parentRecordId = isChild ? route.params[PARENT_RECORD_ID] as string : null;
    const appIdentifiers = new AppIdentifiers(route.params);

    if (this.prepareRoute(route)) {
      const report = this.appService.getReport(appIdentifiers.visibleAppIdentifier, reportIdentifier);
      await this._navigate(isChild, appIdentifiers, parentRecordId, report, route);
      return false;   // don't follow route, as we've explicitly navigated to the updated route
    }

    return true;
  }

  /**
   * Handle report routing using stored query parmeters and maintaining a clean URL
   *
   * @param route route representing current navigation
   * @returns a boolean promise, true if the provided route should be followed
   */
  public async useCurrentParamsWithCleanUrl(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const reportIdentifier = route.params[REPORT_IDENTIFIER] as string;
      const appIdentifiers = new AppIdentifiers(route.params);
      const isChild = route.data['isChild'] || false;
      const recordId = isChild ? route.params[PARENT_RECORD_ID] as string : route.params[RECORD_ID] as string;
      const followRoute = await this.processQuery(route);

      if (followRoute) {
        if (this.prepareRoute(route)) {
          const report = this.appService.getReport(appIdentifiers.visibleAppIdentifier, reportIdentifier);
          await this._navigate(isChild, appIdentifiers, recordId, report, route);
          return false;   // don't follow route, as we've explicitly navigated to the updated route
        }
      }

      return followRoute;
    } catch (error) {
      logError(error, '');
      return false;
    }
  }

  /**
   * Handle query parameters for the request.
   *
   * If we don't have any saved view parameters for this report, initialise with default (if any).
   * Then update the saved view with any query parameters present on the request.  If these have
   * caused any updates to the report view, save them and reload with a clean URL.
   *
   * After this has run (and maybe reloaded) we will have a simple URL and saved view parameters
   * to control which records are displayed.
   *
   * @param route
   * @returns a promise of true if the route should be followed, false otherwise (most likely because we've naviated away)
   *
   */
  protected async processQuery(route: ActivatedRouteSnapshot): Promise<boolean> {
    const reportIdentifier = route.params[REPORT_IDENTIFIER] as string;
    const appIdentifiers = new AppIdentifiers(route.params);
    const isChild = route.data['isChild'] || false;
    const recordId = isChild ? route.params[PARENT_RECORD_ID] as string : route.params[RECORD_ID] as string;

    let changesToSavedView = false;
    let cleanUrlNeeded = false;

    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);
    const report = this.appService.getReport(appIdentifiers.visibleAppIdentifier, reportIdentifier);

    // Extract query parameters and update stored state to match
    let reportViewData: ReportViewParams = await this._reportViewService.getViewDataAsync(appIdentifiers.visibleAppIdentifier, reportIdentifier);
    if (!reportViewData) {
      reportViewData = { ...defaultReportViewParams, reportId: reportIdentifier };

      // Merge in default filter query if we have one
      const defaultFilter = await this.getDefaultFilter(appIdentifiers.visibleAppIdentifier);
      if (defaultFilter) {
        reportViewData.filterId = defaultFilter.Id;
        reportViewData.filterQuery = defaultFilter.Filter;
        reportViewData.orderBy = defaultFilter.OrderBy;
        reportViewData.groupBy = defaultFilter.Group;
      }

      // If quick filters enabled and no filter in view, use report default
      if (app.isFilterAdvanced && report.Sort) {
        if (reportViewData.orderBy === '') {
          reportViewData.orderBy = report.Sort.FieldIdentifier;
          reportViewData.orderDescending = report.Sort.Descending;
        }
      }

      changesToSavedView = true;
    }

    // Handle legacy parameters without $prefix
    const params = this.dollarize({ ...route.queryParams });

    if (params.hasOwnProperty('$skip')) {
      const skip = +params['$skip'];
      if (reportViewData.firstRecordIndex !== skip) {
        reportViewData.firstRecordIndex = skip;
        changesToSavedView = true;
      }
      cleanUrlNeeded = true;
    }

    if (params.hasOwnProperty('$top')) {
      const top = +params['$top'];
      if (reportViewData.pageSize !== top) {
        reportViewData.pageSize = top;
        changesToSavedView = true;
      }
      cleanUrlNeeded = true;
    }

    if (params.hasOwnProperty('$orderby')) {
      if (reportViewData.orderBy !== params['$orderby']) {
        reportViewData.orderBy = params['$orderby'];
        changesToSavedView = true;
      }
      cleanUrlNeeded = true;
    }

    if (params.hasOwnProperty('$groupby')) {
      if (reportViewData.groupBy !== params['$groupby']) {
        reportViewData.groupBy = params['$groupby'];
        changesToSavedView = true;
      }
      cleanUrlNeeded = true;
    }

    if (params.hasOwnProperty('$filter')) {
      // Use a report filter to parse the query params especially avoiding
      // including isarchived in the query
      const filter = new ReportFilter();
      filter.QueryParameters = params as QueryParams;

      if (app.isFilterAdvanced) {
        if (reportViewData.additionalFilter !== filter.Filter) {
          reportViewData.additionalFilter = filter.Filter;
          delete reportViewData.filterQuery;
          changesToSavedView = true;
        }
      } else {
        if (reportViewData.filterQuery !== filter.Filter) {
          reportViewData.filterQuery = filter.Filter;
          changesToSavedView = true;
        }
      }

      cleanUrlNeeded = true;
    }

    if (changesToSavedView) {
      await this._reportViewService.setViewDataAsync(appIdentifiers.visibleAppIdentifier, reportViewData);
    }

    if (cleanUrlNeeded) {
      // reset all query params, they should now match the stored set
      route.queryParams = {};
      this.prepareRoute(route);
      const report = this.appService.getReport(appIdentifiers.visibleAppIdentifier, reportIdentifier);
      await this._navigate(isChild, appIdentifiers, recordId, report, route);
      return false;   // don't follow route, as we've explicitly navigated to the updated route
    }

    return true;
  }

  /**
   * Replace query params with no dollar prefix with the correct form
   * @param params
   */
  private dollarize(params: Params) {
    const properties = ['top', 'skip', 'filter', 'orderby', 'groupby'];
    properties.forEach(prop => {
      if (params.hasOwnProperty(prop)) {
        const $prop = `$${prop}`;
        if (!params.hasOwnProperty($prop)) {
          params[$prop] = params[prop];
          delete params[prop];
        }
      }
    });

    return params;
  }

  public addArchivedFilterToQueryParams = (queryParams: QueryParams, isArchived): void => {
    const archivedFilter = 'IsArchived eq true';
    const notArchivedFilter = 'IsArchived eq false';

    let filter = queryParams.$filter;

    if (!filter) {
      filter = '';
    }

    if (filter.length > 0) {
      if (filter.indexOf('and ' + archivedFilter) >= 0) {
        filter = filter.replace(' and ' + archivedFilter, '');
      } else if (filter.indexOf(archivedFilter) >= 0) {
        filter = filter.replace(archivedFilter, '');
      }

      if (filter.indexOf('and ' + notArchivedFilter) >= 0) {
        filter = filter.replace(' and ' + notArchivedFilter, '');
      } else if (filter.indexOf(notArchivedFilter) >= 0) {
        filter = filter.replace(notArchivedFilter, '');
      }
    }

    filter = filter.trim();

    // If the isArchived parameter was first, this can leave a dangling and
    if (filter.startsWith('and')) {
      filter = filter.substring(3);
      filter = filter.trim();
    }

    if (isArchived) {
      if (filter.length > 0) {
        filter = filter + ' and ' + archivedFilter;
      } else {
        filter = filter + archivedFilter;
      }
    } else {
      if (filter.length > 0) {
        filter = filter + ' and ' + notArchivedFilter;
      } else {
        filter = filter + notArchivedFilter;
      }
    }

    queryParams.$filter = filter;
  }

  /**
   *
   * @param route
   * @returns {boolean} true if the route data was changed
   */
  protected prepareRoute(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams['$top'] || (route.queryParams['$skip'])) {
      route.queryParams = { ...route.queryParams, $top: null, $skip: null };
      return true;
    }

    return false;
  }

  protected async _navigate(isChild, appIdentifiers: AppIdentifiers, parentRecordId, report: Report, route: ActivatedRouteSnapshot) {
    const navigation = this.models.globalModel.navigation;
    if (isChild) {
      await navigation.navigateReportAsync({ appIdentifiers, parentRecordId, report });
    } else {
      await navigation.navigateReportAsync({ appIdentifier: appIdentifiers.appIdentifier, report });
    }
  }
}
