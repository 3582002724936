import { Injectable } from '@angular/core';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, BACKGROUND_PROCESSES } from '../indexedDb';

export interface BackgroundProcessItem {
  filename: string;
}

@Injectable({ providedIn: 'root' })
export class BackgroundProcessStorageService extends StorageServiceBase<BackgroundProcessItem, string> {

  constructor(
    dbContext: DatabaseContextService<BackgroundProcessItem>) {
    super(BACKGROUND_PROCESSES, dbContext);
  }

}
