import { BaseChartDataService } from './base-chart-data.service';
import { Injectable } from '@angular/core';
import { Record, Report, GaugeChartField, IndexedAppData, isDefined } from '@softools/softools-core';
import { ChartEnums } from '../chart.enums';
import { AppField } from 'app/types/fields/app-field';
import { ChartErrorException } from 'app/exceptions';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class GaugeChartDataService extends BaseChartDataService {
  private readonly notEnoughDataMessage = $localize`:@@ChartGaugeRecordError:Not enough data for averages`;
  private readonly gaugeBottomAverage = $localize`:@@ChartGaugeBottomAverage:Bottom X% average: `;
  private readonly gaugeMiddleAverage = $localize`:@@ChartGaugeMiddleAverage:Middle average: `;
  private readonly gaugeTopAverage = $localize`:@@ChartGaugeTopAverage:Top X% average: `;
  private readonly gaugeAverage = $localize`:@@ChartGaugeAverage:Average: `;
  private readonly gaugeAverageLabel = $localize`:@@ChartGaugeAverageLabel:X from Y records`;

  public async getGaugeChartDataOptions(app: Application, appDataIndex: IndexedAppData, report: Report, record?: Record) {
    const gaugeField = report.Chart.ChartFields.find(f => f.Type === ChartEnums.ChartFieldType.Gauge) as GaugeChartField;
    gaugeField.BaseField = app.getField(gaugeField.BaseFieldIdentifier) as AppField;

    // NB This does not support in-app
    let data = await this.getRecordData(app, false, appDataIndex, report);

    data = data.map(doc => (<AppField>gaugeField.BaseField).getRawRecordValue(doc)).filter(val => isDefined(val)).sort((a, b) => a - b);

    if (data.length === 0) {
      throw new ChartErrorException(ChartEnums.ChartErrors.GaugeNotEnoughData);
    }

    const overallSeriesLabel = gaugeField.OverallAverageLabel ? gaugeField.OverallAverageLabel : $localize`Overall Average`;
    const recordLabel = gaugeField.RecordLabel ? gaugeField.RecordLabel : $localize`This records value`;

    let lowerLength: number;
    let lowerBound = 0;
    let lowerAverage = 0;

    let upperLength: number;
    let upperBound = 0;
    let upperAverage = 0;

    if (gaugeField.LowerPercentage) {
      lowerLength = Math.round(data.length * (gaugeField.LowerPercentage / 100));
      if (lowerLength !== 0) {
        const lowerValues = data.slice(0, lowerLength);
        lowerBound = lowerValues[lowerValues.length - 1];
        lowerAverage = lowerValues.reduce((aggregate, val) => aggregate += val) / lowerValues.length;
      } else {
        lowerBound = 0;
      }
    }

    if (gaugeField.UpperPercentage) {
      upperLength = Math.round(data.length * (gaugeField.UpperPercentage / 100));
      if (upperLength !== 0) {
        const upperValues = data.slice(data.length - upperLength, data.length);
        upperBound = upperValues[0];
        upperAverage = upperValues.reduce((aggregate, val) => aggregate += val) / upperValues.length;
      } else {
        upperBound = 0;
      }
    }

    const nonNullLowerLength = lowerLength ?? 0;
    const nonNullLupperLength = upperLength ?? 0;

    let middleAverage: number;
    if (data.length !== nonNullLowerLength + nonNullLupperLength) {
      middleAverage = data.slice(nonNullLowerLength, data.length - nonNullLupperLength).reduce((aggregate, val) => aggregate += val) / (data.length - nonNullLowerLength - nonNullLupperLength);
    }
    const overallAverage = data.reduce((aggregate, val) => aggregate += val) / data.length;

    const reversed = report.Chart.Reversed;

    const valueSuffix = gaugeField.ValueSuffix;

    const yAxis = this.getYAxis(
      gaugeField,
      reversed,
      lowerLength,
      upperLength,
      data[0],
      lowerAverage,
      lowerBound,
      middleAverage == null ? overallAverage : middleAverage,
      upperAverage,
      upperBound,
      data[data.length - 1],
      valueSuffix ? valueSuffix : '',
      gaugeField.ToolTipDecimals || 2,
      data.length
    );

    const series = [{
      name: `${overallSeriesLabel} [${this.gaugeAverageLabel.replace(/(.*)(X)(.*?)(Y)(.*)/, `$1${overallAverage.toFixed(gaugeField.ToolTipDecimals)}$3${data.length}$5`)}]`,
      showInLegend: true,
      visible: true,
      data: [overallAverage],
      dataLabels: {
        format: `{point.y:,.${gaugeField.ToolTipDecimals || 2}f}`
      },
      tooltip: {
        valueSuffix: valueSuffix ? valueSuffix : '',
        valueDecimals: gaugeField.ToolTipDecimals || 2
      }
    }];

    if (record) {
      // const record = await this.getRecordData(app, true, appDataIndex, report, recordId);
      const value = (<AppField>gaugeField.BaseField).getRawRecordValue(record[0]);
      if (value) {
        series[0].visible = false;
        series.push({
          name: `${recordLabel} [${value.toFixed(gaugeField.ToolTipDecimals)}]`,
          showInLegend: true,
          visible: true,
          data: [value],
          dataLabels: {
            format: `{point.y:,.${gaugeField.ToolTipDecimals || 2}f}`
          },
          tooltip: {
            valueSuffix: valueSuffix ? valueSuffix : '',
            valueDecimals: gaugeField.ToolTipDecimals || 2
          }
        });
      }
    }

    const settings = {
      Series: series,
      yAxis: [yAxis]
    };

    return settings;
  }

  private getYAxis(gaugeField: GaugeChartField, reversed: boolean, lowerSet: number, upperSet: number, min: any, lowerAverage: number, lowerBound: number, middleAverage: number, upperAverage: number, upperBound: number, max: any, valueSuffix: string, decimalPlaces: number, totalLength: number) {
    const bands = new Array<any>();

    let middleLabel: string;
    if (totalLength === 1) {
      middleLabel = this.notEnoughDataMessage;
    } else if (lowerSet != null && upperSet != null) {
      middleLabel = `${this.gaugeMiddleAverage}${middleAverage.toFixed(decimalPlaces)}${valueSuffix}`;
    } else if (lowerSet == null && upperSet != null) {
      middleLabel = `${this.gaugeBottomAverage.replace('X', (100 - gaugeField.UpperPercentage).toString())}${middleAverage.toFixed(decimalPlaces)}${valueSuffix}`;
    } else if (upperSet == null && lowerSet != null) {
      middleLabel = `${this.gaugeTopAverage.replace('X', (100 - gaugeField.LowerPercentage).toString())}${middleAverage.toFixed(decimalPlaces)}${valueSuffix}`;
    } else {
      middleLabel = `${this.gaugeAverage}${middleAverage.toFixed(decimalPlaces)}${valueSuffix}`;
    }

    if (lowerSet != null) {
      bands.push({
        from: min,
        to: lowerSet ? lowerBound : min,
        color: reversed ? 'green' : 'red',
        label: {
          align: 'center',
          useHTML: true,
          text: lowerSet && totalLength !== 1 ? `${this.gaugeBottomAverage.replace('X', gaugeField.LowerPercentage.toString())}${lowerAverage.toFixed(decimalPlaces)}${valueSuffix}` : this.notEnoughDataMessage,
          style: {
            backgroundColor: reversed ? 'green' : 'red',
            borderRadius: '1rem',
            fontSize: '1rem',
            padding: '0.5rem',
            color: 'white'
          }
        },
      });
    }

    bands.push({
      from: lowerSet ? lowerBound : min,
      to: upperSet ? upperBound : max,
      color: 'yellow',
      label: {
        align: 'center',
        useHTML: true,
        text: middleLabel,
        style: {
          backgroundColor: '#B3B300',
          borderRadius: '1rem',
          fontSize: '1rem',
          padding: '0.5rem',
          color: 'white'
        }
      }
    });

    if (upperSet != null) {
      bands.push({
        from: upperSet ? upperBound : max,
        to: max,
        color: reversed ? 'red' : 'green',
        label: {
          align: 'center',
          useHTML: true,
          text: upperSet && totalLength !== 1 ? `${this.gaugeTopAverage.replace('X', gaugeField.UpperPercentage.toString())}${upperAverage.toFixed(decimalPlaces)}${valueSuffix}` : this.notEnoughDataMessage,
          style: {
            backgroundColor: reversed ? 'red' : 'green',
            borderRadius: '1rem',
            fontSize: '1rem',
            padding: '0.5rem',
            color: 'white'
          }
        }
      });
    }

    const yAxis = {
      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',
      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      min: min,
      max: max,
      labels: {
        step: 2,
        rotation: 0
      },
      plotBands: bands
    };

    return yAxis;
  }

}
