import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { getHomepageUrl } from 'app/_constants/constants.route';
import { AuthorisationService } from 'app/auth.module/services/authorisation.service';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {

  constructor(
    private router: Router,
    private authorisationService: AuthorisationService
  ) { }

  canActivate(): boolean | UrlTree {

    // If we have a valid authentication token, we don't need to login so
    // navigate back to home page
    if (this.authorisationService.isValidAuthToken()) {
      return this.router.parseUrl(getHomepageUrl());
    }

    return true;
  }
}
