import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrorDisplayComponent } from './validation-error-display/validation-error-display.component';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FontAwesomeModule,
  ],
  declarations: [ValidationErrorDisplayComponent],
  exports: [ValidationErrorDisplayComponent],
})
export class ValidationModule { }
