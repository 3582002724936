export enum VisiblityFlag {
  Hide = 0,
  Show = 1,
}

/** Visibility control */
export interface IVisibility {
  TeamId?: string;
  UserId?: string;
  Visibility: VisiblityFlag.Hide | VisiblityFlag.Show;
}
