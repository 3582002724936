import { HeaderSummaryExpression, RecordId, Record } from '@softools/softools-core';
import { ReportFilter } from 'app/filters/types';
import { FilterModel } from 'app/mvc';
import { IGroupStarts, IGroupInfo, IGroupStartOptions } from 'app/services/indexes/app-data-index';
import { Application } from 'app/types/application';
import { RecordsReportModel } from '../records-report.model';
import { AppDataAccessor } from './app-data-accessor';

export abstract class RecordDataAccessor extends AppDataAccessor {

  protected constructor(public reportModel: RecordsReportModel, public filterModel: FilterModel, app: Application) {
    super(reportModel.busy, app);
  }

  /**
   *
   * @param first
   * @param count
   * @param group If specified, get records for this group value
   */
  public abstract getRecords(first: number, count: number, group?: IGroupStarts): Promise<Array<Record>>;

  public abstract getGroupCount(): Promise<number>;

  public abstract getGroupStartsAsync(first: number, count: number, options?: IGroupStartOptions): Promise<IGroupInfo>;

  public abstract summarise(ids: Set<RecordId>): Promise<Array<HeaderSummaryExpression>>;

  public abstract isArchive(): boolean;

  public abstract getCachedRecords(first: number, count: number): Promise<Array<{ id: RecordId, record?: Record }>>;

  /** Get active report filter including search */
  protected filter(): ReportFilter {
    return this.reportModel.filter();
  }
}
