import { HeaderSummaryExpression, RecordId, Record, logError } from '@softools/softools-core';
import { IGroupStarts, IGroupInfo, DataIndex, IGroupStartOptions } from 'app/services/indexes/app-data-index';
import { InjectService } from 'app/services/locator.service';
import { SummaryExpressionsService } from 'app/services/record/summary-expressions.service';
import { Application } from 'app/types/application';
import { DataIndexService2 as DataIndexService } from 'app/services/indexes/data-index-service';
import { RecordsReportModel } from '../records-report.model';
import { RecordDataAccessor } from './record-data-accessor';
import { FilterModel } from 'app/mvc';

export class OfflineRecordDataAccssor extends RecordDataAccessor {

  @InjectService(SummaryExpressionsService)
  private readonly summaryExpressionsService: SummaryExpressionsService;

  @InjectService(DataIndexService)
  private readonly dataIndexService: DataIndexService;

  private indexPromise: Promise<DataIndex>;

  private useFiniteGroups = false;

  constructor(app: Application, reportModel: RecordsReportModel, filterModel: FilterModel) {
    super(reportModel, filterModel, app);

    if (reportModel.useFiniteGroups.value) {
      this.useFiniteGroups = true;
    }
  }

  public async initialise(): Promise<void> {
    this.resetIndex();
    await this.index();
  }

  public isArchive(): boolean {
    return false;
  }

  public async getRecords(first: number, count: number, group?: IGroupStarts): Promise<Array<Record>> {
    try {
      const index = await this.index();
      if (this.app && index) {

        if (group) {
          if (first + count >= group.count) {
            count = group.count - first;
          }
          first += group.pos;
        }

        const records = await this.app.getIndexedRecordRange(index, first, count);
        return records;
      }
    } catch (error) {
      logError(error, 'OfflineRecordsModel getRecords');
    }

    return [];
  }

  public async getCachedRecords(first: number, count: number) {
    const index = await this.index();
    if (this.app && index) {
      return index.getCachedRecords(first, count);
    }

    return [];
  }

  /** Generate summary values for app and specified row groups */
  public async summarise(ids: Set<RecordId>): Promise<Array<HeaderSummaryExpression>> {

    const index = await this.index();
    if (index) {
      const app = this.app;
      const report = this.reportModel.report.value;
      const groupFieldId = this.reportModel.filterModel.groupBy.value;
      const groupStartIds = Array.from(ids.values());
      const hierarchy = this.reportModel.hierarchy.value;

      const expressions = await this.summaryExpressionsService.getExpressions(app, report, groupFieldId, groupStartIds, hierarchy);
      if (expressions?.length > 0) {
        const results = await this.summaryExpressionsService.calculate(app, index, expressions);
        return results;
      }
    }

    return null;
  }

  public async getGroupCount(): Promise<number> {
    const index = await this.index();
    if (index) {
      return await index.getGroupStartsCount();
    }

    return 0;
  }

  /**
   * Get the group start information for a view range.
   * @returns a group info structure, count is 0 if there are no groups.
   * null indicates not ready to calculate value.
   */
  public async getGroupStartsAsync(first: number, count?: number, options?: IGroupStartOptions): Promise<IGroupInfo> {

    const index = await this.index();
    if (!index) {
      return null;
    }

    const groups = await index?.groupStartInfoAsync(options);

    if (groups.length > 0) {
      const end = count && first + count;
      // perf: get range directly rather than slicing
      return { starts: groups.slice(first, end), count: groups.length };
    }

    // fall back to empty
    return { starts: [], count: 0 };
  }

  /** Get active index.
  * Promise ensures multiple calls get the same index; call resetIndex when a new one is required e.g. a new filter
  */
  private index(): Promise<DataIndex> {
    if (!this.indexPromise) {
      this.indexPromise = new Promise<DataIndex>((resolve, reject) => {
        this.countBusy$.next(true);
        const mergedFilter = this.filter();
        const hierarchy = this.reportModel.hierarchy.value;

        this.dataIndexService.getIndex(this.app, mergedFilter?.QueryParameters || {}, null, hierarchy)
          .then(async index => {
            index.useFiniteGroups(this.useFiniteGroups);
            await index.indexAll();
            if (!this.closed) {
              this.count$.next(index.length);
            }
            this.countBusy$.next(false);
            return index;
          })
          .then(index => {
            resolve(index);
          })
          .catch(e => {
            this.countBusy$.next(false);
            reject(e);
          });
      });
    }

    return this.indexPromise.then(index => {
      // Update index to reflect any changed records
      return index.reindexChangedRecords();
    });
  }

  public override resetIndex() {
    this.indexPromise = null;
  }

  public override async recordChanged(record: Record) {
    const index = await this.index();
    index.recordChanged(record._id);
    this.resetIndex();
  }

}
