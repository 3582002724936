import { Component, ViewChild, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { Team, Invitation, UsersRepository, permissionSets, logError, InviteResponse, stringCompare } from '@softools/softools-core';
import { TeamsService } from 'app/services/teams.service';

export interface UserInvitePopupData {
  teams: Array<Team>;
}

@Component({
  selector: 'app-user-invite-popup',
  templateUrl: './user-invite-popup.component.html',
  styleUrls: ['./user-invite-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInvitePopupComponent {

  @ViewChild('teamlist', { static: true }) private teamlist: MatSelectionList;

  @ViewChild('permlist', { static: true }) private permlist: MatSelectionList;

  public addresses = '';

  public permissionSets = permissionSets;

  public addedCount = 0;

  public errorCount = 0;

  public errorMessage = '';

  public successMessage = '';

  public isRequestSent = false;

  constructor(
    public dialogRef: MatDialogRef<UserInvitePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserInvitePopupData,
    private usersRepository: UsersRepository,
    private teamsService: TeamsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  public get teams() {
    return this.data.teams.sort((a, b) => stringCompare(a.Name, b.Name)).map(team => team.Name);
  }

/**
 * Whether or not the invite model is valid.
 * Addresses are always required.
 * Teams are required when any exist on the site.
 * Permissions are not required, we can have users with no permissions.
*/
  public get inviteValid() {
    const addressesValid = this.addresses &&
      this.addresses.trim().length > 0;

    const teamsValid = this.data.teams.length === 0
      || this.teamlist.selectedOptions.selected.length > 0;

    const isValid = this.isRequestSent ? false : (addressesValid
      && teamsValid);

    return isValid;
  }

  public onInvite(_$event: MouseEvent) {

    const teams = this.teamsService.getAllTeams();
    const ids = this.teamlist.selectedOptions.selected.map(opt => teams.find(t => t.Name === opt.value)?.Id);

    try {
      this.setButtonText(true);
      const re = /$/m;
      const invitation: Invitation = {
        EmailAddresses: this.addresses.split(re).map(a => a.trim()).filter(a => a.length > 0),
        Permissions: this.permlist.selectedOptions.selected.map(opt => opt.value.name),
        TeamIds: ids,
      };

      this._inviteAsync(invitation).catch(error => logError(error, 'Failed to invite'));

    } catch (error) {
      logError(error, '');
    }
    finally {
      this.setButtonText(false);
    }

  }

  private setButtonText(requestSent: boolean) {
    this.isRequestSent = requestSent;
    if (requestSent) {
      this.errorMessage = '';
      this.successMessage = '';
      this.errorCount = 0;
      this.addedCount = 0;
    }
    this.changeDetectorRef.detectChanges();
  }
  public onCancel(_$event: MouseEvent) {
    this.dialogRef.close();
  }

  private async _inviteAsync(invitation: Invitation) {
    const rsvp = await this.usersRepository.inviteAsync(invitation);

    // The API is returning failed items in the success collection.
    // Remove them. This can be simplified once SOF-10219 fully deployed
    const success = rsvp.Sucessful?.filter((addr) => rsvp.Errors.findIndex(err => err.ErrorDetails === addr) < 0)
      ?? [];

    this.addedCount = success.length;
    this.successMessage = success.join('<br/>');

    this.errorCount = rsvp.Errors?.length ?? 0;
    this.errorMessage = rsvp.Errors?.map(message => `${message.ErrorDetails}: ${message.Message}`).join('<br/>') ?? '';

    // Remove succesful email addresses from the list
    this.addresses = this.addresses
      .split('\n')
      .map(a => a.trim())
      .filter(a => !success.includes(a))
      .join('\n');

    this.changeDetectorRef.detectChanges();
  }
}
