import { Injectable } from '@angular/core';
import { AccessedReportData } from '../types/interfaces/accessed-report-data';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, REPORT_DATA_STORE } from '../indexedDb';

@Injectable({ providedIn: 'root' })
export class ReportDataStorageService extends StorageServiceBase<Array<AccessedReportData>, string> {

  constructor(
    dbContext: DatabaseContextService<Array<AccessedReportData>>) {
    super(REPORT_DATA_STORE, dbContext);
  }

  /**
   * Get all report data for an app
   */
  public async getAllReportDataAsync(appIdentifier: string): Promise<Array<AccessedReportData>> {
    return this.get(appIdentifier);
  }

  public async setAllReportDataAsync(appIdentifier: string, data: Array<AccessedReportData>): Promise<Array<AccessedReportData>> {
    await this.save(appIdentifier, data);
    return data;
  }

  public async getReportDataAsync(appIdentifier: string, reportIdentifier: string): Promise<AccessedReportData> {
    const data = await this.getAllReportDataAsync(appIdentifier);
    if (data) {
      const reportdata = data.find(r => r.reportIdentifier === reportIdentifier);
      return reportdata;
    }

    return null;
  }

  public async setReportDataAsync(appIdentifier: string, reportdata: AccessedReportData): Promise<Array<AccessedReportData>> {
    const data = await this.getAllReportDataAsync(appIdentifier) || [];
    const index = data.findIndex(r => r.reportIdentifier === reportdata.reportIdentifier);
    if (index < 0) {
      data.push(reportdata);
    } else {
      data[index] = reportdata;
    }

    return this.setAllReportDataAsync(appIdentifier, data);
  }

}
