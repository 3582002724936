import { Enums, Failure, IFormatting, isDefined, OdataExpressionType, Record, ValidationError } from '@softools/softools-core';
import { AppField } from './app-field';
import { RecordPatch } from 'app/workspace.module/types';
import { Application } from '../application';

export class NumericAppField extends AppField<number> {

  private formatOptions: Intl.NumberFormatOptions;

  public override initialise(app: Application) {
    super.initialise(app);

    if (this.Formatting) {
      const options: Intl.NumberFormatOptions = {};
      if (this.Formatting.Currency) {
        options.style = 'currency';
        options.currency = this.Formatting.Currency;
        options.currencyDisplay = this.Formatting.CurrencyISO ? 'code' : 'symbol';
      } else if (this.Formatting.DisplayPercent) {
        options.style = 'percent';
      }

      if ('DisableDigitGrouping' in this.Formatting) {
        options.useGrouping = !this.Formatting.DisableDigitGrouping;
      }

      if ('MinDecimalPlaces' in this.Formatting) {
        options.minimumFractionDigits = this.Formatting.MinDecimalPlaces;
      }

      if ('MaxDecimalPlaces' in this.Formatting) {
        options.maximumFractionDigits = this.Formatting.MaxDecimalPlaces;
      }

      this.formatOptions = options;
    }
  }

  public override getDisplayRecordValue(record: Record, listRow?: number) {

    if (this.Formatting) {
      const raw = this.getRawRecordValue(record, listRow);
      return this.formatDisplayValue(raw);
    }

    return super.getDisplayRecordValue(record, listRow);
  }

  public override formatDisplayValue(value: any, formatting?: IFormatting) {
    if (this.Formatting) {
      const locale = window?.navigator?.language ?? 'en-GB';
      const formatted = Intl.NumberFormat(locale, this.formatOptions).format(value);
      if (this.Formatting.Prefix || this.Formatting.Suffix) {
        return `${this.Formatting.Prefix}${formatted}${this.Formatting.Suffix}`;
      } else {
        return formatted;
      }
    }

    return super.formatDisplayValue(value, formatting);

  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {

    if (val1 === undefined) {
      return val2 === undefined ? 0 : 1;
    } else if (val2 === undefined) {
      return -1;
    }

    return isDescending ? val2 - val1 : val1 - val2;
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {

    super.validate(value, errors, listRow);

    // Check min/max values
    // If we confirm these only apply to numeric fields, can pull into that class
    // Only applies if a value is set
    if (value !== undefined && value != null) {
      if (this.MinValue !== undefined) {
        if (+value < this.MinValue) {
          errors.push({
            error: ValidationError.BelowMinimum,
            param: this.MinValue,
            fieldId: this.Identifier,
            listRow
          });
        }
      }

      if (this.MaxValue !== undefined) {
        if (+value > this.MaxValue) {
          errors.push({
            error: ValidationError.AboveMaximum,
            param: this.MaxValue,
            fieldId: this.Identifier,
            listRow
          });
        }
      }
    }

    // Field type specific validation.
    // todo more specific types then can remove the switch..
    switch (this.Type) {
      case Enums.FieldType.Long: {
        if (!this._isValidLong(value)) {
          errors.push({
            error: ValidationError.InvalidLong,
            fieldId: this.Identifier,
            listRow
          });
        }
        break;
      }
      case Enums.FieldType.Number: {
        if (!this._isValidFloat(value)) {
          errors.push({
            error: ValidationError.InvalidFloat,
            fieldId: this.Identifier,
            listRow
          });
        }
        break;
      }
    }
  }

  public isValid(value: any) {
    // todo more specific types then can remove the switch..
    switch (this.Type) {
      case Enums.FieldType.Long:
        return this._isValidLong(value);
      case Enums.FieldType.Number:
      case Enums.FieldType.Range:
      default:
        return this._isValidFloat(value);
    }
  }

  public override updatePatchWithDefault(patch: RecordPatch) {
    if (this.DefaultValue !== undefined && !this.DefaultValueIsExpression && this.isValid(this.DefaultValue)) {
      patch.addChange(this.Identifier, +this.DefaultValue);
    }
  }

  public override updatePatchFromStringValue(patch: RecordPatch, value: string) {
    patch.addChange(this.Identifier, +value);
  }

  private _isValidLong(value: string): boolean {
    if (value !== undefined && value !== null && value !== '') {
      // Parse float, as validation needs to detect if value is a decimal and therefore invalid
      const num = Number.parseFloat(value);
      return (!Number.isNaN(num) && Number.isSafeInteger(num));
    } else {
      // no number specified; this is valid unless Required rule specified
      return true;
    }
  }

  private _isValidFloat(value: string): boolean {
    if (value !== undefined && value !== null && value !== '') {
      const num = Number.parseFloat(value);
      return (!Number.isNaN(num));
    } else {
      // no number specified; this is valid unless Required rule specified
      return true;
    }
  }

  /** Format a value suitable for insertion in a filter string */
  public override constantForFilter(value: any) {
    return `${value}`;
  }


  public override filterOperations(): OdataExpressionType[] {
    return [
      OdataExpressionType.Equals,
      OdataExpressionType.NotEquals,
      OdataExpressionType.LessThan,
      OdataExpressionType.LessThanOrEqual,
      OdataExpressionType.GreaterThan,
      OdataExpressionType.GreaterThanOrEqual,
      OdataExpressionType.OneOf,
      OdataExpressionType.NoneOf,
      OdataExpressionType.Between,
    ];
  }
}

export class IntegerAppField extends NumericAppField {

  public override isValid(value: any) {
    return this._isValidInteger(value);
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    super.validate(value, errors, listRow);

    if (!this._isValidInteger(value)) {
      errors.push({ error: ValidationError.InvalidInt, fieldId: this.Identifier, listRow });
    }
  }

  public override finiteValues(options?: { includeNull?: boolean, reverse?: boolean }): Array<number> | null {
    if (isDefined(this.MinValue) && isDefined(this.MaxValue)) {
      const range = (this.MaxValue - this.MinValue) + 1;
      if (range > 0 && range <= 16) {
        const values = Array.from(new Array<number>(range), (_, i) => i + this.MinValue);

        if (options?.reverse) {
          values.reverse();
        }

        if (options?.includeNull) {
          values.push(null);
        }

        return values;
      }
    }

    return null;
  }

  private _isValidInteger(value: string): boolean {
    if (value !== undefined && value !== null && value !== '') {
      // Parse float, as validation needs to detect if value is a decimal and therefore invalid
      const num = Number.parseFloat(value);
      return (!Number.isNaN(num) && Number.isSafeInteger(num));
    } else {
      // no number specified; this is valid unless Required rule specified
      return true;
    }
  }
}
