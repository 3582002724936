import { Component, Input, ViewChild, ElementRef, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { ChartData, isDefined, logError } from '@softools/softools-core';
import { NavigationService } from 'app/services/navigation.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { ChartReportModel } from 'app/mvc';
import { ComponentBase } from '../component-base';

export interface LocationMetaDataJson {
  Title?: string;
  Description?: string;
  Colour?: string;
  ExternalLinkName?: string;
  ExternalLinkUrl?: string;
}

export interface GoogleMapPointData {
  RecordId: string;
  Hierarchy: string;
  Longitude: number;
  Latitude: number;
  LocationMetaDataJson: string;
  Colour: string;
  Title: string;
  Description: string;
  LinkName: string;
  LinkUrl: string;
}

@Component({
  selector: 'app-chart-google',
  templateUrl: './chart-google.component.html',
  styleUrls: ['./chart-google.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartGoogleComponent extends ComponentBase implements OnInit {

  private _map: google.maps.Map;
  private _bounds: google.maps.LatLngBounds; // For auto centre and auto zoom

  @Input() reportModel: ChartReportModel;
  @Input() appIdentifiers: AppIdentifiers;
  @Input() chartContainerId: string;

  @Output() onGoogleChartClickthrough = new EventEmitter<string>();

  @ViewChild('chartgoogle', { static: true }) chartGoogleEl: ElementRef;

  constructor(private navigationService: NavigationService) { super(); }

  public ngOnInit(): void {
    this.subscribe(this.reportModel.chartData.$, async (data) => {
      try {
        await this._initialiseMap(data);
      } catch (error) {
        logError(error, 'Update gmap');
      }
    });
  }

  private _initialiseMap = async (data: ChartData): Promise<void> => {
    const loader = new Loader({
      apiKey: environment.googleMapsApiKey
    });
    await loader.load();

    if (this.chartGoogleEl) {
      const mapOptions: google.maps.MapOptions = {};

      this._bounds = new google.maps.LatLngBounds();
      this._map = new google.maps.Map(this.chartGoogleEl.nativeElement, mapOptions);

      this._loadMapData(data);
    }
  }

  private _loadMapData = (data: ChartData): void => {
    if (data?.Series) {
      const series: Array<GoogleMapPointData> = data.Series[0].data;

      series.forEach(s => {
        const position = { lat: s.Latitude, lng: s.Longitude };
        if (isDefined(position.lat) && isDefined(position.lng)) {
          let locationMetaDataJson: LocationMetaDataJson = {};

          try {
            locationMetaDataJson = s.LocationMetaDataJson ? JSON.parse(s.LocationMetaDataJson) : {};
          } catch (err) {
            // Unexpected/ invalid json should not prevent map from rendering
            logError(err, 'Error parsing GoogleMap LocationMetaDataJson');
          }

          // title and description default to each other if only one specified
          const title = s.Title ?? locationMetaDataJson.Title ?? s.Description;
          const description = s.Description ?? locationMetaDataJson.Title ?? s.Title;

          const colour = s.Colour ?? locationMetaDataJson.Colour;
          const marker = this._createMarker(position, title, colour);
          const content = this._getMarkerInfoWindowContent(s.RecordId, title, description, s.LinkName, s.LinkUrl, locationMetaDataJson);
          if (content) {
            this._addMarkerInfoWindow(marker, content);
          }
        }
      });
      this._autoCenterAndAutoZoom();
    }
  }

  private _getMarkerInfoWindowContent = (recordId: string, title: string, description: string, linkName: string, linkUrl: string, locationMetaDataJson: LocationMetaDataJson) => {
    description = description ? description : locationMetaDataJson.Description;
    if (!title || !description) {
      return null;
    }

    const recordUrl = this.navigationService.getRecordUrl2(this.appIdentifiers, recordId);
    const externalLinkName = linkName ? linkName : locationMetaDataJson.ExternalLinkName;
    const externalLinkUrl = linkUrl ? linkUrl : locationMetaDataJson.ExternalLinkUrl;
    let externalLink: HTMLParagraphElement;

    if (externalLinkName && externalLinkUrl) {
      externalLink = document.createElement('p');
      const atag = document.createElement('a');
      atag.setAttribute('href', externalLinkUrl);
      atag.setAttribute('target', '_blank');
      const nameText = document.createElement('b');
      nameText.appendChild(document.createTextNode(externalLinkName));
      atag.appendChild(nameText);
      externalLink.appendChild(atag);
    }

    const content = document.createElement('div');
    content.setAttribute('id', 'content');

    const siteNotice = document.createElement('div');
    siteNotice.setAttribute('id', 'siteNotice');
    content.appendChild(siteNotice);

    const firstHeading = document.createElement('h1');
    firstHeading.setAttribute('id', 'firstHeading');
    firstHeading.appendChild(document.createTextNode(title));
    content.appendChild(firstHeading);

    const bodyContent = document.createElement('div');
    bodyContent.setAttribute('id', 'bodyContent');
    content.appendChild(bodyContent);

    const descriptionP = document.createElement('p');
    descriptionP.appendChild(document.createTextNode(description));
    bodyContent.appendChild(descriptionP);

    const clickthroughPara = document.createElement('p');
    bodyContent.appendChild(clickthroughPara);

    const clickthroughSpan = document.createElement('span');
    clickthroughPara.appendChild(clickthroughSpan);
    clickthroughSpan.appendChild(document.createTextNode($localize`Open Record`));
    clickthroughSpan.setAttribute('style', 'cursor: pointer; color: blue;');
    clickthroughSpan.onclick = () => this.onGoogleChartClickthrough.emit(recordUrl);

    if (externalLink) {
      bodyContent.appendChild(externalLink);
    }

    return content;
  }

  private _autoCenterAndAutoZoom(): void {
    this._map.fitBounds(this._bounds); // auto zoom
    this._map.panToBounds(this._bounds); // auto center
  }

  private _createMarker(position: { lat: number, lng: number }, title: string, colour: string): any {
    const markerOptions = {
      position: position,
      map: this._map,
      title: title,
      icon: this._getMarkerIconUrl(colour)
    } as google.maps.MarkerOptions;

    const marker = new google.maps.Marker(markerOptions);
    const markerLoc = new google.maps.LatLng(position.lat, position.lng);
    this._bounds.extend(markerLoc);
    return marker;
  }

  private _addMarkerInfoWindow(marker: any, content: Element): void {
    const infowindow = new google.maps.InfoWindow({
      content: content
    });

    marker.addListener('click', () => {
      infowindow.open(this._map, marker);
    });

    google.maps.event.addListener(this._map, 'click', () => {
      infowindow.close();
    });
  }

  private _getMarkerIconUrl(colour: string) {
    const symbol = {
      path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
      fillOpacity: 1,
      strokeColor: 'white',
      strokeWeight: 2
    } as google.maps.Symbol;
    if (!colour) { colour = ''; }
    switch (colour.toLowerCase()) {
      case 'red':
        return { ...symbol, fillColor: '#e51c23' } as google.maps.Symbol;
      case 'amber':
        return { ...symbol, fillColor: '#ffc107' } as google.maps.Symbol;
      case 'green':
        return { ...symbol, fillColor: '#259b24' } as google.maps.Symbol;
      case 'blue':
        return { ...symbol, fillColor: '#5677fc' } as google.maps.Symbol;
      case 'black':
        return { ...symbol, fillColor: '#1a1a1a' } as google.maps.Symbol;
      default:
        return { ...symbol, fillColor: '#cccccc' } as google.maps.Symbol;
    }
  }

}
