import { Component, ChangeDetectionStrategy, Directive } from '@angular/core';
import { FieldSelectionBase } from '../field-selection-base';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ISelectionValue, logError, logMessage, SelectionListChange, SelectListOption } from '@softools/softools-core';
import { MultiSelectionAppField } from 'app/types/fields/selection-app-field';
import { BehaviorSubject } from 'rxjs';

@Directive()
export abstract class MultiSelectionFieldBase
  extends FieldSelectionBase<Array<ISelectionValue>, MultiSelectionAppField> {

  /** The all option if selected */
  public selectedAllOption$ = new BehaviorSubject<SelectListOption>(null);

  protected override onValueChanged(value: Array<ISelectionValue>): void {

    super.onValueChanged(value);

    const selected = this.fieldModel.simpleValue(value);

    // Is a configured "all" option defined
    const options = this.fieldModel.getSelectListOptions();
    const all = options.find(o => o.Implies?.All);
    if (all) {
      if (selected?.find(v => v.Value === all.Value)) {
        this.selectedAllOption$.next(all);
      } else {
        this.selectedAllOption$.next(null);
      }
    } else {
      this.selectedAllOption$.next(null);
    }

    // If we're given a scalar value, convert it to an array as we're expecting
    // This handles a specific case where the field subtype has been changed from single to multi
    // select (SOF-6089) but changing field types could cause no end of unpredicatble mess.  See SOF-6104.
    if (selected !== null && selected !== undefined && !Array.isArray(selected)) {
      logMessage(`MultiSelectionFieldBase wrapping non array value '${selected}'`);
      this.fieldValue = [selected];
    }
  }

  public isDisabledOption(option: SelectListOption): boolean {
    const selectedAll = this.selectedAllOption$.value;
    if (selectedAll && (option.Value !== selectedAll.Value)) {
      return true;
    }

    return super.isDisabled()
  }

  public isChecked(optionValue: string | number): boolean {
    try {
      if (this.selectedAllOption$.value) {
        return true;
      }

      return !!this.fieldValue?.find(r => r.Value === optionValue);
    } catch (error) {
      logError(error, `MultiSelectionFieldBase.isChecked value: '${this.fieldValue}'`);
      return false;
    }
  }

  protected override getOptionText(value: any) {
    if (Array.isArray(value)) {
      return value.map(val => this.selectList?.SelectListOptions?.find(opt => opt.Value === val.Value)?.Text).join(', ');
    }

    // fall back, might have a single value or a backing field
    return super.getOptionText(value);
  }


  protected selectionChanged(optionValue: string, checked: boolean) {
    try {
      const change = new SelectionListChange();

      const value = this.fieldModel.simpleValue(this.value);
      if (value) {

        // Find existing entry if in collection
        const index = value.findIndex(item => item.Value === optionValue);
        if (checked) {
          // Add into collection if not already present
          if (index < 0) {
            this.setvalue([...value, { Value: optionValue }]);
            change.added = [{ Value: optionValue }];
          }
        } else {
          // Remove from collection if present
          if (index >= 0) {
            const update = [...value];
            update.splice(index, 1);
            this.setvalue(update);
            change.removed = [{ Value: optionValue }];
          }
        }
      } else {
        if (checked) {
          this.setvalue([{ Value: optionValue }]);
          change.added = [{ Value: optionValue }];
        }
      }

      if (change.added || change.removed) {
        this.dispatchChangeAsync(change).catch(e => logError(e, 'Failed to dispatch change'));
      }

    } catch (error) {
      logError(error, 'MultiSelectionFieldBase.selectionChanged');
    }
  }

  private setvalue(newValue: any) {
    this.fieldValue = newValue;
  }
}

@Component({
  selector: 'app-selection-check-field',
  templateUrl: './selection-check-field.component.html',
  styleUrls: ['../selection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionCheckFieldComponent extends MultiSelectionFieldBase {

  public onChange($event: MatCheckboxChange, value) {
    this.selectionChanged(value, $event.checked);
  }
}
