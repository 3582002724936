<div *ngIf="viewModelLoaded">
  <table
    *ngIf="headerFields"
    class="table w-100 table-hover"
    appAutoLayout
    #layout="appAutoLayout"
    [globalModel]="reportModel.globalModel"
    [enabled]="enableAutoLayout"
    [runOnInit]="!autoLayoutOffByDefault"
    [showColumn]="showColumn"
    [hideColumn]="hideColumn"
    [headerSummaryExpressions]="headerSummaryExpressions"
    [tableKey]="reportModel.tableKey$|async"
  >
    <!-- Header for filterable lists -->
    <thead
      app-filterheader
      cdkOverlayOrigin
      #filterheader="cdkOverlayOrigin"
      style="border-collapse:collapse; border-spacing:0"
      [appIdentifier]="(appModel.app.$|async)?.Identifier"
      [allowMultiSelect]="allowMultiSelect"
      [formBase]="this"
      [headerFields]="headers()"
      [headerSummaryExpressions]="headerSummaryExpressions"
      [parentFieldIdentifier]="parentFieldIdentifier"
      [filterModel]="filterModel"
      [filter]="filterModel.filter"
      [reportModel]="reportModel"
      [selectAll]="this.reportModel.selectionModel.all.$|async"
      [filterEditOrigin]="filterheader"
      (onSelectAll)="this.reportModel.selectionModel.selectAll()"
      (onDeselectAll)="this.reportModel.selectionModel.deselectAll()"
      (onToggleAutoLayoutClick)="onToggleAutoLayoutClick.emit($event)"
      [showAutoLayoutPopup]="false"
      [showDetailsField]="showDetailsField"
      [expandAllDetailsFields]="expandDetailsFields.size > 0"
      [showGroupsExpanded]="anyGroups()"
      [groupsExpanded]="anyGroupsExpanded()"
      [showFilterManagement]="showFilterManagement"
      (toggleDetailsFields)="toggleDetailsFields()"
      (toggleGroups)="toggleGroups()"
    ></thead>

    <tbody>
      <ng-container *ngIf="(reportModel.records.$|async)?.length > 0">
        <ng-container *ngFor="let record of reportModel.records.$|async; let i = index; trackBy: trackRow">
          <ng-container *ngIf="showGroup(record)">
            <tr class="bg-light-st noautolayout theme-dark-text noselect">
              <td *ngIf="allowMultiSelect">&nbsp;</td>
              <td colspan="100%">
                <div (click)="toggleGroup(record)" class="pointer">
                  <fa-icon
                    *ngIf="!groupsCollapsed.has(groupKey(record)); else thenChevUpBlock"
                    [icon]="['fal', 'chevron-circle-down']"
                    size="1x"
                    class="me-2"
                  ></fa-icon>
                  <ng-template #thenChevUpBlock><fa-icon [icon]="['fal', 'chevron-circle-up']" size="1x" class="me-2"></fa-icon></ng-template>
                  <b *ngIf="!hasGroupValue(record); else thenHasGroupValue" i18n>No Value</b>
                  <ng-template #thenHasGroupValue
                    ><b>{{ groupDisplayValue(record) }}</b></ng-template
                  >
                </div>
              </td>
            </tr>
            <tr *ngIf="headerSummaryExpressions.length > 0" class="noHover bg-light-st noautolayout theme-dark-text">
              <td *ngIf="allowMultiSelect" class="border border-dark border-end-0 border-start-0">&nbsp;</td>
              <td
                *ngFor="let headerField of headers(); let index = index"
                (click)="headerField._Editable && recordCellClicked($event, record, i, index)"
                class="text-truncate st-text-theme-primary border border-dark border-end-0 border-start-0"
                style="max-width: 16rem;"
                [style.text-align]="getHeaderAlignment(headerField._BackingField.Type, headerField._BackingField.Alignment)"
                [attr.column-identifier]="headerField._BackingField.Identifier"
                [attr.column-priority]="headerField.ColumnPriority"
                [class.hidden]="headerField.Hidden"
              >
                <app-summary-expression
                  [appIdentifier]="(appModel.app.$|async)?.Identifier"
                  [fieldIdentifier]="headerField._BackingField.Identifier"
                  [headerSummaryExpressions]="headerSummaryExpressions"
                  [headerFields]="headers()"
                  [groupFieldIdentifier]="groupFieldIdentifier"
                  [groupValue]="groupValue(record)"
                ></app-summary-expression>
              </td>

              <td *ngIf="showDetailsField" class="border border-dark border-end-0 border-start-0">
                &nbsp;
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="record">
            <tr
            [hidden]="groupsCollapsed.has(groupKey(record))"
            [class.selected-row]="isRowSelected(record._id)"
            >
              <td class="d-flex" [style.height.px]="48" >
                <div class="pb-1 " style="padding-top: 0.6rem!important; position: relative; left:0; top: 0;">
                  <app-checkbox *ngIf="allowMultiSelect"
                    [disabled]="this.reportModel.selectionModel.all.$|async"
                    [checked]="this.reportModel.selectionModel.isSelected(record._id) || (this.reportModel.selectionModel.all.$|async)"
                    (onchecked)="this.reportModel.selectionModel.select(record?._id);"
                    (onunchecked)="this.reportModel.selectionModel.deselect(record?._id);"
                  ></app-checkbox>
                </div>

                <div [ngStyle]="getBorderStyle(record)" ></div>
              </td>

              <ng-container *ngIf="editMode && !record?.IsArchived">
                <td
                  *ngFor="let headerField of headers(); let index = index"
                  class="pt-1 pb-1"
                  [style.min-width]="headerField._Width"
                  [style.height.px]="48"
                  [class.td-readonly]="!headerField._Editable"
                  [class.pointer]="isViewable || headerField._Editable"
                  [class.hidden]="headerField.Hidden"
                  [attr.column-identifier]="headerField._BackingField.Identifier"
                  [attr.column-priority]="headerField.ColumnPriority"
                  (click)="(isViewable || headerField._Editable) && recordCellClicked($event, record, i, index )"
                >
                  <app-field  class="my-2 py-2"
                    [application]="appModel.app.$|async"
                    [appModel]="appModel"
                    [appIdentifiers]="appModel.appIdentifiers.$|async"
                    [fieldModel]="headerField._BackingField"
                    [record]="record"
                    [containerType]="listReportContainerType"
                    [forReport]="true"
                    [inline]="true" >
                  </app-field>
                </td>
              </ng-container>

              <ng-container *ngIf="!editMode || record?.IsArchived">
                <td
                  *ngFor="let headerField of headers(); let index = index"
                  class="pt-1 pb-1 text-truncate"
                  style="max-width: 16rem;"
                  [class.archived-row]="record?.IsArchived"
                  [class.pointer]="isViewable || headerField._Editable"
                  [class.hidden]="headerField.Hidden"
                  [attr.column-identifier]="headerField._BackingField.Identifier"
                  [attr.column-priority]="headerField.ColumnPriority"
                  (click)="(isViewable || headerField._Editable) && recordCellClicked($event, record, i, index)"
                >
                  <ng-template *ngIf="useDisplayFieldDirective(headerField._BackingField)"
                    appDisplayField
                    [appIdentifiers]="appModel.appIdentifiers.$|async"
                    [record]="record"
                    [field]="headerField._BackingField"
                    [alignmentOverride]="headerField.Alignment"
                    [containerType]="listReportContainerType"
                    [appModel]="appModel"
                    [commentsModel]="commentsModel"
                    [attachmentsModel]="attachmentsModel"
                  >
                  </ng-template>

                  <app-field  *ngIf="!useDisplayFieldDirective(headerField._BackingField)"
                    [application]="appModel.app.$|async"
                    [appModel]="appModel"
                    [appIdentifiers]="appModel.appIdentifiers.$|async"
                    [fieldModel]="headerField._BackingField"
                    [record]="record"
                    [containerType]="listReportContainerType"
                    [forReport]="true"
                    [reportModel]="reportModel"
                    [inline]="true" >
                  </app-field>

                  <!-- <app-fields *ngIf="!useDisplayFieldDirective(headerField._BackingField)"
                    [appIdentifier]="application?.Identifier"
                    [appIdentifiers]="appIdentifiers"
                    [application]="application"
                    [field]="headerField._BackingField"
                    [editMode]="true"
                    [record]="record"
                    [forReport]="true"
                    [inline]="true"
                    [inListReportView]="true"
                    [fields]="application?.AppFields"
                  >
                  </app-fields> -->

                </td>
              </ng-container>

              <td *ngIf="showDetailsField">
                <span class="pointer" (click)="toggleDetailsField(record?._id)">
                  <fa-icon
                    *ngIf="!expandDetailsFields.has(record?._id); else detailsChevronCircleUp"
                    [icon]="['fal', 'chevron-circle-down']"
                    size="1x"
                  ></fa-icon>
                  <ng-template #detailsChevronCircleUp>
                    <fa-icon [icon]="['fal', 'chevron-circle-up']" size="1x"></fa-icon>
                  </ng-template>
                </span>
              </td>
              <!-- <td *ngIf="enableAutoLayout">
                  &nbsp;
                </td> -->
            </tr>

            <!-- [hidden] has better perf but will add more dom elements -->
            <tr *ngIf="showDetailsField"
              [hidden]="!(expandDetailsFields.has(record?._id)) || groupsCollapsed.has(groupKey(record))"
              class="bg-light"
            >
              <td colspan="100%" class="p-0">
                <!-- We need to set a fix width for the details template, this should be set to the window width - the menu -->
                <div [style.max-width]="clientWidth">
                  <app-listreportdetails
                    [appModel]="appModel"
                    [detailsFields]="detailsFields"
                    [fields]="fields"
                    [record]="record"
                    [reportIdentifier]="reportIdentifier"
                    [appIdentifiers]="appModel.appIdentifiers.$|async"
                    [application]="appModel.app.$|async"
                    [parentFieldIdentifier]="parentFieldIdentifier"
                    [inlineEdit]="editMode"
                    [isHidden]="!(expandDetailsFields.has(record?._id)) || groupsCollapsed.has(groupKey(record))"
                    (onFieldUpdate)="onFieldUpdate.emit($event)"
                    (onRecordClick)="onRecordClick.emit({ event: $event, record: record, row: i })"
                  >
                  </app-listreportdetails>
              </div>
              </td>
            </tr>

            <tr *ngIf="expandedChartReport(record) as exp" class="bg-light" >
              <td colspan="100%">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col">
                      <app-inapp-chart-field
                        [appIdentifier]="appModel.app.value?.Identifier"
                        [appIdentifiers]="appModel.appIdentifiers.value"
                        [fieldModel]="expandedChartField(record)"
                        [inAppChartReportIdentifier]="exp"
                        [record]="record"
                        [recordId]="record?._id"
                        [chartContainerId]="expandedChartContainer(record)"
                      >
                      </app-inapp-chart-field>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>

  <ng-container *ngIf="!(reportModel.busy.busyDelayed$|async) && (this.reportModel.records.$ | async)?.length === 0">
    <app-ws-sharednorecords
      [app]="appModel.app.$|async"
      [reportModel]="reportModel"
      [isLoading]="reportModel.busy.busy$|async"
      [isArchived]="appModel.globalModel.archived.$|async">
    </app-ws-sharednorecords>
  </ng-container>

</div>
