import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnChanges, ChangeDetectionStrategy, Input } from '@angular/core';

import { DateFieldComponent } from '../date-field/date-field.component';
import { MatDatepicker } from '@angular/material/datepicker';

import * as moment from 'moment';
import { EpochConverter } from 'app/types/epoch-converter';

@Component({
  selector: 'app-period-field-2',
  templateUrl: './period-field.component.html',
  styleUrls: ['./period-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodFieldComponent extends DateFieldComponent implements OnInit, OnChanges {

  @ViewChild('dateinput', { static: true }) override inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('datepicker', { static: true }) override datePicker: MatDatepicker<null>;

  constructor(
    protected override renderer: Renderer2,
  ) {
    super(renderer);
  }

  public override async activate() {
    this.input?.nativeElement.focus();
  }

  public override onKeyPress($event: KeyboardEvent) {
    if ($event.code === 'Space') {
      this.datePicker.open();
      return true;
    }

    return super.onKeyPress($event);
  }

  public override openPicker($event?: MouseEvent) {
    $event.stopPropagation();
    this.datePicker.open();
  }

  public monthSelected(normlizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    this.fieldValue = EpochConverter.toEpoch(normlizedMonth);
    this.setEditedValue(normlizedMonth);
    datepicker.close();
  }

  public override get displayValueFormat() {
    return 'MMMM YYYY';
  }
}
