import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LaunchpadModel } from 'app/mvc/headers/launchpad.model';
import { popupStateAnimation } from 'app/_constants';

@Component({
  selector: 'app-categories-filter',
  templateUrl: './categories-filter.component.html',
  styleUrls: ['./categories-filter.component.scss'],
  animations: [popupStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesFilterComponent {

  public state = 'inactive';

  @Input() launchpadModel: LaunchpadModel;

  @Output() catChecked = new EventEmitter();
  @Output() catUnchecked = new EventEmitter();
}
