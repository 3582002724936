import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormHeaderComponent {

  @Input() showAppIcon: boolean;
  @Input() showAppTitle: boolean;
  @Input() showAppDescription: boolean;
  @Input() showHelpIcon = false;

  @Input() appImageUrl = '';
  @Input() iconBase64?: string;
  @Input() appTitle: string;
  @Input() appDescription: string;

  @Output() helpClicked = new EventEmitter();

  constructor() { }
}
