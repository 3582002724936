import { Injectable } from '@angular/core';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { SEARCH_TERM_STORE } from '../indexedDb/database-stores';

interface SavedSearchTerm {
    appIdentifier: string;
    reportIdentifer: string;
    hierarchy: string;
    term: string;
}

@Injectable({ providedIn: 'root' })
export class SearchTermStorageService {

    constructor(private dbContext: DatabaseContextService<SavedSearchTerm>) {
    }

    public async getSearchTermAsync( appIdentifier: string, reportIdentifer: string, hierarchy: string = '' ): Promise<string> {

      // Validate parameters
      if (!appIdentifier || !reportIdentifer) {
        throw new Error(`getSearchTermAsync: Invalid keys '${appIdentifier}':'${reportIdentifer}':'${hierarchy}`);
      }

      const key = [ appIdentifier, reportIdentifer, hierarchy];
      const value = await this.dbContext.get(SEARCH_TERM_STORE, key);
      return value && value.term;
    }

    public async storeSearchTermAsync( term: string, appIdentifier: string, reportIdentifer: string, hierarchy: string = '' ): Promise<boolean> {
        const key = [ appIdentifier, reportIdentifer, hierarchy];
        if (term) {
            const value = { appIdentifier, reportIdentifer, hierarchy, term };
            return this.dbContext.save(SEARCH_TERM_STORE, key, value);
        } else {
            return this.dbContext.delete(SEARCH_TERM_STORE, key);
        }
    }
}
