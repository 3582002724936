import { Component, OnInit } from '@angular/core';
import { TextDisplayFieldComponent } from './text-display-field.component';

@Component({
  selector: 'sof-multi-line-text-display-field',
  templateUrl: './multi-line-text-display-field.component.html',
  styleUrls: [
    './multi-line-text-display-field.component.scss',
    './text-display-field.component.scss',
    '../input.scss'
  ]
})
export class MultiLineTextDisplayFieldComponentComponent extends TextDisplayFieldComponent implements OnInit {

}
