import { NgModule } from '@angular/core';
import { CameraComponent } from './camera.component';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [CameraComponent],
  imports: [
    MatCardModule,
    MatSlideToggleModule,
    MatButtonModule,
    FontAwesomeModule,
  ],
  exports: [
    CameraComponent,
  ]
})
export class CameraModule {

}
