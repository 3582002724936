import { Component } from '@angular/core';
import { AggregateAppField } from 'app/types/fields/aggregate-app-field';
import { TextDisplayFieldComponent } from '../text-display-field/text-display-field.component';

@Component({
  selector: 'app-aggregate-field',
  templateUrl: '../text-display-field/text-display-field.component.html',
  styleUrls: ['./aggregate-field.component.scss', '../text-display-field/text-display-field.component.scss', '../input.scss']
})
export class AggregateFieldComponent extends TextDisplayFieldComponent<AggregateAppField> {
}
