import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HomeComponent } from 'app/home.module/components/home.component/home.component';
import { HomepageComponent } from 'app/home.module/components/homepage.component/homepage.component';
import { AppsExistsGuard } from 'app/guards/apps.guard';
import { ModalModule } from '../softoolsui.module/modal.component/modal.module';
import { SharedFooterModule } from '../softoolsui.module/shared/shared-footer.component/share-footer.module';
import { FoldersModule } from '../softoolsui.module/folders.component/folders.module';
import { DashboardModule } from '../softoolsui.module/dashboardreport.component/dashboardreport.module';
import { OverlaySpinnerModule } from 'app/softoolsui.module/overlay-spinner.component/overlay-spinner.module';
import { FloatingSupportWidgetModule } from 'app/softoolsui.module/floatingsupportwidget/floatingSupportWidget.module';
// import { globalGuards } from 'app/guards/global-guards';
import { CustomNotSupportedModule } from 'app/softoolsui.module/customnotsupported.component/customnotsupported.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AssetImagesModule } from 'app/softoolsui.module/asset-image/asset-images.module';
import { SofSpinnerModule } from '@softools/design/icons';
// import { HOMEPAGE_IDENTIFIER } from 'app/_constants';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    OverlaySpinnerModule,
    SharedFooterModule,
    FoldersModule,
    DashboardModule,
    AssetImagesModule,
    FloatingSupportWidgetModule,
    CustomNotSupportedModule,
    // RouterModule.forChild([
    //   { path: '', component: HomeComponent, canActivate: [...globalGuards] },
    //   { path: `:${HOMEPAGE_IDENTIFIER}`, component: HomeComponent, canActivate: [...globalGuards] }
    // ]),
    FontAwesomeModule,
    SofSpinnerModule,
  ],
  declarations: [HomeComponent, HomepageComponent],
  providers: [AppsExistsGuard],
})
export class HomeModule {}
