
import { Component, Inject } from '@angular/core';

import { OverlayVideo } from '@softools/softools-core';
import { OVERLAY_VIDEO_DATA } from 'app/_constants/constants.injection-tokens';

@Component({
  selector: 'app-overlay-video',
  templateUrl: './overlay-video.component.html',
  styleUrls: ['./overlay-video.component.scss']
})
export class OverlayVideoComponent {

  constructor(@Inject(OVERLAY_VIDEO_DATA) public video: OverlayVideo) { }

}
