import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy, ViewChild, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {

  @ViewChild('searchInput', { static: true })
  public searchInput: ElementRef<HTMLInputElement>;

  @Input() searchText = '';
  @Input() isLoading = false;
  @Input() expandOnFocus = false;
  @Input() searchPending = false;
  @Input() takeFocus = true;

  @Output() onSearch = new EventEmitter<any>();
  @Output() onEnter = new EventEmitter<string>();
  @Output() onEscape = new EventEmitter();

  public ngOnInit(): void {
    if (this.takeFocus) {
      // Set focus on input if configured
      this.focus();
    }
  }

  public onKeyPressed(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      this.onEnter.emit(target.value);
    }
  }

  public onKeyUp(event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      if (event.key !== 'Escape') {
        const target = event.target as HTMLInputElement;
        this.onSearch.emit({ event: event, value: target.value });
      } else {
        const target = event.target as HTMLInputElement;
        target.value = '';
        this.onEscape.emit();
      }
    }
  }

  public focus() {
    setTimeout(() => {
      // slight delay to avoid other components grabbing it - and it looks nice
      this.searchInput?.nativeElement.focus();
    }, 500);
  }
}
