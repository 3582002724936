import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { VERSION_NUMBER } from 'app-version';
import { enableDebugTools } from '@angular/platform-browser';

console.log(`SofTools ${VERSION_NUMBER}`);

try {
  if (navigator.storage) {
    navigator.storage.estimate().then((estimate) => {
      console.log(`Used ${estimate.usage / (1024 * 1024)} MB of ${estimate.quota / (1024 * 1024)} MB storage quota `);
    }).catch(console.error);
  } else {
    console.log('No storage manager available');
  }
} catch (_) {
  console.log('No storage manager available');
}

if (environment.production) {
  enableProdMode();
}

// HACK: This is needed for the sw to register. A new angular project does not
// need this.
if ('serviceWorker' in navigator && environment.production) {
  navigator.serviceWorker.register('/ngsw-worker.js').catch(console.error);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZone: 'noop' })
  .then((module) => {
    if (environment.build == 'prerelease' || environment.build == 'local') {
      enableDebugTools(module.injector.get(ApplicationRef).components[0]);
    }
  })
  .catch((err) => console.error(err));
