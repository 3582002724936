import { Injectable } from '@angular/core';
import { StorageServiceBase, CHANGE_QUEUE_STORE, DatabaseContextService, RecordId } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';

/**
 * Service that manages a change queue
 *
 * This implements the first phase of a two-phase update model.  Patches are entered into this
 * queue in response to updates; they are then extracted by the patch queue to combine
 * pending changes before making updates.
 */
@Injectable({ providedIn: 'root' })
export class ChangeQueueStorageService extends StorageServiceBase<RecordPatch, string> {

  constructor(dbContext: DatabaseContextService<RecordPatch>) {
    super(CHANGE_QUEUE_STORE, dbContext);
  }

  public async queue(patch: RecordPatch) {
    await this.saveAuto(patch);
  }

  public async getChange(key: string): Promise<RecordPatch> {
    const change = await this.get(key);
    return RecordPatch.copy(change);  // concretize
  }

  public async count() {
    const keys = await this.keys();
    return keys.length;
  }

  public async deleteForRecord(id: RecordId) {
    const keys = await this.keys();
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const patch = await this.getChange(key);
      if (patch._id === id) {
        await this.delete(key);
      }
    }
  }

  public override async clear(): Promise<boolean> {
    localStorage.removeItem('latest-change-id');
    return await super.clear();
  }
}
