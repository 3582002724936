import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { BellComponent } from './bell/bell.component';
import { ActionsComponent } from './actions.component/actions.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActionListModule } from './action-list/action-list.module';
import { ActionsBottomSheetModule } from './actions-bottom-sheet/actions-bottom-sheet.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationMessageModule } from '../notification-message/notification-message.module';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    HeaderComponent,
    BellComponent,
    ActionsComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    FontAwesomeModule,
    MatBottomSheetModule,
    ActionsBottomSheetModule,
    ActionListModule,
    OverlayModule,
    NotificationMessageModule,
    MatRippleModule
  ],
  exports: [HeaderComponent],
  providers: []
})
export class HeaderModule { }
