import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { getCurrentUser, logError, setCurrentUser, SiteSettingsStorageService, UserStorageService } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';
import moment from 'moment';
import { EmbeddedAppsService } from 'app/embedded.apps/embedded-apps.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getHomepageUrl } from 'app/_constants';

@Component({
  selector: 'app-license-confirmation',
  templateUrl: './license-confirmation.component.html',
  styleUrls: ['./license-confirmation.component.scss']
})
export class LicenseConfirmationComponent implements OnInit {


  public licenseRequired = false;
  public licenseTitle = '';
  public licenseMessage = '';
  public licenseAccepted = false;

  public gdprRequired = false;
  public gdprTitle = '';
  public gdprMessage = '';
  public gdprAccepted = false;

  constructor(private settingsService: SiteSettingsStorageService,
    private embeddedAppsService: EmbeddedAppsService,
    private userStorageService: UserStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetect: ChangeDetectorRef) { }

  async ngOnInit() {
    const settings = await this.settingsService.getSettingsAsync();
    if (settings) {
      this.licenseRequired = settings.LicensingSignupEnabled;
      if (this.licenseRequired) {
        this.licenseTitle = settings.LicensingSignupTitle;
        this.licenseMessage = settings.LicensingSignupContent;
      }

      this.gdprRequired = settings.GDPRSignupEnabled;
      if (this.gdprRequired) {
        this.gdprTitle = settings.GDPRSignupTitle;
        this.gdprMessage = settings.GDPRSignupContent;
      }
    }

    // Set initial checkbox states - they *should* both be unchecked
    try {
      const user = getCurrentUser();
      this.licenseAccepted = user.LicensingSignedUp;
      this.gdprAccepted = user.GDPRSignedUp;
    } catch (e) {
      logError(e, '');
    }
    this.changeDetect.detectChanges();
  }

  public continueDisabled = () => {
    return (this.gdprRequired && !this.gdprAccepted) ||
      (this.licenseRequired && !this.licenseAccepted);
  }

  public continueClicked = (e: Event) => {
    e.stopPropagation();

    if (this.licenseAccepted || this.gdprAccepted) {
      this.agree().catch(error => logError(error, 'agree license'));
    }
  }

  private async agree() {
    const user = getCurrentUser();

    const acceptGdpr = this.gdprRequired && !user.GDPRSignedUp && this.gdprAccepted;
    const acceptLicense = this.licenseRequired && !user.LicensingSignedUp && this.licenseAccepted;
    if (acceptGdpr) {
      user.GDPRSignedUp = true;
      user.GDPRSignedUpContent = this.gdprMessage;
      user.GDPRSignedUpDateTime = null; // todo
    }

    if (acceptLicense) {
      user.LicensingSignedUp = true;
      user.LicensingSignedUpContent = this.licenseMessage;
      user.LicensingSignedUpDateTime = null; // todo
    }

    const patch = new RecordPatch(user.Id, '', '', true);
    const now = moment().utc().toDate();

    if (acceptGdpr) {
      patch.addChange('GDPRSignedUp', true)
        .addChange('GDPRSignedUpDateTime', now)
        .addChange('GDPRSignedUpContent', this.gdprMessage);
    }

    if (acceptLicense) {
      patch.addChange('LicensingSignedUp', true)
        .addChange('LicensingSignedUpDateTime', now)
        .addChange('LicensingSignedUpContent', this.licenseMessage);
    }

    const userApp = this.embeddedAppsService.usersApp;
    const userRecord = await userApp.upsertAsync(patch);
    const updated = userApp.fromRecord(userRecord);
    await this.userStorageService.replaceUser(updated);

    // Also update the user in local storage.  We should always be updating the current user
    // but check to be sure.
    const current = getCurrentUser();
    if (current.Id === user.Id) {
      setCurrentUser(user);
    }

    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    const url = returnUrl ? returnUrl : getHomepageUrl();
    await this.router.navigateByUrl(url);
  }
}
