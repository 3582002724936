import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Application } from 'app/types/application';

export enum MessageType {
  ConfirmDeleteFilter = 0,
  FilterDeletedMessage = 1,
  ComplexFilterMessage = 2,
  ConfirmBulkUnlink = 3,
  ConfirmBulkSecurityOverride = 5,
  DisplayBaseFilter = 6,
  ConfirmDeleteAppData = 7,
  AppDataDeleted = 8,
  ConfirmSetBaseFilter = 9,
  ConfirmClearBaseFilter = 10,
  NoSelection,
  ConfirmArchive,
  ConfirmArchiveAll,
  ConfirmUnarchive,
  ConfirmUnarchiveAll,
  ConfirmLink,
  ConfirmLinkAll,    // ?
  ConfirmUnlink,
  ConfirmUnlinkAll,
  ConfirmDelete,
  ConfirmDeleteAll,
  ConfirmCancelImport,
  ConfirmDeactivate,
  ConfirmReactivate,
  ConfirmReactivateAll,
  DisallowAll
}

export interface MessageDialogData {
  Type: MessageType;
  Value?: string;
  Application?: Application;
  Count?: number;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDialogComponent {

  static async show(dialog: MatDialog, config: MessageDialogData): Promise<boolean> {
    const messageDialog = dialog.open(MessageDialogComponent, {
      data: config,
      panelClass: '',
      backdropClass: '',
      maxWidth: '100vw',
      width: '100vw',
    });

    return messageDialog.afterClosed().toPromise();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: MessageDialogData,
    private dialogRef: MatDialogRef<MessageDialogComponent>,
  ) { }

  public submit(e: Event) {
    // Stop normal submit processing, don't want to reload
    e.stopPropagation();
    e.preventDefault();

    this.dialogRef.close(true);
  }

  public get isFilterDeleted() { return this.dialogData.Type === MessageType.FilterDeletedMessage; }
  public get isConfirmDelete() { return this.dialogData.Type === MessageType.ConfirmDeleteFilter || this.dialogData.Type === MessageType.ConfirmDeleteAppData; }
  public get isConfirmDeleteFilter() { return this.dialogData.Type === MessageType.ConfirmDeleteFilter; }
  public get isConfirmDeleteOther() { return this.isConfirmDelete && this.dialogData.Type !== MessageType.ConfirmDeleteFilter; }
  public get isConfirmDeleteAppData() { return this.dialogData.Type === MessageType.ConfirmDeleteAppData; }
  public get isAppDataDeleted() { return this.dialogData.Type === MessageType.AppDataDeleted; }

  public get isComplexFilter() { return this.dialogData.Type === MessageType.ComplexFilterMessage; }
  public get isConfirmBulkUnlink() { return this.dialogData.Type === MessageType.ConfirmBulkUnlink; }
  public get isConfirmBulkSecurityOverride() { return this.dialogData.Type === MessageType.ConfirmBulkSecurityOverride; }
  public get isDisplayBaseFilter() { return this.dialogData.Type === MessageType.DisplayBaseFilter; }

  public get isConfirmSetBaseFilter() { return this.dialogData.Type === MessageType.ConfirmSetBaseFilter; }
  public get isConfirmClearBaseFilter() { return this.dialogData.Type === MessageType.ConfirmClearBaseFilter; }

  public get isNoSelection() { return this.dialogData.Type === MessageType.NoSelection; }

  public get isConfirmArchive() { return this.dialogData.Type === MessageType.ConfirmArchive; }
  public get isConfirmArchiveAll() { return this.dialogData.Type === MessageType.ConfirmArchiveAll; }
  public get isConfirmUnarchive() { return this.dialogData.Type === MessageType.ConfirmUnarchive; }
  public get isConfirmUnarchiveAll() { return this.dialogData.Type === MessageType.ConfirmUnarchiveAll; }
  public get isConfirmDeactivate() { return this.dialogData.Type === MessageType.ConfirmDeactivate; }
  public get isConfirmReactivate() { return this.dialogData.Type === MessageType.ConfirmReactivate; }
  public get isConfirmReactivateAll() { return this.dialogData.Type === MessageType.ConfirmReactivateAll; }
  public get isConfirmConfirmDelete() { return this.dialogData.Type === MessageType.ConfirmDelete; }
  public get isConfirmConfirmDeleteAll() { return this.dialogData.Type === MessageType.ConfirmDeleteAll; }
  public get isDisallowAll() { return this.dialogData.Type === MessageType.DisallowAll; }

  public get isConfirmUnlink() { return this.dialogData.Type === MessageType.ConfirmUnlink; }
  public get isConfirmUnlinkAll() { return this.dialogData.Type === MessageType.ConfirmUnlinkAll; }

  public get isConfirmCancelImport() { return this.dialogData.Type === MessageType.ConfirmCancelImport; }

  public get isYesNo() {
    switch (this.dialogData.Type) {
      case MessageType.ConfirmDeleteFilter:
      case MessageType.ConfirmDeleteAppData:
      case MessageType.ConfirmBulkUnlink:
      case MessageType.ConfirmBulkSecurityOverride:
      case MessageType.ConfirmSetBaseFilter:
      case MessageType.ConfirmClearBaseFilter:
      case MessageType.ConfirmArchive:
      case MessageType.ConfirmArchiveAll:
      case MessageType.ConfirmUnarchive:
      case MessageType.ConfirmUnarchiveAll:
      case MessageType.ConfirmUnlink:
      case MessageType.ConfirmUnlinkAll:
      case MessageType.ConfirmDelete:
      case MessageType.ConfirmDeleteAll:
      case MessageType.ConfirmCancelImport:
      case MessageType.ConfirmDeactivate:
      case MessageType.ConfirmReactivate:
      case MessageType.ConfirmReactivateAll:
        return true;
      default:
        return false;
    }
  }

  public get isMessage() {
    return this.isFilterDeleted || this.isComplexFilter ||
      this.isDisplayBaseFilter || this.isAppDataDeleted || this.isNoSelection || this.isDisallowAll;
  }

  public get value() { return this.dialogData.Value || ''; }
}
