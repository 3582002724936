import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlResolver } from '../utils';
import { RepositoryBase } from './repositories.base';
import { MulticastHttpService } from './multicast-http.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SystemApi extends RepositoryBase {
  private lastServerTimeCheckAt = 0;

  private serverTimeDelta = 0;

  /** Interval after which we refresh value from server regardless of cache settings */
  private readonly refreshInterval = 1000 * 60 * 5;

  constructor(urlResolver: UrlResolver, http: HttpClient, multicastHttpService: MulticastHttpService) {
    super(urlResolver, http, multicastHttpService);
  }

  /**
   * Query the current server timestamp
   * @returns A promise yielding the server time in Unix Millisecond format.
   */
  public async getServerTimeAsync(): Promise<number> {
    const url = this.urlResolver.resolveV2Url('System', 'Time');
    return lastValueFrom(this.get(url));
  }

  /**
   * Get the difference between client and server timestamps.
   * The value is cached and updated every 5 minutes (as the clocks going out of sync
   * once the difference is established would be unusual).
   *
   * @param forceFresh    If true, forces an updated timestamp to be retrieved
   * @returns             A promise yielding the difference in milliseconds.  A negative value means the server clock is ahead of the client.
   */
  public async getServerTimeDifferenceAsync(forceFresh = false): Promise<number> {
    const timeBefore = moment.utc();

    if (forceFresh || timeBefore.valueOf() > this.lastServerTimeCheckAt + this.refreshInterval) {
      // Get new value from server
      const serverTime = await this.getServerTimeAsync();
      const timeAfter = moment.utc();

      // Approximate when server processed by averaging before and after time
      // Then take offset
      this.lastServerTimeCheckAt = timeAfter.valueOf();
      this.serverTimeDelta = (timeBefore.valueOf() + timeAfter.valueOf()) / 2 - serverTime;
    }

    return this.serverTimeDelta;
  }
}
