import { Injectable } from '@angular/core';
import { logError, Record, tryGetCurrentUser } from '@softools/softools-core';
import { Subject } from 'rxjs';
import { AppService } from './app.service';
import { UsersService } from './users.service';

/**
 * Service that manages change notifcation for app data records, and for other entities
 * e.g. users.
 *
 * Current implementation only track user changes
 */
@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionService {

  private running = false;

  private timer;

  /**
   * Emit changed records when the back end indicates a change.
   * Currently only fires for User and PendingUser pseudo-records
   */
  public recordChanged$ = new Subject<Array<Record>>();

  constructor(private usersService: UsersService, private appService: AppService) { }

  public start() {

    // Get apps to process changes
    const userApp = this.appService.application('Softools.User');
    const pendingApp = this.appService.application('Softools.PendingUsers');

    this.running = true;
    this.timer = setInterval(async () => {
      // Only check if we have a logged in user (will fail otherwise)
      if (tryGetCurrentUser()) {
        // Check for changes (and update in storage)
        const changes = await this.usersService.checkUpdates();
        if (this.running) {
          if (changes.users?.length > 0) {
            if (userApp) {
              const records = changes.users.map(user => userApp.toRecord(user));
              this.recordChanged$.next(records);
            }
          }

          if (changes.pendingUsers?.length) {
            if (pendingApp) {
              const records = changes.users.map(user => pendingApp.toRecord(user));
              this.recordChanged$.next(records);
            }
          }
        }
      }
    }, 10000);
  }

  public stop() {
    this.running = false;   // immeditaely turn off notification callbacks
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
