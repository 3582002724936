import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldBase, FieldContext } from '..';
import { FileAttachment, logError } from '@softools/softools-core';
import { BehaviorSubject } from 'rxjs';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { DownloadInfo } from 'app/types/download-info.interface';

@Component({
  selector: 'app-attachment-list-field',
  templateUrl: './attachment-list-field.component.html',
  styleUrls: ['./attachment-list-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentListFieldComponent extends FieldBase {

  public attachments$ = new BehaviorSubject<Array<FileAttachment>>([]);
  public displayedColumns = ['fileName', 'user', 'date'];
  public fullList = false;
  public loaded = false;

  constructor(
    models: GlobalModelService) {
    super();

    this.subscribe(models.globalModel.online.$, (online) => {
      if (online) {
        this.loadAttachments();
      }
    });
  }

  public override initialise(context: FieldContext) {
    super.initialise(context);

    if (this.recordModel) {
      this.subscribe(this.attachmentsModel?.attachments.$, (attachments) => {
        if (attachments) {
          const files = this.fullList ? attachments : attachments?.slice(0, 5);
          this.attachments$.next(files);
        } else {
          this.attachments$.next([]);
        }
      });
    }
  }

  public downloadFileClicked(item: FileAttachment) {
    const info: DownloadInfo = {
      url: `/Download/${item.ParentId}/${item.Id}`,
      filename: item.Title ?? item.Name
    };
    this.appModel.globalModel.downloadNamedFileAsync(info).catch(e => logError(e, 'downloadNamedFile'));
  }

  loadAttachments() {
    if (this.isOnline) {
      this.loaded = true;
    }
  }

  toggleFullList() {
    this.fullList = !this.fullList;
  }
}
