import { Injectable } from '@angular/core';
import { Report, IndexedAppData, Record } from '@softools/softools-core';
import { SeriesData } from '../chart-utilities';
import { ChartEnums } from '../chart.enums';
import { BaseChartDataService } from './base-chart-data.service';
import { AppField } from 'app/types/fields/app-field';
import { isDateField } from 'app/_constants';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class ScatterChartDataService extends BaseChartDataService {
  // TODO: When offline expressions are added this needs to be finished
  //   private setDataPoints(series: Array<any>, chartParams: Report) {
  //     if (chartParams == null) {
  //       throw new Error(chartParams.Title);
  //     }

  //     if (chartParams.Chart['ChartDataPointItems'].Any()) {
  //       var chartDataPoints = chartParams.Chart['ChartDataPointItems'];

  //       var fieldX = (<ChartField[]>chartParams.Chart['ChartFields']).find(field => field.Type == ChartEnums.ChartFieldType.X);
  //       var fieldY = (<ChartField[]>chartParams.Chart['ChartFields']).find(field => field.Type == ChartEnums.ChartFieldType.Y);

  //       chartDataPoints.forEach((chartDatePoint) => {
  //         let resultX = null;
  //         let resultY = null;
  //         let resultZ = null;

  //         if (chartDatePoint.ExpressionForX) {
  //           // resultX = this._appDataExpressionManager.Evaluate(chartParams.App.Identifier,
  //           //   chartDatePoint.ExpressionForX,
  //           //   new Dictionary < string, object >
  //           //   {
  //           //                       { "_ids", chartParams.Data.Select(x => x.Id).ToList() },
  //           //   { "AppIdentifier", chartParams.App.Identifier }
  //           //                   });
  //         }

  //         if (chartDatePoint.ExpressionForY) {
  //           // resultY = this._appDataExpressionManager.Evaluate(chartParams.App.Identifier,
  //           //   chartDatePoint.ExpressionForY,
  //           //   new Dictionary < string, object >
  //           //   {
  //           //                           { "_ids", chartParams.Data.Select(x => x.Id).ToList() },
  //           //   { "AppIdentifier", chartParams.App.Identifier }
  //           //                       });
  //         }

  //         if (chartDatePoint.ExpressionForZ) {
  //           // resultZ = this._appDataExpressionManager.Evaluate(chartParams.App.Identifier,
  //           //   chartDatePoint.ExpressionForY,
  //           //   new Dictionary < string, object >
  //           //   {
  //           //                               { "_ids", chartParams.Data.Select(x => x.Id).ToList() },
  //           //   { "AppIdentifier", chartParams.App.Identifier }
  //           //                           });
  //         }

  //         if (resultX != null && resultY != null) {
  //           series.push(

  //             {
  //               data: new Array<any>
  //                 ({
  //                   new
  //                                         {
  //                     x: resultX,
  //                     y: resultY,
  //                     z: resultZ,
  //                     hoverText: new Array<any>
  //                       ({
  //                                                 {
  //                           name : chartDatePoint.HoverText,
  //                           value : `{fieldX.Label ?? "X"}: {resultX}, {fieldY.Label ?? "Y"}: {resultY}`
  //                                                 }
  //                       }),
  //                     label: chartDatePoint.Label,
  //                     marker : { fillColor: chartDatePoint.Colour, radius: 10, symbol: chartDatePoint.Symbol ? chartDatePoint.Symbol.toLower(CultureInfo.InvariantCulture) : null }
  //                                         }
  //         })
  //     });
  //   }
  // });
  //             }
  //         }



  public async getScatterChartDataOptions(app: Application, appDataIndex: IndexedAppData, report: Report, record?: Record) {
    const seriesData = new Array<SeriesData>();
    const hoverText = report.Chart.ChartFields.filter(field => field.Type === ChartEnums.ChartFieldType.HoverText);
    hoverText.forEach(hoverField => {
      hoverField.BaseField = app.getField(hoverField.BaseFieldIdentifier);
    });

    const xField = (report.Chart.ChartFields).find(field => field.Type === ChartEnums.ChartFieldType.X);
    if (xField) { xField.BaseField = app.getField(xField.BaseFieldIdentifier); }
    const xIsDate = isDateField(xField.BaseField.Type);

    const yField = (report.Chart.ChartFields).find(field => field.Type === ChartEnums.ChartFieldType.Y);
    if (yField) { yField.BaseField = app.getField(yField.BaseFieldIdentifier); }
    const yIsDate = isDateField(yField.BaseField.Type);

    const labelField = (report.Chart.ChartFields).find(field => field.Type === ChartEnums.ChartFieldType.Label);
    if (labelField) { labelField.BaseField = app.getField(labelField.BaseFieldIdentifier); }

    const recordCallbackFunction = (doc: any): void => {
      let xValue = xField ? (<AppField>xField.BaseField).getRawRecordValue(doc) : '';
      let yValue = yField ? (<AppField>yField.BaseField).getRawRecordValue(doc) : '';
      let xFieldParsed: number;
      let yFieldParsed: number;
      if (xValue != null && yValue != null) {
        if (xIsDate) { xValue = xValue.$date; }
        if (yIsDate) { yValue = yValue.$date; }
        xFieldParsed = Number.parseFloat(xValue.toString());
        yFieldParsed = Number.parseFloat(yValue.toString());
      } else {
        return;

      }
      const hideSeries = typeof (report.Chart.HideSeries) !== 'undefined' && report.Chart.HideSeries === true;

      if (!isNaN(xFieldParsed) && !isNaN(yFieldParsed) && !hideSeries) {
        const data: any = {
          id: doc._id,
          x: xFieldParsed,
          y: yFieldParsed,
          hoverText: hoverText.map((o: any) => ({
            name: !!o.Label ? o.Label : o.BaseField.Label,
            value: this.formatHoverText(doc, <AppField>o.BaseField)
          })),
          marker: { radius: 5 }
        };

        if (labelField && !!labelField.BaseFieldIdentifier) {
          data.label = (<AppField>labelField.BaseField).getRawRecordValue(doc)
            ? (<AppField>labelField.BaseField).getRawRecordValue(doc).toString()
            : 'undefined';
        }

        seriesData.push(data);
      }
    };

    if (record) {
      const records = await this.getRecordData(app, !!record, appDataIndex, report, record._id, record);
      await records.forEach(recordCallbackFunction);
    } else {
      await appDataIndex.eachRecord(recordCallbackFunction);
    }

    const series = new Array<any>({ data: seriesData });

    // TODO: When offline expressions are working
    // this.setDataPoints(series, report);

    return { Series: series };
  }

}
