import { ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Input, Output } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { ContainerType, FieldBase } from 'app/softoolsui.module/fields';
import { DownloadIconFieldComponent } from 'app/softoolsui.module/fields2/download-icon-field/download-icon-field.component';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { IconDisplayFieldComponent } from 'app/softoolsui.module/fields2/icon-display-field/icon-display-field.component';
import { TextDisplayFieldComponent } from 'app/softoolsui.module/fields2/text-display-field/text-display-field.component';

@Component({
  selector: 'app-table-report-field',
  templateUrl: '../../fields2/field/field.component.html',
  styleUrls: ['./table-report-field.component.scss', '../../fields2/field/field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableReportFieldComponent extends FieldComponent {

  /** True if field is currently selected */
  @Input() public active = false;

  @Output() public componentClicked = new EventEmitter<FieldBase>();

  public override async activate() {

    if (!this.active) {
      this.active = true;

      if (this.displayInactiveAsText(this.fieldModel.Type)) {
        this.addComponentForField();
      }

      await super.activate();
    }
  }

  public override async deactivate() {
    if (this.active) {
      this.active = false;
      await super.deactivate();

      if (this.displayInactiveAsText(this.fieldModel.Type)) {
        this.addComponentForField();
      }
    }
  }

  protected override addComponentForField(): ComponentRef<any> {
    switch (this.fieldModel.Type) {
      case Enums.FieldType.GridField:
      case Enums.FieldType.ListField:
      case Enums.FieldType.AttachmentList:
      case Enums.FieldType.Draw:
      case Enums.FieldType.EmbeddedVideo:
      case Enums.FieldType.Gantt:
      case Enums.FieldType.InAppChart: {
        // Complex field, always shown as icon
        return this.addReadonlyChildComponent(IconDisplayFieldComponent);
      }

      case Enums.FieldType.UrlField:
        // Icon mode for URL field
        if (this.fieldModel.DisplayOptions?.ShowAsIcon) {
          return this.addReadonlyChildComponent(DownloadIconFieldComponent);
        }
        break;
    }

    // Fields that display as text unless in edit mode
    if (!this.active && this.displayInactiveAsText(this.fieldModel.Type)) {
      this.addReadonlyChildComponent(TextDisplayFieldComponent);
    } else {
      // Use default (for now)
      const component = super.addComponentForField();
      component.instance.containerType = ContainerType.TableReport;
      // Components which are table report set to disable for edit via AS
      if (this.reportModel.isDisabledByReport(this.fieldModel.Identifier)) {
        component.instance.isEditable = false;
      }

      this.subscribe(component.instance.componentClicked$, (comp) => {
        this.componentClicked.emit(comp);
      });

      return component;
    }

    return null;
  }

  private displayInactiveAsText(type: Enums.FieldType) {
    switch (type) {
      case Enums.FieldType.Bit:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Image:
      case Enums.FieldType.ImageActionButton:
      case Enums.FieldType.Range:
      case Enums.FieldType.Document:
        return false;
      default:
        return true;
    }
  }

}
