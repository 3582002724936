import { ChangeDetectionStrategy, Component, ComponentRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { TextDisplayFieldComponent } from 'app/softoolsui.module/fields2/text-display-field/text-display-field.component';

@Component({
  selector: 'app-list-report-detail-field',
  templateUrl: '../../../fields2/field/field.component.html',
  styleUrls: ['./list-report-detail-field.component.scss', '../../../fields2/field/field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListReportDetailFieldComponent extends FieldComponent implements OnChanges {

  @Input() editable = false;

  /** Set to true if field is not yet visible to the user. */
  @Input() public isFieldHidden = false;

  public override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes['editable'] && !changes['editable'].firstChange) {
      this.addComponentForField();
    }
  }


  protected override addComponentForField(): ComponentRef<any> {
    // Fields that display as text unless in edit mode
    if (this.displayInactiveAsText(this.fieldModel.Type)) {
      if (!this.editable) {
        this.addReadonlyChildComponent(TextDisplayFieldComponent);
      } else {
        return super.addComponentForField();
      }
    } else {
      switch (this.fieldModel.Type) {
        case Enums.FieldType.GridField:
          // If not editable then text display will show not supported.
          if (!this.editable) {
            return this.addReadonlyChildComponent(TextDisplayFieldComponent);
          } else {
            return super.addComponentForField();
          }
        default:
      // Use default (for now)
          return super.addComponentForField();
      }
    }

    return null;
  }

  // Force isEditable false if inline edit is off
  protected override _initEditableField(fieldComponent: EditableFieldBase): void {
    this._initField(fieldComponent);
    fieldComponent.editMode = this.editable;
    fieldComponent.isEditable = this.editable ? this.fieldModel.IsEditable && this.record?.EditableAccessForUser : false;
    fieldComponent.isFieldHidden = this.isFieldHidden;
  }

  private displayInactiveAsText(type: Enums.FieldType) {
    switch (type) {
      case Enums.FieldType.Bit:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Image:
      case Enums.FieldType.InAppChart:
      case Enums.FieldType.ImageActionButton:
      case Enums.FieldType.Range:
      case Enums.FieldType.AttachmentList:
      case Enums.FieldType.GridField:
      case Enums.FieldType.Gantt:
        return false;
      default:
        return true;
    }
  }
}
