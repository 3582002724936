import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
  OnInit,
  HostListener
} from '@angular/core';
import { FormBase } from 'app/softoolsui.module/form.component/form-base.component';
import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { AutoLayoutDirective } from 'app/softoolsui.module/listreport.component/directives/auto-layout.directive';
import { Enums, ReportViewService, logError, logMessage, stringCompare } from '@softools/softools-core';
import { ReportField } from '@softools/softools-core';
import { Record, RecordId } from '@softools/softools-core';
import { ParentField, HeaderSummaryExpression } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';

import * as moment from 'moment';
import { ContainerType } from '../fields/field-base';
import { AppField } from 'app/types/fields/app-field';
import { UsersService } from 'app/services/users.service';
import { NavigationService } from 'app/services/navigation.service';
import { AppModel, AttachmentsModel, FilterModel } from 'app/mvc';
import { ListReportModel } from 'app/mvc';
import { debounceTime } from 'rxjs/operators';
import { CommentsModel } from 'app/mvc/comments-model';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';
import { SelectionMode } from '../table-report/table-report-types';

export function isSupportedFieldType(type: Enums.FieldType) {
  return type !== Enums.FieldType.Gantt &&
    type !== Enums.FieldType.ListField &&
    type !== Enums.FieldType.Lookup &&
    type !== Enums.FieldType.EmbeddedVideo;
}

export interface HeaderReportFieldModel extends ReportField {
  /** Set on init */
  _Editable: boolean;
  _Width: string;
  _BackingField: AppField;
}

@Component({
  selector: 'app-listreport',
  templateUrl: './listreport.component.html',
  styleUrls: ['./listreport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListReportComponent extends FormBase implements OnInit, OnChanges, AfterViewChecked {

  @Input() public override appModel: AppModel;

  @Input() public commentsModel: CommentsModel;

  @Input() public override attachmentsModel: AttachmentsModel;

  @Input() public reportModel: ListReportModel;

  public override fieldTypes = Enums.FieldType;
  public selectListTypes = Enums.SelectListType;
  public containerTypes = ContainerType;
  public previousRow = null;
  public clientWidth: string;

  @Output() onToggleInAppChartForReport = new EventEmitter();
  @Output() onToggleAutoLayoutClick = new EventEmitter();
  @Output() onReportEmbeddedVideoOpen = new EventEmitter();

  @Input() detailsFields;
  @Input() editMode;
  @Input() showDetailsField;
  @Input() allowMultiSelect = true;
  @Input() enableAutoLayout;
  @Input() showColumn;
  @Input() hideColumn;

  @Input() filterModel: FilterModel;

  @Input() reportIdentifier: string;
  @Input() autoLayoutOffByDefault = false;

  @Input() headerSummaryExpressions: Array<HeaderSummaryExpression>;

  @Input() showFilterManagement: IShowFilterManagement;

  /**
   * The scroller parent element used for inline edit overlay popups
   */
  @Input() scroller: HTMLElement;

  @Input() public parentRecordId: RecordId;

  @Input() public selectionMode = SelectionMode.None;

  @ViewChild('layout') layoutDirective: AutoLayoutDirective;

  public cellEditorOrigin: CdkOverlayOrigin;

  public showCellEditor = false;

  public cellEditField: AppField;

  public cellEditValue: any;

  public cellEditPatch: RecordPatch;

  public cellEditRecord: Record;

  private rowsChanged = false;

  public groupFieldIdentifier = '';

  public headerFields = [] as Array<ReportField>;

  private _headers: Array<HeaderReportFieldModel>;

  private _groupField: AppField;

  public isViewable: boolean;

  public listReportContainerType = ContainerType.List;

  public cellEditorPosition = [
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' }
    )
  ];

  // view model
  public viewModelLoaded = false;
  public groupsCollapsed = new Set<string>();
  public expandDetailsFields = new Set<string>();
  public expandAllGroups = false;

  /** CSS to apply per record for row border */
  private borderCss: Map<RecordId, any>;

  constructor(
    private navigationService: NavigationService,
    private usersService: UsersService,
    private reportViewService: ReportViewService) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(this.reportModel.viewChanged$.pipe(
      debounceTime(100)), (view) => {
      if (view && view.top !== undefined && view.count !== undefined) {
        this.loadData().catch(error => logError(error, 'Failed to load data'));
        this.refresh();
      }
    });

    this.subscribe(this.filterModel.groupBy.$, (groupFieldIdentifier) => {
      const app = this.appModel.app.value;
      this.groupFieldIdentifier = groupFieldIdentifier;
      this._groupField = groupFieldIdentifier ? app.getField(groupFieldIdentifier) : null;
    })

    this.subscribe(this.reportModel.records.$, (records) => {

      this.createRecordStyles(records);

      // SOF-7174 check that the rows we have been given belong to the app we're displaying
      const id = this.appModel.appIdentifiers.value.visibleAppIdentifier;
      records?.forEach(record => {
        if (record) {
          if (id !== record.AppIdentifier) {
            logMessage(`Record ${record._id} invalid app id ${record.AppIdentifier} displaying ${id}`);
          }
        }
      });

      // Render when selection model changes (too complex to observe each record)
      this.subscribe(this.reportModel.selectionModel.$, () => {
        this.refresh();
      });

      if (this.layoutDirective) {
        // Row data has changed and will have all columns displayed
        // so we need to reapply auto layout
        this.rowsChanged = true;
        this.refresh();
      }
    });

    this.subscribe(this.reportModel.report.$, (rep) => {
      if (rep) {
        this.getHeaderFields();
      }
    });

    this.setClientWidth();

    this.setView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['editMode']) {
        if (this.layoutDirective) {
          // Row data has changed and will have all columns displayed
          // so we need to reapply auto layout when DOM updated
          this.rowsChanged = true;
        }
      }
    } catch (error) {
      logError(error, '');
    }
  }

  /** Build style map */
  private createRecordStyles(records: Array<Record>) {
    if (records) {
      const report = this.reportModel.report.value;
      const map = new Map<string, any>();
      records.forEach((record) => {
        if (record) {
          const styles = this.appModel.getNamedStyles({
            target: 'card',
            names: report.NamedStyles,
            record,
            borderSideOverride: 'right'
          });

          const borderStyle = styles['border']?.css ?? {};
          map.set(record._id, borderStyle);
        }
      });

      this.borderCss = map;
    }
  }

  public getBorderStyle(record: Record) {
    return this.borderCss?.get(record?._id);
  }

  @HostListener('window:resize')
  resize() {
    this.setClientWidth();
  }

  private setClientWidth() {
    this.clientWidth = `${this.scroller.clientWidth}px`;
  }

  public ngAfterViewChecked(): void {
    try {
      if (this.rowsChanged) {
        this.rowsChanged = false;
        this.layoutDirective?.refreshLayout();
      }
    } catch (error) {
      logError(error, '');
    }
  }

  public recordCellClicked(event: MouseEvent, record: Record, row: number, column: number) {

    if (this.selectionMode === SelectionMode.Row || this.selectionMode === SelectionMode.MulitpleRows) {
      event.stopPropagation();
      this.reportModel.selectionModel.select(record._id);
      return;
    }

    if (this.editMode) {

      // Inline edit active
      // Create patch ready to be filled in when edit is complete
      this.cellEditPatch = new RecordPatch(record._id, this.appModel.app.value?.Identifier, record.Hierarchy);

      const headerField = this.headerFields[column];
      if (headerField) {
        const id = headerField.FieldIdentifier;
        this.cellEditField = this.fields.find(x => x.Identifier === id);
        this.cellEditValue = record[id];

        // Position and open editor
        this.cellEditRecord = record;
        // this.cellEditorOrigin = cdkOrigin;
        this.showCellEditor = true;
      }

    } else {
      // Normal record level edit active
      const app = this.appModel.app.value;
      if (app.capabilities.canEdit || this.isViewable) {
        if (app.isOfflineReport(this.reportIdentifier)) {
          this.onRecordClick.emit({ event: event, record: record, row: row });
        } else {
          // const report = this.appService.getReport(app.Identifier, this.reportIdentifier);
          // // navigate by cursor
          // const cursor = this.reportModel.topIndex.value + row + 1;
          // const url = this.navigationService.getRecordCursorUrl(this.appModel.appIdentifiers.value, this.parentRecordId, report, cursor);

          // Using the cursor does not work due to the data changing
          // For example, users are sorted by last access this changes often
          // Opening record by cusor id will open incorrect records
          const url = this.navigationService.getRecordUrl2(this.appModel.appIdentifiers.value, record._id);
          this.appModel.globalModel.navigation.navigateUrlAsync({ url })
            .catch(error => logError(error, 'navigate'));
        }
      }
    }
  }

  public cellEditorClosed(): void {
    this.showCellEditor = false;
  }

  private async loadData() {
    const first = this.reportModel.topIndex.value;
    const count = this.reportModel.pageSize.value;
    await this.reportModel.getRecords(first, count);
  }

  private isCellEditable(fieldIdentifier: string): boolean {

    const app = this.appModel.app.value;
    if (!app.capabilities.canEdit) {
      return false;
    }

    if (this.editMode && fieldIdentifier) {
      // Cell edit mode so look up type and return true if we know how to edit
      const field = this.fields.find(x => x.Identifier === fieldIdentifier);
      return field.IsEditable && this.isEditable(field.Type);
    } else {
      // Record edit mode so always editable
      return true;
    }
  }

  public isEditable = (type: Enums.FieldType) => {
    switch (type) {
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Text:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Bit:
      case Enums.FieldType.Text:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.Email:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Number:
      case Enums.FieldType.Money:
      case Enums.FieldType.MultiState:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Period:
      case Enums.FieldType.Range:
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
      case Enums.FieldType.Selection:
        return true;
      case Enums.FieldType.Notes:   // todo: update needs special handling.  Remove pencil icon and the dialog it opens
      default:
        return false;
    }
  }

  public cellEditUpdated = (value: any) => {

    // todo check fieldIsValid, we should have it when the field edit completes
    const id = this.cellEditField.Identifier;
    this.cellEditPatch.fieldIsValid = true;

    if (value !== null && value.patch) {
      // Value knows how to patch itself so ask it to do so
      value.patch(this.cellEditField, this.cellEditPatch);
    } else {
      // Value doesn't know how to patch itself so assume it's
      // a simple value and update the patch accordingly
      this.cellEditPatch.addChange(id, value);
    }


    this.onFieldUpdate.emit(this.cellEditPatch);

    // close the editor
    this.showCellEditor = false;
  }

  public useDisplayFieldDirective(field: AppField) {
    switch (field?.Type) {
      case Enums.FieldType.Image:
      case Enums.FieldType.ImageActionButton:
      case Enums.FieldType.InAppChart:
        return false;
    }
    return true;
  }

  public onToggleInAppChartForReportHandler(payload) {
    // this.reportModel.expandedCharts.toggle(payload);
    this.onToggleInAppChartForReport.emit(payload);
  }

  // must capture this
  public trackRow = (index: number, item: any) => {
    if (item) {
      // Unique tracking key per record, per report and for each copy of the record loaded
      const key = `${this.reportModel?.report.value?.Identifier}|${item._id}|${item._instanceId}`;
      return key;
    } else {
      return null;
    }
  }

  public showGroup(record: Record) {
    if (record) {
      return this.reportModel.groupStartIds.has(record._id);
    }

    return null;
  }

  public anyGroups() {
    return this.reportModel.groupStartIds.size > 0;
  }

  public anyGroupsExpanded() {
    return this.groupsCollapsed.size > 0;
  }

  public headers(): Array<HeaderReportFieldModel> {
    return this._headers;
  }

  /** Get the display text for the current group */
  public groupDisplayValue(record: Record) {
    return this._groupField?.getDisplayRecordValue(record) ?? '';
  }

  /** Get a unique key to represent the current group
   * This currently uses the display value, but we could more effieintly
   * provide a unique scalar value via AppField.
   */
  public groupKey(record: Record) {
    return this._groupField?.getDisplayRecordValue(record) ?? '';
  }

  /** Get the underlying group value, used during summary calculation */
  public groupValue(record: Record) {
    return this._groupField?.getRecordValue(record) ?? '';
  }

  public expandedChartReport(record: Record): string {
    const expanded = this.reportModel.expandedFields.get(record._id);
    return expanded && expanded.inAppChartReportIdentifier;
  }

  public expandedChartField(record: Record): AppField {
    const expanded = this.reportModel.expandedFields.get(record._id);
    return expanded && this.appModel.app.value.getField(expanded.fieldIdentifier);
  }

  public expandedChartContainer(record: Record): string {
    const expanded = this.reportModel.expandedFields.get(record._id);
    return expanded && `chart_${record?._id}_${expanded.fieldIdentifier}`;
  }

  private inlineEditColumnWidth(reportField: ReportField) {
    const field = this.fields.find(f => f.Identifier === reportField.FieldIdentifier);
    if (field) {
      switch (field.Type) {

        case Enums.FieldType.Text:
        case Enums.FieldType.Number:
        case Enums.FieldType.Integer:
        case Enums.FieldType.Long:
        case Enums.FieldType.Money:
        case Enums.FieldType.Range:
        case Enums.FieldType.MultiState:
          // todo should use field length, with an overriding min and max.
          // but we've only got ParentFields...
          return '12rem';
        case Enums.FieldType.Date:
          // case Enums.FieldType.Time:
          return '10rem';
        case Enums.FieldType.Person:
        case Enums.FieldType.Period:
        case Enums.FieldType.Selection:
          return '16rem';
        case Enums.FieldType.PersonByTeam:
          return '18rem';
        case Enums.FieldType.Email:
        case Enums.FieldType.UrlField:
        case Enums.FieldType.LongText:
        case Enums.FieldType.Notes:
          return '20rem';
        case Enums.FieldType.DateTime:
          return '34rem';
      }
    }

    return '';
  }

  public tempShowByType(reportField: ReportField) {
    const field = this.fields.find(f => f.Identifier === reportField.FieldIdentifier);
    if (field) {
      switch (field.Type) {
        case Enums.FieldType.Literal:
        case Enums.FieldType.Text:
        case Enums.FieldType.LongText:
        case Enums.FieldType.Number:
        case Enums.FieldType.Integer:
        case Enums.FieldType.Long:
        case Enums.FieldType.Money:
        case Enums.FieldType.Range:
        case Enums.FieldType.Date:
        case Enums.FieldType.DateTime:
        case Enums.FieldType.Person:
        case Enums.FieldType.PersonByTeam:
        case Enums.FieldType.MultiState:
        case Enums.FieldType.Image:
        case Enums.FieldType.Selection:
        case Enums.FieldType.Email:
        case Enums.FieldType.UrlField:
        case Enums.FieldType.Bit:
        case Enums.FieldType.Notes:
        case Enums.FieldType.GridField:
        case Enums.FieldType.ListField:
        case Enums.FieldType.AttachmentList:
        case Enums.FieldType.Draw:
        case Enums.FieldType.EmbeddedVideo:
        case Enums.FieldType.InAppChart:
          return false;
        default:
          return true;
      }
    }

    return false;
  }

  public isRowSelected(id: RecordId) {
    const selectable = this.selectionMode === SelectionMode.Row || this.selectionMode === SelectionMode.MulitpleRows;
    return selectable && this.reportModel.selectionModel.isSelected(id);
  }

  public hasGroupValue(record: Record): boolean {
    if (this._groupField) {
      const value = this._groupField.getRecordValue(record);
      return value !== null && value !== undefined;
    }

    return false;
  }

  public async toggleDetailsFields() {
    if (this.expandDetailsFields.size > 0) {
      this.expandDetailsFields.clear();
    } else {
      const records = this.reportModel.records.value;
      records.forEach((record) => {
        this.expandDetailsFields.add(record._id);
      });
    }

    await this.saveReportViewAsync();
  }

  public async toggleDetailsField(recordId: string) {

    if (!this.expandDetailsFields.has(recordId)) {
      this.expandDetailsFields.add(recordId);
    } else {
      this.expandDetailsFields.delete(recordId);
    }

    await this.saveReportViewAsync();
  }

  public async toggleGroup(record: Record) {
    const recordValue = this.groupKey(record);
    if (!this.groupsCollapsed.has(recordValue)) {
      this.groupsCollapsed.add(recordValue);
    } else {
      this.groupsCollapsed.delete(recordValue);
    }

    await this.saveReportViewAsync();
  }

  public async toggleGroups() {

    if (this.groupsCollapsed.size > 0) {
      this.groupsCollapsed.clear();
    } else {
      const records = this.reportModel.records.value;
      const groupRecords = records.filter(r => this.reportModel.groupStartIds.has(r._id));
      for (let index = 0; index < groupRecords.length; index++) {
        const record = groupRecords[index];
        const recordValue = this.groupKey(record);
        this.groupsCollapsed.add(recordValue);
      }
    }

    await this.saveReportViewAsync();
  }

  /**
   * Save the view data to indexedDb
   */
  private async saveReportViewAsync() {
    const app = this.appModel.app.value;
    await this.reportViewService.getViewDataAsync(app.Identifier, this.reportIdentifier)
      .then((view) => {
        view.groupValuesCollapsed = [...this.groupsCollapsed.keys()];
        view.expandDetailsFields = [...this.expandDetailsFields.keys()];
        return this.reportViewService.setViewDataAsync(app.Identifier, view);
      })
      .catch((err) => {
        logError(err, '');
      });
  }

  /**
   * Set the view data from the indexedDb data
   */
  private setView() {

    // discards the promise and everything happens asynchronously
    // change detection is trigger when the view model is set.
    const app = this.appModel.app.value;
    this.reportViewService.getViewDataAsync(app.Identifier, this.reportIdentifier)
      .then((view) => {
        if (view) {
          if (view.groupValuesCollapsed) {
            view.groupValuesCollapsed.map(r => this.groupsCollapsed.add(r));
          }

          if (view.expandDetailsFields) {
            view.expandDetailsFields.map(f => this.expandDetailsFields.add(f));
          }
        }

        this.viewModelLoaded = true;
        this.refresh();
      })
      .catch((err) => {
        logError(err, '');
      });
  }

  /** Get header fields from the report */
  private getHeaderFields() {

    const app = this.appModel.app.value;

    // Build list of report fields, excluding unsupported ones
    const listReportFields: Array<ReportField> = this.reportModel.report.value?.ListReportFields || [];

    const reportFields = listReportFields.filter((f) => !f.Hidden)
      // sort by display order, then id so order consistent if same
      .sort((a, b) => a.DisplayOrder - b.DisplayOrder || stringCompare(a.FieldIdentifier, b.FieldIdentifier));

    this.headerFields = reportFields || [];

    this.isViewable = app.capabilities ? app.capabilities.canView : true;

    this._headers = this.headerFields.filter(field => !field.Hidden).map(field => {

      const headeReportField = { ...field } as HeaderReportFieldModel;

      headeReportField._Editable = this.isCellEditable(field.FieldIdentifier);
      headeReportField._Width = this.inlineEditColumnWidth(field);
      headeReportField._BackingField = app.getField(field.FieldIdentifier);
      return headeReportField;
    });
  }
}
