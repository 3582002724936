<div class="position-fixed w-100 h-100 text-center bg-white p-4" style="padding-top: 10% !important; top: 0;">
  <ng-container *ngIf="!crossCookieError; else cookeiError">
    <h1 class="display-4" i18n>Logging in</h1>
    <p i18n>This could take a few seconds!</p>
    <p>
      <img src="assets/icons/softoolsF.gif" style="width: 2.6rem;"  />
    </p>
  </ng-container>
</div>

<div class="fixed-bottom text-muted ps-2">
  <small>Softools v{{ appVersionNumber }}</small>
</div>

<ng-template #cookeiError>
  <p i18n>You are now registed please click here login</p>
  <p i18n>Please ensure you have third party cookies enabled</p>
  <button mat-raised-button color="primary" class="btn btn-default" role="button" (click)="closeAndRedirect()" i18n>Login</button>
</ng-template>
