import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ModelBase } from './model-base';

export const TickApp = new BehaviorSubject(true);

/**
 * An MVC model.
 *
 * The model typically is a collection of observable values that interested
 * parties can observe.
 *
 * The model can contain simple value properties, or observable
 * items, either @see {ModelProperty} to contain a value or nested  @see {Model} instances.
 * The main difference with these is the granularity of observables; a sub model or
 * model property can be individually observer while value properties can only be
 * change detected by observing the containing model.
 * In all cases changes are propagated up to the container model so if you care about
 * several properties it may be better to observe the whole model.
 *
 * Simple properties do not automatically notify of changes so the @see {changed} method
 * must be called afterwards.  A derived model may provide more meaningful interfaces
 * to update properties and enforce change notification; or a seperate controller class
 * can handle interactions.
 *
 * To extend the model, pass the derived type as a type parameter e.g.
 *  class MyModel extends Model<MyModel> {...}
 */
export class Model<T> extends ModelBase {

  private subject: BehaviorSubject<Model<T>>;

  public constructor(private container?: Model<any>) {
    super();
  }

  public get $(): Observable<Model<T>> {
    if (!this.subject) {
      this.subject = new BehaviorSubject<Model<T>>(this);
    }
    return this.subject;
  }

  public override notify() {

    super.notify();

    if (this.subject) {
      this.subject.next(this);
    }

    this.container?.changed();
    TickApp.next(true);
  }
}

/**
 * A model event.  This can be observed to react to a change
 * in the model that is not assocaited with a value.
 */
export class ModelEvent<T = void> {

  private subject: Subject<T>;

  public get $(): Observable<T> {
    if (!this.subject) {
      this.subject = new Subject<T>();
    }
    return this.subject;
  }

  public fire(value?: T) {
    if (this.subject) {
      this.subject.next(value);
    }
  }
}
