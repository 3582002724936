import BTree from 'sorted-btree';
import {
  Record,
  Enums,
  QueryParams,
  IndexedAppData,
  AppDataStorageService,
  RecordId,
  App,
  OdataQueryFilter,
  logError,
  logMessage,
} from '@softools/softools-core';
import { Application, GetRecordOptions } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { InjectService } from '../locator.service';
import { ApplicationValueLookup } from './application-value-lookup';
import { RecordCacheService } from '../record/record-cache.service';

type Comparer = (t1: any, t2: any) => number;

class IndexValues {
  /** Bag of indexed values */
  public values = {};

  constructor(
    /** Record id for indexed record */
    public _id: RecordId
  ) {}
}

export interface IGroupStarts {
  groupFieldId: string;
  pos: number;
  count: number;
  id: RecordId;
  value: any;
  text?: string;

  records?: Array<Record>;
}

export interface IGroupInfo {
  starts: Array<IGroupStarts>;
  count: number;
}

export interface IGroupStartOptions {
  ungroupedReturnAll?: boolean;
}

/**
 * An index over application data held in storage exposed by the app data service.
 * A  @see queryParams object defines the ordering of the data in the index as well
 * as a filter applied to return a subset of the data.
 */
export class DataIndex implements IndexedAppData {

  @InjectService(RecordCacheService)
  private readonly recordCache: RecordCacheService;

  private useFiniteGroupValues = false;

  private tree: BTree<any, string>;

  private filter: OdataQueryFilter;

  private comparer: Comparer;

  /** List of fields to sort by with optional "desc" suffix for reverse order */
  private indexFields: Array<string> = [];

  private firstRecord = 0;

  private recordCount = 0;

  private _changedSinceIndexBuilt: Array<string> = [];

  private matchAllSquareBrackets = /[\[\]]/g;

  private filterFields: Array<string>;

/** The field id used for grouping (if defined).  This is always the first field to sort by.
 * May include asc/desc suffix
 */
  private groupBy: string;

  private groupStarts: Array<IGroupStarts>;

  constructor(
    public app: Application,
    public queryParams: QueryParams,
    public appDataService: AppDataStorageService,
    additionalFilter?: string,
    private hierarchy?: string
  ) {
    // Build sort order from query params
    if (this.queryParams.$groupby) {
      this.groupBy = this.queryParams.$groupby.replace(this.matchAllSquareBrackets, '');
      // eslint-disable-next-line
      const [fieldId] = this.groupBy.split(' ', 2); // extract field id ignoring possible desc suffix
      // check field is on app in case of outdated filter
      if (app.getField(fieldId)) {
        this.indexFields.push(this.groupBy);
      }
    }

    if (this.queryParams.$orderby) {
      // There can be more than one sort field so split by commas
      const sortBy = this.queryParams.$orderby.replace(this.matchAllSquareBrackets, '').split(',');
      sortBy.forEach((field) => {
        // eslint-disable-next-line
        const [fieldId] = field.split(' ', 2); // extract field id ignoring possible desc suffix
        // check field is on app in case of outdated filter
        if (app.getField(fieldId)) {
          // Add field unless we've already seen it
          if (!this.indexFields.find((f) => f === field)) {
            this.indexFields.push(field);
          }
        }
      });
    }

    // Create compare function
    this.comparer = this._multiFieldIndex(this.app, this.indexFields);

    const lookup = new ApplicationValueLookup(app);

    // Build filter, leave undefined if none specified as cue to not filter results
    if (this.queryParams.$filter && additionalFilter) {
      const filters = [additionalFilter, this.queryParams.$filter].join(' and ');
      this.filter = new OdataQueryFilter(filters, lookup);
    } else if (this.queryParams.$filter) {
      this.filter = new OdataQueryFilter(this.queryParams.$filter, lookup);
    } else if (additionalFilter) {
      this.filter = new OdataQueryFilter(additionalFilter, lookup);
    }

    if (this.filter) {
      this.filterFields = Array.from(this.filter.comparisons.keys());
    } else {
      this.filterFields = [];
    }

    this.tree = new BTree<any, string>(undefined, this.comparer);
  }
  public get start() {
    return this.firstRecord;
  }

  public get length() {
    return this.recordCount;
  }

  public useFiniteGroups(useFiniteGroups = false) {
    this.useFiniteGroupValues = useFiniteGroups;
  }

  private indexPromise: Promise<void>;

  /**
   * Add all data that matches the configured  filter in the store
   * to the index.
   */
  public async indexAll(): Promise<void> {
    if (!this.indexPromise) {
      this.indexPromise = new Promise<void>((resolve, reject) => {
        this.indexAllRecords().then(() => resolve()).catch(e => reject(e));
      });
    }

    // Clear group starts on every call as this may have changed - could optimise
    delete this.groupStarts;

    return this.indexPromise;
  }

  private async indexAllRecords(): Promise<void> {
    const t0 = performance.now();

    this.tree.clear();
    delete this.groupStarts;

    const options: GetRecordOptions = {
      hierarchy: this.hierarchy,
      applyPatch: true,
    };

    await this.app.eachRecord((record) => {
      if (record.AppIdentifier === this.app.Identifier) {
        if (this._indexRecord(record)) {
          this.recordCache.put(record);
        }
      } else {
        logError(new Error(`Record ${record._id} of ${record.AppIdentifier} masquerading as ${this.app.Identifier}`), '');
      }
    }, options);

    this.firstRecord = 0;
    this.recordCount = this.tree.length;

    const t1 = performance.now();
    console.log(`indexing ${this.app.Identifier} took ${Math.trunc(t1 - t0)} milliseconds. filter=${this.queryParams.$filter} length=${this.length}  `);
  }

  /** Get records in the specified range, obtaining from service if required */
  public async getRange(start: number, count: number): Promise<Array<Record>> {
    if (this.recordCount === 0) {
      // first request, load whatever was asked for
      const queryParams = {
        ...this.queryParams,
        $skip: start,
        $top: count,
      };

      const records = await this.appDataService.getDataNoIndex(this.app.Identifier, 'All', queryParams);
      this._indexRecords(records);
      this.firstRecord = start;
    } else {
      // subsequent request extend the collection
      // todo currenly we just extend as needed; if we run into data size problems we might need to
      // discard part of the view
      const firstUnloaded = this.firstRecord + this.recordCount;

      if (start < this.firstRecord) {
        // asking for records before the first we've loaded, so request those
        const queryParams = {
          ...this.queryParams,
          $skip: start,
          $top: this.firstRecord - start,
        };

        const records = await this.appDataService.getDataNoIndex(this.app.Identifier, 'All', queryParams);
        this._indexRecords(records);
        this.firstRecord = start;
      }

      if (firstUnloaded < start + count) {
        // asking for records after the first we've loaded, so request those
        const queryParams = {
          ...this.queryParams,
          $skip: firstUnloaded,
          $top: start + count - firstUnloaded,
        };

        const records = await this.appDataService.getDataNoIndex(this.app.Identifier, 'All', queryParams);
        this._indexRecords(records);
      }
    }

    this.recordCount = this.tree.length;

    // Get requested records from storage to ensure we get all of them
    return this.getRecords(start, count);
  }

  public async getRecords(start: number, count: number): Promise<Array<Record>> {
    const records: Array<Promise<Record>> = [];
    this.tree.forEachPair((_k, id, counter) => {
      const i = counter + this.firstRecord;
      if (i >= start) {
        if (i < start + count) {
          const cached = this.recordCache.load(this.app, id);
          if (cached) {
            records.push(Promise.resolve(cached));
          } else {
            // fall back to checking storage - shouldn't be needed
            const record = this.appDataService.getRecordByIdAsync(id);
            records.push(record);
          }
        } else {
          return { break: 0 };
        }
      }

      return undefined;
    });

    return Promise.all(records);
  }

  public async getRecordsWithGetterFunc(
    start: number,
    count: number,
    recordGetterFunc: (app: App, id: string) => Promise<Record>
  ): Promise<Array<Record>> {
    const records: Array<Promise<Record>> = [];
    this.tree.forEachPair((_k, id, counter) => {
      const i = counter + this.firstRecord;
      if (i >= start) {
        if (i < start + count) {
          const record = recordGetterFunc(this.app, id);
          records.push(record);
        } else {
          return { break: 0 };
        }
      }

      return undefined;
    });

    return Promise.all(records);
  }

  public async getRecordIds(start = 0, count?: number): Promise<Array<string>> {
    if (count === undefined) {
      count = this.length - start;
    }

    const ids: Array<string> = [];
    this.tree.forEachPair((_k, id, counter) => {
      const i = counter + this.firstRecord;
      if (i >= start) {
        if (i < start + count) {
          ids.push(id);
        } else {
          return { break: 0 };
        }
      }

      return undefined;
    });
    return ids;
  }

  public getCachedRecords(start: number, count: number): Array<{ id: RecordId, record?: Record }> {
    const records: Array<{ id: RecordId, record?: Record }> = [];
    this.tree.forEachPair((_k, id, counter) => {
      const i = counter + this.firstRecord;
      if (i >= start) {
        if (i < start + count) {
          const record = this.recordCache.get(id);
          records.push({ id, record });   // push record or undefined
        } else {
          return { break: 0 };
        }
      }

      return undefined;
    });

    return records;
  }

  public async eachRecord(callback: (record: Record, id: string, index: number) => any): Promise<void> {
    // Only enumerate if tree has records or we'll never resolve
    if (this.tree.length > 0) {
      let count = 0;
      await new Promise<void>((resolve) => {
        this.tree.forEachPair((_k, id, counter) => {

          // this uses less mem and CPU
          const record = this.recordCache.get(id);
          if (record) {
            callback(record, id, counter);
          } else {
            logMessage(`Record ${id} not in index cache`);
          }

          if (++count === this.tree.length) {
            resolve();
          }

          // this.appDataService.getRecordByIdAsync(id).then((record) => {
          //       callback(record, id, counter);
          //       if (++count === this.tree.length) resolve();
          // });
        });
      });
    }
  }

  public eachRecordSync(callback: (record: Record, id: string, index: number) => any): void {
    this.tree.forEachPair((_k, id, counter) => {
      const record = this.recordCache.get(id);
      if (record) {
        callback(record, id, counter);
      } else {
        logMessage(`Record ${id} not in index cache`);
      }
    });
  }

  /**
   * Replace the record in the index, changing its order if the key fields have changed
   * @param recordIds
   */
  public async replace(...recordIds: Array<string>): Promise<void> {

    // Remove replaced records
    const copy = this.tree.filter((k: IndexValues) => {
      return !recordIds.includes(k?._id);
    });

    const currentRecords: Array<Record> = [];

    for (let i = 0; i < recordIds.length; ++i) {
      const recordId = recordIds[i];
      const current = await this.app.getRecordByIdAsync(recordId, { applyPatch: true });
      if (current) {
        currentRecords.push(current);
        this.recordCache.put(current);
      } else {
        this.recordCache.delete(recordId);
      }
    }

    this.tree = copy;

    // Rebuild group starts next time - could optimise
    delete this.groupStarts;

    this._indexRecords(currentRecords);
  }

  /**
   * Mark a record as modified so we know we may need to reorder.
   * The index is not updated until @see reindexChangedRecords is called to avoid
   * reindexing an index that is not in use.
   *
   * @param id  modified record id
   */
  public recordChanged(id: string): void {
    if (!this._changedSinceIndexBuilt.find((i) => i === id)) {
      this._changedSinceIndexBuilt.push(id);
    }
  }

  /** If any records have changed, reindex them */
  public async reindexChangedRecords(): Promise<DataIndex> {
    if (this.isChanged()) {

      // Rebuild group starts next time - could optimise
      delete this.groupStarts;

      // Capture changed array in sync code as it's just possible
      // another change could occur during async handling
      const changed = this._changedSinceIndexBuilt;
      this._changedSinceIndexBuilt = [];

      await this.replace(...changed);
    }

    return this;
  }

  public isChanged(): boolean {
    return this._changedSinceIndexBuilt.length > 0;
  }

  /**
   * Get the numeric position of the specified record in the index
   * @param recordId
   * nb this is not very performant (O log n probably) so avoid
   * calling frequently if possible.
   */
  //  Consider optimising (maybe maintain  a reverse map?)
  // unused, remove?
  public async positionOf(recordId: string): Promise<number> {
    let pos = undefined;
    this.tree.forEachPair((_key, id, counter) => {
      if (id === recordId) {
        pos = counter;
        return { break: counter };
      }

      return undefined;
    });
    return pos;
  }

  /** Get the ids before and after the specified id in sort order */
  // unused, remove?
  public adjacentIds(recordId: string): { prev: string; next: string } {
    const ids = this.tree.valuesArray();
    const pos = ids.findIndex((id) => id === recordId);
    if (pos < 0) {
      return null; // id not found
    }

    return {
      prev: pos > 0 ? ids[pos - 1] : null,
      next: pos + 1 < ids.length ? ids[pos + 1] : null,
    };
  }

  /** Get group information for the index */
  public async groupStartInfoAsync(options?: IGroupStartOptions): Promise<Array<IGroupStarts>> {

    if (this.groupStarts !== undefined) {
      return this.groupStarts;
    }

    if (this.groupBy) {
      const starts: Array<IGroupStarts> = [];
      const [groupFieldId, direction] = this.groupBy.split(' ', 2);
      const groupField: AppField = this.app.getField(groupFieldId.replace('[', '').replace(']', ''));
      if (groupField) {
        let lastGroupValue: any;

        // Enumerate records to find group starts.  This relies on the index sorting on group first
        // (which it always does).
        let start: IGroupStarts;
        this.eachRecordSync((record, id, i) => {
          const internalValue = groupField.getInternalRecordValue(record);
          if (i === 0 || groupField.compareInternalValues(lastGroupValue, internalValue, true) !== 0) {
            const groupValue = groupField.getRecordValue(record);
            const value = (groupValue === undefined || groupValue === null) ? null : groupField.getRawRecordValue(record);
            const text = groupField.getDisplayRecordValue(record);
            start = { groupFieldId, pos: i, count: 0, id, value, text, records: [] };
            starts.push(start);
            lastGroupValue = internalValue;
          }
          start.count++;    // bump count on last added start info
          start.records.push(record);
        });
      }

      // Always get null groups - report will strip out if not needed
      const options = { reverse: direction === 'desc', includeNull: true }
      const finiteValues = this.useFiniteGroupValues && groupField.finiteValues(options);
      if (finiteValues?.length > 0) {
        const finiteStarts = new Array<IGroupStarts>();
        finiteValues.forEach(value => {
          const start = starts.find(s => s.value === value);
          if (start) {
            finiteStarts.push(start);
          } else {
            finiteStarts.push({
              groupFieldId: groupFieldId,
              pos: 0,
              count: 0,
              id: null,
              value: value
            });
          }
        });

        this.groupStarts = finiteStarts;
      } else {
        this.groupStarts = starts;
      }
    } else {

      // Ungrouped - return all if requested, nothing otherwise
      if (options?.ungroupedReturnAll) {
        const records = await this.getRecords(0, this.length);
        this.groupStarts = [{
          groupFieldId: '',
          pos: 0,
          count: 0,
          id: undefined,
          value: undefined,
          records
        }];
      } else {
        this.groupStarts = [];
      }
    }

    return this.groupStarts;
  }

  public async getGroupStartsCount(): Promise<number> {
    const starts = await this.groupStartInfoAsync();
    return starts?.length ?? 0;
  }

  public onRecordCreated(record: Record) {
  }

  private _indexRecords(records: Array<Record>) {
    records.forEach((record) => {
      this._indexRecord(record);
    });

    this.recordCount = this.tree.length;
  }

  /**
   * Index a single record
   * @param record
   * @returns true if the record is in the filtered data set
   */
  private _indexRecord(record: Record): boolean {
    if (!this.hierarchy || record.Hierarchy === this.hierarchy) {
      // If a filter is specified, capture values is uses
      // (don't use record directly so fields can manage their own values, grids looked up, etc.)
      // We could cache this on the records in state
      const matchValues = {};
      if (this.filter) {
        for (const key of this.filterFields) {
          const field = this.app.getField(key);
          if (field) {
            matchValues[key] = field.getRawRecordValue(record);
          } else {
            matchValues[key] = record[key];
          }
        }

        if (!this.filter.isMatch(matchValues)) {
          return false;
        }
      }

      const indexvalues = new IndexValues(record._id);
      this.indexFields.forEach((f) => {
        const name = f.split(' ')[0];
        const field = this.app.getField(name);
        if (field) {
          indexvalues.values[name] = field.getInternalRecordValue(record);
        }
      });

      this.tree.set(indexvalues, record._id);
      return true;
    } else {
      return false;
    }
  }

  /** Create a @see Comparer to compare multiple field values within a record */
  private _multiFieldIndex(app: Application, indexFields: Array<string>): Comparer {
    const comparers: Array<Comparer> = [];

    indexFields.forEach((indexField) => {
      // Seperate out asc/desc flag
      const nameParts = indexField.split(' ');
      const isDescending = nameParts.length === 2 && nameParts[1] === 'desc';
      const name = nameParts[0];

      const field = app.getField(name);
      if (field) {
        // Create a comparison function based on the field type
        // Some fields do not have great oderering (e.g. person fields will sort by user id not name)
        // To get better results we should use backing field
        switch (field.Type) {
          case Enums.FieldType.Text:
          case Enums.FieldType.LongText:
          case Enums.FieldType.Email:
          case Enums.FieldType.UrlField:
          case Enums.FieldType.Notes:
          case Enums.FieldType.Person:
          case Enums.FieldType.PersonByTeam:
          case Enums.FieldType.Team:
          case Enums.FieldType.Selection: // selected item stored as text
          case Enums.FieldType.ImageList: // selected item stored as text
          case Enums.FieldType.Image: // Image id string.  Not very useful order but will sort/group like images together
          case Enums.FieldType.MultiState: // will sort on state text
          case Enums.FieldType.Barcode: // stored as code string so will sort although a bit meaningless
          case Enums.FieldType.Number:
          case Enums.FieldType.Money:
          case Enums.FieldType.Range:
          case Enums.FieldType.Integer:
          case Enums.FieldType.Bit:
          case Enums.FieldType.Long:
          case Enums.FieldType.Date:
          case Enums.FieldType.DateTime:
          case Enums.FieldType.Time:
          case Enums.FieldType.Period:
          case Enums.FieldType.AttachmentsCount:
          case Enums.FieldType.CommentsCount:
            const comparer = (indexValues1: IndexValues, indexValues2: IndexValues) => {
              try {
                const val1 = indexValues1.values[name];
                const val2 = indexValues2.values[name];
                const diff = field.compareInternalValues(val1, val2, isDescending);
                return diff;
              } catch (error) {
                console.warn(error, indexValues1, indexValues2);
                return 0;
              }
            };

            comparers.push(comparer);
            break;

          default:
            // remainder should be non sortable; we don't add a comparer so it will
            // fall back to sorting by id
            // console.log('ignoring sort on field', field.Identifier);
            break;
        }
      } else {
        console.warn(`Ignoring field ${name} specififed in filter but missing field.`);
      }
    });

    const comp: Comparer = (indexValues1: IndexValues, indexValues2: IndexValues) => {
      for (let i = 0; i < comparers.length; ++i) {
        const diff = comparers[i](indexValues1, indexValues2);
        if (diff !== 0) {
          return diff;
        }
      }

      // Identical, fall back to id order
      if (!indexValues1 && !indexValues2) {
        return 0;
      } else if (!indexValues1) {
        return -1;
      } else if (!indexValues2) {
        return 1;
      } else {
        return indexValues1._id?.localeCompare(indexValues2?._id);
      }
    };

    return comp;
  }
}
