import { Injectable } from '@angular/core';
import { Report, ChartField, IndexedAppData, Record } from '@softools/softools-core';
import { BaseChartDataService } from './base-chart-data.service';
import { ChartEnums } from '../chart.enums';
import { isNumberField } from 'app/_constants';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class MapChartDataService extends BaseChartDataService {

  private series: Array<any>;
  private seriesFields: Array<ChartField>;
  private geographyChartField: ChartField;
  private appDataIndex: IndexedAppData;
  private reportChartConfig: Report;
  private recordId: string;

  public async getMapChartDataOptions(app: Application, appDataIndex: IndexedAppData, reportChartConfig: Report, record?: Record) {
    this.geographyChartField = (reportChartConfig.Chart.ChartFields.find(field => field.Type === ChartEnums.ChartFieldType.Geography));
    if (!this.geographyChartField) {
      throw new Error(`Map config error for ${reportChartConfig.Chart.Identifier}, missing 'Geography' field`);
    }

    const hierarchy = reportChartConfig['Hierarchy'] ? reportChartConfig['Hierarchy'] : '';

    this.series = new Array<any>();
    this.seriesFields = (reportChartConfig.Chart.ChartFields.filter(field => field.Type === ChartEnums.ChartFieldType.Series));
    this.appDataIndex = appDataIndex;
    this.reportChartConfig = reportChartConfig;
    this.recordId = record?._id;

    if (this.seriesFields.length > 0) {
      await this.SetSeriesData();
    } else {
      await this.SetHeatmapData(app);
    }

    return {
      Series: this.series,
      Hierarchy: hierarchy
    };
  }
  private async SetHeatmapData(app: Application): Promise<void> {
    const geographyFieldIdentifier = this.geographyChartField.BaseFieldIdentifier;

    const valueChartField = this.getChartFieldEx(app, this.reportChartConfig, ChartEnums.ChartFieldType.Value);
    if (valueChartField == null) {
      throw new Error(`Map config error for ${this.reportChartConfig.Chart.Identifier}, missing 'Value' field`);
    }

    const valueChartBaseField = valueChartField.BaseAppField;

    if (!isNumberField(valueChartBaseField.Type)) {
      throw new Error(`Map config error for ${this.reportChartConfig.Chart.Identifier}, 'Value' field should be numeric`);
    }

    const geographyChartBaseField = app.getField(geographyFieldIdentifier);

    const mappedSeriesData: { [key: string]: number } = {};

    // todo this should be doing record too
    if (this.appDataIndex) {
      await this.appDataIndex.eachRecord(record => {
        const hcKey = geographyChartBaseField.getRawRecordValue(record);
        const value = parseFloat(valueChartBaseField.getRawRecordValue(record));
        if (!hcKey) {
          return;
        }

        if (!mappedSeriesData[hcKey.toString()]) {
          mappedSeriesData[hcKey.toString()] = 0;
        }

        if (value && !isNaN(value)) {
          mappedSeriesData[hcKey.toString()] += value;
        }
      });
    }

    const seriesData = Object.keys(mappedSeriesData).map(key => ({ 'hc-key': key, value: mappedSeriesData[key] }));

    const geographyFieldLabel = geographyChartBaseField.Label;

    this.series.push({
      name: `${geographyFieldLabel}`/*${this.GetMapName(reportChartConfig.Chart.Map)}`*/,
      joinBy: new Array<string>('hc-key'),
      data: seriesData
    });
  }

  private async SetSeriesData(): Promise<void> {
    const mapChartData = await this.getMapChartData();
    const seriesColourOverrides = this.reportChartConfig.Chart.SeriesColourOverrides;
    const hasSeriesColourOverrides = seriesColourOverrides && seriesColourOverrides.length > 0;

    let colourDictionary: { [value: string]: string } = {};

    if (hasSeriesColourOverrides) {
      // SeriesColourOverrides is JSON serialized Dictionary<string, string>, representing <value, colour>, stored as string in DB.
      // e.g { "100": "#fa3737", "200": "#f5c242", "300": "#6dfc7b" }
      colourDictionary = (<any>JSON.parse(seriesColourOverrides));
    }

    Object.keys(mapChartData).sort().forEach((key, index) => {
      const chartDataKeyValuePair = {
        Key: key,
        Value: mapChartData[key]
      };

      const seriesToAdd = {
        name: chartDataKeyValuePair.Key,
        data: chartDataKeyValuePair.Value,
        allAreas: true,
        color: null
      };

      if (index > 0) {
        // For multiple series tooltips to work.  First series allAreas is true as we set globally for maps, all areas of the map get rendered even when areas don't correspond to data points.
        seriesToAdd.allAreas = false;
      }

      if (hasSeriesColourOverrides && typeof (colourDictionary) === 'object' && colourDictionary.hasOwnProperty(seriesToAdd.name) && colourDictionary[seriesToAdd.name].length > 0) {
        const colour = colourDictionary[seriesToAdd.name];
        seriesToAdd.color = colour;
      }

      this.series.push(seriesToAdd);
    });
  }

  // private async getFieldDataValues(appData: DataIndex, identifier: string, type: number): Promise<any[]> {
  //   const output = new Array<any>()
  //   await appData.eachRecord(row => {
  //     output.push(row[identifier]);
  //   });
  //   switch (type) {
  //     case Enums.FieldType.Number:
  //     case Enums.FieldType.Money:
  //     case Enums.FieldType.Integer:
  //     case Enums.FieldType.Long:
  //       return (<Array<number>>output).sort();
  //     default:
  //       return output.sort((a, b) => a.toString() - b.toString());
  //   }
  // }

  private async getMapChartData() {
    const app = this.appService.application(this.appDataIndex.app.Identifier);
    const mappedData: { [key: string]: Array<string> } = {};
    const recordCallbackFunction = (record) => {
      this.seriesFields.forEach((series) => {
        const baseField = app.getField(series.BaseFieldIdentifier);
        const seriesGroupValue = baseField ? baseField.getRawRecordValue(record) : undefined;
        const geographyField = app.getField(this.geographyChartField.BaseFieldIdentifier);
        const geographyValue = geographyField ? geographyField.getRawRecordValue(record) : undefined;

        if (seriesGroupValue && geographyValue) {
          const seriesGroupValueAsString = seriesGroupValue.toString();
          const geographyValueAsString = geographyValue.toString();

          if (seriesGroupValueAsString && geographyValueAsString) {
            if (!mappedData.hasOwnProperty(seriesGroupValueAsString)) {
              mappedData[seriesGroupValueAsString] = new Array<string>();
            }

            if (!mappedData[seriesGroupValueAsString].includes(geographyValueAsString)) {
              mappedData[seriesGroupValueAsString].push(geographyValueAsString);
            }
          }
        }
      });
    };

    if (this.recordId) {
      const records = await this.getRecordData(app, !!this.recordId, this.appDataIndex, this.reportChartConfig, this.recordId);
      await records.forEach(recordCallbackFunction);
    } else {
      await this.appDataIndex.eachRecord(recordCallbackFunction);
    }

    return mappedData;
  }

}
