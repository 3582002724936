<div class="checkbox-wrapper" *ngIf="checkboxType === Enums.CheckboxType.Default">
  <input
    id="{{ checkBoxId }}"
    type="checkbox"
    [value]="value"
    (click)="checkedClick($event);"
    [checked]="checked"
    [disabled]="disabled"
  />
  <label for="{{ checkBoxId }}">{{ text }}</label>
</div>

<div class="material-switch-wrapper" *ngIf="checkboxType === Enums.CheckboxType.Switch">
  <label title="{{ text }}">
    <input
      type="checkbox"
      [value]="value"
      [checked]="checked"
      [disabled]="disabled"
      (click)="checkedClick($event);"
    />
    <span class="material-switch-lever"></span>
  </label>
</div>
