import { Injectable } from '@angular/core';
import { AppDataStorageService, NamedStylesRepository, Record, Style } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { RecordPatch } from 'app/workspace.module/types';
import { EmbeddedApplication } from '../../embedded-application';
import { namedStyleAppConfig } from './named-styles.config';

@Injectable({ providedIn: 'root' })
export class NamedStylesApplication extends EmbeddedApplication<Style> {

  @InjectService(AppDataStorageService)
  private appDataStorageService: AppDataStorageService;

  @InjectService(NamedStylesRepository)
  private repository: NamedStylesRepository;

  constructor() {
    super(namedStyleAppConfig);
  }

  public toRecord(value: Style): Record {

    const record = value && {
      _id: `style:${value.Name}`,
      AppIdentifier: this.Identifier,
      // Application: value.AppIdentifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',
      IsArchived: false,
      ...value
    };

    return record;
  }

  public toStyle(record: Record): Style {
    const values = record as any;
    return {
      Name: values.Name,
      // AppIdentifier: values.Application,
      // IsGlobal: values.IsGlobal,
      Element: values.Element,
      Alignment: values.Alignment,
      Colour: values.Colour,
      BackgroundColour: values.BackgroundColour,
      DropShadow: values.DropShadow,
      FontSize: values.FontSize,
      FontWeight: values.FontWeight,
      Padding: values.Padding,
      Appearance: values.Appearance,
      StyleKeyValues: values.StyleKeyValues
    };
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  public async getAllStyles(): Promise<Array<Style>> {
    const styles: Array<Style> = [];
    await this.appDataStorageService.eachRecord(this.Identifier, (record) => {
      styles.push(this.toStyle(record));
    });
    return styles;
  }

  /** Synchronize app data from the server to local storage */
  public override async synchronizeAppData(): Promise<void> {
    const styles = await this.repository.getAll();

    // Convert to records and store as appdata
    const promises = styles
      .map(style => this.toRecord(style))
      .map(record => this.appDataStorageService.storeRecordAsync(this, record, false));
    await Promise.all(promises);
  }
}
