import ObjectID from 'bson-objectid';
import App from './selectlists-app.config.json';

import { Record, SelectList, RecordId, SelectListsRepository, Enums, IndexedAppData } from '@softools/softools-core';
import { OfflineEmbeddedApp } from '../embedded-application';

import { SelectListsService } from 'app/services/select-lists.service';
import { RecordPatch } from 'app/workspace.module/types';
import { FieldBase } from 'app/softoolsui.module/fields';
import { ListFieldComponent } from 'app/softoolsui.module/fields2/list-field/list-field.component';
import { ToolbarContext, GetRecordOptions, DeleteRecordOptions } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { Injectable } from '@angular/core';
import { PermissionsService } from 'app/services/permissions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { RecordModel, ReportModel } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable({ providedIn: 'root' })
export class SelectListsApplication extends OfflineEmbeddedApp<SelectList> {

  constructor(
    private repository: SelectListsRepository,
    private selectListsService: SelectListsService,
    private permissionsService: PermissionsService,
    protected recordPersistService: RecordPersistService,
    private models: GlobalModelService
  ) {
    super(App.App);

    // Set list options
    // Todo moveup to App then include in json
    this.getField('SelectListOptions').ListFieldParameters = { RowKey: 'Id' };
  }

  /** Called for each (currently some) field in the application to allow behaviour modification */
  public override initialiseFieldComponent(component: FieldBase) {
    if (component.fieldModel.Identifier === 'SelectListOptions' && component instanceof ListFieldComponent) {
      // Set correct row id so patching works
      component.rowKeyId = 'Id';
    }
  }

  public toRecord(list: SelectList): Record {
    return list && {
      ...list,
      _id: list.Id,
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: list.Name,
      IsArchived: false,
    } as Record;
  }

  public convert(record: Record): SelectList {
    const values = record as any;
    const list: SelectList = {
      Id: record._id,
      Tenant: values.Tenant,
      Identifier: values.Identifier,
      Name: values.Name,
      SelectListOptions: values.SelectListOptions,
      Global: values.Global
    };
    return list;
  }

  public override async totalCount(): Promise<number> {
    return this.selectListsService.getAll().length;
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async eachRecord(callback: (record: Record) => any): Promise<void> {
    const lists = this.selectListsService.getAll();
    lists.forEach(list => {
      callback(this.toRecord(list));
    });
  }

  public override async getRecordByIdAsync(id: RecordId, options?: GetRecordOptions): Promise<Record> {

    // Look up any pending patch if we're going to need it
    const pendingPatch = options?.applyPatch && this.recordPersistService.getPatch(id);

    // If patch is new, it's not been persisted so convert to record and return
    if (pendingPatch?._new) {
      return pendingPatch.toRecord(this);
    }

    const list = await this.repository.getSelectListAsync(id);
    return list && this.toRecord(list);
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {

    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const lists = await this.repository.getSelectListsAsync(selection.count, selection.start, queryParams);
    return lists.map(list => this.toRecord(list));
  }

  public override async getIndexedRecordRange(index: IndexedAppData, start: number, count: number): Promise<Array<Record>> {
    const records = await index.getRecordsWithGetterFunc(start, count, async (_app, id) => {
      return this.toRecord(await this.selectListsService.getById(id));
    });
    return records;
  }


  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const lists = await this.repository.getSelectListsAsync(1, +cursor - 1, queryParams);
    return lists.map(list => this.toRecord(list)).pop();
  }

  public override validateRecordId(recordId: RecordId): RecordId | boolean {
    if (!recordId || !ObjectID.isValid(recordId)) {
      const newObjectId = new ObjectID();
      recordId = newObjectId.toHexString();
    }
    return recordId;
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {

    if (recordPatch._new) {
      // Post format is really ugly
      // Fields are passed as normal properties except the options list which is a tracked
      // change list.  Create a record in that shape.
      const list = {
        ...recordPatch.changes,
        Id: recordPatch._id,
        SelectListOptions: recordPatch.trackedChanges.get('SelectListOptions')
      } as any;

      const result = await this.repository.createAsync(list);
      return this.toRecord(result);
    } else {
      // Apply patch
      const changes = recordPatch.getDelta();
      await this.repository.save(recordPatch._id, changes).toPromise();

      // Unfortunately the patch API doesn't return the updated user and fixing
      // that would mean unravelling dependenceies so for now query current state
      const updatedList = await this.repository.getSelectListAsync(recordPatch._id);
      return this.toRecord(updatedList);
    }
  }

  public override async storeAsync(record: Record): Promise<Record> {
    const list = this.convert(record);
    const stored = await this.selectListsService.storeAsync(list);
    return this.toRecord(stored);
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await reportModel.refresh();
    }));
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    actions.push(new ToolbarAction('Delete', 'trash', async () => {
      const options: DeleteRecordOptions = {
        selection: {
          AllRecordsSelected: false,
          SelectedRecordIds: [context.record._id]
        }
      };

      const deleted = await this.deleteRecordsAsync(options);

      if (deleted) {
        recordModel.appModel.reindexRecord(context.record._id);
        this.models.globalModel.header?.goBack();
      }
    }));

    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await recordModel.reload();
    }));
  }

  /**
   * Delete a range of records by id.
   * @param ids
   * @returns a promise indicating whether records were deleted (true if so)
   */
  public override async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {

    const ids: Array<RecordId> = deleteOptions.selection.SelectedRecordIds;

    // todo check unused?

    const responses = await this.repository.deleteSelectListsAsync(ids);

    // We can have a mix of successes and failures, split responses
    const errors = responses.filter(resp => resp instanceof HttpErrorResponse) as Array<HttpErrorResponse>;
    const deleted = responses.filter(resp => !(resp instanceof HttpErrorResponse)) as Array<string>;

    if (errors.length > 0) {
      // todo message with all errors....
      const firstError = errors[0];
      if (firstError && firstError.error && firstError.error.ValidationErrors) {
        const message = firstError.error.ValidationErrors.map(e => e.Message).join('\n');
        await this.models.globalModel.showModalAsync(Enums.ModalType.info,
          $localize`Error deleting select list`,
          message);
      }
    }

    // The ids in the response have been deleted so update storage to match...
    if (deleted.length > 0) {
      // Storage key is identifier not id
      const identifiers = deleted.map(id => this.selectListsService.getById(id)?.Identifier).filter(select => select);
      await this.selectListsService.removeAsync(...identifiers);

      deleted.forEach(deletedId => {
        this.recordDeleted$.next({ app: this, recordIdentifier: deletedId });
      });
    }

    await this.selectListsService.refresh();
    return deleted.length > 0;
  }

  public override async refresh(): Promise<boolean> {
    await this.selectListsService.refresh('force');
    return true;
  }

  public override get permissions() {
    return this.permissionsService.listPermissions;
  }

}
