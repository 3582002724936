
/**
 * Placeholder to mark invalid values in a record
 * Any invalid values in the record should generate a validation error
 * and prevent the record being sent to the server.
 */
export class InvalidValue {
  isInvalidValue = true;
}

export const invalidValue = new InvalidValue();
