import { Injectable } from '@angular/core';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { UPLOAD_IMAGES_STORE } from '../indexedDb/database-stores';
import * as moment from 'moment';

export interface ImageData extends FieldKey {
  name: string;
  type: string;
  imageId?: string;
  /** Image data to create, no value to delete image */
  image?: Array<number>;
}

/**
 * Key to identifiy a specific field value
 */
export interface FieldKey {
  appIdentifier: string;
  fieldIdentifier: string;
  recordId: string;
  rowKey?: string;
}

@Injectable({ providedIn: 'root' })
export class UploadImagesStorageService {
  constructor(private dbContext: DatabaseContextService<ImageData>) { }

  public async getImageDataAsync(imageKey: string): Promise<ImageData> {
    return await this.dbContext.get(UPLOAD_IMAGES_STORE, imageKey);
  }

  /**
   *
   * @param imageKey
   */
  public async getImageAsync(imageKey: FieldKey, imageId?: string): Promise<File | null> {
    const key = imageId || [imageKey.appIdentifier, imageKey.fieldIdentifier, imageKey.recordId];
    const imageData = await this.dbContext.get(UPLOAD_IMAGES_STORE, key);

    if (imageData) {
      const restored = new Uint8Array(imageData.image);
      const buffer = restored.buffer;
      const file = new File([buffer], imageData.name, { type: imageData.type });
      return file;
    }

    return null;
  }

  /**
   *
   * @param imageData
   * @param imageFile
   */
  public async storeImageAsync(imageData: ImageData, imageFile: File): Promise<boolean> {

    if (!imageData.imageId) {
      throw new Error('imageId is required');
    }

    const data = await this.readImageFile(imageFile);
    const typed = new Uint8Array(data);

    imageData.image = Array.from(typed);

    const imageDataWihBytes: ImageData = {
      ...imageData,
      image: Array.from(typed)
    };

    return this.dbContext.save(UPLOAD_IMAGES_STORE, imageData.imageId, imageDataWihBytes);
  }

  /**
   *
   * @param imageKey
   * @param imageFile
   */
  public async storeCameraAsync(imageKey: FieldKey, imageId: string, data: ArrayBuffer): Promise<boolean> {

    if (!imageId) {
      throw new Error('imageId is required');
    }

    const typed = new Uint8Array(data);

    const imageData: ImageData = {
      appIdentifier: imageKey.appIdentifier,
      fieldIdentifier: imageKey.fieldIdentifier,
      recordId: imageKey.recordId,
      name: `capture-${moment.utc().format('MMDDYYYYHHmmss')}.png`,
      type: 'image/png',
      imageId,
      image: Array.from(typed),
      rowKey: imageKey.rowKey
    };

    return this.dbContext.save(UPLOAD_IMAGES_STORE, imageId, imageData);
  }

  public async storeDeleteImageAsync(imageKey: FieldKey, imageId: string): Promise<boolean> {

    if (!imageId) {
      throw new Error('imageId is required');
    }

    const imageData: ImageData = {
      appIdentifier: imageKey.appIdentifier,
      fieldIdentifier: imageKey.fieldIdentifier,
      recordId: imageKey.recordId,
      rowKey: imageKey.rowKey,
      name: '',
      type: '',
      imageId,
      image: null,
    };

    return this.dbContext.save(UPLOAD_IMAGES_STORE, imageId, imageData);
  }

  public async keys(): Promise<Array<string>> {
    const keys = await this.dbContext.getAllKeys(UPLOAD_IMAGES_STORE);
    return keys as Array<string>;
  }

  public async remove(imageKey: string): Promise<boolean> {
    // const key = [imageKey.appIdentifier, imageKey.fieldIdentifier, imageKey.recordId];
    return this.dbContext.delete(UPLOAD_IMAGES_STORE, imageKey);
  }

  public async getCount(): Promise<number> {

    const q = await this.dbContext.getAll(UPLOAD_IMAGES_STORE);
    return q ? q.length : 0;
  }


  private readImageFile(file: File): Promise<ArrayBuffer> {
    const promise = new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = (ev) => {
        reject(ev);
      };

      reader.readAsArrayBuffer(file);
    });

    return promise;
  }
}
