import { IApplications } from './applications.interface';
import { Application, AppIdentifier } from 'app/types/application';
import { App, Report } from '@softools/softools-core';

// Acts as interface for service and as injection token
// This avoids circular references from importing the concrete type
// Logically should be abstract but LocatorService not compiling with that
export class AppService implements IApplications {
  public applications(): Array<Application> { return null; }

  public application(_identifier: string): Application<any> { return null; }

  public tryGetApplication(_identifier: AppIdentifier): Application { return null; }

  public getReport(_appIdentifier: string, _reportIdentifier: string): Report { return null; }

  public async forceRefresh(): Promise<Array<Application>> { return null; }

  public async refresh(): Promise<Array<Application>> { return null; }

  public async update(app: App) { return null; }

  public async applicationsAsync(): Promise<Array<Application>> { return null; }

  public async applicationMapAsync(): Promise<Map<AppIdentifier, Application>> { return null; }

  public isAppAvailableAnonymously(_appIdentifier: AppIdentifier) { return false; }
}
