<div class="d-inline-flex"   i18n-title="@@CommentsAttachments">
  <div>
      <fa-layers
        *ngIf="appModel.enableComments.$|async"
        i18n-title title="Comments" size="lg" class="me-1" (click)="commentClicked($event)">
          <fa-icon [icon]="['fal', 'comment']" class="pointer theme-dark-text"></fa-icon>
          <fa-layers-counter *ngIf="commentCount > 0" class="countText pointer" [classes]="['mt-2']" content="{{commentCount}}"></fa-layers-counter>
      </fa-layers>
      <fa-layers
        *ngIf="appModel.enableAttachments.$|async"
        i18n-title title="Attachments" size="lg" (click)="fileClicked($event)">
          <fa-icon [icon]="['fal', 'paperclip']" class="pointer theme-dark-text" (click)="fileClicked($event)"></fa-icon>
          <fa-layers-counter *ngIf="attachmentCount > 0" class="countText pointer" [classes]="['mt-2']" content="{{attachmentCount}}"></fa-layers-counter>
      </fa-layers>
  </div>
</div>
