import { HeaderSummaryExpression, logMessage, RecordId, Report, Record, logError, Template } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, ModelProperty, SetModelProperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { AppIdentifier, Application } from 'app/types/application';
import { combineLatest } from 'rxjs';
import { FilterModel } from '../filter.model';
import { RecordDataAccessor, AccessorFactory } from './accessors';
import { ReportModel } from './report.model';

/**
 * Base model class for reports that display a set of records.
 */
export class RecordsReportModel extends ReportModel<RecordDataAccessor> {

  private static recordInstanceId = 1;

  public readonly recordsCount = new ModelProperty<number>(this).withLogging('Records Count');

  /** True if current report includes summary expressions */
  public readonly hasSummaries = new BooleanModelProperty(this);

  public readonly summaryExpressions = new ArrayModelProperty<HeaderSummaryExpression>(this);

  /** Records that are the first in a group in the current view */
  public readonly groupStartIds = new SetModelProperty<RecordId>();

  public readonly headerTemplate = new ModelProperty<Template>(this);

  /** 
   * Should grouping use the set of possible values (where available) instead of the values
   * that are used by the app. This makes unused values visible - useful for drag and drop.
   */
  public readonly useFiniteGroups = new BooleanModelProperty(this, false);

  @InjectService(AccessorFactory)
  protected readonly accessorFactory: AccessorFactory;

  protected override observeProperties() {
    super.observeProperties();
    this.subscribe(this.groupStartIds.$, (ids) => this.calculateSummaryExpressions(ids).catch(error => logError(error, 'calculateSummaryExpressions')));
    this.subscribe(this.report.$, (report) => this.updateHasSummaries(report));

    this.subscribe(combineLatest([this.appModel.app.$, this.report.$]), ([app, report]) => {
      if (app && report) {
        const template = report.HeaderTemplate && app.Templates.find(t => t.Identifier === report.HeaderTemplate.TemplateIdentifier);
        this.headerTemplate.value = template;
      }
    });
  }

  protected override resetAccessor(app: Application, report: Report) {
    // Replace accessor if we change app or archive state
    if (this.accessor) {
      if (this.accessor.app.Identifier === app.Identifier
        && this.globalModel.archived.value === this.accessor.isArchive()
        && this.report.value?.Identifier === report.Identifier) {
        return;
      }

      this.accessor.close();
      this.accessor = null;
    }
  }

  protected override async setAccessor(app: Application, report: Report, filterModel: FilterModel) {

    if (!this.accessor) {
      this.accessor = this.createRecordAccessor(app, filterModel);
    }

    if (this.accessor) {

      this.subscribe(this.accessor.count$, (count) => {
        this.totalCount.value = count;
      });

      await this.loadIndex();

      this.reload();
    }
  }

  protected createRecordAccessor(app: Application, filterModel: FilterModel): RecordDataAccessor {
    return this.accessorFactory.createRecordAccessor(app, this, filterModel);
  }

  protected override async filterChanged() {

    if (this.topIndex.value > 0) {
      this.topIndex.value = 0;
    }

    if (this.accessor) {
      this.accessor.resetIndex();
      const top = this.topIndex.value;
      const count = this.pageSize.value;
      this.viewChanged$.next({ top, count });
    }
  }

  protected updateHasSummaries(report: Report) {
    this.hasSummaries.value = report?.ListReportFields?.findIndex(rf => rf.SummaryExpression) >= 0;
  }

  protected async calculateSummaryExpressions(ids: Set<RecordId>) {

    // Clear expression results before recalculating
    this.summaryExpressions.value = [];

    // Calculate new results in background - may be slow!
    if (this.accessor && this.hasSummaries.value) {
      const results = await this.accessor.summarise(ids);
      if (results) {
        this.summaryExpressions.value = results;
      }
    }
  }

  public override isDisabledByReport(fieldIdentifier: string): boolean {
    return this.report.value.ListReportFields?.find(field => field.FieldIdentifier === fieldIdentifier)?.DisableEdit;
  }

  public async getRecords(first: number, count: number): Promise<void> {
    // Capture accessor in case model changes
    const accessor = this.accessor;
    if (accessor) {
      try {
        this.busy.setLoading(true);
        const records = await accessor?.getRecords(first, count);
        if (!accessor.closed) {
          this.records.value = this.processRecords(first, records);
        }
      } finally {
        this.busy.setLoading(false);
      }
    }

    return null;
  }

  protected processRecords(first: number, records: Array<Record>) {
    if (records) {
      const problem = records?.findIndex((r) => !r);
      if (problem >= 0) {
        logMessage(`recordsChanged ${problem} of ${records.length} undefined`);
      }

      // Add unique id to this copy of the record so we can match rows when copies are different
      records.forEach(rec => {
        if (rec) {
          rec._instanceId = RecordsReportModel.recordInstanceId++;
        }
      });
    }

    return records || [];
  }

  protected override async recordsChanged(records: Record[]) {

    super.recordsChanged(records);

    const app = this.appModel.app.value;

    const changedApps = new Set<AppIdentifier>();
    records.forEach(record => changedApps.add(record.AppIdentifier));

    const affected = changedApps.has(app.Identifier) ||
      !!app.ChildAppsIdentifiers?.map(c => c.Identifier).find(id => changedApps.has(id));

    if (affected) {
      // Refresh counts
      await this.pageModel.folderModel.reloadChildRecordCounts();
    }

  }
}

