import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NotificationMessage, Enums } from '@softools/softools-core';
import { showDownload } from 'app/_constants';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageComponent {

  @Input() notificationMessage: NotificationMessage;
  @Input() isOnline = true;
  @Input() showDeleteIcon = true;

  @Output() onClearNotificationClick = new EventEmitter<string>();
  @Output() onDownloadClick = new EventEmitter<any>();
  @Output() slideout = new EventEmitter<boolean>();

  constructor() { }

  public canDelete() {
    const canBeDeleted = this.notificationMessage.Status === Enums.BackgroundTaskStatus.Completed
      || this.notificationMessage.Status === Enums.BackgroundTaskStatus.Failed
      || this.notificationMessage.Status === Enums.BackgroundTaskStatus.Cancelled
      || this.notificationMessage.DateStarted == null
      || this.notificationMessage.LastUpdated == null
      || (this.notificationMessage.LastUpdated != null && this._getMinutesGap(this.notificationMessage.LastUpdated) >= 30);
    return canBeDeleted;
  }

  private _getMinutesGap(date: string): number {
    if (!date || date.length === 0) {
      return 0;
    }

    const dateNow = new Date();
    const dateToCompare = new Date(date);
    const dateDiffMilliseconds = (<any>dateNow - <any>dateToCompare);
    const diff = Math.abs(dateDiffMilliseconds);
    const minutes = Math.floor((diff / 1000) / 60);
    return minutes;
  }

  public showDownload() {
    return showDownload(this.notificationMessage);
  }
}
