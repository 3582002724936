<div
  *ngIf="
    headerItem(fieldIdentifier)?._BackingField.IsNumberField && headerItem(fieldIdentifier)?.SummaryExpression?.length > 0
  "
>
  <small *ngIf="headerItem(fieldIdentifier).SummaryExpression === 'sum'">(sum) </small>
  <small *ngIf="headerItem(fieldIdentifier).SummaryExpression === 'avg'">(avg) </small>
  <small *ngIf="headerItem(fieldIdentifier).SummaryExpression === 'min'">(min) </small>
  <small *ngIf="headerItem(fieldIdentifier).SummaryExpression === 'max'">(max) </small>
  <small>{{
    getSummaryExpression(
      appIdentifier,
      headerItem(fieldIdentifier).FieldIdentifier,
      headerItem(fieldIdentifier).SummaryExpression
    )
  }}</small>
</div>
