import { Injectable } from '@angular/core';
import { IndexedAppData, Record, Report } from '@softools/softools-core';
import * as moment from 'moment';
import { OrderedPointDataValues } from '../chart-utilities';
import { ChartEnums } from '../chart.enums';
import { BaseChartDataService } from './base-chart-data.service';
import { AppField } from 'app/types/fields/app-field';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class GanttChartDataService extends BaseChartDataService {
  public async getGanttChartDataOptions(app: Application, appDataIndex: IndexedAppData, report: Report, hierarchy: string, record?: Record) {
    const seriesData = new Array<any>();
    const pointData = await this.buildPointData(app, appDataIndex, report, record);
    const ganttPointData = pointData as Array<any> || pointData;
    const values = this.getValuesFromOrderedPointData(ganttPointData, app, report);
    const categories = values.Categories;
    const earliestDate: moment.Moment = values.EarliestDate;

    seriesData.push({
      name: $localize`Actual Dates`,
      data: values?.Actuals?.length ? values.Actuals : new Array(),
      showInLegend: false
    });

    seriesData.push({
      name: $localize`Target Dates`,
      data: values?.Targets?.length ? values.Targets : new Array(),
      showInLegend: false
    });

    return { Series: seriesData, Categories: categories, GanttEarliestDate: earliestDate, Hierarchy: hierarchy };
  }

  private async buildPointData(app: Application, appDataIndex: IndexedAppData, report: Report, record?: Record) {

    const pointData = new Array<any>();
    const recordCallbackFunction = rec => {
      let projectName = '';
      let targetStart: moment.Moment;
      let targetEnd: moment.Moment;
      let actualStart: moment.Moment;
      let actualEnd: moment.Moment;
      let status = '';

      const projectNameField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.ProjectName);
      if (projectNameField) {
        projectName = projectNameField.BaseAppField.getRawRecordValue(rec);
        projectName = projectName && projectName.toString() || '';
      }


      const targetStartField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.TargetStart);
      if (targetStartField) {
        if (targetStartField.BaseAppField.getRawRecordValue(rec) != null) {
          targetStart = moment((<AppField>targetStartField.BaseField).getRawRecordValue(rec).$date);
        }
      }

      const targetEndField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.TargetEnd);
      if (targetEndField) {
        if ((<AppField>targetEndField.BaseAppField).getRawRecordValue(rec) != null) {
          targetEnd = moment((<AppField>targetEndField.BaseField).getRawRecordValue(rec).$date);
        }
      }

      const actualStartField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.ActualStart);
      if (actualStartField) {
        if (actualStartField.BaseAppField.getRawRecordValue(rec) != null) {
          actualStart = moment((<AppField>actualStartField.BaseField).getRawRecordValue(rec).$date);
        }
      }

      const actualEndField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.ActualEnd);
      if (actualEndField) {
        if (actualEndField.BaseAppField.getRawRecordValue(rec) != null) {
          actualEnd = moment((<AppField>actualEndField.BaseField).getRawRecordValue(rec).$date); // ChartEnums.DateTimeStyles.RoundtripKind
        }
      }

      const statusField = this.getChartFieldEx(app, report, ChartEnums.ChartFieldType.Status);
      if (statusField) {
        status = statusField.BaseAppField.getRawRecordValue(rec);
      }

      let id: string;
      if (!record?._id) {
        if (rec['Id']) {
          id = rec['Id'].toString();
        } else {
          throw new Error('In app chart has no record Id');
        }
      } else {
        id = 'null';
      }

      const ganttPoint: any = {
        Id: id,
        ProjectName: projectName,
        Status: status
      };

      ganttPoint.TargetStart = targetStart && targetStart.isValid() ? targetStart : undefined;
      ganttPoint.TargetEnd = targetEnd && targetEnd.isValid() ? targetEnd : undefined;
      ganttPoint.ActualStart = actualStart && actualStart.isValid() ? actualStart : undefined;
      ganttPoint.ActualEnd = actualEnd && actualEnd.isValid() ? actualEnd : undefined;

      const hoverTextChartFields = (report.Chart.ChartFields.filter(cf => cf.Type === ChartEnums.ChartFieldType.HoverText));
      hoverTextChartFields.forEach(hover => hover.BaseField = app.getField(hover.BaseFieldIdentifier));

      ganttPoint.HoverText =
        hoverTextChartFields.map(o => ({
          name: o.Label ? o.Label : o.BaseField.Label,
          value: this.formatHoverText(rec, <AppField>o.BaseField)
        }));

      if (ganttPoint.ProjectName !== ''
        && ((ganttPoint.TargetStart != null && ganttPoint.TargetEnd != null)
          || (ganttPoint.ActualStart != null && ganttPoint.ActualEnd != null))) {
        pointData.push(ganttPoint);
      }
    };

    if (record) {
      const records = this.getInAppRecordData(app, report, record);
      await records.forEach(recordCallbackFunction);
    } else {
      await appDataIndex.eachRecord(recordCallbackFunction);
    }

    return pointData;
  }

  private getValuesFromOrderedPointData(pointData: any[], app: Application, report: Report) {
    const values = new OrderedPointDataValues();

    if (report.Chart['OrderByDate']) {
      pointData = pointData.sort((a, b) => a.TargetStart === b.TargetStart ? a.ActualStart - b.ActualStart : a.TargetStart - b.TargetStart);
    }

    const colorKeyValueStyles = this.chartColourService.getColorKeyValueStylesAsync(app, report.NamedStyles);

    pointData.forEach(pd => {
      if (pd.ActualStart && pd.ActualEnd) {
        values.Actuals.push({
          id: pd.Id,
          low: pd.ActualStart.format('YYYY-MM-DDThh:mm:ssZ'),
          y: pd.ActualEnd.format('YYYY-MM-DDThh:mm:ssZ'),
          seriesFirstName: $localize`Actual`,
          status: pd.Status,
          color: this.chartColourService.getGanttSeriesDataColourHexcode(pd.ProjectName, pd.Status, colorKeyValueStyles),
          hoverText: pd.HoverText
        });
      }

      if (pd.TargetStart && pd.TargetEnd) {
        values.Targets.push(
          {
            id: pd.Id,
            low: pd.TargetStart.format('YYYY-MM-DDThh:mm:ssZ'),
            y: pd.TargetEnd.format('YYYY-MM-DDThh:mm:ssZ'),
            seriesFirstName: $localize`Target`,
            status: pd.Status,
            color: this.chartColourService.ganttDefaultDarkGreyHexcode,
            hoverText: pd.HoverText
          });
      }

      if (values.Targets.length !== values.Actuals.length) {
        if (values.Targets.length < values.Actuals.length) {
          values.Targets.push(null);
        } else {
          values.Actuals.push(null);
        }
      }

      values.Categories.push(pd.ProjectName);

      if (pd.TargetStart && (<moment.Moment>pd.TargetStart).diff(values.EarliestDate) < 0) {
        values.EarliestDate = pd.TargetStart;
      }

      if (pd.TargetEnd && (<moment.Moment>pd.TargetEnd).diff(values.EarliestDate) < 0) {
        values.EarliestDate = pd.TargetEnd;
      }

      if (pd.ActualStart && (<moment.Moment>pd.ActualStart).diff(values.EarliestDate) < 0) {
        values.EarliestDate = pd.ActualStart;
      }

      if (pd.ActualEnd && (<moment.Moment>pd.ActualEnd).diff(values.EarliestDate) < 0) {
        values.EarliestDate = pd.ActualEnd;
      }
    });

    return values;
  }
}
