<section class="homepage-container">

  <app-folders
    #leftAside
    class="left-aside-area"
    [selectedFolderIdentifier]='(homeModel.homepageIdentifier.$ | async)'
    i18n-home
    title="Home"
    [folderModel]="pageModel.folderModel"
    [pinnedStateVal]="showAside$ | async">
  </app-folders>

  <article class="main-content-area" [style.background-color]="homeModel.backgroundColour.$|async">

    <div *ngIf="(homeModel.loadingError.$ | async)">
      <div class="container-fluid text-center">
        <div class="alert alert-danger m-4" role="alert">
          <strong>Oh snap!</strong> Refresh your browser if this error keeps occurring or contact Softools support.
        </div>
      </div>
    </div>

    <ng-container *ngIf="!(homeModel.loadingError.$ | async)">
      <div *ngIf="(homeModel.loading.$ | async)" class="container text-center">
        <sof-spinner-icon [size]="'4x'" class="st-text-theme-primary loader"></sof-spinner-icon>
      </div>
      <app-homepage *ngIf="!(homeModel.loading.$ | async) && (homeModel.homepage.$ | async)"
        [homeModel]="homeModel"
        [dashboardModel]="homeModel.dashboardModel"
        [homepage]="(homeModel.homepage.$ | async)"
        [dashboard]="(homeModel.dashboard.$ | async)">
      </app-homepage>
    </ng-container>
  </article>

</section>

<app-floating-support-widget></app-floating-support-widget>

