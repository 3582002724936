import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BooleanModelProperty, Model, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { FolderModel } from '../folders/folder.model';
import { GlobalModel } from '../global.model';
import { IFocusableModel } from './focusable-model.interface';

/**
 * Model representing an application page.
 *
 * The page represents a browser page (or equivalent).  It manages top-level user
 * interface elements and application content.  The content is represented by
 * other models (e.g. @see RecordModel or @see ReportModel).  These can change
 * over time, and multiple models may be supported at the same time.
 */
export class PageModel extends Model<PageModel> implements IShowFilterManagement {

  // Can't do this until appModel available or factored out
  // public readonly folderModel = new FolderModel(null, this);

  public readonly showFolderBar = new BooleanModelProperty(this, true).withLogging('FOLDERS');

  public readonly folderBarState = new ProjectionModelPoperty<boolean, string>(this.showFolderBar, s => s ? 'active' : 'inactive');

  public readonly folderModel = new FolderModel(this.globalModel, this);

  public readonly showSidebar = new BooleanModelProperty(this, false).withLogging('SIDEBAR');

  public readonly sidebarState = new ProjectionModelPoperty<boolean, string>(this.showSidebar, s => s ? 'active' : 'inactive');

  public readonly showFilterPanel = new BooleanModelProperty(this, false).withLogging('FILTER PANEL');

  public readonly focus = new ModelProperty<IFocusableModel>(this).withLogging('FOCUS');

  // unused now?
  public isFilterManagementPanelVisible$: Observable<boolean>;
  public isQuickFilterVisible$: Observable<boolean>;

  private focusSub: Subscription;

  @InjectService(BreakpointObserver)
  private readonly breakpointObserver: BreakpointObserver;

  @InjectService(Router)
  private router: Router;

  constructor(private globalModel: GlobalModel) {
    super();
    this.isFilterManagementPanelVisible$ = this.showFilterPanel.$;
  }

  public initialise() {
    this.focus.$.subscribe(focus => {
      this.focusSub?.unsubscribe();
      this.focusSub = focus?.toolbarActions.$.subscribe((actions) => {
        this.globalModel.header.toolbarActions.value = actions || [];
      });
    });

    // Handle automatic sidebar closing
    const expression = `(max-width: 768px)`;

    // If we see size go below mimimum...
    const sized = merge(fromEvent(window, 'load'), fromEvent(window, 'resize')).pipe(debounceTime(300));
    this.subscribe(sized, () => {
      if (this.breakpointObserver.isMatched(expression)) {
        this.closeFolderBar();
      }
    });

    // ... or if we're below minimum when we navigate
    // This duplication may be redundant.
    this.subscribe(this.router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd
        || event instanceof NavigationCancel || event instanceof NavigationError),
    ), () => {
      // Close the aside if the max-width is less then 768px
      if (this.breakpointObserver.isMatched(expression)) {
        this.closeFolderBar();
      }
    });
  }

  public openFolderBar() {
    this.showFolderBar.value = true;
    this.globalModel.layoutChanged();
  }

  public closeFolderBar() {
    this.showFolderBar.value = false;
    this.globalModel.layoutChanged();
  }

  public toggleFolderBar() {
    this.showFolderBar.toggle();
    this.globalModel.layoutChanged();
  }

  public toggleSidebar() {
    this.showSidebar.toggle();
  }

  public showFilterManagementPanel(show: boolean) {
    this.showFilterPanel.value = show;
  }

  public toggleFilterManagementPanel() {
    this.showFilterPanel.toggle();
  }

  public showQuickFilters(show: boolean) {
  }

  public toggleQuickFilters() {
  }

}
