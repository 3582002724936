<!-- 
  class="form-control form-control-o"
 -->
<input #input class="w-100"
  [class.validation-error]="hasValidationErrors"
  [ngClass]="extraClasses$|async"
  [ngStyle]="textStyle"
  (input)="onInputChanged($event)"
  (change)="onChange($event)"
  (blur)="onBlur($event)"
  (focus)="onFocus($event)"
  (click)="onClick($event)"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
>
