import { Injectable } from '@angular/core';
import { ImageListsRepository } from '../repos';
import { ImageList } from '../types';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, IMAGE_LISTS__STORE } from '../indexedDb';

@Injectable({ providedIn: 'root' })
export class ImageListsStorageService extends StorageServiceBase<ImageList, string> {

  constructor(dbContext: DatabaseContextService<ImageList>, public repository: ImageListsRepository) {
    super(IMAGE_LISTS__STORE, dbContext);
  }

  public async getImageListAsync(id: string) {
    return this.get(id);
  }

  /** Synchronise all select lists */
  public async syncAll(retryPolicy: IRetryPolicy = null) {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const imageLists = await policy.execute(() => this.repository.getAll());
    await this._syncImageLists(imageLists);
  }

  /** Synchronise all select lists required for an app from server and into storage */
  public async syncForApp(appIdentifier: string): Promise<ImageList[]> {
    const imageLists = await this.repository.getForApp(appIdentifier);
    await this._syncImageLists(imageLists);
    return imageLists;
  }

  public async storeAsync(list: ImageList): Promise<ImageList> {
    await this.save(list.Identifier, list);
    return list;
  }

  public async removeAsync(id: string): Promise<void> {
    await this.delete(id);
  }

  /** Reload select lists and store */
  public async refreshAsync(): Promise<Array<ImageList>> {
    const lists = await this.repository.getAll();
    await this.clear();
    for (let i = 0, length = lists.length; i < length; ++i) {
      await this.storeAsync(lists[i]);
    }

    return lists;
  }

  private async _syncImageLists(imageLists: Array<ImageList>) {
    await this.dbContext.putData(this.store, imageLists, 'Identifier');
  }
}
