import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, NgForm } from '@angular/forms';
import { FieldsHelperModule } from '../fields/fields-helper/fields-helper.module';

@NgModule({
  declarations: [
    DropdownComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    FieldsHelperModule
  ],
  exports: [DropdownComponent],
  providers: [NgForm],
})
export class DropDownModule { }
