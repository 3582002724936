import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { ModelBase } from './model-base';
import { Model } from './model';
import { ProjectionModelPoperty } from './projection-model-property';
import { MvcContainer } from './mvc-container';

export interface PersistOptions<T> {
  name?: string;
  nameProperty?: ModelProperty<string> | ProjectionModelPoperty<string, any>;
  nameFormatter?: (string) => string;
  localStore?: boolean;
  valueConverter?: (string) => T;
  defaultValue?: T;
}

/**
 * Model property that wraps a simple value.
 */
export class ModelProperty<T> extends ModelBase {

  private _value: T;

  private subject: BehaviorSubject<T>;

  private lazyLoader: () => T;

  public constructor(private container: MvcContainer = null, initial?: T) {
    super();
    this._value = initial;
  }

  override toString() {
    return this.value?.toString() ?? '';
  }

  public withLogging(x: string, enable = true) {
    if (enable) {
      this.logChanges = x;
    }
    return this;
  }

  public get value(): T {

    if (this._value === undefined && this.lazyLoader) {
      // load value, set to null if not found so we don't keep trying
      this._value = this.lazyLoader() ?? null;
    }

    return this._value;
  }

  public set value(value: T) {
    if (this.value !== value) {
      this._value = value;
      this.changed();
    }
  }

  public get $(): Observable<T> {
    if (!this.subject) {
      this.subject = new BehaviorSubject<T>(this._value);
    }
    return this.subject;
  }

  public override notify() {
    if (this.logChanges && environment.logModelChanges) {
      console.log(`${this.container?.constructor.name}:${this.logChanges} => `, this.value);
    }

    if (this.subject) {
      this.subject.next(this._value);
    }

    super.notify();

    this.container?.changed();
  }

  public withLazyLoad(loader: () => T) {
    this.lazyLoader = loader;
    return this;
  }

  /**
   * Persist the property value
   * If a concrete property type with a setFromString method
   * is not used, a converted must be supplied in the options.
   * @param options
   * @returns
   */
  public withPersistence(options: PersistOptions<T>) {

    if (options.name) {
      this.load(options.name, options);
    } else if (options.nameProperty) {
      this.subscribe(options?.nameProperty.$, (name) => {
        this.load(name, options);
      });
    }

    this.subscribe(this.$, (value) => {
      const name = options.name ?? options.nameProperty.value;
      if (name && value !== undefined) {
        this.store(name, value, options);
      }
    });

    return this;
  }

  private load(name: string, options: PersistOptions<T>) {
    if (name) {
      const key = options?.nameFormatter ? options.nameFormatter(name) : name;
      const value = localStorage.getItem(key);
      if (value) {
        if (options.valueConverter) {
          this.value = options.valueConverter(value);
        } else {
          // default to setter in concrete types
          // Only available in concrete types, vanilla ModelProperty can't do it
          // so must provide a converter
          this.setFromString(value);
        }
      } else {
        this.value = options.defaultValue;
      }
    }
  }

  private store(name: string, value: T, options: PersistOptions<T>) {
    const key = options?.nameFormatter ? options.nameFormatter(name) : name;
    if (options.defaultValue && value === options.defaultValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value.toString());
    }
  }

  protected setFromString(value: string) {
    // nop
  }
}

export class NumberModelProperty extends ModelProperty<number> {

  public set(num: number) {
    this.value = num;
  }

  protected override setFromString(value: string) {
    this.value = +value;
  }
}

/** Model property containing a boolean value */
export class BooleanModelProperty extends ModelProperty<boolean> {

  public set() {
    this.value = true;
  }

  public reset() {
    this.value = false;
  }

  public toggle() {
    this.value = !this.value;
  }

  protected override setFromString(value: string) {
    this.value = (value === 'true');
  }
}

export class SetModelProperty<T> extends ModelProperty<Set<T>> {

  constructor(container: Model<any> = null) {
    super(container, new Set<T>());
  }

  public has(item: T): boolean {
    return this.value.has(item);
  }

  public set(item: T): void {
    if (!this.has(item)) {
      this.value.add(item);
      this.changed();
    }
  }

  public delete(item: T): void {
    if (this.has(item)) {
      this.value.delete(item);
      this.changed();
    }
  }

  public clear() {
    this.value.clear();
    this.changed();
  }

  public toggle(item: T): void {
    if (this.has(item)) {
      this.value.delete(item);
    } else {
      this.value.add(item);
    }
    this.changed();
  }

  public get size() {
    return this.value.size;
  }

  public get values() {
    return Array.from(this.value.values());
  }
}

export class MapModelProperty<K, V> extends ModelProperty<Map<K, V>> {

  constructor(container: Model<any> = null) {
    super(container, new Map<K, V>());
  }

  public has(key: K) {
    return this.value.has(key);
  }

  public get(key: K) {
    return this.value.get(key);
  }

  public set(key: K, value: V) {
    const current = this.get(key);
    if (current !== value) {
      this.value.set(key, value);
      this.changed();
    }
  }

  public delete(key: K) {
    return this.value.delete(key);
  }

  public get size() {
    return this.value.size;
  }
}
