<div class="grid-container">
    <div class="title-area">
      <app-message-banner *ngIf="globalModel.siteMessage.$|async"  [globalModel]="globalModel" ></app-message-banner>
      <app-header [model]="globalModel.header"></app-header>
    </div>
    <div class="content-area">
        <router-outlet (activate)="routeActivated($event);"></router-outlet>
    </div>
</div>
<app-versioncheck></app-versioncheck>

<app-launchpad [launchpadModel]="launchpadModel" >
</app-launchpad>

<app-cookie-policy></app-cookie-policy>
