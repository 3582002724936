<app-text-area
  class="d-block w-100 h-auto"
  [appModel]="appModel"
  [reportModel]="reportModel"
  [class.inline-control]="inline"
  [style.text-align]="alignment"
  [textStyle]="textStyle"
  [field]="fieldModel"
  [template]="template"
  [form]="form"
  [record]="record"
  [listRow]="listRow"
  [ruleState]="ruleState"
  [containerField]="containerField"
>
</app-text-area>
