import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardReportComponent } from './dashboardreport.component';
import { DashboardReportCellComponent } from './dashboardreportcell.component';
import { DropDownModule } from '../dropdown.component/dropdown.module';
import { ChartModule } from '../chart.component/chart.module';
import { FieldsModule } from 'app/softoolsui.module/fields.component/fields.module';
import { ScalarFieldsModule } from '../fields/scalar-fields/scalar-fields.module';
import { CssGridModule } from 'app/cssgrid.module/cssgrid.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AssetImagesModule } from '../asset-image/asset-images.module';
import { SofSpinnerModule } from '@softools/design/icons';
import { TemplateModule } from 'app/workspace.module/components/template/template.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReportModule } from '../report/report.module';
import { FoldersModule } from '../folders.component/folders.module';

@NgModule({
  declarations: [DashboardReportComponent, DashboardReportCellComponent],
  imports: [
    CommonModule,
    DropDownModule,
    ChartModule,
    AssetImagesModule,
    FieldsModule,
    ScalarFieldsModule,
    CssGridModule,
    ReportModule,
    FontAwesomeModule,
    SofSpinnerModule,
    TemplateModule,
    OverlayModule,
    FoldersModule
  ],
  exports: [DashboardReportComponent],
  providers: [],
})
export class DashboardModule {}
