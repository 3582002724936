import { Injectable } from '@angular/core';
import { Report, Enums, ChartField, IndexedAppData, Record, ChartFieldType } from '@softools/softools-core';
import { ChartEnums } from '../chart.enums';
import { BaseChartDataService } from './base-chart-data.service';
import { ReportFilter } from 'app/filters/types';
import { AppField } from 'app/types/fields/app-field';
import { ChartErrorException } from 'app/exceptions';
import { Application } from 'app/types/application';


interface ChartData {
  column: any;
  values: Array<any>;
  colour?: string;
  order?: number;
}

/**
 * Generate chart data for a summary series chart.
 * 
 * This chart type was implemented as bespoke work for Chyoda. As a result the terminology and functionality
 * reflects the use case they were concerned with.
 * 
 * Records are split according to a "capacity" value - a bit field on the record. This is not a capacity value
 * but indicates whether the values are treated as available capacity.
 * 
 * The record config also specifies one or more values fields. 
 * 
 * Where the capacity value is true:
 * These are grouped according to another record value (see below) and summed. This produces a data series which
 * is displayed as a line series on the chart (x axis is value, Y is aggregated total).
 * 
 * Grouping is according to a record value - the config specifies a default field, which is overridden by the 
 * filter group if one is active.
 * 
 * Where the capacity value is false:
 * Values from the records are accumulated to provide an additional data series. This is presented as an area
 * series on the chart.
 * 
 * to document:
 * Variance
 * Colour
 * Order
 * What do the capacity = false records actually look like?
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class SummarySeriesChartDataService extends BaseChartDataService {
  public async getSummarySeriesChartDataOptions(app: Application, filter: ReportFilter, appData: IndexedAppData, reportChartConfig: Report, record?: Record) {

    const chartFields = reportChartConfig.Chart.ChartFields;
    chartFields.forEach(field => field.BaseField = app.getField(field.BaseFieldIdentifier));

    const cumulativeChartFields: ChartField[] = chartFields
      .filter((field) => field.Type !== ChartFieldType.Order && field.Type !== ChartFieldType.Colour)
      .sort((a, b) => a.Order - b.Order);

    // Get the field that controls whether a record should be included in the capacity calculation
    // This should be a bit field by spec but any field that has a truthy value could be used
    const capacityField: AppField = reportChartConfig.Chart.CapacityFieldIdentifier ?
      app.getField(reportChartConfig.Chart.CapacityFieldIdentifier) :
      null;

    const orderField = chartFields.find(field => field.Type === ChartFieldType.Order);

    const chartData = await this.getSummarySeriesChartData(app, filter, appData, reportChartConfig,
      chartFields, capacityField);

    if (chartData) {

      const series = new Array<any>();
      chartData?.data.forEach((data, i) => {
        const seriesData = this.seriesFromChartData(cumulativeChartFields, data);
        series.push(seriesData);
      });

      if (orderField) {
        series.sort((a, b) => a.order - b.order);
      }

      if (chartData?.capacity) {
        const lineData = await this.getLineSeries(reportChartConfig, chartData?.capacity, cumulativeChartFields, capacityField, reportChartConfig.Chart.CapacityLabel, reportChartConfig.Chart.PartialCapacity, reportChartConfig.Chart.PartialCapacity2, reportChartConfig.Chart.PartialCapacity3);

        lineData.forEach((rec) => {
          series.push(this.seriesFromChartData(cumulativeChartFields, rec, true));
        });

        if (reportChartConfig.Chart.IncludeVariance) {
          const varianceName = reportChartConfig.Chart.VarianceLabel ? reportChartConfig.Chart.VarianceLabel : $localize`Variance`;
          const varianceLine = {} as any;
          cumulativeChartFields.forEach((field, i) => {
            const totalPoints = chartData.data.map(dataPoint => +dataPoint.values[i]).reduce((agg, val) => agg + +val, 0);
            const capacity = +lineData[0].values[i];
            varianceLine[field.BaseFieldIdentifier] = (totalPoints / capacity) * 100;
          });

          const varianceData = this.chartDataFromFieldData(varianceLine, null, null, cumulativeChartFields, chartData.categories, varianceName);
          varianceData.colour = reportChartConfig.Chart.VarianceColour || '#828282';

          const varianceSeries = this.seriesFromChartData(cumulativeChartFields, varianceData, true);
          varianceSeries.yAxis = 1;

          series.push(varianceSeries);
        }
      }

      const matrix = this.buildCumulativeTable(reportChartConfig, chartData?.data, chartFields, appData);
      return { Series: series, Categories: Array.from(chartData.categories), MatrixTableData: matrix };
    }
    return null;
  }

  private buildCumulativeTable(reportChartConfig: Report, chartData: Array<ChartData>, chartFields: ChartField[], appData: IndexedAppData) {
    const tabledata = {
      IsCumulative: true,
      Rows: new Array<any>(),
      Columns: chartData.map(o => {
        const value = o.column ? o.column : $localize`No Value Set`;
        let fieldType;
        if (!!o.column) {
          fieldType = reportChartConfig.Chart.SeriesNameFieldIdentifier ? appData.app.Fields.find(field => field.Identifier === reportChartConfig.Chart.SeriesNameFieldIdentifier).Type : Enums.FieldType.Text;
        } else {
          fieldType = Enums.FieldType.Text;
        }
        return {
          Value: value,
          FieldType: fieldType
        };
      }),
      TargetClickThroughReportIdentifier: ''
    };

    chartFields
      .filter(field => field.Type !== ChartFieldType.Colour && field.Type !== ChartFieldType.Order)
      .forEach((field, i) => {
        const seriesData = this.getSeriesDataFromDictionary(chartData, field, i);
        const cells = seriesData.map((o, j) => {
          return {
            ListOfValues: new Array<any>({ Value: o }),
            XAxisFieldValue: tabledata.Columns[j].Value,
            YAxisFieldValue: field.Calculation === ChartEnums.Calculation.Average || field.Calculation === ChartEnums.Calculation.Total ? null : field.BaseFieldIdentifier
          };
        });

        const tablerow = {
          Label: `${field.Label || field.BaseField.Label} (${ChartEnums.Calculation[field.Calculation]})`,
          LabelFieldType: field.BaseField.Type,
          Cells: cells
        };

        tabledata.Rows.push(tablerow);
    });

    if (reportChartConfig.TargetClickThroughReportId) {
      tabledata.TargetClickThroughReportIdentifier = appData.app.Reports.find(report => report.Id === reportChartConfig.TargetClickThroughReportId).Identifier;
    }
    return tabledata;
  }

  private getSeriesDataFromDictionary(chartData: Array<ChartData>, field: ChartField, index: number) {
    const calculation = field.Calculation;
    const fieldFunction = field.Function;
    const decimalPlaces = field.ToolTipDecimals;
    const seriesPlotValues = new Array<number>();

    chartData.forEach((_, i) => {
      let decValue = chartData[i].values[index];
      if (decimalPlaces != null) {
        decValue = +(decValue.toFixed(decimalPlaces));
      }

      if (fieldFunction === ChartEnums.Function.Addition && seriesPlotValues.length > 0) {
        const prevPlotIndex = seriesPlotValues.length - 1;
        decValue += seriesPlotValues[prevPlotIndex];
      }

      seriesPlotValues.push(decValue);
    });

    return seriesPlotValues;
  }

  private async getSummarySeriesChartData(app: Application, filter: ReportFilter, appData: IndexedAppData, reportChartConfig: Report, cumulativeChartFields: ChartField[], capacityField: AppField) {
    const data = new Array<ChartData>();

    // Build the series based on the configured name field unless overriden by current filter group
    const columnField = app.getField(reportChartConfig.Chart.SeriesNameFieldIdentifier);
    const groupField = (filter?.Group) ? app.getField(filter.Group) : null;
    const seriesColumn = groupField ? groupField : columnField;
    if (!seriesColumn) {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoDefaultSeriesName);
    }

    const recordData = await this.groupRecords(app, filter, appData, seriesColumn, cumulativeChartFields, capacityField);
    const categories = new Set<any>();
    if (recordData) {
      recordData?.data.forEach(record => {
        data.push(this.chartDataFromFieldData(record, groupField, columnField, cumulativeChartFields, categories));
      });

      return { data: data, categories: categories, capacity: recordData.capacity };
    }
    return null;
  }

  private async groupRecords(app: Application, filter: ReportFilter, appData: IndexedAppData, columnField: AppField, cumulativeChartFields: ChartField[], capacityField: AppField) {

    const groupField = (filter?.Group) ? app.getField(filter.Group) : null;
    if (!groupField && appData.length > 50) {
      throw new ChartErrorException(ChartEnums.ChartErrors.TooManySeries);
    }

    const groupedRecords: { [key: string]: { [key: string]: Array<any> } } = {};
    const records: { [key: string]: Array<{ [key: string]: any }> } = {};

    // Locate records that have the capacity flag set
    const capacityRecords: { [key: string]: Array<any> } = {};
    await appData.eachRecord((record => {
      let capacity = false;
      if (capacityField) {
        capacity = capacityField.getRawRecordValue(record);
      }

      if (capacity) {
        cumulativeChartFields.forEach((field) => {
          if (!(field.BaseFieldIdentifier in capacityRecords)) {
            capacityRecords[field.BaseFieldIdentifier] = new Array();
          }

          capacityRecords[field.BaseFieldIdentifier].push((<AppField>field.BaseField).getRawRecordValue(record));
        });
      } else {
        if (groupField) {
          this.addToGroupedRecords(record, groupField, columnField, cumulativeChartFields, groupedRecords);
        } else {
          this.addToRecords(record, columnField, cumulativeChartFields, records);
        }
      }
    }));

    const capacityLine: { [key: string]: any } = {};
    cumulativeChartFields.forEach(field => {
      const id = field.BaseFieldIdentifier;
      if (capacityRecords[id]?.length) {
        const total = capacityRecords[id].reduce((agg, val) => agg += val);
        capacityLine[id] = total;
      }
    });

    if (groupField) {
      const groupedKeys = Object.keys(groupedRecords);
      if (groupedKeys.length > 50) {
        throw new ChartErrorException(ChartEnums.ChartErrors.TooManySeries);
      }

      const aggregatedRecords = groupedKeys.map(groupName => {
        const aggregatedRecord: { [key: string]: any } = {};
        const group = groupedRecords[groupName];
        aggregatedRecord[columnField.Identifier] = group[columnField.Identifier].reduce((agg, val) => agg === val ? agg : !agg ? val : $localize`Multiple Values`);
        cumulativeChartFields.forEach(field => {
          if (field.Type === ChartFieldType.Colour) {
            aggregatedRecord[field.BaseFieldIdentifier] = group[field.BaseFieldIdentifier].find(val => val);
          } else if (field.Type === ChartFieldType.Order) {
            aggregatedRecord[field.BaseFieldIdentifier] = Math.min(...group[field.BaseFieldIdentifier].filter(val => val != null));
          } else {
            aggregatedRecord[field.BaseFieldIdentifier] = group[field.BaseFieldIdentifier].reduce((agg, val) => agg += val);
          }
        });
        return aggregatedRecord;
      });

      return { data: aggregatedRecords, capacity: Object.keys(capacityLine).length ? capacityLine : undefined };
    } else {
      const recordKeys = Object.keys(records);
      const outputRecords = new Array<{ [key: string]: any }>();
      recordKeys.forEach(key => {
        const sameKeyRecords = records[key];
        sameKeyRecords.forEach((record, i) => {
          const outputRecord = { ...record };
          outputRecord[columnField.Identifier] = `${key}${i ? '_' + i : ''}`;
          outputRecords.push(outputRecord);
        });
      });

      return { data: outputRecords, capacity: Object.keys(capacityLine).length ? capacityLine : undefined };
    }

  }

  private chartDataFromFieldData(fieldData: { [key: string]: any; }, groupField: AppField, columnField: AppField, cumulativeChartFields: ChartField[], categories: Set<any>, columnNameOveride?: string) {
    let columnNameValue: any = $localize`No Value Set`;

    if (columnNameOveride) {
      columnNameValue = columnNameOveride;
    } else {
      columnNameValue = fieldData[groupField ? groupField.Identifier : columnField.Identifier];
    }

    const chartData = { column: columnNameValue, values: new Array<any>() } as ChartData;

    cumulativeChartFields.forEach((chartField, i) => {
      if (chartField.BaseField) {
        if (chartField.Type === ChartFieldType.Colour) {
          const val = fieldData[chartField.BaseField.Identifier];
          chartData.colour = val;
        } else if (chartField.Type === 28) {
          const val = fieldData[chartField.BaseField.Identifier] || 0;
          const fieldValueNumeric = parseFloat(val.toString());
          chartData.order = fieldValueNumeric;
        } else {
          const val = fieldData[chartField.BaseField.Identifier] || 0;
          const fieldValueNumeric = parseFloat(val.toString());
          chartData.values.push(!isNaN(fieldValueNumeric) ? fieldValueNumeric : 0);
          if (categories) {
            categories.add(chartField.Label);
          }
        }
      }
    });

    return chartData;
  }

  private seriesFromChartData(cumulativeChartFields: ChartField[], chartData: ChartData, capacityOrVariance = false) {
    const series = {} as any;
    series.name = chartData.column;
    series.data = cumulativeChartFields.map((field, j) => {
      if (field.ToolTipDecimals != null) {
        return parseFloat(chartData.values[j].toFixed(field.ToolTipDecimals));
      }
      return chartData.values[j];
    });
    series.type = capacityOrVariance ? 'line' : 'area';
    series.tooltip = { shared: true, useHTML: true } as Highcharts.TooltipOptions;
    if (chartData.colour) {
      series.color = chartData.colour;
    }
    if (chartData.order) {
      series.order = chartData.order;
    }

    return series;
  }

  private async getLineSeries(reportChartConfig: Report, capacityLine: { [key: string]: any }, cumulativeChartFields: ChartField[], capacityField: AppField, capacityLabel: string, partialCapacity: number, partialCapacity2: number, partialCapacity3: number) {
    if (capacityField) {

      let columnNameValue: any = $localize`Capacity`;

      columnNameValue = capacityLabel ?? columnNameValue;

      const data: Array<ChartData> = new Array<ChartData>();
      data.push({ column: columnNameValue, values: new Array<any>(), colour: reportChartConfig.Chart.CapacityColour || '#1a1a1a' });

      cumulativeChartFields.forEach((chartField) => {
        if (chartField) {
          const val = capacityLine[chartField.BaseFieldIdentifier] || 0;
          const fieldValueNumeric = parseFloat(val.toString());
          data[data.findIndex(d => JSON.stringify(d.column) === JSON.stringify(columnNameValue))].values.push(!isNaN(fieldValueNumeric) ? fieldValueNumeric : 0);
        }
      });

      if (partialCapacity) {
        data.push({ column: `${partialCapacity}% ${columnNameValue}`, values: data[0].values.map(val => val * (partialCapacity / 100)), colour: reportChartConfig.Chart.PartialCapacityColour || '#4E4E4E' });
      }
      if (partialCapacity2) {
        data.push({ column: `${partialCapacity2}% ${columnNameValue}`, values: data[0].values.map(val => val * (partialCapacity2 / 100)), colour: reportChartConfig.Chart.PartialCapacityColour2 || '#4E4E4E' });
      }
      if (partialCapacity3) {
        data.push({ column: `${partialCapacity3}% ${columnNameValue}`, values: data[0].values.map(val => val * (partialCapacity3 / 100)), colour: reportChartConfig.Chart.PartialCapacityColour3 || '#4E4E4E' });
      }

      return data;
    }
    return null;
  }

  private addToGroupedRecords(record: Record, groupField: AppField, columnField: AppField, cumulativeChartFields: ChartField[], groupedRecords: { [key: string]: { [key: string]: Array<any> } }) {
    let group = groupField.getRawRecordValue(record);

    if (group) {
      group = group.toString();
    } else {
      group = '';
    }

    if (!(group.toString() in groupedRecords)) {
      groupedRecords[group.toString()] = {};
    }


    if (!(columnField.Identifier in groupedRecords[group.toString()])) {
      groupedRecords[group.toString()][columnField.Identifier] = new Array();
    }
    groupedRecords[group.toString()][columnField.Identifier].push(columnField.getRawRecordValue(record));

    cumulativeChartFields.forEach((field) => {
      if (!(field.BaseFieldIdentifier in groupedRecords[group.toString()])) {
        groupedRecords[group.toString()][field.BaseFieldIdentifier] = new Array();
      }
      groupedRecords[group.toString()][field.BaseFieldIdentifier].push((<AppField>field.BaseField).getRawRecordValue(record));
    });
  }

  private addToRecords(record: Record, columnField: AppField, cumulativeChartFields: ChartField[], records: { [key: string]: Array<{ [key: string]: any }> }) {
    const colVal = columnField.getRawRecordValue(record);
    const recordData = {};

    cumulativeChartFields.map((field) => {
      recordData[field.BaseFieldIdentifier] = ((<AppField>field.BaseField).getRawRecordValue(record));
    });


    if (!(colVal in records)) {
      records[colVal] = new Array();
    }

    records[colVal].push(recordData);
  }
}
