
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalHelperService {

  public makeRandomTextId(length: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
