import { Injectable } from '@angular/core';
import { Enums, Report } from '@softools/softools-core';
import { AppModel } from '../app.model';
import { FilterModel } from '../filter.model';
import { PageModel } from '../page/page.model';
import { ChartReportModel } from '../reports/chart-report.model';
import { ListReportModel } from '../reports/list-report.model';
import { MatrixReportModel } from '../reports/matrix-report.model';
import { RecordsReportModel } from '../reports/records-report.model';
import { ReportModel } from '../reports/report.model';
import { TableReportModel } from '../reports/table-report.model';
import { ModelFactory } from './model-factory.';

@Injectable()
export class ModelFactoryService extends ModelFactory {

  public override createReportModel(
    report: Report,
    appModel: AppModel,
    filterModel: FilterModel,
    pageModel: PageModel): ReportModel {

    switch (report.Type) {
      case Enums.ReportTypes.List:
        return new ListReportModel(appModel, filterModel, pageModel);
      case Enums.ReportTypes.Table:
        return new TableReportModel(appModel, filterModel, pageModel);
      case Enums.ReportTypes.Matrix:
        return new MatrixReportModel(appModel, filterModel, pageModel);
      case Enums.ReportTypes.Chart:
        return new ChartReportModel(appModel, filterModel, pageModel);
      case Enums.ReportTypes.Dashboard:   // ??
      default:
        return null;
    }
  }

  /** Create a dual report. Currently this only supports record view reports added to a chart */
  public override createDualReportModel(
    report: Report,
    appModel: AppModel,
    filterModel: FilterModel,
    pageModel: PageModel): RecordsReportModel {

    switch (report.Type) {
      case Enums.ReportTypes.List:
        return new ListReportModel(appModel, filterModel, pageModel);
      case Enums.ReportTypes.Table:
        return new TableReportModel(appModel, filterModel, pageModel);
      default:
        return null;
    }
  }

}
