<div class="sof-modal-container">
  <div class="cardv2-popup sof-modal">
    <div class="cardv2-header">
      <div i18n>History</div>
    </div>
    <mat-dialog-content>
      <div class="d-grid gap-2 m-2" style="grid-template-columns: auto auto">
        <div *ngIf="exactCreatedDate" class="d-grid">
          <span class="info-title" i18n="@@Created">Created</span>
          <span class="info-value">{{ exactCreatedDate | date: 'medium' }}</span>
        </div>
        <div *ngIf="!exactCreatedDate" class="d-grid">
          <span class="info-title" i18n="@@Created">Created</span>
          <span class="info-value">-</span>
        </div>
        <div class="d-grid">
          <span class="info-title" i18n="@@Created By">Created By</span>
          <span class="info-value">{{ createdByUser }}</span>
        </div>
        <div *ngIf="exactUpdatedDate" class="d-grid">
          <span class="info-title" i18n="@@Modified">Modified</span>
          <span class="info-value">{{ exactUpdatedDate | date: 'medium' }}</span>
        </div>
        <div *ngIf="!exactUpdatedDate" class="d-grid">
          <span class="info-title" i18n="@@Modified">Modified</span>
          <span class="info-value">-</span>
        </div>
        <div class="d-grid">
          <span class="info-title" i18n="@@Modified By">Modified By</span>
          <span class="info-value">{{ updatedByUser }}</span>
        </div>
      </div>

      <hr />

      <div class="container mt-4">
        <h5 i18n="@@Activity" class="mb-4">Activity</h5>
        <div *ngFor="let activity of activities" class="row mb-2">
          <div class="col">
            <div class="w-100">
              <span>
                {{ activity.Title }}
              </span>
              <div *ngIf="activity.Changes && activity.Changes.length > 0" class="content">
                <div *ngFor="let item of activity.Changes" class="change-item">{{ item.Summary }}</div>
              </div>
              <span class="published">
                {{ publishDate(activity.Published) }}
                <fa-icon [icon]="['fal', 'info-circle']" [title]="publishDate(activity.Published)" (click)="toggleShowDateStrings()">
                </fa-icon>
              </span>
              <span *ngIf="showDateStrings" class="published">
                {{ exactPublishDate(activity.Published) | date: 'medium' }}
              </span>
            </div>
            <hr class="w-100" />
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div class="cardv2-footer mt-4 w-100 d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary border-0" [mat-dialog-close] i18n>Close</button>
    </div>
  </div>
</div>
