<div class="h-100">
  <form (ngSubmit)="submit()" class="h-100" style="user-select: none" cdkOverlayOrigin #formOrigin="cdkOverlayOrigin">
    <div class="h-100 d-flex flex-column outer">
      <ng-container *ngIf="!editFilterSpec">
        <ng-container *ngTemplateOutlet="filterSelector"></ng-container>
      </ng-container>
      <ng-container *ngIf="editFilterSpec">
        <ng-container *ngTemplateOutlet="filterEditor"></ng-container>
      </ng-container>
    </div>
  </form>
</div>

<ng-template #filterEditor>
  <div *ngIf="isOnline" class="filter-edit-container panel-container h-100">
    <div class="header-area border-bottom d-flex text-center">
      <div class="flex-grow-1">
        <ng-container *ngTemplateOutlet="filterDisplay; context: { filter: editFilterSpec }"></ng-container>
      </div>
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel($event)"></button>
    </div>
    <div class="filters-area d-flex flex-column justify-content-start p-2">
      <div class="h-100">
        <div class="d-flex flex-column h-100" style="overflow-y: auto; overflow-x: hidden">
          <app-filter-display
            class="h-100"
            [appIdentifier]="appIdentifier"
            [orign]="formOrigin"
            [filterSpec]="editFilterSpec"
            [filterModel]="filterModel"
          ></app-filter-display>
        </div>
      </div>
    </div>

    <div class="sort-area mx-4 me-2">
      <mat-form-field floatLabel="always" class="w-100">
        <mat-label i18n>Sort</mat-label>
        <input matInput #input [matAutocomplete]="autoSort" [(ngModel)]="selectedSortField" (keyup)="searchFields($event)" />

        <mat-autocomplete #autoSort="matAutocomplete" (optionSelected)="sortFieldChanged($event)">
          <mat-option [value]="sortNoField" i18n>None</mat-option>
          <mat-option *ngFor="let field of filteredFields | async" [value]="field.Identifier">
            <small
              ><b>{{ field.Label }}</b> ({{ field.Identifier }})</small
            >
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="ascdec-area ms-0 me-4">
      <div class="d-flex align-items-center justify-content-end h-100">
        <fa-icon
          *ngIf="editFilterSpec.sortAscending"
          [icon]="['fal', 'sort-amount-up']"
          class="pointer me-2"
          [border]="true"
          (click)="sortOrderChanged($event)"
        ></fa-icon>
        <fa-icon
          *ngIf="!editFilterSpec.sortAscending"
          [icon]="['fal', 'sort-amount-down']"
          class="pointer me-2"
          [border]="true"
          (click)="sortOrderChanged($event)"
        ></fa-icon>
      </div>
    </div>

    <div class="groups-area mx-4 me-2">
      <mat-form-field floatLabel="always" class="w-100">
        <mat-label i18n>Group by</mat-label>
        <input matInput #input [matAutocomplete]="autoGroup" [(ngModel)]="selectedGroupField" (keyup)="searchGroups($event)" />

        <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="groupChanged($event)" [classList]="'filter-autocomplete'">
          <mat-option [value]="sortNoField" i18n>None</mat-option>
          <mat-option *ngFor="let field of filteredGroups | async" [value]="field.Identifier">
            <small
              ><b>{{ field.Label }}</b> ({{ field.Identifier }})</small
            >
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="grouporder-area ms-0 me-4">
      <div class="d-flex align-items-center justify-content-end h-100">
      <fa-icon
        *ngIf="editFilterSpec.groupAscending"
        [icon]="['fal', 'sort-amount-up']"
        class="pointer me-2"
        [border]="true"
        (click)="groupOrderChanged($event)"
      ></fa-icon>
      <fa-icon
        *ngIf="!editFilterSpec.groupAscending"
        [icon]="['fal', 'sort-amount-down']"
        class="pointer me-2"
        [border]="true"
        (click)="groupOrderChanged($event)"
      ></fa-icon>
      </div>
    </div>

    <div class="actions-area border-bottom">
      <div class="d-flex justify-content-between">
        <button type="button" class="primary-line" (click)="updateFilter()" title="Update current filter" i18n-title>
          <fa-icon [icon]="['fal', 'share-square']"></fa-icon>
          <span i18n>Update</span>
        </button>
        <button *ngIf="isEmbeddedApp" type="button" class="primary-line" (click)="saveAs($event)" title="Save filter"
          i18n-title>
          <fa-icon [icon]="['fal', 'save']"></fa-icon> <span i18n>Save</span>
        </button>
        <button
          *ngIf="isEmbeddedApp && editFilterSpec?.Id"
          type="button"
          class="close btn-sm"
          (click)="delete($event)"
          title="Delete filter"
          i18n-title
        >
          <fa-icon [icon]="['fal', 'trash']"></fa-icon> <span i18n>Delete</span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!isOnline" class="text-center">
    <fa-icon [icon]="['fal', 'wifi-slash']" size="2x" class="me-1 text-muted"></fa-icon>
    <div i18n class="mt-2">You cannot edit filters while offline.</div>
  </div>
</ng-template>

<ng-template #filterSelector>
  <div class="filter-sel-container panel-container h-100" [class.select-only]="selectOnly" >
    <div class="header-area border-bottom text-center st-text-theme-primary">Filters</div>
    <div *ngIf="!selectOnly" class="edit-switch-area border-bottom">
      <button type="button" class="primary-line" (click)="$event.stopPropagation(); editFilter(nullPseudoId)">
        <fa-icon [icon]="['fal', 'plus']"></fa-icon> <span i18n>New Filter</span>
      </button>
    </div>
    <div class="close-area border-bottom d-flex justify-content-end align-items-center">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel($event)"></button>
    </div>
    <div class="filters-area" style="overflow: hidden">
      <div class="h-100" style="overflow-y: auto">
        <div class="h-100">
          <mat-list dense class="w-100" style="flex: 1; min-width: 20rem">
            <mat-list-item class="filter-item pointer" (click)="filterClicked(nullPseudoId)">
              <fa-icon [icon]="['fal', 'circle']" size="2x" class="me-2"></fa-icon>
              <span class="mx-2" [class.current-filter]="isSelectedFilter(nullPseudoId)" i18n>No Filter</span>
              <sof-check-icon *ngIf="isSelectedFilter(nullPseudoId)" class="ms-2"></sof-check-icon>
              <span style="margin-left: auto" *ngIf="!selectOnly">
                <ng-container *ngTemplateOutlet="editButton; context: { id: nullPseudoId }"></ng-container>
              </span>
            </mat-list-item>
            <!-- Custom is only show when an ad=hoc filter is in use (so it's alwasy selected) -->
            <mat-list-item *ngIf="isSelectedFilter(customPseudoId)" class="filter-item" (click)="filterClicked(customPseudoId)">
              <fa-icon [icon]="['fal', 'wrench']" size="2x" class="me-2"></fa-icon>
              <span class="mx-2 current-filter" i18n>Custom</span>
              <sof-check-icon class="ms-2"></sof-check-icon>
              <span style="margin-left: auto" *ngIf="!selectOnly">
                <ng-container *ngTemplateOutlet="editButton; context: { id: customPseudoId }"></ng-container>
              </span>
            </mat-list-item>
            <h3 matSubheader class="border-bottom">
              <fa-icon [icon]="['fal', 'globe-americas']" size="2x" class="me-2"></fa-icon>
              <span i18n>Global Filters</span>
            </h3>
            <ng-container *ngTemplateOutlet="filterlist; context: { filters: globalFilters() }"></ng-container>
            <h3 matSubheader class="border-bottom">
              <fa-icon [icon]="['fal', 'users']" size="2x" class="me-2"></fa-icon>
              <span i18n>Team Filters</span>
            </h3>
            <ng-container *ngTemplateOutlet="filterlist; context: { filters: teamFilters() }"></ng-container>
            <h3 matSubheader class="border-bottom">
              <fa-icon [icon]="['fal', 'user']" size="2x" class="me-2"></fa-icon>
              <span i18n>Personal Filters</span>
            </h3>
            <ng-container *ngTemplateOutlet="filterlist; context: { filters: personalFilters() }"></ng-container>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filterlist let-filters="filters">
  <mat-list-item *ngFor="let filter of filters" class="filter-item pointer" (click)="filterClicked(filter.Id)">
    <ng-container *ngTemplateOutlet="filterDisplay; context: { filter: filter }"></ng-container>
    <sof-check-icon *ngIf="isSelectedFilter(filter.Id)" class="ms-2"></sof-check-icon>
    <span style="margin-left: auto" *ngIf="!selectOnly">
      <ng-container *ngTemplateOutlet="baseButton; context: { filter: filter }"></ng-container>
      <ng-container *ngTemplateOutlet="editButton; context: { id: filter.Id }"></ng-container>
    </span>
  </mat-list-item>
</ng-template>

<ng-template #filterDisplay let-filter="filter">
  <span
    *ngIf="filter.Description"
    class="mx-2 filter-title"
    [class.current-filter]="isSelectedFilter(filter.Id)"
    title="{{ filter.Description }}"
    >{{ filter.Description }}</span
  >
  <span *ngIf="!filter.Description" class="mx-2 st-text-theme-primary" [class.current-filter]="isSelectedFilter(filter.Id)" i18n>New Filter</span>

  <span title="Default filter for this app" i18n-title>
    <fa-icon *ngIf="filter.IsDefault" size="1x" [icon]="['fal', 'star']" [classes]="['ms-1', 'st-text-theme-primary']"></fa-icon>
  </span>

  <ng-container *ngTemplateOutlet="baseIndicator; context: { filter: filter }"></ng-container>
</ng-template>

<ng-template #baseButton let-filter="filter">
  <span *ngIf="isBaseFilterSelectionEnabled(filter)" class="pointer mx-2">
    <span title="Base filter for this report" i18n-title (click)="setBaseFilter($event)">
      <fa-icon
        *ngIf="isBaseFilter(report, filter)"
        size="2x"
        [icon]="['fas', 'star-exclamation']"
        [classes]="['ms-1', 'st-text-theme-primary']"
      ></fa-icon>
    </span>
    <span title="Click to set as base filter for this report" i18n-title (click)="setBaseFilter($event, filter)">
      <fa-icon
        *ngIf="!isBaseFilter(report, filter)"
        size="2x"
        [icon]="['fal', 'star-exclamation']"
        class="text-muted"
        [classes]="['ms-1']"
      ></fa-icon>
    </span>
  </span>
</ng-template>

<ng-template #baseIndicator let-filter="filter">
  <span *ngIf="!isBaseFilterSelectionEnabled(filter)" class="pointer mx-2">
    <span title="Base filter for this report" i18n-title>
      <fa-icon
        *ngIf="isBaseFilter(report, filter)"
        size="1x"
        [icon]="['fas', 'star-exclamation']"
        [classes]="['ms-1', 'st-text-theme-primary']"
      ></fa-icon>
    </span>
  </span>
</ng-template>

<ng-template #editButton let-id="id">
  <sof-edit-icon
    *ngIf="isOnline"
    size="2x"
    class="me-1 st-text-theme-primary"
    (click)="$event.stopPropagation(); editFilter(id)"
  ></sof-edit-icon>
  <fa-icon *ngIf="!isOnline" [icon]="['fal', 'wifi-slash']" size="2x" class="me-1 text-muted"></fa-icon>
</ng-template>
