import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { PersonFieldComponent } from '../person-field/person-field.component';
import { UsersService } from 'app/services/users.service';
import { MappedPendingUser, MappedUser } from '@softools/softools-core';

@Component({
  selector: 'app-person-team-field',
  templateUrl: './person-field.component.html',
  styleUrls: ['./person-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonTeamFieldComponent extends PersonFieldComponent implements OnInit, OnDestroy {

  private mappedUsersForMyTeams: Array<MappedUser>;

  constructor(usersService: UsersService) {
    super(usersService);
    this.byTeam = true;
  }

  public override ngOnInit() {
    this.mappedUsersForMyTeams = this.usersService.getAllMappedForMyTeams();
    super.ngOnInit();

    this.subscribe(this.searchOnlyMyTeams$, (t) => {
      this.filter();
    });
  }

  protected override userList(): Array<MappedUser | MappedPendingUser> {
    return this.searchOnlyMyTeams$.value ? this.mappedUsersForMyTeams : super.userList();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
