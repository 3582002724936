import App from './site-settings-app.config.json';

import { Injectable } from '@angular/core';
import { Record, SiteSettingsStorageService, SiteSettings, permissionSets, isString, RecordId } from '@softools/softools-core';
import { EmbeddedApplication } from '../embedded-application';
import { RecordPatch } from 'app/workspace.module/types/record-patch';
import { PermissionsService } from 'app/services/permissions.service';
import { ISelectOptions } from 'app/softoolsui.module/select-options/select-options.component';
import { HomepagesService } from 'app/services/homepages.service';
import { EpochConverter } from 'app/types/epoch-converter';
import { GetRecordOptions } from 'app/types/application';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { InjectService } from 'app/services/locator.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable({ providedIn: 'root' })
export class SiteSettingsApplication extends EmbeddedApplication<SiteSettings> {

  private readonly _recordId = 'bcc7d770-3b92-47e4-b437-4060bd46fde5';

  private _siteId: number;

  protected selectListOptions: ISelectOptions;

  @InjectService(GlobalModelService)
  private readonly globalModelService: GlobalModelService;

  constructor(
    private settingsService: SiteSettingsStorageService,
    private permissionsService: PermissionsService,
    private recordPersistService: RecordPersistService,
    protected homepagesService: HomepagesService
  ) {
    super(App.App);
  }

  public override initRecord(record: any, selectListOptions: ISelectOptions) {
    // Capture config parameters
    this.selectListOptions = selectListOptions;
    record.Languages = this.languageList(record);
  }

  public override async getRecordByIdAsync(_id: RecordId, options?: GetRecordOptions): Promise<Record> {
    const settings = await this.settingsService.getRemoteSettingAsync();
    this._siteId = settings.Id;
    const record = this.toRecord(settings);

    // Look up any pending patch if we're going to need it
    const pendingPatch = options?.applyPatch && this.recordPersistService.getPatch(this._recordId);
    if (record && pendingPatch) {
      pendingPatch.updateRecord(record, this);
    }

    return record;
  }

  public toRecord(settings: SiteSettings): Record {

    const record = settings && ({
      ...settings,
      _id: this._recordId,
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',
      IsArchived: false,
      Languages: this.languageList(settings),
      SSODomainList: settings.SSODomains?.split('|').map(dom => ({ Domain: dom })) || [],
      RegistrationPermissionSets: this.convertPermissionSetValue(settings.RegistrationPermissionSets),
    } as Record);

    return record;
  }

  public languageList(settings: SiteSettings) {
    const enabled = settings.SupportedLanguages?.split(',') || [];
    const options = this.selectListOptions?.languageList || [];
    return options.map(option => ({
      Code: option.Code,
      Name: option.Name,
      Enabled: enabled.includes(option.Code) ? 'Enabled' : '',
      Default: option.Code === settings.DefaultLanguage ? 'Default' : ''
    }));
  }

  public convert(record: Record): SiteSettings {
    const values = record && <any>record;
    return (
      record && {
        Auth0ClientId: values.Auth0ClientId,
        Auth0ClientSecret: values.Auth0ClientSecret,
        Auth0Realm: values.Auth0Realm,
        ChannelPartner: values.ChannelPartner,
        Client: values.Client,
        DefaultTheme: values.DefaultTheme,
        EmbedScript: values.EmbedScript,
        ExportMessage: values.ExportMessage,
        EnforceExportMessage: values.EnforceExportMessage,
        UploadMessage: values.UploadMessage,
        EnforceUploadMessage: values.EnforceUploadMessage,
        MaxDocumentFileSizeMB: values.MaxDocumentFileSizeMB,
        MaxImageFileSizeMB: values.MaxImageFileSizeMB,
        MaxVideoFileSizeMB: values.MaxVideoFileSizeMB,
        GDPRSignupEnabled: values.GDPRSignupEnabled,
        GDPRSignupTitle: values.GDPRSignupTitle,
        GDPRSignupContent: values.GDPRSignupContent,
        HelpAppIdentifier: values.HelpAppIdentifier,
        Hostnames: values.Hostnames,
        Id: values.Id,
        Identifier: values.Identifier,
        ImageURI: values.ImageURI,
        IncludeSupportWidget: values.IncludeSupportWidget,
        KnowledgeShareAppIdentifier: values.KnowledgeShareAppIdentifier,
        KnowledgeShareFilterString: values.KnowledgeShareFilterString,
        LicensingSignupEnabled: values.LicensingSignupEnabled,
        LicensingSignupTitle: values.LicensingSignupTitle,
        LicensingSignupContent: values.LicensingSignupContent,
        RegistrationCodeEnabled: values.RegistrationCodeEnabled,
        RegistrationCodes: values.RegistrationCodes,
        RegistrationPermissionSets: values.RegistrationPermissionSets,
        RegistrationTeamId: values.RegistrationTeamId,
        ShowAppTaxonomies: values.ShowAppTaxonomies,
        ShowRegistration: values.ShowRegistration,
        Status: values.Status,
        Title: values.Title,
        SupportedLanguages: values.SupportedLanguages,
        DefaultLanguage: values.DefaultLanguage,
        UsernameNotRequired: values.UsernameNotRequired,
        CompanyDisabled: values.CompanyDisabled,
        PhoneNumberDisabled: values.PhoneNumberDisabled,
        OverriddenEmailFromAddress: values.OverriddenEmailFromAddress,
        OverriddenInviteEmailTemplate: values.OverriddenInviteEmailTemplate,
        OverriddenInviteEmailSubject: values.OverriddenInviteEmailSubject,
        OverriddenAccountClosedEmailTemplate: values.OverriddenAccountClosedEmailTemplate,
        OverriddenAccountClosedEmailSubject: values.OverriddenAccountClosedEmailSubject,
        OverriddenAccountReopenedEmailTemplate: values.OverriddenAccountReopenedEmailTemplate,
        OverriddenAccountReopenedEmailSubject: values.OverriddenAccountReopenedEmailSubject,
        OverriddenMasterEmailTemplate: values.OverriddenMasterEmailTemplate,
        SSODomains: values.SSODomainList?.map(d => d.Domain).join('|'),
      } as SiteSettings
    );
  }

  public override async storeAsync(record: Record): Promise<Record> {
    const settings = this.convert(record);
    await this.settingsService.storeSettingsAsync(settings);
    return record;
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {
    // If we dont have a site id (we should), look it up
    if (!this._siteId) {
      await this.getRecordByIdAsync(this._recordId);
    }

    // Update settings repo
    const change = recordPatch.getDelta();

    change.RegistrationCodes?.added?.forEach(element => {
      element.Expiration = EpochConverter.toJSON(element.Expiration);
    });

    if (change.RegistrationCodes) {
      Object.getOwnPropertyNames(change.RegistrationCodes.changes).forEach(name => {
        const element = change.RegistrationCodes.changes[name];
        if (element.Expiration) {
          element.Key = element.Id;
          element.Expiration = EpochConverter.toJSON(element.Expiration);
        }
      });
    }

    if (change.RegistrationPermissionSets) {
      // Move this if another field needs also
      const record = await this.getRecordByIdAsync(this._recordId);
      let formattedChange = (record['RegistrationPermissionSets'] as Array<any>) || [];
      formattedChange = formattedChange.filter(value => !change.RegistrationPermissionSets.removed.find(removed => removed.Value === value.Value));
      change.RegistrationPermissionSets.added.forEach(added => {
        formattedChange.push(added);
      });
      change.RegistrationPermissionSets = `[${formattedChange.map(value => `'${value.Value}'`).join(',')}]`;
    }

    try {
      await this.settingsService.saveAsync(this._siteId, change);
      // patch local view
      const settings = this.globalModelService.siteModel.settings.value;
      if (settings) {
        recordPatch.updateObject(settings, this);
        this.globalModelService.siteModel.settings.value = settings;
      }
    } catch (err) {
      this.globalModelService.globalModel.showErrorToasty({ message: $localize`Error saving site settings` });
    }

    return this.getRecordByIdAsync(this._recordId);
  }

  public override async eachRecord(callback: (record: Record) => any): Promise<void> {
  }

  public override get permissions() {
    return this.permissionsService.sitePermissions;
  }

  public override selectionListOptions(fieldId: string): Array<any> {
    switch (fieldId) {
      // Get localised options
      case 'RegistrationPermissionSets': return this.getPermissionSets();
      case 'RegistrationCodes_DefaultHomepageIdentifier': return this.homepagesService.getAll().map(hp => ({ Text: hp.Title, Value: hp.Identifier }));
      default: return null;
    }
  }


  private getPermissionSets() {
    const options = permissionSets.map(ps => ({ Text: ps.label, Value: ps.name, }))
      .map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));
    return options as Array<any>;
  }

  /** Converts the very badly formatted RegistrationPermissionSets value which appears to have been
   * incorrectly stringified.  Should fix API and simplify (or remove) this.
   */
  private convertPermissionSetValue(value: string) {
    return value?.replace(/(?:^\[)|(?:\]$)/g, '')          // remove leading and trailing brackets
      .split(',')                                 // comma separated strings
      .map(q => q.replace(/(?:^")|(?:"$)/g, ''))  // remove leading and trailing quotes from each
      .map(q => q.replace(/(?:^')|(?:'$)/g, ''))  // remove leading and trailing single quotes from each
      .map(s => ({ Value: s }));                  // map to selection field format
  }
}
