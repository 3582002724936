import { ChartData, ChartFieldType, Enums, logError, Report } from '@softools/softools-core';
import { ModelProperty } from '@softools/vertex';
import { ChartErrorException } from 'app/exceptions';
import { ChartEnums } from 'app/services/chart';
import { ChartConfigService, HighChartConfig } from 'app/services/chart/chart-config.service';
import { InjectService } from 'app/services/locator.service';
import { MatrixModel } from 'app/softoolsui.module/matrixreport.component/matrix-model';
import { AppModel } from '../app.model';
import { FilterModel } from '../filter.model';
import { Causes } from './busy.model';
import { OverviewReportModel } from './overview-report.model';
import { AccessorFactory } from './accessors';
import { combineLatest, from } from 'rxjs';
import { Application } from 'app/types/application';
import { debounceTime } from 'rxjs/operators';
import { PageModel } from '../page/page.model';

export class ChartReportModel extends OverviewReportModel {

  public readonly chartConfig = new ModelProperty<HighChartConfig>(this);

  public readonly chartData = new ModelProperty<ChartData>(this);

  public readonly matrixModel = new ModelProperty<MatrixModel>(this);

  public readonly chartError = new ModelProperty<ChartEnums.ChartErrors>(this, ChartEnums.ChartErrors.NoError);

  @InjectService(ChartConfigService)
  private readonly chartConfigService: ChartConfigService;

  @InjectService(AccessorFactory)
  private readonly accessorFactory: AccessorFactory;

  public constructor(public override appModel: AppModel, public override filterModel: FilterModel, public override pageModel: PageModel) {
    super(appModel, filterModel, pageModel);
    this.chartConfig.logChanges = 'CHART CONFIG';
    this.chartData.logChanges = 'CHART DATA';
    this.matrixModel.logChanges = 'MATRIX MODEL';
    this.chartError.logChanges = 'CHART ERROR';
  }

  protected override observeProperties() {
    super.observeProperties();

    this.subscribe(this.chartData.$, (chartData: ChartData) => {
      if (chartData) {
        this.setMatrix(chartData).catch(err => logError(err, 'setMatrix'));
      }
    });

    // Refresh chart on change but with a long delay as it's potentially slow
    this.subscribe(this.appModel.updatedRecord$.pipe(debounceTime(10 * 1000)), async () => {
      await this.reloadChart();
    });
  }

  protected override async filterChanged() {
    await this.reloadChart();
  }

  private async reloadChart() {
    try {
      if (this.accessor) {
        this.accessor.resetIndex();
        await this.accessor.getChartData();
        this.chartError.value = null;
      }
    } catch (error) {
      if (error instanceof ChartErrorException) {
        this.chartError.value = error.chartError;
      } else {
        // Only log unreported errors
        logError(error, 'chart config');
      }
    }
  }

  protected override async setAccessor(app: Application, report: Report) {

    this.accessor?.close();

    this.accessor = this.accessorFactory.createChartAccessor(app, this);

    if (this.accessor) {
      await this.accessor.initialise();

      this.busy.start(Causes.charting);

      this.chartError.value = ChartEnums.ChartErrors.NoError;

      // Set chart config - complete async as it can bes slow
      const configObs = from(
        this.chartConfigService.getSpecificHighchartConfig(
          report,
          this.appModel.appIdentifiers.value,
          this.filterModel.combinedFilter.value?.Filter,
          this.hierarchy.value
        ).catch(error => {
          if (error instanceof ChartErrorException) {
            this.chartError.value = error.chartError;
          } else {
            // Only log unreported errors
            logError(error, 'chart config');
          }
          return null;
        })
      );

      // combineLatest count/data and config into one subscription
      // This fixes the issue with angular CD 10576
      // This forces CD when the chart data, config and count are set.
      this.subscribe(combineLatest([this.accessor.count$, this.accessor.chartData$, configObs]),
        ([count, data, config]) => {
          if (count && data) {
            this.batch(() => {
              this.totalCount.value = count;
              this.chartData.value = data;
              this.chartConfig.value = config;
            });
          }
          this.busy.finish(Causes.charting);
        }
      );

      // Force update
      await this.filterChanged();
    }

  }

  private async setMatrix(chartData: ChartData) {

    switch (this.report.value.Chart.ChartType) {
      case Enums.ChartType.cumulativematrix:
      case Enums.ChartType.summaryseries:
        await this.loadCumulativeMatrixData(chartData);
        break;
      case Enums.ChartType.montecarlo:
        await this.loadMonteCarloMatrixData(chartData);
        break;
      default:
        this.matrixModel.value = null;
        break;
    }
  }

  private async loadCumulativeMatrixData(chartData: ChartData) {
    const selectedReport = this.report.value;
    const chart = selectedReport.Chart;
    const app = this.appModel.app.value;
    const model = new MatrixModel(chart, app);

    // Get axis fields which have their own configuration in cumulative/summary series
    // This is mainly needed to get the type of component to use for header fields
    model.xAxisId = chart.ColumnFieldIdentifier ?? chart.ChartFields.find(cf => cf.Type === ChartFieldType.Cumulative)?.BaseFieldIdentifier;
    model.xAxisField = app.getField(model.xAxisId);
    model.yAxisId = chart.SeriesNameFieldIdentifier;
    model.yAxisField = app.getField(model.yAxisId);
    model.isDetailNumeric = true;
    if (chartData.MatrixTableData) {
      model.importTableData(chartData.MatrixTableData, selectedReport.Chart.OrderColumnCategories, selectedReport.Chart.OrderColumnCategories);
    }

    // todo offline only?
    // model.calculateTotals();
    // this.store.dispatch(new fromReportActions.CalculateMatrixTotalsAction(model));

    this.matrixModel.value = model;
  }

  private async loadMonteCarloMatrixData(chartData: ChartData) {
    const selectedReport = this.report.value;
    const chart = selectedReport.Chart;
    const app = this.appModel.app.value;
    const model = new MatrixModel(chart, app);

    model.importTableData(chartData.MatrixTableData, selectedReport.Chart.OrderColumnCategories, selectedReport.Chart.OrderColumnCategories);

    // todo offline only?
    // model.calculateTotals();
    // this.store.dispatch(new fromReportActions.CalculateMatrixTotalsAction(model));

    this.matrixModel.value = model;
  }

}
