import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { contentPinnedStateAnimation } from 'app/_constants';
import { Record, FilePatch, FileAttachment, logError, ServerErrorMap } from '@softools/softools-core';
import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { Field } from '@softools/softools-core';
import { AppService } from 'app/services/app.service';
import { AppModel, AttachmentsModel, RecordModel } from 'app/mvc';
import { ReportPanelZoom } from 'app/workspace.module/types/report-panel-zoom';
import { CommentsModel } from 'app/mvc/comments-model';
import { ImagePersistService } from 'app/services/images/image-persist.service';
import { ComponentBase } from 'app/softoolsui.module';
import { PendingChangesModel } from 'app/mvc/common/pending.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-shared-footer',
  templateUrl: './shared-footer.component.html',
  styleUrls: ['./shared-footer.component.scss'],
  animations: [contentPinnedStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedFooterComponent extends ComponentBase implements OnInit, OnChanges {

  public isSynchronising$ = new BehaviorSubject(false);

  public get isSingletonApp() {
    const app = this.appService.application(this.appIdentifier);
    return app.IsSingletonApp || false;
  }

  public get hasCommentsAndAttachmentsWidget() {
    return this.record && !this.record._new && (this.appModel.enableAttachments.value || this.appModel.enableComments.value);
  }

  public parent: any;

  /** Current record (for single record) */
  @Input() record: Record;

  @Input() fields: Array<Field> = [];

  @Input() appModel: AppModel;
  @Input() appIdentifier: string;

  @Input() recordModel: RecordModel;

  @Input() commentsModel: CommentsModel;
  @Input() attachmentsModel: AttachmentsModel;

  @Input() workFileAttachment: FileAttachment;

  @Input() isOnline: boolean;

  @Input() showFooter = false;
  @Input() showValidation = true;

  @Input() showAside: string;

  /** Origin for attachments popup */
  @Input() attachmentsOrigin: CdkOverlayOrigin;

  @Input() showReportPaneSelector = false;
  @Input() zoomReportPane: ReportPanelZoom;

  // file attachments events
  @Output() patchFileAttachment = new EventEmitter<any>();
  @Output() previewAttachment = new EventEmitter<FileAttachment>();

  @Output() reportPaneSelectorChanged = new EventEmitter<ReportPanelZoom>();

  public validationPosition = [
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }
    )
  ];

  showValidationPopup = false;

  public numValidationErrors$ = new BehaviorSubject(0);

  constructor(
    private appService: AppService,
    public imagePersistService: ImagePersistService) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(this.imagePersistService.pendingChangesModel.$, (changes: PendingChangesModel) => {
      if (changes) {
        const synchronizing =
          changes.pendingChangesCount.value > 0 ||
          changes.pendingCreatesCount.value > 0 ||
          changes.pendingSaveImagesCount.value > 0 ||
          changes.pendingDeleteImagesCount.value > 0;

        this.isSynchronising$.next(synchronizing);
      }
    });

    // Track validation error count when on record page
    if (this.recordModel) {
      this.subscribe(this.recordModel.record.$, (record) => {
        if (record) {
          const valErrs = record._errs;
          const serverErrs = record._serverError;
          const num = (valErrs?.length ?? 0) + (serverErrs ? 1 : 0);
          this.numValidationErrors$.next(num);
        }
      });
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.appModel.app.value && this.recordModel?.record.value) {
      this.attachmentsModel.load(this.appModel, this.recordModel.recordId.value).catch(error => logError('Attachment model load error', error));
      this.commentsModel.load(this.appModel, this.attachmentsModel, this.recordModel.recordId.value).catch(error => logError('Comments model load error', error));
    }
  }

  // Comments event handlers
  public async clickCommentsToggle() {
    this.commentsModel.toggle();
  }

  public closeCommentsClicked() {
    this.commentsModel.close();
  }

  // File attachment event handlers
  public async clickAttachmentsToggle() {
    await this.attachmentsModel.load(this.appModel, this.recordModel.recordId.value);
    this.attachmentsModel.toggle();
  }

  public closeAttachmentsClicked() {
    this.attachmentsModel.close();
  }

  public previewAttachmentClicked(attachment: FileAttachment) {
    this.previewAttachment.emit(attachment);
  }

  public patchFileAttachmentClicked(filePatch: FilePatch) {
    this.patchFileAttachment.emit(filePatch);
  }

  public chartPanelZoomClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.reportPaneSelectorChanged.emit('zoom-chart');
  }

  public recordsPanelZoomClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.reportPaneSelectorChanged.emit('zoom-records');
  }
}
