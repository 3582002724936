import { Injectable } from '@angular/core';
import { AppIdentifier } from 'app/types/application';
import { StorageMode } from 'app/types/enums';
import { logError } from '@softools/softools-core';

/**
 * Specifies overridden app storage modes.
 *
 * App Identifiers are keys into the structure.
 *
 * If the value is string array, the mode is Selective and the
 * values are the ids of offline reports (toto maybe use a mode for them too?)
 *
 * If the value is absent or undefined the site default for the app should be used.
 */
interface AppStorageModes {
  [key: string]: StorageMode.Online | StorageMode.Offline | Array<string>;
}

/**
 * Service that manages the storage mode for apps and reports
 */
@Injectable({
  providedIn: 'root',
})
export class StorageModeService {
  protected modes: AppStorageModes;

  constructor() {
    try {
      // Migrate old data if present.   We can remove this  soon as the feature if not used much yet.
      this.migrateLegacySettings();

      const modesString = localStorage.getItem('StorageModes');
      if (modesString) {
        this.modes = JSON.parse(modesString);
      }
    } catch (error) {
      logError(error, 'Error parsing storage modes, using defaults');
      this.modes = {};
    }
  }

  public getAppStorageMode(identifier: AppIdentifier): StorageMode {
    const mode = this.modes[identifier];
    switch (mode) {
      case undefined:
        return undefined;
      case StorageMode.Online:
      case StorageMode.Offline:
        return mode;
      default:
        return StorageMode.Selective;
    }
  }

  public setAppStorageMode(identifier: AppIdentifier, mode: StorageMode): void {
    if (mode === undefined) {
      delete this.modes[identifier];
    } else if (mode === StorageMode.Selective) {
      // Store an empty selection set
      // todo will this cause problems losing identifiers?  Probably.
      this.modes[identifier] = [];
    } else {
      this.modes[identifier] = mode;
    }
  }

  public getAppOfflineReports(appIdentifier: AppIdentifier): Array<string> {
    const mode = this.modes[appIdentifier];
    if (Array.isArray(mode)) {
      return mode;
    }

    return null;
  }

  public getReportAvailableOffline(appIdentifier: AppIdentifier, reportIdentifier: string): boolean {
    const mode = this.modes[appIdentifier];
    if (Array.isArray(mode)) {
      return mode.includes(reportIdentifier);
    }

    return false;
  }


  public isOfflineReport(appIdentifier: AppIdentifier, reportIdentifier: string): boolean {
    const mode = this.getAppStorageMode(appIdentifier);
    switch (mode) {
      case StorageMode.Offline:
        return true;
      case StorageMode.Online:
        return false;
      case StorageMode.Selective:
        return this.getReportAvailableOffline(appIdentifier, reportIdentifier);
    }
  }

  /**
   *
   * @param appIdentifier
   * @param reportIdentifier
   * @param available
   */
  public setReportAvailableOffline(appIdentifier: AppIdentifier, reportIdentifier: string, available = true): boolean {
    const mode = this.modes[appIdentifier];
    if (Array.isArray(mode)) {
      const already = mode.includes(reportIdentifier);
      if (available !== already) {
        if (available) {
          mode.push(reportIdentifier);
        } else {
          this.modes[appIdentifier] = mode.filter((id) => id !== reportIdentifier);
        }

        return true;
      }
    } else {
      // should we throw if it's not in selective mode?  Force it in?
      // or just return false...?
    }

    return false;
  }

  public store(): void {
    localStorage.setItem('StorageModes', JSON.stringify(this.modes));
  }

  /** Migrate data held in old format. */
  private migrateLegacySettings() {
    this.modes = {};
    const legacyOn = localStorage.getItem('ForceOnlineApps');
    const legacyOff = localStorage.getItem('ForceOfflineApps');

    if (legacyOn || legacyOff) {
      if (legacyOn) {
        legacyOn.split('|').forEach((identifier) => {
          this.modes[identifier] = StorageMode.Online;
        });
        localStorage.removeItem('ForceOnlineApps');
      }

      if (legacyOff) {
        legacyOff.split('|').forEach((identifier) => {
          this.modes[identifier] = StorageMode.Offline;
        });
        localStorage.removeItem('ForceOfflineApps');
      }

      this.store();
    }
  }
}
