import { Component, Input, ChangeDetectionStrategy, ViewChild, Renderer2, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { SearchComponent } from '../search.component/search.component';
import { flyInflyOut } from 'app/_constants';
import { PermissionsService } from 'app/services/permissions.service';
import { NavigationService } from 'app/services/navigation.service';
import { logError } from '@softools/softools-core';
import { LaunchpadModel } from 'app/mvc/headers/launchpad.model';
import { ComponentBase } from '..';
import { Application } from 'app/types/application';

@Component({
  selector: 'app-launchpad',
  templateUrl: 'launchpad.component.html',
  styleUrls: ['./launchpad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInflyOut],
})
export class LaunchPadComponent extends ComponentBase implements OnChanges {
  @ViewChild('search') search: SearchComponent;

  @Input() launchpadModel: LaunchpadModel;

  constructor(
    private render2: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private permissions: PermissionsService,
    private navigationService: NavigationService
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    try {

    } catch (error) {
      logError(error, 'LaunchPadComponent change');
    }
  }

  public getAppUrlLink(app: Application): string {
    const lastReport = app.getLastAccessedReport();
    if (lastReport) {
      return this.navigationService.getReportUrl(app.Identifier, lastReport.Identifier, lastReport.Type);
    } else {
      return this.navigationService.getDefaultReportUrl(app.Identifier);
    }
  }

  public close() {
    this.launchpadModel.show.value = false;
  }

  public onSearchHandler(payload: { event: KeyboardEvent; value: string }): void {
    this.launchpadModel.search.value = payload.value;
  }

  public clearSearch(): void {
    this.launchpadModel.search.value = '';
  }

  public onSearchEnterHandler(): void {
    // Navigate to first app listed, if we have any search results
    const selectedApps = this.launchpadModel.apps.value;
    if (selectedApps.length > 0) {
      const app = selectedApps[0];
      this.router.navigate([this.navigationService.getDefaultReportUrl(app.Identifier)]).catch(error => logError(error, 'Failed to route to app home'));
    }
  }

  public onSearchEscHandler(): void {
    if (this.launchpadModel.search.value) {
      this.launchpadModel.search.value = '';
    } else {
      this.launchpadModel.show.value = false;
    }
  }

  public catCheckedHandler(cat: string, add: boolean) {
    if (add) {
      this.launchpadModel.checkedCategories.set(cat);
    } else {
      this.launchpadModel.checkedCategories.delete(cat);
    }
  }

  flyInOutEnd(event) {
    if (event.toState === 'in') {
      const shouldFocusOnSearch = this.breakpointObserver.isMatched([Breakpoints.Web, Breakpoints.Tablet]);
      if (shouldFocusOnSearch) {
        this.render2.selectRootElement(this.search.searchInput['nativeElement']).focus();
      }
    }
  }

  onBackdropClick() {
    this.launchpadModel.show.value = false;
  }

  toggleSettings() {
    this.launchpadModel.showSettings.toggle();
  }

  public get settingsAllowed() {
    return this.permissions.hasAnySettings;
  }

  /** Compare two strings with undefined values grouped at the end */
  private _compare(string1: string, string2: string): number {
    if (string1 === undefined) {
      return string2 === undefined ? 0 : 1;
    } else if (string2 === undefined) {
      return -1;
    }

    return (string1 || '').localeCompare(string2 || '');
  }
}
