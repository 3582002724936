import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Enums, Report, ChartField } from '@softools/softools-core';
import { AppService } from '../app.service';
import { ChartEnums } from './index';
import { DataLabelsOptions } from 'highcharts';
import { AppIdentifiers } from '../record/app-info';
import { isDateField } from 'app/_constants';
import { Application } from 'app/types/application';
import { ChartErrorException } from 'app/exceptions';
import { ReportClickThroughParams } from './chart-clickthrough.service';

export interface HighChartConfig extends Highcharts.Options {
  chartId: number;
  chartIdentifier: string;
  xAxis: Highcharts.XAxisOptions;
  yAxis: Array<Highcharts.YAxisOptions>;
  isHeatMap: boolean;
  plotOptions: PlotOptions;
  hierarchy: string;
}

export interface PlotOptions extends Highcharts.PlotOptions {
  clickEventParams?: ReportClickThroughParams;
}


@Injectable({
  providedIn: 'root'
})
export class ChartConfigService {

  constructor(
    private appService: AppService
  ) { }

  private getBaseHighChartChartConfig(reportChartConfig: Report, hierarchy: string) {
    const chartOptions = {} as HighChartConfig;

    chartOptions.chartId = reportChartConfig.Id;
    chartOptions.chartIdentifier = reportChartConfig.Identifier;

    chartOptions.chart = {} as Highcharts.ChartOptions;
    chartOptions.chart.renderTo = `chart_${reportChartConfig.Id}`;
    chartOptions.chart.zoomType = 'xy';
    chartOptions.chart.spacingBottom = 15;
    if (reportChartConfig.Chart.BackgroundImageUri) { chartOptions.chart.plotBackgroundImage = reportChartConfig.Chart.BackgroundImageUri; }

    chartOptions.credits = {} as Highcharts.CreditsOptions;
    chartOptions.credits.enabled = false;

    chartOptions.title = {} as Highcharts.TitleOptions;
    chartOptions.title.align = 'left';

    chartOptions.subtitle = {} as Highcharts.SubtitleOptions;
    chartOptions.subtitle.align = 'left';

    chartOptions.xAxis = {} as Highcharts.XAxisOptions;
    chartOptions.yAxis = new Array<Highcharts.YAxisOptions>();

    chartOptions.legend = {} as Highcharts.LegendOptions;
    chartOptions.legend.y = 0;

    chartOptions.plotOptions = {} as Highcharts.PlotOptions;

    chartOptions.tooltip = {} as Highcharts.TooltipOptions;

    chartOptions.isHeatMap = false;


    chartOptions.hierarchy = hierarchy;

    return chartOptions;
  }

  private getTwoAxisChartConfig(reportChartConfig: Report, hierarchy: string) {
    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);
    chartOptions.title.text = reportChartConfig.Title;
    if (reportChartConfig.SubTitle) { chartOptions.subtitle.text = reportChartConfig.SubTitle; }

    chartOptions.xAxis.title = {} as Highcharts.XAxisTitleOptions;
    if (reportChartConfig.Chart.XaxisMax != null) { chartOptions.xAxis.max = reportChartConfig.Chart.XaxisMax; }
    if (reportChartConfig.Chart.XaxisMin != null) { chartOptions.xAxis.min = reportChartConfig.Chart.XaxisMin; }
    if (reportChartConfig.Chart.XaxisReversed != null) { chartOptions.xAxis.reversed = reportChartConfig.Chart.XaxisReversed; }
    chartOptions.yAxis.push({ title: {} as Highcharts.YAxisTitleOptions } as Highcharts.YAxisOptions);
    if (reportChartConfig.Chart.YaxisMax != null) { chartOptions.yAxis[0].max = reportChartConfig.Chart.YaxisMax; }
    if (reportChartConfig.Chart.YaxisMin != null) { chartOptions.yAxis[0].min = reportChartConfig.Chart.YaxisMin; }
    if (reportChartConfig.Chart.YaxisReversed != null) { chartOptions.yAxis[0].reversed = reportChartConfig.Chart.YaxisReversed; }

    return chartOptions;
  }

  private getBasicChartConfig(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getTwoAxisChartConfig(reportChartConfig, hierarchy);
    const xField = (reportChartConfig.Chart.ChartFields).find((field) => field.Type === ChartEnums.ChartFieldType.X);
    const yField = (reportChartConfig.Chart.ChartFields).find((field) => field.Type === ChartEnums.ChartFieldType.Y);
    const labelField = (reportChartConfig.Chart.ChartFields).find((field) => field.Type === ChartEnums.ChartFieldType.Label);

    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);

    chartOptions.plotOptions.clickEventParams = this.getChartClickthrough(reportChartConfig, appIdentifiers, filter, xField, yField, labelField);
    chartOptions.plotOptions.series = { stacking: 'normal' };

    // Get the main series field - this is the x axis except for bar charts which are flipped
    const seriesField = reportChartConfig.Chart.ChartType === Enums.ChartType.bar ? yField : xField;
    if (seriesField?.Label) {
      chartOptions.xAxis.title.text = seriesField.Label;
    } else if (seriesField?.BaseFieldIdentifier) {
      chartOptions.xAxis.title.text = app.Fields.find(field => field.Identifier === seriesField.BaseFieldIdentifier)?.Label;
    }

    chartOptions.yAxis[0].title.text = $localize`Record Count`;
    chartOptions.yAxis[0].allowDecimals = false;

    chartOptions.legend.backgroundColor = '#FFFFFF';
    chartOptions.legend.reversed = true;

    return chartOptions;
  }

  private getChartClickthrough(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string,
    xField?: ChartField, yField?: ChartField, labelField?: ChartField, geographyField?: ChartField): ReportClickThroughParams {
    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);

    const reportParams: any = { appIdentifiers: appIdentifiers };
    reportParams.targetClickThroughReportIdentifier = reportChartConfig.TargetClickThroughReportId ? app.Reports.find(report => report.Id === reportChartConfig.TargetClickThroughReportId)?.Identifier : '';

    if (xField && xField.BaseFieldIdentifier) {
      reportParams.xFieldIdentifier = xField.BaseFieldIdentifier;
      reportParams.xFieldType = this.getBaseFieldTypeForClickthrough(xField, app);
    }
    if (yField && yField.BaseFieldIdentifier) {
      reportParams.yFieldIdentifier = yField.BaseFieldIdentifier;
      reportParams.yFieldType = this.getBaseFieldTypeForClickthrough(yField, app);
    }
    if (labelField && labelField.BaseFieldIdentifier) {
      reportParams.labelsFieldName = labelField.BaseFieldIdentifier;
      reportParams.labelsFieldType = this.getBaseFieldTypeForClickthrough(labelField, app);
    }
    if (geographyField && geographyField.BaseFieldIdentifier) {
      reportParams.geographyFieldName = geographyField.BaseFieldIdentifier;
      reportParams.geographyFieldType = this.getBaseFieldTypeForClickthrough(geographyField, app);
    }
    if (filter) {
      reportParams.BaseFilter = filter;
    }
    return reportParams;
  }

  private getBaseFieldTypeForClickthrough(chartField: ChartField, app: Application) {
    const baseField = app.getField(chartField.BaseFieldIdentifier);
    if (baseField) {
      return baseField.Type;
    } else {
      throw new ChartErrorException(ChartEnums.ChartErrors.FieldsDontExist);
    }
  }

  private getNetworkClickthrough(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, levelFields: ChartField[]): ReportClickThroughParams {
    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);

    const reportParams: any = { appIdentifiers: appIdentifiers };
    reportParams.targetClickThroughReportIdentifier = reportChartConfig.TargetClickThroughReportId ? app.Reports.find(report => report.Id === reportChartConfig.TargetClickThroughReportId).Identifier : '';

    if (filter) {
      reportParams.BaseFilter = filter;
    }


    reportParams.networkFields = new Array<{ fieldIdentifier: string, fieldType: number }>();
    if (levelFields?.length) {
      levelFields.sort((a, b) => a.Order - b.Order);
      levelFields.forEach(levelField => {
        reportParams.networkFields.push({
          fieldIdentifier: levelField.BaseFieldIdentifier,
          fieldType: app.Fields.find(field => field.Identifier === levelField.BaseFieldIdentifier).Type
        });
      });
    }

    return reportParams;
  }

  private getCumulativeChartClickthrough(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, columnFieldIdentifier: string, seriesFields: ChartField[]) {
    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);

    const reportParams: any = { appIdentifiers: appIdentifiers };
    reportParams.targetClickThroughReportIdentifier = reportChartConfig.TargetClickThroughReportId ? app.Reports.find(report => report.Id === reportChartConfig.TargetClickThroughReportId).Identifier : '';

    if (columnFieldIdentifier) {
      reportParams.columnFieldIdentifier = columnFieldIdentifier;
      reportParams.columnFieldType = app.getField(columnFieldIdentifier).Type;
    } else {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoColumnField);
    }

    reportParams.cumulativeFields = seriesFields.map(seriesField => {
      return {
        cumulativeFieldIdentifier: seriesField.BaseFieldIdentifier,
        cumulativeFieldType: app.getField(seriesField.BaseFieldIdentifier).Type,
        cumulativeCalculationType: seriesField.Calculation
      };
    });

    if (filter) {
      reportParams.BaseFilter = filter;
    }

    return reportParams;
  }

  public async getSpecificHighchartConfig(report: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy?: string, isDashboard?: boolean)
    : Promise<HighChartConfig> {

    if (!report?.Chart) {
      throw new ChartErrorException(ChartEnums.ChartErrors.ChartNotPresent);
    }
    if (!report.Chart.ChartFields) {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoChartFields);
    }
    if (appIdentifiers) {
      const chartType = report.Chart.ChartType;
      switch (chartType) {
        case Enums.ChartType.bar:
          return this.getBarChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.column:
          return this.getColumnChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.area:
          return this.getAreaChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.areaspline:
          return this.getAreaSplineChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.line:
          return this.getLineChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.spline:
          return this.getSplineChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.cumulativematrix:
          return this.getCumulativeMatrixChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.map:
          return this.getMapChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.bubble:
          return this.getBubbleChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.montecarlo:
          return this.getMonteCarloChartOptions(report, hierarchy);
        case Enums.ChartType.scatter:
          return this.getScatterChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.polar:
          return this.getPolarChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.pie:
          return this.getPieChartOptions(report, appIdentifiers, filter, hierarchy, isDashboard);
        case Enums.ChartType.gantt:
          return this.getGanttChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.gauge:
          return this.getGaugeChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.network:
          return this.getNetworkChartOptions(report, appIdentifiers, filter, hierarchy);
        case Enums.ChartType.summaryseries:
          return this.getSummarySeriesConfig(report, appIdentifiers, filter, hierarchy);
      }
    }

    return null;
  }

  private getSummarySeriesConfig(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);

    if (reportChartConfig.Chart.Height) { chartOptions.chart.height = reportChartConfig.Chart.Height; }
    if (reportChartConfig.Chart.ZoomType) {
      switch (reportChartConfig.Chart.ZoomType) {
        case ChartEnums.ZoomType.Xaxis:
          chartOptions.chart.zoomType = 'xy';
          break;
        case ChartEnums.ZoomType.Xaxis:
          chartOptions.chart.zoomType = 'x';
          break;
        case ChartEnums.ZoomType.Yaxis:
          chartOptions.chart.zoomType = 'y';
          break;
        default:
          chartOptions.chart.zoomType = 'xy';
      }
    }

    chartOptions.title.text = reportChartConfig.Title;
    if (reportChartConfig.SubTitle) { chartOptions.subtitle.text = reportChartConfig.SubTitle; }

    chartOptions.plotOptions.series = {
      marker: {
        enabled: reportChartConfig.Chart.ShowPoints
      }
    };

    chartOptions.plotOptions.area = {
      stacking: 'normal'
    };

    chartOptions.xAxis.labels = { enabled: true };

    if (reportChartConfig.Chart.LabelType || reportChartConfig.Chart.LabelType === ChartEnums.ChartLabelType.Horizontal) {
      switch (reportChartConfig.Chart.LabelType) {
        case ChartEnums.ChartLabelType.Horizontal:
          chartOptions.xAxis.labels.align = 'center';
          chartOptions.xAxis.labels.rotation = 0;
          break;
        case ChartEnums.ChartLabelType.Diagonal:
          chartOptions.xAxis.labels.align = 'left';
          chartOptions.xAxis.labels.rotation = 45;
          break;
        case ChartEnums.ChartLabelType.Vertical:
          chartOptions.xAxis.labels.align = 'left';
          chartOptions.xAxis.labels.rotation = 90;
          break;
        case ChartEnums.ChartLabelType.Hidden:
          chartOptions.xAxis.labels.enabled = false;
          break;
      }
    }

    chartOptions.yAxis.push({} as Highcharts.YAxisOptions);

    if (reportChartConfig.Chart.IncludeVariance) {
      chartOptions.yAxis.push({
        opposite: true,
        id: 'variance'
      } as Highcharts.YAxisOptions);
    }

    chartOptions.tooltip = { shared: true, useHTML: true };

    return chartOptions;
  }

  private getNetworkChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {

    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);

    delete chartOptions.xAxis;
    delete chartOptions.yAxis;

    chartOptions.chartId = reportChartConfig.Id;
    chartOptions.chartIdentifier = reportChartConfig.Identifier;

    chartOptions.chart.type = 'networkgraph';

    chartOptions.plotOptions.networkgraph = {
      keys: ['from', 'to'],
      layoutAlgorithm: {
        enableSimulation: true,
        friction: -0.9
      },
      dataLabels: {
        enabled: true,
        format: '{point.name}',
        linkFormat: ''
      }
    };

    const levelFields = reportChartConfig.Chart.ChartFields.filter(field => field.Type === ChartEnums.ChartFieldType.NetworkLevel);

    chartOptions.plotOptions.clickEventParams = this.getNetworkClickthrough(reportChartConfig, appIdentifiers, filter, levelFields);

    return chartOptions;
  }

  private getGaugeChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const arcLength = reportChartConfig.Chart.ArcLength ? reportChartConfig.Chart.ArcLength : 240;

    const settings = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);

    settings.title.text = reportChartConfig.Title;

    if (reportChartConfig.SubTitle) {
      settings.subtitle.text = reportChartConfig.SubTitle;
    }

    settings.chart.type = 'gauge';

    settings.pane = {
      startAngle: 0 - (arcLength / 2),
      endAngle: 0 + (arcLength / 2)
    };

    return settings;
  }

  private getPieChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string, isDashboard: boolean) {
    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);

    const xField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.X);
    const yField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Y);
    const labelField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Label);

    chartOptions.chart.type = 'pie';

    chartOptions.title.text = reportChartConfig.Title;

    if (reportChartConfig.SubTitle) {
      chartOptions.subtitle.text = reportChartConfig.SubTitle;
    }

    chartOptions.plotOptions.pie = {
      cursor: 'pointer',
    } as Highcharts.PlotPieOptions;

    chartOptions.plotOptions.clickEventParams = this.getChartClickthrough(reportChartConfig, appIdentifiers, filter, xField, yField, labelField);

    if (isDashboard
      || reportChartConfig.Chart.ShowInLegend
    ) {
      chartOptions.plotOptions.pie.showInLegend = true;
      chartOptions.plotOptions.pie.dataLabels = { enabled: false };
    }

    return chartOptions;
  }

  private getPolarChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);

    const xField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.X);
    const yField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Y);
    const labelField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Label);

    chartOptions.chart.type = 'line';
    chartOptions.chart.polar = true;

    if (reportChartConfig.Chart.Height != null) {
      chartOptions.chart.height = reportChartConfig.Chart.Height.toString();
    }

    chartOptions.title.text = reportChartConfig.Title;
    if (reportChartConfig.SubTitle) {
      chartOptions.subtitle.text = reportChartConfig.SubTitle;
    }

    chartOptions.pane = { size: '80%' };

    chartOptions.xAxis.tickmarkPlacement = 'on';
    chartOptions.xAxis.lineWidth = 0;

    chartOptions.yAxis.push({});
    chartOptions.yAxis[0].gridLineInterpolation = 'polygon';
    chartOptions.yAxis[0].lineWidth = 0;
    chartOptions.yAxis[0].min = 0;

    if (reportChartConfig.Chart.YaxisMin != null) {
      chartOptions.yAxis[0].min = reportChartConfig.Chart.YaxisMin;
    }

    if (reportChartConfig.Chart.YaxisMax != null) {
      chartOptions.yAxis[0].max = reportChartConfig.Chart.YaxisMax;
    }

    chartOptions.tooltip.shared = true;

    chartOptions.legend.align = 'right';
    chartOptions.legend.verticalAlign = 'top';
    chartOptions.legend.y = 100;
    chartOptions.legend.layout = 'vertical';

    chartOptions.plotOptions = {
      clickEventParams: this.getChartClickthrough(reportChartConfig, appIdentifiers, filter, xField, yField, labelField)
    } as Highcharts.PlotOptions;

    return chartOptions;
  }

  private getMonteCarloChartOptions(reportChartConfig: Report, hierarchy: string) {
    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);

    const monteCarloChartFields = reportChartConfig.Chart.ChartFields;

    if (monteCarloChartFields == null) {
      throw new Error('Invalid chart configuration - no X-axis fields');
    }

    chartOptions.chart.type = 'areaspline';
    chartOptions.chart.zoomType = 'x';

    chartOptions.title.text = reportChartConfig.Title;
    if (reportChartConfig.SubTitle) {
      chartOptions.subtitle.text = reportChartConfig.SubTitle;
    }

    chartOptions.legend = {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      x: 150,
      y: 150,
      floating: true,
      borderWidth: 1,
      backgroundColor: '#FFFFFF'
    };

    chartOptions.xAxis.title = { text: $localize`Value` };
    chartOptions.yAxis.push({});

    if (reportChartConfig.Chart.MonteCarloType && reportChartConfig.Chart.MonteCarloType === ChartEnums.MonteCarloType.SCurve) {
      chartOptions.yAxis[0].title = { text: $localize`Percentage` };
      chartOptions.yAxis[0].min = 0;
      chartOptions.yAxis[0].max = 100;
    } else if (reportChartConfig.Chart.MonteCarloType != null && reportChartConfig.Chart.MonteCarloType === ChartEnums.MonteCarloType.Bell) {
      chartOptions.yAxis[0].title = { text: $localize`Occurrences` };
    }

    chartOptions.tooltip = {
      formatterParams: {
        monteCarloType: reportChartConfig.Chart.MonteCarloType
      }
    } as Highcharts.TooltipOptions;

    const areasplinePlotOptions = {
      fillOpacity: 0.5,
      marker: { enabled: false, radius: 0 }
    } as Highcharts.PlotAreasplineOptions;

    chartOptions.plotOptions = {
      areaspline: areasplinePlotOptions
    };

    return chartOptions;
  }

  private getScatterChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);
    const xField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.X);
    if (xField) { xField.BaseField = app.getField(xField.BaseFieldIdentifier); }
    const yField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Y);
    if (yField) { yField.BaseField = app.getField(yField.BaseFieldIdentifier); }
    if (!yField || !xField) {
      throw new ChartErrorException(ChartEnums.ChartErrors.NoXorYField);
    }
    const labelField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Label);
    const chartOptions = this.getTwoAxisChartConfig(reportChartConfig, hierarchy);

    chartOptions.chart.type = 'scatter';

    if (reportChartConfig.Chart.Height != null) {
      chartOptions.chart.height = reportChartConfig.Chart.Height.toString();
    }

    chartOptions.xAxis.title.text = xField.Label;
    if (xField.BaseField && isDateField(xField.BaseField.Type)) {
      chartOptions.xAxis.type = 'datetime';
    }
    chartOptions.yAxis[0].title.text = yField.Label;
    if (yField.BaseField && isDateField(yField.BaseField.Type)) {
      chartOptions.yAxis[0].type = 'datetime';
    }

    if (reportChartConfig.Chart.XaxisMin != null) {
      chartOptions.xAxis.min = reportChartConfig.Chart.XaxisMin;
    }

    if (reportChartConfig.Chart.XaxisMax != null) {
      chartOptions.xAxis.max = reportChartConfig.Chart.XaxisMax;
    }

    if (reportChartConfig.Chart.YaxisMin != null) {
      chartOptions.yAxis[0].min = reportChartConfig.Chart.YaxisMin;
    }

    if (reportChartConfig.Chart.YaxisMax != null) {
      chartOptions.yAxis[0].max = reportChartConfig.Chart.YaxisMax;
    }

    chartOptions.legend.backgroundColor = '#FFFFFF';
    chartOptions.legend.reversed = true;
    chartOptions.legend.enabled = false;

    chartOptions.plotOptions.scatter = {
      marker: {
        radius: 5,
        lineWidth: 1,
        lineColor: '#FFFFFF',
        states: { hover: { enabled: false } }
      },
      states: {
        hover: {
          enabled: false
        }
      }
    } as Highcharts.PlotScatterOptions;

    chartOptions.plotOptions.clickEventParams = this.getChartClickthrough(reportChartConfig, appIdentifiers, filter, xField, yField);

    if (labelField != null) {
      chartOptions.plotOptions.scatter.dataLabels = { enabled: true };

      chartOptions.plotOptions.series = {
        dataLabels:
        {
          enabled: true,
          align: 'left',
          x: 0,
          y: 0
        }
      };
    }

    return chartOptions;
  }

  private getBubbleChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const app = this.appService.application(appIdentifiers.visibleAppIdentifier);

    const xField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.X);
    if (xField) { xField.BaseField = app.Fields.find(field => field.Identifier === xField.BaseFieldIdentifier); }

    const yField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Y);
    if (yField) { yField.BaseField = app.Fields.find(field => field.Identifier === yField.BaseFieldIdentifier); }

    const labelField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Label);
    if (labelField) { labelField.BaseField = app.Fields.find(field => field.Identifier === labelField.BaseFieldIdentifier); }

    const chartOptions = this.getTwoAxisChartConfig(reportChartConfig, hierarchy);

    chartOptions.chart.type = 'scatter';

    if (reportChartConfig.Chart.Height != null) {
      chartOptions.chart.height = reportChartConfig.Chart.Height.toString();
    }

    chartOptions.chartId = reportChartConfig.Id;
    chartOptions.chartIdentifier = reportChartConfig.Identifier;

    const zField = (reportChartConfig.Chart.ChartFields).find(f => f.Type === ChartEnums.ChartFieldType.Z);
    if (zField) { zField.BaseField = app.Fields.find(field => field.Identifier === zField.BaseFieldIdentifier); }

    chartOptions.xAxis.minPadding = 0.1;
    chartOptions.xAxis.maxPadding = 0.1;
    // TODO: if either x or y re missing the config is invalid for a bubble chart. Should this throw error.
    //        There is already a similar check in bubble-chart-data.service.ts

    // Set axis labels
    chartOptions.xAxis.title.text = xField?.Label ?? xField?.BaseField?.Label;
    chartOptions.yAxis[0].title.text = yField?.Label ?? yField?.BaseField?.Label;

    // Set tooltip labels
    const xLabel = xField?.BaseField?.Label ?? '';
    const yLabel = yField?.BaseField?.Label ?? '';
    const zLabel = zField?.BaseField?.Label ?? '';

    chartOptions.tooltip = {
      // @ts-ignore - TODO: Implement chart helper clickthrough functions here.
      formatterParams: {
        labelText: labelField ? app.Fields.find(field => field.Identifier === labelField.BaseFieldIdentifier).Label : null,
        xFieldLabel: xLabel,
        yFieldLabel: yLabel,
        zFieldLabel: zLabel
      }
    };

    chartOptions.legend.enabled = false;
    chartOptions.plotOptions.scatter = {
      marker: {
        radius: 5,
        states: {
          hover: { enabled: false, lineColor: 'rgb(100,100,100)' }
        }
      },
    };

    chartOptions.plotOptions.clickEventParams = this.getChartClickthrough(reportChartConfig, appIdentifiers, filter, xField, yField, labelField);

    const allowOverlap = reportChartConfig.Chart.AllowOverlap ?? false;
    chartOptions.plotOptions.scatter.dataLabels = { allowOverlap };

    if (labelField) {
      chartOptions.plotOptions.series = {
        dataLabels: {
          enabled: true,
          align: 'left',
          x: 0,
          y: 0
        }
      };
    }

    return chartOptions;
  }

  private getMapChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const geographyField = (reportChartConfig.Chart.ChartFields).find(field => field.Type === ChartEnums.ChartFieldType.Geography);

    const chartOptions = {
      chartId: reportChartConfig.Id,
      chartIdentifier: reportChartConfig.Identifier,
      title: {
        text: reportChartConfig.Title
      } as Highcharts.TitleOptions,
      subtitle: reportChartConfig.SubTitle ? { text: reportChartConfig.SubTitle } : {},
      credits: { enabled: false } as Highcharts.CreditsOptions,
      chart: {
        renderTo: `chart_${reportChartConfig.Id}`,
        type: 'map',
        spacingBottom: 20
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom'
        } as Highcharts.MapNavigationButtonOptions
      } as Highcharts.MapNavigationOptions,
      plotOptions: {
        clickEventParams: this.getChartClickthrough(reportChartConfig, appIdentifiers, filter, undefined, undefined, undefined, geographyField)
      } as Highcharts.PlotOptions
    } as HighChartConfig;

    chartOptions.hierarchy = hierarchy;

    chartOptions.isHeatMap = (reportChartConfig.Chart.ChartFields).every(o => o.Type !== ChartEnums.ChartFieldType.Series);

    if (reportChartConfig.Chart.Height) {
      chartOptions.chart.height = reportChartConfig.Chart.Height.toString();
    }

    const legendTitle = reportChartConfig.Chart.LegendTitle;
    if (legendTitle && legendTitle.length > 0) {
      chartOptions.legend = {
        enabled: true,
        title: {
          text: legendTitle,
          style: {
            fontWeight: 'normal'
          } as Highcharts.CSSObject
        } as Highcharts.LegendTitleOptions
      } as Highcharts.LegendOptions;
    }

    if (chartOptions.isHeatMap) {
      this.SetHeatmapConfigOptions(chartOptions, reportChartConfig);
    } else {
      this.SetSeriesConfigOptions(chartOptions);
    }

    switch (reportChartConfig.Chart.Map) {
      case ChartEnums.Map.Europe:
        chartOptions['mapKey'] = 'custom/europe';
        break;

      case ChartEnums.Map.UnitedKingdom:
        chartOptions['mapKey'] = 'countries/gb/gb-all';
        break;

      case ChartEnums.Map.World:
        chartOptions['mapKey'] = 'custom/world';
        break;
    }

    return chartOptions;
  }

  private SetHeatmapConfigOptions(chartOptions: HighChartConfig, reportChartConfig: Report) {
    const defaultColorAxis = { min: 0 } as Highcharts.ColorAxisOptions;
    if (!reportChartConfig.Chart.HeatmapColorAxisStops || reportChartConfig.Chart.HeatmapColorAxisStops.length === 0) {
      chartOptions.colorAxis = defaultColorAxis;
    } else {
      const heatmapColourAxisStops = (<string>reportChartConfig.Chart.HeatmapColorAxisStops).split(',');
      const numberOfColourAxisStops = heatmapColourAxisStops.length;
      if (numberOfColourAxisStops < 2) {
        chartOptions.colorAxis = defaultColorAxis;
      } else {
        const stops = new Array<[number, string]>();
        heatmapColourAxisStops.forEach((stopColour, index) => {
          const position = index / (numberOfColourAxisStops - 1);
          const positionRounded = parseFloat(position.toFixed(2));
          stops.push([positionRounded, stopColour]);
        });

        chartOptions.colorAxis = {
          min: 0,
          type: 'linear',
          stops: stops
        };
      }
    }
  }

  private SetSeriesConfigOptions(chartOptions: HighChartConfig) {
    if (chartOptions.legend != null) {
      chartOptions.legend.y = 0;
    } else {
      chartOptions.legend = {
        enabled: true,
        y: 0
      } as Highcharts.LegendOptions;
    }

    chartOptions.plotOptions.map = { allAreas: true };

    chartOptions.plotOptions.series = {
      joinBy: ['hc-key', 'code']
    };
    chartOptions.plotOptions.map.nullColor = 'rgba(0,0,0,0)'; // Must set null colour to transparent when showing allAreas with multiple series
    chartOptions.plotOptions.map.tooltip = {
      headerFormat: '',
      pointFormat: '{point.name}: <b>{series.name}</b>'
    };
    chartOptions.plotOptions.map.dataLabels = {
      enabled: true,
      color: '#FFFFFF',
      style: {
        fontWeight: 'bold'
      }
    };
  }

  private getCumulativeMatrixChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);
    const cumulativeChartFields = (reportChartConfig.Chart.ChartFields).filter((field) => field.ShowOnChart);

    if (reportChartConfig.Chart.Height) { chartOptions.chart.height = reportChartConfig.Chart.Height; }
    if (reportChartConfig.Chart.ZoomType) {
      switch (reportChartConfig.Chart.ZoomType) {
        case ChartEnums.ZoomType.Xaxis:
          chartOptions.chart.zoomType = 'xy';
          break;
        case ChartEnums.ZoomType.Xaxis:
          chartOptions.chart.zoomType = 'x';
          break;
        case ChartEnums.ZoomType.Yaxis:
          chartOptions.chart.zoomType = 'y';
          break;
        default:
          chartOptions.chart.zoomType = 'xy';
      }
    }

    chartOptions.title.text = reportChartConfig.Title;
    if (reportChartConfig.SubTitle) { chartOptions.subtitle.text = reportChartConfig.SubTitle; }

    const stackedCount = (reportChartConfig.Chart.ChartFields)
      .map((field) => field.SeriesType && field.SeriesType === ChartEnums.SeriesType.StackedColumn ? <number>1 : 0)
      .reduce((aggregate, value) => aggregate += value);

    // Inapp charts can't have clickthroughs
    if (!reportChartConfig.InAppDataSourceFieldIndentifier) {
      const clickEventParams = this.getCumulativeChartClickthrough(reportChartConfig, appIdentifiers, filter, reportChartConfig.Chart.ColumnFieldIdentifier, reportChartConfig.Chart.ChartFields);
      if (clickEventParams == null) {
        // Error message disptached in get clickthrough function
        return null;
      }
      chartOptions.plotOptions['clickEventParams'] = clickEventParams;
    }

    if (stackedCount) {
      chartOptions.plotOptions.column = {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          color: 'white',
          style: { textShadow: 'none' } as Highcharts.CSSObject
        } as Highcharts.DataLabelsOptions
      } as Highcharts.PlotColumnOptions;
    }

    if (reportChartConfig.Chart.ColumnFieldIdentifier) {
      const colField = this.appService.application(appIdentifiers.visibleAppIdentifier).Fields.find((field) => field.Identifier === reportChartConfig.Chart.ColumnFieldIdentifier);

      if (colField && isDateField(colField.Type)) {
        chartOptions.xAxis.type = 'datetime';
        chartOptions.xAxis.minRange = 1209600000;
        chartOptions.xAxis.dateTimeLabelFormats = { month: '%b. %y' };
      }
    }

    chartOptions.xAxis.labels = { enabled: true };

    if (reportChartConfig.Chart.LabelType || reportChartConfig.Chart.LabelType === ChartEnums.ChartLabelType.Horizontal) {
      switch (reportChartConfig.Chart.LabelType) {
        case ChartEnums.ChartLabelType.Horizontal:
          chartOptions.xAxis.labels.align = 'center';
          chartOptions.xAxis.labels.rotation = 0;
          break;
        case ChartEnums.ChartLabelType.Diagonal:
          chartOptions.xAxis.labels.align = 'left';
          chartOptions.xAxis.labels.rotation = 45;
          break;
        case ChartEnums.ChartLabelType.Vertical:
          chartOptions.xAxis.labels.align = 'left';
          chartOptions.xAxis.labels.rotation = 90;
          break;
        case ChartEnums.ChartLabelType.Hidden:
          chartOptions.xAxis.labels.enabled = false;
          break;
      }
    }

    chartOptions.yAxis.push({} as Highcharts.YAxisOptions);
    cumulativeChartFields.forEach(field => {
      const yIndex = reportChartConfig.Chart.SingleAxis ? 0 : (reportChartConfig.Chart.ChartFields).indexOf(field);
      if (yIndex > 0) {
        chartOptions.yAxis.push({} as Highcharts.YAxisOptions);
      }

      if (!reportChartConfig.Chart.SingleAxis) {
        if (reportChartConfig.Chart.YaxisMin) { chartOptions.yAxis[yIndex].min = reportChartConfig.Chart.YaxisMin; }
        if (reportChartConfig.Chart.YaxisMax) { chartOptions.yAxis[yIndex].max = reportChartConfig.Chart.YaxisMax; }

        chartOptions.yAxis[yIndex].title = { text: `${field.Label ?? ''} (${ChartEnums.Calculation[field.Calculation]})` } as Highcharts.YAxisTitleOptions;
        chartOptions.yAxis[yIndex].opposite = yIndex % 2 !== 0;
      }
    });

    if (reportChartConfig.Chart.SingleAxis) {
      chartOptions.yAxis[0].title = { text: '' };
    }

    chartOptions.tooltip = { shared: true, useHTML: true };

    return chartOptions;
  }

  private getSplineChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBasicChartConfig(reportChartConfig, appIdentifiers, filter, hierarchy);

    chartOptions.chart.type = 'spline';

    return chartOptions;
  }
  private getLineChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBasicChartConfig(reportChartConfig, appIdentifiers, filter, hierarchy);

    chartOptions.chart.type = 'line';

    return chartOptions;
  }

  private getColumnChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBasicChartConfig(reportChartConfig, appIdentifiers, filter, hierarchy);

    chartOptions.chart.type = 'column';
    chartOptions.legend.enabled = true;

    return chartOptions;
  }

  private getBarChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBasicChartConfig(reportChartConfig, appIdentifiers, filter, hierarchy);

    chartOptions.chart.type = 'bar';
    chartOptions.legend.enabled = true;

    if (reportChartConfig.Chart.Height) { chartOptions.chart.height = reportChartConfig.Chart.Height; }

    return chartOptions;
  }

  private getAreaChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBasicChartConfig(reportChartConfig, appIdentifiers, filter, hierarchy);

    chartOptions.chart.type = 'area';

    return chartOptions;
  }

  private getAreaSplineChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const chartOptions = this.getBasicChartConfig(reportChartConfig, appIdentifiers, filter, hierarchy);

    chartOptions.chart.type = 'areaspline';

    return chartOptions;
  }

  private getGanttChartOptions(reportChartConfig: Report, appIdentifiers: AppIdentifiers, filter: string, hierarchy: string) {
    const settings = this.getBaseHighChartChartConfig(reportChartConfig, hierarchy);
    settings.chart.type = 'bar';
    settings.legend.enabled = true;
    settings.title.text = reportChartConfig.Title;

    if (reportChartConfig.SubTitle) {
      settings.subtitle.text = reportChartConfig.SubTitle;
    }

    if (reportChartConfig.Chart.Height != null) {
      settings.chart.height = reportChartConfig.Chart.Height.toString();
    }

    settings.yAxis.push(
      {
        type: 'datetime',
        title: { text: 'Date' },
        dateTimeLabelFormats: { day: '%e of %b' },
        labels: { rotation: -45, align: 'right' }
      });

    settings.plotOptions = {
      clickEventParams: this.getChartClickthrough(reportChartConfig, appIdentifiers, filter)
    } as Highcharts.PlotOptions;

    return settings;
  }
}
