import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation } from 'app/_constants';
import { WSReportBase } from '../../ws-base/ws-reportbase';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlResolver, OnlineStatusService, logError, LastListReportStorageService, ReportEditMode } from '@softools/softools-core';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { TableReportModel } from 'app/mvc';
import { RouteParams } from 'app/mvc/global.model';
import { FilterSpecification, ReportFilter } from 'app/filters/types';
import { SelectionMode } from 'app/softoolsui.module/table-report/table-report-types';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { filterMgtAnimation, quickFiltersAnimation } from 'app/workspace.module/animations/ws-animations';
import { TableReportController } from 'app/softoolsui.module/table-report/table-report-controller';
import { ReportController } from 'app/mvc/reports/report.controller';

@Component({
  selector: 'app-ws-table-report',
  templateUrl: './ws-table-report.component.html',
  styleUrls: ['./ws-table-report.component.scss'],
  animations: [
    contentPinnedStateAnimation,
    rightbarToggleContentStateAnimation,
    leftAsideStateAnimation,
    quickFiltersAnimation,
    filterMgtAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsTableReportComponent extends WSReportBase implements OnInit, OnDestroy {

  public tableReportModel: TableReportModel;

  public tableReportController: TableReportController;

  constructor(
    router: Router,
    navigationService: NavigationService,
    indexedDbService: IndexedDbService,
    appService: AppService,
    urlresolver: UrlResolver,
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    permissionsService: PermissionsService,
    private lastListReportStorageService: LastListReportStorageService,
    models: GlobalModelService,
  ) {
    super(indexedDbService, appService, router, urlresolver, onlineStatus, route, navigationService, permissionsService, models);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscribe(this.reportModel.report.$, (report) => {
      this.reportModel.selectionMode.value = report?.AllowMultiSelect ? SelectionMode.Mulitple : SelectionMode.None;
    });
  }

  protected override async routed(params: RouteParams) {
    await super.routed(params);
    // record as last used
    // we're not a list report but the way we use it to decide how to index records
    // suggests we should store table reports too
    await this.lastListReportStorageService.storeLastRecordsReportAsync(params.appIdentifiers.visibleAppIdentifier, params.reportIdentifier);

    this.processRouteParams();
  }

  protected override createReportModel() {
    this.tableReportModel = new TableReportModel(this.appModel, this.filterModel, this.models.pageModel);
    return this.tableReportModel;
  }

  protected override createReportController(): ReportController {
    this.tableReportController = new TableReportController(this.tableReportModel);
    return this.tableReportController;
  }

  public override filterEditCancelled(): void {
    this.reportModel.closeFilterEditor();
  }

  public override filterTermUpdated(updates: FilterTermUpdates) {
    this.reportModel.closeFilterEditor();

    const reportFilter = new ReportFilter(this.filterModel.reportFilter.value);
    const spec = new FilterSpecification(reportFilter, this.appModel.app.value.AppFields);
    const updated = spec.updateTerm(updates);
    this.filterModel.updateCurrentFilter(updated).catch(error => logError(error, 'Failed to update current filter'));
  }

  public tableSelectionMode(): SelectionMode {
    // Get selection mode.  Either multiple or none as AllowMultiSelect is really a
    // allow selection flag
    return this.reportModel.report.value?.AllowMultiSelect ? SelectionMode.Mulitple : SelectionMode.None;
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tableReportModel?.dispose();

    this.tableReportModel = null;
  }
}
