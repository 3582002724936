<div  class="g-box h-100 pb-2"
  #box
  [ngClass]="orientationClass$|async"
  [vxWatch]="[
    cardModel.showGroupHeaders,
    cardModel.zoom,
    cardModel.zoomTransform ]"
>

  <ng-container *ngIf="cardModel.showGroupHeaders.value">

    <!-- Group header -->
    <div
      [vxWatch]="[cardModel.groupField, appModel?.globalModel.online]"
      class="g-header group-header-card p-2 rounded"
      [class.w-100]="orientation !== 0"
      [ngStyle]="headerStyle?.component?.css"
      [style.top.px]="0"
      [style.left.px]="0"
      [style.width.px]="groupColumnWidth - marginPx"
      [style.height.px]="headerHeight * (cardModel.zoom.value)"
    >

      <!-- Group label -->
      <div class="header-label"
        [style.height.px]="headerLabelHeight * (cardModel.zoom.value)"
      >
        <small class="group-field-name"
          [ngStyle]="cardModel.zoomTransform.value"
        >{{cardModel.groupField.value?.Label}}</small>
      </div>
      <app-card-header-app-field
        class="w-100 header-value"
        [ngStyle]="cardModel.zoomTransform.value"
        [elementStyles]="headerStyle"
        [style.height.px]="headerValueHeight * (cardModel.zoom.value)"
        [appModel]="appModel"
        [value]="column.starts?.value"
        [fieldModel]="cardModel.groupField.value"
        [forReport]="false"
        [inline]="false"
        [alignmentOverride]="'center'"
        [isOnline]="appModel?.globalModel.online.value"
      >
      </app-card-header-app-field>
    </div>
  </ng-container>

  <!-- Scrolling card box -->
  <div #content class="g-cards h-100 w-100"
    [ngClass]="orientationClass$|async"
    [style.left.px]="0"
    (scroll)="scrolled($event)"
    >

    <ng-container *ngIf="(busy.busyDelayed$|async)"  >
      <div class="busy-box w-100 h-100">
        <app-busy-indicator
          [busyModel]="busy" >
        </app-busy-indicator>
      </div>
  </ng-container>

    <div
      [class.w-100]="orientation === 0"
      [class.h-100]="orientation !== 0"
      [style.height.px]="scrollableHeight$|async"
      [style.width.px]="scrollableWidth$|async"
    >
      <div *ngFor="let card of visibleCards$|async; trackBy: trackCard"
        style="position: absolute;"
        [style.top.px]="groupCardY(card.row)"
        [style.left.px]="groupCardX(card.row)"
        [style.height.px]="rowHeight * (cardModel.zoom.value)"
      >
        <div>
          <div
            [vxWatch]="[cardModel.card, cardModel.groupField]"
            class="card-box"
            [style.left.px]="(marginPx/2) * (cardModel.zoom.value)"
            [style.height.px]="rowHeight * (cardModel.zoom.value)"
            [ngStyle]="cardModel.zoomTransform.value"
            >
            <app-card
              [appModel]="appModel"
              [generalController]="generalController"
              [card]="cardModel.card.value"
              [templateIndex]="getCardTemplateIndex(card.record)"
              [record]="card.record"
              [groupField]="cardModel.groupField.value"
              [canDrag]="cardModel.showGroupHeaders.value"
              (cardDragging)="cardDragged($event)"
              (cardClicked)="cardClicked(card.record)"
              (templateIndexChanged)="cardtemplateIndexChanged($event, card.record)"
              >
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Invisible drop target overlays deck when dragging cards -->
<div *ngIf="droppable$|async"
  class="drop-target"
  [style.top.px]="boxElement?.nativeElement.clientTop"
  [style.left.px]="boxElement?.nativeElement.clientLeft"
  [style.width.px]="boxElement?.nativeElement.clientWidth"
  [style.height.px]="boxElement?.nativeElement.clientHeight"
  (dragover)="draggedOver($event)"
  (dragenter)="dragEnter($event)"
  (dragleave)="dragLeave($event)"
  (drop)="droppped($event)"
>
</div>
