
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ACCESS_TOKEN_KEY } from 'app/_constants/constants.keys';

@Injectable({ providedIn: 'root' })
export class AuthorisationService {

  public isValidAuthToken(ms = 0): boolean {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      const expiry = localStorage.getItem('access_token_expires');
      if (expiry) {
        const now = moment().utc();
        const expiresAt = moment(expiry).utc();
        return (now.diff(expiresAt) < ms);
      }
    }

    // Missing token so invalid
    return false;
  }
}
