
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { RepositoryBase } from './repositories.base';
import { SiteLoginDataResource, SiteGlobalBasicDataResource } from '../types';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class SiteRepository extends RepositoryBase {

  public getSiteGlobalBasicData(): Observable<SiteGlobalBasicDataResource> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('Site/GetSiteGlobalBasicData'));
  }

  public getSiteLoginDataResource(tenant: string): Observable<SiteLoginDataResource> {
    const url = this.urlResolver.resolveDefaultApiUrl('Site', `GetSiteLoginDataResource?tenant=${tenant}`);
    return this.get(url);
  }

  public async getLatestSiteVersionNumber(): Promise<number> {
    const url = `${environment.baseUrl}/api/latestsiteversionnumber`;
    return this.get(url).pipe(first()).toPromise();
  }

}
