import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { GlobalHelperService } from '@softools/softools-core';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule],
  exports: [CheckboxComponent],
  providers: [GlobalHelperService],
})
export class CheckboxModule { }
