import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Enums, EpochDate, IFormatting } from '@softools/softools-core';
import { FieldBase } from 'app/softoolsui.module/fields';
import { UsersService } from 'app/services/users.service';
import { AppField } from 'app/types/fields/app-field';

@Component({
  selector: 'app-text-display-field',
  templateUrl: './text-display-field.component.html',
  styleUrls: ['./text-display-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDisplayFieldComponent<TAppField extends AppField = AppField>
  extends FieldBase<string, TAppField> implements OnInit, OnChanges {
  @ViewChild('display', { static: true })
  divElement: ElementRef<HTMLDivElement>;

  public textStyle: {};

  public formatting?: IFormatting;

  public minWidthRem: number = null;

  constructor(private usersService: UsersService) {
    super();
  }

  override ngOnInit() {
    this._modifyField(this.divElement.nativeElement);
    this.textStyle = { ...this.elementStyles?.['text']?.css ?? {}, ...this.elementStyles?.component?.css ?? {} };
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] || changes['record'] || changes['listRow']) {
      this._modifyField(this.divElement.nativeElement);
    }
  }

  public override async activate() {
    this.divElement.nativeElement.focus();
    this._modifyField(this.divElement.nativeElement);
  }

  public useReadonlyStyle() {
    return !this.forReport && this.fieldModel?.Type !== Enums.FieldType.Literal;
  }

  private _modifyField(element: HTMLDivElement) {
    switch (this.fieldModel.Type) {
      case Enums.FieldType.InAppChart:
        element.innerHTML = '<b>[Chart]</b>';
        break;
      case Enums.FieldType.Literal:
        element.innerHTML = this.getDefaultValue() || '';
        element.classList.add('pre');
        break;
      case Enums.FieldType.LongText:
        element.textContent = this.getFieldValue();
        element.classList.add('pre');
        break;
      default:
        element.textContent = this.getFieldValue();
        break;
    }

    switch (this.fieldModel.Type) {
      case Enums.FieldType.Selection:
      case Enums.FieldType.Text:
      case Enums.FieldType.Email:
      case Enums.FieldType.Notes:
      case Enums.FieldType.UrlField: {
        const value = this.getFieldValue();
        element.title = value && value.toString();
        break;
      }
      case Enums.FieldType.LongText: {
        if (this.forReport) {
          const value = this.getFieldValue();
          element.title = value && value.toString();
        }
      }
    }
  }

  /**
   * Get the current record value for the field
   */
  private getFieldValue(): any {

    // Some fields not supported in text display (and shoudln't be requested)
    switch (this.fieldModel.Type) {
      case Enums.FieldType.Image:
      case Enums.FieldType.InAppChart:
      case Enums.FieldType.Gantt:
      case Enums.FieldType.EmbeddedVideo:
      case Enums.FieldType.ListField:
      case Enums.FieldType.GridField:
      case Enums.FieldType.Lookup:
        return 'Not Supported';
    }

    let value: any;

    if (this.record) {
      // todo formatting?
      value = this.fieldModel.getDisplayRecordValue(this.record, this.listRow);
    } else {
      value = this.fieldModel.formatDisplayValue(this.value, this.formatting);
    }

    if (value === undefined && this.fieldModel.DefaultValue && !this.fieldModel.DefaultValueIsExpression) {
      value = this.fieldModel.DefaultValue;
    }

    if (value && typeof value === 'object' && Object.keys(value).length === 0) {
      return '';
    }

    return value;
  }

  protected getDefaultValue(): string {
    return this.fieldModel.DefaultValue;
  }

  public async onClick($event: MouseEvent) {
    // Fields that are clickable
    switch (this.fieldModel.Type) {
      case Enums.FieldType.CommentsCount:
        $event.stopPropagation();
        await this.commentsModel.load(this.appModel, this.attachmentsModel, this.record._id);
        this.commentsModel.open();
        break;
      case Enums.FieldType.AttachmentsCount:
        $event.stopPropagation();
        await this.attachmentsModel.load(this.appModel, this.record._id);
        this.attachmentsModel.open();
        break;
      default:
        if (this.fieldModel.clickToEdit) {
          $event.stopPropagation();
          this.requestActivation$.next(this);
        }
        break;
    }
  }

  public textHeight() {
    if (!this.forReport &&
      this.fieldModel?.Type === Enums.FieldType.LongText &&
      this.fieldModel?.Rows) {
      return `${this.fieldModel.Rows}rem`;
    }

    return 'auto';
  }
}
