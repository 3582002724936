import { Injectable } from '@angular/core';
import { Application } from 'app/types/application';
import { Report } from '@softools/softools-core';
import { BehaviorSubject } from 'rxjs';

/**
 * Container for long-lived application state.
 *
 * This should only be used for state that changes infrequently, typically during
 * navigation.
 */
@Injectable({ providedIn: 'root' })
export class AppContextService {

  private app: Application<any>;

  private childApp: Application<any>;

  private selectedReport: Report;

  private selectedReportIdentifier: string;

  private showArchived: boolean;

  public showArchived$: BehaviorSubject<boolean>;

  constructor() {
    this.showArchived = localStorage.getItem('showArchived') === 'true';
    this.showArchived$ = new BehaviorSubject(this.showArchived);
  }

  public set SelectedApplication(app: Application) {
    this.app = app;
    this.childApp = null;
    this.selectedReport = null;   // reset report when app changed
  }

  public get SelectedApplication() {
    return this.app;
  }

  public set SelectedChildApplication(app: Application) {
    this.childApp = app;
    this.selectedReport = null;   // reset report when app changed
  }

  public get SelectedChildApplication() {
    return this.childApp;
  }

  public get SelectedReport() {
    return this.selectedReport;
  }

  public set SelectedReport(report: Report) {
    this.selectedReport = report;
    this.selectedReportIdentifier = report && report.Identifier;
  }

  public set SelectedReportIdentifier(val: string) {
    this.selectedReportIdentifier = val;
    this.selectedReport = this.app.Reports.find(r => r.Identifier === this.selectedReportIdentifier);
  }

  public get SelectedReportIdentifier() {
    return this.selectedReportIdentifier;
  }

  public get SelectedReportType() {
    return this.selectedReport && this.selectedReport.Type;
  }

  public get ShowArchived() {
    return this.showArchived;
  }

  public set ShowArchived(show: boolean) {
    if (show !== this.showArchived) {
      this.showArchived = show;
      if (show) {
        localStorage.setItem('showArchived', 'true');
      } else {
        localStorage.removeItem('showArchived');
      }

      this.showArchived$.next(show);
    }
  }
}
