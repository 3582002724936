import { Injectable } from '@angular/core';
import { ImageListsStorageService as ImageListsStorageService, ImageList } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { RefreshType } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class ImageListsService {

  private imageListsById: Map<string, ImageList>;
  private imageListsByIdentifier: Map<string, ImageList>;
  private imageLists: Array<ImageList>;

  constructor(
    private imageListStorageService: ImageListsStorageService,
    private models: GlobalModelService) {
  }

  /**
   * Refresh image list data held by this service.  The image lists held in storage are loaded and indexed,
   * and optionally reloaded from the server if needed and/or possible.
   * This is be called by a guard in an async context so that code can access the collections
   * efficiently and synchronously.  Normal code should not need to call @see refresh directly.
   *
   * @param refreshType   Controls whether data is loaded from the server.
   *  'init' loads if no image lists found, 'force' always updates from server provided we are online.
   */
  public async refresh(refreshType?: RefreshType): Promise<Array<ImageList>> {

    let imageLists = await this.imageListStorageService.getAll();

    // Load from server if needed
    if ((refreshType === 'init' && (!imageLists || imageLists.length === 0)) || refreshType === 'force') {
      if (this.models.globalModel.online.value) {
        imageLists = await this.imageListStorageService.refreshAsync();
      }
    }

    this.mapSelectLists(imageLists);
    return this.imageLists;
  }

  public get(identifier: string) {
    this._checkIsLoaded();
    return this.imageListsByIdentifier.get(identifier);
  }

  public getById(identifier: string): ImageList {
    this._checkIsLoaded();
    return this.imageListsById.get(identifier);
  }

  public getAll(): Array<ImageList> {
    this._checkIsLoaded();
    return this.imageLists;
  }

  public async storeAsync(list: ImageList): Promise<ImageList> {
    return this.imageListStorageService.storeAsync(list);
  }

  private mapSelectLists(imageLists: Array<ImageList>) {
    const identifierMap = new Map<string, ImageList>();
    const idMap = new Map<string, ImageList>();

    imageLists.forEach(imageList => {
      identifierMap.set(imageList.Identifier, imageList);
      idMap.set(imageList.Id, imageList);
    });

    // Update atomically in case we're called more than once
    this.imageLists = imageLists;
    this.imageListsByIdentifier = identifierMap;
    this.imageListsById = idMap;
  }

  private _checkIsLoaded(): void {
    if (!this.imageLists) {
      throw new Error('Image Lists not loaded.   Use ImageListsExistsGuard to prime service');
    }
  }
}
