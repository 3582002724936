import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusyIndicatorComponent } from './busy-indicator.component';
import { SofSpinnerModule } from '@softools/design/icons';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';

@NgModule({
  declarations: [BusyIndicatorComponent],
  imports: [CommonModule, SofSpinnerModule, MvcDirectivesModule],
  exports: [BusyIndicatorComponent],
})
export class BusyIndicatorModule {}
