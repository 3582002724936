import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { Template } from '@softools/softools-core';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTemplateComponent {

  /** The form record */
  @Input() record = {};

  /** The form template metadata.  */
  @Input() formTemplate: Template;

  /** Whether the template header is hidden. The default is true. */
  @Input() showTemplateHeader = true;

  /** Whether the form template is expanded */
  @Input() expanded = false;

  /** Whether the form template help is expanded */
  @Input() expandedHelp = false;

  /** The link to the template in the old platform */
  @Input() viewTemplateInOldPlatformLink = '';

  /** Event for form template toggle */
  @Output() onToggleExpandedFormTemplate = new EventEmitter();
  @Output() onToggleExpandedFormTemplateHelp = new EventEmitter();

  constructor() { }
}
