import { Component, ViewChild, ElementRef, HostListener, OnDestroy, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { Enums, logError } from '@softools/softools-core';
import { DrawCanvasComponent } from './draw-canvas/draw-canvas.component';

@Component({
  selector: 'app-draw-field',
  templateUrl: './draw-field.component.html',
  styleUrls: ['./draw-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawFieldComponent extends EditableFieldBase implements OnInit, OnChanges {

  public isDirty;

  /**
   * Switch between canvas mode (drawing allowed) and image mode (just displays).
   * The component is in canvas mode if there is no value, allowing a new signature/drawing
   * to be input.  When it has a value it goes into image display mode and cannot be edited
   * until the value is cleared with the trash button.
   */
  public canvasMode = false;

  @Input() freehandDrawingCanvasSize: Enums.FreehandDrawingCanvasSize;

  @ViewChild('imageEl') _imageEl: ElementRef;
  @ViewChild('canvasContainerEl') _canvasContainerEl: ElementRef;

  @ViewChild(DrawCanvasComponent) drawCanvas: DrawCanvasComponent;

  @HostListener('window:resize') onResize = (): void => {
    if (this.canvasMode) {
      this.resizeCanvas();
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (this.value !== undefined && !this.isDirty) {
      this._load();
      this._setImgSrc();
    }

    if (changes['fieldModel']) {
      this.resetCanvas();
    }
  }

  private resetCanvas() {
    this.isDirty = false;
    this.canvasMode = true;
    this.drawCanvas?.clear();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.recordModel) {
      this.subscribe(this.recordModel?.expandedFormTemplates.$, (expanded) => {
        if (expanded) {
          setTimeout(() => {
            this.resizeCanvas();
          }, 0);
        }
      });
    }

    // If we are editing AND no value then default to showing the canvas
    this.canvasMode = this.editMode && !this.isDisabled() && (!this.value || this.value.length === 0);
  }

  protected override onValueChanged(value: string) {
    super.onValueChanged(value);
    // Overwrite if we've not started editing
    if (!this.isDirty) {
      this.drawCanvas?.setData(value);

      if (this.editMode && !this.isDisabled() && value) {
        this._load();
      } else {
        this.resetCanvas();
      }
    }
  }

  public onAcceptClickHandler($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();

    // Set local value to avoid flicker
    this.value = this.drawCanvas.getData();

    // Emit the update
    this.dispatchChangeAsync(this.value).catch((error) => logError(error, 'Failed to dispatch onAcceptClickHandler'));

    // Load either image if we have one or default to the canvas
    this.canvasMode = !this.value || this.value.length === 0;

    this._load();

    this.isDirty = false;
  }

  public onDeleteClickHandler($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.canvasMode) {
      // Just clear the canvas
      this.drawCanvas.clear();
    } else {
      // Delete png image data url
      this.value = '';
      this.dispatchChangeAsync(this.value).catch(error => logError(error, 'Failed in dispatchChangeAsync'));
      // Now show canvas
      this.canvasMode = true;
      this._load();
    }
  }

  private _load(): any {
    if (this.canvasMode) {
      this.loadCanvas();
    } else {
      this._setImgSrc();
    }
  }

  private _setImgSrc(): void {
    // In timeout otherwise image el not in dom yet
    setTimeout(() => {
      if (this.value && this._imageEl) {
        this._imageEl.nativeElement.src = this.value;
        this._imageEl.nativeElement.className = this._imageEl.nativeElement.className.replace('image-el', '');
      }
    }, 0);
  }

  public loadCanvas(): void {
    if (!this.canvasMode) {
      return;
    }
    // In timeout otherwise height not respected OR canvas el not in dom yet
    setTimeout(() => {
      this.drawCanvas?.setData(this.value);
      this.resizeCanvas();
    }, 0);
  }

  public resizeCanvas(): void {
    if (this.drawCanvas) {
      // To correctly handle canvas on low and high DPI screens
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const newWidth = this._canvasContainerEl.nativeElement.offsetWidth * ratio;
      const newHeight = this._getHeight(newWidth);
      this.drawCanvas.setSize(newWidth, newHeight, ratio);
    }
  }

  private _getHeight(newWidth: number) {
    switch (this.freehandDrawingCanvasSize) {
      case Enums.FreehandDrawingCanvasSize.Small:
        return 100;
      case Enums.FreehandDrawingCanvasSize.Medium:
        return 200;
      case Enums.FreehandDrawingCanvasSize.Large:
        return 400;
      case Enums.FreehandDrawingCanvasSize.Full:
        return newWidth;
      default:
        return 100;
    }
  }

}
