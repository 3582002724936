import { Input, Output, EventEmitter, ViewChild, ElementRef, Directive } from '@angular/core';
import { FileAttachment, Comment } from '@softools/softools-core';
import { AttachmentsModel, RecordModel } from 'app/mvc';
import { InjectService } from 'app/services/locator.service';
import { ToastyService } from 'app/services/toasty.service';
import { CommentsModel } from 'app/mvc/comments-model';
import { ComponentBase } from 'app/softoolsui.module';

/**
 * Provides the content for the popup window for comments and attachments
 * that are associated with a record
 */
@Directive()
export class AttachmentPopupComponent extends ComponentBase {

  public deleteOverlayTrigger;
  public text = '';
  public tempDeleteAttachment: FileAttachment | Comment = null;

  @Input() recordModel: RecordModel;

  @Input() isOnline: boolean;
  @Input() workAttachment: Comment | FileAttachment;

  @Input() model: AttachmentsModel | CommentsModel;

  @Output() close = new EventEmitter();

  @ViewChild('entryElement')
  protected entryElement: ElementRef;

  @InjectService(ToastyService)
  protected readonly toasties: ToastyService;

  constructor() { super(); }

  public backgroundClicked(event: MouseEvent): void {
    // stop click outside other controls from propagating up and
    // causing a UI reset which will close the popup
    event.stopPropagation();
  }

  public closeClicked() {
    this.close.emit();
  }

  public delete(attachment: FileAttachment | Comment, trigger): void {
    this.model.isConfirmVisible.value = true;
    this.tempDeleteAttachment = attachment;
    this.deleteOverlayTrigger = trigger;
  }

  public cancelDelete(): void {
    this.model.isConfirmVisible.value = false;
  }
}
