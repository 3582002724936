import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report.component';
import { ChartModule } from '../chart.component/chart.module';
import { BusyIndicatorModule } from '../busy-indicator/busy-indicator.module';
import { ListReportModule } from '../listreport.component/listreport.module';
import { WsSharedModule } from 'app/workspace.module/components/ws-shared/ws-shared.module';
import { TableReportModule } from '../table-report/table-report.module';
import { ChartGoogleModule } from '../chart-google.component/chart-google.module';
import { ReportOfflineModule } from 'app/workspace.module/components/ws-shared/report-offline/report-offline.module';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';

@NgModule({
  declarations: [
    ReportComponent
  ],
  imports: [
    CommonModule,
    ChartModule,
    BusyIndicatorModule,
    ListReportModule,
    TableReportModule,
    ChartGoogleModule,
    WsSharedModule,
    ReportOfflineModule,
    MvcDirectivesModule
  ],
  exports: [
    ReportComponent
  ]
})
export class ReportModule { }
