<div class="container-fluid mt-4">
  <div class="jumbotron">
    <h1 class="display-3">{{ headerText }}</h1>
    <div>
      <hr class="my-4" />
      <p class="lead" *ngIf="description && description.length > 0">
        {{ description }}
      </p>
      <p i18n class="lead">
        Please speak to your system administrator for support.
      </p>
    </div>
  </div>
</div>
