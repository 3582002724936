<div class="chart-container" >
  <highcharts-chart *ngIf="displayChart && chartConfigIsReady && !chartingError"
  class="chart"
  [Highcharts]="highcharts"
  [constructorType]="chartConstructorType"
  [callbackFunction]="chartCallback"
  [options]="highChartConfigOptions"
  style="display: block;"
  [update]="chartUpdated$|async"
  (updateChange)="onChartUpdated($event)"
  [oneToOne]="true">
</highcharts-chart>

<div *ngIf="displayMatrixTable === true && !chartingError" class="table">
  <app-matrixreport
  [isCumulative]="chartType === 12 || chartType === 18"
  [model]="matrixModel"
  [appIdentifier]="appIdentifier"
  [appIdentifiers]="appIdentifiers"
  [isArchived]="isArchived"
  [parentRecordId]="parentRecordId"
  [clickThroughIdentifier]="clickThroughReportIdentifier">
</app-matrixreport>
</div>

<ng-container *ngIf="!(chartReportModel?.busy.busyDelayed$|async) && chartingError === 4">
  <app-ws-sharednorecords
    [app]="chartReportModel.appModel.app.$|async"
    [reportModel]="chartReportModel"
    [isLoading]="chartReportModel.busy.busy$|async"
    [isArchived]="chartReportModel.globalModel.archived.$|async">
  </app-ws-sharednorecords>
</ng-container>

</div>

<div *ngIf="configInvalid" class="container-fluid mt-4">
  <div class="jumbotron">
    <h1 class="display-3" i18n="@@ChartConfigError">Chart Config Invalid</h1>
    <h2 class="display-5" i18n="@@ChartConfigErrorContactSupport"><small>Please contact your site administrator to resolve</small></h2>
  </div>
</div>

<div *ngIf="chartingError && chartingError !== 4" class="container-fluid mt-4">
  <div class="jumbotron">
    <ng-container *ngIf="chartingError === 1">
      <h1 class="display-3" i18n="@@ChartFieldsNotSet">No chart fields set</h1>
      <h2 class="display-5" i18n="@@ChartConfigErrorContactSupport"><small>Please contact your site administrator to resolve</small></h2>
    </ng-container>
    <ng-container *ngIf="chartingError === 2">
      <h1 class="display-3" i18n="@@ChartGaugeRecordError">Not enough data for averages</h1>
      <h2 class="display-5" i18n="@@ClearFilterIfAppliedTryClearing"><small>If you have a filter applied, try clearing</small></h2>
    </ng-container>
    <ng-container *ngIf="chartingError === 5">
      <h1 class="display-3" i18n="@@ChartNotLoaded">Too many series</h1>
      <h2 class="display-5" i18n="@@TryRefreshing"><small>Filter or group until less than 50 series</small></h2>
    </ng-container>
    <ng-container *ngIf="chartingError === 6">
      <h1 class="display-3" i18n>No X or Y field set</h1>
      <h2 class="display-5" i18n="@@ChartConfigErrorContactSupport"><small>Please contact your site administrator to resolve</small></h2>
    </ng-container>
    <ng-container *ngIf="chartingError === 7">
      <h1 class="display-3" i18n>Missing input field</h1>
      <h2 class="display-5" i18n="@@ChartConfigErrorContactSupport"><small>Please contact your site administrator to resolve</small></h2>
    </ng-container>
    <ng-container *ngIf="chartingError === 8">
      <h1 class="display-3" i18n>No column field set</h1>
      <h2 class="display-5" i18n="@@ChartConfigErrorContactSupport"><small>Please contact your site administrator to resolve</small></h2>
    </ng-container>
    <ng-container *ngIf="chartingError === 9">
      <h1 class="display-3" i18n>No default series name field set</h1>
      <h2 class="display-5" i18n="@@ChartAddAGroupOr"><small>Add a grouping to the filter</small></h2>
      <h2 class="display-5" i18n="@@Connective"><small>Or</small></h2>
      <h2 class="display-5" i18n="@@ChartConfigErrorContactSupport"><small>Please contact your site administrator to resolve</small></h2>
    </ng-container>
  </div>
</div>
