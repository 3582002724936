
<div *ngIf="offline" class="w-100 h-100 d-flex justify-content-center align-items-center card-container">
  <mat-card class="shadowed thin-top-highlight card-el">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          <div role="button">
            <h3 class="d-flex">
              <fa-icon [icon]="['fal', 'wifi-slash']" [border]="false" size="1x" class="me-2"></fa-icon>
              <div class="ps-2 text-center">
                <span i18n>You are currently offline</span><br>
                <span i18n>You need to be connected to the internet to login and synchronise</span><br>
                <span i18n>Once you have synchronised you can work offline</span>
              </div>
            </h3>
          </div>
        </mat-card-title>
      </mat-card-title-group>
    </mat-card-header>
  </mat-card>
</div>

<div *ngIf="!offline && !loginEnabled" class="w-100 h-100 d-flex justify-content-center align-items-center card-container">
  <mat-card class="shadowed thin-top-highlight card-el">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          <h3 i18n>Login not enabled</h3>
        </mat-card-title>
        <mat-card-subtitle>
          <span i18n>
            Please speak to your site administrator to enable login.
          </span>
        </mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>
  </mat-card>
</div>

<div *ngIf="!offline" class="bg">
  <div style="display: flex; align-items: center; justify-content: center;" class="w-100 h-100">
    <div class="max-width: 50%;">
        <sof-spinner-icon size="4x" class="text-white"></sof-spinner-icon>
    </div>
  </div>
</div>
