import { DatabaseService } from './database.service';

/**
 * Manages an indexed DB connection and transaction.
 * Call open() on a new or existing instance, then match with
 * a call to close() when complete.  When all references have
 * been closed, the database connection will be closed.
 */
export class DatabaseTransaction {
  public db: IDBDatabase;
  public txn: IDBTransaction;
  private refCount = 0;

  public constructor(
    private _database: DatabaseService,
    private _store: string,
    private _mode: IDBTransactionMode = 'readwrite') {
  }

  public async open() {

    if (!this.db) {
      this.db = await this._database.getStoreAsync();
    }

    if (!this.txn) {
      this.txn = await this.db.transaction(this._store, this._mode);
    }

    this.refCount++;
  }

  public async close() {

    this.refCount--;
    if (this.refCount === 0) {
      this.db?.close();
      this.txn = null;
      this.db = null;
    }
  }

  public store() {
    return this.txn.objectStore(this._store);
  }

  public set onabort(callback: (this: IDBTransaction, ev: Event) => any) {
    this.txn.onabort = callback;
  }
}
