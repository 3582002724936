import { animate, state, style, transition, trigger } from '@angular/animations';

export const quickFiltersAnimation = trigger('quickFilters', [
  state('active', style({
    width: '20rem'
  })),
  state('inactive', style({
    width: '0'
  })),
  transition('* => inactive', animate('125ms ease-out')),
  transition('* => active', animate('200ms ease-in'))
]);

export const filterMgtAnimation = trigger('filterMgt', [
  state('active', style({
    width: '36rem'  // fixed width of panel plus 1rem for padding (see ).outer in filter-management-panel.component.scss)
  })),
  state('inactive', style({
    width: '0'
  })),
  transition('* => inactive', animate('125ms ease-out')),
  transition('* => active', animate('200ms ease-in'))
]);

export const filterMgtAnimationNarrow = trigger('filterMgtNarrow', [
  state('active', style({
    width: '25rem'  // fixed width of panel plus 1rem for padding (see ).outer in filter-management-panel.component.scss)
  })),
  state('inactive', style({
    width: '0'
  })),
  transition('* => inactive', animate('125ms ease-out')),
  transition('* => active', animate('200ms ease-in'))
]);
