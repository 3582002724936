import { Injectable } from '@angular/core';
import { SelectList, SelectListsStorageService, tryGetCurrentUser } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { RefreshType } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class SelectListsService {

  private selectListsById: Map<string, SelectList>;
  private selectListsByIdentifier: Map<string, SelectList>;
  private selectLists: Array<SelectList>;

  constructor(
    private selectListStorageService: SelectListsStorageService,
    private models: GlobalModelService) { }

  /**
   * Refresh select list data held by this service.  The select lists held in storage are loaded and indexed,
   * and optionally reloaded from the server if needed and/or possible.
   * This is be called by a guard in an async context so that code can access the collections
   * efficiently and synchronously.  Normal code should not need to call @see refresh directly.
   *
   * @param refreshType   Controls whether data is loaded from the server.
   *  'init' loads if no select lists found, 'force' always updates from server provided we are online.
   */
  public async refresh(refreshType?: RefreshType): Promise<Array<SelectList>> {

    let selectLists = await this.selectListStorageService.getAll();

    // Load from server if needed
    if ((refreshType === 'init' && (!selectLists || selectLists.length === 0)) || refreshType === 'force') {
      if (this.models.globalModel.online.value && tryGetCurrentUser()) {
        selectLists = await this.selectListStorageService.refreshAsync();
      }
    }

    this.mapSelectLists(selectLists);

    return this.selectLists;
  }

  public get(identifier: string): SelectList {
    this._checkIsLoaded();
    return this.selectListsByIdentifier.get(identifier);
  }

  public getById(identifier: string): SelectList {
    this._checkIsLoaded();
    return this.selectListsById.get(identifier);
  }

  public getAll(): Array<SelectList> {
    this._checkIsLoaded();
    return this.selectLists;
  }

  public async storeAsync(list: SelectList): Promise<SelectList> {
    return this.selectListStorageService.storeAsync(list);
  }

  public async removeAsync(...ids: Array<string>): Promise<void> {
    await Promise.all(ids.map(id => this.selectListStorageService.removeAsync(id)));
    await this.refresh();
  }

  public async refreshAsync(): Promise<Array<SelectList>> {
    return this.selectListStorageService.refreshAsync();
  }

  private mapSelectLists(selectLists: Array<SelectList>) {
    const selectListsByIdentifier = new Map<string, SelectList>();
    const selectListsById = new Map<string, SelectList>();

    if (selectLists) {
      selectLists.forEach(selectList => {
        selectListsByIdentifier.set(selectList.Identifier, selectList);
        selectListsById.set(selectList.Id, selectList);
      });
    }

    this.selectLists = selectLists;
    this.selectListsByIdentifier = selectListsByIdentifier;
    this.selectListsById = selectListsById;
  }

  private _checkIsLoaded(): void {
    if (!this.selectListsByIdentifier) {
      throw new Error('Select Lists not loaded.   Use SelectListsExistsGuard to prime service');
    }
  }
}
