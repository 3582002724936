import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportColumnHeaderComponent } from './report-column-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';

@NgModule({
  declarations: [
    ReportColumnHeaderComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatTooltipModule,
    MvcDirectivesModule
  ],
  exports: [
    ReportColumnHeaderComponent
  ]
})
export class ReportColumnHeaderModule { }
