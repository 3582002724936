import { User, PendingUser } from '@softools/softools-core';

export class MappedUser {

  public constructor(
    /** User id value */
    public Value: string,

    /** User display name */
    public Text: string,

    /** Email address */
    public Email: string,

    /** The user mapped from */
    public user?: User,
  ) { }


  public toString() {
    return (this.Text?.trim() || this.Email?.trim()) ?? '';
  }

}

export class MappedTeam /* implements PatchUpdater */ {
  public constructor(
    /** Team id value */
    public Value: string,

    /** team display name */
    public Text: string,
  ) { }
}

export class MappedPendingUser {


  public constructor(
    /** User id value */
    public Value: string,

    /** User display name */
    public Text: string,

    /** Email address */
    public Email: string,

    /** The user mapped from */
    public user?: PendingUser,
  ) { }

  public toString() {
    return (this.Text?.trim() || this.Email?.trim()) ?? '';
  }
}
