import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'app/design/signature-pad/signature_pad';

@Component({
  selector: 'app-draw-canvas',
  templateUrl: './draw-canvas.component.html',
  styleUrls: ['./draw-canvas.component.scss']
})
export class DrawCanvasComponent implements OnInit, OnDestroy {

  private _signaturePad: SignaturePad;

  @ViewChild('canvas', { static: true }) _canvasEl: ElementRef<HTMLCanvasElement>;

  constructor() { }

  public ngOnInit(): void {
    this._signaturePad = new SignaturePad(this._canvasEl.nativeElement);
  }

  public ngOnDestroy(): void {
    if (this._signaturePad?.off) {
      this._signaturePad.off(); // Unbinds all event handlers
    }
  }

  public setData(value: string) {
    if (value) {
      this._signaturePad?.fromDataURL(value);
    } else {
      this._signaturePad?.clear();
    }
  }

  public getData(): string {
    return !this._signaturePad.isEmpty()
      ? this._signaturePad.toDataURL() // Returns Image as data URL png
      : '';
  }

  public clear() {
    this._signaturePad?.clear();
  }

  public setSize(width: number, height: number, ratio: number) {
    const element = this._canvasEl?.nativeElement;
    if (element) {
      element.width = width;
      element.height = height;
      element.getContext('2d').scale(ratio, ratio);
    }
  }

}
