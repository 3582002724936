import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { WSReportBase } from 'app/workspace.module/components/ws-base/ws-reportbase';
import { UrlResolver, ReportField, OnlineStatusService, Template, LastListReportStorageService, OverlayVideo, OverlaySoftoolsConfig, ReportClickThroughInfo, logError } from '@softools/softools-core';
import { contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation, _VideoDescription, _VideoUrl } from 'app/_constants';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { ListReportModel } from 'app/mvc';
import { RouteParams } from 'app/mvc/global.model';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { filterMgtAnimation, quickFiltersAnimation } from 'app/workspace.module/animations/ws-animations';

@Component({
  selector: 'app-ws-listreport',
  templateUrl: './ws-listreport.component.html',
  styleUrls: ['./ws-listreport.component.scss'],
  animations: [
    contentPinnedStateAnimation,
    rightbarToggleContentStateAnimation,
    leftAsideStateAnimation,
    quickFiltersAnimation,
    filterMgtAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSListReportComponent extends WSReportBase implements OnInit, OnDestroy {

  public listReportModel: ListReportModel;

  public showColumn = '';
  public hideColumn = '';

  public forChildListReport = false;
  public addNewChildRecordUrlQueryParams: {};

  public cellEditorPosition = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' }
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'bottom' }
    )
  ];

  constructor(
    public override router: Router,
    public override navigationService: NavigationService,
    public override indexedDbService: IndexedDbService,
    public override appService: AppService,
    public override urlresolver: UrlResolver,
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    permissionsService: PermissionsService,
    protected lastListReportStorageService: LastListReportStorageService,
    private readonly overlayService: OverlayService,
    models: GlobalModelService,
  ) {
    super(indexedDbService, appService, router, urlresolver, onlineStatus, route, navigationService, permissionsService, models);

    this.forChildListReport = false;
  }

  public noRecords() {
    return (this.reportModel.totalCount.value === 0);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected override async routed(params: RouteParams) {
    await super.routed(params);

    await this.lastListReportStorageService.storeLastRecordsReportAsync(params.appIdentifiers.visibleAppIdentifier, params.reportIdentifier);

    this.addNewChildRecordUrlQueryParams = params.parentAppIdentifier && params.parentRecordId ?
      { hierarchy: `${params.parentAppIdentifier}|${params.parentRecordId}` } : {};

    this.processRouteParams();
  }

  public onToggleAutoLayoutHandler() {
    this.listReportModel.autoLayout.toggle();
  }

  public onShowColumnHandler(field: ReportField) {
    this.hideColumn = '';
    this.showColumn = field.FieldIdentifier;
  }

  public onHideColumnHandler(field: ReportField) {
    this.showColumn = '';
    this.hideColumn = field.FieldIdentifier;
  }

  public onReportEmbeddedVideoOpenHandler(payload: { $event: { fieldIdentifier: string }, recordId: string }) {
    // This code is untested. as the video popup is not currently hooked up.
    // Copied to remove ngrx dependencies.  Review/move to model if we reenable
    const record = this.listReportModel.records.value?.find(rec => rec._id === payload.recordId);
    if (record) {
      const fieldIdentifier = payload.$event.fieldIdentifier;
      const title = record[fieldIdentifier];
      const description = record[fieldIdentifier + _VideoDescription];
      const url = record[fieldIdentifier + _VideoUrl];

      if (title && description && url) {
        this.overlayService.openVideo({ video: { title, description, url } as OverlayVideo } as OverlaySoftoolsConfig);
      }
    }
  }

  protected override createReportModel() {
    this.listReportModel = new ListReportModel(this.appModel, this.filterModel, this.models.pageModel);
    return this.listReportModel;
  }

  public override filterTermUpdated(updates: FilterTermUpdates) {
    this.filterModel.updateFilterTerms(updates);
    this.reportModel.closeFilterEditor();
  }

  public override filterEditCancelled(): void {
    this.reportModel.closeFilterEditor();
  }
}
