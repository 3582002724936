import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Renderer2, ViewChild, Input, AfterViewInit } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { EpochDate, ErrorMap, logError } from '@softools/softools-core';

import * as moment from 'moment';
import { EditableFieldBase } from 'app/softoolsui.module/fields/editable-field-base';
import { isValidTime } from 'app/types/fields/time-app-field';
import { InputComponent } from '../input/input.component';
import { ContainerType } from 'app/softoolsui.module/fields/field-base';

/**
 * Time selection field.
 * Not yet implemented, see SOF-5925
 */
@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeFieldComponent extends EditableFieldBase<number> implements OnInit, AfterViewInit {

  @Input() inDateTime = false;

  @Input() public validationErrors: ErrorMap;

  /** Fired after the time value has changed when embedded in date/time component */
  @Output() public timeUpdated = new EventEmitter<EpochDate | number>();

  @ViewChild(InputComponent, { static: false })
  private inputComponent: InputComponent;

  public displayTime = '';

  /** Use 24 (default) or 12 hour clock representation  */
  public use24hourClock = true;

  public format = 24;

  public enablePicker = true;

  private activeWhenReady = false;

  constructor(protected renderer: Renderer2) {
    super();
  }

  public override ngOnInit() {

    super.ngOnInit();

    // Set time format - default is 24 hour clock
    this.use24hourClock = !this.fieldModel.TimeOptions?.use12hourClock;
    this.format = this.use24hourClock ? 24 : 12;
    this.displayTime = this.time ?? this.getCurrentTime();

    // Disable picker on filter edit as popups clash
    // Also temp on table report as it's not working (for some reason)
    this.enablePicker = this.containerType !== ContainerType.FilterEdit && this.containerType !== ContainerType.TableReport;
  }

  protected override onValueChanged(value: number) {
    super.onValueChanged(value);

    if (this.time === null || this.value === 0) {
      this.displayTime = this.getCurrentTime();
    } else {
      this.displayTime = this.time;
    }
  }

  public ngAfterViewInit(): void {
    if (this.activeWhenReady) {
      this.activeWhenReady = false;
      this.inputComponent?.element?.nativeElement?.focus();
    }
  }

  public override async activate() {
    this.activeWhenReady = true;
  }

  public override async deactivate() {
    await this.inputComponent.updateCurrentValue();
  }

  public override get filterValue(): any {
    // Make sure value is up to date
    this.inputComponent.updateCurrentValue().catch(error => logError(error, 'Failed to update filter'));

    return this.fieldValue;
  }

  /** Get the time part of the attached value */
  public get time(): string {
    const value = this.fieldValue;
    if (value === null || value === undefined || (value as any) === '') { return null; }
    if (!isValidTime(value)) { return value as any; }
    // Use a temp moment set to requested time today to format time
    const dateTime = moment.utc().hours(0).minutes(0).seconds(0).milliseconds(0).seconds(Math.round(+value));
    return dateTime.format(this.use24hourClock ? 'HH:mm' : 'hh:mm A');
  }

  /**
   * Time has been updated
   * @param value
   */
  public async timeChanged(value: string | number) {
    try {
      let seconds: number;

      if (value === '') {
        seconds = null;
      } else if (Number.isSafeInteger(+value)) {
        seconds = +value;
      } else if (typeof value === 'string') {
        // convert to seconds
        const [time, suffix] = value.split(' ', 2);
        const [hours, minutes] = time.split(':');
        const duration = moment.duration({
          // previous code was hours: hours === '12' ? 0 : +hours
          // may need to restore that when we put 12 hour clocks support back
          hours: +hours,
          minutes: +minutes
        });

        if (suffix === 'PM') {
          duration.add(12, 'h');
        }

        seconds = duration.asSeconds();
      }

      this.fieldValue = seconds;

      if (this.inDateTime) {
        this.timeUpdated.emit(seconds);
      } else {
        await this.updateValueAsync(seconds);
      }

      this.onChanged.emit({ payload: seconds, fieldIsValid: true });

    } catch (error) {
      logError(error, 'timeChanged');
    }
  }

   public clearTime() {
    this.value = null;
    this.displayTime = null;
    this.previousValue = null;
    this.dispatchChangeAsync(this.value).catch(error => logError(error, 'Failed in dispatchChangeAsync'));
  }

  // Adjust theme to be more like date picker.  See stylesheet also.
  public get theme(): NgxMaterialTimepickerTheme {
    return {
      container: {
        bodyBackgroundColor: 'white',
        buttonColor: 'black'
      },
      clockFace: {
        clockFaceBackgroundColor: '#FCFCFC',
        clockHandColor: 'black'
      },
      dial: {
        dialBackgroundColor: 'white',
        dialActiveColor: 'black',
        dialInactiveColor: 'lightgrey'
      }
    };
  }

  private getCurrentTime() {
    return moment().format(this.use24hourClock ? 'HH:mm' : 'hh:mm A');
  }
}
