import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { AppModel } from 'app/mvc';

/**
 * Control to display comments/attachments option in record view footer
 */
@Component({
  selector: 'app-comment-widget',
  templateUrl: './comment-widget.component.html',
  styleUrls: ['./comment-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentWidgetComponent {

  @Input() appModel: AppModel;
  @Input() attachmentCount = 0;
  @Input() commentCount = 0;

  @Output() onCommentClicked = new EventEmitter();
  @Output() onFileClicked = new EventEmitter();

  public commentClicked(event: MouseEvent) {
    this.onCommentClicked.emit();
    event.stopPropagation();
  }

  public fileClicked(event: MouseEvent) {
    this.onFileClicked.emit();
    event.stopPropagation();
  }
}
