export const DB_NAME = `Softools`;

/**
 * Current version.  Update whenever a new version is required.
 */

export const REQUIRED_DB_VERSION = 108;

/**
 * VERSION 1
 */
export const STORE_REPORT_VIEWS = 'ReportViews';
export const STORE_DATA = 'Data';

export const INDEX_APPIDENTIFIER = 'AppIdentifier';

/**
 * VERSION 2
 *
 * ADD v2 here
 */
export const STORE_CHART_DATA = 'ChartData';
export const STORE_IN_APP_CHART_DATA = 'InAppChartData';

/**
 * Version 3
 * Adds Apps
 */

export const STORE_APPS = 'Apps';

/**
 * Version 4
 * Adds PatchQueue
 */

export const PATCH_QUEUE_STORE = 'PatchQueue';

/**
 * Version 5
 * Adds hierarchy index to AppData
 */

export const APPDATA_HIERARCHY_INDEX = 'Hierarchy';

/*
 * Version 6
 * Adds search term storage
 */

export const SEARCH_TERM_STORE = 'SearchTerms';

/*
 * Version 7
 * Adds last used list report
 */

export const LAST_LIST_STORE = 'LastListReport';

/*
 * Version 8
 * Adds upload images store
*/

export const UPLOAD_IMAGES_STORE = 'UploadImages';

/*
 * Version 9
 * Adds users, team users
 */

export const USERS_STORE = 'Users';
export const TEAM_USERS_STORE = 'UsersForMyTeams';

/*
 * Version 10
 * Adds image list assets
 */

export const IMAGE_LIST_ASSET_STORE = 'ImageListAssets';

/*
 * Version 11
 * Adds app data metrics
 */

export const DATA_METRICS_STORE = 'DataMetrics';

/*
 * Version 12
 * Migrate remaining tables from Softools indexed db
 */

export const SELECT_LISTS_STORE = 'SelectLists';
export const IMAGE_LISTS__STORE = 'ImageLists';
export const HOMEPAGES_STORE = 'HomePages';
export const SITE_GLOBAL_STORE = 'SiteGlobal';
export const SITE_SETTINGS_STORE = 'SiteSettings';
export const TEAMS_STORE = 'Teams';
export const RECORD_DATA_STORE = 'AccessedRecordData';
export const REPORT_DATA_STORE = 'AccessedReportData';
export const HOMEPAGE_DASH_REPORTS = 'HomepageDashboardReports';
export const HOMEPAGE_DASH_FIELDS = 'HomepageDashboardFields';
export const PENDING_SAVE_IMAGES = 'PendingSaveImages';
export const PENDING_DELETE_IMAGES = 'PendingDeleteImages';
export const BACKGROUND_PROCESSES = 'BackgroundProcesses';
export const REPORT_SELECTION_STATE = 'CurrentStateForReport';
export const NOTIFICATIONS_STORE = 'Notifications';
export const REPORT_CACHE_STORE = 'ReportCache';

// Next version can delete PENDING_DELETE_IMAGES which has now been merged with PENDING_SAVE_IMAGES

// Version 101
export const CHANGE_QUEUE_STORE = 'Changes';

// Version 102
export const LOCAL_VALUE_STORE = 'LocalValues';

// Version 103
export const WORKFLOW_TRIGGER_QUEUE = 'WorkflowTriggers';

// Version 104
export const PENDING_USER_STORE = 'PendingUsers';

// Version 105
export const CONFIG_UPGRADE_STORE = 'ConfigUpgrade';

// Version 106
export const CHANGED_RECORDS_STORE = 'ChangedRecords';

// Version 107
export const WORKFLOW_RUNNER_QUEUE = 'WorkflowRunner';

// Version 108
export const CHANGED_RECORDS_APP_INDEX = 'App';
