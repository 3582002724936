import { Injectable } from '@angular/core';
import { HomepageStorageService, Homepage, OnlineStatusService } from '@softools/softools-core';
import { RefreshType } from './users.service';

@Injectable({ providedIn: 'root' })
export class HomepagesService {

  private homepagesById: Map<string, Homepage>;

  public constructor(
    private homepageStorageService: HomepageStorageService,
    private onlineStatus: OnlineStatusService
  ) { }

  /**
   * Refresh homepage data held by this service.  The homepages held in storage are loaded and indexed,
   * and optionally reloaded from the server if needed and/or possible.
   * This is be called by a guard in an async context so that code can access the collections
   * efficiently and synchronously.  Normal code should not need to call @see refresh directly.
   *
   * @param refreshType   Controls whether data is loaded from the server.
   *  'init' loads if no homepages found, 'force' always updates from server provided we are online.
   */
  public async refresh(refreshType?: RefreshType): Promise<Array<Homepage>> {
    let homepages = await this.homepageStorageService.getHomepages();

    // Load from server if needed
    if ((refreshType === 'init' && (!homepages || homepages.length === 0)) || refreshType === 'force') {
      if (!this.onlineStatus.isOnline) {
        homepages = await this.homepageStorageService.syncHomepages();
      }
    }

    this.mapHomepages(homepages);
    return homepages;
  }

  private mapHomepages(homepages: Array<Homepage>) {
    const homepagesById = new Map<string, Homepage>();
    homepages.forEach(home => {
      homepagesById.set(home.Identifier, home);
    });
    this.homepagesById = homepagesById;
  }

  public getAll() {
    return Array.from(this.homepagesById.values());
  }

}
