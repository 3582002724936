
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { DrawFieldComponent } from './draw-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DrawCanvasComponent } from './draw-canvas/draw-canvas.component';

@NgModule({
  declarations: [DrawFieldComponent, DrawCanvasComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule
  ],
  exports: [DrawFieldComponent],
  providers: [],
})
export class DrawFieldModule { }
