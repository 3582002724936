import { AppModel, ReportModel } from 'app/mvc';
import { TableReportController } from '../table-report/table-report-controller';

export class RecordSelectorTableReportController extends TableReportController {

  constructor(reportModel: ReportModel) {
    super(reportModel);

    // Initialise for lookup
    this.setSelectControlMode();
    this.editable.reset();
  }
}
