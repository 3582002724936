import { RecordId } from '@softools/softools-core';
import { ReportFilter } from 'app/filters/types';
import { OnlineDataIndex } from 'app/services/indexes/online-data-index';
import { InjectService } from 'app/services/locator.service';
import { RecordCacheService } from 'app/services/record/record-cache.service';
import { RecordSelection } from 'app/types/record-selection';

export class OnlineEmbeddedIndex extends OnlineDataIndex {

  @InjectService(RecordCacheService)
  private readonly recordCache: RecordCacheService;

  protected override async loadPageIds(pageStart: number, pageSize: number): Promise<Array<RecordId>> {

    const app = this.app;

    const selection = new RecordSelection();
    selection.filter = ReportFilter.fromQueryParams(this.query);
    selection.hierarchy = this.options?.hierarchy;
    selection.report = app.getReport(this.reportIdentifier);
    selection.start = pageStart;
    selection.count = pageSize;
    selection.showArchived = this.options?.archived;

    const records = await app.getApiRecordRange(selection);
    if (records) {
      records.forEach(record => this.recordCache.put(record));
      return records.map(record => record._id);
    }

    return [];
  }

}
