import { Directive, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appFormTouch]'
})
export class FormTouchDirective implements OnInit {

  @Input() formRef: NgModel;

  ngOnInit(): void {
    this.formRef.control.markAsTouched();
    this.formRef.control.markAsDirty();
  }
}
