import App from './assets.config.json';

import { Injectable } from '@angular/core';
import { Record, ImageListAsset, ImageListAssetRepository, RecordId, logError } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '../embedded-application';
import { RecordPatch } from 'app/workspace.module/types/index';
import { ToolbarContext, AppCapabilities, DeleteRecordOptions } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { CallbackToolbarAction, ToolbarAction } from 'app/softoolscore.module/types/classes/index';
import { AppZone } from 'app/types/enums';
import { PermissionsService } from 'app/services/permissions.service';
import { IAppExtension } from 'app/types/app-extension';
import { InjectService } from 'app/services/locator.service';
import { ToastyService } from 'app/services/toasty.service';
import { AppField } from 'app/types/fields/app-field';
import { FieldBase } from 'app/softoolsui.module/fields';
import { FileDropComponent } from 'app/softoolsui.module/fields2/file-drop/file-drop.component';
import { ReportModel } from 'app/mvc';
import { AppModel } from 'app/mvc';

/**
 * Extension to create assets when files dropped on upload component
 */
class DropImageListAssetAttachmentExtension implements IAppExtension<File> {

  public constructor(private app: ImageListAssetsApplication) {
  }

  @InjectService(ImageListAssetRepository)
  private readonly repository: ImageListAssetRepository;

  @InjectService(ToastyService)
  private readonly toasties: ToastyService;

  public async executeAsync(appModel: AppModel, _record, file: File): Promise<void> {
    if (this.isImageFile(file)) {
      // Upload file.  Can't use async here as file object gets cleared
      this.repository.createAssetAsync(file).then(() => {
        this.toasties.success(file.name, 'Created Asset');
        this.app.refresh().catch(e => logError(e, 'refresh'));
      }).catch(() => {
        // Display generic error toasty as response is "Ok"
        this.toasties.error('Error uploading Asset');
      });
    } else {
      this.toasties.error('Asset file must be an image type');
    }
  }

  private isImageFile(file: File) {
    switch (file.type) {
      case 'image/png':
      case 'image/gif':
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/jpe':
      case 'image/bmp':
      case 'image/svg':
      case 'image/tiff':
      case 'image/svg+xml':
        return true;
      default:
        return false;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class ImageListAssetsApplication extends OnlineEmbeddedApp<ImageListAsset> {

  private dropField: AppField;

  constructor(
    private repository: ImageListAssetRepository,
    private permissionsService: PermissionsService,
  ) {
    // Use a tweaked version of the asset config as they're almost identical
    super({
      ...App.App,
      Identifier: 'Softools.ImageListAssets',
      Title: 'Image List Assets',
      Description: 'Image List Assets',
      Name: 'Image List Asset',
      NamePlural: 'Image List Assets',
      LogoImageUri: 'assets/app-icons/settings/Image List Assets.png'
    });

    this.dropField = this.getField('DropNewAsset');
  }

  public override initialiseFieldComponent(component: FieldBase) {
    if (component.fieldModel.Identifier === this.dropField?.Identifier && component instanceof FileDropComponent) {
      component.fileDroppedExtension = new DropImageListAssetAttachmentExtension(this);
    }
  }

  public toRecord(asset: ImageListAsset): Record {
    const record = asset && {
      ...asset,
      _id: asset.Id,
      AppIdentifier: this.Identifier,
      Id: asset.Id,
      Uri: `/ImageListAsset/${asset.Id}`,
      Hierarchy: '',
      EditableAccessForUser: false,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: asset.Name,
      IsArchived: false,
    } as Record;
    return record;
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  public override async totalCount(): Promise<number> {
    return this.repository.getCountAsync();
  }

  public override async refresh(): Promise<boolean> {
    return true;
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async getRecordByIdAsync(id: RecordId): Promise<Record> {
    const asset = await this.repository.getAssetAsync(id);
    return asset && this.toRecord(asset);
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const assets = await this.repository.getAssetsAsync(selection.count, selection.start, queryParams);
    return assets.map(summary => this.toRecord(summary));
  }

  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const assets = await this.repository.getAssetsAsync(1, +cursor - 1, queryParams);
    return assets.map(summary => this.toRecord(summary)).pop();
  }

  public override async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {
    const ids: Array<RecordId> = deleteOptions.selection.SelectedRecordIds;
    await Promise.all(ids.map(id => this.repository.deleteAsync(id)));

    // Notify all as deleted (this will include any failures - can improve)
    ids.forEach(deletedId => {
      this.recordDeleted$.next({ app: this, recordIdentifier: deletedId });
    });

    return true;
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    switch (context.zone) {
      case AppZone.listreport:
        actions.push(new CallbackToolbarAction('Delete', 'trash', async () => {
          await reportModel.delete();
        }));
        break;
      default:
        break;
    }

    // Refresh on all zones
    actions.push(new CallbackToolbarAction('Refresh', 'sync', async () => {
      await reportModel.refresh();
    }));
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: false,
      canEdit: false,
      canView: false,
      canSort: true,
      canGroup: false,  // server API currently doesn't support grouping.
      noSelectAll: true,    // select all & delete too risky (and no API support)
    };
  }

  public override get permissions() {
    return this.permissionsService.assetPermissions;
  }

}
