import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SavedFilter, Enums, getCurrentUser, stringCompare, logError } from '@softools/softools-core';
import { Application } from 'app/types/application';
import ObjectID from 'bson-objectid';
import { FilterSpecification } from '../types';

export interface FilterSaveData {
  app: Application;
  savedFilters: Array<SavedFilter>;
  filterSpec: FilterSpecification;
}

export interface FilterSaveResult {
  savedFilter: SavedFilter;
}

@Component({
  selector: 'app-filter-save',
  templateUrl: './filter-save.component.html',
  styleUrls: ['./filter-save.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSaveComponent {

  public newFilter: SavedFilter;

  public selectedFilter: SavedFilter;

  public accessLevel = '0';

  public description: string;

  public savedFilters: Array<SavedFilter>;

  public filterSpec: FilterSpecification;

  public isDefault: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private saveData: FilterSaveData,
    private dialogRef: MatDialogRef<FilterSaveComponent>,
  ) {
    try {
      this.savedFilters = this.saveData.savedFilters;
      this.filterSpec = this.saveData.filterSpec;

      // Generate a unique ID to use when creating new filter
      const newObjectId = new ObjectID();

      this.newFilter = {
        AppIdentifier: this.saveData.app.Identifier,
        Id: newObjectId.toHexString(),
        AccessLevel: Enums.FilterAccessLevel.Personal,
        Filter: this.filterSpec.reportFilter.Filter,
        Group: this.filterSpec.reportFilter.Group,
        IsGroupDescending: this.filterSpec.reportFilter.IsGroupDescending,
        OrderBy: this.filterSpec.reportFilter.OrderBy,
        IsOrderDescending: this.filterSpec.reportFilter.IsOrderDescending,
        Description: '',
        TeamIds: { changes: {}, added: [], removed: [] },
        UserId: getCurrentUser().Id,

      } as SavedFilter;


      // Select filter if specified otherwise select new filter
      if (saveData.filterSpec.Id) {

        const filter = this.savedFilters.find(sf => sf.Id === saveData.filterSpec.Id);
        this.selectedFilter = filter;
        this.accessLevel = filter.AccessLevel.toString();
        this.isDefault = filter.IsDefault;
      } else {
        this.selectedFilter = this.newFilter;
        this.accessLevel = '0';
        this.isDefault = false;
      }
    } catch (err) {
      logError(err, '');
    }
  }

  public submit() {

    const saveFilter = this.selectedFilter;

    // For new filter update the settings from the dialog
    if (!this.isExistingFilter) {
      saveFilter.AccessLevel = +this.accessLevel;
      saveFilter.Description = this.description;
    }

    // Overwrite the core parts with the bit we're saving as...
    const filterString = this.filterSpec.reportFilter.Filter && this.filterSpec.reportFilter.Filter !== 'undefined' ? this.filterSpec.reportFilter.Filter : '';
    saveFilter.Filter = `$filter=${filterString}`;
    saveFilter.Group = this.filterSpec.reportFilter.Group;
    saveFilter.OrderBy = this.filterSpec.reportFilter.OrderBy;
    saveFilter.IsOrderDescending = this.filterSpec.reportFilter.IsOrderDescending;
    saveFilter.IsGroupDescending = this.filterSpec.reportFilter.IsGroupDescending;

    if (saveFilter.AccessLevel === Enums.FilterAccessLevel.Global) {
      saveFilter.IsDefault = this.isDefault;
    }

    // Return back to caller
    const results: FilterSaveResult = {
      savedFilter: saveFilter,
    };

    this.dialogRef.close(results);
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public savedFiltersForCurrentAccessLevel(): Array<SavedFilter> {
    try {
      return this.savedFilters.
        filter(filter => filter.AccessLevel === +this.accessLevel).
        sort((filter1, filter2) => stringCompare(filter1.Description, filter2.Description));
    } catch (error) {
      logError(error, '');
      return [];
    }
  }

  public get isExistingFilter(): boolean {
    try {
      return this.selectedFilter && this.selectedFilter.Id !== this.newFilter.Id;
    } catch (error) {
      logError(error, '');
      return false;
    }
  }

  public accessLevelChanged = () => {
    try {
      // Filter list belongs to an access level so switch to new filter if that changes
      // Use can then pick a filter of the right access level if they want
      this.selectedFilter = this.newFilter;
    } catch (error) {
      logError(error, '');
    }
  }

  public filterChanged(filter: SavedFilter) {
    this.isDefault = filter.IsDefault;
  }
}
