import { Component, ViewChild, ElementRef, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseInputComponent, ATTR_CHECKED } from '../base-input-component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends BaseInputComponent<HTMLInputElement> implements OnInit {

  @ViewChild('input', { static: true })
  public override element: ElementRef<HTMLInputElement>;

  public extraClasses$ = new BehaviorSubject('');

  public override ngOnInit() {
    super.ngOnInit();

    // might want to detect change ...
    if (this.elementStyles?.component.Appearance?.split('|').includes('vanilla')) {
      this.extraClasses$.next('');
    } else {
      this.extraClasses$.next('form-control form-control-o');
    }
  }

  protected override getNativeElementInputValue() {

    // Leftover code to support checkbox fields.  Looks unused?
    if (this.getInputType() === ATTR_CHECKED) {
      return this.element.nativeElement.checked;
    }

    return super.getNativeElementInputValue();
  }

  protected override onEnter($event: KeyboardEvent) {
    this.onBlur($event);
  }
}
