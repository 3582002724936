import { Application } from 'app/types/application';
import { AppModel, FolderModel } from '..';
import { AppContextModel } from '../app/app-context.model';
import { FolderController } from './folder.controller';

/** Folder controller for single app pages (i.e. most of them) */
export abstract class AppFolderController extends FolderController {

  constructor(public appModel: AppModel, folderModel: FolderModel) {
    super(appModel.globalModel, folderModel);
  }

  public override initialise() {
    this.initialiseHeaderApp();
    this.initialiseCount();
    this.initialiseHome();
  }

  protected initialiseHeaderApp(): void {
    this.subscribe(this.appModel.app.$, async (app: Application) => {
      if (app) {
        this.folderModel.headerApp.value = app;
      }
    });
  }

  /** Initialise count for a top level app to  the total record count  */
  protected override initialiseCount(): void {
    this.subscribe(this.appModel.app.$, async (app: Application) => {
      if (app) {
        this.getHeaderCount(app);
      }
    });
  }

  /** Get the count value for the header; updates asynchronously */
  protected async getHeaderCount(app: Application): Promise<void> {
    if (app) {
      try {
        this.folderModel.countBusy.value = true;
        const count = await app.totalCount();
        this.folderModel.count.value = count;
      } finally {
        this.folderModel.countBusy.value = false;
      }
    }
  }

  protected override initialiseHome() {
    this.subscribe(this.appModel.appContext.$, (apps: AppContextModel) => {
      const homeUrl = this.configureHomeUrl(apps);
      this.folderModel.appHomeUrl.value = homeUrl;
    });
  }

  protected configureHomeUrl(apps: AppContextModel): string {
    if (apps.parentRecordId.value) {
      return this.navigationService.getChildAppHomeOrBaseUrl(apps.appIdentifiers.value, apps.parentRecordId.value);
    } else {
      return this.navigationService.getAppHomeUrl(apps.app.value?.Identifier);
    }
  }
}
