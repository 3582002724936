import { Injectable } from '@angular/core';
import { DataIndex } from 'app/services/indexes/app-data-index';
import { IndexedAppData, Record, Report } from '@softools/softools-core';
import { ChartEnums } from '../chart.enums';
import { BaseChartDataService } from './base-chart-data.service';
import { AppField } from 'app/types/fields/app-field';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class PolarChartDataService extends BaseChartDataService {
  public async getPolarChartDataOptions(app: Application, appDataIndex: IndexedAppData, report: Report, hierarchy: string, record?: Record) {
    const seriesFields = (report.Chart.ChartFields)
      .filter(chartField => chartField.Type === ChartEnums.ChartFieldType.Series)
      .map(chartField => ({ ...chartField, BaseField: app.getField(chartField.BaseFieldIdentifier) }));
    const labelField = (report.Chart.ChartFields)
      .find(chartField => chartField.Type === ChartEnums.ChartFieldType.Label);
    labelField.BaseField = app.getField(labelField.BaseFieldIdentifier);

    const polarChartData: { [key: string]: { [key: string]: Array<number> } } = {};

    const seriesToolTipDecimals: { [key: number]: number } = {};
    seriesFields.forEach(seriesField => seriesToolTipDecimals[seriesField.Order] = seriesField.ToolTipDecimals);
    const chartCalculation = report.Chart.PolarCalculation;
    const formatStrings: { [key: number]: string } = {};

    const recordCallbackFunction = (rec) => {
      const recordLabel = (<AppField>labelField.BaseField).getRawRecordValue(rec) ? (<AppField>labelField.BaseField).getRawRecordValue(rec).toString() : '';
      seriesFields.forEach(chartField => {
        const seriesRawValue = (<AppField>chartField.BaseField).getRawRecordValue(rec);
        const seriesValue = seriesRawValue && seriesRawValue.toString();
        const seriesValueDecimal = Number.parseFloat(seriesValue);
        if (chartCalculation === ChartEnums.PolarCalculation.Summation || chartCalculation === ChartEnums.PolarCalculation.AverageIncludingZeros) {
          if (!!polarChartData[recordLabel]) {
            if (!polarChartData[recordLabel][chartField.Order]) { polarChartData[recordLabel][chartField.Order] = []; }
            polarChartData[recordLabel][chartField.Order].push(seriesValueDecimal);
          } else {
            polarChartData[recordLabel] = {};
            if (!polarChartData[recordLabel][chartField.Order]) { polarChartData[recordLabel][chartField.Order] = []; }
            polarChartData[recordLabel][chartField.Order].push(seriesValueDecimal);
          }
        } else if (chartCalculation === ChartEnums.PolarCalculation.AverageExcludingZeros && seriesValueDecimal !== 0) {
          if (!polarChartData[recordLabel]) { polarChartData[recordLabel] = {}; }
          if (!polarChartData[recordLabel][chartField.Order]) { polarChartData[recordLabel][chartField.Order] = new Array<number>(); }
          polarChartData[recordLabel][chartField.Order].push(seriesValueDecimal);
        }

        if (!formatStrings.hasOwnProperty(chartField.Order)) {
          formatStrings[chartField.Order] = chartField.BaseField['FormatString'];
        }
      });
    };

    if (record) {
      const records = await this.getRecordData(app, true, appDataIndex, report, record._id, record);
      await records.forEach(recordCallbackFunction);
    } else {
      await appDataIndex.eachRecord(recordCallbackFunction);
    }

    const polarChartDataCalculated: { [key: string]: { [key: number]: number } } = {};
    for (const label in polarChartData) {
      if (polarChartData.hasOwnProperty(label)) {
        const seriesDictionary: { [key: string]: number } = {};
        for (const seriesKey in polarChartData[label]) {
          if (polarChartData[label].hasOwnProperty(seriesKey)) {
            const polarSeries = polarChartData[label][seriesKey];
            if (polarSeries.length > 0) {
              const formatString = formatStrings[seriesKey];
              // let value = 0;
              const toolTipDecimals = seriesToolTipDecimals[seriesKey];

              switch (chartCalculation) {
                case ChartEnums.PolarCalculation.Summation:
                  if (formatString) {
                    // let formattedValue = string.Format(formatString, polarChartData[label][seriesKey].reduce((agg, num) => agg += num));
                    // value = Number.parseFloat(formattedValue);

                    // if (toolTipDecimals != null) { value = +(value.toFixed(toolTipDecimals)) };

                    // seriesDictionary[seriesKey] = value;
                  } else {
                    let sum = polarChartData[label][seriesKey].reduce((agg, num) => agg += num);
                    if (toolTipDecimals != null) { sum = +(sum.toFixed(toolTipDecimals)); }

                    seriesDictionary[seriesKey] = sum;
                  }
                  break;
                case ChartEnums.PolarCalculation.AverageExcludingZeros:
                case ChartEnums.PolarCalculation.AverageIncludingZeros:
                  if (formatString) {
                    // const formattedValue = string.Format(formatString, polarChartData[label][seriesKey].reduce((agg, num) => agg += num) / polarChartData[label][seriesKey].length);
                    // value = Number.parseFloat(formattedValue);

                    // if (toolTipDecimals != null) { value = +(value.toFixed(toolTipDecimals)) };

                    // seriesDictionary[seriesKey] = value;
                  } else {
                    let average = polarChartData[label][seriesKey].reduce((agg, num) => agg += num) / polarChartData[label][seriesKey].length;
                    if (toolTipDecimals != null) { average = +(average.toFixed(toolTipDecimals)); }

                    seriesDictionary[seriesKey] = average;
                  }
                  break;
              }
            } else {
              seriesDictionary[seriesKey] = 0;
            }
          }
        }
        polarChartDataCalculated[label] = seriesDictionary;
      }
    }

    const seriesEnableMouseTracking = true;
    const seriesShadow = true;
    const colorKeyValueStyles = this.chartColourService.getColorKeyValueStylesAsync(app, report.NamedStyles);

    const series = seriesFields.sort((a, b) => a.Order - b.Order).map(
      cd => {
        const dataArray = new Array<any>();
        for (const labelValue in polarChartDataCalculated) {
          if (polarChartDataCalculated.hasOwnProperty(labelValue)) {
            dataArray.push(polarChartDataCalculated[labelValue][cd.Order]);
          }
        }

        return {
          name: cd.Label,
          fieldName: cd.BaseField.Identifier,
          pointPlacement: 'on',
          data: dataArray,
          animation: true,
          enableMouseTracking: seriesEnableMouseTracking,
          shadow: seriesShadow,
          color: this.chartColourService.getColourHexcode(cd.Label, colorKeyValueStyles)
        };
      });

    const categories = Object.keys(polarChartDataCalculated);

    // @ts-ignore
    const sortedCategories = !isNaN(categories[0]) ? categories.slice().sort((a, b) => Number.parseFloat(a) - Number.parseFloat(b)) : categories.slice().sort();

    series.slice().forEach((seperateSeries, j) => {
      const tempData = [...seperateSeries.data];
      categories.forEach((column, i) => {
        series[j].data[sortedCategories.indexOf(column)] = tempData[i];
      });
    });

    return { Series: series, Categories: sortedCategories, Hierarchy: hierarchy };
  }
}
