import { ReportFilter } from 'app/filters/types';
import { IGroupStarts } from 'app/services/indexes/app-data-index';
import { OnlineRecordDataAccssor } from './online-record-data-accessor';

export class OnlineCardDataAccssor extends OnlineRecordDataAccssor {
  // no difference now as filter model manages grouping
  // can consider removing
}

export class ArchivedCardRecordDataAccessor extends OnlineCardDataAccssor {
  protected override getSelection(first?: number, count?: number, group?: IGroupStarts) {
    const selection = super.getSelection(first, count, group);
    selection.showArchived = true;
    return selection;
  }

  public override isArchive(): boolean {
    return true;
  }

}
