import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorisationService } from 'app/auth.module/services/authorisation.service';

@Injectable({ providedIn: 'root' })
export class SignupLoggedOutGuard implements CanActivate {

  constructor(
    private router: Router,
    private authorisationService: AuthorisationService
  ) { }

  canActivate(): boolean | UrlTree {

    if (this.authorisationService.isValidAuthToken()) {
      return this.router.parseUrl('Error?type=signupWhenLoggedIn');
    }

    return true;
  }
}
