import { Record } from '@softools/softools-core';
import { ModelProperty } from '@softools/vertex';
import { AppModel } from '../app.model';
import { FolderModel } from './folder.model';
import { Application } from 'app/types/application';
import { ReportModel } from '../reports/report.model';
import { combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { AppFolderController } from './app-folder.controller';

export class ChildReportFolderController extends AppFolderController {

  constructor(appModel: AppModel, private reportModel: ReportModel, folderModel: FolderModel, protected parentRecord: ModelProperty<Record>, protected parentApp: ModelProperty<Application>) {
    super(appModel, folderModel);
  }

  public override initialise() {

    super.initialise();

    this.subscribe(combineLatest([this.appModel.parentApp.$, this.appModel.parentRecord.$])
      .pipe(debounceTime(1)),
      async ([parentApp, record]) => {
        // Bit of a kludge - this is pointing at the child app
        const ai = this.appModel.appIdentifiers.value?.pop();
        if (ai && parentApp && this.parentRecord) {
          const root = await this.createRecordFolder(parentApp, ai, record);
          this.folderModel.folderGroups.value = root.FolderGroups;

          if (this.reportModel.report.value) {
            this.selectReport(this.reportModel.report.value.Identifier);
          }
        }
    });

    if (this.reportModel) {
      this.subscribe(this.reportModel.report.$.pipe(
        distinctUntilChanged((p, c) => p?.Identifier === c?.Identifier)), (report) => {
          if (report) {
            this.selectReport(report.Identifier);
          }
        });
    }
  }

  protected override initialiseHeaderApp(): void {
    this.subscribe(this.appModel.parentApp.$, async (app: Application) => {
      if (app) {
        this.folderModel.headerApp.value = app;
      }
    });
  }

  protected override initialiseCount(): void {
    this.folderModel.count.value = 0;
    this.folderModel.countBusy.value = false;
  }

  protected override initialiseHome() {
    this.subscribe(this.parentApp.$, async (app) => {
      if (app) {
        const home = this.navigationService.getAppHomeUrl(app.Identifier);
        this.folderModel.appHomeUrl.value = home;
      }
    });
  }

  protected selectReport(selectedReporidentifier: string) {
    const groups = this.folderModel.folderGroups.value;
    if (groups) {
      groups.forEach(group => {
        group.SubFolders?.forEach((sub, index) => {
          const expanded = !!sub.Items.find(item => item.Identifier === selectedReporidentifier);
          if (sub.Expanded !== expanded) {
            group[index] = { ...sub, Expanded: expanded }
          }

          sub.SubFolders?.forEach((subsub, ssi) => {
            const expanded = !!subsub.Items.find(item => item.Identifier === selectedReporidentifier);
            if (subsub.Expanded !== expanded) {
              subsub.Expanded = expanded;
            }
          })
        });
      });

      this.folderModel.folderGroups.value = [...groups];
    }
  }
}
