import { IFilterTerm, Report } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';

export interface IFilteredField {
  field: AppField;
  term: IFilterTerm;
}

/**
 * Represents the filter parameters for a quick filter.
 * This includes an entry for each filterable field as defined on the App.
 * 
 * @property quickFields is the filter set for user filters. 
 * @property reportFields is fixed base filter.
 * 
 * Each entry has at least one  @see IFilterTerm entry in the terms array; this may be
 * undefined if no filter value is set for that field. In practice the report fields always
 * have a filter term.
 */
export class QuickFilter {

  public readonly quickFields: Array<IFilteredField>;

  public readonly reportFields: Array<IFilteredField>;

  constructor(app: Application, report: Report, terms?: Array<IFilterTerm>) {

    this.reportFields = this.createReportFilters(app, report);

    const quickFields: Array<IFilteredField> = [];
    const ids = app.FilterFieldIdentifiers ?? app.AdvancedFilterFieldIdentifiers;
    ids?.forEach((i) => {
      const field = app.getField(i.Identifier);
      if (field?.isFilterable()) {
        const term = terms?.find(t => t.FieldIdentifier === i.Identifier);
        quickFields.push({ field, term });    // will push null for field with no filter applied
      }
    });

    this.quickFields = quickFields;
  }

  /** Create a filter with no active terms */
  public emptyFilter() {
    return this.quickFields.map(qf => qf.term ?? {
      FieldIdentifier: qf.field.Identifier,
      Operator: undefined,
      Operand: undefined
    });
  }

  private createReportFilters(app: Application, report?: Report) {
    if (report) {
      const reportFields: Array<IFilteredField> = [];

      report.Filter?.forEach((term) => {

        const field = app.getField(term.FieldIdentifier);

        const existing = reportFields.find(ff => ff.field.Identifier === term.FieldIdentifier);
        if (existing) {
          // todo how to handle this... guess we need to merge eq into oneof etc
          // existing.terms.push(term);
        } else {
          reportFields.push({ field, term })
        }
      });

      return reportFields;
    }

    return [];
  }
}
