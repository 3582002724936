import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ImageListsService } from 'app/services/image-lists.service';
import { logError } from '@softools/softools-core';

@Injectable()
export class ImageListsExistsGuard implements CanActivate {

  constructor(
    private imageListsService: ImageListsService
  ) { }

  public async canActivate(): Promise<boolean> {
    try {
      await this.imageListsService.refresh();
      // A site might have no image lists so alwasy accept
      return true;
    } catch (error) {
      logError(error, 'ImageListsExistsGuard rejected navigation due to error');
      throw error;
    }
  }
}
