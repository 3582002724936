<div (click)="onClicked($event)"
cdkOverlayOrigin #origin="cdkOverlayOrigin"
>
  <app-input-icon
    icon="caret-down"
    [clickable]="!getIsDisabled()"
    [elementStyles]="elementStyles"
    (iconClicked)="onClicked($event)"
    [class.pointer]="!getIsDisabled()">

      <input
        readonly="readonly"
        [disabled]="getIsDisabled()"
        [value]="displayText()"
        #input
        class="selection w-100 readonly-normal-appearance form-control  form-control-o"
        [class.inline-control]="inline"
        style="padding-right: 2rem; text-overflow: ellipsis;"
        [ngClass]="{ 'inline': inline, 'readonly-normal-appearance': !getIsDisabled() }"
        [attr.data-identifier]="fieldModel.Identifier"
        (keydown)="onKeyDown($event)"
        (keyup)="onKeyUp($event)"
        (keypress)="onKeyPress($event)"
      />
  </app-input-icon>

  <app-popup-container [isOpen]="overlayOpen"
    [connectedOrigin]="origin"
    [connectedOffsetY]="4"
    [transparentBackdrop]="true"
    (closed)="popupClosed()">
    <ng-template>
      <div class="item-list w-100 d-flex flex-column mat-menu-panel mat-elevation-z4 p-2" >
        <mat-checkbox *ngFor="let option of selectList?.SelectListOptions" class="px-2" [checked]="isChecked(option.Value)"
          [disableRipple]="true" (change)="onSelection(option.Value, $event)">
          <span class="me-4">{{option.Text}}</span>
        </mat-checkbox>
      </div>
    </ng-template>
  </app-popup-container>

</div>
