import { Component, OnInit, Input, TemplateRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { CameraComponent, CodeDetails } from '../camera/camera.component';
import { logError } from '@softools/softools-core';
import { BehaviorSubject } from 'rxjs';
import { RecordPatch } from 'app/workspace.module/types';

@Component({
  selector: 'app-barcode-field',
  templateUrl: './barcode-field.component.html',
  styleUrls: ['./barcode-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarcodeFieldComponent extends EditableFieldBase implements OnInit {

  public summary: string;

  public disableCamera = false;

  public confidence = 0;

  public minConfidence = 90;

  public showControls = false;

  @ViewChild('camera')
  private camera: CameraComponent;

  public showCamera$ = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }

  public override ngOnInit() {

    try {
      super.ngOnInit();

      // console.log(this);
    } catch (error) {
      logError(error, '');
    }
  }

  public cameraVideoWidth = (): number => {
    const size = Math.min(window.innerWidth, window.innerHeight);
    const width = (size > 1024) ? 1024 : (size > 640) ? 640 : 320;
    // console.log(`width = ${width} (${size})`);
    return width;
  }

  public cameraVideoHeight = (): number => {
    const size = Math.min(window.innerWidth, window.innerHeight);
    const height = (size > 1024) ? 768 : (size > 640) ? 480 : 160;
    // console.log(`height = ${height} (${size})`);
    return height;
  }

  public captureClicked = () => {
    this.showCamera$.next(!this.showCamera$.value);
  }

  public cameraAttached = (template: TemplateRef<any>): void => {
    // console.log(template);
  }

  public cameraClosed(): void {
    this.camera.stop();
    this.showCamera$.next(false);
  }

  public onCameraCaptured = (details: CodeDetails) => {
    if (details) {
      if (details.confidence > this.minConfidence) {
        this.fieldValue = details.code;
        this.confidence = details.confidence;
        this.cameraClosed();

        const patch = this.fieldModel.createPatch(this.record, details.code, this.listRow);
        this.dispatchPatchAsync(patch).catch(err => logError(err, 'onCameraCaptured'));
      }
    } else {
      this.cameraClosed();
    }
  }

  public openControlPanel($event: MouseEvent) {
    $event?.stopPropagation();
    if (!this.getIsDisabled()) {
      this.showControls = true;
    }
  }

  public clear($event: MouseEvent) {
    $event?.stopPropagation();
    this.dispatchChange('');
  }

  public controlsClosed() {
    this.showControls = false;
  }
}
