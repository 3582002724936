import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ElementStyles, Enums, Record, Style, Template, TemplateLayout } from '@softools/softools-core';
import { AppModel } from 'app/mvc';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { HomeItem } from 'app/mvc/home/home-item.interface';
import { ICard, ICardMarginFields } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { TickApp } from 'app/vertex';
import { rotateCardanimation } from 'app/_constants/constants.animations';
import { BehaviorSubject } from 'rxjs';
import { ComponentBase } from '../component-base';


interface CardLayout extends TemplateLayout {

  field: AppField;

  EndRow: number;

  EndColumn: number;

  styles: ElementStyles;
}

interface MarginField extends ICardMarginFields {
  appField: AppField;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [rotateCardanimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent extends ComponentBase implements OnInit, OnChanges {

  @Input() appModel: AppModel;

  @Input() generalController: IGeneralController;

  @Input() card: ICard;

  @Input() template: Template;

  @Input() record: Record;

  @Input() homeItem: HomeItem;

  /** Group app field if card is being displayed in a group, null if ungrouped  */
  @Input() groupField: AppField;

  /** Currently visible template */
  @Input() public templateIndex = 0;

  @Input() canDrag = false;

  @Output() cardClicked = new EventEmitter();

  @Output() templateIndexChanged = new EventEmitter<number>();

  /** Fired when a drap operation starts on the card */
  @Output() cardDragging = new EventEmitter<Record>();

  public cardLayout: Array<CardLayout>;

  public lastColumn: number;

  public columnWidths: string;

  public leftMargin: Array<MarginField>;
  public rightMargin: Array<MarginField>;

  public cardStyles$ = new BehaviorSubject<ElementStyles>(null);

  public cardCss$ = new BehaviorSubject(null);

  public hasLeftMargin = false;
  public hasRightMargin = false;

  /** Valid templates (null if none, but should always be at least one) */
  private templates: Array<Template>;

  /** Requested next template index  */
  private nextTemplateIndex = -1;

  /** Active template */
  public template$ = new BehaviorSubject<Template>(null);

  public record$ = new BehaviorSubject<Record>(null);

  /** Animation trigger */
  public rotating$ = new BehaviorSubject('void');

  private defaultStyle: Style = {
    Name: '.default',
    Element: 'component',
    BackgroundColour: 'beige'
  };

  // constructor() { }

  ngOnInit(): void {

    this.rotating$.subscribe(_ => {
      TickApp.next(true);
    });

    // Get initial template from card
    const app = this.appModel.app.value;
    this.templates = this.card.Templates?.map(t => {
      if (t.TemplateIdentifier) {
        return app.Templates.find(c => c.Identifier === t.TemplateIdentifier);
      } else if (t.Template) {
        return t.Template;
      }

      return null;
    })?.filter(t => !!t);

    const template = this.templates?.[this.templateIndex];
    if (template) {
      template.IsReadOnly = true;
    }

    this.setMarginFlags();

    this.template$.next(template);
    this.layoutFields(template);

    this.record$.next(this.record);

    this.subscribe(this.record$, (record) => {
      if (record) {
        const styles = this.appModel.getNamedStyles({
          target: 'card',
          names: this.card.NamedStyles,
          additionalStyles: [this.card.Style, this.defaultStyle],
          record,
          borderSideOverride: 'top'
        });
        this.cardStyles$.next(styles);

        const compStyle = styles.component.css ?? {};
        const borderStyle = styles['border']?.css ?? {};
        this.cardCss$.next({ ...compStyle, ...borderStyle });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['record'] && this.record) {
      const id = this.record._id;
      this.subscribe(this.appModel.updatedRecord$, (records) => {
        const record = records?.find(r => r._id === id);
        if (record) {
          this.record$.next(record);
        }
      });
    }
  }


  public getField(layout: TemplateLayout) {
    return this.appModel.app.value.getField(layout.FieldIdentifier);
  }

  public cardBodyClicked($event: MouseEvent) {
    $event?.stopPropagation();
    this.cardClicked.emit();
  }

  public flipClicked($event: MouseEvent) {
    $event.stopPropagation();

    if (this.templates?.length > 1) {
      // Set requested index but don't change template until first half of animation
      // has completed and the card is edge on.
      this.nextTemplateIndex = (this.templateIndex + 1) % this.templates.length;
      this.rotating$.next('presto');
    }
  }

  public animated($event: AnimationEvent) {

    if ($event.phaseName === 'done') {

      switch ($event.toState) {
        case 'presto': {
          if (this.nextTemplateIndex >= 0) {
            this.templateIndex = this.nextTemplateIndex;
            const template = this.templates[this.templateIndex];
            this.template$.next(template);
            this.layoutFields(template);
            this.templateIndexChanged.emit(this.templateIndex);
          }

          this.rotating$.next('chango');
          break;
        }

        case 'chango':
          this.rotating$.next('void');
          break;
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  // Drag and drop

  public isDraggable() {
    // Don't allow drag drop if group field is disabled for any reason
    if (this.groupField?.isDisabled(this.record)) {
      return false;
    }

    return this.canDrag;
  }

  public dragged($event: DragEvent) {
    $event.dataTransfer.setData('text/plain', this.record._id);
    $event.dataTransfer.dropEffect = 'move';
    this.cardDragging.emit(this.record);
  }

  public dragEnded($event: DragEvent) {
    $event.preventDefault();
    $event.dataTransfer.clearData();
    this.cardDragging.emit(null);
  }

  //////////////////////////////////////////////////////////////////


  private layoutFields(template: Template) {

    if (!template) {
      return;
    }

    // this is likely to be the same for every card in a report etc. - look at pushing up to parent component
    let lastCol = 0;
    const cardLayout: Array<CardLayout> = [];
    template.Layout.forEach(layout => {

      // Find last column so we can handle old-style spans (means field spans to last column)
      if (layout.ColumnId > lastCol) {
        lastCol = layout.ColumnId;
      }

      const field = this.appModel.app.value.getField(layout.FieldIdentifier);

      const ns: Array<{ StyleName: string }> = [];
      if (layout.NamedStyles?.length > 0) {
        ns.push(...layout.NamedStyles);
      }
      if (field.NamedStyles?.length > 0) {
        ns.push(...field.NamedStyles);
      }

      const styles = this.appModel.getNamedStyles({ target: 'field', names: ns, additionalStyles: [layout.Style] });

      cardLayout.push({
        ...layout,
        field,
        EndRow: layout.RowId + (layout.SpanRows ?? 0) + 1,
        EndColumn: layout.ColumnId + (layout.SpanColumns ?? 0) + 1,
        styles
      });
    });

    // Extend all span columns to end of grid
    cardLayout.forEach(layout => {
      if (layout.Mode === Enums.BuilderRowMode.Span) {
        layout.EndColumn = lastCol + 1;
      }
    });

    this.lastColumn = lastCol + 1;
    this.cardLayout = cardLayout;

    // Caclculate column widths
    // Find largest fixed size field for each column
    const fixedSizes = new Array<number>(lastCol).fill(0);
    const variableSizes = new Array<boolean>(lastCol).fill(false);
    cardLayout.forEach(layout => {
      const size = this.fixedFieldWidth(layout.field);
      if (size > 0) {
        if (size > fixedSizes[layout.ColumnId - 1]) {
          fixedSizes[layout.ColumnId - 1] = size;
        }
      } else {
        if (layout.Mode === Enums.BuilderRowMode.Column) {
          variableSizes[layout.ColumnId - 1] = true;
        } else {
          variableSizes.fill(true, layout.ColumnId);
        }
      }
    });

    const columnSizes = new Array<string>(lastCol);
    variableSizes.forEach((isVar, index) => {
      if (isVar) {
        columnSizes[index] = '1fr';
      } else {
        columnSizes[index] = `${fixedSizes[index]}rem`;
      }
    });

    this.columnWidths = columnSizes.join(' ');
    this.leftMargin = this.marginFields('left');
    this.rightMargin = this.marginFields('right');

    this.setMarginFlags();
  }

  private setMarginFlags() {
    this.hasRightMargin = this.rightMargin?.length > 0 || this.templates?.length > 1;
  }

  private marginFields(side: string): Array<MarginField> {
    return this.card.MarginFields?.filter(mf => mf.Side === side)
      .sort((mf1, mf2) => mf1.DisplayOrder - mf2.DisplayOrder)
      .map(mf => {
        if (mf.FieldIdentifier) {
          return { ...mf, appField: this.appModel.app.value.getField(mf.FieldIdentifier) };
        } else if (mf.Field) {
          const appField = this.appModel.app.value.createAppField(mf.Field);
          return { ...mf, appField: appField };
        }

        return null;
      });
  }

  private fixedFieldWidth(field: AppField): number {
    switch (field?.Type) {
      // Small and icon fields have fixed size
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Bit:
      case Enums.FieldType.Notes:
      case Enums.FieldType.ListField:
      case Enums.FieldType.GridField:
      case Enums.FieldType.EmbeddedVideo:
        return 4;
      default:
        return 0;
    }
  }

  private setStyles() {
    // if (this.app) {
    //   if (this.formModel?.NamedStyles) {
    //     this.styles = this.appModel.getNamedStyles(this.formModel.NamedStyles);
    //   } else {
    //     this.styles = null;
    //   }
    // }
  }


}
