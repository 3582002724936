import { Injectable } from '@angular/core';
import { AppDataStorageService, Record, RecordCopyInfo } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { RecordPatch } from 'app/workspace.module/types';
import { RecordPersistService } from './record/record-persist.service';

export interface InitialRecordOptions {
  hierarchy?: string;

  values?: {
    [key: string]: string;
  };
}

@Injectable({ providedIn: 'root' })
export class AppDataService {

  constructor(
    private storage: AppDataStorageService,
    private recordPersistenceService: RecordPersistService) {
  }

  /**
   * Copy a record
   *
   * @param app
   * @param sourceId Source record id
   * @param copyInfo Copy configuration
   * @param hierarchy Option hierarchy string for child records
   */
  public async copyRecordAsync(app: Application, sourceId: string, copyInfo: RecordCopyInfo, hierarchy?: string): Promise<Record> {
    if (app.UseOfflineCopy) {
      const record = await this.copyRecordLocally(app, sourceId, copyInfo, hierarchy);
      return record;
    } else {
      return await this.storage.copyRecordAsync(app, sourceId, copyInfo, hierarchy);
    }
  }

  public async createInitialRecord(app: Application, options?: InitialRecordOptions): Promise<Record> {
    const newId = app.validateRecordId(null);
    if (newId && newId !== true) {
      const patch = new RecordPatch(newId, app.Identifier, options?.hierarchy);
      patch._new = true;
      app.AppFields.forEach(field => {
        field.updatePatchWithDefault(patch);
      });

      // Set initial values - these will override any defaults
      if (options.values) {
        this.setInitialValues(patch, app, options.values);
      }

      // Insert the patch into the queue
      await this.recordPersistenceService.queueChangeImmediate(patch);
      // Return as record
      return patch.toRecord(app);
    }
    return null;
  }

  private async copyRecordLocally(app: Application, sourceId: string, copyInfo: RecordCopyInfo, hierarchy?: string): Promise<Record> {

    const source = await app.getRecordByIdAsync(sourceId);

    const newId = app.validateRecordId(null);
    if (newId && newId !== true) {

      const patch = new RecordPatch(newId, app.Identifier, hierarchy);
      patch._new = true;
      app.AppFields.forEach(field => {
        if (!field.ExcludeFromTemplateCopy || !copyInfo.TemplateCopy) {
          field.updatePatch(patch, source);
        }
      });

      // Insert the patch into the queue
      await this.recordPersistenceService.queueChangeImmediate(patch);

      const record = patch.toRecord(app);
      return this.storage.mutateRecord(app, record);
    }

    return null;
  }

  private setInitialValues(patch: RecordPatch, app: Application, values: { [key: string]: string; }) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const param = values[key];
        const field = app.getField(key);
        if (field) {
          field.updatePatchFromStringValue(patch, param);
        }
      }
    }
  }
}
