import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-new-record-button',
  templateUrl: './new-record-button.component.html',
  styleUrls: ['./new-record-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewRecordButtonComponent {

  @Input() canCreate = false;
  @Input() createUrl = '';
  @Input() floater = true;

  constructor() { }
}
