import { Injectable } from '@angular/core';
import { Report, Enums, Record, AppDataStorageService, IndexedAppData, ChartField } from '@softools/softools-core';
import * as moment from 'moment';
import { AppField } from 'app/types/fields/app-field';
import { StorageModeService } from 'app/services/storage-mode.service';
import { AppService } from 'app/services/app.service';
import { Application } from 'app/types/application';
import { ChartEnums } from '../chart.enums';
import { ChartColourService } from '../chart-colour.service';

export interface ChartFieldEx extends ChartField {
  BaseAppField: AppField;
}

@Injectable({
  providedIn: 'root'
})
export class BaseChartDataService {

  constructor(
    public appService: AppService,
    public appDataService: AppDataStorageService,
    public chartColourService: ChartColourService,
    private storageModeService: StorageModeService
  ) { }

  protected getInAppRecordData(app: Application, report: Report, record: Record): Array<any> {

    if (report.Chart.ChartType === Enums.ChartType.gauge) {
      return [record];
    }

    const inAppDataSourceIdentifier = report.InAppDataSourceFieldIndentifier;
    let inAppDataSource: AppField;
    if (inAppDataSourceIdentifier) {
      inAppDataSource = app.getField(inAppDataSourceIdentifier);
    } else {
      throw new Error('No In-App data source field set');
    }

    const gridHeaders = inAppDataSource?.GridDataSets?.map((dataSet) => dataSet.Label);
    if (gridHeaders) {
      const value = inAppDataSource.getRawRecordValue(record);
      if (value) {
        const gridValues = value.map(data => {
          const dataPoint = {};
          inAppDataSource.SubFieldsIdentifiers.map((subField, i) => {
            dataPoint[`${subField.Identifier}`] = data[i];
          });
          return dataPoint;
        }) as Array<any>;

        const outputData = gridValues.map((data, i) => ({ ...data, GridColumnTitleField: gridHeaders[i] }));
        return outputData;
      } else {
        return new Array(inAppDataSource.GridDataSets.length).fill({});
      }
    } else {
      throw new Error('Invalid-App data source field');
    }
  }

  protected async getRecordData(app: Application, inApp: boolean, appDataIndex: IndexedAppData, report: Report, recordId?: string, record?: Record) {
    if (inApp) {
      // const app = this.appService.application(appDataIndex.app.Identifier);
      // let record: Record;

      if (!record) {
        if (!this.storageModeService.getReportAvailableOffline(app.Identifier, report.Identifier)) {
          record = await this.appDataService.getRecordByIdAsync(recordId);
        } else {
          record = await this.appDataService.getApiRecordByIdAsync(appDataIndex.app, recordId);
        }
      }


      if (report.Chart.ChartType === Enums.ChartType.gauge) {
        return [record];
      }

      const inAppDataSourceIdentifier = report.InAppDataSourceFieldIndentifier;
      let inAppDataSource: AppField;
      if (inAppDataSourceIdentifier) {
        inAppDataSource = app.getField(inAppDataSourceIdentifier);
      } else {
        throw new Error('No In-App data source field set');
      }

      const gridHeaders = inAppDataSource.GridDataSets.map((dataSet) => dataSet.Label);

      const value = inAppDataSource.getRawRecordValue(record);
      if (value) {
        const gridValues = value.map(data => {
          const dataPoint = {};
          inAppDataSource.SubFieldsIdentifiers.map((subField, i) => {
            dataPoint[`${subField.Identifier}`] = data[i];
          });
          return dataPoint;
        }) as Array<any>;

        const outputData = gridValues.map((data, i) => ({ ...data, GridColumnTitleField: gridHeaders[i] }));
        return outputData;
      }

      return [];
    } else {
      return appDataIndex.getRecords(0, appDataIndex.length);
    }
  }

  protected getFieldValue(app: Application, identifier: string, field: AppField, record: any, appDataIndex: IndexedAppData) {
    if (identifier.includes('_')) {
      return this.getGridFieldValue(app, identifier, record, appDataIndex);
    } else {
      return field.getRawRecordValue(record);
    }
  }

  protected getGridFieldValue(app: Application, identifier: string, record: any, appDataIndex: IndexedAppData) {
    const identifiers = identifier.split('_');
    const gridField = app.getField(identifiers[0]);
    const datasetIndex = gridField.GridDataSets.findIndex(dataset => dataset.Identifier === identifiers[1]);
    const subfieldIndex = gridField.SubFieldsIdentifiers.findIndex(subfield => subfield.Identifier.split('_')[1] === identifiers[2]);
    const gridValue = record[identifiers[0]];
    const gridRow = gridValue && gridValue[datasetIndex];
    return gridRow && gridRow[subfieldIndex] || undefined;
  }

  protected formatHoverText(row: Record, field: AppField): string {
    if (row && row[field.Identifier]) {
      switch (field.Type) {
        case Enums.FieldType.Period:
          return (moment(row[field.Identifier])).format('MM YYYY');
        case Enums.FieldType.Date:
          return (moment(row[field.Identifier])).format('dddd, MMMM DD, YYYY');
        case Enums.FieldType.Time:
          return (moment(row[field.Identifier])).format('dddd, MMMM DD, YYYY h:mm A');
        default:
          return row[field.Identifier].toString();
      }
    }
    return undefined;
  }

  protected getChartFieldEx(app: Application, report: Report, type: ChartEnums.ChartFieldType): ChartFieldEx {
    const chartField = report.Chart.ChartFields.find((field) => field.Type === type);
    return chartField && {
      ...chartField,
      BaseField: app.getField(chartField.BaseFieldIdentifier),
      BaseAppField: app.getField(chartField.BaseFieldIdentifier)
    };
  }

  protected getChartFieldsEx(app: Application, report: Report, type: ChartEnums.ChartFieldType): Array<ChartFieldEx> {
    return report.Chart.ChartFields.filter(field => field.Type === type)
      .sort((a, b) => a.Order - b.Order)
      .map(field => ({
        ...field,
        BaseField: app.getField(field.BaseFieldIdentifier),
        BaseAppField: app.getField(field.BaseFieldIdentifier)
      }));
  }
}
