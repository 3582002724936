<div *ngIf="!textClass" [style.text-align]="alignment">
  <div
    #button
    tabindex="-1"
    class="position-relative button-container mw-100"
    [style.width]="!getFill() ? getWidth().toString() + 'rem' : '100%'"
    [title]="getTitle()"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"
    >
      <app-asset-image
      class="action-button"
      [class.inactive-button]="workflowQueued"
      [assetUrl]="imageUri"
      [width]="getWidth()"
      [fill]="getFill()"
      [class.pointer]="showPointer"
      [class.clicked]="(clicked$|async) || workflowQueued"
      (clicked)="onButtonClickHandler($event.$event)"
      >
    </app-asset-image>
    <div [style.fontSize.px]="getOverlayIconSize()" *ngIf="(clicked$|async) || workflowQueued" class="overlay ">
      <fa-icon  class="icon" [icon]="['fal', 'check']"></fa-icon>
    </div>
  </div>

  <br>
  <ng-container *ngIf="workflowQueued">
    <button mat-raised-button class="bg-st-theme-primary text-white" *ngIf="workflowQueued" (click)="cancelWorkflow()" i18n="@@CancelWorkflow">Cancel Workflow</button>
  </ng-container>
</div>

<button *ngIf="textClass"
  class="padded"
  [ngClass]="textClass"
  [style.text-align]="alignment"
  [disabled]="isDisabled" 
  (click)="onButtonClickHandler($event)"
>
  {{fieldModel.Label}}
</button>
