
import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { SavedFilter, Filter, SavedFilterResource, TrackChangeList } from '../types';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SavedFiltersRepository extends RepositoryBase {

  private accessLevels = ['Personal', 'Team', 'Global'];

  public getLastUsed(appIdentifier: string, reportIdentifier: string, hierarchy: string): Promise<any> {
    let url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'SavedFilters/LastUsed', reportIdentifier);
    url = this.urlResolver.prependHierarchy(url, hierarchy);
    return this.get(url).toPromise();
  }

  public saveLastUsed(appIdentifier: string, reportIdentifier: string, data: Filter, hierarchy: string): Promise<any> {
    let url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'SavedFilters/LastUsed', reportIdentifier);
    url = this.urlResolver.prependHierarchy(url, hierarchy);
    return this.post(url, JSON.stringify(data)).toPromise();
  }

  public getAllSavedFilters(): Promise<Array<SavedFilter>> {
    const url = this.urlResolver.resolveApiUrl('SavedFilters');
    return firstValueFrom(this.get(url));
  }

  public getSavedFilters(appIdentifier: string): Promise<Array<SavedFilter>> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'SavedFilters');
    return firstValueFrom(this.get(url));
  }

  public getSavedGlobalFilters(appIdentifier: string): Promise<Array<SavedFilter>> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'SavedFilters', 'Global');
    return this.get(url).toPromise();
  }

  public saveFilter(appIdentifier: string, filter: SavedFilter): Promise<SavedFilter> {
    const accessLevel = this.accessLevels[filter.AccessLevel];
    const patch: SavedFilterResource = this.toResource(filter);
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'SavedFilters', accessLevel);
    return lastValueFrom<SavedFilter>(this.post(url, patch));
  }

  public updateFilter(appIdentifier: string, filter: SavedFilter): Promise<any> {
    const patch: SavedFilterResource = this.toResource(filter);
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'SavedFilters', filter.Id);
    return this.patch(url, patch).toPromise();
  }

  public deleteFilter(appIdentifier: string, filter: SavedFilter): Promise<any> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, `SavedFilters`, filter.Id);
    return this.del(url).toPromise();
  }

  /** Convert to format expected by server */
  private toResource(filter: SavedFilter): SavedFilterResource {
    return {
      Identifier: filter.Id,
      Description: filter.Description,
      Filter: filter.Filter,
      Group: filter.IsGroupDescending ? `${filter.Group} desc` : filter.Group,
      OrderBy: filter.IsOrderDescending ? `${filter.OrderBy} desc` : filter.OrderBy,
      IsHidden: filter.IsHidden,
      IsDefault: filter.IsDefault,
      AccessLevel: filter.AccessLevel,

      // We need to provide a track change list
      // Currently we're not setting team ids so an empty on will do but really should pass in a patch
      // TeamIds: filter.TeamIds,
      TeamIds: new TrackChangeList(),
    };
  }
}
