<div *ngIf="forReport" [style.text-align]="alignment">
  <fa-icon class='pointer st-text-theme-primary' [icon]="['fal', 'chart-bar']" size="1x" aria-hidden='true' (click)="onToggleInAppChartForReportHandler($event)">
  </fa-icon>
</div>

<div *ngIf="!recordId" class="empty-message">
  (In App Chart will display once record created)
</div>

<div *ngIf="!forReport && recordId" [style.text-align]="alignment"
[style.min-height.px]="report?.Chart.Height"
>
  <div *ngIf="isLoading$|async">
    <sof-spinner-icon class="st-text-theme-primary loader"></sof-spinner-icon>
  </div>
  <div *ngIf="!(isLoading$|async)"
    [style.min-height.px]="report?.Chart.Height"
  >
    <app-chart
      [globalModel]="globalModel"
      [chartContainerId]='chartContainerIdVal'
      [displayChart]='true'
      [displayMatrixTable]='false'
      [chartConfig]='chartConfig'
      [chartData]='chartData$|async'
      [chartType]='chartType'
      [reportType]='reportType'
      [appIdentifier]='appIdentifier'
      [showTitle]='true'
      [showSubTitle]='true'
      [templateExpanded]="templateExpanded"
      [forceRerenderOnResize]='forceRerenderOnResize'
      [isInAppChart]=true>
    </app-chart>
  </div>
</div>
