import { OnlineStatusService } from '../services';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export interface IRetryPolicy {
  execute<T>(call: () => Promise<T>): Promise<T>;
}

/** Execute API call with configured policy */
export class RetryPolicy implements IRetryPolicy {

  public numberOfRetries = Infinity;

  public msDelayBetweenRetries = 5000;

  /**
   * If set, the online status service is used to check whether
   * we are connected to the server before attempting retries.
   * If left as null, no such check is made and only HTTP errors
   * cause a retry
   */
  public onlineStatus: OnlineStatusService = null;

  public constructor(
    /** Optional title to include in log messages */
    public title = ''
  ) { }

  /**
   * Call a function (usually a remote API call) with retries
   *
   * @param call
   */
  public async execute<T>(call: () => Promise<T>): Promise<T> {

    let retriesLeft = this.numberOfRetries;
    let busyRetryDelay = 300;

    while (retriesLeft-- > 0) {
      try {

        // If we are configured to check online state, wait until we seem
        // to be connected.  These count against the number of retries
        if (this.onlineStatus) {
          while (retriesLeft > 0 && !this.onlineStatus.isConnected) {
            await new Promise(resolve => setTimeout(resolve, this.msDelayBetweenRetries));
          }
        }

        // We appear to be connected (or decided not to care) so make the call
        return await call();
      } catch (error) {
        // Retry for approptiate HTTP errors
        if (error instanceof HttpErrorResponse) {
          console.warn(`API ${this.title} error, ${retriesLeft} retries left`, error);
          switch (error.status) {
            case HttpStatusCode.NotFound:
              // Won't succeed, stop retrying
              retriesLeft = 0;
              break;
            case HttpStatusCode.TooManyRequests:
              // Server busy, add an extra increasing and randomised delay
              // before next retry
              if (busyRetryDelay < 30_000) {
                busyRetryDelay += 300 + (Math.random() * 200);
              }
              await new Promise(resolve => setTimeout(resolve, busyRetryDelay));
              break;
            case HttpStatusCode.BadRequest:
            default:
              // Keep retrying.
              break;
          }

        } else if (error instanceof SyntaxError) {
          // Invalid JSON returned. Only allow a single retry so we don't
          // keep calling a broken API
          if (retriesLeft > 1) {
            retriesLeft = 1;
          }
        } else {
          throw error;
          // logError(error, '');
          // break;
        }
      }
    }

    throw new Error(`API ${this.title} failed after ${this.numberOfRetries} attempts`);
  }
}

/** Execute API call without retries */
export class NoRetryPolicy implements IRetryPolicy {
  public static instance = new NoRetryPolicy();
  public async execute<T>(call: () => Promise<T>): Promise<T> {
    return call();
  }
}
