<div #notificationOptions style="display: none">
    <option value="0" i18n>Email</option>
</div>

<div #languageOptions style="display: none">
  <option value="ar" i18n>Arabic</option>
  <option value="zh" i18n>Chinese</option>
  <option value="cz" i18n>Czech</option>
  <option value="nl" i18n>Dutch</option>
  <option value="en" i18n>English</option>
  <option value="fr" i18n>French</option>
  <option value="de" i18n>German</option>
  <option value="hi" i18n>Hindi</option>
  <option value="gu" i18n>Gujarati</option>
  <option value="it" i18n>Italian</option>
  <option value="ja" i18n>Japanese</option>
  <option value="pt" i18n>Portuguese</option>
  <option value="sk" i18n>Slovak</option>
  <option value="es" i18n>Spanish</option>
  <option value="vi" i18n>Vietnamese</option>
</div>

