<div #display class="text-container"
  [class.display]=" !useReadonlyStyle()"
  [class.readonly]=" useReadonlyStyle()"
  [class.report-control]="padding && forReport"
  [class.form-control]="padding && !forReport"
  [class.form-control-o]="padding && !forReport"
  [class.text-container]="padding"
  [class.text-truncate]="forReport"
  [class.no-readonly-underscore]="hideUnderline || fieldModel.clickToEdit"
  [class.pointer]="fieldModel.clickToEdit"
  tabindex="-1"
  [style.text-align]="alignment"
  [ngStyle]="textStyle"
  [class.report-text-display]="forReport"
  [style.height]="textHeight()"
  [style.min-width.rem]="minWidthRem"
  [class.long-text-row-limit]="fieldModel?.Rows && !forReport"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
  (click)="onClick($event)"
  [attr.data-identifier]="fieldModel?.Identifier"
></div>
