import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { Observable } from 'rxjs';
import { SiteSettings } from '../types';

@Injectable({ providedIn: 'root' })
export class SiteSettingsRepository extends RepositoryBase {
  public getAll(): Observable<SiteSettings> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('Site'));
  }

  public save( siteid: number, changes: any ) {
    const url = this.urlResolver.resolveDefaultApiUrl('Site', `${siteid}`);
    return this.patch(url, JSON.stringify(changes));
  }
}
