import { Injectable, Injector } from '@angular/core';
import { UrlTree, UrlSegmentGroup, Router } from '@angular/router';

import { RecordId, CoreConfigModel, CoreConfigModelToken } from '../types';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UrlResolver {
  constructor(private router: Router, private injector: Injector) { }

  public servicePath(controller?: string) {
    try {
      // Get configuration.
      // If new injection token not set fall back to deprecated function
      // todo delete fallback when workspace updated
      const coreConfigModel = this.injector.get<CoreConfigModel>(CoreConfigModelToken, this.injector.get('CoreConfigModel'));

      switch (controller) {
        case 'Users':
          return coreConfigModel.userApiBaseUrl || coreConfigModel.environmentBaseUrl;
        default:
          return coreConfigModel.environmentBaseUrl;
      }
    } catch {
      return 'https://staging-api.on.softools.net';
    }
  }

  public resolveAppRelativeUrl(
    appIdentifier: string,
    controller: string,
    id: string = '',
    hierarchy = null,
    filter: string = '',
    search: string = ''
  ) {
    let suffix = '';
    if (id) {
      suffix = suffix + '/' + id;
    }

    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    if (hierarchy) {
      suffixes.push('hierarchy=' + hierarchy);
    }

    if (suffixes.length > 0) {
      suffix = suffix + '?' + suffixes.join('&');
    }

    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/' + controller + suffix;
  }

  public resolveApiUrl(controller: string, id?: string, filter?: string, search?: string) {
    let suffix = '';
    if (id) {
      suffix = suffix + '/' + id;
    }

    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    if (suffixes.length > 0) {
      suffix = suffix + '?' + suffixes.join('&');
    }

    return this.servicePath() + '/Api/' + controller + suffix;
  }

  public resolveAppDataUrl(
    appIdentifier: string,
    controller: string = '',
    id: string = '',
    hierarchy: string = '',
    filter: string = '',
    search: string = '',
    reportId?: number
  ) {
    let suffix = '';
    if (id) {
      suffix = suffix + '/' + id;
    }

    if (controller) {
      suffix = suffix + '/' + controller;
    }

    if (reportId) {
      suffix = suffix + '/' + reportId;
    }

    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    if (hierarchy) {
      suffixes.push('hierarchy=' + hierarchy);
    }

    if (suffixes.length > 0) {
      suffix = suffix + '?' + suffixes.join('&');
    }

    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/Data' + suffix;
  }

  /**
   * Get URL for app data patch API
   * @param appIdentifier App Identifier
   * @param id The record id for the patch
   * @param hierarchy Optional hierarchy.
   */
  public resolvePatchAppDataUrl(appIdentifier: string, id: string, hierarchy: string = '') {
    if (!id) {
      throw new Error(`Record Id for patching App '${appIdentifier}' does not exist`);
    }

    if (hierarchy) {
      return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${id}?hierarchy=${hierarchy}`;
    } else {
      return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${id}`;
    }
  }

  /**
   * Get URL for bulk archive API
   * @param appIdentifier App Identifier
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   * @param hierarchy Optional hierarchy.  If specified only matching child records are archived.
   */
  public resolveBulkArchiveUrl(appIdentifier: string, reportId: number, hierarchy?: string) {
    if (hierarchy) {
      return `${this.baseAppUrl(appIdentifier)}/Data/Archive/${reportId}/${hierarchy}`;
    } else {
      return `${this.baseAppUrl(appIdentifier)}/Data/Archive/${reportId}`;
    }
  }

  /**
   * Get URL for bulk unarchive API
   * NB The server API does not include a hierarchy.
   *
   * @param appIdentifier App Identifier
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   */
  public resolveBulkUnarchiveUrl(appIdentifier: string, reportId: number, hierarchy?: string) {
    if (hierarchy) {
      return `${this.baseAppUrl(appIdentifier)}/Data/Unarchive/${reportId}/${hierarchy}`;
    } else {
      return `${this.baseAppUrl(appIdentifier)}/Data/Unarchive/${reportId}`;
    }
  }

  /**
   * Get URL for bulk delete API
   *
   * @param appIdentifier App Identifier
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   * @param hierarchy Optional hierarchy.  If specified only matching child records are deleted.
   */
  public resolveBulkDeleteUrl(appIdentifier: string, reportId: number, hierarchy?: string) {
    if (hierarchy) {
      return `${this.baseAppUrl(appIdentifier)}/Data/Archived/${reportId}/${hierarchy}`;
    } else {
      return `${this.baseAppUrl(appIdentifier)}/Data/Archived/${reportId}`;
    }
  }

  /**
   * Get URL for bulk set access rights
   * @param appIdentifier App Identifier
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   * @param hierarchy Optional hierarchy.  If specified only matching child records are deleted.
   * @returns URL
   */
  public resolveBulkSetAccessRightsUrl(appIdentifier: string, reportId: number, hierarchy?: string) {
    if (hierarchy) {
      return `${this.baseAppUrl(appIdentifier)}/AppDataAccessRights/${reportId}/${hierarchy}`;
    } else {
      return `${this.baseAppUrl(appIdentifier)}/AppDataAccessRights/${reportId}`;
    }
  }


  public resolveAppDataV2Url(
    appIdentifier: string,
    controller: string = '',
    id: string = '',
    hierarchy: string = '',
    filter: string = '',
    search: string = '',
    reportId?: number
  ) {
    let suffix = '';
    if (id) {
      suffix = suffix + '/' + id;
    }

    if (controller) {
      suffix = suffix + '/' + controller;
    }

    if (reportId) {
      suffix = suffix + '/' + reportId;
    }

    if (hierarchy) {
      suffix = suffix + '/' + hierarchy;
    }

    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    // if (hierarchy) {
    //   suffixes.push('hierarchy=' + hierarchy);
    // }

    if (suffixes.length > 0) {
      suffix = suffix + '?' + suffixes.join('&');
    }

    return this.servicePath() + '/Api/v2/Apps/' + appIdentifier + '/Data' + suffix;
  }

  /**
   * Get the URL for synchrnonising all application data for an application.
   * @param appIdentifier
   * @param pageSize      Max number of records to retrieve
   * @param afterRecordId Base record id.  The set of records with ids following this id will be retrieved if specified
   */
  public resolveV2SyncUrl(appIdentifier: string, pageSize = 1000, afterRecordId?: RecordId) {
    const recordPart = afterRecordId ? `/${afterRecordId}` : '';
    const url = `${environment.baseUrl}/api/apps/${appIdentifier}/syncdata/${pageSize}${recordPart}`;
    return url;
  }

  /**
   * Get the URL for synchrnonising selective application data for an application.
   * @param appIdentifier
   */
  public resolveV2PartialSyncUrl(appIdentifier: string) {
    const url = `${this.servicePath()}/Api/v2/Apps/${appIdentifier}/SyncData/Partial`;
    return url;
  }

  /**
   * Get URL for querying app data group information
   * @param appIdentifier
   * @param descending
   * @param groupBy
   * @param start
   * @param count
   * @param archived
   */
  public resolveGroupDataUrl(appIdentifier: string, groupBy: string, descending: boolean, start: number, count: number, archived: boolean, hierarchy: string) {
    const fragment = archived ? 'archived' : 'data';
    const hierarchyPart = hierarchy ? `/${hierarchy}` : '';
    let url = `${environment.baseUrl}/api/apps/${appIdentifier.toLowerCase()}/${fragment}/groups/${groupBy}${hierarchyPart}/${start}/${count}`;
    if (descending) {
      url += '/desc';
    }
    return url;
  }

  public resolveGroupCountUrl(appIdentifier: string, groupBy: string, hierarchy: string) {
    const hierarchyPart = hierarchy ? `/${hierarchy}` : '';
    const url = `${environment.baseUrl}/api/apps/${appIdentifier.toLowerCase()}/data/groups/${groupBy}${hierarchyPart}`;
    return url;
  }

  /**
   * Get generic URL for v2 api
   * @param controller
   * @param extras  optional addition extra paths
   */
  public resolveV2Url(controller: string, ...extras: string[]) {
    const url = extras ? `${this.servicePath()}/Api/v2/${controller}/${extras.join('/')}` : `${this.servicePath()}/Api/v2/${controller}`;
    return url;
  }

  public resolveAppDataExportUrl(
    appIdentifier: string,
    controller: string,
    hierarchy: string,
    filter: string,
    orderBy: string,
    search: string
  ) {
    let suffix = '';

    if (controller) {
      suffix = suffix + '/' + controller;
    }

    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    if (hierarchy) {
      suffixes.push('hierarchy=' + hierarchy);
    }

    if (orderBy) {
      suffixes.push('$orderby=' + orderBy);
    }

    if (suffixes.length > 0) {
      suffix = suffix + '?' + suffixes.join('&');
    }

    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/Data' + suffix;
  }

  public resolveImportAppDatUrlWithData(appIdentifier: string, hierarchy: string): string {
    const path = `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/PatchFromCsvWithData/${hierarchy}`;
    const useNewImport = localStorage.getItem('feat-useNewImport');
    if (!useNewImport) {
      return path;
    }

    return `${path}?useNewImport=${useNewImport}`;
  }

  public resolveAppDataIsSubscribedUrl(appIdentifier: string, controller: string, id: string) {
    let suffix = '';
    if (id) {
      suffix = suffix + '/' + id;
    }

    if (controller) {
      suffix = suffix + '/' + controller;
    }

    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/Data' + suffix + '/IsSubscribed';
  }

  public resolveAppDataImageUrl(appIdentifier: string, id: string, imageId: string) {
    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/Data/' + id + '/Images/' + imageId;
  }

  public resolveAppDataNotesUrl(appIdentifier: string, id: string, notesFieldId: string) {
    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/Data/' + id + '/Notes/' + notesFieldId;
  }

  public resolveReportDataUrl(appIdentifier: string, reportid: number, hierarchy = null, filter: string = '', search: string = '') {
    return this.resolveReportDataUrlByIdentifier(appIdentifier, reportid.toString(), hierarchy, filter, search);
  }

  public resolveAppDataCommentsUrl(appIdentifier: string, id: string): string {
    return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${id}/Comments`;
  }

  public resolveAppDataAttachmentsUrl(appIdentifier: string, id: string): string {
    return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${id}/Attachments`;
  }

  public resolveAppDataAttachmentsUrlWithData(appIdentifier: string, id: string, filename: string): string {
    return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${id}/Attachments/Data/${this.sanitiseFilename(filename)}`;
  }

  public resolveAppDataImagesUrl(appIdentifier: string, id: string): string {
    return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${id}/Images`;
  }

  public resolveAppDataImagesUrlWithData(appIdentifier: string, imgId: string, recordId: string): string {
    return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/${recordId}/Images/D/${imgId}`;
  }

  /** Reolve URL for ReportDataController Get Api  */
  public resolveGetReportDataUrl(appIdentifier: string,
    reportIdentifier: string | number,
    hierarchy = null,
    archived = false,
    filter: string = '',
    search: string = '') {
    const hierarchyPart = hierarchy ? `/${encodeURIComponent(hierarchy)}` : '';
    const query = this.getQuery(filter, search);    // exlcude hierarchy set above
    let url = `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/Report/${reportIdentifier}`;
    if (archived) {
      url += '/Archived';
    }
    return `${url}${hierarchyPart}${query}`;
  }

/**
 * Reolve URL for ReportDataController, path is "Api/Apps/{appIdentifier}/Data/Report".
 * Report id (as number or string id) is appended to that if supplied
 * Filter, search and hierarchy are set into query params if supplied
 *
 * @param appIdentifier
 * @param reportIdentifier
 * @param hierarchy
 * @param filter
 * @param search
 */
  public resolveReportDataUrlByIdentifier(
    appIdentifier: string,
    reportIdentifier: string | number,
    hierarchy = null,
    filter: string = '',
    search: string = ''
  ) {
    let suffix = '';

    if (reportIdentifier) {
      suffix = suffix + '/' + reportIdentifier;
    }

    const query = this.getQuery(filter, search, hierarchy);
    return this.servicePath() + '/Api/Apps/' + appIdentifier + '/Data/Report' + suffix + query;
  }

  private getQuery(filter: string = '', search: string = '', hierarchy: string = null) {
    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    if (hierarchy) {
      suffixes.push('hierarchy=' + hierarchy);
    }

    if (suffixes.length > 0) {
      return '?' + suffixes.join('&');
    } else {
      return '';
    }
  }

  public resolveReportDataCursorUrlByIdentifier(
    appIdentifier: string,
    reportIdentifier: string,
    cursor: string,
    showArchived = false,
    hierarchy = null,
    filter: string = '',
    search: string = ''
  ) {
    let suffix = '';

    if (reportIdentifier) {
      suffix = suffix + '/' + reportIdentifier;
    }

    if (showArchived) {
      suffix += '/Archived';
    }

    if (cursor) {
      suffix += `/cursor/${cursor}`;
    }

    const suffixes = [];

    if (filter) {
      suffixes.push('$filter=' + encodeURIComponent(decodeURIComponent(filter)));
    }

    if (search) {
      suffixes.push('$search=' + encodeURIComponent(decodeURIComponent(search)));
    }

    if (hierarchy) {
      suffixes.push('hierarchy=' + hierarchy);
    }

    if (suffixes.length > 0) {
      suffix = suffix + '?' + suffixes.join('&');
    }

    return `${this.servicePath()}/Api/Apps/${appIdentifier}/Data/Report${suffix}`;
  }

  public resolveRarPatchUrl(appId: string, controller: string, masterId: string, childId: string) {
    return this.servicePath() + '/Api/' + appId + '/' + controller + '/' + masterId + '/' + childId;
  }

  public resolveDefaultApiUrl(controller: string, ...extras: string[]) {
    const url = (extras?.length > 0) ?
      `${this.servicePath(controller)}/Api/${controller}/${extras.join('/')}` :
      `${this.servicePath(controller)}/Api/${controller}`;
    return url;
  }

  public resolveAppApiUrl(appIdentifier: string) {
    return this.servicePath() + '/Api/Apps/' + appIdentifier;
  }

  public resolveClientUrl(zone: string, pane = '', paramOne = null, paramTwo = null, paramThree = null, paramFour = null) {
    return (
      '/' +
      zone +
      '/' +
      pane +
      (paramOne == null
        ? ''
        : '/' +
        paramOne +
        (paramTwo == null
          ? ''
          : '/' + paramTwo + (paramThree == null ? '' : '/' + paramThree + (paramFour == null ? '' : '/' + paramFour))))
    );
  }

  public resolveTemplateUrl(appId: string, type: string, id = null) {
    if (appId) {
      return '/Templates/' + appId + '/' + type + (!id ? '' : '/' + id);
    } else {
      if (id) {
        return '/Templates/' + type + '/' + id;
      } else {
        return '/Templates/' + type;
      }
    }
  }

  public resolveSignalRUrl() {
    return '/signalr';
  }

  public resolveAssetUri(id: string) {
    if (id) {
      return '/Asset/' + id;
    }
    return null;
  }

  public resolveAssetPublicUri(id: string) {
    if (id) {
      return '/Asset/Public/' + id;
    }
    return null;
  }

  public resolveImageUri(id: string, size = null) {
    if (id) {
      return '/Image/' + id + (size ? '/' + size : '');
    }
    return null;
  }

  public prependHierarchy(url: string, hierarchy: string) {
    let querystring;
    if (hierarchy) {
      querystring = 'hierarchy=' + hierarchy;
      if (url.indexOf('?') >= 0) {
        querystring = '&' + querystring;
      } else {
        querystring = '?' + querystring;
      }

      return url + querystring;
    }

    return url;
  }

  public resolveAppStudioUrl(zone: string, pane = '', paramOne = null, paramTwo = null, paramThree = null, paramFour = null) {
    return (
      '/AppStudio/' +
      zone +
      '/' +
      pane +
      (paramOne == null
        ? ''
        : '/' +
        paramOne +
        (paramTwo == null
          ? ''
          : '/' + paramTwo + (paramThree == null ? '' : '/' + paramThree + (paramFour == null ? '' : '/' + paramFour))))
    );
  }

  public resolveSearchUrl(query: string) {
    return this.servicePath() + '/Api/Search?query=' + encodeURIComponent(decodeURIComponent(query));
  }

  public resolveQueryStringUrlForParams(
    filter?: string,
    groupby?: string,
    orderby?: string,
    hierarchy?: string,
    reportrowcount?: number,
    reportrowindex?: number,
    reportid?: string,
    showingarchived?: boolean
  ) {
    let querystring = '?';

    let filterQuery = '';
    if (filter != null && filter.length > 0) {
      filterQuery = filter.replace('$filter=', '');
      filterQuery = filterQuery.replace(/(%')/g, '%25\'');
      const decodedFilterQuery = decodeURIComponent(filterQuery);
      filterQuery +=
        decodedFilterQuery.indexOf('IsArchived eq') < 0
          ? showingarchived != null && showingarchived
            ? ' and IsArchived eq true'
            : ' and IsArchived eq false'
          : '';
    } else {
      filterQuery = showingarchived != null && showingarchived ? 'IsArchived eq true' : 'IsArchived eq false';
    }
    let encodedFilter = encodeURIComponent(decodeURIComponent(filterQuery));
    encodedFilter = encodedFilter.replace(/'/g, '%27');
    querystring += 'filter=' + encodedFilter;

    let orderByClause = '';
    if (groupby && groupby.length > 0) {
      orderByClause = groupby;
      if (orderby && orderby.length > 0 && (<any>/\[([^\]]+)\]/.exec(groupby))[1] === (<any>/\[([^\]]+)\]/.exec(orderby))[1]) {
        orderByClause = '';
      }
    }

    if (orderby != null && orderby !== groupby) {
      if (orderByClause.length > 0) {
        orderByClause = orderByClause + ',' + orderby;
      } else {
        orderByClause = orderByClause + orderby;
      }
    }
    if (orderByClause.length > 0) {
      orderByClause = '&orderby=' + encodeURIComponent(decodeURIComponent(orderByClause));
      orderByClause = orderByClause.replace(/'/g, '%27');
      querystring += orderByClause;
    }

    querystring +=
      hierarchy != null && hierarchy.length > 0 ? (querystring.length > 1 ? '&hierarchy=' + hierarchy : 'hierarchy=' + hierarchy) : '';

    querystring += reportid != null ? (querystring.length > 1 ? '&reportid=' + reportid : 'reportid=' + reportid) : '';

    querystring +=
      reportrowcount != null ? (querystring.length > 1 ? '&reportrowcount=' + reportrowcount : 'reportrowcount=' + reportrowcount) : '';

    querystring +=
      reportrowindex != null ? (querystring.length > 1 ? '&reportrowindex=' + reportrowindex : 'reportrowindex=' + reportrowindex) : '';

    querystring +=
      showingarchived != null
        ? querystring.length > 1
          ? '&showingarchived=' + showingarchived
          : 'showingarchived=' + showingarchived
        : '';

    return querystring;
  }

  public getQueryString(queryParams: {}) {
    const tree = new UrlTree();
    tree.queryParams = queryParams;
    tree.root = new UrlSegmentGroup([], {});
    const url = this.router.serializeUrl(tree);
    return url.replace('/?', '?');
  }

  public appendQueryParams(url: string, queryParams: {}) {
    const query = this.getQueryString(queryParams);
    if (query && query.length > 0 && query !== '/') {
      if (url.includes('?')) {
        return url + '&' + query.slice(1);
      } else {
        return url + query;
      }
    } else {
      return url;
    }
  }

  private baseAppUrl(appIdentifier: string) {
    return `${this.servicePath()}/Api/Apps/${appIdentifier}`;
  }

  private sanitiseFilename(filename: string) {
    // IIS doesn't like +
    let santised = filename.replace(/\+/g, '');
    santised = encodeURIComponent(santised);
    return santised;
  }
}
