import { NgModule } from '@angular/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimeFieldComponent } from './time-field.component';
import { InputIconModule } from '../input-icon/input-icon.module';
import { InputModule } from '../input/input.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [TimeFieldComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    InputModule,
    InputIconModule,
    FontAwesomeModule
  ],
  exports: [
    TimeFieldComponent
  ]
})
export class TimeFieldModule {
}
