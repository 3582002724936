import * as moment from 'moment';

import { Directive, Input, OnChanges, SimpleChanges, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { OnInit, ViewContainerRef, ComponentFactory } from '@angular/core';
import { Record, Enums, EpochDate, SelectListOption, Form, logError } from '@softools/softools-core';
import { FieldBase } from '../fields';
import { SelectListsService } from 'app/services/select-lists.service';
import { AppService } from 'app/services/app.service';
import { LocatorService } from 'app/services/locator.service';
import { AppField } from 'app/types/fields/app-field';
import { UsersService } from 'app/services/users.service';
import { AlignmentTypeAlias } from '@softools/softools-core';
import { AppIdentifiers } from 'app/services/record/app-info';
import { ContainerType } from '../fields/field-base';
import { AppModel, AttachmentsModel } from 'app/mvc';
import { CommentsModel } from 'app/mvc/comments-model';

@Directive({
  selector: '[appSofValueFormater]',
})
export class SofValueFormaterDirective implements OnChanges, OnInit, OnDestroy {
  /** maybe more performant to call a fields service to get the field, use the fieldIdentifier */
  @Input() field: AppField;

  @Input() record: Record;

  @Input() public appIdentifiers: AppIdentifiers;

  /** If set, the field (e.g. list or grid field) that contains this field  */
  @Input() public containerField: AppField;

  /** The row number if the field is contained inside a list field */
  @Input() public listRow: number;

  /** The form containing the field */
  @Input() public form?: Form;

  @Input() public containerType?: ContainerType;

  @Input() public appModel: AppModel;

  @Input() public commentsModel: CommentsModel;

  @Input() public attachmentsModel: AttachmentsModel;

  /** Alignment string; left/ center/ right. No value will be default alignment.
   * Field/ ListReportField/ ListReportDetailField can have configured optional Alignment property via AppStudio.
   * ListReportField/ ListReportDetailField usage will override field entity alignment as below.
   */
  @Input() alignmentOverride?: AlignmentTypeAlias;

  private appService: AppService;
  private selectListService: SelectListsService;

  constructor(
    private usersService: UsersService,
    public viewContainerRef?: ViewContainerRef,
    protected componentFactoryResolver?: ComponentFactoryResolver
  ) {
    this.appService = LocatorService.get(AppService);
    this.selectListService = LocatorService.get(SelectListsService);
  }
  ngOnDestroy(): void {
    this.viewContainerRef.clear();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['record']) {
      // this._modifyField(this.element.nativeElement);
    }
  }

  public ngOnInit(): void {
    try {
      if (this.field && this.field.Identifier && this.field.Identifier.includes('_')) {
        const parts = this.field.Identifier.split('_', 2);
        const app = this.appService.application(this.record.AppIdentifier);
        this.containerField = app.getField(parts[0]);
      }
    } catch (error) {
      logError(error, '');
    }
  }

  protected _addChildComponent<T extends FieldBase>(factory: ComponentFactory<T>) {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(factory);
    if (this.containerType != null) {
      componentRef.instance.containerType = this.containerType;
    }
    componentRef.instance.initialise({
      field: this.field,
      appIdentifiers: this.appIdentifiers,
      record: this.record,
      alignmentOverride: this.alignmentOverride,
    });
    componentRef.instance.forReport = true;
    componentRef.instance.appModel = this.appModel;
    componentRef.instance.commentsModel = this.commentsModel;
    componentRef.instance.attachmentsModel = this.attachmentsModel;
    return componentRef.instance;
  }

  // following are used in SofInputDirective - review, maybe move

  /**
   * Get the current record value for the field
   */
  protected _getFieldValue() {
    const value = this.field.getRecordValue(this.record, this.listRow);

    if (value && typeof value === 'object' && Object.keys(value).length === 0) {
      return '';
    }

    if (value !== undefined && value !== null) {
      return this._fieldValueFormatter(value);
    }

    return '';
  }

  /**
   * Format fields to have the correct value
   * Dates can be in multiple formats
   * Selection lists should get the text from the select list options
   *
   * @param value
   */
  protected _fieldValueFormatter(value: string | boolean | number | object | EpochDate) {
    switch (this.field.Type) {
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
        return this._dateTypeValueFormatter(value as EpochDate);
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
        if (value !== '') {
          const user = this.usersService.getMapped(value as string);
          return user && user.Text;
        }
        return value;
      case Enums.FieldType.Image:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.InAppChart:
      case Enums.FieldType.Gantt:
      case Enums.FieldType.EmbeddedVideo:
      case Enums.FieldType.ListField:
      case Enums.FieldType.GridField:
      case Enums.FieldType.Lookup:
        return 'Not Supported';
      case Enums.FieldType.Selection:
        return this._formatSelection(value as object);
      default:
        return value;
    }
  }

  protected _dateTypeValueFormatter(val: EpochDate) {
    let when: moment.Moment;
    if (val === undefined || val === null) {
      when = null;
    } else if (val && val['$date']) {
      when = moment.utc(val['$date']);
    } else if (val && (<any>val)._isAMomentObject) {
      when = <any>val;
    } else if (typeof val === 'string') {
      // Despite the best efforts to standardise, some cases e.g. matrix reports
      // supply a string format so attempt to convert if given a string
      when = moment.utc(val);
    } else {
      when = moment.utc(<any>val);
    }

    if (when && when.isValid()) {
      return moment
        .utc({ year: when.year(), month: when.month(), date: when.date(), h: when.hour(), m: when.minutes() })
        .format(this._getDateFormatString());
      // this.dateString = this.dateTime.format(valueFormat);
    } else {
      return '';
      /// this.dateString = null;
    }
  }

  protected _getDateFormatString() {
    switch (this.field.Type) {
      case Enums.FieldType.Date:
        return moment.localeData().longDateFormat('L');
      case Enums.FieldType.DateTime:
        return moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT');
      case Enums.FieldType.Period:
        return 'YYYY-MM';
    }

    throw new Error('Incorrect type');
  }

  private _formatSelection(value: object): string {
    let options: Array<SelectListOption>;

    // let selectList: SelectList;

    if (this.field.UseRecordSelectListOptions) {
      console.warn('todo: UseRecordSelectListOptions ');
    } else {
      const app = this.appService.application(this.record.AppIdentifier);
      options = app && app.selectionListOptions(this.field.Identifier);
    }

    if (!options) {
      const selectList = this.selectListService.get(this.field.SelectListIdentifier);
      options = selectList && selectList.SelectListOptions;
    }

    if (options) {
      switch (this.field.SelectListType) {
        case Enums.SelectListType.Select:
        case Enums.SelectListType.Radio: {
          // single selection, value is option value string
          const val = value.toString();
          const option = options.find((opt) => opt.Value === val);
          return option ? option.Text : val;
        }
        case Enums.SelectListType.Listbox:
        case Enums.SelectListType.Checkbox: {
          // multi selection, value is array of option values
          const values = value as Array<{ Value: string }>;
          const selectedOptions = values.map((v) => options.find((opt) => opt.Value === v.Value));
          return selectedOptions
            .map((v) => v?.Value)
            .filter((v) => v != null)
            .join(', ');
        }
      }
    }

    return value.toString();
  }
}
