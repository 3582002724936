import * as moment from 'moment';
import { from, Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import { ReLoginService } from './services/login.service';
import { Router } from '@angular/router';
import { OnlineStatusService, logError } from '@softools/softools-core';
import { TenantService } from './services/tenant.service';
import { GlobalModelService } from './mvc/common/global-model.service';
import { AuthInterceptorBase } from './auth-interceptor-base';
import { ACCESS_TOKEN_KEY } from './_constants/constants.keys';

/**
 * Inject authenrication token into HTTP requests.
 * Fails requests when we are unathorized (typically during login or token refresh).
 */
@Injectable({ providedIn: 'root' })
export class AuthCheckHttpInterceptor extends AuthInterceptorBase implements HttpInterceptor {
  constructor(
    private loginService: ReLoginService,
    private router: Router,
    private onlineStatusService: OnlineStatusService,
    private tenantService: TenantService,
    private models: GlobalModelService
  ) {
    super();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const event$ = this.isAnonymous(request) ?
      next.handle(request) :
      from(this.ensureValidToken(request)).pipe(
        mergeMap(() => next.handle(request))
      );

    return event$.pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 || (error.status === 400 && error.error?.Message === 'Authorization has been denied for this request.')) {
            this.triggerRelog().catch(err => logError(err, 'Failed to trigger relog'));
          }
        } else {
          logError(error, '');
        }

        return next.handle(request);
      })
    );
  }

  private async ensureValidToken(request: HttpRequest<any>) {

    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token) {
      const expiry = localStorage.getItem('access_token_expires');
      if (expiry) {
        const now = moment().utc();
        const expiresAt = moment(expiry).utc();
        const msRemaining = expiresAt.diff(now);
        if (msRemaining < 60_000) {
          // Try to renew the existing token
          if (!await this.loginService.refresh()) {
            await this.triggerRelog();
          }
        }
      }
    } else {
      await this.triggerRelog();
    }

    if (!this.models.globalModel.authenticated.value) {
      throw new HttpErrorResponse({
        status: 401,
        statusText: 'Unauthorized',
        url: request.urlWithParams
      });
    }
  }

  private async triggerRelog(): Promise<void> {

    this.models.globalModel.authenticated.value = false;

    const url = this.router.url;
    const tenantFromHostname = this.tenantService.tenant();
    // Stop calls to the server until we login.
    this.onlineStatusService.isServerReachable = false;
    await this.loginService.authorise(tenantFromHostname, url);
  }
}
