import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.css'],
})
export class CameraDialogComponent implements OnInit, OnDestroy {
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  stream;
  videoWidth = 0;
  videoHeight = 0;

  capturing = false;
  loading = true;

  errorInCapture = false;

  constraints = {
    video: {
      video: true,
      facingMode: 'environment',
      audio: false,
    },
  };

  constructor(
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<CameraDialogComponent>) { }

  @HostListener('window:resize') onResize() {
    this.ngOnDestroy();
    this.ngOnInit();
  }


  ngOnInit() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices
        .getUserMedia(this.constraints)
        .then(this.attachVideo.bind(this))
        .catch(() => {
          this.errorInCapture = true;
        });
    } else {
      this.errorInCapture = true;
    }
  }

  attachVideo(stream) {
    this.stream = stream;
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);

    this.renderer.listen(this.videoElement.nativeElement, 'play', (_) => {
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
      this.loading = false;
    });
  }

  capture() {
    this.capturing = true;
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    const ctx = this.canvas.nativeElement.getContext('2d');
    ctx.drawImage(this.videoElement.nativeElement, 0, 0);

    ctx.canvas.toBlob((blob) => {
      this.capturing = false;
      try {
        const data = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data.buffer;

        this.dialogRef.close({
          cameraData: data,
          blob: blob,
        });

      } catch (_) {
        this.errorInCapture = true;
      }
    });
  }

  ngOnDestroy(): void {
    this?.stream?.getTracks().forEach((track) => {
      track.stop();
    });
  }
}
