import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';
import { MatCheckbox, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';

import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { InputChangedTrackService } from 'app/services/input-changed-track.service';
import { logError } from 'app/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'sof-bit-field',
  templateUrl: './bit-field.component.html',
  styleUrls: ['./bit-field.component.scss', '../input.scss', '../selection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    // Override behaviour for bit field component only, see Fields2Module
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { clickAction: 'noop' }
  }]
})
export class BitFieldComponent extends EditableFieldBase<boolean> implements OnInit {

  @ViewChild(MatCheckbox, { static: false })
  private checkBox: MatCheckbox;

  // Current display value. We update this as soon as the user clicks so the UI
  // updates, actual value will change later
  public value$: BehaviorSubject<boolean>;

  constructor(private inputChangedTrackService: InputChangedTrackService) {
    super();
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.value$ = new BehaviorSubject(this.value);

    // Watch value and delay until stable to combine multiple clicks
    // Only update if the value changes
    this.subscribe(this.value$.pipe(
      debounceTime(200)
    ), async (value) => {
      try {
        if (this.value !== value) {
          await this.updateValueAsync(value);
        }
      } catch (error) {
        logError(error, 'click bit')
      }
    });
  }

  public override onValueChanged(value: boolean) {
    super.onValueChanged(value);
    this.value$?.next(value);
  }

  public onClicked($event: MouseEvent): void {
    this.inputChangedTrackService.triggerBlur();
    this.componentClicked$.next(this);

    if (!this.getIsDisabled() && this.editMode) {
      $event.stopPropagation();
      const state = !this.value$.value;
      this.value$.next(state);
    }
  }

  public override async activate() {
    this.checkBox.focus();
  }
}
