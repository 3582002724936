import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { AttachmentPopupComponent } from '../attachment-popup.component';
import { Comment, logError, toBase64, tryGetCurrentUser, User } from '@softools/softools-core';
import { CommentsModel } from 'app/mvc/comments-model';

@Component({
  selector: 'app-comment-popup',
  templateUrl: './comment-popup.component.html',
  styleUrls: ['./comment-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentPopupComponent extends AttachmentPopupComponent implements OnInit, OnChanges {

  @Input() override model: CommentsModel;

  @ViewChild('comments', { static: true })
  private commentElement: ElementRef;

  public user: User;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.model.comments.$, () => {
      this.scrollToBottom();
    });
    this.user = tryGetCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading'] || changes['content']) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 200);
    }
  }

  private scrollToBottom() {
    this.commentElement.nativeElement.scrollTop = this.commentElement.nativeElement.scrollHeight;
  }

  public async saveComment() {
    try {
      if (this.text.length > 4000) {
        const f = new File([this.text.toString()], 'snippet.txt');
        await this.save(f);
        this.text = $localize`Comment made into a snippet file`;
      }
      const comment = {
        Text: this.text
      } as Comment;
      this.text = '';

      await this.model.saveComment(comment);
    } catch (error) {
      logError(error, 'saveComment');
    }
  }

  public async save(file: File) {
    if (file) {
      const data = await toBase64(file);
      await this.model.saveCommentSnippet(file.name, data);
    } else {
      this.toasties.error($localize`Cannot save comments snippet`);
    }
  }

  public async confirmDelete(): Promise<void> {
    this.model.isConfirmVisible.value = false;
    await this.model.deleteComment(this.tempDeleteAttachment as Comment);
    this.tempDeleteAttachment = null;
  }

  public override closeClicked() {
    this.model.close();
  }
}
