import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WSSharedNoRecordsComponent } from './ws-sharednorecords/ws-sharednorecords.component';

@NgModule({
  declarations: [
    WSSharedNoRecordsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WSSharedNoRecordsComponent,
  ]
})
export class WsSharedModule { }
