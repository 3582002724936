import { Injectable } from '@angular/core';
import { HomepagesRepository } from '../repos';
import { Homepage } from '../types/interfaces/homepage.interface';
import { sortAscending } from '../utils';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, HOMEPAGES_STORE } from '../indexedDb';

@Injectable({ providedIn: 'root' })
export class HomepageStorageService extends StorageServiceBase<Homepage, string> {

  constructor(
    dbContext: DatabaseContextService<Homepage>,
    public repository: HomepagesRepository) {
    super(HOMEPAGES_STORE, dbContext);
  }

  public async syncHomepages(retryPolicy: IRetryPolicy = null): Promise<Array<Homepage>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const homepages = await policy.execute(() => this.repository.getHomepages().toPromise());
    await Promise.all(homepages.map(async (homepage: Homepage) => {
      await this.save(homepage.Identifier, homepage);
    }));

    return homepages;
  }

  public async getHomepages(): Promise<Array<Homepage>> {
    const homepages = await this.getAll();
    return sortAscending(homepages, 'DisplayOrder');
  }

  public getHomepage(identifier: string): Promise<Homepage> {
    return this.get(identifier);
  }

  public async getHomepageDefaultOrFirst() {
    const homepages = await this.getAll();
    if (homepages.length < 1) {
      return null;
    }

    const defaultHomepage = homepages.find(h => h.IsDefault);
    return defaultHomepage || homepages[0];
  }
}
