import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ComponentRef, Input, OnInit, Type } from '@angular/core';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Enums, isDefined, logError, OdataExpressionType } from '@softools/softools-core';
import { IFilterTerm } from 'app/core/types/interfaces/filter-term.interface';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { FilterTerm } from 'app/filters/types/filter-term';
import { FilterModel } from 'app/mvc';
import { EditableFieldBase, FieldBase } from 'app/softoolsui.module/fields';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { MultiStateMultiSelectorFieldComponent, MultiStateSelectorFieldComponent } from 'app/softoolsui.module/fields2/multi-state-field/multi-state-selector-field.component';
import { SelectionFieldComponent } from 'app/softoolsui.module/fields2/selection-field/selection-field.component';
import { SelectionListFieldComponent } from 'app/softoolsui.module/fields2/selection-list-field/selection-list-field.component';
import { TextDisplayFieldComponent } from 'app/softoolsui.module/fields2/text-display-field/text-display-field.component';
import { TextFieldComponent } from 'app/softoolsui.module/fields2/text-field/text-field.component';
import { AppField } from 'app/types/fields/app-field';
import { BehaviorSubject } from 'rxjs';

export interface IOnTermEdited {
  onTermEdited(term: IFilterTerm);
}

@Component({
  selector: 'app-quick-filter-field',
  templateUrl: './quick-filter-field.component.html',
  styleUrls: ['./quick-filter-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickFilterFieldComponent extends FieldComponent implements OnInit {

  @Input() filterModel: FilterModel;
  @Input() term: IFilterTerm;
  @Input() termEditedHandler: IOnTermEdited;
  @Input() displayOnly = false;
  @Input() hideLabel = false;
  @Input() row: number;

  public filterable$ = new BehaviorSubject(true);

  public operatorIcon$ = new BehaviorSubject<IconName>(null);

  public showOperatorPopup$ = new BehaviorSubject(false);

  public popupPosition = [
    new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }
    ),
  ];

  public ngOnInit(): void {
    const icon = this.operatorIcons[this.term?.Operator] ?? 'ellipsis';
    this.operatorIcon$.next(icon);
  }

  public currentOperatorClicked($event: MouseEvent) {
    $event.stopPropagation();
    if (!this.displayOnly) {
      this.showOperatorPopup$.next(true);
    }
  }

  public operatorClicked($event: MouseEvent, operator: OdataExpressionType) {
    $event.stopPropagation();
    this.showOperatorPopup$.next(false);

    const updates = this.createOpertorFilterUpdates(operator);
    if (updates.term.Operand === undefined) {
      const multi = this.fieldModel.isMultiValue(updates.term.Operator);
      updates.term.Operand = multi ? [] : '';
    }

    this.filterModel.updateFilterTerms(updates);

    if (this.termEditedHandler) {
      this.termEditedHandler.onTermEdited(updates.term);
    }
  }

  protected override addComponentForField(): ComponentRef<FieldBase> {

    if (this.displayOnly) {
      return this.addReadonlyChildComponent(TextDisplayFieldComponent);
    }

    const multiValues = this.fieldModel.isMultiValue(this.term.Operator);

    // Override some field types where editing a filter value is
    // different to the normal field value
    switch (this.fieldModel.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.Selection:
      case Enums.FieldType.Person:
      case Enums.FieldType.Money:
      case Enums.FieldType.Range:
      case Enums.FieldType.Number:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Bit:
      case Enums.FieldType.PersonByTeam:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.Team:
      case Enums.FieldType.Reference:
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
      case Enums.FieldType.Time:
        // Use default component type
        return super.addComponentForField();
      case Enums.FieldType.LongText:
      case Enums.FieldType.Email:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Barcode:
      case Enums.FieldType.ConcealedText:
      case Enums.FieldType.AttachmentsCount:
      case Enums.FieldType.CommentsCount:
        return this.addChildComponent(TextFieldComponent);
      case Enums.FieldType.MultiState:
        if (multiValues) {
          return this.addChildComponent(MultiStateMultiSelectorFieldComponent);
        } else {
          return this.addChildComponent(MultiStateSelectorFieldComponent);
        }
      default:
        // not filterable so create no field
        this.filterable$.next(false);
        return null;
    }
  }

  protected override selectionComponentType(): Type<EditableFieldBase<any, AppField<any>>> {
    // Always use pulldown components
    switch (this.fieldModel.SelectListType) {
      case Enums.SelectListType.Checkbox:
      case Enums.SelectListType.Listbox:
        return SelectionListFieldComponent;
      case Enums.SelectListType.Select:
      case Enums.SelectListType.Radio:
      default:
        return SelectionFieldComponent;
    }
  }

  protected override isEditable() {
    return true;
  }

  protected override _initField(fieldComponent: FieldBase): void {
    fieldComponent.forReport = true;    // don't use form styling
    fieldComponent.isEditable = true;   // force editable
    super._initField(fieldComponent);
  }

  public showClear(value: any) {
    if (this.term.Operator === OdataExpressionType.Between && this.row > 0) {
      return false;
    }
    return value === false || value === 0 || !!value;
  }

  public clearClicked($event: MouseEvent) {
    $event.stopPropagation();
    if (this.term) {
      const options = { row: undefined, delete: true };
      if (!this.fieldModel.isMultiValue(this.term.Operator)) {
        options.row = this.row;
      }

      const newTerm = this.filterModel.updateAdvancedFilterTerm(this.fieldModel.Identifier, options);
      if (newTerm) {
        this.termEditedHandler.onTermEdited(newTerm);
      }
    }
  }

  protected override _initEditableField(fieldComponent: EditableFieldBase<any, AppField<any>>): void {
    super._initEditableField(fieldComponent);

    fieldComponent.forceEditable = true;
    fieldComponent.showClear = false;   // added for all fields by this component

    this.subscribe(fieldComponent.valueChanged$, (value) => {
      try {
        if (value === '' || value === null || value === undefined) {
          this.filterModel.updateAdvancedFilterTerm(this.fieldModel.Identifier, { row: this.row, delete: true });
          // this.filterModel.clearTerm(this.term);
        } else {
          const defaultOperator = this.defaultOperator();
          const newTerm = this.filterModel.updateAdvancedFilterTerm(this.fieldModel.Identifier, { value, row: this.row, defaultOperator });
          if (newTerm && this.termEditedHandler) {
            this.termEditedHandler.onTermEdited(newTerm);
          }
        }
      } catch (error) {
        logError(error, 'Failed to update current filter');
      }
    });
  }

  private createOpertorFilterUpdates(operator: OdataExpressionType): FilterTermUpdates {

    const updates: FilterTermUpdates = {
      fieldId: this.fieldModel.Identifier
    };

    if (this.term) {
      updates.term = { ...this.term, Operator: operator };
    } else {
      updates.term = {
        FieldIdentifier: this.fieldModel.Identifier,
        Operator: operator,
        Operand: undefined,
        displayOperand: ''
      }
    }

    const isMulti = new FilterTerm(updates.term).isMultiValue;
    if (isMulti) {
      // If converting from single value to multi wrap single values in array
      if (isDefined(updates.term.Operand) && !Array.isArray(updates.term.Operand)) {
        updates.term.Operand = [updates.term.Operand];
      }
    } else {
      // If converting from multi to single value, take first value only
      if (Array.isArray(updates.term.Operand) && (updates.term.Operand.length > 1)) {
        updates.term.Operand = updates.term.Operand[0];
      }
    }

    if (operator === OdataExpressionType.Between) {
      if (!updates.term.Operand) {
        updates.term.Operand = [undefined, undefined];
      } else if (Array.isArray(updates.term.Operand)) {
        updates.term.Operand.length = 2;
      }
    }

    return updates;
  }

  /** Filter operator to use when adding a new filter value */
  private defaultOperator() {
    switch (this.fieldModel.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Email:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Barcode:
      case Enums.FieldType.ConcealedText:
        return OdataExpressionType.Substring;
      case Enums.FieldType.DateTime:
        // Nearly impossible to do exact match so default to ≥
        return OdataExpressionType.GreaterThanOrEqual;
      default:
        return OdataExpressionType.Equals;
    }
  }

  public operatorIcons = {
    1: 'equals',
    2: 'not-equal',
    3: 'greater-than',
    4: 'greater-than-equal',
    5: 'less-than',
    6: 'less-than-equal',
    14: 'arrow-right-from-line',
    15: 'arrow-left-from-line',
    16: 'arrows-left-right-to-line',
    17: 'plus-square',
    18: 'minus-square',
    19: 'plus-square',
    20: 'minus-square',
    21: 'arrows-left-right'
  }
}
