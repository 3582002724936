import { ChartData, Enums, ReportDataRepository } from '@softools/softools-core';
import { ReportFilter } from 'app/filters/types';
import { ChartDataService } from 'app/services/chart/data/chart-data.service';
import { DataIndex } from 'app/services/indexes/app-data-index';
import { InjectService } from 'app/services/locator.service';
import { MatrixModel } from 'app/softoolsui.module/matrixreport.component/matrix-model';
import { Application } from 'app/types/application';
import { lastValueFrom, Subscription } from 'rxjs';
import { Causes } from '../busy.model';
import { DataIndexService2 as DataIndexService } from 'app/services/indexes/data-index-service';
import { ChartDataAccssor } from './chart-data-accessor';
import { OverviewReportModel } from '../overview-report.model';

export class OfflineChartDataAccssor extends ChartDataAccssor {

  @InjectService(ChartDataService)
  private readonly chartDataService: ChartDataService;

  @InjectService(ReportDataRepository)
  private readonly reportDataRepository: ReportDataRepository;

  @InjectService(DataIndexService)
  private readonly dataIndexService: DataIndexService;


  private indexPromise: Promise<DataIndex>;

  private filterChanged$: Subscription;

  constructor(app: Application, protected reportModel: OverviewReportModel) {
    super(reportModel.busy, app);
  }

  public async initialise(): Promise<void> {
  }

  public override close() {
    this.filterChanged$?.unsubscribe();
    this.filterChanged$ = null;
    super.close();
  }

  public async loadMatrixData(model: MatrixModel) {
    const index = await this.index();
    if (index) {
      await model.addRecords(index);
    }
  }

  public async getChartData(reset = false): Promise<ChartData> {
    try {
      this.busy.start(Causes.charting);

      if (reset) {
        this.resetIndex();
      }

      const index = await this.index();
      if (index && this.reportModel.report.value) {
        if (this.reportModel.report.value.Chart?.ChartType === Enums.ChartType.googlemap) {
          // We don't have an offline googlemap implementation (as we need to be connected for the maps API)
          // so use the service in this case.

          const data = await lastValueFrom<ChartData>(this.reportDataRepository.getHighChartsData(
            this.app.Identifier,
            this.reportModel.report.value.Identifier,
            this.reportModel.filterQueryParams(),
            this.reportModel.hierarchy.value,
            this.reportModel.globalModel.archived.value));

          this.chartData$.next(data);
          return data;
        } else {

          const data = await this.chartDataService.getChartDataFromIndex(
            this.app,
            this.reportModel.report.value,
            this.filter(),
            this.reportModel.hierarchy.value,
            index,
          );

          this.chartData$.next(data);
          return data;
        }
      }

      return null;
    } finally {
      this.busy.finish(Causes.charting);
    }
  }

  /** Get active index.
   * Promise ensures multiple calls get the same index; call resetIndex when a new one is required e.g. a new filter
   */
  private index(): Promise<DataIndex> {
    if (!this.indexPromise) {
      this.indexPromise = new Promise<DataIndex>((resolve, reject) => {
        const mergedFilter = this.filter();
        const hierarchy = this.reportModel.hierarchy.value;
        this.dataIndexService.getIndex(this.app, mergedFilter?.QueryParameters || {}, null, hierarchy)
          .then(async index => {
            await index.indexAll();
            this.count$.next(index.length);
            return index;
          })
          .then(index => {
            resolve(index);
          })
          .catch(e => reject(e));
      });
    }

    return this.indexPromise;
  }

  public override resetIndex(): void {
    this.indexPromise = null;
  }

  /** Get active report filter including saerch */
  private filter(): ReportFilter {
    const combined = this.reportModel.filterModel.combinedFilter.value;
    const search = this.reportModel.searchFilter.value;
    const mergedFilter = (search && combined) ? ReportFilter.merge([combined, search]) : (combined || search);
    return mergedFilter;
  }
}
