<div *ngIf="model.loading.busy$ | async" class="spinner-container">
  <sof-spinner-icon size="3x" class="spinner"></sof-spinner-icon>
</div>

<div id="attachment-container" class="attachment-container panel-container card h-100 card-popup"
  (click)="backgroundClicked($event)">
  <div class="card-header">
    <div i18n>Comments</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeClicked()"></button>
  </div>

  <!-- Normal content -->
  <div #comments id="main-content" class="items">
    <!-- Container for comment cards, scrolls if needed -->
    <div id="item-container" class="list-group list-group-flush">
      <div *ngFor="let item of model.comments.$ | async" class="list-group-item py-4 comment-card">
        <div class="d-flex st-text-theme-primary">
          <div class="small flex-grow-1">{{ item.FirstName }} {{ item.LastName }}</div>
          <div class="small me-2">
            {{ item.Date | date: 'medium' }}
          </div>
          <ng-container *ngIf="user?.Id === item.UserId">
            <fa-icon
              [icon]="['fal', 'trash']"
              size="1x"
              class="delete-comment pointer"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              (click)="delete(item, trigger)"
            ></fa-icon>
          </ng-container>
        </div>
        <div class="content mt-2">{{ item.Text }}</div>
      </div>
    </div>
  </div>

  <!-- Action buttons when adding a comment, replaces normal button set -->
  <div class="controls card-body border-top">
    <!-- Entry zone for new comment  -->
    <div id="create-comment-section">
      <mat-form-field floatLabel="always" class="w-100">
        <textarea
          #entryElement
          matInput
          style="line-height: 1"
          maxlength="100000"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"
          placeholder="New Comment"
          hintLabel="Enter new comment"
          [(ngModel)]="text"
          autofocus
          i18n-placeholder
          i18n-hintLabel
        ></textarea>
      </mat-form-field>
      <small i18n>{{ 100000 - text.length }} characters remaining</small>
      <div class="d-flex flex-row-reverse">
        <button class="primary-line" (click)="saveComment()" [disabled]="text.length === 0">
          <fa-icon [icon]="['fal', 'paper-plane']" class="me-1"></fa-icon> <span i18n>Comment</span>
        </button>
        <!-- <button class="btn btn-primary mx-3" (click)="cancelNew()" i18n="@@Cancel"><fa-icon [icon]="['fal', 'trash']"></fa-icon></button> -->
      </div>
    </div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="deleteOverlayTrigger"
  [cdkConnectedOverlayOpen]="model?.isConfirmVisible.$ | async"
  (backdropClick)="cancelDelete()"
  [cdkConnectedOverlayHasBackdrop]="true"
>
  <app-confirm-delete id="confirm-section" (confirmDelete)="confirmDelete()" (cancelDelete)="cancelDelete()"></app-confirm-delete>
</ng-template>
