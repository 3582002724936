<div *ngIf="enabled$|async"
  class="sof-modal-container"
  title="Click to capture or clear barcode" i18n-title
  (click)="openControlPanel($event)">
  <ng-container *ngTemplateOutlet="code; context: {clickable: true}" ></ng-container>
</div>

<div *ngIf="!(enabled$|async)"
  class="sof-modal-container" >
  <ng-container *ngTemplateOutlet="code; context: {clickable: false}" ></ng-container>
</div>

<app-popup-container [isOpen]="showControls" (closed)="controlsClosed()">
  <ng-template>
    <div class="selectionContainer cardv2-popup sof-small-modal">
      <div class="selectionPopup d-flex flex-column">
        <ng-container *ngTemplateOutlet="code" ></ng-container>
        <div  class="d-flex justify-content-center my-2" >
          <div class="firstButtonBox">

            <button
              i18n-title
              type="button"
              title="Clear Barcode Value"
              class="primary"
              style="background-color: red!important;"
              (click)="clear($event)"
            >
              <fa-icon [icon]="['fal', 'trash']" [border]="false"></fa-icon>
              <span i18n>Delete</span>
            </button>
          </div>

          <div class="buttonBox">
            <button type="button" class="close"
            (click)="captureClicked()"
            i18n-title title="Capture Barcode" *ngIf="!disableCamera">
              <fa-icon [icon]="['fal', 'camera']" [border]="false"></fa-icon>
              <span i18n>Capture</span>
            </button>
          </div>
          </div>
      </div>
    </div>
  </ng-template>
</app-popup-container>

<app-popup-container [isOpen]="showCamera$|async"
(attached)="cameraAttached($event)"
(closed)="cameraClosed()"
>
  <ng-template>
    <app-camera #camera
      [autoThreshold]="minConfidence"
      [videoWidth]="cameraVideoWidth()"
      [videoHeight]="cameraVideoHeight()"
      (codeCaptured)="onCameraCaptured($event)"
    ></app-camera>
  </ng-template>
</app-popup-container>

<ng-template #code let-clickable="clickable">
  <div class="d-flex justify-content-center" [class.pointer]="clickable"   style="width:100%" >
    <img src="/assets/barcode-placeholder.png" style="opacity: 0.1; width:80%; height:80%">
  </div>
  <div class="d-flex justify-content-center w-100" style="height: 3rem;">
      <span style="align-content: center">{{this.fieldValue}}</span>
  </div>
</ng-template>
