import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SavedFiltersApplication } from 'app/embedded.apps/settings.apps/saved-filters/saved-filters.app';
import { InjectService } from 'app/services/locator.service';
import { BaseGuard } from './base.guard';

@Injectable({ providedIn: 'root' })
export class SavedFiltersGuard extends BaseGuard implements CanActivate {

  @InjectService(SavedFiltersApplication)
  private readonly savedFiltersApp: SavedFiltersApplication;

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    // Load saved filters from storage
    await this.savedFiltersApp.loadAll();

    // Look for any defined child guards and call them in parallel
    const rejected = await this.runChildGuards('SavedFiltersGuard', route, state);
    if (rejected) {
      return rejected;
    }

    return true;
  }
}
