import * as moment from 'moment';

import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { EpochDate, logError } from '@softools/softools-core';
import { VALIDATION_CLASS } from '../sof-input.directive';
import { TemporalFieldBase } from './temporal-field-base';
import { EpochConverter } from 'app/types/epoch-converter';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFieldComponent extends TemporalFieldBase implements OnInit {

  @ViewChild('dateinput', { static: true }) inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('datepicker', { static: true }) datePicker: MatDatepicker<null>;

  private editDate: EpochDate;

  constructor(protected renderer: Renderer2) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.previousValue = this.value;
    this.setValidationClass();
  }

  protected override onValueChanged(value: EpochDate) {
    super.onValueChanged(value);
    this.checkValid();
    this.setValidationClass();
  }


  public override async activate() {
    this.inputElement.nativeElement.focus();
  }

  public override onKeyPress($event: KeyboardEvent) {

    if ($event.code === 'Space') {
      this.datePicker.open();
      return true;
    }

    return super.onKeyPress($event);
  }


  protected override sanitiseMoment(when: moment.Moment) {
    // Force to a UTC date with midnight, to avoid time zone shenanigans
    return moment.utc({ year: when.year(), month: when.month(), date: when.date(), h: 0, m: 0 });
  }

  /**
   * Get the date time value contained in the component.  Unlike the @see value property
   * this can be overriden to adjust the value as required.
   */
  protected getTrueDateTime(): EpochDate {
    return this.editDate || this.value;
  }

  public onKeyUpHandler($event, dateinput) {
    if (dateinput.value === '') {
      this.clearDate();
    }

    return super.onKeyUp($event);
  }

  public openPicker($event?: MouseEvent) {
    $event.stopPropagation();
    if (!this.getIsDisabled()) {
      this.datePicker.open();
    }
  }

  public setEditedValue(val: moment.Moment) {
    this.editDate = EpochConverter.toEpoch(val);
  }

  public dateChanged() {
    try {
      const newValue = this.getTrueDateTime();
      const prevDate = this.previousValue?.$date;
      const newDate = newValue?.$date;

      if (prevDate !== newDate) {
        this.updateValueAsync(newValue).catch(e => logError(e, 'dateChanged dispatch'));
        this.previousValue = newValue;
      }

      this.inputElement.nativeElement.focus();

    } catch (error) {
      logError(error, '');
    }
  }

  public clearDate() {
    this.value = null;
    this.editDate = null;
    this.previousValue = null;
    this.dispatchChange(null);
  }

  private setValidationClass() {
    if (this.isValid) {
      this.renderer.removeClass(this.inputElement.nativeElement, VALIDATION_CLASS);
    } else {
      this.renderer.addClass(this.inputElement.nativeElement, VALIDATION_CLASS);
    }
  }

  protected override formatiCalDateTime(): string {
    return moment.utc(this.value.$date).format('YYYYMMDD');
  }
}
