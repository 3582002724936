import { AppDataStorageService, AppsService, Form, getCurrentUser, logError, QueryParameters, QueryParams, Record, RecordId, Report, stringCompare } from '@softools/softools-core';
import { MvcBase } from '@softools/vertex';
import { getChartType } from 'app/folders.module/effects';
import { AppService } from 'app/services/app.service';
import { InjectService } from 'app/services/locator.service';
import { NavigationService } from 'app/services/navigation.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { FolderGroup, FolderType, NavigationItem, RootFolders } from 'app/softoolsui.module/folders.component/folder';
import { Application } from 'app/types/application';
import { BehaviorSubject } from 'rxjs';
import { FolderModel, GlobalModel } from '..';

/**
 * Base class for folder controllers
 * A folder controller is responsible for populating the folder model.
 * Concrete classes for each type of page (e.g. a chart, a record) create the
 * appropriate folder items.
 */
export abstract class FolderController extends MvcBase {

  // todo use globalModel?
  @InjectService(NavigationService)
  protected readonly navigationService: NavigationService;

  @InjectService(AppService)
  protected readonly appService: AppService;

  @InjectService(AppsService)
  protected readonly appsService: AppsService;

  @InjectService(AppDataStorageService)
  protected readonly appDataService: AppDataStorageService;

  constructor(public globalModel: GlobalModel, public folderModel: FolderModel) {
    super();
  }

  public initialise() {
  }

  /** 
   * Initialise the folder count shown with the main app name 
   * Implementatons should return  immediately, then update the count asynchrnously
   * typically by subscribing to properties that affect the count.
   */
  protected initialiseCount(): void {
    // If not overriden, no count will be displayed
    this.folderModel.count.value = null;
  }

  protected initialiseHome() {
    this.folderModel.appHomeUrl.value = '/';
  }

  public async createRecordFolder(app: Application, appIdentifiers: AppIdentifiers, record: Record) {

    const root = new RootFolders();
    const group: FolderGroup = this.createAppHeaderFolder(root, app);

    // TD: I have hidden the add new record button when in the child report context. I think this is confusing to the user.
    // if (record?.Hierarchy) {
    //   //group.NewItemUrl = this.navigationService.getCreateChildRecordUrl(appIdentifiers, this.getStringAfter(record.Hierarchy, '|'));
    // } else {
    //   //group.NewItemUrl = this.navigationService.getCreateRecordUrl(appIdentifiers);
    // }

    if (record) {
      const visibleForms = await app.getVisibleForms(record, record._new);
      const queryParameters = new QueryParameters({});    // todo get from index report
      this.addFoldersFromForms(group, appIdentifiers, record._id, queryParameters, visibleForms);

      if (!record._new && app.ChildAppsIdentifiers?.length > 0) {
        await this.addChildAppsAsync(root, appIdentifiers, app, record._id);
      }
    }

    return root;
  }

  /**
   * Create the folder for the application.  This contains the header, new record entry and folder selectors.
   *
   * @param root
   * @param app
   */
  protected createAppHeaderFolder(root: RootFolders, app: Application): FolderGroup {
    const folderGroup = new FolderGroup(FolderType.Application);
    folderGroup.RecordType = app.Name;
    folderGroup.Title = app.Title;
    root.FolderGroups.push(folderGroup);
    return folderGroup;
  }

  protected addFoldersFromForms(folderGroup: FolderGroup, appIdentifiers: AppIdentifiers, recordId: RecordId, queryParams: QueryParams, forms: Array<Form>): void {
    forms.forEach(form => {
      const item: NavigationItem = {
        Identifier: form.Identifier,
        Title: form.Title,
        Type: 'form',
        SecondaryType: 'form',
        AppIdentifier: appIdentifiers.appIdentifier,
        QueryParams: queryParams,
        HasAdd: false,
        Url: this.navigationService.getRecordUrl2(appIdentifiers, recordId, form.Identifier)
      };

      folderGroup.Items.push(item);
    });
  }

  protected async addChildAppsAsync(root: RootFolders, appIdentifiers: AppIdentifiers, parentApp: Application, parentRecordId: string, selectedReporidentifier?: string): Promise<FolderGroup> {

    const user = getCurrentUser();

    const folderGroup = new FolderGroup(FolderType.LinkedApplication);

    for (const childConfig of parentApp.ChildAppsIdentifiers) {
      if (!childConfig.HideInParent) {
        const childAppIdentifier = childConfig.Identifier;
        const childApp = this.appService.application(childAppIdentifier);
        if (!childApp) {
          logError(new Error(`Could not find child app '${childAppIdentifier}' for parent '${parentApp.Identifier}'`), '');
        } else {
          const childAppAncestors = appIdentifiers.push(childApp.Identifier);

          const childReport = childApp.preferredListReport();

          const headerItem: NavigationItem = {
            Identifier: 'childAppHome',
            Title: childApp.Title,
            Type: 'childapp',
            SecondaryType: '',
            AppIdentifier: childAppIdentifier,
            HasAdd: true,
            Url: this.navigationService.getCreateChildRecordUrl(childAppAncestors, parentRecordId),
            ChildAppHomeUrl: this.navigationService.getChildAppHomeOrBaseUrl(childAppAncestors, parentRecordId),
            ChildAppDefaultReport: this.navigationService.getChildAppReportFolderUrl2(childAppAncestors, parentRecordId),
            ChildAppIdentifier: childApp.Identifier,
            ChildName: childApp.Name,
            ChildNamePlural: childApp.NamePlural,
            ParentRecordId: parentRecordId,
            ParentAppIdentifier: parentApp.Identifier,
            childCount$: new BehaviorSubject<number>(undefined),
          };

          const childFolder = folderGroup.subfolder(childAppIdentifier);
          childFolder.Title = childApp.Title;
          childFolder.HeaderItem = headerItem;

          // Set child count asynchronously
          this.loadRecordCount(childApp, childFolder.HeaderItem, childReport)
            .catch(err => logError(err, 'rec folder model child count'));

          const reports = await this.appsService.getReportsAsync(childAppIdentifier);
          const filteredReports = reports.filter(o => !o.IsHidden)
            .filter(o => !o.InAppDataSourceFieldIndentifier)
            .filter(report => childApp.isReportVisibleToUser(user, report))
            .sort((report1, report2) => (report1.DisplayOrder - report2.DisplayOrder) || (stringCompare(report1.Title, report2.Title)));


          filteredReports.forEach(report => {
            const reportItem = {
              Identifier: report.Identifier,
              Title: report.Title,
              Type: report.Type,
              SecondaryType: getChartType(report),
              AppIdentifier: parentApp.Identifier,
              ChildAppIdentifier: childAppIdentifier,
              Url: this.navigationService.getChildAppReportFolderUrl2(childAppAncestors, parentRecordId, report),
              MustBeOnline: !childApp.isOfflineReport(report.Identifier)

            } as NavigationItem;

            if (report.Category) {
              // Named category so install in subfolder
              const subfolder = childFolder.subfolder(report.Category);
              subfolder.Items.push(reportItem);

              if (selectedReporidentifier && selectedReporidentifier === report.Identifier) {
                subfolder.Expanded = true;
              }
            } else {
              childFolder.Items.push(reportItem);
            }

          });
        }
      }
    }

    if (folderGroup.SubFolders?.length > 0) {
      root.FolderGroups.push(folderGroup);
    }

    return folderGroup;
  }

  protected async childRecordCount(childApp: Application, childItem: NavigationItem, childReport: Report): Promise<number> {
    const showArchived = this.globalModel.archived.value;
    const online = this.globalModel.online.value;

    if (childApp.isOfflineApp && !showArchived) {
      const count = await this.appDataService.childCount(childItem.ChildAppIdentifier, childItem.ParentAppIdentifier, childItem.ParentRecordId, false);
      return count;
    } else {
      if (online) {
        const countQueryParams = {
          $filter: `IsArchived eq ${showArchived ? 'true' : 'false'}`
        } as QueryParams;

        const count = await this.appDataService.getApiCountAsync(childApp, childReport?.Identifier, countQueryParams, `${childItem.ParentAppIdentifier}|${childItem.ParentRecordId}`);
        return count;
      }
    }

    return 0;
  }

  private async loadRecordCount(childApp: Application, childItem: NavigationItem, childReport: Report): Promise<void> {
    const count = await this.childRecordCount(childApp, childItem, childReport);
    childItem.ChildCount = count;
    childItem.childCount$.next(count);
  }

  // get string after | in string
  protected getStringAfter(str: string, char: string): string {
    const index = str.indexOf(char);
    if (index > -1) {
      return str.substring(index + 1);
    }
    return '';
  }
}
