import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ElementStyles, IFilterTerm, logError, OdataExpressionType, Report } from '@softools/softools-core';
import { BooleanModelProperty, Model, ModelProperty } from '@softools/vertex';
import { FilterSaveComponent, FilterSaveData, FilterSaveResult } from 'app/filters/filter-save/filter-save.component';
import { FilterModel, ReportModel } from 'app/mvc';
import { ReportController } from 'app/mvc/reports/report.controller';
import { ComponentBase } from 'app/softoolsui.module';
import { AppField } from 'app/types/fields/app-field';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { IFilteredField, QuickFilter } from './quick-filter';
import { IOnTermEdited } from './quick-filter-field.component';

class QuickFilterModel extends Model<QuickFilterModel> {

  public readonly quickFilter = new ModelProperty<QuickFilter>(this).withLogging('Quick Filter');

  public readonly groupField = new ModelProperty<AppField>(this);

  public readonly orderField = new ModelProperty<AppField>(this);

  public readonly orderDescending = new BooleanModelProperty(this);

  public addOperandRow(field: IFilteredField) {
    const qff = this.quickFilter.value.quickFields.find(f => f.field.Identifier === field.field.Identifier);

    if (Array.isArray(qff.term.Operand)) {
      qff.term.Operand.push('');
    } else {
      qff.term.Operand = [qff.term.Operand, ''];
    }

    // Notify change in place
    this.quickFilter.changed();
  }

}

@Component({
  selector: 'app-quick-filter',
  templateUrl: './quick-filter.component.html',
  styleUrls: ['./quick-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickFilterComponent extends ComponentBase implements OnInit, IOnTermEdited, OnDestroy {

  @Input() reportModel: ReportModel;
  @Input() reportController: ReportController;
  @Input() filterModel: FilterModel;

  @Output() close = new EventEmitter();

  @Output() showSaved = new EventEmitter();

  @ViewChild('clear') clearButton: ElementRef<HTMLButtonElement>;

  public baseFilter$ = new BehaviorSubject('');
  public baseSavedName$ = new BehaviorSubject('');

  public model = new QuickFilterModel();

  // public pinned$ = new BehaviorSubject(true);

  public fieldStyle: ElementStyles = {
    component: {
      Name: 'qfilter-field',
      Appearance: 'vanilla',
      BackgroundColour: 'transparent',
      css: {
        'background-color': 'transparent',
        'color': 'white',
        'border-left': 'none',
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': 'solid white 1px'
      }
    }, icon: {
      Name: 'qfilter-icon',
      Colour: 'silver',
      css: {
        color: 'silver'
      }
    }
  }

  public displayStyle: ElementStyles = {
    component: {
      Name: 'fake',
      Appearance: 'vanilla',
      BackgroundColour: 'transparent',
      css: {
        'background-color': 'transparent',
        'color': 'white',
        'border': 'none'
      }
    }
  }


  constructor() {
    super();
  }

  public ngOnInit(): void {

    const reportFilter$ = combineLatest([this.reportModel.report.$, this.filterModel.advancedFilter.$]);
    this.subscribe(reportFilter$, ([report, filter]) => {
      if (report && filter) {
        this.configure(report, filter);
      }
    });

    this.subscribe(this.filterModel.groupBy.$, (groupBy) => {
      if (groupBy) {
        const groupField = this.reportModel.appModel.app.value.getField(groupBy);
        this.model.groupField.value = groupField;
      } else {
        this.model.groupField.value = null;
      }
    });

    this.subscribe(this.filterModel.orderBy.$, (orderBy) => {
      if (orderBy) {
        const orderField = this.reportModel.appModel.app.value.getField(orderBy);
        this.model.orderField.value = orderField;
      } else {
        this.model.orderField.value = null;
      }
    });

    this.subscribe(this.filterModel.orderDescending.$, (desc) => {
      this.model.orderDescending.value = desc ?? false;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.baseFilter$?.unsubscribe();
    this.baseSavedName$?.unsubscribe();
    this.model?.dispose();
  }

  public configure(report: Report, terms: Array<IFilterTerm>) {

    const app = this.reportModel.appModel.app.value;

    if (report.BaseFilterId) {
      const saved = app.SavedFilters.find(f => f.Id === report.BaseFilterId);
      const name = saved?.Description ?? '';
      this.baseSavedName$.next(name);
    } else {
      this.baseSavedName$.next('');
    }

    this.baseFilter$.next(report.BaseFilter ?? null);

    this.model.quickFilter.value = new QuickFilter(app, report, terms);
  }

  /** Get operands for the filter entry as an iterable array */
  public operands(filtered: IFilteredField): Array<any> {

    const term = filtered.term;
    if (!term) {
      return [];
    }

    const multiValues = filtered.field.isMultiValue(term.Operator);
    if (Array.isArray(term?.Operand)) {
      return multiValues ? [term.Operand] : term.Operand;
    } else {
      return multiValues ? [[term.Operand]] : [term.Operand];
    }
  }

  public showAddRow(filtered: IFilteredField): boolean {

    const term = filtered.term;

    if (!term) {
      return false;
    } else if (filtered.field.isMultiValue(term.Operator)) {
      // Native multi value so no need for add row
      return false;
    } else if (term.Operator === OdataExpressionType.OneOf ||
      term.Operator === OdataExpressionType.NoneOf ||
      term.Operator === OdataExpressionType.ContainsOneOf ||
      term.Operator === OdataExpressionType.ContainsNoneOf) {
      if (Array.isArray(term?.Operand)) {
        return term.Operand.find(op => !op) === undefined;
      } else {
        return !!term.Operand;
      }
    } else {
      return false;
    }
  }

  public addRowClicked($event: Event, field: IFilteredField) {
    $event.stopPropagation();
    this.model.addOperandRow(field);
  }

  public onTermEdited(term: IFilterTerm) {
    // Close panel unless no operand specified so selecting operator first
    // doesn't close before you can select a value
    if (!term.Operator || term.Operand) {
      // Don't autoclose on datetime fields as it usually takes two changes
      // to select a value. SOF-12423.
      const field = this.reportModel.appModel.app.value.getField(term.FieldIdentifier);
      if (field.Type !== this.Enums.FieldType.DateTime) {
        this.editCompleted();
      }

    }
  }

  public async saveFilter($event: MouseEvent) {
    const globalModel = this.reportModel.globalModel;

    try {
      $event.stopPropagation();

      const appModel = this.reportModel.appModel;

      const saveData: FilterSaveData = {
        app: appModel.app.value,
        savedFilters: appModel.savedFilters.value,
        filterSpec: this.filterModel.filterSpec
      };

      const result: FilterSaveResult = await globalModel.dialogAsync(FilterSaveComponent, saveData);
      if (result) {
        const savedFilter = result.savedFilter;
        if (savedFilter) {
          await appModel.saveFilterAsync(savedFilter);
        }
      }
    } catch (error) {
      logError(error, 'Save filter');
      globalModel.showErrorToasty({ message: error.message });
    }
  }

  public async clearAll($event: MouseEvent) {
    try {
      $event.stopPropagation();
      this.filterModel.clearAdvancedFilter();
      this.editCompleted();
    } catch (error) {
      logError(error, 'Clear all');
    } finally {
      // Remove focus as it looks horrible
      this.clearButton?.nativeElement?.blur();
    }
  }

  public pushPin($event: MouseEvent) {
    $event.stopPropagation();
    this.reportController.quickFiltersPinned.value = true;
  }

  public pullPin($event: MouseEvent) {
    $event.stopPropagation();
    this.reportController.quickFiltersPinned.value = false;
  }

  public closeClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.close.emit();
  }

  public savedFiltersClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.showSaved.emit();
  }

  public clearAdditionalFilter() {
    this.filterModel.clearAdditionalFilter().catch((error) => logError(error, 'clearAdditionalFilter'));
  }

  private editCompleted() {
    if (!this.reportController.quickFiltersPinned.value) {
      this.close.emit();
    }
  }
}
