import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { SofDropZoneModule } from '../../design/dropzone';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    SofDropZoneModule,
    MatButtonModule,
    FontAwesomeModule
  ],
  declarations: [FileUploaderComponent],
  exports: [FileUploaderComponent]
})
export class FileUploaderModule { }
