import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { RecordUpdateComponent } from './record-update/record-update.component';
import { FormModule } from '../form.component/form.module';
import { SelectOptionsModule } from '../select-options/select-options.module';
import { PopupContainerModule } from '../popup-container/popup-container.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    FontAwesomeModule,
    CommonModule,
    OverlayModule,
    FormsModule,
    FormModule,
    SelectOptionsModule,
    PopupContainerModule,
  ],
  declarations: [RecordUpdateComponent],
  exports: [RecordUpdateComponent],
})
export class RecordUpdateModule { }
