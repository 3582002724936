
import { Injectable } from '@angular/core';

import { Enums, ChartData, logError } from '@softools/softools-core';
import { dateFormat } from 'highcharts';
import * as moment from 'moment';
import { ChartEnums } from 'app/services/chart';
import { ChartClickthroughService } from 'app/services/chart/chart-clickthrough.service';
import { ChartReportModel } from 'app/mvc';



@Injectable()
export class ChartReportHelperService {
  private readonly notEnoughDataMessage = $localize`:@@ChartGaugeRecordError:Not enough data for averages`;

  private static extendObject(_arg1: any, _arg2: any) {
    for (let i = 1; i < arguments.length; i++) {
      for (const key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) {
          if (typeof arguments[0][key] === 'object' && typeof arguments[i][key] === 'object') {
            this.extendObject(arguments[0][key], arguments[i][key]);
          } else {
            arguments[0][key] = arguments[i][key];
          }
        }
      }
    }
    return arguments[0];
  }

  private static extendScatterChartJson(chartdata: any) {
    const chart = chartdata;
    let hideDataLabels = false;

    const data = {
      toggleDataLabels: function (target: any, state: any) {
        hideDataLabels = !state;
        target.xAxis[0].isDirty = true;
        target.redraw();
      },
      tooltip: {
        useHTML: true,
        followPointer: false,
        formatter: function () {
          let hoverText = '';
          if (this.point.hoverText?.length > 0) {
            for (const index in this.point.hoverText) {
              if (this.point.hoverText.hasOwnProperty(index)) {
                hoverText += '<b>' + this.point.hoverText[index].name +
                  ':</b>' + this.point.hoverText[index].value + '<br/>';
              }
            }
          } else {
            hoverText += '<b>' + chart.xAxis.title.text + ':</b> ' + this.x + '<br/>' + '<b>'
              + chart.yAxis[0].title.text + ':</b> ' + this.y;
          }
          return '<div style=\'white-space:normal; overflow:auto;\'>' + hoverText + '</div>';
        }
      },
      plotOptions: {
        scatter: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            formatter: function () {
              if (!hideDataLabels && typeof (this.point.label) !== 'undefined') {
                if (this.point.label.length > 0) {
                  return this.point.label;
                }
              } else {
                if (this.point.dataLabel) {
                  return '';
                }
              }

              return null;
            }
          }
        }
      }
    };

    const result = this.extendObject(chartdata, data);
    return result;
  }

  private static extendBubbleChartJson(chartdata: any) {
    const chart = chartdata;
    let hideDataLabels = false;

    const data = {
      toggleDataLabels: function (target: any, state: any) {
        hideDataLabels = !state;
        target.xAxis[0].isDirty = true;
        target.redraw();
      },
      tooltip: {
        useHTML: true,
        followPointer: false,
        formatter: function () {
          const params = chart.tooltip.formatterParams;
          let hoverHtml = '';

          if (this.point.hoverText) {
            for (const index in this.point.hoverText) {
              if (this.point.hoverText.hasOwnProperty(index)) {
                hoverHtml += '<b>' + this.point.hoverText[index].name + ': </b>' +
                  (this.point.hoverText[index].value != null ? this.point.hoverText[index].value : '') + '<br/>';
              }
            }
          }

          if (params.labelText && this.point.label?.length > 0) {
            hoverHtml += '<b>' + params.labelText + ': </b> ' + this.point.label + '<br/>';
          }

          // colour comes from online charts, Unset is an empty string there. Offline unset is #7cb5ec
          if (this.point.colour != null) {
            hoverHtml += `<b>Colour: </b>${this.point.colour ? this.point.colour : 'Unset'}<br/>`;
          } else if (this.point.color) {
            hoverHtml += `<b>Colour: </b>${this.point.color === '#7cb5ec' ? 'Unset' : this.point.color}<br/>`;
          }

          hoverHtml += '<b>' + params.xFieldLabel + ': </b> ' + this.x + '<br/>' + '<b>' + params.yFieldLabel + ': </b> ' + this.y + '<br/>';

          if (this.point.z && this.point.z > 0) {
            hoverHtml += '<b>' + params.zFieldLabel + ': </b> ' + this.point.z;
          }

          return '<div style=\'white-space:normal; overflow:auto;\'>' + hoverHtml + '</div>';
        }
      },
      plotOptions: {
        scatter: {
          dataLabels: {
            enabled: true,
            allowOverlap: chart.plotOptions.scatter != null &&
              chart.plotOptions.scatter.dataLabels != null ? chart.plotOptions.scatter.dataLabels.allowOverlap : false
          }
        },
        series: {
          dataLabels: {
            formatter: function () {
              if (!hideDataLabels && typeof (this.point.label) !== 'undefined') {
                if (this.point.label.length > 0) {
                  return this.point.label;
                }
              } else {
                if (this.point.dataLabel) {
                  return '';
                }
              }

              return null;
            }
          }
        }
      }
    };

    const result = this.extendObject(chartdata, data);
    return result;
  }

  private static extendGanttChartJson(chartdata: any) {
    chartdata.yAxis[0].min = Date.parse(chartdata.yAxis[0].min);

    for (const i in chartdata.series) {
      if (chartdata.series.hasOwnProperty(i)) {
        const series = chartdata.series[i];

        for (const j in series.data) {
          if (series.data.hasOwnProperty(j)) {
            const seriesdata = series.data[j];
            if (!seriesdata) { continue; }
            if (seriesdata.low) { seriesdata.low = moment(seriesdata.low, 'YYYY-MM-DDThh:mm:ssZ').valueOf(); }
            if (seriesdata.y) { seriesdata.y = moment(seriesdata.y, 'YYYY-MM-DDThh:mm:ssZ').valueOf(); }
          }
        }
      }
    }

    const data = {
      tooltip: {
        useHTML: true,
        followPointer: false,
        formatter: function () {
          const point = this.point;
          let hoverText = '';

          if (this.point.hoverText) {
            for (const index in this.point.hoverText) {
              if (this.point.hoverText.hasOwnProperty(index)) {
                hoverText += '<b>' + this.point.hoverText[index].name + ': </b>' + this.point.hoverText[index].value + '<br/>';
              }
            }
          }

          hoverText += '<b>' + point.category + '</b>' + ' (' + point.series?.name + ')' + '<br/>'
            + '<b>' + point.seriesFirstName + 'Start: ' + '</b>' + dateFormat('%b %e, %Y', point.low)
            + ' - ' + '<b>' + point.seriesFirstName + 'End: ' + '</b>' + dateFormat('%b %e, %Y', point.y) + '<br/>'
            + '<b>' + 'Status: ' + '</b>' + point.status;

          return '<div style=\'white-space:normal; overflow:auto;\'>' + hoverText + '</div>';
        }
      }
    };

    const result = this.extendObject(chartdata, data);
    return result;
  }

  private static extendBasicChartJson(chartdata: any, graphType: any) {
    if (graphType === 'cumulativematrix' || graphType === Enums.ChartType.cumulativematrix || graphType === Enums.ChartType.summaryseries) {
      return chartdata;
    } else {
        let data = {};
        if (this.canGenerateTooltip(chartdata, graphType)) {
          data = {
            tooltip: {
              useHTML: true,
              followPointer: false,
              formatter: function () {
                let hoverText = '';

                if (this.point.hoverText?.length > 0) {
                  for (const index in this.point.hoverText) {
                    if (this.point.hoverText.hasOwnProperty(index)) {
                      hoverText += '<b>' + (this.point.hoverText[index].name || '') + ':</b>' + (this.point.hoverText[index].value != null ? this.point.hoverText[index].value.toString() : '') + '<br/>';
                    }
                  }
                } else {
                  hoverText += '<b>' + this.point.category + '</b>' + '<br/>';
                  hoverText += this.series.name + ': ' + this.y;
                }
                return '<div style=\'white-space:normal; overflow:auto;\'>' + hoverText + '</div>';
              }
            }
          };
        } else {
          data = {
            tooltip: {
              enabled: false
            }
          };
        }

      const result = this.extendObject(chartdata, data);
      return result;
    }
  }

  private static canGenerateTooltip(chartdata: any, graphType: any) {
    // In case of network chart if there is no column set for tooltip then hide it.
    if (graphType === Enums.ChartType.network && chartdata.series[0]?.nodes?.find(i => i.hoverText?.length > 0) === undefined) {
              return false;
    }

    return true;
  }

  private static extendNetworkChartJson(chartdata: any, graphType: any) {
    const data = {
      tooltip: {
        useHTML: true,
        followPointer: false,
        formatter: function () {
          let hoverText = '';

          if (this.point.hoverText?.length > 0) {
            for (const index in this.point.hoverText) {
              if (this.point.hoverText.hasOwnProperty(index)) {
                hoverText += '<b>' + (this.point.hoverText[index].name || '') + ':</b>' + (this.point.hoverText[index].value || '') + '<br/>';
              }
            }
          } else {
            hoverText += '<b>' + this.point.category + '</b>' + '<br/>';
            hoverText += this.series.name + ': ' + this.y;
          }
          return '<div style=\'white-space:normal; overflow:auto;\'>' + hoverText + '</div>';
        }
      }
    };

    const result = this.extendObject(chartdata, data);
    return result;
  }

  private static extendPieChartJson(chartdata: any) {
    const chart = chartdata;

    const data = {
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          borderWidth: 1
        }
      },
      tooltip: {
        useHTML: true,
        followPointer: false,
        formatter: function () {
          return '<div style=\'white-space:normal; overflow:auto;\'>' +
            '<b style="color:' + this.point.color + '">' + this.point.name + '</b></br>' +
            '<b>' + this.series.name + ': </b>' + this.point.name + '</br>' +
            '<b>Count: </b>' + this.y + '</br>' +
            '<b>Percentage: </b>' + +parseFloat(this.percentage).toFixed(2) + '%</br>' +
            '</div>';
        }
      }
    };

    for (const i in chart.series) {
      if (chart.series.hasOwnProperty(i)) {
        const series = chartdata.series[i];

        if (series.dataLabels) {
          series.dataLabels.formatter = function () {
            return '<b style="color:' + this.point.color + '">' + this.point.name + '</b>: '
              + this.y + ' (' + +parseFloat(this.percentage).toFixed(2) + '%)';
          };
        }
      }
    }

    const result = this.extendObject(chartdata, data);
    return result;
  }

  private static extendMonteCarloJson(chartdata: any) {
    const chart = chartdata;
    const params = chart.tooltip.formatterParams;

    const data = {
      tooltip: {
        useHTML: true,
        followPointer: false,
        formatter: function () {
          if (params != null && params.monteCarloType != null) {
            switch (params.monteCarloType) {
              case ChartEnums.MonteCarloType.SCurve:
                return '<div style=\'white-space:normal; overflow:auto;\'>' + '<b>Value: </b>' +
                  this.x + '<br/><b>Percentage: </b>' + this.point.percent + '</div>';
              case ChartEnums.MonteCarloType.Bell:
                return '<div style=\'white-space:normal; overflow:auto;\'>' + '<b>Value: </b>' +
                  this.x + '<br/><b>Occurances: </b>' + this.y + '<br/><b>Percentage: </b>' + this.point.percent + '</div>';
            }
          }

          return '';
        }
      }
    };

    const result = this.extendObject(chartdata, data);
    return result;
  }

  private static extendMapJson(chartdata: any) {
    const chart = chartdata;
    const data: any = {};

    if (!chart.mapKey || chart.mapKey.length === 0) {
      logError(new Error('mapKey not present for chart.  Failed to render'), '');
      return chartdata;
    }

    if (chart.isHeatMap === false) {
      data.plotOptions = {
        map: {
          dataLabels: {
            formatter: function () {
              return chart.mapKey === 'custom/world' || chart.mapKey === 'countries/us/us-all' ?
                (this.point.properties && this.point.properties['hc-a2']) :
                this.point.name;
            }
          }
        }
      };
    }

    if (chart.series !== undefined) {

      for (let i = 0; i < chart.series.length; i++) {
        const series = chart.series[i];

        if (chart.isHeatMap === false) {
          series.data = series.data.map(function (code: any) {
            return { code: code };
          });
        } else if (series.name !== '#SeparatorsSeries') {
          series.dataLabels = {
            enabled: true,
            formatter: function () {
              return chart.mapKey === 'custom/world' || chart.mapKey === 'countries/us/us-all' ?
                (this.point.properties && this.point.properties['hc-a2']) :
                this.point.name;
            }
          };

          for (let x = 0; x < series.data.length; x++) {
            const datapoint = chart.series[i].data[x];
            if (!datapoint['hc-key']) {
              datapoint['hc-key'] = datapoint['hckey'];
            }

            chartdata.series[i].data[x] = datapoint;
          }
        }

        chartdata.series[i] = series;
      }
    }

    const result = this.extendObject(chartdata, data);
    return result;
  }

  static extendGaugeJson(chart: any, notEnoughDataMessage: string): any {
    chart.yAxis[0].plotBands = chart.yAxis[0].plotBands.map(plotBand => {
      if (!plotBand.label.text) {
        plotBand.label.text = notEnoughDataMessage;
      }
      return plotBand;
    });
  }

  public createChartViewModel(graphType: any, chartdata: any) {
    let result;
    switch (graphType) {
      case 'scatter':
      case Enums.ChartType.scatter:
        result = ChartReportHelperService.extendScatterChartJson(chartdata);
        break;
      case 'bubble':
      case Enums.ChartType.bubble:
        result = ChartReportHelperService.extendBubbleChartJson(chartdata);
        break;
      case 'gantt':
      case Enums.ChartType.gantt:
        result = ChartReportHelperService.extendGanttChartJson(chartdata);
        break;
      case 'polar':
      case Enums.ChartType.polar:
        result = chartdata;
        break;
      case 'pie':
      case Enums.ChartType.pie:
        result = ChartReportHelperService.extendPieChartJson(chartdata);
        break;
      case 'montecarlo':
      case Enums.ChartType.montecarlo:
        result = ChartReportHelperService.extendMonteCarloJson(chartdata);
        break;
      case 'map':
      case Enums.ChartType.map:
        result = ChartReportHelperService.extendMapJson(chartdata);
        break;
      case 'gauge':
      case Enums.ChartType.gauge:
        result = ChartReportHelperService.extendGaugeJson(chartdata, this.notEnoughDataMessage);
        break;
      // case Enums.ChartType.network:
      //   result = chartdata;
      //   // ChartReportHelperService.extendNetworkChartJson(chartdata, graphType);
      //   break;
      default:
        result = ChartReportHelperService.extendBasicChartJson(chartdata, graphType);
        break;
    }

    const defaults = {
      chart: {
        reflow: true,
        style: {
          fontFamily: 'var(--font-family-sans-serif)',
          'max-width': '100%',
        },
      },
      exporting: {
        enabled: false,
      },
    };

    result = ChartReportHelperService.extendObject(chartdata, defaults);
    return result;
  }

  public setupAdditionalChartConfig(config: any, data: ChartData, chartType: Enums.ChartType, reportType: any, model: ChartReportModel, showTitle: any, showSubTitle: any, isInAppChart: boolean) {

    config.series = data.Series;
    config.matrixTableData = data.MatrixTableData;
    config.hierarchy = config.hierarchy ? config.hierarchy : data.Hierarchy;
    config.xAxis = config.xAxis || {};

    if (chartType === Enums.ChartType.gauge) {
      config.yAxis = data.yAxis;
      if (config.series.length === 1) {
        config.series[0].visible = true;
      }
    }

    if (isInAppChart) {
      if (config.title && config.title.text) {
        config.title.text = showTitle ? config.title.text : '';
      }
      if (config.subtitle && config.subtitle.text) {
        config.subtitle.text = showSubTitle ? config.subtitle.text : '';
      }
    } else {
      config.title.text = '';
      config.subtitle.text = '';
    }

    if (chartType !== Enums.ChartType.cumulativematrix
      || config.xAxis.type == null
      || (config.xAxis.type != null && config.xAxis.type !== 'datetime')) {
      config.xAxis.categories = data.Categories;
    }

    if (chartType === Enums.ChartType.gantt) {
      config.yAxis[0].min = data.GanttEarliestDate;

      if (config.chart.height == null
        && data.Series[0].data.length > 5) {
        const height = 245 + ((data.Series[0].data.length - 5) * 40);
        config.chart.height = height.toString();
      }
    }

    if (data.LegendDisabled != null && data.LegendDisabled === true) {
      config.legend = { enabled: false };
    }

    config = this.setupChartClickThroughs(config, data, chartType, reportType, model);
    return config;
  }

  public setMapGeoData(chartviewmodel: any, mapGeoJson: any) {
    if (chartviewmodel.isHeatMap === false) {
      chartviewmodel.plotOptions.map.mapData = mapGeoJson;
    } else {
      for (let i = 0; i < chartviewmodel.series.length; i++) {
        const series = chartviewmodel.series[i];
        series.mapData = mapGeoJson;
        chartviewmodel.series[i] = series;
      }
    }
  }


  // public getHighChartConfig(reportChartConfig: any){
  //   const chartOptions = {} as Highcharts.Options;

  //   chartOptions.title.text = reportChartConfig.title;
  //   chartOptions.title.align = 'left';

  //   chartOptions.subtitle.align = 'left';

  //   chartOptions.chart = {} as Highcharts.ChartOptions;
  //   chartOptions.chart.renderTo = `chart_${reportChartConfig.Id}`;

  //   chartOptions.tooltip.useHTML = true;
  //   chartOptions.tooltip.followPointer = true;

  //   chartOptions.plotOptions.series = {} as Highcharts.SeriesOptions;

  //   chartOptions.legend.backgroundColor = '#FFFFFF';
  //   chartOptions.legend.reversed = true;
  //   chartOptions.legend.enabled = true;
  //   chartOptions.legend.y = 0;

  //   chartOptions.chart.style.fontFamily = '"proxima-nova","Helvetica Neue","Helvetica","Arial","sans-serif" !important;';
  //   chartOptions.chart.style.fontSize = '0.875rem';
  //   chartOptions.chart.style.maxWidth = '100%';

  //   this.getChartOptions(chartOptions, reportChartConfig.type);

  //   return chartOptions;
  // }

  private setupChartClickThroughs = (config: any, data: any, chartType: Enums.ChartType, reportType: any, model: ChartReportModel) => {
    switch (chartType) {
      case Enums.ChartType.cumulativematrix:
        this.chartClickthroughService.setupCumulativeClickthrough(config, data, model);
        break;
      case Enums.ChartType.scatter:
        this.chartClickthroughService.setupScatterClickthrough(config, model);
        break;
      case Enums.ChartType.bubble:
        this.chartClickthroughService.setupBubbleClickthrough(config, model);
        break;
      case Enums.ChartType.gantt:
        this.chartClickthroughService.setupGanttClickthrough(config, data, model);
        break;
      case Enums.ChartType.polar:
        this.chartClickthroughService.setupPolarClickthrough(config, model);
        break;
      case Enums.ChartType.pie:
        this.chartClickthroughService.setupPieClickthrough(config, model);
        break;
      case Enums.ChartType.map:
        this.chartClickthroughService.setupMapClickthroughs(config, model);
        break;
      case Enums.ChartType.network:
        this.chartClickthroughService.setupNetworkClickthroughs(config, model);
        break;
      default:
        this.chartClickthroughService.setupDefaultClickthroughs(config, chartType, reportType, model);
        break;
    }
    return config;
  }

  constructor(
    private chartClickthroughService: ChartClickthroughService
  ) { }

}
