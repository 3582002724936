import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { PlusComponent } from './plus.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [PlusComponent],
  exports: [PlusComponent]
})
export class SofPlusIconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPlus);
  }
}
