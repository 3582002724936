import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseIconComponent } from '../iconbase';

@Component({
  selector: 'sof-edit-icon',
  templateUrl: '../icon.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditComponent extends BaseIconComponent {
  constructor() {
    super('edit');
  }
}
