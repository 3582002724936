import { Injectable } from '@angular/core';
import { CONFIG_UPGRADE_STORE, DatabaseContextService, Field, StorageServiceBase } from '@softools/softools-core';

export interface IUpgradeData {

  appIdentifier: string;

  version: number;

  fields: Array<Field>;

}

@Injectable({
  providedIn: 'root'
})
export class UpgradeDataStorageService extends StorageServiceBase<IUpgradeData, string[]> {

  constructor(dbContext: DatabaseContextService<IUpgradeData>) {
    super(CONFIG_UPGRADE_STORE, dbContext);
  }

  public async saveUpgradeAsync(upgrade: IUpgradeData) {
    const key = [upgrade.appIdentifier, upgrade.version.toString()];
    await this.save(key, upgrade);
  }

  public async getUpgradeAsync(appIdentifier: string, version: number) {
    const key = [appIdentifier, version.toString()];
    return await this.get(key);
  }
}
