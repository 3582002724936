import { Model, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';

/**
 * Property to contain a record's parent hierarchy information.
 * The main value is the full hierarchy string; the individual ids
 * are exposed by the @property parentAppIdentifier$ and
 * @property parentRecordId$ observables.
 */
export class HierarchyModelProperty extends ModelProperty<string> {

  public readonly parentAppIdentifier = new ProjectionModelPoperty<string, string>(this, h => h ? h.split('|')[0] : null);
  public readonly parentRecordId = new ProjectionModelPoperty<string, string>(this, h => h ? h.split('|')[1] : null);

  constructor(container?: Model<any>, initial?: string) {
    super(container, initial);
  }
}
