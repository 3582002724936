import { HttpRequest } from '@angular/common/http';

export abstract class AuthInterceptorBase {

  /** Does the requested URL support anonymous access  */
  protected isAnonymous(request: HttpRequest<any>): boolean {
    return (
      request.url.includes('Site/GetSiteLoginDataResource') ||
      request.url.includes('HeartBeat') ||
      request.url.includes('Account/GetTenantFromHost') ||
      request.url.includes('api/auth0/')
    );
  }

}
