import { SafeUrl } from '@angular/platform-browser';
import { DownloadRepository, FileAttachment, ImageStorageService, OverlayImage, OverlaySoftoolsConfig } from '@softools/softools-core';
import { Model } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { WindowRef } from 'app/WindowRef';
import { OverlayService } from 'app/workspace.module/services/overlay.service';

export class PreviewModel extends Model<PreviewModel> {

  @InjectService(DownloadRepository)
  private _downloadRepository: DownloadRepository;

  @InjectService(WindowRef)
  private _windowRef: WindowRef;

  @InjectService(ImageStorageService)
  private _imageService: ImageStorageService;

  @InjectService(OverlayService)
  private overlayService: OverlayService;

  /** Open an attachment in the preview pane */
  public async previewAttachment(attachment: FileAttachment) {

    if (attachment) {

      const file = await this._downloadRepository.download(`/Download/${attachment.ParentId}/${attachment.Id}`).toPromise();
      const urls = (this._windowRef.nativeWindow.URL || this._windowRef.nativeWindow.webkitURL);

      const fileURL = urls.createObjectURL(file.data);
      if (attachment.MIMEType === 'application/octet-stream') {
        const reader = new FileReader();
        reader.readAsText(file.data);
        reader.onload = () => {
          const text = reader.result as string;
          this.openFullText(text);
        };
      } else {
        const safeUrl = this._imageService.sanitise(fileURL);
        const i = this.openFullImage(safeUrl);
      }
      urls.revokeObjectURL(file.data);
    }
  }

  /** Open an image in the preview pane */
  public openFullImage(src: SafeUrl) {

    const config: OverlaySoftoolsConfig = {
      image: { src: src } as OverlayImage,
      closed: () => {
        this.close();
      }
    };

    const component = this.overlayService.openImg(config);
    this.subscribe(component.closed$, (closed) => {
      if (closed) {
        this.close();
      }
    });

  }

  /** Open text in the preview pane */
  public openFullText(text: string) {
    const component = this.overlayService.openText({ text: { text: text } });

    this.subscribe(component.closed$, (closed) => {
      if (closed) {
        this.close();
      }
    });
  }

  /** Close any active preview overlay */
  public close() {
    this.overlayService.close();
  }

}
