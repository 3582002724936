import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IGroupStarts } from 'app/services/indexes/app-data-index';
import { Application } from 'app/types/application';
import { HeaderSummaryExpression } from '@softools/softools-core';

@Component({
  selector: 'app-group-header',
  templateUrl: './group-header.component.html',
  styleUrls: ['./group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupHeaderComponent implements OnChanges {

  @Input() public app: Application;

  @Input() public group: IGroupStarts;

  @Input() public rowHeight = 48;

  @Input() public showCount = false;

  @Input() public showSummaryRow = false;

  @Input() public summaryExpressions: Array<HeaderSummaryExpression>;

  @Output() public clicked = new EventEmitter<IGroupStarts>();

  public groupFieldLabel: string;

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['app'] || changes['group'] && (this.app && this.group)) {
      this.groupFieldLabel = this.app.getField(this.group.groupFieldId)?.Label;
    }

  }

  public click($event: MouseEvent, group: IGroupStarts) {
    $event.stopPropagation();
    this.clicked.emit(group);
  }

  public get summaryExpressionsForGroup() {
    return this.summaryExpressions.filter(summary => summary.expression.groupValue === this.group.value);
  }

  public get headerText() {
    return this.group?.text ?? this.group?.value;
  }

}
