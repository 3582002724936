import { Component, Input } from '@angular/core';
import { ModalMessageType, ModalOptions } from '@softools/softools-core';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss', './modal.component.scss']
})
export class ModalMessageComponent {

  public messageTypes = ModalMessageType;

  @Input() public modalOptions: ModalOptions;

  constructor() { }

}
