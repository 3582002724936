import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { logError } from '@softools/softools-core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { VERSION_NUMBER } from 'app-version';
import { filter } from 'rxjs/operators';
import { tryGetCurrentUser } from '@softools/softools-core';
import { RecordPersistService } from './record/record-persist.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private gtm: GoogleTagManagerService,
    recordPersistService: RecordPersistService,
    router: Router,
    route: ActivatedRoute,
    private models: GlobalModelService) {

    recordPersistService.recordCreated$
      .pipe(filter(r => r !== null))
      .subscribe((record) => {
        this.pushTag('sof_record_created', { appIdentifier: record.AppIdentifier });
      });

    recordPersistService.recordUpdated$
      .pipe(filter(r => r !== null))
      .subscribe((record) => {
        this.pushTag('sof_record_updated', { appIdentifier: record.AppIdentifier });
      });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const type = route.snapshot?.children[0]?.data?.['type'];
      if (route.snapshot.children[0].data['type']) {
        this.pushTag('sof_page_view', { page_type: type, page_url: event.url });
      }

      if (event.url.indexOf('/Sync') === 0) {
        this.pushTag('sof_sync', { page_type: 'Sync', page_url: event.url });
      }
    });
  }

  public trackLogin() {
    this.pushTag('sof_login', {});
  }

  public trackComment() {
    const globalModel = this.models.globalModel;
    this.pushTag('sof_comment', { appIdentifier: globalModel.routeParams.value.appIdentifier });
  }

  private pushTag(event: string, params) {
    const user = tryGetCurrentUser();
    if (user) {
      this.gtm.pushTag({
        event,
        userId: user.Id,
        tenant: user.Tenant,
        version: VERSION_NUMBER,
        ...params
      }).catch(error => logError(error, 'Failed to push tag'));
    }
  }
}
