import { Injectable } from '@angular/core';
import { IStyleName, StyleKeyValue } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { NamedStylesService } from '../named-styles.service';

@Injectable({
  providedIn: 'root'
})
export class ChartColourService {

  constructor(
    private _namedStylesService: NamedStylesService
  ) { }

  public ganttDefaultDarkGreyHexcode = '#d3d3d3';

  /**
   * Get 'Color' property type StyleKeyValues.
   * @param namedStyles 
   * @returns Dictionary<string, string> - Name/ Value color styles configured
   */
  public getColorKeyValueStylesAsync(
    app: Application,
    namedStyles?: Array<IStyleName>
  ): { [Key: string]: string } {
    const styles = this._namedStylesService.getAppStyles(app);

    const styleNames = namedStyles?.map(o => o.StyleName) ?? [];

    const styleKeyValueMatches = ([] as StyleKeyValue[]).concat(
      ...styles
        .filter(s => styleNames.find(sn => sn == s.Name) !== undefined)
        .map(s => s.StyleKeyValues ?? [])
    );

    const colorKeyValueStyles: { [Key: string]: string } = Object.assign(
      {},
      ...styleKeyValueMatches
        .filter(o => o.Property === 'Color')
        .map((kv) => ({ [kv.Name]: kv.Value }))
    );

    return colorKeyValueStyles;
  }

  /**
   * Lookup Colour hex code from Data Value.
   * @param value Data value to lookup as the Key.
   * @param colorKeyValueStyles If KeyValue match, return whatever hexcode the AppBuilder has configured within the Style KeyValues configuration.
   * @param hasMultiStateAxis If Multistate axis return the corresponding hex codes for; Red/ Amber/ Green/ Blue/ Black.
   * @returns Hexcode string for colour
   */
  public getColourHexcode(
    value: string,
    colorKeyValueStyles: { [Key: string]: string },
    hasMultiStateAxis: boolean = false
  ): string | null {
    if (hasMultiStateAxis) {
      switch (value.toLowerCase()) {
        case 'red':
          return '#cc0000';

        case 'amber':
          return '#ff9900';

        case 'green':
          return '#009900';

        case 'blue':
          return '#0000cc';

        case 'black':
          return '#000000';

        default:
          return '#808080';
      }
    }

    if (colorKeyValueStyles.hasOwnProperty(value)
      && colorKeyValueStyles[value].length > 0
    ) {
      return colorKeyValueStyles[value];
    }

    return null;
  }

  /**
   * Get Gantt SeriesData Colour.
   * Use MultiState 'Status' as precendence when set.
   * Otherwise check 'Color' Style KeyValues for a match against ProjectName.
   * @param projectName Project Name for the SeriesData
   * @param statusValue Optional config - MultiState 'Status' Value if configured against the Chart config and present for the SeriesData
   * @param colorKeyValueStyles If KeyValue match, return whatever hexcode the AppBuilder has configured within the Style KeyValues configuration.
   * @returns Hexcode string for colour
   */
  public getGanttSeriesDataColourHexcode(
    projectName: string,
    statusValue: string,
    colorKeyValueStyles: { [Key: string]: string }
  ): string {
    if (statusValue && statusValue.length > 0) {
      // Return multistate colour hexcode.
      switch (statusValue.toLowerCase()) {
        case 'red':
          return '#ff0000';

        case 'amber':
          return '#ffa500';

        case 'green':
          return '#7cfc00';

        case 'blue':
          return '#0066cc';

        case 'black':
          return '#1a1a1a';

        default:
          return this.ganttDefaultDarkGreyHexcode;
      }
    }

    if (projectName && projectName.length > 0
      && colorKeyValueStyles.hasOwnProperty(projectName)
      && colorKeyValueStyles[projectName].length > 0
    ) {
      return colorKeyValueStyles[projectName];
    }

    // Dark Grey colour as deault when no match found.
    return this.ganttDefaultDarkGreyHexcode;
  }

}
