
import { Component, Input, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { FolderGroup, FolderType, NavigationItem } from 'app/softoolsui.module/folders.component/folder';
import { compactedSubfolderStateAnimation, leftAsideStateAnimation, flyInflyOut } from 'app/_constants';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RecordId, OnlineStatusService, logError } from '@softools/softools-core';
import { LocatorService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { Subscription } from 'rxjs';
import { AppContextService } from 'app/services/app-context.service';
import { FolderModel, GlobalModel, PageModel, RecordUpdateModel } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { VERSION_NUMBER } from 'app-version';

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [compactedSubfolderStateAnimation, leftAsideStateAnimation, flyInflyOut]
})
export class FoldersComponent implements OnDestroy {

  public get isSmallDevice(): boolean {
    return this.breakpointObserver.isMatched(`(max-width: 768px)`);
  }

  public appVersion = VERSION_NUMBER

  public globalModel: GlobalModel;

  public readonly pageModel: PageModel;

  public url;
  public folderTypes = FolderType;

  public routeSub: Subscription;

  private onlineSub: Subscription;

  @Input() title;
  @Input() folderClickHandler;
  @Input() showAddNewRecord;
  @Input() selectedFolderIdentifier: string;
  @Input() pinnedStateVal;
  @Input() parentAppIdentifier: string;
  @Input() parentRecordIdentifier: RecordId;

  /** Folder model defining contents */
  @Input() folderModel: FolderModel;

  /** Model of record if we're vieiwng one */
  @Input() recordModel: RecordUpdateModel;

  // For linked apps
  @Input() recordId;
  @Input() appIdentifier;
  @Input() appIdentifiers: AppIdentifiers;

  private _appService = LocatorService.get(AppService);

  constructor(
    private _router: Router,
    private breakpointObserver: BreakpointObserver,
    public onlineStatusService: OnlineStatusService,
    private changeDetector: ChangeDetectorRef,
    private appContextService: AppContextService,
    models: GlobalModelService
  ) {
    this.globalModel = models.globalModel;
    this.pageModel = models.pageModel;

    this.routeSub = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Used with SelectedAppHomeUrl
        this.url = event.url;
      }
    });

    this.onlineSub = this.onlineStatusService.isServerReachable$.subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSub) { this.routeSub.unsubscribe(); }
    if (this.onlineSub) { this.onlineSub.unsubscribe(); }
  }

  /** Is the entire folder bar visible or hidden? */
  public get showFoldersBar() {
    if (this.appIdentifier) {
      const appService = LocatorService.get(AppService);
      const app = appService.application(this.appIdentifier);
      return !app.HideFoldersBar;
    } else {
      return true;
    }
  }

  public get addRecordQueryParams() {
    if (this.parentAppIdentifier && this.parentRecordIdentifier) {
      return { hierarchy: `${this.parentAppIdentifier}|${this.parentRecordIdentifier}` };
    } else {
      return {};
    }
  }

  public get isNewRecordVisible() {

    // Disabled when viewing archived (for now, see SOF-8432)
    if (this.appContextService.ShowArchived) {
      return false;
    }

    const appIdentifier = this.parentAppIdentifier || this.appIdentifier;
    const app = this._appService.application(appIdentifier);
    return this.showAddNewRecord && app.capabilities.canCreate;
  }

  public get createPrompt() {
    const appIdentifier = this.parentAppIdentifier || this.appIdentifier;
    const app = this._appService.application(appIdentifier);
    return app.TextResources?.CreatePrompt;
  }

  public compactSubFolderState(folder: FolderGroup): string {
    return this.isSubFolderExpanded(folder) ? 'active' : 'inactive';
  }

  public isSubFolderExpanded(folder: FolderGroup): boolean {
    return folder.Identifier === this.appIdentifier || folder.Expanded;
  }

  public toggleSubFolders(event: Event, folder: FolderGroup): void {
    event.stopPropagation();
    folder.Expanded = !folder.Expanded;
  }

  public isFolderActive(folder: NavigationItem) {
    let active = false;
    if (!folder.ChildAppIdentifier) {
      if (folder.AppIdentifier === this.appIdentifier) {
        active = (this.selectedFolderIdentifier && folder.Identifier === this.selectedFolderIdentifier);
      }
    } else {

      if (folder.ChildAppIdentifier === this.appIdentifier) {
        active = (this.selectedFolderIdentifier && folder.Identifier === this.selectedFolderIdentifier && this.appIdentifier === folder.ChildAppIdentifier);
      }
    }

    return active;
  }

  public isFormSelectItem(folder: NavigationItem): boolean {
    return folder.Type === 'form';
  }

  public async selectForm($event: MouseEvent, folder: NavigationItem) {
    try {
      if (folder.Type === 'form') {
        if (this.isSmallDevice) {
          this.closeAside();
        }

        $event.stopPropagation();

        const app = this._appService.application(folder.AppIdentifier);
        if (app) {
          const form = app.Forms.find(f => f.Identifier === folder.Identifier);
          if (form) {
            // Get the id of the app we're viewing
            const currentAppIdentifier = this.appIdentifiers.visibleAppIdentifier;
            if (currentAppIdentifier !== folder.AppIdentifier) {
              if (folder.Url) {
                this._router.navigateByUrl(folder.Url).catch(error => logError(error, 'Failed to route'));
              }
            } else {
              await this.recordModel?.selectForm(form);
            }
          }
        }
      }
    } catch (error) {
      logError(error, 'selectForm');
    }
  }

  public getAppHomeUrl() {
    // RecordId of grandparents and above aren't accessible without accessing the parent record, so a url for child app home can't be made.
    // Instead the apphome is disabled.
    if (this.appIdentifiers?.ancestorAppIds.length > 1 && this.parentRecordIdentifier) {
      return undefined;
    } else {
      return this.folderModel?.appHomeUrl.value;
    }
  }

  public closeAside() {
    this.pageModel.closeFolderBar();
  }

  public sideChanged() {
    this.globalModel.layoutChanged();
  }
}
