import { WSBase } from 'app/workspace.module/components/ws-base/ws-base';
import { OnlineStatusService, QueryParams } from '@softools/softools-core';
import { Subscription, combineLatest } from 'rxjs';
import { RECORD_CURSOR, REPORT_IDENTIFIER } from 'app/_constants';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { RecordUpdateModel, RouteParams } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { RecordUpdateController } from 'app/mvc/records/record-update.controller';

@Injectable()
export class WSRecordBase extends WSBase implements OnInit, OnDestroy {

  private routerQuerySub: Subscription;

  public creating = false;

  public asChild = false;

  // Report and cusrsor index within it when using online report navigation
  public cursorReportIdentifier?: string;
  public cursor?: number;

  public recordModel = new RecordUpdateModel(this.appModel, this.models.pageModel);

  public recordUpdateController = new RecordUpdateController(this.recordModel, this.models.pageModel);

  constructor(
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    public override appService: AppService,
    navigationService: NavigationService,
    permissionsService: PermissionsService,
    public override models: GlobalModelService,
  ) {
    super(onlineStatus, appService, route, navigationService, permissionsService, models);
    this.generalController = this.recordUpdateController;
    this.showBackButton = true;
  }

  override ngOnInit() {

    super.ngOnInit();

    // Determine if we're instantiated in child or normal mode
    // This usses the route snapshot as it does not change during lifespan of the component
    this.asChild = !!(this.route.snapshot.data['isChild']);

    const combinedRouterObservable = combineLatest(this.route.params, this.route.queryParams,
      (params: Params, qparams: QueryParams) => ({ params, qparams }));

    this.routerQuerySub = combinedRouterObservable.subscribe((latest) => {

      this.routeChanged(this.route.snapshot.params);

      // Record must be initialised by RecordResolver
      const record = this.route.snapshot.data['record'];

      const valid = !!record;   // must have a record
      if (valid) {
        const cursor = latest.params[RECORD_CURSOR] as string;
        this.cursor = cursor && Number.parseInt(cursor);
        this.cursorReportIdentifier = latest.params[REPORT_IDENTIFIER] as string;
      }
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    if (this.routerQuerySub) {
      this.routerQuerySub.unsubscribe();
    }

    this.recordUpdateController?.dispose();
    this.recordModel?.dispose();

    this.recordUpdateController = null;
    this.recordModel = null;
  }

  protected override async routed(params: RouteParams) {
    await this.appModel.routed(params);
    await this.recordModel.routed(params);
  }

  public get cursorMode(): boolean {
    return !!(this.cursorReportIdentifier && this.cursor);
  }
}
