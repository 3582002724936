<div class="record-content h-100 w-100" >
  <article class="record-article h-100 d-flex flex-column"
  cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin"  >

  <div *ngIf="!(recordModel.record.$|async)"
    class="w-100 d-flex justify-content-center align-items-center text-white"
    style="background: red; color: white;" >
    <fa-icon [icon]="['fal', 'times']" size="1x" class="pe-2" ></fa-icon>
    <span i18n class="py-3">
      Failed to load the record, click back and try again.
    </span>
  </div>

  <div *ngIf="recordModel.isArchived.$|async"
    class="w-100 d-flex justify-content-center align-items-center text-white"
    style="background: grey;" >
    <fa-icon [icon]="['fal', 'archive']" size="1x" class="pe-2" ></fa-icon>
    <span *ngIf="appModel.app.value?.TextResources?.ArchivedRecordHeader" i18n class="py-3">
      {{appModel.app.value?.TextResources?.ArchivedRecordHeader}}
    </span>
    <span *ngIf="!appModel.app.value?.TextResources?.ArchivedRecordHeader" i18n class="py-3">
      This record is archived.
    </span>
  </div>

  <div *ngIf="(recordModel.record.$|async)?._deleted"
    class="w-100 d-flex justify-content-center align-items-center text-white"
    style="background: red; color: white;" >
    <fa-icon [icon]="['fal', 'trash']" size="1x" class="pe-2" ></fa-icon>
    <span *ngIf="appModel.app.value?.TextResources?.DeletedRecordHeader" i18n class="py-3">
      {{appModel.app.value?.TextResources?.DeletedRecordHeader}}
    </span>
    <span *ngIf="!appModel.app.value?.TextResources?.DeletedRecordHeader" i18n class="py-3">
      This record has been deleted.
    </span>
  </div>

  <div *ngIf="!(recordModel.editable.$|async)"
    class="w-100 d-flex justify-content-center align-items-center text-white"
    style="background: slategray; color: white;" >
    <div class="pe-2">
      <fa-stack >
        <fa-icon [icon]="['fal', 'edit']" stackItemSize="1x" ></fa-icon>
        <fa-icon [icon]="['fal', 'slash']" stackItemSize="1x" ></fa-icon>
      </fa-stack>
    </div>
    <span *ngIf="appModel.app.value?.TextResources?.ReadOnly" i18n class="py-3">
      {{appModel.app.value?.TextResources?.ReadOnly}}
    </span>
    <span *ngIf="!appModel.app.value?.TextResources?.ReadOnly" i18n class="py-3">
      You do not have permission to edit this record.
    </span>
  </div>

  <div *ngIf="!isOnline"
  class="w-100 d-flex justify-content-center align-items-center text-white"
  style="background: slategray; color: white;" >
  <div class="pe-2">
    <fa-stack >
      <fa-icon [icon]="['fal', 'wifi-slash']" stackItemSize="1x" ></fa-icon>
    </fa-stack>
  </div>
  <span i18n class="py-3">
    You're offline.
  </span>
</div>

<div style="overflow-y: auto;">
  <ng-container *ngIf="recordModel.record.$|async as record">
    <form novalidate *ngIf="(appModel.fields.$|async)?.length > 0 && !(isLoading) && !((recordModel.record.$|async)?._deleted)" [style.touch-action]="'pan-y'"
      class="w-100 h-100"
      [class.faded]="(recordModel.isArchived.$|async)"
      >
      <app-form
        [appModel]="appModel"
        [recordModel]="recordModel"
        [recordUpdateController]="recordUpdateController"
        [attachmentsModel]="attachmentsModel"
        [record]="record"
        [form]="recordModel.form.$|async"
        [application]="appModel.app.$|async"
        [fields]="appModel.fields.$|async"
        [appIdentifiers]="appModel.appIdentifiers.$|async"
        [expandedFormTemplates]="recordModel.expandedFormTemplates.$|async"
        [expandedFormTemplateHelp]="recordModel.expandedFormTemplateHelp.$|async"
        [titleFieldText]="recordModel.titleFieldText()"
        [isOnline]="isOnline"
        (onFieldUpdate)="fieldUpdated($event)"
        (onToggleExpandedFormTemplate)="toggleExpandedFormTemplate($event)"
        (onToggleExpandedFormTemplateHelp)="toggleExpandedFormTemplateHelp($event)"
        (onImageDelete)="imageDelete($event)"
        >
      </app-form>
    </form>

  </ng-container>
</div>

</article>
</div>

<app-select-options></app-select-options>
