<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <div class="cardv2-header">
      <div>
        <div *ngIf="!uploading && !notificationMessage; else importingText" i18n>Choose Import File</div>
        <ng-template #importingText>
          <div i18n>File Uploaded</div>
        </ng-template>
      </div>
    </div>
    <div class="cardv2-body">
      <app-file-uploader *ngIf="!uploading && !uploaded" accept=".csv" i18n-dragText dragText="Drag csv here to upload"
        [enablePasting]="false" (fileOutput)="fileHandler($event)"></app-file-uploader>
      <mat-progress-bar *ngIf="uploading" mode="indeterminate"></mat-progress-bar>

      <div class="mt-2">
        <div *ngIf="uploaded && !error && notificationMessage">
          <app-notification-message [notificationMessage]="notificationMessage" [isOnline]="true"
            [showDeleteIcon]="false">
          </app-notification-message>
          <div><small i18n>Importing in the background, you can close this window</small></div>
        </div>
        <div i18n *ngIf="uploaded && error" [class.text-danger]="error">{{ filename }} could not be uploaded, please try
          again</div>
        <div i18n *ngIf="error && errorMessage !== 'CSV'" class="text-danger">
          {{ errorMessage }}
        </div>
        <div i18n *ngIf="errorMessage === 'CSV'" [class.text-danger]="error" i18n>CSV file was not detected</div>
      </div>
    </div>
    <div class="cardv2-footer w-100 d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary border-0" [mat-dialog-close] i18n>Close</button>
    </div>
  </div>
</div>
