import { Injectable } from '@angular/core';
import { MappedTeam, TeamsStorageService, Team, OnlineStatusService } from '@softools/softools-core';
import { RefreshType } from './users.service';

/**
 * High level team access.
 * Manages teams collections that can be called synchronously from any code
 * and async functions to initialise them, typically from guards.
 */
@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private teamsByIdentifier: Map<string, Team>;
  private mappedTeamsByIdentifier: Map<string, MappedTeam>;

  public constructor(
    private teamsStorageService: TeamsStorageService,
    private onlineStatus: OnlineStatusService) {
  }

  /**
   * Initialise (or re-iniitalise) team data held by this service.  The teams held in storage are
   * loaded and indexed, and optionally reloaded from the server if needed and/or possible.
   * This is be called by a guard in an async context so that code can access the collections
   * efficiently and synchronously.  Normal code should not need to call @see refresh directly.
   *
   * @param refreshType   Controls whether data is loaded from the server.
   *  'init' loads if no teams found, 'force' always updates from server provided we are online.
   */
  public async initialise(refreshType?: RefreshType): Promise<Array<Team>> {

    let teams = await this.teamsStorageService.getTeams();

    // Load from server if needed
    if ((refreshType === 'init' && (!teams || teams.length === 0)) || refreshType === 'force') {
      if (!this.onlineStatus.isOnline) {
        teams = await this.teamsStorageService.syncSortedTeamsAsync();
      }
    }

    this.mapTeams(teams);
    return teams;
  }

  /**
   * Refresh team data from the server
   */
  public async refreshTeamsAsync(): Promise<Array<Team>> {
    const teams = await this.teamsStorageService.refreshTeamsAsync();
    this.mapTeams(teams);
    return teams;
  }

  public async syncTeamsAsync() {
    const teams = await this.teamsStorageService.refreshTeamsAsync();
    this.mapTeams(teams);
    return teams;
  }

  public getAllTeams(): Array<Team> {
    this.checkInitialised();
    return Array.from(this.teamsByIdentifier.values());
  }

  public getTeam(id: string): Team {
    this.checkInitialised();
    return this.teamsByIdentifier.get(id);
  }

  public getAllMappedTeams(): Array<MappedTeam> {
    this.checkInitialised();
    return Array.from(this.mappedTeamsByIdentifier.values());
  }

  public getMappedTeam(id: string): MappedTeam {
    this.checkInitialised();
    return this.mappedTeamsByIdentifier.get(id);
  }

  public async deleteTeamsAsync(deleted: string[]) {
    // Delete from storage
    await this.teamsStorageService.removeTeamsAsync(deleted);

    // Delete from memory cache
    deleted.forEach((deletedRecordId) => {
      this.teamsByIdentifier.delete(deletedRecordId);
      this.mappedTeamsByIdentifier.delete(deletedRecordId);
    });

  }

  public async storeTeamAsync(team: Team): Promise<void> {
    this.checkInitialised();

    // Store locally
    this.teamsByIdentifier.set(team.Id, team);
    this.mappedTeamsByIdentifier.set(team.Id, new MappedTeam(team.Id, team.Name));

    // Update storage
    return this.teamsStorageService.replaceTeam(team);
  }

  private mapTeams(teams: Array<Team>) {
    const teamMap = new Map<string, Team>();
    const mappedTeamMap = new Map<string, MappedTeam>();

    if (teams) {
      teams.forEach(team => {
        teamMap.set(team.Id, team);
        mappedTeamMap.set(team.Id, new MappedTeam(team.Id, team.Name));
      });
    }

    this.teamsByIdentifier = teamMap;
    this.mappedTeamsByIdentifier = mappedTeamMap;
  }

  private checkInitialised() {
    if (!this.teamsByIdentifier || !this.mappedTeamsByIdentifier) {
      throw new Error('TeamsService is not initialised.  Check guards have been called');
    }
  }
}
