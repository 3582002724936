
export enum ValidationRuleType {

  /** Secondary field(s) must match the primary field */
  FieldsMustMatch = 1,

  /** Must be a valid password value - the meaning is
   * defined in the validator and may vary by site
   */
  ValidPasswordValue = 2,

  /** Must be a valid username value - the meaning is
  * defined in the validator and may vary by site
  */
  ValidUsernameValue = 3,


  /**
   * Field is required, same as standard required flag but may be
   * modifier by rule filter
   */
  Required = 4,
}

/**
 * Defines a validation rule for the app.
 * We support a number of ad hoc field attributes that cause validation to occur
 * (e.g. MaxValue) but these can only affect a single field and adding more rules
 * would mean many attributes.  This interface defines an alternative way which
 * defines a set of rules applied to the overall application.  These are easier
 * to manage and can have arbitrary parameters.
 *
 * Not yet supported by app studio.
 *
 */
export interface ValidationRule {

  /** Indicates the rule to be executed */
  Type: ValidationRuleType;

  /** If set, the rule only applies when this filter is matched */
  FilterWhen?: string;

  /** Main field affected by the rule (if any) */
  FieldId?: string;

  /** Other fields affected by the rule (if any) */
  SecondaryField?: string | Array<string>;
}
