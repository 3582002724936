import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Enums } from '@softools/softools-core';

@Component({
  selector: 'app-foldericon',
  templateUrl: './foldericon.component.html',
  styleUrls: ['./foldericon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldericonComponent implements OnInit {

  public folderType;
  public secondaryFolderType;

  public reportTypes = Enums.ReportTypes;
  public chartTypes = Enums.ChartType;
  public iconType = 'list';

  constructor() { }

  ngOnInit() {
    switch (this.folderType) {
      case 1: // List
      case 6: // Table - temp use same as list
        this.iconType = 'list';
        return;
      case 2: // Matrix
        this.iconType = 'th';
        return;
      case 3: // Chart
        switch (this.secondaryFolderType) {
          case 0: // area
          case 1: // areaspline
          case 'area':
          case 'areaspline':
            this.iconType = 'chart-area';
            return;
          case 6: // line
          case 10: // spline
          case 'line':
          case 'spline':
            this.iconType = 'chart-line';
            return;
          case 7: // pie
          case 'pie':
            this.iconType = 'chart-pie';
            return;
          case 14: // geochart
          case 'map':
            this.iconType = 'globe-americas';
            return;
          case 2: // bar
          case 4: // column
          case 'bar':
          case 'column':
          default:
            this.iconType = 'chart-bar';
            return;
        }
      case 5: // Dashboard
        this.iconType = 'tachometer-alt';
        return;
    }
  }

  @Input()
  set FolderType(folderType: number) {
    this.folderType = folderType;
  }

  @Input()
  set SecondaryFolderType(secondaryFolderType: number) {
    this.secondaryFolderType = secondaryFolderType;
  }
}
