<div class="d-flex p-2 w-100 align-items-center overflow-hidden" *ngIf="showAppIcon || showAppTitle">
  <div *ngIf="showAppIcon && iconBase64" class="flex-column m-2 me-4 app-icon">
    <app-icon [iconBase64]="iconBase64" size="4.7rem"></app-icon>
  </div>

  <h1 class="justify-content-center flex-column theme-dark-text"
       *ngIf="showAppTitle"
       title="{{appTitle}}">{{appTitle}}</h1>

  <fa-icon *ngIf="showHelpIcon"
    [icon]="['fal', 'info-circle']" size="2x"
    style="color: royalblue; margin-left: auto;"
    class="flex-column me-2"
    (click)="helpClicked.emit()"
    ></fa-icon>

</div>

<p class="p-2 mb-2 theme-dark-text"
  title="{{appDescription}}"
  *ngIf="showAppDescription">{{appDescription}}</p>
