import { MvcBase } from '@softools/vertex';
import { logError } from 'app/core';
import { Subject, timer } from 'rxjs';

/**
 * 
 */
export class ModelTimer extends MvcBase {

  private ticking = false;

  public readonly $ = new Subject<void>();

  constructor(container: MvcBase, private every: number, private delay?: number) {
    super();

    this.subscribe(container.disposed$, () => {
      this.dispose();
    });
  }

  public start(): void {
    this.subscribe(timer(this.delay, this.every), () => {
      if (!this.ticking) {
        try {
          this.ticking = true;
          this.$.next();
        } catch (error) {
          logError(error, 'tick');
        } finally {
          this.ticking = false;
        }
      }
    });
  }
}
