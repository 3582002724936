import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalContentDirective } from './modalcontent.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalMessageComponent } from './modal-message.component';
import { ModalHeaderComponent } from './modal-header.component';

@NgModule({
  declarations: [
    ModalComponent,
    ModalContentDirective,
    ModalMessageComponent,
    ModalHeaderComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [ModalComponent],
  providers: [],
})
export class ModalModule { }
