import { TextualAppField } from './textual-app-field';

/**
 * Field implementation for an image field.
 *
 * Currently the image just uses the id as a text value.
 * This inherits the sort behaviour of TextualAppField which is not very meaningful
 * but will sort/group like images together.
 *
 * We have added backing fields to the image field but they're currently not used.
 * If that changes we can change the type of field data and use that information
 * e.g. to sort by filename.  Avoid relying on it being implementaed as a textual field.
 */
export class ImageAppField extends TextualAppField {

  /** Convert the field internal value into the format required by the field component */
  public override adaptInternalValue(value: any) {
    // We could get structured values if backing fields exist in the config,
    // or a string value from an old field.  Use the string value in either case
    if (value?.hasOwnProperty('val')) {
      return value.val;
    } else {
      return value;
    }
  }
}
