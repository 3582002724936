<div
  class="position-fixed w-100 h-100 text-center bg-white p-4"
  style="padding-top: 10%!important; top: 0"
>
  <!-- Is default image always company logo? -->
  <div class="d-flex justify-content-center">
    <div style="width: 15rem;">
      <app-asset-image [assetUrl]="imgString" >
      </app-asset-image>
    </div>
  </div>

  <h1 class="display-5" i18n>Syncing Apps and Data</h1>
  <p *ngIf="syncPhase === syncPhases.Starting">
    <span i18n>Getting ready to synchronize ...</span>
  </p>

  <app-online-required #online>
    <h1 i18n>
      <fa-icon
        [icon]="['fal', 'wifi-slash']"
        [border]="false"
        size="1x"
        class="me-2"
      ></fa-icon>
      Softools synchronisation services are not available
    </h1>
    <p i18n>
      Your device cannot connect to the Internet or Softools services cannot be
      reached.
    </p>
    <p i18n>
      Synchronisation will continue when the device is reconnected.
    </p>
  </app-online-required>

  <div *ngIf="online.isOnline">

    <p *ngIf="syncPhase === syncPhases.Clearing">
      <span i18n>Resetting temporary storage</span>
    </p>

    <p *ngIf="syncPhase === syncPhases.Apps">
      <span i18n>Synchronising App Specifications</span>
    </p>
    <ng-container *ngIf="syncPhase === syncPhases.AppData">
      <p>
        <span i18n
          >Synchronising App Data ({{ syncPhaseCount }} of
          {{ syncPhaseTotal }} apps ready)</span
        >
      </p>
    </ng-container>
    <p *ngIf="syncPhase === syncPhases.UsersTeams">
      <span i18n> Synchronising Users and Teams</span>
    </p>
    <p *ngIf="syncPhase === syncPhases.OtherStuff">
      <span i18n>Other items  Synchronisation</span>
    </p>
    <p *ngIf="syncPhase === syncPhases.PendingUsers">
      <span i18n>Pending Users Synchronisation</span>
    </p>
    <p *ngIf="syncPhase === syncPhases.ImageListAssets">
      <span i18n>Image List Assets Synchronisation</span>
    </p>
    <p *ngIf="syncPhase === syncPhases.ImageFieldAssets">
      <span i18n>Image Assets Synchronization</span>
    </p>
    <p *ngIf="syncPhase === syncPhases.Complete">
      <span i18n>Synchronisation Complete</span>
    </p>

    <img
      src="assets/icons/softoolsF.gif"
      style="width: 2.6rem;"
      *ngIf="!isErrored"
    />

    <ng-container *ngIf="syncPhase === syncPhases.AppData">
      <p class="my-4"><small i18n>{{syncRecordCountDisplay}} records stored</small></p>
    </ng-container>

    <div class="alert alert-danger mt-5" *ngIf="isErrored">
      <h2 i18n>An error occured while synchronizing your applications.</h2>
      <p i18n>
        Please <a href="#" (click)="logout()">Log out</a> and retry. If the
        problem persists please contact Softools.
      </p>
    </div>
  </div>
</div>

<div class="fixed-bottom text-muted ps-2">
  <small>Softools v{{ appVersionNumber }}</small>
</div>
