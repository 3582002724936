import { APP_IDENTIFIER, REPORT_IDENTIFIER } from 'app/_constants';
import { globalGuards } from 'app/guards/global-guards';
import { ListReportQueryGuard } from 'app/guards/reports';
import { WSListReportComponent } from './components/ws-reports/ws-listreport/ws-listreport.component';
import {
  SelectListsExistsGuard, UsersGuard, TeamsGuard,
  ImageListsExistsGuard
} from 'app/guards';
import { Route } from '@angular/router';
import { HomeGuard } from 'app/guards/homepages.guard';
import { ImageListAssetsGuard } from 'app/guards/image-list-assets.guard';

export const appGuards = [
  SelectListsExistsGuard,
  UsersGuard,
  TeamsGuard,
  ImageListsExistsGuard,
  ImageListAssetsGuard,
  HomeGuard
];

export const routes = [
  {
    path: `Main/:${APP_IDENTIFIER}/:${REPORT_IDENTIFIER}`,
    component: WSListReportComponent,
    canActivate: [...globalGuards, ...appGuards, ListReportQueryGuard],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    data: { type: 'List', isChild: false, zone: 'listreport' }
  }
] as Array<Route>;
