import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, Params } from '@angular/router';
import { Record, logError } from '@softools/softools-core';
import { AppService } from 'app/services/app.service';
import { BaseRecordCreator } from './BaseRecordCreator';
import { NavigationService } from 'app/services/navigation.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { AppDataService } from 'app/services/appdata.service';

@Injectable()
export class NewRecordResolver extends BaseRecordCreator implements Resolve<any> {

  constructor(
    private appsService: AppService,
    appDataService: AppDataService,
    public router: Router,
    private navigation: NavigationService) {
    super(appDataService);
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record> {
    try {
      const appIdentifiers = new AppIdentifiers(route.params);
      const app = this.appsService.application(appIdentifiers.appIdentifier);
      const record: Record = await this.createInitialRecord(app, { values: route.queryParams });

      // Redirect to edit page for newly created record
      const url = this.navigation.getRecordUrl2(appIdentifiers, record._id);
      this.router.navigateByUrl(url).catch(error => logError(error, 'Failed to navigate'));
      return null;
    } catch (error) {
      logError(error, 'Error in NewRecordResolver.NewRecordResolver');
      return null;
    }
  }
}
