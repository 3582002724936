import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseIconComponent } from '../iconbase';

@Component({
  selector: 'sof-spinner-icon',
  templateUrl: '../icon.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent extends BaseIconComponent {
  constructor() {
    super('spinner-third');

    this.spin = true;
  }
}
