import { Component, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { FieldBase } from 'app/softoolsui.module/fields/field-base';
import { Enums, logError } from '@softools/softools-core';
import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons';
import { IExpandedField } from 'app/mvc/reports/report.model';

@Component({
  selector: 'app-icon-display-field',
  templateUrl: './icon-display-field.component.html',
  styleUrls: ['./icon-display-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDisplayFieldComponent extends FieldBase {

  constructor() {
    super();
  }

  @ViewChild('iconContainer', { static: true })
  private iconComponent: ElementRef<HTMLDivElement>;

  public get icon(): [IconPrefix, IconName] {
    switch (this.fieldModel.Type) {
      case Enums.FieldType.InAppChart:
        return ['fal', 'chart-bar'];
      case Enums.FieldType.ListField:
        return ['fal', 'th-list'];
      case Enums.FieldType.GridField:
        return ['fal', 'th'];
      case Enums.FieldType.AttachmentList:
        return ['fal', 'paperclip'];
      case Enums.FieldType.EmbeddedVideo:
        return ['fal', 'film'];
      case Enums.FieldType.Draw:
        return ['fal', 'pencil-alt'];
      default:
        return ['fal', this.fieldModel.defaultIcon() as IconName];
    }
  }

  public clicked($event: MouseEvent) {

    $event.stopPropagation();

    // If we're an inappchart,  toggle display
    // It would make sense to allow any field not suited to inline display (e.g. grid,
    // list, embedded video) to open in this way in which case the action should be
    // changed to a generic field expansion toggle action and we'll avoid this nasty
    // type check.  The model now mostly does this, but we still need to know which fields
    // are valid to expand - maybe via an AppField method.
    if (this.fieldModel.Type === Enums.FieldType.InAppChart && this.forReport) {
      if (this.reportModel) {
        const expanded: IExpandedField = { recordId: this.record._id, fieldIdentifier: this.fieldModel.Identifier, inAppChartReportIdentifier: this.fieldModel.InAppChartReportIdentifier };
        this.reportModel.toggleExpandedField(expanded);
      }
    } else {
      // perform app field default action
      // above code can be moved into chart app field
      this.fieldModel.perform(this).catch(e => logError(e, 'perform'));
    }
  }

  public override async activate() {
    this.iconComponent?.nativeElement?.focus();
  }
}
