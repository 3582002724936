import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-offline',
  templateUrl: './report-offline.component.html',
  styleUrls: ['./report-offline.component.scss']
})
export class ReportOfflineComponent {

  constructor() { }

}
