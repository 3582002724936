import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordSelectorComponent } from './record-selector.component';
import { TableReportModule } from '../table-report/table-report.module';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupContainerModule } from '../popup-container/popup-container.module';
import { FiltersModule } from 'app/filters/filters.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalOverlayModule } from '../cdk-global-overlay.directive/cdk-global-overlay.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterIconModule, SofSpinnerModule } from '@softools/design/icons';
import { SearchModule } from '../search.component/search.module';
import { MatButtonModule } from '@angular/material/button';
import { ListReportModule } from '../listreport.component/listreport.module';
import { PaginationModule } from '../pagination/pagination.module';
import { QuickFiltersModule } from 'app/workspace.module/components/quick-filter/quick-filters.module';

@NgModule({
  declarations: [
    RecordSelectorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    GlobalOverlayModule,
    MatDialogModule,
    MatButtonModule,
    FontAwesomeModule,
    TableReportModule,
    ListReportModule,
    PaginationModule,
    PopupContainerModule,
    FiltersModule,
    FilterIconModule,
    SearchModule,
    SofSpinnerModule,
    QuickFiltersModule
  ],
  exports: [
    RecordSelectorComponent
  ]
})
export class RecordSelectorModule { }
