import * as moment from 'moment';
import { EpochDate } from '@softools/softools-core';

export enum DateFormat {
  IsoUtc,
  IsoLocale,
  Ago,
  ShortDate,
  LongDate,
  ShortDateTime,
  LongDateTime,
  ShortPeriod,
  LongPeriod,
}

/**
 * Utilities for converting to and from @see EpochDate
 */
export class EpochConverter {

  /**
   * Convert any reasonable date format into an @see EpochDate
   * @param date
   * @param utc   Hints that if true, input date is in utc format, false in locale format
   *    (if the date includes locale info this will take priority)
   */
  public static toEpoch(date: any, utc = true): EpochDate {
    if (date === undefined || date === null) {
      return null;
    }

    if (date.$val) {
      return date;
    }

    const when = utc ? moment.utc(date) : moment(date);
    return { $date: when.valueOf() };
  }

  /**
   * Convert an epoch date into its string format
   * @param date
   * @param format  String format to use
   */
  public static toDateString(date: EpochDate, format: DateFormat = DateFormat.IsoUtc): string {

    if (date === undefined || date === null) {
      return null;
    }

    if (date.$date) {
      const when = moment.utc(date.$date);
      switch (format) {
        case DateFormat.IsoLocale:
          return when.toISOString(true);
        case DateFormat.Ago:
          return when.fromNow();
        case DateFormat.ShortDate:
          return when.format('L');
        case DateFormat.LongDate:
          return when.format('LL');
        case DateFormat.ShortDateTime:
          return when.format('L LT');
        case DateFormat.LongDateTime:
          return when.format('LLL');
        case DateFormat.ShortPeriod:
          return when.format('YYYY-MM');
        case DateFormat.LongPeriod:
          return when.format('MMMM YYYY');
        default:
          return when.toISOString();
      }
    }

    return null;
  }

  public static toMoment(epoch: EpochDate) {
    return moment.utc(epoch.$date);
  }

  public static toJSON(epoch: EpochDate) {
    return this.toMoment(epoch).toJSON();
  }

}
