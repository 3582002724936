import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

import { WSBase } from 'app/workspace.module/components/ws-base/ws-base';
import { contentPinnedStateAnimation, leftAsideStateAnimation, homepageHelpStateAnimation, mobileHomepageHelpStateAnimation } from 'app/_constants';
import { QueryParams, Enums, OnlineStatusService, logError } from '@softools/softools-core';

import { InfoDialogComponent } from './InfoDialog/InfoDialog.component';
import { AppService } from 'app/services/app.service';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { RouteParams, FolderModel, AppHomeModel } from 'app/mvc';
import { AppHomeController } from 'app/mvc/home/app-home.controller';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { AppHomeFolderController, ChildAppHomeFolderController } from 'app/mvc';

const ARROW_UP = 38;
const ARROW_DOWN = 40;


@Component({
  selector: 'app-ws-apphome',
  templateUrl: './ws-apphome.component.html',
  styleUrls: ['./ws-apphome.component.scss'],
  animations: [
    contentPinnedStateAnimation,
    leftAsideStateAnimation,
    homepageHelpStateAnimation,
    mobileHomepageHelpStateAnimation,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSAppHomeComponent extends WSBase implements OnInit, OnDestroy {

  public routerQuerySub: Subscription;
  public LastAccessedReportsSub: Subscription;
  public dialogRefSub: Subscription;

  // @ts-ignore Override of proeprty to accessor is an error in ts now
  public appIdentifier;
  public showAppHelpDescription$ = new BehaviorSubject(true);
  public showAppHelpPage$ = new BehaviorSubject(false);

  public searchText = '';

  public visibleReports: Array<{ reportIdentifier: string, reportTitle: string, isLastAccessed: boolean, reportType: number, chartType: number }> = [];
  public mappedAllReports: Array<{ reportIdentifier: string, reportTitle: string, isLastAccessed: boolean, reportType: number, chartType: number }> = [];

  /** Records can be pinned if true (very WIP) */
  public pinningEnabled = false;

  public appHomeModel = new AppHomeModel(this.appModel, this.models.pageModel);
  public folderModel: FolderModel;

  public controller = new AppHomeController(this.appHomeModel);

  public folderController: AppHomeFolderController | ChildAppHomeFolderController;

  constructor(
    public media: MediaObserver,
    route: ActivatedRoute,
    public override onlineStatus: OnlineStatusService,
    public override appService: AppService,
    navigationService: NavigationService,
    protected override permissionsService: PermissionsService,
    override readonly models: GlobalModelService
  ) {
    super(onlineStatus, appService, route, navigationService, permissionsService, models);

    this.folderModel = models.pageModel.folderModel;
    this.generalController = this.controller;

    this.appHomeModel.initialise();
  }

  public override ngOnInit(): void {

    super.ngOnInit();

    this.routerQuerySub = this.route.params.subscribe(() => {
      this.routeChanged(this.route.snapshot.params);
    });
  }

  protected override async routed(params: RouteParams) {

    // Set up folder controller early so it tracks model changes
    this.folderController = params.parentAppIdentifier ?
      new ChildAppHomeFolderController(this.appHomeModel, this.folderModel) :
      new AppHomeFolderController(this.appHomeModel, this.folderModel);

    await this.appModel.routed(params);
    await this.appHomeModel.routed(params);
    this.folderController.initialise();
  }

  public override ngOnDestroy(): void {

    super.ngOnDestroy();

    this.routerQuerySub.unsubscribe();

    if (this.LastAccessedReportsSub) { this.LastAccessedReportsSub.unsubscribe(); }
    if (this.dialogRefSub) { this.dialogRefSub.unsubscribe(); }

    this.appHomeModel.dispose();
  }

  public get appTitleFieldIdentifier() {
    const app = this.currentApp;
    return app && app.TitleFieldIdentifier;
  }

  public async showInfoOverlay() {
    await this.globalModel.dialogAsync(InfoDialogComponent, this.appModel.app.value);
  }

  public getReportUrl(reportIdentifier: string, reportType: Enums.ReportTypes): string {
    return this.navigationService.getReportUrl(this.appIdentifier, reportIdentifier, reportType);
  }

  public detailPageQueryParams(item: any): QueryParams {
    const hierarchy = item && item.hierarchy && item.hierarchy.length > 0
      ? { hierarchy: item.hierarchy } as QueryParams
      : {} as QueryParams;
    return hierarchy;
  }

  public hideAppHelpClickHandler = (e: Event) => {
    e.stopPropagation();
    this.showAppHelpDescription$.next(false);
  }

  public showAppHelp = (e: Event) => {
    e.stopPropagation();
    this.showAppHelpDescription$.next(true);
  }

  public onAppSearchOptionSelectedHandler(option: { text: string, value: string }): void {
    this.globalModel.navigation.navigateRecordAsync({
      appIdentifier: this.appIdentifier,
      recordId: option.value
    }).catch(error => logError(error, 'AppSearchOption'));
  }

  public async onAppSearchOpenDefaultListReportHandler(value: string): Promise<void> {
    await this.controller.setupSearchListReport(value);
  }

  public onAppSearchHandler(payload: { event: KeyboardEvent, value: string }): void {
    if (payload.event.key !== 'ArrowUp' && payload.event.key !== 'ArrowDown') {
      this.controller.search(payload.value).catch(e => logError(e, 'home-search'));
    }
  }

  public onAppSearchEscHandler(): void {
    this.controller.clearSearch().catch(e => logError(e, 'home-clear-search'));
  }
}
