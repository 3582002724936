import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { FilterComponent } from './filter.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [FilterComponent],
  exports: [FilterComponent]
})
export class FilterIconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFilter);
  }
}
