import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { FilterSpecification } from 'app/filters/types';
import { Enums, logError, Field, IFilterTerm } from '@softools/softools-core';
import { FilterTermUpdates } from '../filter-simple-popup/filter-simple-popup.component';
import { UsersService } from 'app/services/users.service';
import { Application } from 'app/types/application';
import { AppService } from 'app/services/app.service';
import { FilterEditorUi } from '../types/filter-editor-ui';
import { FilterModel } from 'app/mvc';
import { BehaviorSubject } from 'rxjs';
import { AppField } from 'app/types/fields/app-field';

@Component({
  selector: 'app-filter-display',
  templateUrl: './filter-display.component.html',
  styleUrls: ['./filter-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDisplayComponent implements OnInit {
  @Input() appIdentifier: string;

  @Input()
  public filterSpec: FilterSpecification = null;

  @Input() filterModel: FilterModel;

  @Input()
  orign: CdkOverlayOrigin;

  // Filter popup support.  Will need to push up to state
  public filterEditorUi = new FilterEditorUi();

  /** Current filters as array of terms */
  public flattened$ = new BehaviorSubject<Array<IFilterTerm>>([]);

  public showFilter: 'none' | 'add' | 'edit' = 'none';
  public editFilterIndex = 0;

  public popupPosition = [new ConnectionPositionPair({ originX: 'center', originY: 'center' }, { overlayX: 'center', overlayY: 'center' })];

  private app: Application;

  constructor(private usersService: UsersService, private appService: AppService) {}

  ngOnInit() {
    try {
      this.app = this.appService.application(this.appIdentifier);
      this._generateFlatView();
    } catch (error) {
      logError('init FilterDisplayComponent', error);
    }
  }

  public editFilterClause(e: MouseEvent, filter: IFilterTerm) {
    try {
      e.stopPropagation();

      const field = this.filterSpec.getField(filter.FieldIdentifier);

      if (field.isFilterable()) {
        this.showFilter = 'edit';
        this.filterEditorUi = new FilterEditorUi();
        this.filterEditorUi.popupW = 200;
        this.filterEditorUi.editFilterSpec = this.filterSpec;
        this.filterEditorUi.editFilterTerm = filter;
        this.filterEditorUi.fieldId = field.Identifier;
        this.filterEditorUi.isNewFilter = false;
        this.filterEditorUi.disallowFields = [];
      }
    } catch (error) {
      logError(error, '');
    }
  }

  public clearFilterClause(e: MouseEvent, term: IFilterTerm) {
    try {
      e.stopPropagation();
      this.filterSpec.deleteTerm(term);
      this._generateFlatView();
    } catch (error) {
      logError(error, '');
    }
  }

  public createFilterClause(e: MouseEvent) {
    try {
      e.stopPropagation();

      this.showFilter = 'add';
      this.filterEditorUi = new FilterEditorUi();
      this.filterEditorUi.popupW = 100;
      this.filterEditorUi.editFilterSpec = this.filterSpec;
      this.filterEditorUi.editFilterTerm = null;
      this.filterEditorUi.fieldId = null;
      this.filterEditorUi.isNewFilter = true;
      this.filterEditorUi.disallowFields = [];
    } catch (error) {
      logError(error, '');
    }
  }

  public closeFilterTermEdit(): void {
    this.showFilter = 'none';
  }

  public filterTermUpdated(updates: FilterTermUpdates): void {
    this.showFilter = 'none';
    const updatedFilter = this.filterSpec.updateTerm(updates);
    this.filterSpec.updateFrom(updatedFilter);
    this._generateFlatView();
  }

  public filterTermCancelled(): void {
    this.closeFilterTermEdit();
  }

  /** Convert a filter value into the true value as required by edit fields.
   * This is needed for person fields where the editor deals with MappedUsers
   * but the filter only stores the id.  This should probably be considered a
   * code smell!
   */
  private _mapValue(field: AppField, value: any): any {
    switch (field.Type) {
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam: {
        const user = this.usersService.getMapped(value);
        // Return found user name, fall back to GUID if not in mapped user list
        return user ? user.Text : value;
      }

      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
      case Enums.FieldType.Time: {
        // Use app field to format - should be able to do this for other types
        return field.formatDisplayValue(value);
      }
    }

    return value;
  }

  private _generateFlatView() {
    const flat = this.filterSpec.flatten().sort((a, b) => b.id - a.id);
    flat.forEach((term) => {
      const field = this.filterModel.getField(term.FieldIdentifier);
      if (field) {
        term.displayOperand = this._mapValue(field, term.Operand);
      }
    });

    this.flattened$.next(flat);
  }
}
