
<div class="image-list-container d-flex w-100 h-100"
#container
tabindex="-1"
  [attr.title]="attrTitle()"
  [ngClass]="alignment"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
> 

  <div [class]="'image-list-container-' + imageSizeClass">
    <img [class]="'img-responsive image-list-item disabled visible ' + imageSizeClass"  
      [src]="imageSource()" 
      [class.pointer]="enabled$|async"
      (click)="onClickInline($event)"
    >
  </div>
</div>
