import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailActivityComponent } from './email-activity.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BusyIndicatorModule } from 'app/softoolsui.module/busy-indicator/busy-indicator.module';
import { SofSpinnerModule } from 'app/design/icons';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    BusyIndicatorModule,
    SofSpinnerModule,
    MvcDirectivesModule
  ],
  exports: [
    EmailActivityComponent
  ],
  declarations: [EmailActivityComponent]
})
export class EmailActivityModule { }
