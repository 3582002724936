import { AppField } from './app-field';

export class LiteralAppField extends AppField {
  public override updatePatchWithDefault() {
    // nop - literals use value from app definition
  }

  public override validateRequired() {
    // nop - lit fields can't be set
  }

  public override showHeaderOnTemplate() {
    return false;
  }

}
