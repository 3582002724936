import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SelectListsService } from 'app/services/select-lists.service';
import { logError } from '@softools/softools-core';

@Injectable()
export class SelectListsExistsGuard implements CanActivate {

  constructor(
    private selectListsService: SelectListsService,
  ) { }

  public async canActivate(): Promise<boolean> {
    try {
      await this.selectListsService.refresh('init');
      // A site might have no select lists so alwasy accept
      return true;
    } catch (error) {
      logError(error, 'SelectListsExistsGuard rejected navigation due to error');
      throw error;
    }
  }
}
