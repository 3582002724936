import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropZoneComponent } from './dropzone.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [DropZoneComponent],
  exports: [DropZoneComponent]
})
export class SofDropZoneModule {}
