import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormHeaderComponent } from 'app/softoolsui.module/form.component/form-header/form-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AssetImagesModule } from 'app/softoolsui.module/asset-image/asset-images.module';
import { IconModule } from 'app/softoolsui.module/icon/icon.module';

@NgModule({
  declarations: [FormHeaderComponent],
  imports: [
    CommonModule,
    AssetImagesModule,
    FontAwesomeModule,
    IconModule
  ],
  exports: [FormHeaderComponent],
  providers: [],
})
export class FormHeaderModule { }
