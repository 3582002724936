import { InjectionToken } from '@angular/core';

export class CoreConfigModel {
    environmentBaseUrl: string;

    /** Host prefix for User APIs */
    userApiBaseUrl?: string;
}

export const CoreConfigModelToken = new InjectionToken<CoreConfigModel>('CoreConfigModel');
