import { OdataExpression } from './odata-expression';
import { OdataExpressionType } from './odata-expression-type';
import { IRootQuery } from './root-query';

export class OdataNotExpression extends OdataExpression {

  private expression: OdataExpression;

  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.Not, root, parent);
    this.expression = this.walk(onode.value);
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    return !this.expression.isMatch(values, listRow);
  }

  public override expressionText(): string {
    return `not(${this.expression.expressionText()})`
  }
}
