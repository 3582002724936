import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationMessage } from '@softools/softools-core';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  public messages = new BehaviorSubject<NotificationMessage>(null);

  public send(message: NotificationMessage) {
    this.messages.next(message);
  }
}
