<div class="d-flex">
  <div class="flex-fill">
      <ng-content></ng-content>
  </div>
 <ng-container *ngIf="show">
    <a *ngIf="valid && url" [href]="url"
      (click)="$event.stopPropagation()"
      target="_blank"
      class="pe-2 grid-row ms-1"
        [ngClass]="colorClass"
        style="align-self: center; justify-self: end;">
        <fa-icon
        [matTooltip]="tooltip"
        [icon]="['fal', icon]"
        [class.pointer]="clickable"
        >
      </fa-icon>
    </a>
    <fa-icon *ngIf="!valid || !url"
      [matTooltip]="tooltip"
      [icon]="['fal', icon]"
      class="pe-2 grid-row ms-1 position-end"
      [ngClass]="colorClass"
      [ngStyle]="iconStyle"
      [class.pointer]="clickable" 
      (click)="onIconClicked($event)">
    </fa-icon>
  </ng-container>
</div>
