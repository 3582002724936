export enum ChangedRecordAction {
  Created = 1,
  Updated = 2,
  Deleted = 3,
  AccessRightsChanged = 4,
}

export interface ChangedRecord {

  /** Change Id */
  Id: string;

  /** A unique identifier for the object that has changed.  For a record this is the record id. */
  ObjectId: string;

  /** Tenant Id */
  Tenant: string;

  /** App or other object owning the changed object */
  AppIdentifier: string;

  /** Change made */
  Action: ChangedRecordAction;

  /** Time update occured. This should only be used informationally  */
  Timestamp: string;
}
