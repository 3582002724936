// import { Failure, Record, ValidationError } from '@softools/softools-core';
import { Failure, OdataExpressionType, Record, ValidationError } from '@softools/softools-core';
import { AppField } from './app-field';

/**
 * A field that contains text data
 * This includes fields that have native text data (Text, Long Text, etc.) and also
 * fields that use a simple internal text representation
 */
export class TextualAppField extends AppField<string> {

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    val1 = val1 || '';
    val2 = val2 || '';
    return isDescending ? val2.localeCompare(val1) : val1.localeCompare(val2);
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {

    super.validate(value, errors, listRow);

    if (value !== undefined && value != null) {
      if (this.Length) {
        if (value.length > this.Length) {
          errors.push({
            error: ValidationError.TooLong,
            param: this.Length,
            fieldId: this.Identifier, listRow
          });
        }
      }

      if (this.MinLength) {
        if (value.length < this.MinLength) {
          errors.push({
            error: ValidationError.TooShort,
            param: this.MinLength,
            fieldId: this.Identifier,
            listRow
          });
        }
      }
    }
  }

  /** Value to display in value tooltip */
  public override titleValue(record: Record) {
    if (this.TextFieldOptions && this.TextFieldOptions.PasswordField) {
      return '';
    } else {
      return super.titleValue(record);
    }
  }

  public override validatePattern(value: any, errors: Array<Failure>, listRow?: number) {
    if (this.Pattern?.length > 0 && value?.length > 0) {
      const regex = new RegExp(this.Pattern);
      if (!regex.test(value)) {
        errors.push({
          error: ValidationError.Pattern,
          fieldId: this.Identifier,
          listRow,
          param: this.Pattern
        });
      }
    }
  }

  public override filterOperations(): Array<OdataExpressionType> {
    return [
      OdataExpressionType.Equals,
      OdataExpressionType.NotEquals,
      OdataExpressionType.StartsWith,
      OdataExpressionType.EndsWith,
      OdataExpressionType.Substring,
      OdataExpressionType.ContainsOneOf,
      OdataExpressionType.ContainsNoneOf
    ];
  }
}

export class NotesAppField extends TextualAppField {
  public override defaultIcon() {
    return 'sticky-note';
  }
}
