import { Failure, MappedTeam, Record, stringCompare, ValidationError } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';
import { AppField } from './app-field';
import { TeamsService } from 'app/services/teams.service';
import { InjectService } from 'app/services/locator.service';

export class TeamAppField extends AppField {

  @InjectService(TeamsService) teamsService;

  public override getInternalRecordValue(record: Record, _listRow?: number) {
    const value = record[this.DataIdentifier];
    if (value) {

      if (value.hasOwnProperty('val')) {
        // already converted
        return value;
      }

      const team = this.teamsService.getTeam(value);
      if (team) {
        return {
          val: value,
          txt: team.Name
        }
      }
    }

    return {
      val: value,
      txt: value
    }
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    const name1 = val1?.txt;
    const name2 = val2?.txt;
    return isDescending ? stringCompare(name2, name1) : stringCompare(name1, name2);
  }

  public override compareInternalValues(val1: any, val2: any, isDescending: boolean): number {
    const name1 = val1?.txt;
    const name2 = val2?.txt;
    return isDescending ? stringCompare(name2, name1) : stringCompare(name1, name2);
  }

  public override getDisplayRecordValue(record: Record, listRow?: number) {
    // No value if no record (probably only happens in test cases)
    if (!record) {
      return undefined;
    }

    const value = record[this.DataIdentifier];

    if (this.DisplayFormatted) {
      if (value && value.hasOwnProperty('fmt')) {
        return value.fmt;
      }

      // Fall back to old format, should be safe to delete
      const formattedName = `${this.Identifier}_Formatted`;
      if (record && record.hasOwnProperty(formattedName)) {
        return record[formattedName];
      }
    }

    if (value && value.hasOwnProperty('txt')) {
      return value.txt;
    }

    // Fall back to old format, should be safe to delete
    const textBackingName = `${this.Identifier}_Text`;
    if (record && record.hasOwnProperty(textBackingName)) {
      return record[textBackingName];
    }

    return this.getRawRecordValue(record, listRow);
  }

  public override formatDisplayValue(value: any) {

    if (value?.hasOwnProperty('txt')) {
      return value.txt;
    }

    const id = this.simpleValue(value);
    const team = this.teamsService.getTeam(id);
    if (team) {
      return team.Name;
    }

    return id;
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    super.validate(value, errors, listRow);

    if (value) {
      if (value && value.isInvalidValue) {
        errors.push({ error: ValidationError.NotATeam, fieldId: this.Identifier, listRow });
      }
    }
  }

  public override createPatch(record: Record | RecordPatch, value: any, _listRow: number | string, options?: { local?: boolean }): RecordPatch {
    if (value instanceof MappedTeam) {
      const patch = super.createPatch(record, value.Value, _listRow, options);
      patch.addLocalChange(`${this.DataIdentifier}_Text`, value.Text);
      return patch;
    } else {
      const patch = super.createPatch(record, value, _listRow, options);
      if (!value) {
        patch.addLocalChange(`${this.DataIdentifier}_Text`, null);
      }
      return patch;
    }
  }
}
