import { AppDataStorageService, AppsService, Enums, Record, Report, ReportViewService } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { AppSearchReportIdentifierConst, defaultReportViewParams } from 'app/_constants';
import { AppModel, GlobalModel } from '..';
import { IGeneralController } from '../common/general-controller.interface';
import { AppHomeModel } from './app-home.model';

export class AppHomeController implements IGeneralController {

  public readonly globalModel: GlobalModel;

  public appModel: AppModel;

  // temp - should be a better way.
  private currentSearch = '';

  @InjectService(AppsService)
  private readonly appStorageService: AppsService;

  @InjectService(ReportViewService)
  private readonly reportViewService: ReportViewService;

  @InjectService(AppDataStorageService)
  private readonly _appDataService: AppDataStorageService;

  constructor(public appHomeModel: AppHomeModel) {
    this.appModel = this.appHomeModel.appModel;
    this.globalModel = this.appModel.globalModel;
  }

  public async search(search: string) {
    // Ignore re-entrant searchs
    if (search !== this.currentSearch) {
      this.appHomeModel.searchPending.value = false;
      this.appHomeModel.searchOptions.value = [];

      const app = this.appModel.app.value;
      if (search.length > 1 && app.TitleFieldIdentifier) {
        this.currentSearch = search;

        this.appHomeModel.searchPending.value = true;

        // Build match collection
        const matches: Array<{ text: string, value: string }> = [];

        await this._appDataService.searchAsync(app.Identifier, search, (record: Record) => {
          // TODO: need to be able to abort the transaction to stop the cursor, we can then remove the current search fudge.
          // We still check and don't expand the matches collection - but still need to be able to abort search
          if (!record['IsArchived'] && this.currentSearch === search) {
            matches.push({ value: record._id, text: record[app.TitleFieldIdentifier] });
          }
        });

        // Only update if still the active search
        if (this.currentSearch === search) {
          this.appHomeModel.searchOptions.value = matches;
        }

        this.appHomeModel.searchPending.value = false;
      } else {
        this.currentSearch = '';
      }
    }
  }

  public async clearSearch() {
    await this.search('');
  }

  /*
    We will display app search results in a copy of the Default List Report.
    Ignore any previously set BaseFilter and supply our own with users search text.
    In this effect we will clone the default list report and tweak to suit.
  */

  public async setupSearchListReport(search: string) {
    // Only start search on 2nd character to reduce load
    if (search.length >= 2) {
      const application = this.appModel.app.value;
      const defaultListReportIdentifier = application?.DefaultListReportIdentifier;
      if (defaultListReportIdentifier) {

        search = search.toLowerCase();

        // Get search report with magic id, or clone default report if not present
        const appSearchReport: Report = application.getReport(AppSearchReportIdentifierConst) ??
          { ...application.Reports.find((r) => r.Identifier === defaultListReportIdentifier) };

        // let appSearchReport: Report = app.Reports.find((r) => r.Identifier === AppSearchReportIdentifierConst);
        // appSearchReport = appSearchReport ? appSearchReport : { ...app.Reports.find((r) => r.Identifier === defaultListReportIdentifier) };

        appSearchReport.Id = 999999;
        appSearchReport.Identifier = AppSearchReportIdentifierConst;
        appSearchReport.AppIdentifier = application.Identifier;
        appSearchReport.Title = $localize`App Search`;
        appSearchReport.DisplayOrder = 99999999;
        appSearchReport.Category = null;
        appSearchReport.BaseFilter = null;
        appSearchReport.Type = Enums.ReportTypes.List;

        // Upsert the app search report, in state and in store
        // this.appModel.dispatch(new UpsertAppSearchReportAction(app.Identifier, appSearchReport, search));

        if (application) {
          application.addReport(appSearchReport);

          // IndexedDb doesn't like the application as it can't clone it properly
          // So get App from storage and update Reports to match
          const app = await this.appStorageService.getAppAsync(application.Identifier);
          app.Reports = application.Reports;
          await this.appStorageService.putAsync(app);

          // Reset report index to clear any previous filter
          const reportViewParams = { ...defaultReportViewParams, reportId: appSearchReport.Identifier };
          await this.reportViewService.setViewDataAsync(application.Identifier, reportViewParams);

          // Navigate to App Seach Report
          const navigation = this.globalModel.navigation;
          await navigation.navigateReportAsync({
            appIdentifier: application.Identifier,
            report: appSearchReport,
            navigationExtras: {
              queryParams: { '$filter': `substringof('${search}',QuickFilterSearchText)` }
            }
          });
        }
      }
    }
  }

  // IGeneralController impementation
  // ====

  public async archive(): Promise<void> {
    // nop, no selection to decide what to archive
  }

  public async unarchive(): Promise<void> {
    // nop, no selection to decide what to unarchive
  }

  public async delete(): Promise<void> {
    // nop, no selection to decide what to delete
  }

  public async export(): Promise<void> {
    // currently nop, can do something though
  }

  public async import() {
    // nop for now
  }

  public async security() {
    // nop for now
  }

  public async discardChanges(record: Record) {
    if (record) {
      const yes = await this.globalModel.showModalAsync(
        Enums.ModalType.confirm,
        $localize`Discard changes`,
        $localize`Do you want to discard changes to this record?`);

      if (yes) {
        await this.appHomeModel.removeItem(record._id);
      }
    }
  }

  // =====

}
