import { Rule, RuleAction, OdataQueryFilter, IIdentifierContainer, RuleActionType } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { ApplicationValueLookup } from 'app/services/indexes/application-value-lookup';

export class ExecutableRule implements Rule {

  public Filter: string;

  public TargetFields: Array<IIdentifierContainer>;

  public TargetSubFields?: Array<IIdentifierContainer>;

  public Actions: RuleAction[];

  public MultiRow?: boolean;

  public readonly filter: OdataQueryFilter;

  protected targetFields: Array<AppField>;

  protected actions: any;

  private referencedFields = new Set<AppField>();

  constructor(app: Application, rule: Rule) {
    Object.assign(this, rule);

    this.filter = new OdataQueryFilter(rule.Filter, new ApplicationValueLookup(app));

    this.targetFields = this.TargetFields.map(target => app.getField(target.Identifier));

    this.actions = this.actionModel();

    // find fields used in filter
    this.filter.propertyExpressions.forEach((_, id) => {
      const field = app.getField(id);
      if (field) {
        this.referencedFields.add(field);
      }
    });
  }

  public updateModel(record: any, model: {}) {
    this.targetFields.forEach(field => {
      field.match(this, record, (match: boolean) => {
        if (match) {
          // matched, update model
          this.TargetFields.forEach(target => {
            if (this.TargetSubFields) {
              this.TargetSubFields.forEach(sub => {
                const subId = `${target.Identifier}_${sub.Identifier}`;
                this.register(model, subId, this, match);
              });
            } else {
              this.register(model, target.Identifier, this, match);
            }
          });
        }
      });
    });

    // todo review function.  Was:
    // const matched = matchRule(user, this.app, record, isNew, { FilterRule: this.Rule });
    // This has code in to extract grid values.  We could do that, although as ApplicationValueLookup
    // goes via the appfield it should deal with that.  Check.  As we have a list of affected fields
    // we should be able to only do it if the rule references a grid field.
    // user and new flag were used to check team and new only on form rules.  Would be good to merge
    // all that into a single Rule type - complete when new rules are ready if poss.
  }

  /** Set the state of affected fields based on a rule evaluation */
  protected register(model: {}, id: string, rule: Rule, matched: boolean = true) {
    rule.Actions.forEach(action => {
      if (matched) {
        switch (action.Type) {
          case RuleActionType.ReadOnly:
            this.addToModel(model, id, 'readonly', true);
            break;
          case RuleActionType.Hidden:
            this.addToModel(model, id, 'hidden', true);
            break;
          case RuleActionType.HideAdd:
            this.addToModel(model, id, 'hideadd', true);
            break;
          case RuleActionType.HideDelete:
            this.addToModel(model, id, 'hidedelete', true);
            break;
          case RuleActionType.Style: {
            if (action.NamedStyles?.length > 0) {
              this.addToModel(model, id, 'style', action.NamedStyles);
            } else {
              this.addToModel(model, id, 'style', [{ StyleName: action.NamedStyle }]);
            }
            break;
          }
        }
      }
    });
  }

  protected addToModel(model: {}, id: string, key: any, value: any) {
    if (!model[id]) {
      model[id] = { [key]: value };
    } else {
      model[id] = { ...model[id], [key]: value };
    }
  }

  protected addToModel2(model: {}, id: string, x: any) {
    if (!model[id]) {
      model[id] = {};
    }
    Object.assign(model[id], x);
  }

  protected actionModel() {
    const model = {};
    this.Actions.forEach(action => {
      switch (action.Type) {
        case RuleActionType.ReadOnly:
          model['readonly'] = true;
          break;
        case RuleActionType.Hidden:
          model['hidden'] = true;
          break;
        case RuleActionType.HideAdd:
          model['hideadd'] = true;
          break;
        case RuleActionType.HideDelete:
          model['hidedelete'] = true;
          break;
        case RuleActionType.Style: {
          if (action.NamedStyles?.length > 0) {
            model['style'] = action.NamedStyles;
          } else {
            model['style'] = [{ StyleName: action.NamedStyle }];
          }
        }
          break;
      }
    });

    return model;
  }
}

export class ExecutableMultiRowRule extends ExecutableRule {

  public override updateModel(record: any, model: any) {

    // todo same as base but build an array
    // then use in state lookup functions

    // Apply changes for each target field
    // Currently ctor only allows one target but we might relax that.
    // Will need to define what more than one target means.
    this.targetFields.forEach(field => {

      const rowModel = { _rows: {} };
      let anyMatched = false;
      field.match(this, record, (match: boolean, key: any) => {
        if (match) {
          anyMatched = true;
          rowModel._rows[key] = this.actions;
        }
      });

      if (anyMatched) {
        this.TargetFields.forEach(target => {
          if (this.TargetSubFields) {
            this.TargetSubFields?.forEach(sub => {
              const subId = `${target.Identifier}_${sub.Identifier}`;
              // todo will need to merge rows from multiple rules...
              this.addToModel2(model, subId, rowModel);
            });
          } else {
            this.addToModel2(model, target.Identifier, rowModel);
          }
        });
      }
    });
  }
}
