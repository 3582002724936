<section class="record-container report-container"
(click)="models.globalModel.cancelMode()"
>

  <app-folders #leftAside
    class="left-aside-area"
    [recordModel]="recordModel"
    [folderModel]="pageModel.folderModel"
    [selectedFolderIdentifier]="recordModel.formIdentifier.$|async"
    [parentAppIdentifier]="recordModel.hierarchy.parentAppIdentifier.$|async"
    [parentRecordIdentifier]="recordModel.hierarchy.parentRecordId.$|async"
    [title]="currentApp?.Title"
    [showAddNewRecord]="canCreate"
    [appIdentifier]="appIdentifier"
    [appIdentifiers]="appIdentifiers"
    [pinnedStateVal]="showAside$ | async">
  </app-folders>

  <div class="update-area"
    cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin">

    <ng-container *ngIf="recordModel.busy.busy$|async; else recordUpdate">
      <app-busy-indicator
        [busyModel]="recordModel.busy" >
      </app-busy-indicator>
    </ng-container>

    <ng-template #recordUpdate>
      <app-record-update
      [appModel]="appModel"
      [attachmentsModel]="attachmentsModel"
      [recordModel]="recordModel"
      [recordUpdateController]="recordUpdateController"
      [recordIndex]="recordModel.index.$|async"
      [cursor]="cursor"
      [cursorReportIdentifier]="cursorReportIdentifier"
      [parentRecordId]="recordModel.hierarchy.parentRecordId.$|async"
      [isOnline]="appModel.globalModel.online.$|async"
      [asChild]="asChild"
      (onFieldUpdated)="onFieldUpdateHandler($event.patch)"
      >
      </app-record-update>
    </ng-template>
  </div>

  <div class="footer-area">
    <app-shared-footer
      [appModel]="appModel"
      [attachmentsModel]="attachmentsModel"
      [commentsModel]="commentsModel"
      [showAside]="showAside$|async"
      [appIdentifier]="appIdentifier"
      [attachmentsOrigin]="articleOrigin"

      [recordModel]="recordModel"

      [isOnline]="appModel.globalModel.online.$|async"
      [record]="recordModel.record.$|async"
      [fields]="appFields"
      [showFooter]="true"
      [showValidation]="!(recordModel.formReadOnly.$|async)"
      (previewAttachment)="onPreviewAttachment($event)"
      >

      <ng-container  *ngIf="(recordModel.record.$|async)?._new !== true && !isSingletonApp">
        <app-index-paginator *ngIf="!cursorMode"
        [recordModel]="recordModel"
      >
      </app-index-paginator>

      <!-- <app-cursor-paginator *ngIf="cursorMode"
        [appIdentifiers]="appIdentifiers"
        [parentRecordId]="parentRecordIdentifier$|async"
        [cursorReportIdentifier]="cursorReportIdentifier"
        [hierarchy]="recordHierarchy|async"
        [cursor]="cursor"
      ></app-cursor-paginator> -->

      </ng-container>


    </app-shared-footer>
  </div>

</section>
