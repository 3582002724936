import { RepositoryBase } from './repositories.base';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { Team } from '../types';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TeamsRepository extends RepositoryBase {

  public getSorted() {
    const url = this.urlResolver.resolveDefaultApiUrl('Teams', 'GetSorted');
    return this.get(url);
  }

  /** Get a single team by id */
  public getTeamAsync(id: string): Promise<Team> {
    const url = this.urlResolver.resolveDefaultApiUrl('Teams', id);
    return lastValueFrom<Team>(this.get(url));
  }


  public save(teamId: string, changes: any): Promise<string> {
    const url = this.urlResolver.resolveDefaultApiUrl('Teams', teamId);
    return lastValueFrom<string>(this.patchWithType(url, 'text', JSON.stringify(changes)));
  }

  /**
   * Create a new team
   * @param team Initial team data
   * @returns created team id
   */
  public createAsync(team: Team): Promise<string> {
    const url = this.urlResolver.resolveDefaultApiUrl('Teams');
    return firstValueFrom(this.postWithType(url, 'text', team));
  }

  /** Delete a single team by id */
  public async deleteTeamAsync(id: string): Promise<HttpErrorResponse | string> {
    const url = this.urlResolver.resolveDefaultApiUrl('Teams', id);
    const result = await this.delete(url).toPromise();
    return (result instanceof HttpErrorResponse) ? result : id;
  }

  /** Delete multiple teams by id */
  public async deleteTeamsAsync(ids: Array<string>): Promise<Array<HttpErrorResponse | string>> {
    if (ids) {
      const results: Array<Promise<HttpErrorResponse | string>> = [];
      for (let i = 0, length = ids.length; i < length; ++i) {
        results.push(this.deleteTeamAsync(ids[i]));
      }
      return Promise.all(results);
    }

    return null;
  }
}
