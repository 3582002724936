import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { logError } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { ToastyService } from 'app/services/toasty.service';
import { EmbeddedVideoDialogComponent, IEmbeddedVideoConfig } from 'app/softoolsui.module/embedded-video-modal-content.component';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { _VideoDescription, _VideoUrl } from 'app/_constants';

@Component({
  selector: 'app-embedded-video-field',
  templateUrl: './embedded-video-field.component.html',
  styleUrls: ['./embedded-video-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedVideoFieldComponent extends EditableFieldBase {

  @Input() descriptionValue = '';
  @Input() urlValue = '';
  @Input() hasEdit = true;
  @Input() hasDelete = true;

  @Output() onReportEmbeddedVideoOpenClick = new EventEmitter();

  @InjectService(ToastyService)
  private readonly toasties: ToastyService;

  public title: string;

  protected override onValueChanged(value: any) {
    super.onValueChanged(value);
    this.title = value?.val;
    this.urlValue = value?.uri;
    this.descriptionValue = value?.d;
  }

  public onAddEditEmbeddedVideoClickHandler($event: MouseEvent, add: boolean = true): void {
    $event.stopPropagation(); // Prevent UI_RESET
    $event.preventDefault();

    this.addEditVideo(add)?.catch(err => logError(err, 'addEditVideo'));
  }

  public onDeleteEmbeddedVideoClickHandler(): void {
    this.deleteVideo()?.catch(err => logError(err, 'deleteVideo'));

  }

  public onReportClickHandler($event: MouseEvent) {
    $event.stopPropagation(); // Prevent ReportRow Click
    $event.preventDefault();
    this.onReportEmbeddedVideoOpenClick.emit({ fieldIdentifier: this.identifier });
  }

  private async addEditVideo(add: boolean) {

    const embeddedVideoConfig: IEmbeddedVideoConfig = {
      title: this.title,
      description: this.descriptionValue,
      url: this.urlValue,
      add: add
    };

    const result = await this.globalModel.dialogAsync(EmbeddedVideoDialogComponent, embeddedVideoConfig);
    if (result) {
      if (!embeddedVideoConfig.description || !embeddedVideoConfig.title || !embeddedVideoConfig.url) {
        this.toasties.validationMessage();
      } else {
        const title = embeddedVideoConfig.title;
        const description = embeddedVideoConfig.description;
        const url = embeddedVideoConfig.url;

        const patch = this.fieldModel.createPatch(this.record, title, this.listRow)
          .addChange(this.fieldModel.Identifier + _VideoDescription, description)
          .addChange(this.fieldModel.Identifier + _VideoUrl, url);

        await this.dispatchPatchAsync(patch);
      }
    }
  }

  private async deleteVideo() {

    const patch = this.fieldModel.createPatch(this.record, '', this.listRow)
      .addChange(this.fieldModel.Identifier + _VideoDescription, '')
      .addChange(this.fieldModel.Identifier + _VideoUrl, '');

    await this.dispatchPatchAsync(patch);
  }
}
