import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ImageListsService } from 'app/services/image-lists.service';
import { ImageList, Enums, logError, logMessage } from '@softools/softools-core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { ImageListAssetService } from 'app/services/images/image-list-assets.service';
import { SafeUrl } from '@angular/platform-browser';
import { InputChangedTrackService } from 'app/services/input-changed-track.service';

@Component({
  selector: 'sof-image-list-field',
  templateUrl: './image-list-field.component.html',
  styleUrls: ['./image-list-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageListFieldComponent extends EditableFieldBase<string> implements OnInit, OnChanges {
  @Input() public imageSize = 1;

  /** If true the unset value can be chosen while editing */
  @Input() public unsetSelectable = false;

  private _imageList: ImageList;
  private _optionsSize: number;

  @Input() public selectedIndex: number;
  public imageSizeClass: string;

  @ViewChild('container', { static: false })
  private container: ElementRef<HTMLDivElement>;

  constructor(
    private _imageListsService: ImageListsService,
    private imageListAssetService: ImageListAssetService,
    private inputChangedTrackService: InputChangedTrackService
  ) {
    super();
  }

  public override ngOnInit(): void {
    try {
      super.ngOnInit();

      const listId = this.fieldModel['ImageListIdentifier'];
      this._imageList = this._imageListsService.get(listId);

      if (!this._imageList) {
        logMessage(`Missing image list ${this.application?.Identifier} ${this.fieldModel?.Identifier} ${listId}`);
      }

      this.imageSize = this.forReport
        ? Enums.ImageListSize.Small
        : this.fieldModel.ImageListSize === undefined
          ? Enums.ImageListSize.Medium
          : this.fieldModel.ImageListSize;

      this._optionsSize = this._imageList?.ImageListOptions ? this._imageList.ImageListOptions.length : 0;

      this.imageSizeClass = this.imageSize
        ? Enums.ImageListSize[this.imageSize].toString().toLowerCase()
        : Enums.ImageListSize[0].toString().toLowerCase();

      this._setSelectedIndex();
    } catch (error) {
      logError(error, '');
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    try {
      super.ngOnChanges(changes);

      if (changes['record'] || changes['value']) {
        this._setSelectedIndex();
      }
    } catch (error) {
      logError(error, '');
    }
  }

  public override async activate() {
    this.container?.nativeElement?.focus();
  }

  public override onKeyPress($event: KeyboardEvent) {

    if (this.canEdit && $event.code === 'Space') {
      this.selectNextImage().catch(error => logError(error, 'Failed to select next image'));   // completes async
      return true;
    }

    return super.onKeyPress($event);
  }

  public nextImage() {
    return this.selectImage(this.selectedIndex + 1);
  }

  /** Select an image by index, wrapping if outside the image list range */
  public selectImage(index: number) {
    if (index >= this._optionsSize) {
      if (this.unsetSelectable) {
        this.selectedIndex = -1;
        return '';
      } else {
        this.selectedIndex = 0;
      }
    } else {
      this.selectedIndex = index;
    }

    // this.selectedIndex = index < this._optionsSize ? index : 0;
    return this._imageList?.ImageListOptions[this.selectedIndex].Value;
  }

  public imageSource(): SafeUrl {
    const option = this?._imageList?.ImageListOptions.find((opt) => opt.Value === this.fieldValue);
    if (option) {
      const url = this.imageListAssetService.getImageUrl(option.ImageListAssetUri);
      return url;
    } else {
      return '/assets/no-image-300.png';
    }
  }

  // -> base if we really like them
  public override attrTitle() {
    const option = this?._imageList?.ImageListOptions.find((opt) => opt.Value === this.fieldValue);
    return option?.Title || $localize`No value`;
  }

  public onClickHandler($event: Event): void {

    this.componentClicked$.next(this);

    if (!this.getIsDisabled() && this.editMode) {
      $event.stopPropagation();
      this.inputChangedTrackService.triggerBlur();

      // Timeout is needed so that blurs get handled first on the inputs.
      setTimeout(async () => {
        await this.selectNextImage();
      }, 200);
    }
  }

  public onClickInline(e: Event) {
    if (this.canEdit) {
      this.onClickHandler(e);
    }
  }

  public get canEdit(): boolean {
    return this.forceEditable || !this.isDisabled();
  }

  private _setSelectedIndex(): void {
    if (this._imageList) {
      const value = this.fieldValue;
      if (value?.length > 0) {
        this.selectedIndex = this._imageList.ImageListOptions.findIndex((element) => element.Value === value);
        this.selectedIndex = this.selectedIndex === -1 ? 0 : this.selectedIndex; // Default to first if not found
      } else {
        this.selectedIndex = -1;
        this.fieldValue = '';
      }
    }
  }

  private async selectNextImage() {
    const newValue = this.nextImage();

    // update index - otherwise we won't see change in value mode
    this.fieldValue = newValue;
    this._setSelectedIndex();

    await this.updateValueAsync(newValue);
    this.refresh();

    this.onChanged.emit({ payload: newValue, fieldIsValid: true });
  }
}
