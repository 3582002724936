import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { ElementStyles } from '@softools/softools-core';

@Component({
  selector: 'app-input-icon',
  templateUrl: './input-icon.component.html',
  styleUrls: ['./input-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputIconComponent implements OnInit {

  @Input() icon: IconName;

  @Input() show = true;

  @Input() tooltip = '';

  /** URL to use when icon clicked */
  @Input() url: string = null;

  @Input() clickable = false;

  @Input() valid = true;

  @Input() public elementStyles: ElementStyles;

  @Output() public iconClicked = new EventEmitter<MouseEvent>();

  public iconStyle: {};

  public ngOnInit(): void {
    this.iconStyle = this.elementStyles?.['icon']?.css ?? {};
  }

  public onIconClicked(event$: MouseEvent) {
    if (this.clickable) {
      this.iconClicked.emit(event$);
    }
  }

  public get colorClass() {
    if (this.icon === 'exclamation-triangle') {
      return 'text-danger';
    }

    return 'st-text-theme-primary';
  }
}
