<div class="sof-modal-container">
<div class="cardv2-popup sof-small-modal">
  <div class="cardv2-header">
    <div *ngIf="add" i18n>Add Embedded Video</div>
    <div *ngIf="!add" i18n>Edit Embedded Video</div>
  </div>

  <mat-dialog-content>
    <form #videoForm="ngForm" novalidate class="card-body">
      <mat-form-field class="w-100">
        <input matInput i18n-placeholder placeholder="Title" name="filename" [(ngModel)]="videoData.title" />
      </mat-form-field>

      <mat-form-field class="w-100">
        <input matInput i18n-placeholder placeholder="Description" name="description" [(ngModel)]="videoData.description" />
      </mat-form-field>

      <mat-form-field class="w-100">
        <input matInput i18n-placeholder placeholder="Url" name="url" [(ngModel)]="videoData.url" #url="ngModel" pattern="((http|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?" />
        <mat-error *ngIf="url.invalid" i18n>Incorrect Url</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <div class="cardv2-footer">
    <button color="primary" type="button" class="primary" [disabled]="url.invalid" [mat-dialog-close]="returnValue" i18n>Confirm</button>
    <button type="button" class="close" [mat-dialog-close]="null" i18n>Close</button>
  </div>
</div>
</div>
