<div #display class="multi-line-text-container"
  [class.display]=" !useReadonlyStyle()"
  [class.readonly]=" useReadonlyStyle()"
  [class.report-control]="padding && forReport"
  [class.form-control]="padding && !forReport"
  [class.form-control-o]="padding && !forReport"
  [class.text-container]="padding"
  [class.text-truncate]="forReport"
  [class.no-readonly-underscore]="hideUnderline"
  [class.limit-rows]="fieldModel.Rows"
  [style.line-clamp]="fieldModel.Rows"
  [style.-webkit-line-clamp]="fieldModel.Rows"
  tabindex="-1"
  [style.text-align]="alignment"
  [style.min-width.rem]="minWidthRem"
  [ngStyle]="textStyle"
  [class.report-text-display]="forReport"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
  (click)="onClick($event)"
  [attr.data-identifier]="fieldModel?.Identifier"
></div>
