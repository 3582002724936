import App from './import-summaries.config.json';

import { Injectable } from '@angular/core';
import { Record, ImportSummary, ImportSummariesRepository, RecordId, logError, ImportSummaryRowErrors } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '../embedded-application';
import { EpochConverter } from 'app/types/epoch-converter';
import { RecordPatch } from 'app/workspace.module/types';

import { AppCapabilities, ToolbarContext } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { PermissionsService } from 'app/services/permissions.service';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { AppZone } from 'app/types/enums';
import { RecordModel, ReportModel } from 'app/mvc';
import { MessageType } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { ToastyService } from 'app/services/toasty.service';

@Injectable({ providedIn: 'root' })
export class ImportSummariesApplication extends OnlineEmbeddedApp<ImportSummary> {

  constructor(
    private repository: ImportSummariesRepository,
    private permissionsService: PermissionsService,
    private toasties: ToastyService
  ) {
    super(App.App);
  }

  public toRecord(summary: ImportSummary): Record {
    return summary && {
      _id: summary.Id,
      Id: summary.Id,
      AppIdentifier: this.Identifier,
      App: summary.AppIdentifier,
      FileName: summary.FileName,
      Started: EpochConverter.toEpoch(summary.Started),
      Finished: EpochConverter.toEpoch(summary.Finished),
      Added: summary.Added,
      Error: summary.Error,
      Errors: summary.Errors,
      TotalRows: summary.TotalRows,
      Updated: summary.Updated,
      Uri: summary.Uri,
      Username: summary.Username,
      Status: summary.Status,
      ErrorList: this.flaternErrorList(summary.ErrorList),
      Hierarchy: '',
      EditableAccessForUser: false,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',
      IsArchived: false,
    } as Record;
  }

  public convert(record: Record): ImportSummary {
    const values = record as any;
    const summary: ImportSummary = {
      Id: record._id,
      AppIdentifier: values.App,
      FileName: values.FileName,
      Started: EpochConverter.toDateString(values.Started),
      Finished: EpochConverter.toDateString(values.Finished),
      Added: values.Added,
      Error: values.Error,
      Errors: values.Errors,
      TotalRows: values.TotalRows,
      Updated: values.Updated,
      Uri: values.Uri,
      Username: values.Username,
      Status: values.Status,
      ErrorList: values.ErrorList,
    };

    return summary;
  }

  public override async totalCount(): Promise<number> {
    return this.repository.getCountAsync();
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async getRecordByIdAsync(id: RecordId): Promise<Record> {
    const summary = await this.repository.getImportSummaryAsync(id);

    const errors = await this.repository.getErrorsAsync(id);
    errors.forEach((err, i) => err.Key = i.toString());
    summary.ErrorList = errors;
    return summary && this.toRecord(summary);
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const summaries = await this.repository.getImportSummariesAsync(selection.count, selection.start, queryParams);
    return summaries.map(summary => this.toRecord(summary));
  }

  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const summaries = await this.repository.getImportSummariesAsync(1, +cursor - 1, queryParams);

    // Set error list
    if (summaries.length) {
      const errors = await this.repository.getErrorsAsync(summaries[0].Id);
      summaries[0].ErrorList = errors;
    }

    return summaries.map(summary => this.toRecord(summary)).pop();
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: false,
      canEdit: true,
      canView: true,
      canSort: true,
      canGroup: false,  // server API currently doesn't support grouping.
    };
  }

  public override get permissions() {
    return this.permissionsService.importSummaryPermissions;
  }


  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    switch (context.zone) {
      case AppZone.recordupdate:
        actions.push(new ToolbarAction('CancelImport', 'trash', async () => {
          await this.cancelImport(context.record._id, recordModel);
        }));
        break;
      default:
        break;
    }

    // Refresh on all zones
    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await recordModel.reload();
    }));
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new ToolbarAction('Refresh', 'sync', () => {
      reportModel.reload();
    }));
  }

  private async cancelImport(recordId: string, recordModel: RecordModel) {
    try {
      const globalModel = recordModel.globalModel;
      const accepted = await globalModel.showMessageDialogAsync({ Type: MessageType.ConfirmCancelImport });

      if (accepted) {
        await this.repository.cancelImportAsync(recordId);
        await recordModel.loadRecord(recordId);
        this.toasties.info('Import has been marked for cancellation', 'Cancelling Import');
      }
    } catch (error) {
      logError(error, 'cancelImport, Failed to show delete message confirm');
      this.toasties.error(error.Message);
    }
  }

  private flaternErrorList(errors?: Array<ImportSummaryRowErrors>) {
    errors?.forEach(errs => {
      if (Array.isArray(errs.Errors)) {
        errs.Errors = errs.Errors.map(e => $localize`Message: ${e.Message}, Field Identifier: ${e.FieldIdentifier}, FieldLabel: ${e.FieldLabel}`).join(' | ');
      }
    });

    return errors;
  }

}
