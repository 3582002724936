import { Component, OnInit, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { UsersService } from 'app/services/users.service';
import { EditableFieldBase, IFieldAppearance } from 'app/softoolsui.module/fields';
import { MappedPendingUser, MappedUser, logError, stringCompare, isDefined } from '@softools/softools-core';
import { BehaviorSubject } from 'rxjs';
import { Md5 } from 'ts-md5';
import { PersonAppField } from 'app/types/fields/person-app-field';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'sof-person-field',
  templateUrl: './person-field.component.html',
  styleUrls: ['./person-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonFieldComponent extends EditableFieldBase<string, PersonAppField> implements OnInit, OnDestroy {

  /** Used to stop changes resetting the element back to the record value */
  private hasFocus: boolean = false;

  private mappedMerged: Array<MappedUser | MappedPendingUser>;

  /** Currently selected user, on null if typing in the field */
  public displayUser: MappedUser | MappedPendingUser;

  /** Currently displayed text */
  public text$ = new BehaviorSubject('');

  public filtered$ = new BehaviorSubject<Array<MappedUser | MappedPendingUser>>([]);

  /** True if the field value is a valid id that represents no user */
  public missingUser$ = new BehaviorSubject(false);

  /** Teammates filter for person by team subtype */
  public searchOnlyMyTeams$ = new BehaviorSubject(true);

  /** Enable teammate filtering */
  public byTeam = false;

  // @ViewChild('input', { static: false }) public inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('input', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  public gravatarUrl$ = new BehaviorSubject<string>(null);

  private fieldAppearance: IFieldAppearance;

  constructor(protected usersService: UsersService) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.fieldAppearance = this.getAppearance();

    const mappedUsers = this.usersService.getAllMapped().filter(user => !user.user?.IsAccountClosed);
    const mappedPendingUsers = this.usersService.getPendingMapped();
    this.mappedMerged = [...mappedUsers, ...mappedPendingUsers].sort((a, b) => stringCompare(a.Text, b.Text));

    this.setFromValue();
  }

  public byTeamChanged($event: MatCheckboxChange) {
    this.searchOnlyMyTeams$.next($event.checked);
  }

  public filter() {
    this.filtered$.next(this.filteredUsers);
  }

  protected override onValueChanged(value: string) {
    super.onValueChanged(value);
    this.checkValid();
    this.setFromValue();
  }

  public override async activate() {
    this.input?.nativeElement?.focus();
  }

  public changed($event: Event) {
    this.displayUser = null;  // now in edit mode
    this.updateText($event.target['value'], true);
    this.filter();
  }

  protected userList(): Array<MappedUser | MappedPendingUser> {
    return this.mappedMerged;
  }

  protected setFromValue() {
    let missing = false;
    const value = this.fieldModel.simpleValue(this.value);
    if (isDefined(value) && value !== '') {
      const user = this.usersService.getMappedById(value);
      if (user) {
        this.displayUser = user;
        this.updateText(user.toString() ?? '');
      } else {
        const valid = this.fieldModel.isValidIdentifier(value);
        this.displayUser = null;
        if (valid) {
          this.updateText(value);
          missing = true;
        } else {
          this.updateText('');
        }
      }
    } else {
      this.displayUser = null;
      this.updateText('');
    }

    if (!missing) {
      this.filter();
    }

    this.setGravatar(this.displayUser);
    this.missingUser$.next(missing);
  }

  private updateText(text: string, force: boolean = false) {
    if (!this.hasFocus || force) {
      this.text$.next(text);
    }
  }

  public get filteredUsers(): Array<MappedUser | MappedPendingUser> {

    if (this.displayUser) {
      // A user is selected, so return that user.  This will give a single entry popup
      // when the field is focussed - change to [] if we don't want it to appear.
      return [this.displayUser];
    } else {
      const users = this.userList();
      if (users) {
        const text = this.text$.value?.toLowerCase();
        const filtered = users.filter(user =>
          user.Text.toLowerCase().indexOf(text) >= 0 ||
          user.Email.toLowerCase().indexOf(text) >= 0);
        if (filtered.length > 0) {
          return filtered;
        }
      }
    }

    // users not setup or no match
    return [];
  }

  public async onPersonSelectClickHandler($event: MatAutocompleteSelectedEvent): Promise<void> {
    try {
      const user: MappedUser | MappedPendingUser = $event.option.value;
      this.displayUser = user;
      this.updateText(user.toString());
      await this.updateValueAsync(user.Value);
    } catch (error) {
      logError(error, 'personSelect');
    }
  }

  private async dispatchUser(user: MappedUser | MappedPendingUser): Promise<void> {
    if (!user) {
      await this.updateValueAsync(null);
    } else {
      await this.updateValueAsync(user.Value);
      this.displayUser = user;
    }
  }

  public override onKeyUp($event: KeyboardEvent) {

    if ($event.code === 'Backspace' || $event.code === 'Delete') {
      const value = this.input?.nativeElement?.value;
      if (!value?.length || $event.shiftKey) {
        this.clearUser();
      }
    } else if ($event.code === 'Enter') {
      // Pick first user on enter
      const choices = this.filteredUsers;
      const user = choices?.length > 0 && choices[0];
      if (user) {
        this.displayUser = user;
        this.dispatchUser(user).catch(error => logError(error, 'person enter'));
        this.closePanel();
      }
    }
  }

  protected clearUser() {
    this.displayUser = null;
    this.updateText('', true);
    this.dispatchUser(null).catch(error => logError(error, 'checkCleared'));
  }

  public getUsername(user: MappedUser | MappedPendingUser) {
    return user?.toString() || '';
  }

  public get name() {
    return (this.displayUser.Text?.trim() || this.displayUser.Email?.trim()) ?? '';
  }

  public get showTextWithIcon() {
    return this.fieldAppearance?.text;
  }

  filteredUsersTrackByFn(_, item: MappedUser | MappedPendingUser) {
    return item.Value ?? item.Email;
  }

  public closePanel() {
    this.hasFocus = false;
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 500);
  }

  private setGravatar(user: MappedUser | MappedPendingUser): void {
    if (this.fieldAppearance?.icon) {
      if (user?.Email) {
        const key = Md5.hashStr(user.Email.trim().toLowerCase());
        this.gravatarUrl$.next(`https://www.gravatar.com/avatar/${key}.jpg?d=wavatar`);
      } else {
        this.gravatarUrl$.next('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=blank');
      }
    } else {
      this.gravatarUrl$.next(null);
    }
  }

  protected setFocus() {
    this.hasFocus = true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filtered$?.unsubscribe();
    this.text$?.unsubscribe();
    this.gravatarUrl$?.unsubscribe();
  }
}
