import { isDefined, OdataExpressionType } from '@softools/softools-core';
import { ImageListsService } from 'app/services/image-lists.service';
import { InjectService } from 'app/services/locator.service';
import { TextualAppField } from './textual-app-field';

export class ImageListAppField extends TextualAppField {

  @InjectService(ImageListsService)
  private readonly imageListsService: ImageListsService;

  public override isSuitableValue(value: any) {
    return isDefined(value);
  }

  public override filterOperations(): Array<OdataExpressionType> {
    return [
      OdataExpressionType.Equals,
      OdataExpressionType.NotEquals,
      OdataExpressionType.OneOf,
      OdataExpressionType.NoneOf,
    ];
  }

  public override filterOperationName(op: OdataExpressionType) {
    return {
      1: $localize`Is`,
      2: $localize`Is Not`,
      17: $localize`One of`,
      18: $localize`None of`,
    }[op] ?? super.filterOperationName(op);
  }

  public override formatDisplayValue(value: any) {
    const list = this.imageListsService.get(this.ImageListIdentifier);
    return list?.ImageListOptions?.find(option => option.Value === value)?.Title ?? value;
  }
}
