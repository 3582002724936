import { combineLatest, debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty, NumberModelProperty } from '@softools/vertex';
import { logError, Record, RecordId } from '@softools/softools-core';
import { AppModel } from '../app.model';
import { FilterModel } from '../filter.model';
import { IGroupStarts } from 'app/services/indexes/app-data-index';
import { RecordsReportModel } from './records-report.model';
import { PageModel } from '../page/page.model';
import { SelectionMode } from 'app/softoolsui.module/table-report/table-report-types';
import { RecordCacheService } from 'app/services/record/record-cache.service';
import { InjectService } from 'app/services/locator.service';

export enum NavigationKeyAction {
  NextColumn,
  PreviousColumn,
  NextRow,
  PreviousRow,
  Accept
}

export interface ITableRow {
  /** record position in view */
  pos: number;

  /** Record id for this row if known */
  id?: RecordId;

  /** Record, undefined if not yet loaded */
  record?: Record;
}

// todo dedupe
interface IRange {
  first: number;
  last: number;
}

export class TableReportModel extends RecordsReportModel {

  public readonly navigationKey$ = new Subject<NavigationKeyAction>();

  /** Indicates whether we are showing groups or records  */
  public readonly showGroups = new BooleanModelProperty(this, false).withLogging('Show Groups');

  /** Group info for current group when in record mode; null for ungrouped */
  public readonly group = new ModelProperty<IGroupStarts>(this);

  public readonly groups = new ModelProperty<Array<IGroupStarts>>(this).withLogging('Group Starts');

  public readonly groupCount = new ModelProperty<number>(this).withLogging('Group Count');;

  /** True if current view has records (or we don't know yet).  Use to control no records banner */
  public readonly hasRecords = new BooleanModelProperty(this, true).withLogging('Has Records');

  public readonly rows = new ArrayModelProperty<ITableRow>(this).withLogging('Rows');

  public readonly rowRange = new ModelProperty<IRange>(this).withLogging('Row Range');

  /** Requested scroll position. Updates topIndex when we're ready to do so in a batch with row changes */
  public readonly scrollPosition = new NumberModelProperty(this, 0).withLogging('scroll pos');

  public readonly scrollOffset = new NumberModelProperty(this).withLogging('scroll offset');

  /** Logical height of current view in px */
  public readonly viewHeight = new ModelProperty(this, 0).withLogging('View Height');

  private missing$ = new Subject<Array<RecordId>>();

  private readonly overlap = 16;
  private readonly overlapPage = 4;

  @InjectService(RecordCacheService)
  private readonly recordCache: RecordCacheService;

  public constructor(public override appModel: AppModel, public override filterModel: FilterModel, public override pageModel: PageModel, container?: Model<any>) {
    super(appModel, filterModel, pageModel, container);
  }

  public override initialise() {
    super.initialise();
    this.filteredView.watchChanges(this.group.$);

    // Watch changes that affect visible record count, emit has records flag
    this.subscribe(combineLatest([
      this.totalCount.$,
      this.groupCount.$,
      this.group.$,
      this.showGroups.$]), () => {

      this.batch(() => {
          if (this.showGroups.value) {
            this.recordsCount.value = this.groupCount.value;
          } else if (this.group.value) {
            this.recordsCount.value = this.group.value.count;
          } else {
            this.recordsCount.value = this.totalCount.value;
          }

          this.hasRecords.value = this.recordsCount.value !== 0;

        });
      });


    this.subscribe(this.scrollOffset.$, (offset) => {
      if (offset !== undefined) {
        // bad encapsulation on row height - can we get rid of this?
        const top = offset / 48;
        const topRow = Math.round(top);
        this.scrollTo(topRow);
      }
    });

    // Calculate row range when view changes
    this.subscribe(combineLatest([this.scrollPosition.$, this.pageSize.$, this.recordsCount.$]),
      ([scrollPosition, count, recordsCount]) => {
        if (scrollPosition !== undefined && count !== undefined && recordsCount !== undefined) {
          const first = Math.trunc((scrollPosition - this.overlap) / this.overlapPage) * this.overlapPage;
          const last = Math.trunc((scrollPosition + count + this.overlap) / this.overlapPage) * this.overlapPage;
          this.topIndex.value = scrollPosition;
          this.rowRange.value = {
            first: Math.max(0, first), last: Math.min(last, recordsCount)
          };
        }
      });

    // When view changes, update rows property to reflect what we actually display
    const range$ = this.rowRange.$.pipe(distinctUntilChanged((r1, r2) => r1?.first == r2?.first && r1?.last === r2?.last));
    const rowDependencies$ = combineLatest([range$, this.recordsCount.$]);
    this.subscribeAsync(rowDependencies$, async ([range, recordsCount]) => {
      if (range !== undefined && recordsCount !== undefined) {
        await this.initialiseRows(range);
      }
    });

    // Load and Update missing records in backgroun
    this.subscribe(this.missing$, (missing) => {
      this.loadMoreRecords(missing)
    });
  }

  public scrollTo(pos: number) {
    // Set requested scroll position. Real top will be set in a batch inside an async block later on
    if (pos < 0) {
      this.scrollPosition.value = 0;
    } else {
      this.scrollPosition.value = pos;
    }
  }

  public override onFieldKeyDown($event: KeyboardEvent) {
    switch ($event.code) {
      case 'Tab':
        this.navigationKey$.next($event.shiftKey ? NavigationKeyAction.PreviousColumn : NavigationKeyAction.NextColumn);
        return false;
      case 'ArrowDown':
        this.navigationKey$.next(NavigationKeyAction.NextRow);
        return false;
      case 'ArrowUp':
        this.navigationKey$.next(NavigationKeyAction.PreviousRow);
        return false;
      case 'Enter':
        if (this.selectionMode.value === SelectionMode.Row) {
          this.navigationKey$.next(NavigationKeyAction.Accept);
          return false;
        } else {
          return super.onFieldKeyDown($event);
        }
      default:
        return super.onFieldKeyDown($event);
    }
  }

  public async getGroupStartRangeAsync(top: number, count: number) {
    const groups = (await this.accessor?.getGroupStartsAsync(top, count));
    if (groups == null) {
      return null;
    }

    this.batch(() => {
      this.groups.value = groups.starts;
      this.groupCount.value = groups.count;
    });
    return groups;
  }

  protected override async loadIndex(): Promise<void> {
    // Filter changed, switch mode to reflect whether its grouped
    const filterHasGroup = !!this.filterModel.groupBy.value;

    this.batch(() => {
      this.showGroups.value = filterHasGroup;
      if (!filterHasGroup) {
        this.group.value = null;
      }
    });

    return await super.loadIndex();
  }

  protected override async filterChanged() {
    await super.filterChanged();
    this.scrollOffset.value = 0;
    await this.loadIndex();
    await this.loadData();

    // we should do this in loadData but don't want to do more than needed...
    this.rows.value = [];
    if (this.rowRange.value) {
      this.initialiseRows(this.rowRange.value);
    }
  }

  protected override async recordsChanged(records: Record[]): Promise<void> {
    const view = this.rows.value;
    if (view) {
      let anyChanged = false;

      records.forEach((updated, i) => {
        if (updated) {
          const index = view.findIndex((r) => r.id === updated._id);
          if (index >= 0) {
            view[index].record = updated;
            anyChanged = true;
          }
        }
      });

      if (anyChanged) {
        this.rows.changed();
      }
    }
  }

  public override recordModified(record: Record): void {
    this.recordsChanged([record]);
  }

  protected override async activated() {
    const groupFieldIdentifier = this.filterModel.groupBy.value;
    this.showGroups.value = !!groupFieldIdentifier;
    super.activated().catch(error => logError(error, 'Failed to activate'));
  }

  public recordCount() {

    if (this.showGroups.value) {
      return this.groupCount.value;
    } else if (this.group.value) {
      return this.group.value.count;
    } else {
      return this.totalCount.value;
    }
  }

  public override async getRecords(first: number, count: number) {
    // nop - we just use rows
  }

  public async loadRecords(first: number, count: number): Promise<Array<Record>> {
    // Capture accessor in case model changes
    const accessor = this.accessor;
    if (accessor) {
      const records = await this.accessor?.getRecords(first, count, this.group.value);
      if (!accessor.closed) {
        return this.processRecords(first, records);
      }
    }

    return null;
  }


  public selectGroup(group: IGroupStarts) {
    this.batch(() => {
      this.group.value = group;
      this.showGroups.value = !group;
      this.topIndex.value = 0;
    });
  }

  protected override observeProperties() {
    super.observeProperties();

    // todo should we need this?  reportModel should fire but we need to cope with model changing
    this.subscribe(this.appModel.globalModel.archived.$, () => {
      this.loadData().catch(error => logError(error, 'Failed to load data'));
    });

    this.subscribe(this.viewChanged$.pipe(debounceTime(100)), (v) => {
      if (v) {
        this.loadData().catch(error => logError(error, 'Failed to load data'));
        this.setGroupValue();
      }
    });

    this.subscribe(this.group.$, (group) => {
      if (group !== undefined) {
        const top = this.topIndex.value;
        const count = this.pageSize.value;
        this.viewChanged$.next({ top, count });
      }
    });

    this.subscribe(this.groups.$, (g) => {
      this.mapStartIds();
    });
  }

  private mapStartIds() {
    const starts = this.groups.value;
    if (starts && starts.length > 0) {
      const groupStarts = new Set<RecordId>();
      starts.forEach(s => {
        groupStarts.add(s.id);
      });
      this.groupStartIds.value = groupStarts;
    } else {
      this.groupStartIds?.clear();
    }
  }

  private async loadData() {

    try {
      this.busy.setLoading();
      const first = this.topIndex.value;
      const count = this.pageSize.value;

      if (this.showGroups.value) {
        const groups = await this.getGroupStartRangeAsync(first, count);
        // no groups, probably invalid filter so switch out of group mode
        if (groups && groups?.count === 0) {
          this.showGroups.value = false;
        }

        if (this.showGroups.value) {
          // reload active group
          if (this.group.value) {
            this.group.value = groups.starts.find(s => s.value === this.group.value.value);
          }
        }
      }
    } finally {
      this.busy.setLoading(false);
    }
  }

  private setGroupValue() {
    if (this.filterModel.groupBy.value && this.group.value) {
      this.batch(() => {
        this.showGroups.value = false;
      });
    }
  }

  private async initialiseRows(range: IRange) {
    try {
      if (this.accessor && range) {
        const first = range.first;
        const rowCount = range.last - range.first;
        if (rowCount) {
          const offset = this.group.value?.pos ?? 0;
          const missing: Array<RecordId> = [];
          const cached = await this.accessor.getCachedRecords(first + offset, rowCount);
          const rows: Array<ITableRow> = [];
          for (let i = 0; i < rowCount; i++) {
            const pos = first + i;
            if (!cached[i]) {
              // The index didn't find an id - this is likely to get stuck at loading
              // but is needed to keep the row set intact. This should only happen if
              // there's a problem elsewhere
              rows.push({ pos });
            } else if (cached[i].record) {
              rows.push({ pos, id: cached[i].id, record: cached[i].record });
            } else {
              rows.push({ pos, id: cached[i].id });
              missing.push(cached[i].id);
            }
          }

          this.rows.value = rows;

          if (missing?.length > 0) {
            this.missing$.next(missing);
          }
        }
      }
    } catch (error) {
      logError(error, 'initialiseRows')
    }
  }

  private async loadMoreRecords(missing: Array<RecordId>) {
    try {
      const app = this.appModel.app.value;
      if (app) {
        const records = await app.getRecordsByIdAsync(missing);

        const rows = this.rows.value;
        if (rows?.length) {
          const updateRows = [...rows];
          updateRows.forEach((row) => {
            if (!row.record) {
              const loaded = records.find(r => r.id === row.id);
              if (loaded) {
                row.record = loaded.record;
                this.recordCache.put(loaded.record);
              }
            }
          });
          this.rows.value = updateRows;
        }
      }
    } catch (error) {
      logError(error, 'loadMoreRecords')
    }
  }
}
