import { logError, QueryParameters, Record } from '@softools/softools-core';
import { FolderGroup, RootFolders } from 'app/softoolsui.module/folders.component/folder';
import { debounceTime } from 'rxjs';
import { RecordModel } from '..';
import { AppContextModel } from '../app/app-context.model';
import { AppFolderController } from './app-folder.controller';

export class RecordFolderController extends AppFolderController {

  protected apps: AppContextModel;

  constructor(private recordModel: RecordModel) {
    super(recordModel.appModel, recordModel.pageModel.folderModel);
  }

  public override initialise() {
    super.initialise();

    // todo ^
    this.subscribe(this.appModel.appContext.$, async (apps: AppContextModel) => {
      try {
        this.folderModel.loading.value = true;
        await this.configure(apps);
      } catch (error) {
        logError(error, 'FolderModel currentApp.$');
      } finally {
        this.folderModel.loading.value = false;
      }
    });

    // Loading folders is expensive, debounce to give CPU time back
    // Lots of rules and fields cause high CPU
    this.subscribe(this.recordModel.record.$.pipe(debounceTime(200)), async (record: Record) => {
      if (record) {
        const root = await this.createRootFolder(record);
        this.folderModel.folderGroups.value = [...root.FolderGroups];
        this.folderModel.appHomeUrl.value = this.configureHomeUrl(this.appModel.appContext);
      }
    });

    this.subscribe(this.appModel.siteModel.onRecordUpdated.$, (record) => {
      this.initialiseCount();
    });
  }

  protected async configure(apps: AppContextModel) {
    const homeUrl = this.configureHomeUrl(apps);

    // this.getHeaderCount(this.appModel.app.value);

    this.folderModel.batch(() => {
      this.folderModel.appHomeUrl.value = homeUrl;
    });
  }

  protected async createRootFolder(record: Record) {

    const app = this.appModel.app.value;
    const appIdentifiers = this.appModel.appIdentifiers.value;

    const root = new RootFolders();
    const group: FolderGroup = this.createAppHeaderFolder(root, app);

    if (record?.Hierarchy && appIdentifiers.childAppIdentifier) {
      group.NewItemUrl = this.navigationService.getCreateChildRecordUrl(appIdentifiers, this.getStringAfter(record.Hierarchy, '|'));
    } else {
      group.NewItemUrl = this.navigationService.getCreateRecordUrl(appIdentifiers);
    }

    if (record) {
      const visibleForms = await app.getVisibleForms(record, record._new);
      const queryParameters = new QueryParameters({});    // todo get from index report
      this.addFoldersFromForms(group, appIdentifiers, record._id, queryParameters, visibleForms);

      if (!record._new && app.ChildAppsIdentifiers?.length > 0) {
        await this.addChildAppsAsync(root, appIdentifiers, app, record._id);
      }
    }

    return root;
  }

  protected override configureHomeUrl(apps: AppContextModel): string {
    const record = this.recordModel.record.value;
    if (record?.Hierarchy) {
      const [, parentRecordId] = record.Hierarchy.split('|');
      return this.navigationService.getChildAppHomeOrBaseUrl(apps.appIdentifiers.value, parentRecordId);
    } else {
      return super.configureHomeUrl(apps);
    }
  }

  protected override initialiseCount(): void {
    this.folderModel.count.value = 0;
    this.folderModel.countBusy.value = false;
  }
}

export class ChildRecordFolderController extends RecordFolderController {

  constructor(recordModel: RecordModel) {
    super(recordModel);
  }

  public override initialise(): void {

    super.initialise();

    this.initialiseCount();
  }

  // protected override initialiseCount(): void {
  //   this.subscribe(this.recordModel.hierarchy.$, async (hierarchy) => {
  //     if (hierarchy) {
  //       try {
  //         this.folderModel.countBusy.value = true;
  //         const app = this.appModel.app.value;
  //         const selection = new RecordSelection({ hierarchy });
  //         const count = await app?.getViewRecordCountAsync(selection);
  //         this.folderModel.count.value = count;
  //       } finally {
  //         this.folderModel.countBusy.value = false;
  //       }
  //     }
  //   });
  // }

  // protected override async getHeaderCount(app: Application<any>): Promise<void> {
  //   // nop - but need to refactor so we don't need this
  //   // Each folder controller should get the count when it needs to
  //   // Maybe an overridable called from initialise() that sets up up
  // }
}
