import { ReportQueryGuard } from 'app/guards';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class ChartReportQueryGuard extends ReportQueryGuard {

  /**
   *
   * @param route
   * @returns {boolean} true if the route data was changed
   */
  protected override prepareRoute(route: ActivatedRouteSnapshot): boolean {
    // Use base implementation to remove top, skip
    return super.prepareRoute(route);
  }

  public override async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.useCurrentParamsWithCleanUrl(route);
  }
}
