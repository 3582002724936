
export interface Export {
  SelectedExportType: string;
  SelectedTemplatedReportId: number;
  ReportFieldsOnly: boolean;
  Filename: string;
}

export const initialExport: Export = {
  SelectedExportType: '',
  SelectedTemplatedReportId: 0,
  ReportFieldsOnly: false,
  Filename: ''
};
