import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { Enums, Report } from '../types';
import { ReportsRepository } from '../repos/repositories.reportsrepository';
import { HighChartsRepository } from '../repos/repositories.highchartsrepository';
import { AppsService } from './services.apps';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, REPORT_CACHE_STORE } from '../indexedDb';

@Injectable({ providedIn: 'root' })
export class ReportStorageService extends StorageServiceBase<any, [string, string]> {
  constructor(
    public reportsRepository: ReportsRepository,
    public highChartsRepository: HighChartsRepository,
    public appsService: AppsService,
    dbContext: DatabaseContextService<any>) {
    super(REPORT_CACHE_STORE, dbContext);
  }

  public getChartReportConfigIfRelevant(appIdentifier: string, reportIdentifier: string, reportType: Enums.ReportTypes): Observable<any> {
    if (reportType === Enums.ReportTypes.Chart) {
      const key = this.getKey(appIdentifier, reportIdentifier);
      return of(this.getOrCreateItemAsync(key, () =>
        this.highChartsRepository.getHighChartsConfig(appIdentifier, reportIdentifier).toPromise())
      );
    }
    return of(null);
  }

  /** Get chart report config if required for the report type
   * The data is returned from local storage if available, otherwise the data is obtained from the remote service.
   */
  public async getChartReportConfigIfRelevantAsync(appIdentifier: string, reportIdentifier: string, reportType: Enums.ReportTypes): Promise<any> {
    if (reportType === Enums.ReportTypes.Chart) {
      const key = this.getKey(appIdentifier, reportIdentifier);

      return this.getOrCreateItemAsync(key, () =>
        this.highChartsRepository.getHighChartsConfig(appIdentifier, reportIdentifier).toPromise()
      );
    }
    return null;
  }

  public async updateReportAsync(appIdentifier: string, reportId: number, changes: any, retryPolicy: IRetryPolicy = null): Promise<Report> {

    const policy = retryPolicy || NoRetryPolicy.instance;
    const app = await this.appsService.getAppAsync(appIdentifier);
    const reportIndex = app.Reports.findIndex(rep => rep.Id === reportId);
    if (app && reportIndex >= 0) {
      // Update report on server
      await policy.execute(async () => await this.reportsRepository.update(appIdentifier, reportId, changes));

      // Update in storage
      const report = { ...app.Reports[reportIndex], ...changes };
      app.Reports[reportIndex] = report;
      await this.appsService.putAsync(app);

      return report;
    }

    return null;
  }

  private getKey(appIdentifier: string, reportIdentifier: string): [string, string] {
    return [appIdentifier, reportIdentifier];
  }

}
