import { v4 as uuid } from 'uuid';
import ObjectID from 'bson-objectid';

import { Injectable } from '@angular/core';
import { Enums, logError, LogicBlock, LogicBlockAction, Record, UsersRepository } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { IToastyConfig } from 'app/types/toasty-config.interface';

export interface ILogicBlockExecutionContext {

  record?: Record;

  listRow?: number;

  changes?: {};
}

@Injectable({
  providedIn: 'root'
})
export class LogicBlockExecutorService {

  constructor(
    private usersRepository: UsersRepository,
    private models: GlobalModelService,
  ) { }

  public async execute(block: LogicBlock, context: ILogicBlockExecutionContext) {
    return await this.executeActions(block.Actions, null, context);
  }

  private async executeActions(actions: Array<LogicBlockAction>, previous: any, context: ILogicBlockExecutionContext) {
    for (let index = 0; index < actions.length; index++) {
      const action = actions[index];
      previous = await this.executeAction(action, previous, context);
    }

    return previous;
  }

  private async executeAction(action: LogicBlockAction, previous: any, context: ILogicBlockExecutionContext): Promise<any> {
    if (action.GetApiKey) {
      return this.getApiKey();
    }

    if (action.RefreshApiKey) {
      return this.refreshApiKey();
    }

    if (action.SetFieldValue) {
      return await this.setFieldValue(action, previous, context);
    }

    if (action.IfTruthy) {
      return this.ifTruthy(action, previous, context);
    }

    if (action.ShowToasty) {
      return await this.toasty(action.ShowToasty.Type, action.ShowToasty.Message);
    }

    if (action.GenerateValue) {
      return await this.generateValue(action.GenerateValue.Format);
    }

    // Just return previous result.  Probably should throw if we don't find a result
    return previous;
  }

  private async getApiKey() {
    try {
      return await this.usersRepository.getApiKey();
    } catch (error) {
      logError(error, 'LogicBlockExecutorService getApiKey');
      return null;
    }
  }

  private async refreshApiKey() {
    try {
      return await this.usersRepository.refreshApiKey();
    } catch (error) {
      logError(error, 'LogicBlockExecutorService refreshApiKey');
      return null;
    }
  }

  private async setFieldValue(action: LogicBlockAction, previous: any, context: ILogicBlockExecutionContext) {
    if (context.changes) {
      context.changes[action.SetFieldValue.FieldIdentifier] = previous;
    }
    return previous;
  }

  private async ifTruthy(action: LogicBlockAction, previous: any, context: ILogicBlockExecutionContext) {
    const next = previous ? action.IfTruthy.Then : action.IfTruthy.Else;
    if (next) {
      return await this.executeActions(next, previous, context);
    }
  }

  private async toasty(type: any, message: string) {
    const globalModel = this.models.globalModel;
    const config: IToastyConfig = { message, title: '' };
    switch (type) {
      case Enums.ToastySoftoolsType.error:
        globalModel.showErrorToasty(config);
        break;
      case Enums.ToastySoftoolsType.info:
        globalModel.showInfoToasty(config);
        break;
      case Enums.ToastySoftoolsType.success:
        globalModel.showSuccessToasty(config);
        break;
      case Enums.ToastySoftoolsType.warning:
        globalModel.showWarningToasty(config);
        break;
      case Enums.ToastySoftoolsType.wait:
        globalModel.showWaitToasty(config);
        break;
    }
  }

  protected async generateValue(format: string) {
    switch (format) {
      case '{guid}':
      case '{uuid}': {
        return uuid();
      }

      case '{oid}': {
        return new ObjectID().toHexString();
      }

      case '{tok}':
      default: {
        const bytes = new Uint8Array(20);
        crypto.getRandomValues(bytes);
        return Array.from(bytes, (d) => (d % 36).toString(36)).join('');
      }
    }
  }
}
