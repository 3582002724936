import { HttpErrorResponse } from '@angular/common/http';
import { captureException } from '@sentry/angular';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface IErrorMessage {
  message: string;
  err: any;
  situation: string;
  level: LogLevel;
}

const errorSubject = new Subject<IErrorMessage>();

errorSubject.pipe(distinctUntilChanged((prev, curr) => prev.message === curr.message)).subscribe((obj) => {
    const logger = (obj.level === LogLevel.error) ? console.error :
      (obj.level === LogLevel.warning) ? console.warn : console.log;

    if (obj.err instanceof HttpErrorResponse) {
      if (obj.err.status !== 401) {
        // Even though HttpErrorResponse implements Error, it isn't accepted by captureException
        logger(`Network error ${obj.situation}: ${obj.err.status} ${obj.err.message}`, obj.err);
      }
    } else if (obj.err instanceof Error) {
      if (environment.enableTelemetry) {
        const errorCopy = new Error(`${obj.situation}: ${obj.err.message}`);
        errorCopy.name = obj.err.name;
        errorCopy.stack = obj.err.stack;
        captureException(errorCopy);
      } else {
        logger(`Error ${obj.situation}: ${obj.err.message}`, obj.err);
      }
    } else {
      // Sentry only accepts Error objects in captureException, and custom events in captureEvent
      // May be an IndexedDb error Event
      if (obj.err?.target?.readyState) {
        const request = obj.err.target as IDBRequest;
        if (request.readyState === 'done') {
          // request is complete so error property is valid
          logger(`DB Error ${obj.situation}: ${request.error.message}`, obj.err, request);
        } else {
          logger(`DB Error (pending) ${obj.situation}`, obj.err, request);
        }
      } else {
        logger(`Error ${obj.situation}`, obj.err);
      }
    }
});

export enum LogLevel {
  message,
  warning,
  error
}

/**
 * Log an error to console, getting as much context information as possible
 */
export function logError(err: any, situation: string, level: LogLevel = LogLevel.error) {
  errorSubject.next({
    message: err?.message,
    err,
    situation,
    level
  });
}

export function logMessage(message: string, level: LogLevel = LogLevel.error) {

  const logger = (level === LogLevel.error) ? console.error :
    (level === LogLevel.warning) ? console.warn : console.log;

  logger(message);
}

export function logObject(message: string, obj: any, level: LogLevel = LogLevel.error) {
  const summary: string = obj === null ? 'null' :
    obj === undefined ? 'undefined' :
      `${typeof obj}: ${Object.getOwnPropertyNames(obj).join('|').substr(40)}`;

  logMessage(`${message}: ${summary}`);
}
