import App from './users-app.config.json';
import { Injectable } from '@angular/core';
import { Record, User, UserStorageService, UsersRepository, PermissionEnums, Permission, RecordId, Enums, TeamsStorageService, QueryParameters, tryGetCurrentUser, logError, FileUploaderSoftoolsOptions, SelectListOption } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '../embedded-application';
import { ToolbarContext, GetRecordOptions, AppCapabilities, DeleteRecordOptions } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { RecordPatch } from 'app/workspace.module/types';
import { ISelectOptions } from 'app/softoolsui.module/select-options/select-options.component';
import { v4 as uuid } from 'uuid';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { AppZone } from 'app/types/enums';
import { EpochConverter } from 'app/types/epoch-converter';
import { PermissionsService } from 'app/services/permissions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TeamsService } from 'app/services/teams.service';
import { HomepagesService } from 'app/services/homepages.service';
import { ReportFilter } from 'app/filters/types';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { FieldBase, EditableFieldBase } from 'app/softoolsui.module/fields';
import { GlobalModel, RecordModel, ReportModel } from 'app/mvc';
import { SiteService } from 'app/services/site-service.service';
import { InjectService } from 'app/services/locator.service';
import { MessageType } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { DialogFileUploaderComponent } from 'app/softoolsui.module/dialog-file-uploader/dialog-file-uploader.component';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { UsersService } from 'app/services/users.service';
import { EmailActivityComponent } from 'app/email-activity/email-activity.component';

export class UsersApplicationBase extends OnlineEmbeddedApp<User> {

  protected selectListOptions: ISelectOptions;

  @InjectService(SiteService)
  protected readonly siteService: SiteService;

  @InjectService(UsersService)
  protected readonly usersService: UsersService;

  protected globalModel: GlobalModel;

  constructor(
    protected userStorageService: UserStorageService,
    protected usersRepository: UsersRepository,
    protected teamsService: TeamsService,
    protected teamsStorageService: TeamsStorageService,
    protected permissionsService: PermissionsService,
    protected homepagesService: HomepagesService,
    protected recordPersistService: RecordPersistService,
    private models: GlobalModelService,
  ) {
    super(App.App);

    this.globalModel = this.models.globalModel;
  }

  public override async initialise(): Promise<void> {

    await super.initialise();

    const site = this.siteService.Site;
    if (site?.UsernameNotRequired) {
      const field = this.getField('Username');
      if (field) {
        field.Required = false;
        delete field.MinLength;
      }

      this.Reports.forEach(report => {
        report.ListReportFields = report.ListReportFields.filter(f => f.FieldIdentifier !== 'Username');
      });

      this.Templates.forEach(template => {
        template.Layout = template.Layout.filter(l => l.FieldIdentifier !== 'Username');
      });
    }
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {

    if (recordPatch._new) {
      this.adaptPatch(null, recordPatch.changes, recordPatch.trackedChanges);
      const record = recordPatch.toRecord(this);
      const user = this.fromRecord(record);
      await this.usersRepository.create(user).toPromise();
      return this.toRecord(user);    // or just record?
    } else {

      let privateMode = false;

      const user = await this.userStorageService.getUser(recordPatch._id);
      if (user) {

        // GDPR signup and licensing need to have private flag set to work, and they can only be edited by their own user
        const currentUser = tryGetCurrentUser();
        if (user?.Id === currentUser?.Id) {
          privateMode = this.isPrivatePatch(recordPatch);
        }

        // Convert patch to reflect user
        this.adaptPatch(user, recordPatch.changes, recordPatch.trackedChanges);

        // Apply patch
        const changes = recordPatch.getDelta();
        await this.usersRepository.save(recordPatch._id, changes, privateMode);
      } else {
        return null;
      }
    }

    // Unfortunately the patch/post APIs don't return the updated user and fixing
    // that would mean unravelling dependenceies so for now query current state
    const updatedUser = await this.usersRepository.getUserAsync(recordPatch._id);
    await this.usersService.saveUser(updatedUser);
    return this.toRecord(updatedUser);
  }

  private adaptPatch(user: User, changes: any, trackedChanges: Map<string, any>): any {

    const teamIdChanges = trackedChanges.get('TeamIds');
    if (teamIdChanges && (teamIdChanges.added || teamIdChanges.removed)) {
      // value is a SelectionListChange (duck typed as may be pojo)
      let ids = (user && user.TeamIds) || [];
      const value = teamIdChanges;
      if (value.added) {
        value.added.forEach(addition => {
          if (ids.findIndex(item => item === addition.Value) < 0) {
            ids.push(addition.Value);
          }
        });
      }

      if (value.removed) {
        value.removed.forEach(removal => {
          ids = ids.filter(item => item !== removal.Value);
        });
      }

      trackedChanges.set('TeamIds', ids);
    }

    if (changes.LastAccess) {
      changes.LastAccess = EpochConverter.toDateString(changes.LastAccess);
    }

    // Set permissions
    // Changes are sent to the server as a complete permissions array but we have a delta
    // for each permission category.  Apply each to Permissions and remove the individual ones.
    if (trackedChanges.has('RecordPermissions') || trackedChanges.has('ReportPermissions') || trackedChanges.has('ActivityStreamPermissions') || trackedChanges.has('SettingsPermissions') || trackedChanges.has('AppStudioPermissions')) {
      changes.Permissions = (user && user.Permissions) || [];
      this.flattenPermissions(changes, trackedChanges, 'RecordPermissions');
      this.flattenPermissions(changes, trackedChanges, 'ReportPermissions');
      this.flattenPermissions(changes, trackedChanges, 'ActivityStreamPermissions');
      this.flattenPermissions(changes, trackedChanges, 'SettingsPermissions');
      this.flattenPermissions(changes, trackedChanges, 'AppStudioPermissions');
    }
  }

  /**
   * Check if the private flag needs to be set
   * @param patch RecordPatch with all changes
   */
  private isPrivatePatch(patch: RecordPatch) {
    return Object.keys(patch.changes).some(key =>
      key === 'GDPRSignedUp' ||
      key === 'GDPRSignedUpDateTime' ||
      key === 'GDPRSignedUpContent' ||
      key === 'LicensingSignedUp' ||
      key === 'LicensingSignedUpDateTime' ||
      key === 'LicensingSignedUpContent'
    );
  }

  private flattenPermissions(changes: any, trackedChanges: Map<string, any>, name: string) {
    const change = trackedChanges.get(name);
    if (change) {
      if (change.added) {
        change.added.map(p => ({ Code: p.Value })).forEach(p => {
          if (change.added.findIndex(i => i.Value === p.Value) < 0) {
            changes.Permissions.push(p);
          }
        });
      }

      if (change.removed) {
        change.removed.map(p => ({ Code: p.Value })).forEach(victim => {
          changes.Permissions = changes.Permissions.filter(perm => perm.Code !== victim.Code);
        });
      }

      trackedChanges.delete(name);
    }
  }

  public override async storeAsync(record: Record): Promise<Record> {
    const user = this.fromRecord(record);
    await this.userStorageService.replaceUser(user);
    return record;
  }

  public toRecord(user: User): Record {

    if (!user) {
      return null;
    }

    const record = {
      ...user,
      _id: user.Id,
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',
      Person: user.Id,
      TeamIds: (user.TeamIds || []).map(t => ({ Value: t })),

      // Convert permissions array to individual flag collections
      RecordPermissions: user.Permissions && user.Permissions.
        filter(p => p.Code >= PermissionEnums.Records.All && p.Code <= PermissionEnums.Records.Security).
        map(p => ({ Value: p.Code })),
      ReportPermissions: user.Permissions && user.Permissions.
        filter(p => p.Code >= PermissionEnums.Reports.All && p.Code <= PermissionEnums.Reports.SetAsDefaultFilter).
        map(p => ({ Value: p.Code })),
      ActivityStreamPermissions: user.Permissions && user.Permissions.
        filter(p => p.Code >= PermissionEnums.ActivityStream.All && p.Code <= PermissionEnums.ActivityStream.ViewHistory).
        map(p => ({ Value: p.Code })),
      SettingsPermissions: user.Permissions && user.Permissions.
        filter(p => p.Code >= PermissionEnums.Settings.All).
        map(p => ({ Value: p.Code })),
      AppStudioPermissions: user.Permissions && user.Permissions.
        filter(p => p.Code >= PermissionEnums.AppStudio.All && p.Code <= PermissionEnums.AppStudio.DashboardsDelete).
        map(p => ({ Value: p.Code })),


      IsArchived: user.IsAccountClosed,
      LastAccess: EpochConverter.toEpoch(user.LastAccess),

      FullName: `${user.FirstName} ${user.LastName}`,

      NotificationMethods: user.NotificationMethods && user.NotificationMethods.map(nm => ({ Value: nm.toString() })),

    } as any;

    delete record.Permissions;

    return record;
  }

  public override async recordsUpdatedAsync(records: Array<Record>): Promise<void> {
    // ... maybe need this but not initialised...  add mapped user guard if we need it
    // const service = LocatorService.get(LocalUsersService);
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      await this.storeAsync(record);
    }

    // update state.  A bit crude but works
    await this.refresh();
  }

  //

  public fromRecord(record: any): User {

    return record && {
      Id: record._id,
      Tenant: record.Tenant,
      Username: record.Username,
      FirstName: record.FirstName,
      LastName: record.LastName,
      Email: record.Email,
      MobilePhone: record.MobilePhone,
      TeamIds: record.TeamIds && record.TeamIds.map((v: any) => v.Value || v),
      TeamNames: record.TeamNames,
      Permissions: this.getPermissions(record),
      IsAccountClosed: record.IsAccountClosed,
      Language: record.Language,
      SiteId: record.SiteId,
      Theme: record.Theme,
      IsAccountVerified: record.IsAccountVerified,
      Organization: record.Organization,
      Department: record.Department,
      Location: record.Location,
      Notes: record.Notes,
      JobTitle: record.JobTitle,
      LastAccess: EpochConverter.toDateString(record.LastAccess) as unknown,
      UserTenant: record.UserTenant,
      DefaultHomepageIdentifier: record.DefaultHomepageIdentifier,
      hasPermission: record.hasPermission,
      GDPRSignedUp: record.GDPRSignedUp,
      GDPRSignedUpContent: record.GDPRSignedUpContent,
      GDPRSignedUpDateTime: record.GDPRSignedUpDateTime,
      LicensingSignedUp: record.LicensingSignedUp,
      LicensingSignedUpContent: record.LicensingSignedUpContent,
      LicensingSignedUpDateTime: record.LicensingSignedUpDateTime,
      LastUpdated: record.LastUpdated,

      NotificationMethods: record.NotificationMethods && record.NotificationMethods.map(nm => (+nm.Value)),
    } as User;
  }

  private getPermissions(record: any): Array<Permission> {
    const permissions: Array<Permission> = [];

    if (record && record.Permissions) {
      permissions.push(...record.Permissions);
    }

    if (record && record.RecordPermissions) {
      permissions.push(...record.RecordPermissions.map(p => ({ Code: p.Value })));
    }

    if (record && record.ReportPermissions) {
      permissions.push(...record.ReportPermissions.map(p => ({ Code: p.Value })));
    }

    if (record && record.ActivityStreamPermissions) {
      permissions.push(...record.ActivityStreamPermissions.map(p => ({ Code: p.Value })));
    }

    if (record && record.SettingsPermissions) {
      permissions.push(...record.SettingsPermissions.map(p => ({ Code: p.Value })));
    }

    if (record && record.AppStudioPermissions) {
      permissions.push(...record.AppStudioPermissions.map(p => ({ Code: p.Value })));
    }

    return permissions;
  }

  /** Called for each (currently some) field in the application to allow behaviour modification */
  public override initialiseFieldComponent(component: FieldBase) {
    const lowercaseFieldIdentifier = component.identifier.toLowerCase();
    if (lowercaseFieldIdentifier.includes('permissions') || lowercaseFieldIdentifier === 'teamids') {
      if (component instanceof EditableFieldBase) {
        // Only editable if not active user
        const user = tryGetCurrentUser();
        const enabled = (component.record?._id !== user?.Id) ||
          (lowercaseFieldIdentifier === 'teamids' && user.hasPermission([PermissionEnums.Settings.All, PermissionEnums.Settings.UsersTeamsSelf]));
        component.isEditable = enabled;
      }
    }
  }

  /**
   * Get user range from server
   * @param selection
   */
  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {

    const showInactiveFilter = new ReportFilter();
    showInactiveFilter.Filter = `[IsAccountClosed] eq ${!!selection.showArchived}`;
    showInactiveFilter.Top = selection.count;
    showInactiveFilter.Skip = selection.start;

    const queryParams = selection.queryParams({
      stripBrackets: true,
      supressArchived: true,
      includePosition: true,
      additionalFilter: showInactiveFilter
    });

    const users = await this.usersRepository.getAllUsersAsync(queryParams);
    return users.map(user => this.toRecord(user));
  }

  /**
   * Get user from server using report and cursor
   * @param selection
   */
  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {

    const showInactiveFilter = new ReportFilter();
    showInactiveFilter.Filter = `IsAccountClosed eq ${!!selection.showArchived}`;

    // Clone filter
    const filter = new ReportFilter(selection.filter).append(showInactiveFilter);
    filter.Top = 1;
    filter.Skip = +cursor - 1;

    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });

    const users = await this.usersRepository.getAllUsersAsync(queryParams);
    return users.map(user => this.toRecord(user)).pop();
  }

  public override async getRecordByIdAsync(id: RecordId, options?: GetRecordOptions): Promise<Record> {

    // Look up any pending patch if we're going to need it
    const pendingPatch = options?.applyPatch && this.recordPersistService.getPatch(id);

    // If patch is new, it's not been persisted so convert to record and return
    if (pendingPatch?._new) {
      return pendingPatch.toRecord(this);
    }

    const user = await this.usersRepository.getUserAsync(id);
    return this.toRecord(user);
  }

  public override async getRecordsAsync(selection: RecordSelection, options?: GetRecordOptions): Promise<Record[]> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const users = await this.usersRepository.getAllUsersAsync(queryParams);
    return users.map(user => this.toRecord(user));
  }

  public override initRecord(_record: any, selectListOptions: ISelectOptions) {
    // Capture config parameters
    this.selectListOptions = selectListOptions;
  }

  public override async totalCount(selection?: RecordSelection): Promise<number> {
    const isArchived = selection?.showArchived;
    const queryParams: QueryParameters = { $filter: `IsAccountClosed eq ${!!isArchived}` };
    const count = await this.usersRepository.getCount(queryParams);
    return count;

  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {

    const showInactiveFilter = new ReportFilter();
    showInactiveFilter.Filter = `[IsAccountClosed] eq ${!!selection.showArchived}`;

    const queryParams = selection.queryParams({
      stripBrackets: true,
      supressArchived: true,    // uses IsAccountClosed instead
      includePosition: false,   // get all records when counting
      supressOrdering: true,    // don't need to sort when couting
      additionalFilter: showInactiveFilter
    });

    const count = await this.usersRepository.getCount(queryParams);
    return count;
  }

  public override async getViewRecordCountAsync(selection: RecordSelection): Promise<number> {
    return this.getApiViewRecordCount(selection);
  }

  public override validateRecordId(id: RecordId): RecordId | boolean {
    if (!id) {
      id = uuid();
    }
    return id;
  }

  /** Delete a range of users by id. */
  public override async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {

    const ids: Array<RecordId> = deleteOptions.selection.SelectedRecordIds;

    let responses: Array<Object | HttpErrorResponse> = [];
    if (ids.length === 1) {
      // deactivateUserAsync doesn't return anything on success...
      const response = await this.usersRepository.deactivateUserAsync(ids[0]);
      if (response instanceof HttpErrorResponse) {
        responses = [response];
      } else {
        responses = ids;
      }
    } else if (ids.length > 1) {
      // todo bulk version
    }

    // We can have a mix of successes and failures, split responses
    const errors = responses.filter(resp => resp instanceof HttpErrorResponse) as Array<HttpErrorResponse>;
    const deleted = responses.filter(resp => !(resp instanceof HttpErrorResponse)) as Array<string>;

    if (errors.length > 0) {
      // todo message with all errors....
      const firstError = errors[0];
      if (firstError && firstError.error && firstError.error.ValidationErrors) {
        const message = firstError.error.ValidationErrors.map(e => e.Message).join('\n');
        await this.models.globalModel.showModalAsync(Enums.ModalType.info,
          $localize`Error deleting users`,
          message
        );
      }
    }

    // The ids in the response have been deleted so update storage to match...
    if (deleted.length > 0) {
      await this.userStorageService.removeUsersAsync(deleted);

      deleted.forEach(deletedId => {
        this.recordDeleted$.next({ app: this, recordIdentifier: deletedId });
      });
    }

    return deleted.length > 0;
  }

  /** Undelete (reactivate) a user or range of users by id. */
  // todo should pull up to generic app functionality
  public override async undeleteRecordsAsync(ids: RecordId | Array<RecordId>): Promise<boolean> {

    if (Array.isArray(ids)) {
      // Multiple ids, bulk restore
      await this.usersRepository.reactivateUsersAsync(ids);
      // Update is not immediate so do nothing, will needs to refresh manually
    } else {
      // Single id, bulk restore
      await this.usersRepository.reactivateUserAsync(ids);

      // Reload users as we won't have the restored ones in storage
      await this.userStorageService.syncUsersAsync();
      await this.userStorageService.syncUsersForMyTeamsAsync();
    }

    return true;
  }

  // /** Return true if app is visible to the current user */
  // public get visible() {
  //   return this.permissionsService.hasUserSettings;
  // }

  public override get permissions() {
    return this.permissionsService.userPermisions;
  }

  /**
   * Return selection list options for a field if provided by the application
   * @param fieldId Id of selection field
   * @returns selection options, or null if not provided by the app
   */
  public override selectionListOptions(fieldId: string): Array<any> {
    switch (fieldId) {
      // Get localised options
      case 'NotificationMethods': return this.selectListOptions && this.selectListOptions.notificationOptions;
      case 'Language': return this.selectListOptions && this.selectListOptions.languageOptions;
      // Get options that _should_ be localised (todo i18n)
      case 'RecordPermissions': return this._getRecordOptions();
      case 'ReportPermissions': return this._getReportsOptions();
      case 'ActivityStreamPermissions': return this._getActivityStreamOptions();
      case 'SettingsPermissions': return this._getSettingsOptions();
      case 'AppStudioPermissions': return this._getAppStudioOptions();
      // Get options that are set at runtime in ui options
      case 'DefaultHomepageIdentifier': return this.homepagesService.getAll().map(hp => ({ Text: hp.Title, Value: hp.Identifier }));
      case 'TeamIds': return this.teamsService.getAllMappedTeams();
      default: return null;
    }
  }

  private _getRecordOptions(): Array<SelectListOption> {

    const options: Array<SelectListOption> = [
      { Text: 'All Record Permissions', Value: PermissionEnums.Records.All, Implies: { All: true } },
      { Text: 'Create', Value: PermissionEnums.Records.Create, },
      { Text: 'Update', Value: PermissionEnums.Records.Update, },
      { Text: 'Delete', Value: PermissionEnums.Records.Delete, },
      { Text: 'Archive', Value: PermissionEnums.Records.Archive, },
      { Text: 'Tag', Value: PermissionEnums.Records.Tag, },
      { Text: 'Copy', Value: PermissionEnums.Records.Copy, },
      { Text: 'Template', Value: PermissionEnums.Records.Template, },
      { Text: 'Link', Value: PermissionEnums.Records.Link, },
      { Text: 'Export', Value: PermissionEnums.Records.Export, },
      { Text: 'Subscribe', Value: PermissionEnums.Records.Subscribe, },
      { Text: 'Security', Value: PermissionEnums.Records.Security, }
    ].map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));

    // Need to cast to any because SelectListOption.Value is string, should be any
    return options as Array<any>;
  }

  private _getReportsOptions(): Array<SelectListOption> {

    const options: Array<SelectListOption> = [
      { Text: 'All Report Permissions', Value: PermissionEnums.Reports.All, Implies: { All: true } },
      { Text: 'Import', Value: PermissionEnums.Reports.Import, },
      { Text: 'Export', Value: PermissionEnums.Reports.Export, },
      { Text: 'Quick Edit', Value: PermissionEnums.Reports.QuickEdit, },
      { Text: 'Archive', Value: PermissionEnums.Reports.Archive, },
      { Text: 'Show Archived', Value: PermissionEnums.Reports.ShowArchived, },
      { Text: 'Tag', Value: PermissionEnums.Reports.Tag, },
      { Text: 'Copy', Value: PermissionEnums.Reports.Copy, },
      { Text: 'Template', Value: PermissionEnums.Reports.Template, },
      { Text: 'Link', Value: PermissionEnums.Reports.Link, },
      { Text: 'Subscribe', Value: PermissionEnums.Reports.Subscribe, },
      { Text: 'Security', Value: PermissionEnums.Reports.Security, },
      { Text: 'Save Team Filters', Value: PermissionEnums.Reports.SaveTeamFilters, },
      { Text: 'Save Global Filters', Value: PermissionEnums.Reports.SaveGlobalFilters, },
      { Text: 'Save Personal Filters', Value: PermissionEnums.Reports.SavePersonalFilters, },
      { Text: 'Set Default Filter', Value: PermissionEnums.Reports.SetAsDefaultFilter, },
      { Text: 'Delete', Value: PermissionEnums.Reports.Delete, },
    ].map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));

    return options;
  }

  private _getActivityStreamOptions(): Array<SelectListOption> {

    const options: Array<SelectListOption> = [
      { Text: 'All Activity Stream Permissions', Value: PermissionEnums.ActivityStream.All, Implies: { All: true } },
      { Text: 'Attach', Value: PermissionEnums.ActivityStream.Attach, },
      { Text: 'Comment', Value: PermissionEnums.ActivityStream.Comment, },
      { Text: 'History', Value: PermissionEnums.ActivityStream.ViewHistory, },
    ].map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));

    return options;
  }

  private _getSettingsOptions(): Array<SelectListOption> {
    const options: Array<SelectListOption> = [
      { Text: 'All System App Permissions', Value: PermissionEnums.Settings.All, Implies: { All: true } },
      { Text: 'Users Teams Self', Value: PermissionEnums.Settings.UsersTeamsSelf },
      { Text: 'Users Teams Update', Value: PermissionEnums.Settings.UsersTeamsUpdate },
      { Text: 'Users Teams Create', Value: PermissionEnums.Settings.UsersTeamsCreate },
      { Text: 'Users Teams Delete', Value: PermissionEnums.Settings.UsersTeamsDelete },
      { Text: 'Lists', Value: PermissionEnums.Settings.Lists },
      // { Text: 'Lookups', Value: PermissionEnums.Settings.Lookups },
      { Text: 'Site Resources', Value: PermissionEnums.Settings.SiteResources },
      { Text: 'Subscribe', Value: PermissionEnums.Settings.Subscribe },
      { Text: 'Set Login Image', Value: PermissionEnums.Settings.SetLoginImage },
      { Text: 'Imports', Value: PermissionEnums.Settings.Imports },
      { Text: 'Teams All', Value: PermissionEnums.Settings.TeamsAll },
      { Text: 'Exports', Value: PermissionEnums.Settings.Exports },
      { Text: 'Exports All', Value: PermissionEnums.Settings.ExportsAll },
      { Text: 'Imports Cancel', Value: PermissionEnums.Settings.ImportsCancel },
      { Text: 'Imports Cancel All', Value: PermissionEnums.Settings.ImportsCancelAll },
      { Text: 'Imports All', Value: PermissionEnums.Settings.ImportsAll },
      { Text: 'Set Export Policy', Value: PermissionEnums.Settings.SetExportPolicy },
      { Text: 'Set Show Registration', Value: PermissionEnums.Settings.SetShowRegistration },
      { Text: 'Set Api Key Secret', Value: PermissionEnums.Settings.SetApiKeySecret }
    ].map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));

    return options;
  }

  private _getAppStudioOptions(): Array<SelectListOption> {

    const options = [
      { Text: 'All App Studio Permissions', Value: PermissionEnums.AppStudio.All, Implies: { All: true } },
      { Text: 'Add App', Value: PermissionEnums.AppStudio.AddApp },
      { Text: 'Homepages', Value: PermissionEnums.AppStudio.Homepages },
      { Text: 'Homepages Add', Value: PermissionEnums.AppStudio.HomepagesAdd },
      { Text: 'Homepages Delete', Value: PermissionEnums.AppStudio.HomepagesDelete },
      // { Text: 'Homepages Enhanced', Value: PermissionEnums.AppStudio.HomepagesEnhanced },
      { Text: 'Configure Basic', Value: PermissionEnums.AppStudio.ConfigureBasic },
      { Text: 'Configure Delete', Value: PermissionEnums.AppStudio.ConfigureDelete },
      { Text: 'Configure Advanced', Value: PermissionEnums.AppStudio.ConfigureAdvanced },
      { Text: 'Fields', Value: PermissionEnums.AppStudio.Fields },
      { Text: 'Fields Add', Value: PermissionEnums.AppStudio.FieldsAdd },
      { Text: 'Fields Delete', Value: PermissionEnums.AppStudio.FieldsDelete },
      { Text: 'Templates', Value: PermissionEnums.AppStudio.Templates },
      { Text: 'Templates Enhanced', Value: PermissionEnums.AppStudio.TemplatesEnhanced },
      { Text: 'Templates Add', Value: PermissionEnums.AppStudio.TemplatesAdd },
      { Text: 'Templates Delete', Value: PermissionEnums.AppStudio.TemplatesDelete },
      { Text: 'Forms', Value: PermissionEnums.AppStudio.Forms },
      { Text: 'Forms Add', Value: PermissionEnums.AppStudio.FormsAdd },
      { Text: 'Forms Delete', Value: PermissionEnums.AppStudio.FormsDelete },
      { Text: 'Reports', Value: PermissionEnums.AppStudio.Reports },
      { Text: 'Reports Add', Value: PermissionEnums.AppStudio.ReportsAdd },
      { Text: 'Reports Delete', Value: PermissionEnums.AppStudio.ReportsDelete },
      { Text: 'Workflow', Value: PermissionEnums.AppStudio.Workflow },
      { Text: 'Workflow Add', Value: PermissionEnums.AppStudio.WorkflowAdd },
      { Text: 'Workflow Delete', Value: PermissionEnums.AppStudio.WorkflowDelete },
      { Text: 'Templated Reports', Value: PermissionEnums.AppStudio.TemplatedReports },
      { Text: 'Templated Reports Add', Value: PermissionEnums.AppStudio.TemplatedReportsAdd },
      { Text: 'Templated Reports Delete', Value: PermissionEnums.AppStudio.TemplatedReportsDelete },
      { Text: 'Dashboards', Value: PermissionEnums.AppStudio.Dashboards },
      { Text: 'Dashboards Add', Value: PermissionEnums.AppStudio.DashboardsAdd },
      { Text: 'Dashboards Delete', Value: PermissionEnums.AppStudio.DashboardsDelete },
    ].map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));

    return options;
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: false,
      canEdit: true,
      canView: true,
      canSort: true,
      canGroup: false,  // server API currently doesn't support grouping.
    };
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    switch (context.zone) {
      case AppZone.listreport:
      case AppZone.childlistreport:
        actions.push(new ToolbarAction('Export', 'file-download', () => {
          this.export(reportModel).catch(error => logError(error, 'Failed to export user'));
        }));

        actions.push(new ToolbarAction('Import', 'file-upload', async () => {
          const fileUploaderOptions: FileUploaderSoftoolsOptions = {
            appIdentifier: this.Identifier,
            importer: (data) => this.usersRepository.importUsersAsync(data)
          };

          await this.globalModel.dialogAsync(DialogFileUploaderComponent, fileUploaderOptions);
        }));

        if (context.isArchived) {
          actions.push(new ToolbarAction('ShowActive', 'user', () => {
            this.globalModel.archived.value = false;
          }));
        } else {
          actions.push(new ToolbarAction('ShowDeactivated', 'user-slash', () => {
            this.globalModel.archived.value = true;
          }));
        }
        break;

      case AppZone.recordupdate:
      case AppZone.recordadd:
      default:
        break;
    }

    // Pretty hacky, but I assume this will be handled down the line with proper mvc
    if (context.isArchived) {
      actions.push(new ToolbarAction('Reactivate', 'user-check', async () => {

        const selection = await reportModel.promptForSelection({ all: MessageType.ConfirmReactivateAll, some: MessageType.ConfirmReactivate });

        if (selection) {
          const ids = selection.AllRecordsSelected ? reportModel.records.value.map(r => r._id) : selection.SelectedRecordIds;
          await this.usersRepository.reactivateUsersAsync(ids);

          // switch back to normal view.
          this.globalModel.archived.value = false;
        }
      }));
    } else {
      actions.push(new ToolbarAction('Deactivate', 'user-times', async () => {

        // Setting disallowAll to true, As we don't want to allow all selected as it's too likely to be a mistake and leave all users inaccessible
        const selection = await reportModel.promptForSelection({all: MessageType.DisallowAll, some: MessageType.ConfirmDeactivate, disallowAll: true });

        if (selection) {
         await this.usersRepository.deactivateUsersAsync(selection.SelectedRecordIds);
        }
        }
      ));
    }
  }

  public async export(reportModel: ReportModel) {

    const allSelected = reportModel.selectionModel.all.value;
    const selectedRecordIds = reportModel.selectionModel.selectedIds;

    if (!allSelected && selectedRecordIds.length === 0) {
      await this.models.globalModel.showModalAsync(Enums.ModalType.info,
        $localize`No Records Selected`,
        $localize`Please select at least one user to export`
      );
    } else {
      const archived = reportModel.appModel.globalModel.archived.value;
      const filter = reportModel.filter();
      const queryParameters = filter?.getQueryParameters({
        noBracketFields: true,
        noOrder: true,
        noPosition: true,
        showArchived: archived,
        archiveProperty: 'IsAccountClosed'
      });

      if (allSelected) {
        // We should be able to pass [] for user ids as the filter is correct but the
        // server doesn't seem to be applying IsAccountClosed so retain the user list
        // We should resolve as this is one thing forcing us to keep all users in storage
        const users = await this.userStorageService.getUsers();
        const ids = users.filter(u => (u.IsAccountClosed === archived)).map(u => u.Id);
        await this.usersRepository.exportUsersAsync(ids, queryParameters);
      } else {
        await this.usersRepository.exportUsersAsync(selectedRecordIds, queryParameters);
      }
    }
  }
}

@Injectable({ providedIn: 'root' })
export class UsersApplication extends UsersApplicationBase {

  constructor(
    usersService: UserStorageService,
    usersRepository: UsersRepository,
    teamsService: TeamsService,
    teamsStorageService: TeamsStorageService,
    permissions: PermissionsService,
    homepagesService: HomepagesService,
    recordPersistService: RecordPersistService,
    globalModelService: GlobalModelService,
  ) {
    super(usersService, usersRepository, teamsService, teamsStorageService, permissions,
      homepagesService, recordPersistService, globalModelService);
  }

  public override get isOfflineApp(): boolean {
    return false;
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    const id = context.record._id;

    actions.push(new ToolbarAction('EmailActivity', 'envelope', async () => {
      await this.globalModel.dialogAsync(EmailActivityComponent, { userId: id });
    }));

    if (context.record.IsArchived) {
      actions.push(new ToolbarAction('Reactivate', 'user-check', async () => {
        const confirm = await this.globalModel.showModalAsync(Enums.ModalType.confirm,
          $localize`Are you sure you want to reactivate this user?`,
          $localize`Reactivate User`);

        if (confirm) {
          const undeleted = await this.undeleteRecordsAsync([id]);
          if (undeleted) {
            recordModel.appModel.reindexRecord(context.record._id);
            this.globalModel.header?.goBack();    // user record has gone so go up to report

            this.globalModel.showInfoToasty({
              title: $localize`User Reactivated`,
              message: $localize`User has been reactivated`
            });
          }
        }
      }));
    } else {
      actions.push(new ToolbarAction('Deactivate', 'user-times', async () => {
        const confirm = await this.globalModel.showModalAsync(Enums.ModalType.confirm,
          $localize`Are you sure you want to deactivate this user?`,
          $localize`Deactivate User`);

        if (confirm) {
          const deleted = await this.deleteRecordsAsync({
            selection: { AllRecordsSelected: false, SelectedRecordIds: [id] }
          });

          if (deleted) {
            recordModel.appModel.reindexRecord(context.record._id);
            this.globalModel.header?.goBack();    // user record has gone so go up to report
            this.globalModel.showInfoToasty({
              title: $localize`User Deactivated`,
              message: $localize`User has been deactivated`
            });
          }
        }
      }));

      // Add resend verification email item
      // The user API is always returning false here so the menu item is always available
      // See SOF-9503, SOF-11320
      const verified = context.record['IsAccountVerified']
      if (!verified) {
        actions.push(new ToolbarAction(`ResendVerificationEmail`, 'envelope', async () => {
          try {
            await this.usersRepository.resendVerificationEmail(id);
            this.globalModel.showInfoToasty({
              title: $localize`Sent`,
              message: $localize`Verification email sent`
            });
          } catch (error) {
            logError(error, 'Resend Verify');
            this.globalModel.showErrorToasty({ message: $localize`Could not send verification email` });
          }
        }));
      }
    }

    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await recordModel.reload();
    }));
  }
}
