import { Component, Input, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Enums, RecordId, logError } from '@softools/softools-core';
import { ChartReportHelperService } from 'app/workspace.module/services/services.charthelper';
import { MatrixModel, MatrixCellReference, MatrixCellModel } from './matrix-model';
import { AppService } from 'app/services/app.service';
import { ReportClickThroughParams, ChartClickthroughService } from 'app/services/chart/chart-clickthrough.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { ReportFilter } from 'app/filters/types';
import { MatrixReportModel } from 'app/mvc';

@Component({
  selector: 'app-matrixreport',
  templateUrl: './matrixreport.component.html',
  styleUrls: ['./matrixreport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChartReportHelperService]
})
export class MatrixReportComponent implements OnInit, OnChanges {

  public Enums: typeof Enums = Enums;

  @Input() isCumulative: boolean;
  @Input() public matrixReportModel: MatrixReportModel;
  @Input() model: MatrixModel;
  @Input() appIdentifier: string;
  @Input() appIdentifiers: AppIdentifiers;
  @Input() isArchived = false;
  @Input() parentRecordId: string;
  @Input() clickThroughIdentifier: string | number;
  @Input() filter: ReportFilter;

  public clickThroughReportIdentifier: string;

  constructor(
    private _chartClickthroughService: ChartClickthroughService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    try {
      // Set click through report
      // This should be moved to Chart data so we don't need matrix table data
      if (!this.clickThroughIdentifier) {
        this.clickThroughReportIdentifier = null;
      } else if (typeof this.clickThroughIdentifier === 'number') {
        this.clickThroughReportIdentifier = this.appService.application(this.appIdentifier).Reports.find(report => report.Id === this.clickThroughIdentifier).Identifier;
      } else {
        this.clickThroughReportIdentifier = this.clickThroughIdentifier;
      }
    } catch (error) {
      logError(error, '');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clickThroughIdentifier']) {
      if (!this.clickThroughIdentifier) {
        this.clickThroughReportIdentifier = null;
      } else if (typeof this.clickThroughIdentifier === 'number') {
        this.clickThroughReportIdentifier = this.appService.application(this.appIdentifier).Reports.find(report => report.Id === this.clickThroughIdentifier).Identifier;
      } else {
        this.clickThroughReportIdentifier = this.clickThroughIdentifier;
      }
    }
  }

  /** Returns true if value shown as plain label rather than using a field to format  */
  public showAsLabel(fieldType: Enums.FieldType): boolean {
    switch (fieldType) {
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Literal:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Money:
      case Enums.FieldType.Number:
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
        return false;
      default:
        return true;
    }
  }

  public async clickThrough(ref: MatrixCellReference) {

    try {
      const recordId = ref.recordId;
      const reportIdentifier = this.clickThroughReportIdentifier;

      if (reportIdentifier) {
        let params: ReportClickThroughParams;
        if (this.isCumulative) {
          params = {
            xFieldIdentifier: this.model.xAxisId,
            xFieldValue: ref.xAxisValue,
            xFieldType: this.model.xAxisField.Type,
            yFieldIdentifier: ref.yAxisValue,
            yFieldValue: ref.value,
            yFieldType: ref.yAxisValue ? this.appService.application(this.appIdentifier).getField(ref.yAxisValue).Type : null
          } as ReportClickThroughParams;
        } else {
          params = {
            xFieldIdentifier: this.model.xAxisId,
            xFieldValue: ref.xAxisValue,
            xFieldType: this.model.xAxisField.Type,
            yFieldIdentifier: this.model.yAxisId,
            yFieldValue: ref.yAxisValue,
            yFieldType: this.model.yAxisField.Type
          } as ReportClickThroughParams;
        }

        let hierarchy: string;
        if (this.parentRecordId) {
          if (this.appIdentifiers.parentAppIdentifier) {
            hierarchy = `${this.appIdentifiers.parentAppIdentifier}|${this.parentRecordId}`;
          } else {
            hierarchy = `${this.appIdentifiers.appIdentifier}|${this.parentRecordId}`;
          }
        }

        const clickThroughInfo = await this._chartClickthroughService.getReportClickThroughInfo(this.appIdentifiers, reportIdentifier, recordId, params, hierarchy);
        await this.matrixReportModel.reportClickThroughAsync(clickThroughInfo);
      }
    } catch (error) {
      logError(error, 'Error on matrix clickthrough');
    }
  }

  public async clickThroughTotal(cell: MatrixCellModel) {

    if (cell.references.length > 0) {
      return this.navigateclickThrough(cell.references[0]);
    }
  }

  private async navigateclickThrough(ref: MatrixCellReference, recordId?: RecordId) {
    try {
      const reportIdentifier = this.clickThroughReportIdentifier;
      if (reportIdentifier) {
        const params = {
          xFieldIdentifier: this.model.xAxisId,
          xFieldValue: ref.xAxisValue,
          xFieldType: this.model.xAxisField.Type,
          yFieldIdentifier: this.model.yAxisId,
          yFieldValue: ref.yAxisValue,
          yFieldType: this.model.yAxisField.Type
        } as ReportClickThroughParams;

        // @ts-ignore
        params.BaseFilter = this.filter.Filter;

        let hierarchy: string;
        if (this.parentRecordId) {
          if (this.appIdentifiers.parentAppIdentifier) {
            hierarchy = `${this.appIdentifiers.parentAppIdentifier}|${this.parentRecordId}`;
          } else {
            hierarchy = `${this.appIdentifier}|${this.parentRecordId}`;
          }
        }

        const clickThroughInfo = await this._chartClickthroughService.getReportClickThroughInfo(this.appIdentifiers, reportIdentifier, recordId, params, hierarchy);
        await this.matrixReportModel.reportClickThroughAsync(clickThroughInfo);
      }
    } catch (error) {
      logError(error, 'Error on matrix clickthrough');
    }
  }

  public async clickthroughRowTotal(row) {
    const reportIdentifier = this.clickThroughReportIdentifier;

    if (reportIdentifier && !this.isCumulative) {
      let params: ReportClickThroughParams;
      params = {
        yFieldIdentifier: this.model.yAxisField.Identifier,
        yFieldValue: row.value,
        yFieldType: row.type
      } as ReportClickThroughParams;

      // @ts-ignore
      params.BaseFilter = this.filter.Filter;

      let hierarchy;
      if (this.parentRecordId) {
        if (this.appIdentifiers.parentAppIdentifier) {
          hierarchy = `${this.appIdentifiers.parentAppIdentifier}|${this.parentRecordId}`;
        } else {
          hierarchy = `${this.appIdentifiers.appIdentifier}|${this.parentRecordId}`;
        }
      }
      const clickThroughInfo = await this._chartClickthroughService.getReportClickThroughInfo(this.appIdentifiers, reportIdentifier, null, params, hierarchy);
      await this.matrixReportModel.reportClickThroughAsync(clickThroughInfo);
    }
  }

  public async clickthroughColumnTotal(column) {
    const reportIdentifier = this.clickThroughReportIdentifier;

    if (reportIdentifier) {
      let params: ReportClickThroughParams;
      if (this.isCumulative) {
        params = {
          xFieldIdentifier: this.model.xAxisField.Identifier,
          xFieldValue: column.value,
          xFieldType: column.type
        } as ReportClickThroughParams;
      } else {
        params = {
          xFieldIdentifier: this.model.xAxisField.Identifier,
          xFieldValue: column.value,
          xFieldType: column.type
        } as ReportClickThroughParams;
      }

      // @ts-ignore
      params.BaseFilter = this.filter.Filter;

      let hierarchy;
      if (this.parentRecordId) {
        if (this.appIdentifiers.parentAppIdentifier) {
          hierarchy = `${this.appIdentifiers.parentAppIdentifier}|${this.parentRecordId}`;
        } else {
          hierarchy = `${this.appIdentifiers.appIdentifier}|${this.parentRecordId}`;
        }
      }
      const clickThroughInfo = await this._chartClickthroughService.getReportClickThroughInfo(this.appIdentifiers, reportIdentifier, null, params, hierarchy);
      await this.matrixReportModel.reportClickThroughAsync(clickThroughInfo);
    }
  }
}
