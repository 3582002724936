import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';

@Injectable({ providedIn: 'root' })
export class FileImagesRepository extends RepositoryBase {
  public async saveImageFileAsync(
    appIdentifier: string,
    recordId: string,
    imgId: string,
    filename: string,
    base64Data: string
  ): Promise<any> {
    const url = this.urlResolver.resolveAppDataImagesUrlWithData(appIdentifier, imgId, recordId);
    return this.post(url, {
      data: base64Data,
      filename,
    }).toPromise();
  }

  public async deleteImageFileAsync(appIdentifier: string, recordId: string, imageId: string): Promise<any> {
    const baseUrl = this.urlResolver.resolveAppDataImagesUrl(appIdentifier, recordId);
    const url = `${baseUrl}/${imageId}`;
    return this.del(url).toPromise();
  }
}
