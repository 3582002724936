import { Field, SavedFilter, ReportField, OdataExpressionType, OdataQueryFilter, OdataLiteralExpression, OdataBinaryExpression, OdataComparisonExpression, OdataFunctionExpression, OdataExpression, OdataAndExpression, OdataPropertyExpression, logError, IFilterTerm } from '@softools/softools-core';
import { FieldFilters } from './field-filters';
import { ReportFilter } from './report-filter';
import { FilterTermUpdates } from '../filter-simple-popup/filter-simple-popup.component';

import { AppField } from 'app/types/fields/app-field';
import { FilterTerm } from './filter-term';

/**
 *
 */
export class FilterSpecification {


  public readonly parsedFilter: OdataQueryFilter;

  public terms: Array<FieldFilters>;

  public get sortField() { return this.reportFilter.OrderBy; }
  public set sortField(sort: string) { this.reportFilter.OrderBy = sort; }

  public get sortAscending() { return !this.reportFilter.IsOrderDescending; }
  public set sortAscending(asc: boolean) { this.reportFilter.IsOrderDescending = !asc; }

  public get sortDescending() { return this.reportFilter.IsOrderDescending; }

  public get groupField() { return this.reportFilter.Group; }
  public set groupField(group: string) { this.reportFilter.Group = group; }

  public get groupAscending() { return !this.reportFilter.IsGroupDescending; }
  public set groupAscending(asc: boolean) { this.reportFilter.IsGroupDescending = !asc; }
  public get groupDescending() { return this.reportFilter.IsGroupDescending; }


  public get Description() { return this.reportFilter && this.reportFilter.Description; }

  public get Id() { return this.reportFilter && this.reportFilter.Id; }

  public get IsDefault() { return this.reportFilter && this.reportFilter.IsDefault; }

  public get AccessLevel() { return this.reportFilter && this.reportFilter.AccessLevel; }

  public constructor(
    public reportFilter: ReportFilter,
    public readonly appFields: Array<AppField>,
    public readonly reportFields?: Array<ReportField>,
  ) {
    const filter = reportFilter?.Filter || '';
    this.parsedFilter = new OdataQueryFilter(filter);
    this.buildTermLists();
  }

  private buildTermLists() {
    const simpleFilters = [];

    if (this.reportFields) {
      // Start with report fields so we have entries in the right order
      // Would be cleaner if didn't rely on these being sorted
      this.reportFields.
        filter(f => !f.Hidden).
        forEach(reportField => {
          // const appField = this.getField(reportField.FieldIdentifier);
          const appField = this.appFields.find(f => f.Identifier === reportField.FieldIdentifier);
          if (typeof (appField) !== 'undefined') {
            const comp = this.parsedFilter.comparisons.get(reportField.FieldIdentifier);
            const summary = comp ?
              new FieldFilters(reportField, appField, appField.Type, comp[0].value, comp[0].expr.type) :
              new FieldFilters(reportField, appField, appField.Type, '', null);
            simpleFilters.push(summary);
          }
        });
    }

    if (this.appFields) {
      // Enumerate and add all app fields that don't have a report field
      this.appFields.forEach(appField => {

        // locate field on report if present
        const reportField = this.reportFields && this.reportFields.find(repField => repField.FieldIdentifier === appField.Identifier);
        if (!reportField || reportField.Hidden) {
          const comp = this.parsedFilter.comparisons.get(appField.Identifier);
          const summary = comp ?
            new FieldFilters(reportField, appField, appField.Type, comp[0].value, comp[0].expr.type) :
            new FieldFilters(reportField, appField, appField.Type, '', null);

          simpleFilters.push(summary);
        }
      });
    } else {
      console.warn('buildTermLists: No fields on app when creating filter');
    }

    this.terms = simpleFilters;
  }

  /** Get field by identifier */
  public getField(identifier: string): AppField {
    return this.appFields.find(f => f.Identifier === identifier);
  }

  /** Clear the filter(s) attached to a field  */
  public clearField = (fieldId: string): ReportFilter => {
    this.terms = this.terms.filter(sf => sf.identifier !== fieldId);
    return this.updateFilter();
  }

  public deleteTerm = (term: IFilterTerm) => {
    this.parsedFilter.delete(term.id);
    this.reportFilter.Filter = this.parsedFilter.expressionText();
  }

  /** Get field filter details for a single field (by id) */
  public getFieldFilterSummary(fieldId: string): FieldFilters | null {
    return this.terms.find(term => term.identifier === fieldId);
  }

  public fieldsInCurrentFilter(): Array<Field> {
    return this.terms.filter(field => field.operator !== null).map(f => f.appField);
  }

  public updateFrom(filter: ReportFilter) {
    this.reportFilter = filter;
  }

  public get isSimple() {
    return this.parsedFilter.isSimpleFilter;
  }

  /** Update filter term by id.  If id is 0 a new term is added */
  public updateTerm(updates: FilterTermUpdates): ReportFilter {
    try {

      if (updates.isSort !== undefined) {
        this.sortField = updates.isSort ? updates.fieldId : null;
        this.sortAscending = updates.sortAscending;
      }

      if (updates.isGroup !== undefined) {
        this.groupField = updates.isGroup ? updates.fieldId : null;
        this.groupAscending = updates.groupAscending;
      }

      if (updates.term !== undefined) {
        if (!updates.term.Operator) {
          // no operator means remove the term
          this.parsedFilter.delete(updates.term.id);
        } else {
          // Convert term to odata query
          const expr = this._formatTerm(updates.term);
          const termQuery = new OdataQueryFilter(expr);

          if (termQuery.isValidQuery) {
            if (updates.term.id) {
              const existing = this.parsedFilter.nodes.get(updates.term.id);
              if (existing) {
                this.parsedFilter.replace(updates.term.id, termQuery.filter.expression);
              }
            } else {
              // New term so join up with an and clause
              this.parsedFilter.insertAtFront(termQuery.filter.expression);
            }
          }
        }
      }

      return this.updateFilter();
    } catch (error) {
      logError(error, 'updateTerm');
      return null;
    }
  }

  /**
   * Produce a flattened version of the filter.
   *
   * This assumes that the filter has a simple structure with operations seperated by AND
   * clauses.  This is a minimal function to support pre-v18 level of filters.  Ultimately
   * we can and should go further to support aribtraty odata expressions.
   */
  public flatten(): Array<IFilterTerm> {
    const flat = new Array<IFilterTerm>();
    if (this.parsedFilter.filter && this.parsedFilter.filter.expression) {
      this._flatten(flat, this.parsedFilter.filter.expression);
    }
    return flat;
  }

  /** Update a filter column */
  update(fieldId: string, expressionType: OdataExpressionType, value: any, sortOrder: string, grouped: boolean): ReportFilter {

    const existing = this.getFieldFilterSummary(fieldId);
    if (existing) {
      if (expressionType) {
        existing.operator = expressionType;
        existing.value = value;
      } else {
        existing.operator = null;
        existing.value = '';
      }

      // Set sort field if specified, or clear if this was the sort field and now set to none
      if (sortOrder === 'asc') {
        this.sortField = fieldId;
        this.sortAscending = true;
      } else if (sortOrder === 'desc') {
        this.sortField = fieldId;
        this.sortAscending = false;
      } else if (this.sortField === fieldId) {
        this.sortField = null;
      }

      // Set grouping if specified, or clear if this was the group field and now set to none
      if (grouped) {
        this.groupField = fieldId;
      } else if (this.groupField === fieldId) {
        this.groupField = null;
      }

      return this.updateFilter();
    }

    return null;
  }

  /** Recreate the filter from the simple filter data */
  public updateFilter(): ReportFilter {

    try {
      const clauses = [];

      // Enumerate filters from the flat form and build a term for each
      const flat = this.flatten();
      flat.forEach(term => {
        const expr = this._formatTerm(term);
        clauses.push(expr);
      });

      // Join the terms into and clauses (only works in the current and-only model)
      const query = clauses.join(' and ');

      // Clone filter and update with new values
      const filter = new ReportFilter(this.reportFilter);
      filter.OrderBy = this.sortField;
      filter.IsOrderDescending = this.sortDescending;
      filter.Group = this.groupField;
      filter.IsGroupDescending = this.groupDescending;
      // It's a new custom filter so clear identification
      filter.Id = '';
      filter.Description = '';

      if (query) {
        // Parse the filter to validate it
        const odataFilter = new OdataQueryFilter(query);
        if (odataFilter.isValidQuery) {
          // Success so update filter
          filter.Filter = query;
          return filter;
        }
      } else {
        // No query so no need to parse, just return with sort/group
        filter.Filter = '';
        return filter;
      }

    } catch (error) {
      logError(error, '');
    }

    return null;
  }

  private _formatTerm(term: IFilterTerm) {
    return new FilterTerm(term).format(this.appFields);
  }

  /**
   * Flatten a subtree in the parsed filter
   *
   * @param flat
   * @param expression
   */
  private _flatten(flat: Array<IFilterTerm>, expression: OdataExpression) {

    // Depth first walk into the AND expression tree; the leaves are the operations that
    // we include in the flattened list

    if (expression instanceof OdataAndExpression) {
      if (expression.leftExpression) {
        this._flatten(flat, expression.leftExpression);
      }
      if (expression.rightExpression) {
        this._flatten(flat, expression.rightExpression);
      }
    } else if (expression instanceof OdataBinaryExpression) {

      const id = expression.leftExpression instanceof OdataPropertyExpression ? expression.leftExpression.name.replace(/\[|\]/g, '') :
        expression.rightExpression instanceof OdataPropertyExpression ? expression.rightExpression.name.replace(/\[|\]/g, '') : null;
      // const field = this.filterControl.appFields.find(f => f.Identifier === id);

      const val = expression.leftExpression instanceof OdataLiteralExpression ? expression.leftExpression.value :
        expression.rightExpression instanceof OdataLiteralExpression ? expression.rightExpression.value : null;
      // if (field) {
      //   val = this._mapValue(field.Type, val);
      // }

      // todo merge these unless they diverge
      if (expression instanceof OdataComparisonExpression) {

        const ft: IFilterTerm = {
          id: expression.id,
          FieldIdentifier: id,
          Operator: expression.type,
          Operand: val,
          displayOperand: val,    // todo map for users etc - but need to look up field type
        };

        flat.push(ft);

      } else if (expression instanceof OdataFunctionExpression) {

        const ft: IFilterTerm = {
          id: expression.id,
          FieldIdentifier: id,
          Operator: expression.type,
          Operand: val,
          displayOperand: val,    // todo map for users etc - but need to look up field type
        };

        flat.push(ft);
      } else {
        console.warn('Unsupported odata expression, subtree will be ignored', expression);
      }

    } else {
      console.warn('Unsupported odata expression, subtree will be ignored', expression);
    }
  }
}

export class SavedFilterSpecification extends FilterSpecification {
  constructor(savedFilter: SavedFilter,
    appFields: Array<AppField>,
    reportFields: Array<ReportField>) {
    const reportFilter = ReportFilter.fromSavedFilter(savedFilter);
    super(reportFilter, appFields, reportFields);
  }
}

