<app-ws-report 
  [vxWatch]="reportModel.totalCount"
  [appModel]="appModel" 
  [pageModel]="pageModel"
  [reportModel]="reportModel" 
  [reportController]="reportController"
  [folderModel]="pageModel.folderModel"
  [filterModel]="filterModel"
  [showAddNewRecord]="canCreate"
  [createUrl]="createUrl"
  (contentScrolled)="scrolled($event)"
  >
  <div class="footer-left">
    <app-zoom-widget
      [value]="cardModel.zoom.$|async" 
      (zoomed)="zoomChanged($event)"
    ></app-zoom-widget>
  </div>

  <ng-container 
    *ngIf="(reportModel.totalCount.$|async) then normal; else noRecs"  >
  </ng-container>

</app-ws-report>

<ng-template #normal>
  <app-card-report
    [cardModel]="cardModel"
    [reportController]="reportController"
  >
  </app-card-report>
</ng-template>

<!-- <ng-template #cards>
  <ng-container *ngIf="(reportModel.totalCount.$|async) then normal; else noRecs"  >
  </ng-container>
 </ng-template> -->

<ng-template #noRecs>
  <app-ws-sharednorecords 
    [vxWatch]="[appModel.app, appModel.globalModel.archived]"
    *ngIf="reportModel.accessor && !(reportModel.accessor.countBusy$|async)"
    [app]="appModel.app.$|async"
    [reportModel]="reportModel"
    [isLoading]="reportModel.busy.busy$|async"
    [isArchived]="appModel.globalModel.archived.$|async">
  </app-ws-sharednorecords>
</ng-template>
