<div class="p-2" cdkOverlayOrigin #overlayOriginEl="cdkOverlayOrigin">
  <fa-icon [icon]="[iconWeight, icon]" [border]="false" size="2x" role="button" (click)="openActions()"></fa-icon>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOriginEl"
  [cdkConnectedOverlayOpen]="showActions && !isSmallScreen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'no-background'"
  (backdropClick)="closeActions()"
>
  <div id="actionsContainer" role="main" class="popout card">
    <div class="card-block p-2" *ngIf="disableWhenOffline && !isOnline">
      <div role="button">
        <fa-icon [icon]="['fal', 'wifi-slash']" [border]="false" size="1x" class="me-2"></fa-icon>
        <span i18n="@@Offline">Offline</span>
      </div>
    </div>
    <app-action-list
      [actions]="actions"
      [version]="version"
      [showVersion]="showVersion"
      [disableWhenOffline]="disableWhenOffline"
      [isOnline]="isOnline"
      (onActionClick)="closeActions(); onActionClick.emit($event)"
    ></app-action-list>
  </div>
</ng-template>
