<div class="drag-drop-area" #entryElement (drop)="dropped($event)" (dragover)="draggedover($event)" (dragend)="dragend_handler()">
  <sof-drop-zone i18n-dragText [dragText]="dragText"></sof-drop-zone>
</div>

<!-- Action buttons for normal view -->

<div id="createFile">
  <div class="d-flex justify-content-end">
    <!-- Paste button.  As we can't paste directly this just opens up the input below where we can-->
    <button
      *ngIf="enablePasting"
      class="primary-line mx-2"
      (click)="pasteBuffer.style.display = 'inline'; pasteBuffer.focus()"
      i18n-title="@@PasteHint"
      title="Paste from clipboard. Use Print Screen before pasting to attach a screen shot."
    >
      <fa-icon [icon]="['fal', 'paste']" class="me-1"></fa-icon> <span i18n>Paste</span>
    </button>
    <!-- Initially hidden field where we can allow user to paste with system paste function and override to
                      do what we need.  -->
    <input
      #pasteBuffer
      matInput
      type="text"
      class="mx-2"
      placeholder="Press Ctrl+V to paste"
      i18n-placeholder="@@PasteInstructions"
      style="display: none"
      (paste)="pasteAttachment($event)"
    />
    <!-- Hidden file brower which we can drive programatically -->
    <input type="file" hidden #fileSelector (change)="fileSelected($event)" [accept]="accept" />
    <!-- Button that the user presses to start browsing -->
    <button class="primary mx-2" title="Browse for file to upload" i18n-title="@@BrowseHint" (click)="fileSelector.click()">
      <fa-icon [icon]="['fal', 'file']" class="me-1"></fa-icon> <span i18n>Browse ...</span>
    </button>
  </div>
</div>
