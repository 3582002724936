<div class="pointer" cdkOverlayOrigin #zoomOrigin="cdkOverlayOrigin">
  <fa-icon 
    [icon]="['fal', 'search-plus']" 
    size="1x" 
    (click)="openZoomer()"
    >
  </fa-icon>    
</div>

<app-popup-container 
  [isOpen]="zoomerOpen$|async" 
  [connectedOrigin]="zoomOrigin"
  [transparentBackdrop]="true"
  (closed)="openZoomer(false)">
  <!-- 

   -->
  <ng-template>
    <div class="grid-box mx-2"  >
      <div class="slider d-flex align-items-center">
        <input 
          type="range"
          list="zoom-levels"
          max="120"
          min="20"
          [value]="value * 100"
          class="slider-input mx-2"
          (input)="updated($event)"
          >
          <datalist id="zoom-levels">
            <option value="20"></option>
            <option value="30"></option>
            <option value="40"></option>
            <option value="50"></option>
            <option value="60"></option>
            <option value="70"></option>
            <option value="80"></option>
            <option value="90"></option>
            <option value="100"></option>
            <option value="110"></option>
            <option value="120"></option>
          </datalist>
        <small class="mx-2" style="width: 2rem;">{{preview$|async}}</small>
        </div>
      <!-- <small class="min-label mx-2 mt-1">20%</small>
      <small class="max-label mx-2 mt-1">120%</small> -->
      <div class="buttons">
        <button type="button" class="secondary line opaque grey-fill m-2" (click)="reset($event)">
          <span i18n>Reset</span>
        </button>
        <button i18n type="button" class="primary line opaque m-2"
          (click)="finished($event)"
          >
          Zoom
        </button>
      </div>
    </div>


  </ng-template>
</app-popup-container>

