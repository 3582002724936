import { User, Permission } from '../types/interfaces';
import { BehaviorSubject } from 'rxjs';

export const currentUser$ = new BehaviorSubject<User>(tryGetCurrentUser());

/** Get the currently logged in user or null if not logged in. */
export function tryGetCurrentUser(): User {
  const currentUser = localStorage.getItem('currentUser');
  if (currentUser) {
    const user: User = JSON.parse(currentUser);
    user.hasPermission = (permissions: Array<number>) => {
      return user.Permissions.some((userPermission: Permission) => (permissions.find(p => userPermission.Code === p)) !== undefined);
    };
    return user;
  }

  return null;
}

/** Get the currently logged in user. */
export function getCurrentUser(): User {
  const user = tryGetCurrentUser();
  if (!user) {
    throw new Error('No user in storage');
  }
  return user;
}

export function setCurrentUser(user: User) {
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUser$.next(user);
}
