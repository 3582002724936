import { Injectable } from '@angular/core';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { LAST_LIST_STORE } from '../indexedDb/database-stores';

/**
 * Manages storage of visted reports.
 * This is mainly a workround for the lack of context when we open a
 * record from a report. Knowing the last report means the record can
 * use it to configure pagination.
 * 
 * This applies to all "list-like" reports, including table and card.
 */
@Injectable({ providedIn: 'root' })
export class LastListReportStorageService {

  constructor(private dbContext: DatabaseContextService<string>) {
  }

  public async getLastRecordsReportAsync(appIdentifier: string): Promise<string | null> {
    return this.dbContext.get(LAST_LIST_STORE, appIdentifier);
  }

  public async storeLastRecordsReportAsync(appIdentifier: string, reportIdentifier: string): Promise<boolean> {
    return this.dbContext.save(LAST_LIST_STORE, appIdentifier, reportIdentifier);
  }
}
