<div>
  <app-input-icon [icon]="iconName" 
    [clickable]="!getIsDisabled()"
    [elementStyles]="elementStyles"
    (iconClicked)="openMenu($event)" 
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"  
  >
    <input
      readonly="readonly"
      #input
      class="w-100 form-control  form-control-o pointer"
      [value]="displayText()"
      [attr.data-identifier]="fieldModel.Identifier"
      [class.inline-control]="inline"
      [ngClass]="{ 'inline': inline, 'readonly-normal-appearance': !getIsDisabled() }"
      [ngStyle]="textStyle"
      (click)="!getIsDisabled() && openMenu($event)"
    />
  </app-input-icon>
</div>
