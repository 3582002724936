<h5
  *ngIf="!cell.HideTitle"
  class="card-header title-container p-2"
  [ngClass]="{
    'align-left': cell.Alignment === alignments.Left,
    'align-right': cell.Alignment === alignments.Right,
    'align-center': cell.Alignment === alignments.Center
  }"
>
  <ng-container *ngIf="icon?.length > 0; else reportChartIcon">
    <fa-icon *ngIf="icon" [icon]="['fal', icon]" size="1x"></fa-icon>
  </ng-container>
  <ng-template #reportChartIcon>
    <app-foldericon [FolderType]="reportType" [SecondaryFolderType]="chartType"></app-foldericon>
  </ng-template>
  {{ title }}
</h5>

<div
  *ngIf="cell.CellType !== null && cell.CellType === dashboardCellTypeEnums.ReportCell && cell.AppIdentifier && cell.ReportIdentifier"
  class="card-header subtitle-container"
>
  <div
    class="subtitle p-2"
    [ngClass]="{
      'align-left': cell.Alignment === alignments.Left,
      'align-right': cell.Alignment === alignments.Right,
      'align-center': cell.Alignment === alignments.Center
    }"
  >
    {{ subTitle }}
  </div>
  <fa-icon
    *ngIf="globalFilters && globalFilters.length > 0"
    [icon]="['fal', 'filter']"
    size="1x"
    class="filter-icon"
    role="button"
    (click)="onFilterIconClickHandler(trigger)"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  ></fa-icon>
</div>

<div class="card-block text-center m-4" *ngIf="isLoading && !isErrored">
  <sof-spinner-icon [size]="'4x'" class="st-text-theme-primary loader"></sof-spinner-icon>
</div>

<div class="card-block text-center" *ngIf="!isLoading && (isErrored || cell.CellType === null)">
  <span i18n>An error occurred loading the dashboard cell data.</span>
</div>

<div class="card-block" *ngIf="!isLoading && !isErrored">
  <div
    *ngIf="cell.CellType !== null && cell.CellType === dashboardCellTypeEnums.ImageCell && cell.AssetImageUri"
    [ngClass]="{
      'align-left': cell.Alignment === alignments.Left,
      'align-right': cell.Alignment === alignments.Right,
      'align-center': cell.Alignment === alignments.Center
    }"
  >
    <app-asset-image [assetUrl]="cell.AssetImageUri"> </app-asset-image>
  </div>

  <div
    *ngIf="cell.CellType !== null && cell.CellType === dashboardCellTypeEnums.IFrameCell && cell.IFrameSrc"
    [ngClass]="{
      'align-left': cell.Alignment === alignments.Left,
      'align-right': cell.Alignment === alignments.Right,
      'align-center': cell.Alignment === alignments.Center
    }"
  >
    <iframe
      [attr.src]="sanitizer.bypassSecurityTrustResourceUrl(cell.IFrameSrc)"
      [attr.width]="cell.IFrameWidth"
      [attr.height]="cell.IFrameHeight"
      allowfullscreen="true"
      frameborder="0"
    ></iframe>
  </div>

  <div
    *ngIf="cell.CellType !== null && cell.CellType === dashboardCellTypeEnums.ReportCell && cell.AppIdentifier && cell.ReportIdentifier"
    class="chart-container"
  >
    <app-report
      [chartReportModel]="chartReportModel"
      [reportModel]="chartReportModel"
      [dualReportModel]="chartReportModel.dualReportModel$|async"
      [filterModel]="filterModel"
      [isDual]="chartReportModel.isDual.$|async"
      [dualReportStyle]="chartReportModel.dualReportStyle.$|async"
      [isOfflineReport]="false"
      [editMode]="false"
    >
    </app-report>
  </div>

  <div
    *ngIf="cell.CellType === dashboardCellTypeEnums.ButtonFieldCell && cell.AppIdentifier && cell.FieldIdentifier && buttonField"
    class="button-container"
    [ngClass]="{
      'align-left': cell.Alignment === alignments.Left,
      'align-right': cell.Alignment === alignments.Right,
      'align-center': cell.Alignment === alignments.Center
    }"
  >
    <app-button-field
      [appModel]="appModel"
      [fieldModel]="buttonField"
      [alignmentOverride]="cellAlignment()"
      [label]="buttonField.Label"
      [appIdentifier]="cell.AppIdentifier"
      [imageUri]="buttonField.ImageActionButtonImageUri"
      [buttonSize]="buttonField.ImageActionButtonSize"
      [buttonType]="buttonField.ImageActionButtonType"
      [buttonUrl]="buttonField.ImageActionButtonUrl"
      [buttonUrlNewTab]="buttonField.ImageActionButtonUrlNewTab"
      [buttonChildAppIdentifier]="buttonField.ImageActionButtonChildAppIdentifier"
      [inDashboardReportView]="true"
    >
    </app-button-field>
  </div>

  <ng-container *ngIf="cell.CellType === dashboardCellTypeEnums.StaticTemplate">
    <app-template
      *ngIf="template"
      [appModel]="appModel"
      [appIdentifiers]="appIdentifiers"
      [application]="app"
      [template]="template"
      [record]="appModel.staticRecord.$ | async"
      [hideHeader]="true"
    >
    </app-template>
  </ng-container>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showFilters">
  <div
    *ngIf="globalFilters && globalFilters.length > 0 && mappedGlobalFilters && mappedGlobalFilters.length > 0"
    class="filters-container m-2 card"
  >
    <app-dropdown
      labelText="Filter: "
      [value]="selectedGlobalFilterId"
      [selectOptions]="mappedGlobalFilters"
      [disabled]="false"
      (onselect)="onFiltersChangeHandler($event)"
      [multiple]="false"
      class="card-body"
      i18n-labelText
    ></app-dropdown>
  </div>
</ng-template>
