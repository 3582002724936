<div class="wrapper">
  <div class="image-box mt-2 mb-2 w-100 h-100 d-flex justify-content-center align-items-center ">
    <app-icon [iconBase64]="iconBase64" size="6rem"></app-icon>
  </div>

  <div class="title-wrapper text-truncate pt-1 pb-1 pe-2 ps-2" [ngClass]="{ 'bg-primary': !color}" [style.background-color]="color ? color : null">
    <span class="text-white title">{{title}}</span>
    <br />
    <span class="text-white title"><small style="font-weight: 100">{{category}}</small></span>
  </div>

  <div *ngIf="description" class="tile-desc" [ngClass]="{ 'bg-primary': !color}" [style.background-color]="color ? color : null">
    <div class="pt-2 px-2 text-white">{{title}}</div>
    <div class="p-2 small">{{description}}</div>
  </div>
</div>

