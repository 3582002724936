import { Injectable } from '@angular/core';
import { Style } from '@softools/softools-core';
import { Application } from 'app/types/application';

@Injectable({ providedIn: 'root' })
export class NamedStylesService {

  private stylesByName = new Map<string, Style>();

  constructor() { }

  public store(styles: Array<Style>) {
    styles.forEach(style => {

      if (!style.css) {
        style.css = {};
      }

      if (style.Colour) {
        style.css['color'] = style.Colour;
      }

      if (style.BackgroundColour) {
        style.css['background-color'] = style.BackgroundColour;
      }

      if (style.FontSize) {
        style.css['font-size'] = style.FontSize;
      }

      if (style.FontWeight) {
        style.css['font-weight'] = style.FontWeight;
      }

      if (style.DropShadow) {
        style.css['box-shadow'] = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';
      }

      this.stylesByName.set(style.Name, style);
    });
  }

  public getAppStyles(app: Application) {
    const siteStyles = Array.from(this.stylesByName.values());
    return [
      ...siteStyles,
      ...app.Styles ?? []
    ];
  }
}
