<div class="input-group stylish-input-group d-flex align-items-center">
  <fa-icon
    id="searchIcon"
    [icon]="['fal', 'search']"
    class="pointer ms-2"
    (click)="onSearch.emit({ event: $event, value: searchInput.value })"
  ></fa-icon>

  <input
    id="searchInput"
    name="searchInput"
    value="{{ searchText }}"
    #searchInput
    type="text"
    class="form-control form-control-o me-1"
    autocomplete="off"
    [class.expand-on-focus]="expandOnFocus"
    style="margin-right: 1rem; background: inherit"
    i18n-placeholder="@@Search"
    placeholder="Search"
    [disabled]="isLoading"
    (keypress)="onKeyPressed($event)"
    (keyup)="onKeyUp($event)"
  />

  <sof-spinner-icon *ngIf="searchPending" class="me-2"> </sof-spinner-icon>

  <fa-icon
    id="clearSearch"
    *ngIf="!searchPending"
    [icon]="['fal', 'times-circle']"
    class="pointer me-2"
    (click)="searchInput.value = ''; onEscape.emit()"
  >
  </fa-icon>
</div>
