import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-not-supported',
  templateUrl: './customnotsupported.component.html'
})
export class CustomNotSupportedComponent {

  @Input() headerText: string;
  @Input() description: string;

}
