import { ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Input, Output } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { EditableFieldBase, FieldBase } from 'app/softoolsui.module/fields';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { ImageListFieldComponent } from 'app/softoolsui.module/fields2/image-list-field/image-list-field.component';
import { AppField } from 'app/types/fields/app-field';

@Component({
  selector: 'sof-filter-popup-field',
  templateUrl: './filter-popup-field.component.html',
  styleUrls: ['./filter-popup-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPopupFieldComponent extends FieldComponent {

  @Input() required = false;

  @Output() public onValueChanged = new EventEmitter<any>();

  @Output() public onInputChanged = new EventEmitter<any>();

  /** All fields here are editable */
  protected override isEditable(): boolean {
    return true;
  }

  protected override _initEditableField(fieldComponent: EditableFieldBase<any, AppField<any>>): void {

    super._initEditableField(fieldComponent);
    fieldComponent.forceEditable = true; 

    // Forward change notifications to container via emitter
    // We could simpify this as this is dedicated to being in the filter popup
    // so could call into an interface forward the observable
    this.subscribe(fieldComponent.valueChanged$, (value) => {
      this.onValueChanged.emit(value);
    });

    this.subscribe(fieldComponent.inputChanged$, (value) => {
      this.onInputChanged.emit(value);
    })
  }

  protected override addComponentForField(): ComponentRef<FieldBase<any, AppField<any>>> {
    switch (this.fieldModel.Type) {
      // Treat text-like fields as simple text,
      // We don't want specialised field validation etc. becauseit doesn't apply to operations like contains
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Email:
      case Enums.FieldType.Literal:
      case Enums.FieldType.Notes:
      case Enums.FieldType.Barcode:
      case Enums.FieldType.LongText:  // If we want to use LongTextFieldComponent need to supress Rows
      case Enums.FieldType.AttachmentsCount:
      case Enums.FieldType.CommentsCount:
        return this.addTextComponent();
      default:
        return super.addComponentForField();
    }
  }

  protected override addImageListComponent(): ComponentRef<ImageListFieldComponent> {
    const component = super.addChildComponent(ImageListFieldComponent);
    component.instance.unsetSelectable = true;
    return component;
  }

}
