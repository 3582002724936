import { Injectable } from '@angular/core';
import { AppDataStorageService, ChangedRecord, Record, ChangedRecordAction, RecordId, logMessage, LogLevel, logError } from '@softools/softools-core';
import { AppIdentifier, Application } from 'app/types/application';
import { Subject } from 'rxjs';
import { AppService } from './app.service';
import { ChangedRecordsStorageService } from './changed-records-storage.service';
import { ChangeQueueStorageService } from './change-queue.service';
import { RecordQueueService } from './record/record-queue.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackgroundDataSyncService {

  public recordUpdated$ = new Subject<Record>();

  constructor(
    private appService: AppService,
    private appDataService: AppDataStorageService,
    private changedRecordsStorageService: ChangedRecordsStorageService,
    private changeQueueStorageService: ChangeQueueStorageService,
    private recordQueueService: RecordQueueService
  ) {

  }

  public async processChangedRecords(changes: Array<ChangedRecord>) {

    const changesByApp = new Map<AppIdentifier, Array<ChangedRecord>>();
    changes.forEach((change) => {
      if (changesByApp.has(change.AppIdentifier)) {
        changesByApp.get(change.AppIdentifier).push(change);
      } else {
        changesByApp.set(change.AppIdentifier, [change]);
      }
    });

    changesByApp.forEach(async (chgs, appIdentifier) => {
      try {
        const app = this.appService.tryGetApplication(appIdentifier);
        if (app) {
          await this.processAppChangedRecords(app, chgs);
        }
      } catch (error) {
        if (error instanceof HttpErrorResponse) {
          // Ignore HTTP errors, they can happen in normal use and we will naturally retry
        } else {
          logError(error, 'processAppChangedRecords');
        }
      }
    });
  }

  private async processAppChangedRecords(app: Application, changes: Array<ChangedRecord>) {

    const changeMap = new Map<string, ChangedRecord>();
    for (const change of changes) {
      const existing = changeMap.get(change.ObjectId);
      // Store most recent change, unless it's Deleted which is final so can't be overriden
      // (shouldn't be possible in practice)
      if (existing?.Action !== ChangedRecordAction.Deleted) {
        changeMap.set(change.ObjectId, change);
      }
    }

    const changeList = Array.from(changeMap.values());
    const promises: Array<Promise<void>> = [];

    /** object ids we couldn't access so need to retain the id */
    const redoes = new Set<string>();

    const updates = changeList.filter(chg => chg.Action !== ChangedRecordAction.Deleted);
    if (updates.length > 0) {
      const ids = updates.map(chg => chg.ObjectId);
      const results = await app.getRecordsByIdAsync(ids, { forceRemote: true });
      if (results) {
        promises.push(...results.map(async (result) => {
          switch (result.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.NotFound:
            case HttpStatusCode.Forbidden:
              await this.removeRecord(app, result.id);
              break;
            case HttpStatusCode.Unauthorized:
              // Ignore 401 (Unauthorized) - we will try again until the user
              // has reauthenticated
              logMessage('background sync ignoring 401 error', LogLevel.warning);
              redoes.add(result.id);
              break;
            case HttpStatusCode.TooManyRequests:
              // Ignore 429. It should work on a retry, eventually.
              // We should reduce frequency, ideally, but as this is a slow poll
              // we shouldn't do too much damage. See SOF-12398.
              redoes.add(result.id);
              break;
            default:
              if (result.record) {
                const record = this.appDataService.mutateRecord(app, result.record);
                await app.storeAsync(record);
                this.recordUpdated$.next(record);

                if (result.record.IsArchived) {
                  await this.removeRecordFromStorage(result.record._id);
                }
              } else {
                // Record no longer visible so remove
                // todo are there scenarios where we don't want to do this?
                await this.removeRecord(app, result.id);
              }

              break;
          }
        }));
      }
    }

    const deletes = changeList.filter(chg => chg.Action === ChangedRecordAction.Deleted);
    if (deletes.length > 0) {
      promises.push(...deletes.map(del => this.removeRecord(app, del.ObjectId)))
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }

    for (const change of changes) {
      if (!redoes.has(change.ObjectId)) {
        // Remove from the change map
        await this.changedRecordsStorageService.delete(change.Id);
      }
    }
  }

  private async removeRecord(app: Application, id: RecordId) {
    // Publish a pseudo-record to indicate record deleted
    const deletedRecord: Record = {
      _id: id,
      AppIdentifier: app.Identifier,
      _deleted: true
    };
    this.recordUpdated$.next(deletedRecord);

    await this.removeRecordFromStorage(id);
  }

  /** Delete a record from storage along with any queued changes */
  private async removeRecordFromStorage(id: RecordId) {
    await this.changeQueueStorageService.deleteForRecord(id);
    await this.recordQueueService.delete(id);
    await this.appDataService.removeRecordAsync(id);
  }
}
