import { Injectable } from '@angular/core';
import { DatabaseContextService, StorageServiceBase, WORKFLOW_TRIGGER_QUEUE } from '@softools/softools-core';

export interface TriggeredWorkflow {
  appIdentifier: string;
  fieldId: string;
  recordId?: string;
}

@Injectable({ providedIn: 'root' })
export class WorkflowTriggerQueueService extends StorageServiceBase<TriggeredWorkflow, string>  {

  constructor(dbContext: DatabaseContextService<TriggeredWorkflow>) {
    super(WORKFLOW_TRIGGER_QUEUE, dbContext);
  }

  public async queue(triggered: TriggeredWorkflow) {
    await this.save(`${triggered.appIdentifier}-${triggered.fieldId}${triggered.recordId ? '-' + triggered.recordId : ''}`, triggered);
  }

  public async getTriggeredWorkflow(keys: string): Promise<TriggeredWorkflow> {
    const change = await this.get(keys);
    return change;
  }

  public async count() {
    const keys = await this.keys;
    return keys.length;
  }
}
