<div *ngIf="fieldModel && fieldModel.Type === fieldTypes.Selection
            && fieldModel.SelectListType === selectListType.Checkbox"
      class="d-flex"
      [class.flex-column]="!isCompact()"
      [class.flex-wrap]="isCompact()">

  <mat-checkbox *ngFor="let option of selectList?.SelectListOptions"
                [disabled]="isDisabledOption(option)"
                [checked]="isChecked(option.Value)"
                [disableRipple]="true"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyPress($event)"
                (change)="onChange($event, option.Value)">
      <span class="me-4" 
        [class.text-muted]="isDisabledOption(option)"
        [class.theme-dark-text]="!isDisabledOption(option)"
       >
        {{option.Text}}
      </span>
  </mat-checkbox>
</div>
