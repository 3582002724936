import { Component, Input, ViewContainerRef, OnChanges, SimpleChanges, Injector, ViewChild, ChangeDetectionStrategy, Type, ComponentRef, OnDestroy } from '@angular/core';
import { Record, AlignmentTypeAlias, Enums, logError, FileAttachment, Template, ElementStyles, Form, ErrorMap } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { TextFieldComponent } from '../text-field/text-field.component';
import { LongTextFieldComponent } from '../long-text-field/long-text-field.component';
import { DateTimeFieldComponent } from '../date-time-field/date-time-field.component';
import { DateFieldComponent } from '../date-field/date-field.component';
import { PeriodFieldComponent } from '../period-field/period-field.component';
import { BitFieldComponent } from '../bit-field/bit-field.component';
import { ImageListFieldComponent } from '../image-list-field/image-list-field.component';
import { MultiStateField2Component } from '../multi-state-field/multi-state-field.component';
import { ImageFieldComponent, ButtonFieldComponent, InappChartFieldComponent, EmbeddedVideoFieldComponent } from 'app/softoolsui.module';
import { PersonFieldComponent } from '../person-field/person-field.component';
import { PersonTeamFieldComponent } from '../person-field/person-team-field.component';
import { SelectionFieldComponent } from '../selection-field/selection-field.component';
import { SelectionListFieldComponent } from '../selection-list-field/selection-list-field.component';
import { EmailFieldComponent } from '../email-field/email-field.component';
import { UrlFieldComponent } from '../url-field/url-field.component';
import { NotesFieldComponent } from '../notes-field/notes-field.component';
import { TextDisplayFieldComponent } from '../text-display-field/text-display-field.component';
import { IconDisplayFieldComponent } from '../icon-display-field/icon-display-field.component';
import { EditableFieldBase } from 'app/softoolsui.module/fields/editable-field-base';
import { FieldBase, ContainerType } from 'app/softoolsui.module/fields/field-base';
import { TeamFieldComponent } from '../team-field/team-field.component';
import { RangeFieldComponent } from '../range-field/range-field.component';
import { SelectionRadioFieldComponent } from '../selection-radio-field/selection-radio-field.component';
import { SelectionCheckFieldComponent } from '../selection-check-field/selection-check-field.component';
import { BarcodeFieldComponent } from 'app/softoolsui.module/fields/scalar-fields/barcode-field/barcode-field.component';
import { LookupComponent } from '../lookup/lookup.component';
import { AppIdentifiers } from 'app/services/record/app-info';
import { DrawFieldComponent } from 'app/softoolsui.module/fields/draw-field.component/draw-field.component';
import { GridFieldComponent } from '../grid-field/grid-field.component';
import { AttachmentListFieldComponent } from 'app/softoolsui.module/fields/attachment-list-field/attachment-list-field.component';
import { TimeFieldComponent } from '../time-field/time-field.component';
import { IRuleState } from 'app/mvc/rules/rule-state.interface';
import { AppModel, AttachmentsModel, RecordUpdateController, RecordUpdateModel, ReportModel } from 'app/mvc';
import { FileDropComponent } from '../file-drop/file-drop.component';
import { ReferenceComponent } from '../reference/reference.component';
import { ComponentBase } from 'app/softoolsui.module/component-base';
import { ConcealedTextFieldComponent } from '../concealed-text-field/concealed-text-field.component';
import { DocumentFieldComponent } from '../document-field/document-field.component';
import { AggregateFieldComponent } from '../aggregate-field/aggregate-field.component';
import { CommentsModel } from 'app/mvc/comments-model';
import { DownloadIconFieldComponent } from '../download-icon-field/download-icon-field.component';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { ValueTextFieldComponent } from '../value-text-field/value-text-field.component';

/**
 * Field container, provides an implementation of any field type.
 * The real field is dynamically injected inside this container.
 * Because Ivy does not hook up changes to dynamic components, we pass on
 * changes to the field.  The contained carries a set of inputs that must be
 * the same as the contained field types.
 */
@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldComponent extends ComponentBase implements OnChanges, OnDestroy {

  public containerTypes = ContainerType;

  @Input() public appModel: AppModel;

  @Input() public generalController?: IGeneralController;

  @Input() commentsModel: CommentsModel;

  @Input() attachmentsModel: AttachmentsModel;

  /** Report model (if the field is on a report) */
  @Input() public reportModel?: ReportModel;

  /** Record model (if the field is on a record view) */
  @Input() public recordModel?: RecordUpdateModel;

  /** Record update controller when on a record form */
  @Input() recordUpdateController?: RecordUpdateController;

  @Input() public appIdentifiers: AppIdentifiers;

  @Input() public application: Application;

  /** The app field that this element represents */
  @Input() public fieldModel: AppField;

  /** If set, the field (e.g. list or grid field) that contains this field  */
  @Input() public containerField: AppField;

  /** Form when field is on a form */
  @Input() public form: Form;

  /** The template containing the field when it is on a form (not set if on a report etc.) */
  @Input() public template?: Template;

  /** true if the container state implies the field should be readonly */
  @Input() public containerReadOnly: boolean;

  @Input() public record: Record;

  @Input() public value: any;

  /** The row number of the field within a list field */
  @Input() public listRow: number;

  /**
   * If the field is used in an inline template i.e Grid or List Report
   * @deprecated Use containerType and delete this when fully implemented
   */
  @Input() inline = false;

  /** Context where the field is contained
   * NB THis is currently set correctly in a couple of places to get long text formatted
   * correctly.  It should replace all uses of @see inline.
   */
  @Input() containerType = ContainerType.Form;

  @Input() public forReport = false;

  @Input() public isAttachmentListLoading = false;

  @Input() public files: Array<FileAttachment> = [];

  // @Input() isEditable;

  /** Alignment string; left/ center/ right. No value will be default alignment.
   * Field/ ListReportField/ ListReportDetailField can have configured optional Alignment property via AppStudio.
   * ListReportField/ ListReportDetailField usage will override field entity alignment as below.
   */
  @Input() alignmentOverride?: AlignmentTypeAlias;

  @Input() public isOnline: boolean;

  @Input() public ruleState: IRuleState;

  @Input() public elementStyles: ElementStyles;

  /** Optional column number.  This can be used to track the field's location in reports. */
  @Input() public column: number;

  /** Reference to inner element to be repalced by real field */
  @ViewChild('inner', { read: ViewContainerRef, static: true })
  private viewContainer: ViewContainerRef;

  private innerComponent: FieldBase<any>;

  constructor(
    private injector: Injector
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {

      // Add and init child component.  We do this here and not in OnInit so the
      // first change gets routed to the child. Also regenerate if the attached
      // form or template changes as the field might be different
      if (changes['fieldModel'] || changes['form'] || changes['template']) {
        if (this.fieldModel) {
          this.addComponentForField();
        }
      }

      if (this.innerComponent) {
        // Copy changed values down to encapsulated component
        for (const c in changes) {
          if (this.hasOwnProperty(c) && this.innerComponent.hasOwnProperty(c)) {
            this.innerComponent[c] = this[c];
          }
        }

        this.innerComponent.ngOnChanges(changes);
        this.refresh(this.innerComponent);
      }

    } catch (error) {
      logError(error, 'FieldComponent changes');
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.viewContainer.clear();
    this.innerComponent = null;
  }

  /** Make a component inactive */
  public async deactivate() {
    await this.innerComponent.deactivate();
  }

  /** Make a component active. */
  public async activate() {
    await this.innerComponent.activate();
  }

  /** Called when a component becomes active */
  public async activated(active: boolean) {
  }

  public refreshValue() {
    this.innerComponent?.refreshValue();
  }

  public onToggleExpandedFormTemplate() {
    this.innerComponent?.onToggleExpandedFormTemplate();
  }

  protected addValueDisplayField(): ComponentRef<FieldBase> {
    switch (this.fieldModel.Type) {
      case Enums.FieldType.MultiState:
        return this.addReadonlyChildComponent(MultiStateField2Component);
      default:
        return this.addReadonlyChildComponent(ValueTextFieldComponent);
    }
  }

  protected isEditable() {
    return this.fieldModel.isEditable();
  }

  protected addComponentForField(): ComponentRef<FieldBase> {

    if (this.elementStyles?.component.Appearance === 'value') {
      return this.addValueDisplayField();
    }

    if (this.fieldModel.DisplayFormatted) {
      return this.addReadonlyChildComponent(TextDisplayFieldComponent);
    }

    if (this.containerType !== ContainerType.Form) {
      switch (this.fieldModel.Type) {
        case Enums.FieldType.GridField:
        case Enums.FieldType.ListField:
        case Enums.FieldType.AttachmentList:
        case Enums.FieldType.Draw:
        case Enums.FieldType.EmbeddedVideo:
        case Enums.FieldType.InAppChart: {
          return this.addReadonlyChildComponent(IconDisplayFieldComponent);
        }
      }
    }

    switch (this.fieldModel.Type) {

      case Enums.FieldType.Text:
      case Enums.FieldType.Number:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Money: {
        const editable = this.isEditable() && !this.template?.IsReadOnly;
        if (editable) {
          return this.addTextComponent();
        } else {
          return this.addTextDisplayComponent();
        }
      }

      case Enums.FieldType.AttachmentsCount:
      case Enums.FieldType.CommentsCount: {
        return this.addReadonlyChildComponent(TextDisplayFieldComponent);
      }

      case Enums.FieldType.Range: {
        return this.addChildComponent(RangeFieldComponent);
      }

      case Enums.FieldType.LongText: {
        return this.addLongTextComponent();
      }

      case Enums.FieldType.DateTime: {
        return this.addChildComponent(DateTimeFieldComponent);
      }

      case Enums.FieldType.Date: {
        return this.addChildComponent(DateFieldComponent);
      }

      case Enums.FieldType.Period: {
        return this.addChildComponent(PeriodFieldComponent);
      }

      case Enums.FieldType.Time: {
        return this.addChildComponent(TimeFieldComponent);
      }

      case Enums.FieldType.Bit: {
        return this.addChildComponent(BitFieldComponent);
      }

      case Enums.FieldType.ImageList: {
        return this.addImageListComponent();
      }

      case Enums.FieldType.MultiState: {
        return this.addChildComponent(MultiStateField2Component);
      }

      case Enums.FieldType.Image: {
        return this.addChildComponent(ImageFieldComponent);
      }

      case Enums.FieldType.PersonByTeam: {
        return this.addPersonTeamComponent();
        break;
      }

      case Enums.FieldType.Team: {
        return this.addChildComponent(TeamFieldComponent);
      }

      case Enums.FieldType.Selection: {
        const componentType = this.selectionComponentType();
        return this.addChildComponent(componentType);
      }

      case Enums.FieldType.Email: {
        return this.addChildComponent(EmailFieldComponent);
      }

      case Enums.FieldType.UrlField: {

        if (this.fieldModel.DisplayOptions?.ShowAsIcon) {
          return this.addReadonlyChildComponent(DownloadIconFieldComponent);
        } else {
          return this.addChildComponent(UrlFieldComponent);
        }

      }

      case Enums.FieldType.Notes: {
        return this.addChildComponent(NotesFieldComponent);
      }

      case Enums.FieldType.Literal: {
        return this.addReadonlyChildComponent(TextDisplayFieldComponent);
      }

      case Enums.FieldType.ImageActionButton: {
        return this.addReadonlyChildComponent(ButtonFieldComponent);
      }

      case Enums.FieldType.Barcode: {
        return this.addReadonlyChildComponent(BarcodeFieldComponent);
      }

      case Enums.FieldType.Lookup: {
        return this.addReadonlyChildComponent(LookupComponent);
      }

      case Enums.FieldType.InAppChart: {
        return this.addChildComponent(InappChartFieldComponent);
      }

      case Enums.FieldType.Draw: {
        return this.addChildComponent(DrawFieldComponent);
      }

      case Enums.FieldType.EmbeddedVideo: {
        return this.addChildComponent(EmbeddedVideoFieldComponent);
      }

      // Probably need to revert to app-grid-field to avoid circular refs
      case Enums.FieldType.GridField: {
        return this.addReadonlyChildComponent(GridFieldComponent);
      }

      case Enums.FieldType.AttachmentList: {
        return this.addReadonlyChildComponent(AttachmentListFieldComponent);
      }

      case Enums.FieldType.FileDrop: {
        return this.addReadonlyChildComponent(FileDropComponent);
      }

      case Enums.FieldType.Reference: {
        return this.addChildComponent(ReferenceComponent);
      }

      // Person field is a specially configured reference
      case Enums.FieldType.Person: {
        // Check config and use reference based implementation if set
        // Supports large number of users but selective transition until polished
        if (this.fieldModel.Person?.UseReferences) {
          const ref = this.addChildComponent(ReferenceComponent);
          ref.instance.iconName = 'user';
          ref.instance.appIdentifier = 'Softools.User';
          ref.instance.reportIdentifier = 'Lookup';
          ref.instance.titleField = 'FullName';
          return ref;
        } else {
          return this.addPersonComponent();
        }
      }

      case Enums.FieldType.ConcealedText: {
        return this.addChildComponent(ConcealedTextFieldComponent);
      }

      case Enums.FieldType.Document: {
        return this.addChildComponent(DocumentFieldComponent);
      }

      case Enums.FieldType.Aggregate: {
        return this.addReadonlyChildComponent(AggregateFieldComponent);
      }

      // The following field types are not impplemented - add if/when they are
      case Enums.FieldType.Time:
      case Enums.FieldType.Gantt:
      case Enums.FieldType.UrlDownloadField:
      default:
        console.warn('Field not supported by field component', this.fieldModel);
        break;
    }

    return null;
  }

  /** Determine selection field component using selection type and container */
  protected selectionComponentType(): Type<EditableFieldBase> {
    if (this.containerType === ContainerType.Form || this.containerType === ContainerType.Grid) {
      switch (this.fieldModel.SelectListType) {
        case Enums.SelectListType.Radio:
          return SelectionRadioFieldComponent;
        case Enums.SelectListType.Checkbox:
          return SelectionCheckFieldComponent;
        case Enums.SelectListType.Listbox:
          return SelectionListFieldComponent;
        case Enums.SelectListType.Select:
        default:
          return SelectionFieldComponent;
      }
    } else {
      switch (this.fieldModel.SelectListType) {
        case Enums.SelectListType.Checkbox:
        case Enums.SelectListType.Listbox:
          return SelectionListFieldComponent;
        case Enums.SelectListType.Select:
        case Enums.SelectListType.Radio:
        default:
          return SelectionFieldComponent;
      }
    }
  }

  protected addChildComponent<T extends EditableFieldBase>(type: Type<T>): ComponentRef<T> {

    this.viewContainer.clear();
    const componentRef = this.viewContainer.createComponent(type, {
      injector: this.injector
    });

    // Initialise in readonly mode if container suggests it
    if (this.containerReadOnly === true || this.reportModel?.editMode.value === false) {
      this._initField(componentRef.instance);
      componentRef.instance.editMode = false;
    } else {
      this._initEditableField(componentRef.instance);
    }

    this._initElement(componentRef);
    return componentRef;
  }

  ////////////////////////////////////
  // Typed factory methods - these can be overriden by derived classes to
  // change concrete type or to set additional properties to adjust behaviour

  protected addTextComponent(): ComponentRef<FieldBase> {
    return this.addChildComponent(TextFieldComponent);
  }

  protected addLongTextComponent(): ComponentRef<FieldBase> {
    if (this.fieldModel.IsEditable) {
      return this.addChildComponent(LongTextFieldComponent);
    } else {
      return this.addReadonlyChildComponent(TextDisplayFieldComponent);
    }
  }

  protected addImageListComponent(): ComponentRef<FieldBase> {
    return this.addChildComponent(ImageListFieldComponent);
  }

  protected addPersonComponent(): ComponentRef<FieldBase> {
    return this.addChildComponent(PersonFieldComponent);
  }

  protected addPersonTeamComponent(): ComponentRef<FieldBase> {
    return this.addChildComponent(PersonTeamFieldComponent);
  }

  protected addTextDisplayComponent(): ComponentRef<FieldBase> {
    return this.addReadonlyChildComponent(TextDisplayFieldComponent);
  }

  ////////////////////////////////////

  protected addReadonlyChildComponent<T extends FieldBase>(type: Type<T>): ComponentRef<T> {
    this.viewContainer.clear();
    const componentRef = this.viewContainer.createComponent<T>(type, {
      injector: this.injector,
    });
    this._initField(componentRef.instance);
    this._initElement(componentRef);
    return componentRef;
  }

  protected _initEditableField(fieldComponent: EditableFieldBase): void {
    fieldComponent.editMode = true;
    this._initField(fieldComponent);
  }

  private _initElement<T>(componentRef: ComponentRef<T>) {
    componentRef.location.nativeElement.classList.add('w-100');
  }

  protected _initField(fieldComponent: FieldBase): void {
    this.innerComponent = fieldComponent;

    // Initialise common valudes
    fieldComponent.appModel = this.appModel;
    fieldComponent.commentsModel = this.commentsModel;
    fieldComponent.attachmentsModel = this.attachmentsModel;
    fieldComponent.reportModel = this.reportModel;
    fieldComponent.generalController = this.generalController;
    fieldComponent.recordModel = this.recordModel;
    fieldComponent.recordUpdateController = this.recordUpdateController;
    fieldComponent.application = this.application;
    fieldComponent.fieldModel = this.fieldModel;
    fieldComponent.record = this.record;
    fieldComponent.value = this.value;
    fieldComponent.listRow = this.listRow;
    fieldComponent.forReport = this.forReport;
    fieldComponent.reportModel = this.reportModel;
    fieldComponent.inline = this.inline;
    fieldComponent.containerType = this.containerType;
    fieldComponent.containerField = this.containerField;
    fieldComponent.form = this.form;
    fieldComponent.isOnline = this.isOnline;
    fieldComponent.ruleState = this.ruleState;
    fieldComponent.elementStyles = this.elementStyles;
    fieldComponent.template = this.template;
    fieldComponent.containerReadOnly = this.containerReadOnly;

    // this.setContainer(fieldComponent);

    // Allow field specific initialisation
    fieldComponent.initialise({
      field: this.fieldModel,
      appIdentifiers: this.appIdentifiers,
      record: this.record,
      value: this.value,
      alignmentOverride: this.alignmentOverride
    });
  }

  // protected setContainer(fieldComponent: FieldBase): void {
  //   fieldComponent.containerType = ContainerType.Inline;
  // }
}
