import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTouchDirective } from './form-touch.directive';
import { IcsCalendarEventDirective } from './ics-calendar-event.directive';
import { ValidationDirective } from './validation.directive';
import { ValidationComponent } from './validation.component/validation.component';

@NgModule({
  declarations: [
    FormTouchDirective,
    IcsCalendarEventDirective,
    ValidationDirective,
    ValidationComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FormTouchDirective,
    IcsCalendarEventDirective,
    ValidationDirective,
    ValidationComponent,
  ],
  providers: [],
})
export class FieldsHelperModule { }
