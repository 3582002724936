<div class="filter-edit-popup">
  <div class="px-3 py-4 w-100 d-flex align-items-center justify-content-center" >
    <app-filter-asc-desc
      [selected]="selectedSort"
      [disabled]="!field"
      placeholder="Sort"
      i18n-placeholder="SortPrompt@@SortPrompt"
      (change)="sortChanged($event)"
    >
    </app-filter-asc-desc>
  </div>
</div>
