import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { logError } from '@softools/softools-core';
import { Observable } from 'rxjs';
import { EffectActionEnums } from 'app/softoolscore.module/types/enums/effectaction.enums';
import { contentPinnedStateAnimation, leftAsideStateAnimation } from 'app/_constants';
import { HomeModel, PageModel } from 'app/mvc';
import { ComponentBase } from 'app/softoolsui.module';
import { GlobalModel, RouteParams } from 'app/mvc/global.model';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { HomeFolderController } from 'app/mvc/folders/home-folder.controller';
import { LaunchpadModel } from 'app/mvc/headers/launchpad.model';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentPinnedStateAnimation, leftAsideStateAnimation]
})
export class HomeComponent extends ComponentBase implements OnInit, OnDestroy {

  public showAside$: Observable<string>;
  public EffectActionEnums: typeof EffectActionEnums = EffectActionEnums;
  public showFooter = false;

  public globalModel: GlobalModel;

  public pageModel: PageModel;

  public homeModel: HomeModel;

  public launchpadModel: LaunchpadModel;

  public folderController: HomeFolderController;

  constructor(private models: GlobalModelService) {
    super();
    this.globalModel = this.models.globalModel;
    this.pageModel = this.models.pageModel;
    this.launchpadModel = this.models.launchpadModel;
    this.showAside$ = this.pageModel.folderBarState.$;

    this.folderController = new HomeFolderController(this.globalModel, this.pageModel.folderModel);
  }

  public ngOnInit() {

    this.homeModel = new HomeModel(this.models, this.globalModel, this.pageModel, this.launchpadModel);
    this.homeModel.initialise();

    // Switch back to app mode when home selected
    this.launchpadModel.showSettings.value = false;
    this.launchpadModel.search.value = '';

    this.subscribe(this.globalModel.routeParams.$, (params) => {
      this.routed(params).catch(error => logError(error, 'Failed to route on home model'));
    });

    this.folderController.initialise();

    // Load notifications in background
    this.models.notificationsController.loadNotifications().catch(e => logError(e, 'loadNotifications'));
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected async routed(params: RouteParams) {
    // // update app model if not overriden (most do)
    // this.appModel.routed(params);
    const homepageIdentifier = params.homepageIdentifier;
    await this.homeModel.loadHomepage(homepageIdentifier);
  }
}
