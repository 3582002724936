import { FieldBase } from './field-base';
import { Input, Output, EventEmitter, Directive, OnInit, OnDestroy } from '@angular/core';
import { initialLookupOptions, LookupFieldMapping, Enums, logError, SelectionListChange, TrackChangeList, Failure } from '@softools/softools-core';
import { InvalidValue } from 'app/types/invalid-value';
import { AppField } from 'app/types/fields/app-field';
import { InjectService } from 'app/services/locator.service';
import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { LookupParameters } from 'app/types/lookup-parameters.interface';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Base class for editable fields.
 * TValue is the type of the data managed by the field.
 */
@Directive()
export class EditableFieldBase<TValue = any, TAppField extends AppField = AppField>
  extends FieldBase<TValue, TAppField>
  implements OnInit, OnDestroy {

  @Input() required = false;

  @Input() editMode = false;

  /** Show clear button (if supported by component) */
  @Input() showClear = true;

  /** If true the field is editable irrespective of other flags  */
  @Input() forceEditable = false;

  /** Final value changed e.g. when user clicks out of field, or selection made */
  @Output() onChanged = new EventEmitter<{ payload: TValue; fieldIsValid: boolean }>();

  /** Intermediate value changed e.g. user has typed into the field */
  @Output() onInputChanged = new EventEmitter<{ value: any }>();

  /** Final value changed e.g. when user clicks out of field, or selection made */
  public valueChanged$ = new Subject<TValue>();

  /** Intermediate value changed e.g. user has typed into the field */
  public inputChanged$ = new Subject<any>();

  @Output() onLookupClick = new EventEmitter<LookupParameters>();

  public isValid: boolean = true;

  public textStyle: {};

  public enabled$ = new BehaviorSubject(false);

  @InjectService(RecordValidationService)
  protected readonly validator: RecordValidationService;

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.enabled$.next(!this.getIsDisabled());

    this.textStyle = { ...this.elementStyles?.['text']?.css ?? {}, ...this.elementStyles?.component?.css ?? {} };
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.valueChanged$?.unsubscribe();
    this.enabled$?.unsubscribe();
  }

  protected override onValueChanged(value: TValue) {
    this.enabled$.next(!this.isDisabled());
  }

  public onBlurHandler() {
    if ((!this.previousValue && this.value) || (this.previousValue !== undefined && this.previousValue !== this.value && this.isEditable)) {
      this.onChanged.emit({ payload: this.value, fieldIsValid: true }); // todo what if it's not valid...
    }

    this.previousValue = this.value;
  }

  public inputChangedHandler($event) {
    const value = this.input?.nativeElement.value;
    this.onInputChanged.emit({ value });
    this.inputChanged$.next(value);
  }

  protected updateValue(value: TValue) {
    // Hook up several ways of notifying - should rationalise
    // Ouputs only used in legacy header filters via StandaloneFieldComponent & EditFieldComponent  2022-10-26
    this.onInputChanged.emit({ value });
    this.onChanged.emit({ payload: value, fieldIsValid: true });
    this.inputChanged$.next(value);
    this.valueChanged$.next(value);
  }

  public onLookupClickHandler($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.searchLookupField != null) {
      const lookupParams: LookupParameters = {
        lookupOptions: {
          ...initialLookupOptions,
          appIdentifier: this.searchLookupField.LookupFieldApp,
          searchLookupAppField: (<Array<LookupFieldMapping>>this.searchLookupField.LookupFieldMappings).find(
            (m) => m.ChildFieldIdentifier === this.identifier
          ).ParentFieldIdentifier,
          searchValue: this.value,
        },
        lookupFieldMappings: <Array<LookupFieldMapping>>this.searchLookupField.LookupFieldMappings,
      };

      this.onLookupClick.emit(lookupParams);
    }
  }

  public onInputValidation(errors: Array<Failure>) {
    this.editValidationErrors$.next(errors);
  }

  public isDisabled() {

    if (this.forceEditable) {
      return false;
    }

    if (this.form?.ReadOnly || this.template?.IsReadOnly) {
      return true;
    }

    // Is editable is used to override the field config.
    // Filters for examble pass in a field from the app model but need to be editable.
    if (this.isEditable !== undefined) return !this.isEditable;

    // Disable if false specified or field spec indicates readonly
    return this.fieldModel?.isDisabled(this.record, this.ruleState);
  }

  public override getIsDisabled(): boolean {

    if (this.forceEditable) {
      return false;
    }

    return super.getIsDisabled();
  }


  protected checkValid() {
    if (this.fieldModel && this.record) {
      const errorMapsForRecord = this.record._errs;
      const errorList = errorMapsForRecord?.filter((err) => err.fieldId === this.fieldModel.Identifier);
      this.isValid = !(errorList?.length > 0);
    }
  }

  /**
   * Dispatch a simple field value change event.
   * @deprecated Use dispatchChangeAsync which is explicitly async
   *
   * @param value new field value
   */
  protected dispatchChange(value: TValue | InvalidValue | TrackChangeList | SelectionListChange) {
    // Patch record if we are editing one, not for a single value
    if (this.record) {
      if (!this.isDisabled()) {
        const patch = this.fieldModel.createPatch(this.record, value, this.listRow);
        this.appModel?.patchRecordValue(patch).catch(error => logError(error, 'Failed to patch record value'));
      }
    } else {
      this.value = value as TValue;
    }
  }

  /**
   * Dispatch a simple field value change event.
   *
   * @param value new field value
   */
  protected async dispatchChangeAsync(value: TValue | InvalidValue | TrackChangeList | SelectionListChange): Promise<void> {
    // Only dispatch if we are editing a record, not for a single value
    if (this.record) {
      if (!this.isDisabled()) {
        const patch = this.fieldModel.createPatch(this.record, value, this.listRow);
        await this.dispatchPatchAsync(patch);
      }
    } else {
      this.value = value as TValue;
    }
  }

  /**
   * Update the local copy of the field value, and optionally persist the change
   * into the patch queue.
   * @param value 
   * @param persist 
   */
  protected async updateValueAsync(value: TValue | InvalidValue | TrackChangeList | SelectionListChange, persist = true) {
    // Only update when field is valid
    if (!this.hasEditValidationErrors) {
      this.updateValue(value as TValue);
      if (persist) {
        await this.dispatchChangeAsync(value);
      }
    }
  }

  private get hasEditValidationErrors() {
    return this.editValidationErrors$.value?.length > 0;
  }

  // public calendarChangeEvent(model, type) {
  //   let dateValue = model.value;
  //   if (dateValue && type === 'date') {
  //     dateValue = moment(dateValue).format('YYYY-MM-DD');
  //   } else if (dateValue && type === 'period') {
  //     dateValue = moment(dateValue).format('YYYY-MM');
  //   } else {
  //     dateValue = moment(dateValue).format('YYYY-MM-DDTHH:mm');
  //   }
  //   const valid = getFieldIsValidFromModel(model);
  //   this.onChanged.emit({ payload: dateValue, fieldIsValid: valid });
  // }
}

@Directive()
export class TextFieldBase extends EditableFieldBase {
  @Input() length: number;
}

@Directive()
export class NumberFieldBase extends EditableFieldBase {
  @Input() maxValue: number;

  @Input() minValue: number;

  constructor(public validRegex, public keypressRegex) {
    super();
  }

  public setFloatValue() {
    this.value = parseFloat(this.value);
  }

  public onKeyPressHandler(event: KeyboardEvent) {
    const regEx = this.keypressRegex;
    if (!regEx.test(event.key)) {
      event.preventDefault();
    }
  }
}
