import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, HostListener, ElementRef, AfterViewChecked, ChangeDetectionStrategy, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Report, Record, logError, ReportField, WidthSpecifier, Enums, AlignmentTypeAlias, RecordId, WidthSpecifierMetaData, IFilterTerm, ReportEditMode, isDefined } from '@softools/softools-core';
import { AppModel, TableReportModel, NavigationKeyAction, AttachmentsModel, ITableRow, ReportModel } from 'app/mvc';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { FieldFilters, FilterSpecification, ReportFilter } from 'app/filters/types';
import { headerAlignment } from '../form.component/form-base.component';
import { ContainerType, FieldBase } from '../fields/field-base';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FieldComponent } from '../fields2/field/field.component';
import { TableReportFieldComponent } from './table-report-field/table-report-field.component';
import { AppField } from 'app/types/fields/app-field';
import { ComponentBase } from '../component-base';
import { IGroupStarts } from 'app/services/indexes/app-data-index';
import { ControlColumnMode, SelectionMode } from './table-report-types';
import { CommentsModel } from 'app/mvc/comments-model';
import { FilterEditorUi } from 'app/filters/types/filter-editor-ui';
import { TableReportController } from './table-report-controller';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';

interface FieldInfo extends ReportField {

  fieldFilter: FieldFilters;

  field: AppField;

  x: number;

  w: number;

  flexOrder: number;

  minWidth: number;

  maxWidth: number;

  elasticity: number;

  sticky: boolean;

  editable: boolean;
}

@Component({
  selector: 'app-table-report',
  templateUrl: './table-report.component.html',
  styleUrls: ['./table-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableReportComponent extends ComponentBase implements OnInit, OnChanges, AfterViewChecked, AfterViewInit, OnDestroy {

  @Input() public appModel: AppModel;

  @Input() public commentsModel: CommentsModel;

  @Input() public attachmentsModel: AttachmentsModel;

  @Input() public reportModel: TableReportModel;

  @Input() public selectionMode = SelectionMode.None;

  /** Override record edit mode */
  @Input() public showRecordEdit = true;

  /** If true set focus on first cell after initialisation */
  @Input() public setInitialActiveCell = true;

  @Input() public controller: TableReportController;

  @Input() public containerReadOnly: boolean;

  @Output() acceptClicked = new EventEmitter<void>();

  public report: Report;

  public displayedColumns: string[] = [];

  public fields: Array<FieldInfo> = [];

  public widths: Array<number> = [];

  public totalWidth$ = new BehaviorSubject(0);

  public heightMayHaveChanged = true;

  private activeFieldComponent: FieldComponent;

  public activeCellRecordId: RecordId;

  public activeCellColumn: number;

  public readonly rowHeight = 48;

  public readonly controlColumnWidth = 32;

  public containerType = ContainerType.TableReport;

  public controlColumnModes = ControlColumnMode;

  public scrollPos$ = new BehaviorSubject<number>(0);

  /** Left scroll offset in px */
  public scrollLeftPos = 0;

  public popupPosition = [
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' }
    ),
  ];

  @ViewChild('container', { static: true })
  private containerDiv: ElementRef<HTMLDivElement>;

  @ViewChildren(TableReportFieldComponent) fieldComponents: QueryList<TableReportFieldComponent>;

  private sizedChanged$ = new Subject<{ w: number, h: number }>();

  /** Width of reprot window in px */
  private width = 0;

  public headerHeight$ = new BehaviorSubject(48);

  /** CSS to apply per record for row border */
  private borderCss: Map<RecordId, any>;

  constructor() {
    super();

    this.subscribe(this.sizedChanged$.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ), ({ w, h }) => {
      this.layout();
    });
  }

  public ngOnInit(): void {
    this.subscribe(this.appModel.globalModel.modeCancelled$, () => {
      this.deactivate().catch(error => logError(error, 'Failed deactivate'));    // completes async
    });

    this.subscribe(this.appModel.globalModel.layoutChanged$, () => {
      this.resize();
    });

    this.layoutColumns();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['reportModel']) {
        // Unsubscribe from any previous model
        const prev = changes['reportModel'].previousValue;
        if (prev) {
          this.unsubscribeFor(prev);
        }

        if (this.reportModel) {

          // Create a default controller if the container hasn't supplied one
          if (!this.controller) {
            this.controller = new TableReportController(this.reportModel);
          }

          this.modelChanged(this.reportModel);
        }
      }

    } catch (error) {
      logError(error, 'TableReportComponent changed');
    }
  }

  public ngAfterViewInit(): void {
    // Track change active component when regenerated
    this.subscribe(this.fieldComponents.changes, ((list: QueryList<TableReportFieldComponent>) => {
      if (this.setInitialActiveCell) {
        const actives = list.filter(f => f.active);
        if (actives.length > 0) {
          this.activeFieldComponent = actives[0];
          this.activeFieldComponent.activate().catch(error => logError(error, 'Failed to activate table report'));
        }
      }
    }));

    // Calculate initial wiewport size
    this.resize();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.totalWidth$?.unsubscribe();
    this.fieldComponents = null;
    this.report = null;
    // this.controller?.distroy();
  }

  public modelChanged(model: TableReportModel) {
    this.subscribeFor(model, model.navigationKey$, (action: NavigationKeyAction) => {
      this.navigateActiveCell(action).catch(error => logError(error, 'Failed to navigate active cell'));    // completes async
    });

    this.subscribeFor(model, model.selectionModel.reset.$, () => {
      this.controller.setRecordControlMode();
      this.refresh();
    });

    // When scrollbar moves, set index to match
    // Limit rate to avoid rendering too much while scrolling
    this.subscribeFor(model, this.scrollPos$.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ), (pos) => {
      model.scrollOffset.value = pos;
    });

    this.subscribeFor(model, model.recordChanged$, () => {
      this.activeFieldComponent?.refreshValue();
      this.refresh();
    });

    this.subscribeFor(model, model.scrollOffset.$, (offset) => {
      if (this.containerDiv && offset !== this.containerDiv.nativeElement.scrollTop) {
        this.containerDiv.nativeElement.scrollTop = offset;
        this.refresh();
      }
    });

    this.subscribeFor(model, model.report.$, (report) => {
      this.report = report;
      this.loadColumns();
    });

    this.subscribeFor(model, model.rows.$, async (rows) => {
      if (rows) {
        const records = rows.map(row => row.record).filter(record => !!record);
        this.createRecordStyles(records);
      }
      this.activeFieldComponent?.refreshValue();
    });

    this.subscribeFor(model, model.zoomPane.$, () => {
      this.resize();
    });

    this.subscribeFor(model, combineLatest([
      model.showGroups.$,
      model.groupCount.$,
      model.groups.$,
      model.totalCount.$
    ]), (countInfo) => {
      if (countInfo) {
        model.viewHeight.value = this.viewHeight();
      }
    });

    this.subscribeFor(model, combineLatest([
      this.appModel.app.$,
      model.report.$,
    ]), ([app, report]) => {
      if (app && report) {
        // Show record edit widget if app supports view at least and not turned off by container
        const show = this.showRecordEdit && (app.capabilities.canEdit || app.capabilities.canView)
          && report.EditMode !== ReportEditMode.Never;
        this.controller.showRecordEdit.value = show;

        this.controller.showEditModeSwitch.value = (report.EditMode === ReportEditMode.Switch);
        if (report.EditMode === ReportEditMode.Always || !isDefined(report.EditMode)) {
          model.editMode.set();
        }
      }
    });

    // Deactivate edit cell when switching out of edit mode
    this.subscribeFor(model, model.editMode.$, (enabled) => {
      if (!enabled) {
        this.deactivate();
      }
    });

    this.subscribeFor(model, model.group.$, (group) => {
      this.headerHeight$.next(group ? 96 : 48);
    });

    this.subscribeFor(model, model.topIndex.$, (top) => {
      this.containerDiv.nativeElement.scrollTo({ top: top * this.rowHeight });
    });
  }

  public async activateFirstRow() {
    const records = this.reportModel.records.value;
    if (records?.length > 0) {
      await this.activate({ recordId: records[0]._id });
    }
  }

  public ngAfterViewChecked(): void {
    if (this.heightMayHaveChanged) {
      this.layout();
    }
  }

  public scrolled(_) {
    this.scrollLeftPos = this.containerDiv.nativeElement.scrollLeft;
    this.scrollPos$.next(this.containerDiv.nativeElement.scrollTop);
  }

  public viewHeight() {
    if (this.reportModel.showGroups.value) {
      const count = this.reportModel.groupCount.value || 0;
      // Add space for header and last row, or we round down and lose it
      return (count + 2) * this.rowHeight;
    } else {
      const count = this.reportModel?.recordCount() || 0;
      // Add space for header and last row, or we round down and lose it
      return (count + 2) * this.rowHeight;
    }
  }

  public topOffset() {
    return (this.reportModel.scrollOffset.value);
  }

  public scrollXpos() {
    return this.containerDiv?.nativeElement?.scrollLeft || 0;
  }

  public trackRow(_index: number, row: ITableRow) {
    if (row.record) {
      return row.record._id;
    } else {
      return row.pos;
    }
  }

  @HostListener('window:resize')
  public resize() {
    // this.layout();
    const w = this.containerDiv?.nativeElement?.clientWidth;
    const h = this.containerDiv?.nativeElement?.clientHeight;
    this.sizedChanged$.next({ w, h });
  }

  /** Whether the button toggle group contains the id as an active value. */
  public isSticky(field: ReportField): boolean {

    const enabled = field.StickyMetaData?.Enabled;
    const width = field.StickyMetaData?.Width;

    return (enabled === true || this.width > (+width));
  }

  public getHeaderAlignment(field: AppField): AlignmentTypeAlias {
    return headerAlignment(field.Type, field.Alignment);
  }

  /** Build style map */
  private createRecordStyles(records: Array<Record>) {
    if (records) {
      const report = this.reportModel.report.value;
      const map = new Map<string, any>();
      records.forEach((record) => {
        if (record) {
          const styles = this.appModel.getNamedStyles({
            target: 'card',
            names: report.NamedStyles,
            record,
            borderSideOverride: 'right'
          });

          const borderStyle = styles['border']?.css ?? {};
          map.set(record._id, borderStyle);
        }
      });

      this.borderCss = map;
    }
  }

  public getBorderStyle(record: Record) {
    return this.borderCss.get(record?._id);
  }

  public showFilterPopup($event: MouseEvent, field: FieldInfo) {
    const filterSpec = this.reportModel.filterModel.filterSpec;

    const terms = filterSpec?.flatten()
      .filter(t => t.FieldIdentifier === field.FieldIdentifier);

    const term: IFilterTerm =
      terms?.length === 1
        ? terms[0]
        : {
          id: 0,
          FieldIdentifier: field.FieldIdentifier,
          Operator: 0,
          Operand: null,
          displayOperand: null
        };

    if (field.sticky) {
      this.controller.popupLocation.value = { x: field.x, y: this.rowHeight };
    } else {
      this.controller.popupLocation.value = { x: (field.x - this.scrollLeftPos), y: this.rowHeight };
    }

    const ui = new FilterEditorUi(
      true,   // show popup
      null,   // cdk origin - not used
      field.x, this.rowHeight, 200,      // x, y offsets  (not used) and width
      filterSpec, term, field.FieldIdentifier,
    );

    this.reportModel.showFilterEditor(ui);
  }

  public isActiveCell(recordId: RecordId, column: number) {
    return this.activeFieldComponent && column + 1 === this.activeCellColumn && recordId === this.activeCellRecordId;
  }

  public async activateCell($event: MouseEvent, record: Record, column: number, fieldInfo: FieldInfo) {

    $event.stopPropagation();

    const recordId: RecordId = record._id;
    if (this.reportModel.editMode.value === false) {
      this.clickedOpenRecord($event, record, 0);
    } else if (this.controller.editable.value && fieldInfo.editable) {
      await this.activate({ column, recordId });
    } else {
      await this.deactivate();

      if (this.reportModel.selectionMode.value === SelectionMode.Row) {
        this.reportModel.selectionModel.toggle(recordId);
      }
    }

  }

  public activeCellFields(record: Record): Array<FieldInfo> {

    if (this.activeCellColumn && record._id === this.activeCellRecordId) {
      return [this.fields[this.activeCellColumn - 1]];
    } else {
      return [];
    }
  }

  public clickedBackground() {
    this.appModel.globalModel.cancelMode();
  }

  /**
   * @param row 0 based row index in current view
   */
  public clickedOpenRecord($event: MouseEvent, record: Record, row: number) {
    $event.stopPropagation();
    this.reportModel.navigateToRecord(record, row);
  }

  public recordModeClicked($event: MouseEvent) {
    $event.stopPropagation();

    this.reportModel.selectionModel.deselectAll();
    this.controller.setRecordControlMode();
  }

  public selectModeClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.controller.setSelectControlMode();
    this.reportModel.selectionModel.all.value = false;
  }

  public multiSelectModeClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.controller.setAllSelectedControlMode();
    this.reportModel.selectionModel.selectAll();
  }

  public clickGroup(group: IGroupStarts) {
    this.reportModel.selectGroup(group);
  }

  public clickCloseGroup(group: IGroupStarts) {
    this.reportModel.selectGroup(null);
  }

  public noRecords() {
    return (this.reportModel.recordCount() === 0);
  }

  public isRowSelected(mode: SelectionMode, id: RecordId) {
    return id && mode === SelectionMode.Row && this.reportModel.selectionModel.isSelected(id);
  }

  public showSelection(mode: SelectionMode) {
    return mode === SelectionMode.Mulitple || mode == SelectionMode.MulitpleRows;
  }

  public filterTermUpdated(updates: FilterTermUpdates) {
    this.reportModel.closeFilterEditor();

    const reportFilter = new ReportFilter(this.reportModel.filterModel.reportFilter.value);
    const spec = new FilterSpecification(reportFilter, this.appModel.app.value.AppFields);
    const updated = spec.updateTerm(updates);
    this.reportModel.filterModel.updateCurrentFilter(updated).catch(error => logError(error, 'Failed to update current filter'));
  }

  public async sortUpdated(updates: FilterTermUpdates) {
    try {
      this.reportModel.closeFilterEditor();

      if (updates.isSort) {
        const reportFilter = new ReportFilter(this.reportModel.filterModel.reportFilter.value);
        reportFilter.OrderBy = updates.fieldId;
        reportFilter.IsOrderDescending = !updates.sortAscending;
        await this.reportModel.filterModel.updateCurrentFilter(reportFilter);
      }
    } catch (error) {
      logError(error, 'Failed to update sort');
    }
  }

  private async navigateActiveCell(action: NavigationKeyAction) {
    switch (action) {
      case NavigationKeyAction.NextColumn: {
        const next = this.fields.find((f, i) => f.editable && f.flexOrder > this.activeCellColumn);
        if (next) {
          await this.activate({ column: next.flexOrder });
        }
        break;
      }

      case NavigationKeyAction.PreviousColumn: {
        const prev = [...this.fields].reverse().find((f, i) => f.editable && f.flexOrder < this.activeCellColumn);
        if (prev) {
          await this.activate({ column: prev.flexOrder });
        }
        break;
      }

      case NavigationKeyAction.NextRow: {
        // const records = this.reportModel.recordsModel.value?.records.value;
        const records = this.reportModel.records.value;
        const rowIndex = records.findIndex(rec => rec._id === this.activeCellRecordId);
        if (rowIndex + 1 < records.length) {
          this.activate({ recordId: records[rowIndex + 1]._id }).catch(error => logError(error, 'Failed to activate'));
        } else {
          // todo next page - for now wrap
          this.activate({ recordId: records[0]._id }).catch(error => logError(error, 'Failed to activate'));
        }
        break;
      }

      case NavigationKeyAction.PreviousRow: {
        // const records = this.reportModel.recordsModel.value?.records.value;
        const records = this.reportModel.records.value;
        const rowIndex = records.findIndex(rec => rec._id === this.activeCellRecordId);
        if (rowIndex > 0) {
          this.activate({ recordId: records[rowIndex - 1]._id }).catch(error => logError(error, 'Failed to activate'));
        } else {
          // todo prev page - for now wrap
          this.activate({ recordId: records[records.length - 1]._id }).catch(error => logError(error, 'Failed to activate'));
        }
        break;
      }

      case NavigationKeyAction.Accept: {
        this.acceptClicked.emit();
      }
    }
  }

  private async activate(a: { column?: number, recordId?: RecordId }) {
    if (this.activeFieldComponent) {
      await this.activeFieldComponent?.deactivate();
      this.refresh(this.activeFieldComponent);
    }

    const findColumn = a.column || this.activeCellColumn;
    const findRecord = a.recordId || this.activeCellRecordId;

    if (this.reportModel.selectionMode.value === SelectionMode.Row) {
      if (findRecord) {
        this.reportModel.selectionModel.select(findRecord);
      }
    } else {
      this.activeFieldComponent = this.fieldComponents.find(comp => comp.column === findColumn && comp.record?._id === findRecord);
      if (this.activeFieldComponent) {
        this.activeCellColumn = findColumn;
        this.activeCellRecordId = findRecord;
        await this.activeFieldComponent.activate();
        this.refresh(this.activeFieldComponent);
      } else {
        this.activeCellRecordId = null;
        this.activeCellColumn = null;
      }
    }
  }

  private async deactivate() {
    if (this.activeFieldComponent) {
      await this.activeFieldComponent.deactivate();
      // todo this is throwing because field component doesn't have CONTEXT set
      this.refresh(this.activeFieldComponent);
      // this.refresh();
    }

    this.activeFieldComponent = null;
    this.activeCellRecordId = null;
    this.activeCellColumn = null;
  }

  public componentClicked(component: FieldBase) {
    this.deactivate();
  }

  private loadColumns() {

    this.heightMayHaveChanged = true;

    if (this.report) {

      const app = this.appModel.app.value;

      const listFields = this.report.ListReportFields.filter(f => !f.Hidden) || [];
      this.fields = listFields.
        map(f => {
          const appField = app.getField(f.FieldIdentifier);
          const fieldInfo = {
            ...f,
            sticky: this.isSticky(f),
            field: appField,
            fieldFilter: new FieldFilters(f, appField, appField?.Type, null, null)
          } as FieldInfo;

          // Convert field width values into pixels
          this.setWidth(fieldInfo);

          return fieldInfo;
        })
        // Sort so sticky columns come first, then into display order
        .sort((a, b) => ((b.sticky ? 1 : 0) - (a.sticky ? 1 : 0)) || (a.DisplayOrder - b.DisplayOrder));

      this.displayedColumns = this.fields.map(f => f.FieldIdentifier);

      this.layoutColumns();

    } else {
      this.fields = [];
      this.displayedColumns = [];
    }

    let total = this.controlColumnWidth;

    this.fields.forEach(field => {
      field.x = total;
      total += field.w;
    });

    this.totalWidth$.next(total);
  }

  private setWidth(fieldInfo: FieldInfo) {
    const def = this.defaultWidth(fieldInfo);

    if (fieldInfo.ColumnWidthMetaData?.WidthSpecifier?.Value) {
      fieldInfo.minWidth = fieldInfo.maxWidth = fieldInfo.ColumnWidthMetaData?.WidthSpecifier?.Value;
      fieldInfo.elasticity = 0;
    } else if (fieldInfo.ColumnWidthMetaData?.WidthSpecifier?.Named) {
      fieldInfo.minWidth = fieldInfo.maxWidth = this.widthSpecifierToPixels(fieldInfo.ColumnWidthMetaData.WidthSpecifier.Named);
      fieldInfo.elasticity = 0;
    } else if (fieldInfo.ColumnWidthMetaData?.WidthParameters) {
      fieldInfo.minWidth = this.widthMetadataToPixels(fieldInfo.ColumnWidthMetaData?.WidthParameters?.Min, def);
      fieldInfo.maxWidth = this.widthMetadataToPixels(fieldInfo.ColumnWidthMetaData?.WidthParameters?.Max);
      if (fieldInfo.maxWidth < fieldInfo.minWidth) {
        fieldInfo.maxWidth = fieldInfo.minWidth;
      }
      fieldInfo.elasticity = fieldInfo.ColumnWidthMetaData?.WidthParameters?.Elasticity || 0;
    } else {
      fieldInfo.minWidth = fieldInfo.maxWidth = this.widthSpecifierToPixels(def);
      fieldInfo.elasticity = 0;
    }
  }

  private layoutColumns() {

    const pixels = this.containerDiv?.nativeElement?.clientWidth;
    if (pixels) {
      let remainder = pixels;
      remainder -= this.controlColumnWidth;

      const widths = new Array<number>(this.fields.length);
      this.fields.forEach((info, index) => {
        widths[index] = info.minWidth;
        info.w = info.minWidth;
        remainder -= info.minWidth;
      });

      if (remainder > 0) {
        // share extra width amongst elastic fields
        const available = remainder;
        const elastic = this.fields.filter(i => i.elasticity > 0);
        const elasticTotal = elastic.reduce((prev, i) => prev += i.elasticity, 0);
        elastic.forEach(info => {
          const share = Math.floor(available * info.elasticity / elasticTotal);
          if (!info.maxWidth || (info.minWidth + share <= info.maxWidth)) {
            info.w += share;
            remainder -= share;
          } else {
            info.w = info.maxWidth;
            remainder -= (info.maxWidth - info.minWidth);
          }
        });
      }

      if (remainder > 0) {
        // some left, need to share out amongst elastic items that have no max
        const available = remainder;
        const elastic = this.fields.filter(i => i.elasticity > 0 && !i.maxWidth);
        const elasticTotal = elastic.reduce((prev, i) => prev += i.elasticity, 0);
        elastic.forEach(info => {
          const share = Math.floor(available * info.elasticity / elasticTotal);
          info.w += share;
          remainder -= share;
        });
      }

      let total = this.controlColumnWidth;
      this.fields.forEach((field, index) => {
        field.x = total;
        total += field.w;
        field.flexOrder = index + 1;
        field.editable = field.field.IsEditable && !field.DisableEdit;
      });

      this.totalWidth$.next(total);
    }
  }

  private layout() {
    this.heightMayHaveChanged = false;
    this.width = this.containerDiv?.nativeElement?.clientWidth;
    this.layoutColumns();
    this.calculateHeight();
  }

  private calculateHeight() {
    const pixels = this.containerDiv?.nativeElement?.clientHeight;
    if (pixels) {
      const oldRows = this.reportModel.pageSize.value;
      const rows = Math.floor(pixels / this.rowHeight) - 1;
      if (rows !== oldRows) {
        this.reportModel.pageSize.value = rows;
        // this.loadData();    // async
      }
    }
  }

  private widthSpecifierToPixels(width: WidthSpecifier): number {
    switch (width) {
      case 'tiny': return 60;
      case 'small': return 100;
      case 'medium':
      case 'default': return 200;
      case 'wide': return 400;
      default: return 0;
    }
  }

  private widthMetadataToPixels(width: WidthSpecifierMetaData, def?: WidthSpecifier): number {
    if (width?.Value) {
      return width.Value;
    } else if (width?.Named) {
      return this.widthSpecifierToPixels(width.Named);
    } else {
      return def && this.widthSpecifierToPixels(def);
    }
  }

  private defaultWidth(fieldInfo?: FieldInfo): WidthSpecifier {
    switch (fieldInfo.field.Type) {
      case Enums.FieldType.GridField:
      case Enums.FieldType.ListField:
      case Enums.FieldType.AttachmentList:
      case Enums.FieldType.Draw:
      case Enums.FieldType.EmbeddedVideo:
      case Enums.FieldType.Gantt:
      case Enums.FieldType.InAppChart:
      case Enums.FieldType.Bit:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.MultiState:
        return 'tiny';
      default:
        return 'default';
    }
  }
}
