import { Component, OnInit } from '@angular/core';
import { ComponentBase } from 'app/softoolsui.module/component-base';
import { FieldBase } from 'app/softoolsui.module/fields';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-value-text-field',
  templateUrl: './value-text-field.component.html',
  styleUrls: ['./value-text-field.component.scss']
})
export class ValueTextFieldComponent extends FieldBase implements OnInit {

  public textStyle: {};

  public displayText$ = new BehaviorSubject('');

  public override ngOnInit(): void {
    super.ngOnInit();
    this.textStyle = { ...this.elementStyles?.['text']?.css ?? {}, ...this.elementStyles?.component?.css ?? {} };
  }

  protected override onValueChanged(value: any) {
    const text = this.fieldModel.formatDisplayValue(value);
    this.displayText$.next(text);
  }

}
