<div mat-dialog-content class="position-relative p-0" style="height: 80%;">
  <ng-container *ngIf="!errorInCapture; else errorInCaptureTemplate">
    <video #video autoplay playsinline class="position-absolute fixed-top w-100 h-100" style="left: 0; object-fit: contain;"></video>
    <canvas #canvas style="width: 0; height: 0; object-fit: contain;"></canvas>
    <ng-container *ngIf="loading">
      <div class="position-absolute fixed-bottom d-flex justify-content-center">
        <p class="st-text-theme-primary font-weight-bold" i18n>Loading...</p>
      </div>
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  <div class="d-flex justify-content-center w-100">
    <button type="button" class="primary" style="background-color: red!important;" mat-dialog-close [disabled]="capturing || loading && !errorInCapture">
      <fa-icon [icon]="['fal', 'times']" [border]="false" size="lg"></fa-icon>
      <span i18n>Cancel</span>
    </button>
    <button
      *ngIf="!errorInCapture"
      type="button"
      class="primary"
      style="background-color: green!important;"
      (click)="capture()"
      [disabled]="capturing || loading"
    >
      <fa-icon [icon]="['fal', 'camera']" [border]="false" size="lg"></fa-icon>
      <span i18n>Capture</span>
    </button>
  </div>
</div>

<ng-template #errorInCaptureTemplate>
  <div class="position-absolute fixed-bottom d-flex justify-content-center">
    <p class="text-danger font-weight-bold m-4" i18n>Cannot capture an image, the browser might not have permission to the camera!</p>
  </div>
</ng-template>
