
<mat-form-field *ngIf="!multiple" class="w-100" [floatLabel]="isEmbedded ? 'never' : 'always'" [appearance]="appearance"> 
  <mat-label *ngIf="!disableLabel">{{labelText}}</mat-label>
  <mat-select   
    [id]="uiIdentifier"
    [name]="uiIdentifier" 
    [disabled]="disabled"
    [required]="required"
    [(ngModel)]="value" 
    (selectionChange)="optionSelected($event)"
    #select="ngModel"
    appFormTouch
    [formRef]=select
    [compareWith]="selectionValueCompare">
      <mat-option *ngIf="hasSelectAnItem" value="">Select an item...</mat-option>
      <mat-option *ngFor="let selectOption of selectOptions" [value]="selectOption.Value">
        {{selectOption.Text}}
      </mat-option>
  </mat-select>
  
  <mat-error *ngIf="select.hasError('required')">
    This field is required
  </mat-error>
</mat-form-field>

<mat-form-field *ngIf="multiple" class="w-100" [floatLabel]="isEmbedded ? 'never' : 'always'" [appearance]="appearance"> 
    <mat-label *ngIf="!disableLabel">{{labelText}}</mat-label>
    <mat-select    
      multiple
      [id]="uiIdentifier"
      [name]="uiIdentifier"
      [disabled]="disabled"
      [required]="required"
      [(ngModel)]="value" 
      (selectionChange)="optionSelected($event)"
      #select="ngModel"
      appFormTouch
      [formRef]=select
      [compareWith]="selectionValueCompare">
        <mat-option *ngIf="hasSelectAnItem" value="">Select an item...</mat-option>
        <mat-option *ngFor="let selectOption of selectOptions" [value]="selectOption.Value">
          {{selectOption.Text}}
        </mat-option>
    </mat-select>
      
    <mat-error *ngIf="select.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  