import { Component, OnInit, Input } from '@angular/core';
import { OnlineStatusService } from '@softools/softools-core';

@Component({
  selector: 'app-online-required',
  templateUrl: './online-required.component.html',
  styleUrls: ['./online-required.component.scss']
})
export class OnlineRequiredComponent {

  @Input() public isOnline = true;

  constructor(
    private onlineStatus: OnlineStatusService
  ) {

    this.isOnline = window.navigator && window.navigator.onLine && this.onlineStatus.isServerReachable;

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
    window.addEventListener('online', () => {
      this.isOnline = this.onlineStatus.isServerReachable;
    });
  }
}
