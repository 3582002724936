import { Injectable } from '@angular/core';
import { ImagesListAssetStorageService, ImageListsStorageService } from '@softools/softools-core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class ImageListAssetService {

  /**
   * Collection of local image blobs used while loading to
   * make sure we only ask for each url once
   */
  private blobPromise = new Map<string, Promise<SafeUrl>>();

  /**
   * Collection of local image blobs.
   */
  private blobs = new Map<string, SafeUrl>();

  public constructor(
    private imagesListAssetStorageService: ImagesListAssetStorageService,
    private imageListsService: ImageListsStorageService,
    private domSanitizer: DomSanitizer,
  ) {
  }

  public getImageUrl(assetUrl: string) {
    return this.blobs.get(assetUrl);
  }

  public async loadImageListBlobsAsync(imageListId: string): Promise<void> {
    const list = await this.imageListsService.getImageListAsync(imageListId);
    if (list?.ImageListOptions) {
      const promises = list.ImageListOptions.map(option => this.getImageUrlAsync(option.ImageListAssetUri));
      await Promise.all(promises);
    }
  }

  public async getImageUrlAsync(assetUrl: string): Promise<SafeUrl> {

    // Don't try to load from storage if no URL provided (e.g. if options are invalid)
    if (!assetUrl) {
      return Promise.resolve(null);
    }

    // If we don't have a promise for this url, create one and start loading
    if (!this.blobPromise.has(assetUrl)) {
      const promise = new Promise<SafeUrl>((resolve, reject) => {
        this.imagesListAssetStorageService.getImageBlobAsync(assetUrl).then(blob => {
          if (blob) {
            const url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            this.blobs.set(assetUrl, url);
            resolve(url);
          } else {
            this.blobs.set(assetUrl, null);
            resolve(null);
          }
        }).catch(error => {
          reject(error);
        });
      });

      this.blobPromise.set(assetUrl, promise);
    }

    return await this.blobPromise.get(assetUrl);
  }

}
