
import { Enums } from '@softools/softools-core';

export const RECORD_VALID = '[RECORD_VALID]';

export const RECORD_ADD_MODE = '[RECORD_ADD_MODE]';

export const _DataSetIdentifier = '_DataSetIdentifier';
export const _DataSetHeader = '_DataSetHeader';

export const VIDEO_VALID = '[VIDEO_VALID]';
export const _VideoUrl = '_VideoUrl';
export const _VideoDescription = '_VideoDescription';

export const NumberValidRegex = /^[-]?[0-9]*(?:\.[0-9]{1,})?$/;
export const NumberKeyPressRegex = /[0-9.-]/;

export const IntegerValidRegex = /^-?[0-9]*$/;
export const IntegerKeyPressRegex = /[0-9-]/;

export const getFieldIsValidFromModel = (model) => {
  if (model) {
    if (!model.dirty) {
      return true;
    }
    return model.valid;
  }
  return true;
};

export function isDateField(fieldType: Enums.FieldType) {
  return fieldType === Enums.FieldType.Date ||
    fieldType === Enums.FieldType.DateTime ||
    fieldType === Enums.FieldType.Period;
}

export function isDecimalField(fieldType: Enums.FieldType) {
  return fieldType === Enums.FieldType.Money;
}

export function isTextualField(fieldType: Enums.FieldType) {
  return fieldType === Enums.FieldType.Email ||
    fieldType === Enums.FieldType.LongText ||
    fieldType === Enums.FieldType.Text ||
    fieldType === Enums.FieldType.UrlField;
}

export function isNumberField(fieldType: Enums.FieldType) {
  return fieldType === Enums.FieldType.Number ||
    fieldType === Enums.FieldType.Integer ||
    fieldType === Enums.FieldType.Money ||
    fieldType === Enums.FieldType.Range ||
    fieldType === Enums.FieldType.Long;
}

export function extractDecimal(fieldValue: any) {
  if (fieldValue?.hasOwnProperty('$numberDecimal')) {
    // For now, we'll process decimals as float. We have the opportunity to handle them
    // in higher precision later by changing this
    return parseFloat(fieldValue['$numberDecimal']);
  } else {
    return fieldValue;
  }
}

export function removeBrackets(identifier: string) {
  return identifier.replace('[', '').replace(']', '');
}
