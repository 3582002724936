
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayVideoComponent } from 'app/softoolsui.module/overlay-video.component/overlay-video.component';
import { FieldsModule } from 'app/softoolsui.module/fields.component/fields.module';
import { ScalarFieldsModule } from '../fields/scalar-fields/scalar-fields.module';

@NgModule({
  declarations: [
    OverlayVideoComponent
  ],
  imports: [
    CommonModule,
    FieldsModule,
    ScalarFieldsModule,
  ],
  exports: [OverlayVideoComponent],
  providers: []
})
export class OverlayVideoModule { }
