import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeComponent } from './badge.component';
import { RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    BadgeComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    RouterModule,
    FontAwesomeModule
  ],
  exports: [
    BadgeComponent
  ],
  providers: [],
})
export class BadgeModule { }
