
<div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center " style="background: #565f69; position: absolute; top: 0;">

  <mat-card class="shadowed thin-top-highlight" style=" max-width: 80rem; max-height: 90%; overflow-y: auto;">
    <ng-container *ngIf="licenseRequired">
      <mat-card-header >
        <mat-card-title-group>
          <mat-card-title>
            <h3 i18n>License Confirmation</h3>
          </mat-card-title>
          <mat-card-subtitle>
            <span i18n>
              Please read and accept the following terms before continuing to use this application.
            </span>
        </mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>

    <mat-card-content class="m-4">
      <h3 *ngIf="licenseTitle">{{licenseTitle}}</h3>
      <h3 *ngIf="!licenseTitle" i18n>Application License</h3>
      <div *ngIf="licenseMessage" style="word-wrap:break-word">
        <textarea readonly class="w-100" style="resize: none; min-height: 10rem;">
          {{licenseMessage}}
        </textarea>
      </div>
      <div class="my-2">
        <mat-checkbox [(ngModel)]="licenseAccepted" i18n>
          I have read and accept the above license information
        </mat-checkbox>
      </div>
    </mat-card-content>
  </ng-container>

    <mat-card-content *ngIf="gdprRequired" class="m-4">
      <h3 *ngIf="gdprTitle">{{gdprTitle}}</h3>
      <h3 *ngIf="!gdprTitle" i18n>General Data Processing Regulations (GDPR)</h3>
      <div *ngIf="gdprMessage" style="word-wrap:break-word;">
        <textarea readonly class="w-100" style="resize: none; min-height: 10rem;">
          {{gdprMessage}}
        </textarea>
      </div>
      <div class="my-2">
        <mat-checkbox [(ngModel)]="gdprAccepted">
          I have read and accept the above GDPR information
        </mat-checkbox>
      </div>
    </mat-card-content>

    <mat-card-actions class="mx-2 mb-2">
      <button mat-raised-button
        color="primary"
        [class.bg-st-theme-primary]="gdprAccepted && licenseAccepted"
        [class.text-white]="gdprAccepted && licenseAccepted"
        [disabled]="continueDisabled()"
        (click)="continueClicked($event)">
        <span i18n>Continue</span>
      </button>
    </mat-card-actions>
  </mat-card>

</div>
