import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { getHomepageUrl } from 'app/_constants/constants.route';
import { BehaviorSubject } from 'rxjs';
import { getCurrentUser, logError } from '@softools/softools-core';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { backButtonAnimation } from 'app/_constants';
import { GlobalModel, HeaderModel, PageModel } from 'app/mvc';
import { ComponentBase } from '..';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { NotificationsModel } from 'app/mvc/common/notifications.model';
import { LaunchpadModel } from 'app/mvc/headers/launchpad.model';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [backButtonAnimation],
})
export class HeaderComponent extends ComponentBase implements OnInit {

  public homepageLinkUrl = getHomepageUrl();

  public readonly globalModel: GlobalModel;

  public readonly pageModel: PageModel;

  public launchpadModel: LaunchpadModel;

  @Input() model: HeaderModel;

  public notificationsModel: NotificationsModel;

  // UI flags
  public showFoldersButton$ = new BehaviorSubject(false);
  public showLaunchButton$ = new BehaviorSubject(false);
  public showHomeButton$ = new BehaviorSubject(false);
  public showNotifcationBell$ = new BehaviorSubject(false);

  constructor(
    public readonly models: GlobalModelService
  ) {
    super();
    this.globalModel = models.globalModel;
    this.pageModel = models.pageModel;
    this.launchpadModel = this.models.launchpadModel;
    this.notificationsModel = models.notificationsModel;
  }

  public ngOnInit(): void {
    this.subscribe(this.model.app.$, (app) => {
      this.showFoldersButton$.next(!app || !app.HideFoldersBar);
      this.showLaunchButton$.next(!app || !app.DisableNavigation);
      this.showHomeButton$.next(!app || !app.DisableNavigation);
      this.showNotifcationBell$.next(!app || !app.Anonymous);
    });
  }

  public onLaunchPadClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.launchpadModel.show.value = true;
  }

  public onDownloadClick($event: { processId: string; message: string }) {
    this.globalModel.downloadFileAsync($event.processId, $event.message).catch(e => logError(e, 'downloadFile'));
  }

  public onClearAllNotificationClick() {
    this.models.notificationsController.clearNotifications().catch(e => logError(e, 'clearNotifications'));
  }

  public onClearNotificationClick(notificationId: string) {
    this.models.notificationsController.removeNotification(notificationId).catch(e => logError(e, 'removeNotification'));
  }

  public onPinClickHandler($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.pageModel.toggleFolderBar();
  }

  public onActionClickHandler(toolbarAction: ToolbarAction) {
    toolbarAction.perform();
  }

  public onGoBackClickHandler(payload: MouseEvent) {
    payload.preventDefault();
    payload.stopPropagation();
    this.model.goBack();
  }
}
