import { combineLatest } from 'rxjs';
import { QueryParams, Report, Field, OdataExpressionType, Enums, SavedFilter, ReportViewService, logError, IFilterTerm } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty } from '@softools/vertex';
import { ReportFilter, FilterSpecification, FieldFilters } from 'app/filters/types';
import { Application } from 'app/types/application';
import { InjectService } from 'app/services/locator.service';
import { AppModel } from './app.model';
import { defaultReportViewParams } from 'app/_constants/constants.reports';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { RouteParams } from '.';
import { FilterTerms } from 'app/filters/types/filter-terms';
import { QuickFilter } from 'app/workspace.module/components/quick-filter/quick-filter';
import { FilterTerm } from 'app/filters/types/filter-term';
import { AppField } from 'app/types/fields/app-field';

/**
 * Model/Controller that tracks the active filter for a report.
 * It also manages UI state for the filter popup - we might want to change that.
 */
export class FilterModel extends Model<FilterModel> {

  public report = new ModelProperty<Report>(this).withLogging('Report');

  public queryParams: QueryParams;

  public readonly reportStaticFilter = new ModelProperty<ReportFilter>(this).withLogging('Static Filter');

  public readonly reportFilter = new ModelProperty<ReportFilter>(this).withLogging('Report Filter');

  public readonly combinedFilter = new ModelProperty<ReportFilter>(this).withLogging('Combined Filter');

  public readonly groupBy = new ModelProperty<string>(this).withLogging('Group By');

  public readonly groupDescending = new BooleanModelProperty(this, false).withLogging('Group Desc');

  public readonly orderBy = new ModelProperty<string>(this).withLogging('Order By');

  public readonly orderDescending = new BooleanModelProperty(this, false).withLogging('Order Desc');

  public readonly isAdvanced = new BooleanModelProperty(this, false).withLogging('Advanced Mode');

  public readonly advancedFilter = new ArrayModelProperty<IFilterTerm>(this).withLogging('Advanced Filter');

  public readonly additionalFilter = new ModelProperty<ReportFilter>(this).withLogging('Additional Filter');

  public get filter(): ReportFilter {
    return this.reportFilter.value;
  }

  public set filter(value: ReportFilter) {
    this.reportFilter.value = value;
  }

  /**
   * Current app saveed filters (legacy mode)
   */
  public readonly savedFilters = new ModelProperty<Array<SavedFilter>>();

  /**
   * Specification of the active filter.
   * This is derived from the
   * filter and updated whenever the filter changes.  When the filter has
   * a simple pattern, it contains the existing simple values for each column
   * and it also contains the values that the user has entered but not applied yet.
   * This also serves as a master list of application fields which can be filtered
   * to produce a list of report fields.
   *   */
  public filterSpec: FilterSpecification;

  public readonly filterSpecification = new ModelProperty<FilterSpecification>();

  @InjectService(ReportViewService)
  private reportViewService: ReportViewService;

  public constructor(public appModel: AppModel, container?: Model<any>) {
    super(container);
  }

  public initialise() {

    // Track saved filters as app changes
    this.subscribe(this.appModel.savedFilters.$, (savedFilters) => {
      this.savedFilters.value = savedFilters || [];

      // If the active filter is a saved filter that is no longer available, reset it
      const reportFilter = this.reportFilter.value;
      if (reportFilter?.Id && !savedFilters.find(f => f.Id === reportFilter.Id)) {
        this.updateCurrentFilter(null).catch(error => logError(error, 'updateCurrentFilter'));
      }
    });

    this.subscribe(combineLatest([
      this.appModel.app.$,
      this.report.$,
      this.reportFilter.$
    ]), ([app, report, reportFilter]) => {

      if (app && report) {

        let groupId: string = null;
        let groupDesc = false;

        this.isAdvanced.value = app.isFilterAdvanced;

        if (app.isFilterAdvanced) {
          // quick mode so group specified by report
          if (report.GroupBy?.FieldIdentifier) {
            groupId = report.GroupBy.FieldIdentifier;
            groupDesc = report.GroupBy.Descending;
          } else if (report.Type === Enums.ReportTypes.Card) {
            groupId = report.CardReport.CardGroupingFieldIdentifier;
            groupDesc = false;
          }
        } else {
          // quick mode so group specified by filter
          if (report.Type === Enums.ReportTypes.Card) {
            groupId = report.CardReport.CardGroupingFieldIdentifier;
            groupDesc = false;
          } else {
            if (reportFilter?.Group) {
              groupId = reportFilter.Group;
              groupDesc = reportFilter.IsGroupDescending;
            } else {
              // Look up default filter for group
              if (report.BaseFilterId) {
                const saved = app.SavedFilters.find(sf => sf.Id === report.BaseFilterId);
                const grouping = this.groupingFromSavedFilter(saved);
                groupId = grouping.groupIdentifier;
                groupDesc = grouping.isDescending;
              }
            }
          }
        }

        this.batch(() => {
          this.groupBy.value = groupId;
          this.groupDescending.value = groupDesc;
        });
      }
    });

    const globalModel = this.appModel.globalModel;
    this.subscribe(
      combineLatest([this.reportFilter.$, this.additionalFilter.$, globalModel.archived.$]),
      ([reportFilter]) => {
        if (reportFilter) {
          this.createInternals();
        }
      });

    //  When we set an advanced filter, update ReportFilter to match
    this.subscribe(this.advancedFilter.$, (advanced) => {
      if (advanced) {
        // Convert to report filter to apply to reports
        const filter = this.convertAdvanced(advanced);
        this.reportFilter.value = filter;
      }
    });
  }

  private convertAdvanced(advanced: Array<IFilterTerm>): ReportFilter {
    if (this.appModel.app.value) {
      const appFields = this.appModel.app.value.AppFields;
      const filterString = advanced
        .filter(term => term.Operator && (term.Operand !== undefined))
        .map(term => new FilterTerm(term).format(appFields))
        .join(' and ');

      const reportFilter = new ReportFilter();
      reportFilter.filterString = filterString;

      // retain current group/sort settings
      reportFilter.Group = this.groupBy.value;
      reportFilter.IsGroupDescending = this.groupDescending.value;
      reportFilter.OrderBy = this.orderBy.value;
      reportFilter.IsOrderDescending = this.orderDescending.value;

      return reportFilter;
    }

    return null;
  }

  /** Configure the filter model from routing parameters derived from the url  */
  public async routed(params: RouteParams) {
    await this.configure(params.reportIdentifier);
  }

  /**
   * Configure the filter model.
   * @param reportIdentifier
   */
  public async configure(reportIdentifier: string) {
    const app = this.appModel.app.value;
    const report = app.Reports.find(rep => rep.Identifier === reportIdentifier);
    this.report.value = report

    await this.configureForReport(app, report);
  }

  private async configureForReport(app: Application, report: Report) {
    if ((app.isFilterAdvanced) && report.Filter) {
      const staticFilter = FilterTerms.combineTerms(app, report.Filter, 'and');
      this.reportStaticFilter.value = staticFilter;
    } else {
      this.reportStaticFilter.value = null;
    }

    const filter = await this.getReportFilter(app);

    if (filter && app.isFilterAdvanced) {

      // Apply default sort order if not specified
      if (!filter.OrderBy && report.Sort?.FieldIdentifier) {
        filter.OrderBy = report.Sort.FieldIdentifier;
        filter.IsOrderDescending = report.Sort.Descending ?? false;
      }

      if (report.GroupBy?.FieldIdentifier) {
        filter.Group = report.GroupBy.FieldIdentifier;
        filter.IsGroupDescending = report.GroupBy.Descending;
      } else if (report.Type === Enums.ReportTypes.Card) {
        filter.Group = report.CardReport.CardGroupingFieldIdentifier;
        filter.IsGroupDescending = false;
      } else {
        filter.Group = '';
      }
    }

    this.setFilter(filter);
  }

  protected async getReportFilter(app: Application): Promise<ReportFilter> {
    const viewFilter = await this.getViewFilter();
    if (viewFilter) {
      return viewFilter;
    }

    // Lookup default saved filter
    if (this.report.value.BaseFilterId) {
      const baseSaved = this.appModel.savedFilters?.value.find(r => r.Id === this.report.value.BaseFilterId);
      if (baseSaved) {
        return ReportFilter.fromSavedFilter(baseSaved);
      }
    }

    return null;
  }

  /** Set the active filter value */
  public setFilter(filter: ReportFilter) {
    this.batch(() => {
      this.reportFilter.value = filter;
      this.orderBy.value = filter?.OrderBy;
      this.orderDescending.value = filter?.IsOrderDescending;
      this.groupBy.value = filter?.Group;
      this.groupDescending.value = filter?.IsGroupDescending;
    });
  }

  public get parsedFilter() {
    return this.filterSpec?.parsedFilter;
  }

  /** Recreate the filter from the simple filter data */
  updateFilter(): void {
    this.filterSpec?.updateFilter();
  }

  update(fieldId: string, expressionType: OdataExpressionType, value: any, sortOrder: string, grouped: boolean): ReportFilter {
    const updated = this.filterSpec?.update(fieldId, expressionType, value, sortOrder, grouped);
    return updated;
  }

  clearClause(fieldId: string): void {
    this.filterSpec?.clearField(fieldId);
  }

  /** Get field filter details for a single field (by id) */
  getFieldFilterSummary(fieldId: string): FieldFilters {
    return this.filterSpec?.terms.find((sf) => sf.identifier === fieldId);
  }

  public getField(identifier: string): AppField {
    const app = this.appModel.app.value;
    return app.getField(identifier);
  }

  public isFieldSortable(fieldId: string) {

    const field = this.appModel.app.value?.getField(fieldId);
    if (field?.Sortable) {
      return true;
    }

    // Fall back to report fields while migrating to SOF-11434 
    if (this.filterSpec?.reportFields?.find(f => f.FieldIdentifier === fieldId)?.Sortable) {
      return true;
    }
    return false;
  }

  isFilterParticipant(field: Field): boolean {
    const fieldSummary = this.getFieldFilterSummary(field.Identifier);
    return fieldSummary?.operator !== null;
  }

  fieldsInCurrentFilter(): Field[] {
    return this.filterSpec?.terms.filter((field) => field.operator !== null).map((f) => f.appField);
  }

  // Controller style methods
  // As this becomes more complex we should split into a real controller class

  /**
   * Update the active legacy filter.  If null is passed, the filter is removed.
   */
  public async updateCurrentFilter(filter: ReportFilter) {

    // If clearing, replace with an empty filter
    if (!filter) {
      filter = new ReportFilter();
    }

    // Store filter in view
    const app = this.appModel.app.value;
    const appId = app.Identifier;
    const reportIdentifier = this.report.value.Identifier;

    const view = await this.reportViewService.getViewDataAsync(appId, reportIdentifier) ?? { ...defaultReportViewParams, reportId: reportIdentifier };
    view.filterId = filter.Id;
    view.filterQuery = filter.Filter;
    view.orderBy = filter.OrderBy;
    view.orderDescending = filter.IsOrderDescending;
    view.groupBy = filter.Group;
    view.groupDescending = filter.IsGroupDescending;
    view.showArchived = filter.IsArchived;
    await this.reportViewService.setViewDataAsync(appId, view);

    // Set as current filter
    this.setFilter(filter);
  }

  public clearAdvancedFilter() {

    const terms = this.advancedFilter.value;
    const cleared = terms.map(term => ({
      ...term,
      Operator: undefined,
      Operand: undefined,
      displayOperand: undefined
    }));

    this.advancedFilter.value = cleared;
    this.updateAdvancedFilter(cleared);
  }

  public async clearAdditionalFilter() {
    this.additionalFilter.value = null;
    const advanced = this.advancedFilter.value;
    await this.updateAdvancedFilter(advanced);
  }

  /** Clear the current filter */
  public async clearFilter() {
    if (this.isAdvanced.value) {
      this.clearAdvancedFilter();
    } else {
      this.updateCurrentFilter(null);
    }
  }

  public async updateAdvancedFilter(advanced: Array<IFilterTerm>) {

    // Store filter in view
    const app = this.appModel.app.value;
    const appId = app.Identifier;
    const reportIdentifier = this.report.value.Identifier;

    const view = await this.reportViewService.getViewDataAsync(appId, reportIdentifier) ?? { ...defaultReportViewParams, reportId: reportIdentifier };
    delete view.filterQuery;

    const populated = advanced.filter(a => a.Operator && a.Operand);

    if (populated?.length > 0) {
      view.advancedFilter = populated;
    } else {
      delete view.advancedFilter;
    }

    const additional = this.additionalFilter.value;
    if (additional?.Filter) {
      view.additionalFilter = additional.Filter;
    } else {
      delete view.additionalFilter;
    }

    view.groupBy = this.groupBy.value;
    view.groupDescending = this.groupDescending.value;
    view.orderBy = this.orderBy.value;
    view.orderDescending = this.orderDescending.value;

    await this.reportViewService.setViewDataAsync(appId, view);
  }

  public updateFilterTerms(updates: FilterTermUpdates) {
    const app = this.appModel.app.value;

    if (!updates.isSort && this.isAdvanced.value) {
      const terms = this.advancedFilter.value;
      const id = updates.fieldId ?? updates.term?.FieldIdentifier;
      const index = terms?.findIndex(t => t.FieldIdentifier === id);
      if (index >= 0) {
        const update = [...terms];
        update[index] = updates.term;
        this.advancedFilter.value = update;
        this.updateAdvancedFilter(update);
      }
    } else {
      // Handle legacy filter or sort in advanced mode which is still part of report filter
      const reportFilter = new ReportFilter(this.filter);
      const spec = new FilterSpecification(reportFilter, app.AppFields);
      const updated = spec.updateTerm(updates);

      // Set as current filter
      this.updateCurrentFilter(updated).catch(error => logError(error, 'Failed to update the current filter'));
    }
  }

  /**
   * 
   * @param fieldId 
   * @param options 
   * @returns 
   */
  public updateAdvancedFilterTerm(fieldId: string,
    options: {
      value?: any,
      row?: number,
      defaultOperator?: OdataExpressionType,
      delete?: boolean
    }): IFilterTerm {

    const terms = this.advancedFilter.value;
    const index = terms?.findIndex(t => t.FieldIdentifier === fieldId);
    if (index >= 0) {

      const filterTerm = new FilterTerm(terms[index]);
      if (options.delete) {
        filterTerm.clear(options.row);
      } else {
        if (filterTerm.Operator === undefined) {
          filterTerm.Operator = options.defaultOperator;
        }
        // todo Bit is weird, handle it explicitly
        if (options.value !== undefined) {
          filterTerm.setOperand(options.value, options.row);
        }
      }

      const update = [...terms];
      update[index] = filterTerm;
      this.advancedFilter.value = update;
      this.updateAdvancedFilter(update);
      return filterTerm;
    }

    return null;
  }

  // End controller methods

  private async getViewFilter() {

    try {
      let filter: ReportFilter = null;

      const app = this.appModel.app.value;
      const report = this.report.value;
      const view = await this.reportViewService.getViewDataAsync(app.Identifier, report.Identifier);
      if (view) {
        if (this.isAdvanced.value) {
          if (view.advancedFilter) {
            filter = this.convertAdvanced(view.advancedFilter);
            filter.OrderBy = view.orderBy;
            filter.IsOrderDescending = view.orderDescending;
            filter.Group = view.groupBy;
            filter.IsGroupDescending = view.groupDescending;
          }

          if (view.additionalFilter) {
            const rf = new ReportFilter();
            rf.filterString = view.additionalFilter;
            this.additionalFilter.value = rf;
          } else {
            this.additionalFilter.value = null;
          }

        } else {
          // Do we have a matching saved filter? (legacy mode only)
          if (view.filterId?.length > 0) {
            const savedFilter = this.appModel.savedFilters.value?.find(f => f.Id === view.filterId);
            if (savedFilter) {
              filter = ReportFilter.fromSavedFilter(savedFilter);
            }
          }

          this.additionalFilter.value = null;
        }

        // If we didn't get a saved filter (legacy) or advanced terms from the view
        // create a filter from the stored query. This represents any adhoc filtering.
        // We will use it directly (legacy) or to initialise the advanced filter
        if (!filter) {
          filter = new ReportFilter();
          filter.Filter = view.filterQuery || '';
          filter.Group = view.groupBy || '';
          filter.IsGroupDescending = view.groupDescending || false;

          // Selecting the sort order is a bit complicated to handle views stored
          // before report.Sort was added, specifically to distinguish between 
          // default filter set by the guard and user selected no sort. See SOF-12164.
          if (view.orderBy) {
            // view specifies a field
            filter.OrderBy = view.orderBy;
          } else if (view.orderBy === '') {
            // view has unset/default order so use report default sort
            filter.OrderBy = report.Sort?.FieldIdentifier ?? '';
          } else {
            // null/undefined - sort has been cleared so leave empty
            filter.OrderBy = '';
          }

          filter.IsOrderDescending = view.orderDescending ?? report.Sort?.Descending ?? false;
        }

        if (this.isAdvanced.value) {
          if (view.advancedFilter) {
            const ids = app.FilterFieldIdentifiers ?? app.AdvancedFilterFieldIdentifiers;
            const af = ids?.map((i) => i.Identifier)
              .map((id) => {
                return view.advancedFilter.find(f => f.FieldIdentifier === id) ?? {
                  FieldIdentifier: id,
                  Operator: undefined,
                  Operand: undefined
                };
              }) ?? [];

            this.advancedFilter.value = af;
          } else {
            this.initialiseAdvanced(app);
          }
        }

        this.filter = filter;
        return filter;
      } else {
        const quickFilter = new QuickFilter(app, report, []);
        this.advancedFilter.value = quickFilter.emptyFilter();
      }

      // Default to blank filter
      filter = new ReportFilter();
      filter.Filter = '';
      filter.Group = '';
      filter.IsGroupDescending = false;

      if (report.Sort) {
        filter.OrderBy = report.Sort.FieldIdentifier;
        filter.IsOrderDescending = report.Sort.Descending;
      } else {
        filter.OrderBy = '';
        filter.IsOrderDescending = false;
      }

      if (app.isFilterAdvanced) {
        if (report.GroupBy?.FieldIdentifier) {
          filter.Group = report.GroupBy.FieldIdentifier;
          filter.IsGroupDescending = report.GroupBy.Descending;
        } else if (report.Type === Enums.ReportTypes.Card) {
          filter.Group = report.CardReport.CardGroupingFieldIdentifier;
          filter.IsGroupDescending = false;
        }
      } else {
        if (report.Type === Enums.ReportTypes.Card) {
          filter.Group = report.CardReport.CardGroupingFieldIdentifier;
          filter.IsGroupDescending = false;
        }       
      }

      this.reportFilter.value = filter;
      return filter;
    } catch (error) {
      logError(error, '');
    }

    return null;
  }

  protected initialiseAdvanced(app: Application) {
    const ids = app.FilterFieldIdentifiers ?? app.AdvancedFilterFieldIdentifiers;
    const af = ids?.map((i) => i.Identifier)
      .map((id) => {
        return {
          FieldIdentifier: id,
          Operator: undefined,
          Operand: undefined
        };
      }) ?? [];

    this.advancedFilter.value = af;
  }

  public updateBaseFilter() {
    // BasefilterId is in report, so the updated report needs to be used.
    // JC 2022-07-29 This looks very weird. Review.
    this.report.value = this.appModel.app.value.Reports.find(r => r.Identifier === this.report.value.Identifier);
    this.createInternals();
  }

  /** Create dependent objects, including combined filter that includes all active filter sources */
  private createInternals() {

    const app = this.appModel.app.value;
    const report = this.report.value;
    if (app && report) {

      // Build filter array to apply
      const filters: Array<ReportFilter> = [];

      if (app.isFilterAdvanced) {
        const staticFilter = this.reportStaticFilter.value;
        if (staticFilter) {
          filters.push(staticFilter);
        }

        const additionalFilter = this.additionalFilter.value;
        if (additionalFilter) {
          filters.push(additionalFilter);
        }
      } else {
        // legacy, apply base filters
        const baseFilter = report.BaseFilter;
        if (baseFilter) {
          const base = new ReportFilter();
          base.Description = '(base)';
          base.filterString = baseFilter;
          filters.push(base);
        }

        if (report.BaseFilterId) {
          const saved = this.savedFilters.value?.find(filt => filt.Id === report.BaseFilterId && filt.AccessLevel === Enums.FilterAccessLevel.Global);
          if (saved) {
            filters.push(ReportFilter.fromSavedFilter(saved));
          }
        }
      }

      // Apply current dynamic filter (legacy & quick modes)
      const current = this.reportFilter.value;
      if (current) {
        filters.push(current);
      }

      // update in batch so we're consistent
      this.batch(() => {
        const merged = ReportFilter.merge(filters);
        merged.IsArchived = this.appModel.globalModel.archived.value;

        this.combinedFilter.value = merged;
        // Set filter - fallback needed for lookup which doesn't set report filter
        const filter = this.filter || this.combinedFilter.value;
        this.filterSpec = new FilterSpecification(filter, app.AppFields, report.ListReportFields);
        this.filterSpecification.value = this.filterSpec;
      });
    }
  }

  /**
   * Get group properties from a saved filter.
   * The Group value should just be a field identifier, with the descending
   * flag specified in IsGroupDescending. However we also allow for grouping to
   * be specified as "field desc" etc. as we have data in that form.
   */
  private groupingFromSavedFilter(saved: SavedFilter): { groupIdentifier: string, isDescending: boolean } {
    if (saved) {
      const parts = saved.Group?.split(' ') ?? [''];
      const groupIdentifier = parts[0];
      const isDescending = parts.length > 1 ? parts[1] === 'desc' : saved.IsGroupDescending;
      return { groupIdentifier, isDescending };
    } else {
      return { groupIdentifier: null, isDescending: false };
    }
  }
}
