import { Failure, IFormatting, MappedPendingUser, MappedUser, Record, stringCompare, ValidationError } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { UsersService } from 'app/services/users.service';
import { RecordPatch } from 'app/workspace.module/types';
import { AppField } from './app-field';

export class PersonAppField extends AppField {

  @InjectService(UsersService)
  private readonly usersService: UsersService;

  public override getInternalRecordValue(record: Record, _listRow?: number) {
    const value = record[this.DataIdentifier];
    if (value) {

      if (value.hasOwnProperty('val')) {
        // already converted
        return value;
      }

      const user = this.usersService.getUser(value);
      if (user) {
        return {
          val: value,
          txt: `${user.FirstName} ${user.LastName}`
        }
      }
    }

    return {
      val: value,
      txt: value
    }
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    const name1 = val1?.txt;
    const name2 = val2?.txt;
    return isDescending ? stringCompare(name2, name1) : stringCompare(name1, name2);
  }

  public override compareInternalValues(val1: any, val2: any, isDescending: boolean): number {
    const name1 = val1?.txt;
    const name2 = val2?.txt;
    return isDescending ? stringCompare(name2, name1) : stringCompare(name1, name2);
  }

  public override updatePatchWithDefault(patch: RecordPatch) {
    // Default value can't be empty string for Default value field
    if (this.DefaultValue) {
      super.updatePatchWithDefault(patch);
    }
  }

  /**
   * Get formatted value for this field from a record if possible, otherwise returns raw value
   * @param record Data record
   * @param listRow Row number for a list field; ignored for scalar fields
   */
  public override getDisplayRecordValue(record: Record, listRow?: number) {
    // No value if no record (probably only happens in test cases)
    if (!record) {
      return undefined;
    }

    const value = record[this.DataIdentifier];

    if (this.DisplayFormatted) {
      if (value && value.hasOwnProperty('fmt')) {
        return value.fmt;
      }

      // Fall back to old format, should be safe to delete
      const formattedName = `${this.Identifier}_Formatted`;
      if (record && record.hasOwnProperty(formattedName)) {
        return record[formattedName];
      }
    }

    if (value && value.hasOwnProperty('txt')) {
      return value.txt;
    }

    // Fall back to old format, should be safe to delete
    // - still used in team, person etc. - should compact
    const textBackingName = `${this.Identifier}_Text`;
    if (record && record.hasOwnProperty(textBackingName)) {
      return record[textBackingName];
    }

    return this.getRawRecordValue(record, listRow);
  }

  public override formatDisplayValue(value: any, formatting?: IFormatting) {

    if (value?.hasOwnProperty('txt')) {
      return value.txt;
    }

    const id = this.simpleValue(value);

    if (id) {
      const user = this.usersService.getUser(id);
      if (user) {
        return `${user.FirstName} ${user.LastName}`
      }
    }

    if (formatting?.Unknown) {
      return id ? $localize`'Unknown'` : $localize`'Unset'`;
    } else {
      return id;
    }
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    super.validate(value, errors, listRow);

    if (value) {
      if (value && value.isInvalidValue) {
        errors.push({ error: ValidationError.NotAUser, fieldId: this.Identifier, listRow });
      } else {
        const user = this.usersService.getUserOrPending(value);
        if (!user) {
          if (!this.isValidIdentifier(value)) {
            errors.push({ error: ValidationError.NotAUser, fieldId: this.Identifier, listRow });
          }
        }
      }
    }
  }

  public override createPatch(record: Record | RecordPatch, value: any, _listRow: number | string, options?: { local?: boolean }): RecordPatch {
    if (value instanceof MappedUser || value instanceof MappedPendingUser) {
      const patch = super.createPatch(record, value.Value, _listRow, options);
      patch.addLocalChange(`${this.DataIdentifier}_Text`, value.Text);
      return patch;
    } else {
      return super.createPatch(record, value, _listRow, options);
    }
  }

  public override informationText(record: any) {
    const id = this.getRawRecordValue(record);
    if (id) {
      const user = this.usersService.getUserOrPending(id);
      if (user) {
        if ('Expiration' in user) {
          return 'This user is not activated';
        } else if (user.IsAccountClosed) {
          return 'This user has been deactivated';
        }
      }
    }
    return null;
  }

  public override defaultIcon() {
    return '.generated';
  }

  /** Is the id a valid user identitfier string (uuid)? */
  public isValidIdentifier(id: string) {
    return !id || /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(id);
  }

  public override isFieldSortable(): boolean {
    // Person fields defer sortability to _Text backing (SOF-12580)
    return false;
  }
}
