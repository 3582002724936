import { RecordId } from '../interfaces';

export enum ValidationError {
  None,
  Required,
  BelowMinimum,
  AboveMaximum,
  InvalidUrl,
  InvalidEmail,
  InvalidInt,
  InvalidLong,
  InvalidFloat,
  NotAUser,
  NotATeam,
  TooLong,
  TooShort,
  RowsRequired,
  ValuesMustMatch,
  InvalidPasswordValue,
  Other,
  InvalidUsernameValue,
  InvalidTime,
  Pattern,

  /** Generic invalid value - use specific type if possible */
  Invalid
}

export interface Failure {
  fieldId: string;
  error: ValidationError;
  param?: any;
  listRow?: number;

  /** Message to display if supplied; otherwise a message base on the error code is shown¬ */
  message?: string;
}

export type ErrorMap = Map<String, Array<Failure>>;
export type ServerErrorMap = Map<RecordId, string>;
