import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FieldFilters } from 'app/filters/types';
import { FilterModel } from 'app/mvc';
import { AlignmentTypeAlias } from '@softools/softools-core';
import { headerAlignment } from '../form.component/form-base.component';

@Component({
  selector: 'app-report-column-header',
  templateUrl: './report-column-header.component.html',
  styleUrls: ['./report-column-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportColumnHeaderComponent implements OnChanges {

  public alignment: AlignmentTypeAlias = null;
  public allowFiltering: boolean = null;

  @Input() public filterModel: FilterModel;

  @Input() public simpleFilter: FieldFilters;

  @Input() public singleLineLabel = true;

  @Output() public headerClicked = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnChanges(_: SimpleChanges): void {
    if (!this.alignment) {
      this.alignment = this.getHeaderAlignment();
    }

    if (!this.allowFiltering) {
      this.allowFiltering = this.isFilteringSupported();
    }
  }

  public isSortable(fieldSummary: FieldFilters): boolean {

    if (!fieldSummary) {
      return false;
    }

    return fieldSummary.field.FieldIdentifier === this.filterModel.orderBy.value;
  }

  /** Get the sort image for a field */
  public sortImage(fieldSummary: FieldFilters): IconName {
    if (this.isSortable(fieldSummary)) {
      if (this.filterModel.orderDescending.value) {
        return 'sort-down';
      } else {
        return 'sort-up';
      }
    }

    return null;
  }

  private getHeaderAlignment(
  ): AlignmentTypeAlias {
    return headerAlignment(this.simpleFilter?.type, this.simpleFilter?.field.Alignment);
  }

  private isFilteringSupported(): boolean {
    // Can't filter from header if it's a complex filter
    // May want to remvoe the first check and add feedback below...
    // return // this.filterControl.filterSpec.isSimple &&

    const app = this.filterModel.appModel.app.value;
    if (app?.isFilterAdvanced) {
      return this.simpleFilter?.appField.isFieldSortable();
    } else {
      return this.simpleFilter?.appField.isFilterable();
    }
  }

  public isFilterParticipant(fieldSummary: FieldFilters): boolean {
    return fieldSummary?.operator !== null;
  }

  public clicked($event: MouseEvent) {
    $event.stopPropagation();
    if (this.allowFiltering) {
      this.headerClicked.emit($event);
    }
  }
}
