import { DetailsField, Record, RecordId, Report, ReportField, stringCompare } from '@softools/softools-core';
import { ProjectionModelPoperty } from '@softools/vertex';
import { AppField } from 'app/types/fields/app-field';
import { BehaviorSubject } from 'rxjs';
import { RecordsReportModel } from './records-report.model';

export const sortListFields = (detailsFields: ReportField[]) => {
  return detailsFields.map((v: ReportField, i) => ({ value: v, index: i }))
    .sort((l, r) => (l.value.DisplayOrder - r.value.DisplayOrder) || (stringCompare(r.value.ShortLabel, l.value.ShortLabel) || (l.index - r.index)))
    .map(x => x.value);
};
export class ListReportModel extends RecordsReportModel {

  public readonly listReportDetailFields =
    new ProjectionModelPoperty<Report, Array<DetailsField>>(this.report, report => report?.ListReportDetailFields || []);

  public readonly showDetailFields =
    new ProjectionModelPoperty<Report, boolean>(this.report, report => report?.ListReportDetailFields?.length > 0);

  public readonly allowMultiSelect =
    new ProjectionModelPoperty<Report, boolean>(this.report, report => report?.AllowMultiSelect || false);

  public readonly listReportFields =
    new ProjectionModelPoperty<Report, Array<ReportField>>(this.report, report => sortListFields(report?.ListReportFields || []));

  /** Key used for auto layout storage */
  public readonly tableKey$ = new BehaviorSubject<string>('');

  protected override observeProperties() {
    super.observeProperties();
    this.subscribe(this.report.$, (report) => this.configureAutolayout(report));
    this.subscribe(this.records.$, (records) => this.mapStartIds(records));
  }

  public override isInlineEditSupported() {
    // supported if app is editable
    return this.appModel.app.value?.capabilities.canEdit;
  }


  protected mapStartIds(records: Array<Record>) {
    const groupStarts = new Set<RecordId>();

    if (records) {
      const app = this.appModel.app.value;
      const groupFieldIdentifier = this.filterModel.groupBy.value;
      if (app && groupFieldIdentifier) {
        const groupField: AppField = app.getField(groupFieldIdentifier.replace('[', '').replace(']', ''));
        if (groupField) {
          let lastGroupValue: any;

          records.forEach((record, index) => {
            const groupValue = groupField.getInternalRecordValue(record);
            if (index === 0 || groupField.compareInternalValues(lastGroupValue, groupValue, true) !== 0) {
              groupStarts.add(record._id);
              lastGroupValue = groupValue;
            }
          });
        }
      }
    }

    if (groupStarts.size > 0) {
      this.groupStartIds.value = groupStarts;
    } else {
      this.groupStartIds?.clear();
    }
  }

  public override toggleAutoLayout() {
    this.autoLayout.toggle();
    this.storeAutoLayout();
  }

  private configureAutolayout(report: Report) {
    if (report && this.appModel?.app.value) {
      this.tableKey$.next(`${this.appModel.app.value.Identifier}-${report.Identifier}`);
    }
  }

}
