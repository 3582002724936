import { OdataExpression } from './odata-expression';
import { OdataExpressionType } from './odata-expression-type';
import { OdataAndExpression } from './odata-binary-expression';
import { IRootQuery } from './root-query';

/** Respresentation of the filter query option */
export class OdataQueryFilterExpression extends OdataExpression {

  expression: OdataExpression;

  constructor(onode: any, root: IRootQuery) {
    super(OdataExpressionType.Filter, root, null);
    if (onode) {
      this.expression = this.walk(onode);
    }
  }

  // Filter is simple if its contained expression is simple
  public override isSimple = () => this.expression.isSimple();

  public override each(callback: (node: OdataExpression) => OdataExpression | boolean | null) {
    let response = callback(this);
    if (!response) {
      response = this.expression.each(callback);
    }
    return response;
  }

  /**
   * Insert a node at the head of a query expression.  The expression is joined to
   * the existing expression tree with an and clause
   * @param newNode
   */
  public insertAtFront(newNode: OdataExpression) {

    if (this.expression) {

      // Create and of old expression and new node, then make current
      const and = new OdataAndExpression(null, this.root, null);
      and.leftExpression = newNode;
      newNode.parent = and;
      newNode.root = this.root;

      and.rightExpression = this.expression;
      this.expression.parent = and;

      this.expression = and;
      and.parent = this;

      // Assign new ids and map the new nodes
      newNode.each(expr => {
        this.root.addExpression(expr);
        return false;
      });

    } else {
      // empty filter so just plug in the new node
      newNode.parent = this;    // adopt the node
      newNode.root = this.root;
      this.expression = newNode;

      // Assign new ids and map the new nodes
      this.expression.each(expr => {
        this.root.addExpression(expr);
        return false;
      });
    }
  }

  public override replace(nodeId: number, newNode: OdataExpression) {
    if (this.expression && this.expression.id === nodeId) {
      this.removeChild(nodeId);
    }

    this.expression = newNode;
    newNode.parent = this;
    this.root.addExpression(newNode);
  }

  public override removeChild(nodeId: number) {
    let removed: OdataExpression = null;
    if (this.expression && this.expression.id === nodeId) {
      removed = this.expression;
      this.expression = null;
      this.root.isValidQuery = false;
    }

    if (removed) {
      // Unlink from node map
      removed.each(node => {
        this.root.removeExpression(node);
        return false;
      });
    }
  }

  public override repair() {
    const reduced = this.expression && this.expression.canReduceTo();
    if (reduced) {
      this.root.removeExpression(this.expression);
      this.expression = reduced;
      reduced.parent = this;
    }
    super.repair();
  }

  /** Get the textual form of the query expression */
  public override expressionText() {
    return this.expression && this.expression.expressionText();
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    // concrete clases should override
    return this.expression.isMatch(values, listRow);
  }
}
