import { Component, OnInit, HostListener, ChangeDetectionStrategy, ApplicationRef } from '@angular/core';
import { logError } from '@softools/softools-core';
import { OfflineService } from './services/offline.service';
import { loadIcons } from './icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { environment } from 'environments/environment';
import { GlobalModel } from './mvc/global.model';
import { TenantService } from './services/tenant.service';
import { ComponentBase } from './softoolsui.module';
import { GlobalModelService } from './mvc/common/global-model.service';
import { debounceTime, timer } from 'rxjs';
import { Router } from '@angular/router';
import { LaunchpadModel } from './mvc/headers/launchpad.model';
import { TickApp } from './vertex';

@Component({
  selector: 'app-workspace',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends ComponentBase implements OnInit {

  public globalModel: GlobalModel;

  public launchpadModel: LaunchpadModel;

  ngOnInit(): void {
    try {

      // Get head child elements (should be exactly 1)
      const heads = document.documentElement.getElementsByTagName('head');
      if (heads?.length > 0) {
        const head = heads[0];

        // Create icon link element pointing to API service
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = `${environment.baseUrl}/Api/Site/${this.tenantService.tenant()}/FavIcon`;
        head.appendChild(link);
      }
    } catch (error) {
      logError(error, '');
    }

    // (<any>rg4js)('saveIfOffline', true);

    // // raygun config
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // Track navigation end
    //     (<any>rg4js)('trackEvent', {
    //       type: 'pageView',
    //       path: event.url
    //     });
    //   }
    // });

    // To set theme colour, do this
    // Unfortunately bootstrap doesn't use its own CSS variables so we'll have to use
    // our own in which case why bother... just have our own var.
    document.documentElement.style.setProperty('--bs-primary', 'green');
    document.documentElement.style.setProperty('--faded', 'palegreen');
    document.documentElement.style.setProperty('--primary-contrast', 'white');
    document.documentElement.style.setProperty('--faded-contrast', 'black');

    this.globalModel.initialise();

    this.models.pageModel.initialise();

    this.models.siteController.initialise();
  }

  public routeActivated($event) {
  }

  @HostListener('window:offline', [])
  onOffline() {
    this.offlineService.setOnlineStatus(false);
  }

  @HostListener('window:online', [])
  onOnline() {
    this.offlineService.setOnlineStatus(true);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(_: FocusEvent): void {
    this.models.notificationsModel.initialise();
  }

  constructor(
    private offlineService: OfflineService,
    library: FaIconLibrary,
    private tenantService: TenantService,
    private models: GlobalModelService,
    appRef: ApplicationRef,
    router: Router
  ) {
    super();
    this.globalModel = models.globalModel;
    this.launchpadModel = models.launchpadModel;
    loadIcons(library);

    // Angular recommend that 16ms is the time CD should take per frame
    // We need to optimize each tick to take less than this.

    router.events.pipe(debounceTime(20)).subscribe(_ => { appRef.tick(); });
    TickApp.pipe(debounceTime(20)).subscribe(_ => {
      appRef.tick();
    });

    // hack to force a tick on mobile, I cant call ng.profiler.appRef.tick();
    setInterval(() => appRef.tick(), 1000);
  }
}
