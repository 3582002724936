import { Component, ViewChild, ChangeDetectionStrategy, Inject, } from '@angular/core';
import { EmbeddedVideoData } from '@softools/softools-core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IEmbeddedVideoConfig extends EmbeddedVideoData {
  add: boolean;
}

@Component({
  selector: 'app-embedded-video-dialog',
  templateUrl: './embedded-video-dialog.component.html',
  styleUrls: ['./embedded-video-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedVideoDialogComponent {

  @ViewChild('videoForm') videoForm;

  public videoData: EmbeddedVideoData;
  public add: boolean;

  constructor(
    public dialogRef: MatDialogRef<EmbeddedVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: IEmbeddedVideoConfig
  ) {
    this.init(config);
  }

  public get returnValue(): EmbeddedVideoData {
    return this.videoData;
  }

  public init(config: IEmbeddedVideoConfig) {
    this.videoData = config;
    this.add = config.add;
  }
}
