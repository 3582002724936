import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ElementStyles, Style } from '@softools/softools-core';
import { AppModel, FilterModel, FolderModel, GlobalModel, PageModel, ReportModel } from 'app/mvc';
import { ReportController } from 'app/mvc/reports/report.controller';
import { ComponentBase } from 'app/softoolsui.module';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filterMgtAnimation, quickFiltersAnimation } from 'app/workspace.module/animations/ws-animations';

@Component({
  selector: 'app-ws-report',
  templateUrl: './ws-report.component.html',
  styleUrls: ['./ws-report.component.scss'],
  animations: [quickFiltersAnimation, filterMgtAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsReportComponent extends ComponentBase implements OnInit, IShowFilterManagement {

  @ViewChild('content', { static: true })
  private contentArea: ElementRef<HTMLElement>;

  public isFilterManagementPanelVisible$ = new BehaviorSubject(false);

  public isQuickFilterVisible$ = new BehaviorSubject(false);

  public openState$ = new BehaviorSubject('inactive');

  public filterManageOpenState$ = new BehaviorSubject('inactive');

  constructor() { super(); }

  @Input() appModel: AppModel;
  @Input() pageModel: PageModel;
  @Input() reportModel: ReportModel;
  @Input() reportController: ReportController;
  @Input() folderModel: FolderModel;
  @Input() filterModel: FilterModel;
  @Input() showAddNewRecord: boolean;
  @Input() createUrl = '';

  public backgroundStyles$ = new BehaviorSubject<ElementStyles>(null);

  private defaultBackgroundStyle: Style = {
    Name: '.default-background',
    Element: 'component',
    BackgroundColour: 'white'
  };

  @Output() public contentScrolled = new EventEmitter<{ x: number, y: number }>();

  public globalModel: GlobalModel;

  ngOnInit(): void {
    this.globalModel = this.appModel.globalModel;

    const reportApp$ = combineLatest([this.appModel.app.$, this.reportModel.report.$]);
    this.subscribe(reportApp$, ([app, report]) => {
      const styles = this.appModel.getNamedStyles({
        target: 'report',
        names: report?.NamedStyles,
        additionalStyles: [this.defaultBackgroundStyle]
      });
      this.backgroundStyles$.next(styles);

      if (app && report) {
        const initiallyShowQuickFilters = (app.isFilterAdvanced) &&
          this.reportController?.quickFiltersPinned.value;
        this.showQuickFilters(initiallyShowQuickFilters);
      }
    });

    this.subscribe(this.isQuickFilterVisible$, (visble) => {
      this.openState$.next(visble ? 'active' : 'inactive');
      setTimeout(() => this.globalModel.layoutChanged(), 400);
    });

    this.subscribe(this.isFilterManagementPanelVisible$, (visble) => {
      this.filterManageOpenState$.next(visble ? 'active' : 'inactive');
      setTimeout(() => this.globalModel.layoutChanged(), 400);
    });
  }

  public onArchivedRecordsBadgeClickHandler() {
    this.appModel.globalModel.archived.value = false;
  }

  public scrolled(_) {
    const x = this.contentArea?.nativeElement?.scrollLeft;
    const y = this.contentArea?.nativeElement?.scrollTop;
    this.contentScrolled.emit({ x, y });
  }


  public contentSize() {
    const element = this.contentArea?.nativeElement;
    if (element) {
      return { width: element.clientWidth, height: element.clientHeight };
    } else {
      return { width: 0, height: 0 };
    }
  }

  public showFilterManagementPanel(show: boolean) {
    this.isFilterManagementPanelVisible$.next(show);
  }

  public toggleFilterManagementPanel() {
    const current = this.isFilterManagementPanelVisible$.value;
    this.showFilterManagementPanel(!current);
  }

  public showQuickFilters(show: boolean) {
    this.isQuickFilterVisible$.next(show);
  }

  public toggleQuickFilters() {
    const current = this.isQuickFilterVisible$.value;
    this.isQuickFilterVisible$.next(!current);
  }

  public filterManagementClosed() {
    this.showFilterManagementPanel(false);
  }

  public quickFilterClosed() {
    this.showQuickFilters(false);
  }

  public quickToFilterManagement() {
    this.showQuickFilters(false);
    // Slight kludge to wait until quick filters is closed
    // Without this the panel appears at the old edge of the window
    // At least use animation events, or preferably  position the panel correctly
    // without waiting
    // Keeping it simple as we may go another way e.g. embedding panel
    setTimeout(() => this.showFilterManagementPanel(true), 250);
  }
}
