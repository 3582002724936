import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlaySpinnerComponent {

}
