<textarea #inp class="w-100 h-100"
  class="form-control form-control-o fa-border textarea-height"
  [ngStyle]="textStyle"
  [rows]="field?.Rows || 1"
  (input)="onInputChanged($event)"
  (change)="onChange($event)"
  (blur)="onBlur($event)"
  (focus)="onFocus($event)"
  (click)="onClick($event)"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
  [cdkTextareaAutosize]="field?.Rows === 0"
></textarea>
