import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Enums, logError } from '@softools/softools-core';
import { MatRadioChange } from '@angular/material/radio';
import { FieldSelectionBase } from '../field-selection-base';

@Component({
  selector: 'app-selection-radio-field',
  templateUrl: './selection-radio-field.component.html',
  styleUrls: ['../selection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionRadioFieldComponent extends FieldSelectionBase<string | number> {

  public selection: string;

  constructor() {
    super();
  }

  protected override onValueChanged(value: string | number): void {
    super.onValueChanged(value);

    const val = this.fieldModel.simpleValue(value);
    if (this.fieldModel.SelectListSubType === Enums.SelectListSubType.Text) {
      this.selection = val;
    } else {
      try {
        this.selection = (+val).toString();
      } catch {
        // if a field has been changed from text to number it might have incorrect values
        this.selection = '0';
      }
    }
  }

  public onChange(event: MatRadioChange) {

    const value = event.value;
    const text = this.getOptionText(value);
    this.input.nativeElement.value = text ?? '';

    this.updateValueAsync(value).catch(e => logError(e, 'Failed to dispatch change'));;
    this.onChanged.emit({ payload: value, fieldIsValid: true });
  }
}
