import { Injectable } from '@angular/core';
import { GlobalModel } from '../global.model';
import { LaunchpadModel } from '../headers/launchpad.model';
import { PageModel } from '../page/page.model';
import { NotificationsController } from './notifications.controller';
import { NotificationsModel } from './notifications.model';
import { SiteController } from './site.controller';
import { SiteModel } from './site.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalModelService {

  public globalModel = new GlobalModel();

  public notificationsModel = new NotificationsModel(this.globalModel);

  public notificationsController = new NotificationsController(this.notificationsModel);

  public siteModel = new SiteModel(this.globalModel);

  public siteController = new SiteController(this.siteModel);

  public pageModel = new PageModel(this.globalModel);

  public readonly launchpadModel = new LaunchpadModel(this.globalModel, this.siteModel);

}
