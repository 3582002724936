import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { ZendeskWebwidgetService } from 'app/zendesk-webwidget/zendesk-webwidget.service';
import { SiteService } from 'app/services/site-service.service';

@Component({
  selector: 'app-floating-support-widget',
  templateUrl: './floatingSupportWidget.component.html',
  styleUrls: ['./floatingSupportWidget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingSupportWidgetComponent implements OnInit {
  @Input() fixed = true;

  public show = false;

  constructor(private ngxZendeskWebwidgetService: ZendeskWebwidgetService, private siteService: SiteService) {}

  ngOnInit(): void {
    this.show = this.siteService.Site?.IncludeSupportWidget;
  }

  public iconClick() {
    this.ngxZendeskWebwidgetService.activate();
  }
}
