import { Injectable } from '@angular/core';
import { SelectListsRepository } from '../repos';
import { SelectList } from '../types';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, SELECT_LISTS_STORE } from '../indexedDb';

@Injectable({ providedIn: 'root' })
export class SelectListsStorageService extends StorageServiceBase<SelectList, string> {

  constructor(
    dbContext: DatabaseContextService<SelectList>,
    public selectListRepository: SelectListsRepository) {
    super(SELECT_LISTS_STORE, dbContext);
  }

  /** Synchronise all select lists */
  public async syncAll(retryPolicy: IRetryPolicy = null): Promise<Array<SelectList>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const selectLists = await policy.execute(() => this.selectListRepository.getAll());
    return await this._syncSelectLists(selectLists);
  }

  /** Synchronise all select lists required for an app from server and into storage */
  public async syncForApp(appIdentifier: string): Promise<Array<SelectList>> {
    const selectLists = await this.selectListRepository.getForApp(appIdentifier);
    return await this._syncSelectLists(selectLists);
  }

  public async storeAsync(list: SelectList): Promise<SelectList> {
    await this.save(list.Identifier, list);
    return list;
  }

  public async removeAsync(id: string): Promise<void> {
    await this.delete(id);
  }

  /** Reload select lists and store */
  public async refreshAsync(): Promise<Array<SelectList>> {
    const lists = await this.selectListRepository.getAll();
    await this.clear();
    for (let i = 0, length = lists.length; i < length; ++i) {
      await this.storeAsync(lists[i]);
    }

    return lists;
  }

  private async _syncSelectLists(selectLists: Array<SelectList>) {
    await this.dbContext.putData(this.store, selectLists, 'Identifier');
    return selectLists;
  }
}
