import * as moment from 'moment';
import { ChartField } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';

export interface PlotPoint {
  id: string;
  xVal: number;
  yVal: number;
  zVal: number;
  label: string;
  colour: string;
  colour2: string;
  colour3: string;
  HoverText: {
    name: string,
    value: string,
  }[];
}

export interface SeriesData {
  x: number;
  y: number;
  z: number;
  color: string;
  marker: {
    radius: number;
    fillColor: {
      radialGradient: {
        cx: number;
        cy: number;
        r: number;
      };
      stops: any[];
    };
  };
  id: string;
  label: string;
  hoverText: {
    name: string;
    value: string;
  }[];
}

export interface MonteCarloChartField extends ChartField {
  BestCaseFieldIdentifier?: string;
  BestCaseField?: AppField;
  ExpectedCaseFieldIdentifier?: string;
  ExpectedCaseField?: AppField;
  WorstCaseFieldIdentifier?: string;
  WorstCaseField?: AppField;
}
export interface MonteCarloSeries {
  Series: MonteCarloChartField;
  RunningTotal: number;
  Totals: Array<number>;
  BellValues: Array<{ key: number, value: number }>;
  CurveValues: Array<{ key: number, value: number }>;
  BestValues: Array<number>;
  ExpectedValues: Array<number>;
  WorstValues: Array<number>;
}

export interface MatrixTableData {
  IsCumulative: boolean;
  Rows: Array<any>;
  Columns: {
    Value: string
  }[];
}

export interface PieData {
  value: string;
  recordCount: number;
  filters: {
    [key: string]: string
  };
  colourIndex: number;
  childLevel?: PieLevel;
  recordValue?: number;
}

export interface PieLevel {
  chartField: ChartField;
  chartDataValue: PieData[];
  data?: any;
  numberOfChildren?: number;
}

export class OrderedPointDataValues {
  Actuals: Array<any>;
  Targets: Array<any>;
  Categories: Array<string>;
  EarliestDate: moment.Moment;
  constructor() {
    this.Actuals = new Array<any>();
    this.Targets = new Array<any>();
    this.Categories = new Array<string>();
    this.EarliestDate = moment().year(9999);
  }
}
