
import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { SelectList, QueryParams, QueryParameters } from '../types';
import { HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectListsRepository extends RepositoryBase {

  public getAll(): Promise<Array<SelectList>> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('SelectLists')).toPromise();
  }

  public getForApp(appIdentifier: string): Promise<Array<SelectList>> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('SelectLists/GetForApp', appIdentifier)).toPromise();
  }

  public getSelectListsAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<SelectList>> {
    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveDefaultApiUrl('SelectLists');
    url = this.urlResolver.appendQueryParams(url, params);

    return lastValueFrom<Array<SelectList>>(this.get(url));
  }


  /** Get a single team by id */
  public getSelectListAsync(id: string): Promise<SelectList> {
    const url = this.urlResolver.resolveDefaultApiUrl('SelectLists', id);
    return lastValueFrom<SelectList>(this.get(url));
  }

  /**
   * get count of select lists optionally filtered by query params
   */
  public async getCountAsync(queryParams?: QueryParams): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('SelectLists', '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }


  /**
   * Create a new select list
   * @param list Initial select list data
   * @returns created select list
   */
  public async createAsync(list: SelectList): Promise<SelectList> {
    const url = this.urlResolver.resolveDefaultApiUrl('SelectLists');
    const id = await lastValueFrom<string>(this.postWithType(url, 'text', list));
    if (id) {
      return this.getSelectListAsync(id);
    } else {
      return null;
    }
  }


  /** Save a change to the select list  */
  public save(id: string, changes: any) {
    const url = this.urlResolver.resolveDefaultApiUrl('SelectLists', id);
    return this.patch(url, JSON.stringify(changes));
  }

  /** Delete a single select list by id */
  public async deleteSelectListAsync(id: string): Promise<HttpErrorResponse | string> {
    const url = this.urlResolver.resolveDefaultApiUrl('SelectLists', id);
    const result = await this.delete(url).toPromise();
    return (result instanceof HttpErrorResponse) ? result : id;
  }

  /** Delete multiple teams by id */
  public async deleteSelectListsAsync(ids: Array<string>): Promise<Array<HttpErrorResponse | string>> {
    if (ids) {
      const results: Array<Promise<HttpErrorResponse | string>> = [];
      for (let i = 0, length = ids.length; i < length; ++i) {
        results.push(this.deleteSelectListAsync(ids[i]));
      }
      return Promise.all(results);
    }

    return null;
  }


}
