import App from './export-summaries.config.json';

import { Injectable } from '@angular/core';
import { Record, RecordId, ExportSummary, ExportSummariesRepository } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '../embedded-application';
import { EpochConverter } from 'app/types/epoch-converter';
import { RecordPatch } from 'app/workspace.module/types';

import { AppCapabilities, ToolbarContext } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { PermissionsService } from 'app/services/permissions.service';
import { RecordModel, ReportModel } from 'app/mvc';
import { CallbackToolbarAction, ToolbarAction } from 'app/softoolscore.module/types/classes/toolbaraction.class';

@Injectable({ providedIn: 'root' })
export class ExportSummariesApplication extends OnlineEmbeddedApp<ExportSummary> {

  constructor(
    private repository: ExportSummariesRepository,
    private permissionsService: PermissionsService,
  ) {
    super(App.App);
  }

  public toRecord(summary: ExportSummary): Record {
    return summary && {
      _id: summary.Id,
      Id: summary.Id,
      AppIdentifier: this.Identifier,
      App: summary.AppIdentifier,
      Filename: summary.Filename,
      Started: EpochConverter.toEpoch(summary.Started),
      Finished: EpochConverter.toEpoch(summary.Finished),
      Error: summary.Error,
      Status: summary.Status,
      TaskId: summary.TaskId,
      Uri: summary.Uri,
      Downloaded: summary.Downloaded,
      Tenant: summary.Tenant,
      Username: summary.Username,
      Type: summary.Type,
      Downloads: summary.Downloads &&
        summary.Downloads.map(download => ({ ...download, DownloadDate: EpochConverter.toEpoch(download.DownloadDate) })),
      Hierarchy: '',
      EditableAccessForUser: false,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',
      IsArchived: false,
    } as Record;
  }

  public convert(record: Record): ExportSummary {
    const values = record as any;
    const summary: ExportSummary = {
      Id: record._id,
      AppIdentifier: values.App,
      Filename: values.Filename,
      Started: EpochConverter.toDateString(values.Started),
      Finished: EpochConverter.toDateString(values.Finished),
      Error: values.Error,
      Status: values.Status,
      TaskId: values.TaskId,
      Uri: values.Uri,
      Downloaded: values.Downloaded,
      Tenant: values.Tenant,
      Username: values.Username,
      Type: values.Type,
      Downloads: values.Downloads.map(download => ({ ...download, DownloadDate: EpochConverter.toDateString(download.DownloadDate) })),
    };

    return summary;
  }

  public override async totalCount(): Promise<number> {
    return this.repository.getCountAsync();
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async getRecordByIdAsync(id: RecordId): Promise<Record> {
    const summary = await this.repository.getExportSummaryAsync(id);

    const downloads = await this.repository.getDownloadsAsync(id);
    summary.Downloads = downloads;

    return summary && this.toRecord(summary);
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const summaries = await this.repository.getErrorSummariesAsync(selection.count, selection.start, queryParams);
    return summaries.map(summary => this.toRecord(summary));
  }

  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const summaries = await this.repository.getErrorSummariesAsync(1, +cursor - 1, queryParams);
    return summaries.map(summary => this.toRecord(summary)).pop();
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: false,
      canEdit: true,
      canView: true,
      canSort: true,
      canGroup: false,  // server API currently doesn't support grouping.
    };
  }

  public override get permissions() {
    return this.permissionsService.exportSummaryPermissions;
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new CallbackToolbarAction('Refresh', 'sync', async () => {
      await recordModel.reload();
    }));
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new CallbackToolbarAction('Refresh', 'sync', () => {
      reportModel.reload();
    }));
  }
}
