import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy } from '@angular/core';
import { Field } from '@softools/softools-core';
import { SelectListOption } from '@softools/softools-core';
import { TranslationsService } from 'app/services/translations.service';


export interface ILanguage {
  Code: string;
  Name: string;
}
export interface ISelectOptions {
  notificationOptions: SelectListOption[];
  languageOptions: SelectListOption[];
  languageList: Array<ILanguage>;
}

@Component({
  selector: 'app-select-options',
  templateUrl: './select-options.component.html',
  styleUrls: ['./select-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOptionsComponent implements OnInit {

  @Input() public appFields: Array<Field>;

  @ViewChild('notificationOptions', { static: true }) notificationOptions;
  @ViewChild('languageOptions', { static: true }) languageOptions;

  constructor(private translationService: TranslationsService) { }

  ngOnInit() {
    this.translationService.setSelectOptionsTranslations(this);
  }

  public getNotificationOptions(): Array<SelectListOption> {

    const options: Array<SelectListOption> = [];

    if (this.notificationOptions) {
      for (let i = 0; i < this.notificationOptions.nativeElement.children.length; ++i) {
        const el = this.notificationOptions.nativeElement.children.item(i);
        const opt = { Id: `${i}`, Text: el.innerText, Value: el.value, DisplayOrder: i };
        options.push(opt);
      }
    }

    return options;
  }

  public getLanguageOptions(): Array<SelectListOption> {

    const options: Array<SelectListOption> = [];

    if (this.languageOptions) {
      for (let i = 0; i < this.languageOptions.nativeElement.children.length; ++i) {
        const el = this.languageOptions.nativeElement.children.item(i);
        const opt = { Id: `${i}`, Text: `${el.innerText} [${el.value}]`, Value: el.value, DisplayOrder: i };
        options.push(opt);
      }
    }

    return options;
  }


  public getLanguageList(): Array<ILanguage> {
    const options: Array<ILanguage> = [];
    if (this.languageOptions) {
      for (let i = 0; i < this.languageOptions.nativeElement.children.length; ++i) {
        const el = this.languageOptions.nativeElement.children.item(i);
        const opt = { Code: el.value, Name: `${el.innerText}` };
        options.push(opt);
      }
    }

    return options;
  }

}
