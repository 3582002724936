import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { RecordQueueService } from 'app/services/record/record-queue.service';

@Injectable({ providedIn: 'root' })
export class PatchQueueCacheGuard implements CanActivate {

  constructor(private queue: RecordQueueService) {
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    await this.queue.cachePatches();
    return true;
  }
}
