
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { GlobalHelperService, Enums } from '@softools/softools-core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnInit {

  public Enums = Enums as typeof Enums;
  public checkBoxId;

  @Output() onchecked = new EventEmitter();
  @Output() onunchecked = new EventEmitter();

  @Input() checked;
  @Input() disabled;
  @Input() text;
  @Input() value;
  @Input() checkboxType: Enums.CheckboxType;

  constructor(private globalHelperService: GlobalHelperService) { }

  public ngOnInit() {
    this.checkBoxId = this.globalHelperService.makeRandomTextId(5);
    this.value = this.value || '';
    this.checkboxType = this.checkboxType || Enums.CheckboxType.Default;
  }

  public checkedClick($event: MouseEvent) {
    $event.stopPropagation();

    if (!this.disabled) {
      this.checked = !this.checked;

      if (this.checked) {
        this.onchecked.emit($event);
      } else {
        this.onunchecked.emit($event);
      }
    }
  }
}
