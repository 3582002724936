import { ExportOptions, initialExportOptions } from './exportoptions.interface';
import { SecurityOptions, initialSecurityOptions } from './securityoptions.interface';
import { LookupOptions, initialLookupOptions } from './lookupoptions.interface';
import { FileUploaderSoftoolsOptions, initialFileUploaderSoftoolsOptions } from './fileuploadersoftoolsoptions.interface';

export interface ModalComponentContentOptions {
  FileUploaderOptions: FileUploaderSoftoolsOptions;
  ExportOptions: ExportOptions;
  SecurityOptions: SecurityOptions;
  LookupOptions: LookupOptions;
}

export const initialModalComponentContentOptions: ModalComponentContentOptions = {
  FileUploaderOptions: initialFileUploaderSoftoolsOptions,
  ExportOptions: initialExportOptions,
  SecurityOptions: initialSecurityOptions,
  LookupOptions: initialLookupOptions
};
