import { Injectable } from '@angular/core';
import { AppIdentifier } from 'app/types/application';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { RepositoryBase } from '../repos/repositories.base';
import { RecordId } from '../types';
import { getCurrentUser } from './repositories.global';

const _toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

@Injectable({ providedIn: 'root' })
export class DocumentsRepository extends RepositoryBase {

  public async upload(appIdentifier: string, recordId: string, file: File) {

    // get the SAS token
    const token = await this.getSasToken();

    if (token.Id && token.UploadTokenUrl) {
      // upload the file to azure storage
      // cors is an issue but is a small security issue, this token only lasts for a few minutes
      await lastValueFrom(this.http.put(token.UploadTokenUrl, file, { headers: { 'x-ms-blob-type': 'BlockBlob' } }));

      const url = `${environment.baseUrl}/api/app/documents`;
      const body = {
        fileId: token.Id,
        filename: file.name,
        appIdentifier: appIdentifier,
        recordId: recordId,
        mimeType: file.type?.length > 0 ? file.type : "application/octet-stream",
      };

      return lastValueFrom(this.postWithType(url, 'json', body));
    }

    throw new Error('Unable to get SAS token');
  }

  /**
   * Get url for downloading a document file.  It is identified by the record and field it's stored against
   * so record security can be used to control access to the file.
   */
  public downloadUrl(appIdentifier: AppIdentifier, fieldIdentifier: string, recordIdentifier: RecordId) {
    const tenant = getCurrentUser().Tenant;
    const url = `${environment.baseUrl}/api/app/documents/${tenant}/${appIdentifier}/${fieldIdentifier}/${recordIdentifier}`;
    return url;
  }

  private async getSasToken(): Promise<{ Id: string, UploadTokenUrl: string }> {
    return lastValueFrom(this.get(`${environment.baseUrl}/api/app/documents/sas`));
  }
}
