import { QueryParameters, QueryParams } from '../types';
import { RepositoryBase } from './repositories.base';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UrlResolver } from '../utils/utils.urlresolver';
import { MulticastHttpService } from './multicast-http.service';
import { lastValueFrom } from 'rxjs';

/**
 * Base class for repositories that use a standard API set
 */
export abstract class StandardRepository<T> extends RepositoryBase {
  public abstract controller: string;

  constructor(urlResolver: UrlResolver, http: HttpClient, multicastHttpService: MulticastHttpService) {
    super(urlResolver, http, multicastHttpService);
  }

  protected getItemsAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<T>> {
    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveDefaultApiUrl(this.controller);
    url = this.urlResolver.appendQueryParams(url, params);
    return lastValueFrom<Array<T>>(this.get(url));
  }

  /** Get a single item by id */
  protected getItemAsync(id: string): Promise<T> {
    const url = this.urlResolver.resolveDefaultApiUrl(this.controller, id);
    return lastValueFrom<T>(this.get(url));
  }

  /** Get count of items optionally filtered by query params */
  public async getCountAsync(queryParams?: QueryParams): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl(this.controller, '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }

  /** Delete a single item by id */
  public async deleteAsync(id: string): Promise<HttpErrorResponse | string> {
    const url = this.urlResolver.resolveDefaultApiUrl(this.controller, id);
    const result = await this.delete(url).toPromise();
    return result instanceof HttpErrorResponse ? result : id;
  }

  protected resolveUrl(suffix?: string) {
    return this.urlResolver.resolveDefaultApiUrl(this.controller, suffix);
  }
}
