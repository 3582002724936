import { Injectable } from '@angular/core';

const PREFIX = 'autolayout-hidden';

@Injectable({
  providedIn: 'root'
})
export class AutolayoutStorageService {

  constructor() { }

  public store(key: string, hiddenCols: Array<string>) {
    localStorage.setItem(`${PREFIX}-${key}`, JSON.stringify([...hiddenCols]));
  }

  public getHiddenColumns(key: string): Array<string> {
    return JSON.parse(localStorage.getItem(`${PREFIX}-${key}`));
  }
}
