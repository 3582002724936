import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsBottomSheetComponent } from './actions-bottom-sheet.component';
import { ActionListModule } from '../action-list/action-list.module';

@NgModule({
  imports: [
    CommonModule,
    ActionListModule
  ],
  declarations: [ActionsBottomSheetComponent],
  exports: [ActionsBottomSheetComponent]
})
export class ActionsBottomSheetModule { }
