<div class="container-fluid text-center ps-0 pe-0 h-100 w-100">

  <app-pagination class="w-100 h-100"
    [isBusy]="isBusy"
    [singleRecord]="false"
    [skip]="skip"
    [top]="top"
    [count]="count"
    [fixedPageSize]="fixedPageSize"
    (firstRecordClicked)="onFirstClicked()"
    (lasttRecordClicked)="onLastClicked()"
    (prevClicked)="onPrevClicked()"
    (nextClicked)="onNextClicked()"
    (onChangeTopSize)="pageSizeChanged($event)"
  >
  </app-pagination>
</div>
