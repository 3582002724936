import { Injectable } from '@angular/core';
import {
  STORE_REPORT_VIEWS, STORE_DATA,
  INDEX_APPIDENTIFIER,
  STORE_CHART_DATA,
  STORE_IN_APP_CHART_DATA,
  STORE_APPS,
  REQUIRED_DB_VERSION,
  PATCH_QUEUE_STORE,
  APPDATA_HIERARCHY_INDEX,
  SEARCH_TERM_STORE,
  LAST_LIST_STORE,
  UPLOAD_IMAGES_STORE,
  USERS_STORE,
  TEAM_USERS_STORE,
  IMAGE_LIST_ASSET_STORE,
  DB_NAME,
  DATA_METRICS_STORE,
  SELECT_LISTS_STORE,
  IMAGE_LISTS__STORE,
  HOMEPAGES_STORE,
  SITE_GLOBAL_STORE,
  SITE_SETTINGS_STORE,
  TEAMS_STORE,
  RECORD_DATA_STORE,
  REPORT_DATA_STORE,
  HOMEPAGE_DASH_REPORTS,
  HOMEPAGE_DASH_FIELDS,
  PENDING_SAVE_IMAGES,
  PENDING_DELETE_IMAGES,
  BACKGROUND_PROCESSES,
  REPORT_SELECTION_STATE,
  NOTIFICATIONS_STORE,
  REPORT_CACHE_STORE,
  CHANGE_QUEUE_STORE,
  LOCAL_VALUE_STORE,
  WORKFLOW_TRIGGER_QUEUE,
  PENDING_USER_STORE,
  CONFIG_UPGRADE_STORE,
  CHANGED_RECORDS_STORE,
  WORKFLOW_RUNNER_QUEUE,
  CHANGED_RECORDS_APP_INDEX
} from './database-stores';
import { logError } from '../utils/log-error';

export class DatabaseError extends Error {

  constructor(dbMessage: string) {
    super();
    this.name = 'Database Error';
    this.message = `Error creating indexedDb ${DB_NAME}: ${dbMessage}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class DatabaseService {

  /** Get store for this app's data (creating if needed)` */
  public async getStoreAsync(): Promise<IDBDatabase> {

    return new Promise<IDBDatabase>((resolve, reject) => {

      // Open the database.
      // Set the DB version when adding a new object store or an new index on a store.
      const request = window.indexedDB.open(DB_NAME, REQUIRED_DB_VERSION);

      request.onblocked = () => {
        // If some other tab is loaded with the database, then it needs to be closed
        // before we can proceed.
        alert('Please close all other tabs with this site open!');
      };

      request.onerror = (error) => {
        logError(error, 'getStoreAsync onerror');
        reject(new DatabaseError(request.error.message));
      };

      // Success will get called when the version has not changed.
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };

      /**
       * When you create a new database or increase the version number of an existing database
       * (by specifying a higher version number than you did previously, when Opening a database),
       * the onupgradeneeded event will be triggered and an IDBVersionChangeEvent object will be
       * passed to any onversionchange event handler set up on request.result (i.e., db in the example).
       * In the handler for the upgradeneeded event, you should create the object stores needed for this
       * version of the database:
       * */
      request.onupgradeneeded = (event: any) => {

        const upgradeEvent: IDBVersionChangeEvent = event;

        // Special case when upgarding to DB version 13 or above from
        // 12 or below - remove the Softools-Db store as we wll continue
        // using Softools store.

        // Special case for upgrading from the localforage version of the store
        // At this point we're switching store back to Softools so delete the
        // Softools-Db store which is now redundant
        if (upgradeEvent.oldVersion < 100) {
          this.removeOldDatabase().catch(error => logError(error, 'Failed to remove old db'));
        }

        const db: IDBDatabase = event.target.result;

        // Create an object stores in the database
        // **** IF YOU ADD A NEW STORE INCREMENT THE VERSION (REQUIRED_DB_VERSION)  ****

        this.setupV1Store(db, event);
        this.setupV2Store(db, event);
        this.setupV3store(db, event);
        this.setupV4store(db, event);
        this.setupV5store(db, event);
        this.setupV6store(db, event);
        this.setupV7store(db, event);
        this.setupV8store(db, event);
        this.setupV9store(db, event);
        this.setupV10store(db, event);
        this.setupV11store(db, event);
        this.setupV12store(db, event);
        this.setupV101store(db, event);
        this.setupV102store(db, event);
        this.setupV103store(db, event);
        this.setupV104store(db, event);
        this.setupV105store(db, event);
        this.setupV106store(db, event);
        this.setupV107store(db, event);
        this.setupV108store(db, event);

        // **** IF YOU ADD A NEW STORE INCREMENT THE VERSION (REQUIRED_DB_VERSION)  ****

        const transaction = event.target.transaction;

        transaction.oncomplete = () => {
          resolve(db);
        };

      };

    });
  }

  private removeOldDatabase() {

    const promise = new Promise<IDBDatabase>((resolve, reject) => {

      const request = indexedDB.deleteDatabase('Softools-Db');

      request.onblocked = () => {
        const err = new Error('blocked');
        logError(err, '');
        reject(err);
      };

      request.onerror = (error) => {
        logError(error, '');
        reject(new Error(`Error deleting indexedDb Softools-Db: ${request.error.message}`));
      };

      // Success will get called when the version has not changed.
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
    });

    return promise;
  }

  /**
   * Setup the database stores and indexes for v1
   *
   * @param db
   * @param event
   */
  private setupV1Store(db: IDBDatabase, event: any) {

    // Report view object store
    const reportViewStore = this.getOrCreateObjectStore(
      db,
      event.currentTarget.transaction,
      STORE_REPORT_VIEWS);

    this.tryCreateAppIdentifierIndex(reportViewStore);

    // Data object store
    const dataStore = this.getOrCreateObjectStore(db, event.currentTarget.transaction, STORE_DATA);
    // Indexes can be created on any upgrade.

    this.tryCreateAppIdentifierIndex(dataStore);
  }

  private setupV2Store(db: IDBDatabase, event: any) {

    // Chart Data Store
    const chartDataStore = this.getOrCreateObjectStore(db, event.currentTarget.transaction, STORE_CHART_DATA);

    this.tryCreateAppIdentifierIndex(chartDataStore);

    // In App Chart Data Store
    const inAppChartDataStore = this.getOrCreateObjectStore(db, event.currentTarget.transaction, STORE_IN_APP_CHART_DATA);

    this.tryCreateAppIdentifierIndex(inAppChartDataStore);
  }

  private setupV3store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, STORE_APPS);
  }

  private setupV4store(db: IDBDatabase, event: any) {
    const dataStore = this.getOrCreateObjectStore(db, event.currentTarget.transaction, PATCH_QUEUE_STORE);

    // Create index on appIdentifier - note lower case initial
    if (!dataStore.indexNames.contains(INDEX_APPIDENTIFIER)) {
      dataStore.createIndex(INDEX_APPIDENTIFIER, 'appIdentifier');
    }
  }

  private setupV5store(db: IDBDatabase, event: any) {

    const dataStore = this.getOrCreateObjectStore(db, event.currentTarget.transaction, STORE_DATA);

    // Create index on AppIdentifier then Hierarchy
    if (!dataStore.indexNames.contains(APPDATA_HIERARCHY_INDEX)) {
      dataStore.createIndex(APPDATA_HIERARCHY_INDEX, ['AppIdentifier', 'Hierarchy']);
    }
  }

  private setupV6store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, SEARCH_TERM_STORE);
  }

  private setupV7store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, LAST_LIST_STORE);
  }

  private setupV8store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, UPLOAD_IMAGES_STORE);
  }

  private setupV9store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, USERS_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, TEAM_USERS_STORE);
  }

  private setupV10store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, IMAGE_LIST_ASSET_STORE);
  }

  private setupV11store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, DATA_METRICS_STORE);
  }


  private setupV12store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, SELECT_LISTS_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, IMAGE_LISTS__STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, HOMEPAGES_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, SITE_GLOBAL_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, SITE_SETTINGS_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, TEAMS_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, RECORD_DATA_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, REPORT_DATA_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, HOMEPAGE_DASH_REPORTS);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, HOMEPAGE_DASH_FIELDS);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, PENDING_SAVE_IMAGES);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, BACKGROUND_PROCESSES);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, REPORT_SELECTION_STATE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, NOTIFICATIONS_STORE);
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, REPORT_CACHE_STORE);

    // clean up forage debris
    if (db.objectStoreNames.contains('local-forage-detect-blob-support')) {
      db.deleteObjectStore('local-forage-detect-blob-support');
    }
  }

  private setupV101store(db: IDBDatabase, event: any) {
    this.createAutoIncremenentObjectStore(db, event.currentTarget.transaction, CHANGE_QUEUE_STORE);

    if (db.objectStoreNames.contains(PENDING_DELETE_IMAGES)) {
      db.deleteObjectStore(PENDING_DELETE_IMAGES);
    }
  }

  private setupV102store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, LOCAL_VALUE_STORE);
  }

  private setupV103store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, WORKFLOW_TRIGGER_QUEUE);
  }

  private setupV104store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, PENDING_USER_STORE);
  }

  private setupV105store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, CONFIG_UPGRADE_STORE);
  }

  private setupV106store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, CHANGED_RECORDS_STORE);
  }

  private setupV107store(db: IDBDatabase, event: any) {
    this.getOrCreateObjectStore(db, event.currentTarget.transaction, WORKFLOW_RUNNER_QUEUE);
  }

  private setupV108store(db: IDBDatabase, event: any) {
    const dataStore = this.getOrCreateObjectStore(db, event.currentTarget.transaction, CHANGED_RECORDS_STORE);

    // Create index on AppIdentifier then Hierarchy
    if (!dataStore.indexNames.contains(CHANGED_RECORDS_APP_INDEX)) {
      dataStore.createIndex(CHANGED_RECORDS_APP_INDEX, ['AppIdentifier']);
    }
  }

  private getOrCreateObjectStore(db: IDBDatabase, currentTransaction: IDBTransaction, name: string) {
    if (!db.objectStoreNames.contains(name)) {
      return db.createObjectStore(name);
    } else {
      return currentTransaction.objectStore(name);
    }
  }

  private createAutoIncremenentObjectStore(db: IDBDatabase, currentTransaction: IDBTransaction, name: string) {
    if (!db.objectStoreNames.contains(name)) {
      return db.createObjectStore(name, { autoIncrement: true });
    }

    return null;
  }


  private tryCreateAppIdentifierIndex(store: IDBObjectStore) {
    if (!store.indexNames.contains(INDEX_APPIDENTIFIER)) {
      store.createIndex(INDEX_APPIDENTIFIER, INDEX_APPIDENTIFIER);
    }
  }
}
