import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseIconComponent } from '../iconbase';

@Component({
  selector: 'sof-filter-icon',
  templateUrl: '../icon.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent extends BaseIconComponent {
  constructor() {
    super('filter');
  }
}
