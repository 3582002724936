export module Enums {

  export enum ReportTypes {
    List = 1,
    Matrix = 2,
    Chart = 3,
    Dashboard = 5,
    Table = 6,
    Card = 7,
  }

  export enum FieldType {
    Literal = 0,
    Lookup = 1,
    Notes = 3,
    Text = 4,
    Email = 6,
    LongText = 8,
    Date = 9,
    Selection = 10,
    Person = 11,
    Money = 12,
    Range = 13,
    Number = 14,
    Integer = 15,
    Long = 16,
    Bit = 17,
    MultiState = 18,
    ListField = 20,
    GridField = 21,
    UrlField = 23,
    Gantt = 24,
    InAppChart = 25,
    Period = 26,
    Image = 27,
    DateTime = 28,
    PersonByTeam = 29,
    ImageList = 30,
    UrlDownloadField = 31,
    EmbeddedVideo = 32,
    ImageActionButton = 33,
    Team = 34,
    AttachmentList = 35,
    Draw = 36,
    Barcode = 37,
    Time = 38,

    /** File Drop target (no AS support) */
    FileDrop = 40,

    /** Reference field (no AS support) */
    Reference = 41,

    /** Text field that hides value until an eye icon is pressed */
    ConcealedText = 42,

    /** Document upload/download/storage */
    Document = 43,

    Aggregate = 45,

    /** Only one can exist per App.  Is Readonly.  Uses _CommentsCount Identifier */
    CommentsCount = 46,

    /** Only one can exist per App.  Is Readonly.  Uses _AttachmentsCount Identifier */
    AttachmentsCount = 47,

    None = -1,
  }

  export enum MultiStateType {
    RAG = 0,
    RAGBB = 1,
    HarveyBall = 2,
    HarveyBallTriState = 3,
    HarveyBallTwoState = 4
  }

  export enum SelectListType {
    Select,
    Radio,
    Checkbox,
    Listbox
  }

  export enum SelectListSubType {
    Numeric,
    Text
  }

  export enum ChartType {
    area = 0,
    areaspline = 1,
    bar = 2,
    bubble = 3,
    column = 4,
    gantt = 5,
    line = 6,
    pie = 7,
    polar = 8,
    scatter = 9,
    spline = 10,
    matrix = 11,
    cumulativematrix = 12,
    montecarlo = 13,
    map = 14,
    googlemap = 15,
    gauge = 16,
    network = 17,
    summaryseries = 18
  }

  export enum ModalType {
    info = 'info',
    confirm = 'confirm'
  }

  export enum ArchiveActionType {
    archive = 'info',
    unarchive = 'unarchive',
    delete = 'delete'
  }

  export enum ToastySoftoolsType {
    info = 'info',
    success = 'success',
    wait = 'wait',
    error = 'error',
    warning = 'warning'
  }

  export enum HomepageType {
    image = 'image',
    dashboard = 'dashboard',
    custom = 'custom',
    misconfigured = 'misconfigured',
  }

  export enum LayoutType {
    OneByOne = 0,
    OneByTwo = 1,
    TwoByOne = 2,
    TwoByTwo = 3,
    TwoByThree = 4,
    ThreeByThree = 5,
    ThreeByTwo = 6,
    ThreeByOne = 7,
    OneByThree = 8
  }

  export enum TemplatedReportSubType {
    App = 1,
    Record = 2,
    Global = 3,

    // Hidden from AppStudio config, set via user selection when requesting export.
    // List Report Context allows for multi record exports to be combined/ merged into single export
    AppCombinedRecord = 4
  }

  export enum ExportType {
    csv = 'csv',
    templatedReportExport = 'templatedReportExport',
    templatedReportExportAsPdf = 'templatedReportExportAsPdf',
    templatedReportExportCombinedRecord = 'templatedReportExportCombinedRecord',
    templatedReportExportAsPdfCombinedRecord = 'templatedReportExportAsPdfCombinedRecord'
  }

  export enum FilterAccessLevel {
    Personal = 0,
    Team = 1,
    Global = 2
  }

  export enum ValidationErrorTypes {
    required = 'required',
    pattern = 'pattern',
    minValue = 'minValue',
    maxValue = 'maxValue',
    maxLength = 'maxLength'
  }

  export enum ImageListSize {
    Small,
    Medium,
    Large
  }

  export enum BuilderRowMode {
    Column = 0,
    Span = 1
  }

  export enum Orientation {
    Horizontal,
    Vertical
  }

  export enum CheckboxType {
    Default = 'Default',
    Switch = 'Switch'
  }

  export enum ImageActionButtonSize {
    Small,
    Medium,
    Large,
    Full
  }

  export enum ImageActionButtonType {
    AddChildRecord = 0,
    CloseRecord = 1,
    GoToChildApp = 2,
    GoToParentRecord = 3,
    OpenUrl = 4,
    TriggerWorkflow = 5,
    CopyRecord = 6,
    CopyRecordAsTemplate = 7,

    Submit = 8,

    Logout = 9,

    /** Execute a logic block */
    LogicBlock = 10,

    // Following are used for menu items
    // We could use logic blocks instead especially if the actions are useful
    // A tradeoff, logic blocks allow them to be used in other circumstances,
    // but is a bit more complex, less zero-codey.

    /** Reload app configuration */
    ResyncApp = 11,

    /** Archive the current record or report selection. */
    Archive = 12,

    /** Unarchive the current record or report selection. */
    Unarchive = 13,

    Copy = 14,
    Delete = 15,
    Security = 16,
    Import = 17,
    Export = 18,
    History = 19,
    Refresh = 20,
    SyncChildData = 21,
    ShowArchived = 22,
    HideArchived = 23,
    Link = 24,
    Unlink = 25,
    MakeAvailableOffline = 26,
    MakeUnavailableOffline = 27,

    /** Discard any changes stored for the record.  A _new record will effectively be removed */
    DiscardChanges = 28,

    OpenRecord = 29,
  }

  export enum DashboardCellAlignment {
    Left = 0,
    Center = 1,
    Right = 2
  }

  export enum BackgroundTaskStatus {
    Initialising = 0,
    Running = 1,
    Failed = 2,
    Completed = 3,
    Cancelled = 4
  }

  export enum FormVisibilityStatus {
    ReadOnly = 0,
    Hidden = 1
  }

  export enum FreehandDrawingCanvasSize {
    Small = 0,
    Medium = 1,
    Large = 2,
    Full = 3
  }

  export enum LinkPickerForce {
    NoForce = 0,
    ForceLink = 1,
    ForceCopyAndLink = 2
  }

  export enum LinkPickerDefault {
    Link = 0,
    CopyAndLink = 1
  }

  export enum CellBackgroundColourSource {
    Field = 0, // Single Record expectancy, e.g. source as Multistate RAG
    // Threshold = 1 // Multi Record expectancy
  }

  export enum RowColumnTotals {
    None = 0,
    RowOnly = 1,
    ColumnOnly = 2,
    RowAndColumn = 3,
  }

  export enum CellType {
    Count = 0,
    FieldDetails = 1,
  }

  export enum DashboardCellTypeEnum {
    ImageCell = 0,
    ReportCell = 1,
    ButtonFieldCell = 2,
    IFrameCell = 3,
    StaticTemplate = 4,
    Empty = 5
  }

}
