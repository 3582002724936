export interface FileUploaderSoftoolsOptions {
  appIdentifier: string;
  parentAppIdentifier?: string;
  parentRecordIdentifier?: string;

  /** Optional import function */
  importer?: (data: string) => Promise<any>;
}

export const initialFileUploaderSoftoolsOptions: FileUploaderSoftoolsOptions = {
  appIdentifier: '',
  parentAppIdentifier: '',
  parentRecordIdentifier: ''
};
