import { Injectable } from '@angular/core';

import { ReportDataRepository } from '../repos';

import { ChartData } from '../types';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { STORE_CHART_DATA, STORE_IN_APP_CHART_DATA } from '../indexedDb/database-stores';

@Injectable({ providedIn: 'root' })
export class ReportDataService {
  constructor(
    private reportDataRepository: ReportDataRepository,
    private dbService: DatabaseContextService<ChartData>) {
  }

  /**
   * Get the chart data for a chart and a set of query parameters.  A local cache is used if available,
   * if no local copy is available the data is retrieved from the server.
   *
   * @param appIdentifier
   * @param reportIdentifier
   * @param queryParams
   */
  public async getHighChartsDataAsync(appIdentifier: string, reportIdentifier: string, queryParams: {}, hierarchy: string): Promise<ChartData> {
    const data = await this.dbService.getAndBackgroundRefresh(
      STORE_CHART_DATA,
      appIdentifier,
      `${appIdentifier}-${reportIdentifier}-${JSON.stringify(queryParams)}`,
      () => this.reportDataRepository.getHighChartsData(appIdentifier, reportIdentifier, queryParams, hierarchy),
      (res) => ({ ...res, AppIdentifier: appIdentifier, ReportIdentifier: reportIdentifier, QueryParams: JSON.stringify(queryParams) }));

    return data.value;
  }

  public async clear() {
    return await Promise.all([
      this.dbService.clear(STORE_CHART_DATA),
      // In App data no longer cached - retain the clear to tidy up. Can remove completely eventually
      this.dbService.clear(STORE_IN_APP_CHART_DATA)]
    );
  }
}
