<ul>
    <div [id]="toolbarAction.text" class="card-block" *ngFor="let toolbarAction of actions">
      <div id="toolbarAction" role="button" class="p-2 menu-element pe-2 d-flex align-items-center"
        [class.disabled]="disableWhenOffline && !isOnline"
        [class.pointer]="!disableWhenOffline || isOnline"
        (click)="disableWhenOffline && !isOnline ? null : onActionClick.emit(toolbarAction); $event.stopPropagation()">
        <li class="icon me-2"><fa-icon [icon]="['fal', toolbarAction.icon]" [border]="false" size="1x"></fa-icon></li>
        <!-- <span *ngIf="toolbarAction.text == 'Settings'" i18n="@@Settings">Settings</span> -->
        <li *ngIf="toolbarAction.text === 'Profile'"><span i18n="@@MyProfile">My Profile</span></li>
        <li *ngIf="toolbarAction.text === 'Refresh'"><span i18n="@@Refresh">Refresh</span></li>
        <li *ngIf="toolbarAction.text === 'Link'"><span i18n="@@Link">Link</span></li>
        <li *ngIf="toolbarAction.text === 'Unlink'"><span i18n="@@Unlink">Unlink</span></li>
        <li *ngIf="toolbarAction.text === 'Import'"><span i18n="@@Import">Import</span></li>
        <li *ngIf="toolbarAction.text === 'Export'"><span i18n="@@Export">Export</span></li>
        <li *ngIf="toolbarAction.text === 'Security'"><span i18n="@@Security">Security</span></li>
        <li *ngIf="toolbarAction.text === 'Delete'" class="delete"><span i18n="@@Delete">Delete</span></li>
        <li *ngIf="toolbarAction.text === 'Archive'"><span i18n="@@Archive">Archive</span></li>
        <li *ngIf="toolbarAction.text === 'Unarchive'"><span i18n="@@Unarchive">Unarchive</span></li>
        <li *ngIf="toolbarAction.text === 'ShowArchived'"><span i18n="@@Show Archived">Show Archived</span></li>
        <li *ngIf="toolbarAction.text === 'HideArchived'"><span i18n="@@Hide Archived">Hide Archived</span></li>
        <li *ngIf="toolbarAction.text === 'History'"><span i18n="@@History">History</span></li>
        <li *ngIf="toolbarAction.text === 'Support'"><span i18n="@@Support">Support</span></li>
        <li *ngIf="toolbarAction.text === 'MyProfile'"><span i18n="@@MyProfile">My Profile</span></li>
        <li *ngIf="toolbarAction.text === 'Old Platform'"><span i18n="@@OldPlatform">Old Platform</span></li>
        <li *ngIf="toolbarAction.text === 'Copy'"><span i18n="@@Copy">Copy</span></li>
        <li *ngIf="toolbarAction.text === 'Invite'"><span i18n>Invite</span></li>
        <li *ngIf="toolbarAction.text === 'Verify'"><span i18n="@@Verify">Request Verification</span></li>
        <li *ngIf="toolbarAction.text === 'ResendInvite'"><span i18n="@@ResendEmail">Resend Email</span></li>
        <li *ngIf="toolbarAction.text === 'Filter'"><span i18n="@@Filter">Filter</span></li>
        <li *ngIf="toolbarAction.text === 'ShowDeactivated'"><span i18n="@@ShowDeactivated">Show Deactivated</span></li>
        <li *ngIf="toolbarAction.text === 'ShowActive'"><span i18n="@@ShowActive">Show Active</span></li>
        <li *ngIf="toolbarAction.text === 'Deactivate'"><span i18n="@@Deactivate">Deactivate</span></li>
        <li *ngIf="toolbarAction.text === 'Reactivate'"><span i18n="@@Reactivate">Reactivate</span></li>
        <li *ngIf="toolbarAction.text === 'DeleteTeam'"><span i18n="@@DeleteTeam">Delete Team</span></li>
        <li *ngIf="toolbarAction.text === 'DeleteTeams'"><span i18n="@@DeleteTeams">Delete Teams</span></li>
        <li *ngIf="toolbarAction.text === 'DeleteRecord'"><span i18n="@@Delete">Delete</span></li>
        <li *ngIf="toolbarAction.text === 'ResyncApp'"><span i18n="@@ResyncApp">Resync App</span></li>
        <li *ngIf="toolbarAction.text === 'AppStudio'"><span i18n="@@AppStudio">AppStudio</span></li>
        <li *ngIf="toolbarAction.text === 'MakeOffline'"><span i18n="@@MakeOffline">Make available offline</span></li>
        <li *ngIf="toolbarAction.text === 'MakeOnline'"><span i18n="@@MakeOnline">Disable Offline</span></li>
        <li *ngIf="toolbarAction.text === 'Log out'"><span i18n="@@LogOut">Log out</span></li>
        <li *ngIf="toolbarAction.text === 'SyncChildData'"><span i18n="@@Log out">Sync Child App Data</span></li>
        <li *ngIf="toolbarAction.text === 'CancelImport'"><span i18n="@@CancelImport">Cancel Import</span></li>
        <li *ngIf="toolbarAction.text === 'ResendVerificationEmail'"><span i18n="@@ResendVerificationEmail">Resend Verification Email</span></li>
        <li *ngIf="toolbarAction.text === 'EmailActivity'"><span i18n>Email Activity</span></li>
      </div>
    </div>
  </ul>
  <div *ngIf="showVersion" class="card-block px-2 text-muted d-flex">
    <!-- no 18n intentionally -->
    <small class="ms-auto">Softools v{{version}}</small>
  </div>
