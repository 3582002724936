import { ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Failure } from '@softools/softools-core';
import { EditableFieldBase, FieldBase } from 'app/softoolsui.module/fields';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { AppField } from 'app/types/fields/app-field';

@Component({
  selector: 'sof-template-field',
  templateUrl: './template-field.component.html',
  styleUrls: ['./template-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateFieldComponent extends FieldComponent {

  public editValidationErrors$ = new BehaviorSubject<Array<Failure>>(null);

  @Output() public activateComponent = new EventEmitter<FieldComponent>();

  public active = false;

  protected override _initEditableField(fieldComponent: EditableFieldBase<any, AppField<any>>): void {
    super._initEditableField(fieldComponent);

    this.subscribe(fieldComponent.editValidationErrors$, (errors) => {
      this.editValidationErrors$.next(errors);
    });
  }

  protected override _initField(fieldComponent: FieldBase): void {
    super._initField(fieldComponent);

    this.subscribe(fieldComponent.componentFocussed$, (inner) => {
      this.activateComponent.emit(inner);
    });

    this.subscribe(fieldComponent.requestActivation$, () => {
      if (this.fieldModel.clickToEdit) {
        this.activateComponent.emit(this);
      } else {
        this.activateComponent.emit(null);
      }
    });
  }

  protected override addComponentForField(): ComponentRef<FieldBase> {

    if (!this.active && this.fieldModel.clickToEdit) {
      return this.addTextDisplayComponent();
    }

    return super.addComponentForField();
  }

  public override async activated(active: boolean) {
    if (this.active !== active) {
      this.active = active;

      const component = this.addComponentForField();
      await component.instance.activate();  // ??
      component.instance.ngOnInit();
    }

    await super.activated(active);
  }
}
