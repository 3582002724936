import { Component, ChangeDetectionStrategy, Input, AfterViewInit, HostListener, OnChanges, SimpleChanges, OnDestroy, OnInit } from '@angular/core';

import { Enums, ChartData, logError } from '@softools/softools-core';
import { ChartReportHelperService } from '../../workspace.module/services/services.charthelper';
import { HttpClient } from '@angular/common/http';

import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsNetwork from 'highcharts/modules/networkgraph';
import { Options } from 'highcharts';
import MapModule from 'highcharts/modules/map';
import { MatrixModel } from '../matrixreport.component/matrix-model';
import { AppIdentifiers } from 'app/services/record/app-info';
import { ChartEnums } from 'app/services/chart';
import { ComponentBase } from '../component-base';
import { ChartReportModel, GlobalModel } from 'app/mvc';
import { BehaviorSubject, debounceTime } from 'rxjs';

MapModule(Highcharts);
HighchartsMore(Highcharts);
HighchartsNetwork(Highcharts);


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChartReportHelperService]
})
export class ChartComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  public highChartConfigOptions: Options;
  public configInvalid = false;
  public highcharts: typeof Highcharts = Highcharts;
  public chartConstructorType = 'chart';
  public chartConfigIsReady = false;
  public firstTime = true;
  public clickThroughReportIdentifier: string;

  private domReady = false;

  /** Highchart update flag. This is set true to force the chart to update;
   * the updateChange event is fired when complete, resetting the flag
   */
  public chartUpdated$ = new BehaviorSubject(false);

  private chart: Highcharts.Chart;

  @Input() public globalModel: GlobalModel;
  @Input() public chartReportModel: ChartReportModel;

  @Input() chartContainerId: string;
  @Input() displayChart: boolean;
  @Input() displayMatrixTable: boolean;
  @Input() chartConfig: Highcharts.Options;
  @Input() chartData: ChartData;
  @Input() matrixModel: MatrixModel;
  @Input() chartType: Enums.ChartType;
  @Input() reportType: Enums.ReportTypes;
  @Input() appIdentifier: string;
  @Input() appIdentifiers: AppIdentifiers;
  @Input() showTitle = true;
  @Input() showSubTitle = true;
  @Input() forceRerenderOnResize = false;
  @Input() templateExpanded = true;
  @Input() isArchived: boolean;
  @Input() parentRecordId: string;
  @Input() isInAppChart = false;
  @Input() chartingError: ChartEnums.ChartErrors = ChartEnums.ChartErrors.NoError;

  constructor(
    private chartReportHelper: ChartReportHelperService,
    private _httpClient: HttpClient
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(this.globalModel.layoutChanged$.pipe(debounceTime(10)), () => {
      this.chart?.reflow();
    });
  }

  public ngAfterViewInit(): void {
    this.domReady = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] || (changes['templateExpanded'] && this.templateExpanded)) {
      // The DOM isn't ready yet so defer chart creation
      this.chartConfigIsReady = false;
      this.highChartConfigOptions = null;
      setTimeout(async () => {
        if (this.templateExpanded) {
          await this._genChart(this.domReady);
        } else {
          await this._genChart(!this.firstTime);
        }
      });
    }
    if (changes['chartConfig'] && !this.isInAppChart) {
      const plotOptions = <any>this.chartConfig?.plotOptions;
      if (plotOptions && plotOptions.clickEventParams) {
        this.clickThroughReportIdentifier = plotOptions.clickEventParams.targetClickThroughReportIdentifier;
      }
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.refresh();
  }

  public onChartUpdated(updated: boolean) {
    this.chartUpdated$.next(updated);
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (this.forceRerenderOnResize) {
      this._genChart(!this.firstTime).catch(error => logError(error, 'Failed to gen chart'));
    }
  }

  /**
   * Callback for chart when created
   * Uses arrow function to capture this.
   *
   * @param chart
   */
  public chartCallback = (chart: Highcharts.Chart) => {

    this.chart = chart;

    // Adds ids only to chart points that have values
    for (let seriesNumber = 0; seriesNumber < chart.series.length; seriesNumber++) {
      const filteredPoints = chart.series[seriesNumber].points.filter((point) => {
        if (point['shapeArgs']) {
          return point['shapeArgs'].height > 0;
        }

        // bubble charts (at least) don't have shapeArgs
        return true;
      });

      const pointsGraphics = filteredPoints.filter(point => point['graphic']).map((point) => {
        return point['graphic'].element;
      });

      pointsGraphics.forEach((point, pointNumber: Number) => {
        point.setAttribute('id', `chart-series-${seriesNumber}-point-${pointNumber}`);
      });
    }
  }

  /**
   * Generate or regenerate the chart
   *
   * @param setUpdateFlag If true the highchart component's update flag is set
   *  Currently this is disabled because it was stopping the initial render from
   *  working.  Made it easy to selectively replace in case some scenarios need it
   */
  private async _genChart(setUpdateFlag = false): Promise<void> {

    // Only render if we're on an expanded template otherwise we don't have a physical div to attach to
    if (!this.chartData || !this.templateExpanded || this.chartType === Enums.ChartType.googlemap) {
      return;
    }

    this.chartReportHelper.setupAdditionalChartConfig(this.chartConfig, this.chartData, this.chartType,
      this.reportType, this.chartReportModel, this.showTitle, this.showSubTitle, this.isInAppChart);

    this.highChartConfigOptions = this.chartReportHelper.createChartViewModel(this.chartType, this.chartConfig);

    if (!this.highChartConfigOptions) {
      // No chart config - set invalid
      this._setConfigInvalid(true);
      this.refresh();
      return;
    }

    this.firstTime = false;
    if (this.chartConfig.chart.plotBackgroundImage) {
      this.chartConfig.chart.plotBackgroundImage = this.chartConfig.chart.plotBackgroundImage ?? '';
    }

    if (this.chartType !== Enums.ChartType.map) {
      if (setUpdateFlag) {
        this.onChartUpdated(true);
      }

      this._setConfigInvalid(false);
      this._setConfigIsReady(true);
      this.refresh();
    } else {
      let mapGeoJsonRoute = '';
      switch ((<any>this.highChartConfigOptions).mapKey) {
        case 'countries/gb/gb-all':
          mapGeoJsonRoute = '/assets/mapjson/countries.gb.gb-all.json';
          break;
        case 'custom/europe':
          mapGeoJsonRoute = '/assets/mapjson/custom.europe.json';
          break;
        case 'custom/world':
          mapGeoJsonRoute = '/assets/mapjson/custom.world.json';
          break;
      }
      if (!mapGeoJsonRoute) {
        return;
      }
      await this._httpClient.get(mapGeoJsonRoute).toPromise().then(mapGeoJson => {
        this.chartReportHelper.setMapGeoData(this.highChartConfigOptions, mapGeoJson);
        this.chartConstructorType = 'mapChart';

        if (setUpdateFlag) {
          this.onChartUpdated(true);
        }
        this._setConfigInvalid(false);
        this._setConfigIsReady(true);
        this.refresh();
      });
    }
  }

  private _setConfigIsReady(value: boolean): void {
    this.chartConfigIsReady = value;
  }

  private _setConfigInvalid(value: boolean): void {
    this.configInvalid = value;
  }

}
