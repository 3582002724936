import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Enums, Template, TemplateLayout, Record, ElementStyles, Form, IStyleName } from '@softools/softools-core';
import { AppModel, AttachmentsModel, RecordUpdateController, RecordUpdateModel } from 'app/mvc';
import { CommentsModel } from 'app/mvc/comments-model';
import { RulesEngine } from 'app/mvc/rules/rules-engine';
import { InjectService } from 'app/services/locator.service';
import { PermissionsService } from 'app/services/permissions.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { ContainerType } from 'app/softoolsui.module/fields';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { Application } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { TemplateFieldComponent } from './template-field/template-field.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateComponent implements OnInit, OnChanges {

  @Input() appModel: AppModel;

  @Input() commentsModel: CommentsModel;

  @Input() attachmentsModel: AttachmentsModel;

  /** Record model when on a record form */
  @Input() recordModel?: RecordUpdateModel;

  /** Record update controller when on a record form */
  @Input() recordUpdateController?: RecordUpdateController;

  @Input() public appIdentifiers: AppIdentifiers;

  @Input() public application: Application;

  /** Form when template is part of a form */
  @Input() public form: Form;

  @Input() public template: Template;

  /** Whether the template is expanded */
  @Input() expanded = true;

  /** Whether the template help is expanded */
  @Input() expandedHelp = false;

  @Input() public record?: Record;

  @Input() public hideHeader = false;

  /** true if the template is being shown in a read-only context */
  @Input() public readOnly = false;

  @Output() public infoIconClicked = new EventEmitter<{ field: AppField, origin: CdkOverlayOrigin }>();

  /** Event for form template toggle */
  @Output() onToggleExpandedFormTemplate = new EventEmitter();

  @Output() onToggleExpandedFormTemplateHelp = new EventEmitter();

  @Output() public activateComponent = new EventEmitter<FieldComponent>();

  public templateRowGroups: TemplateLayout[][];

  public rulesEngine: RulesEngine;

  /** Container type, for now say we're a form but may need a specific type */
  public formContainerType = ContainerType.Form;

  public fieldTypes = Enums.FieldType;

  public hasAppStudioAccess = false;

  /** Field styles indexed by layout cell id */
  private fieldStyles = new Map<number, ElementStyles>();

  @ViewChildren(TemplateFieldComponent) fieldComponents: QueryList<TemplateFieldComponent>;

  @InjectService(PermissionsService)
  protected permissionsService: PermissionsService;

  constructor() { }

  public ngOnInit(): void {
    this.buildTemplateGroups();

    const embedded = this.application && this.application.IsEmbedded;
    this.hasAppStudioAccess = !embedded && this.permissionsService.hasAppStudio;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['application']) {
      this.rulesEngine = this.application ? new RulesEngine(this.application) : null;
    }

    if (changes['application'] || changes['record']) {
      this.rulesEngine?.recordChange(this.record, this.appModel?.staticRecord.value);
      this.setFieldStyles();
    }
  }

  public templateRowGroupsTrackByFn(index, _) {
    return index;
  }

  public rowGroupTrackByFn(_, item) {
    return item.FieldIdentifier;
  }


  public getMaxColumnsForTemplate(layouts: Array<TemplateLayout>) {
    return Math.max(...layouts.map((o) => o.ColumnId));
  }

  public getField(fieldIdentifier: string) {
    return this.application.getField(fieldIdentifier);
  }

  public isScrollable(fieldIdentifier: string) {
    const field = this.application?.getField(fieldIdentifier);
    if (field) {
      return field.Type === Enums.FieldType.GridField || field.Type === Enums.FieldType.ListField;
    }

    return false;
  }

  private buildTemplateGroups() {
    if (this.template?.Layout) {
      const templateRowGroupsObject = this.template.Layout.reduce(function (accumulator, tl) {
        accumulator['Row-' + tl.RowId] = accumulator['Row-' + tl.RowId] || ([] as Array<TemplateLayout>);
        accumulator['Row-' + tl.RowId].push(tl);
        return accumulator;
      }, Object.create(null));

      const templateRowGroupsArray = [] as Array<Array<TemplateLayout>>;
      // eslint-disable-next-line guard-for-in
      for (const templateRowGroup in templateRowGroupsObject) {
        const rowGroup = templateRowGroupsObject[templateRowGroup];
        rowGroup.sort(function (a, b) {
          return a.ColumnId - b.ColumnId;
        });
        templateRowGroupsArray.push(rowGroup);
      }

      this.templateRowGroups = templateRowGroupsArray;
    }
  }

  public showFieldHeader(field: AppField) {
    return field?.showHeaderOnTemplate(this.appModel);
  }

  /** Value to display in value tooltip */
  public titleValue(field: AppField, record: Record) {
    const value = field.titleValue(record);
    return value || '';
  }

  public isFieldVisible(field: AppField, record: Record) {
    return field?.isVisible(record) && !this.rulesEngine?.isHidden(field.Identifier);
  }

  public isCartouche(layout: TemplateLayout) {
    const styles = this.fieldStyles.get(layout.Id);
    return styles?.component?.Appearance === 'cartouche';
  }

  public fieldElementStyles(layout: TemplateLayout): ElementStyles {
    return this.fieldStyles.get(layout.Id);
  }

  public labelCss(layout: TemplateLayout): any {
    const style = this.fieldStyles.get(layout.Id);
    return style['label']?.css ?? {};
  }

  public componentCss(layout: TemplateLayout): any {
    const style = this.fieldStyles.get(layout.Id);
    const compStyle = style['component'];

    // Skip styling on round-rects as they handle it (differently) - see SOF-11956
    if (compStyle?.Appearance === 'cartouche') {
      return {}
    } else {
      return compStyle?.css ?? {};
    }
  }


  public isInvalid(field: AppField) {

    if (this.record?._errs?.length > 0 && field) {
      return this.record._errs.findIndex((f) => f.fieldId === field.Identifier) >= 0;
    }

    // if (this.validationErrors && this.record && field) {
    //   const recordErrors = this.validationErrors.get(this.record._id);
    //   if (recordErrors) {
    //     return recordErrors.findIndex((f) => f.fieldId === field.Identifier) >= 0;
    //   }
    // }

    return false;
  }

  public toggleExpandedFormTemplate($event) {
    this.fieldComponents.forEach((component) => {
      component.onToggleExpandedFormTemplate();
    });
    this.onToggleExpandedFormTemplate.emit($event);
  }

  public onActivateComponent(component: FieldComponent) {
    this.activateComponent.emit(component);
  }

  private setFieldStyles() {

    this.template.Layout?.forEach(cell => {

      const fieldIdentifier = cell.FieldIdentifier;
      const field = this.application.getField(fieldIdentifier);

      // Get all relevant named stylesin most specific to least specific
      // order, getNamedStyles preserves the earliest in the list
      const styleNames: Array<IStyleName> = [];

      // Add style enabled by rules
      const ruleStyles = this.rulesEngine?.getStyleNames(fieldIdentifier);
      if (ruleStyles) {
        styleNames.push(...ruleStyles);
      }

      if (cell.NamedStyles?.length > 0) {
        styleNames.push(...cell.NamedStyles);
      }

      if (field.NamedStyles) {
        styleNames.push(...field.NamedStyles);
      }

      if (this.template.NamedStyles?.length > 0) {
        styleNames.push(...this.template.NamedStyles)
      }

      if (this.form?.NamedStyles?.length > 0) {
        styleNames.push(...this.form.NamedStyles)
      }

      if (this.application.NamedStyles?.length > 0) {
        styleNames.push(...this.application.NamedStyles)
      }

      const styles = this.appModel?.getNamedStyles({
        target: 'field',
        names: styleNames,
        additionalStyles: [field.Style]
      });
      if (styles) {
        this.fieldStyles.set(cell.Id, styles);
      }
    });
  }
}
