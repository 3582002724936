
import { AccessRight } from './accessright.interface';

export interface Security {
  DataRows: Array<AccessRight>;
}

export const initialSecurity: Security = {
  DataRows: [] as Array<AccessRight>
};
