import { Injectable } from '@angular/core';
import { ReportViewParams } from '../types/interfaces/report-view-params';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { STORE_REPORT_VIEWS } from '../indexedDb/database-stores';
import { AppDatabaseContextService } from './services.apps';

/**
 * Provides access to a locally stored cache of report view data.
 * This records how a list report is being viewed e.g. the first
 * record displayed, page size and filter strings.
 */
@Injectable({ providedIn: 'root' })
export class ReportViewService {

  private defaultViewData = {
    firstRecordIndex: 0,
    orderBy: '',
    groupBy: '',
    showArchived: false,
    filterQuery: '',
    groupValuesCollapsed: [],
    expandDetailsFields: [],
    pageSize: 25
  };

  constructor(private dbContext: DatabaseContextService<ReportViewParams>, private service: AppDatabaseContextService) {

  }

  public getViewDataAsync(appIdentifier: string, reportIdentifier: string): Promise<ReportViewParams> {
    return this.dbContext.get(STORE_REPORT_VIEWS, `${appIdentifier}-${reportIdentifier}`);
  }

  public setViewDataAsync(appIdentifier: string, reportdata: ReportViewParams): Promise<boolean> {
    return this.dbContext.put(STORE_REPORT_VIEWS, appIdentifier, `${appIdentifier}-${reportdata.reportId}`, reportdata);
  }

  /** Store page size in place */
  public async setPageSizeAsync(appIdentifier: string, reportIdentifier: string, size: number) {
    const view = await this.getViewDataAsync(appIdentifier, reportIdentifier);
    if (view) {
      return await this.setViewDataAsync(appIdentifier, { ...view, pageSize: size });
    } else {
      // No existing record (unlikely) - persist with default
      return await this.setViewDataAsync(appIdentifier, {
        ...this.defaultViewData,
        reportId: reportIdentifier,
        pageSize: size
      });
    }
  }

  public async setAutoLayoutAsync(appIdentifier: string, reportIdentifier: string, enabled: boolean) {
    const view = await this.getViewDataAsync(appIdentifier, reportIdentifier);
    if (view) {
      return await this.setViewDataAsync(appIdentifier, { ...view, autoLayout: enabled });
    } else {
      // No existing record (unlikely) - persist with default
      return await this.setViewDataAsync(appIdentifier, {
        ...this.defaultViewData,
        reportId: reportIdentifier,
        autoLayout: enabled
      });
    }
  }

  public async clear(): Promise<boolean> {
    return this.service.clear(STORE_REPORT_VIEWS);
  }
}
