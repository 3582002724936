<div *ngIf="filterable$|async" class="w-100 d-flex justify-content-between">
  <span *ngIf="!hideLabel">{{fieldModel.Label}}</span>
</div>

<div cdkOverlayOrigin #operatorPopup="cdkOverlayOrigin" 
  class="w-100 h-100 d-flex justify-content-between align-items-center"
  [title]="fieldModel.filterOperationName(term.Operator) ?? ''"
  >
  <div style="width: 3rem;" class="operator-icon pe-2" (click)="currentOperatorClicked($event)" 
    [class.pointer]="!displayOnly"
  >
    <fa-icon *ngIf="row === 0"  [icon]="['fas', (operatorIcon$|async)]" size="2x" ></fa-icon>
  </div>
  <ng-container #inner></ng-container>
  <div *ngIf="filterable$|async" class="ms-2 h-100 d-flex align-items-center justify-content-end">
    <div style="width: 1rem;">
      <fa-icon *ngIf="!displayOnly && showClear(term?.Operand)" class="pointer" 
        [icon]="['fal', 'circle-trash']" 
        size="2x" 
        (click)="clearClicked($event)"
        >
      </fa-icon>
    </div>
  </div>
</div>

<!-- 
 -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="popupPosition"
  [cdkConnectedOverlayOrigin]="operatorPopup"
  [cdkConnectedOverlayOpen]="showOperatorPopup$|async"
  [cdkConnectedOverlayHasBackdrop]="true"
  (detach)="showOperatorPopup$.next(false)"
  (backdropClick)="showOperatorPopup$.next(false)"
>
 
  <div class="py-4 px-2 popup arrow-top" style="background-color: white; color: black;  width: auto; height: auto;">
    <div class="popup-wrapper">
      <div *ngFor="let op of fieldModel.filterOperations()" 
        class="menu-row operator-row pointer p-2"
        [class.selected-operator]="op === this.term?.Operator"
        (click)="operatorClicked($event, op)"
        >
        <div class="icon" >
          <fa-icon [icon]="['fal', operatorIcons[op]]" size="1x" ></fa-icon>
        </div>
        <span class="px-2">{{fieldModel.filterOperationName(op)}}</span>
      </div>
    </div>
  </div>
</ng-template>
