import { Record, Field, App, TrackChangeList, TrackChange, Failure } from '@softools/softools-core';
import { AppField } from './app-field';
import { IApplication } from '../app-field-source';
import { RecordPatch } from 'app/workspace.module/types';
import { DocumentAppField } from './document-app-field';
import { BackingField } from './backing-field';
import { Application } from '../application';

export class ListItemAppField<TContained extends AppField = AppField> extends AppField {
  private _containerIdentifier: string;
  private _container: AppField;
  private _dataIdentifier: string;

  constructor(field?: Field, public containedField?: TContained, app?: App & IApplication) {
    super(field, app);

    // Extract data id part
    const [container, base] = this.Identifier.split('_', 2);
    this._containerIdentifier = container;
    this._dataIdentifier = base;
  }

  public override initialise(app: Application) {
    super.initialise(app);
    this._container = app.getField(this._containerIdentifier);
  }

  public override get DataIdentifier() {
    return this._dataIdentifier;
  }

  // @ts-ignore Override of proeprty to accessor is an error in ts now
  public get ListFieldParameters() {
    // Inherit list params from container
    return this._container?.ListFieldParameters;
  }

  public override rowKey(record: Record, listRow: number) {
    const listValues: Array<any> = record[this._containerIdentifier];
    if (listValues?.length >= listRow) {
      return listValues[listRow]?.Key;
    }
    return undefined;
  }

  public override compactRecord(record: Record): any | null {
    const value = record[this.DataIdentifier];
    const x = this.containedField.adaptValue(value, record, record);  // ?
    record[this.DataIdentifier] = x;

    // return this.containedField.compactRecord(record);
  }

  public override addBackingField(backing: BackingField) {
    this.containedField.addBackingField(backing);
  }

  /**
   * Get value for this field from a record without applying
   * formating.  This is the simple value for the field, any
   * secondary values are discarded.
   *
   * @param record Data record
   * @param listRow Row number
   */
  public override getRawRecordValue(record: Record, listRow?: number) {
    if (listRow !== undefined && listRow !== null) {
      const values = record[this._containerIdentifier];
      const value = values[listRow][this._dataIdentifier];

      if (value && value.hasOwnProperty('val')) {
        return value.val;
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  public override getInternalRecordValue(record: Record, _listRow?: number) {
    const probe = this._container.getInternalRecordValue(record, _listRow);
    const row = probe?.[_listRow];
    const value = row?.[this.DataIdentifier];
    return value;

    // Following WIP shouldn't be needed now as we will set up record value
    // correctly when we've finished.  Remvoe before final commit.
    // // This value should follow the rules for a field value - if it is a
    // // simple field it's a simple value, but if it has backing fields it
    // // should be a structure.  However we're currently not correcly compressing
    // // the data because the config doesn't contain backing fields
    // // - so we have individual backing field entries.
    // // Deal with this case - we should compacted and this will be redundant once
    // // everything has synced with compacted data.
    // if (this.containedField.backingFields.length < 1) {
    //   return value;   // simple value
    // }
    // if (value.hasOwnProperty('val')) {
    //   // looks like we've already compacted this so return it
    //   return value;
    // }
    // // const compact = this.containedField.compactRecord(value);
    // return super.getInternalRecordValue(record, _listRow);
  }

  public override formatDisplayValue(value: any) {
    return this.containedField?.formatDisplayValue(value);
  }

  public override editableValue(value: any): string {
    return this.containedField?.editableValue(value);
  }

  public override updateRecord(record: Record, value: any) {
    this.containedField?.updateRecord(record, value);
  }

  public override adaptValue(value: any, _current: any, _record?: Record, _source?: 'input' | 'server'): any {
    return this.containedField?.adaptValue(value, _current, _record, _source);
  }

  public override storeToRecord(record: Record, value: any, listRow?: number) {
    if (listRow !== undefined && listRow !== null) {
      const values = record[this._containerIdentifier];
      values[listRow][this._dataIdentifier] = value;
    } else {
      // Is this a sensible fallback?  Probably not.
      super.storeToRecord(record, value, listRow);
    }
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    if (this.containedField) {
      this.containedField.validate(value, errors, listRow);
    } else {
      super.validate(value, errors);
    }
  }

  public override validateRecord(record: Record, listRow?: number): Array<Failure> {
    // Only validate if we've been given a list row by container
    // No action if called directly
    if (listRow === undefined) {
      return [];
    } else {
      return super.validateRecord(record, listRow);
    }
  }

  public override updatePatchWithDefault(_patch: RecordPatch) {
    // nop - as this represents a field within a list we don't initialise for a new record
    // We should initalise for a new row
  }

  /**
   * Create a patch to update this field.
   * For a list field item, this updates the tracked changes section of the patch.
   *
   * If the local option is set, @see value represents a local value that should be added
   * to the local tracked changes collection.  Otherwise it is the field value and should
   * be patched into the main tracked changes.  It might also generate local changes if
   * the contained field type requires it.
   *
   * @param record    record or patch to configure new patch
   * @param value     new field value
   * @param listRow   row key or index.  This must be specified for a list field item.
   * @param options   optional mofifiers.  Set local to update local changes.
   */
  public override createPatch(record: Record | RecordPatch, value: any, listRow: number | string, options?: { local?: boolean; }): RecordPatch {
    // Ask contained field to build patch.  It doesn't know about change lists so builds
    // simple top level changes
    const inner = this.containedField.createPatch(record, value, listRow, options);
    return this.trackChanges(record, inner, listRow);
  }

  public override getPatchValue(patch: RecordPatch, rowKey?: string, local = false) {
    const tracked = local ? patch.localTrackedChanges : patch.trackedChanges;
    const entry = tracked?.get(this._containerIdentifier) as TrackChangeList;
    const change = entry?.changes[rowKey];
    return change && change[this.DataIdentifier];
  }

  private mapChanges(source: any) {
    const changes = {};
    for (const change in source) {
      if (change.startsWith(`${this._containerIdentifier}_`)) {
        const name = change.substr(this._containerIdentifier.length + 1);
        changes[name] = source[change];
      }
    }

    return changes;
  }

  private mapTrackedChanges(trackedChanges: Map<string, TrackChange>) {
    const changes = {};
    for (let [change, value] of trackedChanges) {
      if (change.startsWith(`${this._containerIdentifier}_`)) {
        const name = change.substr(this._containerIdentifier.length + 1);
        changes[name] = value;
      }
    }

    return changes;
  }

  public createFilePatch(record: Record, assetId: string, file: File, listRow?: number) {
    // hack - pretend to be a doc field if we need to
    if (this.containedField instanceof DocumentAppField) {
      const linePatch = this.containedField.createFilePatch(record, assetId, file, listRow);
      return this.trackChanges(record, linePatch, listRow);
      // return this.containedField.createFilePatch(record, assetId, file, listRow);
    }

    return null;
  }

  public override isFilterable() {
    return false;   // Can't filter on list items
  }

  public override isEditable(): boolean {
    return this._container.isEditable() && this.containedField.isEditable();
  }

  /**
   * Convert changes in a  patch into tracked changes
   * @param record    record or patch to configure new patch
   * @param inner     Flat patch
   * @param listRow   Row indicaror - either row number or row key string
   */
  private trackChanges(record: Record | RecordPatch, inner: RecordPatch, listRow?: number | string) {

    const listData = record[this._containerIdentifier] as Array<any>;
    const rowKeyId = this.ListFieldParameters?.RowKey || 'Key';
    const rowKey: string = Number.isFinite(<number>listRow) ? listData[listRow][rowKeyId] : listRow;

    const patch = new RecordPatch(record._id, record.AppIdentifier, record.Hierarchy);
    if (record._new) {
      patch._new = true;
    }

    // Move any changes onto the tracked list
    const changes = this.mapChanges(inner.changes);
    if (Object.keys(changes).length > 0) {
      const changeList = new TrackChangeList(rowKeyId).addChange(rowKey, changes);
      patch.addTrackedChange(this._containerIdentifier, changeList);
    }

    if (inner.trackedChanges.size > 0) {
      const tracked = this.mapTrackedChanges(inner.trackedChanges);
      const changeList = new TrackChangeList(rowKeyId).addChange(rowKey, tracked);
      patch.addTrackedChange(this._containerIdentifier, changeList);
    }

    // Move any local changes onto local tracked change list
    if (Object.keys(inner.localChanges).length > 0) {
      const locals = this.mapChanges(inner.localChanges);
      if (Object.keys(locals).length > 0) {
        const changeList = new TrackChangeList(rowKeyId).addChange(rowKey, locals);
        patch.addLocalTrackedChange(this._containerIdentifier, changeList);
      }
    }

    return patch;
  }
}
