import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBannerComponent } from './message-banner.component';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';



@NgModule({
  declarations: [
    MessageBannerComponent
  ],
  imports: [
    CommonModule,
    MvcDirectivesModule
  ],
  exports: [
    MessageBannerComponent
  ]
})
export class MessageBannerModule { }
