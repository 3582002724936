import { Injectable } from '@angular/core';
import { NotificationMessage, NotificationsRepository, Record, SelectListOption } from '@softools/softools-core';
import { ReportModel } from 'app/mvc';
import { InjectService } from 'app/services/locator.service';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { AppCapabilities, DeleteRecordOptions, ToolbarContext } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { RecordPatch } from 'app/workspace.module/types';
import { firstValueFrom } from 'rxjs';
import { OnlineEmbeddedApp } from '../../embedded-application';
import { notificationsAppConfig } from './notifications.config';

@Injectable({ providedIn: 'root' })
export class NotificationsApplication extends OnlineEmbeddedApp<NotificationMessage> {

  @InjectService(NotificationsRepository)
  private repository: NotificationsRepository;

  constructor() {
    super(notificationsAppConfig);
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: false,
      canEdit: false,
      canView: false,
      canSort: true,
      canGroup: false
    };
  }

  public toRecord(value: NotificationMessage): Record {

    const record = value && {
      _id: value.Id,
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: false,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',
      IsArchived: false,
      ...value
    };

    return record;
  }

  public toMessage(record: Record): NotificationMessage {
    return {
      Id: record._id,
      Message: record['Title'],
      Current: record['Current'],
      LastUpdated: record['LastUpdated'],
      DateStarted: record['DateStarted'],
      Status: record['Status'],
    } as any;   // try to remove any but lots off guff in the type
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true, includePosition: true });
    const summaries = await firstValueFrom(this.repository.getBackgroundTasks(queryParams));
    return summaries.map(summary => this.toRecord(summary));
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true, includePosition: false });
    return await this.repository.getBackgroundTaskCountAsync(queryParams);
  }

  public override async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {
    if (deleteOptions.selection.AllRecordsSelected) {
      this.repository.removeAllAsync();
    } else {
      for (let i = 0; i < deleteOptions.selection.SelectedRecordIds.length; i++) {
        const id = deleteOptions.selection.SelectedRecordIds[i];
        await this.repository.removeAsync(id);
      }
    }

    return true;
  }

  public override selectionListOptions(fieldId: string): Array<SelectListOption> {
    if (fieldId === 'Status') {
      return [
        { Text: $localize`Initialising`, Value: '0', DisplayOrder: 1 },
        { Text: $localize`Running`, Value: '1', DisplayOrder: 2 },
        { Text: $localize`Failed`, Value: '2', DisplayOrder: 3 },
        { Text: $localize`Completed`, Value: '3', DisplayOrder: 4 },
        { Text: $localize`Cancelled`, Value: '4', DisplayOrder: 5 },
      ];
    }
    return null;
  }

  public override async refresh(): Promise<boolean> {
    return true;    // online so no need to refresh
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new ToolbarAction('Delete', 'trash', async () => {
      await reportModel.delete();
    }));

    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      reportModel.reload();
    }));
  }
}
