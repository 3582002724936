import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationMessageComponent } from './notification-message.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatProgressBarModule
  ],
  declarations: [NotificationMessageComponent],
  exports: [NotificationMessageComponent]
})
export class NotificationMessageModule { }
