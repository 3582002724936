<!--<i fa [name]="iconType" [border]=false [size]=1 ></i>-->

<fa-icon *ngIf="folderType === reportTypes.List" [icon]="['fal', 'list']"></fa-icon>
<fa-icon *ngIf="folderType === reportTypes.Table" [icon]="['fal', 'table-layout']"></fa-icon>
<fa-icon *ngIf="folderType === reportTypes.Dashboard" [icon]="['fal', 'chart-tree-map']"></fa-icon>
<fa-icon *ngIf="folderType === reportTypes.Card" [icon]="['fal', 'square-kanban']"></fa-icon>
<fa-icon *ngIf="folderType === 'form'" [icon]="['fal', 'memo-pad']"></fa-icon>
<fa-icon *ngIf="folderType === 'home'" [icon]="['fal', 'chart-tree-map']"></fa-icon>
<fa-icon *ngIf="folderType === 'childapp'" [icon]="['fal', 'chart-tree-map']"></fa-icon>

<fa-icon *ngIf="secondaryFolderType === chartTypes.area" [icon]="['fal', 'chart-area']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.areaspline" [icon]="['fal', 'chart-mixed']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.bar" [icon]="['fal', 'chart-bar']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.bubble" [icon]="['fal', 'chart-scatter-bubble']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.network" [icon]="['fal', 'chart-network']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.column" [icon]="['fal', 'chart-column']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.gantt" [icon]="['fal', 'chart-gantt']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.line" [icon]="['fal', 'chart-line']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.pie" [icon]="['fal', 'chart-pie']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.polar" [icon]="['fal', 'chart-scatter-3d']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.scatter" [icon]="['fal', 'chart-scatter']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.spline" [icon]="['fal', 'chart-waterfall']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.matrix" [icon]="['fal', 'grid-dividers']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.cumulativematrix || secondaryFolderType === 18" [icon]="['fal', 'chart-line-up']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.montecarlo" [icon]="['fal', 'chart-simple']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.map || secondaryFolderType === chartTypes.googlemap" [icon]="['fal', 'map-location-dot']"></fa-icon>
<fa-icon *ngIf="secondaryFolderType === chartTypes.gauge" [icon]="['fal', 'gauge-circle-bolt']"></fa-icon>

