import ObjectID from 'bson-objectid';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';

import { SafeUrl } from '@angular/platform-browser';

import { ImageData, ImagaFieldButtonMode, logError, ImageStorageService } from '@softools/softools-core';

import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { DeleteImageDetails, SaveCameraDetails } from 'app/workspace.module/services/pending.service';
import { ImagePersistService } from 'app/services/images/image-persist.service';
import { CameraDialogComponent } from 'app/softoolsui.module/camera/camera-dialog/camera-dialog.component';
import { AssetImageComponent } from 'app/softoolsui.module/asset-image/asset-image.component';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { LocalValuesStorageServiceService } from 'app/services/local-values-storage-service.service';
import { Subscription } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { ContainerType } from '../../field-base';
import { ToastyService } from 'app/services/toasty.service';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageFieldComponent extends EditableFieldBase<string> implements OnInit, OnDestroy {
  public deleteMode = false;
  public cameraData;

  public disableCamera = false;

  /** true for an uploaded image, false for an asset from config  */
  @Input() public isImageField = true;

  /** local url for pending upload image */
  @Input() public blobUrl: string;

  /** local value override while uploading */
  @Input() public localValue: string;

  @Output() onImageDeleteClick = new EventEmitter();

  @ViewChild(AssetImageComponent) private imageChild;

  public showButtons: boolean;

  public assetImageWidth: number;

  public uploadIconSize: SizeProp;

  public showSelectionPopup = false;

  public cursor = 'pointer';

  private disposed = false;

  private localValue$: Subscription;

  private rowKey: string;

  protected loadingLargeImage = false;

  constructor(
    private imagePersistService: ImagePersistService,
    private recordPersistServicer: RecordPersistService,
    private localValuesStorageServiceService: LocalValuesStorageServiceService,
    private toasties: ToastyService,
    private imageStorageService: ImageStorageService,
    private cd: ChangeDetectorRef) {
    super();
  }

  public override ngOnInit(): void {

    try {
      super.ngOnInit();

      // Monitor local value changes
      this.localValue$ = this.localValuesStorageServiceService.changed$.pipe(
        filter((change) => {
          return change.key.recordId === this.record?._id &&
            change.key.fieldIdentifier === this.fieldModel.Identifier &&
            change.key.rowKey === this.rowKey;
        }),
        tap(change => {
          this.localValue = change.value;
          this.findBlobWhenValueChanges();
        })
      ).subscribe();

      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        this.disableCamera = true;
      }

      this.showButtons = (this.fieldModel.ImageOptions?.buttonMode === ImagaFieldButtonMode.Always);

      if (this.showButtons || this.fieldModel.IsExpression || this.getIsDisabled()) {
        this.cursor = 'zoom-in';
      }

      this.assetImageWidth = this.inline || this.forReport ?
        this.fieldModel.ImageOptions?.inlineWidth || 2 :
        this.fieldModel.ImageOptions?.formWidth || 12.5;

      // Choose an upload icon size roughly based on image width so we don't get too big on a small image
      this.uploadIconSize = this.assetImageWidth > 8 ? '5x' : '2x';

    } catch (error) {
      logError(error, 'Error in ImageFieldComponent.ngOnInit');
    }
  }

  override ngOnDestroy(): void {
    this.localValue$?.unsubscribe();
    this.disposed = true;
  }

  protected override onValueChanged(value: string) {
    super.onValueChanged(value);
    this.initializeValues(value);
  }

  public onImageFileSelectedHandler(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0] as File;
      this._showLatestImageAndSave(file);
    }
  }

  public toggleDeleteMode() {
    this.deleteMode = !this.deleteMode;
  }

  public getImageIdentifier() {
    if (this.value?.startsWith('/Asset/')) {
      return this.value.substr(7);
    } else if (/^([a-f0-9]{24})$/.test(this.value)) {
      return this.value;
    }

    return null;
  }

  public async onImageClickHandler(e: { $event: MouseEvent; url: SafeUrl }) {
    e.$event.stopPropagation();

    this.componentClicked$.next(this);

    // An image field could be set via an expression, this will make the field readonly.
    if (this.editMode === false || this.showButtons || this.fieldModel.IsExpression || this.getIsDisabled()) {
      // Permanent button mode so click opens full size view
      const url = await this.imageStorageService.getImageAssetUrlAsync(this.value, this.isImageField, 'full');
      this.appModel.globalModel.previewModel.openFullImage(url);
    } else {
      this.showSelectionPopup = true;
      this.deleteMode = false;
    }
  }

  public onNoImageClickHandler($event: MouseEvent) {
    $event.stopPropagation();

    if (!this.isDisabled()) {
      if (!this.showButtons) {
        this.showSelectionPopup = true;
      }
    }
  }

  public async zoomImage() {
    this.cd.markForCheck();
    this.loadingLargeImage = true;
    const url = this.globalModel.online.value
      ? await this.imageStorageService.getImageAssetUrlAsync(this.imageValue, this.isImageField, 'full')
      : await this.imageStorageService.getImageAssetUrlAsync(this.imageValue, this.isImageField, 'medium');
    this.loadingLargeImage = false;
    this.cd.markForCheck();
    this.selectorClosed();
    if (this.imageChild) {
      this.appModel.globalModel.previewModel.openFullImage(url);
    }
  }

  public fileDropped(event: any) {
    event.preventDefault();
    if (!this.isDisabled()) {
      const dt = event.dataTransfer;
      if (!dt.items) {
        // Use DataTransfer interface to access the file
        this._showLatestImageAndSave(dt.files[0]);
      } else if (dt.items.length > 0) {
        // Use DataTransferItemList interface to access the file
        if (dt.items.length > 0 && dt.items[0].kind === 'file') {
          const file = dt.items[0].getAsFile();
          this._showLatestImageAndSave(file);
        }
      }
    }
  }

  public fileDraggedOver(event: any) {
    event.preventDefault();
  }

  private _isValidImageFormat(fileType: string): boolean {
    return (
      fileType === 'image/png' ||
      fileType === 'image/gif' ||
      fileType === 'image/jpg' ||
      fileType === 'image/jpeg' ||
      fileType === 'image/jpe' ||
      fileType === 'image/bmp' ||
      fileType === 'image/svg' ||
      fileType === 'image/tiff'
    );
  }

  private _showLatestImageAndSave(file: File): void {
    this.selectorClosed();

    if (file.size > 30000000) {
      this.toasties.error($localize`Image size must be less than 30mbs`);
      return;
    }

    if (!this._isValidImageFormat(file.type)) {
      this.toasties.error($localize`Image is not the correct type`);
    } else {

      // Capture values while still in sync code, things may change while we are persisting
      const record = this.record;

      const imageDetails: ImageData = {
        appIdentifier: record.AppIdentifier,
        fieldIdentifier: this.fieldModel.Identifier,
        recordId: record._id,
        name: file.name,
        type: file.type,
        imageId: new ObjectID().toHexString(),
        rowKey: this.rowKey
      };

      this.uploadFile(imageDetails, file).catch(e => logError(e, 'Failed to upload file')); // completes asynchronously
    }
  }

  public async openCamera() {

    this.selectorClosed();

    const result = await this.globalModel.dialogAsync(CameraDialogComponent, {}, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
    });

    if (result?.cameraData) {
      // Capture values while still in sync code, things may change while we are persisting
      const cameraDetails: SaveCameraDetails = {
        appIdentifier: this.record.AppIdentifier,
        fieldIdentifier: this.identifier,
        recordId: this.record._id,
        imageId: new ObjectID().toHexString(),
        data: result.cameraData,
        blob: result.blob,
        rowKey: this.rowKey
      };

      this.uploadCapture(cameraDetails).catch(e => logError(e, 'Failed to upload capture'));    // completes async
    }
  }

  public onImageDeleteClickHandler() {
    try {
      this.selectorClosed();

      // Delete is only supported when attached to a record
      if (this.record) {

        // Capture values while still in sync code, things may change while we are persisting
        const details: DeleteImageDetails = {
          appIdentifier: this.record.AppIdentifier,
          fieldIdentifier: this.fieldModel.Identifier,
          recordId: this.record._id,
          imageId: this.getImageIdentifier(),
          rowKey: this.rowKey
        };

        this.deleteImage(details).catch(e => logError(e, 'Failed to delete image'));    // completes async
      }
    } catch (error) {
      logError(error, 'Error in onImageDeleteClickHandler');
    }
  }

  public selectorClosed() {
    this.showSelectionPopup = false;
  }

  public get imageValue() {
    if (this.blobUrl) {
      return this.blobUrl;
    }

    if (this.localValue !== null && this.localValue !== undefined) {
      return this.localValue;
    }

    return this.value;
  }

  public get hasImageValue() {
    return this.imageValue?.length > 0;
  }

  public showUploadIcon() {
    switch (this.containerType) {
      case ContainerType.FilterEdit:
        return false;
      default:
        return (this.editMode !== false) && !this.getIsDisabled();
    }
  }

  private async uploadFile(imageDetails: ImageData, file: File) {

    // Create an image blob for the new image
    this.blobUrl = this.imagePersistService.storeImage(imageDetails, file);
    this.invalidate();

    await this.localValuesStorageServiceService.setValueAsync(imageDetails, imageDetails.imageId);

    await this.imagePersistService.queueChange(imageDetails, file);
    this.appModel.globalModel.persistQueuedChanges();
  }

  private async uploadCapture(cameraDetails: SaveCameraDetails) {

    // Create an image blob for the new image
    this.blobUrl = this.imagePersistService.storeCameraImage(cameraDetails);
    this.invalidate();

    await this.localValuesStorageServiceService.setValueAsync(cameraDetails, cameraDetails.imageId);

    await this.imagePersistService.queueCameraChange(cameraDetails);
    this.appModel.globalModel.persistQueuedChanges();
  }

  private async deleteImage(details: DeleteImageDetails) {
    try {
      // clear blob for this image
      this.imagePersistService.clearImage(details);

      await this.localValuesStorageServiceService.setValueAsync(details, '');

      // Check we have an image id, it's possible to get the value async cleared
      // after we've started to delete
      if (details.imageId) {
        // // Queue deletion
        await this.imagePersistService.queueDelete(details);

        this.blobUrl = '';
        this.invalidate();

        // const localPatch = this.fieldModel.createPatch(record, '', this.listRow, { local: true });
        // this.recordPersistServicer.queueChange(localPatch);
      } else {
        // We didn't have an image - patch with blank to be sure it clears
        const patch = this.fieldModel.createPatch(this.record, '', this.listRow);
        await this.recordPersistServicer.queueChange(patch);
      }

      this.deleteMode = false;

      // Wake persister up
      this.appModel.globalModel.persistQueuedChanges();
    } catch (error) {
      logError(error, 'Failed to delete image');
      throw error;
    }
  }

  private initializeValues(newValue: string) {

    this.rowKey = this.fieldModel.rowKey(this.record, this.listRow);

    const key = {
      appIdentifier: this.record?.AppIdentifier,
      fieldIdentifier: this.fieldModel.Identifier,
      recordId: this.record?._id,
      rowKey: this.rowKey
    };

    this.localValuesStorageServiceService.getValueAsync(key).then(value => {
      if ((value && value === newValue) || (!value && !newValue)) {
        return this.localValuesStorageServiceService.deleteValueAsync(key).then(() => {
          this.localValue = undefined;
          this.findBlobWhenValueChanges();
        });
      } else {
        this.localValue = value;
        this.findBlobWhenValueChanges();
      }

      return null;
    }).catch(error => logError(error, 'Failed to get local storage value'));
  }

  private findBlobWhenValueChanges() {

    this.blobUrl = null;
    this.invalidate();

    this.imagePersistService.getImageUrl(this.localValue || this.value).then((blob) => {
      // Check not disposed as fast navigation might this
      // component is destroyed before the promise resolves.
      if (blob) {
        this.blobUrl = blob;
      }

      this.invalidate();
    }).catch(error => logError(error, 'Failed to get image url'));
  }

  private invalidate() {
    if (!this.disposed) {
      this.refresh();
    }
  }

}
