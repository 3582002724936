
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchAutoCompleteComponent } from './search-autocomplete.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayModule } from '@angular/cdk/overlay';
import { SearchModule } from '../search.component/search.module';

@NgModule({
  declarations: [
    SearchAutoCompleteComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ScrollingModule,
    OverlayModule,
    SearchModule
  ],
  exports: [
    SearchAutoCompleteComponent
  ],
  providers: [],
})
export class SearchAutoCompleteModule { }
