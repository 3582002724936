import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Form, Template, Record, TemplateReference, Enums, FileAttachment, TemplateLayout, tryGetCurrentUser, logError, LogLevel, StickyPosition, Style, ElementStyles, ErrorMap } from '@softools/softools-core';
import { FormBase } from 'app/softoolsui.module/form.component/form-base.component';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AppField } from 'app/types/fields/app-field';
import { Application } from 'app/types/application';
import { ContainerType } from '../fields/field-base';
import { RulesEngine } from 'app/mvc/rules/rules-engine';
import { RecordUpdateModel } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { RecordUpdateController } from 'app/mvc/records/record-update.controller';
import { BehaviorSubject } from 'rxjs';
import { FieldComponent } from '../fields2/field/field.component';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent extends FormBase implements OnInit, OnChanges {
  @ViewChildren('label', { read: ElementRef }) labels: QueryList<ElementRef>;
  @ViewChildren(FaIconComponent, { read: ElementRef }) faIcons: QueryList<ElementRef>;

  // private recordChangesSub: Subscription;

  public formModel: Form = null;
  public templateRowGroups = {};
  public showFieldInfo$ = new BehaviorSubject(false);
  public fieldInfoOverlayOriginEl;
  public selectedFieldForInfo: { field: AppField; templateIdentifier: string } = { field: undefined, templateIdentifier: '' };

  public override fieldTypes = Enums.FieldType;
  public hasAppStudioAccess = false;

  public app: Application;

  public formContainerType = ContainerType.Form;


  @Output() onToggleExpandedFormTemplate = new EventEmitter();
  @Output() onToggleExpandedFormTemplateHelp = new EventEmitter();
  @Output() onImageDelete = new EventEmitter();

  @Input() titleFieldText: string;
  @Input() record: Record;

  @Input() recordModel: RecordUpdateModel;

  @Input() recordUpdateController: RecordUpdateController;

  @Input() isLoading: boolean;
  @Input() expandedFormTemplates: Array<string> = [];
  @Input() expandedFormTemplateHelp: Array<string> = [];

  @Input() isOnline: boolean;

  @Input() public formValid: boolean;

  public isNewRecord = false;

  /** If set false, no template headers are shown and all template content is included
   * Useful for simple, fixed forms that do not need breaking down
   */
  @Input() public showTemplateHeaders = true;

  @Input() set form(value) {
    this.formModel = value;
  }

  public styles: ElementStyles;

  public rulesEngine: RulesEngine;

  public topTemplates: Array<TemplateReference> = [];

  public bottomTemplates: Array<TemplateReference> = [];

  public mainTemplates: Array<TemplateReference> = [];

  private activeFieldComponent: FieldComponent;

  constructor(public models: GlobalModelService, private element: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.isNewRecord = this.record?._new;
    const embedded = this.application && this.application.IsEmbedded;
    this.hasAppStudioAccess = !embedded && this.permissionsService.hasAppStudio;

    const globalModel = this.models.globalModel;
    this.subscribe(globalModel.modeCancelled$, () => {
      this.onModeCancelled();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['application']) {
        this.rulesEngine = new RulesEngine(this.application);
      }

      if (changes['record']) {
        this.isNewRecord = this.record?._new;
        this.rulesEngine?.recordChange(this.record, this.appModel.staticRecord.value);
      }

      if (changes['form'] || changes['record']) {
        this.buildTemplateGroups();
      }

      if (changes['appIdentifier'] || changes['form']) {
        this.setStyles();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  templateTrackByFn(_, item: TemplateReference) {
    return item.TemplateIdentifier;
  }

  templateRowGroupsTrackByFn(index, _) {
    return index;
  }

  rowGroupTrackByFn(_, item) {
    return item.FieldIdentifier;
  }

  public closeInfoPopup() {
    this.showFieldInfo$.next(false);
    this.refresh();
  }

  public get templates() {
    return this.application?.Templates;
  }

  /**
   * Get template references in display order
   */
  public sortedTemplateRefs(position?: StickyPosition): Array<TemplateReference> {
    try {
      if (this.formModel.FormTemplates) {
        const user = tryGetCurrentUser();
        return this.formModel.FormTemplates.filter(tr => tr.StickyPosition === position)
          .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
          .filter(tr => this.application.matchRule(user, this.record, this.isNewRecord,
            this.formModel.Rules?.find(r => r.TargetTemplateIdentifier === tr.TemplateIdentifier)) === false)
          ;
      }

      return [];
    } catch (err) {
      logError(err, 'sortedTemplateRefs error', LogLevel.error);
      return [];
    }
  }

  public getTemplate(templateIdentifer: string): Template {
    if (!this.templates) {
      return null;
    }

    const template = this.templates.find((t) => t.Identifier === templateIdentifer);
    return template;
  }

  public getMaxColumnsForTemplate(layouts: Array<TemplateLayout>) {
    return Math.max(...layouts.map((o) => o.ColumnId));
  }

  public setTimerClass(els: ElementRef<any>[], className = 'text-success') {
    els.forEach((el) => {
      if (el) {
        el.nativeElement.classList.remove('text-success');
        el.nativeElement.classList.remove('text-warning');

        el.nativeElement.classList.add(className);
        setTimeout(() => {
          el.nativeElement.classList.remove(className);
        }, 3000);
      }
    });
  }

  public showFieldInfoHandler(connectedEl, field, templateIdentifier) {
    this.fieldInfoOverlayOriginEl = connectedEl;
    this.selectedFieldForInfo.field = field;
    this.selectedFieldForInfo.templateIdentifier = templateIdentifier;
    this.showFieldInfo$.next(true);
  }

  public async goToAppStudio(field, templateIdentifier = null) {
    this.models.siteController.goToAppStudio(this.application.Identifier, {
      field: field,
      templateIdentifier: templateIdentifier
    });
  }

  private buildTemplateGroups() {
    if (this.formModel?.FormTemplates?.length > 0) {
      for (let i = 0; i < this.formModel.FormTemplates.length; i++) {
        const formTemplate = this.formModel.FormTemplates[i];
        const template = this.getTemplate(formTemplate.TemplateIdentifier);
        if (template?.Layout) {
          template.Layout.sort(function (a, b) {
            return a.RowId - b.RowId;
          });

          const templateRowGroupsObject = template.Layout.reduce(function (accumulator, tl) {
            accumulator['Row-' + tl.RowId] = accumulator['Row-' + tl.RowId] || ([] as Array<TemplateLayout>);
            accumulator['Row-' + tl.RowId].push(tl);
            return accumulator;
          }, Object.create(null));

          const templateRowGroupsArray = [] as Array<Array<TemplateLayout>>;
          // eslint-disable-next-line guard-for-in
          for (const templateRowGroup in templateRowGroupsObject) {
            const rowGroup = templateRowGroupsObject[templateRowGroup];
            rowGroup.sort(function (a, b) {
              return a.ColumnId - b.ColumnId;
            });
            templateRowGroupsArray.push(rowGroup);
          }

          this.templateRowGroups[template.Identifier] = templateRowGroupsArray;
        }
      }

      this.topTemplates = this.sortedTemplateRefs(StickyPosition.Top);
      this.mainTemplates = this.sortedTemplateRefs();
      this.bottomTemplates = this.sortedTemplateRefs(StickyPosition.Bottom);
    }
  }

  /** Build style list for form and contained fields */
  private setStyles() {
    if (this.application) {
      if (this.formModel?.NamedStyles) {
        this.styles = this.appModel.getNamedStyles({ target: 'form', names: this.formModel.NamedStyles });
      } else {
        this.styles = null;
      }
    }
  }

  public copyClick() {
    this.models.globalModel.showInfoToasty({ message: $localize`Identifier copied to clipboard` });
  }

  public onModeCancelled() {
    this.element.nativeElement.focus(null);
    if (this.activeFieldComponent?.fieldModel.clickToEdit) {
      this.onActivateComponent(null);
    }
  }

  public async onActivateComponent(component: FieldComponent) {
    if (this.activeFieldComponent !== component) {
      await this.activeFieldComponent?.activated(false);
      this.activeFieldComponent = component;
      if (component) {
        await component.activated(true);
        await component.activate();
      }
    }
  }
}
