import { Directive, OnDestroy, OnChanges, Input, Output, EventEmitter, TemplateRef, ViewContainerRef, Optional, SimpleChanges } from '@angular/core';
import { OverlayRef, GlobalPositionStrategy, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { Overlay } from '@angular/cdk/overlay';
import { Directionality, Direction } from '@angular/cdk/bidi';
import { ESCAPE } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';

// Pretending this is part of CDK with the intention of submitting
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/no-inferrable-types */

// Still todo: scroll strategy

/**
 * Directive to facilitate declarative creation of an Overlay using a GlobalPositionStrategy.
 */
@Directive({
  selector: '[cdk-global-overlay], [cdkGlobalOverlay]',
  exportAs: 'cdkGlobalOverlay'
})
export class CdkGlobalOverlayDirective implements OnDestroy, OnChanges {
  private _overlayRef: OverlayRef;
  private _templatePortal: TemplatePortal;
  private _hasBackdrop = false;
  private _lockPosition = false;
  private _backdropSubscription = Subscription.EMPTY;
  private _position: GlobalPositionStrategy;

  /** If set centres horizontally in the overlay; optionally value may be supplied as an offset */
  @Input('cdkCenterHorizontally') centerHorizontally: string = null;

  /** If set centres vertically in the overlay; optionally value may be supplied as an offset */
  @Input('cdkCenterVertically') centerVertically: string = null;

  @Input('cdkOverlayBottom') bottom: string | undefined;

  @Input('cdkOverlayTop') top: string | undefined;

  @Input('cdkOverlayLeft') left: string | undefined;

  @Input('cdkOverlayRight') right: string | undefined;

  /** The width of the overlay panel. */
  @Input('cdkOverlayWidth') width: number | string;

  /** The height of the overlay panel. */
  @Input('cdkOverlayHeight') height: number | string;

  /** The min width of the overlay panel. */
  @Input('cdkOverlayMinWidth') minWidth: number | string;

  /** The min height of the overlay panel. */
  @Input('cdkOverlayMinHeight') minHeight: number | string;

  /** The custom class to be set on the backdrop element. */
  @Input('cdkOverlayBackdropClass') backdropClass: string;

  // /** Strategy to be used when handling scroll events while the overlay is open. */
  // @Input('cdkOverlayScrollStrategy') scrollStrategy: ScrollStrategy =
  //   this._scrollStrategy();

  /** Whether the overlay is open. */
  @Input('cdkOverlayOpen') open: boolean = false;

  /** Whether or not the overlay should attach a backdrop. */
  @Input('cdkOverlayHasBackdrop')
  get hasBackdrop() { return this._hasBackdrop; }
  set hasBackdrop(value: any) { this._hasBackdrop = coerceBooleanProperty(value); }

  /** Whether or not the overlay should be locked when scrolling. */
  @Input('cdkOverlayLockPosition')
  get lockPosition() { return this._lockPosition; }
  set lockPosition(value: any) { this._lockPosition = coerceBooleanProperty(value); }

  /** Event emitted when the backdrop is clicked. */
  @Output() backdropClick = new EventEmitter<MouseEvent>();

  /** Event emitted when the position has changed. */
  @Output() positionChange = new EventEmitter<ConnectedOverlayPositionChange>();

  /** Event emitted when the overlay has been attached. */
  @Output() attach = new EventEmitter<void>();

  /** Event emitted when the overlay has been detached. */
  @Output() detach = new EventEmitter<void>();

  // TODO(jelbourn): inputs for size, scroll behavior, animation, etc.

  constructor(
    private _overlay: Overlay,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    //    @Inject(CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY) private _scrollStrategy,
    @Optional() private _dir: Directionality) {
    this._templatePortal = new TemplatePortal(templateRef, viewContainerRef);
  }

  /** The associated overlay reference. */
  get overlayRef(): OverlayRef {
    return this._overlayRef;
  }

  /** The element's layout direction. */
  get dir(): Direction {
    return this._dir ? this._dir.value : 'ltr';
  }

  ngOnDestroy() {
    this._destroyOverlay();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['open']) {
      this.open ? this._attachOverlay() : this._detachOverlay();
    }
  }

  /** Creates an overlay */
  private _createOverlay() {
    this._overlayRef = this._overlay.create(this._buildConfig());
  }

  /** Builds the overlay config based on the directive's inputs */
  private _buildConfig(): OverlayConfig {
    this._position = this._createGlobalPositionStrategy();
    const positionStrategy = this._position;
    const overlayConfig = new OverlayConfig({
      positionStrategy,
      //      scrollStrategy: this.scrollStrategy,
      hasBackdrop: this.hasBackdrop
    });

    if (this.width || this.width === 0) {
      overlayConfig.width = this.width;
    }

    if (this.height || this.height === 0) {
      overlayConfig.height = this.height;
    }

    if (this.minWidth || this.minWidth === 0) {
      overlayConfig.minWidth = this.minWidth;
    }

    if (this.minHeight || this.minHeight === 0) {
      overlayConfig.minHeight = this.minHeight;
    }

    if (this.backdropClass) {
      overlayConfig.backdropClass = this.backdropClass;
    }

    return overlayConfig;
  }

  private _createGlobalPositionStrategy(): GlobalPositionStrategy {

    const strategy = this._overlay.position().global();

    if (this.centerVertically !== null) {
      strategy.centerVertically(this.centerVertically);
    } else if (this.top) {
      strategy.top(this.top);
    } else if (this.bottom) {
      strategy.bottom(this.bottom);
    }

    if (this.centerHorizontally != null) {
      strategy.centerHorizontally(this.centerHorizontally);
    } else if (this.left) {
      strategy.top(this.left);
    } else if (this.right) {
      strategy.bottom(this.right);
    }

    return strategy;
  }

  /** Attaches the overlay and subscribes to backdrop clicks if backdrop exists */
  private _attachOverlay() {
    if (!this._overlayRef) {
      this._createOverlay();

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._overlayRef!.keydownEvents().subscribe((event: KeyboardEvent) => {
        if (event.keyCode === ESCAPE) {
          this._detachOverlay();
        }
      });
    } else {
      // Update the overlay size, in case the directive's inputs have changed
      this._overlayRef.updateSize({
        width: this.width,
        minWidth: this.minWidth,
        height: this.height,
        minHeight: this.minHeight,
      });
    }

    // this._position.withDirection(this.dir);
    this._overlayRef.setDirection(this.dir);

    if (!this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._templatePortal);
      this.attach.emit();
    }

    if (this.hasBackdrop) {
      this._backdropSubscription = this._overlayRef.backdropClick().subscribe(event => {
        this.backdropClick.emit(event);
      });
    }
  }

  /** Detaches the overlay and unsubscribes to backdrop clicks if backdrop exists */
  private _detachOverlay() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this.detach.emit();
    }

    this._backdropSubscription.unsubscribe();
  }

  /** Destroys the overlay created by this directive. */
  private _destroyOverlay() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }

    this._backdropSubscription.unsubscribe();
  }
}
