import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeItemCardComponent } from './home-item-card.component';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FontAwesomeModule,
  ],
  declarations: [
    HomeItemCardComponent
  ],
  exports: [
    HomeItemCardComponent
  ]
})
export class HomeItemCardModule { }
