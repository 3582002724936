import { NgModule } from '@angular/core';
import { InputIconComponent } from './input-icon.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [InputIconComponent],
  exports: [InputIconComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatTooltipModule,
  ]
})
export class InputIconModule {

}
