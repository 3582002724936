import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { tryGetCurrentUser, logError } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { UsersService } from 'app/services/users.service';
import { BaseGuard } from './base.guard';

@Injectable()
export class UsersGuard extends BaseGuard implements CanActivate {

  @InjectService(UsersService)
  private _usersService: UsersService;

  public async canActivate(): Promise<boolean | UrlTree> {
    try {
      // If user is not logged in don't refresh the list as we won't be authenticated
      // Other guards e.g. AuthGuard should determine whether we can run unauthorised
      const user = tryGetCurrentUser();
      if (!user) {
        return true;
      }

      const users = await this._usersService.getAll();
      return users?.length > 0;
    } catch (error) {
      logError(error, 'MappedUsersExistsGuard rejected navigation due to error');
      throw error;
    }
  }
}
