import App from './my-profile-app.config.json';

import { Injectable } from '@angular/core';
import { Record, UserStorageService, UsersRepository, tryGetCurrentUser, TeamsStorageService, logError, PermissionEnums } from '@softools/softools-core';
import { UsersApplicationBase } from './users-app';
import { PermissionsService } from 'app/services/permissions.service';
import { TeamsService } from 'app/services/teams.service';
import { HomepagesService } from 'app/services/homepages.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { AppCapabilities } from 'app/types/application';

@Injectable({ providedIn: 'root' })
export class MyProfileApp extends UsersApplicationBase {

  constructor(
    usersService: UserStorageService,
    usersRepository: UsersRepository,
    teamsService: TeamsService,
    teamsStorageService: TeamsStorageService,
    permissionsService: PermissionsService,
    homepagesService: HomepagesService,
    recordPersistService: RecordPersistService,
    models: GlobalModelService,
  ) {
    // Start with users app
    super(usersService, usersRepository, teamsService, teamsStorageService,
      permissionsService, homepagesService, recordPersistService, models);

    try {
      // Modify to turn into My Profile
      this.Identifier = 'Softools.MyProfile';
      this.Title = $localize`My Profile`;
      this.IsSingletonApp = true;

      // My Profile doesn't count as a settings app
      this.IsSettingsApp = false;

      // No reports for a single record
      this.Reports = [];

      // Add extra fields, templates and forms etc.
      this.Fields = [...this.Fields, ...App.Fields];
      this.Templates = [...this.Templates, ...App.Templates];
      this.Forms = [...this.Forms, ...App.Forms];
      this.LogicBlocks = [...this.LogicBlocks, ...App.LogicBlocks];
      this.Styles = [...this.Styles, ...App.Styles];

      // Remove signup flags as they are on the licensing form for my profile
      const userTemplate = this.Templates.find(template => template.Identifier === 'User');
      userTemplate.Layout = userTemplate.Layout.filter(layout => layout.FieldIdentifier !== 'GDPRSignedUp' && layout.FieldIdentifier !== 'LicensingSignedUp');

    } catch (error) {
      logError(error, 'Error creating my profile app');
    }
  }

  // @ts-ignore Override of proeprty to accessor is an error in ts now
  public get StaticRecordId() {
    const user = tryGetCurrentUser();
    return user.Id;
  }

  public override async eachRecord(callback: (record: Record) => any): Promise<void> {
    const user = await this.userStorageService.getUser(this.StaticRecordId);
    if (user) {
      callback(this.toRecord(user));
    }
  }

  public override get visible() {
    // App is not shown in springboard, present in cog menu instead
    return false;
  }

  public override get permissions() {
    return [];    // no permissions required for my profile
  }

  public override get permitted() {
    return true;    // All users can run my profile
  }

  public override  get capabilities(): AppCapabilities {
    return {
      ...super.capabilities,
      impliedPermissions: [{
        Code: PermissionEnums.Records.Update
      }]
    }
  }

}
