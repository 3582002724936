import { Observable } from 'rxjs';
import { filter, first, mergeMap, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HEADER_TENANT_KEY, STORAGE_TENANT_KEY } from './_constants/constants.keys';
import { AuthInterceptorBase } from './auth-interceptor-base';
import { GlobalModelService } from './mvc/common/global-model.service';
import { GlobalModel } from './mvc';

/**
 * Inject authenrication token into HTTP requests.
 * This is not responsible for checking the token is valid, so should come after
 * AuthCheckHttpInterceptor which does so
 */
@Injectable({ providedIn: 'root' })
export class AuthHttpInterceptor extends AuthInterceptorBase implements HttpInterceptor {

  private globalModel: GlobalModel;

  constructor(
    models: GlobalModelService) {
    super();

    this.globalModel = models.globalModel;
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const tenant = localStorage.getItem(STORAGE_TENANT_KEY);

    return this.isAnonymous(request) ? next.handle(request) :
      this.globalModel.accessTokens.$.pipe(
        filter(tokens => !!tokens.token),
        first(),
        mergeMap(tokens => {
          if (tokens?.token && tenant) {
            request = request.clone({
              headers: request.headers.set('Softools-Authorization', tokens.token).set(HEADER_TENANT_KEY, tenant),
            });
          }
          return next.handle(request);
        })
      );
  }
}
