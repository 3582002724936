import { Injectable } from '@angular/core';
import { Comment } from '../types/interfaces';
import { Observable } from 'rxjs';
import { CommentsRepository } from '../repos';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(
    private commentsRepository: CommentsRepository
  ) { }

  public getComments(appIdentifier: string, recordId: string): Observable<Array<Comment>> {

    const comments = this.commentsRepository.getComments(appIdentifier, recordId);

    return comments;
  }

  public saveComment(appIdentifier: string, recordId: string, comment: Comment): Observable<string> {
    return this.commentsRepository.createComment(appIdentifier, recordId, comment);
  }

  public deleteComment(appIdentifier: string, recordId: string, comment: Comment): Observable<string> {
    return this.commentsRepository.deleteComment(appIdentifier, recordId, comment.Id);
  }
}
