<div class="d-flex"
  class="d-flex w-100"
  [ngClass]="alignment"
  >
  <mat-checkbox
    class="form-control  form-control-o no-bottom-border bit-field-checkbox"
    [checked]="value$|async"
    [disableRipple]="true"
    [disabled]="getIsDisabled()"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"
    (click)="onClicked($event)">
  </mat-checkbox>
</div>
