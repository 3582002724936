import { IIdentifierContainer } from './identifier-container.interface';
import { IStyleName } from './style-name.interface';

export enum RuleActionType {
  ReadOnly = 0,
  Hidden = 1,
  HideAdd = 2,
  HideDelete = 3,
  Style = 4
}

export interface RuleAction {
  Type: RuleActionType;
  NamedStyle?: string;
  NamedStyles?: Array<IStyleName>;
}

/**
 * Rule definition.
 * This rule schema will be replaced by the ongoing rule engine development.
 */
export interface Rule {

  /** Expression to match to apply rule */
  Filter: string;

  /** list of fields that the rule appies to  */
  TargetFields: Array<IIdentifierContainer>;

  /**
   * If defined, the rule applies to list subfields within the @see TargetField
   * list rather than the referenced fields.
   * Multiple fields withthe same set of of subfields can be matched in a single
   * rule; subfields that are not part of the target field are ignored.
   */
  TargetSubFields?: Array<IIdentifierContainer>;

  /** Actions to take if the rule context applies */
  // Action: Array<RuleAction | string>;   // allow string or json parse barfs
  Actions: Array<RuleAction>;

  /**
   * If true the rule applies individually to the rows of a list field.
   * The target field must identify a single list field.  Multiple target fields as not supported.
   * The Rule can reference subfields of the list field; the value is evaludated based on the values
   * for each row.  It can also reference top level fields e.g.
   *    "Complete eq 'yes' and Item_Status ne 'error'"
   * When a row is matched, actions apply to the whoel row if TargetSubField is not set, or to the
   * named subfields on the row if specified.
   */
  MultiRow?: boolean;
}
