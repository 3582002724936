
<div class="d-flex">

  <app-input-icon icon="calendar"
    [clickable]="!getIsDisabled()"
    [elementStyles]="elementStyles"
    (iconClicked)="openPicker($event)">
    <input #dateinput
      [readonly]="true"
      [id]="uiIdentifier"
      [ngModel]="dateTime"
      #date="ngModel"
      [matDatepicker]="datepicker"
      (ngModelChange)="setEditedValue($event)"
      [ngStyle]="textStyle"
      (click)="openPicker($event)"
      (keydown)="onKeyDown($event)"
      (keypress)="onKeyPress($event)"
      (keyup)="onKeyUpHandler($event, dateinput)"
      (focus)="hasFocus = true"
      (blur)="hasFocus = false"
      [class.inline-control]="inline"
      [class.readonly-normal-appearance]="!getIsDisabled()"
      class="pe-4 overflow-hidden form-control  form-control-o"
      [disabled]="isDisabled()"
    />
    <mat-datepicker
    #datepicker
    (closed)="dateChanged();"></mat-datepicker>
  </app-input-icon>

  <fa-icon *ngIf="fieldModel.ShowCalendarEvent"
    class="pointer mx-1 clear-button st-text-theme-primary"
    [icon]="['fas', 'arrow-circle-down']"
    title="Download iCal file" i18n-title
    (click)="downloadiCalClicked($event)"
  >
  </fa-icon>

  <fa-icon *ngIf="showClear && !getIsDisabled()"
    class="pointer mx-1 text-muted clear-button"
    [icon]="['fal', 'times-circle']"
    title="Clear Date"
    i18n-title
    [hidden]="required"
    (click)="clearDate(); $event.stopPropagation()"
    [style.opacity]="hasFocus || true ? 1: 0">
  </fa-icon>

</div>
