import { NgModule } from '@angular/core';
import { FilterheaderComponent } from './filterheader.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from '../checkbox.component/checkbox.module';
import { SortableModule } from '../listreport.component/sortable.component/sortable.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SummaryExpressionModule } from '../summary-expression/summary-expression.module';
import { ReportColumnHeaderModule } from '../report-column-header/report-column-header.module';

@NgModule({
  declarations: [
    FilterheaderComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    CheckboxModule,
    SortableModule,
    FontAwesomeModule,
    MatTooltipModule,
    ReportColumnHeaderModule,
    SummaryExpressionModule
  ],
  exports: [
    FilterheaderComponent,
  ],
})
export class FilterheaderModule {
}
