import { Component, ComponentRef, OnInit } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { TextDisplayFieldComponent } from 'app/softoolsui.module/fields2/text-display-field/text-display-field.component';
import { CardAppFieldComponent } from '../card-app-field/card-app-field.component';

@Component({
  selector: 'app-card-header-app-field',
  templateUrl: '../../fields2/field/field.component.html',
  styleUrls: ['./card-header-app-field.component.scss', '../../fields2/field/field.component.scss']
})
export class CardHeaderAppFieldComponent extends CardAppFieldComponent {

  protected override addComponentForField(): ComponentRef<any> {

    switch (this.fieldModel.Type) {
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
      case Enums.FieldType.Team:
        return this.addReadonlyChildComponent(TextDisplayFieldComponent);

      default:
        return super.addComponentForField();
    }
  }
}
