import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportComponent } from './export.component';
import { DropDownModule } from '../dropdown.component/dropdown.module';
import { CheckboxModule } from 'app/softoolsui.module/checkbox.component/checkbox.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ExportComponent],
  imports: [
    CommonModule,
    DropDownModule,
    CheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    ExportComponent
  ],
  providers: [],
})
export class ExportModule { }
