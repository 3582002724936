
import { Component, ViewChild, ChangeDetectionStrategy, Inject, ViewChildren, ViewContainerRef, QueryList, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { mappedAccessTypes, SourceType, TrackChangeList, stringCompare } from '@softools/softools-core';
import { initialAccessRight, mappedSourceTypes, mappedActorTypes, AccessRight, ActorType, GlobalHelperService } from '@softools/softools-core';
import { GlobalModel } from 'app/mvc';
import { RecordPatch } from 'app/workspace.module/types';

export class SecurityComponentConfig {
  constructor(
    public users: Array<{ Text: string; Value: string; }>,
    public teams: Array<{ Text: string; Value: string; }>,
    public dataRows: any[]
  ) { }
}

export interface SecurityComponentResult {
  patch?: RecordPatch;
  rights?: Array<AccessRight>;
}

/**
 * Editor for record security permissions.  Implemented to be contained with a
 * Material or Modal dialog (the latter is deprecated and will be removed)
 *
 * The main UI element is a list of AccessRight objects that the user can
 * manipulate.  A slight complication is that the Actor column can contain
 * users or teams according to the actor type setting which requires us to
 * dyamically change the select list on the record according to that setting.
 *
 * As the user changes the collection, a RecordPatch is generated which can
 * be used to modify a single record.  The modified set of access rights are
 * also returned which is suitable for the bulk change API.
 */

/*
 The record id is not consistently managed.  The server side DTO has an Id
 member, but the get method returns it as an _id member with a $oid field.
 We make sure both are present and use the simpler Id variant for tracking changes.
*/
@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityComponent {

  private static _securityDialog: MatDialogRef<SecurityComponent>;

  public config: SecurityComponentConfig;
  public displayedColumns = ['AccessType', 'SourceType', 'ActorType', 'ActorId', 'RemoveColumn'];
  public selectLists = {};
  public actorTypeEnums = ActorType;

  @ViewChild('appForm') appForm;

  @ViewChildren('matrow', { read: ViewContainerRef }) rows: QueryList<ViewContainerRef>;

  private _patch: RecordPatch;

  public static async openAsync(globalModel: GlobalModel, config: SecurityComponentConfig) {
    return await globalModel.dialogAsync(SecurityComponent, config);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SecurityComponentConfig,
    private globalHelperService: GlobalHelperService,
    private cdr: ChangeDetectorRef
  ) {
    if (data) {
      this.init(data);
    }

    // Initialise an empty patch.  This is not attached to a specific record so must be updated by the caller if it is to be used
    this._patch = new RecordPatch('', '');
  }

  public init(config: SecurityComponentConfig): void {
    this.config = config;
    this._setupSelectLists();

    // Initialise data records
    this.config.dataRows = this.config.dataRows.map(right => ({
      ...right,
      Id: right.Id || right._id.$oid // Id mapping is inconsistent.  Make sure we both Id & _id members
    }));
  }

  /*
    Ok button click will returnValue
  */
  public get returnValue(): SecurityComponentResult {
    return {
      patch: this._patch,
      rights: this.config.dataRows,
    };
  }

  public onCellUpdateHandler(row: AccessRight, changeIdentifier: string, $event: MatSelectChange): void {
    // Need to check OID because it gets treated as a record which doesn't have Id.  Yuck.
    const id = row.Id || row._id.$oid;
    const updatedValue = $event.value;

    const changes = {};
    changes[changeIdentifier] = updatedValue;

    // If we have made any change the SourceType now should be Record
    row.SourceType = SourceType.Record;

    if (changeIdentifier === 'ActorType') {
      // If we have made ActorType change then set ActorId default
      if (updatedValue === ActorType.User) {
        row.ActorId = this.selectLists['Users'][0].Value;
      } else if (updatedValue === ActorType.Team) {
        row.ActorId = this.selectLists['Teams'][0].Value;
      } else {
        row.ActorId = '';
      }
      changes['ActorId'] = row.ActorId;
    }


    this._patchChangeAccessRight(id, changes);
  }

  public onAddRowClickHandler(): void {
    const accessRight = this._createNewAccessRights();
    this._patchCreateAccessRight(accessRight);

    const newDataRows = [...this.config.dataRows];
    newDataRows.push(accessRight);
    this.config.dataRows = newDataRows;
    this.scrollToBottom();
  }

  public onRemoveRowClickHandler($event: AccessRight): void {
    const id = $event.Id || $event._id.$oid;
    this._patchDeleteAccessRight(id);

    const newDataRows = [...this.config.dataRows];
    const index = newDataRows.findIndex((r: AccessRight) => r.Id === id);
    newDataRows.splice(index, 1);
    this.config.dataRows = newDataRows;
  }

  public getRowIsDisabled(row: AccessRight): boolean {
    return row.ActorType === ActorType.Owner;
  }

  public getUserTeamIsDisabled(row: AccessRight): boolean {
    return !(row.ActorType === ActorType.User || row.ActorType === ActorType.Team);
  }

  private _patchCreateAccessRight(row: AccessRight): void {
    const tracker = new TrackChangeList('Id').addAdditionalRow(<any>row);
    const patch = new RecordPatch('', '').addTrackedChange('AccessRights', tracker);
    this._patch.merge(patch);
  }

  private _patchDeleteAccessRight(id: string): void {
    const tracker = new TrackChangeList('Id').addRemovalKey(id);
    const patch = new RecordPatch('', '').addTrackedChange('AccessRights', tracker);
    this._patch.merge(patch);
  }

  private _patchChangeAccessRight(id: string, change: any): void {
    const tracker = new TrackChangeList('Id').addChange(id, change);
    const patch = new RecordPatch('', '').addTrackedChange('AccessRights', tracker);
    this._patch.merge(patch);
  }

  private _createNewAccessRights(): AccessRight {
    const id = this.globalHelperService.makeRandomTextId(8);
    const accessRight: AccessRight = {
      ...initialAccessRight,
      Id: id, // Needed for server side Dto
      _id: { $oid: id },
    };

    accessRight.AccessType = mappedAccessTypes.filter(o => o.Text === 'Full')[0].Value;
    accessRight.SourceType = mappedSourceTypes.filter(o => o.Text === 'Record')[0].Value;
    accessRight.ActorType = mappedActorTypes.filter(o => o.Text === 'Team')[0].Value;
    accessRight.ActorId = this.selectLists['Teams'][0].Value;
    return accessRight;
  }

  private _setupSelectLists(): void {
    // *** Todo: not i18n ***
    const actorTypes = [...mappedActorTypes];
    if (this.config.teams.length <= 0) {
      // Remove Team actor option when no teams present
      actorTypes.splice(actorTypes.findIndex(at => at.Value === ActorType.Team), 1);
    }

    this.selectLists = {
      'AccessTypes': mappedAccessTypes,
      'SourceTypes': mappedSourceTypes,
      'ActorTypes': actorTypes,
      'OwnerActorTypes': [{ Text: 'Owner', Value: ActorType.Owner }],
      'Users': this.config.users.sort((a, b) => stringCompare(a.Text, b.Text)),
      'Teams': this.config.teams.sort((a, b) => stringCompare(a.Text, b.Text)),
      'All': [{ text: 'All', value: 'All' }]
    };
  }

  private scrollToBottom() {
    this.cdr.detectChanges();
    this.rows?.last?.element.nativeElement.scrollIntoView(true);
  }
}
