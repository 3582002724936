import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableReportComponent } from './table-report.component';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReportColumnHeaderModule } from '../report-column-header/report-column-header.module';
import { WsSharedModule } from 'app/workspace.module/components/ws-shared/ws-shared.module';
import { CheckboxModule } from '../checkbox.component/checkbox.module';
import { Fields2Module } from '../fields2/fields2.module';
import { TableReportFieldComponent } from './table-report-field/table-report-field.component';
import { BusyIndicatorModule } from '../busy-indicator/busy-indicator.module';
import { GroupHeaderModule } from '../group-header/group-header.module';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';
import { PopupContainerModule } from '../popup-container/popup-container.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { FiltersModule } from 'app/filters/filters.module';
import { SofSpinnerModule } from 'app/design/icons';

@NgModule({
  declarations: [
    TableReportComponent,
    TableReportFieldComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    FontAwesomeModule,
    ScrollingModule,
    ReportColumnHeaderModule,
    WsSharedModule,
    Fields2Module,
    CheckboxModule,
    BusyIndicatorModule,
    GroupHeaderModule,
    MvcDirectivesModule,
    OverlayModule,
    PopupContainerModule,
    FiltersModule,
    SofSpinnerModule
  ],
  exports: [
    TableReportComponent
  ]
})
export class TableReportModule { }
