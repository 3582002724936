<section *ngIf="chartReportModel as model" class="report-grid-container">

  <ng-container *ngIf="!(reportModel.loadError.$| async)">
    <div [vxWatch]="reportModel.editMode" class="report-controls-area">
      <app-ws-sharedreportactions
        [app]="currentApp"
        [reportModel]="reportModel"
        [filterModel]="filterModel"
        [isLoading]="model.busy.busy$|async"
        [filter]="filterModel.reportFilter.$|async"
        [selectedFilter]="(filterModel.reportFilter.$ | async)"
        [showFilterManagement]="this"
        [isArchived]="globalModel.archived.$|async"
        [showInlineEditSwitch]="(model.dualReportModel$|async)?.isInlineEditSupported()"
        [isEditMode]="reportModel.editMode.value"
        [showSelected]="model.isDual.$|async"
        [selectedCount]="(model.dualReportModel$|async)?.selectedCount.$|async"
        [allRecordsSelected]="model.dualReportModel$.value?.selectionModel.all.$|async"
        [isSearchable]="appSearchable"
        [isReportOption]="model.isDual.$|async"
        [baseFilter]="(reportModel.report.$|async)?.BaseFilter"
        [subtitle]="(reportModel.report.$|async)?.SubTitle"
        [searchText]="reportModel.searchTerm.$|async"
        (onDeselectAllHandler)="model.dualReportModel$.value?.selectionModel.deselectAll()"
        (onEditModeHandler)="toggleEditMode()"
        (onToggleReportOptions)="(model.dualReportModel$.value)?.toggleAutoLayout()"
        (onArchivedRecordsBadgeClickHandler)="onArchivedRecordsBadgeClickHandler()"
        (onSearchEnterClickHandler)="onSearchEnterClickHandler($event)"
        (onSearchEscClickHandler)="onSearchEscClickHandler()">
      </app-ws-sharedreportactions>
    </div>

    <app-folders
      #leftAside
      class="left-aside-area"
      [folderModel]="pageModel.folderModel"
      [selectedFolderIdentifier]="reportModel.reportIdentifier.$|async"
      [title]="currentApp?.Title"
      [showAddNewRecord]="canCreate"
      [appIdentifier]="appIdentifier"
      [appIdentifiers]="appIdentifiers"
      [pinnedStateVal]="showAside$ | async">
    </app-folders>

    <div class="main-content-area report-content">
        <ng-container>
          <article [vxWatch]="reportModel.editMode" class="report-article h-100 w-100"
            cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin">
            <app-report
              [chartReportModel]="chartReportModel"
              [reportModel]="model"
              [dualReportModel]="model.dualReportModel$|async"
              [filterModel]="filterModel"
              [isDual]="model.isDual.$|async"
              [dualReportStyle]="model.dualReportStyle.$|async"
              [isOfflineReport]="isOfflineReport$|async"
              [editMode]="reportModel.editMode.value"
            >
            </app-report>
          </article>
        </ng-container>
    </div>

    <div class="footer-area">
      <app-shared-footer
        [appModel]="appModel"
        [appIdentifier]="appIdentifier"
        [showAside]="showAside$|async"
        [showReportPaneSelector]="(globalModel.isSmallDisplay.$|async) && (model.isDual.$|async)"
        [zoomReportPane]="reportModel.zoomPane.$|async"
        [showFooter]="showFooter"
        [isOnline]="(onlineStatus.isServerReachable$|async)"
        (reportPaneSelectorChanged)="setZoomPane($event)"
        >
        <ng-container *ngIf="model.isDual.$|async">
          <ng-container *ngIf="model.dualReportModel$|async as model2">
            <ng-container *ngIf="(model2.records.$ | async)?.length > 0  && !isSingletonApp">
              <app-list-report-paginator *ngIf="model2.report.value?.Type === Enums.ReportTypes.List"
                [reportModel]="model2"
                [isBusy]="model2.accessor?.countBusy$|async"
                [count]="model2.totalCount.$|async"
                [skip]="model2.topIndex.$|async"
                [top]="model2.pageSize.$|async"
                >
              </app-list-report-paginator>
              <app-table-report-paginator *ngIf="model2.report.value?.Type === Enums.ReportTypes.Table"
                [reportModel]="model2"
              >
              </app-table-report-paginator>
            </ng-container>
          </ng-container>
        </ng-container>

      </app-shared-footer>
    </div>

    <div [@quickFilters]="quickFilterOpenState$|async" class="quick-filters">
      <app-quick-filter *ngIf="isQuickFilterVisible$|async"
        [reportModel]="reportModel"
        [filterModel]="filterModel"
        [reportController]="reportController"
        (close)="quickFilterClosed()"
        (showSaved)="quickToFilterManagement()"
      ></app-quick-filter>
    </div>

    <div [@filterMgt]="filterManageOpenState$|async" class="saved-filters">
      <app-filter-management-panel *ngIf="isFilterManagementPanelVisible$|async"
        [appModel]="appModel"
        [filterModel]="filterModel"
        [showFilterManagement]="this"
        [isOnline]="appModel.globalModel.online.$|async"
        [selectedFilter]="filterModel.reportFilter.$|async"
        [appIdentifier]="(appModel.app.$|async)?.Identifier"
        [appFields]="(appModel.app.$|async)?.AppFields  "
        [report]="reportModel.report.$|async"
        [reportFields]="(reportModel.report.$|async)?.ListReportFields"
        [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
      </app-filter-management-panel>
    </div>
  </ng-container>

  <app-popup-container *ngIf="model.dualReportModel$|async as model"
  [isOpen]="(model.filterEditorUi.$|async)?.showPopup"
  [connectedOrigin]="(model.filterEditorUi.$|async)?.popupOrigin"
  [connectedOffsetX]="(model.filterEditorUi.$|async)?.popupX"
  [connectedOffsetY]="(model.filterEditorUi.$|async)?.popupY"
  (closed)="model.closeFilterEditor()">
  <ng-template>
    <sof-filter-edit
      [filterModel]="filterModel"
      [reportModel]="reportModel"
      [filterSpec]="(model.filterEditorUi.$|async)?.editFilterSpec"
      [filterEditorUi]="model.filterEditorUi.$|async"
      [appIdentifier]="appIdentifier"
      (termUpdated)="model.updateFilterTerms($event);"
      (cancelled)="model.closeFilterEditor()">
    </sof-filter-edit>
  </ng-template>
</app-popup-container>

</section>

<!-- <app-popup-container *ngIf="reportModel.filterEditorUi.$|async as fui"
  [isOpen]="fui.showPopup"
  [connectedOrigin]="fui.popupOrigin"
  [connectedOffsetX]="fui.popupX"
  (closed)="reportModel.closeFilterEditor()">
  <ng-template>
    <sof-filter-edit
      [filterControl]="filterModel"
      [filterSpec]="fui.editFilterSpec"
      [filterEditorUi]="fui"
      (termUpdated)="reportModel.updateFilterTerms($event);"
      (cancelled)="reportModel.closeFilterEditor()">
    </sof-filter-edit>
  </ng-template>
</app-popup-container> -->

<div class="new-rec-container" [class.quick-open]="isQuickFilterVisible$|async">
  <app-new-record-button [canCreate]="canCreate" [createUrl]="createUrl" [floater]="false"></app-new-record-button>
</div>
