import * as moment from 'moment';
import { Enums, IFilterTerm, MappedUser, OdataExpressionType } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';
import { extractDecimal } from 'app/_constants';

const expressionTypeToOperator = new Map<OdataExpressionType, string>([
  [OdataExpressionType.Equals, 'eq'],
  [OdataExpressionType.NotEquals, 'ne'],
  [OdataExpressionType.GreaterThan, 'gt'],
  [OdataExpressionType.GreaterThanOrEqual, 'ge'],
  [OdataExpressionType.LessThan, 'lt'],
  [OdataExpressionType.LessThanOrEqual, 'le'],
  // One/None shouldn't appear as a normal expression
  // but fall back to eq/ne just in case
  [OdataExpressionType.OneOf, 'eq'],
  [OdataExpressionType.NoneOf, 'ne'],
  [OdataExpressionType.ContainsOneOf, 'eq'],
  [OdataExpressionType.ContainsNoneOf, 'ne'],
]);

export class FilterTerm implements IFilterTerm {

  id?: number;
  FieldIdentifier: string;
  Operator: OdataExpressionType;
  Operand: any;
  displayOperand?: any;

  constructor(term?: IFilterTerm) {
    if (term) {
      Object.assign(this, term);
    }
  }

  public get isMultiValue() {
    return this.Operator === OdataExpressionType.OneOf ||
      this.Operator === OdataExpressionType.NoneOf ||
      this.Operator === OdataExpressionType.ContainsOneOf ||
      this.Operator === OdataExpressionType.ContainsNoneOf ||
      this.Operator === OdataExpressionType.Between;
  }

  public format(appFields: Array<AppField>) {
    const id = this.FieldIdentifier;
    let fieldType: Enums.FieldType;
    let field: AppField;

    if (id === 'QuickFilterSearchText') {
      fieldType = Enums.FieldType.Text;
    } else {
      field = appFields.find(f => f.Identifier === id);
      fieldType = field?.Type;
    }

    if (this.Operator === OdataExpressionType.Between) {
      return this.formatBetween(field, this.Operand);
    } else if (this.isMultiValue) {
      return this.formatMultiValue(field, this.Operand);
    } else {
      const literal = this._formatOperand(fieldType, this.Operand, field);
      return this.formatExpression(this.Operator, id, literal);
    }
  }

  private formatExpression(operator: OdataExpressionType, id: string, literal: string) {
    switch (operator) {
      case OdataExpressionType.StartsWith:
        return `startswith([${id}],${literal})`;
      case OdataExpressionType.EndsWith:
        return `endswith([${id}],${literal})`;
      case OdataExpressionType.Substring:
        // nb parameter order may not be what you expect...
        return `substringof(${literal},[${id}])`;
      default:
        const op = expressionTypeToOperator.get(operator);
        return `[${id}] ${op} ${literal}`;
    }
  }

  private formatMultiValue(field: AppField, operands: any | Array<any>) {
    const id = field.Identifier;
    const op = this.defaultOperator(field);   // operator for each subexpression
    const expr = Array.isArray(operands)
      ? operands.map((operand) => {
        const literal = this._formatOperand(field.Type, operand, field);
        return this.formatExpression(op, id, literal);
      }).join(' or ')
      : this.formatExpression(op, id,
        this._formatOperand(field.Type, operands, field)
      );

    switch (this.Operator) {
      case OdataExpressionType.OneOf:
      case OdataExpressionType.ContainsOneOf:
        return `(${expr})`;
      case OdataExpressionType.NoneOf:
      case OdataExpressionType.ContainsNoneOf:
        return `(not(${expr}))`;
      default:
        throw new Error('Unreachable');
    }
  }

  private formatBetween(field: AppField, operands: any | Array<any>) {
    // Between is only permitted if there are exactly two operands, with values,
    // and the first is <= the second.
    if (Array.isArray(operands)
      && operands.length === 2
      && operands[0] && operands[1]
      && (field.compareValues(operands[0], operands[1], true) >= 0)) {
      const lower = this._formatOperand(field.Type, operands[0], field);
      const upper = this._formatOperand(field.Type, operands[1], field);
      const id = field.Identifier;
      const expr = [
        this.formatExpression(OdataExpressionType.GreaterThanOrEqual, id, lower),
        this.formatExpression(OdataExpressionType.LessThanOrEqual, id, upper),
      ].join(' and ');

      return expr;
    }

    return null;
  }

  // copied from QuickFilterFieldComponent, todo make common
  private defaultOperator(field: AppField) {
    switch (field.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Email:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Barcode:
      case Enums.FieldType.ConcealedText:
        return OdataExpressionType.Substring;
      case Enums.FieldType.DateTime:
        // Nearly impossible to do exact match so default to ≥
        return OdataExpressionType.GreaterThanOrEqual;
      default:
        return OdataExpressionType.Equals;
    }
  }

  private _formatOperand(type: Enums.FieldType, value: any, field?: AppField): string {

    let literal: string;

    if (value !== undefined) {
      // Get the literal value according to type.  OData is a bit fussy.
      literal = String(value);
      switch (type) {
        case Enums.FieldType.Bit:
          literal = value ? 'true' : 'false';
          break;
        case Enums.FieldType.Integer:
        case Enums.FieldType.Long:
        case Enums.FieldType.Number:
        case Enums.FieldType.Time:
        case Enums.FieldType.Range:
          // Number-like so just leave as the value
          break;

        case Enums.FieldType.Money:
          literal = String(extractDecimal(value));
          break;

        case Enums.FieldType.Person:
        case Enums.FieldType.PersonByTeam: {
          // User, extract user GUID
          if (value instanceof MappedUser) {
            const user: MappedUser = value;
            literal = `'${user.Value}'`;
          } else {
            literal = `'${value}'`;
          }
          break;
        }
        case Enums.FieldType.Selection: {
          if (field?.SelectListSubType === Enums.SelectListSubType.Numeric && (field?.SelectListType === Enums.SelectListType.Radio || field?.SelectListType === Enums.SelectListType.Select)) {
            literal = `${literal}`;
          } else {
            literal = `'${literal}'`;
          }
          break;
        }
        case Enums.FieldType.Date:
        case Enums.FieldType.DateTime:
        case Enums.FieldType.Period:
          if (value === '') {
            // Allow space for testing has value - AS can't supply this yet but see tweaks
            literal = `''`;
          } else {
            let date;
            if (value.$date) {
              // untested = not following this path
              date = moment.utc(value.$date);
            } else {
              // parse and regen string
              date = moment.utc(value);
            }

            // Format conistent with v14 and LinqToQueryString
            literal = `datetime'${date.format('YYYY-MM-DD[T]HH:mm')}'`;
          }

          break;
        default:
          // Single quotes escaped by doubling them up
          literal = literal.replace(/\'/g, '\'\'');
          // Anything else wrap in single quotes
          literal = `'${literal}'`;
          break;
      }
    }

    return literal;
  }

  public setOperand(value: any, row?: number) {
    if (this.isMultiValue) {
      if (Array.isArray(value)) {
        this.Operand = value;
      } else {
        if (this.Operand) {
          if (!Array.isArray(this.Operand)) {
            this.Operand = [this.Operand];
          }
        } else {
          this.Operand = [];
        }

        this.Operand[row] = value;
      }
    } else {
      // Ignore row, store directly. This will remove any additional
      // terms if we switch from multi to single values
      this.Operand = value;
    }
  }

  /**
   * Clear the filter term, or a row within a multi-value term.
   * @param row 
   */
  public clear(row?: number) {
    if (this.Operator === OdataExpressionType.Between) {
      // special case delete completely for between
      this.Operator = undefined;
      this.Operand = undefined;
    } else if (this.isMultiValue && row !== undefined) {
      if (this.Operand) {
        if (Array.isArray(this.Operand)) {
          if (this.Operand.length <= 1) {
            this.Operator = undefined;
            this.Operand = undefined;
          } else {
            this.Operand.splice(row, 1);
          }
        } else {
          if (row === 0) {
            this.Operator = undefined;
            this.Operand = undefined;
          }
        }
      }
    } else {
      this.Operator = undefined;
      this.Operand = undefined;
    }
  }

}
