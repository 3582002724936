import { AppField } from './app-field';
import { Record } from '@softools/softools-core';

export class BooleanAppField extends AppField<boolean> {
  public override adaptValue(value: any, _current: any, _record?: Record, _source?: 'input' | 'server'): any {
    if (typeof (value) === 'boolean') {
      return value;
    } else {
      if (typeof (value) === 'string') {
        return this.adaptStringValue(value);
      } else {
        return value;
      }
    }
  }

  /**
   * Convert a string value to the correct value format for this field.
   * "true" is treated as true, any other value as false.
   */
  public override adaptStringValue(value: string): any {
    return value?.toLowerCase() === 'true';
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {

    if (val1 === undefined) {
      return val2 === undefined ? 0 : 1;
    } else if (val2 === undefined) {
      return -1;
    }

    return val1 === val2 ? 0 : val1 === isDescending ? -1 : 1;
  }

  /** Format a value suitable for insertion in a filter string */
  public override constantForFilter(value: any) {
    return value ? 'true' : 'false';
  }

  public override finiteValues(options?: { includeNull?: boolean, reverse?: boolean }): Array<boolean> | null {

    const values = [true, false];

    if (options?.reverse) {
      values.reverse();
    }

    if (options?.includeNull) {
      values.push(null);
    }

    return values;
  }
}
