import {
  Component, Input, ChangeDetectionStrategy, Output,
  EventEmitter
} from '@angular/core';
import { IndexedAppData } from '@softools/softools-core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {

  public formattedText: string;

  @Input() count = 0;

  @Input() isBusy = true;

  @Input() skip = 0;

  @Input() top = 25;

  @Input() prevRecordId: string;

  @Input() nextRecordId: string;

  @Input() recordIndex: IndexedAppData;

  @Input() singleRecord = false;

  @Input() public fixedPageSize = false;

  @Output() onChangeTopSize = new EventEmitter();

  @Output() firstRecordClicked = new EventEmitter();
  @Output() lasttRecordClicked = new EventEmitter();
  @Output() prevClicked = new EventEmitter();
  @Output() nextClicked = new EventEmitter();

  constructor() {
  }

  public get rangeText(): string {

    if (this.singleRecord) {
      return `${this.skip + 1} / ${this.count}`;
    } else {
      let end = this.skip + this.top;
      if (end > this.count) {
        end = this.count;
      }

      const countText = (this.count !== undefined) ? ` / ${this.count}` : '';
      return `${this.skip + 1} - ${end}${countText}`;
    }
  }

  public get recordDisplayCount() {
    return this.top;
  }

  public get isAtEnd() {
    return this.count <= (this.skip + this.recordDisplayCount);
  }

  public firstClicked = (e: MouseEvent) => {
    e.stopPropagation();
    this.firstRecordClicked.emit();
  }

  public leftClicked = (e: MouseEvent) => {
    e.stopPropagation();
    this.prevClicked.emit();
  }

  public rightClicked = (e: MouseEvent) => {
    e.stopPropagation();
    this.nextClicked.emit();
  }

  public lastClicked = (e: MouseEvent) => {
    e.stopPropagation();
    this.lasttRecordClicked.emit();
  }

  public pageSizeChanged = (value: any) => {
    this.onChangeTopSize.emit(+value);
  }
}
