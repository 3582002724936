<div [vxWatch]="[reportModel]" #container cdkOverlayOrigin #filterPopupOrigin="cdkOverlayOrigin"
  class="scroll-container w-100 h-100" style="overflow-x: auto; contain: strict; background: white;"
  (click)="clickedBackground()"  (scroll)="scrolled($event)">
  <div #virtualspace class="table-frame"
    [style.width.px]="totalWidth$|async"
    [style.height.px]="reportModel.viewHeight.value">
    <ng-container>
      <ng-container>
        <!-- Header row -->
        <div class="table-header-container"
          [style.width.px]="totalWidth$|async"
          [style.height.px]="headerHeight$|async"
          >

          <div class="table-header-row"
            [style.width.px]="totalWidth$|async"
          >
          <div [vxWatch]="controller?.controlColumnMode" class="table-header-selection-cell">
            <div *ngIf="showSelection(reportModel.selectionMode.value)"
              class="checkbox-box control-icon d-flex align-items-center justify-content-center">
              <fa-icon *ngIf="controller?.nextControlColumnMode.value === controlColumnModes.Select"
                class="pointer"
                i18n-title title="Click to select"
                (click)="selectModeClicked($event)"
                [icon]="['fal', 'square']" [border]="false" ></fa-icon>

              <fa-icon *ngIf="controller?.nextControlColumnMode.value === controlColumnModes.AllSelected"
                class="pointer"
                (click)="multiSelectModeClicked($event)"
                i18n-title title="Click to select all"
                [icon]="['fal', 'check-square']" [border]="false" ></fa-icon>

              <fa-icon *ngIf="controller?.nextControlColumnMode.value === controlColumnModes.Record"
                class="pointer"
                (click)="recordModeClicked($event)"
                i18n-title title="Click to clear selection"
                [icon]="['fas', 'check-square']" [border]="false"></fa-icon>
            </div>
          </div>

          <app-report-column-header *ngFor="let field of fields"
            class="table-header-cell"
            [style.left.px]="field.x"
            [style.width.px]="field.w"
            [class.table-header-sticky-column]="field.sticky"
            [singleLineLabel]="false"
            [filterModel]="reportModel.filterModel"
            [simpleFilter]="field.fieldFilter"
            (headerClicked)="showFilterPopup($event, field)"
          ></app-report-column-header>

          </div>

          <app-group-header *ngIf="(reportModel.group.value) && !(reportModel.showGroups.value)"
            class="position-absolute"
            [app]="appModel.app.value"
            [group]="reportModel.group.value"
            [rowHeight]="rowHeight"
            [style.width.px]="totalWidth$|async"
            [style.top.px]="rowHeight"
            [showSummaryRow]="reportModel.hasSummaries.value"
            [summaryExpressions]="reportModel.summaryExpressions.value"
            (clicked)="clickCloseGroup($event)" >
          </app-group-header>

        </div>
      </ng-container>

      <ng-container *ngIf="reportModel.showGroups.value; else records">
        <div *ngIf="(reportModel.hasRecords.value); else norecs"
          [hidden]="(reportModel.busy.busy$|async)"
          [style.top.px]="0"
          [style.width.px]="totalWidth$|async"
          [style.transform]="'translateY('+ (topOffset() + rowHeight) +  'px)'"
          style="position: absolute;" >
          <app-group-header *ngFor="let group of reportModel.groups.value"
            [app]="appModel.app.value"
            [group]="group"
            [rowHeight]="rowHeight"
            [style.width.px]="totalWidth$|async"
            [showCount]="true"
            [showSummaryRow]="reportModel.hasSummaries.value"
            [summaryExpressions]="reportModel.summaryExpressions.value"
            (clicked)="clickGroup($event)" >
          </app-group-header>
        </div>
      </ng-container>

      <ng-template #records >
        <ng-container *ngIf="reportModel.hasRecords.value; else norecs">
          <div *ngFor="let row of reportModel.rows.value || [] let nr = index; trackBy: trackRow;"
          class="table-row table-row-show-active"
          [class.archived-row]="row.record?.IsArchived"
          [class.selected-row]="isRowSelected(reportModel.selectionMode.value, row.record?._id)"
          [style.top.px]="row.pos * rowHeight + (headerHeight$|async)"
          [style.width.px]="totalWidth$|async"  >
            <ng-template #loading>
              <div class="h-100 w-100 d-flex" style="background-color: #fafafa; padding: 4px; margin: 4px">
                <div class="h-100 d-flex align-items-center justify-content-center" [style.width.px]="controlColumnWidth">
                  <sof-spinner-icon class="st-text-theme-primary"></sof-spinner-icon>
                </div>
              </div>
            </ng-template>

            <ng-container *ngIf="row.record as record; else loading">
              <div class="selection-cell d-flex" [style.width.px]="controlColumnWidth" [style.order]="0"
                [class.selected-row]="isRowSelected(reportModel.selectionMode.value, record._id)">
                <div [vxWatch]="controller" [style.width.px]="controlColumnWidth - 4">
                  <div *ngIf="controller?.showRecordEdit.value && (controller?.controlColumnMode.value) === controlColumnModes.Record"
                    class="table-record-edit-cell w-100" (click)="clickedOpenRecord($event, record, nr)">
                    <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                      <ng-container *ngIf="record.EditableAccessForUser; else eyeIcon">
                        <fa-icon [icon]="['fal', 'edit']"></fa-icon>
                      </ng-container>
                      <ng-template #eyeIcon>
                        <fa-icon [icon]="['fal', 'eye']"></fa-icon>
                        </ng-template>
                        </div>
                        </div>

                  <div
                    *ngIf="showSelection(reportModel.selectionMode.value) && (controller?.controlColumnMode.value) !== controlColumnModes.Record"
                    class="checkbox-box">
                    <app-checkbox
                      [checked]="(this.reportModel.selectionModel.all.value) || this.reportModel.selectionModel.isSelected(record._id)"
                      [disabled]="this.reportModel.selectionModel.all.value"
                      (onchecked)="this.reportModel.selectionModel.select(record._id)"
                      (onunchecked)="this.reportModel.selectionModel.deselect(record._id)" >
                    </app-checkbox>
                  </div>
                </div>
                <div style="margin: 2px 0;"
                  [ngStyle]="getBorderStyle(record)"
                ></div>
              </div>

              <div class="table-cell" *ngFor="let field of fields; let i = index" [style.left.px]="field.x" [style.width.px]="field.w"
                [style.order]="field.flexOrder" [class.table-sticky-column]="field.sticky"
                [class.table-cell-show-active]="(reportModel.editMode.value)"
                [class.selected-row]="isRowSelected(reportModel.selectionMode.value, record._id)"
                [class.active-cell-editor]="isActiveCell(record._id, i)" [class.pointer]="field.editable"
                [style.text-align]="getHeaderAlignment(field.field)"
                (click)="activateCell($event, record, i+1, field)"
                >

                <app-table-report-field class="py-2 px-1 h-100 w-100" [appModel]="appModel" [commentsModel]="commentsModel"
                  [attachmentsModel]="attachmentsModel" [reportModel]="reportModel" [application]="this.appModel.app.value"
                  [appIdentifiers]="this.appModel.appIdentifiers.value" [fieldModel]="field.field"
                  [active]="isActiveCell(record._id, i)" [record]="record" [containerType]="containerType" [column]="i + 1"
                  [forReport]="true" [inline]="true" [containerReadOnly]="containerReadOnly" [alignmentOverride]="field.Alignment"
                  (componentClicked)="componentClicked($event)">
                </app-table-report-field>
                </div>
                </ng-container>
          </div>
        </ng-container>
      </ng-template>


      </ng-container>


</div>

<ng-template #norecs>
  <app-ws-sharednorecords
    [app]="appModel.app.$|async"
    [reportModel]="reportModel"
    [isLoading]="(reportModel.busy.busy$|async)"
    [isArchived]="appModel.globalModel.archived.$|async">
  </app-ws-sharednorecords>
</ng-template>

<app-popup-container [vxWatch]="controller?.popupLocation" [isOpen]="(reportModel.filterEditorUi.value)?.showPopup"
  [connectedPositions]="popupPosition"
  [connectedOrigin]="filterPopupOrigin"
  [connectedOffsetX]="controller?.popupLocation.value.x"
  [connectedOffsetY]="controller?.popupLocation.value.y"
  (closed)="reportModel.closeFilterEditor()">
  <ng-template>
    <ng-container *ngIf="(appModel.app.$|async)?.isFilterAdvanced;
      then quickFilterPopup;
      else legacyFilter"
    ></ng-container>
  </ng-template>
</app-popup-container>

<ng-template #legacyFilter>
  <sof-filter-edit
    [filterModel]="reportModel.filterModel"
    [reportModel]="reportModel"
    [filterSpec]="(reportModel.filterEditorUi.value)?.editFilterSpec"
    [filterEditorUi]="reportModel.filterEditorUi.value"
    [appIdentifier]="appModel.app.value?.Identifier"
    (termUpdated)="filterTermUpdated($event)"
    (cancelled)="reportModel.closeFilterEditor()">
  </sof-filter-edit>
</ng-template>

<ng-template #quickFilterPopup>
  <app-sort-popup
    [filterModel]="reportModel.filterModel"
    [filterEditorUi]="reportModel.filterEditorUi.value"
    (termUpdated)="sortUpdated($event)"
  ></app-sort-popup>
</ng-template>
