import { AppField } from './app-field';
import { App, Enums, Field, Record } from '@softools/softools-core';
import { IApplication } from '../app-field-source';
import { RecordPatch } from 'app/workspace.module/types';
import { Application } from '../application';

export class BackingField extends AppField {

  public static readonly textKey = 'txt';
  public static readonly numKey = 'num';
  public static readonly fmtKey = 'fmt';
  public static readonly uriKey = 'uri';
  public static readonly descKey = 'd';
  public static readonly sizeKey = 'size';
  public static readonly typeKey = 'type';

  public suffix: string;

  public key: string;

  public parentId: string;

  public parent: AppField;

  constructor(field?: Field, public containedField?: AppField, app?: App & IApplication) {
    super(field, app);

    const splitPoint = this.Identifier.lastIndexOf('_');
    this.suffix = this.Identifier.substr(splitPoint + 1);
    this.parentId = this.Identifier.substr(0, splitPoint);
  }

  public override initialise(app: Application) {
    switch (this.suffix) {
      case 'Text':
      case 'FileName':
        this.key = BackingField.textKey;
        break;
      case 'Numeric':
        this.key = BackingField.numKey;
        break;
      case 'ImageListAssetUri':
      case 'VideoUrl':
        this.key = BackingField.uriKey;
        break;
      case 'Formatted':
        this.key = BackingField.fmtKey;
        break;
      case 'VideoDescription':
        this.key = BackingField.descKey;
        break;
      case 'FileSize':
        this.key = BackingField.sizeKey;
        break;
      case 'FileType':
        this.key = BackingField.typeKey;
        break;
      default:
        // Fall back to same text as suffix if no known key
        // Shouldn't happen in practice
        this.key = this.suffix;
        break;
    }

    let parent = app.getField(this.parentId);
    if (!parent) {
      // hack - list field backing fields don't include the list field in the id
      // so hunt for subfield in all listfields.  See SOF-10283.
      const subfieldSuffix = `_${this.parentId}`;
      const possibleLists =
        app.AppFields
          .filter(f => f.Type === Enums.FieldType.ListField)
          .filter(f => f.SubFieldsIdentifiers?.find(sf => sf.Identifier.endsWith(subfieldSuffix)));
      if (possibleLists.length === 1) {
        const fullId = `${possibleLists[0].Identifier}_${this.parentId}`;
        parent = app.getField(fullId);
      }
    }

    if (parent) {
      this.parent = parent;
      this.parent.addBackingField(this);
    }
  }

  public override compactRecord(_record: Record): any | null {
    // nop - the parent  field is resposible
    return null;
  }

  public override updatePatchWithDefault(patch: RecordPatch) {
    // nop - backing fields shouldn't have default values
  }

  public override getRawRecordValue(record: Record, _listRow?: number) {
    // Sone fields are incorrectly named in the app spec - a grid subfield
    // appears without the parent prefix so we can't find it.
    // Can't do much more than bail out (SOF-7661)
    // This should be safe as the actual formatted value is on the cell
    // not the subfield.
    if (!this.parent) {
      return null;
    }

    const internal = this.parent.getInternalRecordValue(record, _listRow);
    if (internal?.hasOwnProperty(this.key)) {
      const value = internal[this.key];
      if (value !== undefined) {
        return value;
      }
    }

    return record[this.DataIdentifier];
  }

  public override getRecordValue(record: Record, listRow?: number) {
    return this.getRawRecordValue(record, listRow);
  }

  public override getInternalRecordValue(record: Record, _listRow?: number) {
    // check parent see SOF-7661
    if (this.parent) {
      const value = this.parent.getInternalRecordValue(record, _listRow);
      return value?.[this.key];
    }

    return super.getInternalRecordValue(record, _listRow);
  }

  public override updatePatch(patch: RecordPatch, record: Record, local = false) {
    // most backing fields should not get added to the patch as they
    // are generated by the server.  There are a few exceptions which we
    // check for here.
    switch (this.parent.Type) {
      case Enums.FieldType.Document:
      case Enums.FieldType.EmbeddedVideo:
        return super.updatePatch(patch, record, local);
      default:
        break;
    }
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    return this.containedField.compareValues(val1, val2, isDescending);
  }

  public override storeToRecord(record: Record, value: any, listRow?: number) {
    // check parent see SOF-7661
    if (this.parent) {
      const internal = this.parent.getInternalRecordValue(record, listRow);
      if (internal instanceof Object && internal.hasOwnProperty('val')) {
        // Parent value is a structured object so add our value
        internal[this.key] = value;
        this.parent.storeToRecord(record, internal);
      } else {
        // Parent not defined or simple value, upgrade to structured object
        this.parent.storeToRecord(record, { val: internal, [this.key]: value });
      }
    }
  }

  public override isFilterable() {
    // Filterable if parent is
    // Deal with parent not set - see SOF-10283
    return this.parent?.isFilterable() ?? super.isFilterable()
  }

}

export class PersonTextBackingField extends BackingField {

  public override isFieldSortable(): boolean {
    switch (this.parent.Type) {
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
        // Person fields defer sortability to _Text backing (SOF-12580, SOF-12607)
        return this.Sortable || this.parent.Sortable;
      default:
        return super.isFieldSortable();
    }
  }
}
