import { NgModule } from '@angular/core';
import { SortableComponent } from './sortable.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({

  declarations: [
    SortableComponent
  ],
  exports: [
    SortableComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
})
export class SortableModule { }
