<div [style.width]="formModel?.CentredFormWidth"
  class="template-container h-auto"
  [ngStyle]="styles?.component?.css"
  style="margin-left: auto; margin-right: auto; max-width: 100%; overflow-y: auto;" >

  <app-form-header *ngIf="formModel" [showAppIcon]="formModel.ShowAppIcon"
    class="form-header"
    [showAppTitle]="formModel.ShowAppTitle"
    [appTitle]="application.Title"
    [showAppDescription]="formModel.ShowAppDescription"
    [appDescription]="application.Description"
    [appImageUrl]="application.LogoImageUri"
    [iconBase64]="application.IconBase64">
  </app-form-header>

  <div id="noFormTemplates" class="main-templates" *ngIf="!templates || templates.length === 0" class="container-fluid">
    <div class="jumbotron">
      <h1 class="display-2" i18n="@@NoTemplatesForFormHeader">No templates configured</h1>
      <p class="lead" i18n="@@NoTemplatesForFormMessage">Please speak to your system administrator</p>
      <hr class="my-4">
    </div>
  </div>

  <ng-container *ngIf="formModel && templates?.length > 0">
    <div #tc1 class="header-templates">
      <ng-container *ngFor="let templateRef of topTemplates; trackBy: templateTrackByFn">
        <ng-container *ngTemplateOutlet="templatetemplate; context: {
          template: getTemplate(templateRef.TemplateIdentifier),
            ref: templateRef,
            templateContainer: tc1
          }"  >
        </ng-container>
      </ng-container>
    </div>

    <div #tc2 class="main-templates">
      <ng-container *ngFor="let templateRef of mainTemplates; trackBy: templateTrackByFn">
        <ng-container *ngTemplateOutlet="templatetemplate; context: {
          template: getTemplate(templateRef.TemplateIdentifier),
            ref: templateRef,
            templateContainer: tc2
          }"  >
        </ng-container>
      </ng-container>
    </div>

    <div #tc3 class="footer-templates">
      <ng-container *ngFor="let templateRef of bottomTemplates; trackBy: templateTrackByFn">
        <ng-container *ngTemplateOutlet="templatetemplate; context: {
          template: getTemplate(templateRef.TemplateIdentifier),
            ref: templateRef,
            templateContainer: tc3
          }"  >
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

</div>

<ng-template #templatetemplate let-template="template" let-templateRef="ref" let-templateContainer="templateContainer" >
  <app-template *ngIf="template"
    [appModel]="appModel"
    [recordModel]="recordModel"
    [recordUpdateController]="recordUpdateController"
    [appIdentifiers]="appIdentifiers"
    [application]="application"
    [form]="formModel"
    [attachmentsModel]="attachmentsModel"
    [template]="template"
    [record]="template.Static ? (appModel.staticRecord.$|async) : record"
    [readOnly]="formModel.ReadOnly || template.IsReadOnly"
    [expanded]="expandedFormTemplates.indexOf(templateRef.TemplateIdentifier) >= 0"
    [expandedHelp]="expandedFormTemplateHelp.indexOf(templateRef.TemplateIdentifier) > -1"
    (onToggleExpandedFormTemplate)="onToggleExpandedFormTemplate.emit($event)"
    (onToggleExpandedFormTemplateHelp)="onToggleExpandedFormTemplateHelp.emit($event)"
    [hideHeader]="templateRef.HideHeader || !showTemplateHeaders"
    (activateComponent)="onActivateComponent($event)"
    (infoIconClicked)="showFieldInfoHandler($event.origin, $event.field, templateRef.TemplateIdentifier)" >
  </app-template>
</ng-template>

<ng-template
cdkConnectedOverlay
[cdkConnectedOverlayOrigin]="fieldInfoOverlayOriginEl"
[cdkConnectedOverlayOpen]="showFieldInfo$|async"
[cdkConnectedOverlayHasBackdrop]="true"
(backdropClick)="closeInfoPopup()">
  <div class="theme p-3 rounded ps-4 pe-4">
    <div class="description-text" *ngIf="selectedFieldForInfo.field.Description">
      <span>{{selectedFieldForInfo.field.Description}}</span>
      <hr />
    </div>

    <ng-container *ngIf="hasAppStudioAccess">
      <span><b i18n>Field Identifier</b></span> <br /> <span (click)="copyClick()"><span class="pointer"
          [cdkCopyToClipboard]="selectedFieldForInfo.field.Identifier">{{selectedFieldForInfo.field.Identifier}}</span>
        <fa-icon class="pointer ms-1" [icon]="['fal', 'copy']" [cdkCopyToClipboard]="selectedFieldForInfo.field.Identifier">
        </fa-icon>
      </span>

      <div class="pt-2"><span><b i18n>Field Type</b></span> <br />
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Barcode">Barcode</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Bit">Bit</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.AttachmentList">Attachment List</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Date">Date</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.DateTime">Date Time</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Document">Document</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Draw">Draw</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Email">Email</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.EmbeddedVideo">Embedded Video</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Gantt">Gantt</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.GridField">Grid Field</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Image">Image</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.ImageActionButton">Image Action Button</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.ImageList">Image List</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.InAppChart">In App Chart</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Integer">Integer</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.ListField">List Field</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Literal">Literal</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Long">Long</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.LongText">Long Text</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Lookup">Lookup</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Money">Money</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.MultiState">Multi State</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Notes">Notes</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Number">Number</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Period">Period</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Person">Person</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.PersonByTeam">Person By Team</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Range">Range</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Reference">
          Reference ({{selectedFieldForInfo.field.ReferenceOptions?.TargetAppIdentifier}})
        </span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Selection">Selection</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Team">Team</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Text">Text</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.Time">Time</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.UrlDownloadField">Url Download Field</span>
        <span i18n *ngIf="selectedFieldForInfo.field.Type === fieldTypes.UrlField">Url Field</span>
      </div>

      <div *ngIf="selectedFieldForInfo.field.IsExpression" class="pt-2">
          <span><b i18n>Expression</b></span> <br /> <div class="alert alert-secondary p-2 small mt-1">{{selectedFieldForInfo.field.Expression}}</div>
      </div>

      <hr />

      <!-- Dont translate App Studio. -->
      <div class="mt-2"><b>App Studio</b></div>
      <div class="mt-2 st-text-theme-primary pointer" (click)="goToAppStudio(selectedFieldForInfo.field)"><span i18n>Go to Field</span></div>
      <div class="mt-2 st-text-theme-primary pointer" (click)="goToAppStudio(selectedFieldForInfo.field, selectedFieldForInfo.templateIdentifier)"><span i18n>Go to Template</span></div>
    </ng-container>

  </div>
</ng-template>
