import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-url-field',
  templateUrl: './url-field.component.html',
  styleUrls: ['./url-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlFieldComponent extends EditableFieldBase<string> implements AfterViewInit {

  @ViewChild(InputComponent, { static: false }) public inputComponent: InputComponent;

  private activeWhenReady = false;

  public get urlValue() {
    let url = this.fieldValue;
    if (url && url.indexOf('://') < 0) {
      url = 'https://' + url;
    }

    return url;
  }

  public override async activate() {
    this.activeWhenReady = true;
  }

  public override async deactivate() {
    await this.inputComponent?.updateCurrentValue();
  }

  public ngAfterViewInit() {
    if (this.activeWhenReady) {
      this.activeWhenReady = false;
      this.inputComponent?.activate();
    }
  }

  public checkUrlValid() {
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.exec(this.urlValue);
  }
}
