
import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { lastValueFrom, Observable } from 'rxjs';

export interface IAppVersions {
  appIdentifier: string;
  version: number;
}

@Injectable({ providedIn: 'root' })
export class AppsRepository extends RepositoryBase {

  public getApp(appIdentifier: string): Observable<any> {
    return this.get(this.urlResolver.resolveDefaultApiUrl(`v2/Apps/GetApp/${appIdentifier}`));
  }

  public getApps(): Observable<any> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('v2/Apps'));
  }

  public getLatestAppVersionNumber(appIdentifier: string): Promise<number> {
    return this.get(this.urlResolver.resolveDefaultApiUrl(`v2/Apps/GetLatestAppVersionNumber/${appIdentifier}`)).toPromise();
  }

  public getLatestAppVersionNumbers(): Promise<Array<IAppVersions>> {
    return lastValueFrom<Array<IAppVersions>>(this.get(this.urlResolver.resolveDefaultApiUrl(`v2/Apps/GetAppVersionNumbers`)));
  }


}
