<div class="sof-modal-container">
  <form class="cardv2-popup sof-modal">
    <div class="cardv2-header">
      <div i18n>Invite users to Softools</div>
    </div>
    <mat-dialog-content class="cardv2-body">
      <div class="invitation-layout">
        <div class="caption mb-4" i18n>
          Choose one or more teams and quick permission sets to be automatically applied to the users that you invite.
        </div>

        <div class="email-header my-4" i18n>Enter each email address to invite below on a seperate line</div>

        <mat-selection-list #teamlist dense class="team-list mb-1">
          <mat-list-option *ngFor="let team of teams" [value]="team">{{ team }}</mat-list-option>
        </mat-selection-list>

        <mat-selection-list #permlist dense class="perm-list mb-1">
          <ng-container *ngFor="let set of permissionSets">
            <mat-list-option *ngIf="set.name === 'Minimal'" [value]="set" i18n> Minimal Permissions </mat-list-option>
            <mat-list-option *ngIf="set.name === 'BasicTasks'" [value]="set" i18n> Basic Tasks </mat-list-option>
            <mat-list-option *ngIf="set.name === 'AdvancedTasks'" [value]="set" i18n> Advanced Tasks </mat-list-option>
            <mat-list-option *ngIf="set.name === 'BasicAppBuilder'" [value]="set" i18n> Basic App Builder </mat-list-option>
            <mat-list-option *ngIf="set.name === 'AdvancedAppBuilder'" [value]="set" i18n> Advanced App Builder </mat-list-option>
            <mat-list-option *ngIf="set.name === 'ManageUsers'" [value]="set" i18n> Manage Users </mat-list-option>
            <mat-list-option *ngIf="set.name === 'ManageSettings'" [value]="set" i18n> Manage Settings </mat-list-option>
            <mat-list-option *ngIf="set.name === 'AllSettings'" [value]="set" i18n> All Settings </mat-list-option>
          </ng-container>
        </mat-selection-list>

        <textarea cdkFocusInitial class="email-list" spellcheck="false" rows="6" [(ngModel)]="addresses" name="emails"></textarea>

        <div class="results d-flex flex-column">
          <div *ngIf="addedCount" class="mt-2">
            <span i18n>Successfully invited</span>&nbsp;<b>{{ addedCount }}</b
            >&nbsp;<span i18n>user(s) with the below email address</span>
          </div>
          <div *ngIf="addedCount" [innerHTML]="successMessage"></div>

          <div *ngIf="errorCount" class="mt-2">
            <span i18n>Failed to invite</span>&nbsp;<b>{{ errorCount }}</b
            >&nbsp;<span i18n>user(s) with below reason</span>
          </div>
          <div *ngIf="errorCount" [innerHTML]="errorMessage"></div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="cardv2-footer">
      <button
        *ngIf="!isRequestSent"
        [disabled]="!inviteValid"
        [class.bg-st-theme-primary]="inviteValid"
        [class.text-white]="inviteValid"
        class="primary my-2 ms-2"
        (click)="onInvite($event)"
        i18n
      >
        Invite
      </button>
      <button
        *ngIf="isRequestSent"
        [class.bg-st-theme-primary]="inviteValid"
        [class.text-white]="inviteValid"
        class="primary my-2 ms-2"
        i18n="@@PleaseWait"
      >
        Please Wait
      </button>
      <button class="close my-2 ms-2" (click)="onCancel($event)">Cancel</button>
    </div>
  </form>
</div>
