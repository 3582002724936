import { Record } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { AppDataService, InitialRecordOptions } from 'app/services/appdata.service';

export class BaseRecordCreator {

  protected constructor(
    private appDataService: AppDataService) {
  }

  protected async createInitialRecord(app: Application, options?: InitialRecordOptions): Promise<Record> {
    return this.appDataService.createInitialRecord(app, options);
  }
}
