import { Report } from '@softools/softools-core';
import { AppModel } from '../app.model';
import { FilterModel } from '../filter.model';
import { PageModel } from '../page/page.model';
import { RecordsReportModel } from '../reports/records-report.model';
import { ReportModel } from '../reports/report.model';

export abstract class ModelFactory {
  public abstract createReportModel(
    report: Report,
    appModel: AppModel,
    filterModel: FilterModel,
    pageModel: PageModel): ReportModel;

  /** Create a dual report. Currently this only supports record view reports added to a chart */
  public abstract createDualReportModel(
    report: Report,
    appModel: AppModel,
    filterModel: FilterModel,
    pageModel: PageModel): RecordsReportModel;

}
