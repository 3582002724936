<section class="report-grid-container">

  <app-folders #leftAside
    class="left-aside-area"
    [folderModel]="folderModel"
    [selectedFolderIdentifier]="reportModel.reportIdentifier.$|async"
    [parentAppIdentifier]="reportModel.parentAppIdentifier.$|async"
    [parentRecordIdentifier]="reportModel?.parentRecordId.$|async"
    [title]="(appModel.app.$|async)?.Title"
    [appIdentifiers]="(appModel.appIdentifiers.$|async)"
    [showAddNewRecord]="showAddNewRecord"
    [appIdentifier]="(appModel.app.$|async)?.Identifier"
    [pinnedStateVal]="pageModel.folderBarState.$|async">
  </app-folders>

  <!-- todo  showAddNewRecord  -->

  <div class="report-controls-area">
    <app-ws-sharedreportactions
      [reportModel]="reportModel"
      [filterModel]="filterModel"
      [isLoading]="reportModel.busy.busy$|async"
      [filter]="filterModel.reportFilter.$|async"
      [baseFilter]="(reportModel.report.$|async)?.BaseFilter"
      [showFilterManagement]="this"
      [isArchived]="appModel.globalModel.archived.$|async"
      [showInlineEditSwitch]="false"
      [isEditMode]="false"
      [recordCount]="reportModel.totalCount.$|async"
      [showSelected]="true"
      [selectedCount]="reportModel.selectedCount.$|async"
      [allRecordsSelected]="reportModel.selectionModel.all.$|async"
      [isSearchable]="appModel.isSearchable()"
      [isReportOption]="false"
      [searchText]="reportModel.searchTerm.$|async"
      [subtitle]="(reportModel.report.$|async)?.SubTitle"
      (onArchivedRecordsBadgeClickHandler)="onArchivedRecordsBadgeClickHandler()"
      (onSearchEnterClickHandler)="reportModel.setSearchTerm($event)"
      (onSearchEscClickHandler)="reportModel.setSearchTerm(null)"
      >
    </app-ws-sharedreportactions>
  </div>

  <!-- 
      (onDeselectAllHandler)="this.reportModel.selectionModel.deselectAll()"
      search - doesn't handle dual report so move functionality to model when this is used for charts
   -->

   <div class="main-content-area report-content h-100 w-100"
     cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin"
     >
    <article #content class="report-article h-100 w-100"
      [ngStyle]="(backgroundStyles$|async)?.component?.css"
      (scroll)="scrolled($event)"
      style="position: relative; overflow-x: auto;" 
      >
      <ng-content></ng-content>
    </article>

    <div class="new-rec-container" [class.quick-open]="isQuickFilterVisible$|async">
      <app-new-record-button [canCreate]="showAddNewRecord" [createUrl]="createUrl" [floater]="false"></app-new-record-button>
    </div>
  </div>

  <div [@quickFilters]="openState$|async" class="quick-filters">
    <app-quick-filter *ngIf="isQuickFilterVisible$|async" 
      [reportModel]="reportModel"
      [reportController]="reportController"
      [filterModel]="filterModel"
      (close)="quickFilterClosed()"
      (showSaved)="quickToFilterManagement()"
    ></app-quick-filter>
  </div>


  <div [@filterMgt]="filterManageOpenState$|async" class="saved-filters">
    <app-filter-management-panel *ngIf="isFilterManagementPanelVisible$|async" 
      [appModel]="appModel"
      [filterModel]="filterModel"
      [showFilterManagement]="this"
      [isOnline]="appModel.globalModel.online.$|async"
      [selectedFilter]="filterModel.reportFilter.$|async"
      [appIdentifier]="(appModel.app.$|async)?.Identifier"
      [appFields]="(appModel.app.$|async)?.AppFields  "
      [report]="reportModel.report.$|async"
      [reportFields]="(reportModel.report.$|async)?.ListReportFields"
      [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
    </app-filter-management-panel>
  </div>

  <!-- 
    Filter management sidebar 
  -->
  <!-- <app-popup-container [connectedOrigin]="articleOrigin" dock="right"
    [isOpen]="isFilterManagementPanelVisible$|async"
    [maximizeWhenCentred]="true"
    (closed)="filterManagementClosed()">
    <ng-template>
      <app-filter-management-panel
        [appModel]="appModel"
        [filterModel]="filterModel"
        [showFilterManagement]="this"
        [isOnline]="globalModel.online.$|async"
        [selectedFilter]="filterModel.reportFilter.$|async"
        [appIdentifier]="(appModel.app.$|async)?.Identifier"
        [appFields]="(appModel.app.$|async)?.AppFields"
        [report]="reportModel.report.$|async"
        [reportFields]="(reportModel.report.$|async)?.ListReportFields"
        [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
      </app-filter-management-panel>
    </ng-template>
  </app-popup-container> -->


  <div class="footer-area" *ngIf="(appModel.globalModel.online.$|async)">
    <!-- 
      *ngIf="(isOfflineReport$|async) || (appModel.globalModel.online.$|async)"
     -->
    <app-shared-footer
    [appModel]="appModel"
    [appIdentifier]="(appModel.app.$|async)?.Identifier"
    [showAside]="(pageModel.showSidebar.$|async) ? 'active' : 'inactive'"
    [isOnline]="appModel.globalModel.online.$|async"
    [showFooter]="true"
    [attachmentsOrigin]="articleOrigin"
    >
    <ng-content class="footer-left" select=".footer-left"></ng-content>
    <ng-content select=".footer-paginator"></ng-content>
  
      <!-- <app-table-report-paginator *ngIf="!(appModel.app.$|async)?.isSingletonApp && (this.reportModel.records.$ | async)?.length > 0"
        [reportModel]="reportModel.$|async"
      >
      </app-table-report-paginator> -->
    </app-shared-footer>
  </div>

  <!-- 
    [attachmentsModel]="attachmentsModel"
    [commentsModel]="commentsModel"

   -->


</section>
