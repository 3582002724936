import { Component, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { logError } from '@softools/softools-core';
import { UrlAppField } from 'app/types/fields/url-app-field';
import { BehaviorSubject } from 'rxjs';
import { IconName } from '@fortawesome/pro-light-svg-icons';

/**
 * Alternative representation of @see UrlFieldComponent which displays as a download
 * icon rather than displaying the URL text.
 */
@Component({
  selector: 'app-download-icon-field',
  templateUrl: './download-icon-field.component.html',
  styleUrls: ['./download-icon-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadIconFieldComponent extends EditableFieldBase<string, UrlAppField> implements OnInit, OnChanges {

  public icon: IconName;

  public url$ = new BehaviorSubject<string>('');

  constructor() {
    super();
  }

  public override ngOnInit() {
    try {
      super.ngOnInit();
      this.icon = (this.fieldModel.DisplayOptions?.Icon ?? this.fieldModel.defaultIcon()) as IconName;
      this.setUrl();
    } catch (error) {
      logError(error, '');
    }
  }

  public override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.setUrl();
  }

  public clicked($event: MouseEvent) {
    try {
      $event.stopPropagation();
      this.fieldModel.perform(this).catch(e => logError(e, 'url clicked'));
    } catch (error) {
      logError(error, '');
    }
  }

  private setUrl() {
    this.url$.next(this.fieldModel.ImageActionButtonUrl || this.value);
  }
}
