import { Record } from '@softools/softools-core';
import { AppField } from './app-field';

export interface IDocumentFieldValue {
  assetId: string;
  filename: string;
  size: number;
  mimeType: string;
}

export class DocumentAppField extends AppField {

  public createFilePatch(record: Record, assetId: string, file: File, listRow?: number) {
    const patch = this.createPatch(record, assetId, listRow);

    // Add backing field values from file...
    patch.addChange(`${this.Identifier}_FileName`, file.name);
    patch.addChange(`${this.Identifier}_FileSize`, file.size);
    patch.addChange(`${this.Identifier}_FileType`, file.type);

    return patch;
  }

  public createResetPatch(record: Record, listRow?: number) {
    const patch = this.createPatch(record, null, listRow);
    patch.addChange(`${this.Identifier}_FileName`, null);
    patch.addChange(`${this.Identifier}_FileSize`, null);
    patch.addChange(`${this.Identifier}_FileType`, null);
    return patch;
  }

  public override getRecordValue(record: Record, listRow?: number) {
    const internal = this.getInternalRecordValue(record, listRow);
    return internal && this.adaptInternalValue(internal);
  }

  public override adaptInternalValue(internal: any) {
    const value: IDocumentFieldValue = {
      assetId: internal?.val,
      filename: internal?.txt,
      size: internal?.size,
      mimeType: internal?.type
    };

    return value;
  }
}
