<ng-container *ngIf="gravatarUrl$ | async as url; else normal">
  <div class="d-flex flex-column">
    <img [src]="url" style="width: 80px" />
    <small *ngIf="showTextWithIcon" class="st-text-theme-primary">{{ name }}</small>
  </div>
</ng-container>

<ng-template #normal>
  <div class="person-container">
    <app-input-icon icon="user" [elementStyles]="elementStyles">
      <input
        #input
        [value]="(text$ | async) ?? ''"
        [title]="displayUser"
        class="pe-4 form-control form-control-o"
        [class.inline-control]="inline"
        [class.validation-error]="!isValid"
        [ngStyle]="textStyle"
        [disabled]="getIsDisabled() || (missingUser$|async)"
        [matAutocomplete]="auto"
        (keydown)="onKeyDown($event)"
        (keyup)="onKeyUp($event)"
        (keypress)="onKeyPress($event)"
        (click)="$event.stopPropagation()"
        #trigger="matAutocompleteTrigger"
        (blur)="closePanel()"
        (input)="changed($event)"
        (focus)="setFocus()"
      />
    </app-input-icon>

    <mat-checkbox *ngIf="byTeam" class="mx-1 overflow-hidden"
      [checked]="searchOnlyMyTeams$|async"
      title="Search only my teams?" i18n-title
      [disabled]="getIsDisabled()"
      (change)="byTeamChanged($event)"
    ></mat-checkbox>

    <ng-container *ngIf="showClear && !getIsDisabled()">
      <div class="clear-area">
        <fa-icon
          type="button"
          class="text-muted"
          [icon]="['fal', 'times-circle']"
          [border]="false"
          title="Clear User"
          i18n-title
          (click)="clearUser()"
        >
        </fa-icon>
      </div>
    </ng-container>
  </div>
  <small *ngIf="missingUser$|async" i18n>
    Missing or deleted user
  </small>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onPersonSelectClickHandler($event)" [displayWith]="getUsername">
    <mat-option *ngFor="let user of filtered$ | async; trackBy: filteredUsersTrackByFn" [value]="user">
      <span>{{ user.Text }}</span>
      <small> {{ user.Email }}</small>
    </mat-option>
  </mat-autocomplete>
</ng-template>
