import {
  faHome,
  faBars,
  faTh,
  faThList,
  faCog,
  faTrashAlt,
  faBell,
  faEllipsisH,
  faDownload,
  faShareSquare,
  faSync,
  faSyncAlt,
  faSave,
  faCircle,
  faEye,
  faEnvelope,
  faQuestionCircle,
  faExclamationCircle,
  faTrash,
  faTimesCircle,
  faWrench,
  faUser,
  faUsers,
  faGlobeAmericas,
  faFilter,
  faCloudDownloadAlt,
  faSpinner,
  faCircleNotch,
  faMinus,
  faCamera,
  faSearch,
  faPencilAlt,
  faCloudUploadAlt,
  faExternalLinkAlt,
  faWifiSlash,
  faEllipsisV,
  faArrowDown,
  faArrowCircleDown,
  faStepBackward,
  faChevronLeft,
  faChevronRight,
  faStepForward,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faSortUp,
  faSortDown,
  faSort,
  faSlidersH,
  faComment,
  faMapPin,
  faArchive,
  faCheckSquare,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFilePowerpoint,
  faFile,
  faList,
  faImage,
  faPlusSquare,
  faSquare,
  faExclamationTriangle,
  faMousePointer,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faTachometerAlt,
  faCloudUpload,
  faLock,
  faHistory,
  faCopy,
  faSignIn,
  faExternalLink,
  faSignOut,
  faPaperclip,
  faStar,
  faStarExclamation,
  faCalendar,
  faCalendarPlus,
  faLink,
  faUnlink,
  faTableColumns,
  faGripHorizontal,
  faPenSquare,
  faCaretDown,
  faExternalLinkSquareAlt,
  faStickyNote,
  faClock,
  faFilm,
  faSpinnerThird,
  faFileAlt,
  faCheckCircle,
  faPlusCircle,
  faFileUpload,
  faFileDownload,
  faUserSlash,
  faUserTimes,
  faUserCheck,
  faLockOpenAlt,
  faUserPlus,
  faFunction,
  faSortAmountDown,
  faSortAmountUp,
  faNetworkWired,
  faChevronCircleUp,
  faChevronCircleDown,
  faEnvelopeOpenText,
  faLayerGroup,
  faFileCsv,
  faFileCode,
  faFileArchive,
  faPaperPlane,
  faFileVideo,
  faBan,
  faEyeSlash,
  faPaste,
  faSearchPlus,
  faRepeatAlt,
  faSlash,
  faChartGantt,
  faChartMixed,
  faChartScatterBubble,
  faChartColumn,
  faGaugeCircleBolt,
  faChartScatter,
  faChartNetwork,
  faTableLayout,
  faChartTreeMap,
  faSquareKanban,
  faMapLocationDot,
  faChartLineUp,
  faChartWaterfall,
  faGridDividers,
  faDash,
  faInputPipe,
  faChartSimple,
  faChartScatter3d,
  faMemoPad,
  faThumbTack,
  faCircleX,
  faSquareX,
  faEquals,
  faNotEqual,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faArrowLeftFromLine,
  faArrowRightFromLine,
  faArrowsLeftRightToLine,
  faArrowsLeftRight,
  faCircleTrash,
  faMinusSquare

} from '@fortawesome/pro-light-svg-icons';

import {
  faCog as faCogSolid,
  faTimes as faTimesSolid,
  faCheck as faCheckSolid,
  faStarExclamation as faStarExclamationSolid,
  faCheckSquare as faCheckSquareSolid,
  faCheckCircle as faCheckCircleSolid,
  faFile as faFileSolid,
  faSpinnerThird as faSpinnerThirdSolid,
  faArrowCircleDown as faArrowCircleDownSolid,
  faEllipsis as faEllipsisSolid,
  faThumbTack as faThumbTackSolid,
  faFilter as faFilterSolid,
  faTableColumns as faTableColumnsSolid,
  faEquals as faEqualsSolid,
  faNotEqual as faNotEqualSolid,
  faGreaterThan as faGreaterThanSolid,
  faGreaterThanEqual as faGreaterThanEqualSolid,
  faLessThan as faLessThanSolid,
  faLessThanEqual as faLessThanEqualSolid,
  faArrowLeftFromLine as faArrowLeftFromLineSolid,
  faArrowRightFromLine as faArrowRightFromLineSolid,
  faArrowsLeftRightToLine as faArrowsLeftRightToLineSolid,
  faPlusSquare as faPlusSquareSolid,
  faSquare as faSquareSolid,
  faMinusSquare as faMinusSquareSolid,
  faArrowsLeftRight as faArrowsLeftRightSolid

} from '@fortawesome/pro-solid-svg-icons';

import {
  faSortUp as faSortUpDuo,
  faSortDown as faSortDownDuo,
} from '@fortawesome/pro-duotone-svg-icons';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

export function loadIcons(library: FaIconLibrary) {

  // solid icons
  library.addIcons(
    faCogSolid, faTimesSolid, faStarExclamationSolid, faCheckSquareSolid,
    faCheckCircleSolid, faFileSolid, faSpinnerThirdSolid, faArrowCircleDownSolid,
    faEllipsisSolid, faThumbTackSolid, faFilterSolid, faTableColumnsSolid,
    faCheckSolid,
    faEqualsSolid, faNotEqualSolid,
    faGreaterThanSolid, faGreaterThanEqualSolid,
    faLessThanSolid, faLessThanEqualSolid,
    faArrowLeftFromLineSolid, faArrowRightFromLineSolid, faArrowsLeftRightToLineSolid,
    faPlusSquareSolid, faSquareSolid, faMinusSquareSolid,
    faArrowsLeftRightSolid
  );

  // light icons
  library.addIcons(
    faQuestionCircle,
    faExclamationCircle,
    faTrashAlt,
    faTrash,
    faTimesCircle,
    faWrench,
    faUser,
    faUsers,
    faGlobeAmericas,
    faFilter,
    faExclamationCircle,
    faCloudDownloadAlt,
    faSpinner,
    faCircleNotch,
    faMinus,
    faCamera,
    faSearch,
    faPencilAlt,
    faCloudUploadAlt,
    faExternalLinkAlt,
    faWifiSlash,
    faEllipsisV,
    faSave,
    faCircle,
    faEye,
    faEyeSlash,
    faEnvelope,
    faHome,
    faBars,
    faTh,
    faThList,
    faCog,
    faTrashAlt,
    faBell,
    faEllipsisH,
    faDownload,
    faShareSquare,
    faSync,
    faSyncAlt,
    faArrowDown,
    faArrowCircleDown,
    faStepBackward,
    faChevronLeft,
    faChevronRight,
    faStepForward,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faInfoCircle,
    faChevronUp,
    faChevronDown,
    faArrowLeft,
    faSortUp,
    faSortDown,
    faSort,
    faSlidersH,
    faComment,
    faPaperclip,
    faMapPin,
    faArchive,
    faCheckSquare,
    faFileImage,
    faFileExcel,
    faFilePdf,
    faFileWord,
    faFilePowerpoint,
    faFileImage,
    faFile,
    faList,
    faImage,
    faPlusSquare,
    faMinusSquare,
    faSquare,
    faExclamationTriangle,
    faMousePointer,
    faChartArea,
    faChartBar,
    faChartLine,
    faChartPie,
    faTachometerAlt,
    faCloudUpload,
    faLock,
    faLockOpenAlt,
    faHistory,
    faCopy,
    faSignIn,
    faExternalLink,
    faSignOut,
    faPaperclip,
    faStar,
    faStarExclamation,
    faCalendar,
    faCalendarPlus,
    faLink,
    faUnlink,
    faTableColumns,
    faGripHorizontal,
    faPenSquare,
    faCaretDown,
    faExternalLinkSquareAlt,
    faStickyNote,
    faClock,
    faFilm,
    faSpinnerThird,
    faFileAlt,
    faCheckCircle,
    faPlusCircle,
    faFileUpload,
    faFileDownload,
    faUserSlash,
    faUserTimes,
    faUserCheck,
    faUserPlus,
    faFunction,
    faNetworkWired,
    faSortAmountDown,
    faSortAmountUp,
    faChevronCircleUp,
    faChevronCircleDown,
    faEnvelopeOpenText,
    faLayerGroup,
    faFileCsv,
    faFileCode,
    faFileArchive,
    faPaperPlane,
    faFileVideo,
    faBan,
    faPaste,
    faSearchPlus,
    faRepeatAlt,
    faSlash,
    faChartGantt,
    faChartMixed,
    faChartScatterBubble,
    faChartColumn,
    faGaugeCircleBolt,
    faChartScatter,
    faChartNetwork,
    faTableLayout,
    faChartTreeMap,
    faSquareKanban,
    faMapLocationDot,
    faChartLineUp,
    faChartWaterfall,
    faGridDividers,
    faDash,
    faInputPipe,
    faChartSimple,
    faChartScatter3d,
    faMemoPad,
    faThumbTack,
    faCircleX,
    faSquareX,
    faEquals,
    faNotEqual,
    faGreaterThan,
    faGreaterThanEqual,
    faLessThan,
    faLessThanEqual,
    faArrowLeftFromLine,
    faArrowRightFromLine,
    faArrowsLeftRightToLine,
    faArrowsLeftRight,
    faCircleTrash
  );

  // Duotone icons
  library.addIcons(
    faSortUpDuo,
    faSortDownDuo
  );
}
