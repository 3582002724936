import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { logError } from '@softools/softools-core';
import { MultiSelectionFieldBase } from '../selection-check-field/selection-check-field.component';

@Component({
  selector: 'app-selection-list-field',
  templateUrl: './selection-list-field.component.html',
  styleUrls: ['./selection-list-field.component.scss', '../selection.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionListFieldComponent extends MultiSelectionFieldBase implements OnChanges {

  public overlayOpen = false;

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public override async activate() {
    this.input.nativeElement.focus();
  }

  public override async deactivate() {
  }

  public override refreshValue() {
    this.setValueFromRecord(this.record);
    this.input.nativeElement.value = this.displayText();
  }


  onSelection(value: string, $event: MatCheckboxChange) {
    this.selectionChanged(value, $event.checked);
    this.overlayOpen = false;
  }

  public onClicked(e: MouseEvent) {
    e.stopPropagation();
    if (!this.getIsDisabled()) {
      this.overlayOpen = !this.overlayOpen;
    }
  }

  public popupClosed() {
    this.overlayOpen = false;
    this.activate().catch(error => logError(error, 'Failed to activate'));
  }

}
