import { Enums } from '../';

export interface ExportOptions {
  exportTypes: Array<Enums.ExportType>;
  templatedReports: Array<{ Text: string, Value: number }>;
  templatedReportsForAppCombinedRecord: Array<{ Text: string, Value: number }>;
  showReportFieldsOnly: boolean;
}

export const initialExportOptions: ExportOptions = {
  exportTypes: [] as Array<Enums.ExportType>,
  templatedReports: [] as Array<{ Text: string, Value: number }>,
  templatedReportsForAppCombinedRecord: [] as Array<{ Text: string, Value: number }>,
  showReportFieldsOnly: true
};
