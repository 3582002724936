import { Injectable } from '@angular/core';

import { Observable, of, BehaviorSubject, Subject, lastValueFrom, firstValueFrom } from 'rxjs';

import { Record, QueryParams, RecordId, App, RecordCopyInfo, ValidationErrorResponse, IRecordSelection } from '../types';
import { AppDataRepository, SystemApi, SyncParameters, ReportDataRepository } from '../repos';
import { UrlResolver, logError } from '../utils';
import { switchMap } from 'rxjs/operators';

// See https://github.com/rollup/rollup/issues/670#issuecomment-281139978
import * as moment_ from 'moment';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { STORE_DATA, INDEX_APPIDENTIFIER, APPDATA_HIERARCHY_INDEX } from '../indexedDb/database-stores';
import { OnlineStatusService } from './online-status-service';
import { Enums } from '../types';
import { Field } from '../types/interfaces/field.interface';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { RecordConcretizerService } from './record-concretizer';
import { StorageServiceBase } from './storage-service-base';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangedRecord } from '../types/interfaces/changed-record.interface';
import { Application } from 'app/types/application';
import { HttpError } from '@microsoft/signalr';

const moment = moment_;

@Injectable({ providedIn: 'root' })
export class AppDataStorageService extends StorageServiceBase<Record, RecordId> {
  public syncRecordCount$ = new BehaviorSubject(null);

  private syncRecordCount = 0;

  public updatedRecord$ = new Subject<Array<Record>>();

  constructor(
    public appDataRepository: AppDataRepository,
    private urlResolver: UrlResolver,
    private onlineStatus: OnlineStatusService,
    protected systemApi: SystemApi,
    private recordConcretizerService: RecordConcretizerService,
    dbContext: DatabaseContextService<Record>,
    private reportDataRepository: ReportDataRepository
  ) {
    super(STORE_DATA, dbContext);
  }

  /**
   * Synchronise application data from the remote repository into the store.  The supplied query parameters
   * control the filtered subset of data retrieved (with $filter) and the maximum number of records (via $top)
   * if specified. Any existing data is removed from the store.
   *
   * This requires the service to be available, so should only be called when online.  Typically the application
   * data - either all records or a working subset - is sync'd while the application is online to prepare for
   * subsequent offline use.
   *
   * @param app Application to syn
   * @param syncParameters  Sync configuration
   * @param retryPolicy   Optionally specify a retry configuration (usually a @see RetryPolicy instance)
   */
  public async syncDataNoIndex(app: App, syncParameters: SyncParameters, retryPolicy: IRetryPolicy = null): Promise<boolean> {
    const policy = retryPolicy || NoRetryPolicy.instance;

    const appIdentifier = app.Identifier;

    this.syncRecordCount = 0;
    this.syncRecordCount$.next(this.syncRecordCount);

    await this.internalsyncData(app, syncParameters, policy);

    // Current sync mechamism doesn't use timestamps for sync
    // Remove any old horizn data
    localStorage.removeItem(`${appIdentifier}-Horizon`);

    return true;
  }
  /**
   * Synchronise app data without updating horizon
   *
   * @param app Application to syn
   * @param syncParameters  Sync configuration
   * @param retryPolicy   Optionally specify a retry configuration (usually a @see RetryPolicy instance)
   */
  public async syncDataNorizon(app: App, syncParameters: SyncParameters, retryPolicy: IRetryPolicy = null): Promise<boolean> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    await this.internalsyncData(app, syncParameters, policy);
    return true;
  }

  public async clearData() {
    return this.dbContext.clear(STORE_DATA);
  }

  /**
   * Get application data from the remote repository into the store.  The supplied query parameters
   * control the filtered subset of data retrieved (with $filter) and the maximum number of records (via $top)
   * if specified.
   *
   * Typically this should be used when managing an app that has been configured to only hold partial data.
   * Data specified by the range in the query parameters is loaded; if any records are already present the new
   * records with the same ids will replace the old ones in the store.
   *
   * @param appIdentifier
   * @param reportIdentifier
   * @param queryParams
   * @param hierarchy
   */
  public async getDataNoIndex(
    appIdentifier: string,
    reportIdentifier: string,
    queryParams: QueryParams,
    hierarchy?: string
  ): Promise<Array<Record>> {
    // Ensure the requested records are available
    await this.loadDataNoIndex(appIdentifier, reportIdentifier, queryParams, hierarchy);

    const first = queryParams.$skip || 0;
    const count = queryParams.$top || 25;

    let cursorCount = 0;
    const records: Array<Record> = [];

    await this.dbContext.openCursorAsync(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier, (record) => {
      if (cursorCount >= first && records.length < count && record._id) {
        records.push(record);
      }

      cursorCount = cursorCount + 1;
    });

    return records;
  }

  public async loadDataNoIndex(
    appIdentifier: string,
    reportIdentifier: string,
    queryParams: QueryParams,
    hierarchy?: string
  ): Promise<void> {
    const storeCount = await this.dbContext.countByIndex(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier);

    const first = queryParams.$skip || 0;
    const count = queryParams.$top || 25;

    if (first + count > storeCount) {
      const sortedQueryParams = this._sortAndCleanseQueryParams(queryParams);
      const added = await this.appDataRepository.getData(appIdentifier, reportIdentifier, sortedQueryParams, hierarchy).toPromise();

      for (let i = 0; i < added.length; ++i) {
        const record = added[i];
        await this.setRecordAsync(appIdentifier, record);
      }
    }
  }

  public async getAllDataNoIndex(appIdentifier: string): Promise<Array<Record>> {
    const records = [];

    await this.dbContext.openCursorAsync(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier, (value) => {
      records.push(value);
    });

    return records;
  }

  /** Get data from the local store. */
  // public getData(appIdentifier: string, reportIdentifier: string, queryParams: {}): Observable<Array<Record>> {

  //   const sortedQueryParams = this._sortAndCleanseQueryParams(queryParams);
  //   const dataKey = this._getKey(appIdentifier, reportIdentifier, sortedQueryParams);
  //   return Observable.create((observer: Subject<any>) => {
  //     this.indexDb.keys().subscribe((keys) => {
  //       const matchingKey = keys.find(k => k === dataKey);
  //       if (!matchingKey) {
  //         observer.next([]);
  //         observer.complete();
  //       } else {
  //         return this.indexDb.getItem(dataKey).subscribe(async (recordIds) => {
  //           if (recordIds && recordIds.length > 0) {
  //             const records = await Promise.all(recordIds.map(id => this.service.get(STORE_DATA, [appIdentifier, id])));
  //             observer.next(records);
  //           } else {
  //             observer.next([]);
  //           }
  //           observer.complete();
  //         });
  //       }
  //     });
  //   });
  // }

  public async getRecordAsync(appIdentifier: string, recordId: string, refresh: boolean = false): Promise<Record> {
    if (refresh && this.onlineStatus.isConnected) {
      const record = await this.appDataRepository.getRecord(appIdentifier, recordId).toPromise();
      await this.dbContext.put(STORE_DATA, appIdentifier, recordId, record);
    }

    return this.getRecordByIdAsync(recordId);
  }

  /** Get record by id, returning a Promise */
  public async getRecordByIdAsync(recordId: string): Promise<Record> {
    if (!recordId) {
      throw new Error(`getRecordByIdAsync: Called without record id.`);
    }

    const transaction = await this.dbContext.transaction(STORE_DATA, 'readonly');
    return this.dbContext.get(STORE_DATA, recordId, transaction);
  }

  // public getNextRecordId(appIdentifier: string, queryParams: {}, currentRecordId: string) {
  //   return this._getNextOrPreviousRecordId(appIdentifier, queryParams, currentRecordId, true);
  // }

  // public getPreviousRecordId(appIdentifier: string, queryParams: {}, currentRecordId: string) {
  //   return this._getNextOrPreviousRecordId(appIdentifier, queryParams, currentRecordId, false);
  // }

  /** @deprecated use @see storeRecordAsync which returns the modified record */
  public async setRecordAsync(appIdentifier: string, record: Record): Promise<boolean> {
    const currentRecord = await this.getRecordByIdAsync(record._id);

    return this.dbContext.put(STORE_DATA, appIdentifier, record._id, Object.assign({}, currentRecord, record));
  }

  /**
   * Store a record in storage.
   *
   * if @see mergeExisting is specified (the default)
   * the record is merged with any record already in storage, so if field values are missing from
   * the new record the old values will be retained.  The stored record with these changes included
   * and differences between the record returned by the API adjusted is returned and should be used
   * instead of the input record.  Null is returned if the update fails.
   *
   * @param app
   * @param record
   */
  public async storeRecordAsync(app: App, record: Record, mergeExisting = true): Promise<Record> {
    if (mergeExisting) {
      const currentRecord = await this.getRecordByIdAsync(record._id);
      const newRecord = this.mutateRecord(app, { ...record });
      const merged = currentRecord ? Object.assign({}, currentRecord, newRecord) : newRecord;
      const updated = await this.dbContext.put(STORE_DATA, app.Identifier, record._id, merged);
      return updated ? merged : null;
    } else {
      const updated = await this.dbContext.put(STORE_DATA, app.Identifier, record._id, record);
      return updated ? record : null;
    }
  }

  /**
   * Remove a record from storage
   * @param id Record id to remove
   */
  public async removeRecordAsync(id: RecordId): Promise<boolean> {
    return this.dbContext.delete(STORE_DATA, id);
  }


  /**
   * Delete records from the repository
   * @param app
   * @param selection
   * @param reportId      report id for base filter - 0 for no filtering by report
   * @param queryParams   query params to filter by when deleting all
   */
  public async deleteRecordsAsync(app: App, selection: IRecordSelection, reportId: number = 0, queryParams?: QueryParams) {

    return await this.appDataRepository.bulkDelete(
      { SelectedRows: selection.AllRecordsSelected ? [] : selection.SelectedRecordIds },
      app.Identifier,
      queryParams,
      reportId,
      queryParams?.hierarchy
    ).toPromise();

  }

  /**
   * Execute a callback function over each @see Record in the store.
   *
   * @param appIdentifier
   * @param callback
   */
  public async eachRecord(appIdentifier: string, callback: (record: Record) => any): Promise<void> {
    await this.dbContext.openCursorAsync(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier, (v) => {
      // The store contains housekeeping entries as well as records
      // Use the presence of the _id member to identify real records
      if (v._id) {
        callback(v);
      }
    });
  }

  /**
   * Execute a callback function over each matching child @see Record in the store.
   *
   * @param appIdentifier
   * @param hierarchy
   * @param callback
   */
  public async eachChildRecord(appIdentifier: string, hierarchy: string, callback: (record: Record) => any): Promise<void> {
    if (!hierarchy) {
      return this.eachRecord(appIdentifier, callback);
    } else {
      const keys = [appIdentifier, hierarchy];
      await this.dbContext.openCursorAsync(STORE_DATA, APPDATA_HIERARCHY_INDEX, keys, (v) => {
        // The store contains housekeeping entries as well as records
        // Use the presence of the _id member to identify real records
        if (v._id) {
          callback(v);
        }
      });
    }
  }

  public async nativeGetAll(appIdentifier: string, hierarchy?: string): Promise<Array<Record>> {
    if (hierarchy) {
      return this.dbContext.nativeGetAll(STORE_DATA, APPDATA_HIERARCHY_INDEX, [appIdentifier, hierarchy]);
    } else {
      return this.dbContext.nativeGetAll(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier);
    }
  }

  /** Get number of records in the application store */
  public countAsync(appIdentifier: string): Promise<number> {
    return this.dbContext.countByIndex(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier);
  }

  /** Get number of child records for a given parent */
  public async childCount(
    childAppIdentifier: string,
    parentAppIdentifier: string,
    parentRecordId: RecordId,
    archived?: boolean
  ): Promise<number> {
    // Builld keypatch and get matching record count
    const hierarchy = `${parentAppIdentifier}|${parentRecordId}`;

    if (archived === undefined) {
      // Get total count ignoring archived flag
      return this.dbContext.countByIndex(STORE_DATA, APPDATA_HIERARCHY_INDEX, [childAppIdentifier, hierarchy]);
    }

    // Checking archived flag so enumerate records
    // Relatively slow but number of records should be low.
    let count = 0;
    await this.eachChildRecord(childAppIdentifier, hierarchy, (record) => {
      if (!(<any>record).IsArchived) {
        count++;
      }
    });

    return count;
  }

  /** Get number of child records for a given parent */
  public async childCountAsync(childAppIdentifier: string, hierarchy: string) {
    return this.dbContext.countByIndex(STORE_DATA, APPDATA_HIERARCHY_INDEX, [childAppIdentifier, hierarchy]);
  }

  public async clearStore(appIdentifier: string): Promise<boolean> {
    return this.dbContext.clearIndex(STORE_DATA, INDEX_APPIDENTIFIER, appIdentifier);
  }

  /**
   * Get single record data directly from the server API
   *
   * @param app                 App to obtain data for
   * @param recordId            Record to query
   */
  public async getApiRecordByIdAsync(app: App, recordId: string): Promise<Record> {
    const record = await this.appDataRepository.getRecord(app.Identifier, recordId).toPromise();
    return record && this.mutateRecord(app, record);
  }

  /**
   * Get record data directly from the server API
   *
   * @param app                 App to obtain data for
   * @param reportIdentifier    Report id (implies base filter)
   * @param queryParams         Option parameters to filter data
   * @param hierarchy           Optional hierarchy parent for child apps
   */
  public async getApiDataAsync(app: App, reportIdentifier: string, queryParams: {}, hierarchy?: string): Promise<Array<Record>> {
    const records = await this.appDataRepository.getData(app.Identifier, reportIdentifier, queryParams, hierarchy).toPromise();
    return records.map((r) => this.mutateRecord(app, r, reportIdentifier));
  }

  /**
   * Get record data directly from the server API
   *
   * @param app                 App to obtain data for
   * @param reportId            Report id (implies base filter)
   * @param queryParams         Option parameters to filter data
   * @param hierarchy           Optional hierarchy parent for child apps
   */
  public async getApiReportDataAsync(
    app: App,
    reportId: number,
    queryParams: QueryParams,
    hierarchy?: string,
    archived?: boolean
  ): Promise<Array<Record>> {
    const reportIdentifier = app.Reports.find(report => report.Id === reportId)?.Identifier;
    const records = await this.reportDataRepository.getData(app.Identifier, reportId, queryParams, hierarchy, archived).toPromise();
    return records.map((r) => this.mutateRecord(app, r, reportIdentifier));
  }

  public async getApiRecordCursorAsync(
    app: App,
    reportId: number,
    cursor: string,
    queryParams: {},
    showArchived = false,
    hierarchy?: string
  ): Promise<Record> {
    const record = await this.reportDataRepository
      .getCursorData(app.Identifier, reportId, cursor, queryParams, showArchived, hierarchy)
      .toPromise();
    return this.mutateRecord(app, record.pop());
  }

  public async getRecordsByIdAsync(app: App, ids: Array<RecordId>) {
    return firstValueFrom(this.appDataRepository.getRecords(app.Identifier, ids));
  }

  /**
   * Get record count directly from the server API
   *
   * @param app                 App to obtain data for
   * @param reportIdentifier    Report id (implies base filter)
   * @param queryParams         Option parameters to filter records to count
   * @param hierarchy           Optional hierarchy parent for child apps
   */
  public async getApiCountAsync(app: App, _reportIdentifier: string, queryParams: {}, hierarchy?: string): Promise<number> {
    return this.appDataRepository.getCount(app.Identifier, queryParams, hierarchy).toPromise();
  }

  /**
   * Get the total number of records stored for the app
   *
   * @param app         App to count for
   * @param isArchived  true to count archived records
   * @param hierarchy   Optionally count records matching hierarchy
   */
  public async getApiTotalCountAsync(app: App, isArchived: boolean = false, hierarchy?: string): Promise<number> {
    const queryParams = { $filter: `IsArchived eq ${isArchived}` };
    return this.appDataRepository.getCount(app.Identifier, queryParams, hierarchy).toPromise();
  }

  public async getApiGroupInformation(app: App, groupBy: string, descending: boolean, start: number, count: number, archived: boolean, hierarchy: string, filter?: string) {
    return this.appDataRepository.getGroupInformation(app.Identifier, groupBy, descending, start, count, archived, hierarchy, filter);
  }

  public async getGroupCount(app: App, groupBy: string, hierarchy: string, filter?: string): Promise<{ count: number }> {
    return this.appDataRepository.getGroupCount(app.Identifier, groupBy, hierarchy, filter);
  }

  /**
   * Post a new record directly to the server API
   *
   * @param app                 App owning data
   * @param recordId            Record id to create
   * @param changes             Patch to apply to initialise the record
   * @param hierarchy           Optional hierarchy parent for child apps
   */
  public postApiRecord(app: App, recordId: string, changes: any, hierarchy?: string): Observable<Record> {
    return this.appDataRepository
      .create(app.Identifier, recordId, changes, hierarchy)
      .pipe(switchMap((rec) => of(rec && this.mutateRecord(app, rec))));
  }

  /**
   * Post a new record directly to the server API
   * If the sever returns validation errors, a ValidationErrorResponse is thrown
   */
  public async postApiRecordAsync(app: App, recordId: string, changes: any, hierarchy?: string): Promise<Record> {
    const record = await this.appDataRepository.create(app.Identifier, recordId, changes, hierarchy).toPromise();
    this.throwValidationError(record);
    return this.mutateRecord(app, record);
  }

  /**
   * Patch record changes directly to the server API
   *
   * @param app                 App owning data
   * @param recordId            Record id to update
   * @param changes             Patch to apply to the record
   * @param hierarchy           Optional hierarchy parent for child apps
   */
  public async patchApiRecordAsync(app: App, recordId: string, changes: any, hierarchy?: string): Promise<Record> {
    const record = await this.appDataRepository.save(app.Identifier, recordId, changes, hierarchy).toPromise();
    this.throwValidationError(record);
    return this.mutateRecord(app, record);
  }

  /**
   * Update records in storage that have changed since we last updated.
   *
   * This can be used to periodically check for changes made by other users and freshen the local store
   *
   * todo: This is a quick effort that uses existing APIs.  We should add a new a new API that checks all
   * apps in the site and that only returns a delta of changed fields.
   * We need to check for collisions and consider pending changes.
   *
   * @param appIdentifier
   *
   */
  public async refreshStorage(app: Application): Promise<Array<Record>> {
    try {
      // We ask for records changed since our last known stable state.  If any have changed
      // the server returns a collection that contains at least some of the changed records
      // in change order.  This allows us to calculate the next timestamp to query against:
      // * If records are returned we need to ask for records after the latest record's update time
      // * If no records are returned we can retain the previous timestamp
      // * If we don't have a stored timestamp, default to now.  This is not very useful but is only
      //   a fallback - we should always have a time from sync.
      // If we synthesize a time, we find out how synchronised the client and server clocks are and
      // look back in time by that amount.  This is less useful now but leaving in for safety.
      // It is possible (especially if we use default time) for records to be returned by more
      // than one call.  Users of this API should cope with that.
      // Timestamps are stored in numeric Unix Millisecord format.

      // Check browser online status because we're checking server connectivity
      // Do not use isConnected beacuse isServerReachable is set via the error below
      if (this.onlineStatus.isServerReachable) {
        // Get stored horizon value.  Allow for numeric timestamp or date string as format has changed.
        // Can remove date string version when whole world has resynced
        const storageKey = `${app.Identifier}-Horizon`;
        let horizon: moment_.Moment;
        const storedHorizon = localStorage.getItem(storageKey);
        if (!storedHorizon) {
          // Default to now (may want to change this...)
          horizon = moment.utc();
        } else if (Number.isNaN(+storedHorizon)) {
          horizon = moment.utc(storedHorizon);
        } else {
          horizon = moment.utc(+storedHorizon);
        }

        const records = await this.appDataRepository.recordsChangedSince(app.Identifier, horizon.valueOf());

        // We were able to get data from the server so connectivity is good
        this.onlineStatus.isServerReachable = true;

        if (records.length > 0) {
          // We got some records so update the horizon to the latest change time
          let newHorizon = horizon.valueOf();
          records.forEach((rec) => {
            if (rec.UpdatedDate.$date > newHorizon) {
              newHorizon = rec.UpdatedDate.$date;
            }
          });

          // If we get a full batch back, adjust down by 1ms so we will catch any
          // records with exactly the same timestamp.  This does mean some records
          // will be repeated so we still need to cope with that
          // Todo: in some cases user access will cause less than 200 records to be
          // returned in a full batch - we need to detect that case.
          // Todo: remove this hardcoded value.  See SOF-7421.
          if (records.length === 200) {
            newHorizon--;
          }

          if (newHorizon > horizon.valueOf()) {
            const newHorizonString = `${newHorizon}`;
            localStorage.setItem(storageKey, newHorizonString);
          }
        } else if (!storedHorizon) {
          // Client and server clocks may not be aligned, so we could miss changes that occur in the gap
          // caused by this difference.  Get the offset and look back that far to account for it
          const delta = await this.systemApi.getServerTimeDifferenceAsync();
          const lookBackSeconds = Math.ceil(Math.abs(delta) / 1000);

          // We had no horizon in storage and got no records so make the stored horizon when
          // we queried, stepped back by the differnece in clocks
          const newHorizonString = `${horizon.valueOf() - lookBackSeconds}`;
          localStorage.setItem(storageKey, newHorizonString);
        }

        // See what has really changed by examining existing records and their updated timestamp
        const changed: Array<Record> = [];
        for (let i = 0; i < records.length; ++i) {
          const record = this.mutateRecord(app, records[i]);

          const existing = await this.getRecordAsync(app.Identifier, record._id);
          if (!existing) {
            changed.push(record);
          } else {
            const incomingDate = record.UpdatedDate;
            const existingDate = existing.UpdatedDate;
            if (!incomingDate || !existingDate || incomingDate.$date > existingDate.$date) {
              changed.push(record);
            }
          }
        }

        if (changed.length > 0) {
          if (app.isOfflineApp) {
            // Update storage and return changed records
            const stored = await Promise.all(
              changed.map(async (record) => {
                await this.storeRecordAsync(app, record, false);
                return record;
              })
            );

            // Notify observers
            this.updatedRecord$.next(stored);

            // And return to caller
            return stored;
          } else {
            // Notify observers
            this.updatedRecord$.next(changed);

            // And return to caller
            return changed;
          }
        }
      }

      return [];
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        console.warn('Network error refreshing app data');
        switch (error.status) {
          case 0:   // http stack does this...
          case HttpStatusCode.BadRequest:
          case HttpStatusCode.NotFound:
            // Set offline state if plausibly not connected
            this.onlineStatus.isServerReachable = false;
            break;
          case HttpStatusCode.TooManyRequests:
          default:
            break;
        }

      } else if (error instanceof Error) {
        logError(error, `${error.message} error refreshing storage`);
      } else {
        // log error type - limited use due to minification :-(
        logError(error, `${error.constructor.name} error refreshing storage`);
      }

      return [];
    }
  }

  public async refreshRecord(app: App, recordId: string, recordUpdatedDate) {
    const record = await this.getApiRecordByIdAsync(app, recordId);
    if (record?.UpdatedDate.$date > recordUpdatedDate?.$date) {
      this.updatedRecord$.next([record]);
      return record;
    }

    return null;
  }

  // private _getNextOrPreviousRecordId(appIdentifier: string, queryParams: {}, currentRecordId: string, next: boolean) {
  //   return this.indexDb.getItem(`${appIdentifier}${this.urlResolver.getQueryString(queryParams)}`)
  //     .pipe(
  //       map((recordIds: Array<string>) => {
  //         const currentRecordIndex = recordIds.findIndex(recordId => recordId === currentRecordId);
  //         if (next) {
  //           return recordIds[currentRecordIndex + 1];
  //         } else {
  //           return recordIds[currentRecordIndex - 1];
  //         }
  //       })
  //     );
  // }

  private _getKey(appIdentifier: string, reportIdentifier: string, queryParams: {}) {
    const key = `${appIdentifier}-${reportIdentifier}-${this.urlResolver.getQueryString(queryParams)}`;
    return key;
  }

  private _sortAndCleanseQueryParams(queryParams: {}) {
    const sortedQueryParms = Object.keys(queryParams)
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .reduce((result, key) => {
        result[key] = queryParams[key];
        return result;
      }, {});

    delete sortedQueryParms['refresh']; // refresh added to cause router subscriptions to get triggered

    return sortedQueryParms;
  }

  public async searchAsync(appIdentifier: string, query: string, callback: (record: Record) => any) {
    await this.dbContext.openCursorForSearchAsync(
      STORE_DATA,
      INDEX_APPIDENTIFIER,
      query,
      'QuickFilterSearchText',
      appIdentifier,
      (record) => {
        callback(record);
      }
    );
  }

  /**
   * Copy a record
   *
   * @param app
   * @param sourceId Source record id
   * @param copyInfo Copy configuration
   * @param hierarchy Option hierarchy string for child records
   */
  public async copyRecordAsync(app: App, sourceId: string, copyInfo: RecordCopyInfo, hierarchy?: string): Promise<Record> {
    const record = await lastValueFrom<Record>(this.appDataRepository.copy(app.Identifier, sourceId, copyInfo, hierarchy));
    this.throwValidationError(record);
    return record && this.mutateRecord(app, record);
  }

  private async internalsyncData(
    app: App,
    syncParameters: SyncParameters,
    policy: IRetryPolicy,
    recordCallback?: (rec) => void
  ): Promise<boolean> {
    const appIdentifier = app.Identifier;

    // Clone parameters as we're going to modifiy it
    const parameters = { ...syncParameters, AfterRecordId: null };

    for (; ;) {
      const added = await policy.execute(() => this.appDataRepository.syncData(appIdentifier, parameters));

      // Publish sync record count
      this.syncRecordCount += added.length;
      this.syncRecordCount$.next(this.syncRecordCount);

      let abortEvent: Event = null;

      const transaction = await this.dbContext.transaction(STORE_DATA);
      try {
        await transaction.open();

        transaction.onabort = (ev) => {
          abortEvent = ev;
        };

        for (let i = 0; i < added.length; ++i) {
          const record = this.mutateRecord(app, added[i]);
          await this.dbContext.put(STORE_DATA, appIdentifier, record._id, record, transaction);

          if (recordCallback) {
            recordCallback(record);
          }
        }

        if (abortEvent) {
          throw abortEvent;
        }

        if (added.length === syncParameters.PageSize) {
          // Got a full set back so there may be more.  Ask for next batch
          parameters.AfterRecordId = added[syncParameters.PageSize - 1]._id;
        } else {
          break;
        }
      } finally {
        transaction.close().catch(error => logError(error, 'Failed to close internalsyncData transaction'));
      }
    }

    return true;
  }

  public mutateRecord(app: App, record: Record, reportIdentifier?: string): Record {
    // Copy record - update in place causes problems if a shared promise
    // returns the same records
    record = { ...record };

    if (app.Fields) {
      app.Fields.forEach((field) => {
        switch (field.Type) {
          case Enums.FieldType.Text:
          case Enums.FieldType.LongText:
          case Enums.FieldType.UrlField:
          case Enums.FieldType.Email:
          case Enums.FieldType.Image:
          case Enums.FieldType.ImageActionButton:
          case Enums.FieldType.Barcode:
          case Enums.FieldType.Notes:
          case Enums.FieldType.AttachmentList:
          case Enums.FieldType.InAppChart:
          case Enums.FieldType.Gantt:
          case Enums.FieldType.Person:
          case Enums.FieldType.PersonByTeam: {
            this._removeValueIfDefault(record, field, '');
            break;
          }
        }
      });
    }

    if (!record.AppIdentifier) {
      record.AppIdentifier = app.Identifier;
    }

    if (reportIdentifier) {
      record._reportIdentidier = reportIdentifier;
    }

    record = this.recordConcretizerService.concreteRecord(record);

    return record;
  }

  public async getLatestChange(): Promise<ChangedRecord> {
    return await this.appDataRepository.getLatestChange();
  }

  public async getChangesAfter(changeId: string, count = 200): Promise<Array<ChangedRecord>> {
    return await this.appDataRepository.getChangesAfter(changeId, count);
  }

  public async getAppChangesAfter(appIdentifier: string, changeId: string, count = 200): Promise<Array<ChangedRecord>> {
    return await this.appDataRepository.getChangesAfter(changeId, count, appIdentifier);
  }

  public async getIndexAsync(
    appIdentifier: string,
    skip: number,
    count: number,
    reportIdentifier?: string,
    query?: QueryParams,
    hierarchy?: string) {
    return await this.appDataRepository.getIndexAsync(appIdentifier, skip, count, reportIdentifier, query, hierarchy);
  }

  public async getRecordPositionAsync(
    recordId: RecordId,
    appIdentifier: string,
    reportIdentifier: string,
    query?: QueryParams,
    hierarchy?: string
  ): Promise<number> {
    return await this.appDataRepository.getRecordPositionAsync(recordId, appIdentifier, reportIdentifier, query, hierarchy);
  }

  private _removeValueIfDefault(record: Record, field: Field, defValue: any) {
    const value = record[field.Identifier];
    if (value === null || value === defValue) {
      delete record[field.Identifier];
    }
  }

  private throwValidationError(record: any) {
    if (record?.ValidationErrors) {
      const validationError = new ValidationErrorResponse();
      Object.assign(validationError, record);
      throw validationError;
    }
  }
}
