export module ChartEnums {

  export enum Calculation {
    Total = 0,
    Average = 1,
    Minimum = 2,
    Maximum = 3
  }

  export enum ZoomType {
    none = 0,
    XandY = 1,
    Xaxis = 2,
    Yaxis = 3
  }

  export enum ChartFieldType {
    ActualStart = 0,
    ActualEnd = 1,
    Colour = 2,
    Cumulative = 3,
    HoverText = 4,
    Label = 5,
    PieLevel = 6,
    ProjectName = 7,
    Series = 8,
    Status = 9,
    TargetStart = 10,
    TargetEnd = 11,
    X = 12,
    Y = 13,
    Z = 14,
    MonteCarlo = 15,
    Geography = 16,
    Value = 17,
    Latitude = 18,
    Longitude = 19,
    LocationMetaDataJson = 20,
    Gauge = 21,
    GoogleMapTitle = 22,
    GoogleMapDescription = 23,
    GoogleMapLinkName = 24,
    GoogleMapLinkUrl = 25,
    NetworkLevel = 26,
    NetworkAggregate = 27,
    Order = 28
  }

  export enum ChartLabelType {
    Horizontal = 0,
    Vertical = 1,
    Diagonal = 2,
    Hidden = 3
  }

  export enum MonteCarloType {
    Bell = 0,
    SCurve = 1
  }

  export enum PolarCalculation {
    AverageExcludingZeros = 0,
    AverageIncludingZeros = 1,
    Summation = 2
  }

  export enum Function {
    None = 0,
    Addition = 1
  }

  export enum SeriesType {
    Column = 0,
    Line = 1,
    Scatter = 2,
    Spline = 3,
    StackedColumn = 4
  }

  export enum DateTimeStyles {
    None = 0,
    AllowLeadingWhite = 1,
    AllowTrailingWhite = 2,
    AllowInnerWhite = 4,
    AllowWhiteSpaces = 7,
    NoCurrentDateDefault = 8,
    AdjustToUniversal = 16,
    AssumeLocal = 32,
    AssumeUniversal = 64,
    RoundtripKind = 128
  }

  export enum Map {
    Europe = 0,
    UnitedKingdom = 1,
    World = 2
  }

  export enum RowColumnTotals {
    None = 0,
    RowOnly = 1,
    ColumnOnly = 2,
    RowAndColumn = 3
  }

  export enum MonteCarloIterations {
    OneHundredThousand = 0,
    FiveHundredThousand = 1,
    OneMillion = 2
  }


  export enum ChartErrors {
    NoError = 0,
    NoChartFields = 1,
    GaugeNotEnoughData = 2,
    ChartNotPresent = 3,
    NoRecords = 4,
    TooManySeries = 5,
    NoXorYField = 6,
    FieldsDontExist = 7,
    NoColumnField = 8,
    NoDefaultSeriesName = 9
  }
}
