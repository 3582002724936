import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { logError, NotesService } from '@softools/softools-core';
import { NotesComponent } from 'app/softoolsui.module/notes.component/notes.component';
import { EditableFieldBase } from 'app/softoolsui.module/fields';

@Component({
  selector: 'app-notes-field-2',
  templateUrl: './notes-field.component.html',
  styleUrls: ['./notes-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesFieldComponent extends EditableFieldBase<string> implements OnInit {

  private _previousValue: string;

  constructor(public notesService: NotesService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.value = this.recordValue || '';
    this._previousValue = this.value;
  }

  public async openDialog(): Promise<void> {
    try {
      const data = {
        appIdentifier: this.record.AppIdentifier,
        recordId: this.record._id,
        fieldIdentifier: this.fieldModel.Identifier,
        field: this.fieldModel,
        editable: true /* todo */
      };

      const result = await this.globalModel.dialogAsync(NotesComponent, data);
      if (result && result !== this._previousValue) {
        await this.dispatchChangeAsync(result);
        this._previousValue = result;
      }
    } catch (error) {
      logError(error, 'note dlg');
    }
  }
}
