export interface QueryParams {
  $skip?: number;
  $top?: number;
  $search?: string;
  $filter?: string;
  $orderby?: string;
  $groupby?: string;
  hierarchy?: string;
}

export class QueryParameters implements QueryParams {
  /* eslint-disable  */
  $skip?= 0;
  $top?= 25;
  $search?: string;
  $filter?: string;
  $orderby?: string;
  $groupby?: string;
  hierarchy?: string;
  /* eslint-enable */

  constructor(query: QueryParams) {
    if (query) {
      if (query.$filter) {
        this.$filter = query.$filter;
      }

      if (query.$groupby) {
        this.$groupby = query.$groupby;
      }

      if (query.$orderby) {
        this.$orderby = query.$orderby;
      }

      if (query.$search) {
        this.$search = query.$search;
      }

      if (query.$skip) {
        this.$skip = parseInt(query.$skip.toString(), 0);
      } else {
        this.$skip = 0;
      }

      if (query.$top) {
        this.$top = parseInt(query.$top.toString(), 0);
      } else {
        this.$top = 25;
      }

      if (query.hierarchy) {
        this.hierarchy = query.hierarchy;
      }
    }
  }

  // public getFromFilter(filter: Filter): QueryParameters {

  //   if (filter.Filter) {
  //     this.$filter = filter.Filter.replace('$filter='; '')
  //   }

  //   if (filter.Group) {
  //     this.$groupby = this._getGroupBy(filter.Group);
  //   }

  //   if (filter.OrderBy) {
  //     this.$orderby = filter.OrderBy;
  //   }

  //   if (filter.Search) {
  //     this.$search = filter.Search;
  //   }

  //   if (filter.Skip) {
  //     this.$skip = parseInt(filter.Skip.toString());
  //   } else {
  //     this.$skip = 0;
  //   }

  //   if (filter.Top) {
  //     this.$top = parseInt(filter.Top.toString());
  //   } else {
  //     this.$top = 25;
  //   }

  //   return Object.assign({}; this);
  // }
}
