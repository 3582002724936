/* eslint-disable */
/* TODOL Remove at some point */

import * as parser from '@softools/odata-parser';
import { OdataPropertyExpression } from './odata-property-expression';
import { OdataExpression, OdataLiteralExpression } from './odata-expression';
import { OdataBinaryExpression, OdataAndExpression, OdataOrExpression, OdataComparisonExpression, OdataStartsWithExpression, OdataEndsWithExpression, OdataSubstringExpression } from './odata-binary-expression';
import { OdataQueryFilterExpression } from './odata-query-filter-expression';
import { IRootQuery } from './root-query';
import { OdataExpressionType } from './odata-expression-type';
import { logError, LogLevel } from '../log-error';
import { OdataNotExpression } from './odata-not-expression';

export interface ILookupValue {
  getValue(name: string, values: any, listRow?: number): any;
}

export class DefaultValueLookup implements ILookupValue {
  getValue(name: string, values: any) {
    return values[name];
  }
}

/** Model of an ODATA query filter, parsed to typed objects for each node */
export class OdataQueryFilter implements ILookupValue, IRootQuery {

  public filter: OdataQueryFilterExpression;

  public isValidQuery: boolean;
  public lastError: any;

  /**
   * Indicates whether the query is a simple filter, i.e. whether it can be
   * represented as a single row of filter options.  A simple filter must therefore
   * have at most one comparison operation per column, and the comparison must be
   * against a literal value.  The comparisons must be combined using and operators
   * only.
   */
  public isSimpleFilter = false;


  public propertyExpressions = new Map<string, Array<OdataPropertyExpression>>();

  public comparisons = new Map<string, Array<SimpleComparison>>();

  public nodes = new Map<number, OdataExpression>();

  public lookupValue: ILookupValue;

  private _nextId = 1;

  private matchAllSquareBrackets = /[\[\]]/g;

  constructor(queryString: string, lookupValue?: ILookupValue) {

    this.lookupValue = lookupValue || new DefaultValueLookup();

    // Historicaally our filters quoted identifiers with square brackets
    // This is not legal Odata syntax and blows up the parser.
    // Avoid this by stripping all square brackets (which should not appear
    // elsewhere in the query).  This can be removed if/when all of our
    // filters have been brought into line with the spec.
    // Also remove any existing $filter= prefix so we don't add a second copy
    queryString = queryString && queryString.replace(this.matchAllSquareBrackets, '').replace('$filter=', '');

    const filterString = queryString ? `$filter=${queryString}` : '';
    try {

      if (filterString.length === 0) {
        // no filter string supplied so allow everything through by not setting
        // a filter.  We mark this as invalid as that is similar to previous
        // behavour but might want to change that or have a specific state
        this.isValidQuery = false;
      } else {
        // Parse the filter string to a rough JS object
        const tree = parser.parse(filterString);

        if (tree.error) {
          this.lastError = tree.error;
          this.isValidQuery = false;
        } else {
          const filterNode = tree.$filter;
          if (filterNode) {
            this.filter = new OdataQueryFilterExpression(filterNode, this);
            this.isValidQuery = true;

            // Examine the tree to determine simplicity
            this.isSimpleFilter = this.isSimple();

          } else {
            this.lastError = 'No filter returned by parser';
            this.isValidQuery = false;
          }
        }
      }
    } catch (error) {
      logError(error, `Parse error in filter ${queryString}`, LogLevel.warning);
      this.lastError = error;
      this.isValidQuery = false;
    }
  }

  public createExpression(onode: any, expressionType: string, parent: OdataExpression): OdataExpression {
    switch (expressionType.toLowerCase()) {
      case 'and':
        return new OdataAndExpression(onode, this, parent);
      case 'or':
        return new OdataOrExpression(onode, this, parent);
      case 'not':
        return new OdataNotExpression(onode, this, parent);
      case 'eq':
        return new OdataComparisonExpression(OdataExpressionType.Equals, onode, this, parent);
      case 'ne':
        return new OdataComparisonExpression(OdataExpressionType.NotEquals, onode, this, parent);
      case 'gt':
        return new OdataComparisonExpression(OdataExpressionType.GreaterThan, onode, this, parent);
      case 'ge':
        return new OdataComparisonExpression(OdataExpressionType.GreaterThanOrEqual, onode, this, parent);
      case 'lt':
        return new OdataComparisonExpression(OdataExpressionType.LessThan, onode, this, parent);
      case 'le':
        return new OdataComparisonExpression(OdataExpressionType.LessThanOrEqual, onode, this, parent);
      case 'property':
        return new OdataPropertyExpression(onode, this, parent);
      case 'literal':
        return new OdataLiteralExpression(onode, this, parent);
      case 'functioncall': {
        switch (onode.func.toLowerCase()) {
          case 'startswith':
            return new OdataStartsWithExpression(onode, this, parent);
          case 'endswith':
            return new OdataEndsWithExpression(onode, this, parent);
          case 'substringof':
            return new OdataSubstringExpression(onode, this, parent);
        }
        break;
      }
    }

    return null;
  }

  public addReference(expression: OdataPropertyExpression) {
    const current = this.propertyExpressions.get(expression.name) || [];
    this.propertyExpressions.set(expression.name, [...current, expression]);
  }

  public addReferenceIfSimple(expression: OdataBinaryExpression) {

    let property: OdataPropertyExpression = null;
    let literal: OdataLiteralExpression = null;

    if (expression.leftExpression instanceof OdataPropertyExpression
      && expression.rightExpression instanceof OdataLiteralExpression) {

      property = expression.leftExpression;
      literal = expression.rightExpression;

    } else if (expression.leftExpression instanceof OdataLiteralExpression
      && expression.rightExpression instanceof OdataPropertyExpression) {

      property = expression.rightExpression;
      literal = expression.leftExpression;
    }

    if (property && literal) {
      const comparison = new SimpleComparison(expression, property.name, literal.value);
      const current = this.comparisons.get(comparison.name) || [];
      this.comparisons.set(comparison.name, [...current, comparison]);
    }
  }

  /** Discover whether this is a simple filter. */
  public isSimple(): boolean {

    // If any term has more than one expression we don't
    // consider it simple
    let simple = true;
    this.comparisons.forEach(element => {
      if (element.length > 1) {
        simple = false;
      }
    });

    if (!simple) {
      return false;
    } else {
      // A null filter is simple, otherwise walk the filter
      // This finds any parts of the filter too complex for us to treat as simple
      return !this.filter || this.filter.isSimple();
    }
  }

  /** Add the expression into the node map and assign a unique id */
  public addExpression(node: OdataExpression) {
    node.id = this._nextId++;
    node.root = this;
    this.nodes.set(node.id, node);
  }

  /** Remove the expression from the node map
   * NB This does not remove the node from the tree; it should be called when the tree has been adjusted.
   */
  public removeExpression(node: OdataExpression) {
    this.nodes.delete(node.id);
  }

  /** Call a supplied function for each node in the tree
   * Iteration continues until the function returns a truthy value which can be an
   * exoression of true, allowing the function to be used for searches or evaluation
   * a condition.  Returning a falsy value allows iteration to be terminated early.
   */
  public each(callback: (node: OdataExpression) => OdataExpression | boolean | null) {
    return this.filter && this.filter.each(callback);
  }

  /**
   * Replace the node specified with a new subtree.  It is placed in exactly the
   * same position, e.g. replacing the node that is being used as the right operand
   * of a binary operation makes the new expression subtree the right operand.  It
   * does not have to be the same type as the original expression.
   *
   * @param nodeId
   * @param newNode
   */
  public replace(nodeId: number, newNode: OdataExpression) {

    // Find the node we are replacing
    const expression = this.nodes.get(nodeId);
    if (expression instanceof OdataExpression) {

      // The nodes in the old expression will be removed so clear from node map
      expression.each(n => {
        this.removeExpression(n);
        return false;
      });

      newNode.root = this;

      // Replace old node in parent with new one
      expression.parent.replace(nodeId, newNode);

      // Assign new ids and map the new child nodes
      newNode.each(n => {
        this.addExpression(n);
        return false;
      });

    } else {
      throw new Error(`Could not find not ${nodeId} in expression tree`);
    }
  }

  /**
   * Insert a node at the head of a query expression.  The expression is joined to
   * the existing expression tree with an and clause
   * @param newNode
   */
  public insertAtFront(newNode: OdataExpression) {

    // Initialise for empty filter
    if (!this.filter) {
      this.filter = new OdataQueryFilterExpression(null, this);
    }

    this.filter.insertAtFront(newNode);
  }

  /**
   * Delete a node from the expresion tree
   * @param nodeId
   */
  public delete(nodeId: number) {
    const expresion = this.find(nodeId);
    if (expresion) {
      expresion.parent.removeChild(nodeId);
      expresion.parent.repair();
    }
  }

  public find(nodeId: number): OdataExpression {
    const expression = this.filter && this.filter.each(node => {
      return (node.id === nodeId ? node : false);
    });
    return expression instanceof OdataExpression ? expression : null;
  }

  /** Get the textual form of the query expression */
  public expressionText() {
    return this.filter?.expressionText();
  }

  public isMatch(values: {}, listRow?: number): boolean {
    if (this.filter) {
      return this.filter.isMatch(values, listRow);
    } else {
      return true;
    }
  }

  public getValue(name: string, values: any, listRow?: number): any {
    return this.lookupValue.getValue(name, values, listRow);
  }
}

export class SimpleComparison {
  constructor(public expr: OdataBinaryExpression, public name: string, public value: any) { }
}
