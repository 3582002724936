import { Component, ComponentRef, Input } from '@angular/core';
import { FieldBase } from 'app/softoolsui.module/fields/field-base';
import { TemplateFieldComponent } from 'app/workspace.module/components/template/template-field/template-field.component';
import { MultiLineTextDisplayFieldComponentComponent } from '../text-display-field/multi-line-text-display-field.component';
import { TextDisplayFieldComponent } from '../text-display-field/text-display-field.component';

@Component({
  selector: 'sof-grid-cell',
  templateUrl: '../field/field.component.html',
  styleUrls: [
    './grid-field.component.scss',
    '../field/field.component.scss'
  ]
})
export class GridCellFieldComponent extends TemplateFieldComponent {

  public override addLongTextComponent(): ComponentRef<FieldBase> {

    // If parent grid is readonly, use a text display instead
    const isGridDisabled = this.containerField.isDisabled(this.record, this.ruleState);
    if (isGridDisabled) {
      const component = this.addReadonlyChildComponent(MultiLineTextDisplayFieldComponentComponent);
      component.instance.hideUnderline = true;
      component.instance.minWidthRem = 14;
      return component;
    }

    return super.addLongTextComponent();
  }

  protected override addTextComponent(): ComponentRef<FieldBase> {
    // If parent grid is readonly, use a text display instead
    const isGridDisabled = this.containerField.isDisabled(this.record, this.ruleState);
    if (isGridDisabled) {
      return this.addTextDisplayComponent();
    }

    return super.addTextComponent();
  }

  protected override addTextDisplayComponent(): ComponentRef<FieldBase> {
    const component = this.addReadonlyChildComponent(TextDisplayFieldComponent);
    component.instance.minWidthRem = 12;

    const isGridDisabled = this.containerField.isDisabled(this.record, this.ruleState);
    if (isGridDisabled) {
      component.instance.hideUnderline = true;
    }

    return component;
  }
}
