import { Security, initialSecurity } from '../interfaces/security.interface';
import { Export, initialExport } from '../interfaces/export.interface';
import { EmbeddedVideoData, initialEmbeddedVideoData } from '../interfaces/embedded-video-data.interface';
import { LookupData, initialLookupData } from '../interfaces/lookup-data.interface';
import { HistoryData, initialHistoryData } from './historydata.interface';

export interface ModalComponentContentData {
  Security: Security;
  Export: Export;
  EmbeddedVideo: EmbeddedVideoData;
  Lookup: LookupData;
  HistoryData: HistoryData;
}

export const initialModalComponentContentData: ModalComponentContentData = {
  Security: initialSecurity,
  Export: initialExport,
  EmbeddedVideo: initialEmbeddedVideoData,
  Lookup: initialLookupData,
  HistoryData: initialHistoryData
};
