import { Field, IndexedAppData, logError } from '@softools/softools-core';
import { Chart, Enums, stringCompare } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { MatrixTableData } from 'app/types/matrix/matrix-table-data';
import { extractDecimal } from 'app/_constants';

export interface MatrixCellReference {
  recordId: string;
  xAxisValue: any;
  yAxisValue: any;
  value: any;
  styleValue?: any;
}

export class MatrixCellModel {
  references = new Array<MatrixCellReference>();
  count = 0;
  total = 0;
}

export interface AxisModel {
  value: any;
  type: Enums.FieldType;
}

export class MatrixModel {

  // Columns
  /**
   * Field Id for X axis. If not specified the values are not associated with fields and 
   * the axis values should be used as labels.
   */
  public xAxisId: string;
  public xAxisField: AppField;
  public xAxisValues = new Map<any, any>();
  public columns = new Array<AxisModel>();
  public columnTotals: Array<number>;

  // Rows
  /**
   * Field Id for Y axis. If not specified the values are not associated with fields and 
   * the axis values should be used as labels.
   */
  public yAxisId: string;
  public yAxisField: AppField;
  public yAxisValues = new Map<any, any>();
  public rows = new Array<AxisModel>();
  public rowTotals: Array<number>;

  // Cells
  public cells = new Map<string, MatrixCellModel>();

  public detailsField: AppField;

  public grandTotal = 0;

  // Styling
  private colourSourceField: AppField;

  public isDetailNumeric = false;

  /**
   *  If true we calculate totals within the model, required when using local data.
   * If false totals are not processed, but may be provided as normal rows and columns in matrix data
   * */
  public calculatedTotals = false;

  public constructor(public chart: Chart, private app: Application) {
    this.xAxisId = chart.XAxisFieldIdentifier;
    this.yAxisId = chart.YAxisFieldIdentifier;
    this.xAxisField = this.xAxisId ? this.app.getField(this.xAxisId) : null;
    this.yAxisField = this.yAxisId ? this.app.getField(this.yAxisId) : null;

    // Look up field to be used for background, leave undefined if none or invalid
    // NB current demo level implementation assumes it to be a multistate
    if (chart.CellBackgroundColourSource !== undefined) {
      this.colourSourceField = this.app.getField(chart.CellBackgroundColourFieldIdentifier);
    }

    if (this.chart.DetailsFieldIdentifier) {
      this.detailsField = this.app.getField(this.chart.DetailsFieldIdentifier);
      if (this.detailsField) {
        switch (this.detailsField.Type) {
          case Enums.FieldType.Number:
          case Enums.FieldType.Money:
          case Enums.FieldType.Integer:
          case Enums.FieldType.Long:
          case Enums.FieldType.Range:
            this.isDetailNumeric = true;
            break;
        }
      }
    }
  }

  /** Add the records contained in the app data service */
  public async addRecords(appDataService: IndexedAppData) {

    await appDataService.eachRecord(record => {

      try {

        const xValue = this.xAxisField.getRecordValue(record);
        const xKey = this._valueKey(xValue, this.xAxisField.Type);
        if (!this.xAxisValues.has(xKey)) {
          this.xAxisValues.set(xKey, xValue);
        }

        const yValue = this.yAxisField.getRecordValue(record);
        const yKey = this._valueKey(yValue, this.yAxisField.Type);
        if (!this.yAxisValues.has(yKey)) {
          this.yAxisValues.set(yKey, yValue);
        }

        if (xKey !== undefined && yKey !== undefined) {
          const value = this.detailsField && this.detailsField.getRecordValue(record);

          const key = `${xKey}\t${yKey}`;
          let cell = this.cells.get(key);
          if (!cell) {
            cell = new MatrixCellModel();
            this.cells.set(key, cell);
          }

          // Placeholders... should do an aggregation specific action
          const ref: any = { recordId: record._id, value, xAxisValue: xValue, yAxisValue: yValue };
          if (this.colourSourceField) {
            ref.styleValue = this.colourSourceField.getRecordValue(record);
          }

          cell.references.push(ref);
          cell.count++;

          if (Number.isFinite(value)) {
            cell.total += value;
          }
        }

      } catch (error) {
        logError(error, 'Error adding records to matrix model');
      }
    });

    // Build and sort heading arrays for both axes
    this.xAxisValues.forEach((heading) => {
      this.columns.push({ value: heading, type: this.xAxisField.Type });
    });

    this.columns = this._sort(this.columns, this.xAxisField);

    this.yAxisValues.forEach((heading) => {
      const rowModel: AxisModel = { value: heading, type: this.yAxisField.Type };
      this.rows.push(rowModel);
    });

    this.rows = this._sort(this.rows, this.yAxisField);

    this.calculateTotals();
  }

  /** Populate the model using table data precalculated by the server */
  public importTableData(matrixTableData: MatrixTableData, xSort = true, ySort = true) {

    try {
      if (matrixTableData) {

        matrixTableData.Columns.forEach(col => {
          const columnModel: AxisModel = { value: col.Value, type: col.FieldType };
          const xKey = this._valueKey(columnModel.value, columnModel.type);
          this.xAxisValues.set(xKey, columnModel.value);
          this.columns.push(columnModel);
        });

        matrixTableData.Rows.forEach(row => {
          const yKey = this._valueKey(row.Label, row.LabelFieldType);
          this.yAxisValues.set(yKey, row.Label);
          this.rows.push({ value: row.Label, type: row.LabelFieldType });

          row.Cells.forEach((cell, index) => {
            const columnModel = this.columns[index];
            const xKey = this._valueKey(columnModel.value, columnModel.type);
            const key = `${xKey}\t${yKey}`;
            const cellModel = new MatrixCellModel();
            if (this.chart.CellType === Enums.CellType.Count) {
              cellModel.count = cell.ListOfValues[0].Value;
              cellModel.total = cellModel.count;
              cellModel.references = [{
                recordId: undefined,
                value: undefined,
                xAxisValue: cell.XAxisFieldValue,
                yAxisValue: cell.YAxisFieldValue
              }];
            } else {
              cellModel.count = cell.ListOfValues.length;
              cellModel.total = 0;
              cell.ListOfValues.forEach(val => {
                const ref: any = {
                  recordId: val.RecordId,
                  value: val.Value,
                  xAxisValue: cell.XAxisFieldValue,
                  yAxisValue: cell.YAxisFieldValue
                };
                cellModel.references.push(ref);

                if (this.isDetailNumeric) {
                  cellModel.total += +val.Value;
                }
              });
            }
            this.cells.set(key, cellModel);
          });
        });

        if (this.xAxisField && xSort) {
          this.columns = this._sort(this.columns, this.xAxisField);
        }

        if (this.yAxisField && ySort) {
          this.rows = this._sort(this.rows, this.yAxisField);
        }
      }

    } catch (error) {
      logError(error, 'Error importing data into matrix model');
    }
  }

  public rowCells(rowIndex: number): Array<MatrixCellModel> {
    const cells = new Array<MatrixCellModel>(this.columns.length);

    const row = this.rows[rowIndex];
    const yKey = this._valueKey(row.value, row.type);

    this.columns.forEach((columnModel, index) => {
      const xKey = this._valueKey(columnModel.value, columnModel.type);
      const key = `${xKey}\t${yKey}`;
      const cell = this.cells.get(key);
      if (cell) {
        cells[index] = cell;
      }
    });

    return cells;
  }

  public rowTotal(rowIndex: number): number {
    return this.rowTotals[rowIndex];
  }

  public columnHeaderValue(columnModel: AxisModel) {
    const key = this._valueKey(columnModel.value, columnModel.type);
    return this.xAxisValues.get(key);
  }

  public rowHeaderValue(row: AxisModel) {
    const key = this._valueKey(row.value, row.type);
    return this.yAxisValues.get(key);
  }

  public cellClass(cell: MatrixCellModel) {

    if (cell && this.colourSourceField) {
      if (cell.references && cell.references.length > 0) {
        const ref = cell.references[0];
        switch (ref.styleValue) {
          case 'Red':
            return 'red-cell';
          case 'Green':
            return 'green-cell';
          case 'Amber':
            return 'amber-cell';
          case 'Blue':
            return 'blue-cell';
          case 'Black':
            return 'black-cell';
        }
      }
    }

    return '';
  }

  public get showCountInCells() {
    return this.chart.CellType === Enums.CellType.Count;
  }

  public get showOverallTotalColumn() {
    return this.calculatedTotals && this.chart.RowColumnTotals === Enums.RowColumnTotals.ColumnOnly;
  }

  public get showRowTotalColumn() {
    return this.calculatedTotals && this.isDetailNumeric && (this.chart.RowColumnTotals === Enums.RowColumnTotals.RowOnly || this.chart.RowColumnTotals === Enums.RowColumnTotals.RowAndColumn);
  }

  public get showRowCountTotalColumn() {
    return this.calculatedTotals && !this.isDetailNumeric && (this.chart.RowColumnTotals === Enums.RowColumnTotals.RowOnly || this.chart.RowColumnTotals === Enums.RowColumnTotals.RowAndColumn);
  }

  public get showTotalsRow() {
    return this.calculatedTotals && (this.chart.RowColumnTotals === Enums.RowColumnTotals.ColumnOnly
      || this.chart.RowColumnTotals === Enums.RowColumnTotals.RowAndColumn ||
      this.showOverallTotalRow
    );
  }

  public get showOverallTotalRow() {
    return this.calculatedTotals && this.chart.RowColumnTotals === Enums.RowColumnTotals.RowOnly;
  }

  public get showColumnTotalRow() {
    return this.calculatedTotals && this.isDetailNumeric && (this.chart.RowColumnTotals === Enums.RowColumnTotals.ColumnOnly || this.chart.RowColumnTotals === Enums.RowColumnTotals.RowAndColumn);
  }

  public get showColumnCountTotalRow() {
    return this.calculatedTotals && !this.isDetailNumeric && (this.chart.RowColumnTotals === Enums.RowColumnTotals.ColumnOnly || this.chart.RowColumnTotals === Enums.RowColumnTotals.RowAndColumn);
  }

  /** Calculate row, column and overall totals */
  public calculateTotals() {

    // Set early because we use this in getters.
    this.calculatedTotals = true;

    this.rowTotals = new Array<number>(this.rows.length);
    this.columnTotals = new Array<number>(this.columns.length);
    this.grandTotal = 0;

    // Don't bother to calculate if undefined or no totals specified
    if (this.chart.RowColumnTotals) {

      this.columns.forEach((columnModel, x) => {

        const xKey = this._valueKey(columnModel.value, columnModel.type);
        let total = 0;
        this.rows.forEach(row => {
          const yKey = this._valueKey(row.value, row.type);
          const key = `${xKey}\t${yKey}`;
          const cell = this.cells.get(key);
          if (cell) {
            // Show count if configured, or if non-numeric (so we have no choice)
            if (this.showCountInCells || !this.isDetailNumeric) {
              total += cell.count;
              this.grandTotal += cell.count;
            } else {
              total += cell.total;
              this.grandTotal += cell.total;
            }
          }
        });

        if (this.showColumnTotalRow || this.showColumnCountTotalRow) {
          this.columnTotals[x] = total;
        }

      });

      this.rows.forEach((row, y) => {
        const yKey = this._valueKey(row.value, row.type);
        let total = 0;
        this.columns.forEach((columnModel) => {
          const xKey = this._valueKey(columnModel.value, columnModel.type);
          const key = `${xKey}\t${yKey}`;
          const cell = this.cells.get(key);
          if (cell) {
            // Show count if configured, or if non-numeric (so we have no choice)
            if (this.showCountInCells || !this.isDetailNumeric) {
              total += cell.count;
            } else {
              total += cell.total;
            }
          }
        });

        if (this.showRowTotalColumn || this.showRowCountTotalColumn) {
          this.rowTotals[y] = total;
        }
      });
    }
  }

  protected _valueKey(value: any, fieldType: Enums.FieldType): any {

    if (value === null || value === undefined) {
      return null;
    }

    switch (fieldType) {
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
        return value.$date ? value.$date : value;
      case Enums.FieldType.Money:
        return extractDecimal(value);
      default:
        return value;
    }
  }

  private _sort(values: Array<AxisModel>, field: Field) {
    switch (field.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Selection:   // We get the selection strings
        return values.sort((row1, row2) => this.sortStrings(row1 && row1.value, row2 && row2.value));
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
        return values.sort((row1, row2) => this.sortDates(row1 && row1.value, row2 && row2.value));
      case Enums.FieldType.Money:
        return values.sort((row1, row2) => (row1 && extractDecimal(row1.value)) - (row2 && extractDecimal(row2.value)));
      default:
        return values.sort((row1, row2) => (row1 && row1.value) - (row2 && row2.value));
    }
  }

  private sortStrings(str1, str2): number {
    if (this.noValue(str1) && this.noValue(str2)) {
      return 0;
    }

    if (this.noValue(str1)) {
      return -1;
    }

    if (this.noValue(str2)) {
      return 1;
    }

    return stringCompare(str1, str2);
  }

  private sortDates(d1, d2) {

    if (this.noValue(d1) && this.noValue(d2)) {
      return 0;
    }

    if (this.noValue(d1)) {
      return -1;
    }

    if (this.noValue(d2)) {
      return 1;
    }

    return d1.$date - d2.$date;
  }

  private noValue(val: any): boolean {
    return val === null || val === undefined;
  }

}
