import { stringCompare } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty, SetModelProperty } from '@softools/vertex';
import { Application } from 'app/types/application';
import { combineLatest } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { GlobalModel } from '../global.model';
import { SiteModel } from '../common/site.model';

export class LaunchpadModel extends Model<LaunchpadModel> {

  public readonly show = new BooleanModelProperty(this, false).withLogging('SHOW');

  public readonly availableApps = new ArrayModelProperty<Application>(this).withLogging('AVAIL APPS', false);

  public readonly apps = new ArrayModelProperty<Application>(this).withLogging('APPS', false);

  public readonly categories = new ArrayModelProperty<string>(this).withLogging('CATEGORIES');

  public readonly checkedCategories = new SetModelProperty<string>(this).withLogging('CHECKED CATS');

  public readonly showSettings = new BooleanModelProperty(this, false).withLogging('SHOW SETTINGS');

  public readonly search = new ModelProperty<string>(this).withLogging('SEARCH');

  private readonly uncategorisedApps = $localize`Uncategorised Apps`;

  public constructor(private globalModel: GlobalModel, private siteModel: SiteModel) {
    super(globalModel);

    // Lazy initialise on first show
    // As well as not wasting time if launchpad is closed, the component is currently
    // hosted by the app component and so will be created before guards run
    this.subscribe(this.show.$.pipe(
      filter(show => !!show),
      first()
    ), async () => {
      await this.initialise();
    });
  }

  public async initialise() {
    this.observeProperties();
  }

  protected observeProperties() {

    this.subscribe(combineLatest([this.availableApps.$, this.showSettings.$]), ([availableApps, showSettings]) => {
      this.createCategories(availableApps, showSettings);
    });

    // Rebuild available app list when when state changes
    const changes = combineLatest([
      this.availableApps.$,
      this.checkedCategories.$,
      this.showSettings.$,
      this.search.$.pipe(map(s => s?.toLowerCase()))
    ]);

    this.subscribe(changes, ([availableApps, categories, showSettings, search]) => {
      this.createAppList(availableApps, categories, showSettings, search);
    });

    // Rebuild master app list when when state changes
    this.subscribe(this.siteModel.apps.$, (apps) => {
      if (apps) {
        const user = this.globalModel.getUser();
        const arr = Array.from(apps.values())
          .filter((app) => !app.HiddenForSpringboard)
          .filter((app) => app.isAppVisibleToUser(user))
          .sort((app1, app2) => {
            return stringCompare(app1.Taxonomy, app2.Taxonomy) || stringCompare(app1.Title, app2.Title);
          });

        this.setAppsTaxomonyColors(arr);

        this.availableApps.value = arr;
      }
    });
  }

  private createAppList(availableApps: Array<Application>, categories: Set<string>, showSettings: boolean, search: string) {
    this.apps.value = availableApps
      .filter((app) => showSettings === app.IsSettingsApp)
      .filter((app) => {
        return !search ||
          (app.Title.toLowerCase().indexOf(search) >= 0) ||
          (app.Taxonomy?.toLowerCase().indexOf(search) >= 0);
      })
      .filter((app) => {
        if (categories?.size > 0) {
          if (app.Taxonomy) {
            return categories.has(app.Taxonomy);
          } else {
            return categories.has(this.uncategorisedApps);
          }
        } else {
          return true;
        }
      });
  }

  private createCategories(availableApps: Array<Application>, showSettings: boolean) {
    const categories = new Set<string>();
    availableApps.filter(app => app.IsSettingsApp === showSettings)
      .forEach(app => categories.add(app.Taxonomy || this.uncategorisedApps));

    this.categories.value = Array.from(categories.values()).sort((cat1, cat2) => {
      return stringCompare(cat1, cat2);
    });

    this.checkedCategories.clear();
  }

  /**
   * Random set the colors for the Taxomony
   * @param apps
   */
  private setAppsTaxomonyColors(apps: Array<Application>) {
    const usedColors = [];
    const colors = [
      '#E1484C', '#8FC241', '#21588E', '#FFA26B', '#993483', '#E79292', '#359E51', '#706FA2', '#53A6BE', '#65A085', '#6C55AD', '#316A85',
      '#E1484C', '#8FC241', '#21588E', '#FFA26B', '#993483', '#E79292', '#359E51', '#706FA2', '#53A6BE', '#65A085', '#6C55AD', '#316A85',
      '#E1484C', '#8FC241', '#21588E', '#FFA26B', '#993483', '#E79292', '#359E51', '#706FA2', '#53A6BE', '#65A085', '#6C55AD', '#316A85',
      '#E1484C', '#8FC241', '#21588E', '#FFA26B', '#993483', '#E79292', '#359E51', '#706FA2', '#53A6BE', '#65A085', '#6C55AD', '#316A85',
    ];
    for (let i = 0; i < apps.length; i++) {
      if (usedColors[apps[i].Taxonomy] !== undefined) {
        apps[i].TaxonomyColor = usedColors[apps[i].Taxonomy];
      } else {
        apps[i].TaxonomyColor = colors.pop();
        usedColors[apps[i].Taxonomy] = apps[i].TaxonomyColor;
      }
    }
  }
}
