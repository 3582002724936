import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UsersRepository, UserInvitationInfo, Invitation, InviteResponse } from '../repos';
import { User, PendingUser } from '../types';
import { lastValueFrom, Observable } from 'rxjs';
import { OnlineStatusService } from './online-status-service';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { DatabaseContextService, USERS_STORE, TEAM_USERS_STORE, PENDING_USER_STORE } from '../indexedDb';

import * as moment_ from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { logError, LogLevel } from '../utils/log-error';
import { PendingUsersRepository } from '../repos/pending-users.repository';
const moment = moment_;

export interface IUserRefreshChanges {
  users: Array<User>;
  pendingUsers: Array<PendingUser>;
}

/**
 * Access to user and user by team data held in local storage
 *
 * The stored values are held under two keys, 'Users' & 'UsersForMyTeams'; each holds
 * an array of appropriate users.
 */
@Injectable({ providedIn: 'root' })
export class UserStorageService {
  private UsersUpdatedHorizonKey = 'Users-Updated-Horizon';

  constructor(
    public usersRepository: UsersRepository,
    public pendingUsersRepository: PendingUsersRepository,
    private service: DatabaseContextService<User>,
    private pendingUserService: DatabaseContextService<PendingUser>,
    private onlineStatus: OnlineStatusService
  ) { }

  /** Get the set of users currently in storage */
  public async getUsers(): Promise<Array<User>> {
    return this.service.getAll(USERS_STORE);
  }

  /** Get the set of users for my teams currently in storage */
  public async getUsersForMyTeams(): Promise<Array<User>> {
    return this.service.getAll(TEAM_USERS_STORE);
  }

  public async getPendingUsers(): Promise<Array<PendingUser>> {
    return this.pendingUserService.getAll(PENDING_USER_STORE);
  }

  /**
   * Synchronise users from server into local storage.
   * @param retryPolicy     Optional policy for retrying when connections are poor.
   */
  public async syncUsersAsync(retryPolicy: IRetryPolicy = null): Promise<Array<User>> {
    const now = moment.utc().toISOString();
    const policy = retryPolicy || NoRetryPolicy.instance;
    const users = await policy.execute(() => this.usersRepository.getAllUsersAsync());
    await this.saveUsers(users);
    localStorage.setItem(this.UsersUpdatedHorizonKey, now);
    return users;
  }

  /**
   * Synchronise users that are members of current user's teams server into local storage.
   * @param retryPolicy     Optional policy for retrying when connections are poor.
   */
  public async syncUsersForMyTeamsAsync(retryPolicy: IRetryPolicy = null): Promise<Array<User>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const users = await policy.execute(() => lastValueFrom<Array<User>>(this.usersRepository.getForMyTeams()));
    return this.saveUsersForMyTeam(users);
  }

  /**
   * Synchronise pending users from server into local storage.
   * @param retryPolicy     Optional policy for retrying when connections are poor.
   */
  public async syncPendingUsersAsync(retryPolicy: IRetryPolicy = null): Promise<Array<PendingUser>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const users = await policy.execute(() => lastValueFrom<Array<PendingUser>>(this.pendingUsersRepository.getAllPendingUsersAsync()));
    return this.savePendingUsers(users);
  }

  /** Syncs user data with repository; the name is incorrect (todo) */
  public getSorted(): Observable<Array<User>> {
    return this.usersRepository.getSorted().pipe(
      tap((users: Array<User>) => {
        users.forEach((user) => {
          this.replaceUser(user).catch(error => logError(error, 'Failed to replace user'));
        });
      })
    );
  }

  /** Syncs user/team data with repository; the name is incorrect (todo) */
  public getForMyTeams(): Observable<Array<User>> {
    return this.usersRepository.getForMyTeams().pipe(
      tap((users: Array<User>) => {
        users.forEach((user) => {
          this.saveUserForMyTeam(user).catch(error => logError(error, 'Failed to save users for my team'));
        });
      })
    );
  }

  /** Get a single user by identifier
   * @param user id
   */
  public async getUser(id: string, pending = false): Promise<User> {
    if (!pending) {
      return this.service.get(USERS_STORE, id);
    } else {
      return this.service.get(PENDING_USER_STORE, id);
    }
  }

  public async saveUsers(users: Array<User>) {
    await this.service.putData(USERS_STORE, users, 'Id');
    return users;
  }

  public async saveUsersForMyTeam(users: Array<User>) {
    await this.service.putData(TEAM_USERS_STORE, users, 'Id');
    return users;
  }

  public async savePendingUsers(users: Array<PendingUser>) {
    await this.pendingUserService.putData(PENDING_USER_STORE, users, 'Id');
    return users;
  }

  public async replaceUser(user: User) {
    await this.service.save(USERS_STORE, user.Id, user);
  }

  public async saveUserForMyTeam(user: User) {
    await this.service.save(TEAM_USERS_STORE, user.Id, user);
  }

  /** Reload users and store  */
  public async refreshUsersAsync(): Promise<Array<User>> {
    const users = await lastValueFrom<Array<User>>(this.usersRepository.getSorted());
    return this.saveUsers(users);
  }

  /**
   * Update users in storage that have changed since we last updated.
   *
   * This can be used to periodically check for changes made by other users and freshen the local store
   *
   */
  public async refreshStorageAsync(): Promise<IUserRefreshChanges> {
    try {
      // Check browser online status because we're checking server connectivity
      // Do not use isConnected beacuse isServerReachable is set via the error below
      if (!this.onlineStatus.isOnline) {
        return { users: [], pendingUsers: [] };
      }

      // Get horizon time (when we last saw something).  Defaults to start of epoch (may want to change this...)
      // !!!! we're stepping back 1000 sec in time to ensure updates come over even if the client and server
      // clocks are out of sync.  This causes more updates than we need so we have to filter changes out. Get server clock to resolve this
      const lookBackSeconds = 1000; // 16 minutes?
      const horizon = moment.utc((await localStorage.getItem(this.UsersUpdatedHorizonKey)) || lookBackSeconds);
      const when = horizon.unix() - lookBackSeconds;
      const changedUsers = await this.usersRepository.usersChangedSince(when);
      const changedPendingUsers = await this.pendingUsersRepository.pendingUsersChangedSince(when);

      // We were able to get data from the server so connectivity is good
      this.onlineStatus.isServerReachable = true;

      const actualChangedUsers: Array<User> = [];
      const actualChangedPendingUsers: Array<PendingUser> = [];

      if (changedUsers?.length > 0) {
        // We got some users so update the horizon to the time we used
        localStorage.setItem(this.UsersUpdatedHorizonKey, moment.utc().toISOString());

        for (let i = 0, length = changedUsers.length; i < length; ++i) {
          const user = changedUsers[i];
          const existing = await this.getUser(user.Id);
          if (existing) {
            const incomingDate = user.LastUpdated && user.LastUpdated.length > 0 ? new Date(user.LastUpdated) : null;
            const existingDate = existing.LastUpdated && existing.LastUpdated.length > 0 ? new Date(existing.LastUpdated) : null;
            if (!incomingDate || !existingDate || incomingDate > existingDate) {
              // Incoming is newer so update storage
              await this.replaceUser(user);
              actualChangedUsers.push(user);
            }
          } else {
            // new user so add to storage
            await this.replaceUser(user);
            await this.removeUsersAsync(user.Id, true);
            actualChangedUsers.push(user);
          }
        }
      }


      if (changedPendingUsers?.length > 0) {
        // We got some users so update the horizon to the time we used
        localStorage.setItem(this.UsersUpdatedHorizonKey, moment.utc().toISOString());

        for (let i = 0, length = changedPendingUsers.length; i < length; ++i) {
          const user = changedPendingUsers[i];
          const existingUser = await this.getUser(user.Id.toString());
          const existingPending = await this.getUser(user.Id.toString(), true);
          if (!existingUser && !existingPending) {
            // new user so add to storage
            await this.savePendingUsers([user]);
            actualChangedPendingUsers.push(user);
          }
        }
      }

      return { users: actualChangedUsers, pendingUsers: actualChangedPendingUsers };
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        // HTTP error can be a server error or network error
        // As we are polling most errors are not fatal
        switch (error.status) {
          case 401: // not authorised, probably logged out recently
            logError(error, 'refreshing users (noauth)', LogLevel.error);
            return { users: [], pendingUsers: [] };
          case 500: // server error.  treat as non fatal as stack too keen on faking one
          case 502: // gateway error should be recoverable
          case 504: // gateway timeout should be recoverable
          case 429: // too many requests
            logError(error, 'refreshing users', LogLevel.warning);
            return { users: [], pendingUsers: [] };
          case 0: // 0 is commonly reported when offline
            logError(error, 'refreshing users - offline', LogLevel.message);
            this.onlineStatus.isServerReachable = false; // Failed to connect (todo more accurate)
            return { users: [], pendingUsers: [] };
          default:
            // fall back to old behaviour; we may want to refine this further
            this.onlineStatus.isServerReachable = false;
            break;
        }
      }

      logError(error, 'refreshing users', LogLevel.error);
      return { users: [], pendingUsers: [] };
    }
  }

  /**
   * Remove one or more users from storage.
   * @param ids single user id or array of user ids to remove
   */
  public async removeUsersAsync(ids: string | Array<string>, pending = false) {
    // Convert single id to arrary
    const idArray: Array<string> = Array.isArray(ids) ? ids : [ids];

    idArray.forEach((id) => {
      if (!pending) {
        this.service.delete(USERS_STORE, id)
          .then(_ => this.service.delete(TEAM_USERS_STORE, id))
          .catch(error => logError(error, 'Failed to delete users and team'));
      } else {
        this.service.delete(PENDING_USER_STORE, id)
          .catch(error => logError(error, 'Failed to delete user'));
      }
    });
  }

  /**
   * Deactivate one or more users by id.  This soft deletes the user
   * and performs other associated workflow e.g. sending a confirmatory email.
   * @param ids user id or array of user ids
   */
  public async deactivateUsersAsync(ids: string | Array<string>) {
    // Remove deactivated users from storage so we see change on refresh
    // Deactivate in remote repo
    if (Array.isArray(ids)) {
      await Promise.all([this.removeUsersAsync(ids), this.usersRepository.deactivateUsersAsync(ids)]);
    } else {
      await Promise.all([this.removeUsersAsync(ids), this.usersRepository.deactivateUserAsync(ids)]);
    }
  }

  public async inviteAsync(invitation: Invitation): Promise<InviteResponse> {
    return this.usersRepository.inviteAsync(invitation);
  }

  public async confirmEmail(key: string): Promise<void> {
    return this.usersRepository.confirmEmail(key);
  }

  public async getInvitation(key: string): Promise<UserInvitationInfo> {
    return this.usersRepository.getInvitation(key);
  }

  public async verifyUser(response: UserInvitationInfo): Promise<void> {
    return this.usersRepository.verifyUser(response);
  }

  public async clear() {
    await this.service.clear(USERS_STORE);
    await this.service.clear(PENDING_USER_STORE);
    await this.service.clear(TEAM_USERS_STORE);
  }
}
