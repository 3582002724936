import { ReportField, Enums, OdataExpressionType } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';

/**
 * Description of an application field and the filtering applied to it by the current filter
 */
export class FieldFilters {

  constructor(
    public field: ReportField,
    public appField: AppField,
    public fieldType: Enums.FieldType,
    public value: any,
    public operator: OdataExpressionType,
  ) {
    // Make sure the fields belong together
    if (field && field.FieldIdentifier !== appField?.Identifier) {
      throw new Error('Application and report field mismatch');
    }
  }

  /** Get the field identifier */
  public get identifier(): string { return this.appField.Identifier; }

  public get type(): Enums.FieldType { return this.appField.Type; }
}
