import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { OverlayText } from '@softools/softools-core';
import { OVERLAY_TEXT_DATA } from 'app/_constants/constants.injection-tokens';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-overlay-text',
  templateUrl: './overlay-text.component.html',
  styleUrls: ['./overlay-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayTextComponent {

  public closed$ = new BehaviorSubject(false);

  constructor(
    @Inject(OVERLAY_TEXT_DATA) public text: OverlayText) { }

  public onCloseFullImageClickHandler($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.closed$.next(true);
  }
}
