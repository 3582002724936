import {
  Record,
  AppDataStorageService,
  IndexedAppData,
  App,
  RecordId,
  AttachmentService,
  FileAttachment,
  tryGetCurrentUser,
  AppDataMetricsStorageService,
  OnlineStatusService,
  QueryParams,
  logMessage,
  LogLevel,
  logError,
  getCurrentUser,
  QueryParameters,
  IRecordsResponse
} from '@softools/softools-core';
import { Application, ToolbarContext, GetRecordOptions, DeleteRecordOptions, IGetIndexOptions } from './application';
import { RecordSelection } from 'app/types/record-selection';
import { InjectService, LocatorService } from 'app/services/locator.service';
import { IRecordPersitor } from 'app/services/record/record-persitor.interface';
import ObjectID from 'bson-objectid';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { StorageMode } from './enums';
import { RecordPatch } from 'app/workspace.module/types';
import { getAppTweaks } from './app-tweaks';
import { StorageModeService } from 'app/services/storage-mode.service';
import { RecordModel, ReportModel } from 'app/mvc';
import { IUpgradeData, UpgradeDataStorageService } from 'app/services/upgrade-data-storage.service';
import { DataSyncService } from 'app/workspace.module/services/data-sync.service';
import { DataIndexService2 as DataIndexService } from 'app/services/indexes/data-index-service';
import { StandardAppMenus } from './application/standard-app-menus';
import { IPerformContext } from './fields/app-field';
import { lastValueFrom } from 'rxjs';
import { OnlineDataIndex } from 'app/services/indexes/online-data-index';
import { ReportFilter } from 'app/filters/types';
import { FilteredIndex } from 'app/services/indexes/filtered-index';

/**
 * A standard Softools zero-code application
 */
export class StandardApplication extends Application {

  @InjectService(StorageModeService)
  private readonly storageModeService: StorageModeService;

  @InjectService(AppDataStorageService)
  private readonly appDataService: AppDataStorageService;

  @InjectService(UpgradeDataStorageService)
  private readonly upgradeDataStorageService: UpgradeDataStorageService;

  @InjectService(DataSyncService)
  private readonly dataSyncService: DataSyncService;

  @InjectService(DataIndexService)
  private readonly dataIndexService: DataIndexService;


  /** Upgrade configs by version */
  private upgrades = new Map<number, IUpgradeData>();

  constructor(private recordPersistService: IRecordPersitor, app?: App) {
    super(app);

    this.setStorageMode();
  }

  public toRecord(value: any): Record {
    return value;
  }

  public override async eachRecord(callback: (record: Record) => any, options?: GetRecordOptions): Promise<void> {

    // If apply patch specified modify callback
    const handler = options?.applyPatch
      ? (rec) => {
        const patch = this.recordPersistService.getPatch(rec._id);
        if (patch) {
          patch.updateRecord(rec, this);
        }
        callback(rec);
      }
      : callback;

    const promise = options?.hierarchy
      ? this.appDataService.eachChildRecord(this.Identifier, options.hierarchy, handler)
      : this.appDataService.eachRecord(this.Identifier, handler);

    return await promise;
  }

  public override async nativeGetAll(hierarchy?: string): Promise<Array<Record>> {
    return this.appDataService.nativeGetAll(this.Identifier, hierarchy);
  }

  /**
   * Get a range of records using the supplied index
   * @param index
   * @param start     First record number (0 based)
   * @param count     Max number of records to return
   */
  public override async getIndexedRecordRange(index: IndexedAppData, start: number, count: number): Promise<Array<Record>> {
    if (index) {
      const records = await index.getRecords(start, count);

      for (let i = 0; i < records.length; i++) {
        const record = records[i];
        await this.upgradeRecord(record);
      }

      return records;
    }

    // No index (yet) return null to indicate no records to process
    return null;
  }

  /**
   * Get a range of records from the server
   * @param selection   Record selection parameters
   * @param options     Controls optional behaviour
   */
  public override async getApiRecordRange(selection: RecordSelection, options?: GetRecordOptions): Promise<Array<Record>> {

    const queryParams: QueryParams = selection.filter ?
      { ...selection.filter.getQueryParameters({ showArchived: selection.showArchived }) } : {};
    queryParams.$skip = selection.start;
    queryParams.$top = selection.count;

    const hierarchy = selection.hierarchy || options?.hierarchy;
    const records = await this.appDataService.getApiReportDataAsync(
      this,
      selection.report?.Id || 0,
      queryParams,
      hierarchy,
      selection.showArchived
    );

    if (records) {
      const problem = records.findIndex((r) => !r);
      if (problem >= 0) {
        logMessage(`getApiRecordRange record ${problem} of ${records.length} undefined`, LogLevel.error);
      }

      // Look up and apply patches if requested
      if (options?.applyPatch) {
        for (let i = 0; i < records.length; ++i) {
          const record = records[i];
          const pendingPatch = this.recordPersistService.getPatch(record._id);
          if (pendingPatch) {
            pendingPatch.updateRecord(record, this);
          }
        }
      }

      // The report data API doesn't return hierarchy so patch that in
      if (selection.hierarchy) {
        records.forEach(record => {
          record.Hierarchy = selection.hierarchy;
        });
      }
    }

    return records;
  }

  /**
   * Get a range of records.
   * This is a general function that we should extend to replace the other record query functions.
   * Currently it is only implemented by standard application and does not work for all parameter values.
   * @param selection Selects the records to be returned
   * @param options   Controls how the records are obtained
   * @returns         A Promise with the returned records; an empty array if no matches (or not implemented)
   */
  public override async getRecordsAsync(selection: RecordSelection, options?: GetRecordOptions): Promise<Array<Record>> {

    let records: Array<Record>;

    // Go straight to remote API if requested
    if (options?.forceRemote) {
      // todo
    }

    if (options?.forceStorage) {
      records = await this.getLocalRecordsAsync(selection, options);
    }

    // Try local
    if (this.storageMode === StorageMode.Offline || this.storageMode === StorageMode.Selective) {
      records = await this.getLocalRecordsAsync(selection, options);
    }

    // Try remote
    if (records !== undefined &&
      (this.storageMode === StorageMode.Online || this.storageMode === StorageMode.Selective || options?.fallbackRemote)) {
      // records = // todo
    }

    return records;
  }

  public async getLocalRecordsAsync(selection: RecordSelection, options?: GetRecordOptions) {
    // Get an index
    const filter = selection.filter?.QueryParameters || {};
    const index = await this.dataIndexService.getIndex(this, filter, null, options?.hierarchy);
    await index.indexAll();

    if (options?.applyPatch) {
      return await index.getRecordsWithGetterFunc(selection.start, selection.count, this.recordPersistService.getModifiedRecord);
    } else {
      return await this.getIndexedRecordRange(index, selection.start, selection.count);
    }
  }



  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    const queryParams = selection.filter.getQueryParameters();
    delete queryParams.$top;
    delete queryParams.$skip;
    const record = await this.appDataService.getApiRecordCursorAsync(
      this,
      selection.report?.Id || 0,
      cursor,
      queryParams,
      selection.showArchived,
      selection.hierarchy
    );
    return record;
  }

  public override async getRecordsByIdAsync(ids: Array<RecordId>, _options?: GetRecordOptions)
    : Promise<Array<IRecordsResponse>> {
    const response = await this.appDataService.getRecordsByIdAsync(this, ids);
    return response;
  }

  public override async getApiGroupInformation(groupBy: string, descending: boolean, start: number, count: number, archived: boolean, hierarchy: string, filter?: string) {
    return this.appDataService.getApiGroupInformation(this, groupBy, descending, start, count, archived, hierarchy, filter);
  }

  public override async getApiGroupCount(groupBy: string, hierarchy: string, filter?: string): Promise<{ count: number }> {
    return this.appDataService.getGroupCount(this, groupBy, hierarchy, filter);
  }

  /** Delete a range of record by id, we dont need to . */
  public override async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {

    // Delete from remote repository
    await this.appDataService.deleteRecordsAsync(
      this,
      deleteOptions.selection,
      deleteOptions.reportId || 0,
      deleteOptions.queryParams
    );

    // Delete from local storage
    const ids = deleteOptions.selection.SelectedRecordIds;
    for (let index = 0; index < ids.length; index++) {
      const element = ids[index];
      await this.appDataService.delete(element);
    }

    // Notify all as deleted (this will include any failures - can improve)
    ids.forEach(deletedId => {
      this.recordDeleted$.next({ app: this, recordIdentifier: deletedId });
    });

    return true;
  }

  /**
   * Get record count for a view directly from server
   * @param selection
   */
  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    const queryParams = selection.queryParamsForCount();
    const hierarchy = selection.hierarchy;
    delete queryParams.hierarchy;
    const count = await this.appDataService.getApiCountAsync(this, selection.report?.Id.toString() || ' ', queryParams, hierarchy);
    return count;
  }

  /**
   *
   * @param selection
   */
  public override async getViewRecordCountAsync(selection: RecordSelection): Promise<number> {
    if (this.storageMode === StorageMode.Offline) {
      if (selection.hierarchy) {
        return await this.appDataService.childCountAsync(this.Identifier, selection.hierarchy);
      } else {
        return await this.appDataService.countAsync(this.Identifier);
      }
    } else {
      // Could handle selective locally
      return this.getApiViewRecordCount(selection);
    }
  }

  public override async getRecordByIdAsync(id: RecordId, options?: GetRecordOptions): Promise<Record> {

    // Go straight to remote API if requested
    if (options?.forceRemote) {
      return await this.appDataService.getApiRecordByIdAsync(this, id);
    }

    if (options?.forceStorage) {
      const rec = await this.appDataService.getRecordByIdAsync(id);
      await this.upgradeRecord(rec);
      return rec;
    }

    // Look up any pending patch if we're going to need it
    const pendingPatch = (options?.applyPatch || options?.patchOnly) && this.recordPersistService.getPatch(id);

    // If patch is new, it's not been persisted so convert to record and return
    if (pendingPatch?._new || options?.patchOnly) {
      return pendingPatch?.toRecord(this);
    }

    let record: Record;

    // Try local
    if (this.storageMode === StorageMode.Offline || this.storageMode === StorageMode.Selective) {
      record = await this.appDataService.getRecordByIdAsync(id);
    }

    // Try remote
    if (!record &&
      (this.storageMode === StorageMode.Online || this.storageMode === StorageMode.Selective || options?.fallbackRemote)) {
      try {
        record = await this.appDataService.getApiRecordByIdAsync(this, id);
      } catch (error) {
        logError(error, 'Record API', LogLevel.warning);
        return null;
      }
    }

    if (record && pendingPatch) {
      pendingPatch.updateRecord(record, this);
    } else if (!record && pendingPatch) {
      // Patch but no record, fall back to compare.  Is this reachable?
      record = pendingPatch.toRecord(this);
    }

    await this.upgradeRecord(record);
    return record;
  }

  public override loadAttachmentssAsync(id: RecordId): Promise<Array<FileAttachment>> {
    const service = LocatorService.get(AttachmentService);
    return lastValueFrom<Array<FileAttachment>>(service.getAttachments(this.Identifier, id));
  }

  public override async totalCount(selection?: RecordSelection): Promise<number> {
    const isArchived = selection?.showArchived || false;

    if (this.isOfflineApp && !isArchived) {
      if (selection?.hierarchy) {
        return this.appDataService.childCountAsync(this.Identifier, selection.hierarchy);
      } else {
        return this.appDataService.countAsync(this.Identifier);
      }
    } else {
      const onlineService = LocatorService.get(OnlineStatusService);
      const appDataMetricsStorageService = LocatorService.get(AppDataMetricsStorageService);

      if (onlineService.isConnected) {
        try {
          // const count = await service.getApiCountAsync(this, report.Identifier, queryParams);
          // todo hierarchy for child apps - total should honour
          let count;
          if (selection?.hierarchy) {
            count = await this.appDataService.getApiTotalCountAsync(this, isArchived, selection?.hierarchy);
          } else {
            count = await this.appDataService.getApiTotalCountAsync(this, isArchived);
          }
          await appDataMetricsStorageService.setCountAsync(this.Identifier, count);
          return count;
        } catch (error) {
          console.warn(error);
        }
      }

      // Not online or error so return cached value if available
      return appDataMetricsStorageService.getCountAsync(this.Identifier);
    }
  }

  public override validateRecordId(recordId: RecordId): RecordId | boolean {
    if (!recordId || !ObjectID.isValid(recordId)) {
      const newObjectId = new ObjectID();
      recordId = newObjectId.toHexString();
    }
    return recordId;
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {
    const changes = recordPatch.getDelta();

    // We have some corrupt patches, see e.g. V18-6JG
    // These have managed to get a structure like { val: val } - pointing to the same record
    // Detect these and remove the changes which are meaningless and throw a JSON error
    // keeping the patch stuck  in the queue.
    // This can be removed when we are no longer seeing errors
    Object.getOwnPropertyNames(changes).forEach(name => {
      const change = changes[name];
      if (change?.val && change.val.val) {
        // Log as error so we can track it
        logMessage(`Correcting invalid patch ${this.Identifier} ${name} ${recordPatch._id}`);
        // Don't need to check for cicularity - this structure is always invalid
        // Patch a null instead
        changes[name] = null;
      }
    });

    if (recordPatch._new) {
      return this.appDataService.postApiRecordAsync(this, recordPatch._id, changes, recordPatch.Hierarchy);
    } else {
      return this.appDataService.patchApiRecordAsync(this, recordPatch._id, changes, recordPatch.Hierarchy);
    }
  }

  public override async storeAsync(record: Record): Promise<Record> {
    return await this.appDataService.storeRecordAsync(this, record, false);
  }

  /** Get toolbar actions for reports */
  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    const performContext: IPerformContext = {
      value: null,
      record: null,
      reportModel,
      generalController: context.reportController,
      appModel: reportModel.appModel
    };

    this.addMenuItems(StandardAppMenus.Menu, actions, reportModel.report.value.Type, performContext);
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    const performContext: IPerformContext = {
      value: null,
      record: context.record,
      recordModel,
      appModel: recordModel.appModel,
      recordUpdateController: context.recordUpdateController,
      generalController: context.generalController
    };

    this.addMenuItems(StandardAppMenus.Menu, actions, 'record', performContext);
  }

  public override addToolbarActions(actions: Array<ToolbarAction>, scope: string | number, context: IPerformContext) {
    super.addToolbarActions(actions, scope, context);
    this.addMenuItems(StandardAppMenus.Menu, actions, scope, context);
  }

  public override get isOfflineApp(): boolean {
    // treat selective as online for now
    return this.storageMode === StorageMode.Offline;
  }

  public override isOfflineReport(reportIdentifier: string): boolean {
    switch (this.storageMode) {
      case StorageMode.Online:
        return false;
      case StorageMode.Offline:
        return true;
      case StorageMode.Selective: {
        return this.storageModeService.getReportAvailableOffline(this.Identifier, reportIdentifier);
      }
      default:
        return this.isOfflineApp;
    }
  }

  public setStorageMode() {
    const mode = this.storageModeService.getAppStorageMode(this.Identifier);

    switch (mode) {
      case StorageMode.Online:
      case StorageMode.Offline:
      case StorageMode.Selective: {
        // Explicit override takes priority
        this.storageMode = mode;
        break;
      }
      default: {
        // Check app tweaks for a hardcoded override
        const user = tryGetCurrentUser();
        if (user) {
          this.storageMode = this.defaultStorageMode(user.Tenant);
        }

        break;
      }
    }
  }

  public override defaultStorageMode(tenant: string) {
    const tweak = getAppTweaks(tenant, this.Identifier);
    let defaultValue = tweak?.storageMode != null ? tweak.storageMode : undefined;
    const appAvailability = this.OfflineAvailability?.AvailableOffline;
    if (defaultValue == null) {
      if (appAvailability != null) {
        defaultValue = appAvailability ? StorageMode.Offline : StorageMode.Online;
      } else {
        defaultValue = StorageMode.Offline;
      }
    }
    return defaultValue;
  }

  /** Store upgrade information when app config changes */
  public override async storeUpgradeInformation(newAppConfig: App) {
    const upgradeData: IUpgradeData = {
      appIdentifier: this.Identifier,
      version: this.AppVersionNumber,
      fields: []
    };

    let changes = false;
    this.AppFields.forEach(field => {
      changes ||= field.getUpgradeInformation(newAppConfig, upgradeData);
    });

    if (changes) {
      await this.upgradeDataStorageService.saveUpgradeAsync(upgradeData);
    }
  }

  /** Synchronize app data from the server to local storage */
  public override async synchronizeAppData() {
    if (this.storageMode === StorageMode.Offline || this.storageMode === StorageMode.Selective) {
      const user = getCurrentUser();
      await this.dataSyncService.syncAppData(this, user);
    }
  }

  /** Upgrade record data to current version if needed */
  private async upgradeRecord(record: Record): Promise<Record> {
    if (record) {
      const recordVersion = record._appVersion || 0;
      if (this.AppVersionNumber > recordVersion) {
        // record created by an older vesion, may need upgrade
        const upgrade = await this.getUpgradeFromVesion(recordVersion);
        if (upgrade) {
          upgrade.fields.forEach(field => {
            const appField = this.getField(field.Identifier);
            if (appField) {
              appField.upgradeRecord(record, upgrade);
            } else {
              // field not found - we could delete from the record here
            }
          });

          // Update record version number and write back to storage
          record._appVersion = this.AppVersionNumber;
          await this.appDataService.storeRecordAsync(this, record, false);
        }
      }
    }

    return record;
  }

  private async getUpgradeFromVesion(version: number) {
    if (this.upgrades.has(version)) {
      // Get cached upgrade - may be null if we don't have one
      return this.upgrades.get(version);
    } else {
      // Get upgrade from storage (if any) and store in cache
      const upgrade = await this.upgradeDataStorageService.getUpgradeAsync(this.Identifier, version);
      this.upgrades.set(version, upgrade);
      return upgrade;
    }
  }

  public override async getIndex(reportIdentifier: string, options: IGetIndexOptions): Promise<IndexedAppData> {
    // It would be better to have concrete online and offline implementations
    // but this is complicated by selective mode. If we can get rid of that we
    // can make this propertly OO
    if (this.isOfflineApp && !options.archived) {

      const query = options.filter ? options.filter.QueryParameters : new QueryParameters(null);

      const index = await this.dataIndexService.getIndex(this, query, null, options?.hierarchy);
      await index.indexAll();

      // If search in force, wrap in a filtered index
      if (options.searchTerm) {
        const searchIndex = new FilteredIndex(index, this.appDataService);
        await searchIndex.addKey('QuickFilterSearchText', options.searchTerm, false).createIndex();
        return searchIndex;
      }

      // No search, just use raw index
      return index;
    } else {
      const filters: Array<ReportFilter> = [];

      if (options.filter) {
        filters.push(options.filter);
      }

      if (options.searchTerm) {
        filters.push(this.createSearchFilter(options.searchTerm));
      }

      const query = filters.length > 0 ?
        ReportFilter.merge(filters).QueryParameters :
        new QueryParameters(null);

      const index = new OnlineDataIndex(this, reportIdentifier, query, options);
      await index.indexAll();
      return index;
    }
  }

  /**
   * Create a filter to perform a search.
   * Standard applications compare against a generated QuickFilterSearchText field
   * that concatenates searchable fields.
   * @param term    Search string
   * @returns       A configured filter or null to skip search
   */
  public override createSearchFilter(term: string): ReportFilter {
    const searchFilter = new ReportFilter();
    const santisedTerm = term.replace(/\'/g, '\'\'');
    searchFilter.Filter = `substringof('${santisedTerm?.toLowerCase()}',QuickFilterSearchText)`;
    return searchFilter;
  }
}
