import { NgModule } from '@angular/core';
import { CdkGlobalOverlayDirective } from './cdk-global-overlay.directive';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [CdkGlobalOverlayDirective],
  exports: [CdkGlobalOverlayDirective],
  imports: [
    OverlayModule,
  ]
})
export class GlobalOverlayModule {
}
