
<div class="d-flex">

  <app-input-icon icon="calendar" 
    [clickable]="!getIsDisabled()"
    [elementStyles]="elementStyles"
    (iconClicked)="openPicker($event)">

    <input #dateinput
      [matDatepicker]="datepicker"
      (keydown)="onKeyDown($event)"
      (keypress)="onKeyPress($event)"
      (keyup)="onKeyUpHandler($event, dateinput)"
      [readonly]="true"
      [ngModel]="dateTime"
      [ngClass]="{ 'inline': inline }"
      style="visibility: hidden; position: absolute;"
    />

    <input
      #input
      type="text"
      [readonly]="true"
      [id]="uiIdentifier"
      [ngModel]="displayDate"
      (click)="openPicker($event)"
      (keydown)="onKeyDown($event)"
      (keypress)="onKeyPress($event)"
      (keyup)="onKeyUpHandler($event, dateinput)"
      (focus)="hasFocus = true"
      (blur)="hasFocus = false"
      [class.inline-control]="inline"
      [class.readonly-normal-appearance]="!getIsDisabled()"
      class="pe-4 overflow-hidden form-control  form-control-o"
      [class.inline-control]="inline"
      [ngStyle]="textStyle"
      [disabled]="getIsDisabled()"
    />

    <mat-datepicker #datepicker

      (monthSelected)="monthSelected($event, datepicker)"
      (closed)="dateChanged()"
      startView="multi-year">
    </mat-datepicker>
  </app-input-icon>

  <fa-icon *ngIf="showClear && !getIsDisabled()"
    class="pointer mx-1 clear-button"
    [icon]="['fal', 'times-circle']"
    title="Clear Date"
    i18n-title
    [hidden]="required"
    (click)="clearDate()"
    [style.opacity]="hasFocus || true ? 1: 0">
  </fa-icon>

</div>
