import { ChangeDetectionStrategy, Component, ComponentRef } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { ButtonFieldComponent, ImageFieldComponent } from 'app/softoolsui.module';
import { FieldBase } from 'app/softoolsui.module/fields';
import { BitFieldComponent } from 'app/softoolsui.module/fields2/bit-field/bit-field.component';
import { EmailFieldComponent } from 'app/softoolsui.module/fields2/email-field/email-field.component';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { IconDisplayFieldComponent } from 'app/softoolsui.module/fields2/icon-display-field/icon-display-field.component';
import { ImageListFieldComponent } from 'app/softoolsui.module/fields2/image-list-field/image-list-field.component';
import { MultiStateField2Component } from 'app/softoolsui.module/fields2/multi-state-field/multi-state-field.component';
import { PersonFieldComponent } from 'app/softoolsui.module/fields2/person-field/person-field.component';
import { PersonTeamFieldComponent } from 'app/softoolsui.module/fields2/person-field/person-team-field.component';
import { RangeFieldComponent } from 'app/softoolsui.module/fields2/range-field/range-field.component';
import { TeamFieldComponent } from 'app/softoolsui.module/fields2/team-field/team-field.component';
import { TextDisplayFieldComponent } from 'app/softoolsui.module/fields2/text-display-field/text-display-field.component';
import { UrlFieldComponent } from 'app/softoolsui.module/fields2/url-field/url-field.component';

@Component({
  selector: 'app-card-app-field',
  templateUrl: '../../fields2/field/field.component.html',
  styleUrls: ['./card-app-field.component.scss', '../../fields2/field/field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAppFieldComponent extends FieldComponent {

  protected override addComponentForField(): ComponentRef<any> {

    if (this.fieldModel.DisplayOptions?.ShowAsIcon) {
      if (this.fieldModel.defaultIcon() !== '.generated') {
        return this.addReadonlyChildComponent(IconDisplayFieldComponent);
      }
    }

    switch (this.fieldModel.Type) {

      case Enums.FieldType.Bit:
        return this.addReadonlyChildComponent(BitFieldComponent);

      case Enums.FieldType.Range:
        return this.addReadonlyChildComponent(RangeFieldComponent);

      case Enums.FieldType.Email:
        return this.addReadonlyChildComponent(EmailFieldComponent);

      case Enums.FieldType.UrlField:
        return this.addReadonlyChildComponent(UrlFieldComponent);

      case Enums.FieldType.MultiState:
        return this.addReadonlyChildComponent(MultiStateField2Component);

      case Enums.FieldType.Image:
        return this.addReadonlyChildComponent(ImageFieldComponent);

      case Enums.FieldType.ImageList:
        return this.addReadonlyChildComponent(ImageListFieldComponent);

      // Currently using text display. This might not be right in future
      // right, see notes on SOF-11983
      // case Enums.FieldType.Person:
      //   return this.addReadonlyChildComponent(PersonFieldComponent);
      // case Enums.FieldType.PersonByTeam:
      //   return this.addReadonlyChildComponent(PersonTeamFieldComponent);
      // case Enums.FieldType.Team:
      //   return this.addReadonlyChildComponent(TeamFieldComponent);

      case Enums.FieldType.ImageActionButton:
        return this.addReadonlyChildComponent(ButtonFieldComponent);

      case Enums.FieldType.EmbeddedVideo:
        // return this.addReadonlyChildComponent(EmbeddedVideoFieldComponent);
        // fall through = needs work to enable

      // Show unsupported fields as icons
      case Enums.FieldType.ListField:
      case Enums.FieldType.GridField:
      case Enums.FieldType.Lookup:
        return this.addReadonlyChildComponent(IconDisplayFieldComponent);

      default:
        return this.addReadonlyChildComponent(TextDisplayFieldComponent);
    }
  }

  protected override _initField(fieldComponent: FieldBase): void {
    fieldComponent.hideUnderline = true;
    fieldComponent.forReport = true;    // don't use form styling
    super._initField(fieldComponent);
  }
}
