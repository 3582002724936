import { OdataExpression, OdataLiteralExpression } from './odata-expression';
import { OdataExpressionType } from './odata-expression-type';
import { IRootQuery } from './root-query';
import { OdataPropertyExpression } from './odata-property-expression';

export abstract class OdataBinaryExpression extends OdataExpression {

  leftExpression: OdataExpression;
  rightExpression: OdataExpression;

  constructor(type: OdataExpressionType, onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(type, root, parent);

    this.walkArgs(onode);
    root.addReferenceIfSimple(this);
  }

  protected walkArgs(onode: any) {
    if (onode) {
      this.leftExpression = this.walk(onode.left);
      this.rightExpression = this.walk(onode.right);
    }
  }

  public override each(callback: (node: OdataExpression) => OdataExpression | boolean | null) {
    let response = callback(this);
    if (!response) {
      response = this.leftExpression?.each(callback) ?? false;
    }
    if (!response) {
      response = this.rightExpression?.each(callback) ?? false;
    }
    return response;
  }

  public override eachChild(callback: (node: OdataExpression) => boolean | OdataExpression): OdataExpression | boolean | null {
    let response: OdataExpression | boolean | null = false;
    if (!response) {
      response = this.leftExpression?.each(callback) ?? false;
    }
    if (!response) {
      response = this.rightExpression?.each(callback) ?? false;
    }
    return response;
  }

  public override replace(nodeId: number, newNode: OdataExpression) {

    if (this.leftExpression.id === nodeId) {
      this.leftExpression = newNode;
    } else if (this.rightExpression.id === nodeId) {
      this.rightExpression = newNode;
    } else {
      throw new Error('replace node not found');
    }

    newNode.parent = this;
  }

  public override removeChild(nodeId: number) {
    let removed: OdataExpression = null;
    if (this.leftExpression.id === nodeId) {
      removed = this.leftExpression;
      this.leftExpression = null;
    } else if (this.rightExpression.id === nodeId) {
      removed = this.rightExpression;
      this.rightExpression = null;
    }

    if (removed) {
      // Unlink from node map
      removed.each(node => {
        this.root.removeExpression(node);
        return false;
      });
    }
  }

  public override repair() {

    // If either of our children reports that it can be reduced to a simpler
    // expression, apply it
    const leftReduced = this.leftExpression && this.leftExpression.canReduceTo();
    if (leftReduced) {
      this.root.removeExpression(this.leftExpression);
      this.leftExpression = leftReduced;
      leftReduced.parent = this;
    }

    const rightReduced = this.rightExpression && this.rightExpression.canReduceTo();
    if (rightReduced) {
      this.root.removeExpression(this.rightExpression);
      this.rightExpression = rightReduced;
      rightReduced.parent = this;
    }

    super.repair();
  }
}


/**
 * Expression that performs a logical coomparison on two values.
 * Currently we only support a comparison between a property and a value but this could
 * be extended to include arbitrary expressions e.g. comparing two different properties.
 */
export class OdataComparisonExpression extends OdataBinaryExpression {

  private odataOper;

  constructor(type: OdataExpressionType, onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(type, onode, root, parent);
    this.odataOper = onode.type;
  }

  // A comparison is simple if one argument is a property and the other a literal
  public override isSimple = () => (this.leftExpression instanceof OdataPropertyExpression
    && this.rightExpression instanceof OdataLiteralExpression) ||
    (this.leftExpression instanceof OdataLiteralExpression
      && this.rightExpression instanceof OdataPropertyExpression)

  public override expressionText() {
    return `${this.leftExpression.expressionText()} ${this.odataOper} ${this.rightExpression.expressionText()}`;
  }

  public override isMatch(values: {}, listRow?: number): boolean {

    const left = this.leftExpression.lookupValue(values, listRow);
    const right = this.rightExpression.lookupValue(values, listRow);

    switch (this.type) {
      case OdataExpressionType.Equals:
        // eslint-disable-next-line eqeqeq
        return left == right;
      case OdataExpressionType.NotEquals:
        // eslint-disable-next-line eqeqeq
        return left != right;
      case OdataExpressionType.LessThan:
        return left < right;
      case OdataExpressionType.LessThanOrEqual:
        return left <= right;
      case OdataExpressionType.GreaterThan:
        return left > right;
      case OdataExpressionType.GreaterThanOrEqual:
        return left >= right;
    }

    return false;
  }

}

export abstract class OdataBinaryBooleanExpression extends OdataBinaryExpression {

  constructor(type: OdataExpressionType, onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(type, onode, root, parent);
  }

  public override canReduceTo(): OdataExpression {
    // If only one expression is set, we can be reduced to that expression
    if (!this.leftExpression) {
      return this.rightExpression;
    } else if (!this.rightExpression) {
      return this.leftExpression;
    } else {
      return null;
    }
  }
}

export class OdataAndExpression extends OdataBinaryBooleanExpression {
  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.And, onode, root, parent);
  }

  // And is simple if left and right subexpressions are both simple
  public override isSimple = () => this.leftExpression.isSimple() && this.rightExpression.isSimple();

  public override expressionText() {
    return `${this.leftExpression.expressionText()} and ${this.rightExpression.expressionText()}`;
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    return this.leftExpression.isMatch(values) && this.rightExpression.isMatch(values);
  }
}

export class OdataOrExpression extends OdataBinaryBooleanExpression {
  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.Or, onode, root, parent);
  }

  public override expressionText() {
    return `${this.leftExpression.expressionText()} or ${this.rightExpression.expressionText()}`;
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    return this.leftExpression.isMatch(values) || this.rightExpression.isMatch(values);
  }
}

export abstract class OdataFunctionExpression extends OdataBinaryExpression {

  // argExpressions: Array<OdataExpression> = [];

  constructor(type: OdataExpressionType, onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(type, onode, root, parent);
  }

  protected override walkArgs(onode: any) {
    this.leftExpression = this.walk(onode.args[0]);
    this.rightExpression = this.walk(onode.args[1]);
  }

  // public isSimple = () => this.argExpressions.every((expr) => expr.isSimple());
}



export class OdataStartsWithExpression extends OdataFunctionExpression {
  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.StartsWith, onode, root, parent);
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    const container = this.leftExpression.lookupValue(values, listRow);
    const containee = this.rightExpression.lookupValue(values, listRow);
    return container && containee && container.startsWith(containee);
  }

  public override expressionText(): string {
    return `startswith(${this.leftExpression.expressionText()},${this.rightExpression.expressionText()})`;
  }
}

export class OdataEndsWithExpression extends OdataFunctionExpression {
  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.EndsWith, onode, root, parent);
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    const container = this.leftExpression.lookupValue(values, listRow);
    const containee = this.rightExpression.lookupValue(values, listRow);
    return container && containee && container.endsWith(containee);
  }

  public override expressionText(): string {
    return `endswith(${this.leftExpression.expressionText()},${this.rightExpression.expressionText()})`;
  }
}

export class OdataSubstringExpression extends OdataFunctionExpression {
  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.Substring, onode, root, parent);
  }

  public override isMatch(values: {}, listRow?: number): boolean {
    const containee = this.leftExpression.lookupValue(values, listRow);
    const container = this.rightExpression.lookupValue(values, listRow);
    return container && containee && container.includes(containee);
  }

  public override expressionText(): string {
    return `substringof(${this.leftExpression.expressionText()},${this.rightExpression.expressionText()})`;
  }
}
