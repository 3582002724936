import { Injectable } from '@angular/core';
import { CanActivate, Params, ActivatedRouteSnapshot } from '@angular/router';
import { HOMEPAGE_IDENTIFIER } from 'app/_constants';
import { AppService } from 'app/services/app.service';
import { ImageListAssetService } from 'app/services/images/image-list-assets.service';
import { HomepagesService } from 'app/services/homepages.service';
import { ImageListAssetsGuard } from './image-list-assets.guard';

@Injectable({ providedIn: 'root' })
export class HomepageImageListAssetsGuard extends ImageListAssetsGuard implements CanActivate {

  public constructor(
    appService: AppService,
    imageListAssetService: ImageListAssetService,
    private homepagesService: HomepagesService,
  ) {
    super(appService, imageListAssetService)
  }

  public override async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    const params: Params = route.params;
    const homeIdentifier: string = params[HOMEPAGE_IDENTIFIER];
    if (homeIdentifier) {
      const homepage = this.homepagesService.getAll()?.find(p => p.Identifier === homeIdentifier);
      const ids = homepage?.Dashboard?.DashboardCells.filter(cell => cell.AppIdentifier).map(cell => cell.AppIdentifier);
      if (ids) {
        const unique = Array.from(new Set(ids.values()));
        await Promise.all(unique.map(id => this.loadAppImages(id)));
      }
    }

    return true;
  }
}
