import { AttachmentService, EmailActivity, FileAttachment, FilePatch, logError, RecordId } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { BusyModel, Causes } from './reports/busy.model';
import { AppModel } from './app.model';
import { lastValueFrom } from 'rxjs';

export class EmailActivityModel extends Model<EmailActivityModel>
{
  public readonly loading: BusyModel = new BusyModel(this);
  public readonly emailActivity = new ModelProperty<EmailActivity>(this);
  public readonly error = new ModelProperty<boolean>(this, false);
}

export class AttachmentsModel extends Model<AttachmentsModel> {

  private recordId: RecordId;
  private appModel: AppModel;

  public readonly attachments = new ArrayModelProperty<FileAttachment>(this);
  public readonly loading = new BusyModel(this);
  public readonly showAttachments = new BooleanModelProperty(this);
  public readonly isConfirmVisible = new BooleanModelProperty(this);

  @InjectService(AttachmentService)
  private attachmentService: AttachmentService;

  public constructor(container?: Model<any>) {
    super(container);
    this.attachments.logChanges = 'ATTACHMENTS';
  }

  public clear() {
    this.attachments.value = null;
  }

  public async load(appModel: AppModel, recordId: RecordId) {
    this.recordId = recordId;
    this.appModel = appModel;

    await this.loadAttachments();
  }

  public async loadAttachments() {
    try {
      if (this.appModel.app.value.AllowAttachments) {
        this.loading.start(Causes.loading);
        const attachments = await lastValueFrom<Array<FileAttachment>>(this.attachmentService.getAttachments(this.appModel.identifier.value, this.recordId));
        this.attachments.value = attachments.sort((a, b) => (b.CreatedDate > a.CreatedDate ? 1 : -1));
      }
    } catch (error) {
      logError(error, 'loadAttachments');
    } finally {
      this.loading.finish(Causes.loading);
    }
  }

  public async patchAttachment(filePatch: FilePatch) {
    await this.attachmentService.patchAttachment(this.appModel.identifier.value, this.recordId, filePatch).toPromise();
    await this.loadAttachments();
  }

  public async saveAttachment(filename: string, base64Data: string) {
    await this.attachmentService.saveAttachmentWithData(this.appModel.identifier.value, this.recordId, filename, base64Data).toPromise();
    await this.loadAttachments();
  }

  public async deleteAttachment(file: FileAttachment) {
    await this.attachmentService.deleteAttachment(this.appModel.identifier.value, this.recordId, file).toPromise();
    await this.loadAttachments();
  }

  public open() {
    this.showAttachments.value = true;
  }

  public close() {
    this.showAttachments.value = false;
  }

  public toggle() {
    this.showAttachments.toggle();
  }
}

