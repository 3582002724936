import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { Field, Form, Failure, Template, ValidationError } from '@softools/softools-core';
import { Record } from '@softools/softools-core';
import { Enums } from '@softools/softools-core';
import { ComponentBase } from 'app/softoolsui.module/component-base';
import { AppField } from 'app/types/fields/app-field';
import { isTextualField } from 'app/_constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-field-footer',
  templateUrl: './field-footer.component.html',
  styleUrls: ['./field-footer.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldFooterComponent extends ComponentBase implements OnChanges {

  @Input() field: AppField;

  /** The form containing the field */
  @Input() public form?: Form;

  /** The template containing the field */
  @Input() public template?: Template;

  /** Is the field in a read-only context */
  @Input() public readonly = false;

  @Input() record: Record;

  @Input() editErrors: Array<Failure>;

  public errcodes = ValidationError;

  public firstError$ = new BehaviorSubject<Failure>(null);

  public invalid$ = new BehaviorSubject(false);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['editErrors'] || changes['record'] || changes['field']) {
      this.invalid$.next(this.isInvalid());
      this.firstError$.next(this.firstError);
    }
  }


  public showStandardFooter() {

    // Don't show on display forms
    if (this.form?.ReadOnly || this.template?.IsReadOnly) {
      return false;
    }

    // Don't show footer content on fields that display their own
    return this.field && (this.field.Type !== Enums.FieldType.Lookup);
  }

  // This is used in the template so must be in the component
  public isTextualField(field: Field) {
    return isTextualField(field.Type);
  }

  private isInvalid() {

    if (this.editErrors?.length > 0) {
      return true;
    }

    if (this.record && this.field) {
      const recordErrors = this.record._errs;
      if (recordErrors) {
        return recordErrors.findIndex(f => f.fieldId === this.field.Identifier) >= 0;
      }
    }

    return false;
  }

  private get firstError(): Failure {

    if (this.editErrors?.length > 0) {
      return this.editErrors[0];
    }

    if (this.record && this.field) {
      const recordErrors = this.record._errs;
      if (recordErrors) {
        const error = recordErrors.find(f => f.fieldId === this.field.Identifier);
        if (error) {
          return error;
        }
      }
    }

    return null;
  }

}
