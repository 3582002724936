import { Injectable } from '@angular/core';
import { Application } from 'app/types/application';
import { User, SyncParameters, RetryPolicy, OnlineStatusService, AppDataStorageService, logError } from '@softools/softools-core';
import { getAppTweaks } from 'app/types/app-tweaks';
import { StorageMode } from 'app/types/enums';
import { StorageModeService } from 'app/services/storage-mode.service';

@Injectable({ providedIn: 'root' })
export class DataSyncService {

  public constructor(
    private appDataService: AppDataStorageService,
    private storageModeService: StorageModeService,
    protected onlineStatus: OnlineStatusService,
  ) {
  }

  public async syncAppData(app: Application, user: User, hierarchy?: string) {
    try {
      const syncParameters: SyncParameters = {
        PageSize: this.getSyncPageSize(app, user),
      };

      if (app.storageMode === StorageMode.Selective) {
        syncParameters.Filter = this.getSelectiveSyncFilter(app, user);
      }

      if (hierarchy) {
        syncParameters.Hierarchy = hierarchy;
      }

      // Only sync if app supports offline data
      if (app.storageMode === StorageMode.Offline || app.storageMode === StorageMode.Selective) {
        const retry = new RetryPolicy(`sync ${app.Identifier}`);
        retry.onlineStatus = this.onlineStatus;
        await this.appDataService.syncDataNoIndex(app, syncParameters, retry);
      }
    } catch (error) {
      logError(error, `Sync app ${app.Identifier} data failed`);
      throw error;
    }
  }

  public async resyncAppData(app: Application, user: User, hierarchy?: string) {
    try {
      const syncParameters: SyncParameters = {
        PageSize: this.getSyncPageSize(app, user),
      };

      if (app.storageMode === StorageMode.Selective) {
        syncParameters.Filter = this.getSelectiveSyncFilter(app, user);
      }

      if (hierarchy) {
        syncParameters.Hierarchy = hierarchy;
      }

      // Only sync if app supports offline data
      if (app.storageMode === StorageMode.Offline || app.storageMode === StorageMode.Selective) {
        const retry = new RetryPolicy(`sync ${app.Identifier}`);
        retry.onlineStatus = this.onlineStatus;
        await this.appDataService.syncDataNorizon(app, syncParameters, retry);
      }
    } catch (error) {
      logError(error, `Sync app ${app.Identifier} data failed`);
      throw error;
    }
  }


  private getSelectiveSyncFilter(app: Application, _user: User) {
    const offlineReportIds = this.storageModeService.getAppOfflineReports(app.Identifier);
    if (offlineReportIds) {
      const offlineReports = app.Reports.filter((rep) => rep.BaseFilter).filter((rep) => offlineReportIds.includes(rep.Identifier));
      const filter = offlineReports.map((rep) => `(${rep.BaseFilter.replace('$filter=', '')})`).join(' or ');
      return filter;
    } else {
      return '';
    }
  }

  /**
   * Calculate page size
   * @param app
   * @param user
   */
  private getSyncPageSize(app: Application, user: User): number {
    // The total length of field names within the app is used as a proxy for record
    // size - in the JSON data the field labels typically take more space than the
    // values.  This is especially true where we have many fields with no value set
    // To avoid very large responses (which can blow the JS heap) we aim for a 10Mb
    // maximum, dividing that by the label length.  In practice the response will be
    // larger as we don't inlcude the values or various bits of JSON noise like
    // brackets or quotes.  Testing showed around 15Mb was not a problem and we
    // should have plenty of headroom.
    // This is largely so support apps with very many fields (and/or very wordy
    // labels) - see SOF-5819 where a ~6500 field app returned about 1Gb of response
    // and blew up JS memory.

    // Get total length of field names
    let pageSize = 2000; // default if no fields (it is possible!)
    if (app.Fields) {
      const labelLength = app.Fields.map((field) => field.Identifier.length).reduce((p, c) => p + c, 1);
      const tenMeg = 10 * 1024 * 1024;
      const size = Math.floor(tenMeg / labelLength);
      // Must request at least one record.   This will be slow if that happens!
      pageSize = size < 1 ? 1 : size;

      // Where we have a simple app with short labels, this could allow a huge number of records.
      // To avoid this blowing memory apply an arbitrary limit to number of records requested.  See SOF-6876.
      if (pageSize > 5000) {
        pageSize = 5000;
      }

      // Allow site/app specific override of max page szie
      const tweak = getAppTweaks(user.Tenant, app.Identifier);
      if (tweak && tweak.maxSyncRecords && pageSize > tweak.maxSyncRecords) {
        pageSize = tweak.maxSyncRecords;
      }
    }

    return pageSize;
  }
}
