import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { Failure } from '@softools/softools-core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFieldComponent extends EditableFieldBase<string> implements AfterViewInit {

  @ViewChild(InputComponent, { static: false })
  private inputComponent: InputComponent;

  private activeWhenReady = false;

  public override async activate() {
    this.activeWhenReady = true;
  }

  public override async deactivate() {
    await this.inputComponent?.updateCurrentValue();
  }

  ngAfterViewInit() {
    if (this.activeWhenReady) {
      this.activeWhenReady = false;
      this.inputComponent?.activate();
    }
  }

  public inputChanged($event) {
    const errors: Array<Failure> = [];
    this.fieldModel.validate($event.value, errors);
    this.onInputChanged.emit({ value: $event.value });
    this.inputChanged$.next($event.value);
  }

  public onInputFocus() {
    this.requestActivation$.next(null);
  }

  public onInputBlurred(value: any) {
    this.updateValue(value);
  }
}
