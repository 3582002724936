<div #iconContainer
  tabindex="-1"
  class="iconContainer"
  [class.pointer]="forReport"
  [style.text-align]="alignment"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
  (click)="clicked($event)" >
  <fa-icon class='st-text-theme-primary pointer' [icon]="icon" size="1x" aria-hidden='true' >
  </fa-icon>
</div>
