import App from './teams-app.config.json';

import { Injectable } from '@angular/core';
import { Record, Team, TeamsRepository, IndexedAppData, RecordId, Enums, LookupOptions, logError, LookupCaptionStyle, ValidationErrorResponse } from '@softools/softools-core';
import { EmbeddedApplication } from '../embedded-application';
import { RecordPatch } from 'app/workspace.module/types';
import { IAppExtension } from 'app/types/app-extension';
import { ListFieldComponent } from 'app/softoolsui.module/fields2/list-field/list-field.component';
import { FieldBase } from 'app/softoolsui.module/fields/field-base';
import { Field, TrackChangeList } from '@softools/softools-core';
import { UsersService } from 'app/services/users.service';
import ObjectID from 'bson-objectid';
import { ToolbarContext, GetRecordOptions, AppCapabilities, DeleteRecordOptions } from 'app/types/application';
import { ToolbarAction } from 'app/softoolscore.module/types/classes/toolbaraction.class';
import { AppZone } from 'app/types/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionsService } from 'app/services/permissions.service';
import { TeamsService } from 'app/services/teams.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { InjectService } from 'app/services/locator.service';
import { ILookupDialog } from 'app/softoolsui.module/lookup-dialog-service/lookup-dialog.interface';
import { LookupDialogService } from 'app/softoolsui.module/lookup-dialog-service/lookup-dialog.service';
import { RecordModel, ReportModel } from 'app/mvc';
import { AppModel } from 'app/mvc';
import { AppService } from 'app/services/app.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { MessageType } from 'app/softoolsui.module/message-dialog/message-dialog.component';

export class AddMemberExtension implements IAppExtension {

  @InjectService(LookupDialogService)
  private readonly lookupDialog: ILookupDialog;

  @InjectService(AppService)
  private readonly appService: AppService;

  public constructor(private app: TeamsApplication, private field: Field) {
  }

  public async executeAsync(appModel: AppModel, record: Record): Promise<void> {
    const values = record[this.field.Identifier] || [];
    const memberIds = values.map(val => val.Key);
    this.lookupMembers(appModel, record, memberIds).catch(error => logError(error, 'Failed to lookup members')); // async - returns when dialog closed
  }

  private async lookupMembers(appModel: AppModel, record: Record, selectedIds: Array<string>) {

    const targetAppIdentifier = 'Softools.User';
    const targetReport = 'Lookup';
    const usersApp = this.appService.application(targetAppIdentifier);
    const report = usersApp.getReport(targetReport) ?? usersApp.preferredListReport();

    if (report) {
      try {
        const options: LookupOptions = {
          appIdentifier: targetAppIdentifier,
          reportIdentifier: report.Identifier,
          searchLookupAppField: '',
          searchValue: '',
          multiSelect: true,
          selectedIds: selectedIds || [],
          uiState: null,
          captionStyle: LookupCaptionStyle.MultiSelectWithAppRecordName,
          report
        };

        const result = await this.lookupDialog.lookup(options);
        if (result?.ids) {

          const changeList = new TrackChangeList('Value');

          // Add any selected ids (in returned selection but not initial list)
          result.ids.forEach(id => {
            if (!selectedIds.find(i => i === id)) {
              // create a row to add.  Typing is ugly because we're not using Key here
              const row: any = { Value: id };
              changeList.addAdditionalRow(row);
            }
          });

          const patch = new RecordPatch(record._id, record.AppIdentifier)
            .addTrackedChange('UserIds', changeList)
            .setValid();

          await appModel.patchRecordValue(patch);
        }
      } catch (error) {
        logError(error, 'AddMemberExtension.lookupMembers');
      }
    }
  }

}
export class DeleteTeamMemberAppExtension implements IAppExtension<string> {

  public constructor(private app: TeamsApplication) {
  }

  public async executeAsync(appModel: AppModel, record: Record, key: string): Promise<void> {

    const user = this.app.userService.getUser(key);
    if (user.TeamIds.length <= 1) {
      await appModel.globalModel.showModalAsync(Enums.ModalType.info,
        $localize`Error removing team member`,
        $localize`Cannot remove user '${user.Username}' from team as they have no other team membership`);
    } else {
      const ok = await appModel.globalModel.showModalAsync(Enums.ModalType.confirm,
        $localize`Remove team member`,
        $localize`Are you sure you want to remove this user from the team?`
      );

      if (ok) {
        const changeList = new TrackChangeList('Value');
        changeList.addRemovalKey(key);

        const patch = new RecordPatch(record._id, record.AppIdentifier)
          .addTrackedChange('UserIds', changeList)
          .setValid();

        await appModel.patchRecordValue(patch);
      }
    }
  }
}
interface IMember {
  Key: string;
  UserName: string;
  Email: string;
  FirstName: string;
  LastName: string;
}

/** Extended Team details */
interface TeamDetails extends Team {
  _new?: boolean;
  Users?: Array<IMember>;
  PendingUsers?: Array<IMember>;
  UserCount: number;
  PendingCount: number;
  ClosedCount: number;
}

@Injectable({ providedIn: 'root' })
export class TeamsApplication extends EmbeddedApplication<TeamDetails> {

  private _usersField: Field;

  private static readonly hasMembers = $localize`Must not have members.  Remove before deleting team.`;

  constructor(
    private teamsService: TeamsService,
    private teamsRepository: TeamsRepository,
    public userService: UsersService,
    private permissionsService: PermissionsService,
    private recordPersistService: RecordPersistService,
    private models: GlobalModelService,
  ) {
    super(App.App);

    this._usersField = this.getField('Users');
  }

  public override get capabilities(): AppCapabilities {
    return {
      ...super.capabilities,
      noArchived: true
    };
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {
    if (recordPatch._new) {
      const record = recordPatch.toRecord(this);
      recordPatch.updateRecord(record, this);
      const details = this.toTeamDetails(record);
      const result = await this.teamsRepository.createAsync(details);

      if (result?.['ValidationErrors']) {
        const validationError = new ValidationErrorResponse();
        Object.assign(validationError, result);
        throw validationError;
      }
    } else {
      // Extract changes fom patch and map TeamIds which is a simple id list in the API
      // eslint-disable-next-line prefer-const
      let changes = recordPatch.getDelta();
      // if (changes.TeamIds) {
      //   changes = { ...changes, TeamIds: changes.TeamIds.map((v: any) => v.Value) }
      // }

      // Apply patch
      await this.teamsRepository.save(recordPatch._id, changes);
    }

    // Unfortunately the patch/post APIs don't return the updated user and fixing
    // that would mean unravelling dependenceies so for now query current state
    const updatedTeam = await this.teamsRepository.getTeamAsync(recordPatch._id);
    return this.toRecord(updatedTeam);

  }

  public override async storeAsync(record: Record): Promise<Record> {
    const details = this.toTeamDetails(record);
    await this.teamsService.storeTeamAsync(details);
    return record;
  }

  public toRecord(team: Team): Record {
    if (team) {
      const teamRecord = this.teamToTeamDetails(team);
      const record = {
        ...teamRecord,
        _id: teamRecord.Id,
        _new: teamRecord._new || false,
        AppIdentifier: this.Identifier,
        Hierarchy: '',
        EditableAccessForUser: true,
        CreatedDate: null,
        CreatedByUser: '',
        UpdatedDate: null,
        UpdatedByUser: '',
        QuickFilterSearchText: team.Name,
        IsArchived: false,
      } as Record;
      return record;
    } else {
      return null;
    }
  }

  public teamToTeamDetails(team: Team): TeamDetails {

    if (!team) {
      return null;
    }

    const users: Array<IMember> = [];
    const pendings: Array<IMember> = [];
    const closed: Array<IMember> = [];

    if (team.UserIds) {
      const ids = team.UserIds.map(id => ({ Key: id.Value }));
      ids.forEach(rec => {
        const id: string = rec.Key;
        // Check and ignore duplicates
        if (!users.find(member => member.Key === id) && !pendings.find(member => member.Key === id)) {
          const user = this.userService.getUser(id);
          if (user) {
            if (!('IsAccountClosed' in user) || !user.IsAccountClosed) {
              users.push({
                Key: id,
                UserName: user.Username,
                Email: user.Email,
                FirstName: user.FirstName,
                LastName: user.LastName,
              });
            } else {
              closed.push({
                Key: id,
                UserName: user.Username,
                Email: user.Email,
                FirstName: user.FirstName,
                LastName: user.LastName,
              });
            }
          } else {
            const pending = this.userService.getPendingUser(id);
            if (pending) {
              pendings.push({
                Key: id,
                UserName: pending.Username,
                Email: pending.Email,
                FirstName: pending.FirstName,
                LastName: pending.LastName,
              });
            }
          }
        }
      });
    }

    const details: TeamDetails = {
      ...team,
      Users: users,
      UserCount: users.length,
      PendingUsers: pendings,
      PendingCount: pendings.length,
      ClosedCount: closed.length
    };

    return details;
  }

  public toTeamDetails(record: Record): TeamDetails {
    const values = record as any;
    return record && {
      Id: record._id,
      _new: record._new,
      Name: values.Name,
      NumberOfUsers: values.NumberOfUsers,
      UserIds: values.UserIds,
    } as TeamDetails;
  }

  public override updateRecord(record: Record, fieldId?: string, value?: any) {
    super.updateRecord(record, fieldId, value);
    if (fieldId === 'UserIds') {
      // roundabout way of populating users to match ids...
      const td = this.toTeamDetails(record);
      const td2 = this.teamToTeamDetails(td);
      record['Users'] = td2['Users'];
    }
  }

  /** Called for each (currently some) field in the application to allow behaviour modification */
  public override initialiseFieldComponent(component: FieldBase) {
    if (component.fieldModel.Identifier === this._usersField.Identifier && component instanceof ListFieldComponent) {
      component.addItemExtension = new AddMemberExtension(this, this._usersField);
      component.removeItemExtension = new DeleteTeamMemberAppExtension(this);
    }
  }

  public override async recordsUpdatedAsync(records: Array<Record>): Promise<void> {
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (!record._new) {
        await this.storeAsync(record);
      }
    }
  }

  public override async eachRecord(callback: (record: Record) => any): Promise<void> {
    const teams = this.teamsService.getAllTeams();
    teams.forEach(team => {
      callback(this.toRecord(team));
    });
  }

  public override async nativeGetAll(): Promise<Array<Record>> {
    return await this.teamsService.getAllTeams().map(team => this.toRecord(team));
  }

  public override async getIndexedRecordRange(index: IndexedAppData, start: number, count: number): Promise<Array<Record>> {
    const records = await index.getRecordsWithGetterFunc(start, count, async (_app, id) => {
      return this.toRecord(await this.teamsService.getTeam(id));
    });
    return records;
  }

  public override async getRecordByIdAsync(id: RecordId, options?: GetRecordOptions): Promise<Record> {

    // Look up any pending patch if we're going to need it
    const pendingPatch = options?.applyPatch && this.recordPersistService.getPatch(id);

    let details: TeamDetails;

    // If patch is new, it's not been persisted so convert to record and return
    if (pendingPatch?._new) {
      const record = pendingPatch.toRecord(this);
      details = this.toTeamDetails(record);
    } else {
      const team = await this.teamsService.getTeam(id);
      details = this.teamToTeamDetails(team);
    }

    if (details) {
      const record = this.toRecord(details);
      if (pendingPatch && !pendingPatch._new) {
        pendingPatch.updateRecord(record, this);
      }

      return record;
    }

    return null;
  }

  public override async totalCount(): Promise<number> {
    // todo need count API
    const teams = await this.teamsService.getAllTeams();
    return teams && teams.length;
  }

  public override validateRecordId(recordId: RecordId): RecordId | boolean {
    if (!recordId || !ObjectID.isValid(recordId)) {
      const newObjectId = new ObjectID();
      recordId = newObjectId.toHexString();
    }
    return recordId;
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    switch (context.zone) {
      case AppZone.listreport:

        actions.push(new ToolbarAction('DeleteTeams', 'trash', async () => {
          const selection = await reportModel.promptForSelection({
            some: MessageType.ConfirmDeleteAppData,
            all: MessageType.DisallowAll,
            disallowAll: true,
          });

          if (selection) {
            await reportModel.delete(selection);
          }
        }));

        actions.push(new ToolbarAction('Refresh', 'sync', async () => {
          await reportModel.refresh();
        }));
        break;

      case AppZone.childlistreport:
      case AppZone.recordupdate:
      case AppZone.recordadd:
      default:
        break;
    }
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    const id = context.record?._id;
    if (id) {
      actions.push(new ToolbarAction('DeleteTeam', 'trash', async () => {
        const deleted = await this.deleteRecordsAsync({
          selection: {
            AllRecordsSelected: false,
            SelectedRecordIds: [id]
          }
        });

        if (deleted) {
          recordModel.appModel.reindexRecord(context.record._id);
          this.models.globalModel.header?.goBack();    // record has gone so go up to report
        }
      }));
    }

    // Refresh on all zones
    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      if (await this.refresh()) {
        await recordModel.reload();
      }
    }));
  }

  public override async refresh(): Promise<boolean> {
    const teams = await this.teamsService.refreshTeamsAsync();
    return true;
  }

  public override async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {

    const ids: Array<RecordId> = deleteOptions.selection.SelectedRecordIds;

    const messages: Array<string> = [];

    // Validate teams for delete (must have no members)
    for (let i = 0, length = ids.length; i < length; ++i) {
      const id = ids[i];
      const record: any = await this.getRecordByIdAsync(id);
      if (record?.UserCount > 0) {

        // Prevent deletion if we have any active users
        const team = this.toTeamDetails(record);
        if (team.UserIds) {
          for (let i = 0; i < team.UserIds.length; i++) {
            const id = team.UserIds[i].Value;
            const user = this.userService.getUser(id);
            if (user && !user.IsAccountClosed) {
              messages.push(`<p><b>${record.Name}</b>: ${TeamsApplication.hasMembers}</p>`);
              break;    // only need one message
            }
          }
        }
      }
    }

    if (messages.length > 0) {
      await this.models.globalModel.showModalAsync(Enums.ModalType.info,
        $localize`Error deleting teams`,
        messages.join('\n')
      );

      return false;
    }

    const responses = await this.teamsRepository.deleteTeamsAsync(ids);

    // We can have a mix of successes and failures, split responses
    const errors = responses.filter(resp => resp instanceof HttpErrorResponse) as Array<HttpErrorResponse>;
    const deleted = responses.filter(resp => !(resp instanceof HttpErrorResponse)) as Array<string>;

    if (errors.length > 0) {
      // todo message with all errors....
      const firstError = errors[0];
      if (firstError && firstError.error && firstError.error.ValidationErrors) {
        const message = firstError.error.ValidationErrors.map(e => e.Message).join('\n');
        await this.models.globalModel.showModalAsync(Enums.ModalType.info,
          $localize`Error deleting teams`,
          message
        );
      }
    }

    // The ids in the response have been deleted so update storage to match...
    if (deleted.length > 0) {
      await this.teamsService.deleteTeamsAsync(deleted);

      deleted.forEach(deletedId => {
        this.recordDeleted$.next({ app: this, recordIdentifier: deletedId });
      });
    }

    return true;
  }

  // /** Return true if app is visible to the current user */
  // public get visible() {
  //   return this.permissionsService.hasTeamSettings;
  // }

  public override get permissions() {
    return this.permissionsService.teamPermissions;
  }
}
