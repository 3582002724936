
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { Filter, logError } from '@softools/softools-core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ReportFilter } from '../../../../filters/types';
import { MessageType, MessageDialogData } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { Subscription } from 'rxjs';
import { Application } from 'app/types/application';
import { FilterModel, ReportModel } from 'app/mvc';
import { ComponentBase } from 'app/softoolsui.module';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';

@Component({
  selector: 'app-ws-sharedreportactions',
  templateUrl: './ws-sharedreportactions.component.html',
  styleUrls: ['./ws-sharedreportactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSSharedReportActionsComponent extends ComponentBase implements OnDestroy {

  public parent: any;

  private mediaSub: Subscription;

  @Input() reportModel: ReportModel;

  @Input() app: Application;

  @Input() filterModel: FilterModel;

  @Input() filter: ReportFilter;
  @Input() baseFilter: string;
  @Input() subtitle: string;

  @Input() showFilterSummary = false;

  @Input() selectedFilter: Filter;
  @Input() isArchived = false;

  @Input() showInlineEditSwitch = false;
  @Input() isEditMode = false;

  @Input() showSelected = false;

  @Input() recordCount: number | null = null;
  @Input() selectedCount: number | null = null;
  @Input() allRecordsSelected = false;

  @Input() isSearchable = false;
  @Input() showSearchPopup = false;
  @Input() searchText = '';

  @Input() isFilterable = true;

  @Input() isReportOption = false;
  @Input() isAutoLayout = false;

  @Input() isLoading = false;

  @Input() showFilterManagement: IShowFilterManagement;


  @Output() onEditModeHandler = new EventEmitter<boolean>();
  @Output() onSearchEscClickHandler = new EventEmitter<any>();
  @Output() onSearchEnterClickHandler = new EventEmitter<string>();
  @Output() onDeselectAllHandler = new EventEmitter<any>();
  @Output() onArchivedRecordsBadgeClickHandler = new EventEmitter();
  @Output() onToggleReportOptions = new EventEmitter();

  public filterPopupuPosition = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }
    )
  ];

  public searchPopupuPosition = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'bottom' },
      { overlayX: 'center', overlayY: 'top' }
    )
  ];

  constructor(
    public media: MediaObserver,
    private cd: ChangeDetectorRef
  ) {
    super();

    this.mediaSub = media.asObservable().pipe().subscribe(this.onMediaChanged);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.mediaSub.unsubscribe();
  }

  public toggleQuickFilters(e: Event) {
    e.stopPropagation();
    this.showFilterManagement?.toggleQuickFilters();
  }

  public toggleFilterManagement(e: Event) {
    e.stopPropagation();
    this.showFilterManagement?.toggleFilterManagementPanel();
  }

  public filterManagementClosed() {
    this.showFilterManagement?.showFilterManagementPanel(false);
  }

  public searchClosed() {
    this.showSearchPopup = false;
  }

  public filterSummaryClosed() {
    this.showFilterSummary = false;
  }

  public clearFilterClicked(e: MouseEvent) {
    e.stopPropagation();
    this.filterModel.updateCurrentFilter(null).catch(error => logError(error, 'Failed to update filters'));
  }

  public onToggleFieldsPanel(e: MouseEvent) {
    e.stopPropagation();
    this.onToggleReportOptions.emit();
  }

  public onSearch = (searchTerm: string) => {
    this.showSearchPopup = false;
    // todo test search popup mode
    // this.dispatcher.dispatch(new SetSearchAction(searchTerm));
  }

  public flterBadgeClicked(e: Event) {
    this.toggleFilterManagement(e);
  }

  public async baseFilterBadgeClicked() {
    const data: MessageDialogData = {
      Type: MessageType.DisplayBaseFilter,
      Value: this.baseFilter
    };

    await this.reportModel.globalModel.showMessageDialogAsync(data);
  }

  public isMediaActive(query: string) {
    return this.media.isActive(query);
  }

  private onMediaChanged = (change: MediaChange[]) => {
    // Was firing an unused action, look for missing functionality then remove
  }

  public searchPopupEnter(event) {
    this.onSearchEnterClickHandler.emit(event);
    this.showSearchPopup = false;
  }

  public searchPopupEscape() {
    this.onSearchEscClickHandler.emit();
    this.showSearchPopup = false;
  }

  /**
   * Force a redraw for the responsive media break check 'isMediaActive'
   */
  @HostListener('window:resize')
  resize() {
    this.cd.markForCheck();
  }
}
