import { IconName } from '@fortawesome/fontawesome-common-types';

import {
  App,
  Form,
  Field,
  Record,
  Enums,
  Report,
  Template,
  OfflineAvailability,
  IndexedAppData,
  RecordId,
  Comment,
  FileAttachment,
  tryGetCurrentUser,
  stringCompare,
  SelectListOption,
  ValidationRule,
  Style,
  Rule,
  LogicBlock,
  LogicBlockIdentifer,
  logError,
  IRecordSelection,
  QueryParams,
  User,
  IAppIdentifier,
  IVisibility,
  VisiblityFlag,
  getCurrentUser,
  SavedFilter,
  IStyleName,
  Permission,
  IRecordsResponse,
  FormRule,
  OdataQueryFilter
} from '@softools/softools-core';
import { AppField, IPerformContext } from './fields/app-field';
import { GridSubField } from './fields/grid-sub-field';
import { RecordPatch } from 'app/workspace.module/types';
import { ISelectOptions } from 'app/softoolsui.module/select-options/select-options.component';
import { FieldBase } from 'app/softoolsui.module/fields';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { Subject } from 'rxjs';
import { BackingField } from './fields/backing-field';
import { StorageMode } from './enums';
import { RecordModel, RecordUpdateController, ReportModel } from 'app/mvc';
import { RecordSelection } from './record-selection';
import { ReportController } from 'app/mvc/reports/report.controller';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { InjectService } from 'app/services/locator.service';
import { appFieldFactoryToken, IAppFieldFactoryService } from 'app/services/fields/app-field-factory.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportFilter } from 'app/filters/types';
import { GridCellField } from './fields/grid-cell-field';
import { ApplicationValueLookup } from 'app/services/indexes/application-value-lookup';

export type AppIdentifier = string;
export const LAST_ACCESSED = 'last-accessed';

export interface GetRecordOptions {

  /** Overlay pending patch changes */
  applyPatch?: boolean;

  /** If true, always get using remote API.
   * Can be used to ensure latest version or to get an archived record
   */
  forceRemote?: boolean;

  /**
   * If true, always check in storage
   * This will only return a record for an offline app
   */
  forceStorage?: boolean;

  /** If true, checks remote API if local storage lookup fails */
  fallbackRemote?: boolean;

  /** If true, only gets any current patch, doesn't use storage or API.  */
  patchOnly?: boolean;

  /** fetch child records with specified hierarchy.  No effect when requesting single records.  */
  hierarchy?: string;
}

export interface ICard {
  /** Card identifier, unique within app */
  Identifier: string;

  /** Default card, used when no card specified e.g. on homepage.  Only one should be Default */
  Default?: boolean;

  /**
   * Templates to use for the card.  The first template is displayed normally; if there are
   * more than one we can provide a way to switch between them (todo).
   * Either a template id from the app's templates or an inline template can be provided.
   */
  Templates?: Array<{
    TemplateIdentifier?: string;
    Template?: Template;
  }>;

  Width: number;
  Height: number;

  MarginFields?: Array<ICardMarginFields>;

  /** Optional list of styles to apply to the card */
  NamedStyles?: Array<IStyleName>;

  /**
   * Optional Additional style  to apply to the card
   * This probably won't be available in AS but is useful to test styling
   * We may well remove it when AS style support is complete
   * */
  Style?: Style;
}

export interface ICardMarginFields {
  Side: string;
  DisplayOrder: number;
  FieldIdentifier?: string;
  Field?: Field;
}

export interface DeleteRecordOptions {

  selection: IRecordSelection;

  reportId?: number;

  queryParams?: QueryParams;

  softDelete?: boolean;
}

export interface IToolbarMenuItem {

  Icon?: string;

  /**
   * The contexts that the menu item is available in.  It can include repor types
   * using the @see ReportTypes enumeration, 'report' to appear on all reports,
   * 'record' to appear on record views and 'cog' to appear on the cog menu on
   * all pages.
   */
  Scope: Array<string | number>;    // 'record'|'report'|'cog'|'home' report type enum

  DisplayOrder?: number;

  /** IF specified the user must have one or more of the listed permissions */
  Permissions?: Array<number>;

  /** IF specified the user must have edit access */
  Editable?: boolean;

  /** true/false specifies specific archive state, undefined always  */
  Archived?: boolean;

  /** If specified, only applies to new or existing record according to value */
  New?: boolean;

  StorageMode?: StorageMode;

  /** If specified, only applies to offline or online apps according to value */
  Offline?: boolean;

  /** If specified, only applies to a parent/non-parent app (i.e. has one or more child apps) */
  Parent?: boolean;

  ChildContext?: boolean;

  /** Field definition that defines the action to be performed. Must be a button or other type that
   * has a default action implementation */
  ActionField: Field;
}

export interface IGetIndexOptions {
  hierarchy?: string;
  searchTerm?: string;
  filter?: ReportFilter;
  archived: boolean;
}

/**
 * Definition of a Softools application
 */
export abstract class Application<T = any> implements App {

  @InjectService(appFieldFactoryToken)
  protected appFieldFactory: IAppFieldFactoryService;

  AllowAttachments: boolean;
  AllowComments: boolean;

  /** True if current user is allowed to edit app definition */
  IsEditable?: boolean;

  ChildAppsIdentifiers: Array<IAppIdentifier>;
  CreatedByUserId: number;
  CreatedDate?: Date;

  Cards?: Array<ICard>;

  Description: string;
  Forms: Array<Form> = [];
  HelpEnabled: boolean;
  HideFoldersBar?: boolean;
  Id: number;
  Identifier: AppIdentifier;
  IsHidden: boolean;
  IsLookupApp: boolean;
  IsSingletonApp?: boolean;
  IsSystemApp: boolean;
  LastUpdate: string;
  LinkPickerDefault: Enums.LinkPickerDefault;
  LinkPickerDefaultIncludeAttachments: boolean;
  LinkPickerDefaultIncludeComments: boolean;
  LinkPickerDefaultIncludeHistory: boolean;
  LinkPickerDefaultIncludeNotes: boolean;
  LinkPickerDefaultTemplatedCopy: boolean;
  LinkPickerForce: Enums.LinkPickerForce;
  LinkPickerIgnoreAccessRights: boolean;
  LinkPickerShowOptionArea: boolean;
  LinkPickerDefaultFilter: string;

  /** Report to use for link picker. This must be a list or table report.
   * If not specified, the default or first suitable report is used
   */
  LinkPickerReportIdentifier?: string;

  ModifiedByUserId: number;
  ModifiedDate: Date | string;
  Name: string;
  NamePlural: string;

  /** Optional text values to use in UI.  No AS support.
   * These should eventually be translatable.
   * When adding more, Use complete strings - avoid fragments that need to be concatenated
   */
  TextResources?: {

    /** Replacement text for "Archived" e.g. in archive state badge */
    Archived?: string;

    /** Replacement text for title of archive state badge */
    ArchivedBadgeTitle?: string;

    /** Replacement message for "No records found" */
    NoRecordsFound?: string;

    /** Replacement message for "No archived records found" */
    NoArchivedRecordsFound?: string;

    /** Replacement message for "New Record" in folders pane */
    CreatePrompt?: string;

    /** Message in header in case of Archived  */
    ArchivedRecordHeader?: string;

    /** Message in header in case of Deleted  */
    DeletedRecordHeader?: string;

    /** Readonly record banner  */
    ReadOnly?: string;
  };

  ParentAppsIdentifiers: Array<AppIdentifier>;
  Publisher: { Id: string; Name: string };
  Reports: Array<Report> = [];

  RevisionTracked: boolean;

  // ====================================
  // Filtering

  /** List of filter field ids if app uses new filter mechanism (empty still enables) */
  FilterFieldIdentifiers?: Array<{ Identifier: string }>;

  /** @deprecated Use FilterFieldIdentifiers by preference, in transition we fall back to this */
  AdvancedFilterFieldIdentifiers?: Array<{ Identifier: string }>;

  // List of saved filters.  This is no longer used form standard apps, where we
  // use SavedFiltersApplication to access them.  This is retained as a fallback
  // for embedded apps, and while we migrate to the new mechanism (see SOF-11259)
  SavedFilters?: Array<SavedFilter>;

  // ====================================

  StaticRecordId?: RecordId;

  /** Optional array of styles for this app */
  Styles?: Array<Style>;

  /** Optional list of named styles to apply to the entire app  */
  NamedStyles?: Array<IStyleName>;

  Taxonomy: string;
  TeamAccess: Array<any>;
  TeamAppVisibilities: Array<IVisibility>;
  Templates: Array<Template> = [];
  Title: string;
  TrackExpressions: boolean;
  LogoImageUri: string;

  /** Use over logo url if set */
  IconBase64?: string;
  TaxonomyColor: string;
  TitleFieldIdentifier: string;
  HasIncludeInSearchFields: boolean;
  DefaultListReportIdentifier: string;
  AppVersionNumber: number;
  HiddenForSpringboard: boolean;
  OfflineAvailability?: OfflineAvailability;

  /** Optional complex validation rules */
  ValidationRules?: Array<ValidationRule>;

  /** Optional array of conditional behaviour rules */
  Rules?: Array<Rule>;

  /** Optional array of client logic blocks */
  LogicBlocks?: Array<LogicBlock>;

  /** If true the app is available without authentication.
   * User related functions should be removed
   */
  Anonymous?: boolean;

  /** If true, navigation features (e.g. springboard) should be hidden or disabled */
  DisableNavigation?: boolean;

  /** If true autosave is turned off. the app must provide an explicit submit option  */
  DisableAutoSave?: boolean;

  /** If true, record copy uses simpler implementation that works offline */
  UseOfflineCopy?: boolean;

  /**
   * Menu definitions.
   * This defines menu items using fields to define the action taken
   */
  Menu?: Array<IToolbarMenuItem>;

  /** Indicates whether the app is defined in embedded code */
  public IsEmbedded = false;

  /** If true app is a settings app and displayed seperately in springboard */
  public IsSettingsApp = false;

  /** Collection of Fields as supplied to the constructor */
  private _rawFields: Array<Field> = [];

  /** Collection of typed fields; lazily created when first accessed */
  private appFields: Array<AppField>;

  /** Collection of typed fields indexed by id; lazily created when first accessed */
  private appFieldsById: Map<string, AppField>;

  /** Is the app fully, partialy or not ceched> */
  public storageMode: StorageMode = StorageMode.Offline;

  /**
   * Fires to notify that a record has been deleted and should be removed from local caches etc.
   */
  public recordDeleted$ = new Subject<{ app: Application, recordIdentifier: RecordId }>();

  constructor(app?: App) {
    if (app) {
      Object.assign(this, app);
      // This should probably be done on the server, but I'm not sure, done so we can take first visible form
      this.Forms = this.Forms?.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }
  }

  private initialiseFields(app: App) {
    this.appFields = [];
    this.appFieldsById = new Map<string, AppField>();

    if (this._rawFields) {
      // Create concrete fields
      for (let i = 0; i < this._rawFields.length; ++i) {
        const appField = this.createConcreteField(app, this.Fields[i]);
        this.appFieldsById.set(appField.Identifier, appField);
        this.appFields.push(appField);
      }

      this.appFieldsById.forEach((field: AppField) => {
        field.initialise(this);
      });
    }
  }

  public async initialise(): Promise<void> {
    // nop - override if needed
  }

  public set Fields(fields: Array<Field>) {
    this._rawFields = fields;
    delete this.appFields;
    delete this.appFieldsById; // trigger lazy init
  }

  public get Fields(): Array<Field> {
    return this._rawFields;
  }

  public addReport(report: Report) {
    const existing = this.Reports.findIndex((r) => r.Identifier === report.Identifier);
    if (existing >= 0) {
      this.Reports[existing] = report;
    } else {
      this.Reports.push(report);
    }
  }

  public removeReport(report: Report) {
    this.Reports = this.Reports.filter((r) => r.Identifier !== report.Identifier);
  }

  /** Get list of concrete typed fields.
   * This should be used in preference to @see Fields.
   * If you need to look up a single field, use the @see getField method which is faster than searching this collection.
   */
  public get AppFields(): Array<AppField> {
    if (!this.appFields) {
      this.initialiseFields(this);
    }

    return this.appFields;
  }

  public getField(fieldId: string): AppField {
    if (!this.appFieldsById) {
      this.initialiseFields(this);
    }

    return this.appFieldsById.get(fieldId);
  }

  /**
   * Get the default list report for this app.  If a report id is
   * specified and matches a valid list report, this takes priority.
   * Otherwise the configured default is used; if that is not configured
   * the first list report on the app is used.  If the app has not list
   * reports undefined will be returned.
   *
   * @param reportId    Optional explicit report id
   */
  public preferredListReport(reportId?: string): Report {
    // If a report id has been suggested, return that if it's a list report
    if (reportId) {
      const report = this.Reports.find((rep) => rep.Identifier === reportId);
      if (report && report.Type === Enums.ReportTypes.List) {
        return report;
      }
    }

    const user = getCurrentUser();
    // If app specifies a default list report, return that
    if (this.DefaultListReportIdentifier) {
      const report = this.Reports.find((rep) => rep.Identifier === this.DefaultListReportIdentifier);
      if (report?.Type === Enums.ReportTypes.List && this.isReportVisibleToUser(user, report)) {
        return report;
      }
    }

    // Return first list report (if any)
    return this.Reports.find((rep) => rep.Type === Enums.ReportTypes.List && this.isReportVisibleToUser(user, rep));
  }

  /**
   * 
   * @param mustBeVisible 
   * @param listOrTable 
   * @returns 
   */
  public preferredReport(mustBeVisible = false, listOrTable = false) {
    const user = getCurrentUser();
    let report = this.getDefaultReport();
    if (!report ||
      (listOrTable && (report.Type !== Enums.ReportTypes.List && report.Type !== Enums.ReportTypes.Table)) ||
      (mustBeVisible && !this.isReportVisibleToUser(user, report))) {
      // Default report not found or too hidden
      // Check preferred - this only returns visible
      report = this.preferredListReport();
    }
    return report;
  }

  public updateRecord(record: Record, fieldId?: string, value?: any) {
    if (fieldId) {
      const field = this.getField(fieldId);
      if (field) {
        field.updateRecord(record, value);
      }
    } else {
      this.AppFields.forEach((field) => {
        field.updateRecord(record, record[field.Identifier]);
      });
    }
  }

  /**
   * Comnvert a record from the server format to the more compact internal
   * format.  The record is updated in-place (and also returned)
   * @param record
   */
  public compactRecord(record: Record): Record {
    this.AppFields.forEach(field => {
      const updatedValue = field.compactRecord(record);
      // Replace value unless null (value has not been changed) or undefined (no value)
      if (updatedValue !== null && updatedValue !== undefined) {
        record[field.Identifier] = updatedValue;
      }
    });

    // Record app config version used to compact the data
    record._appVersion = this.AppVersionNumber;

    return record;
  }

  public cloneRecord(record: Record) {
    const clone = { ...record };
    this.AppFields.forEach((field) => {
      field.cloneValue(clone);
    });
    return clone;
  }

  public createAppField(field: Field): AppField {
    return this.createConcreteField(this, field);
  }

  protected createConcreteField(app: App, field: Field): AppField {

    const appField = this.constructConcreteField(field);

    if (appField.IsBackingField) {
      // wrap backing fields....
      return this.appFieldFactory.createBackingField(field, appField, this);
    }

    // See if field is contained in a grid or list field
    // We don't have a simple indicator for this but it can be determined from
    // this id which is built from the grid field, data set, and sub field ids
    if (field.Identifier.includes('_')) {
      const parts = field.Identifier.split('_');

      // Look up parent field.  Don't use the field collection as we're
      // still building it and it would be dangerous to assume it's complete
      const parent = app.Fields.find((f) => f.Identifier === parts[0]);
      if (parent) {
        if (parent.Type === Enums.FieldType.GridField && parts.length >= 2) {
          if (parts.length === 2) {
            return new GridSubField(field, appField, this);
          } else if (parts.length === 3) {
            return this.appFieldFactory.createGridCell(field, appField, this);
          }
        } else if (parent.Type === Enums.FieldType.ListField) {
          return this.appFieldFactory.createListItem(field, appField, this);
        }
      }
    }

    return appField;
  }

  protected constructConcreteField(field: Field): AppField {
    const appField = this.appFieldFactory.createField(field, this);
    return appField;
  }

  /** Called for each (currently some) field in the application to allow behaviour modification */
  public initialiseFieldComponent(_component: FieldBase) { }

  /** true if app supports full offline operation */
  public get isOfflineApp(): boolean {
    return !this.OfflineAvailability || this.OfflineAvailability.AvailableOffline;
  }

  public isOfflineReport(_reportIdentifier: string): boolean {
    return this.isOfflineApp;
  }

  public async eachRecord(_callback: (record: Record) => any, _options?: GetRecordOptions): Promise<void> {
    // nop
  }

  public async nativeGetAll(hierarchy?: string): Promise<Array<Record>> {
    // Derived classes can override to load efficiently using IDb.GetAll()
    const records: Array<Record> = [];
    this.eachRecord((rec) => {
      if (!hierarchy || rec.Hierarchy === hierarchy) {
        records.push(rec);
      }
    }).catch(e => logError(e, 'Failed to cusror over all records'));
    return records;
  }

  /**
   * Get a range of records using the supplied index
   * @param index
   * @param start     First record number (0 based)
   * @param count     Max number of records to return
   */
  // @ts-ignore: unused parameters are for implementations
  public async getIndexedRecordRange(index: IndexedAppData, start: number, count: number): Promise<Array<Record>> {
    return [];
  }

  /**
   * Get a range of records from the server
   * @param selection   Record selection parameters
   * @param options     Controls optional behaviour
   */
  // @ts-ignore: unused parameters are for implementations
  public async getApiRecordRange(selection: RecordSelection, options?: GetRecordOptions): Promise<Array<Record>> {
    return [];
  }

  /**
   * Get a range of records.
   * This is a general function that we should extend to replace the other record query functions.
   * Currently it is only implemented by standard application and does not work for all parameter values.
   * @param selection Selects the records to be returned
   * @param options   Controls how the records are obtained
   * @returns         A Promise with the returned records; an empty array if no matches (or not implemented)
   */
  public async getRecordsAsync(selection: RecordSelection, options?: GetRecordOptions): Promise<Array<Record>> {
    return [];
  }

  /**
     * Get a record from the server using report and cursor
     * @param selection   Record selection parameters
     */
  public async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    return undefined;
  }

  public async getApiGroupInformation(groupBy: string, descending: boolean, start: number, count: number, archived: boolean, hierarchy: string, filter?: string) {
    return [];
  }

  public async getApiGroupCount(groupBy: string, hierarchy: string, filter?: string): Promise<{ count: number }> {
    return { count: 0 };
  }

  public async getApiViewRecordCount(_selection: RecordSelection): Promise<number> {
    return 0;
  }

  public async getViewRecordCountAsync(_selection: RecordSelection): Promise<number> {
    return 0;
  }

  public async getRecordByIdAsync(_id: RecordId, _options?: GetRecordOptions): Promise<Record> {
    return null;
  }

  /** Get multiple records by id */
  public async getRecordsByIdAsync(ids: Array<RecordId>, _options?: GetRecordOptions)
    : Promise<Array<IRecordsResponse>> {
    // Default loops over ids and requests each.
    // Override to use more efficient implementation if available.
    if (ids.length > 0) {
      const promises = ids.map(async (id) => {
        try {
          const record = await this.getRecordByIdAsync(id, _options)
          return { id, status: 200, record };
        } catch (error) {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              // Currently API is returning 400 for record not found/accessible
              // so we need to remove the record in that case even though 400
              // could mean other errors. Also handle 404 as that is a more 
              // correct respose
              case 400:
              case 404: {
                return { id, status: 404 };
              }
            }
          } else {
            logError(error, 'Get record');
          }

          return { id, status: 400 };
        }
      });

      return Promise.all(promises);
    }

    return null;
  }


  public initRecord(record: any, _selectOptions: ISelectOptions) {
    return record;
  }

  public abstract toRecord(value: T): Record;

  public async loadCommentsAsync(_id: RecordId): Promise<Array<Comment>> {
    return null;
  }

  public async loadAttachmentssAsync(_id: RecordId): Promise<Array<FileAttachment>> {
    return null;
  }

  /**
   * Get total number of records.
   * This total is independent of any filters, searches etc. applied.
   * The selection parameter (if provided) specifies the archived flag and hieracrchy
   * to specify whether normal or archived records are considered (if relevant) and to limit
   * the count to the current hierarchy.
   *
   * @param selection
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async totalCount(selection?: RecordSelection): Promise<number> {
    return 0;
  }

  public abstract upsertAsync(_recordPatch: RecordPatch): Promise<Record>;

  public async storeAsync(record: Record): Promise<Record> {
    return record;
  }

  /** Called when records are updaeted;  app should store in its own state */
  public async recordsUpdatedAsync(_records: Array<Record>): Promise<void> {
    // only implemented in embedded apps - should standard do so?
  }

  /**
   * Delete a range of records by id.
   * @param _ids
   * @param _softDelete   Soft delete if true and is supported (e.g. Archive)
   * @returns a promise indicating whether records were deleted (true if so)
   */
  public async deleteRecordsAsync(deleteOptions: DeleteRecordOptions): Promise<boolean> {
    // override to perform deletion
    // Fire recordDeleted$ to notify that the record has been deleted and should 
    // be removed from local caches etc.
    return false;
  }

  /**
   * Undelete a record or range of records by id.  The records must be soft deleted to succeed.
   * @param _ids
   */
  public async undeleteRecordsAsync(_ids: RecordId | Array<RecordId>): Promise<boolean> {
    // nop, override
    return false;
  }

  /**
   * Validate a record id.  The id can be accepted, rejected or replaced.
   * @param id  Candidate record id
   * @returns   true if the id was acceptable, false if it was rejected, or a new id to use
   */
  public validateRecordId(id: RecordId): RecordId | boolean {
    return id;
  }

  public getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
  }

  public getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
  }

  public addToolbarActions(actions: Array<ToolbarAction>, scope: string | number, context: IPerformContext) {
    // Add any actions defined in Menu config
    if (this.Menu) {
      this.addMenuItems(this.Menu, actions, scope, context);
    }
  }

  /**
   * @returns true if data was updated
   * @param _dispatcher
   */
  public async refresh(): Promise<boolean> {
    // nop - override to perform refresh
    return false;
  }

  /** Get report by identifier, undefined if not present in app */
  public getReport(identifier: string) {
    return this.Reports.find((rep) => rep.Identifier === identifier);
  }

  public getDefaultReport(): Report {
    return this.Reports.find((rep) => rep.IsDefault);
  }

  public getFirstListReport(): Report {
    return this.Reports.find((rep) => rep.Type === Enums.ReportTypes.List);
  }

  public getLastAccessedReport(): Report {
    const lastAccessed = localStorage.getItem(`${LAST_ACCESSED}-${this.Identifier}`);

    if (lastAccessed) {
      const report = this.Reports.find((rep) => rep.Identifier === lastAccessed);
      if (!report?.IsHidden) {
        return report;
      }
    }

    return null;
  }

  public setLastAccessedReport(reportIdentifier: string) {
    localStorage.setItem(`${LAST_ACCESSED}-${this.Identifier}`, reportIdentifier);
  }

  /**
   * Get the report to go to when navigating back to the app.
   * The last report the user visited is returned; if there's none it tries to find
   * a suitable default prioritising list or table reports. It will return some report
   * unless the app has none.
   * @returns A report or null if no suitable report found.
   */
  public getDestinationReport(): Report {
    if (!this.Reports?.length) {
      return null;
    } else {
      return this.getLastAccessedReport() ??
        this.getDefaultReport() ??
        this.getFirstListReport() ??
        this.Reports.find((rep) => rep.Type === Enums.ReportTypes.Table) ??
        this.Reports[0];
    }
  }

  /**
   * Check app visibiliy.  If restricted, the user must have at least one visibility record for a
   * team the user is a member of, or a personal visiblity, or suitable perimssion for a settings app
   */
  public isAppVisibleToUser(user: User): boolean {

    if (!user) {
      return false;
    }

    // todo can now move this to suitable classes, stop testing type
    if (this.IsSettingsApp) {
      return this.visible;
    } else {
      // Visible if no TeamAppVisibilities defined, or user or team appears in TeamAppVisibilities
      return !this.TeamAppVisibilities || !!this.TeamAppVisibilities.filter(vis => vis.Visibility !== VisiblityFlag.Hide)
        .find(vis => vis.UserId === user.Id || user.TeamIds?.find(teamId => vis.TeamId === teamId));
    }
  }

  /**
   * Check report visibiliy.  If restricted, the user must have at least one visibility record for a
   * team the user is a member of, or a personal visiblity.
   */
  public isReportVisibleToUser(user: User, report: Report): boolean {
    if (report.IsHidden) {
      return false;
    } else if (report.TeamAppVisibilities) {
      return !!report.TeamAppVisibilities.filter(vis => vis.Visibility !== VisiblityFlag.Hide)
        .find(vis => vis.UserId === user.Id || user.TeamIds?.find(teamId => vis.TeamId === teamId));
    } else {

      return true;    // no access rules so visible
    }
  }

  /** Return true if app is visible (e.g. in springboard) to the current user */
  public get visible() {
    // Most apps are only loaded if the user has permissions to see them so default to true
    return true;
  }

  public get permitted() {
    // By default allow access if visible in UI.
    // Apps should override if they don't follow this pattern.
    return this.visible;
  }

  /**
   * Return sorted visible Forms for current user
   * Hidden will either be HiddenOnCreate when forNewRecord true
   * OR when formRule match based on record values
   * @param forNewRecord
   * @param record
   */
  public async getVisibleForms(record: Record, forNewRecord: boolean): Promise<Array<Form>> {
    const forms = this.Forms || [];
    const user = tryGetCurrentUser();

    return this.formsVisibleInContext(forms, forNewRecord)
      .filter(form => this.isFormVisibleByRule(user, form, record))
      .filter(form => this.isFormAllowed(user, form))
      .sort((form1, form2) => form1.DisplayOrder - form2.DisplayOrder || stringCompare(form1.Title, form2.Title));
  }

  private formsVisibleInContext(forms: Array<Form>, forNewRecord: boolean) {
    return forNewRecord ?
      forms.filter((f) => !f.HiddenOnCreate) :
      forms.filter((f) => !f.HiddenOnUpdate);
  }

  private isFormAllowed(user: User, form: Form) {
    if ((form?.Permissions && user.hasPermission(form.Permissions.map(f => f.Code)) || !form?.Permissions)) {
      return true;
    }

    return false;
  }

  public isFormVisibleByRule(user: User, form: Form, record: Record): boolean {
    const recordForQuery = { ...record } as any;  // matchRule mutates
    const rules = form.Rules?.filter(r => !r.TargetFieldIdentifier && !r.TargetTemplateIdentifier);
    if (rules?.length > 0) {
      const res = rules.filter(rule => this.matchRule(user, recordForQuery, recordForQuery._new, rule));
      return res.length === 0; // If a match is found then we need to hide the form.
    } else {
      return true;
    }
  }

  public formHasValidationErrors(record: Record, form: Form) {

    if (record._errs?.length > 0) {
      const templateWithErrs = form.FormTemplates.find((templateRef) => {
        const template = this.Templates.find(t => t.Identifier === templateRef.TemplateIdentifier);
        return template.Layout.find((layout) => record._errs.find(e => e.fieldId === layout.FieldIdentifier));
      });

      return !!templateWithErrs;
    }

    return false;
  }

  /**
   * Match a visibility rule.  If the rule matches the item (form, template etc.) is hidden,
   *
   * @param user
   * @param app
   * @param record
   * @param isNew
   * @param rule
   * @returns true if the rule is matched
   */
  public matchRule(user: User, record: Record, isNew: boolean, rule: FormRule) {

    let match = true;

    if (!rule) return false;

    if (rule.FilterRule?.length > 0) {
      // Grid field data is stored in an array on the record.
      // We need to add the grid cell fields data to the record for query object.
      // todo I don't think this is needed, ApplicationValueLookup should do the work
      // review and remove if so. Then we don't need to copy the record before calling
      const gridCellFields = this.AppFields ? this.AppFields.filter(field => field instanceof GridCellField) : [];
      gridCellFields.forEach(gcf => {
        record[`${gcf.Identifier}`] = gcf.getRecordValue(record);
      });

      const filter = new OdataQueryFilter(rule.FilterRule, new ApplicationValueLookup(this));
      if (filter.isValidQuery) {
        match = filter.isMatch(record);
      }
    }

    if (match && rule.HiddenForTeams?.length > 0) {
      if (!user) {
        throw new Error('HiddenForTeams rule needs a current user');
      }
      match = rule.HiddenForTeams.filter(tId => user.TeamIds.find(utId => utId === tId)).length > 0;
    }

    if (match) {
      // If RecordIsNew is specified, the match only applies if the
      // new/existing flag is the same
      if (rule.RecordIsNew !== undefined) {
        return rule.RecordIsNew === isNew;
      }
    }

    return match;
  }

  /**
   * Return indication of supported app behaviours
   */
  public get capabilities(): AppCapabilities {
    return {
      canCreate: !this.IsSingletonApp,
      canEdit: !this.IsSingletonApp,
      canView: !this.IsSingletonApp,
      canSort: true,
      canGroup: true,
    };
  }

  /**
   * Return selection list options for a field if provided by the application
   * @param fieldId Id of selection field
   * @returns selection options, or null if not provided by the app
   */
  public selectionListOptions(_fieldId: string): Array<SelectListOption> {
    return null;
  }

  /**
   * Get the default storage mode for app
   *
   * @param tenant site tenant to access app tweaks
   */
  public defaultStorageMode(tenant: string) {
    return undefined;
  }

  public getLogicBlock(id: LogicBlockIdentifer) {
    return this.LogicBlocks?.find(block => block.Identifier === id);
  }

  /** Store any upgrade information needed when app config changes */
  public async storeUpgradeInformation(newAppConfig: App) {
    // nop - override in implementations as needed
  }

  /** Synchronize app data from the server to local storage */
  public async synchronizeAppData() {
    // implement in derived classes
  }

  /**
   * Add toolbar menu items
   * Menu items can be specified in config (which we'll do for settings apps) but unless and
   * until they are added to standard app config we do them in code.
   * */
  protected addMenuItems(items: Array<IToolbarMenuItem>, actions: Array<ToolbarAction>, scope: string | number, context: IPerformContext) {
    const user = tryGetCurrentUser();
    items.filter(item => item.Scope.includes(scope))
      .filter(item => this.isMenuItemAvailable(item, user, context))
      .sort((a, b) => ((a.DisplayOrder ?? 0) - (b.DisplayOrder ?? 0)) || stringCompare(a.ActionField?.Label, b.ActionField?.Label))
      .forEach(item => {
        const field = this.createConcreteField(this, item.ActionField);
        if (field) {
          field.attachModel(context.appModel);
          const icon = item.Icon ?? field.defaultIcon();
          actions.push(new ToolbarAction(item.ActionField.Label, icon as IconName, async () => {
            await field.perform(context);
          }));
        }
      });
  }

  /** Check whether a toolbar menu item is available in the current context */
  protected isMenuItemAvailable(item: IToolbarMenuItem, user: User, context: IPerformContext) {

    // If Archived specified, only if matches current state
    if (item.Archived !== undefined) {
      if (context.record) {
        // looking at record - use archive menu if record is archived
        if (item.Archived !== context.record.IsArchived) {
          return false;
        }
      } else {
        // looking at report etc - use archive menu if in show archive mode
        if (item.Archived !== context.appModel.globalModel.archived.value) {
          return false;
        }
      }
    }

    // If Editable specified as true, must have a record with editable accecss
    if (item.Editable === true && !context.record?.EditableAccessForUser) {
      return false;
    }

    // If New specified, must have a record with matching state
    if (item.New !== undefined && item.New !== (context.record?._new ?? false)) {
      return false;
    }

    // If Parent specified, must have child apps or not
    if (item.Parent !== undefined && item.Parent !== context.appModel.app.value.ChildAppsIdentifiers?.length > 0) {
      return false;
    }

    if (item.StorageMode !== undefined) {
      if (this.storageMode !== item.StorageMode) {
        return false;
      }
    }

    if (item.Offline !== undefined) {
      if (context.reportModel) {
        if (this.isOfflineReport(context.reportModel.report.value?.Identifier) !== item.Offline) {
          return false;
        }
      } else {
        if (item.Offline !== context.appModel.app.value.isOfflineApp) {
          return false;
        }
      }
    }

    if (item.ChildContext !== undefined) {
      const isChild = (!!context.reportModel.hierarchy.value) ?? (!!context.recordModel.hierarchy.value);
      if (item.ChildContext !== isChild) {
        return false;
      }
    }

    // If has permissions, must match at least one
    if (item.Permissions?.length) {
      // must be logged in
      if (!user) {
        return false;
      }

      if (item.Permissions.findIndex(perm => user.Permissions?.map(p => p.Code).includes(perm)) < 0) {
        return false;
      }
    }

    return true;
  }

  /** 
   * Create a data index for this app
   * @returns an index or null if not supported
   */
  public async getIndex(reportIdentifier: string, options: IGetIndexOptions): Promise<IndexedAppData> {
    return null;
  }

  /**
   * Create a filter to perform a search.
   * Concrete app classes should override to implement their search rules.
   * @param term    Search string
   * @returns       A configured filter or null to skip search
   */
  public createSearchFilter(term: string): ReportFilter {
    return null;
  }

  /** Is this app enabled for advanced filtering */
  public get isFilterAdvanced() {
    // Advanced filtering enabled if any advanced fields specified
    const ids = this.FilterFieldIdentifiers ?? this.AdvancedFilterFieldIdentifiers;
    return ids?.length > 0;
  }
}

export interface AppCapabilities {
  canCreate: boolean;
  canEdit: boolean;
  canView: boolean;
  canSort: boolean;
  canGroup: boolean;
  noArchived?: boolean;
  noSelectAll?: boolean;

  impliedPermissions?: Array<Permission>;
}

export interface ToolbarContext {
  /** Kind of page being displayed */
  zone: string;

  app: Application;

  /** Current report if displaying a report page */
  report?: Report;

  /** Current record if displaying a single record page (add/update) */
  record?: Record;

  /** True if showing as a child app */
  isChildApp?: boolean;

  /** True if displaying archived data.  Overrides record archived flag if specified */
  isArchived?: boolean;

  reportController?: ReportController;

  recordUpdateController?: RecordUpdateController;

  generalController?: IGeneralController;
}
