import { StorageServiceBase, DatabaseContextService } from '@softools/softools-core';

export class PendingBaseService<T = any> extends StorageServiceBase<T, string> {

  constructor(dbName: string, dbContext: DatabaseContextService<T>) {
    super(dbName, dbContext);
  }

  public override async get(key: string): Promise<T> {
    return super.get(key);
  }

  public async put(key: string, value: T): Promise<T> {
    await this.save(key, value);
    return value;
  }

  public async remove(key: string): Promise<void> {
    await this.delete(key);
  }

  public override async keys(): Promise<Array<string>> {
    return super.keys();
  }
}
