import { Injectable } from '@angular/core';
import { AppDataRepository, AppDataStorageService, HeaderSummaryExpression, IExpression, IndexedAppData, QueryParams, RecordId, Report, SummaryExpression } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { StorageMode } from 'app/types/enums';
import { AppField } from 'app/types/fields/app-field';
import { removeBrackets } from 'app/_constants';
import { DataIndexService2 as DataIndexService } from '../indexes/data-index-service';

@Injectable({
  providedIn: 'root'
})
export class SummaryExpressionsService {

  constructor(
    private appDataService: AppDataStorageService,
    private appDataRepository: AppDataRepository,
    private dataIndexService: DataIndexService
  ) { }

  public readonly allowedExpressions = ['avg', 'sum', 'min', 'max'];

  /**
   * Get the expression data for a report.  The overall totals are always present, and expressions for the current
   * groups are addded so it must be regenerated when the view changes.
   */
  public async getExpressions(app: Application, report: Report, groupFieldId: string, groupStartIds: Array<RecordId>, hierarchy?: string) {
    const expressions: Array<IExpression> = [];

    if (report?.ListReportFields) {
      report.ListReportFields.filter((f) => this.allowedExpressions.some((e) => e === f.SummaryExpression)).forEach((field) => {
        const splitFieldIdentifier = field.FieldIdentifier.split('_');
        const appField = app.getField(splitFieldIdentifier[0]);
        if (appField) {
          expressions.push({
            appIdentifier: app.Identifier,
            hierarchy: hierarchy,
            summaryExpression: field.SummaryExpression,
            fieldIdentifier: field.FieldIdentifier,
            gridDataSetIndex: appField.GridDataSets?.findIndex((g) => g.Identifier === splitFieldIdentifier[1]),
            gridSubFieldIndex: appField.SubFieldsIdentifiers?.findIndex((c) => c.Identifier.split('_')[1] === splitFieldIdentifier[2]),
          });
        }
      });
    }

    const groupHeaderIds = groupStartIds;
    if (groupHeaderIds.length > 0) {
      const fields = report.ListReportFields?.filter((f) =>
        this.allowedExpressions.some((e) => e === f.SummaryExpression)
      );

      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];

          for (let j = 0; j < groupHeaderIds.length; j++) {
            const recordId = groupHeaderIds[j];
            const groupFieldIdentifier = removeBrackets(groupFieldId);
            const record = await this.appDataService.getRecordByIdAsync(recordId);
            const appField = app.getField(groupFieldIdentifier);
            const gridField = app.getField(field.FieldIdentifier.split('_')[0]);
            if (appField) {
              expressions.push({
                appIdentifier: app.Identifier,
                summaryExpression: field.SummaryExpression,
                fieldIdentifier: field.FieldIdentifier,
                hierarchy: hierarchy,
                groupFieldIdentifier: groupFieldIdentifier,
                groupValue: appField.getRecordValue(record),
                gridDataSetIndex: gridField.GridDataSets?.findIndex((g) => g.Identifier === field.FieldIdentifier.split('_')[1]),
                gridSubFieldIndex: gridField.GridDataSets?.map((g) =>
                  g.GridCellIdentifiers.findIndex((c) => c.DataCellIdentifier === field.FieldIdentifier)
                ).find(val => val > -1),
              });
            }
          }
        }
      }
    }

    return expressions;
  }

  /**
   * Get expression results.  This is asynchronous and returns a promise that completes when the background
   * thread has completed its calculation.
   *
   * @param appIdentifier
   * @param index
   * @param expressions
   */
  public async calculate(app: Application, index: IndexedAppData, expressions: Array<SummaryExpression>): Promise<Array<HeaderSummaryExpression>> {

    const recordIds = await index.getRecordIds(0);

    // We only want one instance of the summary expression calc running
    // It could be an expensice operations
    // When executing call terminate first
    const worker = new Worker(new URL('../../summary-expression-calc.worker', import.meta.url), {
      type: 'module',
    });

    const promise = new Promise<Array<HeaderSummaryExpression>>((resolve) => {

      worker.onmessage = async ({ data }) => {
        worker.terminate();
        resolve(data);
      };

      worker.postMessage({
        recordIds,
        appIdentifier: app.Identifier,
        expressions: expressions,
      });
    });

    return promise;
  }

  /**
   * Get aggregrate expression results for an aggregate field.
   * @param app     Application
   * @param field   Aggregation field
   * @returns       numeric result
   */
  public async calculateForAggregateField(app: Application, field: AppField) {

    if (!field.AggregateConfig) {
      throw new Error(`Invalid field for aggregataion ${app.Identifier} ${field.Identifier}`);
    }

    const query: QueryParams = field.AggregateConfig?.Filter ?
      { $filter: field.AggregateConfig.Filter } :
      {};

    const expression = {
      appIdentifier: app.Identifier,
      summaryExpression: field.AggregateConfig.SummaryExpression,
      fieldIdentifier: field.AggregateConfig.ReferenceFieldIdentifier
    };

    if (app.storageMode === StorageMode.Offline) {
      const index = await this.dataIndexService.getIndex(app, query, null);
      await index.indexAll();

      const result = await this.calculate(app, index, [expression]);
      const value = result[0].result;
      if (value === null && expression.summaryExpression === 'count') {
        return 0;
      }

      return result[0].result || 0;
    } else {
      const result = await this.appDataRepository.calculateAggregateAsync(expression.appIdentifier, field.Identifier, query);
      return result;
    }
  }
}
