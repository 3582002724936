
/** A row in a tracked collection */
export interface TrackedRow { Key: string; }

export abstract class TrackChange {
  public added: any;
  public removed: any;

  public static copy(src: any) { }

  public merge(other: TrackChange) { }

}

/**
 * Describes changes that can be made to a multi-row field.
 *
 * The field value must be an array of records that have a unique Key element as described by the
 * @see TrackedRow interface.  Other than the key eache row is treated as a property bag.
 */
export class TrackChangeList extends TrackChange {

  changes = {};

  /** Added rows */
  public override added: Array<TrackedRow> = [];

  /** Removed row ids */
  public override removed: Array<string> = [];


  public static override copy(src: any): TrackChangeList {
    const list = new TrackChangeList();
    list.changes = src.changes;
    list.added = src.added;
    list.removed = src.removed;
    list.keyField = src.keyField;
    return list;
  }

  /**
   * Construct a tracked changes list
   *
   * @param keyField
   *  The id of the field used to distringuish child records.
   *  The default (Key) is used for standard app data records, but this may
   *  differ when the change refers to another  type.
   */
  public constructor(public keyField: string = 'Key') {
    super();
  }

  public override merge(other: TrackChangeList) {

    other.added.forEach(row => {
      this.addAdditionalRow(row);
    });

    other.removed.forEach(key => {
      this.addRemovalKey(key);
    });

    Object.getOwnPropertyNames(other.changes).forEach(name => {
      const change = other.changes[name];
      this.addChange(name, change);
    });
  }

  public addChange(rowKey: string, change: any): TrackChangeList {

    // Use == because key may be numeric (e.g. site properties)
    /* eslint-disable-next-line eqeqeq */
    const added = this.added.findIndex(r => r[this.keyField] == rowKey);
    if (added >= 0) {
      // Row is new so merge into that
      this.added[added] = { ...this.added[added], ...change };
    } else if (this.changes[rowKey]) {
      // Already a change for this row, so merge favouring new values
      this.changes[rowKey] = { ...this.changes[rowKey], ...change };
    } else {
      // New change, just store
      this.changes[rowKey] = change;
    }

    return this;
  }


  public addAdditionalRow(row: TrackedRow): TrackChangeList {

    const existing = this.added.findIndex(r => r[this.keyField] === row[this.keyField]);
    if (existing >= 0) {
      // Alreay have a row with this id so merge them
      this.added[existing] = { ...this.added[existing], ...row };
    } else {
      // new row, add to collection
      this.added.push(row);
    }

    return this;
  }

  public addRemovalKey(rowKey: string): TrackChangeList {

    const additional = this.added.findIndex(r => r[this.keyField] === rowKey);
    if (additional >= 0) {
      // Row is newly added so remove it
      this.added = this.added.filter(r => r[this.keyField] !== rowKey);
    } else {
      // Just add to the collection, don't worry about duplicates
      // until we apply them
      this.removed.push(rowKey);
    }

    return this;
  }

  public update(field: any): any {

    if (!field) {
      field = [];
    }

    if (field instanceof Array) {

      // Update added/removed fields first so any changes can apply to them
      this.added.forEach(addition => {
        if (!field.find(f => f[this.keyField] === addition[this.keyField])) {
          field.push(addition);
        }
      });

      this.removed.forEach(removal => {
        field = field.filter(f => removal !== f[this.keyField]);
      });

      Object.getOwnPropertyNames(this.changes).forEach(name => {
        const change = this.changes[name];
        // Use == because key may be numeric (e.g. site properties)
        /* eslint-disable-next-line eqeqeq */
        const entry = field.find(f => f[this.keyField] === name);
        if (change && entry) {
          Object.getOwnPropertyNames(change).forEach(item => {
            entry[item] = change[item];
          });
        }
      });

      return field;
    }
  }

}

/**
 * Change list format for multi-selection selection fields
 */
export class SelectionListChange extends TrackChange {
  public override added: { Value: string }[] = [];
  public override removed: { Value: string }[] = [];

  constructor() {
    super();
  }

  public static override copy(src: any) {
    const selection = new SelectionListChange();
    selection.added = src.added;
    selection.removed = src.removed;
    return selection;
  }

  public override merge(change: SelectionListChange) {
    change.added.forEach(addedValue => {
      if (this.removed.find(removedValue => removedValue.Value === addedValue.Value)) {
        this.removed = this.removed.filter(removedValue => removedValue.Value !== addedValue.Value);
      } else {
        this.added = [...this.added, ...change.added];
      }
    });

    change.removed.forEach(removedValue => {
      if (this.added.find(addedValue => addedValue.Value === removedValue.Value)) {
        this.added = this.added.filter(addedValue => addedValue.Value !== removedValue.Value);
      } else {
        this.removed = [...this.removed, ...change.removed];
      }
    });
  }
}
