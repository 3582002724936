<div id="attachment-container" class="attachment-container card panel-container h-100"
  (click)="backgroundClicked($event)">
  <ng-container *ngIf="isOnline; else isOffline">
    <div id="header" class="header card-header d-flex text-center">
      <div class="flex-grow-1" i18n="@@Attachments">Attachments</div>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeClicked()"></button>
    </div>

    <!-- Loading indicator shown when needed -->
    <div id="load-section" class="items card-body" *ngIf="model.loading.busy$ | async">
      <sof-spinner-icon [size]="'4x'" style="margin-right: 1rem; margin-left: 2rem"></sof-spinner-icon>
      <span i18n="Generic loading message@@Loading">Loading ...</span>
    </div>

    <!-- Normal content -->
    <div *ngIf="!(model.loading.busy$ | async)" #comments id="main-content" class="items">
      <div class="h-100">
        <!-- Container for comment cards, scrolls if needed -->
        <div id="item-container" class="list-group list-group-flush">
          <!-- Expansion panel shown for each comment/attachment -->
          <div *ngFor="let item of content; let count = index" class="attachment-panel list-group-item py-4">
            <div class="d-flex st-text-theme-primary">
              <fa-icon
                [icon]="['fal', getAttachmentIconName(item.MIMEType)]"
                class="att-icon st-text-theme-primary fa-lg icon expander-header"
              ></fa-icon>
              <div class="small flex-grow-1">{{ item.Owner }}</div>
              <div class="small me-2">
                {{ item.CreatedDate | date: 'medium' }}
              </div>
              <fa-icon
                [icon]="['fal', 'trash']"
                size="1x"
                class="delete-comment pointer"
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin"
                (click)="delete(item, trigger)"
              ></fa-icon>
            </div>

            <div id="file-content">
              <div class="my-4" style="display: grid; grid-template-columns: auto 1fr auto; gap: 1rem">
                <div i18n>Name</div>
                <ng-template #nameTemplate>
                  <div>{{ item.Title || item.Name }}</div>
                  <sof-edit-icon
                    class="fa-sm pointer st-text-theme-primary d-flex justify-content-end"
                    (click)="editTitleClicked(count)"
                  ></sof-edit-icon>
                </ng-template>

                <ng-container *ngIf="editId === count && editTitle; else nameTemplate">
                  <div>
                    <mat-form-field class="w-100">
                      <input matInput [(ngModel)]="text" autofocus />
                    </mat-form-field>
                  </div>
                  <div class="d-flex justify-content-end">
                    <fa-icon
                      *ngIf="editId === count && editTitle"
                      [icon]="['fal', 'check-circle']"
                      class="fa-sm pointer st-text-theme-primary"
                      (click)="patchFile()"
                    ></fa-icon>
                  </div>
                </ng-container>

                <div i18n>Description</div>
                <ng-template #descTemplate>
                  <div class="description-display">{{ item.Description }}</div>
                  <sof-edit-icon
                    class="fa-sm pointer st-text-theme-primary d-flex justify-content-end"
                    (click)="editDescriptionClicked(count)"
                  ></sof-edit-icon>
                </ng-template>

                <ng-container *ngIf="editId === count && editDesc; else descTemplate">
                  <div>
                    <mat-form-field class="w-100">
                      <textarea
                        matInput
                        [(ngModel)]="text"
                        autofocus
                        [cdkTextareaAutosize]="true"
                        #autosize="cdkTextareaAutosize"
                      ></textarea>
                    </mat-form-field>
                  </div>
                  <div class="d-flex justify-content-end">
                    <fa-icon [icon]="['fal', 'check-circle']" class="fa-sm pointer st-text-theme-primary" (click)="patchFile()"></fa-icon>
                  </div>
                </ng-container>

                <div i18n>File Size</div>
                <div>
                  {{ getFileSize(item.Bytes) }}
                </div>
                <div></div>
              </div>
            </div>
            <div>
              <button
                *ngIf="isPreviewable(item)"
                class="primary me-2"
                title="Preview"
                i18n-title="@@Preview"
                (click)="previewClickHandler(item)"
              >
                <fa-icon [icon]="['fal', 'eye']" size="1x" class="me-1"></fa-icon> <span i18n>View</span>
              </button>

              <button class="primary" *ngIf="editId !== count || !editTitle" (click)="downloadFileClicked($event, item)">
                <fa-icon [icon]="['fal', 'download']" size="1x" class="me-1"></fa-icon> <span i18n>Download</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="controls card-body border-top">
      <app-file-uploader i18n-dragText dragText="Drag files here to upload as attachment" (fileOutput)="save($event)"></app-file-uploader>
    </div>
  </ng-container>
</div>

<ng-template #isOffline>
  <div class="align-middle text-center">
    <h4 i18n>You are offline</h4>
    <fa-icon [icon]="['fal', 'wifi-slash']"></fa-icon>
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="deleteOverlayTrigger"
  [cdkConnectedOverlayOpen]="model?.isConfirmVisible.$ | async"
  (backdropClick)="cancelDelete()"
  [cdkConnectedOverlayHasBackdrop]="true"
>
  <app-confirm-delete id="confirm-section" (confirmDelete)="confirmDelete()" (cancelDelete)="cancelDelete()"></app-confirm-delete>
</ng-template>
