import { PermissionEnums, Enums } from '@softools/softools-core';
import { IToolbarMenuItem } from '../application';
import { StorageMode } from '../enums';

const recordReports = [Enums.ReportTypes.List, Enums.ReportTypes.Table];

const allReports = [
  Enums.ReportTypes.List,
  Enums.ReportTypes.Matrix,
  Enums.ReportTypes.Chart,
  Enums.ReportTypes.Dashboard,
  Enums.ReportTypes.Table,
  Enums.ReportTypes.Card,
];

// Cards don't support selection so must exclude some items for now
const allReportsExceptCards = [
  Enums.ReportTypes.List,
  Enums.ReportTypes.Matrix,
  Enums.ReportTypes.Chart,
  Enums.ReportTypes.Dashboard,
  Enums.ReportTypes.Table,
];

export class StandardAppMenus {

  private static archiveField = {
    DisplayFormatted: false,
    ExcludeFromTemplateCopy: false,
    IncludeInSearch: false,
    Identifier: 'Menu:Archive',
    Label: 'Archive',
    SystemLabel: 'Archive',
    Type: Enums.FieldType.ImageActionButton,
    IsEditable: true,
    IsReadOnly: false,
    ImageActionButtonSize: 1,
    ImageActionButtonType: Enums.ImageActionButtonType.Archive
  };

  private static unArchiveField = {
    DisplayFormatted: false,
    ExcludeFromTemplateCopy: false,
    IncludeInSearch: false,
    Identifier: 'Menu:Unarchive',
    Label: 'Unarchive',
    SystemLabel: 'Unarchive',
    Type: Enums.FieldType.ImageActionButton,
    IsEditable: true,
    IsReadOnly: false,
    ImageActionButtonSize: 1,
    ImageActionButtonType: Enums.ImageActionButtonType.Unarchive
  };


  public static Menu: Array<IToolbarMenuItem> = [
    {
      DisplayOrder: 200,
      Scope: ['record'],
      Permissions: [PermissionEnums.Records.All, PermissionEnums.Records.Copy],
      Archived: false,
      Editable: true,
      New: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:CopyRecord',
        Label: 'Copy',
        SystemLabel: 'Copy',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Copy
      }
    },

    {
      DisplayOrder: 0,
      Scope: allReports,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.ShowArchived],
      Archived: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:ShowArchived',
        Label: 'ShowArchived',
        SystemLabel: 'Show Archived',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.ShowArchived
      }
    },
    {
      DisplayOrder: 0,
      Scope: allReports,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.ShowArchived],
      Archived: true,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:HideArchived',
        Label: 'HideArchived',
        SystemLabel: 'Hide Archived',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.HideArchived
      }
    },
    {
      DisplayOrder: 100,
      Scope: ['record'],
      Permissions: [PermissionEnums.Records.All, PermissionEnums.Records.Archive],
      Archived: true,
      ActionField: StandardAppMenus.unArchiveField
    },
    {
      DisplayOrder: 100,
      Scope: ['record'],
      Permissions: [PermissionEnums.Records.All, PermissionEnums.Records.Archive],
      Archived: false,
      New: false,
      ActionField: StandardAppMenus.archiveField
    },
    {
      DisplayOrder: 100,
      Scope: allReportsExceptCards,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Archive],
      Archived: true,
      ActionField: StandardAppMenus.unArchiveField
    },
    {
      DisplayOrder: 100,
      Scope: allReportsExceptCards,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Archive],
      Archived: false,
      New: false,
      ActionField: StandardAppMenus.archiveField
    },
    {
      DisplayOrder: 20000,
      Scope: ['record'],
      Permissions: [PermissionEnums.Records.All, PermissionEnums.Records.Delete],
      Archived: true,
      Editable: true,
      New: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:DeleteRecord',
        Label: 'Delete',
        SystemLabel: 'Delete',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Delete
      }
    },
    {
      DisplayOrder: 20000,
      Scope: recordReports,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Delete],
      Archived: true,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:DeleteReportRecords',
        Label: 'Delete',
        SystemLabel: 'Delete',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Delete
      }
    },

    {
      DisplayOrder: 400,
      Scope: ['record'],
      Permissions: [PermissionEnums.Records.All, PermissionEnums.Records.Security],
      Archived: false,
      Editable: true,
      New: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:Security',
        Label: 'Security',
        SystemLabel: 'Security',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Security
      }
    },

    {
      DisplayOrder: 400,
      Scope: recordReports,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Security],
      Archived: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:ReportSecurity',
        Label: 'Security',
        SystemLabel: 'Security',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Security
      }
    },

    {
      DisplayOrder: 300,
      Scope: ['record'],
      Permissions: [PermissionEnums.Records.All, PermissionEnums.Records.Export],
      New: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:Export',
        Label: 'Export',
        SystemLabel: 'Export',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Export
      }
    },


    {
      DisplayOrder: 100,
      Scope: allReports,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Link],
      Archived: false,
      ChildContext: true,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:Link',
        Label: 'Link',
        SystemLabel: 'Link',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Link
      }
    },
    {
      DisplayOrder: 120,
      Scope: allReportsExceptCards,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Link],
      Archived: false,
      ChildContext: true,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:Unlink',
        Label: 'Unlink',
        SystemLabel: 'Unlink',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Unlink
      }
    },

    {
      DisplayOrder: 200,
      Scope: allReports,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Import],
      Archived: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:ReportImport',
        Label: 'Import',
        SystemLabel: 'Import',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Import
      }
    },

    {
      DisplayOrder: 300,
      Scope: allReportsExceptCards,
      Permissions: [PermissionEnums.Reports.All, PermissionEnums.Reports.Export],
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:ReportExport',
        Label: 'Export',
        SystemLabel: 'Export',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Export
      }
    },


    {
      DisplayOrder: 500,
      Scope: ['record'],
      Permissions: [PermissionEnums.ActivityStream.All, PermissionEnums.ActivityStream.ViewHistory],
      New: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:History',
        Label: 'History',
        SystemLabel: 'History',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.History
      }
    },

    {
      DisplayOrder: 15000,
      Scope: ['record'],
      New: false,
      Offline: false,
      Archived: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:RefreshRecord',
        Label: 'Refresh',
        SystemLabel: 'Refresh',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Refresh
      }
    },

    {
      DisplayOrder: 15000,
      Scope: allReports,
      // Offline: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:RefreshReport',
        Label: 'Refresh',
        SystemLabel: 'Refresh',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.Refresh
      }
    },

    {
      DisplayOrder: 32000,
      Scope: ['apphome', 'record', ...allReports, Enums.ReportTypes.Card],
      Permissions: [PermissionEnums.AppStudio.All],
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:Sync',
        Label: 'ResyncApp',
        SystemLabel: 'Resync App',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.ResyncApp
      }
    },

    {
      DisplayOrder: 19500,
      Scope: ['record'],
      Parent: true,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:SyncChildData',
        Label: 'SyncChildData',
        SystemLabel: 'SyncChildData',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.SyncChildData
      }
    },

    {
      DisplayOrder: 2000,
      Scope: allReports,
      StorageMode: StorageMode.Selective,
      Offline: false,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:MakeOffline',
        Label: 'MakeOffline',
        SystemLabel: 'MakeOffline',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.MakeAvailableOffline
      }
    },

    {
      DisplayOrder: 2100,
      Scope: allReports,
      StorageMode: StorageMode.Selective,
      Offline: true,
      ActionField: {
        DisplayFormatted: false,
        ExcludeFromTemplateCopy: false,
        IncludeInSearch: false,
        Identifier: 'Menu:MakeOnline',
        Label: 'MakeOnline',
        SystemLabel: 'MakeOnline',
        Type: Enums.FieldType.ImageActionButton,
        IsEditable: true,
        IsReadOnly: false,
        ImageActionButtonSize: 1,
        ImageActionButtonType: Enums.ImageActionButtonType.MakeUnavailableOffline
      }
    },


  ];


}
