// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  enableTelemetry: true,
  logModelChanges: false,
  baseUrl: 'https://prerelease-api.on.softools.net',
  apiAudiance: 'https://prerelease-api.on.softools.net/api',
  raygun: 'Twa8XgdxtE7+Pz6NoYvTmQ==',
  googleMapsApiKey: 'AIzaSyDPmlCwg5g6BdyjvIAiu9vrI9ODac13_uU',
  auth0Domain: 'auth-prerelease.on.softools.net',
  auth0Cdn: 'https://cdn.eu.auth0.com',
  debugTenant: '',
  build: 'prerelease',
  /** Temporary flag - if present and true, all setting apps including work in progress are shown */
  showAllSettings: true,
  appStudioUrlPrefix: 'prerelease-',
};
