import { Injectable } from '@angular/core';
import { NotificationMessage } from '../types/interfaces/notificationmessage.interface';
import { NotificationsRepository } from '../repos';
import { OnlineStatusService } from './online-status-service';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, NOTIFICATIONS_STORE } from '../indexedDb';
import { lastValueFrom } from 'rxjs';
import { QueryParams } from '../types';

@Injectable({ providedIn: 'root' })
export class NotificationStorageService extends StorageServiceBase<NotificationMessage> {

  constructor(
    private onlineStatus: OnlineStatusService,
    public notificationsRepository: NotificationsRepository,
    dbContext: DatabaseContextService<NotificationMessage>) {
    super(NOTIFICATIONS_STORE, dbContext);
  }

  public async syncNotifications(retryPolicy: IRetryPolicy = null): Promise<Array<NotificationMessage>> {
    if (this.onlineStatus.isConnected) {
      // Use retry policy, the above check means we should be online at the start or we skip loading notifications
      // However we will retry if we go offline during the connection
      const policy = retryPolicy || NoRetryPolicy.instance;
      const backgroundTasks = await policy.execute(() => lastValueFrom(this.notificationsRepository.getBackgroundTasks()));

      await Promise.all(backgroundTasks.map(async (task: NotificationMessage) => {
        const existing = await this.get(task.Id);
        if (!existing) {
          await this.save(task.Id, task);
        } else {
          await this.save(task.Id, { ...task, IsRead: existing.IsRead });
        }
      }));

      return backgroundTasks;
    }

    return await this.getNotifications();
  }

  /**
   * Set the notification store to the most recent @param count messages,
   * marking them as read. The notifications are sorted by latest update
   * date with most recent first.
   * @param retryPolicy 
   * @returns 
   */
  public async syncNotificationsMarkRead(count = 100, retryPolicy: IRetryPolicy = null): Promise<Array<NotificationMessage>> {
    if (this.onlineStatus.isConnected) {
      // Use retry policy, the above check means we should be online at the start or we skip loading notifications
      // However we will retry if we go offline during the connection
      const policy = retryPolicy || NoRetryPolicy.instance;
      const query: QueryParams = { $top: count, $orderby: 'LastUpdated desc' };
      const backgroundTasks = await policy.execute(() => lastValueFrom(this.notificationsRepository.getBackgroundTasks(query)));

      await this.clear();

      await Promise.all(backgroundTasks.map(async (task: NotificationMessage) => {
        await this.save(task.Id, { ...task, IsRead: true });
      }));

      return backgroundTasks;
    }

    return await this.getNotifications();
  }


  public async addNotification(notification: NotificationMessage): Promise<NotificationMessage> {
    await this.save(notification.Id, notification);
    return notification;
  }

  public async addAllNotifications(notifications: Array<NotificationMessage>): Promise<Array<NotificationMessage>> {
    await this.removeAllNotifications();
    await Promise.all(notifications.map(async (notification: NotificationMessage) => {
      await this.save(notification.Id, notification);
    }));
    return notifications;
  }

  public async removeNotification(notification: NotificationMessage): Promise<boolean> {
    await lastValueFrom(this.notificationsRepository.remove(notification.Id));
    await this.delete(notification.Id);
    return true;
  }

  public async removeNotificationById(notificationId: string): Promise<boolean> {
    await lastValueFrom(this.notificationsRepository.remove(notificationId));
    await this.delete(notificationId);
    return true;
  }


  public async removeAllNotifications(): Promise<boolean> {
    const keys = await this.keys();
    await lastValueFrom(this.notificationsRepository.removeAll());
    await Promise.all(keys.map(async (key: string) => {
      await this.delete(key);
    }));
    return true;
  }

  public async getNotifications(): Promise<Array<NotificationMessage>> {
    const keys = await this.keys();
    const notifications = [] as Array<NotificationMessage>;
    await Promise.all(keys.map(async (key: string) => {
      notifications.push(await this.get(key));
    }));
    return notifications;
  }

  public async setReadStatusToAll(): Promise<void> {
    const keys = await this.keys();
    await Promise.all(keys.map(async (key: string) => {
      const notification = await this.get(key);
      if (!notification.IsRead) {
        await this.save(key, { ...notification, IsRead: true } as NotificationMessage);
      }
    }));
  }
}
