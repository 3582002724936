import { Injectable } from '@angular/core';
import { DatabaseContextService, PATCH_QUEUE_STORE, RecordId, logError, logMessage, TrackChange } from '@softools/softools-core';
import { AppIdentifier } from 'app/types/application';
import { RecordPatch, LegacyRecordPatch } from 'app/workspace.module/types/record-patch';
import { Subject } from 'rxjs';

/**
 * This service managers a post and patch queue.
 * Records are persisted into storage.  A memory cache is used for
 * synchrpnous access to allow fast, synchronous access.  The
 * @see cachePatches function must be called from a page guard
 * to initialise this cache.
 */
@Injectable({ providedIn: 'root' })
export class RecordQueueService {

  private lastUpdateTimeMs: number = Date.now();

  private patchCache: Map<RecordId, RecordPatch>;

  public patchQueued$ = new Subject<RecordPatch>();

  constructor(private db: DatabaseContextService<RecordPatch | LegacyRecordPatch>) {
    // setInterval(() => {
    //   console.warn('queue', this._queue);
    // }, 3000)
  }

  public async cachePatches() {
    // Load cache from storage (first time only)
    if (!this.patchCache) {
      this.patchCache = new Map<RecordId, RecordPatch>();
      const patches = await this.getPatchesFromStorage();
      patches.forEach(patch => {
        // create concrete type
        const merged = new RecordPatch(patch._id, patch.AppIdentifier, patch.Hierarchy);
        merged.merge(patch);
        this.patchCache.set(patch._id, merged);
      });

      // If the cache has become very large, log it.  This isn't really an error but we need to know.
      if (this.patchCache.size > 1000) {
        logMessage(`${this.patchCache.size} entries in cache`);
      }
    }
  }

  /**
   * Get a record patch from the queue
   * @param recordId Record Id
   */
  public getPatch(recordId: string): RecordPatch {
    this.checkCache();
    return this.patchCache.get(recordId);
  }

  public getPatchesForApp(appIdentifier: AppIdentifier): Array<RecordPatch> {
    const patches: Array<RecordPatch> = [];
    this.patchCache.forEach((patch) => {
      if (patch.AppIdentifier === appIdentifier) {
        patches.push(patch);
      }
    });
    return patches;
  }

  public getPatches(): Array<RecordPatch> {
    return Array.from(this.patchCache.values());
  }

  public clearPatches(): void {
    this.patchCache.clear();
    this.db.clear(PATCH_QUEUE_STORE).catch(error => logError(error, 'Failed to clear patch'));
  }

  /**
   * This will merge patches for the same record ID and then sync with the server
   *
   * @param recordPatch Record Patch object
   */
  public async addRecordPatch(recordPatch: RecordPatch): Promise<void> {

    this.lastUpdateTimeMs = Date.now();

    // todo could share a transaction but get needs to support it
    //    const txn = await this.db.transaction(PATCH_QUEUE_STORE, 'readwrite' );

    const existing = this.getPatch(recordPatch._id);
    if (!existing) {
      this.patchCache.set(recordPatch._id, recordPatch);
      await this.db.save(PATCH_QUEUE_STORE, recordPatch._id, recordPatch);
    } else {
      // Merge the patch
      // NB The value returned is a JSON object so we need to wrap in a concrete instance
      const patch = this.mapLegacyPatch(existing);
      const merged = new RecordPatch(recordPatch._id, recordPatch.AppIdentifier, recordPatch.Hierarchy);
      merged.merge(patch);
      merged.merge(recordPatch);
      merged.removeSupercededLocalChanges();
      this.patchCache.set(patch._id, merged);
      await this.db.save(PATCH_QUEUE_STORE, existing._id, merged);
    }

    this.patchQueued$.next(recordPatch);
  }

  /**
   * Store a patch to the queue.  Unlike @see addRecordPatch this replaces any existing
   * patch for the record rather than merging.
   * @param recordPatch
   */
  public async saveRecordPatchAsync(recordPatch: RecordPatch): Promise<void> {
    this.lastUpdateTimeMs = Date.now();
    this.patchCache.set(recordPatch._id, recordPatch);
    await this.db.save(PATCH_QUEUE_STORE, recordPatch._id, recordPatch);

    this.patchQueued$.next(recordPatch);
  }

  public async delete(recordId: RecordId): Promise<boolean> {
    this.patchCache.delete(recordId);
    return await this.db.delete(PATCH_QUEUE_STORE, recordId);
  }

  public getCount(): number {
    this.checkCache();
    return this.patchCache.size;
  }

  public getRecordIds(): Array<RecordId> {
    this.checkCache();
    return Array.from(this.patchCache.keys());
  }

  public async extract(recordId: string): Promise<RecordPatch> {
    const patch = this.mapLegacyPatch(await this.db.extract(PATCH_QUEUE_STORE, recordId));
    if (patch) {
      const merged = new RecordPatch(patch._id, patch.AppIdentifier, patch.Hierarchy);
      merged.merge(patch);
      return merged;
    }
    return null;
  }

  public async waitUntilQuiet(interval = 500) {
    // Limit number of waits to avoid risk of being locked forever
    for (let i = 0; i < 10; ++i) {
      const delta = Date.now() - this.lastUpdateTimeMs;
      if (delta > interval) {
        break;    // ready
      } else {
        await new Promise(resolve => setTimeout(resolve, delta));
      }
    }
  }

  /** Check whether the cache is running.  Not all pages enable it */
  public get isQueueActive() {
    return this.patchCache !== undefined;
  }

  private async getPatchesFromStorage(): Promise<Array<RecordPatch>> {
    return (await this.db.getAll(PATCH_QUEUE_STORE)).map(p => this.mapLegacyPatch(p));
  }

  private mapLegacyPatch(patch: RecordPatch | LegacyRecordPatch): RecordPatch {
    const tempPatch = (<LegacyRecordPatch>patch);
    if (tempPatch?.appIdentifier) {
      return { ...patch, AppIdentifier: tempPatch.appIdentifier, _new: tempPatch.newRecord, Hierarchy: tempPatch.hierarchy, localTrackedChanges: new Map<string, TrackChange>() } as RecordPatch;
    }

    return patch as RecordPatch;
  }

  /** Check guard has created cache */
  private checkCache() {
    // Check guard has created cache
    if (this.patchCache === undefined) {
      throw new Error('patch cache has not been initialised');
    }
  }

}
