import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Params } from '@angular/router';

// import * as rg4js from 'raygun4js';

import { tryGetCurrentUser, logError } from '@softools/softools-core';
import { AuthenticationService } from 'app/auth.module';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';
import { AuthorisationService } from 'app/auth.module/services/authorisation.service';
import { SyncStatusService } from 'app/services/sync-status.service';
import { ReLoginService } from 'app/services/login.service';
import { LOGIN_ROUTE } from 'app/_constants';
import { TenantService } from 'app/services/tenant.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appsService: AppService,
    private authorisationService: AuthorisationService,
    private syncStatus: SyncStatusService,
    private reLoginService: ReLoginService,
    private tenantService: TenantService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {

      if (this.authorisationService.isValidAuthToken()) {
        const currUser = tryGetCurrentUser();

        if (!currUser) {
          throw new Error('Auth guard - No user');
        }

        if (!currUser.Email) {
          throw new Error('Auth guard - Email address is missing');
        }

        if (environment.enableTelemetry) {
          // (<any>rg4js)('setUser', {
          //   identifier: currUser.Email,
          //   isAnonymous: false,
          //   email: currUser.Email
          // });
        }

        // logged in so return true
        return true;
      }

      // If app is flagged for anonymous access we're allowed to use it anyway
      if (this.isAppAvailableAnonymously(route.params)) {
        return true;
      }

      if (this.syncStatus.syncStatus === 'completed') {
        const tenantFromHostname = this.tenantService.tenant();
        await this.reLoginService.authorise(tenantFromHostname, state.url);
        return true;
      }

      // not logged in so redirect to login page with the return url
      const loginUrlTree = this.router.parseUrl(LOGIN_ROUTE);

      // dont add '/' as a return url, this looks odd in the browser.
      if (state.url !== '/') {
        loginUrlTree.queryParams = { returnUrl: state.url };
      }

      return loginUrlTree;
    } catch (error) {
      logError(error, 'Failed to activate auth guard');
      try {
        await this.authenticationService.logout();
      } catch (err) {
        logError(err, 'Failed to logout');
      } finally {
        return this.router.parseUrl(LOGIN_ROUTE);
      }
    }
  }

  private isAppAvailableAnonymously(routeParams: Params) {
    // Get app id from route
    const appIdentifier = routeParams['appIdentifier'];
    if (!appIdentifier) {
      return false;
    }

    return this.appsService.isAppAvailableAnonymously(appIdentifier);
  }
}
