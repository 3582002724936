import { Record, logError, App, Field } from '@softools/softools-core';
import { IUpgradeData } from 'app/services/upgrade-data-storage.service';
import { AppField } from './app-field';
import { GridCellField } from './grid-cell-field';

export class GridAppField extends AppField {

  public override validate(): void {
    // no operation - grid itself isn't validated just contained fields
  }

  public override isEqual(record1: Record, record2: Record, listRow?: number): boolean {

    const numDatasets = this.GridDataSets ? this.GridDataSets.length : 0;
    const numSubfields = this.SubFieldsIdentifiers ? this.SubFieldsIdentifiers.length : 0;
    for (let ds = 0; ds < numDatasets; ++ds) {
      for (let sf = 0; sf < numSubfields; ++sf) {
        const subId = this.SubFieldsIdentifiers[sf].Identifier;
        if (subId.startsWith(`${this.Identifier}_`)) {
          const subName = subId.slice(this.Identifier.length + 1);
          const name = `${this.Identifier}_${this.GridDataSets[ds].Identifier}_${subName}`;
          const cellField = this.application.getField(name) as GridCellField;
          if (!cellField.isEqual(record1, record2)) {
            return false;
          }
        }
      }
    }

    return true;
  }

  public override compactRecord(record: Record): any | null {

    // It is possible to get old data in the record as a reuslt of a field type change  (SOF-7725)
    // So clear out.  We don't assign immediately so only valid data gets stored
    delete record[this.Identifier];

    const numDatasets = this.GridDataSets ? this.GridDataSets.length : 0;
    const numSubfields = this.SubFieldsIdentifiers ? this.SubFieldsIdentifiers.length : 0;
    const gridData = new Array<Array<any>>(numDatasets);
    for (let ds = 0; ds < numDatasets; ++ds) {
      gridData[ds] = new Array<any>(numSubfields);
      for (let sf = 0; sf < numSubfields; ++sf) {
        // The sub field identifier is prefixed with the field id, so strip it off
        const subId = this.SubFieldsIdentifiers[sf].Identifier;
        if (subId.startsWith(`${this.Identifier}_`)) {
          const subName = subId.slice(this.Identifier.length + 1);
          const name = `${this.Identifier}_${this.GridDataSets[ds].Identifier}_${subName}`;
          const cellField = this.application.getField(name) as GridCellField;

          if (!cellField) {
            logError(`Subfield does not exist ${this.Identifier}_${this.GridDataSets[ds].Identifier}_${subName}`, `compactRecord`);
          }

          const value = cellField.compactCellRecord(record);
          if (value !== null) {
            gridData[ds][sf] = value;
            delete record[name];
          }
        }
      }
    }

    record[this.Identifier] = gridData;

    return gridData;
  }

  public override getUpgradeInformation(newAppConfig: App, upgradeData: IUpgradeData): boolean {
    const newFieldConfig = newAppConfig.Fields.find(f => f.Identifier === this.Identifier);
    if (newFieldConfig && this.configDiffers(newFieldConfig)) {
      // Minimal clone to store relevant parts of field config
      const field: Field = {
        Identifier: this.Identifier,
        DisplayFormatted: this.DisplayFormatted,
        ExcludeFromTemplateCopy: this.ExcludeFromTemplateCopy,
        IncludeInSearch: this.IncludeInSearch,
        IsEditable: this.IsEditable,
        IsReadOnly: this.IsReadOnly,
        Label: this.Label,
        SystemLabel: this.SystemLabel,
        Type: this.Type,

        GridDataSets: this.GridDataSets,
        SubFieldsIdentifiers: this.SubFieldsIdentifiers,
      };

      upgradeData.fields.push(field);
      return true;
    }

    return false;
  }

  public override upgradeRecord(record: Record, upgradeData: IUpgradeData) {
    const value: Array<Array<any>> = this.getInternalRecordValue(record);

    // Expand out all fieldss using the grid configuration from the old version
    const config = upgradeData.fields.find(f => f.Identifier === this.Identifier);
    if (config) {
      const numDatasets = config.GridDataSets?.length || 0;
      const numSubfields = config.SubFieldsIdentifiers.length || 0;
      for (let ds = 0; ds < numDatasets; ++ds) {
        for (let sf = 0; sf < numSubfields; ++sf) {
          const subId = config.SubFieldsIdentifiers[sf].Identifier;
          if (subId.startsWith(`${this.Identifier}_`)) {
            const subName = subId.slice(this.Identifier.length + 1);
            const name = `${this.Identifier}_${config.GridDataSets[ds].Identifier}_${subName}`;
            record[name] = value[ds][sf];
          }
        }
      }

      // Compact using current config
      this.compactRecord(record);
    }
  }

  /** Does the supplied field configuration differ significantly from this one? */
  private configDiffers(otherConfig: Field) {

    if (this.GridDataSets?.length ?? 0 !== otherConfig.GridDataSets?.length ?? 0) {
      return true;
    }

    if (this.SubFieldsIdentifiers?.length ?? 0 !== otherConfig.SubFieldsIdentifiers?.length ?? 0) {
      return true;
    }

    let changed = false;

    this.GridDataSets?.forEach((set, i) => {
      if (set.Identifier !== otherConfig.GridDataSets[i].Identifier) {
        changed = true;
      }
    });

    this.SubFieldsIdentifiers?.forEach((sub, i) => {
      if (sub.Identifier !== otherConfig.SubFieldsIdentifiers[i].Identifier) {
        changed = true;
      }
    });

    return changed;
  }

  public override showHeaderOnTemplate() {
    return false;
  }

}
