<div class="filter-edit-popup">
  <form (ngSubmit)="f.form.valid && accept()" #f="ngForm">
    <mat-card>
      <mat-card-content>
        <div>
          <mat-form-field *ngIf="filterEditorUi.isNewFilter">
            <input
              matInput
              #input
              [matAutocomplete]="auto"
              (keyup)="searchFields($event)"
              placeholder="Filter on Field ..."
              i18n-placeholder
            />

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="fieldChanged($event)" [classList]="'filter-autocomplete'">
              <mat-option [value]="''" i18n>None</mat-option>
              <mat-option *ngFor="let field of filteredFields | async" [value]="field.Identifier">
                <span>{{ field.Label }}</span>
                <small> {{ field.Identifier }}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-select [(value)]="selectedOperation" [disabled]="!field" placeholder="Filter Type" i18n-placeholder
            (selectionChange)="operationChanged($event)"
          >
            <mat-option [value]="noOperation" i18n=" None filter operation|WHen no filter operation is selected@@FilterOperationNone "
              >None</mat-option
            >

            <mat-option *ngIf="isEquality" [value]="equalOperation" i18n="Equals filter operation@@FilterOperationEq">Equal to</mat-option>
            <mat-option *ngIf="isEquality" [value]="notEqualOperation" i18n="Not equals filter operation@@FilterOperationNe"
              >Not equal to</mat-option
            >

            <mat-option
              *ngIf="isIdentity"
              [value]="equalOperation"
              i18n=" Is filter operation|Specifies same value when not numeric@@FilterOperationIs "
              >Is</mat-option
            >
            <mat-option
              *ngIf="isIdentity"
              [value]="notEqualOperation"
              i18n=" Is not filter operation|Specifies different value when not numeric@@FilterOperationIsNot "
              >Is Not</mat-option
            >

            <mat-option *ngIf="isNumeric" [value]="greaterOperation" i18n="Greater than filter operation@@FilterOperationGt"
              >Greater than</mat-option
            >
            <mat-option *ngIf="isNumeric" [value]="greaterEqualOperation" i18n="Greater than or equal filter operation@@FilterOperationGe"
              >Greater than or equal to</mat-option
            >
            <mat-option *ngIf="isNumeric" [value]="lessOperation" i18n="Less than filter operation@@FilterOperationLt"
              >Less than</mat-option
            >
            <mat-option *ngIf="isNumeric" [value]="lessEqualOperation" i18n="Less than or equal filter operation@@FilterOperationLe"
              >Less than or equal to</mat-option
            >

            <mat-option
              *ngIf="isTemporal"
              [value]="greaterOperation"
              i18n=" Later than filter operation|Comparison for dates and times@@FilterOperationLater "
              >Later than</mat-option
            >
            <mat-option
              *ngIf="isTemporal"
              [value]="greaterEqualOperation"
              i18n=" Later than or same filter operation|Comparison for dates and times@@FilterOperationLaterEq "
              >Later than or is</mat-option
            >
            <mat-option
              *ngIf="isTemporal"
              [value]="lessOperation"
              i18n=" Earlier than filter operation|Comparison for dates and times@@FilterOperationEarlier "
              >Earlier than</mat-option
            >
            <mat-option
              *ngIf="isTemporal"
              [value]="lessEqualOperation"
              i18n=" Earlier than or same filter operation|Comparison for dates and times@@FilterOperationEarlierEq "
              >Earlier than or is</mat-option
            >

            <mat-option *ngIf="isBinary" [value]="equalOperation" i18n="Checked filter operation@@FilterOperationCheck"
              >Is Checked</mat-option
            >
            <mat-option *ngIf="isBinary" [value]="notEqualOperation" i18n="Unchecked filter operation@@FilterOperationUncheck"
              >Is Unchecked</mat-option
            >

            <mat-option *ngIf="isTextual" [value]="startsWithOperation" i18n="Starts with filter operation@@FilterOperationStarts"
              >Starts with</mat-option
            >
            <mat-option *ngIf="isTextual" [value]="endsWithOperation" i18n="Ends with filter operation@@FilterOperationEnds"
              >Ends with</mat-option
            >
            <mat-option *ngIf="isTextual" [value]="substringOperation" i18n="Contains filter operation@@FilterOperationContains"
              >Contains</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div *ngIf="selectedOperation !== noOperation" class="mb-4">
          <ng-container *ngIf="field$|async as fld">
            <sof-filter-popup-field
              *ngIf="isFilterable(fld)"
              #editor
              [appModel]="filterModel.appModel"
              [application]="application"
              [fieldModel]="fld"
              [value]="value"
              [required]="isValidExpression"
              (onInputChanged)="valueChanged($event)"
              (onValueChanged)="valueChanged($event)"
            >
            </sof-filter-popup-field>

          </ng-container>
        </div>
        <ng-container *ngIf="showSortGroup && sortable">
          <div >
            <app-filter-asc-desc
              [selected]="selectedSort"
              [disabled]="!field"
              placeholder="Sort"
              (change)="selectedSort = $event; sortChanged()"
              i18n-placeholder="SortPrompt@@SortPrompt"
            >
            </app-filter-asc-desc>
          </div>
          <div *ngIf="showSortGroup">
            <app-filter-asc-desc
              [selected]="selectedGrouping"
              [disabled]="!field"
              placeholder="Grouping"
              (change)="groupOrderChanged($event)"
              i18n-placeholder="GroupValuesPrompt@@GroupPrompt"
            >
            </app-filter-asc-desc>
          </div>
        </ng-container>
      </mat-card-content>
      <mat-card-actions>
        <div class="d-flex justify-content-between">
          <button
            mat-icon-button
            type="submit"
            disableRipple="true"
            [disabled]="disable$|async"
            title="Accept updates (Enter)"
            i18n-title="@@AcceptUpdaatesTitle"
          >
            <sof-check-icon class="accept-icon"></sof-check-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            disableRipple="true"
            (click)="cancel()"
            title="Cancel Updates (Esc)"
            i18n-title="@@CancelUpdatesTitle"
            style="float: right;"
          >
            <sof-times-icon class="cancel-icon"></sof-times-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
