<ng-container *ngIf="forReport">
  <img #imageEl class="image-el image-for-report">
</ng-container>

<ng-container *ngIf="!forReport">
  
  <ng-container *ngIf="!editMode || !(enabled$|async)">
    <img #imageEl class="image-el img-fluid">
  </ng-container>

  <ng-container *ngIf="editMode && (enabled$|async)">
    <div #canvasContainerEl class="canvas-field-container">
      <ng-container *ngIf="!canvasMode">
        <img #imageEl class="image-el img-fluid">
      </ng-container>

      <ng-container *ngIf="canvasMode">
        <app-draw-canvas [id]="'canvas_' + identifier" class="canvas" (click)="isDirty = true" >
        </app-draw-canvas>
      </ng-container>      

      <div class="actions p-1">
        <a *ngIf="canvasMode" (click)="onAcceptClickHandler($event)" role="button" class="icon pointer">
          <fa-icon [icon]="['fal', 'save']" [border]="false" size="2x"></fa-icon>
        </a>
        <a *ngIf="!canvasMode" (click)="onDeleteClickHandler($event)" role="button" class="icon pointer">
          <fa-icon [icon]="['fal', 'trash']" [border]="false" size="2x"></fa-icon>
        </a>
      </div>
    </div>
  </ng-container>
</ng-container>