<div *ngIf="!expanded" class="collapsed d-flex my-1 px-2" (click)="expandBar($event)">
    <ng-container *ngTemplateOutlet="headertext"></ng-container>
    <p class="m-1">Click to view errors.</p>
</div>


<div *ngIf="expanded" class="expanded d-flex flex-column">
  <mat-card class="bar-back">
      <fa-icon [icon]="['fal', 'chevron-down']" class="pointer" style="position: absolute; right: 1rem; top: 1rem; font-size: 1.5rem;" (click)="collapseBar($event)"></fa-icon>
      <mat-card-header class="w-100">
        <mat-card-title>
            <ng-container *ngTemplateOutlet="headertext"></ng-container>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div>
            <ul>
              <ng-container *ngFor="let err of errors">
                <li *ngIf="err.error === 1"><span i18n>A value must be supplied for "{{fieldName(err)}}".</span>
                  <a href="#">
                    <fa-icon [icon]="['fal', 'search']"></fa-icon>
                    <span class="mx-1" i18n>Locate field</span>
                  </a>
                </li>
                <li *ngIf="err.error === 2"><span i18n>"{{fieldName(err)}}" must be greater than or equal to {{err.param}}.</span>
                  <a href="#">
                    <fa-icon [icon]="['fal', 'search']"></fa-icon>
                    <span class="mx-1" i18n>Locate field</span>
                  </a>
                </li>
                <li *ngIf="err.error === 3"><span i18n>"{{fieldName(err)}}" must be less than or equal to {{err.param}}.</span>
                  <a href="#">
                    <fa-icon [icon]="['fal', 'search']"></fa-icon>
                    <span class="mx-1" i18n>Locate field</span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
      </mat-card-content>
  </mat-card>
</div>

<ng-template #headertext>
  <div>
    <p class="m-1">
      <fa-icon [icon]="['fal', 'exclamation-triangle']" style="color: red"></fa-icon>
      <span *ngIf="errors.length > 1">
        This record has {{errors.length}} invalid field values. It will not be sent to the server until they are corrected
      </span>
      <span *ngIf="errors.length === 1">
        This record has an invalid field value. It will not be sent to the server until they are corrected
      </span>
    </p>
  </div>
</ng-template>