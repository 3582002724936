import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { Fields2Module } from '../fields2/fields2.module';
import { CardAppFieldComponent } from './card-app-field/card-app-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardHeaderAppFieldComponent } from './card-header-app-field/card-header-app-field.component';

@NgModule({
  declarations: [
    CardComponent,
    CardAppFieldComponent,
    CardHeaderAppFieldComponent
  ],
  imports: [
    CommonModule,
    Fields2Module,
    FontAwesomeModule
  ],
  exports: [
    CardComponent,
    CardAppFieldComponent,
    CardHeaderAppFieldComponent
  ]
})
export class CardModule { }
