import { Injectable } from '@angular/core';

const STORAGE_NAME = 'sync-status';

@Injectable({ providedIn: 'root' })
export class SyncStatusService {
  public get hasRun() {
    return localStorage.getItem(STORAGE_NAME) !== null;
  }

  public get syncStatus(): 'completed' | 'failed' | 'reset' | 'none' {
    return localStorage.getItem(STORAGE_NAME) === null ? 'none' : (localStorage.getItem(STORAGE_NAME) as 'completed' | 'reset');
  }

  public reset() {
    localStorage.setItem(STORAGE_NAME, 'reset');
  }

  public setStatus(status: 'completed' | 'failed') {
    localStorage.setItem(STORAGE_NAME, status);
  }
}
