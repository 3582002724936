import { Component, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { EditableFieldBase, FieldContext } from 'app/softoolsui.module/fields';
import { AppsService, ReportStorageService, ReportDataService, ChartData, QueryParams, logError, Record, RecordId, Report, logMessage } from '@softools/softools-core';
import { ChartConfigService } from 'app/services/chart/chart-config.service';
import { ChartDataService } from 'app/services/chart/data/chart-data.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AppField } from 'app/types/fields/app-field';

@Component({
  selector: 'app-inapp-chart-field',
  templateUrl: './inapp-chart-field.component.html',
  styleUrls: ['./inapp-chart-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InappChartFieldComponent extends EditableFieldBase implements OnInit, OnChanges, OnDestroy {
  public chartConfig;
  public records;

  public chartData$ = new BehaviorSubject<ChartData>(null);
  public isLoading$ = new BehaviorSubject(false);

  public chartType;
  public reportType;

  public chartContainerIdVal;

  public report: Report;

  public initialised = false;

  @Input() appIdentifier: string;
  @Input() override appIdentifiers: AppIdentifiers;
  @Input() formId = '';
  @Input() inAppChartReportIdentifier: string;
  @Input() recordId: string;
  @Input() forceRerenderOnResize = false;
  @Input() templateExpanded = true;
  @Input() chartContainerId: string;

  @Output() onToggleInAppChartForReport = new EventEmitter();

  private dataSourceField: AppField;

  constructor(
    public appStorageService: AppsService,
    public reportsService: ReportStorageService,
    public reportDataService: ReportDataService,
    private chartConfigService: ChartConfigService,
    private chartDataService: ChartDataService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    const app = this.appService.application(this.appIdentifier);
    this.report = app?.Reports.find((r) => r.Identifier === this.inAppChartReportIdentifier);
    this.dataSourceField = app?.getField(this.report.InAppDataSourceFieldIndentifier);

    if (!this.dataSourceField) {
      logMessage(`Invalid chart field ds: ${this.report?.InAppDataSourceFieldIndentifier} app: ${this.appIdentifier}  rep: ${this.inAppChartReportIdentifier} `);
    }

    // If we're attached to a record model, watch for dynamic changes
    if (this.recordModel) {
      if (this.dataSourceField) {
        this.subscribe(this.recordModel.record.$.pipe(
          distinctUntilChanged(this.isSourceEqual),
          debounceTime(300),
        ), (record) => {
          if (record) {
            this._getChartConfigAndData(record).catch(error => logError(error, 'Failed to get chart config data'));
          }
        });
      }
    } else if (this.record) {
      // Not attached to a model (e.g. popup chart on a list report)
      // Use record property - this won't dynamically update
      if (!this.isFieldHidden) {
        this._getChartConfigAndData(this.record).catch(error => logError(error, 'Failed to get rec chart config data'));
      }
    }
  }

  public override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes['isFieldHidden']) {
      if (!this.initialised && !this.isFieldHidden) {
        this._getChartConfigAndData(this.record).catch(error => logError(error, 'Failed to get rec chart config data'));
      }
    }
  }

  public override initialise(context: FieldContext) {
    super.initialise(context);
    this.appIdentifier = this.application?.Identifier;
    this.appIdentifiers = context.appIdentifiers;
    this.recordId = context.record?._id;
    this.inAppChartReportIdentifier = context.field.InAppChartReportIdentifier;
    this.chartContainerId = 'chart_' + context.record._id + '_' + context.field.Identifier;
    this.forceRerenderOnResize = true;
  }

  public onToggleInAppChartForReportHandler($event: MouseEvent) {
    $event.stopPropagation();
    this.onToggleInAppChartForReport.emit();
  }

  /** Check whether source data is the sane in two record instances */
  private isSourceEqual = (r1: Record, r2: Record): boolean => {
    if (r1._id !== r2._id) {
      return false;
    }

    return this.dataSourceField.isEqual(r1, r2);
  }

  private async _getChartConfigAndData(record: Record) {

    this.initialised = true;

    const recordId = record._id;
    if (this.appIdentifier && this.inAppChartReportIdentifier && recordId) {
      const app = this.appService.application(this.appIdentifier);
      const report = app.Reports.find((r) => r.Identifier === this.inAppChartReportIdentifier);
      if (report) {
        this.isLoading$.next(true);

        // If record has been loaded for a report, get full record so we have chart source data
        if (record._reportIdentidier) {
          record = await app.getRecordByIdAsync(recordId);
        }

        this.reportType = report.Type;
        this.chartType = report.Chart.ChartType;
        const chartConfig = await this.chartConfigService.getSpecificHighchartConfig(report, this.appIdentifiers, report.BaseFilter);
        if (chartConfig) {
          this.chartConfig = chartConfig;
          const chartData = await this.chartDataService.getRecordChartData(app, report, record);
          this.chartData$.next(chartData);
        }

        this.isLoading$.next(false);
      }
    }
  }
}
