<div
  style="align-items: center; border-style: dashed!important"
  class="row justify-content-center border m-2 p-4 mb-4 bg-light"
>
  <div class="col-sm st-text-theme-primary">
    <p style="text-align: center;" i18n>
      <fa-icon [icon]="['fal', 'cloud-upload']" size="3x"></fa-icon>
      <br />
      {{dragText}}
    </p>
  </div>
</div>
