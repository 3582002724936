import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Field, Failure } from '@softools/softools-core';

@Component({
  selector: 'app-ws-validation-bar',
  templateUrl: './ws-validation-bar.component.html',
  styleUrls: ['./ws-validation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WsValidationBarComponent {

  @Input() public errors: Array<Failure>;

  @Input() public fields: Array<Field>;

  public expanded = false;

  constructor() { }

  public fieldName(error: Failure): string {
    const field = this.fields.find(f => f.Identifier === error.fieldId);
    return (field && field.Label) || error.fieldId;
  }

  public expandBar = ($event: Event) => {
    $event.stopPropagation();
    this.expanded = true;
  }

  public collapseBar = ($event: Event) => {
    $event.stopPropagation();
    this.expanded = false;
  }
}
