
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { setCurrentUser, logError } from '@softools/softools-core';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { SyncStatusService } from 'app/services/sync-status.service';
import { ACCESS_TOKEN_KEY } from 'app/_constants/constants.keys';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private indexedDbService: IndexedDbService,
    private syncStatusService: SyncStatusService
  ) {}


  public login(auth0AccessToken: string, auth0Domain: string, userLang: string): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('cache-control', 'no-cache');

    const data = new HttpParams()
      .set('grant_type', 'auth0_grant_type')
      .set('auth0_access_token', auth0AccessToken)
      .set('auth0_domain', auth0Domain)
      .set('user_lang', userLang);

    return this.http.post(environment.baseUrl + '/token', data.toString(), { headers: headers }).pipe(
      map((response: any) => {
        // Login successful if there's a jwt token in the response.  Will use softools access token for api authentication/authorisation via bearer
        const res = response;
        if (res && res.access_token) {
          // Store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('expires_in', res.expires_in); // expiry duration (sec)
          localStorage.setItem(ACCESS_TOKEN_KEY, res.access_token);

          // Store expiry time as UTC string
          const expiry = moment().utc().add(res.expires_in, 'seconds').format();
          localStorage.setItem('access_token_expires', expiry);
        } else {
          logError(new Error('No access token found'), '');
        }
      })
    );
  }

  public async logout() {
    // Clear all local storage
    localStorage.clear();
    this.syncStatusService.reset();

    // Build list of variable items to remove as removing will affect index
    const victims: Array<string> = [];
    for (let i = 0; ; ++i) {
      const key = localStorage.key(i);
      if (key) {
        if (key.endsWith('-Horizon')) {
          victims.push(key);
        }
      } else {
        break;
      }
    }

    // delete them
    victims.forEach((victim) => localStorage.removeItem(victim));

    await this.indexedDbService.clear();
  }

  /**
   * Check if the user is registed as a Softools user account
   * Auth0 user should exist at this point
   */
  public userExists(): Promise<boolean> {
    return this.http
      .get<{ error: boolean }>(`${environment.baseUrl}/api/user/exists`)
      .pipe(
        map((response) => {
          return !response.error;
        })
      )
      .toPromise();
  }

  /**
   * Try and register an SSO user
   *
   * Retruns an error message
   */
  public tryRegisterUser(): Promise<string> {
    return this.http
      .post<{ errorMessage: string }>(`${environment.baseUrl}/api/user/register`, {})
      .pipe(
        map((response) => {
          return response.errorMessage;
        })
      )
      .toPromise();
  }

  public getUserInfo() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
    });

    return this.http.get(`${environment.baseUrl}/Api/Users/Current`, { headers: headers }).pipe(
      map((response: any) => {
        // Login successful if there's a jwt token in the response
        if (response) {
          setCurrentUser(response);
        }
      })
    );
  }

  public setAccessTokenQueryParamForUrl(url: string): string {
    if (!url) {
      return url;
    }

    const accessToken = this._getAccessToken();
    const authorisedUrl = url.indexOf('?') > -1 ? `${url}&access_token=${accessToken}` : `${url}?access_token=${accessToken}`;
    return authorisedUrl;
  }

  private _getAccessToken(): string {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    return accessToken;
  }

  public postUserLanguage(language: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('cache-control', 'no-cache')
      .set('authorization', `Bearer ${this._getAccessToken()}`);

    return this.http.post(`${environment.baseUrl}/Api/Users/Language/${language}`, null, { headers: headers }).pipe(
      catchError((e) => {
        logError(e, 'AuthenticationService.postUserLanguage');
        return throwError(e);
      })
    );
  }
}
