<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <div class="cardv2-header">
      <div>
        <ng-container *ngTemplateOutlet="header; context: { copyRecordStage: (copyRecordStage$ | async) }"></ng-container>
      </div>
    </div>

    <mat-dialog-content>
      <div class="cardv2-body">
        <ng-container *ngTemplateOutlet="content; context: { copyRecordStage: (copyRecordStage$ | async) }"></ng-container>
        <ng-container *ngIf="(copyRecordStage$ | async) === copyRecordStageEnum.InitialOptions">
          <div class="d-flex flex-column">
            <mat-checkbox [(ngModel)]="templateCopy" class="copy-templates" i18n>Template Copy</mat-checkbox>
            <ng-container *ngIf="!app?.UseOfflineCopy">
              <mat-checkbox [(ngModel)]="copyNotes" class="copy-notes" i18n>Copy Notes</mat-checkbox>
              <mat-checkbox *ngIf="hasLinkCopyToParent" [(ngModel)]="linkCopyToParent" class="copy-to-parent" i18n
                >Link Copy To Parent Record</mat-checkbox
              >
              <mat-checkbox [(ngModel)]="copyComments" class="copy-comments" i18n>Copy Comments</mat-checkbox>
              <mat-checkbox [(ngModel)]="copyAttachments" class="copy-attachments" i18n>Copy Attachments</mat-checkbox>
              <mat-checkbox [(ngModel)]="copyHistory" class="copy-history" i18n>Copy History</mat-checkbox>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </mat-dialog-content>

    <div class="cardv2-footer">
      <ng-container *ngIf="(copyRecordStage$ | async) === copyRecordStageEnum.InitialOptions">
        <button type="button" class="primary" (click)="startCopyClickHandler()" i18n>Copy</button>
      </ng-container>

      <ng-container *ngIf="(copyRecordStage$ | async) === copyRecordStageEnum.SuccessfulCopy">
        <button type="button" class="primary" (click)="naviateCopiedRecordClickHandler(); popupClosedClickHandler()" i18n>
          Copied record
        </button>
      </ng-container>

      <button
        *ngIf="(copyRecordStage$ | async) === copyRecordStageEnum.SuccessfulCopy"
        type="button"
        class="primary"
        (click)="popupClosedClickHandler()"
        i18n
      >
        Original record
      </button>
      <button
        *ngIf="(copyRecordStage$ | async) !== copyRecordStageEnum.SuccessfulCopy"
        type="button"
        class="close"
        (click)="popupClosedClickHandler()"
        i18n
      >
        Close
      </button>
    </div>

    <ng-template #header let-copyRecordStage="copyRecordStage">
      <div *ngIf="copyRecordStage$ | async as stage">
        <div *ngIf="stage === copyRecordStageEnum.InitialOptions" i18n>Copy Record</div>
        <div *ngIf="stage === copyRecordStageEnum.PendingCopy" i18n>Record Copying</div>
        <div *ngIf="stage === copyRecordStageEnum.SuccessfulCopy" i18n>Record Copied</div>
        <div *ngIf="stage === copyRecordStageEnum.FailedCopy" i18n>Copy Failed</div>
      </div>
    </ng-template>

    <ng-template #content let-copyRecordStage="copyRecordStage">
      <ng-container *ngIf="copyRecordStage === copyRecordStageEnum.PendingCopy">
        <div class="new-rec-link">
          <sof-spinner-icon [size]="'1x'" class="st-text-theme-primary loader"></sof-spinner-icon>
          <span i18n> In progress...</span>
        </div>
      </ng-container>

      <ng-container *ngIf="copyRecordStage === copyRecordStageEnum.FailedCopy">
        <div class="new-rec-link">
          <span i18n>Please try again.</span>
        </div>
      </ng-container>

      <ng-container *ngIf="copyRecordStage === copyRecordStageEnum.SuccessfulCopy">
        <div class="mt-4">
          <p i18n>The record has been succesfully copied.</p>
          <p i18n>Please choose the record you want to continue editing.</p>
          <span i18n></span>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
