import { logError, Point, Record, Report } from '@softools/softools-core';
import { BooleanModelProperty, ModelProperty } from '@softools/vertex';
import { ReportFilter } from 'app/filters/types';
import { IGroupInfo, IGroupStarts } from 'app/services/indexes/app-data-index';
import { Application, ICard } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { debounceTime } from 'rxjs';
import { RecordDataAccessor } from './accessors';
import { RecordsReportModel } from './records-report.model';

export interface IDragItem {
  dragging: boolean;
  value?: any;
}

export class CardReportModel extends RecordsReportModel {

  /** Card being used on the report */
  public card = new ModelProperty<ICard>(this).withLogging('Card');

  public columnGroups = new ModelProperty<IGroupInfo>(this).withLogging('COL GROUPS');

  public showGroupHeaders = new BooleanModelProperty(this).withLogging('Group Hdrs');

  public readonly groupCount = new ModelProperty<number>(this).withLogging('Group Count');

  public readonly groupPageSize = new ModelProperty<number>(this).withLogging('Group Page Size');

  public readonly groupedCardsPerColumn = new ModelProperty(this, 0);

  public readonly ungroupedCardsPerColumn = new ModelProperty(this, 0);

  public readonly groupField = new ModelProperty<AppField>(this).withLogging('Group Field');

  public readonly scrollPosition = new ModelProperty<Point>(this, { x: 0, y: 0 });  

  //////////////////////////////////////////////////////////////////
  // Drag and drop

  /** Current drag source grouo value or null if not dragging */
  public readonly draggingFromGroup = new ModelProperty<IDragItem>(this).withLogging('drag group');

  /** Record being dragged or null if not dragging */
  public readonly dragRecord = new ModelProperty<Record>(this, null).withLogging('drag record');

  /** Record being dropped. We don't update layout until we see this record patched */
  public readonly dropRecord = new ModelProperty<Record>(this, null).withLogging('drop record');

  //////////////////////////////////////////////////////////////////

  /** Selected zoom level, 1 = actual size */
  public readonly zoom = new ModelProperty(this, 1).withPersistence({
    nameProperty: this.reportIdentifier,
    nameFormatter: (name) => `Zoom-${name}`,
    valueConverter: (value) => +value,
    defaultValue: 1
  }).withLogging('Zoom');

  public readonly zoomTransform = new ModelProperty<any>(this);

  override initialise() {
    this.useFiniteGroups.value = true;
    super.initialise();
  }

  protected override observeProperties() {
    super.observeProperties();

    this.subscribe(this.viewChanged$, (view) => {
      if (view) {
        this.loadData().catch(error => logError(error, 'Failed to load data'));
        // this.setGroupValue();
      }
    });


    this.subscribe(this.report.$, async (report) => {
      if (report) {
        this.configureReport(report);
        await this.invalidateDeck();
      }
    });

    // map zoom transform into CSS
    this.subscribe(this.zoom.$, (level) => {
      const zoom = { zoom: level };
      this.zoomTransform.value = zoom;
    });

    // Update decks when drop record is patched
    // This updates layout when a drag/drop completes
    this.subscribe(this.recordPersistService.patchQueued$.pipe(debounceTime(250)), async (patch) => {
      if (patch) {
        const record = this.dropRecord.value;
        if (record?._id === patch._id) {
          this.dropRecord.value = null;
          await this.invalidateDeck();
        }
      }
    });
  }

  private async invalidateDeck() {
    // Force reindex
    await this.dataChanged();
  }

  private configureReport(report: Report) {

    const app = this.appModel.app.value;
    if (app) {
      if (report.CardReport.CardGroupingFieldIdentifier) {
        this.groupField.value = app.getField(report.CardReport.CardGroupingFieldIdentifier);
      } else {
        this.groupField.value = null;
      }

      const cards = app.Cards;
      if (cards?.length > 0) {
        const cardId = report.CardReport?.Card;

        let card: ICard;
        if (cardId) {
          card = cards.find(c => c.Identifier === cardId);
        }

        if (!card) {
          card = cards.find(c => c.Default) ?? cards[0];
        }

        this.card.value = card;
      } else {
        this.card.value = null;
      }
    }
  }

  private async loadData() {
    // don't load here, all work done in getGroupRecords
  }

  /**
   * Get records for a group
   * @param starts Start info
   * @param first  Offset of first record to fetch within group
   * @param count  Max number of records to fetch
   * @returns      Record array, which may be empty if group is empty. null if not available.
   */
  public async getGroupRecords(starts: IGroupStarts, first: number, count: number) {
    if (this.accessor) {
      if (starts?.value === undefined) {
        const records = await this.accessor.getRecords(first, count);
        return records;
      } else {
        count = Math.min(count, starts.count);
        const records = await this.accessor.getRecords(first, count, starts);
        return records;
      }
    }

    return null;
  }

  protected override async filterChanged() {
    // Rebuilds index if needed
    await super.filterChanged();

    // const groupBy = this.filterModel.combinedFilter.value?.groupFieldId;
    // this.groupField.value = groupBy ? this.appModel.app.value.getField(groupBy) : null;

    await this.prepareGroups();

    // inefficient...
    await this.loadData();
  }

  protected override async loadIndex(): Promise<void> {
    await super.loadIndex();
    await this.prepareGroups();
  }

  protected override createRecordAccessor(app: Application): RecordDataAccessor {
    return this.accessorFactory.createCardAccessor(app, this, this.filterModel);
  }

  private async prepareGroups() {
    if (this.accessor) {
      const groups = await this.accessor?.getGroupStartsAsync(0, undefined, { ungroupedReturnAll: true }) ?? { starts: [], count: 0 };

      // remove undefined/null groups unless configured to include
      // This should be removed by filter now but there can still be an empty entry
      if (this.groupField.value && !this.report.value.CardReport?.IncludeUndefinedGroups) {
        groups.starts = groups.starts.filter(s => s.value !== null && s.value !== undefined);
        groups.count = groups.starts.length
      }

      // Extract group values
      this.showGroupHeaders.value = !!this.groupField.value;
      this.columnGroups.value = groups;
      this.groupCount.value = groups.count;
    } else {
      this.groupCount.value = 0;
      this.columnGroups.value = { starts: [], count: 0 };
      this.showGroupHeaders.value = false;
    }
  }

  public async dataChanged() {
    this.accessor?.resetIndex();
    await this.prepareGroups();
  }

  public async recordChanged(record: Record) {
    await this.accessor?.recordChanged(record);
  }

  public override filter(): ReportFilter {
    // If report is grouped and excludes undefined, add an extra filter to exclude
    let filter = super.filter();
    const report = this.report.value;
    const group = report.CardReport?.CardGroupingFieldIdentifier ?? report.GroupBy?.FieldIdentifier;
    if (group && !report.CardReport?.IncludeUndefinedGroups) {
      // Exclude undefined group values
      const groupFilter = ReportFilter.fromQueryParams({ $filter: `([${group}] ne null and [${group}] ne '')` });
      filter = ReportFilter.merge([filter, groupFilter]);
    }

    // Copy group settings into filter
    if (report.CardReport?.CardGroupingFieldIdentifier) {
      filter.Group = report.CardReport?.CardGroupingFieldIdentifier;
      filter.IsGroupDescending = false;
    } else if (report.GroupBy?.FieldIdentifier) {
      filter.Group = report.GroupBy.FieldIdentifier;
      filter.IsGroupDescending = report.GroupBy.Descending;
    }

    return filter;
  }
}
