
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { CookiesService } from 'app/services/cookies.service';
import { InjectService } from 'app/services/locator.service';
import { BaseGuard } from './base.guard';

@Injectable()
export class CookieCheckGuard extends BaseGuard implements CanActivate {

  @InjectService(CookiesService)
  private _cookiesService: CookiesService;

  public canActivate() {
    if (this._cookiesService.IsCompatible) {
      return true;
    }
    return super.getErrorUrlTree();
  }

}
