import { Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { BaseInputComponent } from '../base-input-component';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaComponent extends BaseInputComponent<HTMLTextAreaElement> implements AfterViewInit {

  @ViewChild('inp', { static: true })
  protected override element: ElementRef<HTMLTextAreaElement>;

  @ViewChild(CdkTextareaAutosize, { static: false })
  _autosize: CdkTextareaAutosize;

  ngAfterViewInit(): void {
    this.resizeToFitContent();
  }

  public onToggleExpandedFormTemplate() {
    this.resizeToFitContent();
  }

  private resizeToFitContent() {
    this._autosize?.resizeToFitContent(true);
  }

}
