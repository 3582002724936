import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionListComponent {

  @Input() version: string;
  @Input() showVersion: boolean;
  @Input() actions: Array<ToolbarAction>;
  @Input() disableWhenOffline = true;
  @Input() isOnline = false;

  @Output() onActionClick = new EventEmitter();

  constructor() { }
}
