<div class="display-container bar-back w-100 h-100">

  <div class="client-icon px-4 pt-2" *ngIf="errors?.length > 0">
    <fa-icon [icon]="['fal', 'exclamation-triangle']"></fa-icon>
  </div>

  <span *ngIf="errors?.length > 1" class="client-error-header pt-2" i18n>
    This record has {{errors.length}} invalid field values. It will not be sent to the server until they are
    corrected
  </span>

  <span *ngIf="errors?.length === 1" class="client-error-header pt-2" i18n>
    This record has an invalid field value. It will not be sent to the server until it is corrected
  </span>

  <div class="client-errors d-flex flex-column" *ngIf="errors?.length > 0">
        <div  *ngFor="let err of errors">
            <span *ngIf="err.error === errcodes.Required" i18n>"{{fieldName(err)}}" must have a value.</span>
            <span *ngIf="err.error === errcodes.BelowMinimum" i18n>"{{fieldName(err)}}" must be greater than or equal to
              {{err.param}}.</span>
            <span *ngIf="err.error === errcodes.AboveMaximum" i18n>"{{fieldName(err)}}" must be less than or equal to
              {{err.param}}.</span>
            <span *ngIf="err.error === errcodes.InvalidUrl" i18n>"{{fieldName(err)}}" must be a valid supported
              URL.</span>
            <span *ngIf="err.error === errcodes.InvalidEmail" i18n>"{{fieldName(err)}}" must be a valid Email
              address.</span>
            <span *ngIf="err.error === errcodes.InvalidInt" i18n>"{{fieldName(err)}}" must be a valid integer
              value.</span>
            <span *ngIf="err.error === errcodes.InvalidLong" i18n>"{{fieldName(err)}}" must be a valid long integer
              value.</span>
            <span *ngIf="err.error === errcodes.InvalidFloat" i18n>"{{fieldName(err)}}" must be a valid numeric
              value.</span>
            <span *ngIf="err.error === errcodes.NotAUser" i18n>"{{fieldName(err)}}" must specify a valid Softools
              user.</span>
            <span *ngIf="err.error === errcodes.NotATeam" i18n>"{{fieldName(err)}}" must specify a valid Softools
              team.</span>
              <span *ngIf="err.error === errcodes.TooLong" i18n>Must be less than {{err.param}} characters.</span>
              <span *ngIf="err.error === errcodes.TooShort" i18n>Must be at least {{err.param}} characters.</span>
              <span *ngIf="err.error === errcodes.RowsRequired" i18n>List must have at least one row.</span>
              <span *ngIf="err.error === errcodes.ValuesMustMatch" i18n>Value must match the {{err.param}} field.</span>
              <span *ngIf="err.error === errcodes.InvalidPasswordValue" i18n>Must be a valid password: {{err.param}}.</span>
              <span *ngIf="err.error === errcodes.InvalidUsernameValue" i18n>Must be a valid username: {{err.param}}.</span>

              <span *ngIf="err.error === errcodes.InvalidTime" i18n>"{{fieldName(err)}}" must be a valid time</span>

            <span *ngIf="err.error === errcodes.Other" i18n>"{{fieldName(err)}}" is not valid.</span>

            <span *ngIf="err.error === errcodes.Pattern" i18n>Must match the regex pattern '{{err.param}}'</span>
            <!-- Reinstate this to search for the field on the form -->
            <!-- <a href="#" class="mx-2">
                  <fa-icon [icon]="['fal', 'search']"></fa-icon>
                  <span class="mx-1" i18n>Locate field</span>
                </a> -->

        </div>
    </div>

  <div class="server-icon px-4 pt-2" *ngIf="serverError">
    <fa-icon [icon]="['fal', 'exclamation-triangle']"></fa-icon>
  </div>

  <div i18n class="server-header pt-2" *ngIf="serverError">
    The server rejected the record with the following error
  </div>

  <div class="server-error" *ngIf="serverError">
    <diV>{{serverError}}</diV>
  </div>

</div>
