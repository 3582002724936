import { Injectable } from '@angular/core';
import { OnlineStatusService } from '@softools/softools-core';
import { WorkflowTriggerQueueService, TriggeredWorkflow } from './workflow-queue.service';
import { tryGetCurrentUser, WorkflowItemsRepository } from '@softools/softools-core';
import { Subject } from 'rxjs';
import { logError } from '@softools/softools-core';
import { WorkflowRun, WorkflowRunnerService } from './workflow-runner-service';

@Injectable({ providedIn: 'root' })
export class WorkflowTriggerService {
  private _lock = false;

  public workflowsTriggered$: Subject<void>;
  public workflowStatusUpdate$: Subject<WorkflowRun>;

  constructor(
    private onlineStatus: OnlineStatusService,
    private workflowQueueService: WorkflowTriggerQueueService,
    private workflowRepository: WorkflowItemsRepository,
    private workflowRunnerService: WorkflowRunnerService
  ) {

    this.workflowsTriggered$ = new Subject();
    this.workflowStatusUpdate$ = new Subject();

    // todo: SignalR events

    setTimeout(() => {
      setInterval(() => {
        // Temp fix. We should start this service via workspace once the user logs in
        const user = tryGetCurrentUser();
        if (user) {
          this.triggerWorkflows().catch(error => logError(error, 'Failed to trigger workflows'));
        }
      }, 5000);
    }, 30 * 1000);
  }

  public async updateWorkflowStatus(workflowRun: WorkflowRun) {
    var run = await this.workflowRunnerService.get(workflowRun.workflowId);
    if (run) {
      run.running = workflowRun.running;
      await this.workflowRunnerService.update(run);
      this.workflowStatusUpdate$.next(run);
    }
  }

  public async queueWorkflow(workflowTrigger: TriggeredWorkflow): Promise<boolean> {
    if (this.onlineStatus.isConnected) {
      await this.runWorkflow(workflowTrigger);
      return false;
    } else {
      await this.workflowQueueService.queue(workflowTrigger);
      return true;
    }
  }

  public async cancelWorkflow(toCancel: TriggeredWorkflow) {
    if (!this._lock) {
      this.setLock();
      await this.workflowQueueService.delete(`${toCancel.appIdentifier}-${toCancel.fieldId}${toCancel.recordId ? '-' + toCancel.recordId : ''}`);
      this.unlock();
    }
  }

  public async triggerWorkflows() {
    let locked = false;
    try {
      if (!this._lock) {
        locked = true;
        // Ensures code only runs in the active tab
        if (!document.hidden) {
          this.setLock();
          if (this.onlineStatus.isConnected) {
            const keys = await this.workflowQueueService.keys();
            if (keys?.length) {
              for (let i = 0; i < keys.length; ++i) {
                const key = keys[i];

                const workflowTrigger = await this.workflowQueueService.get(key);

                await this.runWorkflow(workflowTrigger);

                this.workflowQueueService.delete(key).catch(error => logError(error, 'Failed to delete workflow'));
              }
              this.workflowsTriggered$.next();
            }
          }
        }
      }
    } finally {
      if (locked) {
        this.unlock();
      }
    }
  }

  public getTrigger(key: string) {
    return this.workflowQueueService.get(key);
  }

  private setLock() {
    this._lock = true;
  }

  private unlock() {
    this._lock = false;
  }

  private async runWorkflow(workflowTrigger: TriggeredWorkflow) {
    const result = await this.workflowRepository.triggerFieldWorkflow(workflowTrigger.appIdentifier, workflowTrigger.fieldId, workflowTrigger.recordId);
    if (result) {
      await result.map(async workflowId => {
        const run = { ...workflowTrigger, workflowId, running: true } as WorkflowRun
        await this.workflowRunnerService.queue(run);
        this.workflowStatusUpdate$.next(run);
      });
    }
  }
}
