import { Record, SelectListOption, stringCompare, SelectionListChange, ISelectionValue, logMessage, Enums, ValidationError, Failure } from '@softools/softools-core';
import { AppField } from './app-field';
import { RecordPatch } from 'app/workspace.module/types';
import { SelectListsService } from 'app/services/select-lists.service';
import { InjectService } from 'app/services/locator.service';

export class BaseSelectionAppField<TData> extends AppField<TData> {

  @InjectService(SelectListsService)
  private selectListsService: SelectListsService;

  private selectListOptions: Array<SelectListOption>;

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {

    // Use display order to determine order
    const options = this.getSelectListOptions();
    if (options) {
      const opt1 = options?.find((opt) => opt.Value === val1);
      const opt2 = options?.find((opt) => opt.Value === val2);
      const order1 = opt1?.DisplayOrder ?? -1;
      const order2 = opt2?.DisplayOrder ?? -1;
      return isDescending ? order2 - order1 : order1 - order2;;
    }

    // Fall back to comparing values
    if (this.SelectListSubType === Enums.SelectListSubType.Numeric) {
      return isDescending ? val2 - val1 : val1 - val2;;
    } else {
      val1 = val1?.toString() ?? '';
      val2 = val2?.toString() ?? '';
      return isDescending ? val2.localeCompare(val1) : val1.localeCompare(val2);
    }
  }

  public override setBacking(record: Record, value: any) {
    const backingId = `${this.Identifier}_Text`;
    record[backingId] = value;
  }

  public override getDisplayRecordValue(record: Record, listRow?: number) {
    const options = this.getSelectListOptions();
    const value = this.getRawRecordValue(record, listRow);
    const val = value?.toString();
    const option = options?.find((opt) => opt.Value === val);
    return option ? option.Text : val;
  }

  public getSelectListOptions() {
    if (this.selectListOptions === undefined) {
      const selectList = this.selectListsService.get(this.SelectListIdentifier);
      if (selectList) {
        this.selectListOptions = selectList.SelectListOptions;
      } else {
        this.selectListOptions = this.application.selectionListOptions(this.SelectListIdentifier);
      }
    }

    return this.selectListOptions;
  }
}

export class SelectionAppField extends BaseSelectionAppField<string | number> {

  public override finiteValues(options?: { includeNull?: boolean, reverse?: boolean }): Array<any> | null {
    const selectOptions = this.getSelectListOptions();
    if (selectOptions?.length < 16) {
      const values = selectOptions.map(o => o.Value);
      if (options?.reverse) {
        values.reverse();
      }

      if (options?.includeNull) {
        values.push(null);
      }

      return values;
    }

    return null;
  }
}

export class MultiSelectionAppField extends BaseSelectionAppField<Array<string | number>> {

  public override getRecordValue(record: Record, listRow?: number) {

    const value = this.getRawRecordValue(record, listRow);
    if (Array.isArray(value)) {
      const cleaned = value.filter(item => item.Value).filter((item, index) => value.findIndex(i => i.Value === item.Value) === index);
      return cleaned;
    }

    return super.getRecordValue(record, listRow);
  }

  public override adaptValue(value: any, current: any): any {
    if (value === null || value === undefined) {
      return value;
    } else if (value.added || value.removed) {
      // Here we want to do...
      // if (value instanceof SelectionListChange) {
      // ... but can't beacuuse it's plain JS when it comes back from storage
      let values = Array.isArray(current) ? [...(current as Array<{ Value: string }>)] : [];
      if (value.added) {
        value.added.forEach(addition => {
          if (values.findIndex(item => item.Value === addition.Value) < 0) {
            values.push(addition);
          }
        });
      }

      if (value.removed) {
        value.removed.forEach(removal => {
          values = values.filter(item => item.Value !== removal.Value);
        });
      }

      return values;
    } else if (Array.isArray(value)) {
      return value;
    } else if (value === '') {
      return [];
    } else {
      const options = this.getSelectListOptions();
      const option = options?.find(o => o.Value === value);
      if (option) {
        return [option.Value];
      }

      // If value isn't part of option list log and return no selection
      logMessage(`'${value}' is not valid change for multi-select field '${this.Identifier}'`);
      return [];
    }
  }

  public override adaptStringValue(value: string): any {
    // todo what does default look like esp in multi-select
    return super.adaptStringValue(value);
  }


  public override getDisplayRecordValue(record: Record, listRow?: number) {
    const options = this.getSelectListOptions();

    const value = this.getRecordValue(record, listRow);

    if (Array.isArray(value)) {
      const values = value as Array<ISelectionValue>;
      const selectedOptions = values?.map((v) => options.find((opt) => opt.Value === v.Value));
      // Extract values.  Check not undefined - AllApp has some values with array of strings
      // If this can happen in real scenarios need to allow for it above
      return selectedOptions?.map((v) => v?.Text || v?.Value.toString())
        .filter(v => !!v)
        .sort((a, b) => stringCompare(a, b)).join(', ')
        ?? '';
    } else {
      // Invalid value, probably because field type has been changed
      return value?.toString();
    }
  }


  public override setBacking(record: Record, value: any) {
    const backingValue = value ? value.map(item => item.Value).join('|') : '';
    const backingId = `${this.Identifier}_Text`;
    record[backingId] = backingValue;
  }

  public override storeToRecord(record: Record, value: any) {
    if (value && !Array.isArray(value)) {
      throw new Error(`'${value}' is not valid value for multi-select field ${this.Identifier}`);
    }
    super.storeToRecord(record, value);
  }

  public override updatePatchWithDefault(patch: RecordPatch) {
    if (this.DefaultValue !== undefined && !this.DefaultValueIsExpression) {
      const change = new SelectionListChange();
      change.added = [{ Value: this.DefaultValue }];
      patch.addChange(this.Identifier, change);
    }
  }

  public override updatePatch(patch: RecordPatch, record: Record, local = false) {
    const value = this.getRawRecordValue(record);

    if (value && Array.isArray(value)) {
      const change = new SelectionListChange();
      change.added = [];
      value.forEach(entry => {
        change.added.push(entry);
      });
      patch.addChange(this.Identifier, change);
    }
  }

  public override validateRequired(value: any, errors: Array<Failure>, listRow?: number, forceCheck = false): void {
    if (forceCheck || this.Required) {
      // for multi select, value must be an array with some values
      if (!Array.isArray(value) || value.length === 0) {
        errors.push({ error: ValidationError.Required, fieldId: this.Identifier, listRow });
      }
    }
  }
}
