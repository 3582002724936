import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CompatibilityService {
  private hasIndexedDB: boolean;

  constructor(public platform: Platform) { }

  public async HasIndexedDB(): Promise<boolean> {
    const success = true;

    /**
     * This is causing slow load times for new apps
     *
     */

    // if (typeof window.indexedDB !== 'undefined' && window.indexedDB != null) {
    //   success = await new Promise<boolean>((resolve) => {
    //     const name = 'Softools-Temp';
    //     const request = window.indexedDB.open(name, 3);

    //     request.onerror = () => {
    //       resolve(false);
    //     };

    //     request.onsuccess = () => {
    //       const db = request.result;

    //       const transaction = db.transaction(['toDoList'], 'readwrite');
    //       const objectStore = transaction.objectStore('toDoList');

    //       if (objectStore.getAll) {
    //         resolve(true);
    //       } else {
    //         resolve(false);
    //       }
    //     };

    //     request.onblocked = () => {
    //       resolve(true);
    //     };

    //     request.onupgradeneeded = (event) => {
    //       // @ts-ignore
    //       const db = event.target.result;
    //       const objectStore = db.createObjectStore('toDoList', { keyPath: 'taskTitle' });

    //       if (objectStore.getAll) {
    //         resolve(true);
    //       } else {
    //         resolve(false);
    //       }
    //     };
    //   });
    // }

    // if (typeof (window.indexedDB) !== 'undefined' && window.indexedDB != null && success) {
    //   const name = 'Softools-Temp';
    //   const request = window.indexedDB.deleteDatabase(name);
    //   request.onsuccess = () => {
    //     console.log('Softools-Temp cleared');
    //   };
    // }

    return success;
  }

  get HasCssGrid() {
    return this._testCssGridCapability();
  }

  public async IsCompatible() {
    return (await this.HasIndexedDB()) && this.HasCssGrid && !this.platform.TRIDENT && this._checkForLegacyEdge();
  }

  private _testCssGridCapability() {
    const divEl = document.createElement('div');
    divEl.setAttribute('id', 'div_El');
    document.body.appendChild(divEl);
    const hasCssGrid = typeof (<any>divEl.style).grid !== 'undefined';
    const el = document.getElementById('div_El');
    el.parentNode.removeChild(el);
    return hasCssGrid;
  }

  private _checkForLegacyEdge() {
    if (this.platform.EDGE) {
      if (navigator.storage) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
