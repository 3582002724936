
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { CompatibilityService } from 'app/services/compatibility.service';
import { Router } from '@angular/router';
import { getHomepageUrl } from 'app/_constants/constants.route';
import { logError } from '@softools/softools-core';

@Component({
  selector: 'app-incompatible-components',
  templateUrl: './incompatible.component.html'
})
export class IncompatibleComponent implements OnInit {

  public baseUrl = environment.baseUrl;
  public hasIndexedDb = false;
  public hasCssGrid = false;

  constructor(private router: Router, private compatibilityService: CompatibilityService) { }

  async ngOnInit() {
    this.hasCssGrid = this.compatibilityService.HasCssGrid;
    this.hasIndexedDb = await this.compatibilityService.HasIndexedDB();

    if (await this.compatibilityService.IsCompatible()) {
      this.router.navigate([getHomepageUrl()]).catch(error => logError(error, 'Failed to navigate'));
    }
  }
}
