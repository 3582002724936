import { Report } from '@softools/softools-core';
import { ModelProperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { MatrixModel } from 'app/softoolsui.module/matrixreport.component/matrix-model';
import { Application } from 'app/types/application';
import { AppModel } from '../app.model';
import { FilterModel } from '../filter.model';
import { PageModel } from '../page/page.model';
import { AccessorFactory } from './accessors';
import { OverviewReportModel } from './overview-report.model';

export class MatrixReportModel extends OverviewReportModel {

  public readonly matrixModel = new ModelProperty<MatrixModel>(this);

  @InjectService(AccessorFactory)
  private readonly accessorFactory: AccessorFactory;

  public constructor(public override appModel: AppModel, public override filterModel: FilterModel, public override pageModel: PageModel) {
    super(appModel, filterModel, pageModel);
    this.matrixModel.logChanges = 'MATRIX MODEL';
  }

  protected override observeProperties() {
    super.observeProperties();

    this.subscribe(this.filterModel.combinedFilter.$, async (f) => {
      if (f) {
        await this.loadMatrixData();
      }
    });

    this.subscribe(this.report.$, async (rep) => {
      if (rep) {
        await this.loadMatrixData();
      }
    });
  }

  protected override async filterChanged() {
    this.accessor?.resetIndex();
    await this.loadMatrixData();
  }

  protected override async setAccessor(app: Application, report: Report) {

    this.accessor?.close();

    this.accessor = this.accessorFactory.createChartAccessor(app, this);

    if (this.accessor) {

      await this.accessor.initialise();

      this.subscribe(this.accessor.count$, (count) => {
        this.totalCount.value = count;
      });

      // Force update
      await this.filterChanged();
    }
  }

  private async loadMatrixData() {
    // const chart = this.appContextService.SelectedReport.Chart;
    const chart = this.report.value?.Chart;
    const app = this.appModel.app?.value;
    if (app && chart) {
      const model = new MatrixModel(chart, app);
      await this.accessor?.loadMatrixData(model);
      this.matrixModel.value = model;
    }
  }

}
