import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HighChartsRepository extends RepositoryBase {

  public getHighChartsConfig(appIdentifier: string, reportIdentifier: string): Observable<any> {
    return this.get(this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'HighCharts', reportIdentifier));
  }
}
