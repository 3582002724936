import * as moment from 'moment';
import { BooleanModelProperty, Model, ModelEvent, ModelProperty } from '@softools/vertex';
import { firstValueFrom, lastValueFrom, Subject, timer } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { InjectService } from 'app/services/locator.service';
import { AppContextService } from 'app/services/app-context.service';
import { distinctUntilKeyChanged, filter, flatMap, map } from 'rxjs/operators';
import { APP_IDENTIFIER, CHILD_APP_IDENTIFIER, FORM_IDENTIFIER, HOMEPAGE_IDENTIFIER, PARENT_APP_IDENTIFIER, PARENT_RECORD_ID, RECORD_ID, REPORT_IDENTIFIER } from 'app/_constants/constants.route';
import { BackgroundProcessStorageService, DownloadRepository, Enums, getCurrentUser, logError, ModalMessageType, ModalOptions, OnlineStatusService, stringCompare, tryGetCurrentUser, User, UsersRepository } from '@softools/softools-core';
import { AppIdentifier } from 'app/types/application';
import { HeaderModel } from './headers/header.model';
import { AppIdentifiers } from 'app/services/record/app-info';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { environment } from 'environments/environment';
import { AppField } from 'app/types/fields/app-field';
import { PreviewModel } from './app/preview.model';
import { MessageDialogComponent, MessageDialogData } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { NavigationController } from './navigation/navigation.controller';
import { ACCESS_TOKEN_KEY } from 'app/_constants/constants.keys';
import { ModalComponent } from 'app/softoolsui.module/modal.component/modal.component';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { WindowRef } from 'app/WindowRef';
import { DownloadInfo } from 'app/types/download-info.interface';
import { ToastrService } from 'ngx-toastr';
import { IToastyConfig } from 'app/types/toasty-config.interface';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { ImagePersistService } from 'app/services/images/image-persist.service';
import { WorkflowTriggerService } from 'app/services/workflow-trigger.service';
import { AppService } from 'app/services/app.service';
import { ZoneService } from 'app/services/zone.service';
import { OfflineService } from 'app/services/offline.service';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AppZone } from 'app/types/enums';
import { MediaObserver } from '@angular/flex-layout';
import { AuthorisationService } from 'app/auth.module/services/authorisation.service';

export interface IAuthTokens {
  token?: string;
  refreshToken?: string;
  expires?: any;
}

/**
 * Model for global state and settings
 */
export class GlobalModel extends Model<GlobalModel> {
  public routeParams = new ModelProperty<RouteParams>(this);

  /** True if we are connected to a Softools server */
  public online = new BooleanModelProperty(this, true).withLogging('ONLINE');

  /** True if we have valid authetication credentials */
  public authenticated = new ModelProperty<boolean>(this, true).withLogging('AUTH');

  public archived = new ModelProperty<boolean>(this, false);

  /** Display a cooke policy accept/decline banner */
  public cookiesAccepted = new ModelProperty<boolean>(this, false);

  /** True if the device or window is considered small and a compact UI should be used */
  public readonly isSmallDisplay = new BooleanModelProperty(this);

  // Triggered once when the app component is loaded.
  public appInit = new ModelEvent();

  // Trigger when the access token is set
  public accessTokenSetEvent = new ModelEvent();

  public readonly accessTokens = new ModelProperty<IAuthTokens>(this).withLogging('Access Tokens', false);

  // Site message, displayed on banner on all pages if not null
  public readonly siteMessage = new ModelProperty<string>(this);

  // Softools icon, displayed in the folders
  public readonly showSoftoolsIcon = new BooleanModelProperty(this);

  /** Header state */
  // Should this belong to a different model?  Would need to restructure if so
  public readonly header = new HeaderModel(this);

  public readonly navigation = new NavigationController();

  public modeCancelled$ = new Subject<void>();

  /**
   * Fired when a change occurs that might affect app layout e.g. a sidebar opening or closing.
   * Currently this is *not* fired when window size changes
   */
  public layoutChanged$ = new Subject<void>();

  public previewModel = new PreviewModel(this);

  /** Re-entrancy guard */
  // We can probably lose this as the observable will serialize
  // calls to processQueues()
  private _processingChanges = false;

  @InjectService(AppContextService)
  public appContextService: AppContextService;

  @InjectService(Router)
  public router: Router;

  @InjectService(ActivatedRoute)
  public activatedRoute: ActivatedRoute;

  @InjectService(MediaObserver)
  private readonly media: MediaObserver;

  @InjectService(OnlineStatusService)
  private readonly onlineStatusService: OnlineStatusService;

  @InjectService(OfflineService)
  private readonly offlineService: OfflineService;

  @InjectService(OverlayService)
  private readonly overlayService: OverlayService;

  @InjectService(DownloadRepository)
  private readonly downloadRepositpory: DownloadRepository;

  @InjectService(BackgroundProcessStorageService)
  private readonly backgroundProcessStorageService: BackgroundProcessStorageService;

  @InjectService(RecordQueueService)
  private readonly recordQueue: RecordQueueService;

  @InjectService(RecordPersistService)
  private readonly recordPersistService: RecordPersistService;

  @InjectService(ImagePersistService)
  private readonly imagePersistService: ImagePersistService;

  @InjectService(WorkflowTriggerService)
  private readonly workflowTriggerService: WorkflowTriggerService;

  @InjectService(AppService)
  private readonly appService: AppService;

  @InjectService(ZoneService)
  private readonly zoneService: ZoneService;

  @InjectService(UsersRepository)
  private usersRepository: UsersRepository;

  @InjectService(HttpClient)
  private readonly http: HttpClient;

  @InjectService(MatDialog)
  private readonly dialog: MatDialog;

  @InjectService(ToastrService)
  private toastr: ToastrService;

  @InjectService(WindowRef)
  private windowRef: WindowRef;

  @InjectService(AuthorisationService)
  private authorisationService: AuthorisationService;

  public constructor(container?: Model<any>) {
    super(container);

    this.initAccessTokens();
  }

  public initialise() {
    this.observeProperties();
    this.appInit.fire();

    this.startHeartbeat();
    this.startSiteInfoPoller();
    this.startLastAccessUpdater();
    this.setCookiePolicy();

    // Kick of persist queue handling
    this.startPersistQueue().catch(e => logError(e, 'startPersistQueue'));
  }

  private async startPersistQueue() {
    await this.recordQueue.cachePatches();
    await this.appService.refresh();
    this.persistQueuedChanges();
  }

  /**
   * Cancel any current modal activity.
   * This should be called to indicate that a mode cancelling gesture has occured
   * e.g. hitting the escape key or clicking in a neutral area.  The @see modeCancelled$
   * observer is fired so the modal operation can be cancelled.
   */
  public cancelMode() {
    this.modeCancelled$.next();
  }

  /** Call to indicate a change in layout */
  public layoutChanged() {
    this.layoutChanged$.next();
  }

  /** Return authenticated user.
   *  Unlike calling getCurrentUser directly, this can be overriden in test cases */
  public getUser(): User {
    return getCurrentUser();
  }

  /** Return authenticated user.
   *  Unlike calling tryGetCurrentUser directly, this can be overriden in test cases */
  public tryGetUser(): User {
    return tryGetCurrentUser();
  }

  /**
   * Configure dependent properties by observing changes and
   * updating related props.
   */
  private observeProperties() {
    // Monitor changes to old archived flag
    this.subscribe(this.appContextService.showArchived$, (archived) => {
      this.archived.value = archived;
    });

    // Monitor online (server reachable) state
    this.subscribe(this.onlineStatusService.isServerReachable$, (reachable) => {
      this.online.value = reachable;
    });

    this.subscribe(this.online.$, (online) => {
      if (online && this.recordQueue.isQueueActive) {
        // Persist any queued changes when we return to online status
        this.persistQueuedChanges();
      }
    });

    // Also pass our changes to old archived flag
    // NB These two subscriptions will trigger each other but as both
    // sides only notify on a change it will not cause death by recursion
    this.subscribe(this.archived.$, (archived) => {
      this.appContextService.ShowArchived = archived;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route.params;
        }),
        flatMap((params) => params),
        map((params) => new RouteParams(params)),
        distinctUntilKeyChanged('key', (k1, k2) => stringCompare(k1, k2) === 0)
      )
      .subscribe((params) => {
        this.routeParams.value = params;
      });

    this.subscribe(this.imagePersistService.persistQueueAwoken$, async () => {
      // Wake up queue - todo modelize, see pesistUpdateQueue
      // All it does is call record and image persist service but uses
      // the action to loop round if more to do.  Move to the queue
      // service and fire an observable when anything to process?
      await this.processQueues();
    });

    this.subscribe(this.media.asObservable(), () => {
      this.isSmallDisplay.value = this.media.isActive('lt-sm');
    });
  }

  public persistQueuedChanges() {
    this.imagePersistService.persistQueuedChanges();
  }

  /**
   * Process queues when a change is possible.
   * The persistQueueAwoken$ fires to start the process and is
   * fired again at the end if more processing needed.
   */
  private async processQueues() {
    try {
      if (!this._processingChanges) {
        this._processingChanges = true;

        // Process record and image queues
        const more = (
          await Promise.all([
            this.recordPersistService.persistChanges(this.appService),
            this.imagePersistService.persistChanges(),
            this.workflowTriggerService.triggerWorkflows(),
          ])
        ).some((m) => m);

        this._processingChanges = false;

        if (more) {
          this.persistQueuedChanges();
        }
      }
    } catch (err) {
      logError(err, 'AppDataEffects.pesistUpdateQueue.tap');
    } finally {
      this._processingChanges = false;
    }
  }

  private startSiteInfoPoller() {
    // poll every 5 minutes. The service has a 5 minute cache when the site manager app sets the message!
    this.subscribe(timer(5000, 300000), async () => {
      try {
        if (this.tryGetUser() && this.online.value && this.authorisationService.isValidAuthToken()) {
          const siteInfo = await lastValueFrom(this.http.get<{ SoftoolsMessage: string, ShowSoftoolsLogo: boolean }>(`${environment.baseUrl}/Api/SiteInfo`));
          this.siteMessage.value = siteInfo.SoftoolsMessage;
          this.showSoftoolsIcon.value = siteInfo.ShowSoftoolsLogo;
        }
      } catch (err) {
        logError(err, 'Error getting site info');
      }
    });
  }

  private startHeartbeat() {
    const url = `${environment.baseUrl}/Api/HeartBeat`;

    setInterval(async () => {
      if (this.zoneService.getCurrentZone() !== AppZone.sync && this.authenticated.value) {
        try {
          await this.http.get(url, { responseType: 'text' }).toPromise();

            this.offlineService.setOnlineStatus(true);
            this.onlineStatusService.isServerReachable = true;
          } catch {
            this.offlineService.setOnlineStatus(false);
            this.onlineStatusService.isServerReachable = false;
          }
        }
      }, 15000);
    }

  private startLastAccessUpdater() {
    setInterval(() => {
      const zone = this.zoneService.getCurrentZone();
      if (zone !== AppZone.login) {
        if (this.onlineStatusService.isConnected) {
          this.usersRepository.postLastAccess().subscribe(
            (_) => { },
            (error) => logError(error, 'Post last access')
          );
        }
      }
    }, 60000);
  }

  /**
   * Display a component as a dialog
   * @param component   Component defining content
   * @param data        Context data passed to component
   * @param options     Optional additional dialog config
   * @returns           Response from dialog component
   */
  public async dialogAsync<TComp, TData>(component: ComponentType<TComp>, data: TData, options?: MatDialogConfig<TData>) {
    const dialogData = options ? { ...options, data } : { data, maxWidth: '100vw', width: '100vw' };
    const dialogRef = this.dialog.open(component, dialogData);
    return await firstValueFrom(dialogRef.afterClosed());
  }

  public async showModalAsync(type: Enums.ModalType, title: string, text: string): Promise<boolean> {

    const options: ModalOptions = {
      Show: true,
      Type: type,
      Title: title,
      TextContent: text,
      MessageType: ModalMessageType.UseContent,
    };

    return await this.dialogAsync(ModalComponent, options);
  }

  /**
   * Show a defined confirmation message.
   * This is only used for "make available on/off line" confirmation
   * It's a bit convoluted because of how it tried to manage i18n on long messages
   * Use showModalAsync with type confirm in preference to this.
   * @param messageType message contetnt type
   * @returns           true if confirmed
   */
  public async confirmModalAsync(messageType: ModalMessageType): Promise<boolean> {

    const options: ModalOptions = {
      Show: true,
      Type: Enums.ModalType.confirm,
      MessageType: messageType,
    };

    return await this.dialogAsync(ModalComponent, { data: options });
  }

  public async showMessageDialogAsync(data: MessageDialogData) {
    return await MessageDialogComponent.show(this.dialog, data);
  }

  /** Show an error popup (toast) message */
  public showErrorToasty(config: IToastyConfig) {
    this.toastr.error(config.message, config.title ?? $localize`Error`);
  }

  /** Show an info popup (toast) message */
  public showInfoToasty(config: IToastyConfig) {
    this.toastr.info(config.message, config.title);
  }

  /** Show a success popup (toast) message */
  public showSuccessToasty(config: IToastyConfig) {
    const title = config.title ?? $localize`Success`;
    this.toastr.success(config.message, title);
  }

  /** Show a warning popup (toast) message */
  public showWarningToasty(config: IToastyConfig) {
    // Only display single warning at a time
    this.toastr.clear();
    this.toastr.warning(config.message, config.title);
  }

  /** Show a wait popup (toast) message */
  public showWaitToasty(config: IToastyConfig) {
    this.toastr.warning(config.message, config.title);
  }

  public showSoftoolsHelp() {
    // Show help by opening fixed webpage in a new tab
    window.open('https://support.softools.net', '_blank');
  }

  public openAppStudio(options?: { field: AppField, appIdentifier: string, templateIdentifier: string }) {
    const user = getCurrentUser();
    const baseAppStudioUrl = `https://${environment.appStudioUrlPrefix}${user.Tenant}.as.softools.net/AppStudio`;
    if (!options?.appIdentifier) {
      window.open(baseAppStudioUrl, '_blank');
    } else {
      const identifier = options.templateIdentifier || options.field.Identifier;
      window.open(`${baseAppStudioUrl}/App/Main/${options.appIdentifier}/${options.templateIdentifier ? 'Templates' : 'Fields'}?search=${identifier}`, '_blank');
    }
  }

  /**
   * Update access tokens on authentication.
   *
   * @param expires_in
   * @param access_token
   * @param refresh_token
   */
  public setAccessTokens(expires_in: string, access_token: string, refresh_token: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
    localStorage.setItem('refresh_token', refresh_token);

    // Store expiry time as UTC string
    const expiry = moment().utc().add(expires_in, 'seconds').format();
    localStorage.setItem('access_token_expires', expiry);

    const tokens: IAuthTokens = {
      token: access_token,
      refreshToken: refresh_token,
      expires: expiry
    }
    this.accessTokens.value = tokens;

    this.authenticated.value = !!tokens?.token;

    this.accessTokenSetEvent.fire();
  }

  private initAccessTokens() {
    const tokens: IAuthTokens = {
      token: localStorage.getItem(ACCESS_TOKEN_KEY),
      refreshToken: localStorage.getItem(ACCESS_TOKEN_KEY),
      expires: localStorage.getItem('access_token_expires')
    }
    this.accessTokens.value = tokens;
  }

  /** Log the current user out (by navigating to the logout page) */
  // returnUrl seems no longer used in pre-mvc code,
  // leaving it in as it feels like it should be
  public async logout(returnUrl?: string) {
    await this.navigation.navigateUrlAsync({ url: '/Logout' });
  }

  /** Download a file obtained from a background process */
  public async downloadFileAsync(processId: string, fileName: string) {
    const process = await this.backgroundProcessStorageService.get(processId);
    if (!process) {
      this.overlayService.openSpinner();
      this.downloadRepositpory.download(fileName).subscribe((file: { data: Blob, name: string }) => {
        this.overlayService.close();
        const a = <any>document.createElement('a');
        a.style = 'display: none';
        const fileURL = (this.windowRef.nativeWindow.URL || this.windowRef.nativeWindow.webkitURL).createObjectURL(file.data);
        a.href = fileURL;
        a.download = fileName;
        a.click();
        (this.windowRef.nativeWindow.URL || this.windowRef.nativeWindow.webkitURL).revokeObjectURL(file.data);
      });
    } else {
      const ext = fileName.split('.').pop();
      const info: DownloadInfo = { url: fileName, filename: `${process.filename}.${ext}` };
      await this.downloadNamedFileAsync(info);
    }
  }

  /** Download a file */
  public async downloadNamedFileAsync(payload: DownloadInfo) {
    this.overlayService.openSpinner();
    this.downloadRepositpory.download(payload.url).toPromise().then((file: { data: Blob, name: string }) => {
      this.overlayService.close();
      if (file) {
        const fileName = payload.filename || file.name || payload.url;
        this.createAndDownloadLink(fileName, file.data);
      }
    }, (err) => {
      this.overlayService.close();
      logError(err, 'Document field download failed');
    });
  }

  public acceptCookiePolicy() {
    this.cookiesAccepted.value = true;
    localStorage.setItem('CookiesAccepted', 'true');
  }

  private createAndDownloadLink(fileName: string, blob: Blob) {
    const a = <any>document.createElement('a');
    a.style = 'display: none';
    const fileURL = (this.windowRef.nativeWindow.URL || this.windowRef.nativeWindow.webkitURL).createObjectURL(blob);
    a.href = fileURL;
    a.download = fileName;
    a.click();
    (this.windowRef.nativeWindow.URL || this.windowRef.nativeWindow.webkitURL).revokeObjectURL(blob);
  }

  private setCookiePolicy() {
    this.cookiesAccepted.value = localStorage.getItem('CookiesAccepted') === 'true' ?? false;
  }
}

export class RouteParams {

  public key?: any;
  public appIdentifiers: AppIdentifiers;
  public appIdentifier: AppIdentifier;
  public recordIdentifier?: string;
  public formIdentifier?: string;
  public childAppIdentifier?: AppIdentifier;
  public reportIdentifier?: string;
  public parentRecordId?: string;
  public parentAppIdentifier?: string;
  public hierarchy?: string;
  public homepageIdentifier?: string;

  constructor(public params?: Params) {

    if (params) {
      this.appIdentifiers = new AppIdentifiers(params);
      this.appIdentifier = this.params[APP_IDENTIFIER] as AppIdentifier;
      this.recordIdentifier = this.params[RECORD_ID];
      this.formIdentifier = this.params[FORM_IDENTIFIER];
      this.parentAppIdentifier = this.params[PARENT_APP_IDENTIFIER] as AppIdentifier;
      this.childAppIdentifier = this.params[CHILD_APP_IDENTIFIER] as AppIdentifier;
      this.parentRecordId = params[PARENT_RECORD_ID] as string;
      this.reportIdentifier = this.params[REPORT_IDENTIFIER] as string;
      this.hierarchy = this.parentRecordId ? `${this.parentAppIdentifier}|${this.parentRecordId}` : '';
      this.homepageIdentifier = this.params[HOMEPAGE_IDENTIFIER] as string;
    }

    if (!this.key) {
      this.key = [
        this.appIdentifier,
        this.childAppIdentifier,
        this.reportIdentifier,
        this.recordIdentifier,
        this.parentRecordId,
        this.formIdentifier,
        this.hierarchy,
        this.homepageIdentifier
      ].map(s => s || '-').join('|');
    }
  }
}
