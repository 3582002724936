import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Homepage, HomepageStorageService, Report, Form, Enums } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { UsersService } from './users.service';

export interface NamedItems {
  app?: Application;
  reports: Array<{ app: Application, report: Report }>;
  forms: Array<{ app: Application, form: Form }>;
  homepage?: Homepage;
}

/**
 * Service that provides lookup of application related objects by identifier.
 * If a name is unique it can be unqualified, otherwise a context may be required
 */
@Injectable({
  providedIn: 'root'
})
export class IdentifiersService {

  private namedItems: Map<string, NamedItems>;

  private defaultHomepage: Homepage;

  constructor(
    private appService: AppService,
    private homeService: HomepageStorageService,
    private usersService: UsersService
  ) { }

  public async initialise(apps?: Array<Application>) {

    if (!apps) {
      apps = await this.appService.applicationsAsync();
    }

    const homepages = await this.homeService.getHomepages();
    this.map(apps, homepages);
  }

  public map(apps: Array<Application>, homepages?: Array<Homepage>) {
    this.namedItems = new Map<string, NamedItems>();
    apps.forEach(app => {

      let item = this.namedItems.get(app.Identifier);
      if (item) {
        item.app = app;
      } else {
        item = { app, reports: [], forms: [] };
        this.namedItems.set(app.Identifier, item);
      }

      app.Reports.forEach(report => {
        let reportItem = this.namedItems.get(report.Identifier);
        if (reportItem) {
          reportItem.reports.push({ app, report });
        } else {
          reportItem = { reports: [{ app, report }], forms: [] };
          this.namedItems.set(report.Identifier, reportItem);
        }
      });

      app.Forms.forEach(form => {
        let formItem = this.namedItems.get(form.Identifier);
        if (formItem) {
          formItem.forms.push({ app, form });
        } else {
          formItem = { reports: [], forms: [{ app, form }] };
          this.namedItems.set(form.Identifier, formItem);
        }
      });
    });

    if (homepages && homepages.length > 0) {
      homepages.forEach(homepage => {
        let homeItem = this.namedItems.get(homepage.Identifier);
        if (homeItem) {
          homeItem.homepage = homepage;
        } else {
          homeItem = { reports: [], forms: [], homepage };
          this.namedItems.set(homepage.Identifier, homeItem);
        }

        if (homepage.IsDefault) {
          this.defaultHomepage = homepage;
        }
      });

      if (!this.defaultHomepage) {
        // No default so use first homepage in display order
        this.defaultHomepage = homepages.sort((page1, page2) => page1.DisplayOrder - page2.DisplayOrder)[0];
      }
    }
  }

  /** Get application by id when it is known to refer to an application */
  public getApp(id: string): Application {
    const item = this.namedItems?.get(id);
    return item && item.app;
  }

  /** Get application by id if no other item uses the same id */
  public getUnambiguousApp(id: string): Application {

    // Homepage cannot be unambiguous as it macthes site home
    if (id === 'Homepage') {
      return null;
    }

    const item = this.namedItems?.get(id);
    if (item && item.reports.length === 0 && item.forms.length === 0 && !item.homepage) {
      return item.app;
    }
    return null;
  }

  public getUnambiguousReport(id: string): { app: Application, report: Report } {

    // Homepage cannot be unambiguous as it macthes site home
    if (id === 'Homepage') {
      return null;
    }

    // Reports cannot be unambiguous if it matches a report type
    if (Object.keys(Enums.ReportTypes).some(key => id === key)) {
      return null;
    }

    const item = this.namedItems?.get(id);
    if (item && !item.app && item.reports.length === 1 && !item.homepage) {
      return item.reports[0];
    }
    return null;
  }

  /** Get homepage by id when it is known to refer to an homepage */
  public getHomepage(id: string): Homepage {
    const item = this.namedItems?.get(id);
    return item && item.homepage;
  }

  /** Get homepage by id if no other item uses the same id */
  public getUnambiguousHomepage(id: string): Homepage {
    const item = this.namedItems?.get(id);
    if (item && item.reports.length === 0 && !item.app) {
      return item.homepage;
    }
    return null;
  }

  /**
   * Get the default homepage optionally taking the user's default into account.
   * @param useUserDefault true to use current user's default setting
   */
  public getDefaultHomepage(useUserDefault = true): Homepage {

    let page: Homepage = null;

    if (useUserDefault) {
      const userDefault = this.usersService.getCurrentUserDefaultHomepage();
      if (userDefault) {
        page = this.getHomepage(userDefault);
      }
    }

    return page || this.defaultHomepage;
  }
}
