import { Record, logError, App, Failure } from '@softools/softools-core';
import { AppField } from './app-field';
import { Field } from '@softools/softools-core';
import { RecordPatch } from 'app/workspace.module/types';
import { IApplication } from '../app-field-source';
import { DocumentAppField } from './document-app-field';
import { Application } from '../application';
import { IRuleState } from 'app/mvc/rules/rule-state.interface';
import { AppModel } from 'app/mvc';


export class GridCellField<TContained extends AppField = AppField> extends AppField {

  public ParentGridField: AppField;

  public SubFieldIndex: number;

  public DataSetIndex: number;

  /**
   * Construct a grid cell containing field data.
   * @param field Cell field metadata
   * @param containedField  Field metadata for contained field
   */
  constructor(field?: Field, public containedField?: TContained, app?: App & IApplication) {
    super(field, app);
  }

  public override initialise(app: Application) {
    const [gridId, datasetId = '', subfieldId = ''] = this.Identifier.split('_');
    this.ParentGridField = app.getField(gridId);
    this.DataSetIndex = this.ParentGridField.GridDataSets.findIndex(ds => ds.Identifier === datasetId);
    const subId = `${gridId}_${subfieldId}`;
    this.SubFieldIndex = this.ParentGridField.SubFieldsIdentifiers.findIndex(sf => sf.Identifier === subId);

    // Inherit some properties from subfield
    const sub = app.getField(subId);
    if (sub) {
      if (sub.DisplayFormatted) {
        this.DisplayFormatted = true;
      }

      if (this.containedField.ClickToEdit === undefined) {
        this.containedField.ClickToEdit = this.ParentGridField.ClickToEdit ?? sub.ClickToEdit;
      }

      if (sub.Formatting) {
        if (this.containedField.Formatting) {
          this.containedField.Formatting = { ...this.containedField.Formatting, ...sub.Formatting };
        } else {
          this.containedField.Formatting = sub.Formatting;
        }
      }
    }

    this.containedField.initialise(app);
  }

  public override attachModel(appModel: AppModel): void {
    super.attachModel(appModel);
    this.ParentGridField.attachModel(appModel);
  }

  public override compactRecord(_record: Record): any | null {
    // nop - the containing grid field is resposible for handling the cell
    // via compactCellRecord
    return null;
  }

  public compactCellRecord(record: Record): any | null {
    return super.compactRecord(record);
  }

  public override getDisplayRecordValue(record: Record, listRow?: number) {
    if (!record) { throw new Error(`Record not found for grid app field ${this.Identifier}`); }

    const array = record[this.ParentGridField.Identifier] as Array<Array<any>>;
    if (array) {
      try {
        const value = array && array[this.DataSetIndex][this.SubFieldIndex];

        if (this.DisplayFormatted) {
          if (value && value.hasOwnProperty('fmt')) {
            return value.fmt;
          }

          // fallback
          const formattedName = `${this.Identifier}_Formatted`;
          if (record.hasOwnProperty(formattedName)) {
            return record[formattedName];
          }
        }


        if (value && value.hasOwnProperty('val')) {
          return this.formatDisplayValue(value.val);
        } else {
          return this.formatDisplayValue(value);
        }
        // return array && array[this.DataSetIndex][this.SubFieldIndex];
      } catch (err) {
        logError(err, '');
        throw new Error(`Index not found for gridfield ${this.Identifier} for parent gridfield ${this.ParentGridField.Identifier} in app ${record.AppIdentifier}`);
      }
    } else {
      // If we don't have a value array, fall back to data stored under the field id
      // This shouldn't be the case for real record data but may be for pseudo-record data
      // e.g. filter values.
      const directValue = record[this.Identifier];
      return directValue;
    }
  }


  public override getRecordValue(record: Record) {

    if (!record) { throw new Error(`Record not found for grid app field ${this.Identifier}`); }

    const array = record[this.ParentGridField.Identifier] as Array<Array<any>>;
    if (array) {
      try {
        const value = array && array[this.DataSetIndex] && array[this.DataSetIndex][this.SubFieldIndex];
        const actual = this.containedField.adaptInternalValue(value);

        if (this.DisplayFormatted) {
          if (actual?.hasOwnProperty('fmt')) {
            return actual.fmt;
          }

          // fallback
          const formattedName = `${this.Identifier}_Formatted`;
          if (record.hasOwnProperty(formattedName)) {
            return record[formattedName];
          }
        }

        if (actual?.hasOwnProperty('val')) {
          return actual.val;
        } else {
          return actual;
        }

      } catch (err) {
        logError(err, '');
        throw new Error(`Index not found for gridfield ${this.Identifier} for parent gridfield ${this.ParentGridField.Identifier} in app ${record.AppIdentifier}`);
      }
    } else {
      // If we don't have a value array, fall back to data stored under the field id
      // This shouldn't be the case for real record data but may be for pseudo-record data
      // e.g. filter values.
      const directValue = record[this.Identifier];
      return directValue;
    }
  }

  public override getRawRecordValue(record: Record) {
    const value = this.getInternalRecordValue(record);
    if (value && value.hasOwnProperty('val')) {
      return value.val;
    } else {
      return value;
    }
  }

  /**
   * Get the internal data value for this field from the supplied record.
   * If the field has associated backing field values, this is a structure
   * containing them all.  If there are not backing values, it is a simple
   * value.
   */
  public override getInternalRecordValue(record: Record, _listRow?: number) {
    if (!record) { throw new Error(`Record not found for grid app field ${this.Identifier}`); }

    let value: any;

    const array = record[this.ParentGridField.Identifier] as Array<Array<any>>;
    if (array) {
      try {
        value = array && array[this.DataSetIndex] && array[this.DataSetIndex][this.SubFieldIndex];
      } catch (err) {
        logError(err, `Index not found for gridfield ${this.Identifier} for parent gridfield ${this.ParentGridField.Identifier} in app ${record.AppIdentifier}`);
      }
    } else {
      // If we don't have a value array, fall back to data stored under the field id
      // This shouldn't be the case for real record data but may be for pseudo-record data
      // e.g. filter values.
      value = record[this.Identifier];
    }

    return value;
  }

  public override adaptValue(value: any, current: any, record?: Record): any {
    return this.containedField.adaptValue(value, current, record);
  }

  public override adaptStringValue(value: string): any {
    return this.containedField.adaptStringValue(value);
  }

  /** Convert the field internal value into the format required by the field component */
  public override adaptInternalValue(value: any) {
    return this.containedField.adaptInternalValue(value);
  }

  public override storeToRecord(record: Record, value: any) {
    let array = record[this.ParentGridField.Identifier] as Array<Array<any>>;
    if (!array) {
      // Initialise parent array if not set
      // todo this is badly encapsulated, revisit creation and do through
      // parent GridAppField.  Complicated by not assuming creation order.
      array = new Array<Array<any>>(this.ParentGridField.GridDataSets.length);
      for (let i = 0; i < array.length; ++i) {
        array[i] = new Array<any>(this.ParentGridField.SubFieldsIdentifiers.length);
      }
      record[this.ParentGridField.Identifier] = array;
    }

    array[this.DataSetIndex][this.SubFieldIndex] = value;
  }

  public override cloneValue(record: Record) {
    const array = record[this.ParentGridField.Identifier] as Array<Array<any>>;
    if (array) {
      record[this.ParentGridField.Identifier] = [...array].map(sub => [...sub]);
    }
  }

  public override setBacking(record: Record, value: any) {
    this.containedField.setBacking(record, value);
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    return this.containedField && this.containedField.compareValues(val1, val2, isDescending);
  }

  public override formatDisplayValue(value: any) {
    return this.containedField?.formatDisplayValue(value);
  }

  public override editableValue(value: any): string {
    return this.containedField?.editableValue(value);
  }

  public override validate(value: any, errors: Array<Failure>): void {
    if (this.containedField) {
      this.containedField.validate(value, errors);
    }
  }

  public override createPatch(record: Record | RecordPatch, value: any, _listRow: number | string, options?: { local?: boolean; }): RecordPatch {
    return this.containedField.createPatch(record, value, _listRow, options);
  }

  public override updatePatchWithDefault(patch: RecordPatch) {
    if (this.containedField) {
      this.containedField.updatePatchWithDefault(patch);
    }
  }

  public createFilePatch(record: Record, assetId: string, file: File, listRow?: number) {
    // hack - pretend to be a doc field if we need to
    // See SelectionGridCellField for the right way to do this
    if (this.containedField instanceof DocumentAppField) {
      return this.containedField.createFilePatch(record, assetId, file, listRow);
    }

    return null;
  }

  public createResetPatch(record: Record, listRow?: number) {
    // hack - pretend to be a doc field if we need to
    // See SelectionGridCellField for the right way to do this
    if (this.containedField instanceof DocumentAppField) {
      return this.containedField.createResetPatch(record, listRow);
    }

    return null;
  }

  public override isDisabled(record: Record, ruleState?: IRuleState): boolean {
    return this.ParentGridField.isDisabled(record, ruleState) || super.isDisabled(record, ruleState);
  }

  public override get clickToEdit() {
    return this.containedField.clickToEdit;
  }
}
