import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Enums, logError, LookupCaptionStyle, LookupOptions, Record } from '@softools/softools-core';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { AppModel, FilterModel, TableReportModel, RouteParams, ListReportModel, GlobalModel } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { RecordsReportModel } from 'app/mvc/reports/records-report.model';
import { ReportController } from 'app/mvc/reports/report.controller';
import { AppIdentifiers } from 'app/services/record/app-info';
import { filterMgtAnimationNarrow, quickFiltersAnimation } from 'app/workspace.module/animations/ws-animations';
import { BehaviorSubject } from 'rxjs';
import { ComponentBase } from '../component-base';
import { SearchComponent } from '../search.component/search.component';
import { ControlColumnMode, SelectionMode } from '../table-report/table-report-types';
import { RecordSelectorFilterModel } from './record-selector-filter.model';
import { RecordSelectorTableReportController } from './record-selector-table-report-controller';

export interface SelectedRecords {
  // one of all or ids must be set
  all?: boolean;
  ids?: Set<string>;
}

@Component({
  selector: 'app-record-selector',
  templateUrl: './record-selector.component.html',
  styleUrls: ['./record-selector.component.scss'],
  animations: [
    filterMgtAnimationNarrow,
    quickFiltersAnimation,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordSelectorComponent extends ComponentBase implements OnInit, OnDestroy {

  public readonly globalModel: GlobalModel;
  public readonly appModel: AppModel;
  public readonly filterModel: FilterModel;
  public readonly reportModel: RecordsReportModel;

  public readonly listReportModel: ListReportModel;
  public readonly tableReportModel: TableReportModel;

  public readonly showFilterManagement$ = new BehaviorSubject<boolean>(false);

  public filterOpenState$ = new BehaviorSubject('inactive');

  public readonly ready$ = new BehaviorSubject<boolean>(false);

  public readonly showSearch$ = new BehaviorSubject<boolean>(false);

  public initialised$ = new BehaviorSubject(false);

  public records: Array<Record>;

  private firstRecords = true;

  public selected: SelectedRecords = {};

  public captionStyle: LookupCaptionStyle = LookupCaptionStyle.Lookup;

  public readonly captionStyles = LookupCaptionStyle;

  public readonly controlColumnModes = ControlColumnMode;

  public readonly reportController: ReportController;

  public readonly tableReportController: RecordSelectorTableReportController;

  public popupPosition = [
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' }
    ),
  ];

  @ViewChild('table', { static: false })
  private tableReport: any;   // TableReportComponent but avoiding circular ref

  @ViewChild(SearchComponent)
  public searchComponent: SearchComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LookupOptions,
    private dialog: MatDialogRef<RecordSelectorComponent>,
    models: GlobalModelService,
  ) {
    super();

    // Create models
    this.globalModel = models.globalModel;
    this.appModel = new AppModel(this.globalModel, models.launchpadModel, models.siteModel);
    this.filterModel = new RecordSelectorFilterModel(this.appModel);
    // this.reportModel = new TableReportModel(this.appModel, this.filterModel, globalModelService.pageModel);

    if (data.report?.Type === Enums.ReportTypes.List) {
      this.reportModel = this.listReportModel = new ListReportModel(this.appModel, this.filterModel, models.pageModel);
      this.reportController = new ReportController(this.reportModel);
    } else {
      // set up controller for lookup mode
      this.reportModel = this.tableReportModel = new TableReportModel(this.appModel, this.filterModel, models.pageModel);
      this.reportController = this.tableReportController = new RecordSelectorTableReportController(this.reportModel);
    }
  }

  public ngOnInit(): void {
    if (this.data) {   // not set in tests, messy to fake
      this.initialise().catch(error => logError(error, 'Failed to init record selector'));
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.reportController?.dispose();
    this.reportModel?.dispose();
  }

  public selectedRecord() {
    return this.reportModel.selectionModel.selectedId;
  }

  public result(): SelectedRecords {
    const selectionModel = this.reportModel.selectionModel;
    if (selectionModel.all.value) {
      return { all: true };
    } else if (selectionModel.singleSelection) {
      return { ids: new Set<string>([selectionModel.selectedId]) };
    } else {
      return { ids: selectionModel.selected.value };
    }
  }

  acceptClicked() {
    this.dialog.close(this.result());
  }

  public filterEditCancelled(): void {
    this.reportModel.closeFilterEditor();
  }

  public filterTermUpdated(updates: FilterTermUpdates) {
    this.filterModel.updateFilterTerms(updates);
    this.reportModel.closeFilterEditor();
  }

  public filterButtonClicked($event: MouseEvent) {
    $event.stopPropagation();
    const state = this.showFilterManagement$.value;
    this.showFilterManagement$.next(!state);
  }

  public filterManagementClosed() {
    this.showFilterManagement$.next(false);
  }

  public filterPopupX() {
    return this.reportModel.filterEditorUi.value?.popupX - this.tableReport?.scrollXpos();
  }

  public tableSelectionMode(): SelectionMode {
    return this.data.multiSelect ? SelectionMode.MulitpleRows : SelectionMode.Row;
  }

  public multiSelectionMode(): boolean {
    return this.data.multiSelect;
  }


  public cancelClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  public async setSearch(term: string) {
    await this.reportModel.setSearchTerm(term, false).catch(error => logError(error, 'Failed to set search term'));
  }

  public clearSearch() {
    this.reportModel.clearSearchTerm(false);
  }

  private async initialise() {

    if (this.data.captionStyle) {
      this.captionStyle = this.data.captionStyle;
    }

    // Set model app - todo Api
    const appIdentifiers = new AppIdentifiers();
    appIdentifiers.appIdentifier = this.data.appIdentifier;

    this.appModel.initialise();
    this.appModel.configure(appIdentifiers);

    const reportId = this.data.reportIdentifier;
    await this.filterModel.initialise();
    await this.filterModel.configure(reportId);

    this.reportModel.selectionMode.value = this.tableSelectionMode();

    const selectionModel = this.reportModel.selectionModel;
    selectionModel.singleSelection = !this.data.multiSelect;

    this.subscribe(selectionModel.$, () => {
      this.ready$.next(selectionModel.count > 0);
    });

    await this.reportModel.initialise();

    this.subscribe(this.reportModel.records.$, async (records) => {
      if (this.firstRecords && records?.length > 0) {
        this.firstRecords = false;
        this.searchComponent?.focus();
      }
    });

    // If we've been given initial selection, apply it
    if (this.data.selectedIds?.length > 0) {
      selectionModel.batch(() => {
        this.data.selectedIds.forEach(id => {
          selectionModel.select(id);
        });
      });
    }

    const params = new RouteParams();
    params.appIdentifier = appIdentifiers.appIdentifier;
    params.appIdentifiers = appIdentifiers;
    params.reportIdentifier = reportId;
    await this.reportModel.routed(params);

    const searchable = this.appModel.app.value?.Fields.findIndex(f => f.IncludeInSearch) >= 0;
    this.showSearch$.next(searchable);

    if (searchable && this.data.searchValue) {
      await this.setSearch(this.data.searchValue);
    }

    this.subscribe(this.showFilterManagement$, (visble) => {
      this.filterOpenState$.next(visble ? 'active' : 'inactive');
      setTimeout(() => this.globalModel.layoutChanged(), 400);
    });

    this.initialised$.next(true);
  }
}
