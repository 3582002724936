<div class="h-100">
  <div class="quick-filters-2" style="overflow-y: hidden;">
    <div [vxWatch]="[
      reportController?.quickFiltersPinned,
      reportModel.report,
      reportModel?.appModel?.globalModel.online
      ]"
      class="pin px-2 my-2 d-flex justify-content-between">
      <ng-container *ngIf="reportController?.quickFiltersPinned.value; then pinned; else unpinned" ></ng-container>
      <span>
        <b style="font-size: large;" class="p-4">Filter</b>
      </span>
      <fa-icon class="pointer mid-sized" [icon]="['fal', 'square-x']" size="2x"
      (click)="closeClicked($event)" ></fa-icon>
    </div>
    <ng-container *ngIf="this.reportModel.report.value as report"  >
      <div [vxWatch]="[model.quickFilter, filterModel.additionalFilter]" class="filter-fields py-2">
        <ng-container *ngFor="let quick of model.quickFilter.value?.quickFields">
          <div class="quick-filter-field">
            <div *ngFor="let operand of operands(quick); let i=index;" 
              class="w-100 px-2 d-flex justify-content-between align-items-center">
              <app-quick-filter-field class="w-100 flex-fill"
                [filterModel]="filterModel"
                [row]="i"
                [hideLabel]="i > 0"
                [appModel]="reportModel?.appModel"
                [application]="reportModel?.appModel?.app.value"
                [appIdentifiers]="reportModel?.appModel?.appIdentifiers.value"
                [fieldModel]="quick.field"
                [elementStyles]="fieldStyle"
                [term]="quick.term"
                [value]="operand"
                [isOnline]="reportModel?.appModel?.globalModel.online.value"
                [termEditedHandler]="this"
              >
              </app-quick-filter-field>
            </div>
            <div style="width: 3rem;" *ngIf="showAddRow(quick)" class="pointer p-2" (click)="addRowClicked($event, quick)">
              <fa-icon [icon]="['fal', 'circle-plus']" size="2x" ></fa-icon>  
            </div>
          </div>
        </ng-container>
        <!-- <div class="p-3">
          <button type="button" class="control-button" (click)="saveFilter($event)">
            <fa-icon [icon]="['fal', 'save']" size="1x" ></fa-icon>
            <span class="px-2">Save</span>
          </button>
        </div> -->
      </div>

      <div [vxWatch]="model" class="ordering top-border py-3">

        <div class="px-3 pt-1">
          <button  #clear  type="button" class="control-button mx-1 primary-line padded" (click)="clearAll($event)">
            <fa-icon [icon]="['fal', 'trash']" size="1x" ></fa-icon>
            <span i18n class="px-2">Clear All</span>
          </button>
        </div>

        <div class="group-by d-flex flex-column" *ngIf="model.groupField.value as groupField" >
          <div title="Group by" i18n-title  class="flex-fill d-flex" style="overflow: hidden;">
            <div class="icon-box" >
              <fa-icon size="1x" [icon]="['fal', 'layer-group']"></fa-icon>
            </div>
            <span class="order-text">{{groupField.Label}}</span>
            <!-- <span class="order-text" >
            </span> -->
          </div>
        </div>

        <div class="order-by d-flex flex-column" *ngIf="model.orderField.value as orderField" >
          <ng-container *ngIf="model.orderDescending.value; then odesc; else oasc;"  ></ng-container>
          <ng-template #oasc >
            <div title="Sort by (ascending)" i18n-title  class="flex-fill d-flex" style="overflow: hidden;">
              <div class="icon-box" >
                <fa-icon size="1x" [icon]="['fad', 'sort-up']"></fa-icon>
              </div>
              <span class="order-text">{{orderField.Label}}</span>
            </div>
          </ng-template>
          <ng-template #odesc>
            <div title="Sort by (descending)" i18n-title  class="flex-fill d-flex" style="overflow: hidden;">
              <div class="icon-box" >
                <fa-icon size="1x" [icon]="['fad', 'sort-down']"></fa-icon>
              </div>
              <span class="order-text">{{orderField.Label}}</span>
            </div>
          </ng-template>
        </div>
        <div *ngIf="model.quickFilter.value?.reportFields?.length" class="report-filters pb-2">
          <div *ngFor="let quick of model.quickFilter.value?.reportFields"
            class="report-filter-field">
            <div *ngFor="let operand of operands(quick); let i=index;" 
              class="w-100 px-2 d-flex flex-column justify-content-start align-items-center">
              <app-quick-filter-field class="w-100 flex-fill"
                [filterModel]="filterModel"
                [row]="i"
                [displayOnly]="true"
                [hideLabel]="i > 0"
                [appModel]="reportModel?.appModel"
                [application]="reportModel?.appModel?.app.value"
                [appIdentifiers]="reportModel?.appModel?.appIdentifiers.value"
                [fieldModel]="quick.field"
                [elementStyles]="displayStyle"
                [term]="quick.term"
                [value]="operand"
                [isOnline]="reportModel?.appModel?.globalModel.online.value"
                [termEditedHandler]="this"
              >
              </app-quick-filter-field>
            </div>
          </div>
        </div>
        <div class="additional-filter p-3" *ngIf="filterModel.additionalFilter.value as baz">
          <div class="w-100 d-flex align-items-center">
            <span class="flex-fill">Additional Filter</span>
            <fa-icon class="pointer" 
              (click)="clearAdditionalFilter()"
              [icon]="['fal', 'circle-trash']" size="2x" ></fa-icon>
          </div>
          <div class="w-100">
            <span style="font-size:smaller;">{{baz.Filter}}</span>
          </div>
        </div>
      </div>

      <!-- <div class="base-saved-filter w-100 d-flex" *ngIf="baseSavedName$|async as basename" >
        <div class="flex-fill d-flex" style="overflow: hidden;">
          <span style="text-overflow: ellipsis; white-space: nowrap; width: 16rem; font-size: medium;">
            <fa-icon
              size="1x"
              [icon]="['fas', 'star-exclamation']"
              [classes]="['st-text-theme-primary']"
            ></fa-icon>
            {{basename}}
          </span>
        </div>
      </div>

      <div class="base-filters w-100 d-flex" *ngIf="baseFilter$|async as base" >
        <div class="flex-fill d-flex" style="overflow: hidden;">
          <span style="text-overflow: ellipsis; white-space: nowrap; width: 16rem; font-size: medium;">
            <fa-icon
              size="1x"
              [icon]="['fas', 'filter']"
              [classes]="['st-text-theme-primary']"
            ></fa-icon>
            {{base}}
          </span>
        </div>
      </div> -->

    </ng-container>

    <!-- <div class="controls">
      <button type="button" class="control-button" (click)="savedFiltersClicked($event)">
        <fa-icon [icon]="['fal', 'filter']" size="1x" ></fa-icon>
        <span class="px-2" i18n>Saved Filters</span>
      </button>
    </div> -->
  </div>
</div>

<ng-template #pinned>
  <fa-icon class="pointer pinned"
    [icon]="['fas', 'thumbtack']" size="2x"
    (click)="pullPin($event)"
  ></fa-icon>
</ng-template>

<ng-template #unpinned>
  <fa-icon class="pointer mid-sized"
    [icon]="['fal', 'thumbtack']" size="2x"
    (click)="pushPin($event)"
  ></fa-icon>
</ng-template>
