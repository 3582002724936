<ng-container *ngIf="forReport; else forField">
  <ng-container *ngTemplateOutlet="imageView; context: { showzoom: false, size: 'tiny' }"></ng-container>
</ng-container>

<ng-template #forField>
  <ng-container *ngTemplateOutlet="imageView; context: { showzoom: false, size: 'medium' }"></ng-container>
</ng-template>

<ng-container *ngIf="showButtons">
  <ng-container *ngTemplateOutlet="buttons; context: { showzoom: false, size: 'tiny' }"> </ng-container>
</ng-container>

<ng-template #imageView let-showzoom="showzoom" let-size="size">
  <ng-container *ngIf="imageValue; else upload">
    <!-- The max thumbnail is 500px -->
    <div style="max-width: 500px">
      <app-asset-image
        class="w-100 d-flex"
        [ngClass]="alignment"
        [style.cursor]="cursor"
        [uiIdentifier]="uiIdentifier"
        [assetUrl]="imageValue"
        [isImageField]="true"
        [size]="size"
        (clicked)="onImageClickHandler($event)"
      >
      </app-asset-image>
    </div>
  </ng-container>
</ng-template>

<ng-template #buttons let-showzoom="showzoom">
  <ng-container *ngIf="enabled$ | async">
    <input type="file" accept="image/*" hidden #imageFileSelector (change)="onImageFileSelectedHandler($event)" />

    <ng-container *ngIf="!deleteMode; else deleteModeTemplate">
      <div class="action1-area">
        <button
          i18n-title
          type="button"
          title="Browse for image to upload"
          (click)="imageFileSelector.click()"
          class="primary-line me-4"
          type="button"
        >
          <fa-icon [icon]="['fal', 'cloud-upload-alt']" [border]="false"></fa-icon>
          <span i18n>Upload</span>
        </button>
      </div>

      <div class="action2-area">
        <button type="button" class="primary-line" (click)="openCamera()" i18n-title title="Open camera" *ngIf="!disableCamera">
          <fa-icon [icon]="['fal', 'camera']" [border]="false"></fa-icon>
          <span i18n>Open</span>
        </button>
      </div>

      <ng-container *ngIf="hasImageValue">
        <div class="action3-area">
          <button *ngIf="showzoom" type="button" class="primary-line" (click)="zoomImage()" i18n-title title="Show full size image">
            <fa-icon [icon]="['fal', 'search']" [border]="false"></fa-icon>
            <ng-container *ngIf="loadingLargeImage; else zoomTemplate">
              <span i18n>Loading...</span>
            </ng-container>
            <ng-template #zoomTemplate>
              <span i18n>Zoom</span>
            </ng-template>
          </button>
        </div>

        <div class="action4-area">
          <button *ngIf="enabled$ | async" type="button" class="primary-line" (click)="toggleDeleteMode()" i18n-title title="Delete">
            <fa-icon [icon]="['fal', 'trash']" [border]="false"></fa-icon>
            <span i18n>Delete</span>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <div class="action6-area">
      <button *ngIf="enabled$ | async" type="button" class="close" (click)="selectorClosed()" i18n-title title="Close">
        <fa-icon [icon]="['fal', 'times']" [border]="false"></fa-icon>
        <span i18n>Close</span>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #upload>
  <div (drop)="fileDropped($event)" (dragover)="fileDraggedOver($event)">
    <!-- hidden on mobile unless in click to edit mode -->
    <div
      *ngIf="showUploadIcon()"
      class="regular w-100 d-flex"
      [ngClass]="alignment"
      [class.d-none]="showButtons"
      [class.d-sm-block]="showButtons"
      [class.pointer]="!showButtons && !isDisabled()"
      [class.bottomAlign]="showButtons"
      (click)="onNoImageClickHandler($event)"
    >
      <fa-icon [icon]="['fal', 'cloud-upload-alt']" [size]="uploadIconSize" class="uploadIcon"></fa-icon>
    </div>
  </div>
</ng-template>

<ng-template #deleteModeTemplate>
  <div class="action4-area">
    <button
      i18n-title
      type="button"
      title="Cancel delete"
      (click)="toggleDeleteMode()"
      class="primary me-4"
      style="background-color: red !important"
      type="button"
    >
      <fa-icon [icon]="['fal', 'times']" [border]="false" size="lg"></fa-icon>
      <span i18n>Cancel</span>
    </button>
  </div>

  <div class="action1-area">
    <button
      i18n-title
      type="button"
      title="Confirm delete"
      class="primary"
      style="background-color: green !important"
      id="imageDeleteButton"
      color="primary"
      (click)="onImageDeleteClickHandler()"
    >
      <fa-icon [icon]="['fal', 'check']" [border]="false" size="lg"></fa-icon>
      <span i18n>Delete</span>
    </button>
  </div>
</ng-template>

<app-popup-container [isOpen]="showSelectionPopup" (closed)="selectorClosed()">
  <ng-template>
    <div class="p-4 selectionContainer rounded-4">
      <div class="image-area pb-4">
        <ng-container *ngTemplateOutlet="imageView; context: { showzoom: true, size: 'medium' }"></ng-container>
      </div>
      <ng-container *ngIf="!isDisabled()">
        <ng-container *ngTemplateOutlet="buttons; context: { showzoom: true }"> </ng-container>
      </ng-container>
    </div>
  </ng-template>
</app-popup-container>
