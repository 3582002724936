
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RefreshComponent } from './refresh/refresh.component';
import { environment } from 'environments/environment';
import { logError, OnlineStatusService } from '@softools/softools-core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { ZoneService } from 'app/services/zone.service';
import { AppZone } from 'app/types/enums';

@Component({
  selector: 'app-versioncheck',
  templateUrl: './versioncheck.component.html',
  styleUrls: ['./versioncheck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionCheckComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private onlineStatusService: OnlineStatusService,
    private updates: SwUpdate,
    private zoneService: ZoneService) { }

  public ngOnInit(): void {
    if (environment.production && this.onlineStatusService.isConnected) {

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      // const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      // now being done in module init
      interval(60000).subscribe(() => {
        if (this.updates.isEnabled && this.onlineStatusService.isConnected && this.zoneService.getCurrentZone() !== AppZone.sync && this.zoneService.getCurrentZone() !== AppZone.login) {
          this.updates.checkForUpdate().catch(e => logError(e, 'Failed to check for updates'));
        }
      });

      this.updates.available.subscribe(() => {
        this.snackBar.openFromComponent(RefreshComponent, {});
      });
    }
  }
}
