import { ChartEnums } from 'app/services/chart';

/**
 * Exception thrown on error loading or creating chart data
 */
export class ChartErrorException extends Error {

  constructor(public readonly chartError: ChartEnums.ChartErrors) {
    super();
  }

}
