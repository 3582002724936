import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { Observable } from 'rxjs';
import { ChartData } from '../types/interfaces/chart-data';
import { QueryParams, Record } from '../types';

@Injectable({ providedIn: 'root' })
export class ReportDataRepository extends RepositoryBase {

  public getData(
    appIdentifier: string,
    reportIdentifier: number,
    queryParams: QueryParams,
    hierarchy: string = '',
    archived = false
  ): Observable<Array<Record>> {

    let url = this.urlResolver.resolveGetReportDataUrl(appIdentifier, reportIdentifier, hierarchy, archived);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.get(url);
  }

  public getCursorData(
    appIdentifier: string,
    reportIdentifier: number,
    cursor: string,
    queryParams: {},
    showArchived = false,
    hierarchy: string = ''
  ): Observable<Record[]> {
    let url = this.urlResolver.resolveReportDataCursorUrlByIdentifier(appIdentifier, reportIdentifier.toString(), cursor, showArchived, hierarchy);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.get(url);
  }

  public getHighChartsData(appIdentifier: string, reportIdentifier: string, queryParams: {}, hierarchy: string, archived = false): Observable<ChartData> {
    let url = this.urlResolver.resolveReportDataUrlByIdentifier(appIdentifier, reportIdentifier) + '/HighchartsSeries';
    if (archived) {
      url += '/Archived';
    }
    if (hierarchy) {
      url += `/${hierarchy}`;
    }
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.get(url);
  }

  public getHighChartsDataForInAppChart(appIdentifier: string, reportIdentifier: string, recordId: string): Observable<any> {
    const url = this.urlResolver.resolveReportDataUrlByIdentifier(appIdentifier, reportIdentifier) + '/HighchartsInAppSeries/' + recordId;
    return this.get(url);
  }

  public refresh(appIdentifier: string, reportId: number): Observable<any> {
    const url = this.urlResolver.resolveReportDataUrl(appIdentifier, reportId) + '/Refresh';
    return this.post(url);
  }

  public exportAppLevel(appIdentifier: string, reportId: number, selectedRows: Array<any>, excludedColumns: Array<string>, flags: ReportDataExportFlags, query: any) {
    const model = {
      ExcludedColumns: excludedColumns,
      SelectedRows: selectedRows
    };

    delete query['$top'];
    delete query['$skip'];

    // Remove empty/unset params
    if (!query.$search) {
      delete query.$search;
    }

    if (!query.hierarchy) {
      delete query.hierarchy;
    }

    const queryString = this.urlResolver.getQueryString(query);
    const url =
      this.urlResolver.resolveDefaultApiUrl('Apps', appIdentifier) + '/Data/Export/' + reportId + '/' + flags + queryString;
    return this.post(url, JSON.stringify(model));
  }
}

export enum ReportDataExportFlags {
  None = 0,
  ReportFieldsOnly = 1,
  ExcludeReadOnlyFields = 2
}