import { Injectable } from '@angular/core';
import { IndexedAppData, Record, Report } from '@softools/softools-core';
import { MonteCarloSeries, MatrixTableData, MonteCarloChartField } from '../chart-utilities';
import { BaseChartDataService } from './base-chart-data.service';
import { ChartEnums } from '../chart.enums';
import { Application } from 'app/types/application';

@Injectable({
  providedIn: 'root'
})
export class MonteCarloChartDataService extends BaseChartDataService {
  public async getMonteCarloChartDataOptions(app: Application, appDataIndex: IndexedAppData, report: Report, record?: Record) {
    const monteCarloChartData = (<MonteCarloChartField[]>report.Chart.ChartFields).filter(f => f.BestCaseFieldIdentifier != null && f.ExpectedCaseFieldIdentifier != null && f.WorstCaseFieldIdentifier != null);

    if (monteCarloChartData == null) {
      throw new Error('Invalid chart configuration - no X-axis fields');
    }
    monteCarloChartData.forEach(chartField => {
      chartField.BestCaseField = app.getField(chartField.BestCaseFieldIdentifier);
      chartField.ExpectedCaseField = app.getField(chartField.ExpectedCaseFieldIdentifier);
      chartField.WorstCaseField = app.getField(chartField.WorstCaseFieldIdentifier);
    });
    const series = new Array<any>();

    const recordCount = record ? 1 : appDataIndex.length;

    if (recordCount <= 0) {
      return { Series: series };
    }

    const sData: MonteCarloSeries[] = monteCarloChartData.map(s => ({
      Series: s,
      RunningTotal: 0,
      Totals: new Array<number>(),
      BellValues: new Array<{ key: number, value: number }>(),
      CurveValues: new Array<{ key: number, value: number }>()
    } as MonteCarloSeries));

    let minVal = Number.MAX_VALUE;
    let maxVal = Number.MIN_VALUE;

    for (const s of sData) {
      const bestArray = new Array<number>(recordCount);
      const expectedArray = new Array<number>(recordCount);
      const worstArray = new Array<number>(recordCount);

      let pointer = 0;

      const recordCallbackFunction = (rec) => {
        const bestValue = +this.getFieldValue(app, s.Series.BestCaseFieldIdentifier, s.Series.BestCaseField, rec, appDataIndex);
        bestArray[pointer] = bestValue || 0;

        const expectedValue = +this.getFieldValue(app, s.Series.ExpectedCaseFieldIdentifier, s.Series.ExpectedCaseField, rec, appDataIndex);
        expectedArray[pointer] = expectedValue || 0;

        const worstValue = +this.getFieldValue(app, s.Series.WorstCaseFieldIdentifier, s.Series.WorstCaseField, rec, appDataIndex);
        worstArray[pointer] = worstValue || 0;
        pointer += 1;
      };

      if (record) {
        const records = this.getInAppRecordData(app, report, record);
        await records.forEach(recordCallbackFunction);
      } else {
        await appDataIndex.eachRecord(recordCallbackFunction);
      }

      s.BestValues = bestArray;
      s.ExpectedValues = expectedArray;
      s.WorstValues = worstArray;
    }

    let iterations: number;
    if (report.Chart.Iteration != null) {
      switch (report.Chart.Iteration) {
        case ChartEnums.MonteCarloIterations.OneHundredThousand:
          iterations = 100000;
          break;
        case ChartEnums.MonteCarloIterations.FiveHundredThousand:
          iterations = 500000;
          break;
        case ChartEnums.MonteCarloIterations.OneMillion:
          iterations = 1000000;
          break;
      }
    }

    for (let i = 1; i <= iterations; i++) {
      sData.forEach(s => {
        for (let pointer = 0; pointer <= recordCount - 1; pointer++) {
          s.RunningTotal += this.triangularDistribution(s.BestValues[pointer], s.ExpectedValues[pointer], s.WorstValues[pointer], Math.random());
        }
      });

      sData.forEach(s => {
        if (s.RunningTotal < minVal) { minVal = s.RunningTotal; }
        if (s.RunningTotal > maxVal) { maxVal = s.RunningTotal; }
        s.Totals.push(s.RunningTotal);
        s.RunningTotal = 0;
      });
    }

    if (minVal === maxVal) {
      return { Series: series };
    }

    // Now split the totals into 100 'buckets' for the chart
    const axisSteps = 100;
    const stepVal = (maxVal - minVal) / axisSteps;

    for (let i = 0; i <= axisSteps; i++) {
      const thisStep = minVal + (i * stepVal);
      sData.forEach(s => {
        s.BellValues.push({ key: thisStep, value: 0 });
      });
    }

    sData.forEach(s => {
      s.Totals.sort(); // The totals need to be sorted in numeric order for us to get the percentile values

      s.Totals.forEach(total => {
        const position = Math.round((total - minVal) / stepVal) || 0;
        s.BellValues[position].value += 1;
      });
    });

    // Calculate the percentages for each plot point
    sData.forEach(s => {
      let cumulativeIterations = 0;
      s.BellValues.forEach((keyValue) => {
        cumulativeIterations += keyValue.value;
        const percent = (cumulativeIterations / iterations) * 100;
        s.CurveValues.push({ key: keyValue.key, value: percent });
      });
    });

    const colorKeyValueStyles = this.chartColourService.getColorKeyValueStylesAsync(app, report.NamedStyles);

    sData.forEach(s => {
      const seriesIndex = monteCarloChartData.indexOf(s.Series);

      series.push();
      series[seriesIndex] = {
        name: s.Series.Label,
        data: this.getMonteCarloSeriesData(sData, seriesIndex, report.Chart.MonteCarloType),
        color: this.chartColourService.getColourHexcode(s.Series.Label, colorKeyValueStyles)
      };
    });

    // Monte Carlo - Matrix Table Data
    const tableData: MatrixTableData = {
      IsCumulative: true,
      Rows: new Array<any>()
    } as MatrixTableData;

    const columns = new Array<string>('0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%');
    tableData.Columns = columns.map(o => ({
      Value: o
    }));

    sData.forEach(s => {
      const tableDataRow = {
        Label: s.Series.Label,
        Cells: new Array<any>()
      };

      tableDataRow.Cells.push(...new Array<{ ListOfValues: Array<any> }>
        (
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[0].toFixed(s.Series.ToolTipDecimals)) : s.Totals[0] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.1 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.1 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.2 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.2 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.3 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.3 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.4 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.4 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.5 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.5 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.6 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.6 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.7 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.7 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.8 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.8 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(0.9 * iterations)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(0.9 * iterations)] }) },
          { ListOfValues: new Array<any>({ Value: s.Series.ToolTipDecimals != null ? +(s.Totals[(iterations - 1)].toFixed(s.Series.ToolTipDecimals)) : s.Totals[(iterations - 1)] }) },
        )
      );

      tableData.Rows.push(tableDataRow);
    });

    return { Series: series, MatrixTableData: tableData };
  }

  private triangularDistribution(best: number, expected: number, worst: number, random: number) {
    let proportion = 0;
    let range = 0;

    if (best === worst) {
      return best;
    }

    range = worst - best;
    proportion = (expected - best) / range;

    if (random <= proportion) {
      return best + ((Math.pow((random * proportion), 0.5)) * range);
    }

    return worst - ((Math.pow(((1 - random) * (1 - proportion)), 0.5)) * range);
  }

  private getMonteCarloSeriesData(sData: MonteCarloSeries[], seriesIndex: number, monteCarloType: number) {
    const seriesData = new Array<any>();
    const toolTipDecimals = sData[seriesIndex].Series.ToolTipDecimals;

    if (monteCarloType != null && monteCarloType === ChartEnums.MonteCarloType.SCurve) {
      sData[seriesIndex].CurveValues.forEach((value, index) => {
        seriesData.push({
          x: toolTipDecimals != null ? +(value.key.toFixed(toolTipDecimals)) : value.key,
          y: toolTipDecimals != null ? +(value.value.toFixed(toolTipDecimals)) : value.value,
          percent: toolTipDecimals != null ? +(sData[seriesIndex].CurveValues[index].value.toFixed(toolTipDecimals)) : sData[seriesIndex].CurveValues[index].value
        });
      });
    } else if (monteCarloType != null && monteCarloType === ChartEnums.MonteCarloType.Bell) {
      sData[seriesIndex].BellValues.forEach((value, index) => {
        seriesData.push({
          x: toolTipDecimals != null ? +(value.key.toFixed(toolTipDecimals)) : value.key,
          y: value.value,
          percent: toolTipDecimals != null ? +(sData[seriesIndex].CurveValues[index].value.toFixed(toolTipDecimals)) : sData[seriesIndex].CurveValues[index].value
        });
      });
    }

    return seriesData;
  }
}
