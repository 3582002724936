
import { Injectable } from '@angular/core';
import { RepositoryBase, RequestHeaders } from './repositories.base';
import { ImageList, QueryParameters, QueryParams } from '../types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImageListsRepository extends RepositoryBase {

  public getAll(): Promise<Array<ImageList>> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('ImageLists')).toPromise();
  }

  public getForApp(appIdentifier: string): Promise<Array<ImageList>> {
    return this.get(this.urlResolver.resolveDefaultApiUrl('ImageLists/GetForApp', appIdentifier)).toPromise();
  }

  /** Get a single image list by id */
  public getImageListAsync(id: string): Promise<ImageList> {
    const url = this.urlResolver.resolveDefaultApiUrl('ImageLists', id);
    return lastValueFrom<ImageList>(this.get(url));
  }

  public getImageListsAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<ImageList>> {
    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveDefaultApiUrl('ImageLists');
    url = this.urlResolver.appendQueryParams(url, params);

    return lastValueFrom<Array<ImageList>>(this.get(url));
  }

  /**
   *
   * @param assetUrl
   * @param accessToken
   */
  public async gatImageByAssetUrl(assetUrl: string): Promise<Blob> {

    let url = `${this.urlResolver.servicePath()}${assetUrl}`;

    var fileResource = await lastValueFrom(this.http
      .get<{ Name: string, Url: string }>(url.replace('/ImageListAsset/', '/Api/ImageListAssetSas/'), {
        headers: RequestHeaders(),
      }));

    return await lastValueFrom(this.http
      .get(fileResource.Url, {
        responseType: 'blob'
      }));
  }

  /**
   * get count of image lists optionally filtered by query params
   */
  public async getCountAsync(queryParams?: QueryParams): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('ImageLists', '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }

  /**
   * Create a new image list
   * @param list Initial image list data
   * @returns created image list
   */
  public async createAsync(list: ImageList): Promise<ImageList> {
    const url = this.urlResolver.resolveDefaultApiUrl('ImageLists');
    const id = await lastValueFrom<string>(this.postWithType(url, 'text', list));
    if (id) {
      return this.getImageListAsync(id);
    } else {
      return null;
    }
  }

  /** Save a change to the image list  */
  public save(id: string, changes: any) {
    const url = this.urlResolver.resolveDefaultApiUrl('ImageLists', id);
    return this.patch(url, JSON.stringify(changes));
  }
}
