import { Failure, IFormatting, Record } from '@softools/softools-core';
import { extractDecimal } from 'app/_constants';
import { NumericAppField } from './numeric-app-field';

export class MoneyAppField extends NumericAppField {

  public override getRawRecordValue(record: Record, listRow?: number) {
    const value = super.getRawRecordValue(record, listRow);
    return extractDecimal(value);
  }

  public override getInternalRecordValue(record: Record, listRow?: number) {
    const value = super.getInternalRecordValue(record, listRow);
    return extractDecimal(value);
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    return super.compareValues(extractDecimal(val1), extractDecimal(val2), isDescending);
  }

  public override formatDisplayValue(value: any, formatting?: IFormatting) {
    const decimal = extractDecimal(value);
    return super.formatDisplayValue(decimal);
  }

  public override editableValue(value: any): string {
    return extractDecimal(value)?.toString();
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    return super.validate(extractDecimal(value), errors, listRow);
  }

  public override isValid(value: any) {
    return super.isValid(extractDecimal(value));
  }

  public override constantForFilter(value: any) {
    return super.constantForFilter(extractDecimal(value));
  }

  public override adaptValue(value: any, current: any, record?: Record, source?: 'input' | 'server'): any {
    // If/when we extend the money field type to process decimal-precision values internally, we
    // have the opportunity here to convert `value` into a decimal-precision type.
    // The patch value is still a plain number even though the value is the decimal structure.
    return super.adaptValue(value, current, record, source);
  }
}
