
<app-embedded-video-field
  identifier="embeddedvideo"
  [value]="video.title" 
  [descriptionValue]="video.description" 
  [urlValue]="video.url" 
  [editMode]="true"
  [isEditable]="true"
  [forReport]="false"
  [hasEdit]="false"
  [hasDelete]="false">
</app-embedded-video-field>
