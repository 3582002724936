import { Injectable } from '@angular/core';
import { OdataQueryFilter, Record, ValidationErrorResponse, Failure, ValidationError, isDefined, Enums } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';
import { Application } from 'app/types/application';
import { InjectService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { ValidationRuleType, ValidationRule } from '@softools/softools-core';
import { ApplicationValueLookup } from 'app/services/indexes/application-value-lookup';

/**
 * Service that provides validation functions
 */
@Injectable({
  providedIn: 'root'
})
export class RecordValidationService {

  @InjectService(AppService)
  private readonly appService: AppService;

  constructor() {
  }

  /** Process validation rules for the record and set any failures on the record */
  public validateRecord(record: Record, fields: Array<AppField>) {
    record._errs = this.createRecordErrors(record, fields);
  }

  public isValid(record: Record, app: Application): boolean {
    if (app) {
      // Build error list if not already present
      // Will beocome more efficient when we persist errors
      if (!isDefined(record._errs)) {
        this.validateRecord(record, app.AppFields);
        return record._errs.length === 0;
      }
    }
    return false;
  }

  public isFieldValid(field: AppField, record: Record, row?: number) {
    const failures = this.getFieldFailures(field, record, row);
    return !failures || failures.length === 0;
  }

  public setServerError(record: Record, message: string) {
    record._serverError = message;
  }

  public setServerErrors(record: Record, errors: ValidationErrorResponse) {
    if (errors.ValidationErrors?.length > 0) {
      errors.ValidationErrors.forEach((err) => {
        this.setServerError(record, err.Message);
      });
    } else {
      this.setServerError(record, errors.Message);
    }
  }

  public clearServerError(record: Record) {
    this.setServerError(record, null);
  }

  private createRecordErrors(record: Record, fields: Array<AppField>, row?: number): Array<Failure> {

    const errors: Array<Failure> = [];

    if (record && fields) {
      const app = this.appService.application(record?.AppIdentifier);

      fields.forEach((field: AppField) => {

        // Validate all "normal" fields
        if (!field.Expression && !field.AutoNumber) {

          // Handle field specific validation rules
          if (record) {
            const failures = this.getFieldFailures(field, record, row);
            if (failures?.length > 0) {
              errors.push(...failures);
            }
          }
        }
      });

      app?.ValidationRules?.forEach(rule => {

        // Check for rule match is provided
        let execute = true;
        if (rule.FilterWhen) {
          const filter = new OdataQueryFilter(rule.FilterWhen, new ApplicationValueLookup(app));
          execute = filter.isMatch(record);
        }

        if (execute) {
          switch (rule.Type) {
            case ValidationRuleType.FieldsMustMatch:
              this._validateFieldsMustMatch(app, record, rule, errors);
              break;
            case ValidationRuleType.ValidPasswordValue:
              this._validatePasswordField(app, record, rule, errors);
              break;
            case ValidationRuleType.ValidUsernameValue:
              this._validateUsernameField(app, record, rule, errors);
              break;
            case ValidationRuleType.Required:
              this.validateRequired(app, record, rule, errors);
              break;
          }
        }
      });
    }

    return errors;
  }

  private getFieldFailures(field: AppField, record: Record, row?: number) {
    const failures = field.validateRecord(record, row);
    return failures;
  }

  private _validateFieldsMustMatch(app: Application, record: Record, rule: ValidationRule, errors: Array<Failure>) {
    const masterField = app.getField(rule.FieldId);
    const masterValue = masterField.getRecordValue(record);
    const otherIds = Array.isArray(rule.SecondaryField) ? rule.SecondaryField : [rule.SecondaryField];
    otherIds.forEach(otherId => {
      const otherValue = app.getField(otherId).getRecordValue(record);
      if (masterValue !== otherValue) {
        errors.push({
          fieldId: otherId,
          error: ValidationError.ValuesMustMatch,
          param: masterField.Label
        })
      }
    });
  }

  private _validatePasswordField(app: Application, record: Record, rule: ValidationRule, errors: Array<Failure>) {
    const field = app.getField(rule.FieldId);
    const value: string = field.getRecordValue(record);

    // Todo - should get password complecity from Auth0 settings.
    // Until then, use 8 char minimum limit as per v14 for consistency.
    const isValid = value && value.search(/^[\S]{8,}$/) >= 0;
    if (!isValid) {
      errors.push({
        fieldId: rule.FieldId,
        error: ValidationError.InvalidPasswordValue,
        param: $localize`Password must be at least 8 characters long and contain no white spaces`
      });
    }
  }

  private _validateUsernameField(app: Application, record: Record, rule: ValidationRule, errors: Array<Failure>) {
    const field = app.getField(rule.FieldId);
    const value: string = field.getRecordValue(record);
    const isValid = value && value.search(/^[a-z0-9_.+-]{3,30}$/) >= 0;

    if (!isValid) {
      errors.push({
        fieldId: rule.FieldId,
        error: ValidationError.InvalidUsernameValue,
        param: $localize`Username should use 3-30 lowercase letters or numbers or the following characters: _ . + -`
      });
    }
  }

  private validateRequired(app: Application, record: Record, rule: ValidationRule, errors: Array<Failure>, listRow?: number) {
    const field = app.getField(rule.FieldId);
    const value: string = field.getRecordValue(record);

    const failures: Array<Failure> = [];
    field.validateRequired(value, failures, listRow, true);
    failures.forEach(failure => {
      const errorFieldIdentifier = failure.fieldId ?? field.Identifier;
      errors.push({ error: failure.error, fieldId: errorFieldIdentifier, param: failure.param });
    });
  }
}
