export enum ControlColumnMode {
  Record,
  Select,
  AllSelected,
}

export enum SelectionMode {
  None,
  Single,
  Mulitple,
  Row,
  MulitpleRows
}
