import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FileAttachment, FilePatch } from '../types';
import { RepositoryBase } from './repositories.base';

@Injectable({ providedIn: 'root' })
export class FileAttachmentsRepository extends RepositoryBase {
  public getAttachments(appIdentifier: string, recordId: string): Observable<Array<FileAttachment>> {
    const url = this.urlResolver.resolveAppDataAttachmentsUrl(appIdentifier, recordId);
    return this.get(url) as Observable<Array<FileAttachment>>;
  }

  public saveAttachmentWithData(appIdentifier: string, recordId: string, filename: string, base64Data: string): Observable<any> {
    const url = this.urlResolver.resolveAppDataAttachmentsUrlWithData(appIdentifier, recordId, filename);
    return this.post(url, base64Data);
  }

  public deleteFileAttachment(appIdentifier: string, recordId: string, id: string): Observable<any> {
    const baseUrl = this.urlResolver.resolveAppDataAttachmentsUrl(appIdentifier, recordId);
    const url = `${baseUrl}/${id}`;
    return this.del(url);
  }

  public patchFileAttachment(appIdentifier: string, recordId: string, filePatch: FilePatch): Observable<any> {
    const baseUrl = this.urlResolver.resolveAppDataAttachmentsUrl(appIdentifier, recordId);
    const url = `${baseUrl}/${filePatch.Id}`;
    const patch = { Title: filePatch.Title, Description: filePatch.Description };
    return this.patch(url, JSON.stringify(patch));
  }
}
