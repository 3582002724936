<div
    [class.inline-control]="inline">

  <input
    appSofInput
    [appModel]="appModel"
    [field]="fieldModel"
    [record]="record"
    [listRow]="listRow"
    #input
    style="display: none"
    [attr.data-identifier]="fieldModel.Identifier"
    [attr.data-selectListIdentifier]="selectList?.Identifier"
  />
  <mat-radio-group *ngIf="selectList" [value]="selection" [disabled]="isDisabled()" [ngClass]="{ 'tc': !isCompact() }" >
    <mat-radio-button *ngFor="let option of selectList?.SelectListOptions"
                      [value]="option.Value"
                      (change)="onChange($event)"
                      >
      <span class="me-4" style="white-space: normal; word-break: break-word; overflow-wrap: break-word;">{{option.Text}}</span>
    </mat-radio-button>
    <br />
  </mat-radio-group>
</div>


