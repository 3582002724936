import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SiteSettingsStorageService } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

/**
 * Guard that ensures settings state is available before activating a page
 */
@Injectable()
export class SiteSettingsGuard implements CanActivate {

  constructor(
    private settingsStore: SiteSettingsStorageService,
    private models: GlobalModelService
  ) {
  }

  public async canActivate(): Promise<boolean> {
    let settings = this.models.siteModel.settings.value;
    if (!settings) {
      settings = await this.settingsStore.getSettingsAsync();
      this.models.siteModel.settings.value = settings;
    }

    return !!settings;
  }
}
