import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, Router } from '@angular/router';

import { AppDataStorageService, Record, OnlineStatusService, logError, logMessage } from '@softools/softools-core';
import { APP_IDENTIFIER, RECORD_ID } from 'app/_constants/constants.route';
import { AppService } from 'app/services/app.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';

@Injectable({ providedIn: 'root' })
export class ChildRecordResolver implements Resolve<Record> {

  constructor(
    private appService: AppService,
    private appDataService: AppDataStorageService,
    private router: Router,
    private onlineStatus: OnlineStatusService,
    private recordPersistenceService: RecordPersistService,
  ) { }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record> {

    const params: Params = route.params;
    const appIdentifier: string = params[APP_IDENTIFIER] as string;
    const recordId = route.params[RECORD_ID] as string;

    try {
      const app = this.appService.application(appIdentifier);

      // See if we have a record in storage.  This will be the case for offline apps
      // and new online app records.  Todo check pending update behaviour.
      let record: Record = await this.recordPersistenceService.getModifiedRecord(app, recordId);

      // if no record exists call refresh and get from the server if online
      if (!record && this.onlineStatus.isConnected) {
        try {
          record = await app.getRecordByIdAsync(recordId);

          if (!record) {
            record = await this.appDataService.getApiRecordByIdAsync(app, recordId); // record is archived
          } else {
            await this.appDataService.storeRecordAsync(app, record); // Record from server so update storage
          }
        } catch (err) {
          // The record does not exist, we dont want to route to add because its most likely the record Id has been deleted.
          this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
        }
      } else if (!record) {
        // the user is offline and the record is not in indexed db
        this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
      }

      if (record.AppIdentifier !== appIdentifier) {
        logMessage(`Selected a ${record.AppIdentifier} record for ${recordId} running ${appIdentifier}`);

        // Could do with an error place
        this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
        record = null;
      }

      return record;
    } catch (error) {
      console.log(`Could not load ${appIdentifier} record ${recordId}`, error);
      return null;
    }
  }
}
