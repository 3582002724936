import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

import { Input, Directive, SimpleChanges, OnChanges } from '@angular/core';
import { Record, EpochDate, logError, ErrorMap } from '@softools/softools-core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { WindowRef } from 'app/WindowRef';
import { InjectService } from 'app/services/locator.service';

const filterFormat = 'YYYY-MM-DDTHH:mmZ';

@Directive()
export class TemporalFieldBase extends EditableFieldBase<EpochDate> implements OnChanges {
  /** Master UTC date time */
  public dateTime: moment.Moment;

  /** date/time formatted as local string */
  public dateString: string;

  /** Track focus so we can display clear widget
   * NB we use opacity to ensure that clicks aren't lost by re-rendering
   * This is not currently done as the field is readonly - leaving focus tracking in case we can make it work
   */
  public hasFocus = false;

  public override previousValue: EpochDate;

  private _valueFormat: string;

  @Input() public validationErrors: ErrorMap;

  @Input() public calendarEventSummay;

  @InjectService(WindowRef)
  private windowRef: WindowRef;

  constructor() {
    super();
    this._valueFormat = moment.localeData().longDateFormat('L');
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    try {
      super.ngOnChanges(changes);

      if (changes['record'] || changes['value']) {
        this.convertValue(this.value);
      }
    } catch (error) {
      logError(error, 'TemporalFieldBase changed');
    }
  }

  protected convertValue(val: any) {

    const value = (val instanceof Object && val.hasOwnProperty('val')) ? val['val'] : val;

    let when: moment.Moment;
    if (value === undefined || value === null) {
      when = null;
    } else if (value && value['$date']) {
      when = moment.utc(value['$date']);
    } else if (value && (<any>value)._isAMomentObject) {
      when = <any>value;
    } else if (typeof value === 'string') {
      // Despite the best efforts to standardise, some cases e.g. matrix reports
      // supply a string format so attempt to convert if given a string
      when = moment.utc(value);
    } else {
      when = moment.utc(<any>value);
    }

    // If we have display local set convert to a local date format
    if (when && this.fieldModel.DisplayAsLocal) {
      when = when.local();
    }

    if (when && when.isValid()) {
      // Force to a UTC date with midnight, to avoid time zone shenanigans
      // this.dateTime = moment.utc({ year: when.year(), month: when.month(), date: when.date(), h: 0, m: 0 })
      this.dateTime = this.sanitiseMoment(when);
      this.dateString = this.dateTime.format(this._valueFormat);
    } else {
      this.dateTime = null;
      this.dateString = null;
    }
  }

  protected override setValueFromRecord(record: Record) {
    super.setValueFromRecord(record);
    this.convertValue(this.value);
  }

  // @ts-ignore Override of proeprty to accessor is an error in ts now
  public get required() {
    return this.fieldModel && this.fieldModel.Required;
  }

  // @ts-ignore
  public set required(b: boolean) {
    // nop as getter does it
  }

  /** Get or set date as a local date string.
   * Suitable as a model for a standard date picker
   */
  public get localDate(): string {
    return this.dateString;
  }

  public set localDate(date: string) {
    const utc = moment.utc(date);
    this.dateTime = utc;
    this.dateString = utc.format(this._valueFormat);
  }

  /** Current date/time formatted for title tip display  */
  public get displayDate(): string {
    return this.dateTime && this.dateTime.format(this.displayValueFormat);
  }

  /** Current date/time formatted for title tip display  */
  public get dateTitle(): string {
    return this.dateTime && this.dateTime.format(this.displayValueFormat);
  }

  public override get filterValue(): any {
    return this.dateTime && this.dateTime.format(filterFormat);
  }

  public get displayValueFormat() {
    return moment.localeData().longDateFormat('L');
  }

  protected setDateTime(dt: moment.Moment) {
    this.dateTime = dt;
  }

  protected sanitiseMoment(when: moment.Moment) {
    return when;
  }

  protected isRecordValid(record: Record): boolean {
    if (this.validationErrors && this.fieldModel) {
      const recordErrors = this.validationErrors.get(record._id);
      if (recordErrors) {
        return recordErrors.findIndex((f) => f.fieldId === this.fieldModel.Identifier) < 0;
      }
    }

    return true;
  }

  public downloadiCalClicked($event: MouseEvent) {
    $event.stopPropagation();

    const urlApi = this.windowRef.nativeWindow.URL || this.windowRef.nativeWindow.webkitURL;
    if (this.value?.$date && urlApi) {
      const blob = this.createCalendarEvent();

      const a = <any>document.createElement('a');
      a.style = 'display: none';
      const fileURL = urlApi.createObjectURL(blob);
      a.href = fileURL;
      a.download = `${this.fieldModel.Identifier.toLowerCase()}.ics`;
      a.click();
      urlApi.revokeObjectURL(blob);
    }
  }

  protected formatiCalDateTime() {
    return moment.utc(this.value.$date).format('YYYYMMDD[T]hhmmss[Z]');
  }

  protected createCalendarEvent(): Blob {
    const date = this.formatiCalDateTime();
    const iCal = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Softools//NONSGML v1.0//EN
BEGIN:VEVENT
UID:${uuid()}@softools.net
DTSTAMP:${moment.utc().format('YYYYMMDD[T]hhmmss[Z]')}
DTSTART:${date}
DTEND:${date}
END:VEVENT
END:VCALENDAR
`;

    return new Blob([iCal], { type: 'text/calendar' });
  }
}
