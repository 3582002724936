import { AppDataStorageService, AppsService, logError, QueryParams, Report } from '@softools/softools-core';
import { ArrayModelProperty, Model, ModelProperty } from '@softools/vertex';
import { FolderGroup, NavigationItem } from 'app/softoolsui.module/folders.component/folder';
import { Application } from 'app/types/application';
import { InjectService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { GlobalModel } from '../global.model';

export class FolderModel extends Model<FolderModel> {

  /** App to use for header, may be current app or its parent */
  public readonly headerApp = new ModelProperty<Application>(this).withLogging('Headeer App');

  public readonly folderGroups = new ArrayModelProperty<FolderGroup>(this).withLogging('FOLDER GROUPS');

  public loading = new ModelProperty<boolean>(this, true);

  public count = new ModelProperty<number>(this);

  public countBusy = new ModelProperty<boolean>(this, true);

  /** Incremented each time we get a child record count; kludge to trigger UI refresh in folder component  */
  public childAppUpdatecount = new ModelProperty<number>(this);

  public appHomeUrl = new ModelProperty<string>(this).withLogging('HOME URL', false);

  @InjectService(NavigationService)
  protected readonly navigationService: NavigationService;

  @InjectService(AppsService)
  protected readonly appsService: AppsService;

  @InjectService(AppDataStorageService)
  protected readonly appDataService: AppDataStorageService;

  @InjectService(AppService)
  protected readonly appService: AppService;

  public constructor(protected globalModel: GlobalModel, container?: Model<any>) {
    super(container);
  }

  public async reloadChildRecordCounts() {
    const groups = this.folderGroups.value;
    groups?.forEach(group => {
      group.SubFolders?.forEach(sub => {
        if (sub.HeaderItem?.Type === 'childapp') {
          const childApp = this.appService.application(sub.HeaderItem.ChildAppIdentifier);
          this.loadRecordCount(childApp, sub.HeaderItem, childApp.preferredListReport()).catch(err => logError(err, 'loadRecordCount'));
        }
      });
    });
  }

  private async loadRecordCount(childApp: Application, childItem: NavigationItem, childReport: Report) {

    const showArchived = this.globalModel.archived.value;
    const online = this.globalModel.online.value;

    if (childApp.isOfflineApp && !showArchived) {
      const count = await this.appDataService.childCount(childItem.ChildAppIdentifier, childItem.ParentAppIdentifier, childItem.ParentRecordId, false);
      childItem.ChildCount = count;
      childItem.childCount$.next(count);
    } else {
      if (online) {
        const countQueryParams = {
          $filter: `IsArchived eq ${showArchived ? 'true' : 'false'}`
        } as QueryParams;

        const count = await this.appDataService.getApiCountAsync(childApp, childReport?.Identifier, countQueryParams, `${childItem.ParentAppIdentifier}|${childItem.ParentRecordId}`);
        childItem.ChildCount = count;
        childItem.childCount$.next(count);
      }
    }
  }

}
