import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { SingleSelectionFieldBase } from '../field-selection-base';
import { MatMenuTrigger } from '@angular/material/menu';
import { logError } from '@softools/softools-core';

@Component({
  selector: 'app-selection-field',
  templateUrl: './selection-field.component.html',
  styleUrls: ['../input.scss', '../selection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionFieldComponent extends SingleSelectionFieldBase {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public overlayOpen = false;

  constructor() {
    super();
  }

  public onSelection(newValue: string | number) {
    const text = this.getOptionText(newValue);
    this.input.nativeElement.value = text ?? '';
    this.overlayOpen = false;

    this.updateValueAsync(newValue).catch(e => logError(e, 'Failed to dispatch change'));;
    this.onChanged.emit({ payload: newValue, fieldIsValid: true });
  }

  public openMenu($event: MouseEvent) {
    $event.stopPropagation();
    this.trigger?.openMenu();
  }

}
