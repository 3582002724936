import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Enums, TemplatedReport } from '@softools/softools-core';
import { GlobalModel } from 'app/mvc';

export class ExportComponentConfig {
  constructor(
    public exportTypes: Enums.ExportType[],
    public templatedReports: TemplatedReport[],
    public templatedReportsForAppCombinedRecord: TemplatedReport[] = [],
    public reportOnlyFieldsDefault?: boolean
  ) { }
}

export interface ExportComponentResult {
  filename?: string;
  templatedReport?: TemplatedReport;
  reportFieldsOnly?: boolean;
  excludeReadOnlyFields?: boolean;
  selectedTemplatedReportId?: number;
  selectedExportType?: string;
}

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {

  public config: ExportComponentConfig;
  public selectedExportTypeValue = '';
  public selectedTemplatedReportId: number;
  public Enums: typeof Enums = Enums;
  public exportData: ExportComponentResult;
  private SystemDefaultReport = 'SystemDefaultReport';

  public static async openAsync(globalModel: GlobalModel, config: ExportComponentConfig): Promise<ExportComponentResult> {
    return await globalModel.dialogAsync(ExportComponent, config);
  }

  constructor(
    public dialogRef: MatDialogRef<ExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportComponentConfig
  ) {
    this.init(data);
  }

  public get returnValue(): ExportComponentResult {
    return this.exportData;
  }


  public init(data: ExportComponentConfig) {
    this.exportData = {};
    this.config = data;
    this.exportData.reportFieldsOnly = this.config.reportOnlyFieldsDefault;
    this.exportData.excludeReadOnlyFields = false;

    // Default report is only needed if no other reports
    if (this.config.templatedReports.length > 1) {
      const index = this.config.templatedReports.findIndex(id => id.Identifier === this.SystemDefaultReport);
      if (index >= 0) {
        const defaultReportItem = this.config.templatedReports.splice(index, 1);
        this.config.templatedReports.push(...defaultReportItem);
      }
    }

    if (this.config.templatedReportsForAppCombinedRecord.length > 1) {
      const index = this.config.templatedReportsForAppCombinedRecord.findIndex(id => id.Identifier === this.SystemDefaultReport);
      if (index >= 0) {
        const defaultReportItem = this.config.templatedReportsForAppCombinedRecord.splice(index, 1);
        this.config.templatedReportsForAppCombinedRecord.push(...defaultReportItem);
      }
    }

    if (this.config.exportTypes.length > 0) {
      this.onExportTypeChangeHandler({ value: this.config.exportTypes[0] } as MatSelectChange);
    }
    if (this.config.templatedReports.length > 0) {
      this.onTemplatedReportIdChangeHandler(this.config.templatedReports[0].Id);
    }
  }

  public onReportFieldsOnlyChangeHandler($event: MatCheckboxChange): void {
    this.exportData.reportFieldsOnly = $event.checked;
  }

  public onExcludeReadOnlyFieldsChangeHandler($event: MatCheckboxChange): void {
    this.exportData.excludeReadOnlyFields = $event.checked;
  }

  public onExportTypeChangeHandler(event: MatSelectChange): void {
    this.exportData.selectedExportType = event.value;
    this.selectedExportTypeValue = this.exportData.selectedExportType;

    // Reset default selection
    if (this.config.templatedReports.length > 0 && (this.exportData.selectedExportType === Enums.ExportType.templatedReportExport || this.exportData.selectedExportType === Enums.ExportType.templatedReportExportAsPdf)) {
      this.onTemplatedReportIdChangeHandler(this.config.templatedReports[0].Id);
    } else if (this.config.templatedReportsForAppCombinedRecord.length > 0 && (this.exportData.selectedExportType === Enums.ExportType.templatedReportExportCombinedRecord || this.exportData.selectedExportType === Enums.ExportType.templatedReportExportAsPdfCombinedRecord)) {
      this.onTemplatedReportIdChangeHandler(this.config.templatedReportsForAppCombinedRecord[0].Id);
    } else {
      this.onTemplatedReportIdChangeHandler(null);
    }
  }

  public onTemplatedReportIdChangeHandler(value: number): void {
    this.exportData.selectedTemplatedReportId = value;
    this.selectedTemplatedReportId = this.exportData.selectedTemplatedReportId;
  }

  public showExportOption(value: string): boolean {
    const matchingExportType = this.config.exportTypes.find((enumVal) => enumVal === value);
    return matchingExportType != null;
  }

}
