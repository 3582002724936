import { Dashboard, Enums, Homepage, HomepageStorageService, PermissionEnums, TemplatedReport, TemplatedReportsRepository, tryGetCurrentUser } from '@softools/softools-core';
import { BooleanModelProperty, Model, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { ExportComponent, ExportComponentConfig } from 'app/softoolsui.module/export.component';
import { GlobalModel } from '..';
import { GlobalModelService } from '../common/global-model.service';
import { DashboardModel } from '../dashboard-model';
import { HeaderModel } from '../headers/header.model';
import { LaunchpadModel } from '../headers/launchpad.model';
import { IFocusableModel } from '../page/focusable-model.interface';
import { PageModel } from '../page/page.model';

export class HomeModel extends Model<HomeModel> implements IFocusableModel {

  public readonly homepage = new ModelProperty<Homepage>(this).withLogging('HOMEPAGE');

  public dashboardModel: DashboardModel;

  public readonly homepageIdentifier = new ProjectionModelPoperty<Homepage, string>(this.homepage, home => home?.Identifier);
  public readonly dashboard = new ProjectionModelPoperty<Homepage, Dashboard>(this.homepage, home => home?.Dashboard);
  public readonly backgroundColour = new ProjectionModelPoperty<Homepage, string>(this.homepage, home => home?.BackgroundColour || '#ffffff');

  /** Loading placeholder - old state was never set */
  public readonly loading = new BooleanModelProperty(this).withLogging('LOADING');

  /** Loading error placeholder - old state was never set */
  public readonly loadingError = new BooleanModelProperty(this).withLogging('LOADING ERROR');

  /** Toolbar actions for the main (ellipsis) menu */
  public readonly toolbarActions = new ModelProperty<Array<ToolbarAction>>(this).withLogging('TOOLS');

  @InjectService(HomepageStorageService)
  private readonly homeService: HomepageStorageService;

  @InjectService(TemplatedReportsRepository)
  private _templatedReportsRepository: TemplatedReportsRepository;

  constructor(private models: GlobalModelService, private globalModel: GlobalModel, private pageModel: PageModel, private launchpadModel: LaunchpadModel) {
    super();
  }

  public initialise() {
    this.dashboardModel = new DashboardModel(this.models, this.globalModel, this.launchpadModel);
    this.pageModel.focus.value = this;

    this.dashboardModel.initialize();

    this.subscribe(this.dashboard.$, (dashboard) => {
      this.dashboardModel.setDashboard(dashboard);
    });

    const header = this.globalModel.header;
    header.batch(() => {
      header.app.value = null;
      header.title.value = $localize`Home`;
      this.setToolbarActions(header);
      header.setSettingsActions();

      header.showBackButton.value = false;
      header.loading.value = false;
    });
  }

  public async loadHomepage(identifier?: string) {
    const homepage = identifier ?
      await this.homeService.getHomepage(identifier) :
      await this.homeService.getHomepageDefaultOrFirst();
    this.homepage.value = { ...homepage };
    return homepage;
  }

  private setToolbarActions(headerModel: HeaderModel) {

    const user = tryGetCurrentUser();
    const actions = [] as Array<ToolbarAction>;

    if (user.hasPermission([PermissionEnums.Reports.All, PermissionEnums.Reports.Export])) {
      actions.push(new ToolbarAction('Export', 'share-square', async () => {
        await this.export();
      }));
    }

    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      // reload to refresh
      // This is the same as the pre-mvc behaviour - only reloads from storage
      await this.loadHomepage(this.homepageIdentifier.value);
    }));

    this.toolbarActions.value = actions;
  }

  private async export() {
    const templatedReports: Array<TemplatedReport> = await this._templatedReportsRepository.getGlobalTemplatedReports().toPromise();
    const exportTypes: Array<Enums.ExportType> = [];

    if (templatedReports.length > 0) {
      // Note: [Default Report] not catered for server side at present.  SOF-4057  See ticket https://team.on.softools.net/../../Edit/devsprint/599d4cd1f7795b1330c3a997/Ticket
      if (templatedReports[0].Identifier === 'SystemDefaultReport' && templatedReports[0].Title === '[Default Report]') {
        templatedReports.splice(0, 1);
      }
      if (templatedReports.length > 0) {
        exportTypes.push(Enums.ExportType.templatedReportExport);
        exportTypes.push(Enums.ExportType.templatedReportExportAsPdf);
      }
    }

    const data = new ExportComponentConfig(exportTypes, templatedReports);
    const result = await this.globalModel.dialogAsync(ExportComponent, data);

    if (result?.selectedExportType) {
      switch (result.selectedExportType) {
        case Enums.ExportType.templatedReportExport:
        case Enums.ExportType.templatedReportExportAsPdf:
          const asPdf = result.selectedExportType === Enums.ExportType.templatedReportExportAsPdf;
          const templatedReport = templatedReports.filter((r) => r.Id === result.selectedTemplatedReportId)[0];
          await this._templatedReportsRepository.exportHomeLevel(templatedReport.Id, templatedReport.Title, asPdf).toPromise();
      }
    }

  }
}
