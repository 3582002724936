import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';

import * as fromServices from '@softools/softools-core';
import {
  App, SiteStorageService, getCurrentUser, OnlineStatusService, RetryPolicy,
  RequestHeaders, Enums, logError, AppDataStorageService
} from '@softools/softools-core';

import { VERSION_NUMBER } from 'app-version';
import { AppService } from 'app/services/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty } from 'rxjs';
import { processAssetUrl } from 'app/services/img-src.service';
import { catchError } from 'rxjs/operators';
import { IdentifiersService } from 'app/services/identifiers.service';
import { UsersService } from 'app/services/users.service';
import { HomepagesService } from 'app/services/homepages.service';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { SyncStatusService } from 'app/services/sync-status.service';
import { ACCESS_TOKEN_KEY, ASSETS_TOKEN_KEY } from 'app/_constants/constants.keys';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { TeamsService } from 'app/services/teams.service';
import { Application } from 'app/types/application';

enum SyncPhases {
  Starting,
  Clearing,
  Apps,
  AppData,
  OtherStuff,
  UsersTeams,
  Complete,
  PendingUsers,
  ImageListAssets,
  ImageFieldAssets
}

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncComponent implements OnInit, AfterViewInit {
  public syncingSelectListsCompleted = false;
  public syncingAppsCompleted = false;
  public syncingHomepagesCompleted = false;
  public syncingHomePageDashboardFieldsCompleted = false;
  public syncingHomePageDashboardReportsCompleted = false;
  public syncingUsersCompleted = false;
  public syncingUsersForMyTeamsCompleted = false;
  public syncingTeamsCompleted = false;
  public syncingImageListsCompleted = false;
  public syncingNotificationsCompleted = false;
  public isErrored = false;

  public syncPhases = SyncPhases;
  public syncPhase = SyncPhases.Starting;
  public syncPhaseTotal = 0;
  public syncPhaseCount = 0;

  public appVersionNumber = VERSION_NUMBER;

  public imgString: string;

  @Input() syncRecordCountDisplay = '0';

  constructor(
    private selectListsService: fromServices.SelectListsStorageService,
    private appService: AppService,
    private homeService: fromServices.HomepageStorageService,
    private homePageDashboardFieldsService: fromServices.HomePageDashboardFieldsStorageService,
    private homePageDashboardReportsService: fromServices.HomePageDashboardReportStorageService,
    private usersStorageService: fromServices.UserStorageService,
    private teamsStorageService: fromServices.TeamsStorageService,
    private teamsService: TeamsService,
    private imageListsService: fromServices.ImageListsStorageService,
    private imagesListAssetStorageService: fromServices.ImagesListAssetStorageService,
    private notificationsService: fromServices.NotificationStorageService,
    private router: Router,
    protected onlineStatus: OnlineStatusService,
    private siteService: SiteStorageService,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,

    private identifiersService: IdentifiersService,
    private usersService: UsersService,
    private homepagesService: HomepagesService,
    private indexedDbService: IndexedDbService,
    private syncStatusService: SyncStatusService,
    private appDataStorageService: AppDataStorageService,
    private models: GlobalModelService,
    private imageStorageService: fromServices.ImageStorageService
  ) { }

  async ngOnInit() {
    localStorage.setItem('current-version', VERSION_NUMBER);

    try {
      const user = getCurrentUser();

      // Turn off background sync processing
      this.models.siteModel.stopBackgroundSync();

      // Clear existing data
      this.changePhase(SyncPhases.Clearing);
      await this.indexedDbService.clear();

      this.changePhase(SyncPhases.Apps);

      const apps = await this.syncApps();

      this.syncPhaseTotal = apps.filter(app => app.isAppVisibleToUser(user)).length;
      this.syncPhaseCount = 0;

      await this.userTeamsPhase(apps);

      this.changePhase(SyncPhases.AppData);

      this.syncRecordCountDisplay = '0';
      const sub = this.appDataStorageService.syncRecordCount$.subscribe(count => {
        this.syncRecordCountDisplay = `${count}`;
        this.changeDetector.detectChanges();
      });

      const promises: Array<Promise<void>> = [];

      const t0 = performance.now();
      for (let i = 0; i < apps.length; ++i) {
        const app = apps[i];
        const dataPromise = app.synchronizeAppData().then(() => {
          if (app.isAppVisibleToUser(user)) {
            this.syncPhaseCount++;
          }
          this.changeDetector.detectChanges();
        });
        promises.push(dataPromise);
      }

      await Promise.all(promises);

      sub.unsubscribe();

      await this.pendingUsersPhase();

      await this.otherStuffPhase(apps);

      await this.imageListAssetsPhase(apps);

      await this.imageFieldAssetsPhase(apps);

      const t1 = performance.now();
      console.log(`sync took ${t1 - t0} milliseconds.`);

      // Restart background sync
      // We could do this in finally but probably not a good ideas as data in
      // an unknown state and needs a full resync
      this.models.siteModel.startBackgroundSync();

      this.changePhase(SyncPhases.Complete);

      if (navigator.storage) {
        try {
          navigator.storage.estimate().then((estimate) => {
            console.log(`After Sync Used ${estimate.usage / (1024 * 1024)} MB of ${estimate.quota / (1024 * 1024)} MB storage quota `);
          }).catch(error => logError(error, 'Failed to get store estimate'));
        } catch (_) {
          console.log('No storage manager available');
        }
      }

      // Update data in services
      // We must ensure all data required by the matchers in RootRoutesGuard is available
      await this.homepagesService.refresh();
      await this.identifiersService.initialise();

      this.syncStatusService.setStatus('completed');
      this.router.navigateByUrl('/Homepage', { replaceUrl: true }).catch(error => logError(error, 'Failed to navigate to homepage'));
    } catch (error) {
      logError(error, 'Error syncing');
      this.syncStatusService.setStatus('failed');
      this.isErrored = true;
      this.changeDetector.detectChanges();
      // throw error;
    }
  }

  private async imageListAssetsPhase(apps: Application<any>[]) {
    this.changePhase(SyncPhases.ImageListAssets);
    await this.syncImageListAssets(apps);
  }

  private async imageFieldAssetsPhase(apps: Application<any>[]) {
    this.changePhase(SyncPhases.ImageFieldAssets);
    await this.syncImageFieldAssets(apps);
  }

  private async pendingUsersPhase() {
    this.changePhase(SyncPhases.PendingUsers);
    await this.syncPendingUsers();
  }

  private async userTeamsPhase(apps: Application<any>[]) {

    this.changePhase(SyncPhases.UsersTeams);

    await Promise.all([
      this.syncUsers(),
      this.syncUsersForMyTeam(),
      this.syncTeams(),
    ]);

    await this.usersService.refresh();
    await this.teamsService.refreshTeamsAsync();
  }

  private async otherStuffPhase(apps: Application<any>[]) {

    this.changePhase(SyncPhases.OtherStuff);

    await Promise.all([
      this.syncHomepages(),
      this.syncHomepageDashboardFields(),
      this.syncHomepageDashboardReports(),
      this.syncSelectLists(),
      this.syncImageLists(),
      this.syncNotifications(),
      this.syncImageActionButtonImages(apps),
    ]);
  }

  ngAfterViewInit() {
    this.loadImages().catch(error => logError(error, 'Failed to load images on sync'));   // completes async
  }

  private async loadImages() {
    try {
      const user = getCurrentUser();
      const site = await this.siteService.getOrCreateSiteLoginDataResourceAsync(user.Tenant);

      if (/^([a-f0-9]{24})$/.test(site.ImageURI)) {
        // site image is an asset id.  Add prefix so display component knows what to do
        this.imgString = `/Asset/${site.ImageURI}`;
      } else {
        // could be anything - pass unchanged.  Expect /Asset/id or a full URL
        this.imgString = site.ImageURI;
      }

    } catch (err) {
      logError(err, 'sync loadImages');
    }
  }

  public logout() {
    this.models.globalModel.logout().catch(error => logError(error, 'logout'));
  }

  private async syncHomepages() {
    const retry = new RetryPolicy(`sync homepages`);
    retry.onlineStatus = this.onlineStatus;
    return this.homeService.syncHomepages(retry).then(async (homepages) => {
      const imageSync = [];

      // Pre fetch the images so that they are stored in cache.s
      homepages.forEach((homepage) => {
        imageSync.push(this.downloadAssetImageUri(homepage.AssetImageUri).toPromise());

        if (homepage.Dashboard && homepage.Dashboard.DashboardCells) {
          homepage.Dashboard.DashboardCells.forEach((cell) => {
            if (cell.AssetImageUri && cell.AssetImageUri.match(/\.(jpeg|jpg|gif|png)$/) != null) {
              imageSync.push(this.downloadAssetImageUri(cell.AssetImageUri).toPromise());
            }
          });
        }
      });

      await Promise.all(imageSync);

      this.syncingHomepagesCompleted = true;

      return homepages;
    });
  }

  private downloadAssetImageUri(assetImageUri: string) {
    if (assetImageUri && assetImageUri.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      return this.http
        .get(processAssetUrl(assetImageUri), {
          responseType: 'blob',
          headers: RequestHeaders(),
          params: new HttpParams().set(ASSETS_TOKEN_KEY, localStorage.getItem(ACCESS_TOKEN_KEY)),
        })
        .pipe(catchError((_) => empty()));
    }

    return empty();
  }

  private async syncImageActionButtonImages(apps: App[]) {
    const imageSync = [];

    apps.forEach((app) => {
      const fields = app.Fields.filter((f) => f.Type === Enums.FieldType.ImageActionButton);

      fields.forEach((f) => {
        imageSync.push(this.downloadAssetImageUri(f.ImageActionButtonImageUri).toPromise());
      });
    });

    await Promise.all(imageSync);
  }

  private async syncHomepageDashboardFields() {
    const retry = new RetryPolicy(`sync dash fields`);
    retry.onlineStatus = this.onlineStatus;
    return this.homePageDashboardFieldsService.syncHomepageDashboardFields(retry).then((f) => {
      this.syncingHomePageDashboardFieldsCompleted = true;
      return f;
    });
  }

  private async syncHomepageDashboardReports() {
    const retry = new RetryPolicy(`sync dash reports`);
    retry.onlineStatus = this.onlineStatus;
    return this.homePageDashboardReportsService.syncHomepageDashboardReports(retry).then((f) => {
      this.syncingHomePageDashboardReportsCompleted = true;
      return f;
    });
  }

  private async syncSelectLists(): Promise<Array<any>> {
    const retry = new RetryPolicy(`sync select list`);
    retry.onlineStatus = this.onlineStatus;
    return this.selectListsService.syncAll(retry).then((a) => {
      this.syncingSelectListsCompleted = true;
      return a;
    });
  }

  private async syncApps() {
    const retry = new RetryPolicy(`sync apps`);
    retry.onlineStatus = this.onlineStatus;
    await this.models.siteModel.syncApps(retry);
    const apps = await this.appService.refresh();
    this.syncingAppsCompleted = true;
    return apps;
  }

  private async syncUsers() {
    const retry = new RetryPolicy(`sync users`);
    retry.onlineStatus = this.onlineStatus;
    return this.usersStorageService.syncUsersAsync(retry);
  }

  private async syncUsersForMyTeam() {
    const retry = new RetryPolicy(`sync users for teams`);
    retry.onlineStatus = this.onlineStatus;
    return this.usersStorageService.syncUsersForMyTeamsAsync(retry);
  }

  private async syncPendingUsers() {
    const retry = new RetryPolicy(`sync pending users`);
    retry.onlineStatus = this.onlineStatus;
    return this.usersStorageService.syncPendingUsersAsync(retry);
  }

  private async syncTeams() {
    const retry = new RetryPolicy(`sync team`);
    retry.onlineStatus = this.onlineStatus;
    return this.teamsStorageService.syncSortedTeamsAsync(retry);
  }

  private async syncImageLists() {
    const retry = new RetryPolicy(`sync imagelists`);
    retry.onlineStatus = this.onlineStatus;
    await this.imageListsService.syncAll(retry);
  }

  private async syncImageListAssets(apps: Array<App>): Promise<any> {
    return this.imagesListAssetStorageService.syncAllImageListAssets(apps);
  }

  private async syncImageFieldAssets(apps: Array<App>): Promise<any> {
    return this.imageStorageService.syncImageFieldAssets(apps);
  }

  private async syncNotifications() {
    await this.notificationsService.syncNotifications();
    this.syncingNotificationsCompleted = true;
  }

  private changePhase(syncPhase: SyncPhases) {
    this.syncPhase = syncPhase;
    this.changeDetector.detectChanges();
  }
}
