import { Component, Input, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { LookupFieldMapping, Enums, Record, logError, isString, Report } from '@softools/softools-core';
import { RECORD_ADD_MODE } from 'app/_constants';
import { FieldBase } from 'app/softoolsui.module/fields/field-base';
import { Application } from 'app/types/application';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map, flatMap, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RecordPatch } from 'app/workspace.module/types';
import { AppField } from 'app/types/fields/app-field';
import { RecordSelectorComponent, SelectedRecords } from 'app/softoolsui.module/record-selector/record-selector.component';
import { ToastyService } from 'app/services/toasty.service';
import { LookupParameters } from 'app/types/lookup-parameters.interface';
import { RecordSelection } from 'app/types/record-selection';
import { ReportFilter } from 'app/filters/types';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LookupComponent extends FieldBase<string> implements OnInit, OnChanges {

  public lookupControl = new UntypedFormControl();

  @Input() public isNewRecord = false;

  /** Observable with autocomplete records */
  public lookupOptions$: Observable<Array<Record>>;

  /** Record to select by default, null if none */
  public defaultRecord?: Record;

  public targetApp: Application;
  public lookupReport: Report;
  public filter: ReportFilter;

  public searchLookupAppFieldIdentifier: string;
  public lookupFieldTitleFieldIdentifier: string;
  public lookupSearchField: AppField;
  public lookupTitleField: AppField;

  constructor(private toast: ToastyService) {
    super();
  }

  override ngOnInit(): void {

    try {

      this.previousValue = this.value;
      this.label = this.disableLabel === true ? '' : this.label;

      // Changed from randomstring so appForm.valid is accurate when multiple of the same field exists within form/ templates
      // Side effect is only the first validatable field element will show the red validation message on load, duplicate field references will show on focus
      // (ticket SOF-5769 created for this)
      // If forReport we should use random as more than one record can be expanded, so field should be unique
      this.uiIdentifier = this.forReport ? `${this.identifier}-${this.getRandomString()}` : this.identifier;

      this.targetApp = this.appService.application(this.fieldModel.LookupFieldApp);

      // Should always find target app.  Fail safe if not found
      if (this.targetApp) {
        // Find report to use for lookup
        this.lookupReport = this.appService.getReport(this.targetApp.Identifier, this.fieldModel.Lookup?.ReportIdentifier) ?? this.targetApp.preferredReport() ?? this.targetApp.getFirstListReport();

        const filters: Array<ReportFilter> = [];

        // Apply any base filter string
        if (this.lookupReport?.BaseFilter) {
          const baseFilter = new ReportFilter();
          baseFilter.filterString = this.lookupReport.BaseFilter;
          filters.push(baseFilter);
        }

        // Apply any base saved filter
        if (this.lookupReport?.BaseFilterId) {
          const baseSaved = this.appModel.savedFilters.value.find(f => f.Id === this.lookupReport.BaseFilterId);
          if (baseSaved) {
            filters.push(ReportFilter.fromSavedFilter(baseSaved));
          }
        }

        this.filter = ReportFilter.merge(filters);
      }


      if (this.fieldModel.LookupSearchFieldIdentifier && this.targetApp?.Fields.find(f =>
        f.Identifier === this.fieldModel.LookupSearchFieldIdentifier
        && (f.Type === Enums.FieldType.Text
          || f.Type === Enums.FieldType.LongText
          || f.Type === Enums.FieldType.Email
          || f.Type === Enums.FieldType.Integer
          || f.Type === Enums.FieldType.Long
          || f.Type === Enums.FieldType.Number
          || f.Type === Enums.FieldType.UrlField
          || f.Type === Enums.FieldType.Person
          || f.Type === Enums.FieldType.PersonByTeam
          || f.Type === Enums.FieldType.Selection))) {

        this.searchLookupAppFieldIdentifier = this.fieldModel.LookupSearchFieldIdentifier;

        if (this.targetApp.TitleFieldIdentifier !== this.searchLookupAppFieldIdentifier) {
          this.lookupFieldTitleFieldIdentifier = this.targetApp.TitleFieldIdentifier;
          this.lookupTitleField = this.targetApp.getField(this.lookupFieldTitleFieldIdentifier);
        }
      }

      if (!this.searchLookupAppFieldIdentifier) {
        this.searchLookupAppFieldIdentifier = this.targetApp?.TitleFieldIdentifier;
      }

      if (this.searchLookupAppFieldIdentifier) {
        this.lookupSearchField = this.targetApp.getField(this.searchLookupAppFieldIdentifier);
      }

      this.lookupOptions$ = this.lookupControl.valueChanges
        .pipe(
          startWith(''),
          distinctUntilChanged(),
          map(async (value: string) => {

            // Only allow typeahead on offline apps
            // We can extend this to online but will need to make API calls here
            if (this.targetApp?.isOfflineApp) {
              const selection = new RecordSelection();
              selection.start = 0;
              selection.count = 100;
              selection.showArchived = false;

              if (isString(value) && value.length > 0) {
                const filter = ReportFilter.fromQueryParams({
                  $filter:
                    `$filter=substringof('${value}',[${this.fieldModel.LookupSearchFieldIdentifier}])`
                });
                selection.filter = this.filter ? ReportFilter.merge([this.filter, filter]) : filter;

                const matches = await this.targetApp.getRecordsAsync(selection, { applyPatch: true });
                return matches;
              }

              if (value && !this.searchLookupAppFieldIdentifier) {
                this.toast.info($localize`Lookup Config is invalid`, $localize`Lookup Invalid`);
              }
            }

            return [];
          }),
          flatMap(ra => ra)
        );

      // Track first record in list
      this.subscribe(this.lookupOptions$, (records) => {
        this.defaultRecord = (records?.length) > 0 && records[0];
      });

    } catch (error) {
      logError(error, '');
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes['record']) {
      if (this.input) {
        this.input.nativeElement.value = '';
      }
    }
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    const targetRecord = event.option.value as Record;
    this.select(targetRecord).catch(e => logError(e, 'select lookup'));
  }

  public optionActivated($event) {
    // Set default as we move throygh autocomplete list
    const targetRecord = $event.option?.value as Record;
    if (targetRecord) {
      this.defaultRecord = targetRecord;
    }
  }

  private async select(parentRecord: Record) {
    const patch = new RecordPatch(this.record._id, this.record.AppIdentifier, this.record.Hierarchy, true);
    this.fieldModel.LookupFieldMappings.forEach(mapping => {
      patch.addChange(mapping.ChildFieldIdentifier, this.getFieldValue(mapping.ParentFieldIdentifier, parentRecord));
    });

    await this.dispatchPatchAsync(patch);

    // Reset lookup test
    this.lookupControl.setValue('');
  }

  private getFieldValue(fieldIdentifier: string, record: Record) {
    const field = this.targetApp.getField(fieldIdentifier);
    return field?.getRecordValue(record);
  }

  public openLookup(e: Event): void {
    e.stopPropagation();

    // We either expect there to be a FieldMapping with IsSearchLookupField true, OR a default mapping for this field.
    const isSearchLookupMappingField: LookupFieldMapping = this.fieldModel.LookupFieldMappings.find(m => m.IsSearchLookupField);
    const defaultLookupMappingField = this.fieldModel.LookupFieldMappings.find(m => m.ChildFieldIdentifier === this.fieldModel.Identifier);
    const defaultLookupMappingFieldIdentifier: string = defaultLookupMappingField ? defaultLookupMappingField.ParentFieldIdentifier : null;

    const searchLookupAppFieldIdentifier: string = isSearchLookupMappingField
      ? isSearchLookupMappingField.ParentFieldIdentifier
      : defaultLookupMappingFieldIdentifier;

    // if (!searchLookupAppFieldIdentifier) {
    //   this.dispatcher.dispatch(showToasty({ Type: Enums.ToastySoftoolsType.info, Title: 'Lookup Invalid', Message: 'Lookup Config is invalid' }));
    //   return;
    // }

    const targetAppIdentifier = this.fieldModel.LookupFieldApp;
    const app = this.appService.application(targetAppIdentifier);
    const report = this.lookupReport;

    const lookupParams: LookupParameters = {
      lookupOptions: {
        searchLookupAppField: this.searchLookupAppFieldIdentifier || searchLookupAppFieldIdentifier,
        searchValue: this.lookupControl.value,
        appIdentifier: this.fieldModel.LookupFieldApp,
        reportIdentifier: report.Identifier,
        multiSelect: false,
        selectedIds: [],
        uiState: null,
        targetApp: app,
        report,
      },
      lookupFieldMappings: this.fieldModel.LookupFieldMappings,
    };

    lookupParams[RECORD_ADD_MODE] = this.isNewRecord;

    // Run popup asynchronously
    this.showLookupDialog(lookupParams).catch(error => { logError(error, ''); });
  }

  public keyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      if (this.defaultRecord && !$event.shiftKey) {
        this.select(this.defaultRecord).catch(e => logError(e, 'select lookup def'));
      } else {
        this.openLookup($event);
      }
    }
  }

  private async showLookupDialog(lookupParams: LookupParameters) {

    try {
      const options = lookupParams.lookupOptions;
      const app = this.appService.application(options.appIdentifier);
      const report = lookupParams.lookupOptions.report;

      // sanity check report type - should always be table or list
      if (report?.Type === Enums.ReportTypes.Table || report?.Type === Enums.ReportTypes.List) {
        const config = { maxHeight: '90vh', width: '100vw', maxWidth: '1000px' };
        const selected: SelectedRecords = await this.globalModel.dialogAsync(RecordSelectorComponent, options, config);
        if (selected?.ids?.size > 0) {
          const id = Array.from(selected.ids)[0];
          const record = await app.getRecordByIdAsync(id);
          if (record) {
            await this.patchLookup(lookupParams, record);
          }
        }

      } else {
        this.toast.error($localize`Invalid report type for lookup`);
      }
    } catch (error) {
      logError(error, 'Update lookup record');
    }
  }

  private async patchLookup(lookupParams: LookupParameters, targetRecord: Record) {
    try {
      if (targetRecord) {
        // A record was selected so look up values from it
        // const appIdentifier = storeState.apps.SelectedAppIdentifier;
        const app = this.appModel.app.value;
        const lookupApp = this.appService.application(lookupParams.lookupOptions.appIdentifier);

        const _id = this.record._id;

        const patch = new RecordPatch(_id, app.Identifier);
        patch._new = this.record._new;
        lookupParams.lookupFieldMappings.forEach((m) => {
          const sourceField = lookupApp.getField(m.ParentFieldIdentifier);
          const targetField = app.getField(m.ChildFieldIdentifier);
          if (sourceField) {
            // Build a pseudo record with the field values under the target ids
            const values: Record = {} as any;
            const value = sourceField.getInternalRecordValue(targetRecord);
            if (value !== undefined) {
              values[targetField.Identifier] = sourceField.getInternalRecordValue(targetRecord);
              targetField.updatePatch(patch, values);
            }
          }
        });

        await this.dispatchPatchAsync(patch);
      }
    } catch (error) {
      logError(error, 'patchLookup');
    }
  }


}
