
import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';

@Injectable({ providedIn: 'root' })
export class NotesRepository extends RepositoryBase {

  public getData(appIdentifier: string, recordId: string, fieldId: string) {
    return this.get(this.urlResolver.resolveAppDataNotesUrl(appIdentifier, recordId, fieldId));
  }

  public patchData(appIdentifier: string, recordId: string, fieldId: string, changes: {}) {
    return this.patch(this.urlResolver.resolveAppDataNotesUrl(appIdentifier, recordId, fieldId), JSON.stringify(changes));
  }
}
