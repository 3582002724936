
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

import { SecurityComponent } from './security.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofPlusIconModule } from '@softools/design/icons';

@NgModule({
  declarations: [SecurityComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    SofPlusIconModule
  ],
  exports: [SecurityComponent],
  providers: [],
})
export class SecurityModule { }
