import { Params } from '@angular/router';
import { AppIdentifier } from 'app/types/application';
import { APP_IDENTIFIER, PARENT_APP_IDENTIFIER, ANCESTOR_APP_IDENTIFIER_PREFIX, CHILD_APP_IDENTIFIER } from 'app/_constants';

/**
 * The complete list of application identifiers that define the current app.
 * The simplest situation is where we are looking at an app in isolation.  There we have
 * a single id contained in the @see appIdentifier member; the other ids are empty.
 *
 * Child apps are more complex.  The child has a sequence of containing apps.
 * The current app provided by @see appIdentifier can be the parent or child app;
 * a report is  the parent app showing its children while a record view belongs to
 * the child app itself.
 *
 * We then have a child app identifier specified by
 * @see childAppIdentifier and one or more enclosing apps in the @see ancestorAppIds collection.
 * The parent app is the last entry in @see ancestorAppIds and is also available through the
 * @see parentAppIdentifier field.
 *
 */
export class AppIdentifiers {

  /** The app we are looking at.  This can be the child or parent app id when dealing with child apps */
  public appIdentifier: AppIdentifier;

  /** The id of the current child app, undefined if not a child app */
  public childAppIdentifier: AppIdentifier;

  /** For a child app, the immediate parent.  This is the same as the last ancestor app id */
  public parentAppIdentifier?: AppIdentifier;

  /** For a child app, the ids of all enclosing apps. */
  public ancestorAppIds?: Array<AppIdentifier>;

  public constructor(params?: Params | AppIdentifiers) {

    if (params instanceof AppIdentifiers) {
      this.appIdentifier = params.appIdentifier;
      this.childAppIdentifier = params.childAppIdentifier;
      this.parentAppIdentifier = params.parentAppIdentifier;
      this.ancestorAppIds = [...params.ancestorAppIds];
    } else {
      this.ancestorAppIds = [];

      if (params) {
        this.appIdentifier = params[APP_IDENTIFIER];
        this.parentAppIdentifier = params[PARENT_APP_IDENTIFIER];
        this.childAppIdentifier = params[CHILD_APP_IDENTIFIER];

        for (let index = 1; ; index++) {
          const ancestor = params[`${ANCESTOR_APP_IDENTIFIER_PREFIX}${index}`];
          if (ancestor) {
            this.ancestorAppIds.push(ancestor);
          } else {
            break;
          }
        }

        // Make oldest ancestor come first
        this.ancestorAppIds = this.ancestorAppIds.reverse();
      }
    }
  }

  /**
   * Transform this AppIdentifiers object to represent the specified child
   */
  public push(childIdentifier: AppIdentifier) {
    const ids = new AppIdentifiers();

    if (this.childAppIdentifier) {
      ids.ancestorAppIds = [...this.ancestorAppIds, this.childAppIdentifier];
      ids.parentAppIdentifier = this.childAppIdentifier;
    } else {
      ids.ancestorAppIds = [this.appIdentifier];
      ids.parentAppIdentifier = this.appIdentifier;
    }

    ids.childAppIdentifier = childIdentifier;
    ids.appIdentifier = childIdentifier;
    return ids;
  }

  /**
   * Transform this AppIdentifiers object to represent its parent
   */
  public pop() {
    const ids = new AppIdentifiers();
    ids.ancestorAppIds = [...this.ancestorAppIds];
    ids.appIdentifier = ids.ancestorAppIds.pop();
    ids.childAppIdentifier = ids.appIdentifier;
    const len = ids.ancestorAppIds.length;
    ids.parentAppIdentifier = len > 0 ? ids.ancestorAppIds[len - 1] : null;
    return ids;
  }

  /**
   * Get the id of the app that the user is interacting with.
   * This is the current app id, unless a child report is open in which
   * case it is the child app id.
   */
  public get visibleAppIdentifier() {
    return this.childAppIdentifier || this.appIdentifier;
  }
}
