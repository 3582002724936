import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Enums, RecordId, Report } from '@softools/softools-core';
import { IdentifiersService } from './identifiers.service';
import { AppIdentifiers } from './record/app-info';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private appService: AppService, private identifiersService: IdentifiersService) { }

  /**
   *
   * @param appIdentifier
   */
  public getAppBaseUrl(appIdentifier: string) {
    if (this.identifiersService.getUnambiguousApp(appIdentifier)) {
      // app id is unique so just use that
      return `/${appIdentifier}`;
    } else {
      // app id has other matches so quality to disambiguate
      return `/App/${appIdentifier}`;
    }
  }

  /**
   * Get site hompage url
   *
   * @param homepageIdentifier
   */
  public getHompageUrl(homepageIdentifier: string) {
    return `/Homepage/${homepageIdentifier}`;
  }

  /**
   *
   * @param appIdentifier
   */
  public getAppHomeUrl(appIdentifier: string) {
    const app = this.appService.tryGetApplication(appIdentifier);
    if (!app) {
      // If app isn't found return to root
      return '/';
    } else {
      // Here we can check for unambiguity and use if suitable
      // return `/${appIdentifier}`;
      return this.getAppBaseUrl(appIdentifier);
    }
  }

  /**
   *
   * @param appIdentifier
   */
  public getDefaultReportUrl(appIdentifier: string) {
    const app = this.appService.tryGetApplication(appIdentifier);
    if (!app) {
      // If app isn't found return to root
      return '/';
    } else {
      // Here we can check for unamibuity and use if suitbale
      // return `/${appIdentifier}`;
      return `${this.getAppBaseUrl(appIdentifier)}/${app.preferredReport(true)?.Identifier || ''}`;
    }
  }

  public getChildAppHomeOrBaseUrl(appIdentifiers: AppIdentifiers, parentRecordId: string) {
    // const app = this.appService.application(appIdentifiers.appIdentifier);
    if (appIdentifiers.ancestorAppIds.length > 0) {
      const ids = [...appIdentifiers.ancestorAppIds, appIdentifiers.appIdentifier];

      const ancestry = appIdentifiers.ancestorAppIds.map((id) => this.getAppBaseUrl(id)).join('');
      const childAppUrl = this.getAppBaseUrl(appIdentifiers.childAppIdentifier);
      return `${ancestry}/${parentRecordId}/${childAppUrl}`;
    } else {
      return this.getAppBaseUrl(appIdentifiers.appIdentifier);
    }
  }

  public getAppHomeUrl2(appIdentifiers: AppIdentifiers) {
    // const app = this.appService.application(appIdentifiers.appIdentifier);
    if (appIdentifiers.ancestorAppIds.length > 0) {
      const ids = [...appIdentifiers.ancestorAppIds, appIdentifiers.appIdentifier];
      const ancestry = ids.map((id) => this.getAppBaseUrl(id)).join('');
      return ancestry;
    } else {
      return this.getAppBaseUrl(appIdentifiers.appIdentifier);
    }
  }

  /**
   *
   * @param appIdentifier
   * @param recordId
   * @param formIdentifier
   */
  public getRecordUrl(appIdentifier: string, recordId: string, formIdentifier: string = null) {
    const baseUrl = this.getAppBaseUrl(appIdentifier);
    if (formIdentifier) {
      return `${baseUrl}/${recordId}/Form/${formIdentifier}`;
    } else {
      return `${baseUrl}/${recordId}`;
    }
  }

  public getRecordUrl2(appIdentifiers: AppIdentifiers, recordId: RecordId | 'create', formIdentifier: string = null) {
    const ancestry = appIdentifiers.ancestorAppIds.map((id) => this.getAppBaseUrl(id)).join('');
    const childAppUrl = this.getAppBaseUrl(appIdentifiers.childAppIdentifier || appIdentifiers.appIdentifier);
    if (formIdentifier) {
      return `${ancestry}${childAppUrl}/${recordId}/Form/${formIdentifier}`;
    } else {
      return `${ancestry}${childAppUrl}/${recordId}`;
    }
  }

  public getCreateRecordUrl(appIdentifiers: AppIdentifiers) {
    return this.getRecordUrl2(appIdentifiers, 'create');
  }

  public getParentRecordUrl(appIdentifiers: AppIdentifiers, recordId: RecordId | 'create', formIdentifier: string = null) {
    const ancestry = appIdentifiers.ancestorAppIds.map((id) => this.getAppBaseUrl(id)).join('');
    const childAppUrl = this.getAppBaseUrl(appIdentifiers.appIdentifier);
    if (formIdentifier) {
      return `${ancestry}${childAppUrl}/${recordId}/Form/${formIdentifier}`;
    } else {
      return `${ancestry}${childAppUrl}/${recordId}`;
    }
  }

  /**
   *
   * @param childAppIdentifier
   * @param appIdentifiers      Ancestor app information
   * @param recordId
   * @param formIdentifier
   */
  public getChildRecordUrl(_childAppIdentifier: string, appIdentifiers: AppIdentifiers, recordId?: string, formIdentifier?: string) {
    return this.getRecordUrl2(appIdentifiers, recordId, formIdentifier);
  }

  /**
   *
   * @param appIdentifiers
   * @param parentRecordId
   */
  public getCreateChildRecordUrl(appIdentifiers: AppIdentifiers, parentRecordId: RecordId) {
    const ancestry = appIdentifiers.ancestorAppIds.map((id) => this.getAppBaseUrl(id)).join('');
    const childAppPart = this.getAppBaseUrl(appIdentifiers.childAppIdentifier);
    return `${ancestry}/${parentRecordId}${childAppPart}/create`;
  }

  /**
   * Get the URL for creating a record from the context of a list report.
   * If the list is a top level list, the record is created directly;
   * if it is a child list it is the URL to create and link to the parent
   *
   * @param appIdentifiers
   * @param parentRecordId
   */
  public getListCreateRecordUrl(appIdentifiers: AppIdentifiers, parentRecordId: RecordId) {
    const ancestry = appIdentifiers.ancestorAppIds.map((id) => this.getAppBaseUrl(id)).join('');
    if (appIdentifiers.childAppIdentifier && parentRecordId) {
      const childAppPart = this.getAppBaseUrl(appIdentifiers.childAppIdentifier);
      return `${ancestry}/${parentRecordId}${childAppPart}/create`;
    } else {
      return this.getCreateRecordUrl(appIdentifiers);
    }
  }

  /**
   *
   * @param appIdentifier
   * @param reportIdentifier
   * @param _reportType
   */
  public getReportUrl(appIdentifier: string, reportIdentifier: string, _reportType: Enums.ReportTypes) {
    const baseUrl = this.getAppBaseUrl(appIdentifier);
    if (this.identifiersService.getUnambiguousReport(reportIdentifier)) {
      return `${baseUrl}/${reportIdentifier}`;
    } else {
      return `${baseUrl}/${Enums.ReportTypes[_reportType]}/${reportIdentifier}`;
    }
  }

  /**
   *
   * @param parentAppIdentifier
   * @param parentRecordId
   * @param appIdentifier
   * @param childReportIdentifier
   * @param reportType
   */
  public getChildAppReportFolderUrl(
    parentAppIdentifier: string,
    parentRecordId: string,
    appIdentifier: string,
    childReportIdentifier: string,
    reportType: Enums.ReportTypes
  ): string {
    const baseUrl = this.getAppBaseUrl(parentAppIdentifier);
    if (!reportType || !childReportIdentifier) {
      return `${baseUrl}/${parentAppIdentifier}/${parentRecordId}/${appIdentifier}`;
    }

    const childAppFragment = this.getAppBaseUrl(appIdentifier);
    return `${baseUrl}/${parentRecordId}${childAppFragment}/${childReportIdentifier}`;
  }

  public getChildAppReportFolderUrl2(appIdentifiers: AppIdentifiers, parentRecordId: string, report?: Report): string {
    const parentAppIdentifier = appIdentifiers.parentAppIdentifier;
    const appIdentifier = appIdentifiers.appIdentifier;
    const childAppIdentifier = appIdentifiers.childAppIdentifier;

    // If no report specified, get app default
    if (!report) {
      const app = this.appService.application(appIdentifiers.visibleAppIdentifier);
      report = app.getLastAccessedReport() || app.preferredReport(true);
    }

    const childReportIdentifier = report?.Identifier;
    const reportType: Enums.ReportTypes = report?.Type;

    const ancestry = appIdentifiers.ancestorAppIds.map((id) => this.getAppBaseUrl(id)).join('');

    if (!reportType || !childReportIdentifier) {
      return `${ancestry}/${parentRecordId}/${appIdentifier}`;
    }

    const childAppFragment = this.getAppBaseUrl(childAppIdentifier);
    return `${ancestry}/${parentRecordId}${childAppFragment}/${childReportIdentifier}`;
  }

  public getRecordCursorUrl(appIdentifiers: AppIdentifiers, parentRecordId: string, report: Report, cursor: number) {
    let reportPart: string;
    if (parentRecordId && appIdentifiers.parentAppIdentifier || appIdentifiers.childAppIdentifier) {
      reportPart = this.getChildAppReportFolderUrl2(appIdentifiers, parentRecordId, report);
    } else {
      reportPart = this.getReportUrl(appIdentifiers.appIdentifier, report.Identifier, report.Type);
    }

    const url = `${reportPart}/${cursor}`;
    return url;
  }

  /**
   *
   * @param appIdentifier
   */
  public getVersion14AppUrl(appIdentifier: string) {
    return `/App/Main/${appIdentifier}`;
  }
}
