<div class="w-100" *ngIf="notificationMessage.Status === 0 || notificationMessage.Status === 1">
  <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
</div>

<div
  class="notice theme"
  [ngClass]="{
    'notice-info': notificationMessage.Status === 0 || notificationMessage.Status === 1 || notificationMessage.Status === 4,
    'notice-danger': notificationMessage.Status === 2,
    'notice-success': notificationMessage.Status === 3
  }"
>
  <div class="d-flex">
    <strong class="flex-grow-1 me-3">
      <ng-container *ngTemplateOutlet="jobtype"></ng-container>
    </strong>
    <div class="me-3"><ng-container *ngTemplateOutlet="status"></ng-container></div>
    <ng-container *ngIf="isOnline && showDownload()">
      <fa-icon
        id="download"
        (click)="
          onDownloadClick.emit({ message: notificationMessage.Message, processId: notificationMessage.Id }); $event.stopPropagation()
        "
        [icon]="['fal', 'download']"
        size="1x"
        class="download pointer me-3"
        i18n-title="@@Download"
        title="Download"
      ></fa-icon>
    </ng-container>
    <ng-container *ngIf="showDeleteIcon && isOnline && canDelete()">
      <fa-icon
        id="clearNotification"
        (click)="slideout.emit(true); $event.stopPropagation()"
        [icon]="['fal', 'trash']"
        size="1x"
        class="pointer"
        i18n-title="@@Clear"
        title="Clear"
      ></fa-icon>
    </ng-container>
  </div>
  <ng-container *ngIf="notificationMessage.Title">
    <div>
      <small id="title">{{ notificationMessage.Title }}</small>
      <div *ngIf="notificationMessage.Current">
        <small id="title">{{ notificationMessage.Current }} completed</small>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!notificationMessage.Title">
    <div>
      <small>{{ notificationMessage.Message }}</small>
    </div>
  </ng-container>
  <ng-container *ngIf="notificationMessage.LastUpdated">
    <div>
      <small>{{ notificationMessage.LastUpdated | date: 'medium' }}</small>
    </div>
  </ng-container>
</div>

<ng-template #status>
  <span *ngIf="notificationMessage.Status === 0" i18n="@@Initialising"> Initialising </span>
  <span *ngIf="notificationMessage.Status === 1" i18n="@@Running"> Running </span>
  <!-- The color should be enough for completed and failed, or show when no delete button in the import dialog -->
  <span *ngIf="notificationMessage.Status === 2 && !showDeleteIcon" i18n="@@Failed"> Failed </span>
  <span *ngIf="notificationMessage.Status === 3 && !showDeleteIcon" i18n="@@Completed"> Completed </span>
  <span *ngIf="notificationMessage.Status === 4" i18n="@@InitialCancelledising"> Cancelled </span>
</ng-template>

<ng-template #jobtype>
  <span
    id="job"
    *ngIf="notificationMessage.Tag && notificationMessage.Tag.indexOf('export_') >= 0"
    class="st-text-theme-primary"
    i18n="@@ExportJob"
  >
    Export Job
  </span>
  <span
    id="job"
    *ngIf="notificationMessage.Tag && notificationMessage.Tag.indexOf('import_') >= 0"
    class="st-text-theme-primary"
    i18n="@@ImportJob"
  >
    Import Job
  </span>
  <span
    id="job"
    *ngIf="notificationMessage.Tag && notificationMessage.Tag.indexOf('workflow') >= 0"
    class="st-text-theme-primary"
    i18n="@@Workflow"
  >
    Workflow
  </span>
</ng-template>
