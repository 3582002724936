import { App, Field, logError } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { SummaryExpressionsService } from 'app/services/record/summary-expressions.service';
import { Application } from '../application';
import { AppModel } from 'app/mvc';
import { RecordPatch } from 'app/workspace.module/types';
import { NumericAppField } from './numeric-app-field';
import { debounceTime } from 'rxjs/operators';
import { IApplication } from '../app-field-source';

export class AggregateAppField extends NumericAppField {

  @InjectService(SummaryExpressionsService)
  private readonly summaryExpressionsService: SummaryExpressionsService;

  constructor(field: Field, app: App & IApplication) {
    super(field, app);

    // Note that we don't persist values
    this.isEmphermeral = true;

    // Always static, currently not being set by AS
    this.IsStaticField = true;
  }

  public override attachModel(appModel: AppModel) {
    super.attachModel(appModel);

    if (this.appModel) {
      this.appModel.updatedRecord$.pipe(debounceTime(100)).subscribe(() => {
        this.calculateAggregate().catch(error => logError(error, 'calculateAggregate'));
      });

      setTimeout(() => {
        this.calculateAggregate().catch(error => logError(error, 'calculateAggregate init'));
      }, 100);
    }

  }

  public override async calculateAggregate() {
    // console.warn('aggregating', this.Identifier);
    const app = this.appModel.app.value;
    const result = await this.summaryExpressionsService.calculateForAggregateField(app, this);

    // patch local value
    const patch = new RecordPatch(app.StaticRecordId, app.Identifier);
    patch.addLocalChange(this.Identifier, result);
    // console.warn('patchagg', this.Identifier, patch);
    await this.appModel.patchRecordValue(patch);
  }

  public override validate(): void {
    // don't get involved
  }

}
