<div class="drop-box d-flex flex-column justify-content-center align-items-center py-4"
  (drop)="dropped($event)"
  (dragover)="draggedover($event)"
>
  <fa-icon class="st-text-theme-primary" [icon]="['fal', 'cloud-upload']" size="3x"></fa-icon>
  <span>{{fieldModel.Label}}</span>
</div>
<ng-container  *ngIf="fieldModel.SubText" >
  <!-- Hidden file brower which we can drive programatically -->
  <input
    type="file"
    hidden
    #fileSelector
    (change)="fileSelected($event)"
    [accept]=""
  />
  <div class="st-text-theme-primary pointer m-1" (click)="fileSelector.click()">
    <fa-icon [icon]="['fal', 'cloud-upload']" size="1x"></fa-icon>
    <span class="ms-2">{{fieldModel.SubText}}</span>
  </div>
</ng-container>
