import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth.module/services/authentication.service';
import { tryGetCurrentUser } from '@softools/softools-core';
import { logError, OnlineStatusService } from '@softools/softools-core';
import { VERSION_NUMBER } from 'app-version';
import { SyncStatusService } from 'app/services/sync-status.service';
import { ReLoginService } from 'app/services/login.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { TenantService } from 'app/services/tenant.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Component({
  selector: 'app-auht0-callback',
  templateUrl: './auht0-callback.component.html',
  styleUrls: ['./auht0-callback.component.css'],
})
export class Auht0CallbackComponent implements OnInit {
  public appVersionNumber = VERSION_NUMBER;

  public crossCookieError = false;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private router: Router,
    private syncStatusService: SyncStatusService,
    private loginService: ReLoginService,
    private onlineStatusService: OnlineStatusService,
    private analyticsService: AnalyticsService,
    private tenantService: TenantService,
    private models: GlobalModelService
  ) { }

  ngOnInit() {
    // Stop api calls
    this.onlineStatusService.isServerReachable = false;

    const code = this.route.snapshot.queryParams?.['code'];

    if (code) {
      const tenant = this.tenantService.tenant();

      this.http
        .post(`${environment.baseUrl}/api/auth0/authorize`, {
          RedirectUri: `${window.location.protocol}//${window.location.host}/Account/Callback`,
          Code: code,
          SiteIdentifier: tenant,
        })
        .subscribe(
          async (res: any) => {
            if (res && res.access_token) {
              this.models.globalModel.setAccessTokens(res.expires_in, res.access_token, res.refresh_token);

              // hack tentative fix for storage not updateing before current user is called.
              setTimeout(async () => {
                // get the current user.
                try {
                  // check if the user is registered as a platform user or in a team
                  const userExists = await this.authenticationService.userExists();

                  if (!userExists) {
                    // try and register a user to a team.
                    // currently this should only be needed for SSO users.
                    // once legacy is deprecated we can pass in signup options like firstname, lastname and code etc...
                    const errorMessage = await this.authenticationService.tryRegisterUser();

                    if (errorMessage?.length > 0) {
                      logError(new Error(`${errorMessage}`), 'Failed to register a user');
                      this.closeAndRedirect();
                      return;
                    }
                  }

                  await this.authenticationService.getUserInfo().toPromise();
                  const user = tryGetCurrentUser();

                  if (user) {
                    // Google Tag to track user logins
                    this.analyticsService.trackLogin();

                    // We stop connections to the server on token expire, this stops 401 errors forcing a new popup.
                    this.onlineStatusService.isServerReachable = true;
                    let returnUrl = '/';
                    if (this.route.snapshot.queryParams?.['returnUrl']) {
                      returnUrl = decodeURIComponent(this.route.snapshot.queryParams?.['returnUrl']);
                    }

                    if (this.syncStatusService.syncStatus !== 'completed') {
                      this.router.navigate(['Sync']).catch(error => logError(error, 'Failed to route'));
                    } else {
                      this.router.navigateByUrl(returnUrl).catch(error => logError(error, 'Failed to route'));
                    }
                  } else {
                    this.redirectToLogin();
                  }

                } catch (error) {
                  logError(error, 'Login failed, current user not found.');
                  this.closeAndRedirect();
                }
              });
            } else {
              this.closeAndRedirect();
            }
          },
          (error) => {
            logError(error, 'Login failed');
            this.closeAndRedirect();
          }
        );
    } else if (this.route.snapshot.queryParams?.['error_description'] === 'Unable to configure verification page.') {
      logError(new Error('Unable to configure verification'), '');
      this.crossCookieError = true;
    } else {
      this.closeAndRedirect();
    }
  }

  public closeAndRedirect() {
    this.onlineStatusService.isServerReachable = true;
    this.loginService.loginClosed();
    this.redirectToLogin();
  }

  public redirectToLogin() {
    setTimeout(() => {
      this.router.navigate(['Account/Login']).catch(error => logError(error, 'Failed to route'));
    }, 1500);
  }
}
