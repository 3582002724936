import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListReportComponent } from './listreport.component';
import { AutoLayoutDirective } from './directives/auto-layout.directive';
import { ListreportdetailsComponent } from './listreportdetails.component/listreportdetails.component';
import { CheckboxModule } from '../checkbox.component/checkbox.module';
import { FieldsModule } from '../fields.component/fields.module';
import { SearchModule } from '../search.component/search.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilterheaderModule } from '../filterheader/filterheader.module';
import { SortableModule } from './sortable.component/sortable.module';
import { FiltersModule } from '../../filters/filters.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalOverlayModule } from '../cdk-global-overlay.directive/cdk-global-overlay.module';
import { PopupContainerModule } from '../popup-container/popup-container.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Fields2Module } from '../fields2/fields2.module';
import { MatIconModule } from '@angular/material/icon';
import { SofPlusIconModule } from '@softools/design/icons';
import { RouterModule } from '@angular/router';
import { SummaryExpressionModule } from '../summary-expression/summary-expression.module';
import { ListReportDetailFieldComponent } from './listreportdetails.component/listreportdetailfield.component/list-report-detail-field.component';
import { WsSharedModule } from 'app/workspace.module/components/ws-shared/ws-shared.module';

@NgModule({
  declarations: [
    ListReportComponent,
    AutoLayoutDirective,
    ListreportdetailsComponent,
    ListReportDetailFieldComponent
  ],
  imports: [
    CommonModule,
    FieldsModule,
    CheckboxModule,
    SearchModule,
    SortableModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    FilterheaderModule,
    FiltersModule,
    OverlayModule,
    GlobalOverlayModule,
    PopupContainerModule,
    FontAwesomeModule,
    Fields2Module,
    SofPlusIconModule,
    MatIconModule,
    RouterModule,
    SummaryExpressionModule,
    WsSharedModule,
  ],
  exports: [
    ListReportComponent,
    AutoLayoutDirective,
  ],
  providers: []

})
export class ListReportModule { }
