<div *ngIf="appModel.enableAttachments.$|async">
  <div id="offline" *ngIf="!isOnline">
    <p i18n="Offline message for attachments@@AttachmentsOffline" i18n>
      Go online to view attachments
    </p>
  </div>

  <div id="online" style="position: relative;" *ngIf="isOnline">
    <div class="busy-indicator" *ngIf="attachmentsModel?.loading.busyDelayed$|async">
      <sof-spinner-icon [size]="'2x'"></sof-spinner-icon>
      <span i18n="Generic loading message@@Loading">Loading ...</span>
    </div>

    <mat-table #table [dataSource]="attachments$|async">
      <!-- Name Column -->
      <ng-container matColumnDef="fileName">
        <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="pointer"
          (click)="downloadFileClicked(element)"
        >
          <fa-icon
            [icon]="['fal', 'cloud-download-alt']"
            size="1x"
            class="text-muted"
            style="margin-right:1rem;"
          ></fa-icon>
          {{ element.Title || element.Name }}
        </mat-cell>
      </ng-container>

      <!-- Uploaded by Column -->
      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef i18n>Uploaded By</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.Owner }} </mat-cell>
      </ng-container>

      <!-- Uploaded Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef i18n>Uploaded Date</mat-header-cell>
        <!-- Date format in following is hardcoded because locale en-gb doesn't seem to work.  Revisit for other locales -->
        <mat-cell *matCellDef="let element">
          {{
            element.CreatedDate | date: 'medium'
          }}
        </mat-cell>
      </ng-container>

      <!-- Upload Column -->
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef i18n>Download</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <fa-icon
            [icon]="['fal', 'cloud-download-alt']"
            size="1x"
            class="text-muted"
          ></fa-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div>
      <button
        mat-button
        class="bg-st-theme-primary text-white mt-2"
        (click)="toggleFullList()"
        *ngIf="(attachments$|async)?.length > 5"
      >
        <span *ngIf="!fullList" i18n="@@showMore">Show More</span>
        <span *ngIf="fullList" i18n="@@showLess">Show Less</span>
      </button>
    </div>

  </div>
</div>
