<section class="report-grid-container">

  <ng-container *ngIf="!(reportModel.loadError.$| async)">
    <div class="report-controls-area">
      <app-ws-sharedreportactions
        [app]="currentApp"
        [isLoading]="reportModel.busy.busy$|async"
        [baseFilter]="(reportModel.report.$|async)?.BaseFilter"
        [isArchived]="globalModel.archived.$|async"
        [isSearchable]="false"
        [isFilterable]="false">
      </app-ws-sharedreportactions>
    </div>

    <app-folders
      #leftAside
      class="left-aside-area"
      [folderModel]="pageModel.folderModel"
      [selectedFolderIdentifier]="reportModel.reportIdentifier.$|async"
      [title]="currentApp?.Title"
      [showAddNewRecord]="canCreate"
      [appIdentifier]="appIdentifier"
      [appIdentifiers]="appIdentifiers"
      [pinnedStateVal]="showAside$ | async">
    </app-folders>

    <div class="main-content-area">
      <article *ngIf="(isOfflineReport$|async) || (onlineStatus.isServerReachable$|async); else reportoffline">
        <div>
          <ng-container *ngIf="!(reportModel.busy.busy$|async)">
              <app-custom-not-supported *ngIf="isCustomDashboard()"
                headerText="Custom dashboard is not supported"
                description="(Please contact support to migrate this enhanced custom dashboard report to standard)">
              </app-custom-not-supported>

              <app-dashboardreport *ngIf="(this.reportModel.report.$|async)?.Dashboard as dashboard"
                [@rightbarToggleContentState]="pageModel.sidebarState.$|async"
                [dashboard]="dashboard"
                [dashboardModel]="dashboardModel"
                [appIdentifiers]="appIdentifiers"
                [parentAppId]="reportModel?.parentAppIdentifier.$|async"
                [parentRecordId]="reportModel?.parentRecordId.$|async">
              </app-dashboardreport>
          </ng-container>

        </div>
      </article>
    </div>

    <div class="footer-area">
      <app-shared-footer
      [appModel]="appModel"
      [appIdentifier]="appIdentifier"
      [showAside]="showAside$|async"
      [showFooter]="showFooter"
      [isOnline]="(onlineStatus.isServerReachable$|async)"></app-shared-footer>
    </div>
  </ng-container>
</section>

<app-new-record-button [canCreate]="canCreate" [createUrl]="createUrl"></app-new-record-button>

<ng-template #reportoffline>
  <app-report-offline></app-report-offline>
</ng-template>
