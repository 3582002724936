
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CompatibilityService } from 'app/services/compatibility.service';

@Injectable()
export class CompatibilityGuard implements CanActivate {

  private compatible: boolean;

  constructor(
    private router: Router,
    private compatibilityService: CompatibilityService) { }

  async canActivate() {
    if (this.compatible == null) {
      this.compatible = await this.compatibilityService.IsCompatible();
    }
    if (!this.compatible) {
      return this.router.parseUrl('/Incompatible');
    }
    return true;
  }
}
