import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimesComponent } from './times.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [TimesComponent],
  exports: [TimesComponent]
})
export class SofTimesIconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faTimes);
  }
}
