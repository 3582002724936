import { Report, QueryParameters } from '@softools/softools-core';
import { ReportFilter } from 'app/filters/types';

export interface IRecordSelectionQueryOptions {
  stripBrackets?: boolean;
  includePosition?: boolean;
  supressOrdering?: boolean;
  supressArchived?: boolean;
  additionalFilter?: ReportFilter;
}

export interface IRecordSelection {
  start?: number;
  count?: number;
  filter?: ReportFilter;
  report?: Report;
  showArchived?: boolean;
  hierarchy?: string;
}

export class RecordSelection implements IRecordSelection {
  start?: number;
  count?: number;
  filter?: ReportFilter;
  report?: Report;
  showArchived?: boolean;
  hierarchy?: string;

  constructor(template?: IRecordSelection) {
    if (template) {
      Object.assign(this, template);
    }
  }

  public queryParamsForCount(stripBrackets = false): QueryParameters {
    const queryParams: QueryParameters = this.filter?.getQueryParameters({ showArchived: this.showArchived, noBracketFields: stripBrackets }) || {};
    delete queryParams.$skip; // get all records when counting
    delete queryParams.$top;
    delete queryParams.$orderby; // don't need to sort when couting
    delete queryParams.$groupby;

    if (this.hierarchy) {
      queryParams.hierarchy = this.hierarchy;
    }

    if (stripBrackets && queryParams.$filter) {
      queryParams.$filter = queryParams.$filter.replace(/[\[\]]/g, '');
    }

    return queryParams;
  }

  public queryParams(options?: IRecordSelectionQueryOptions) {
    const stripBrackets = options?.stripBrackets || false;
    const archived = options?.supressArchived ? undefined : this.showArchived;

    // Combine filters
    const filters: Array<ReportFilter> = [];
    if (this.filter) {
      filters.push(this.filter);
    }

    if (options?.additionalFilter) {
      filters.push(options.additionalFilter);
    }

    const filter = ReportFilter.merge(filters);
    const queryParams: QueryParameters = filter?.getQueryParameters({ showArchived: archived, noBracketFields: stripBrackets }) || {};
    if (options?.includePosition) {
      queryParams.$skip = this.start;
      queryParams.$top = this.count;
    } else {
      delete queryParams.$skip;
      delete queryParams.$top;
    }

    if (options?.supressOrdering) {
      delete queryParams.$orderby;
      delete queryParams.$groupby;
    }

    return queryParams;
  }
}
