<div class="categories-filter">
  <fa-layers class="h-100">
    <fa-icon [icon]="['fal', 'filter']" size="2x" class="pointer" (click)="state = state === 'active' ? 'inactive' : 'active'"></fa-icon>
    <fa-icon *ngIf="launchpadModel.checkedCategories.size > 0" 
      [icon]="['fas', 'check-circle']"
      class="check-icon-overlay report-action-icon pointer">
    </fa-icon>
  </fa-layers>
</div>

<div [@popupState]="state" class="card text-dark"  style="position: absolute; z-index: 1000; right: 1rem; min-width: 8rem;" (mouseleave)="state = 'inactive'">
  <div class="card-body">
    <div class="me-2">
      <app-checkbox
         *ngFor="let cat of launchpadModel.categories.$|async" 
          [text]="cat"         
          (onchecked)="catChecked.emit(cat)" 
          (onunchecked)="catUnchecked.emit(cat)">
        </app-checkbox>
    </div>
  </div>
</div>
