import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponent } from './edit.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [EditComponent],
  exports: [EditComponent]
})
export class SofEditIconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faEdit);
  }
}
