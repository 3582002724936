import { Injectable } from '@angular/core';
import { OnlineStatusService } from '@softools/softools-core';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(
    private onlineStatusService: OnlineStatusService) {
  }

  public setOnlineStatus(val: boolean) {
    if (this.onlineStatusService.isServerReachable !== val) {
      this.onlineStatusService.isServerReachable = val;
    }
  }
}
