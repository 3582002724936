import { FilterModel } from 'app/mvc';
import { Application } from 'app/types/application';
import { OverviewReportModel } from '../overview-report.model';
import { RecordsReportModel } from '../records-report.model';
import { ChartDataAccssor } from './chart-data-accessor';
import { RecordDataAccessor } from './record-data-accessor';

export const ACCESSOR_FACTORY = 'ACCESSOR_FACTORY';

export class AccessorFactory {

  public createChartAccessor(app: Application, model: OverviewReportModel): ChartDataAccssor {
    return null;
  }

  public createRecordAccessor(app: Application, model: RecordsReportModel, filterModel: FilterModel): RecordDataAccessor {
    return null;
  }

  public createCardAccessor(app: Application, model: RecordsReportModel, filterModel: FilterModel): RecordDataAccessor {
    return null;
  }

}
