<div>
  <app-input-icon icon="caret-down"
    [clickable]="!getIsDisabled()" 
    [elementStyles]="elementStyles"
    (iconClicked)="openMenu($event)" 
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"  
  >
    <input
      [value]="displayValue$|async"
      readonly="readonly"
      #input
      class="w-100 form-control  form-control-o"
      [ngStyle]="textStyle"
      [attr.data-identifier]="fieldModel.Identifier"
      [class.inline-control]="inline"
      [class.inline]="inline"
      [class.readonly-normal-appearance]="!getIsDisabled()"
      (click)="!getIsDisabled() && openMenu($event)"
    />
  </app-input-icon>

  <div *ngIf="selectList" [matMenuTriggerFor]="selectionMenu" style="height: 0" ></div>

  <mat-menu #selectionMenu>
    <ng-template matMenuContent>
      <button mat-menu-item (click)="onSelection('')" i18n>-- Select an Item --</button>
      <button mat-menu-item *ngFor="let option of selectList?.SelectListOptions" (click)="onSelection(option?.Value)">
        {{option?.Text}}
      </button>
    </ng-template>
  </mat-menu>
</div>
