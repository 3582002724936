import { Record, IndexedAppData, App, AppDataStorageService } from '@softools/softools-core';

export interface SearchKey {
  fieldId: string;
  value: any;
  exact: boolean;
}

/**
 * An @see AppDataIndex that is further filtered by field values.
 *
 * The index is expensive to build as we need to consider the sort order.  When records
 * are filtered by queries that last for a long time (e.g. a reports active filter) we
 * can speed up indexing by pre-filtering (so there is less to sort).  However when a
 * filter is short lived (e.g. a search term) we don't want to take the cost of a sort
 * every time.  The @see FilteredIndex allows a filter to be applied to the sorted filter
 * which is much quicker.
 *
 */
export class FilteredIndex implements IndexedAppData {
  /** Fields we need to search */
  private keys: Array<SearchKey> = [];

  /** Filtered record id list.  If null we have applied no filter and should delegate to the index  */
  private recordIds: Array<string> = null;

  constructor(
    private index: IndexedAppData,
    public appDataService: AppDataStorageService,
    public app: App = index.app
  ) {}

  public async indexAll(): Promise<void> {
    await this.index.indexAll();
  }

  /**
   * Add a search item
   * @param fieldId field to scan
   * @param value value
   * @param exact true for exact match, false for contains
   *
   * If exact is true, the value can be any object and is compared using equality
   * operations.  If exact is false the field and values should be string types;
   * a match is made if the field contains the value ignoring case.
   */
  public addKey(fieldId: string, value: any, exact: boolean = true): FilteredIndex {
    if (!exact) {
      value = value.toLocaleLowerCase();
    }
    const match = { fieldId, value, exact };
    this.keys.push(match);
    return this;
  }

  public async createIndex(): Promise<FilteredIndex> {
    this.recordIds = [];

    await this.index.eachRecord((record) => {
      this.keys.forEach((key) => {
        const value = record[key.fieldId];
        const matched = key.exact ? value === key.value : value && (<string>value).toLocaleLowerCase().includes(key.value);
        if (matched) {
          this.recordIds.push(record._id);
        }
      });
    });

    return this;
  }

  public get length() {
    return this.recordIds ? this.recordIds.length : this.index.length;
  }

  public async getRecords(start: number, count: number): Promise<Array<Record>> {
    if (this.recordIds) {
      const appDataService = this.appDataService;
      // const app = this.index.app;
      const promises: Array<Promise<Record>> = [];
      const ids = this.recordIds.slice(start, start + count);
      ids.forEach((id) => {
        const record = appDataService.getRecordByIdAsync(id);
        promises.push(record);
      });

      return Promise.all(promises);
    } else {
      return this.index.getRecords(start, count);
    }
  }

  public async getRecordsWithGetterFunc(
    start: number,
    count: number,
    recordGetterFunc: (app: App, id: string) => Promise<Record>
  ): Promise<Array<Record>> {
    if (this.recordIds) {
      const app = this.index.app;
      const promises: Array<Promise<Record>> = [];
      const ids = this.recordIds.slice(start, start + count);
      ids.forEach((id) => {
        const record = recordGetterFunc(app, id);
        promises.push(record);
      });

      return Promise.all(promises);
    } else {
      return this.index.getRecords(start, count);
    }
  }

  public async getRecordIds(start = 0, count?: number): Promise<Array<string>> {
    if (count === undefined) {
      count = this.length - start;
    }

    const ids = this.recordIds.slice(start, start + count);
    return ids;
  }

  public async eachRecord(callback: (record: Record, id: string, index: number) => any): Promise<void> {
    const promises: Array<Promise<any>> = [];

    this.recordIds.forEach((id, couter) => {
      const recordPromise = this.appDataService.getRecordAsync(this.app.Identifier, id);
      promises.push(recordPromise.then((record) => {
        callback(record, id, couter);
      }));
    });

    await Promise.all(promises);

    return;
  }

  // unused, remove?
  public async positionOf(recordId: string): Promise<number> {
    return this.recordIds.findIndex((id) => id === recordId);
  }

  // unused, remove?
  public adjacentIds(recordId: string): { prev: string; next: string } {
    const ids = this.recordIds;
    const pos = ids.findIndex((id) => id === recordId);
    if (pos < 0) {
      return null; // id not found
    }

    return {
      prev: pos > 0 ? ids[pos - 1] : null,
      next: pos + 1 < ids.length ? ids[pos + 1] : null,
    };
  }

  public onRecordCreated(record: Record) {
  }
}
