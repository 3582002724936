import { NgZone } from '@angular/core';
import { SiteStorageService, SiteRepository, OnlineStatusService, tryGetCurrentUser, SiteSettingsStorageService, logError } from '@softools/softools-core';
import { ISiteController } from 'app/mvc/common/site.controller.interface';
import { InjectService } from 'app/services/locator.service';

export const CURRENT_SITE_VERSION_NUMBER_KEY = 'CURRENT_SITE_VERSION_NUMBER_KEY';

/*
    Get latest site version number from server.
    Auto Resync to storage and state if updated
*/
export class SiteSyncService {

  @InjectService(SiteStorageService)
  private siteService: SiteStorageService;

  @InjectService(SiteSettingsStorageService)
  private siteSettingsService: SiteSettingsStorageService;

  @InjectService(SiteRepository)
  private siteRepository: SiteRepository;

  @InjectService(OnlineStatusService)
  private onlineStatus: OnlineStatusService;

  @InjectService(NgZone)
  private ngZone: NgZone;

  constructor(
    private site: ISiteController
  ) {
  }

  public start() {
    setInterval(async () => {
        try {
          const userModel = tryGetCurrentUser();

          if (userModel && this.onlineStatus.isConnected) {
            const currentStorageSiteVersionNumber: string = localStorage.getItem(CURRENT_SITE_VERSION_NUMBER_KEY);

            const latestSiteVerionNumberFromServer: number = await this.siteRepository.getLatestSiteVersionNumber();

            if (currentStorageSiteVersionNumber === undefined || currentStorageSiteVersionNumber === null) {
              localStorage.setItem(CURRENT_SITE_VERSION_NUMBER_KEY, latestSiteVerionNumberFromServer.toString());
              // Don't have version number so assume freshly loaded and we are ok
              return true;
            }

            if (latestSiteVerionNumberFromServer > +currentStorageSiteVersionNumber) {
              localStorage.setItem(CURRENT_SITE_VERSION_NUMBER_KEY, latestSiteVerionNumberFromServer.toString());

              // Update site login resource in storage
              await this.siteService.updateSiteLoginDataResourceAsync(userModel.Tenant);

              // Update global site basic data in storage
              await this.siteService.updateSiteGlobalBasicDataAsync();

              const settings = await this.siteSettingsService.refreshSettingsAsync();
              if (settings) {
                // Update site properties model
                this.site.setSiteSettings(settings);
              }
            }
          }
        } catch (error) {
          // Don't blow up on errors as this isn't crucial
          console.warn('Error caught getting site version', error);
        }

        return null;

      }, 60000);
  }
}
