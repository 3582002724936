import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-filter-asc-desc',
  templateUrl: './filter-asc-desc.component.html',
  styleUrls: ['./filter-asc-desc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterAscDescComponent {
  public orderAscending = 'asc';
  public orderDescending = 'desc';
  public orderNone = 'none';

  @Input() selected = 'none';
  @Input() disabled = false;
  @Input() placeholder = '';

  @Output() change = new EventEmitter<string>();

  constructor() { }

  public selectionChange(order: string) {
    this.change.emit(order);
  }
}
