import { v4 as uuid } from 'uuid';

import { Injectable } from '@angular/core';
import { PendingUser, Record, RecordId, permissionSets, AddUserRequest, UsersRepository, PendingUsersRepository, Enums, UserStorageService, ValidationErrorResponse } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '..';
import { RecordPatch } from 'app/workspace.module/types';
import { PendingUsersAppConfig } from './pending-users.app.config';
import { ToolbarContext, GetRecordOptions, AppCapabilities } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { EpochConverter } from 'app/types/epoch-converter';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { ISelectOptions } from 'app/softoolsui.module/select-options/select-options.component';
import { HomepagesService } from 'app/services/homepages.service';
import { TeamsService } from 'app/services/teams.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { PermissionsService } from 'app/services/permissions.service';
import { InjectService } from 'app/services/locator.service';
import { SiteService } from 'app/services/site-service.service';
import { GlobalModel, RecordModel, ReportModel } from 'app/mvc';
import { UserInvitePopupComponent, UserInvitePopupData } from 'app/workspace.module/components/user-invite-popup/user-invite-popup.component';
import { ToastyService } from 'app/services/toasty.service';
import { UsersService } from 'app/services/users.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { EmailActivityComponent } from 'app/email-activity/email-activity.component';

@Injectable({ providedIn: 'root' })
export class PendingUsersApplication extends OnlineEmbeddedApp<PendingUser> {

  @InjectService(SiteService)
  protected readonly siteService: SiteService;

  protected selectListOptions: ISelectOptions;

  private globalModel: GlobalModel;

  constructor(
    protected permissionsService: PermissionsService,
    private repository: PendingUsersRepository,
    private usersService: UsersService,
    protected usersRepository: UsersRepository,
    private userStorageService: UserStorageService,
    private homepagesService: HomepagesService,
    private teamsService: TeamsService,
    private recordPersistService: RecordPersistService,
    private toast: ToastyService,
    private models: GlobalModelService
  ) {
    super(PendingUsersAppConfig);

    this.globalModel = this.models.globalModel;
  }

  public override async initialise(): Promise<void> {

    await super.initialise();

    const site = this.siteService.Site;
    if (site?.UsernameNotRequired) {
      const field = this.getField('Username');
      if (field) {
        field.Required = false;
        delete field.MinLength;
      }

      this.Reports.forEach(report => {
        report.ListReportFields = report.ListReportFields.filter(f => f.FieldIdentifier !== 'Username');
      });

      this.Templates.forEach(template => {
        template.Layout = template.Layout.filter(l => l.FieldIdentifier !== 'Username');
      });
    }
  }

  public override get permissions() {
    return this.permissionsService.userPermisions;
  }

  public override initRecord(_record: any, selectListOptions: ISelectOptions) {
    // Capture config parameters
    this.selectListOptions = selectListOptions;
  }

  public toRecord(pending: PendingUser): Record {
    return pending && {
      ...pending,
      CreatedDate: EpochConverter.toEpoch(pending.Created),
      Expiration: EpochConverter.toEpoch(pending.Expiration),
      _id: pending.Id.toString(),
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: '',    // can't search using API
      IsArchived: false,
    } as Record;
  }

  public override async totalCount(): Promise<number> {
    return this.repository.getCountAsync();
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async getViewRecordCountAsync(selection: RecordSelection): Promise<number> {
    return this.getApiViewRecordCount(selection);
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    delete selection.showArchived;    // Archived not supported
    const queryParams = selection.queryParams({ stripBrackets: true });

    const pendings = await this.repository.getPendingUsersAsync(selection.count, selection.start, queryParams);
    return pendings.map(summary => this.toRecord(summary));
  }

  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    delete selection.showArchived;    // Archived not supported
    const queryParams = selection.queryParams({ stripBrackets: true });
    const pendings = await this.repository.getPendingUsersAsync(1, +cursor - 1, queryParams);
    return pendings.map(summary => this.toRecord(summary)).pop();
  }

  public override async getRecordByIdAsync(id: RecordId, options?: GetRecordOptions): Promise<Record> {


    // Look up any pending patch if we're going to need it
    const pendingPatch = options?.applyPatch && this.recordPersistService.getPatch(id);

    // If patch is new, it's not been persisted so convert to record and return
    if (pendingPatch?._new) {
      return pendingPatch.toRecord(this);
    }

    const pending = await this.repository.getPendingUserAsync(id);
    return this.toRecord(pending);
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {
    if (recordPatch._new) {
      const record: any = recordPatch.toRecord(this);
      const user: AddUserRequest = {
        Id: recordPatch._id,
        Username: record.Username,
        FirstName: record.FirstName,
        LastName: record.LastName,
        Email: record.Email,
        Company: record.Company,
        MobilePhone: record.MobilePhone,
        Language: record.Language,
        DefaultHomepageIdentifier: record.DefaultHomepageIdentifier,
        Permissions: record.RegistrationPermissionSets.map(ps => ps.Value),
        TeamIds: record.TeamIds.map(t => t.Value)
      };

      const result = await this.usersRepository.create(user).toPromise();

      if (result?.['ValidationErrors']) {
        const validationError = new ValidationErrorResponse();
        Object.assign(validationError, result);
        throw validationError;
      }

      if (result) {
       // Get the real record
        const pending = await this.repository.getPendingUserAsync(result.RecordId);
        return this.toRecord(pending);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public override validateRecordId(id: RecordId): RecordId | boolean {
    if (!id) {
      id = uuid();
    }
    return id;
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new ToolbarAction('Invite', 'envelope-open-text', async () => {
      await this.invite();
    }));

    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await reportModel.refresh();
    }));
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {

    actions.push(new ToolbarAction('EmailActivity', 'envelope', async () => {
      await this.globalModel.dialogAsync(EmailActivityComponent, { userId: id });
    }));

    actions.push(new ToolbarAction('Invite', 'envelope-open-text', async () => {
      await this.invite();
    }));

    const address = (context.record as any)?.Email;
    const id = context.record?._id;
    if (address && id) {
      actions.push(new ToolbarAction('ResendInvite', 'envelope', async () => {
        await this.resend(id, address);
      }));
    }

    if (id) {
      actions.push(new ToolbarAction('Delete', 'trash', async () => {
        await this.delete(id);
      }));
    }

    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await recordModel.reload();
    }));
  }

  /**
   * Return selection list options for a field if provided by the application
   * @param fieldId Id of selection field
   * @returns selection options, or null if not provided by the app
   */
  public override selectionListOptions(fieldId: string): Array<any> {
    switch (fieldId) {
      // Get localised options
      case 'Language': return this.selectListOptions && this.selectListOptions.languageOptions;
      case 'DefaultHomepageIdentifier': return this.homepagesService.getAll().map(hp => ({ Text: hp.Title, Value: hp.Identifier }));
      case 'RegistrationPermissionSets': return this._getPermissionSets();
      case 'TeamIds': return this.teamsService.getAllMappedTeams();
      default: return null;
    }
  }

  private _getPermissionSets() {
    const options = permissionSets.map(ps => ({ Text: ps.label, Value: ps.name, }))
      .map((item, index) => ({ ...item, Id: `${index}`, DisplayOrder: index }));
    return options as Array<any>;
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: true,
      canEdit: false,
      canView: true,
      canSort: true,
      canGroup: false,  // server API currently doesn't support grouping.
    };
  }

  private async invite() {
    const teams = await this.teamsService.getAllTeams();
    const data: UserInvitePopupData = { teams };
    await this.globalModel.dialogAsync(UserInvitePopupComponent, data, {
      maxWidth: '100vw',
      width: '100vw',
    });
  }

  private async resend(id: string, address: string) {
    const confirm = await this.globalModel.showModalAsync(Enums.ModalType.confirm,
      $localize`Resend invitation`,
      $localize`Resend the pending user email to ${address}?`);

    if (confirm) {
      await this.repository.resend(id, address);
      this.toast.info($localize`Email Sent`, $localize`Invitation email has been resent`);
    }
  }

  public override async refresh(): Promise<boolean> {
    return true;
  }

  private async delete(id: string) {
    const confirm = await this.globalModel.showModalAsync(Enums.ModalType.confirm,
      $localize`Delete the pending user invitation?`,
      $localize`Delete invitation`);

    if (confirm) {
      await this.repository.deleteAsync(id);
      await this.userStorageService.removeUsersAsync(id, true);
      await this.usersService.refresh();

      this.globalModel.header?.goBack();    // switch back to report

      await this.refresh();

      this.toast.info($localize`Invitation  has been deleted`, $localize`Invitation Deleted`);
    }
  }
}
