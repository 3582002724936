
/**
 *
 */
export class ServerValidationError {
  FieldLabel: string;
  Message: string;
}

/**
 *
 */
export class ValidationErrorResponse {
  ValidationErrors: Array<ServerValidationError>;
  Code: number;
  Message: string;
}
