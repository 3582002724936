
import { AccessType, SourceType, ActorType } from '../enums';

export interface AccessRight {
  Id: string;
  AccessType: AccessType;
  SourceType: SourceType;
  ActorType: ActorType;
  ActorId: string;
  ParentId: string;
  Permissive: boolean;

  _id: {
    $oid: string;
  };
}

export const initialAccessRight: AccessRight = {
  Id: '',
  AccessType: AccessType.Allow,
  SourceType: SourceType.App,
  ActorType: ActorType.All,
  ActorId: '',
  ParentId: '',
  Permissive: true,
  _id: { $oid: '' },
};

