import * as moment from 'moment';

import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { OdataExpressionType, Enums, logError, stringCompare, isDefined, IFilterTerm } from '@softools/softools-core';
import { Field } from '@softools/softools-core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FilterSpecification } from 'app/filters/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UsersService } from 'app/services/users.service';
import { Application } from 'app/types/application';
import { AppService } from 'app/services/app.service';
import { AppField } from 'app/types/fields/app-field';
import { ContainerType } from 'app/softoolsui.module/fields';
import { FilterModel, ReportModel } from 'app/mvc';
import { FilterEditorUi } from '../types/filter-editor-ui';
import { ComponentBase } from 'app/softoolsui.module';
import { FilterPopupFieldComponent } from '../filter-popup-field/filter-popup-field.component';

export enum ComparisonType {
  Equality = 1,
  Textual = 2,
  Numeric = 4,
  Binary = 8,
  Temporal = 16,
  Identity = 32,    // The same as equality but with less mathy labels, is/is not
}

export interface FilterTermUpdates {
  fieldId: string;
  term?: IFilterTerm;
  isSort?: boolean;
  sortAscending?: boolean;
  isGroup?: boolean;
  groupAscending?: boolean;
  row?: number;
}

@Component({
  selector: 'sof-filter-edit',
  templateUrl: './filter-simple-popup.component.html',
  styleUrls: ['./filter-simple-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSimplePopupComponent extends ComponentBase implements OnChanges, OnInit {

  @Input() public appIdentifier: string;

  /** The filter being edited */
  @Input() public filterSpec: FilterSpecification;

  @Input() public filterModel: FilterModel;

  /** Report the popup is attached to (if any) */
  @Input() public reportModel: ReportModel;

  @Input() public filterEditorUi: FilterEditorUi;

  // @Output() public updated = new EventEmitter<ReportFilter>();

  @Output() public termUpdated = new EventEmitter<FilterTermUpdates>();

  @Output() public cancelled = new EventEmitter();

  /** if true, dismisses immediately on changing simple states (sort, group) to reduce number of clicks */
  @Input() public autoClose = true;

  /** Show or hide shorting and grouping controls */
  @Input() public showSortGroup = true;

  public field$ = new BehaviorSubject<AppField>(null);

  public disable$ = new BehaviorSubject(false);

  public application: Application;

  public editMode = true;

  /** Based on AS setting this flag will be set and control the visibility of Sorting button */
  public sortable = true;

  public get showPopup() { return this.filterEditorUi.showPopup; }
  public get posLeft() { return this.filterEditorUi.popupX; }
  public get posTop() { return this.filterEditorUi.popupY; }

  // Operation options and current selection
  public noOperation: OdataExpressionType = OdataExpressionType.Filter;   // 0 - strange name but works for none
  public equalOperation = OdataExpressionType.Equals;
  public notEqualOperation = OdataExpressionType.NotEquals;
  public greaterOperation = OdataExpressionType.GreaterThan;
  public greaterEqualOperation = OdataExpressionType.GreaterThanOrEqual;
  public lessOperation = OdataExpressionType.LessThan;
  public lessEqualOperation = OdataExpressionType.LessThanOrEqual;
  public startsWithOperation = OdataExpressionType.StartsWith;
  public endsWithOperation = OdataExpressionType.EndsWith;
  public substringOperation = OdataExpressionType.Substring;

  public selectedOperation: OdataExpressionType = this.noOperation;

  // Sort/Group options and current selection
  public orderAscending = 'asc';
  public orderDescending = 'desc';
  public orderNone = 'none';
  public selectedSort = this.orderNone;
  public selectedGrouping = this.orderNone;

  public value: any;

  public fieldType: Enums.FieldType;

  /** Field being edited */
  public field: AppField;

  public permittedFields: Array<Field>;

  public containerType = ContainerType.FilterEdit;

  public filteredFields: Observable<Array<Field>>;
  private sub = new Subject<string>();

  @ViewChild('editor')
  private editor: FilterPopupFieldComponent;

  private _sortChanged = false;
  private _groupChanged = false;

  /* eslint-disable no-bitwise */
  public get isEquality(): boolean { return (this.comparisonType & ComparisonType.Equality) !== 0; }
  public get isIdentity(): boolean { return (this.comparisonType & ComparisonType.Identity) !== 0; }
  public get isTextual(): boolean { return (this.comparisonType & ComparisonType.Textual) !== 0; }
  public get isTemporal(): boolean { return (this.comparisonType & ComparisonType.Temporal) !== 0; }
  public get isBinary(): boolean { return (this.comparisonType & ComparisonType.Binary) !== 0; }
  public get isNumeric(): boolean { return (this.comparisonType & ComparisonType.Numeric) !== 0; }

  constructor(
    private usersService: UsersService,
    private appService: AppService,
  ) {
    super();
  }

  ngOnInit(): void {

    this.application = this.appService.application(this.appIdentifier);

    this.filteredFields = this.sub
      .pipe(
        startWith(''),
        map((field) => {
          const data = field ? this.filterFields(field) : [...this.permittedFields];
          data.sort((a, b) => stringCompare(a.Label, b.Label));
          return data;
        }));

    this.setDisabled();
  }

  public get comparisonType(): ComparisonType {
    switch (this.fieldType) {
      case Enums.FieldType.Integer:
      case Enums.FieldType.Long:
      case Enums.FieldType.Number:
      case Enums.FieldType.Money:
      case Enums.FieldType.Range:
        return ComparisonType.Numeric | ComparisonType.Equality;
      case Enums.FieldType.Bit:
        return ComparisonType.Binary;
      case Enums.FieldType.Literal:
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Notes:
      case Enums.FieldType.Email:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Barcode:
        return ComparisonType.Textual | ComparisonType.Equality;
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
      case Enums.FieldType.Time:
        return ComparisonType.Temporal | ComparisonType.Identity;
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Selection:
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
      case Enums.FieldType.ImageList:
        return ComparisonType.Identity;
      default:
        return ComparisonType.Equality;
    }
  }

  public cancel() {
    this.cancelled.emit();
  }

  public accept() {

    // Get current value or null if editor hidden because filter is off
    this.value = this.editor?.value;

    const updates: FilterTermUpdates = { fieldId: this.field?.Identifier };

    const originalOperator = this.filterEditorUi.editFilterTerm?.Operator;
    const originalValue = this.filterEditorUi.editFilterTerm?.Operand;
    const termChanged = this.selectedOperation !== originalOperator || this.value !== originalValue;
    if (termChanged) {
      updates.term = {
        ...this.filterEditorUi.editFilterTerm,
        FieldIdentifier: this.field?.Identifier,
        Operator: this.selectedOperation,
        // Bit fields don#t set a value, always compare eq/ne against true
        Operand: (this.field.Type === Enums.FieldType.Bit) ? true : this.value,
      };
    }

    if (this._sortChanged) {
      updates.isSort = this.selectedSort !== this.orderNone;
      updates.sortAscending = this.selectedSort === this.orderAscending;

      // Sorting and Grouping done on the same field then remove grouping.
      if (this.field.Identifier === this.filterSpec.groupField) {
        updates.isGroup = false;
        this.selectedGrouping = this.orderNone;
      }
    }

    if (this._groupChanged) {
      updates.isGroup = this.selectedGrouping !== this.orderNone;
      updates.groupAscending = this.selectedGrouping !== this.orderDescending;

      // Sorting and Grouping done on the same field then remove sorting.
      if (this.field.Identifier === this.filterSpec.sortField) {
        updates.isSort = false;
        this.selectedSort = this.orderNone;
      }
    }

    this.termUpdated.emit(updates);
  }

  public ngOnChanges(changes: SimpleChanges): void {

    try {

      this.application = this.appService.application(this.appIdentifier);

      if (changes['filterEditorUi']) {

        let id: string;

        const term = this.filterEditorUi.editFilterTerm;
        if (term) {
          // we have a term set, use that
          id = term.FieldIdentifier;
          this.field = this.application.getField(id);
          this.fieldType = this.field?.Type;
          this.field$.next(this.field);
          this.value = term.Operand;
          this.selectedOperation = term.Operator;


        } else {
          // When the UI state changes, update working fields to
          // capture the field we're popping up for
          id = this.filterEditorUi.fieldId;
          if (id) {
            const summary = this.filterSpec.getFieldFilterSummary(id);
            if (summary) {

              this.value = summary.value;
              this.selectedOperation = summary.operator;

              this.field = this.application.getField(id);
              this.fieldType = summary.fieldType;
              this.field$.next(this.field);
            }
          }
        }

        // Show sort/group controls when field is sortable
        this.sortable = this.field?.isFieldSortable();

        // get sort/group from filter spec whether we using a term or not.  This could be simplified
        if (this.filterEditorUi.editFilterSpec) {
          // Set sort value
          if (id === this.filterSpec.sortField) {
            this.selectedSort = this.filterSpec.sortAscending ? this.orderAscending : this.orderDescending;
          } else {
            this.selectedSort = this.orderNone;
          }

          // Set grouping value
          if (id === this.filterSpec.groupField) {
            this.selectedGrouping = this.filterSpec.groupAscending ? this.orderAscending : this.orderDescending;
          } else {
            this.selectedGrouping = this.orderNone;
          }

          // if both group by and sorting on same field ignore sorting
          if (this.filterSpec.groupField === this.filterSpec.sortField) {
            this.selectedSort = this.orderNone;
          }
        }
      }

      // Update valid field type list for new filters
      if (changes['filterEditorUi'] || changes['filterControl']) {
        if (this.filterEditorUi && this.filterModel && this.filterSpec) {
          // Fields are allowed if they are of filterable type and do not appear in the current filter
          this.filterSpec.fieldsInCurrentFilter();
          this.permittedFields = this.filterSpec.appFields
            .filter(field => field.isFilterable())
            .filter(f => this.filterEditorUi.disallowFields.findIndex(ff => ff.Identifier === f.Identifier) < 0);
        }
      }

      if (changes['value']) {
        this.setDisabled();
      }

    } catch (error) {
      logError(error, '');
    }
  }

  public get isValidExpression(): boolean {
    return this.selectedOperation !== null;
  }

  public sortChanged() {
    this._sortChanged = true;
    if (this.autoClose) {
      this.accept();
    }
  }

  public groupChanged() {
    this._groupChanged = true;
    if (this.autoClose) {
      this.accept();
    }
  }

  public groupOrderChanged(order: string) {
    this.selectedGrouping = order;

    this._groupChanged = true;
    if (this.autoClose) {
      this.accept();
    }
  }

  public fieldChanged(e: MatAutocompleteSelectedEvent) {
    if (e.option.value === '') {
      this.field = null;
      this.fieldType = Enums.FieldType.None;
    } else {
      this.field = this.application.getField(e.option.value);
      this.fieldType = this.field.Type;

      // hack/bad encapsulation - set sensible default on multi
      if (this.fieldType === Enums.FieldType.MultiState) {
        switch (this.field.SubType) {
          case Enums.MultiStateType.HarveyBall:
          case Enums.MultiStateType.HarveyBallTwoState:
          case Enums.MultiStateType.HarveyBallTriState:
            this.value = '0%';
            break;
          case Enums.MultiStateType.RAG:
          case Enums.MultiStateType.RAGBB:
            this.value = 'Unset';
            break;
        }
      } else if (this.fieldType === Enums.FieldType.ImageList) {
        this.value = this.value;
      } else {
        this.value = null;
      }
    }

    this.field$.next(this.field);
    this.setDisabled();
  }

  public filterFields(field: string): any {
    return this.permittedFields.filter(appField => {

      if (appField.Label) {
        return appField.Label.toLowerCase().indexOf(field.toLowerCase()) >= 0;
      }

      if (appField.Identifier) {
        return appField.Identifier.toLowerCase().indexOf(field.toLowerCase()) >= 0;
      }

      return false;
    });
  }

  public searchFields(val) {
    this.sub.next(val.target.value);
    if (!val) {
      this.fieldChanged(<MatAutocompleteSelectedEvent>{ option: { value: '' } });
    }
  }

  public isFilterable(field: AppField) {
    switch (field.Type) {
      case Enums.FieldType.Bit:
        return false;
      default:
        return field.isFilterable();
    }
  }

  public valueChanged($event) {
    this.value = $event;
    this.setDisabled();
  }

  public operationChanged($event) {
    this.setDisabled();
  }

  private setDisabled() {
    if (this.selectedOperation === this.noOperation) {
      this.disable$.next(false);
    } else {
      const disabled = !this.isBinary && !isDefined(this.value);
      this.disable$.next(disabled);
    }
  }
}
