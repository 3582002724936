import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Enums, Homepage, Dashboard } from '@softools/softools-core';
import { DashboardModel, HomeModel } from 'app/mvc';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageComponent {
  public homepageData: Homepage;
  public homepageType: Enums.HomepageType;
  public Enums = Enums as typeof Enums;

  @Input() set homepage(value) {
    this.homepageData = value;
    this._setHomepageType();
  }

  @Input() homeModel: HomeModel;

  @Input() dashboardModel: DashboardModel;

  @Input() dashboard: Dashboard;

  constructor() { }

  private _setHomepageType(): void {
    if (!this.homepageData || !this.homepageData.Identifier) {
      return;
    }

    if (this.homepageData.IsCustom) {
      this.homepageType = Enums.HomepageType.custom;
    } else if (
      this.homepageData.AssetImageUri &&
      this.homepageData.AssetImageUri.length > 0
    ) {
      this.homepageType = Enums.HomepageType.image;
    } else if (this.homepageData.Dashboard) {
      this.homepageType = Enums.HomepageType.dashboard;
    } else {
      this.homepageType = Enums.HomepageType.custom;
    }
  }

}
