<div class="mb-4">
  <div *ngFor="let row of rows; let rowIndex = index" class="container-fluid pt-4">
    <div style="display: grid; grid-gap: 0.8rem;" appSetGridTemplateColumns [maxColAnyRow]="row.CellCount"
      trackSizing="minmax(250px, 1fr)">
      <ng-container *ngFor="let cell of row.DashboardCells; let colIndex = index">
        <ng-container *ngIf="cell.CellType === 5; else nonempty">
          <div>&nbsp;</div>
        </ng-container>
        <ng-template #nonempty>
          <div style="--aspect-ratio: 1/1;" appSetGridColumnStart [currentCol]="colIndex + 1" mode="col"
            [class]="!cell.TransparentBackground ? 'cell-border' : ''">
            <app-dashboardreportcell [appModel]="getCellAppModel(cell)" [cell]="cell" [appIdentifiers]="appIdentifiers"
              [parentAppId]="parentAppId" [parentRecordId]="parentRecordId"></app-dashboardreportcell>
          </div>
        </ng-template>
        </ng-container>
    </div>
  </div>
</div>
