import { Permission } from './permission.interface';
import { IStyleName } from './style-name.interface';

export enum StickyPosition {
  Top = 0,
  Bottom = 1
}

export interface TemplateReference {
  DisplayOrder: number;
  LastUpdate?: string;
  TemplateIdentifier: string;
  CollapsedByDefault: boolean;

  /** If true, template header is not shown.  No appstudio support yet */
  HideHeader?: boolean;

  /** If set, aligns the template at the top of the bottom of the form
   * independent of scroll position.  No appstudio support yet */
  StickyPosition?: StickyPosition;
}

export interface Form {
  CreatedByUserId: number;
  CreatedDate: string;
  DisplayOrder: number;
  FormTemplates: Array<TemplateReference>;


  /**
   * If set to true, form is hidden while creating a record.  It is only shown
   * when the record has been succesfully created on the server.
   */
  HiddenOnCreate: boolean;

  /**
   * If set to true, form is hidden while updating an existing record, so is only
   * available when creating a record.
   * This is not yet supported by app studio.
   */
  HiddenOnUpdate?: boolean;

  Identifier: string;
  ModifiedByUserId: number;
  ShowAppDescription: boolean;
  ShowAppIcon: boolean;
  ShowAppTitle: boolean;
  SystemTitle: string;
  Title: string;

  /** If true, the templates on the form retain their collapsed state when paging over records.
   * The state is always reset at a major change e.g. reloading a page.
   * N.B. Currently we have no UI to set this to true so templates will always reset.
   */
  RetainTemplateCollapsedState?: boolean;

  /** Optional list of styles to apply to the form  */
  NamedStyles?: Array<IStyleName>;

  Rules: Array<FormRule>;

  /**
   * If set, the form will be displayed horizontally centerd.  The value is a CSS
   * width string (e.g. "20rem", "90%" or "auto").
   * This is not yet supported by app studio.
   */
  CentredFormWidth?: string;

  /**
   * If set true, the form is read only.  It can be used to display details of the
   * record but not to modify it.  All fields are set read only, patch and post are
   * disabled and validation messages are not shown.
   * This is not yet supported by app studio.
   */
  ReadOnly?: boolean;

  /**
   * This will contain the list of permission, The user who has these permission
   * then only he can access this form.
   */
  Permissions?: Array<Permission>;
}

export interface FormRule {

  /** If specified the rule ony applies to new/existing records according to value.
   * If no other rule parameters are specified the rule just checks new/existing status.
   */
  // no appstudio support yet
  RecordIsNew?: boolean;

  FilterRule?: string;
  HiddenForTeams?: Array<string>;
  TargetTemplateIdentifier?: string;
  TargetFieldIdentifier?: string;
}
