
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

export const leftAsideStateAnimation = trigger('leftAsideState', [
    state('active', style({
        width: '20rem'
    })),
    state('inactive', style({
        width: '0'
    })),
    transition('active => inactive', animate('270ms ease-out')),
    transition('inactive => active', animate('250ms ease-in'))
]);

export const contentPinnedStateAnimation = trigger('contentPinnedState', [
    state('active', style({
        left: '15rem'
    })),
    state('inactive', style({
        left: '0'
    })),
    transition('active => inactive', animate('270ms ease-out')),
    transition('inactive => active', animate('250ms ease-in'))
]);

export const rightbarToggleContentStateAnimation = trigger('rightbarToggleContentState', [
    state('inactive', style({
        right: '0rem'
    })),
    state('active', style({
        right: '24rem'
    })),
    transition('inactive => active', animate('270ms ease-in')),
    transition('active => inactive', animate('250ms ease-out'))
]);

export const rightbarToggleStateAnimation = trigger('rightbarToggleState', [
    state('inactive', style({
        right: '-24rem'
    })),
    state('active', style({
        right: '0rem'
    })),
    transition('inactive => active', animate('270ms ease-in')),
    transition('active => inactive', animate('250ms ease-out'))
]);

export const popupStateAnimation = trigger('popupState', [
    state('inactive', style({
        transform: 'scale3d(0, 0, 0)',
        opacity: 0
    })),
    state('active', style({
        transform: 'scale3d(1, 1, 1)',
        opacity: 1
    })),
    transition('inactive => active', animate('250ms ease-in')),
    transition('active => inactive', animate('150ms ease-out'))
]);

export const compactedSubfolderStateAnimation = trigger('compactedSubfolderState', [
    state('active', style({
        height: '*', opacity: 1
    })),
    state('inactive', style({
        height: '0', opacity: 0
    })),
    transition('active => inactive', animate('270ms ease-out')),
    transition('inactive => active', animate('250ms ease-in'))
]);
export const homepageHelpStateAnimation = trigger('homepageHelpState', [

    state('active', style({
        width: '30rem',
    })),
    state('inactive', style({
        width: '0',
    })),
    transition('active => inactive', animate('250ms ease-out')),
    transition('inactive => active', animate('250ms ease-in'))
]);


export const mobileHomepageHelpStateAnimation = trigger('mobileHomepageHelpState', [

    state('active', style({
        height: '*',
    })),
    state('inactive', style({
        height: '0',
    })),
    transition('active => inactive', animate('250ms ease-out')),
    transition('inactive => active', animate('250ms ease-in'))
]);

export const flyInflyOut = trigger('flyInOut', [
    state('in', style({ transform: 'translateX(0)' })),
    state('out', style({ transform: 'translateX(-100%)' })),
    transition('in => out', animate('270ms ease-in')),
    transition('out => in', animate('250ms ease-out'))
]);

export const flyInflyOutRight = trigger('flyInflyOutRight', [
    state('in', style({ transform: 'translateX(-100%)' })),
    state('out', style({ transform: 'translateX(0)' })),
    transition('in => out', animate('270ms ease-in')),
    transition('out => in', animate('250ms ease-out'))
]);

export const backButtonAnimation = trigger('backButtonState', [
  state('in', style({ transform: 'translateY(0)', opacity: 1 })),
  state('out', style({ transform: 'translateY(-3.2rem)', opacity: 0 })),
  transition('in => out', animate('270ms ease-in')),
  transition('out => in', animate('250ms ease-out'))
]);

export const jiggleAnimation = trigger('jiggle', [
  state('on', style({ transform: 'translate3d(0, 0, 0)' })),
  transition('* => on', [
    animate('1s', keyframes([
      style({ transform: 'translate3d(5px, 0, 0) rotate(-20deg)' }),
      style({ transform: 'translate3d(-5px, 0, 0) rotate(20deg)' }),
      style({ transform: 'translate3d(5px, 0, 0) rotate(-15deg)' }),
      style({ transform: 'translate3d(-5px, 0, 0) rotate(15deg)' }),
      style({ transform: 'translate3d(5px, 0, 0) rotate(-10deg)' }),
      style({ transform: 'translate3d(-5px, 0, 0) rotate(10deg)' }),
      style({ transform: 'translate3d(5px, 0, 0) rotate(-10deg)' }),
      style({ transform: 'translate3d(-5px, 0, 0) rotate(10deg)' }),
      style({ transform: 'translate3d(0, 0, 0)' })
    ]))
  ])
]);

export const rotateCardanimation = trigger('rotateCard', [
  state('on', style({ transform: 'translate3d(0, 0, 0)' })),
  transition('* => presto', [
    animate('180ms', keyframes([
      style({ transform: 'perspective(500px) rotateY(90deg)' })
    ]))
  ]),
  transition('* => chango', [
    animate('180ms', keyframes([
      style({ transform: 'perspective(500px) rotateY(-90deg)' }),
      style({ transform: 'perspective(500px) rotateY(0)' })
    ]))
  ])

]);
