import { Injectable } from '@angular/core';
import { App, Enums, Field } from '@softools/softools-core';
import { IApplication } from 'app/types/app-field-source';
import { AggregateAppField } from 'app/types/fields/aggregate-app-field';
import { AppField } from 'app/types/fields/app-field';
import { BackingField, PersonTextBackingField } from 'app/types/fields/backing-field';
import { BooleanAppField } from 'app/types/fields/boolean-app-field';
import { ButtonAppField } from 'app/types/fields/button-app-field';
import { DocumentAppField } from 'app/types/fields/document-app-field';
import { EmailAppField } from 'app/types/fields/email-app-field';
import { GridAppField } from 'app/types/fields/grid-app-field';
import { GridCellField } from 'app/types/fields/grid-cell-field';
import { ImageAppField } from 'app/types/fields/image-app-field';
import { ImageListAppField } from 'app/types/fields/image-list-app-field';
import { ListAppField } from 'app/types/fields/list-app-field';
import { ListItemAppField } from 'app/types/fields/list-item-app-field';
import { LiteralAppField } from 'app/types/fields/literal-app.fields';
import { MoneyAppField } from 'app/types/fields/money-app-field';
import { MultiStateAppField } from 'app/types/fields/multistate-app-field';
import { IntegerAppField, NumericAppField } from 'app/types/fields/numeric-app-field';
import { PersonAppField } from 'app/types/fields/person-app-field';
import { BaseSelectionAppField, MultiSelectionAppField, SelectionAppField } from 'app/types/fields/selection-app-field';
import { SelectionGridCellField } from 'app/types/fields/selection-grid-cell-field';
import { SelectionListItemField } from 'app/types/fields/selection-list-item-field';
import { TeamAppField } from 'app/types/fields/team-app-field';
import { DateAppField, DateTimeAppField, PeriodAppField } from 'app/types/fields/temporal-app-field';
import { NotesAppField, TextualAppField } from 'app/types/fields/textual-app-field';
import { TimeAppField } from 'app/types/fields/time-app-field';
import { UrlAppField } from 'app/types/fields/url-app-field';
import { IAppFieldFactoryService } from './app-field-factory.interface';

@Injectable({ providedIn: 'root' })
export class AppFieldFactoryService implements IAppFieldFactoryService {

  constructor() { }

  public createField(field: Field, app: App & IApplication): AppField<any> {
    let appField: AppField;
    switch (field.Type) {
      case Enums.FieldType.Integer:
      case Enums.FieldType.Range:
        appField = new IntegerAppField(field, app);
        break;
      case Enums.FieldType.Long:
      case Enums.FieldType.Number:
      case Enums.FieldType.AttachmentsCount:
      case Enums.FieldType.CommentsCount:
        appField = new NumericAppField(field, app);
        break;
      case Enums.FieldType.Money:
        appField = new MoneyAppField(field, app);
        break;
      case Enums.FieldType.Bit:
        appField = new BooleanAppField(field, app);
        break;
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Barcode: // stored as code string so will sort although a bit meaningless
        appField = new TextualAppField(field, app);
        break;
      case Enums.FieldType.ImageList:
        appField = new ImageListAppField(field, app);
        break;
      case Enums.FieldType.Notes:
        appField = new NotesAppField(field, app);
        break;
      case Enums.FieldType.Image:
        appField = new ImageAppField(field, app);
        break;
      case Enums.FieldType.Literal:
        appField = new LiteralAppField(field, app);
        break;
      case Enums.FieldType.UrlField:
        appField = new UrlAppField(field, app);
        break;
      case Enums.FieldType.Email:
        appField = new EmailAppField(field, app);
        break;
      case Enums.FieldType.GridField:
        appField = new GridAppField(field, app);
        break;
      case Enums.FieldType.ListField:
        appField = new ListAppField(field, app);
        break;
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
        appField = new PersonAppField(field, app);
        break;
      case Enums.FieldType.Team:
        appField = new TeamAppField(field, app);
        break;
      case Enums.FieldType.Selection: {
        switch (field.SelectListType) {
          case Enums.SelectListType.Checkbox:
          case Enums.SelectListType.Listbox:
            appField = new MultiSelectionAppField(field, app);
            break;
          default:
            appField = new SelectionAppField(field, app);
            break;
        }
        break;
      }
      case Enums.FieldType.Date:
        appField = new DateAppField(field, app);
        break;
      case Enums.FieldType.DateTime:
        appField = new DateTimeAppField(field, app);
        break;
      case Enums.FieldType.Period:
        appField = new PeriodAppField(field, app);
        break;
      case Enums.FieldType.Time:
        appField = new TimeAppField(field, app);
        break;
      case Enums.FieldType.MultiState:
        appField = new MultiStateAppField(field, app);
        break;
      case Enums.FieldType.Document:
        appField = new DocumentAppField(field, app);
        break;
      case Enums.FieldType.Aggregate:
        appField = new AggregateAppField(field, app);
        break;
      case Enums.FieldType.ImageActionButton:
        appField = new ButtonAppField(field, app);
        break;
      default:
        appField = new AppField(field, app);
        break;
    }

    return appField;
  }

  public createGridCell(field?: Field, containedField?: AppField, app?: App & IApplication): AppField {
    switch (field.Type) {
      case Enums.FieldType.Selection:
        if (containedField instanceof BaseSelectionAppField) {
          return new SelectionGridCellField(field, containedField, app);
        }
        break;

      default:
        return new GridCellField(field, containedField, app);
    }

    // Shouldn't get here, so throw
    throw new Error(`Invalid grid cell ${field.Identifier}`);
  }

  public createListItem(field?: Field, containedField?: AppField, app?: App & IApplication): AppField {

    switch (field.Type) {
      case Enums.FieldType.Selection:
        if (containedField instanceof BaseSelectionAppField) {
          return new SelectionListItemField(field, containedField, app);
        }
        break;

      default:
        return new ListItemAppField(field, containedField, app);
    }

    // Shouldn't get here, so throw
    throw new Error(`Invalid grid cell ${field.Identifier}`);
  }

  public createBackingField(field?: Field, containedField?: AppField, app?: App & IApplication): BackingField {
    const [parentId, suffix] = field.Identifier.split('_');
    const parent = app.Fields.find(f => f.Identifier === parentId);

    // Use concrete type if available
    switch (suffix) {
      case 'Text':
        switch (parent?.Type) {
          case Enums.FieldType.Person:
          case Enums.FieldType.PersonByTeam:
            return new PersonTextBackingField(field, containedField, app);
          default:
            break;
        }
        break;
      default:
        break;
    }

    // Use generic backing field
    return new BackingField(field, containedField, app);
  }
}
