<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <div class="cardv2-header">
      <div i18n>Export</div>
    </div>

    <div class="cardv2-body">
      <div *ngIf="!config.exportTypes || config.exportTypes.length === 0" class="filters-container">
        <p i18n>Sorry, there are no export options configured. Please contact your system administrator</p>
      </div>

      <form *ngIf="config.exportTypes && config.exportTypes.length > 0">
        <mat-form-field class="w-100">
          <input matInput i18n-placeholder placeholder="Filename (optional)" name="filename" [(ngModel)]="exportData.filename" />
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-select
            i18n-placeholder
            placeholder="Export Type"
            [(value)]="selectedExportTypeValue"
            (selectionChange)="onExportTypeChangeHandler($event)"
          >
            <mat-option *ngIf="showExportOption(Enums.ExportType.csv)" [value]="Enums.ExportType.csv" i18n> File (CSV) </mat-option>
            <mat-option
              *ngIf="showExportOption(Enums.ExportType.templatedReportExport)"
              [value]="Enums.ExportType.templatedReportExport"
              i18n
            >
              Templated Report (Word Document)</mat-option
            >
            <mat-option
              *ngIf="showExportOption(Enums.ExportType.templatedReportExportAsPdf)"
              [value]="Enums.ExportType.templatedReportExportAsPdf"
              i18n
            >
              Templated Report (Word Document as PDF)
            </mat-option>
            <mat-option
              *ngIf="showExportOption(Enums.ExportType.templatedReportExportCombinedRecord)"
              [value]="Enums.ExportType.templatedReportExportCombinedRecord"
              i18n
            >
              Templated Report (Word Document Combined Record)</mat-option
            >
            <mat-option
              *ngIf="showExportOption(Enums.ExportType.templatedReportExportAsPdfCombinedRecord)"
              [value]="Enums.ExportType.templatedReportExportAsPdfCombinedRecord"
              i18n
            >
              Templated Report (Word Document as PDF Combined Record)
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <mat-checkbox
            *ngIf="config.reportOnlyFieldsDefault && exportData.selectedExportType === 'csv'"
            (change)="onReportFieldsOnlyChangeHandler($event)"
            [checked]="this.exportData.reportFieldsOnly"
            i18n
          >
            Report Fields Only
          </mat-checkbox>
        </div>

        <div class="my-2">
          <mat-checkbox
            *ngIf="exportData.selectedExportType === 'csv'"
            (change)="onExcludeReadOnlyFieldsChangeHandler($event)"
            [checked]="this.exportData.excludeReadOnlyFields"
            i18n
          >
            Exclude Read Only Fields
          </mat-checkbox>
        </div>
      </form>

      <form
        *ngIf="
          config.templatedReports &&
          config.templatedReports.length > 0 &&
          selectedExportTypeValue &&
          (selectedExportTypeValue === Enums.ExportType.templatedReportExport ||
            selectedExportTypeValue === Enums.ExportType.templatedReportExportAsPdf ||
            selectedExportTypeValue === Enums.ExportType.templatedReportExportCombinedRecord ||
            selectedExportTypeValue === Enums.ExportType.templatedReportExportAsPdfCombinedRecord)
        "
      >
        <mat-form-field
          class="w-100"
          *ngIf="
            selectedExportTypeValue === Enums.ExportType.templatedReportExport ||
            selectedExportTypeValue === Enums.ExportType.templatedReportExportAsPdf
          "
        >
          <mat-select
            i18n-placeholder
            placeholder="Templated Report"
            [(value)]="selectedTemplatedReportId"
            (selectionChange)="onTemplatedReportIdChangeHandler($event.value)"
          >
            <mat-option *ngFor="let templatedReport of config.templatedReports" [value]="templatedReport.Id">
              {{ templatedReport.Title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="w-100"
          *ngIf="
            selectedExportTypeValue === Enums.ExportType.templatedReportExportCombinedRecord ||
            selectedExportTypeValue === Enums.ExportType.templatedReportExportAsPdfCombinedRecord
          "
        >
          <mat-select
            i18n-placeholder
            placeholder="Templated Report"
            [(value)]="selectedTemplatedReportId"
            (selectionChange)="onTemplatedReportIdChangeHandler($event.value)"
          >
            <mat-option *ngFor="let templatedReport of config.templatedReportsForAppCombinedRecord" [value]="templatedReport.Id">
              {{ templatedReport.Title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="cardv2-footer">
      <button type="button" class="primary" [mat-dialog-close]="returnValue" i18n>Confirm</button>
      <button type="button" class="close" [mat-dialog-close]="null" i18n>Close</button>
    </div>
  </div>
</div>
