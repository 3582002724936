
import { NgModule } from '@angular/core';

import { SafePipe } from 'app/pipes';

@NgModule({
  imports: [],
  declarations: [SafePipe],
  exports: [SafePipe],
  providers: []
})
export class PipeModule { }
