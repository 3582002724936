import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppZone } from 'app/types/enums';
import { REPORT_IDENTIFIER, PARENT_APP_IDENTIFIER, PARENT_RECORD_ID, RECORD_ID } from 'app/_constants';

@Injectable({ providedIn: 'root' })
export class ZoneService {
  constructor(private router: Router) {}

  public getCurrentZone(paramsSnapshot?: {}, data?: any): AppZone {
    // If zone specified in route data, use that in prefrerence
    if (data && data.zone) {
      return data.zone;
    }

    const url = this.router.url;
    const addMode = url.startsWith('/App/Add/');
    const editMode = url.startsWith('/App/Update/');

    let reportIdentifier: string = null;
    let parentAppIdentifier: string = null;
    let parentRecordId: string = null;
    let hasHierarchy = null;
    let recordId = null;

    if (paramsSnapshot) {
      reportIdentifier = paramsSnapshot[REPORT_IDENTIFIER];
      parentAppIdentifier = paramsSnapshot[PARENT_APP_IDENTIFIER];
      parentRecordId = paramsSnapshot[PARENT_RECORD_ID];
      hasHierarchy = parentAppIdentifier && parentRecordId;
      recordId = paramsSnapshot[RECORD_ID];
    }

    if (url.startsWith('/login') || url.startsWith('/Account/Login') || url.startsWith('/Account/Callback')) {
      return AppZone.login;
    }

    if (url.startsWith('/Sync')) {
      return AppZone.sync;
    }

    if (url.startsWith('/Signup')) {
      return AppZone.licensing;
    }

    if (url.startsWith('/Homepage/')) {
      return AppZone.home;
    } else if (url.startsWith('/App/Main/')) {
      if (!reportIdentifier) {
        return AppZone.apphome;
      }

      if (hasHierarchy) {
        return AppZone.childapp;
      }

      return AppZone.app;
    } else if (addMode || editMode) {
      if (!hasHierarchy) {
        return addMode ? AppZone.recordadd : AppZone.recordupdate;
      }

      if (addMode) {
        return AppZone.childrecordadd;
      }

      // Find child list report.  Not sure, don't like
      if (parentAppIdentifier && parentRecordId && reportIdentifier && !recordId) {
        return AppZone.childapp;
      }

      if (parentAppIdentifier && parentRecordId) {
        return AppZone.childrecordupdate;
      }

      return AppZone.childrecordupdate;
    }
    return AppZone.home;
  }

  public isInRecordTypeZone(): boolean {
    const currentZone: AppZone = this.getCurrentZone();
    return this.isRecordTypeZone(currentZone);
  }

  public isRecordTypeZone(currentZone: AppZone): boolean {
    const isRecordTypeZone =
      currentZone === AppZone.recordadd ||
      currentZone === AppZone.recordupdate ||
      currentZone === AppZone.childrecordadd ||
      currentZone === AppZone.childrecordupdate;
    return isRecordTypeZone;
  }
}
