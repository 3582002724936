
import { Injectable } from '@angular/core';

import { RepositoryBase } from './repositories.base';
import { firstValueFrom, Observable } from 'rxjs';
import { NotificationMessage, QueryParams } from '../types';

@Injectable({ providedIn: 'root' })
export class NotificationsRepository extends RepositoryBase {

  public getBackgroundTasks(queryParams?: QueryParams): Observable<Array<NotificationMessage>> {
    let url = this.urlResolver.resolveDefaultApiUrl('Notifications', 'BackgroundTasks');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }

    return this.get(url);
  }

  public getBackgroundTaskCountAsync(queryParams?: QueryParams): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('Notifications', 'BackgroundTasks/Count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }

    return firstValueFrom(this.get(url));
  }

  public remove(id: string): Observable<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('Notifications', `DeleteById/${id}`);
    return this.del(url);
  }

  public removeAll(): Observable<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('Notifications');
    return this.del(url);
  }

  public removeAsync(id: string): Promise<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('Notifications', `DeleteById/${id}`);
    return firstValueFrom(this.del(url));
  }

  public removeAllAsync(): Promise<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('Notifications');
    return firstValueFrom(this.del(url));
  }


}
