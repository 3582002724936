<section class="report-grid-container">

  <ng-container *ngIf="!(reportModel.loadError.$| async)">
    <div class="report-controls-area">
      <app-ws-sharedreportactions
        [app]="currentApp"
        [reportModel]="reportModel"
        [filterModel]="filterModel"
        [isLoading]="reportModel.busy.busy$|async"
        [isArchived]="globalModel.archived.$|async"
        [filter]="filterModel.reportFilter.$|async"
        [baseFilter]="(reportModel.report.$|async)?.BaseFilter"
        [showFilterManagement]="this"
        [isSearchable]="appSearchable"
        [isReportOption]="false"
        [searchText]="reportModel.searchTerm.$|async"
        [subtitle]="(reportModel.report.$|async)?.SubTitle"
        (onArchivedRecordsBadgeClickHandler)="onArchivedRecordsBadgeClickHandler()"
        (onSearchEnterClickHandler)="onSearchEnterClickHandler($event)"
        (onSearchEscClickHandler)="onSearchEscClickHandler()"
      ></app-ws-sharedreportactions>
    </div>

    <app-folders #leftAside
      class="left-aside-area"
      [folderModel]="pageModel.folderModel"
      [selectedFolderIdentifier]="reportModel.reportIdentifier.$|async"
      [title]="currentApp?.Title"
      [showAddNewRecord]="canCreate"
      [appIdentifier]="appIdentifier"
      [appIdentifiers]="appIdentifiers"
      [pinnedStateVal]="showAside$ | async">
    </app-folders>

    <div class="main-content-area  report-content">
      <article class="report-article" cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin">
        <ng-container *ngIf="(isOfflineReport$|async) || (onlineStatus.isServerReachable$|async); else reportoffline" >
          <div class="h-100">
            <app-matrixreport [@rightbarToggleContentState]="pageModel.sidebarState.$|async" *ngIf="!(reportModel.busy.busy$|async)"
              [isCumulative]="false"
              [matrixReportModel]="matrixReportModel"
              [model]="matrixReportModel.matrixModel.$|async"
              [appIdentifier]="appIdentifier"
              [appIdentifiers]="appIdentifiers"
              [isArchived]="globalModel.archived.$|async"
              [parentRecordId]="reportModel?.parentRecordId.$|async"
              [clickThroughIdentifier]="clickThroughId"
              [filter] = "filterModel.combinedFilter.$|async" >
            </app-matrixreport>
          </div>
          </ng-container>
      </article>
    </div>

    <div class="footer-area">
      <app-shared-footer zone=""
      [appModel]="appModel"
      [appIdentifier]="appIdentifier"
      [isOnline]="(onlineStatus.isServerReachable$|async)"
      [showFooter]="showFooter"
      ></app-shared-footer>
    </div>

    <div [@quickFilters]="quickFilterOpenState$|async" class="quick-filters">
      <app-quick-filter *ngIf="isQuickFilterVisible$|async" 
        [reportModel]="reportModel"
        [filterModel]="filterModel"
        [reportController]="reportController"
        (close)="quickFilterClosed()"
        (showSaved)="quickToFilterManagement()"
      ></app-quick-filter>
    </div>

    <div [@filterMgt]="filterManageOpenState$|async" class="saved-filters">
      <app-filter-management-panel *ngIf="isFilterManagementPanelVisible$|async" 
        [appModel]="appModel"
        [filterModel]="filterModel"
        [showFilterManagement]="this"
        [isOnline]="appModel.globalModel.online.$|async"
        [selectedFilter]="filterModel.reportFilter.$|async"
        [appIdentifier]="(appModel.app.$|async)?.Identifier"
        [appFields]="(appModel.app.$|async)?.AppFields  "
        [report]="reportModel.report.$|async"
        [reportFields]="(reportModel.report.$|async)?.ListReportFields"
        [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
      </app-filter-management-panel>
    </div>
    

  </ng-container>
</section>

<div class="new-rec-container" [class.quick-open]="isQuickFilterVisible$|async">
  <app-new-record-button [canCreate]="canCreate" [createUrl]="createUrl" [floater]="false"></app-new-record-button>
</div>

<ng-template #reportoffline>
  <app-report-offline></app-report-offline>
</ng-template>
