import { Injectable } from '@angular/core';
import { PendingBaseService } from 'app/workspace.module/services/pendingbase.service';
import { DatabaseContextService, PENDING_SAVE_IMAGES, FieldKey } from '@softools/softools-core';

export interface SaveImageDetails extends FieldKey {
  imageId: string;
  file?: File;
  blobUrl?: string;
}

export interface SaveCameraDetails extends FieldKey {
  imageId: string;
  data: ArrayBuffer;
  blob: Blob;
}

export interface DeleteImageDetails extends FieldKey {
  imageId: string;
}

@Injectable({ providedIn: 'root' })
export class PendingSaveImagesService extends PendingBaseService<SaveImageDetails> {
  constructor(dbContext: DatabaseContextService<SaveImageDetails>) {
    super(PENDING_SAVE_IMAGES, dbContext);
  }
}

// @Injectable({ providedIn: 'root' })
// export class PendingDeleteImagesService extends PendingBaseService<DeleteImageDetails> {
//   constructor(dbContext: DatabaseContextService<DeleteImageDetails>) {
//     super(PENDING_DELETE_IMAGES, dbContext);
//   }
// }
