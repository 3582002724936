<div cdkOverlayOrigin #actionbar="cdkOverlayOrigin"
  class="report-actions h-100">

  <div *ngIf="isMediaActive('lt-lg')" class="control-bar px-2 h-100" >
    <div class="editmode h-100 d-flex align-items-center">
      <ng-container *ngTemplateOutlet="inlineEditSwitch"></ng-container>
    </div>

      <div class="archived h-100 d-flex align-items-center" *ngIf="isArchived">
        <fa-icon
          [icon]="['fal', 'archive']" size="2x" class="report-action-icon"
          title="Archived items are being displayed.  Click to reset." i18n-title="@@ArchivedBadgeTitle"
          (click)="onArchivedRecordsBadgeClickHandler.emit()" >
          </fa-icon>
      </div>

    <div *ngIf="showSelected && selectedCount && !allRecordsSelected"
      class="selections h-100 d-flex align-items-center mx-2"  >
        <fa-icon [icon]="['fal', 'check-square']" size="2x" class="report-action-icon"
        (click)="onDeselectAllHandler.emit()"
        ></fa-icon><label class="ms-2">({{(selectedCount)}})</label>
    </div>

    <!-- all records indicator  -->
    <div *ngIf="showSelected && allRecordsSelected && selectedCount"
      class="selections h-100 d-flex align-items-center mx-2"  >
        <fa-icon [icon]="['fal', 'check-square']" size="2x" class="report-action-icon"
        (click)="onDeselectAllHandler.emit()"
        ></fa-icon>
        <label class="ms-1" *ngIf="selectedCount">({{selectedCount}})</label>
    </div>

    <div *ngIf="isSearchable" class="search h-100 d-flex align-items-center mx-2"  [style.width.rem]="2">
      <fa-layers>
        <fa-icon (click)="showSearchPopup = !showSearchPopup" [icon]="['fal', 'search']" size="2x"  class="report-action-icon pointer base-icon"></fa-icon>
        <fa-icon *ngIf="searchText" (click)="showSearchPopup = !showSearchPopup" [icon]="['fas', 'check-circle']" class="red-icon-search report-action-icon pointer"></fa-icon>
      </fa-layers>
    </div>

    <div *ngIf="isFilterable" class="filters h-100 d-flex align-items-center mx-2" [style.width.rem]="2">
      <ng-container *ngIf="(reportModel?.appModel.app.$|async)?.isFilterAdvanced;
        then quickfilter;
        else mobilefilter"
      ></ng-container>
    </div>

    <div *ngIf="isReportOption" class="layout h-100 d-flex align-items-center mx-2" [style.width.rem]="2">
      <ng-container *ngTemplateOutlet="layout"></ng-container>
    </div>

  </div>

  <div #sharedActionContainer *ngIf="!isMediaActive('lt-lg')" class="control-bar h-100 position-relative">

    <div class="editmode d-flex align-items-center h-100 ">
      <ng-container  *ngTemplateOutlet="inlineEditSwitch"></ng-container>
    </div>

    <div class="selections d-flex align-items-center">
      <mat-chip-list>
        <ng-container *ngIf="isArchived">
          <app-badge class="d-flex" [text]="app?.TextResources?.Archived || 'Archived'" icon="archive"
            [title]="app?.TextResources?.ArchivedBadgeTitle || 'Archived items are being displayed.  Click to reset.'"
            i18n-title="@@ArchivedBadgeTitle"
            (onBadgeClick)="onArchivedRecordsBadgeClickHandler.emit()" (onBadgeCloseClick)="onArchivedRecordsBadgeClickHandler.emit()" >
          </app-badge>
        </ng-container>

        <ng-container *ngIf="showSelected">
          <!-- Selected indicator-->
          <ng-container *ngIf="selectedCount && selectedCount > 0 && !allRecordsSelected" >
            <app-badge class="d-flex"
              [text]="selectedCount"
              icon="check-square" title="Selected items.  Click to clear selection." i18n-title="@@SelectedItemsBadgeTitle"
              (onBadgeClick)="onDeselectAllHandler.emit()" (onBadgeCloseClick)="onDeselectAllHandler.emit()" >
            </app-badge>
          </ng-container>

          <!-- All selected indicator-->
          <ng-container *ngIf="allRecordsSelected && selectedCount">
              <fa-icon [icon]="['fal', 'check-square']" size="2x" class="text-muted d-sm-block d-md-none me-1"></fa-icon>
              <app-badge class="d-none d-md-block"
              [text]="selectedCount" icon="check-square"
              title="Selected items.  Click to clear selection." i18n-title="@@SelectedItemsBadgeTitle" (onBadgeClick)="onDeselectAllHandler.emit()"
              (onBadgeCloseClick)="onDeselectAllHandler.emit()" >
            </app-badge>
          </ng-container>

        </ng-container>
      </mat-chip-list>
    </div>

    <h4 class="subtitle mx-2 text-start d-flex align-middle align-items-center">{{subtitle}}</h4>

    <div class="search d-flex flex-nowrap align-items-center justify-content-end" >
      <app-search *ngIf="isSearchable"
        class="m-2 d-none d-md-block"
        [searchText]="searchText"
        [isLoading]="isLoading"
        [expandOnFocus]="true"
        (onEnter)="onSearchEnterClickHandler.emit($event)"
        (onEscape)="onSearchEscClickHandler.emit()">
      </app-search>
    </div>

    <div *ngIf="isFilterable" class="filters h-100 d-flex align-items-center mx-2">
      <ng-container *ngIf="(reportModel?.appModel.app.$|async)?.isFilterAdvanced;
        then quickfilter;
        else filterbadge"
        ></ng-container>
    </div>

    <div *ngIf="isReportOption" class="layout h-100 d-flex align-items-center mx-2 theme-dark-text" [style.width.rem]="2">
      <ng-container *ngTemplateOutlet="layout"></ng-container>
    </div>
  </div>
</div>

<ng-template #layout>
  <fa-icon *ngIf="isAutoLayout"  [icon]="['fas', 'table-columns']" size="2x" class="pointer" (click)="onToggleFieldsPanel($event)" i18n-title title="Auto Layout on, click to disable " ></fa-icon>
  <fa-icon *ngIf="!isAutoLayout" [icon]="['fal', 'table-columns']" size="2x" class="pointer" (click)="onToggleFieldsPanel($event)" i18n-title title="Auto Layout off, click to enable" ></fa-icon>
</ng-template>

<ng-template #quickfilter>
  <div class="h-100 d-flex align-items-center" [style.width.rem]="3">
    <fa-layers>
      <fa-icon (click)="toggleQuickFilters($event)" [icon]="['fal', 'filter']" size="2x"  class="report-action-icon pointer base-icon"></fa-icon>
      <fa-icon *ngIf="filter?.isFiltered()" (click)="toggleQuickFilters($event)" [icon]="['fas', 'check-circle']" class="red-icon-filter report-action-icon pointer"></fa-icon>
    </fa-layers>
  </div>
</ng-template>

<ng-template #filterbadge>
  <mat-chip-list class="filters d-none d-md-flex">
    <ng-container *ngTemplateOutlet="baseFilterBadge"></ng-container>
      <ng-container *ngTemplateOutlet="namedFilterBadge"></ng-container>
      <ng-container *ngTemplateOutlet="noFilterBadge"></ng-container>
  </mat-chip-list>
</ng-template>

<ng-template #mobilefilter>
  <div class="h-100 d-flex align-items-center" [style.width.rem]="3">
    <fa-layers *ngIf="isFilterable">
      <fa-icon (click)="flterBadgeClicked($event)" [icon]="['fal', 'filter']" size="2x"  class="report-action-icon pointer base-icon"></fa-icon>
      <fa-icon *ngIf="filter && !filter.isNullFilter" (click)="flterBadgeClicked($event)" [icon]="['fas', 'check-circle']" class="red-icon-filter report-action-icon pointer"></fa-icon>
    </fa-layers>
  </div>
</ng-template>

<app-popup-container *ngIf="isSearchable"
  [connectedOrigin]="actionbar"
  [connectedPositions]="searchPopupuPosition"
  [largeDeviceMinWidth]="250"
  [isOpen]="showSearchPopup"
  (closed)="searchClosed()">
  <ng-template>
      <form>
        <mat-card style="width:24rem">
          <mat-card-content>
              <app-search
              class="m-2"
              [searchText]="searchText"
              [isLoading]="isLoading"
              (onEnter)="searchPopupEnter($event)"
              (onEscape)="searchPopupEscape()">
            </app-search>
          </mat-card-content>
        </mat-card>
      </form>
  </ng-template>
</app-popup-container>

<app-popup-container *ngIf="isMediaActive('lt-lg')"
  [connectedOrigin]="actionbar"
  [connectedPositions]="searchPopupuPosition"
  [largeDeviceMinWidth]="250"
  [isOpen]="showFilterSummary"
  (closed)="filterSummaryClosed()">
  <ng-template>
      <form>
        <mat-card style="width:24rem">
          <mat-card-content>
            <div class="d-flex justify-content-around w-100 mb-4">
              <ng-container *ngTemplateOutlet="baseFilterBadge"></ng-container>
            </div>
            <div class="d-flex justify-content-around w-100 mb-4">
              <ng-container *ngIf="filter">
                <ng-container *ngTemplateOutlet="namedFilterBadge"></ng-container>
                <ng-container *ngTemplateOutlet="noFilterBadge"></ng-container>
              </ng-container>
            </div>
          </mat-card-content>
        </mat-card>
      </form>
  </ng-template>
</app-popup-container>

<!-- Common elements-->
<ng-template #baseFilterBadge >
    <app-badge *ngIf="baseFilter && baseFilter.length > 0"
      class="ps-2"
      icon="filter"
      text="Base Filter" i18n-text
      [hideClose]="true"
      (onBadgeClick)="baseFilterBadgeClicked()"
      >
    </app-badge>
  </ng-template>

  <ng-template #namedFilterBadge >
    <ng-container *ngIf="filter">
      <app-badge *ngIf="!filter.isNullFilter && !filter.isCustom"
      class="ps-2"
      icon="filter"
      title="Current Filter.  Click to expand, change and  manage filters." i18n-title
      closeTitle="Click to clear filter" i18n-closeTitle
      [text]="filter.Description"
      (onBadgeClick)="flterBadgeClicked($event)"
      (onBadgeCloseClick)="clearFilterClicked($event)">
      </app-badge>
      <app-badge *ngIf="!filter.isNullFilter && filter.isCustom"
        class="ps-2"
        icon="filter"
        title="No Current Filter.  Click to expand, change and  manage filters." i18n-title
        closeTitle="Click to clear filter" i18n-closeTitle
        text="Custom filter" i18n-text
        (onBadgeClick)="flterBadgeClicked($event)"
        (onBadgeCloseClick)="clearFilterClicked($event)">
      </app-badge>
    </ng-container>
  </ng-template>

  <ng-template #noFilterBadge >
    <app-badge *ngIf="!filter || filter.isNullFilter"
      class="ps-2"
      icon="filter"
      title="Current Filter.  Click to expand, change and  manage filters." i18n-title
      closeTitle="Click to clear filter" i18n-closeTitle
      text="No filter" i18n-text
      (onBadgeClick)="flterBadgeClicked($event)"
      (onBadgeCloseClick)="clearFilterClicked($event)">
    </app-badge>
  </ng-template>

<ng-template #inlineEditSwitch >
  <app-checkbox *ngIf="showInlineEditSwitch"
    [disabled]=false
    [checked]='isEditMode'
    (onchecked)="onEditModeHandler.emit(true)"
    (onunchecked)="onEditModeHandler.emit(false)"
    ii8-text="@@InlineEdit" text="Inline Edit"
    style="height: auto;"
    [checkboxType]="Enums.CheckboxType.Switch" >
  </app-checkbox>
</ng-template>
