import { HeaderSummaryExpression, Record, logError, IndexedAppData } from '@softools/softools-core';
import { IGroupStarts, IGroupInfo } from 'app/services/indexes/app-data-index';
import { Application, IGetIndexOptions } from 'app/types/application';
import { ReportFilter } from 'app/filters/types';
import { RecordsReportModel } from '../records-report.model';
import { RecordDataAccessor } from './record-data-accessor';
import { RecordSelection } from 'app/types/record-selection';
import { FilterModel } from 'app/mvc';
import { RecordCacheService } from 'app/services/record/record-cache.service';
import { InjectService } from 'app/services/locator.service';

export class OnlineRecordDataAccssor extends RecordDataAccessor {

  @InjectService(RecordCacheService)
  private readonly recordCache: RecordCacheService;

  private indexPromise: Promise<IndexedAppData>;

  constructor(app: Application, reportModel: RecordsReportModel, filterModel: FilterModel) {
    super(reportModel, filterModel, app);
  }

  public async initialise(): Promise<void> {
    try {
      const selection = this.getSelection();
      this.updateCount(selection);    // completes async
    } catch (error) {
      logError(error, 'OnlineRecordsModel initialise');
      this.count$.next(null);
    }
  }

  public isArchive(): boolean {
    return false;
  }

  public async getRecords(first: number, count: number, group?: IGroupStarts): Promise<Array<Record>> {
    try {
      this.busy.setLoading();
      const selection = this.getSelection(first, count, group);
      const records = await this.app.getApiRecordRange(selection, { applyPatch: true });
      return records;
    } catch (error) {
      logError(error, 'OnlineRecordsModel getRecords');
      return [];
    } finally {
      this.busy.setLoading(false);
    }
  }

  public async getGroupCount(): Promise<number> {
    const combined = this.reportModel.filterModel.combinedFilter.value;
    if (combined) {
      const groupBy = this.filterModel.groupBy.value;
      if (this.app && groupBy) {
        const hierarchy = this.reportModel.hierarchy.value
        const filter = this.reportModel.filterModel.combinedFilter.value?.Filter;
        const groupCount = await this.app.getApiGroupCount(groupBy, hierarchy, filter);
        return groupCount?.count ?? 0;
      }
    }

    return 0;
  }

  /**
   * Get a range of group start information.
   * The returned collection will begin with the group at the offset indicated by first
   * and at least count groups will be returned unless the groups can't be calculated
   * (yet) in which case an empty collection is returned
   * 
   * @param first 
   * @param count 
   * @returns 
   */
  public async getGroupStartsAsync(first: number, count?: number): Promise<IGroupInfo> {

    const combined = this.filter();
    if (combined) {
      const groupBy = this.filterModel.groupBy.value;
      const desc = this.filterModel.groupDescending.value;

      if (this.app && groupBy) {
        const hierarchy = this.reportModel.hierarchy.value
        const filter = combined.Filter;
        const groupCount = await this.app.getApiGroupCount(groupBy, hierarchy, filter);
        if (!groupCount?.count) {
          return { starts: [], count: 0 };
        }

        const groupField = this.app.getField(groupBy);

        const options = { reverse: desc, includeNull: true }
        const finiteValues = this.reportModel.useFiniteGroups.value ? groupField?.finiteValues(options) : null;

        // Request all groups (Set to group count - API doesn't know how to do All) if
        // we have a small known set of values. This avoids problems with the 
        // API not returning select fields in display order which means it doesn't
        // return the groups we want and so some could appear as 0 records (SOF-12524)
        // Also ask for all if count not specified
        let from = first;
        if (finiteValues?.length > 0) {
          from = 0;
          count = groupCount?.count;
        } else if (count === undefined) {
          count = groupCount?.count;
        }

        const archived = this.reportModel.globalModel.archived.value;
        const groupInfo = await this.app.getApiGroupInformation(groupBy, desc, from, count, archived, hierarchy, filter);

        let pos = from;
        const starts: Array<IGroupStarts> = [];
        groupInfo.forEach((x, i) => {
          const start: IGroupStarts = {
            groupFieldId: groupBy,
            pos,
            count: x.count,
            id: x.first,
            value: x.value,
            // Should use display value but we don't have it.  See SOF-10898
            text: groupField ? groupField.formatDisplayValue(x.value) : x.value
          };

          starts.push(start);

          pos += x.count;
        });

        // Always get null groups - report will strip out if not needed
        if (finiteValues?.length > 0) {
          const finiteStarts = new Array<IGroupStarts>();
          finiteValues.forEach(value => {
            const start = starts.find(s => s.value === value);
            if (start) {
              finiteStarts.push(start);
            } else {
              finiteStarts.push({
                groupFieldId: groupBy,
                pos: -1,
                count: 0,
                id: null,
                value: value
              });
            }
          });

          const begin = first - from;
          const groupStarts = { starts: finiteStarts.slice(begin, count), count: groupCount.count };
          return groupStarts;
        }

        return { starts: starts, count: groupCount.count };
      }
    }

    return { starts: [], count: 0 };
  }

  public async summarise(): Promise<Array<HeaderSummaryExpression>> {
    // Not yet supported in online mode.  We'd need a summary API.
    return [];
  }

  public override resetIndex() {
    this.indexPromise = null;
    this.initialise().catch((error) => logError(error, 'resetIndex online recs'));
  }

  public async getCachedRecords(first: number, count: number) {
    if (this.app) {
      const index = await this.index();
      const ids = await index.getRecordIds(first, count);
      return ids.map(id => ({ id, record: this.recordCache.get(id) }));
    }

    return [];
  }

  protected getSelection(first?: number, count?: number, group?: IGroupStarts) {

    const filters: Array<ReportFilter> = [];

    const combined = this.filter();
    if (combined) {
      filters.push(combined);
    }

    const search = this.reportModel.searchFilter.value;
    if (search) {
      filters.push(search);
    }

    if (group) {
      const groupFilter = new ReportFilter();
      const groupField = this.app.getField(group?.groupFieldId);
      groupFilter.setSimpleFilter(groupField, group.value);
      filters.push(groupFilter);
    }

    const selection = new RecordSelection();
    Object.assign(selection, {
      start: first || 0,
      count: count || 25,
      report: this.reportModel.report.value,
      reportIdentifier: this.reportModel.reportIdentifier,
      showArchived: false,
      hierarchy: this.reportModel.hierarchy.value
    });

    if (filters.length > 0) {
      selection.filter = ReportFilter.merge(filters);
    }

    return selection;
  }

  private updateCount(selection: RecordSelection) {
    this.countBusy$.next(true);
    this.app.getApiViewRecordCount(selection)
      .then(count => {
        // Only update if we've not switched app
        if (!this.closed) {
          this.count$.next(count);
        }
        this.countBusy$.next(false);
      })
      .catch(err => logError(err, 'updateCount'));
  }

  /** Get active index.
  * Promise ensures multiple calls get the same index; call resetIndex when a new one is required e.g. a new filter
  */
  private index(): Promise<IndexedAppData> {
    if (!this.indexPromise) {
      this.indexPromise = new Promise<IndexedAppData>((resolve, reject) => {
        this.countBusy$.next(true);

        const options: IGetIndexOptions = {
          filter: this.filterModel.combinedFilter.value,
          searchTerm: this.reportModel.searchTerm.value,
          hierarchy: this.reportModel.hierarchy.value,
          archived: this.reportModel.globalModel.archived.value
        };

        this.app.getIndex(this.reportModel.report.value.Identifier, options)
          .then(async index => {
            await index.indexAll();
            if (!this.closed) {
              this.count$.next(index.length);
            }
            this.countBusy$.next(false);
            return index;
          })
          .then(index => {
            resolve(index);
          })
          .catch(e => {
            this.countBusy$.next(false);
            reject(e);
          });
      });
    }

    return this.indexPromise;

    // todo do we need equivalent to this?
    // return this.indexPromise.then(index => {
    //   // Update index to reflect any changed records
    //   return index.reindexChangedRecords();
    // });
  }


}

export class ArchivedRecordDataAccessor extends OnlineRecordDataAccssor {
  protected override getSelection(first?: number, count?: number, group?: IGroupStarts) {
    const selection = super.getSelection(first, count, group);
    selection.showArchived = true;
    return selection;
  }

  public override isArchive(): boolean {
    return true;
  }

}
