import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { flyInflyOutRight, jiggleAnimation } from 'app/_constants';
import { logError, NotificationMessage } from '@softools/softools-core';
import { debounceTime, delay, take, tap } from 'rxjs/operators';
import { NotificationsModel } from 'app/mvc/common/notifications.model';
import { ComponentBase } from 'app/softoolsui.module';
import { BehaviorSubject } from 'rxjs';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { NotificationsController } from 'app/mvc/common/notifications.controller';
import { GlobalModel } from 'app/mvc';

@Component({
  selector: 'app-bell',
  templateUrl: './bell.component.html',
  styleUrls: ['./bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInflyOutRight, jiggleAnimation],
})
export class BellComponent extends ComponentBase implements OnInit {
  public noticeTab = true;
  public workflowTab = false;

  public slideIn = false;
  public showNav = false;
  public showNotifications = false;
  public jiggling$ = new BehaviorSubject('off');

  @Input() public globalModel: GlobalModel;
  @Input() public notificationsModel: NotificationsModel;
  @Input() isOnline = false;

  @Output() onClearNotificationClick = new EventEmitter();
  @Output() onClearAllNotificationClick = new EventEmitter();
  @Output() onDownloadClick = new EventEmitter();

  private notificationsController: NotificationsController;

  constructor(
    private changeDetectorRef: ChangeDetectorRef, models: GlobalModelService) {
    super();
    this.notificationsController = models.notificationsController;
  }

  public ngOnInit() {
    this.subscribe(this.notificationsModel.notified.$.pipe(
      debounceTime(100),
      tap(() => {
        this.jiggling$.next('on');
      }),
      delay(2000),
      tap(() => {
        this.jiggling$.next('off');
      }),
    ), () => {
    });
  }

  public open() {
    this.showNotifications = true;

    // Load notification in background
    this.notificationsController.loadNotifications().catch(e => logError(e, 'loadNotifications'));

    // slide in once the overlay is rendered
    this.slideIn = this.showNotifications;
  }

  public close(deleteAllNotifications: boolean) {
    this.slideIn = false;

    // slide in once the overlay is rendered
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
      this.showNotifications = false;
      if (deleteAllNotifications) {
        this.onClearAllNotificationClick.emit();
      }
    }, 250);
  }

  public isCompleted(notification: NotificationMessage) {
    return notification.Status > 1;
  }

  public getPercentage(notification: NotificationMessage) {
    return ((notification.Current - 0) * 100) / (notification.Maximum - 0);
  }

  public more() {
    this.close(false);
    this.globalModel.navigation.navigateApp({ appIdentifier: 'Softools.Notifications' });
  }
}
