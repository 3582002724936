<div id="headerPanel" class="bg-light-st p-2 mb-2 d-flex flex-nowrap justify-content-between pointer"
        style="max-width: 100vw;"
        (click)="onToggleFormTemplateClickHandler($event, identifier)">
  <div class="d-flex flex-nowrap text-truncate">
      <span *ngIf="iconBase64" style="width: 2rem; min-width: 2rem;" class="me-2">
        <app-icon [iconBase64]="iconBase64" size="2rem"></app-icon>
      </span>
      <h4 class="mt-auto mb-auto me-2 text-truncate theme-dark-text">{{title}}</h4>
  </div>
  <div class="flex-nowrap side-icons theme-dark-text">
     <fa-icon id="headerTemplateChevDown" *ngIf="!expanded" class="side-icon"
        [icon]="['fal', 'chevron-down']" size="2x"></fa-icon>

      <fa-icon id="headerTemplateChevUp" *ngIf="expanded" class="side-icon"
        [icon]="['fal', 'chevron-up']" size="2x"></fa-icon>

      <fa-icon id="headerTemplateInfoCircle" *ngIf="helpText && helpText.length > 0" class="ps-2 side-icon me-2"
        [icon]="['fal', 'info-circle']" size="2x"
        (click)="onToggleFormTemplateHelpClickHandler($event, identifier)"></fa-icon>
  </div>
</div>
<div id="helpTextPanel" class="d-flex p-2 mb-2 bg-light-st"
  *ngIf="expandedHelp && helpText"
  >
  <pre [innerHtml]="helpText"></pre>
</div>
