import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateComponent } from './template.component';
import { CssGridModule } from 'app/cssgrid.module/cssgrid.module';
import { Fields2Module } from 'app/softoolsui.module/fields2/fields2.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormTemplateComponent } from 'app/softoolsui.module/form.component/form-template/form-template.component';
import { FormTemplateHeaderComponent } from 'app/softoolsui.module/form.component/form-template-header/form-template-header.component';
import { AssetImagesModule } from 'app/softoolsui.module/asset-image/asset-images.module';
import { CustomNotSupportedModule } from 'app/softoolsui.module/customnotsupported.component/customnotsupported.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ListFieldModule } from 'app/softoolsui.module/fields2/list-field/list-field.module';
import { IconModule } from 'app/softoolsui.module/icon/icon.module';
import { TemplateFieldComponent } from './template-field/template-field.component';

@NgModule({
  declarations: [
    TemplateComponent,
    FormTemplateHeaderComponent,
    FormTemplateComponent,
    TemplateFieldComponent
  ],
  imports: [
    CommonModule,
    // FormModule,
    CssGridModule,
    Fields2Module,
    ListFieldModule,
    FontAwesomeModule,
    MatTooltipModule,
    OverlayModule,
    AssetImagesModule,
    CustomNotSupportedModule,
    IconModule
  ],
  exports: [
    TemplateComponent,
    FormTemplateHeaderComponent,
    FormTemplateComponent
  ]
})
export class TemplateModule { }
