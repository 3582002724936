import { getCurrentUser, SiteSettings } from '@softools/softools-core';
import { SiteSyncService } from 'app/effects/sitesync.effect';
import { AppField } from 'app/types/fields/app-field';
import { environment } from 'environments/environment';
import { ISiteController } from './site.controller.interface';
import { SiteModel } from './site.model';

export class SiteController implements ISiteController {

  private readonly siteSyncService: SiteSyncService;

  public constructor(public model: SiteModel) {
    this.siteSyncService = new SiteSyncService(this);
  }

  public initialise() {
    this.siteSyncService.start();
  }

  /**
   *  Open app studio in a new tab/window
   * @param appIdentifier
   * @param target
   */
  public goToAppStudio(appIdentifier?: string, target?: { field?: AppField, templateIdentifier?: string }): void {
    const user = getCurrentUser();
    const baseAppStudioUrl = `https://${environment.appStudioUrlPrefix}${user.Tenant}.as.softools.net/AppStudio`;
    if (!appIdentifier) {
      window.open(baseAppStudioUrl, '_blank');
    } else {
      const identifier = target?.templateIdentifier || target?.field.Identifier;
      window.open(`${baseAppStudioUrl}/App/Main/${appIdentifier}/${target?.templateIdentifier ? 'Templates' : 'Fields'}?search=${identifier}`, '_blank');
    }
  }

  public setSiteSettings(settings: SiteSettings) {
    this.model.settings.value = settings;
  }
}
