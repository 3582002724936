import { Component, ComponentRef, Input } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { FieldBase } from 'app/softoolsui.module/fields';
import { FieldComponent } from 'app/softoolsui.module/fields2/field/field.component';
import { TextDisplayFieldComponent } from 'app/softoolsui.module/fields2/text-display-field/text-display-field.component';

@Component({
  selector: 'sof-matrix-cell-field',
  templateUrl: '../../fields2/field/field.component.html',
  styleUrls: [
    './matrix-cell-field.component.scss',
    '../../fields2/field/field.component.scss'
  ]
})
export class MatrixCellFieldComponent extends FieldComponent {
  // constructor() { }

  @Input() public header = false;

  protected override addComponentForField(): ComponentRef<FieldBase> {

    switch (this.fieldModel.Type) {
      // Some types use native control (in read only mode) or override in
      // factory methods
      // todo use addReadonlyChildComponent?
      case Enums.FieldType.MultiState:
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
        return super.addComponentForField();
      default:
        // Most just display text
        return this.addTextDisplayComponent();
    }
  }

  protected override addPersonComponent(): ComponentRef<FieldBase> {
    const component = this.addTextDisplayComponent();
    if (/*this.header &&*/ component.instance instanceof TextDisplayFieldComponent) {
      component.instance.formatting = { Unknown: true }
    }
    return component;
  }

  protected override addPersonTeamComponent(): ComponentRef<FieldBase> {
    return this.addPersonComponent();
  }

  protected override addTextDisplayComponent(): ComponentRef<FieldBase> {
    const component = super.addTextDisplayComponent();
    component.instance.forReport = true;
    component.instance.padding = false;
    return component;
  }
}
