import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
})
export class SofSpinnerModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSpinnerThird);
  }
}
