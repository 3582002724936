import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LayoutsModule } from 'app/layouts.module/layouts.module';
import { SoftoolsHelperModule } from 'app/softoolscore.module/softoolscore.module';
import { environment } from 'environments/environment';
import { OverlaySpinnerModule } from './softoolsui.module/overlay-spinner.component/overlay-spinner.module';
import { OverlayImgModule } from 'app/softoolsui.module/overlay-img.component/overlay-img.module';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { OverlayVideoModule } from 'app/softoolsui.module/overlay-video.component/overlay-video.module';
import { LayoutModule } from '@angular/cdk/layout';
import { CompatibilityService } from 'app/services/compatibility.service';
import { CoreConfigModel, CoreConfigModelToken, RecordConcretizerService } from '@softools/softools-core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ZendeskWebwidgetConfig, ZendeskWebwidgetModule } from 'app/zendesk-webwidget/zendesk-webwidget.module';
import { MessageDialogComponent } from './softoolsui.module/message-dialog/message-dialog.component';
import { FormsModule } from '@angular/forms';
import { LaunchPadModule } from 'app/softoolsui.module/launchpad.component/launchpad.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocatorService } from './services/locator.service';
import { DialogFileUploaderModule } from './softoolsui.module/dialog-file-uploader/dialog-file-uploader.module';
import { ExportModule } from './softoolsui.module/export.component/export.module';
import { SecurityModule } from './softoolsui.module/security.component/security.module';
import { NotesModule } from './softoolsui.module/notes.component/notes.module';
import { EmbeddedVideoModalContentModule } from './softoolsui.module/embedded-video-modal-content.component/embedded-video-dialog.module';
import { HistoryModule } from './softoolsui.module/history.component/history.module';
import { VersionCheckModule } from './versioncheck/versioncheck.module';
import { OverlayTextModule } from './softoolsui.module/overlay-text.component/overlay-text.module';
import { HeaderModule } from './softoolsui.module/header.component/header.module';
import { ModalModule } from './softoolsui.module/modal.component/modal.module';
import { NotificationsModule } from './notifications.module/notifications.module';
import { GlobalGuardsModule } from './guards/global-guards';
import { FiltersModule } from './filters/filters.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppService } from './services/app.service';
import { AppServiceImpl } from './services/app.service.impl';
import { ToastrModule } from 'ngx-toastr';

import { WorkspaceModule } from './workspace.module/workspace.module';
import { initialRootAppRoutes } from './guards/root-routes.guard';
import { HomeModule } from './home.module/home.module';

import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from './error-handlers/sentry';
import { RecordConcretizer } from './services/record/record-concretizer.service';
import { AuthHttpInterceptor } from './auth-http-interceptor';
import { TranslationConstantsModule } from './_constants/global-translations.module';
import { PlatformModule } from '@angular/cdk/platform';
import { LogoutComponent } from './logout/logout.component';
import { Auht0CallbackComponent } from './auht0-callback/auht0-callback.component';
import { AuthCheckHttpInterceptor } from './auth-check-http-interceptor';
import * as moment from 'moment';
import { SofSpinnerModule } from '@softools/design/icons';
import { IdpCallbackComponent } from './auht0-callback/idp-callback.component';
import { TenantService } from './services/tenant.service';
import { AccessorFactoryService } from './mvc/reports/accessors/accessor-factory.service';
import { AccessorFactory } from './mvc/reports/accessors/accesssor-factory';
import { GlobalModelService } from './mvc/common/global-model.service';
import { ModelFactory } from './mvc/services/model-factory.';
import { ModelFactoryService } from './mvc/services/model-factory.service';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { appFieldFactoryToken } from './services/fields/app-field-factory.interface';
import { AppFieldFactoryService } from './services/fields/app-field-factory.service';
import { MessageBannerModule } from './softoolscore.module/message-banner/message-banner.module';
import { TeamsService } from './services/teams.service';
import { UsersService } from './services/users.service';
import { SelectListsService } from './services/select-lists.service';
import { EmailActivityModule } from './email-activity/email-activity.module';

function initializeApp(tenantService: TenantService): () => Promise<any> {
  return async () => {
    try {
      await tenantService.initTenant();
    } catch (error) {
      alert(error.message);
    }
  }
}

function initializeUsersAndTeams(teamsService: TeamsService, usersService: UsersService): () => Promise<any> {
  return async () => {
    try {
      await teamsService.initialise('init');
      await usersService.refresh('init');
    } catch (error) {
      alert(error.message);
    }
  }
}

function initializeSite(models: GlobalModelService, selectListService: SelectListsService): () => Promise<any> {
  return async () => {
    try {
      await models.siteModel.initialiseAsync(selectListService);
    } catch (error) {
      alert(error.message);
    }
  }
}

const providers = [
  OverlayService,
  CompatibilityService,
  {
    provide: CoreConfigModelToken,
    useValue: {
      environmentBaseUrl: environment.baseUrl,
      // userApiBaseUrl: environment.userApiBaseUrl,
    } as CoreConfigModel,
  },
  // Register with old text token for backwards compat
  // todo remove when updated core in use
  {
    provide: 'CoreConfigModel',
    useValue: { environmentBaseUrl: environment.baseUrl } as CoreConfigModel,
  },
  { provide: GlobalModelService },
  { provide: AppService, useClass: AppServiceImpl },
  { provide: RecordConcretizerService, useClass: RecordConcretizer },
  { provide: AccessorFactory, useClass: AccessorFactoryService },
  { provide: ModelFactory, useClass: ModelFactoryService },
  { provide: HTTP_INTERCEPTORS, useClass: AuthCheckHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  { provide: 'googleTagManagerId', useValue: 'GTM-PH5NQ9W' },
  { provide: 'googleTagManagerAuth', useValue: environment.production ? 'HQeWdo2WQ95r5VTyBhKMag' : 'U_DrlBEQsMbvMIpRG0tshg' },
  { provide: 'googleTagManagerPreview', useValue: environment.production ? 'env-1' : 'env-5' },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [TenantService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeUsersAndTeams,
    deps: [TeamsService, UsersService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeSite,
    deps: [GlobalModelService, SelectListsService],
    multi: true
  },
  {
    provide: appFieldFactoryToken, useClass: AppFieldFactoryService
  }
];

const telemetryProviders = [
  {
    provide: ErrorHandler,
    useClass: SentryErrorHandler,
  },
];

export class ZendeskConfig extends ZendeskWebwidgetConfig {
  accountUrl = 'softools.zendesk.com';
  beforePageLoad(zE) {
    zE.setLocale('en');
    zE.hide();
  }
}
@NgModule({
  declarations: [AppComponent, MessageDialogComponent, LogoutComponent, Auht0CallbackComponent, IdpCallbackComponent, CookiePolicyComponent],
  imports: [
    BrowserModule,
    FormsModule,
    OverlayModule,
    OverlaySpinnerModule,
    OverlayImgModule,
    OverlayTextModule,
    OverlayVideoModule,
    LayoutModule,
    MatProgressSpinnerModule,
    MatMomentDateModule,
    MatDialogModule,
    MatButtonModule,
    RouterModule.forRoot(initialRootAppRoutes, {
      relativeLinkResolution: 'corrected',
      preloadingStrategy: PreloadAllModules,
      // Enableing this adds routing messages to console.
      // enableTracing: true
    }),
    HttpClientModule,
    BrowserAnimationsModule.withConfig({ disableAnimations: !('animate' in document.documentElement) }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    SoftoolsHelperModule,
    LayoutsModule.forRoot(),
    ZendeskWebwidgetModule.forRoot(ZendeskConfig),
    LaunchPadModule,
    FontAwesomeModule,
    DialogFileUploaderModule,
    ExportModule,
    SecurityModule,
    NotesModule,
    EmbeddedVideoModalContentModule,
    HistoryModule,
    VersionCheckModule,
    MatSnackBarModule,
    HeaderModule,
    ModalModule,
    NotificationsModule,
    GlobalGuardsModule,
    FiltersModule, // FilterSaveComponent is an entryComponent so import here otherwise we could encounter "Uncaught Error: Component FilterSaveComponent is not part of any NgModule or the module has not been imported into your module"
    WorkspaceModule,
    HomeModule,
    PlatformModule,
    SofSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: `registerWhenStable`,
    }),
    TranslationConstantsModule,
    MessageBannerModule,
    EmailActivityModule
  ],
  providers: environment.enableTelemetry ? [...providers, ...telemetryProviders] : [...providers],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(adapter: DateAdapter<any>, injector: Injector) {
    // Save injector reference for global lookup
    LocatorService.injector = injector;

    // if (environment.enableTelemetry) {
    //   ai.init();
    // }

    // Apply current user locale
    // We now set the locale from the browser settings (fall back to UK english)
    const locale = window?.navigator?.language ?? 'en-GB';
    moment.locale(locale);
    adapter.setLocale(locale);
  }
}
