
<app-input-icon icon="sticky-note" (iconClicked)="openDialog()" [clickable]="!getIsDisabled()">
  <input
    readonly="readonly"
    [value]="fieldValue || ''"
    class="pe-4 readonly-normal-appearance form-control  form-control-o"
    [class.inline-control]="inline"
    [ngStyle]="textStyle"
  />
</app-input-icon>
