import { DatabaseContextService } from '../indexedDb/database-context.service';
import { DatabaseTransaction } from '../indexedDb/database-transaction';

/**
 * Base class for services that manage local storage in index db
 */
export abstract class StorageServiceBase<T, TKey extends string | string[] = string | string[]> {

  constructor(
    protected store: string,
    protected dbContext: DatabaseContextService<T>,
  ) {
  }

  public get(key: TKey, transaction?: DatabaseTransaction): Promise<T> {
    return this.dbContext.get(this.store, key, transaction);
  }

  public getAll(count?: number): Promise<Array<T>> {
    return this.dbContext.getAll(this.store, count);
  }

  public save(key: TKey, value: T, transaction?: DatabaseTransaction): Promise<boolean> {
    return this.dbContext.save(this.store, key, value, transaction);
  }

  public saveAuto(value: T, transaction?: DatabaseTransaction): Promise<boolean> {
    return this.dbContext.saveAuto(this.store, value, transaction);
  }

  public delete(key: TKey, transaction?: DatabaseTransaction): Promise<boolean> {
    return this.dbContext.delete(this.store, key, transaction);
  }

  public clear(): Promise<boolean> {
    return this.dbContext.clear(this.store);
  }

  public async keys(): Promise<Array<TKey>> {
    const all = await this.dbContext.getAllKeys(this.store);
    return all as Array<TKey>;
  }

  /**
   * Get an item for storage, adding it if it doesn't already exist.  A caller supplied
   * function creates the new item; this is only called if the item is not already present.
   *
   * @param key       Item unique storage key
   * @param creator   Creation method
   */
  protected async getOrCreateItemAsync(key: TKey, creator: () => Promise<T>): Promise<T> {
    let item = await this.get(key);
    if (!item) {
      item = await creator();
      await this.save(key, item);
    }
    return item;
  }

}
