<div class="w-100 d-flex " style="flex-wrap: wrap; justify-items: flex-start">
  <div *ngFor="let item of homeItems" 
    class="h-100 p-2 pointer" 
    >
    <app-card 
      [appModel]="appHomeModel.appModel" 
      [generalController]="generalController"
      [card]="card"
      [homeItem]="item"
      [template]="cardTemplate" 
      [record]="itemRecord(item)"
      (cardClicked)="cardClicked(item)"
    >
    </app-card>
  </div>
</div>
