import { GridDataSet } from './griddataset';
import { LookupFieldMapping } from './lookupfieldmapping.interface';
import { Enums, IActionButtonConfig, LogicBlockIdentifer } from '../../types';
import { IStyleName } from './style-name.interface';

export type AlignmentTypeAlias = 'left' | 'center' | 'right';

/**
 * Application field description
*/
export interface Field {
  AutoNumber?: boolean;

  AutoGenerated?: boolean;
  CreatedByUserId?: number;
  CreatedDate?: string;

  /** If true field is suitable for sort, group, etc.*/
  Sortable?: boolean;

  DefaultSortByIdentifier?: string;
  DefaultSortOrder?: string;

  DefaultValue?: string;
  DefaultValueIsExpression?: boolean;

  /** Optional user-supplied field description  */
  Description?: string;

  DisplayFormatted: boolean;
  ExcludeFromTemplateCopy: boolean;
  Expression?: string;

  /** Identifies the field.
   * While this should be unique within a scope, the same id can appear
   *  more than once if they have different @see ParentFieldIdentifier values
   */
  Identifier: string;

  IncludeInSearch: boolean;
  // IsAToZField?: boolean;
  IsBackingField?: boolean;
  IsEditable: boolean;

  /** If true, field can only be edited during creation (subject to other permissions)
  * No zerocode/appstudio support yet, used in embedded apps.
  */
  IsImmutable?: boolean;

  /**
   * If defined and true, this field has a single shared instance at the app level.
   * In a singleton app, all fields must be static.  Normal apps can also have static
   * fields to control common values.
   */
  IsStaticField?: boolean;

  IsReadOnly: boolean;
  IsTitleField?: boolean;
  IsUserIdentifier?: boolean;
  Label: string;
  ModifiedByUserId?: number;
  ModifiedDate?: string;
  ParentFieldIdentifier?: string;
  Required?: boolean;

  SelectListIdentifier?: string;
  SelectListType?: Enums.SelectListType;    // is this valid/used?
  SelectListSubType?: number;
  /** If true select list options are taken from a record field named by SelectListIdentifier */
  UseRecordSelectListOptions?: boolean;

  ShowCalendarEvent?: boolean;
  SubType?: number;
  SystemLabel: string;
  Type: number;
  InAppChartReportIdentifier?: string;

  // Image List specific fields
  ImageListIdentifier?: string;
  ImageListSize?: number;

  // Grid Specific fields
  GridDataSets?: Array<GridDataSet>;
  SubFieldsIdentifiers?: Array<{ Identifier: string }>;
  Orientation?: number;
  HeaderText?: string;
  HideGridRowTitle?: boolean;

  // Barcode Specific Fields
  /** Comma separated list of formats */
  BarcodeFormats?: string;
  BarcodeValidCode?: string;
  BarcodeValidCodeRegex?: string;

  /** Display order for grid subfields */
  GridDisplayOrder?: number;

  /** App id of app for lookup field */
  LookupFieldApp?: string;
  LookupFieldMappings?: Array<LookupFieldMapping>;

  ImageActionButtonSize?: Enums.ImageActionButtonSize;
  ImageActionButtonImageUri?: string;
  ImageActionButtonType?: Enums.ImageActionButtonType;

  /** Referenced URL  Used for button fields and other url aware fields e.g. UrlField */
  ImageActionButtonUrl?: string;

  /** Open links a new tab.  Used for button fields and other url aware fields e.g. UrlField */
  ImageActionButtonUrlNewTab?: boolean;

  ImageActionButtonChildAppIdentifier?: string;
  ImageActionButtonTriggerWorkflowId?: number;

  /** Additional action button config - currently just for record copy */
  ImageActionButtonConfig?: IActionButtonConfig;

  /** If set the field is hidden unless the specified record value is truthy.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  ShowWhenValueTrue?: string;

  /** If set, specifies a number of items in a field (e.g. a selection list) before it
   * alters to a more compact display format.  If not set, a field specific default is used.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  CompactItemsThreshold?: number;

  /** If set and true, the field label text is displayed styled as a header
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
  */
  LabelAsHeader?: boolean;

  /** If set, specifies additional prompt text to be displayed with the field.
   * Not all field types support this.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   * File drop field uses this as prompt for choose file option.
   */
  SubText?: string;

  /** If set on a list field, sets whether the row delete function is on or off.
   * If not specified, delete is enabled when the field is editable.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  DeleteRowEnabled?: boolean;

  /** If set on a list field, sets whether the add row  function is on or off.
   * If not specified, add is enabled when the field is editable.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  AddRowEnabled?: boolean;

  /** If set on a list field, it will use pagination. Not in AS yet
   * So far only tested with read-only fields.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  ListFieldPagination?: boolean;

  /**
   * If set Min/Max value for range and number fields
   */
  MinValue?: number;
  MaxValue?: number;

  /**
   * If set this is the number of rows for a long text field
   */
  Rows?: number;

  /** If set this is the max length of a text field type */
  Length?: number;

  /** If set this is the min length of a text field type */
  MinLength?: number;

  /** Alignment string; left/ center/ right. No value will be default alignment. */
  Alignment?: string; //  AlignmentTypeAlias;

  /** Optional list of styles to apply to the field  */
  NamedStyles?: Array<IStyleName>;

  DisplayOptions?: {
    ShowAsIcon?: boolean;

    /**
     * Icon name to display if ShowAsIcon is true
     * The name is one of a set supported by workspace.  Initially this is a subset of the FontAwesome icon
     * set but could include other sources.  If ShowAsIcon is specified without an Icon, a default for the
     * field type is used.
     * Currently only supported for limited field types, and no AS support.
     */
    Icon?: string;

    /** Optionally Display label 'before' or 'after' icon.  Normal small label is supressed in any case */
    LabelPosition?: string;
  };

  /** If defined, the field is only local to a device, and is not persisted to the server */
  Local?: {
    /** If true, the field should be persisted locally on the device  */
    Persist?: boolean;

    /** If defined and the field supports lazy loading, this logic block is used to get the field value when needed */
    LazyGetValueLogicBlock?: LogicBlockIdentifer;
  };

  /** Options for button-like fields.  NB many properties are at the top level  */
  Button?: {

    /**
     * Specifies form to use in buton action where relevant.
     * OpenRecord button - hint to show popup form instead of navigating (todo)
     */
    FormIdentifier?: string;
  }

  /** Optional additional parameters for text fields */
  TextFieldOptions?: {

    /** If set field is a password field */
    PasswordField?: boolean;

    /** If set, specifies the autocomplete mode for the field.  If not specified, 'off' is used */
    AutoCompleteType?: string;
  };

  /** Logic blocks to execute for the field */
  LogicBlocks?: {
    /** Default action for field e.g. button pressed */
    Default?: LogicBlockIdentifer;
  };

  /** Optional additional parameters for image fields */
  ImageOptions?: {

    /** Edit control display mode */
    buttonMode?: ImagaFieldButtonMode;

    /** Size of the field in rem when displayed inline (e.g. list field or report).
     * The field is set to the specified width; the height is automatically
     * calculated from the image aspect ratio.  Default is 2rem.
    */
    inlineWidth?: number;

    /** Size of the field when displayed directly on a form template */
    formWidth?: number;
  };


  /** Options for reference field  */
  ReferenceOptions?: IReferenceField;

  AggregateConfig?: {
    ReferenceFieldId?: string;
    SummaryExpression: string;
  };

  /**
   * Optional additional parameters for lookup fields.
   * NB Some lookup specific properties are defined at the top level
   */
  Lookup?: {

    /**
     * Report to use for selectinb the lookup record.  This must be
     * a table or list report in the target app specified by LookupFieldApp.
     */
    ReportIdentifier?: string;
  };

  Person?: IPersonOptions;

  /**
   * Formatting information.  If present the field is displayed
   * using this formatting information.  The exact interpretations
   * depend on the field type and it's state.
   */
  Formatting?: IFormatting;

  /**
   * Display date/time as local date/time
   */
  DisplayAsLocal?: boolean;

  /**
   * If true then image field data will be synced to work offline.
   */
  AvailableOffline?: boolean;

  /** If true, field is disabled until clicked on */
  ClickToEdit?: boolean;
}

export enum ImagaFieldButtonMode {

  /** Buttons are always shown for editable images (default behaviiour)  */
  Always,

  /** Buttons are hidden; click to open popup with controls */
  ClickToEdit,

}

export interface IReferenceField {

  TargetAppIdentifier: string;

  ReportIdentifier?: string;

}

export interface IPersonOptions {
  UseReferences?: boolean;
}


export interface IFormatting {

  /** Don't use digit grouping.  Default is to group digits if formatting specified */
  DisableDigitGrouping?: boolean;

  /** Optional currency code to display as currency */
  Currency?: string;

  /** Use ISO code instead of currency symbol if currency set */
  CurrencyISO?: boolean;

  /** Display as percentage.  0.01 is displayed as 1% */
  DisplayPercent?: boolean;

  /** Min number of digits after decimal point */
  MinDecimalPlaces?: number;

  /** Max number of digits after decimal point */
  MaxDecimalPlaces?: number;

  /**  Optional fixed prefix string */
  Prefix?: string;

  /**  Optional fixed suffix string */
  Suffix?: string;

  /**  Optional fixed regex pattern string */
  Pattern?: string;

  /** If true format unknown values specially (limited implementation) */
  Unknown?: boolean;
}
