import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CursorPaginatorComponent } from './cursor-paginator/cursor-paginator.component';
import { PaginationComponent } from './pagination.component/pagination.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListReportPaginatorComponent } from './list-report-paginator/list-report-paginator.component';
import { TableReportPaginatorComponent } from './table-report-paginator/table-report-paginator.component';
import { SofSpinnerModule } from '@softools/design/icons';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, SofSpinnerModule],
  declarations: [CursorPaginatorComponent, PaginationComponent, ListReportPaginatorComponent, TableReportPaginatorComponent],
  exports: [CursorPaginatorComponent, PaginationComponent, ListReportPaginatorComponent, TableReportPaginatorComponent],
})
export class PaginationModule {}
