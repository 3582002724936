
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartComponent } from './chart.component';
import { MatrixReportModule } from '../matrixreport.component/matrixreport.module';
import { MonteCarloMatrixChartComponent } from './montecarlomatrixchart.component/montecarlomatrixchart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { WsSharedModule } from 'app/workspace.module/components/ws-shared/ws-shared.module';

@NgModule({
  declarations: [
    ChartComponent,
    MonteCarloMatrixChartComponent
  ],
  imports: [
    CommonModule,
    MatrixReportModule,
    HighchartsChartModule,
    WsSharedModule
  ],
  exports: [ChartComponent],
  providers: [],
})
export class ChartModule { }
