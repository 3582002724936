import { App, Failure, Record } from '@softools/softools-core';
import { AppField } from './app-field';
import { Field } from '@softools/softools-core';
import { Application } from '../application';
import { IApplication } from '../app-field-source';


export class GridSubField<TContained extends AppField = AppField> extends AppField {

  public ParentGridField: Field;

  public SubFieldIndex: number;

  constructor(field: Field, public containedField: TContained, app: App & IApplication) {
    super(field, app);
  }

  public override initialise(app: Application) {
    const [gridId, subfieldId = ''] = this.Identifier.split('_');
    this.ParentGridField = app.getField(gridId);
    this.SubFieldIndex = this.ParentGridField.SubFieldsIdentifiers.findIndex(sf => sf.Identifier === subfieldId);
  }

  public override validateRecord(): Array<Failure> {
    // subfield not used in validation
    return [];
  }

  public override compactRecord(_record: Record): any | null {
    // nop - the containing grid field is resposible for handling the cell
    return null;
  }

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    return this.containedField?.compareValues(val1, val2, isDescending) ?? 0;
  }
}
