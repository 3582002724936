import { Component, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { logError, Record, RecordCopyInfo, RecordId } from '@softools/softools-core';
import { AppIdentifiers } from 'app/services/record/app-info';
import { AppService } from 'app/services/app.service';
import { Application } from 'app/types/application';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { InjectService } from 'app/services/locator.service';
import { AppDataService } from 'app/services/appdata.service';
import { DataIndexService2 } from 'app/services/indexes/data-index-service';
import { AppModel } from 'app/mvc';

export interface RecordCopyParameters {
  appModel: AppModel;
  id: RecordId;
  hierarchy: string;
  appIdentifier: string;
  appIdentifiers: AppIdentifiers;
  RecordCopyInfo?: RecordCopyInfo;
}

export enum CopyRecordStageEnum {
  InitialOptions = 1,
  PendingCopy = 2,
  SuccessfulCopy = 3,
  FailedCopy = 4
}

@Component({
  selector: 'app-record-copy',
  templateUrl: './record-copy.component.html',
  styleUrls: ['./record-copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordCopyComponent {

  @Input() public id: string;
  @Input() public hierarchy: string;
  @Input() public appIdentifier: string;
  @Input() public appIdentifiers: AppIdentifiers;
  @Input() public show = false;

  /** If set, copy is complete and we're displaying results instead */
  @Input() public newRecordId: string;

  @InjectService(AppDataService)
  private readonly appData: AppDataService;

  @InjectService(DataIndexService2)
  private readonly dataIndexService: DataIndexService2;

  public templateCopy = false;
  public hasLinkCopyToParent = false;
  public linkCopyToParent = false;
  public copyNotes = false;
  public copyComments = false;
  public copyAttachments = false;
  public copyHistory = false;
  public copyRecordStageEnum = CopyRecordStageEnum as typeof CopyRecordStageEnum;
  public app: Application;

  public copyRecordStage$ = new BehaviorSubject<CopyRecordStageEnum>(CopyRecordStageEnum.InitialOptions);

  constructor(
    private appService: AppService,
    public dialog: MatDialogRef<RecordCopyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecordCopyParameters,
  ) {
    this.id = data.id;
    this.hierarchy = data.hierarchy;
    this.appIdentifier = data.appIdentifier;
    this.appIdentifiers = data.appIdentifiers;

    this.copyNotes = data.RecordCopyInfo?.CopyNotes ?? false;
    this.copyComments = data.RecordCopyInfo?.CopyComments ?? false;
    this.copyAttachments = data.RecordCopyInfo?.CopyAttachments ?? false;
    this.copyHistory = data.RecordCopyInfo?.CopyHistory ?? false;

    if (this.appIdentifiers) {    // should be set except tests
      this.app = this.appService.application(this.appIdentifiers.visibleAppIdentifier);
    }

    this.linkCopyToParent = this._isChild(); // Default to checked if we are in child scenario
    this.hasLinkCopyToParent = this.linkCopyToParent;
  }

  public async naviateCopiedRecordClickHandler(): Promise<void> {
    try {
      const navigation = this.data.appModel.globalModel.navigation;
      if (this._isChild()) {
        await navigation.navigateRecordAsync({
          appIdentifiers: this.appIdentifiers,
          recordId: this.newRecordId
        });
      } else {
        await navigation.navigateRecordAsync({
          appIdentifier: this.appIdentifier,
          recordId: this.newRecordId
        });
      }

    } catch (error) {
      logError(error, 'CopiedRecordNav');
    }
  }

  public popupClosedClickHandler(): void {
    this.dialog.close();
  }

  public startCopyClickHandler = (): void => {
    this.copyRecordStage$.next(CopyRecordStageEnum.PendingCopy);

    const copyInfo: RecordCopyInfo = {
      TemplateCopy: this.templateCopy,
      CopyNotes: this.copyNotes,
      CopyComments: this.copyComments,
      CopyAttachments: this.copyAttachments,
      CopyHistory: this.copyHistory,
    };

    this.copyRecord(copyInfo).catch(err => logError(err, ''));
  }

  private _isChild(): boolean {
    return this.hierarchy ? true : false;
  }

  private async copyRecord(copyInfo: RecordCopyInfo) {

    try {
      this.copyRecordStage$.next(CopyRecordStageEnum.PendingCopy);

      const hierarchy = this.linkCopyToParent ? this.hierarchy : null;

      const record: Record = await this.data.appModel.copyRecord(this.id, copyInfo, hierarchy);
      if (record && !record._new) {
        this.newRecordId = record._id;
        this.copyRecordStage$.next(CopyRecordStageEnum.SuccessfulCopy);
      } else {
        this.copyRecordStage$.next(CopyRecordStageEnum.FailedCopy);
      }
    } catch (error) {
      this.copyRecordStage$.next(CopyRecordStageEnum.FailedCopy);
    }
  }
}
