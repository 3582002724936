import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { logError } from '@softools/softools-core';
import { STORAGE_TENANT_KEY } from 'app/_constants/constants.keys';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private http: HttpClient, private router: Router) { }

  public async initTenant(): Promise<void> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
    });

    const tenant = environment.debugTenant; // Only present if localhost dev - to set login lock, otherwise we use hostname
    if (tenant && tenant.length > 0) {
      localStorage.setItem(STORAGE_TENANT_KEY, tenant);
      return;
    }

    const storageTenant = localStorage.getItem(STORAGE_TENANT_KEY);

    if (!storageTenant) {
      try {
        const response = await this.http.get<string>(`${environment.baseUrl}/Api/Account/GetTenantFromHost/${window.location.hostname}`, { headers: headers }).toPromise();
        localStorage.setItem(STORAGE_TENANT_KEY, response);
      } catch (err) {
        logError(err, 'Failed to get tenant from hostname');

        this.router
          .navigate(['/Error'], { queryParams: { type: 'hostname' } } as NavigationExtras)
          .catch((error) => logError(error, 'Failed to route to error'));
      }
    }

    return;
  }

  public tenant(): string {
    return localStorage.getItem(STORAGE_TENANT_KEY);
  }
}
