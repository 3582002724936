import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileAttachment, FilePatch } from '../types/interfaces';
import { FileAttachmentsRepository } from '../repos';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  constructor(private fileAttachmentsRepository: FileAttachmentsRepository) {}

  /**
   * Get all attachment items (comments and file attachments) for the specified item.
   * The returned items are sorted into date order.
   *
   * @param appIdentifier
   * @param recordId
   */
  public getAttachments(appIdentifier: string, recordId: string): Observable<Array<FileAttachment>> {
    const attachments = this.fileAttachmentsRepository.getAttachments(appIdentifier, recordId);

    // combine the latest values of the two observables
    return attachments;
  }

  public saveAttachmentWithData(appIdentifier: string, recordId: string, filename: string, base64Data: string): Observable<any> {
    return this.fileAttachmentsRepository.saveAttachmentWithData(appIdentifier, recordId, filename, base64Data);
  }

  /**
   * Delete attachment (comment or file attachment)
   * @param appIdentifier
   * @param recordId
   * @param addenum
   */
  public deleteAttachment(appIdentifier: string, recordId: string, file: FileAttachment): Observable<string> {
    return this.fileAttachmentsRepository.deleteFileAttachment(appIdentifier, recordId, file.Id);
  }

  public patchAttachment(appIdentifier: string, recordId: string, filePatch: FilePatch) {
    return this.fileAttachmentsRepository.patchFileAttachment(appIdentifier, recordId, filePatch);
  }
}
