import * as Color from 'color';
import { Component, OnDestroy, AfterViewInit, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';

import Auth0Lock from 'auth0-lock';

import { environment } from 'environments/environment';

import {
  SiteStorageService,
  SiteLoginDataResource,
  logError,
  OnlineStatusService,
} from '@softools/softools-core';

import * as auth0_fr from './fr';
import * as auth0_de from './de';
import * as auth0_es from './es';
import * as auth0_ja from './ja';
import { CookieService } from 'ngx-cookie-service';
import { LOGIN_RETURN_URL, LOGIN_LANGUAGE } from 'app/_constants/constants.keys';
import { Subscription } from 'rxjs';
import { ReLoginService } from 'app/services/login.service';
import { ComponentBase } from 'app/softoolsui.module';
import { TenantService } from 'app/services/tenant.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

interface AuthResult {
  accessToken: string;
  expiresIn: number;
}

export function getRedirect() {
  const protocalAndHost = `${window.location.protocol}//${window.location.hostname}`;
  return window.location.port
    ? `${protocalAndHost}:${window.location.port}/Account/Callback`
    : `${protocalAndHost}/Account/Callback`;
}

export function getScopes() {
  return 'openid email profile offline_access';
}


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends ComponentBase implements OnDestroy, AfterViewInit, OnInit {
  public offline = false; // can assume online if you have got to the login screen, this will stil change if the network drops
  public loginEnabled = true;

  private st = null;
  private auth0Lock: Auth0Lock;
  private anonSignalRConnectionId: string = null;

  // invite or registration code
  // If set we open the lock in signup mode
  private qCode = '';

  // The query params might have the email address sent as part of the invite
  private qEmail = '';

  public heartBeatSubscription: Subscription;

  private signUpFieldPlaceholders = {
    firstname: {
      en: 'your first name',
      fr: 'votre nom',
      de: 'ihr vorname',
      ja: 'あなたの下の名前',
      es: 'su nombre',
    },
    lastname: {
      en: 'your last name',
      fr: 'votre nom de famille',
      de: 'ihr nachname',
      ja: 'あなたの名字',
      es: 'tu apellido'
    },
    company: {
      en: 'your company',
      fr: 'votre entreprise',
      de: 'ihre firma',
      ja: 'あなたの会社',
      es: 'tu compañía'
    },
    mobilephone: {
      en: 'your mobile phone',
      fr: 'votre téléphone portable',
      de: 'dein handy',
      ja: 'あなたの携帯電話',
      es: 'tu telefono movil'
    },
    code: {
      en: 'your code',
      fr: 'votre code',
      de: 'dein code',
      ja: 'あなたのコード',
      es: 'tu codigo'
    },
    blankhint: {
      en: 'Can\'t be blank',
      fr: 'Ne peut pas être vide',
      de: 'Kann nicht leer sein',
      ja: '空白にすることはできません',
      es: 'No puede estar en blanco'
    },
    minimum2characters: {
      en: 'Minimum 2 characters',
      fr: 'Minimum 2 caractères',
      de: 'Mindestens 2 Zeichen',
      ja: '2文字以上',
      es: 'Mínimo 2 caracteres'
    },
    signupsuccess: {
      en: 'Thanks for signing up. Please check your email for verification.',
      fr: 'Merci pour l\'enregistrement.Veuillez vérifier votre e- mail pour vérification.',
      de: 'Danke für\'s Registrieren.Bitte überprüfen Sie Ihre E- Mails zur Bestätigung.',
      ja: 'サインアップしていただきありがとうございます。確認のためにあなたの電子メールをチェックしてください。',
      es: 'Gracias por registrarte. Por favor revise su correo electrónico para verificarlo.'
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteStorageService,
    private cookieService: CookieService,
    private onlineStatusService: OnlineStatusService,
    private loginService: ReLoginService,
    private tenantService: TenantService,
    private models: GlobalModelService,
  ) {
    super();
    this.offline = !navigator.onLine;
  }

  ngOnInit(): void {

    // Turn off background sync processing
    this.models.siteModel.stopBackgroundSync();

    this.qCode = this.route.snapshot.queryParams['code'];
    this.qEmail = this.route.snapshot.queryParams['email'];

    this.heartBeatSubscription = this.onlineStatusService.isServerReachable$.subscribe((status) => {
      if (!status) {
        this.onOffline();
      } else {
        this.onOnline();
      }
    });
  }

  @HostListener('window:offline', [])
  onOffline() {
    this.offline = !navigator.onLine;
    this.showOrHideLock(true);
    this.refresh();
  }

  @HostListener('window:online', [])
  onOnline() {
    this.offline = !navigator.onLine;
    this.showOrHideLock();
    this.refresh();
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      if (this.offline) {
        return;
      }

      await this.showLock();
    } catch (error) {
      console.warn('Error in LoginComponent.ngAfterViewInit', error);
    }
  }

  // private async connectAnonymousNotifierHub(): Promise<any> {
  //   try {
  //     const options = {
  //       hubName: 'anonymousNotifierHub',
  //       url: environment.baseUrl,
  //       logging: true,
  //       withCredentials: false,
  //     } as IConnectionOptions;

  //     const connection = await this.signalR.connect(options);
  //     this.anonSignalRConnectionId = connection.id;

  //     const onNotifySignUpError$ = connection.listenFor('notifySignUpError');
  //     onNotifySignUpError$.subscribe((notification: NotificationMessage) => {
  //       if (notification && notification.errorMessage) {
  //         this.actionDispatcherService.dispatch(
  //           showToasty({
  //             Type: Enums.ToastySoftoolsType.error,
  //             Title: 'Validation Error',
  //             Message: notification.errorMessage,
  //           })
  //         );
  //       }
  //     });
  //   } catch (err) {
  //     logError(err, 'Cannot connect to Signal R');
  //   }
  // }

  private async showLock() {

    const tenant = this.tenantService.tenant();

    // We always want to get latest SiteLoginDataResource here.  SiteResyncGuard will then take care of subsequent site version updates for settings etc.
    const site = await this.siteService.updateSiteLoginDataResourceAsync(tenant);

    // Set limited colour subset
    const primary = new Color(site.DefaultTheme, 'rgb');
    const primaryContrast = primary.isDark() ? 'white' : 'black';
    document.documentElement.style.setProperty('--bs-primary', primary.hex());
    document.documentElement.style.setProperty('--primary-contrast', primaryContrast);

    this.loginEnabled = site.Auth0ClientId && site.Auth0ClientId.length > 0;
    if (!this.loginEnabled) {
      return;
    }

    this.setLanguageCookie();
    // await this.connectAnonymousNotifierHub();

    const auth0Domain = await this.siteService.getAuth0Domain(tenant);
    const options = this.getLockOptions(site);

    this.auth0Lock = new Auth0Lock(site.Auth0ClientId, auth0Domain, options);
    this.auth0Lock.show();
  }

  override ngOnDestroy(): void {
    try {
      super.ngOnDestroy();
      this.heartBeatSubscription.unsubscribe();
      clearTimeout(this.st);
    } catch (err) {
      logError(err, 'LoginComponent ngOnDestroy');
    }
  }

  private getLockOptions(site: SiteLoginDataResource): any {
    const language = this.loginService.siteLanguage();

    const options = {
      closable: false,
      allowAutocomplete: false,
      configurationBaseUrl: `${environment.auth0Cdn}`,
      loginAfterSignUp: true,
      language: language,
      languageDictionary: {
        title: '',
        success: {
          signUp: this.signUpFieldPlaceholders['signupsuccess'][language],
        },
      },
      auth: {
        redirectUrl: getRedirect(),
        responseType: 'code',
        params: {
          scope: getScopes(), // Learn about scopes: https://auth0.com/docs/scopes
        },
        audience: environment.apiAudiance,
      },
      allowSignUp: site.ShowRegistration || this.qCode?.length > 0,
      theme: {
        logo:
          site.ImageURI && site.ImageURI.startsWith('/Asset/')
            ? `${environment.baseUrl}${site.ImageURI.replace('/Asset/', '/Api/Asset/Public/')}`
            : `${environment.baseUrl}/Api/Asset/Public/${site.ImageURI}`,
        primaryColor: `${site.DefaultTheme}`,
      },
      additionalSignUpFields: this.getAdditionalSignUpFields(site, language),
      prefill: {
        email: this.qEmail ?? '',
        username: this.route.snapshot.queryParams['username'],
      },
    };

    if (!this.qCode && this.qEmail) {
      // No signup code, but we do have email, should set username/ email input prefill on login
      options.prefill.username = this.qEmail;
    }

    switch (options.language) {
      case 'fr':
        (<any>options).dict = auth0_fr;
        break;
      case 'de':
        (<any>options).dict = auth0_de;
        break;
      case 'ja':
        (<any>options).dict = auth0_ja;
        break;
      case 'es':
        (<any>options).dict = auth0_es;
        break;
    }

    if (this.qCode?.length > 0) {
      (<any>options).initialScreen = 'signUp';
    }

    return options;
  }

  private setLanguageCookie(): any {
    // Cater for language queryParam, allows users to navigate in and sign up via SSO, for example, with a predetermined profile language set.
    const queryParams = this.route.snapshot.queryParams;
    const returnUrl = queryParams['returnUrl'];
    if (returnUrl) {
      localStorage.setItem(LOGIN_RETURN_URL, returnUrl);
      const returnUrlTree: UrlTree = this.router.parseUrl(returnUrl);
      if (
        returnUrlTree &&
        returnUrlTree.queryParams &&
        returnUrlTree.queryParams['language'] &&
        returnUrlTree.queryParams['language'].length > 0
      ) {
        this.cookieService.set(LOGIN_LANGUAGE, returnUrlTree.queryParams['language']);
      }
    } else if (queryParams['language'] && queryParams['language'].length > 0) {
      this.cookieService.set(LOGIN_LANGUAGE, queryParams['language']);
    } else {
      let language = navigator.language;
      if (language?.length > 2) {
        language = language.split('-')[0];
        this.cookieService.set(LOGIN_LANGUAGE, language.toLowerCase());
      } else if (language?.length === 2) {
        this.cookieService.set(LOGIN_LANGUAGE, language.toLowerCase());
      }
    }
  }

  private getAdditionalSignUpFields(site: SiteLoginDataResource, shortLanguage: string): Array<any> {
    const additionalSignUpFields: any[] = [
      {
        name: 'firstname',
        placeholder: this.signUpFieldPlaceholders['firstname'][shortLanguage],
        prefill: this.route.snapshot.queryParams['firstName'],
        validator: (firstname) => {
          return {
            valid: firstname.length >= 2,
            hint: this.signUpFieldPlaceholders['minimum2characters'][shortLanguage],
          };
        },
      },
      {
        name: 'lastname',
        placeholder: this.signUpFieldPlaceholders['lastname'][shortLanguage],
        prefill: this.route.snapshot.queryParams['lastName'],
        validator: (lastname) => {
          return {
            valid: lastname.length >= 2,
            hint: this.signUpFieldPlaceholders['minimum2characters'][shortLanguage],
          };
        },
      },
    ];

    if (!site.CompanyDisabled) {
      additionalSignUpFields.push({
        name: 'company',
        placeholder: this.signUpFieldPlaceholders['company'][shortLanguage],
        prefill: this.route.snapshot.queryParams['company'],
        validator: (company) => {
          return {
            valid: company.length > 0,
            hint: this.signUpFieldPlaceholders['blankhint'][shortLanguage],
          };
        },
      });
    }

    if (!site.PhoneNumberDisabled) {
      additionalSignUpFields.push({
        name: 'mobilephone',
        placeholder: this.signUpFieldPlaceholders['mobilephone'][shortLanguage],
        prefill: this.route.snapshot.queryParams['mobilePhone'],
        validator: function () {
          return {
            valid: true,
          };
        },
      });
    }

    if (site.RegistrationCodeEnabled || this.qCode?.length > 0) {
      additionalSignUpFields.push({
        name: 'code',
        placeholder: this.signUpFieldPlaceholders['code'][shortLanguage],
        prefill: this.qCode ?? '',
        validator: (code) => {
          return {
            valid: code.length > 0,
            hint: this.signUpFieldPlaceholders['blankhint'][shortLanguage],
          };
        },
      });
    }

    if (this.anonSignalRConnectionId) {
      additionalSignUpFields.push({
        name: 'signalrconnectionid',
        value: this.anonSignalRConnectionId,
        type: 'hidden',
      } as any);
    }

    return additionalSignUpFields;
  }

  private showOrHideLock(hide = false) {
    if (this.auth0Lock != null && this.auth0Lock.hide && hide) {
      this.auth0Lock.hide();
    }
    if (this.auth0Lock != null && this.auth0Lock.show && !hide) {
      this.auth0Lock.show();
    }
  }
}
