import { Injectable } from '@angular/core';
import { RecordQueueService } from './record-queue.service';
import { Record, OnlineStatusService, RecordId, ValidationErrorResponse, logError, logMessage } from '@softools/softools-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { RecordPatch } from 'app/workspace.module/types';
import { isEqual } from 'lodash-es';
import { Application, DeleteRecordOptions } from 'app/types/application';
import { HttpErrorResponse } from '@angular/common/http';
import { IRecordPersitor } from './record-persitor.interface';
import { IApplications } from '../applications.interface';
import { ChangeQueueStorageService } from '../change-queue.service';
import { environment } from 'environments/environment';
import { FunctionQueue } from 'app/workspace.module/types/function-queue';

/**
 * Record adds should be dispatched befor any record updates.
 * If a servcer call is in progress dont do anything
 * Changes from the server should update the local IDB record
 * Should dispatch any server validation errors
 * Should assume the record add or patch has been validated before it hits this service
 * Once a record add has saved to the server switch to patch for that record ID
 * If a record is deleted on the server remove from indexedDB
 * Retry policy from all server calls
 * Dont do server calls if offline
 *
 * If a post is a bad request it should remove from the queue and throw an error
 * - Should retry. (Maybe not need if the queue does not send two updates)
 * - Should then switch to a patch.
 * - Then remove from IDB.
 */
@Injectable({ providedIn: 'root' })
export class RecordPersistService implements IRecordPersitor {
  public recordCreated$: BehaviorSubject<Record>;
  public recordUpdated$: BehaviorSubject<Record>;
  // public recordChanges$: Subject<Record>;
  public recordPatched$: Subject<RecordPatch>;
  public recordPatching$: Subject<{ patch: RecordPatch, processing: boolean }>;
  public pendingChanges$: Subject<RecordPatch>;
  public patchQueued$: Subject<RecordPatch>;

  /** Fired when a pach has been succesfully sent to the server as a post or patch */
  public patchApplied$ = new Subject<RecordPatch>();

  private functionQueue = new FunctionQueue();

/**
 * We need to lock across tabs.
 * If we fail to lock across tabs we will get the same record id creating twice
 * This fixes long outstanding sentry errors for postwithid record exists.
 */
  public get lock(): boolean {
    return JSON.parse(localStorage.getItem('record-syncing')) ?? false;
  }

  public set lock(value) {
    localStorage.setItem('record-syncing', JSON.stringify(value));
  }

  private _skipped = false;

  private _queued = false;

  constructor(
    private changeQueueStorageService: ChangeQueueStorageService,
    private recordQueueService: RecordQueueService,
    private onlineStatus: OnlineStatusService,
    private recordValidationService: RecordValidationService
  ) {
    this.lock = false;
    this.recordCreated$ = new BehaviorSubject<Record>(null);
    this.recordUpdated$ = new BehaviorSubject<Record>(null);
    this.recordPatched$ = new Subject<RecordPatch>();
    this.recordPatching$ = new Subject<{ patch: RecordPatch, processing: boolean }>();
    // this.recordChanges$ = new Subject<Record>();
    this.pendingChanges$ = new Subject<RecordPatch>();
    this.patchQueued$ = new Subject<RecordPatch>();
  }

  public async queueChange(recordPatch: RecordPatch): Promise<void> {
    await this.changeQueueStorageService.queue(recordPatch);
    this._queued = true;
  }

  /**
   * Write a patch directly into the record queue.  This can only be used for
   * initial patches, subsequent changes must be added via the change queue
   * @param recordPatch patch
   */
  public async queueChangeImmediate(recordPatch: RecordPatch): Promise<void> {
    if (!environment.production) {
      if (!recordPatch._new || this.recordQueueService.getPatch(recordPatch._id)) {
        throw new Error('Invalid immediate change queue');
      }
    }

    await this.recordQueueService.saveRecordPatchAsync(recordPatch);
    this._queued = true;
  }


  /**
   * Process the patch queue and send any records that are complete to the server.
   * @returns true if some patches are still queueud, false if the queue has been completely processed
   */
  public async persistChanges(applications: IApplications): Promise<boolean> {
    return await this.functionQueue.run(async () => {
      return await this._persistChanges(applications);
    });
  }

  /**
   * Persist any changes for the specified record immediately
   * @param app
   * @param id
   * @returns true if some patches are still queueud, false if the queue has been completely processed
   */
  public async persistRecord(app: Application, id: RecordId) {
    return await this.functionQueue.run(async () => {
      return await this._persistRecord(app, id);
    }, { priority: true });
  }

  public async _persistChanges(applications: IApplications): Promise<boolean> {

    let locked = false;

    try {
      // Wait for a gap between updates
      await this.recordQueueService.waitUntilQuiet(500);

      if (!this.lock) {
        locked = true;
        this.setLock();
        this._queued = false;

        // Apply all queued changes to the patch queue
        await this.promoteChanges();

        if (this.onlineStatus.isConnected) {
          const keys = this.recordQueueService.getRecordIds();
          for (let i = 0; i < keys.length; ++i) {
            const key = keys[i];
            try {
              const recordPatch = this.recordQueueService.getPatch(key);
              const isCreate = recordPatch?._new;

              let updated: Record = null;
              if (recordPatch?.hasPersistableChanges) {
                const app = applications.tryGetApplication(recordPatch.AppIdentifier);
                if (app && !app.DisableAutoSave) {
                  updated = await this.process(recordPatch, app);
                }
              }

              this.recordPatched$.next(recordPatch);

              if (updated) {
                // Notify listeners of the changed record
                if (isCreate) {
                  this.recordCreated$.next(updated);
                } else {
                  this.recordUpdated$.next(updated);
                  // this.recordUpdated$.next({ ...<any>response, '_deltas': deltas });
                }
              }
            } catch (error) {
              logError(error, `persistChanges failed`);
            }
          }
        }

        // We've looked at all the records and either processed them or decided not to
        // If an item was queued during processing we need to process again; otherwise
        // return false to stop processing until there's a change
        // const queued = this._queued;
        const changeKeys = await this.changeQueueStorageService.keys();
        return changeKeys?.length > 0 || this._queued;
      } else {
        this._skipped = true;
        return false;
      }
    } finally {
      if (locked) {
        this.unlock();

        this._queued = false;

        // If we called persistChanges while it was locked, return true to look again
        if (this._skipped) {
          this._skipped = false;
          return true;
        }
      }
    }
  }

  /**
   * Persist any changes for the specified record immediately
   * @param app
   * @param id
   * @returns true if some patches are still queueud, false if the queue has been completely processed
   */
  public async _persistRecord(app: Application, id: RecordId) {

    let locked = false;

    try {
      if (!this.lock && this.onlineStatus.isConnected) {
        locked = true;
        this.setLock();

        const recordPatch = await this.recordQueueService.extract(id);
        if (recordPatch) {
          const updated = await this.process(recordPatch, app);
          const isCreate = recordPatch?._new;
          if (updated) {
            // Notify listeners of the changed record
            if (isCreate) {
              this.recordCreated$.next(updated);
            } else {
              this.recordUpdated$.next(updated);
            }
          } else if (!updated) {
            // Didn't update server so restore patch
            await this.queueChange(recordPatch);
          }
        }

        return true;
      } else {
        return false;
      }
    } finally {
      if (locked) {
        this.unlock();
      }
    }
  }

  /**
   * Process a single record with an upsert operation depending on whether the
   * patch indicates a new record.
   *
   * @returns the record returned by the server in response to the create or update
   *  request.  It reflects the state of the record at the point the operation completed.
   *  If the operation fails and the record is not updated, null is returned.
   */
  private async process(recordPatch: RecordPatch, app: Application): Promise<Record> {
    if (recordPatch) {
      this.pendingChanges$.next(recordPatch);

      if (Object.keys(recordPatch.getDelta()).length > 0) {

        let record: Record;

        // If the record has new record flag set its not been posted or stored, the patch is all we have
        if (recordPatch._new) {
          record = recordPatch.toRecord(app);
        } else {
          // Get the current record value.  We must have this to do validation (which may involve fields not in the patch)
          // If the record is not stored locally by an offline app/report which involves an extra server hit
          // Maybe we can check for cached record in state - but we're not hooked up for that.
          const current = await app.getRecordByIdAsync(recordPatch._id);
          if (current) {
            // This is an existing record, apply patch
            record = recordPatch.updateRecord(current, app, true);
          } else {
            // Didn't find record so abandon update
            // The patch will be stuck in the queue so we might want to take additional action here
            // e.g. if you try to patch a record that has been deleted or hidden from you, the patch will never work
            return null;
          }
        }

        const isValid = this.recordValidationService.isValid(record, app);
        if (isValid) {

          // start a record patch operation
          this.recordPatching$.next({ patch: recordPatch, processing: true });

          let retries = 3;
          while (retries-- > 0) {
            try {
              const response = await app.upsertAsync(recordPatch);
              if (response?._id) {
                // const deltas = await this.deltas(currentRecord, response);

                // Clear new flag as record now has been posted
                delete (<any>response)._new;

                // Clear stored errors from previous runs
                this.recordValidationService.clearServerError(record);

                if (app.isOfflineApp) {
                  // Store the record.  This also sets the correct format
                  // Do before clearing the patch to avoid race conditions
                  // We might have to store again if there's a queued patch
                  // but that will be rare
                  await app.storeAsync(response);
                }

                // Processed the patch so delete it
                await this.recordQueueService.delete(recordPatch._id);

                this.patchApplied$.next(recordPatch);

                // We may have queued some changes while the new flag was set
                // so promote them into the patch queue removing _new
                await this.promoteChanges(true);

                // If we have any changes in the change queue, convert
                // them to a patch and apply to the record so we always see the
                // most recent change.  This is a snapshot, new changes could
                // appear at any moment but they will be processed on the next iteration
                const patch = await this.peekChanges(recordPatch._id);
                if (patch) {
                  patch._new = false; // record is now created so ignore any new flags
                  this.mergePending(patch, response, app);

                  if (app.isOfflineApp) {
                    // Store the record.  This also sets the correct format
                    await app.storeAsync(response);
                  }
                }

              } else {
                return null;
              }

              return response;
            } catch (error) {
              if (error instanceof HttpErrorResponse) {
                logError(error, 'HTTP error returned');
                switch (error.status) {
                  case 404:
                    // The backend returned an not found
                    // This would mean the record is deleted or permissions changed.

                    // remove the patch
                    await this.recordQueueService.delete(recordPatch._id);
                    // remove the record

                    const options: DeleteRecordOptions = {
                      selection: {
                        AllRecordsSelected: false,
                        SelectedRecordIds: [record._id],
                      },
                      softDelete: false
                    };

                    await app.deleteRecordsAsync(options);
                    return null;
                  case 408:
                    // Retry makes sense for these codes so continue
                    break;
                  default:
                    // The logger does not log http errors
                    // Forcing this to track the _new issue
                    if (error.status === 400 && error.error) {
                      logError(new Error(error.error), 'Persist record');
                    }

                    // This should not happen but we are seeing PostWithId 400 alot with the record already exists message.
                    // Clear the _new property on the recordPatch and persist back to storage.
                    if (error.error === 'The record already exisits') {
                      delete recordPatch._new;
                      await this.recordQueueService.addRecordPatch(recordPatch);
                      return null;
                    }

                    // Default is to fail without a retry

                    // Store server error response
                    if (error.error) {
                      this.recordValidationService.setServerErrors(record, error.error);
                      this.recordUpdated$.next(record);
                    }

                    return null;
                }
              }

              if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                logError(error, 'updating server');
              } else if (error.status || error.error) {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                logError(new Error(`Backend returned code ${error.status}, body was: ${error.error}`), 'updating server');
              } else {
                logError(error, `Unexpected ${typeof error} updating server`);
              }

              if (error instanceof ValidationErrorResponse) {
                this.recordValidationService.setServerErrors(record, error);
                this.recordUpdated$.next(record);
                return null;
              }
            } finally {
              this.recordPatching$.next({ patch: recordPatch, processing: false });
            }
          }
        }
      }
    }

    return null;
  }

  /**
   * Get current record value including any pending changes
   *
   * @param app
   * @param recordIdentifier
   */
  public getModifiedRecord = async (app: Application, recordIdentifier: RecordId): Promise<Record> => {
    // nb we use => because we must capture this
    const pendingPatch = this.recordQueueService.getPatch(recordIdentifier);
    if (pendingPatch && pendingPatch._new) {
      // record has not been persisted yet so just convert to a record
      return pendingPatch.toRecord(app);
    }

    const record = await app.getRecordByIdAsync(recordIdentifier);
    if (record) {
      this.updateRecord(app, record);
      return record;
    } else if (pendingPatch) {
      return pendingPatch.toRecord(app);
    } else {
      logMessage(`Record ${recordIdentifier} not found`);
      return null;
    }
  }

  public getPatch(recordIdentifier: RecordId): RecordPatch {
    return this.recordQueueService.getPatch(recordIdentifier);
  }

  /**
   * Get number of records in the queue
   */
  public async count() {
    return this.recordQueueService.getCount();
  }

  public merge(app: Application, currentRecord: Record, response: Record): Record {
    const merged = { ...currentRecord };

    app.AppFields.forEach((field) => {
      const currentValue = field.getRecordValue(currentRecord);
      const responseValue = field.getRecordValue(response);

      if (responseValue !== undefined && responseValue !== null) {
        if (currentValue === undefined || currentValue === null) {
          field.updateRecord(merged, responseValue);
        } else if (!isEqual(currentValue, responseValue)) {
          field.updateRecord(merged, responseValue);
        }
      } else {
        if (currentValue !== undefined && currentValue !== null) {
          field.updateRecord(merged, currentValue);
        }
      }
    });

    return merged;
  }

  private async promoteChanges(forceExisting = false) {
    // Grab a snapshot of entries currently in the change queue
    // We'll process them now - any that are added during processing will
    // get caught next time round
    const keys = await this.changeQueueStorageService.keys();
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const change = await this.changeQueueStorageService.getChange(key);
      if (change) {
        const patch = this.recordQueueService.getPatch(change._id);
        if (patch) {
          // we already have a patch for this record so merge
          patch.merge(change);
          patch.removeSupercededLocalChanges();
          await this.recordQueueService.saveRecordPatchAsync(patch);
        }

        // Use merged patch or change if it's the first
        const update = patch || change;
        if (forceExisting) {
          update._new = false;
        }

        await this.recordQueueService.saveRecordPatchAsync(update);

        await this.changeQueueStorageService.delete(key);

        this.patchQueued$.next(update);
      }
    }
  }

  /**
   * Get all queued changes for a record
   * @param id
   */
  private async peekChanges(id: RecordId) {
    return this.recordQueueService.getPatch(id);
  }

  private mergePending(recordPatch: RecordPatch, response: Record, app: Application): void {
    recordPatch.updateRecord(response, app);
  }

  private updateRecord(app: Application, record: Record): void {
    const pendingPatch = this.recordQueueService.getPatch(record._id);
    if (pendingPatch) {
      pendingPatch.updateRecord(record, app);
    }
  }

  private setLock() {
    this.lock = true;
  }

  private unlock() {
    this.lock = false;
  }
}
