import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckComponent } from './check.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [CheckComponent],
  exports: [CheckComponent]
})
export class SofCheckIconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheck);
  }
}
