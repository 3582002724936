import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';

@Injectable({ providedIn: 'root' })
export class ReportsRepository extends RepositoryBase {
  public getAll(appIdentifier: string, excludeHidden: boolean) {
    return this.get(this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'Reports', (excludeHidden ? '' : 'All')));
  }

  public getFields(appIdentifier: string, reportId: string) {
    return this.get(this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'Reports', reportId) + '/Fields');
  }

  public update(appIdentifier: string, reportId: number, changes: any): Promise<any> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'Reports', reportId.toString());
    return this.patch(url, JSON.stringify(changes)).toPromise();
  }

}
