import { Injectable } from '@angular/core';
import { CanActivate, Params, ActivatedRouteSnapshot } from '@angular/router';
import { APP_IDENTIFIER, CHILD_APP_IDENTIFIER } from 'app/_constants';
import { AppService } from 'app/services/app.service';
import { Enums } from '@softools/softools-core';
import { ImageListAssetService } from 'app/services/images/image-list-assets.service';

@Injectable({ providedIn: 'root' })
export class ImageListAssetsGuard implements CanActivate {

  public constructor(
    private appService: AppService,
    private imageListAssetService: ImageListAssetService,
  ) {
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const params: Params = route.params;
    const appIdentifier: string = params[CHILD_APP_IDENTIFIER] || params[APP_IDENTIFIER];
    await this.loadAppImages(appIdentifier);
    return true;
  }

  protected async loadAppImages(appIdentifier: string) {
    const app = this.appService.application(appIdentifier);

    if (app) {
      // Get all image list asset urls for this app
      const ids = new Set<string>();
      app.Fields.filter(field => field.Type === Enums.FieldType.ImageList)
        .map(field => field.ImageListIdentifier)
        .filter(id => !!id) // some fields may not specify id
        .forEach(id => ids.add(id));

      for (const id of ids) {
        await this.imageListAssetService.loadImageListBlobsAsync(id);
      }
    }
  }

}
