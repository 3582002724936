import { Injectable } from '@angular/core';
import { QueryParams, AppDataStorageService } from '@softools/softools-core';
import { DataIndex } from './app-data-index';
import { Application, AppIdentifier } from 'app/types/application';

/**
 * Creates and manages indexes for cached application data
 */
@Injectable({ providedIn: 'root' })
export class DataIndexService2 {

  private indexes = new Map<string, DataIndex>();

  public constructor(private appDataService: AppDataStorageService) { }

  /**
   * Get an index filtered and sorted according to the @see queryParams
   * @param app
   * @param queryParams
   * @param additionalFilter
   * @param hierarchy   Optional pre-filter by hierarchy value
   */
  public async getIndex(app: Application, queryParams: QueryParams, additionalFilter?: string, hierarchy?: string): Promise<DataIndex> {

    const index = this._index(app, queryParams, additionalFilter, hierarchy);

    // Allow the index to lazily updated if anything has changed
    await index.reindexChangedRecords();
    return index;
  }

  /**
   * Mark a record as modified so cached indexs can reorder to account for it.
   * @param id  modified record id
   */
  public recordChanged(id: string, appIdentifier: AppIdentifier): void {

    // Update all active indexes
    this.indexes.forEach(index => {
      if (index.app.Identifier === appIdentifier) {
        index.recordChanged(id);
      }
    });
  }

  public isIndexChanged(app: Application, queryParams: QueryParams) {
    const index = this._index(app, queryParams);
    return index.isChanged();
  }

  /**
   *
   * @param app
   * @param queryParams
   * @param additionalFilters
   */
  private _index(app: Application, queryParams: QueryParams, additionalFilter?: string, hierarchy?: string): DataIndex {

    // Assemble key - it only needs to be unique and consistent.
    // eslint-disable-next-line max-len
    let key = `${app.Identifier}|${queryParams.$groupby || ''}|${queryParams.$orderby || ''}|${hierarchy || '|'}|${queryParams.$filter || ''}`;
    if (additionalFilter) {
      key += `|${additionalFilter}`;
    }

    let index = this.indexes.get(key);
    if (!index) {
      index = new DataIndex(app, queryParams, this.appDataService, additionalFilter, hierarchy);
      this.indexes.set(key, index);
    }

    return index;
  }
}
