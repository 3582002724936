import { Directive, OnDestroy } from '@angular/core';
import { Enums, Field } from '@softools/softools-core';
import { TickApp } from 'app/vertex';
import { Observable, Subscription } from 'rxjs';

interface OwnerSub {
  subsciption: Subscription;
  owner?: any;
}

/**
 * Base class for Softools Angular components.
 *
 * Use the refresh method to safely re-render a component.  The
 * ngOnDestroy method in this base class must be called when the
 * component is destroyed.
 */
@Directive()
export class ComponentBase implements OnDestroy {

  public Enums = Enums;

  protected destroyed = false;

  private subscriptions = new Array<OwnerSub>();

  /**
   * Returns true if the field is simple enough to be injected into the DOM
   * using FieldComponent.  Fields have have nested components must be
   * added to markup statically.
   */
  public isSimpleField(field: Field) {
    switch (field.Type) {
      case Enums.FieldType.GridField:
      case Enums.FieldType.ListField:
      case Enums.FieldType.InAppChart:
        return false;
      default:
        return true;
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;

    this.subscriptions.forEach(sub => {
      sub.subsciption?.unsubscribe();
    });

    this.subscriptions.length = 0;
  }

  protected refresh(component?: ComponentBase) {
    const target = component || this;
    try {
      if (!target.destroyed) {
        TickApp.next(true);
      }
    } catch (error) {
      console.warn(error, target);
    }
  }

  /**
   * Subscribe to an observer.
   * Subscriptions made through this function are automatically released when the component
   * is destroyed.
   */
  protected subscribe<T>(obs: Observable<T>, handler: (t: T) => void) {
    if (obs) {
      const subsciption = obs.subscribe(handler);
      this.subscriptions.push({ subsciption });
    }
  }

  /**
   * Subscribe to an observer.
   * Subscriptions made through this function are automatically released when the component
   * is destroyed.
   * An owner object is specified so any subscriptions to it can be unsubscribed with a
   * call to @see unsubscribeFor
   * @param owner
   * @param obs
   * @param handler
   */
  protected subscribeFor<T>(owner: any, obs: Observable<T>, handler: (t: T) => void) {
    if (obs) {
      const subsciption = obs.subscribe(handler);
      this.subscriptions.push({ subsciption, owner });
    }
  }

  /**
   * Unsubscribe any subsriptions for the specified owner object
   * @param owner
   */
  protected unsubscribeFor(owner: any) {
    const remains: Array<OwnerSub> = [];
    this.subscriptions.forEach(sub => {
      if (sub.owner === owner) {
        sub.subsciption?.unsubscribe();
      } else {
        remains.push(sub);
      }
    });

    this.subscriptions = remains;
  }
}
