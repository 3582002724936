<div class="detail-container">
  <ng-container *ngFor="let detailsField of detailsFields">
    <div class="label-col">
      <div class="w-100 text-truncate st-text-theme-primary">
        <b>{{getLabel(detailsField)}}</b>
      </div>
    </div>
    <app-list-report-detail-field
      [appModel]="appModel"
      [application]="application"
      [appIdentifiers]="appIdentifiers"
      [fieldModel]="fieldsById.get(detailsField.FieldIdentifier)"
      [record]="record"
      [containerType]="detailsContainerType"
      [forReport]="false"
      [isFieldHidden]="isHidden"
      [editable]="inlineEdit" ></app-list-report-detail-field>
  </ng-container>
</div>
