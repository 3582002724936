import * as moment from 'moment';
import { isString, OdataExpressionType, Record } from '@softools/softools-core';
import { AppField } from './app-field';
import { DateFormat, EpochConverter } from '../epoch-converter';
import { RecordPatch } from 'app/workspace.module/types';

abstract class TemporalAppField extends AppField {

  public override compareValues(val1: any, val2: any, isDescending: boolean): number {
    const ms1: number = (val1 && val1.$date) || 0;
    const ms2: number = (val2 && val2.$date) || 0;
    return isDescending ? ms2 - ms1 : ms1 - ms2;
  }

  public override getDisplayRecordValue(record: Record, listRow?: number) {
    const value = super.getRawRecordValue(record, listRow);
    return this.formatDisplayValue(value);
  }

  public override updatePatchFromStringValue(patch: RecordPatch, value: string) {
    const epoch = EpochConverter.toEpoch(value);
    if (epoch) {
      patch.addChange(this.Identifier, epoch);
    } else {
      super.updatePatchFromStringValue(patch, value);
    }
  }

  protected formatTemporalValue(value: any, format: DateFormat) {
    if (value?.$date) {
      return EpochConverter.toDateString(value, format);
    } else if (isString(value)) {
      const when = moment.utc(value);
      if (when.isValid) {
        const epoch = { $date: when.valueOf() };
        return EpochConverter.toDateString(epoch, format);
      }
    } else if (Number.isFinite(value)) {
      return EpochConverter.toDateString({ $date: value }, format);
    }

    return value;
  }

  public override constantForFilter(value: any) {
    if (value) {
      return `datetime'${value}'`;
    } else {
      return 'null';
    }
  }

  public override filterOperations(): Array<OdataExpressionType> {
    return [
      OdataExpressionType.Equals,
      OdataExpressionType.NotEquals,
      OdataExpressionType.GreaterThan,
      OdataExpressionType.GreaterThanOrEqual,
      OdataExpressionType.LessThan,
      OdataExpressionType.LessThanOrEqual,
      OdataExpressionType.OneOf,
      OdataExpressionType.NoneOf,
      OdataExpressionType.Between,
    ];
  }

  public override filterOperationName(op: OdataExpressionType) {
    return {
      1: $localize`Is`,
      2: $localize`Is not`,
      3: $localize`Later than`,
      4: $localize`Later than or is`,
      5: $localize`Earlier than`,
      6: $localize`Earlier than or is`,
      17: $localize`One of`,
      18: $localize`None of`,
    }[op] ?? super.filterOperationName(op);
  }
}

export class DateAppField extends TemporalAppField {

  public override  formatDisplayValue(value: any) {
    return this.formatTemporalValue(value, DateFormat.ShortDate);
  }
}

export class DateTimeAppField extends TemporalAppField {

  public override  formatDisplayValue(value: any) {
    return this.formatTemporalValue(value, DateFormat.ShortDateTime);
  }

  public override filterOperations(): Array<OdataExpressionType> {
    return [
      OdataExpressionType.GreaterThan,
      OdataExpressionType.GreaterThanOrEqual,
      OdataExpressionType.LessThan,
      OdataExpressionType.LessThanOrEqual,
      OdataExpressionType.Between,
    ];
  }
}

export class PeriodAppField extends TemporalAppField {

  public override  formatDisplayValue(value: any) {
    return this.formatTemporalValue(value, DateFormat.ShortPeriod);
  }
}
