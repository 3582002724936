import { Injectable } from '@angular/core';
import { HomepagesRepository } from '../repos';
import { HomepageDashboardReport } from '../types/interfaces';
import { IRetryPolicy, NoRetryPolicy } from '../utils/retry';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, HOMEPAGE_DASH_REPORTS } from '../indexedDb';

@Injectable({ providedIn: 'root' })
export class HomePageDashboardReportStorageService extends StorageServiceBase<HomepageDashboardReport, [string, string]> {

  constructor(
    public repository: HomepagesRepository,
    dbContext: DatabaseContextService<HomepageDashboardReport>) {
    super(HOMEPAGE_DASH_REPORTS, dbContext);
  }

  public async syncHomepageDashboardReports(retryPolicy: IRetryPolicy = null): Promise<Array<HomepageDashboardReport>> {
    const policy = retryPolicy || NoRetryPolicy.instance;
    const homepageDashboardReports = await policy.execute(() => this.repository.getHomepageDashboardReports().toPromise());
    await Promise.all(homepageDashboardReports.map(async (report: HomepageDashboardReport) => {
      const key = this._getKey(report.AppIdentifier, report.Identifier);
      await this.save(key, report);
    }));

    return homepageDashboardReports;
  }

  public getHomepageDashboardReport(appIdentifier: string, reportIdentifier: string): Promise<HomepageDashboardReport> {
    const key = this._getKey(appIdentifier, reportIdentifier);
    return this.get(key);
  }

  private _getKey(appIdentifier: string, reportIdentifier: string): [string, string] {
    return [appIdentifier, reportIdentifier];
  }
}
