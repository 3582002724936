import { ReportViewParams } from '@softools/softools-core';

export const AppSearchReportIdentifierConst = 'AppSearchReport';

export const defaultReportViewParams = {
  reportId: null,
  firstRecordIndex: 0,
  pageSize: 25,
  orderBy: '',
  groupBy: '',
  showArchived: false,
  filterQuery: ''
} as ReportViewParams;
