import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaunchPadComponent } from './launchpad.component';
import { TileComponent } from './tile.component/tile.component';
import { CategoriesFilterComponent } from './categories-filter/categories-filter.component';
import { SearchModule } from '../search.component/search.module';
import { RouterModule } from '@angular/router';
import { CheckboxModule } from '../checkbox.component/checkbox.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AssetImagesModule } from '../asset-image/asset-images.module';
import { PipeModule } from 'app/pipes/pipe.module';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [
    LaunchPadComponent,
    TileComponent,
    CategoriesFilterComponent],
  imports: [
    CommonModule,
    SearchModule,
    RouterModule,
    AssetImagesModule,
    CheckboxModule,
    FontAwesomeModule,
    PipeModule,
    IconModule
  ],
  exports: [
    LaunchPadComponent
  ],
  providers: [],
})
export class LaunchPadModule { }
