import { Component, Directive, OnInit } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { ArrayModelProperty, Model } from '@softools/vertex';
import { EditableFieldBase } from 'app/softoolsui.module/fields/editable-field-base';

// Edits a multi-state field displaying all possible values with the
// current selection indicated

interface IState {
  value: string;
  classes: string;
}

class MultiStateSelectionModel extends Model<MultiStateSelectionModel> {
  public readonly states = new ArrayModelProperty<IState>();
}


@Directive()
class MultiStateSelectorFieldBase<TValue> extends EditableFieldBase<TValue> implements OnInit {

  public model = new MultiStateSelectionModel();

  override ngOnInit(): void {
    super.ngOnInit();
    this.configure();
  }

  protected configure() {

    const states: Array<IState> = [];

    switch (this.fieldModel.SubType) {
      case Enums.MultiStateType.HarveyBall: {
        states.push({ value: '0%', classes: 'harveyball empty' });
        states.push({ value: '25%', classes: 'harveyball quarter' });
        states.push({ value: '50%', classes: 'harveyball half' });
        states.push({ value: '75%', classes: 'harveyball threequarters' });
        states.push({ value: '100%', classes: 'harveyball full' });
        break;
      }

      case Enums.MultiStateType.HarveyBallTriState: {
        states.push({ value: '0%', classes: 'harveyball empty' });
        states.push({ value: '50%', classes: 'harveyball half' });
        states.push({ value: '100%', classes: 'harveyball full' });
        break;
      }

      case Enums.MultiStateType.HarveyBallTwoState: {
        states.push({ value: '0%', classes: 'harveyball empty' });
        states.push({ value: '100%', classes: 'harveyball full' });
        break;
      }

      case Enums.MultiStateType.RAG: {
        states.push({ value: 'Unset', classes: 'rag unset' });
        states.push({ value: 'Red', classes: 'rag red' });
        states.push({ value: 'Amber', classes: 'rag amber' });
        states.push({ value: 'Green', classes: 'rag green' });
        break;
      }

      case Enums.MultiStateType.RAGBB: {
        states.push({ value: 'Unset', classes: 'ragbb unset' });
        states.push({ value: 'Red', classes: 'ragbb red' });
        states.push({ value: 'Amber', classes: 'ragbb amber' });
        states.push({ value: 'Green', classes: 'ragbb green' });
        states.push({ value: 'Blue', classes: 'ragbb blue' });
        states.push({ value: 'Black', classes: 'ragbb black' });
        break;
      }
    }

    this.model.states.value = states;
  }
}


/** Single select version */
@Component({
  selector: 'app-multi-state-selector-field',
  templateUrl: './multi-state-selector-field.component.html',
  styleUrls: ['./multi-state-selector-field.component.scss']
})
export class MultiStateSelectorFieldComponent extends MultiStateSelectorFieldBase<string> {

  public clicked($event: MouseEvent, state: IState): void {
    $event.stopPropagation();
    if (!this.isDisabled()) {
      this.updateValue(state.value);
    }
  }

  public isSelected(state: IState): boolean {
    return state.value === this.value;
  }
}

/** Multi-select version, clicking a state toggles it */

@Component({
  selector: 'app-multi-state-selector-field',
  templateUrl: './multi-state-selector-field.component.html',
  styleUrls: ['./multi-state-selector-field.component.scss']
})
export class MultiStateMultiSelectorFieldComponent extends MultiStateSelectorFieldBase<Array<string>> {

  public clicked($event: MouseEvent, state: IState): void {
    $event.stopPropagation();
    if (!this.isDisabled()) {
      const current = this.value ?? [];
      const index = current.findIndex(s => s === state.value);
      if (index < 0) {
        current.push(state.value);
      } else {
        current.splice(index, 1);
      }
      this.updateValue(current);
    }
  }

  public isSelected(state: IState): boolean {
    return this.value?.includes(state.value);
  }
}
