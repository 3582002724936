import { InjectionToken } from '@angular/core';
import { LookupOptions, Record } from '@softools/softools-core';
import { SelectedRecords } from '../record-selector/record-selector.component';

export interface ILookupDialog {

  /**
   * Lookup records using popup record selector dialog.
   * The response is an array of record (at most one if the multi select option is not set)
   * or null if the selector is cancelled.
   *
   * @param options
   */
  lookup(options: LookupOptions): Promise<SelectedRecords>;
}

export const lookupServiceToken = new InjectionToken<ILookupDialog>('LookupDialogService');
