import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { HeaderSummaryExpression } from '@softools/softools-core';
import { HeaderReportFieldModel } from '../listreport.component/listreport.component';

@Component({
  selector: 'app-summary-expression',
  templateUrl: './summary-expression.component.html',
  styleUrls: ['./summary-expression.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryExpressionComponent {

  @Input() appIdentifier: string;
  @Input() fieldIdentifier: string;
  @Input() headerSummaryExpressions: Array<HeaderSummaryExpression>;
  @Input() headerFields = [] as Array<HeaderReportFieldModel>;
  @Input() groupFieldIdentifier: string;
  @Input() groupValue: string | object;

  constructor() { }

  public getSummaryExpression(appIdentifier: string, fieldIdentifier: string, summaryExpression: string) {

    const hse = this.headerSummaryExpressions && this.headerSummaryExpressions.find(h => h.expression.appIdentifier === appIdentifier
      && h.expression.fieldIdentifier === fieldIdentifier && h.expression.summaryExpression === summaryExpression
      && h.expression.groupFieldIdentifier === this.groupFieldIdentifier && (h.expression.groupValue === this.groupValue || (h.expression.groupValue?.valueOf().hasOwnProperty('$date') && h.expression.groupValue['$date'] === this.groupValue['$date'])));

    if (hse) {
      return hse.result;
    }

    return '-';
  }


  public headerItem(identifier: string) {
    return this.headerFields.find(h => h.FieldIdentifier === identifier);
  }
}
