import { Form, IFilterTerm, IndexedAppData, LastListReportStorageService, logError, Record, RecordId, ReportViewParams, ReportViewService, SearchTermStorageService } from '@softools/softools-core';
import { ArrayModelProperty, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { AppIdentifier, Application, IGetIndexOptions } from 'app/types/application';
import { combineLatest } from 'rxjs';
import { ChildRecordFolderController, RecordFolderController } from '../folders/record-folder.controller';
import { RouteParams } from '../global.model';
import { IFocusableModel } from '../page/focusable-model.interface';
import { RecordModel } from './record-model';
import { WorkflowTriggerService } from 'app/services/workflow-trigger.service';
import { ReportFilter } from 'app/filters/types';
import { DataIndexService2 } from 'app/services/indexes/data-index-service';
import { FilterTerm } from 'app/filters/types/filter-term';
import { FilterTerms } from 'app/filters/types/filter-terms';

export interface IRecordContext {
  record: Record;
  form: Form;
}

interface IIndexData {
  appIdentifier: AppIdentifier;
  hierarchy: string;
  index: IndexedAppData;
}

/**
 * Model for the state of the record update page.
 */
export class RecordUpdateModel extends RecordModel implements IFocusableModel {

  public folderController: RecordFolderController;

  /** Current form */
  public readonly form = new ModelProperty<Form>(this).withLogging('Form');

  /** Toolbar actions for the main (ellipsis) menu */
  public readonly toolbarActions = new ModelProperty<Array<ToolbarAction>>(this).withLogging('TOOLBAR ACTIONS');

  public readonly formIdentifier = new ProjectionModelPoperty<Form, string>(this.form, form => form?.Identifier);

  public readonly formReadOnly = new ProjectionModelPoperty<Form, boolean>(this.form, form => form?.ReadOnly);

  public readonly expandedFormTemplates = new ArrayModelProperty<string>();

  public readonly expandedFormTemplateHelp = new ArrayModelProperty<string>();

  /** Context info for current record view */
  public readonly context = new ModelProperty<IRecordContext>(this).withLogging('URL');

  /** Index info used for navigating records */
  public readonly indexData = new ModelProperty<IIndexData>(this).withLogging('RECORD INDEX');

  public readonly index = new ProjectionModelPoperty<IIndexData, IndexedAppData>(this.indexData, data => data?.index);

  @InjectService(RecordQueueService)
  private readonly recordQueueService: RecordQueueService;

  @InjectService(WorkflowTriggerService)
  private readonly workflowTriggerService: WorkflowTriggerService;

  @InjectService(LastListReportStorageService)
  private readonly lastListReportStorageService: LastListReportStorageService;

  @InjectService(ReportViewService)
  private readonly reportViewService: ReportViewService;

  @InjectService(DataIndexService2)
  private readonly dataIndexService: DataIndexService2;

  @InjectService(SearchTermStorageService)
  private readonly searchTermStorageService: SearchTermStorageService;

  /**
   * Configure the model from a set of route parameters
   * @param params
   */
  public async routed(params: RouteParams) {

    try {
      this.busy.setLoading(true);

      this.pageModel.focus.value = this;

      // Load data - don't await as this can be time consuming
      // Initialisation that requires the record should observe the record
      // property and do so when it changes
      this.applyRouteAsync(params);

    } catch (e) {
      logError(e, 'routed');
      this.busy.setLoading(false);
    }
  }

  public override dispose(): void {
    super.dispose();

    this.folderController?.dispose();
    this.folderController = null;
  }

  protected override observeProperties() {

    super.observeProperties();

    const header = this.globalModel.header;
    this.subscribe(header.back.$, () => {
      this.goBack().catch(error => logError(error, 'apphome back'));
    });


    // Update folder panel when record selected
    this.subscribe(this.recordId.$, async (id) => {
      if (id) {
        this.loadFolderController();
      }
    });

    // Update form visibility state when 
    this.subscribe(this.record.$, async (record) => {
      if (record) {
        await this.checkCurrentFormIsVisible();
      }
    });

    this.subscribe(this.form.$, () => this.formSelected());
    this.subscribe(this.recordId.$, () => this.recordSelected());

    // Update header when record/form changes
    this.subscribe(combineLatest([
      this.record.$,
      this.form.$
    ]), async () => {
      await this.setHeaderModel();
    });

    this.subscribe(this.appModel.siteModel.onRecordChangeDetected.$, (change) => {
      const app = this.appModel.app.value;
      if (app.Identifier === change.appIdentifier && !app.isOfflineApp && this.recordId.value === change.id) {
        this.loadRecord(change.id);
      }
    });

    this.subscribe(this.workflowTriggerService.workflowStatusUpdate$, async (run) => {
      if (run.recordId === this.recordId.value) {
        this.busy.setWorkflowRunning(run.running);

        if (!run.running) {
          // reload record
          this.reload().catch(error => logError(error, 'reload failed'));
          this.globalModel.showInfoToasty({ message: $localize`Workflow run completed` });
        }
      }
    });
  }

  public titleFieldText(): string {
    const app = this.appModel.app.value;
    const record = this.record.value;
    if (app.TitleFieldIdentifier && record) {
      const field = app.getField(app.TitleFieldIdentifier);
      return field?.getDisplayRecordValue(record);
    }

    return '';
  }

  public override async loadRecord(id: string): Promise<void> {

    // Clear out old folder controller if we're changing records
    // We need to do this as we might switch between parent & child app without reloading the component
    if (this.record.value?._id !== id) {
      this.folderController?.dispose();
    }

    const record = await super.loadRecord(id);
    this.updateContext();
    return record;
  }

  public async selectForm(form: Form, checkVisible = true) {
    // Check whether requested form is visible; if not select the
    // first visible one instead
    if (checkVisible) {
      form = await this.enforceVisibleForm(form);
    }

    this.form.value = form;
    this.updateContext();
  }

  private recordSelected() {
    this.rebuildExpandedTemplates();
  }

  protected override recordCreated(record: Record) {
    this.indexData.value?.index?.onRecordCreated(record);
  }


  public async selectFormById(id: string) {

    const form = this.appModel.app.value.Forms.find(f => f.Identifier === id);
    if (form) {
      await this.selectForm(form);
    } else {
      // default it?
    }
  }

  private async checkCurrentFormIsVisible() {
    const form = this.form.value;
    const visble = await this.enforceVisibleForm(form);
    if (!form || visble?.Identifier !== form.Identifier) {
      await this.selectForm(visble);
    }
  }

  private formSelected() {
    const form = this.form.value;
    if (form && !form.RetainTemplateCollapsedState) {
      // Reset expanded templates to defaults
      // If the RetainTemplateCollapsedState flag is set we only do this if we're changing
      // form, doing a full reload etc.
      // Not quite right as closing all templates will revert to default
      // Currently no way to set RetainTemplateCollapsedState so we always reset
      // todo probably fixed now, review comment
      this.rebuildExpandedTemplates();
    }
  }

  private async enforceVisibleForm(form: Form) {
    const record = this.record.value;
    if (record) {
      const visibleForms = await this.appModel.app.value?.getVisibleForms(record, record._new);
      if (visibleForms?.length > 0) {
        // If form specified and visible, use it
        if (form && visibleForms.find(f => f.Identifier === form.Identifier)) {
          return form;
        }

        // Otherwise use first visible form
        return visibleForms[0];
      }
    }

    // No context so use what we're given
    return form;
  }

  public toggleTemplateExpansion(templateId: string) {
    const expanded = this.expandedFormTemplates.value;
    const index = expanded.findIndex(id => id === templateId);
    if (index < 0) {
      this.expandedFormTemplates.push(templateId);
    } else {
      this.expandedFormTemplates.splice(index, 1);
    }
  }

  public toggleTemplateHelpExpandion(templateId: string) {
    const expanded = this.expandedFormTemplateHelp.value;
    const index = expanded.findIndex(id => id === templateId);
    if (index < 0) {
      this.expandedFormTemplateHelp.push(templateId);
    } else {
      this.expandedFormTemplateHelp.splice(index, 1);
    }
  }

  public override selectRecord(record: Record) {
    super.selectRecord(record);

    this.setRecordReport().catch(error => logError(error, 'setRecordReport sub'));
    this.recordValidationService.validateRecord(this.record.value, this.appModel.app.value.AppFields);
  }

  private async applyRouteAsync(params: RouteParams) {
    try {
      await this.context.batchAsync(async () => {
        if (params.formIdentifier) {
          await this.selectFormById(params.formIdentifier);
        } else {
          const form = this.appModel.app.value.Forms?.[0];
          await this.selectForm(form);
        }
      });

      await this.loadRecord(params.recordIdentifier);

    } catch (error) {
      logError(error, 'loading')
    } finally {
      this.busy.setLoading(false);
    }
  }

  /**
   * Set the report backing up the record view.
   * This is done when a record is selected as we need to extract the hierarchy, but we
   * only regenerate the index when the app or hierarchy changes.
   * This is only for offline apps - cuurently online has record navigation disabled
   */
  private async setRecordReport() {

    const app = this.appModel.app.value;
    const record = this.record.value;

    if (app && record && !app.IsSingletonApp) {
      const hierarchy = this.hierarchy.value;
      const current = this.indexData.value;
      const changed = !current || current.appIdentifier !== app.Identifier || current.hierarchy !== hierarchy;

      if (changed) {
        // Get last used report from storage; fall back to default
        const reportIdentifier =
          (await this.lastListReportStorageService.getLastRecordsReportAsync(app.Identifier)) ||
          app.preferredListReport()?.Identifier;

        const searchTerm = reportIdentifier ?
          await this.searchTermStorageService.getSearchTermAsync(app.Identifier, reportIdentifier, hierarchy || '') :
          null;

        const filter = await this._filterForReport(app, reportIdentifier);
        const options: IGetIndexOptions = { hierarchy, searchTerm, filter, archived: this.globalModel.archived.value };
        const index = await app.getIndex(reportIdentifier, options);
        if (index) {
          this.indexData.value = {
            appIdentifier: app.Identifier,
            hierarchy: hierarchy,
            index: index
          };
        } else {
          this.indexData.value = null;
        }
      }
    } else {
      this.indexData.value = null;
    }
  }

  private convertAdvanced(advanced: Array<IFilterTerm>): ReportFilter {
    const reportFilter = new ReportFilter();
    if (advanced && this.appModel.app.value) {
      const appFields = this.appModel.app.value.AppFields;
      const filterString = advanced
        .filter(term => term.Operator && (term.Operand !== undefined))
        .map(term => new FilterTerm(term).format(appFields))
        .join(' and ');

      reportFilter.filterString = filterString;
    }

    return reportFilter;
  }

  private async _filterForReport(app: Application, reportIdentifier: string): Promise<ReportFilter> {
    if (app && reportIdentifier) {
      const filters: Array<ReportFilter> = [];

      const report = app.Reports.find((rep) => rep.Identifier === reportIdentifier);

      if (app.isFilterAdvanced) {
        if (report.Filter) {
          const staticFilter = FilterTerms.combineTerms(app, report.Filter, 'and');
          filters.push(staticFilter);
        }
      } else {
        // Apply any base filter string
        if (report?.BaseFilter) {
          const baseFilter = new ReportFilter();
          baseFilter.filterString = report.BaseFilter;
          filters.push(baseFilter);
        }

        // Apply any base saved filter
        if (report?.BaseFilterId) {
          const baseSaved = this.appModel.savedFilters.value?.find(f => f.Id === report.BaseFilterId);
          if (baseSaved) {
            filters.push(ReportFilter.fromSavedFilter(baseSaved));
          }
        }
      }

      // Apply any current view filter
      const reportViewData: ReportViewParams = await this.reportViewService.getViewDataAsync(app.Identifier, reportIdentifier);
      if (reportViewData) {
        if (app.isFilterAdvanced) {
          const viewFilter = this.convertAdvanced(reportViewData.advancedFilter);
          viewFilter.OrderBy = reportViewData.orderBy;
          viewFilter.IsOrderDescending = reportViewData.orderDescending;
          viewFilter.Group = reportViewData.groupBy;
          viewFilter.IsGroupDescending = reportViewData.groupDescending;
          filters.push(viewFilter);
        } else {
          const viewFilter = ReportFilter.fromReportViewParams(reportViewData);
          filters.push(viewFilter);
        }
      }

      // return filter, merging if more than one found
      switch (filters.length) {
        case 0:
          return null;
        case 1:
          return filters[0];
        default:
          return ReportFilter.merge(filters);
      }
    }

    return null;
  }

  private async setHeaderModel() {
    try {
      const header = this.globalModel.header;
      const app = this.appModel.app.value;
      if (app) {
        const parts: Array<string> = [];
        const parentApp = this.appModel.appContext.parentApp.value;
        if (parentApp) {

          let parentRecordTitle = '';
          const hierarchy = this.record.value?.Hierarchy;
          if (hierarchy?.includes('|')) {
            const [, parentRecordId] = hierarchy.split('|');
            parentRecordTitle = await this.recordTitle(parentApp, parentRecordId);
          }

          if (parentRecordTitle) {
            parts.push(parentRecordTitle);
          }
        }

        parts.push(app.Title);

        const title = this.titleFieldText();
        if (title) {
          parts.push(title);
        }

        if (this.form.value && (!parentApp || (!title && parentApp))) {
          parts.push(this.form.value.Title);
        }

        const text = parts.join(' > ');
        header.batch(() => {
          header.app.value = app;
          header.title.value = text;
          header.showBackButton.value = !this.appModel.app.value.IsSingletonApp;
          header.loading.value = false;
        });
      }
    } catch (error) {
      logError(error, 'RecordModel setHeaderModel');
    }
  }

  private async recordTitle(app: Application, recordIdentifier: RecordId): Promise<string> {
    if (app && recordIdentifier && app.TitleFieldIdentifier) {
      const record = await app.getRecordByIdAsync(recordIdentifier);
      if (record) {
        const titleField = app.getField(app.TitleFieldIdentifier);
        if (titleField) {
          const recordTitleFieldText = titleField.getRecordValue(record);
          const patch = this.recordQueueService.getPatch(record._id);
          const changeRecordTitleFieldText = patch?.getChange(app.TitleFieldIdentifier);
          return changeRecordTitleFieldText || recordTitleFieldText;
        }
      }
    }

    return '';
  }

  private rebuildExpandedTemplates() {
    const form = this.form.value;
    const expandedFormTemplates = [] as Array<string>;
    form?.FormTemplates?.forEach((formTemplate) => {
      if (!formTemplate.CollapsedByDefault) {
        expandedFormTemplates.push(formTemplate.TemplateIdentifier);
      }
    });

    this.expandedFormTemplates.value = expandedFormTemplates;
    this.expandedFormTemplateHelp.value = [];
  }

  private updateContext() {
    this.context.value = { form: this.form.value, record: this.record.value };
  }

  private async goBack() {
    if (this.hierarchy.value) {
      await this.globalModel.navigation.navigateReportAsync({
        appIdentifiers: this.appModel.appContext.appIdentifiers.value,
        parentRecordId: this.hierarchy.parentRecordId.value
      });
    } else {
      const app = this.appModel.app.value;
      const report = app.getDestinationReport();
      if (report) {
        await this.globalModel.navigation.navigateReportAsync({
          appIdentifier: this.appModel.identifier.value,
          report: report
        });
      }
    }
  }

  private loadFolderController() {
    // Load folder controller.
    if (this.appModel.parentApp.value) {
      this.folderController = new ChildRecordFolderController(this);
    } else {
      this.folderController = new RecordFolderController(this);
    }
    this.folderController.initialise();
  }
}
