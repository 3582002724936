import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { EditableFieldBase, FieldContext } from 'app/softoolsui.module/fields';
import { Record, Enums, logError } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'sof-multi-state-field',
  templateUrl: './multi-state-field.component.html',
  styleUrls: ['./multi-state-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiStateField2Component extends EditableFieldBase implements OnInit, OnChanges {

  @ViewChild('container', { static: false }) private container: ElementRef<HTMLDivElement>;

  @ViewChild('multistate') multistate: ElementRef;

  @Input() override fieldModel: AppField;
  @Input() override record: Record;

  public subject = new Subject();
  public className = '';
  public className$ = new BehaviorSubject<string>('');
  public multiStateType = '';
  public multiStateTypes = Enums.MultiStateType;
  public hasFocus = false;

  private _value;

  constructor() {
    super();
  }

  override ngOnInit() {

    super.ngOnInit();

    this.setType();

    this.subscribe(this.subject.pipe(debounceTime(250)), (value) => {
      if (value) {
        this.dispatchChangeAsync(value).catch(e => logError(e, 'Failed to dispatch change'));
      }
    });
  }

  override ngOnChanges(changes: SimpleChanges): void {

    super.ngOnChanges(changes);

    if (this.hasFocus) {
      return;
    }
  }

  protected override onValueChanged(value: any) {
    super.onValueChanged(value);
    this._value = this.value?.val ?? this.value;
    this.setClassName();
  }

  public override initialise(context: FieldContext) {
    super.initialise(context);
    this.setType();
    this._value = this.value?.val ?? this.value;
    this.setClassName();
  }

  private setType() {
    if (this.fieldModel.SubType !== undefined && this.fieldModel.SubType !== null) {
      this.multiStateType = this.multiStateTypes[this.fieldModel.SubType].toLowerCase();
    }
  }

  public onClickHandler($event: Event) {

    this.componentClicked$.next(this);

    if (!this.getIsDisabled() && this.editMode) {
      $event.stopPropagation();

      const updated = this.getNewMultiStateValue(this._value, this.fieldModel.SubType);
      this._value = updated;

      this.className = this._getMultiStateClassName(this._value, this.fieldModel.SubType);
      this.multistate.nativeElement.className = `multistate ${this.className}`;

      this.subject.next(updated);

      this.updateValue(updated);
    }
  }

  public override async activate() {
    this.container?.nativeElement?.focus();
  }

  public override onKeyPress($event: KeyboardEvent) {

    if ($event.code === 'Space') {
      this.onClickHandler($event);
      return true;
    }

    return super.onKeyPress($event);
  }

  private _getMultiStateClassName(value: string, fieldTypeEnum: number): string {

    let className = '';

    const subTypeName = this.multiStateTypes[fieldTypeEnum];
    const fieldType = subTypeName && subTypeName.toLowerCase();

    if (value) {
      value = value.toLowerCase();
    }

    switch (value) {
      case 'unset':
        className = 'unset';
        break;
      case 'green':
        className = 'green';
        break;
      case 'amber':
        className = 'amber';
        break;
      case 'red':
        className = 'red';
        break;
      case 'blue':
        className = 'blue';
        break;
      case 'black':
        className = 'black';
        break;
      case '0%':
        className = 'empty';
        break;
      case '25%':
        className = 'quarter';
        break;
      case '50%':
        className = 'half';
        break;
      case '75%':
        className = 'threequarters';
        break;
      case '100%':
        className = 'full';
        break;
      default:
        switch (fieldType) {
          case 'harveyball':
          case 'harveyballtristate':
            className = 'empty';
            break;
          case 'rag':
          case 'ragbb':
          default:
            className = 'unset';
            break;
        }

        break;
    }
    return this.multiStateType + ' ' + className;
  }

  private getNewMultiStateValue(value: string, fieldTypeEnum: number) {
    const fieldType = this.multiStateTypes[fieldTypeEnum].toLowerCase();
    let fieldValue = '';

    if (value) {
      value = value.toLowerCase();
    }

    switch (value) {
      case 'unset':
        fieldValue = 'Green';
        break;
      case 'green':
        fieldValue = 'Amber';
        break;
      case 'amber':
        fieldValue = 'Red';
        break;
      case 'red':
        if (fieldType === 'rag') {
          fieldValue = 'Unset';
        } else if (fieldType === 'ragbb') {
          fieldValue = 'Blue';
        }
        break;
      case 'blue':
        fieldValue = 'Black';
        break;
      case 'black':
        fieldValue = 'Unset';
        break;
      case '0%':
        switch (fieldType) {
          case 'harveyballtristate':
            fieldValue = '50%';
            break;
          case 'harveyballtwostate':
            fieldValue = '100%';
            break;
          default:
            fieldValue = '25%';
            break;
        }
        break;
      case '25%':
        fieldValue = '50%';
        break;
      case '50%':
        switch (fieldType) {
          case 'harveyballtristate':
            fieldValue = '100%';
            break;
          default:
            fieldValue = '75%';
            break;
        }
        break;
      case '75%':
        fieldValue = '100%';
        break;
      case '100%':
        fieldValue = '0%';
        break;
      default:
        switch (fieldType) {
          case 'ragbb':
            fieldValue = 'Green';
            break;
          case 'harveyball':
            fieldValue = '25%';
            break;
          case 'harveyballtwostate':
            fieldValue = '100%';
            break;
          case 'harveyballtristate':
            fieldValue = '50%';
            break;
          case 'rag':
            fieldValue = 'Green';
            break;
        }
        break;
    }
    return fieldValue;
  }

  private setValue() {
    if (!this._value || (this._value && this._value.length < 0)) {
      if (this.multiStateType === Enums.MultiStateType[0].toLowerCase() || this.multiStateType === Enums.MultiStateType[1].toLowerCase()) {
        this._value = 'unset';
      } else if (this.multiStateType === Enums.MultiStateType[2].toLowerCase() || this.multiStateType === Enums.MultiStateType[3].toLowerCase() || this.multiStateType === Enums.MultiStateType[4].toLowerCase()) {
        this._value = '0%';
      }
    }
  }

  private setClassName() {
    this.setValue();
    if (this._value && (typeof this._value === 'string' || this._value instanceof String)) {
      const multiStateClassName = this._getMultiStateClassName(this._value.toLowerCase(), this.fieldModel.SubType);
      this.className = `${this.multiStateType} ${multiStateClassName}`;
      this.className$.next(this.className);
    }
  }
}
