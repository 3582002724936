
import { Component, Input, Output, EventEmitter, AfterViewInit, Renderer2, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SearchComponent } from '../search.component/search.component';

@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchAutoCompleteComponent implements AfterViewInit {

  @ViewChild('searchEl') searchEl: SearchComponent;

  @Input() options = [];
  @Input() searchPending = false;
  @Input() hasFooterAction = false;
  @Input() appIdentifier = '';
  @Input() searchText = '';
  @Input() footerActionImgSrc = '';

  @Output() onSearch = new EventEmitter<{ event: KeyboardEvent, value: string }>();
  @Output() onSearchEnter = new EventEmitter<string>();
  @Output() onSearchEscape = new EventEmitter<any>();
  @Output() onOptionSelected = new EventEmitter<{ text: string, value: string }>();
  @Output() onFooterActionClick = new EventEmitter<string>();

  constructor(
    private _render2: Renderer2,
    private _breakpointObserver: BreakpointObserver
  ) { }

  public onSearchEnterHandler(value: string): void {
    if (this.options.length > 0) {
      this.onSearchEnter.emit(value);
    }
  }

  public ngAfterViewInit(): void {
    this.focusSearchInput();
  }

  public onBackdropClickHandler($event: KeyboardEvent): void {
    this.onSearch.emit({ event: $event, value: '' });
    this.focusSearchInput(true);
  }

  public onFooterActionClickHandler(): void {
    this.onFooterActionClick.emit(this._getSearchInput().value);
  }

  public focusSearchInput(forceFocus: boolean = false): void {
    const shouldFocusOnSearch = this._breakpointObserver.isMatched([Breakpoints.Web, Breakpoints.Tablet]);
    if (forceFocus || shouldFocusOnSearch) {
      this._render2.selectRootElement(this._getSearchInput()).focus();
    }
  }

  public getOptionsHeight(): string {
    if (this.options.length <= 1) {
      return '2.5rem';
    } else if (this.options.length <= 2) {
      return '5rem';
    } else if (this.options.length <= 3) {
      return '7.5rem';
    }

    return '10rem';
  }

  private _getSearchInput(): HTMLInputElement {
    return this.searchEl.searchInput['nativeElement'];
  }

}
