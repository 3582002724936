<section class="report-grid-container">

  <ng-container *ngIf="!(reportModel.loadError.$| async)">

    <div [vxWatch]="reportModel.editMode" class="report-controls-area">
      <app-ws-sharedreportactions
        [app]="currentApp"
        [filterModel]="filterModel"
        [reportModel]="reportModel"
        [isLoading]="reportModel.busy.busy$|async"
        [filter]="filterModel.reportFilter.$|async"
        [baseFilter]="(reportModel.report.$|async)?.BaseFilter"
        [showFilterManagement]="this"
        [isArchived]="globalModel.archived.$|async"
        [showInlineEditSwitch]="reportModel.isInlineEditSupported()"
        [isEditMode]="reportModel.editMode.value"
        [recordCount]="reportModel.totalCount.$|async"
        [showSelected]="true"
        [isReportOption]="true"
        [isAutoLayout]="listReportModel.autoLayout.$|async"
        [selectedCount]="reportModel.selectedCount.$|async"
        [allRecordsSelected]="reportModel.selectionModel.all.$|async"
        [isSearchable]="appSearchable"
        [searchText]="reportModel.searchTerm.$|async"
        [subtitle]="(reportModel.report.$|async)?.SubTitle"
        (onArchivedRecordsBadgeClickHandler)="onArchivedRecordsBadgeClickHandler()"
        (onDeselectAllHandler)="this.reportModel.selectionModel.deselectAll()"
        (onSearchEnterClickHandler)="onSearchEnterClickHandler($event)"
        (onSearchEscClickHandler)="onSearchEscClickHandler()"
        (onEditModeHandler)="toggleEditMode()"
        (onToggleReportOptions)="listReportModel.toggleAutoLayout()">
      </app-ws-sharedreportactions>
    </div>

    <app-folders #leftAside
      class="left-aside-area"
      [folderModel]="pageModel.folderModel"
      [selectedFolderIdentifier]="reportModel.reportIdentifier.$|async"
      [parentAppIdentifier]="reportModel?.parentAppIdentifier.$|async"
      [parentRecordIdentifier]="reportModel?.parentRecordId.$|async"
      [title]="currentApp?.Title"
      [appIdentifiers]="appIdentifiers"
      [showAddNewRecord]="canCreate"
      [appIdentifier]="appIdentifier"
      [pinnedStateVal]="showAside$ | async">
    </app-folders>


    <div class="main-content-area report-content" cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin" >
      <app-busy-indicator  *ngIf="reportModel.busy.busyDelayed$|async"
        [busyModel]="reportModel.busy" >
      </app-busy-indicator>

      <article #scroller *ngIf="(isOfflineReport$|async) || (appModel.globalModel.online.$|async); else reportoffline"
        class="reports report-article h-100" [hidden]="reportModel.busy.busyDelayed$|async">
        <ng-container>
          <app-template *ngIf="listReportModel.headerTemplate.$|async as ht"
            class="header-template"
            [appModel]="appModel"
            [appIdentifiers]="appIdentifiers"
            [application]="currentApp"
            [template]="ht"
            [hideHeader]="(this.reportModel.report.$|async)?.HeaderTemplate?.HideHeader"
          >
          </app-template>

          <div [vxWatch]="reportModel.editMode" class="primary-report"  [ngClass]="listReportModel.dualReportStyle.$|async">
            <app-listreport
              cdkOverlayOrigin
              #rightAsideContainer="cdkOverlayOrigin"
              [appModel]="appModel"
              [commentsModel]="commentsModel"
              [attachmentsModel]="attachmentsModel"
              [reportModel]="listReportModel"
              [filterModel]="filterModel"
              [application]="appModel.app.$|async"
              [scroller]=scroller
              [showColumn]="showColumn"
              [hideColumn]="hideColumn"
              [editMode]="reportModel.editMode.value"
              [detailsFields]="listReportModel.listReportDetailFields.$|async"
              [showDetailsField]="listReportModel.showDetailFields.$|async"
              [allowMultiSelect]="listReportModel.allowMultiSelect.$|async"
              [fields]="(appModel.app.$|async)?.AppFields"
              [parentRecordId]="appModel.appContext.parentRecordId.value"
              [reportIdentifier]="reportModel.reportIdentifier.$|async"
              [enableAutoLayout]="listReportModel.autoLayout.$ | async"
              [autoLayoutOffByDefault]="(reportModel.report.$|async)?.AutoLayoutOffByDefault"
              [headerSummaryExpressions]="listReportModel.summaryExpressions.$|async"
              [showFilterManagement]="this"
              (onRecordClick)="onRecordClickHandler($event)"
              (onFieldUpdate)="$event.fieldIsValid ? onFieldUpdateHandler($event) : null"
              (onToggleAutoLayoutClick)="onToggleAutoLayoutHandler()"
              (onReportEmbeddedVideoOpen)="onReportEmbeddedVideoOpenHandler($event)">
            </app-listreport>
          </div>

          <!-- Currently don't support secondary reprots on list reports.  This would need reworking -->
          <!-- <div *ngIf="isDual.$" class="secondary-report"  [ngClass]="dualReportStyle$|async">
            <ng-container *ngIf="dualReportModel$|async as model">
              <app-chart *ngIf="true || ( model.chartConfig?.value || model.chartError?.value)"
                class="h-100"
                [chartReportModel]="model"
                cdkOverlayOrigin #rightAsideContainer="cdkOverlayOrigin"
                [chartContainerId]="'chart_' + model.report.value?.Id"
                [displayChart]="model.displayChart"
                [displayMatrixTable]="model.displayMatrixTable"
                [chartConfig]='model.chartConfig.$ | async'
                [matrixModel]="model.matrixModel.$ | async"
                [chartData]="model.chartData.$ | async"
                [chartType]='model.report.value?.Chart?.ChartType'
                [reportType]='model.report.value?.Type'
                [appIdentifier]='(appModel.app.$|async)?.Identifier'
                [appIdentifiers]='appModel.appIdentifiers.$|async'
                [isArchived]='globalModel.archived.$|async'
                [parentRecordId]="reportModel?.parentRecordId.$|async"
                [chartingError]="model.chartError.value"
              >
              </app-chart>
            </ng-container>
          </div> -->
        </ng-container>
        </article>
    </div>

    <div class="footer-area" *ngIf="(isOfflineReport$|async) || (appModel.globalModel.online.$|async)">
      <app-shared-footer
      [appModel]="appModel"
      [attachmentsModel]="attachmentsModel"
      [commentsModel]="commentsModel"
      [appIdentifier]="appIdentifier"
      [showAside]="showAside$|async"
      [isOnline]="appModel.globalModel.online.$|async"
      [showFooter]="showFooter"
      [attachmentsOrigin]="articleOrigin"
      >

      <app-list-report-paginator *ngIf="showFooter && !isSingletonApp  && (this.reportModel.records.$ | async)?.length > 0"
        [reportModel]="listReportModel"
        [isBusy]="reportModel.accessor?.countBusy$|async"
        [count]="(reportModel.totalCount.$|async)"
        [skip]="reportModel.topIndex.$|async"
        [top]="reportModel.pageSize.$|async"
      >
      </app-list-report-paginator>
    </app-shared-footer>
    </div>

    <div [@quickFilters]="quickFilterOpenState$|async" class="quick-filters">
      <app-quick-filter *ngIf="isQuickFilterVisible$|async"
        [reportModel]="reportModel"
        [filterModel]="filterModel"
        [reportController]="reportController"
        (close)="quickFilterClosed()"
        (showSaved)="quickToFilterManagement()"
      ></app-quick-filter>
    </div>

    <div [@filterMgt]="filterManageOpenState$|async" class="saved-filters">
      <app-filter-management-panel *ngIf="isFilterManagementPanelVisible$|async"
          [appModel]="appModel"
        [filterModel]="filterModel"
        [showFilterManagement]="this"
        [isOnline]="appModel.globalModel.online.$|async"
        [selectedFilter]="filterModel.reportFilter.$|async"
        [appIdentifier]="(appModel.app.$|async)?.Identifier"
        [appFields]="(appModel.app.$|async)?.AppFields  "
        [report]="reportModel.report.$|async"
        [reportFields]="(reportModel.report.$|async)?.ListReportFields"
        [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
      </app-filter-management-panel>
    </div>


  </ng-container>
</section>

<app-popup-container
  [isOpen]="(reportModel.filterEditorUi.$|async)?.showPopup"
  [connectedOrigin]="(reportModel.filterEditorUi.$|async)?.popupOrigin"
  [connectedOffsetX]="(reportModel.filterEditorUi.$|async)?.popupX"
  [connectedOffsetY]="(reportModel.filterEditorUi.$|async)?.popupY"
  (closed)="filterEditCancelled()">
  <ng-template>
    <ng-container *ngIf="(appModel.app.$|async)?.isFilterAdvanced; then quickFilterPopup; else legacyFilter"></ng-container>
  </ng-template>
</app-popup-container>

<ng-template #legacyFilter>
  <sof-filter-edit
    [filterModel]="filterModel"
    [reportModel]="reportModel"
    [filterSpec]="(reportModel.filterEditorUi.$|async)?.editFilterSpec"
    [filterEditorUi]="reportModel.filterEditorUi.$|async"
    [appIdentifier]="appIdentifier"
    (termUpdated)="filterTermUpdated($event)"
    (cancelled)="filterEditCancelled()">
  </sof-filter-edit>
</ng-template>

<ng-template #quickFilterPopup>
  <app-sort-popup
    [filterModel]="filterModel"
    [filterEditorUi]="reportModel.filterEditorUi.$|async"
    (termUpdated)="filterTermUpdated($event)"
  ></app-sort-popup>
</ng-template>

<div class="new-rec-container" [class.quick-open]="isQuickFilterVisible$|async">
  <app-new-record-button [canCreate]="canCreate" [createUrl]="createUrl" [floater]="false"></app-new-record-button>
</div>

<ng-template #reportoffline>
  <app-report-offline></app-report-offline>
</ng-template>
