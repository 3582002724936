
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { BaseGuard } from './base.guard';
import { HomepagesService } from 'app/services/homepages.service';
import { InjectService } from 'app/services/locator.service';

@Injectable()
export class HomeGuard extends BaseGuard implements CanActivate {

  @InjectService(HomepagesService)
  private homepagesService: HomepagesService;

  public async canActivate(): Promise<boolean | UrlTree> {

    const homepages = await this.homepagesService.refresh();
    if (homepages) {
      return true;
    }

    return false;
  }

}
