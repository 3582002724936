import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatrixReportComponent } from '.';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofSpinnerModule } from '@softools/design/icons';
import { MatrixModule } from '../matrix';

@NgModule({
  declarations: [MatrixReportComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SofSpinnerModule,
    MatrixModule
  ],
  exports: [MatrixReportComponent],
  providers: [],
})
export class MatrixReportModule {}
