import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRoute } from '@angular/router';

import { SiteStorageService, tryGetCurrentUser, SiteGlobalBasicDataResource } from '@softools/softools-core';
import { LOGIN_ROUTE } from 'app/_constants';

@Injectable()
export class LicensingAgreedGuard implements CanActivate {
  constructor(private siteService: SiteStorageService, private router: Router, private route: ActivatedRoute) {}

  public async canActivate(): Promise<boolean | UrlTree> {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    const queryParams = returnUrl ? { returnUrl } : {};

    try {
      const user = tryGetCurrentUser();
      if (!user) {
        // No current user so nobody to sign up to t&cs.  Allow this through
        // as other guards are responsible for whether we need a user.
        return true;
      }

      // Use this to force the license screen - DO NOT SIMPLY CHECK IF ENABLED!!!!
      // user.GDPRSignedUp = false;
      // user.LicensingSignedUp = false;
      // setCurrentUser(user);

      const siteGlobalBasicData: SiteGlobalBasicDataResource = await this.siteService.getSiteGlobalBasicDataAsync();
      if (
        (siteGlobalBasicData.GDPRSignupEnabled && !user.GDPRSignedUp) ||
        (siteGlobalBasicData.LicensingSignupEnabled && !user.LicensingSignedUp)
      ) {
        const urlTree = this.router.parseUrl('/Signup');
        urlTree.queryParams = queryParams;
        return urlTree;
      } else {
        return true;
      }
    } catch {
      const urlTree = this.router.parseUrl(LOGIN_ROUTE);
      urlTree.queryParams = queryParams;
      return urlTree;
    }
  }
}
