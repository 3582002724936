import { Component, Input } from '@angular/core';
import { GlobalModel } from 'app/mvc';

@Component({
  selector: 'app-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss']
})
export class MessageBannerComponent {

  @Input() globalModel: GlobalModel;
}
