<footer #foot [@contentPinnedState]="showAside" layout="column" class="w-100">
  <div class="footer-content">
    <div class="sync-status d-flex">
      <div *ngIf="isSynchronising$|async" class="px-2">
        <sof-spinner-icon  *ngIf="isOnline" [size]="'1x'"></sof-spinner-icon>
        <fa-icon *ngIf="!isOnline" [icon]="['fal', 'wifi-slash']" size="1x" style="font-size: 1.7rem"></fa-icon>
      </div>

      <div cdkOverlayOrigin #validationOrigin="cdkOverlayOrigin">
        <div style="width: 2rem" >
          <ng-container *ngIf="showValidation" >
            <div *ngIf="numValidationErrors$|async as numValidationErrors" class="mx-2" (click)="showValidationPopup = !showValidationPopup">
              <fa-layers *ngIf="numValidationErrors === 1"  title="1 problem on this page." i18n-title class="w-100">
                <fa-icon [icon]="['fal', 'exclamation-triangle']" size="lg" style="color: darkorange" ></fa-icon>
                <fa-layers-counter class="err-count w-100 fa-layers-top-right" style="font-size:2.5em;" content="{{numValidationErrors}}"></fa-layers-counter>
              </fa-layers>

              <fa-layers *ngIf="numValidationErrors > 1"  title="{{numValidationErrors}} problems on this page" i18n-title class="w-100 pointer">
                <fa-icon [icon]="['fal', 'exclamation-triangle']" size="lg" style="color: darkorange" ></fa-icon>
                <fa-layers-counter class="err-count w-100 fa-layers-top-right" style="font-size:2.5em;" content="{{numValidationErrors}}"></fa-layers-counter>
              </fa-layers>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="showReportPaneSelector" class="panel-select">
      <fa-icon *ngIf="zoomReportPane !== 'zoom-chart'"
         [icon]="['fal', 'chart-bar']" size="lg" class="zoom-pane-button mx-2 pointer"
         title="Show Chart" i18n-title
         (click)="chartPanelZoomClicked($event)"
      ></fa-icon>
      <fa-icon  *ngIf="zoomReportPane !== 'zoom-records'"
        [icon]="['fal', 'th-list']" size="lg" class="zoom-pane-button mx-2 pointer"
        title="Show Records" i18n-title
        (click)="recordsPanelZoomClicked($event)"
      ></fa-icon>
    </div>

    <div class="left-content">
      <ng-content select=".footer-left"></ng-content>
    </div>

    <div class="paginator">
      <!-- Embed pagination components  -->
      <ng-content></ng-content>
    </div>

    <div class="support">
      <div class="d-flex flex-row-reverse">
        <!-- Support widget in the footer bar -->
        <app-floating-support-widget [fixed]=false class="pe-1 me-1" ></app-floating-support-widget>

        <!-- Widget located in footer bar-->
        <app-comment-widget *ngIf="hasCommentsAndAttachmentsWidget" #commenter
          [appModel]="appModel"
          [attachmentCount]="(attachmentsModel?.attachments.$|async)?.length" [commentCount]="(commentsModel?.comments.$|async)?.length" class="pe-1"
          (onCommentClicked)="clickCommentsToggle()" (onFileClicked)="clickAttachmentsToggle()">
        </app-comment-widget>
      </div>
    </div>
  </div>

  <app-popup-container
    [isOpen]="showValidationPopup"
    [connectedOrigin]="validationOrigin"
    [connectedPositions]="validationPosition"
    [hasBackdrop]="true"
    (closed)="showValidationPopup = false">
    <ng-template>
      <div class="bg-light-st" style="height: 16rem; ">
        <div class="w-100 h-100">
          <app-validation-error-display
            [errors]="record._errs"
            [serverError]="record._serverError"
            [fields]="fields">
          </app-validation-error-display>
        </div>
      </div>
    </ng-template>
  </app-popup-container>

  <app-popup-container
    [connectedOrigin]="attachmentsOrigin"
    [isOpen]="commentsModel?.showComments.$|async"
    dock="right"
    [maximizeWhenCentred]="true"
    (closed)="closeCommentsClicked()"
  >
    <ng-template>
      <app-comment-popup
        [recordModel]="recordModel"
        [model]="commentsModel"
        (close)="closeCommentsClicked()"
        >
      </app-comment-popup>
    </ng-template>
  </app-popup-container>

  <app-popup-container
  [connectedOrigin]="attachmentsOrigin"
  [isOpen]="attachmentsModel?.showAttachments.$|async"
  dock="right"
  [maximizeWhenCentred]="true"
  (closed)="closeAttachmentsClicked()"
  >
    <ng-template>
      <app-file-attachment-popup
        [recordModel]="recordModel"
        [model]="attachmentsModel"
        [content]="attachmentsModel?.attachments.$|async"
        [isOnline]="isOnline"
        [workAttachment]="workFileAttachment"
        (close)="closeAttachmentsClicked()"
        (previewAttachment)="previewAttachmentClicked($event)"
        >
      </app-file-attachment-popup>
    </ng-template>
  </app-popup-container>

</footer>
