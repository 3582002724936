import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSimplePopupComponent } from './filter-simple-popup/filter-simple-popup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { FieldsModule } from 'app/softoolsui.module/fields.component/fields.module';
import { FilterSaveComponent } from './filter-save/filter-save.component';
import { FilterManagementPanelComponent } from './filter-management-panel/filter-management-panel.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopupContainerModule } from '../softoolsui.module/popup-container/popup-container.module';
import { FilterDisplayComponent } from './filter-display/filter-display.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofEditIconModule, SofCheckIconModule, SofTimesIconModule, SofPlusIconModule } from '@softools/design/icons';
import { FilterAscDescComponent } from './filter-asc-desc/filter-asc-desc.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SortPopupComponent } from './sort-popup/sort-popup/sort-popup.component';
import { FilterPopupFieldComponent } from './filter-popup-field/filter-popup-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatRadioModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatDialogModule,
    OverlayModule,
    FieldsModule,
    PopupContainerModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    SofEditIconModule,
    SofCheckIconModule,
    SofTimesIconModule,
    SofPlusIconModule,
  ],
  declarations: [
    FilterSimplePopupComponent,
    FilterSaveComponent,
    FilterManagementPanelComponent,
    FilterDisplayComponent,
    FilterAscDescComponent,
    SortPopupComponent,
    FilterPopupFieldComponent
  ],
  exports: [
    FilterSimplePopupComponent,
    FilterSaveComponent,
    FilterManagementPanelComponent,
    FilterDisplayComponent,
    SortPopupComponent,
  ],
})
export class FiltersModule {}
