import { RepositoryBase } from './repositories.base';
import { Injectable } from '@angular/core';
import { SelectedRowsRequest } from '../types/interfaces/selectedrowsrequest.interface';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { Record, RecordId } from '../types/interfaces/record.interface';
import { RecordCopyInfo, BulkUnlinkHierarchyRequest, BulkAddToHierarchyRequest, AccessRight, QueryParams } from '../types/interfaces';
import { Activity } from '../types/interfaces/historydata.interface';
import { logError } from '../utils';
import { environment } from 'environments/environment';
import { getCurrentUser } from './repositories.global';
import { ChangedRecord } from '../types/interfaces/changed-record.interface';

export interface SyncParameters {
  AfterRecordId?: string;

  PageSize: number;

  Filter?: string;

  Hierarchy?: string;
}

export interface IRecordsRequest {
  AppIdentifier: string;
  RecordIdentifiers: Array<string>;
}

export interface IRecordsResponse {
  id: string,
  status: number,
  record?: Record
}

@Injectable({ providedIn: 'root' })
export class AppDataRepository extends RepositoryBase {
  public getData(appIdentifier: string, reportIdentifier: string, queryParams: {}, hierarchy?: string): Observable<Array<Record>> {
    let url = this.urlResolver.resolveAppDataV2Url(appIdentifier, '', reportIdentifier, hierarchy);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.get(url);
  }

  public async syncData(appIdentifier: string, syncParameters: SyncParameters): Promise<Array<Record>> {
    try {
      const pageSize = syncParameters.PageSize;

      let response: string;
      if (syncParameters.Filter || syncParameters.Hierarchy) {
        const url = this.urlResolver.resolveV2PartialSyncUrl(appIdentifier);
        response = await lastValueFrom<string>(this.postWithType(url, 'text', syncParameters));
      } else {
        const url = this.urlResolver.resolveV2SyncUrl(appIdentifier, pageSize, syncParameters.AfterRecordId);
        return await lastValueFrom<Array<Record>>(this.get(url, 'json'));
      }

      // Specific (crude) validation to check for truncated JSON
      // This appears to be happening on iOS 13 so treat as a specific error
      if (!response.endsWith(']')) {
        throw new SyntaxError('JSON was truncated - no closing bracket');
      }

      const records = JSON.parse(response);
      return records;
    } catch (error) {
      logError(error, `Error syncing ${appIdentifier}  ${syncParameters.PageSize} ${syncParameters.AfterRecordId}`);
      throw error;
    }
  }

  public getRecord(appIdentifier: string, recordId: string) {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier, '', '');
    return this.get(url + '/' + recordId);
  }

  /** Get multiple records */
  public getRecords(appIdentifier: string, recordIds: Array<RecordId>)
    : Observable<Array<IRecordsResponse>> {

    // Build request body
    // The API allows requests for multiple apps but we only request
    // for a single app here
    const request: IRecordsRequest = {
      AppIdentifier: appIdentifier,
      RecordIdentifiers: recordIds
    }

    const url = `${this.urlResolver.servicePath()}/Api/v2/Records`;
    // Use post because xhr does not allow body on get
    return this.post(url, [request]);
  }

  /**
   * Get record count
   * @param appIdentifier
   * @param queryParams
   * @param hierarchy
   */
  public getCount(appIdentifier: string, queryParams: QueryParams, hierarchy?: string) {
    const hierarchyPart = hierarchy ? `/${hierarchy}` : '';
    // We always apply the base filter from v18 in the query params, we dont need to add the report id when doing a count.
    let url = this.urlResolver.resolveAppDataV2Url(appIdentifier, `%20/$count${hierarchyPart}`);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.get(url);
  }

  /**
   * Get app data group information (group values and counts)
   * @param appIdentifier
   * @param groupBy
   * @param descending
   * @param start
   * @param count
   * @param archived
   */
  public async getGroupInformation(appIdentifier: string, groupBy: string, descending: boolean, start: number, count: number, archived: boolean, hierarchy: string, filter?: string) {
    let url = this.urlResolver.resolveGroupDataUrl(appIdentifier, groupBy, descending, start, count, archived, hierarchy);
    if (filter) {
      url = this.urlResolver.appendQueryParams(url, { $filter: filter });
    }
    return this.get(url).toPromise();
  }

  public async getGroupCount(appIdentifier: string, groupBy: string, hierarchy: string, filter?: string): Promise<{ count: number }> {
    let url = this.urlResolver.resolveGroupCountUrl(appIdentifier, groupBy, hierarchy);
    if (filter) {
      url = this.urlResolver.appendQueryParams(url, { $filter: filter });
    }
    return this.get(url).toPromise();
  }

  public getHistory(appIdentifier: string, recordId: string): Observable<Array<Activity>> {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier, 'History', recordId);
    return this.get(url);
  }

  public archive(appIdentifier: string, recordId: string) {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier, '', recordId);
    return this.del(url);
  }

  public unarchive(appIdentifier: string, recordId: string) {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier, 'Unarchive', recordId);
    return this.post(url);
  }

  public deleteArchived(appIdentifier: string, recordId: string) {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier, 'Archived', recordId);
    return this.del(url);
  }

  /**
   * Archive multiple records as a batch operation
   *
   * @param requestData Specifies records to archive; an empty list implies all matching records.
   * @param appIdentifier
   * @param queryParams
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   * @param hierarchy
   */
  public bulkArchive(requestData: SelectedRowsRequest, appIdentifier: string, queryParams: {}, reportId: number, hierarchy?: string) {
    let url = this.urlResolver.resolveBulkArchiveUrl(appIdentifier, reportId, hierarchy);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.post(url, JSON.stringify(requestData));
  }

  /**
   * Unarchive multiple records as a batch operation
   *
   * @param requestData Specifies records to unarchive; an empty list implies all matching records.
   * @param appIdentifier
   * @param queryParams
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   * @param hierarchy Hierarchy for child apps
   */
  public bulkUnarchive(requestData: SelectedRowsRequest, appIdentifier: string, queryParams: {}, reportId: number, hierarchy?: string) {
    let url = this.urlResolver.resolveBulkUnarchiveUrl(appIdentifier, reportId, hierarchy);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.post(url, JSON.stringify(requestData));
  }

  /**
   * Delete multiple archived records as a batch operation
   *
   * @param requestData Specifies records to delete; an empty list implies all matching records.
   * @param appIdentifier
   * @param queryParams
   * @param reportId Numeric report identifier (use Report.Id not Report.Identifier).
   *    If not 0 the report is used as a source of base filter.
   * @param hierarchy Optional hierarchy, if specified only matching child records are affected
   */
  public bulkDelete(requestData: SelectedRowsRequest, appIdentifier: string, queryParams: {}, reportId: number, hierarchy?: string) {
    let url = this.urlResolver.resolveBulkDeleteUrl(appIdentifier, reportId, hierarchy);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.post(url, JSON.stringify(requestData));
  }

  /**
   * Get access rights for a record.
   * @param appIdentifier
   * @param recordId
   */
  public getAccessRights(appIdentifier: string, recordId: string): Promise<Array<AccessRight>> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'AppDataAccessRights', recordId);
    return lastValueFrom<Array<AccessRight>>(this.get(url));
  }

  /**
   * Update the access rights associated with a single record.  The calling user must have Record Security
   * permission to make this call.
   *
   * @param appIdentifier
   * @param recordId
   * @param patch   Record patch data; it should only modify the AccessRights member
   */
  public updateAccessRights(appIdentifier: string, recordId: string, patch: any): Promise<void> {
    const url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'AppDataAccessRights', recordId);
    return this.patch(url, JSON.stringify(patch)).toPromise();
  }

  /**
   * Update access rights for multiple records
   *
   * @param requestData
   * @param appIdentifier
   * @param queryParams
   * @param reportId
   * @param hierarchy
   */
  public bulkAccessRightUpdates(
    requestData: SelectedRowsRequest,
    appIdentifier: string,
    queryParams: {},
    reportId: number,
    hierarchy = null
  ): Promise<void> {
    let url = this.urlResolver.resolveBulkSetAccessRightsUrl(appIdentifier, reportId, hierarchy);
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.post(url, JSON.stringify(requestData)).toPromise();
  }

  public save(appIdentifier: string, id: string, changes: {}, hierarchy?: string) {
    try {
      const url = this.urlResolver.resolvePatchAppDataUrl(appIdentifier, id, hierarchy);
      return this.patch(url, JSON.stringify(changes));
    } catch (error) {
      logError(error, `Error updating ${appIdentifier} record ${id} ${Object.getOwnPropertyNames(changes).join(',')} `);
      throw error;
    }
  }

  public create(appIdentifier: string, id: string, changes: {}, hierarchy?: string) {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier, id, 'PostWithId', hierarchy);
    return this.post(url, JSON.stringify(changes));
  }

  public linkRecordsToParent(requestData: BulkAddToHierarchyRequest) {
    // Build URL.  The API doesn't specify which app identifier but it's currently ignored
    const url = this.urlResolver.resolveAppRelativeUrl(requestData.ParentAppIdentifier, 'BulkHierarchy', null, null, requestData.Filter);
    return this.post(url, JSON.stringify(requestData));
  }

  public sendBulkHierarchyUnlinkUpdate(requestData: BulkUnlinkHierarchyRequest, appIdentifier: string, queryParams: {}) {
    let url = this.urlResolver.resolveAppRelativeUrl(appIdentifier, 'BulkHierarchy/Unlink');
    url = this.urlResolver.appendQueryParams(url, queryParams);
    return this.patchNoResponse(url, JSON.stringify(requestData));
  }

  public exportRecordLevel(appIdentifier: string, recordId: string, excludedColumns: Array<string>, excludeReadOnlyFields?: boolean) {
    const model = {
      ExcludedColumns: excludedColumns,
      SelectedRows: [recordId]
    };
    const flags = excludeReadOnlyFields ? 2 : 0;
    const url = this.urlResolver.resolveDefaultApiUrl('Apps', appIdentifier) + `/Data/Export/0/${flags}`;
    return this.post(url, JSON.stringify(model));
  }

  public refresh(appIdentifier: string) {
    const url = this.urlResolver.resolveAppDataUrl(appIdentifier) + '/Refresh';
    return this.post(url);
  }

  public copy(appIdentifier: string, sourceId: string, copyInfo: RecordCopyInfo, hierarchy?: string): Observable<Record> {
    let url = this.urlResolver.resolveDefaultApiUrl('Apps');
    url = `${url}/${appIdentifier}/Data/${sourceId}/Copy`;
    if (hierarchy) {
      url = `${url}/${hierarchy}`;
    }

    return this.post(url, copyInfo);
  }

  /**
   * @param appIdentifier
   * @param when
   */
  public async recordsChangedSince(appIdentifier: string, when: number): Promise<Array<Record>> {
    const url = `${environment.baseUrl}/Api/Apps/${appIdentifier}/since/${when}`;

    const response = await firstValueFrom(this.get(url, 'text'));
    if (response.startsWith('[') && response.endsWith(']')) {
      return JSON.parse(response);
    }

    throw new Error('Bad data since response');
  }

  public importWithData(appIdentifier: string, hierarchy: string, base64Data: string): Observable<any> {
    const url = this.urlResolver.resolveImportAppDatUrlWithData(appIdentifier, hierarchy);
    return this.patch(url, base64Data);
  }


  /** Calculate aggregate expression */
  public async calculateAggregateAsync(appIdentifier: string, fieldIdentifier: string, queryParams?: QueryParams): Promise<number> {
    const tenant = getCurrentUser().Tenant;
    let url = `${environment.baseUrl}/Api/App/Aggregate/${tenant}/${appIdentifier}/${fieldIdentifier}`;
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }

    const response = await this.get(url).toPromise();
    return response?.result;
  }

  public async getLatestChange(): Promise<ChangedRecord> {
    const url = `${environment.baseUrl}/Api/ChangeList/Latest`;
    const result = await lastValueFrom<ChangedRecord>(this.get(url));
    return result;
  }

  public async getChangesAfter(changeId: string, count: number, appIdentifier?: string): Promise<Array<ChangedRecord>> {
    let url = `${environment.baseUrl}/Api/ChangeList/After/${changeId}/${count}`;
    if (appIdentifier) {
      url += `?appIdentifier=${appIdentifier}`;
    }
    const result = await lastValueFrom<Array<ChangedRecord>>(this.get(url));
    return result;
  }

  public async getIndexAsync(
    appIdentifier: string,
    skip: number,
    count: number,
    reportIdentifier?: string,
    query?: QueryParams,
    hierarchy?: string) {

    let url = `${environment.baseUrl}/Api/v2/Index/${appIdentifier}/${reportIdentifier}/${count}`;
    if (hierarchy) {
      url += `/${hierarchy}`;
    }

    const params: QueryParams = {};

    if (query) {
      if (query.$filter) { params.$filter = query.$filter; }
      if (query.$orderby) { params.$orderby = query.$orderby; }
    }

    params.$skip = skip;

    url = this.urlResolver.appendQueryParams(url, params);

    const result = await lastValueFrom<Array<RecordId>>(this.get(url));
    return result;
  }

  public async getRecordPositionAsync(
    recordId: RecordId,
    appIdentifier: string,
    reportIdentifier: string,
    query?: QueryParams,
    hierarchy?: string
  ): Promise<number> {

    let url = `${environment.baseUrl}/Api/v2/Index/${appIdentifier}/${reportIdentifier}/Position/${recordId}`;
    if (hierarchy) {
      url += `/${hierarchy}`;
    }

    if (query) {
      const params: QueryParams = {};
      if (query.$filter) { params.$filter = query.$filter; }
      if (query.$orderby) { params.$orderby = query.$orderby; }
      url = this.urlResolver.appendQueryParams(url, params);
    }

    const result = await lastValueFrom<number>(this.get(url));
    return result;
  }

}
