export module PermissionEnums {
  // 1000s
  export enum Records {
    All = 1000,
    Create = 1001,
    Update = 1002,
    Delete = 1003,
    Archive = 1004,
    Tag = 1005,
    Copy = 1006,
    Template = 1007,
    Link = 1008,
    Export = 1009,
    Subscribe = 1010,
    Security = 1011
  }

  // 2000s
  export enum Reports {
    All = 2000,
    Import = 2001,
    Export = 2002,
    QuickEdit = 2003,
    Archive = 2004,
    Tag = 2005,
    Copy = 2006,
    Template = 2007,
    Link = 2008,
    Subscribe = 2009,
    Security = 2010,
    SavePersonalFilters = 2014,
    SaveTeamFilters = 2011,
    SaveGlobalFilters = 2012,
    ShowArchived = 2013,
    Delete = 2015,
    SetAsDefaultFilter = 2016
  }

  // 3000s
  export enum Settings {
    All = 3000,
    UsersTeamsSelf = 3001,
    UsersTeamsUpdate = 3002,
    UsersTeamsCreate = 3003,
    UsersTeamsDelete = 3004,
    Lists = 3005,
    // Lookups = 3006,
    SiteResources = 3007,
    Other = 3008,
    //UsersResetPassword = 3009,
    //UsersChangePassword = 3010,
    Subscribe = 3011,
    SetLoginImage = 3012,
    Imports = 3013,
    TeamsAll = 3014,
    Exports = 3015,
    ExportsAll = 3016,
    ImportsCancel = 3018,
    ImportsCancelAll = 3019,
    // SendAlerts = 3030,
    // SetAccessControl = 3031,
    ImportsAll = 3032,
    SetExportPolicy = 3033,
    SetShowRegistration = 3034,
    SetGDPRSignup = 3040,
    SetLicensingSignup = 3041,
    // SetSecurityPolicy = 3042,
    AcceptSecurityPolicy = 3043,
    ErrorSummaries = 3044,
    ErrorSummariesAll = 3045,
    SetApiKeySecret = 3046
  }

  // 4000s
  export enum ActivityStream {
    All = 4000,
    Attach = 4001,
    Comment = 4003,
    ViewHistory = 4004
  }

  // 5000s
  export enum AppStudio {
    All = 5000,
    AddApp = 5010,
    Homepages = 5020,
    HomepagesAdd = 5021,
    HomepagesDelete = 5022,
    // HomepagesEnhanced = 5023,
    ConfigureAdvanced = 5030, // SOF-12215:  AppStudio.Configure to be renamed AppStudio.ConfigureAdvanced, as we split out Basic/ Advanced areas.  Add new permission AppStudio.ConfigureBasic.
    ConfigureDelete = 5031,
    ConfigureBasic = 5032, 
    // ProcessMap = 5040,
    Fields = 5050,
    FieldsAdd = 5051,
    FieldsDelete = 5052,
    Templates = 5060,
    TemplatesEnhanced = 5061,
    TemplatesAdd = 5062,
    TemplatesDelete = 5063,
    Forms = 5070,
    FormsAdd = 5071,
    FormsDelete = 5072,
    Reports = 5080,
    ReportsAdd = 5081,
    ReportsDelete = 5082,
    Workflow = 5090,
    WorkflowAdd = 5091,
    WorkflowDelete = 5092,
    TemplatedReports = 5110,
    TemplatedReportsAdd = 5111,
    TemplatedReportsDelete = 5112,
    Dashboards = 5113,
    DashboardsAdd = 5114,
    DashboardsDelete = 5115
  }

  export enum SiteManager {
    All = 6000,
    Delete = 6010
  }

  // Note - Only allow developers to have this permission, as it enables Hanfire Dashboard access
  export enum Hangfire {
    All = 8000
  }
}
