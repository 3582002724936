<app-input
  class="d-flex align-items-center"
  [appModel]="appModel"
  [reportModel]="reportModel"
  [class.inline-control]="inline"
  [style.text-align]="alignment"
  [elementStyles]="elementStyles"
  [textStyle]="textStyle"
  [field]="fieldModel"
  [template]="template"
  [record]="record"
  [value]="value"
  [listRow]="listRow"
  [ruleState]="ruleState"
  [containerField]="containerField"
  [form]="form"
  [disabled]="!(enabled$|async)"
  (focussed)="onInputFocus()"
  (blurred)="onInputBlurred($event)"
  (inputChanged)="inputChanged($event)"
  (onInputValidation)="onInputValidation($event)"
>
</app-input>
