import { Filter, logMessage, logObject, QueryParams, ReportViewParams, SavedFilter, stringCompare } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';

export class ReportFilter implements Filter {

  public Id: string;
  public Description: string;

  public Filter: string;

  public Group: string;
  public IsGroupDescending: boolean;

  public OrderBy: string;
  public IsOrderDescending: boolean;

  public IsArchived = false;

  public AccessLevel: number;
  public IsHidden: boolean;
  public IsDefault: boolean;
  public TeamIds: string[];
  public Top = 25;
  public Skip = 0;

  public static fromSavedFilter(saved: SavedFilter): ReportFilter {
    const filter = new ReportFilter();
    filter.Id = saved.Id;
    filter.Description = saved.Description;
    filter.filterString = saved.Filter;
    filter.groupByString = saved.Group;
    filter.orderByString = saved.OrderBy;
    filter.AccessLevel = saved.AccessLevel;
    filter.IsHidden = saved.IsHidden;
    filter.IsDefault = saved.IsDefault;

    // If we didn't get sort/group direction from the string
    // and an explicit one is defined, use that.
    // This is probably redundant, playing safe.
    if (filter.IsOrderDescending === undefined) {
      filter.IsOrderDescending = saved.IsOrderDescending;
    }

    if (filter.IsGroupDescending === undefined) {
      filter.IsGroupDescending = saved.IsGroupDescending;
    }

    return filter;
  }

  public static fromQueryParams(queryParams: QueryParams): ReportFilter {
    const filter = new ReportFilter();
    filter.QueryParameters = queryParams;
    return filter;
  }

  public static fromReportViewParams(view: ReportViewParams): ReportFilter {
    const filter = new ReportFilter();
    filter.filterString = view.filterQuery;
    filter.OrderBy = view.orderBy;
    filter.IsOrderDescending = view.orderDescending;
    filter.Group = view.groupBy;
    filter.IsGroupDescending = view.groupDescending;
    return filter;
  }

  public static merge(filters: Array<ReportFilter>) {
    if (filters?.length) {
      let filter = filters.shift();
      while (filters.length > 0) {
        const next = filters.shift();
        if (next) {
          filter = filter.append(next);
        } else {
          logMessage('Undefined filter when merging');
        }
      }
      return filter;
    } else {
      return null;
    }
  }

  /** Compare two filters.
   * This returns true if the selection parts of the filters are identical.
   * Skip and Top properties are ignored
   */
  public static equals(f1: ReportFilter, f2: ReportFilter) {
    const equal = stringCompare(f1?.Filter, f2?.Filter) === 0 &&
      stringCompare(f1?.OrderBy, f2?.OrderBy) === 0 &&
      (f1?.IsOrderDescending || false) === (f2?.IsOrderDescending || false) &&
      stringCompare(f1?.Group, f2?.Group) === 0 &&
      (f1?.IsGroupDescending || false) === (f2?.IsGroupDescending || false) &&
      (f1?.IsArchived === f2?.IsArchived);
    return equal;
  }

  public append(other: ReportFilter): ReportFilter {

    // clone this filter as we'll mutate it
    const filter = new ReportFilter(this);

    filter.Id = '';
    filter.Description = `${filter.Description} | ${other.Description}`;
    filter.IsDefault = false;

    if (filter.Filter && other.Filter) {
      filter.Filter = `(${filter.Filter}) and (${other.Filter})`;
    } else if (other.Filter) {
      filter.Filter = other.Filter;
    }

    if (other.OrderBy) {
      filter.OrderBy = other.OrderBy;
      filter.IsOrderDescending = other.IsOrderDescending;
    }

    if (other.Group) {
      filter.Group = other.Group;
      filter.IsGroupDescending = other.IsGroupDescending;
    }

    // Latest filter takes priority
    filter.IsArchived = other.IsArchived;

    // todo everything else
    return filter;
  }

  public equalTo(other: ReportFilter) {

    return other &&
      this.Filter === other.Filter &&
      this.Group === other.Group &&
      this.OrderBy === other.OrderBy &&
      this.Top === other.Top &&
      this.Skip === other.Skip &&
      this.IsOrderDescending === other.IsOrderDescending &&
      this.IsGroupDescending === other.IsGroupDescending;
  }

  constructor(filter: Filter = null) {
    if (filter) {
      Object.assign(this, filter);
    }
  }

  /**
   * Get query parameters.  This synthesizes the parameters based on the
   * filter settings
   */
  public getQueryParameters(options?: {
    showArchived?: boolean;
    /** Override archive property */
    archiveProperty?: string;
    noBracketFields?: boolean;
    /** Exclude skip & top */
    noPosition?: boolean;
    /** Exclude sort & group */
    noOrder?: boolean;
  }): QueryParams {

    // Grab optional arguments setting default values
    const removeFieldBrackets = options?.noBracketFields || false;
    const showArchived = options?.showArchived;

    // Handle order and group ascending/descending
    // This can be specified by boolean flags or a suffix
    let group: string = this.Group;
    let groupDesc = this.IsGroupDescending ? ' desc' : '';
    if (group) {
      const groupParts = group.split(' ');
      if (groupParts.length > 1) {
        group = groupParts[0];
        groupDesc = ' ' + groupParts[1];
      }
    }

    let orderBy: string = this.OrderBy;
    let orderDesc = this.IsOrderDescending ? ' desc' : '';
    if (orderBy) {
      const orderParts = orderBy.split(' ');
      if (orderParts.length > 1) {
        orderBy = orderParts[0];
        orderDesc = ' ' + orderParts[1];
      }
    }

    // API needs square brackets around app fields, but not settings fields
    if (removeFieldBrackets) {
      if (group) {
        group = removeFieldBrackets ? group.replace(/[\[\]]/g, '') : group;
      }

      if (orderBy) {
        orderBy = removeFieldBrackets ? orderBy.replace(/[\[\]]/g, '') : orderBy;
      }

    } else if (!removeFieldBrackets) {
      if (group && !group?.startsWith('[') && !group?.endsWith(']')) {
        group = `[${group}]`;
      }
      if (this.OrderBy && !this.OrderBy?.startsWith('[') && !this.OrderBy?.endsWith(']')) {
        orderBy = `[${this.OrderBy}]`;
      }
    }

    // Assemble filter string from constituent parts
    const filterParts: Array<string> = [];
    if (this.Filter) {
      const filter = removeFieldBrackets ? this.Filter.replace(/[\[\]]/g, '') : this.Filter;
      filterParts.push(`(${filter})`);
    }

    if (showArchived !== undefined) {
      const arch = options?.archiveProperty ?? 'IsArchived';
      filterParts.push(`${arch} eq ${showArchived}`);
    }

    const queryParams = {
      $filter: filterParts.join(' and '),
    } as QueryParams;

    if (!options?.noPosition) {
      if (this.Skip || this.Skip === 0) {
        queryParams.$skip = this.Skip;
      }

      if (this.Top) {
        queryParams.$top = this.Top;
      }
    }

    if (!options?.noOrder) {

      if (group) {
        queryParams.$groupby = `${group}${groupDesc}`;
      }

      // Setup sort order.  We currently rely on grouped items coming back in the right order
      // so have to take both group and order settings into account
      if (!options?.noOrder && group && orderBy) {
        // both group and order specified.  If they are different then apply both, otherwise just order (which is same as group)
        if (group !== orderBy) {

          // Only add the group in if it#s not already there
          if (orderBy.split(',').find(order => order === group)) {
            queryParams.$orderby = `${orderBy}${orderDesc}`;
          } else {
            queryParams.$orderby = `${group}${groupDesc},${orderBy}${orderDesc}`;
          }
        } else {
          queryParams.$orderby = `${orderBy}${orderDesc}`;
        }
      } else if (group) {
        // grouped but not ordered so just use group as order
        queryParams.$orderby = `${group}${groupDesc}`;
      } else if (orderBy) {
        queryParams.$orderby = `${orderBy}${orderDesc}`;
      }
    }

    return queryParams;
  }

  /**
   * Get query parameters.  This synthesizes the parameters based on the
   * filter settings
   */
  public get QueryParameters(): QueryParams {
    // todo stop using the archived flag
    return this.getQueryParameters({ showArchived: this.IsArchived });
  }

  /**
   * Set query parameters.  This sets the values of the related fields which represent the
   * same values as specified in the argument
   */
  public set QueryParameters(queryParams: QueryParams) {

    // Get sort value.  Because we currently have to include the group in
    // the sort order we need to strip this out
    let order = queryParams.$orderby;
    if (queryParams.$groupby) {
      order = order.replace(queryParams.$groupby, '');
      if (order.startsWith(',')) {
        order = order.substring(1);
      }
    }

    this.filterString = queryParams.$filter;
    this.groupByString = queryParams.$groupby;
    this.orderByString = order;
    this.Top = +queryParams.$top;
    this.Skip = +queryParams.$skip;
    // this.Search = queryParams.$search;

    // Make a name up for the filter
    // todo Should be changed to flags e.g. isCustom and the text specified in the UI
    // Get filter expression excluding the archive flag to indicate whether any real filtering
    // is occuring.  NB this may not be a valid search result but it will not be blank if there
    // are other clauses.  todo Still too clunky!
    const filterWithoutArchive = (this.Filter || '').replace(/IsArchived (eq|ne) (true|false)/, '');
    if (filterWithoutArchive || this.Group || this.OrderBy) {
      this.Description = 'Custom';    // something is specified
    } else {
      this.Description = null;
    }
  }

  public get isCustom(): boolean {
    return !this.Id || this.Id.length === 0;
  }

  /**
   * Indicates whether a filter has any field filters,
   * sort or grouping specified.  If none of these are present
   * it will have no effect.
   */
  public get isNullFilter(): boolean {
    return (!this.Filter || this.Filter.length === 0) &&
      (!this.Group || this.Group.length === 0) &&
      (!this.OrderBy || this.OrderBy.length === 0);
  }

  /** @returns true if fields are filtered */
  public isFiltered(): boolean {
    return this.Filter?.length > 0;
  }


  /**
   * Set parameters from a filter string as found in query parameters.
   * Any parameter prefix and any square brackets (which have been used incorrectly to quote identifiers)
   * are removed.  The IsArchived flag is set according to the expression and the remaining expression
   * terms are stored in the Filter property.
   */
  public set filterString(filter: string) {

    // Strip any brackets from filter and prefix
    const expression = (filter || '').replace(/\$filter=/g, '');

    // Break the filter expression into parts seperated by and operators
    // If we want to support more complex filtering in the query parameters we
    // could do a full odata parse here
    let parts = expression.split(/\s+and\s+/);

    // Find the archive check (if any) to set IsArchived flag
    const arch = parts.filter(s => /IsArchived (eq|ne) (true|false)/.test(s));
    this.IsArchived = (arch.length > 0 && /IsArchived\s+eq\s+true|IsArchived\s+ne\s+false/.test(arch[0]));

    // Recombine the remaining expression parts into a suitable expression
    parts = parts.filter(s => !/IsArchived (eq|ne) (true|false)/.test(s));

    this.Filter = parts.join(' and ');
  }

  /** Set sort parameters from a single parameter string e.g. "FieldName asc" */
  private set orderByString(spec: string) {
    const cleanSpec = (spec || '').replace(/\$orderby=/g, '');
    const parts = cleanSpec.split(/ +/, 2);
    this.OrderBy = parts[0];
    if (parts.length > 1) {
      this.IsOrderDescending = (parts[1] === 'desc');
    }
  }

  /** Set grouping parameters from a single parameter string e.g. "FieldName asc" */
  private set groupByString(spec: string) {
    const cleanSpec = (spec || '').replace(/\$groupby=/g, '');
    const parts = cleanSpec.split(/ +/, 2);
    this.Group = parts[0];
    if (parts.length > 1) {
      this.IsGroupDescending = (parts[1] === 'desc');
    }
  }

  /**
   * Get the field id used for grouping.
   * Unlike the Group property this returns a valid id even if the filter uses a
   * bracketed value.
   */
  public get groupFieldId() {

    // Sentry V18-4M1 bad value is getting here.  Temp log to see what it is
    if (this.Group === null || this.Group === undefined || (this.Group as any instanceof String || typeof this.Group === 'string')) {
      return this.Group?.replace(/[\[\]]/g, '');
    }

    logObject('Invalid Group', this.Group);
    return null;
  }

  /**
   * Set a simple equality filter for a field
   * @param field
   * @param value
   */
  public setSimpleFilter(field: AppField, value: any) {
    this.filterString = `[${field.Identifier}] eq ${field.constantForFilter(value)}`;
  }
}
