import { ListItemAppField } from './list-item-app-field';
import { BaseSelectionAppField } from './selection-app-field';

export class SelectionListItemField<T> extends ListItemAppField<BaseSelectionAppField<T>> {

  public getSelectListOptions() {
    return this.containedField.getSelectListOptions();
  }

}
