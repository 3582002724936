import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppZone } from 'app/types/enums';
import { APP_IDENTIFIER, REPORT_IDENTIFIER, PARENT_APP_IDENTIFIER, PARENT_RECORD_ID, RECORD_ID, FORM_IDENTIFIER, HOMEPAGE_IDENTIFIER } from 'app/_constants';
import { NavigationService } from 'app/services/navigation.service';
import { Enums, logError } from '@softools/softools-core';
import { AppIdentifiers } from 'app/services/record/app-info';

@Injectable({
  providedIn: 'root'
})
export class LegacyRoutesGuard implements CanActivate {

  constructor(private router: Router, private navigationService: NavigationService) {

  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {

    const appIdentifier = route.params[APP_IDENTIFIER];
    const reportIdentifier = route.params[REPORT_IDENTIFIER];
    let parentAppIdentifier = route.params[PARENT_APP_IDENTIFIER];
    const recordIdentifier = route.params[RECORD_ID];
    const parentRecordIdentifier = route.params[PARENT_RECORD_ID];
    const formIdentifier = route.params[FORM_IDENTIFIER];
    const homepageIdentifier = route.params[HOMEPAGE_IDENTIFIER];

    const appIdentifiers = new AppIdentifiers(route.params);

    let url: string = null;

    const zone = route.data['zone'];
    switch (zone) {

      case AppZone.home: {
        url = this.navigationService.getHompageUrl(homepageIdentifier);
        break;
      }

      case AppZone.apphome: {
        url = this.navigationService.getAppHomeUrl(appIdentifier);
        break;
      }

      case AppZone.recordupdate: {

        if (route.queryParams['hierarchy']) {
          const [parentIdentifier] = route.queryParams['hierarchy'].split('|');
          if (parentIdentifier) {
            parentAppIdentifier = parentIdentifier;
          }

          url = this.navigationService.getChildRecordUrl(appIdentifier, parentAppIdentifier, recordIdentifier, formIdentifier);
        } else {
          url = this.navigationService.getRecordUrl(appIdentifier, recordIdentifier, formIdentifier);
        }
        break;
      }

      case AppZone.recordadd:
      case AppZone.childrecordadd: {
        url = this.navigationService.getCreateRecordUrl(appIdentifiers);
        break;
      }

      case AppZone.listreport: {
        url = this.navigationService.getReportUrl(appIdentifier, reportIdentifier, Enums.ReportTypes.List);
        break;
      }

      case AppZone.childlistreport: {
        url = this.navigationService.getChildAppReportFolderUrl(
          parentAppIdentifier, parentRecordIdentifier, appIdentifier, reportIdentifier, Enums.ReportTypes.List);
        break;
      }

      case AppZone.chartreport: {
        url = this.navigationService.getReportUrl(appIdentifier, reportIdentifier, Enums.ReportTypes.Chart);
        break;
      }

      case AppZone.childchartreport: {
        url = this.navigationService.getChildAppReportFolderUrl(
          parentAppIdentifier, parentRecordIdentifier, appIdentifier, reportIdentifier, Enums.ReportTypes.Chart);
        break;
      }

      case AppZone.matrixreport: {
        url = this.navigationService.getReportUrl(appIdentifier, reportIdentifier, Enums.ReportTypes.Matrix);
        break;
      }

      case AppZone.childmatrixreport: {
        url = this.navigationService.getChildAppReportFolderUrl(
          parentAppIdentifier, parentRecordIdentifier, appIdentifier, reportIdentifier, Enums.ReportTypes.Matrix);
        break;
      }

      case AppZone.dashboardreport: {
        url = this.navigationService.getReportUrl(appIdentifier, reportIdentifier, Enums.ReportTypes.Dashboard);
        break;
      }

      case AppZone.childdashboardreport: {
        url = this.navigationService.getChildAppReportFolderUrl(
          parentAppIdentifier, parentRecordIdentifier, appIdentifier, reportIdentifier, Enums.ReportTypes.Dashboard);
        break;
      }

      case AppZone.verify: {
        url = this.navigationService.getRecordUrl('Softools.UserVerification', recordIdentifier);
        break;
      }

      default: {
        console.warn('Unepected legacy URL request', route.data['zone']);
        break;
      }
    }

    // If we found a matching new URL, redirect to it
    if (url) {
      this.router.navigate([url], { replaceUrl: true, queryParams: route.queryParams, queryParamsHandling: 'merge' })
        .catch(error => logError(error, 'Failed to route'));
    }

    // Always return false as we either redirect or fail
    return false;
  }
}
