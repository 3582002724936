import { Observable } from 'rxjs';
import { Model } from '@softools/vertex';
import { FilterModel } from '../filter.model';
import { ReportModel } from './report.model';

/**
 * Model that encapsulates active filter for a report model.
 * Although this doesn't own most of the properties itself it ensures that
 * changes are collected together, especially when initialising, to minimise
 * notifications.  The model itself should be observed.
 *
 * The ready property is set after the model is initialised.  This can be
 * used to filter out premature notifications e.g. when a subscription is
 * added.
 */
export class FilteredViewModel extends Model<FilteredViewModel> {

  constructor(public reportModel: ReportModel, public filterModel: FilterModel) {
    super(reportModel);
  }

  /** Initialise the model.  Call after construction */
  public initialise() {
    this.observeProperties();
  }

  // public async initFilters(app: Application, reportIdentifier: string, searchTerm: string) {
  //   await this.filterModel.initialise(app, reportIdentifier);
  //   this.reportModel.searchTerm.value = searchTerm;
  //   // this.ready.value = true;
  // }

  protected observeProperties() {
    this.watchChanges(this.reportModel.searchTerm.$);
    this.watchChanges(this.reportModel.filterModel.combinedFilter.$);
  }

  /** Add additional observer that causes a view change to occur */
  public watchChanges<T>(event: Observable<T>) {
    this.subscribe(event, (t) => {
      if (t) {
        this.changed();
      }
    });
  }
}
