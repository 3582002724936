import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable()
export class GlobalResetGuard implements CanActivate {

  constructor(private models: GlobalModelService) { }

  canActivate(): boolean {
    const launchpadModel = this.models.launchpadModel;
    launchpadModel.show.value = false;
    launchpadModel.search.value = '';
    return true;
  }
}
