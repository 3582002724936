import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Field, IFilterTerm } from '@softools/softools-core';
import { FilterSpecification } from './filter-specification';

export class FilterEditorUi {

  constructor(
    public showPopup = false,

    /** CDK origin ref of element to position against */
    public popupOrigin: CdkOverlayOrigin = null,

    public popupX = 0,
    public popupY = 0,
    public popupW = 0,

    /** Filter being edited  */
    public editFilterSpec: FilterSpecification = null,

    /** Filter term being edited  */
    public editFilterTerm?: IFilterTerm,

    /** Id of field being filter-edited */
    public fieldId: string = null,

    /** True for creating a field, false for editing existing */
    public isNewFilter = false,

    /** Fields that are not available for filtering when creating new filter */
    public disallowFields: Array<Field> = [],

  ) { }
}
