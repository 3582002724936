import { Subject } from 'rxjs';
import { Record, AppDataStorageService, logError, Style, ElementStyles, SavedFilter, RecordCopyInfo, RecordId, ReportStorageService, Report, getCurrentUser, PermissionEnums, ValidationErrorResponse, StyleTarget } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';
import { AppIdentifiers } from 'app/services/record/app-info';
import { InjectService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { AppIdentifier, Application, GetRecordOptions } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { GlobalModel, RouteParams } from './global.model';
import { RecordPatch } from 'app/workspace.module/types';
import { DataIndexService2 as DataIndexService } from 'app/services/indexes/data-index-service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { AppField } from 'app/types/fields/app-field';
import { debounceTime, filter } from 'rxjs/operators';
import { NamedStylesService } from 'app/services/named-styles.service';
import { AppDataService } from 'app/services/appdata.service';
import { BackgroundDataSyncService } from 'app/services/background-data-sync.service';
import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { SavedFiltersApplication } from 'app/embedded.apps/settings.apps/saved-filters/saved-filters.app';
import { LaunchpadModel } from './headers/launchpad.model';
import { IStyleName } from 'app/core/types/interfaces/style-name.interface';
import { AppContextModel } from './app/app-context.model';
import { SiteModel } from './common/site.model';

/**
 * Application model.
 * Any component that runs in the context of an App
 * (which is almost all) can use it to manage app
 * specific behaviour.
 */
export class AppModel extends Model<AppModel> {

  public readonly appContext = new AppContextModel(this);

  public readonly appIdentifiers = new ModelProperty<AppIdentifiers>(this);

  public readonly app = new ModelProperty<Application>(this).withLogging('App');

  public readonly parentApp = new ModelProperty<Application>(this).withLogging('Parent App');

  public readonly parentRecord = new ModelProperty<Record>(this).withLogging('Parent Record');

  public readonly totalRecordCountBusy = new ModelProperty<boolean>(this, true);

  public readonly totalRecordCount = new ModelProperty<number>(this);

  public readonly staticRecord = new ModelProperty<Record>(this).withLogging('APP STATIC RECORD');

  /** Saved filters associated with the app.  Use this, not App.SavedFilters as we plan to move this from the config */
  public readonly savedFilters = new ArrayModelProperty<SavedFilter>(this).withLogging('SAVED FILTERS');

  /** True if comments are enabled for the current user on this app */
  public readonly enableComments = new BooleanModelProperty(this).withLogging('Enable Comments');

  /** True if attachments are enabled for the current user on this app */
  public readonly enableAttachments = new BooleanModelProperty(this).withLogging('Enable Attachments');

  /** True if history is enabled for the current user on this app */
  public readonly enableHistory = new BooleanModelProperty(this).withLogging('Enable History');

  /** Notification that a record has been created */
  public readonly createdRecord$ = new Subject<Record>();

  /** Notification that a record has been deleted */
  public readonly deletedRecord$ = new Subject<RecordId>();

  /** Notification that a record has been replaced due to an external update */
  public readonly updatedRecord$ = new Subject<Array<Record>>();

  /** Notification that a record has been patched */
  public readonly recordPatched$ = new Subject<RecordPatch>();

  /** Notification that a record has been patched */
  public readonly recordPatching$ = new Subject<{ patch: RecordPatch, processing: boolean }>();

  // Projections
  public readonly identifier = new ProjectionModelPoperty<Application, AppIdentifier>(this.app, app => app?.Identifier);
  public readonly fields = new ProjectionModelPoperty<Application, Array<AppField>>(this.app, app => app?.AppFields);

  public styles: Array<Style>;

  @InjectService(AppService)
  private readonly appService: AppService;

  @InjectService(ReportStorageService)
  private reportsService: ReportStorageService;

  @InjectService(AppDataService)
  private readonly appDataService: AppDataService;

  @InjectService(AppDataStorageService)
  private readonly appDataStorageService: AppDataStorageService;

  @InjectService(BackgroundDataSyncService)
  private readonly backgroundDataSyncService: BackgroundDataSyncService;

  @InjectService(DataIndexService)
  private readonly dataIndexService: DataIndexService;

  @InjectService(RecordPersistService)
  private readonly recordPersistService: RecordPersistService;

  @InjectService(RecordValidationService)
  private readonly recordValidationService: RecordValidationService;

  @InjectService(NamedStylesService)
  private readonly namedStylesService: NamedStylesService;

  @InjectService(SavedFiltersApplication)
  private readonly savedFiltersApplication: SavedFiltersApplication;

  @InjectService(OverlayService)
  private readonly overlayService: OverlayService;

  public constructor(
    public globalModel: GlobalModel,
    public launchpadModel: LaunchpadModel,
    public siteModel: SiteModel,
    container?: Model<any>) {
    super(container);
  }

  /** Initialise the model.  Call after construction */
  public initialise() {
    this.observeProperties();
  }

  protected observeProperties() {

    // Track app indentifiers changes to keep current Application
    this.subscribe(this.appIdentifiers.$, (appIdentifiers) => {
      this.appContext.setIdentifiers(appIdentifiers);
      if (appIdentifiers?.appIdentifier) {
        this.app.value = this.appService.application(appIdentifiers.visibleAppIdentifier);
      }

      if (appIdentifiers?.parentAppIdentifier) {
        this.parentApp.value = this.appService.application(appIdentifiers.parentAppIdentifier);
      }
    });

    this.subscribe(this.app.$, async (app) => {
      if (app) {
        try {
          this.totalRecordCountBusy.value = true;

          if (app.isFilterAdvanced) {
            // Advanced filters enabled so no saved filters
            this.savedFilters.value = [];
          } else {
            const filters = this.savedFiltersApplication.getAppSavedFilters(app.Identifier);

            // If no filters returned, look in app.SavedFilters which is populated for
            // embedded apps (until we migrate them too).  This also picks up filters
            // for apps that have not been synced using the new mechanism  yet (see SOF-11259)
            if (filters.length === 0 && app.SavedFilters?.length > 0) {
              this.savedFilters.value = [...app.SavedFilters]
            } else {
              this.savedFilters.value = [...filters];
            }
          }

          const user = getCurrentUser();

          this.enableComments.value = app.AllowComments && user.hasPermission([
            PermissionEnums.ActivityStream.Comment,
            PermissionEnums.ActivityStream.All
          ]);

          this.enableAttachments.value = app.AllowAttachments && user.hasPermission([
            PermissionEnums.ActivityStream.Attach,
            PermissionEnums.ActivityStream.All
          ]);

          // Until we can do more...
          this.enableHistory.value = true;

          await this.loadAppStyles(app);

          await this.loadStaticRecord();

          const selection = new RecordSelection();
          selection.showArchived = this.globalModel.archived.value;
          // Get record count whenever app changes
          const count = await app?.totalCount(selection);
          this.batch(() => {
            this.totalRecordCount.value = count;
            this.totalRecordCountBusy.value = false;
          });

          this.launchpadModel.showSettings.value = app.IsSettingsApp;
          this.globalModel.header.setSettingsActions(app);

          this.subscribe(app.recordDeleted$, (deleted) => {
            if (deleted.app?.Identifier === this.app.value?.Identifier) {
              this.deletedRecord$.next(deleted.recordIdentifier);
            }
          });

          this.setPriorityApps(app);

        } catch (error) {
          console.log(error, 'appmodel app changed');
          this.totalRecordCountBusy.value = false;
        }
      }
    });

    // Monitor record changes
    // todo this is dependent on InitialLoadAppAction handled in AppDataEffects
    // this model should perform the polling but consider multiple instances.
    this.appDataStorageService.updatedRecord$.subscribe((records) => {
      if (records) {
        const mine = records.filter((rec) => rec.AppIdentifier === this.app.value.Identifier);
        mine.forEach(record => {
          this.recordModified(record);
        });

        this.updatedRecord$.next(mine);
      }
    });

    this.subscribe(this.siteModel.onRecordUpdated.$, (record) => {
      if (record.AppIdentifier === this.app.value.Identifier) {
        this.recordChanged(record).catch(error => logError(error, 'AppModel recordUpdated$'));
      }
    });

    this.subscribe(this.recordPersistService.recordCreated$, (record) => {
      if (record) {
        this.createdRecord$.next(record);
        this.updatedRecord$.next([record]);
        this.recordModified(record);
      }
    });

    this.subscribe(this.recordPersistService.recordPatching$, (recordPatching) => {
      if (recordPatching) {
        this.recordPatching$.next(recordPatching);
      }
    });

    this.subscribe(this.recordPersistService.recordUpdated$, (record) => {
      if (record) {
        this.updatedRecord$.next([record]);
        this.recordModified(record);
      }
    });

    const staticChanged$ = this.recordPersistService.recordPatched$.pipe(
      filter((patch) => patch._id === this.app.value?.StaticRecordId),
      debounceTime(10)
    );

    this.subscribe(staticChanged$, () => {
      // hack - reload static record if it is patched
      // handles case where it only contains ephermaral values
      this.loadStaticRecord().catch(error => logError(error, 'appModel staticChanged'));
    });

    this.subscribe(this.recordPersistService.patchApplied$, patch => {
      // A record has been changed so invalidate in indexes
      this.dataIndexService.recordChanged(patch._id, patch.AppIdentifier);
    });
  }

  async loadStaticRecord() {
    const app = this.app.value;

    if (app?.AppFields) {

      // Only load static data if we have  static fields in this app
      // Static records are WIP and we don't want to load static data for an online app
      // which will not find it and report errors
      const staticFields = app.AppFields.filter(field => field.IsStaticField);
      const persistentStaticFields = staticFields.filter(field => !field.isEmphermeral);
      const needsStatic = app.StaticRecordId && staticFields.length > 0;

      if (needsStatic) {
        try {
          const options: GetRecordOptions = { applyPatch: true };

          // If we don't have any persistent static fields, just get the patch that stores local values
          if (persistentStaticFields.length === 0) {
            options.patchOnly = true;
          }

          let record = await app.getRecordByIdAsync(app.StaticRecordId, options);
          if (!record) {
            // If we don't have a static record, create a new patch
            // This is a bit of a kludge, revist with full static record support
            const patch = new RecordPatch(app.StaticRecordId, app.Identifier);
            patch._new = true;
            await this.patchRecordValue(patch);

            record = patch.toRecord(app);
          }
          this.staticRecord.value = { ...record };
        } catch (error) {
          logError(error, 'loadStaticRecord');
        }
      } else {
        this.staticRecord.value = null;
      }
    }
  }

  /** Call when a record has been changed */
  public recordModified(record: Record) {
    // If any record other than the static record has changed, recalculae values
    // We need to exclude static record updates as this would cause a loop
    const staticId = this.app.value?.StaticRecordId;
    if (record._id === staticId) {
      this.staticRecord.value = { ...record };
    }

    // Allow the app to update in storage
    this.app.value?.recordsUpdatedAsync([record]).catch(e => logError(e, 'recordModified'));
  }

  public async routed(params: RouteParams) {
    this.appIdentifiers.value = params.appIdentifiers;

    if (params.parentRecordId) {
      this.parentRecord.value = await this.parentApp.value.getRecordByIdAsync(params.parentRecordId);
    } else {
      this.parentRecord.value = null;
    }

    await this.appContext.routed(params);
  }

  public async configure(appIdentifiers: AppIdentifiers, parentRecordId?: RecordId) {
    this.appContext.batch(() => {
      this.appContext.setIdentifiers(appIdentifiers);
      this.appContext.parentRecordId.value = parentRecordId;
    });

    this.appIdentifiers.value = appIdentifiers;

    if (parentRecordId) {
      this.parentRecord.value = await this.parentApp.value.getRecordByIdAsync(parentRecordId);
    } else {
      this.parentRecord.value = null;
    }
  }

  private async loadAppStyles(app: Application) {
    this.styles = this.namedStylesService.getAppStyles(app);
  }

  /**
   * Get style information for a set of style names
   * @param options Style options.
   *  target: UI object to apply styles to
   *  names: style names
   *  additionalStyles: additional hard-coded styles
   *  borderSideOverride: Forces location of 'border' named styles. A bit of a hack,
   *    should replace with specific border location properties
   * @returns ElementStyles for the element. The css member includes the css styles to apply
   */
  public getNamedStyles(
    options: {
      target: StyleTarget,
      names?: Array<IStyleName>,
      additionalStyles?: Array<Style>,
      record?: Record,
      borderSideOverride?: string
    }
  ): ElementStyles {
    const elementStyles: ElementStyles = {
      component: {
        Name: '',
        css: {}
      }
    };

    const allNames = options.names ? [...options.names] : [];
    const siteStyleNames = this.siteModel.settings.value;
    if (siteStyleNames?.NamedStyles?.length > 0) {
      allNames.push(...siteStyleNames.NamedStyles);
    }

    const styles: Array<Style> = [];

    allNames?.forEach((name) => {
      const style = this.styles?.find(s => s.Name === name.StyleName);
      // Match if correct target or (for legacy styles) no target specified
      if (style && (!style.Target || options.target === style.Target)) {
        let useStyle = true;
        if (style.StyleKeyValues && name.SourceFieldIdentifier && options.record) {
          useStyle = this.applyValueStyle(style, name, options.record, options.borderSideOverride);
        }

        if (useStyle) {
          styles.push({ ...style, Element: name.Element ?? style.Element ?? 'component' });
        }
      }
    });

    options.additionalStyles?.forEach((style) => {
      styles.push(style);
    });

    styles.forEach(style => {
      if (style) {
        const css = this.cssFromStyle(style);
        const element = style.Element || 'component';
        const existing = elementStyles[element];
        if (existing) {
          elementStyles[element] = {
            Name: '',
            Alignment: existing.Alignment ?? style.Alignment,
            Colour: existing.Colour || style.Colour,
            BackgroundColour: existing.BackgroundColour || style.BackgroundColour,
            DropShadow: existing.DropShadow ?? style.DropShadow,
            FontSize: existing.FontSize || style.FontSize,
            FontWeight: existing.FontWeight || style.FontWeight,
            Appearance: existing.Appearance || style.Appearance,
            css: { ...css, ...existing.css }
          };
        } else {
          elementStyles[element] = { ...style, css };
        }
      }
    });

    return elementStyles;
  }

  private applyValueStyle(style: Style, name: IStyleName, record: Record, borderSideOverride?: string) {
    const borderSide: any = borderSideOverride ?? 'all';
    const field = this.app.value.getField(name.SourceFieldIdentifier);
    if (field) {
      const recordValue = field.getRawRecordValue(record);
      const styleValue = style.StyleKeyValues.find(s => s.Name == recordValue);
      if (styleValue) {
        switch (name.Element) {
          case 'border':
            style.Border = { Location: borderSide, Colour: styleValue.Value, Width: '4px' };
            return true;
        }
      }
    }

    return false;
  }

  public isSearchable(): boolean {
    return this.app.value?.Fields?.findIndex((field) => field.IncludeInSearch) >= 0;
  }

  // Controller style methods
  // As this becomes more complex we should split into a real controller class

  public loadApp(appIdentifier: string) {
    const appIdentifiers = new AppIdentifiers();
    appIdentifiers.appIdentifier = appIdentifier;
    this.appIdentifiers.value = appIdentifiers;
  }

  public async patchRecordValue(patch: RecordPatch) {

    // Queue change
    await this.recordPersistService.queueChange(patch);

    // Mark indexes as potentially invalid
    this.dataIndexService.recordChanged(patch._id, patch.AppIdentifier);

    this.recordPatched$.next(patch);

    // todo Revalidate changed fields
    // AppDataEffects.updateAppDataInStorage did RevalidateFieldAction which calls
    // RecordValidationService.revalidate for the first record in state (so works for
    // record update but not for report edit mode).

    // todo headerfx re-inits.  updates breadcumbs, not needed yet for reports
    // when doing record view, should obvserve record change

    this.globalModel.persistQueuedChanges();
  }

  /** Reload app definition for this app */
  public async resyncConfig() {
    try {
      this.overlayService.openSpinner();
      const appIdentifier = this.app.value.Identifier;
      await this.siteModel.syncApplicationAsync(appIdentifier);

      // Reload to see updates
      location.reload();
    } finally {
      this.overlayService.close();
    }

  }

  public async export() {
    // coming soon
  }

  public async navigateToRecord(record: Record) {
    await this.globalModel.navigation.navigateRecordAsync({
      appIdentifiers: this.appIdentifiers.value,
      recordId: record._id
    });
  }

  public async saveFilterAsync(savedFilter: SavedFilter) {
    const filters = await this.savedFiltersApplication.saveFilter(this.app.value.Identifier, savedFilter);
    this.savedFilters.value = filters;
  }

  public async deleteSavedFilterAsync(id: string) {

    const filters = this.savedFilters.value;
    const index = filters?.findIndex((f) => f.Id === id);
    if (index >= 0) {
      // Call remove API
      const victim = filters[index];

      try {
        await this.savedFiltersApplication.deleteFilter(this.app.value.Identifier, victim);
      } catch (error) {
        this.globalModel.showErrorToasty({
          title: $localize`Failed to delete filter`,
          message: $localize`The filter may be referenced by a report or dashboard`
        });
        return;
      }

      // Remove locally
      const updated = [...filters];
      updated.splice(index, 1);
      this.savedFilters.value = updated;

      this.globalModel.showInfoToasty({
        title: $localize`Filter Deleted`,
        message: $localize`Filter ' ${victim.Description}' has been deleted`
      });
    }
  }

  /**
   * Copy the specified record
   * @param recordId
   * @param copyInfo
   * @param hierarchy
   * @returns The new record, null if it failed to create.
   */
  public async copyRecord(recordId: RecordId, copyInfo: RecordCopyInfo, hierarchy: string): Promise<Record> {
    try {
      const record: Record = await this.appDataService.copyRecordAsync(this.app.value, recordId, copyInfo, hierarchy);
      if (record && !record._new) {
        // add record to storage if successful
        await this.app.value.storeAsync(record);
        this.dataIndexService.recordChanged(record._id, this.app.value.Identifier);
      }

      return record;
    } catch (error) {
      if (error instanceof ValidationErrorResponse) {
        const message = error.ValidationErrors.length > 0 ? error.ValidationErrors[0].Message : '';
        this.globalModel.showErrorToasty({ title: $localize`Failed to copy record`, message });
      }
      return null;
    }
  }

  /** Update a report definition in the app */
  public async updateReportAsync(reportId: number, changes: any): Promise<Report> {

    const app = this.app.value;

    const report = await this.reportsService.updateReportAsync(app.Identifier, reportId, changes);

    // Update reports in app in situ
    const index = app.Reports.findIndex(rep => rep.Id === reportId);
    if (index >= 0) {
      app.Reports[index] = report;
    }

    return report;
  }

  // End controller methods

  public reindexRecord(recordId: RecordId) {
    const app = this.app.value;
    if (app) {
      this.dataIndexService.recordChanged(recordId, app.Identifier);
    }
  }

  private async recordChanged(record: Record) {
    this.dataIndexService.recordChanged(record._id, record.AppIdentifier);
    this.updatedRecord$.next([record]);
    this.recordModified(record);
  }

  private cssFromStyle(style: Style) {

    const css = {};

    if (style.Alignment) {
      css['align-self'] = style.Alignment;
    }

    if (style.Border) {
      const infix = style.Border.Location === 'all' ? '' : style.Border.Location + '-';
      css[`border-${infix}style`] = 'solid';
      css[`border-${infix}color`] = style.Border.Colour ?? '#808080';
      css[`border-${infix}width`] = style.Border.Width ?? '4px';
      if (style.Border.Radius) {
        css[`border-${infix}radius`] = style.Border.Radius;
      }
    }

    if (style.Colour) {
      css['color'] = style.Colour;
    }

    if (style.BackgroundColour) {
      css['background-color'] = style.BackgroundColour;
    }

    if (style.FontSize) {
      css['font-size'] = style.FontSize;
    }

    if (style.FontWeight) {
      css['font-weight'] = style.FontWeight;
    }

    if (style.DropShadow) {
      css['box-shadow'] = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';
    }

    if (style.Padding) {
      css['padding'] = style.Padding;
    }

    return css;
  }

  private setPriorityApps(app: Application) {

    const ids: Array<AppIdentifier> = ['Softools.NamedStyles'];
    ids.push(app.Identifier);

    if (app.ChildAppsIdentifiers?.length > 0) {
      ids.push(...app.ChildAppsIdentifiers.map(c => c.Identifier));
    }

    this.siteModel.priorityApps.value = ids;
  }
}
