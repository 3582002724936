<div *ngIf="url$|async" 
  class="d-flex align-items-center"
  [style.text-align]="alignment"  
  [title]="url$|async" >
  <span *ngIf="fieldModel.DisplayOptions?.LabelPosition === 'before' " class="mx-2" >
    {{fieldModel.Label}}
  </span>
  <fa-icon  [icon]="['fal', icon]" 
    class="pointer st-text-theme-primary"
    size="2x" aria-hidden="true"
    (click)="clicked($event)" >
    </fa-icon>
    <span *ngIf="fieldModel.DisplayOptions?.LabelPosition === 'after' " class="mx-2" >
      {{fieldModel.Label}}
    </span>
</div>

<div *ngIf="!(url$|async)" class="pointer" [style.text-align]="alignment" i18n-title title="Download Unavailable" (click)="clicked($event)" >
  <fa-stack >
    <fa-icon [icon]="['fal', icon]"  class='st-text-theme-primary' stackItemSize="1x"></fa-icon>
    <fa-icon [icon]="['fal', 'ban']"  class='ban' stackItemSize="2x"></fa-icon>
  </fa-stack>
</div>