import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailActivity, UsersRepository } from 'app/core';
import { BusyModel, EmailActivityModel } from 'app/mvc';
import { ComponentBase } from 'app/softoolsui.module';
import { ModelProperty } from 'app/vertex';

export interface EmailActivityParameters {
  userId: string;
}

@Component({
  selector: 'app-email-activity',
  templateUrl: './email-activity.component.html',
  styleUrls: ['./email-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailActivityComponent extends ComponentBase implements OnInit, OnDestroy {
  protected model = new EmailActivityModel();

  constructor(
    protected usersRepository: UsersRepository,
    public dialog: MatDialogRef<EmailActivityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailActivityParameters
  ) {
    super();
  }

  ngOnInit() {
    this.model.error.value = false;

    this.subscribe(this.dialog.afterOpened(), () => {
      this.model.loading.setLoading(true);
      this.usersRepository
        .getEmailActivity(this.data.userId)
        .then((activity) => {
          this.model.emailActivity.value = activity;
        })
        .catch(() => {
          this.model.error.value = true;
        })
        .finally(() => {
          this.model.loading.setLoading(false);
        });
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.model.dispose();
  }
}
