import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HistoryComponent } from 'app/softoolsui.module/history.component/history.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [HistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    FontAwesomeModule
  ],
  exports: [HistoryComponent],
  providers: []
})
export class HistoryModule { }
