import { ChangeDetectionStrategy, Component, Input, Type } from '@angular/core';
import { Enums } from '@softools/softools-core';
import { EditableFieldBase } from 'app/softoolsui.module/fields/editable-field-base';
import { AppField } from 'app/types/fields/app-field';
import { FieldComponent } from '../field/field.component';
import { SelectionCheckFieldComponent } from '../selection-check-field/selection-check-field.component';
import { SelectionFieldComponent } from '../selection-field/selection-field.component';
import { SelectionListFieldComponent } from '../selection-list-field/selection-list-field.component';
import { SelectionRadioFieldComponent } from '../selection-radio-field/selection-radio-field.component';
import { ListFieldComponent } from './list-field.component';

@Component({
  selector: 'sof-list-cell-field',
  templateUrl: './list-cell-field.component.html',
  styleUrls: ['./list-cell-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCellFieldComponent extends FieldComponent {

  @Input() public containingList: ListFieldComponent;

  protected override _initEditableField(fieldComponent: EditableFieldBase<any, AppField<any>>): void {
    super._initEditableField(fieldComponent);

    this.subscribe(fieldComponent.editValidationErrors$, (errors) => {
      this.containingList?.subFieldErrorsChanged(errors, this.fieldModel, this.listRow);
    });
  }

  protected override selectionComponentType(): Type<EditableFieldBase> {
    switch (this.fieldModel.SelectListType) {
      case Enums.SelectListType.Radio:
        return SelectionRadioFieldComponent;
      case Enums.SelectListType.Checkbox:
        return SelectionCheckFieldComponent;
      case Enums.SelectListType.Listbox:
        return SelectionListFieldComponent;
      case Enums.SelectListType.Select:
      default:
        return SelectionFieldComponent;
    }
  }
}
