import { Dashboard, IFilterTerm, IVisibility, TemplateReference } from '../interfaces';
import { Enums } from '../index';
import { Field, AlignmentTypeAlias } from './field.interface';
import { IStyleName } from './style-name.interface';
import { IOrder } from './order.interface';

export interface Report {
  Id: number;
  Identifier: string;

  /** Associated app.  NOT ALWAYS SET */
  AppIdentifier?: string;

  /** If specified, the report is only visible if a user/team permission is present  */
  TeamAppVisibilities?: Array<IVisibility>;

  AllowMultiSelect: boolean;

  NamedStyles?: Array<IStyleName>;

  /**
   * Simple base filter string.  This only includes filter terms.  A base saved filter (@see BaseFilterId)
   * should be used instead.
   */
  BaseFilter?: string;

  /**
   * Id of saved filter that applies as a base filter for this report.  Data for the report is always
   * filtered by this filter if it is specified (it can be added to by the selected filter or ad hoc filtering).
   * Because this allows grouping and sorting and easier management it should be used in preference to the
   * @see BaseFilter string.
   */
  BaseFilterId?: string;

  Category: string;
  ChartConfig?: any;
  ChartTypeString: string;
  ChildFieldIdentifier?: number;    // not used in WS
  Dashboard?: Dashboard;
  DisplayAttachmentsCount: boolean;
  DisplayCommentsCount: boolean;
  DisplayOrder: number;
  InAppDataSourceFieldId: number;
  IsDefault: boolean;
  IsHidden: boolean;
  ListReportDetailFields: Array<DetailsField>;
  /** fields for list report; also used for table fields  */
  ListReportFields?: Array<ReportField>;
  NestedChartReports?: Array<any>;
  ParentFieldIdentifier?: number;    // not used in WS
  QuickEditListReport: boolean;
  SubTitle: string;
  SystemTitle: string;
  TargetClickThroughReportId: number;
  TemplateId?: number;    // not used in WS
  Title: string;
  TitleAppTranslationId?: number;    // not used in WS
  Type: Enums.ReportTypes;
  TypeString?: string;    // not used in WS
  Chart: Chart;
  InAppDataSourceFieldIndentifier: string;
  AutoLayoutOffByDefault: boolean;

  /**
   * If specified, describes a template to display at the top of the report.
   * This should only contain static fields.
   * No app studio support
   */
  HeaderTemplate?: TemplateReference;

  // Dual report support
  DualReportIdentifier?: string;
  DualReportPosition?: DualReportPositionEnum;

  /** Card report details for card report */
  CardReport?: CardReport;

  /** Fixed report filter */
  Filter?: Array<IFilterTerm>;

  /** Optional grouping for report */
  GroupBy?: IOrder;

  /** Optional default sort order for report */
  Sort?: IOrder;

  /** Table report - is report directly editable? */
  EditMode?: ReportEditMode;
}

export enum ReportEditMode {
  Always = 0,
  Never = 1,
  Switch = 2
}

export enum CardReportOrientation {
  Vertical,
  Horizontal,
  HorizontalAlt,
}

export interface CardReport {

  /** Card specification to use on report */
  Card?: string;

  /** Field to group into swimlanes */
  CardGroupingFieldIdentifier?: string;

  /** Swim-lane orientation */
  Orientation?: CardReportOrientation;

  /** Number of cards to display across a group column or row  */
  GroupCardCount?: number;

  IncludeUndefinedGroups?: boolean;

  /** Styles to apply to group header card */
  HeaderStyles?: Array<IStyleName>;

  /**
   * Field definitions that define the action to be performed.
   * Must be a button or other type that has a default action implementation
   */
  Actions?: {
    OnClick?: Array<Field>;
    OnDoubleClick?: Array<Field>;
    OnLongPress?: Array<Field>;
  }
}

export interface Chart {
  Identifier: string;
  ChartFields: Array<ChartField>;
  ChartType: Enums.ChartType;
  DisplayTable: boolean;
  DisplayChart: boolean;
  BackgroundImageUri?: string;
  XaxisMax?: number;
  XaxisMin?: number;
  XaxisReversed?: boolean;
  YaxisMax?: number;
  YaxisMin?: number;
  YaxisReversed?: boolean;
  Height?: number;
  AllowOverlap?: boolean;
  LegendTitle?: string;
  ZoomType?: number; // TODO: Replace with enum when moved
  LabelType?: number; // TODO: Replace with enum when moved
  HideSeries?: boolean;

  /** 
   * If true, values on the chart are sorted using the field's natural
   * order (e.g. display order if present). Otherwise the field is sorted
   * alphabetically using its display format.
   */
  SortFieldOrder?: boolean;

  // Pie specific
  HasValueFields: boolean;
  ShowInLegend?: boolean; // Whether to display this particular series or series type in the legend. Since 2.1, pies are not shown in the legend by default.

  // Polar Chart specific
  PolarCalculation?: number; // TODO: Replace with enum when moved

  // Map specific
  Map?: number; // TODO: Replace with enum when moved
  HeatmapColorAxisStops?: string;
  SeriesColourOverrides?: string;

  // MonteCarlo specific
  MonteCarloType?: number; // TODO: Replace with enum when moved
  Iteration?: number; // TODO: Replace with enum when moved

  // Cumulative matrix specific
  ColumnFieldIdentifier?: string;
  SingleAxis?: boolean;
  OrderColumnCategories?: boolean;

  // Matrix specifics
  XAxisFieldIdentifier?: string;
  YAxisFieldIdentifier?: string;
  DetailsFieldIdentifier?: string;
  CellBackgroundColourSource?: Enums.CellBackgroundColourSource;
  CellBackgroundColourFieldIdentifier?: string;
  RowColumnTotals?: Enums.RowColumnTotals;
  CellType?: Enums.CellType;

  // Gauge specfics
  Reversed?: boolean;
  ArcLength?: number;

  // Summary series specifics
  SeriesNameFieldIdentifier?: string;
  IncludeVariance?: boolean;
  VarianceLabel?: string;
  VarianceColour?: string;
  CapacityFieldIdentifier?: string;
  CapacityLabel?: string;
  CapacityColour?: string;
  PartialCapacity?: number;
  PartialCapacityColour?: string;
  PartialCapacity2?: number;
  PartialCapacityColour2?: string;
  PartialCapacity3?: number;
  PartialCapacityColour3?: string;
  ShowPoints?: boolean;
}

export interface DetailsField {
  DisplayOrder: number;
  Id?: number;
  FieldIdentifier: string;

  /** 
   * @deprecated flag indicates sortability on record. 
   * This is now defined on @see Field and this will be removed.
   */
  Sortable?: boolean;

  /** Alignment string; left/ center/ right. No value will be default alignment. */
  Alignment?: AlignmentTypeAlias;
}

export interface ReportField extends DetailsField {
  DisplayOrder: number;
  ColumnPriority?: number;
  Hidden: boolean;
  ShortLabel?: string;

  SummaryExpression?: string;

  // Table report field properties

  /**
   * Is table report column sticky?
   * If Enabled is true, it is always sticky.
   * If Width is specified, sticky if window width > Width to allow responsive stickiness.
   */
  StickyMetaData?: {
    Enabled?: boolean;
    Width?: number;
  };

  /**Width of table report column */
  ColumnWidthMetaData?: ColumnWidthMetaData;

  /** Override editable state in table reports */
  DisableEdit?: boolean;
}

export type WidthSpecifier = 'default' | 'tiny' | 'small' | 'medium' | 'wide' | number;

export interface WidthParametersMetaData {
  Min?: WidthSpecifierMetaData;
  Max?: WidthSpecifierMetaData;
  Elasticity?: number;
}

export interface WidthSpecifierMetaData {
  Named?: WidthSpecifier;
  Value?: number;
}

export interface ColumnWidthMetaData {
  /** Fixed width if specified */
  WidthSpecifier?: WidthSpecifierMetaData;
  /** Variable width parameters */
  WidthParameters?: WidthParametersMetaData;
}

export enum ChartFieldType {
  ActualStart = 0,
  ActualEnd = 1,
  Colour = 2,
  Cumulative = 3,
  HoverText = 4,
  Label = 5,
  PieLevel = 6,
  ProjectName = 7,
  Series = 8,
  Status = 9,
  TargetStart = 10,
  TargetEnd = 11,
  X = 12,
  Y = 13,
  Z = 14,
  MonteCarlo = 15,
  Geography = 16,
  Value = 17,
  Latitude = 18,
  Longitude = 19,
  LocationMetaDataJson = 20,
  Gauge = 21,
  GoogleMapTitle = 22,
  GoogleMapDescription = 23,
  GoogleMapLinkName = 24,
  GoogleMapLinkUrl = 25,
  NetworkLevel = 26,
  NetworkAggregate = 27,
  Order = 28
}

export interface ChartField {
  Label: string;
  Type: ChartFieldType;
  BaseFieldIdentifier: string;
  Order: number;
  Calculation: number;
  Function: number;
  SeriesType: number;
  ShowOnChart: boolean;
  ToolTipDecimals: number;
  BaseField?: Field;
  ValueFieldIdentifier?: string;
  ValueField?: Field;
}

export interface GaugeChartField extends ChartField {
  LowerPercentage?: number;
  UpperPercentage?: number;
  ValueSuffix?: string;
  OverallAverageLabel?: string;
  RecordLabel?: string;
}

/** Specifies the position of the addional report when Dual reports are configured  */
export enum DualReportPositionEnum {
  Above = 0,
  Below = 1,
  Left = 2,
  Right = 3
}
