import { BooleanModelProperty, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';
import { Point } from '@softools/softools-core';
import { ReportModel } from 'app/mvc';
import { ReportController } from 'app/mvc/reports/report.controller';
import { ControlColumnMode } from './table-report-types';

export class TableReportController extends ReportController {

  public constructor(reportModel: ReportModel) {
    super(reportModel);
  }

  public readonly showRecordEdit = new BooleanModelProperty(this, true)
    .withLogging('show record edit', false);

  /** Current state of control column */
  public readonly controlColumnMode = new ModelProperty<ControlColumnMode>(this, ControlColumnMode.Record)
    .withLogging('Control Column Mode');

  /** Next state of control column after it is clicked */
  public readonly nextControlColumnMode = new ProjectionModelPoperty(this.controlColumnMode,
    (mode) => this.getNextControlColumnMode(mode)
  );

  /** Indicates whether the report is currently editable. */
  public readonly editable = new BooleanModelProperty(this, true);

  /** Pixel position of header popup */
  public readonly popupLocation = new ModelProperty<Point>(this, { x: 0, y: 0 });

  public setRecordControlMode() {
    this.controlColumnMode.value = ControlColumnMode.Record;
  }

  public setSelectControlMode() {
    this.controlColumnMode.value = ControlColumnMode.Select;
  }

  public setAllSelectedControlMode() {
    this.controlColumnMode.value = ControlColumnMode.AllSelected;
  }

  /** Identify the next control mode - selects the first column widget */
  protected getNextControlColumnMode(mode: ControlColumnMode) {

    let next = mode;

    switch (mode) {
      case ControlColumnMode.Record:
        next = ControlColumnMode.Select;
        break;

      case ControlColumnMode.Select:
        next = (this.appModel.app.value?.capabilities?.noSelectAll !== true) ?
          ControlColumnMode.AllSelected : ControlColumnMode.Record;
        break;

      case ControlColumnMode.AllSelected:
        next = ControlColumnMode.Record;
        break;

      default:
        next = ControlColumnMode.Record;
        break;
    }

    if (!this.editable.value && next === ControlColumnMode.Record) {
      next = ControlColumnMode.Select;
    }

    return next;
  }
}
