import { Enums } from '../types';

export function sortAscending(array: Array<any>, propertyName: string) {
  return array.sort((val1, val2) => {
    if (val1[propertyName] > val2[propertyName]) {
      return 1;
    }
    if (val1[propertyName] < val2[propertyName]) {
      return -1;
    }
    return 0;
  });
}

export function stringCompare(s1: string, s2: string): number {
  if (s1 === null || s1 === undefined) {
    return s2 === null || s2 === undefined ? 0 : 1;
  } else if (s2 === null || s2 === undefined) {
    return -1;
  } else {
    return s1.localeCompare(s2);
  }
}

export function isString(object: any) {
  return object && (object instanceof String || typeof (object) === 'string');
}

export function isDefined(val) {
    if (val !== undefined && val !== null) {
      return true;
    }
    return false;
}

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
