import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Enums, logError, Record, Template, TemplateLayout } from '@softools/softools-core';
import { AppIdentifiers } from 'app/services/record/app-info';
import { AppHomeModel } from 'app/mvc';
import { HomeItem } from 'app/mvc/home/home-item.interface';
import { ComponentBase } from 'app/softoolsui.module';
import { ICard } from 'app/types/application';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';

const marginControls = [{
  Side: 'right',
  DisplayOrder: 10,
  Field: {
    DisplayFormatted: false,
    DisplayOptions: { ShowAsIcon: true },
    ExcludeFromTemplateCopy: false,
    IncludeInSearch: false,
    Identifier: 'Home:Discard',
    Label: 'Discard Changes',
    SystemLabel: 'Discard Changes',
    Type: Enums.FieldType.ImageActionButton,
    IsEditable: true,
    IsReadOnly: false,
    ImageActionButtonSize: 1,
    ImageActionButtonType: Enums.ImageActionButtonType.DiscardChanges
  }
}];

@Component({
  selector: 'app-home-record-cards',
  templateUrl: './home-record-cards.component.html',
  styleUrls: ['./home-record-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeRecordCardsComponent extends ComponentBase implements OnInit {

  @Input() public appHomeModel: AppHomeModel;

  @Input() generalController: IGeneralController;

  @Input() public set items(items: Array<HomeItem>) {
    if (this.forChildHome && this.appIdentifiers) {
      const hieraarchy = `${this.appIdentifiers.parentAppIdentifier}|${this.parentRecordId}`;
      this.homeItems = items.filter(item => item.hierarchy === hieraarchy);
    } else {
      this.homeItems = items;
    }
  }

  @Input() public records: Array<Record>;

  @Input() public appIdentifiers: AppIdentifiers;

  @Input() public forChildHome = false;

  @Input() private parentRecordId: string;

  public homeItems: Array<HomeItem>;

  public card: ICard;

  public cardTemplate: Template;

  public ngOnInit(): void {
    this.card = this.prepareCard();
    const app = this.appHomeModel.appModel.app.value;

    const templates = this.card?.Templates?.map(t => {
      if (t.TemplateIdentifier) {
        return app.Templates.find(c => c.Identifier === t.TemplateIdentifier);
      } else if (t.Template) {
        return t.Template;
      }

      return null;
    });

    // grab first template - needs to be more robust
    if (templates?.length > 0) {
      this.cardTemplate = templates.filter(t => !!t)[0];
    }
  }

  public itemRecord(item: HomeItem) {
    return item._id && this.records && this.records.find(r => r._id === item._id);
  }

  private prepareCard(): ICard {
    const app = this.appHomeModel.appModel.app.value;
    if (app) {
      const cards = app.Cards;
      const homeCardIdentifier = `AppHomeCard${app.Identifier}`;
      // Lookup home card by id; fall back to Default flag but this is deprecated and unlikely to be in real config
      const baseCard = cards?.find(c => c.Identifier === homeCardIdentifier) ?? cards?.find(c => c.Default);
      if (baseCard) {
        return { ...baseCard, MarginFields: marginControls };
      } else {
        return this.defaultCard();
      }
    }

    return null;
  }

  private defaultCard(): ICard {
    const app = this.appHomeModel.appModel.app.value;
    const card: ICard = {
      Identifier: 'HomepageCard',
      Default: true,
      Templates: [{
        Template: {
          CollapsedByDefault: false,
          HelpText: '',
          Identifier: 'HomepageCard',
          IsCustom: false,
          SystemTitle: '',
          Title: '',
          Layout: []
        }
      }],
      Width: 300,
      Height: 120,
      MarginFields: marginControls
    };

    if (app.TitleFieldIdentifier) {
      card.Templates[0].Template.Layout.push({
        ColumnId: 1,
        RowId: 1,
        FieldIdentifier: app.TitleFieldIdentifier,
        Mode: 0,
        Style: {
          Name: '.apphome-id',
          FontWeight: 'bold'
        }
      });
    }

    // Add id.  Give it a label if we don't have a title field
    // or it looks a bit
    const idLayout: TemplateLayout = {
      ColumnId: 1,
      RowId: app.TitleFieldIdentifier ? 2 : 1,
      FieldIdentifier: '_id',
      Mode: 0,
    };

    if (app.TitleFieldIdentifier) {
      idLayout.HideLabel = true;
      idLayout.Style = {
        Name: '.apphome-id',
        FontSize: 'x-small'
      };
    }

    card.Templates[0].Template.Layout.push(idLayout);

    return card;
  }

  public async cardClicked(item: HomeItem) {
    try {
      const id = item._id;
      const navigation = this.appHomeModel.appModel.globalModel.navigation;
      const app = this.appHomeModel.appModel.app.value;

      if (this.forChildHome) {
        await navigation.navigateChildRecordAsync({ appIdentifiers: this.appIdentifiers, recordId: id });
      } else {
        await navigation.navigateRecordAsync({ appIdentifier: app.Identifier, recordId: id });
      }
    } catch (error) {
      logError(error, 'cardClicked');
    }
  }
}
