import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { logError, DocumentsRepository, logMessage } from '@softools/softools-core';
import { SiteService } from 'app/services/site-service.service';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { ContainerType } from 'app/softoolsui.module/fields/field-base';
import { DownloadInfo } from 'app/types/download-info.interface';
import { DocumentAppField, IDocumentFieldValue } from 'app/types/fields/document-app-field';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-document-field',
  templateUrl: './document-field.component.html',
  styleUrls: ['./document-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentFieldComponent extends EditableFieldBase<IDocumentFieldValue, DocumentAppField> implements OnInit, OnChanges {

  public noDocUploadedText = $localize`No document uploaded`;

  public assetId: string;

  public filename: string;

  public size: number;

  public mimeType: string;

  public unknownType = false;

  public iconSpec: IconProp = ['fas', 'file'];

  public showSelectionPopup = false;

  public uploading$ = new BehaviorSubject(false);

  public iconTransform: string;

  public horizontal = false;

  public fieldHeight: number;

  public iconWidth: number;

  public maxUploadSize: number;

  /*
    If set the UI will ask for delete confirmation
  */
  public deleteMode = false;

  private readonly uploadError = $localize`Error uploading document`;
  private readonly tooLargeHeader = $localize`Invalid Document`;
  private readonly tooLarge: string;

  constructor(
    private documentsRepository: DocumentsRepository,
    site: SiteService
  ) {
    super();

    this.maxUploadSize = site.Site.MaxDocumentFileSizeMB ?? 10;
    this.tooLarge = $localize`The selected file is too large to upload. Maximum size is ${this.maxUploadSize} Mb.`;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.setLayout();
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes['containerType']) {
      this.setLayout();
    }
  }

  protected override onValueChanged(value: IDocumentFieldValue) {
    super.onValueChanged(value);
    if (value) {
      this.assetId = value.assetId;
      this.filename = value.filename;
      this.size = value.size;
      this.mimeType = value.mimeType;
    } else {
      this.assetId = null;
      this.mimeType = null;
    }

    this.setIcon();
  }

  public setLayout() {
    switch (this.containerType) {
      case ContainerType.Form:
        this.iconTransform = '';
        this.fieldHeight = 8;
        this.iconWidth = 6;
        this.horizontal = false;
        break;
      case ContainerType.Grid:
        this.iconTransform = `scale(0.666)  translateY(-3rem)`;
        this.fieldHeight = 4;
        this.iconWidth = 4;
        this.horizontal = !false;
        break;
      case ContainerType.Inline:
      case ContainerType.List:
        this.iconTransform = `scale(0.3333) translateY(-6rem)`;
        this.fieldHeight = 2;
        this.iconWidth = 2;
        this.horizontal = true;
        break;
      case ContainerType.TableReport:
        this.iconTransform = `scale(0.3333) translateY(-6rem)`;
        this.fieldHeight = 2;
        this.iconWidth = 2;
        this.horizontal = true;
        break;
      case ContainerType.ListField:
      case ContainerType.FilterEdit:
      default:
        this.iconTransform = `scale(0.3333) translateY(-6rem)`;
        this.fieldHeight = 2;
        this.iconWidth = 2;
        this.horizontal = false;
        break;
    }
  }

  public onDocClickHandler($event: MouseEvent) {
    $event.stopPropagation();
    this.componentClicked$.next(this);
    if (!this.getIsDisabled()) {
      this.showSelectionPopup = true;
    } else {
      this.downloadClicked($event);
    }
  }

  public selectorClosed($event?: MouseEvent) {
    $event?.stopPropagation();
    this.showSelectionPopup = false;
    this.deleteMode = false;
  }

  public fileDraggedOver(event: any) {
    event.preventDefault();
  }

  public fileDropped(event: any) {
    event.preventDefault();
    if (!this.isDisabled()) {
      const dt = event.dataTransfer;
      if (!dt.items) {
        // Use DataTransfer interface to access the file
        this.uploadFileAndPatch(dt.files[0]).catch(error => logError(error, 'Failed to upload file and patch'));   // async
      } else if (dt.items.length > 0) {
        // Use DataTransferItemList interface to access the file
        if (dt.items.length > 0 && dt.items[0].kind === 'file') {
          const file = dt.items[0].getAsFile();
          this.uploadFileAndPatch(file).catch(error => logError(error, 'Failed to upload and patch'));    // async
        }
      }
    }
  }

  public onFileSelectedHandler(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0] as File;
      this.uploadFileAndPatch(file).catch(error => logError(error, 'Failed to upload and patch'));
    }
  }

  public downloadClicked($event: MouseEvent) {
    try {
      $event.stopPropagation();
      if (this.record && this.value?.assetId) {
        const url = this.documentsRepository.downloadUrl(this.record.AppIdentifier, this.fieldModel.Identifier, this.record._id);
        const info: DownloadInfo = { url, filename: this.filename };
        this.appModel.globalModel.downloadNamedFileAsync(info).catch(e => logError(e, 'downloadNamedFile'));
      }
    } catch (error) {
      logError(error, 'DocumentField:download');
    }
  }

  public async trashClicked() {
    try {
      this.showSelectionPopup = false;    // close popup
      // todo can we do anything to clear unused assets?
      const patch = this.fieldModel.createResetPatch(this.record, this.listRow);
      await this.dispatchPatchAsync(patch);
    } catch (error) {
      logError(error, 'trashClicked');
    } finally {
      this.deleteMode = false;
    }
  }

  private async uploadFileAndPatch(file: File) {

    try {
      this.showSelectionPopup = false;    // close popup
      this.uploading$.next(true);

      // Check file size first
      if (file.size > (this.maxUploadSize * 1024 * 1024)) {
        this.globalModel.showErrorToasty({
          title: this.tooLargeHeader,
          message: this.tooLarge
        });
        return;
      }

      // Upload file and get doc id
      const id = await this.documentsRepository.upload(this.appIdentifiers.visibleAppIdentifier, this.record._id, file);

      if (id) {

        // Locally update values so we don't see incorrect values flash up
        this.assetId = id;
        this.filename = file.name;
        this.size = file.size;
        this.setIcon();

        const patch = this.fieldModel.createFilePatch(this.record, id, file, this.listRow);
        await this.dispatchPatchAsync(patch);
      } else {
        this.globalModel.showErrorToasty({
          message: this.uploadError
        });
      }
    } catch (error) {
      logError(error, 'DocumentFieldComponent uploadFileAndPatch');
      if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.PayloadTooLarge) {
        this.globalModel.showErrorToasty({
          title: this.uploadError,
          message: this.tooLarge
        });
      } else {
        this.globalModel.showErrorToasty({
          message: this.uploadError
        });
      }
    } finally {
      this.uploading$.next(false);
    }
  }

  private setIcon() {

    if (this.mimeType) {
      const icon = this.iconMap.get(this.mimeType);
      if (icon) {
        this.unknownType = false;
        this.iconSpec = ['fal', icon];
        return;
      }
    }

    const dotIndex = this.filename?.lastIndexOf('.');
    if (dotIndex > 0) {
      const ext = this.filename.substr(dotIndex);
      const icon = this.iconMap.get(ext);
      if (icon) {
        this.unknownType = false;
        this.iconSpec = ['fal', icon];
        return;
      }
    }

    this.iconSpec = ['fal', 'file'];
    this.unknownType = true;
  }

  /** Lookup icon by mime type or extension (with .)  */
  /* eslint-disable-next-line @typescript-eslint/member-ordering */
  private readonly iconMap = new Map<string, IconName>([
    ['text/csv', 'file-csv'],
    ['.csv', 'file-csv'],
    ['application/msword', 'file-word'],
    ['.doc', 'file-word'],
    ['.docx', 'file-word'],
    ['application/pdf', 'file-pdf'],
    ['.pdf', 'file-pdf'],
    ['image/png', 'file-image'],
    ['.png', 'file-image'],
    ['image/jpeg', 'file-image'],
    ['.jpeg', 'file-image'],
    ['.jpg', 'file-image'],
    ['text/plain', 'file-alt'],
    ['.txt', 'file-alt'],
    ['application/vnd.ms-excel', 'file-excel'],
    ['.xls', 'file-excel'],
    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'file-excel'],
    ['.xlsx', 'file-excel'],
    ['.ppt', 'file-powerpoint'],
    ['.pptx', 'file-powerpoint'],
    ['application/vnd.ms-powerpoint', 'file-powerpoint'],
    ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'file-powerpoint'],
    ['text/xml', 'file-code'],
    ['application/xml', 'file-code'],
    ['.xml', 'file-code'],
    ['application/zip', 'file-archive'],
    ['.zip', 'file-archive'],
    ['video/mpeg', 'file-video'],
    ['.mpeg', 'file-video'],
    ['.mp4', 'file-video'],
    ['video/ogg', 'file-video'],
    ['.ogv', 'file-video'],
    ['video/mp2t', 'file-video'],
    ['.ts', 'file-video'],
    ['video/webm', 'file-video'],
    ['.webm', 'file-video'],

  ]);

}
