import { StorageMode } from './enums';
import { App, Report, ReportEditMode, ReportField } from '@softools/softools-core';
import { environment } from 'environments/environment';

export interface AppTweak {
  siteIdentifier: string;
  appIdentifier: string;
  environments?: Array<string>
  maxSyncRecords?: number;
  storageMode?: StorageMode;
  AllowAttachments?: boolean;
  AllowComments?: boolean;

  AppTweaks?: any;
  FieldTweaks?: any;
  FormTweaks?: any;
  TemplateTweaks?: any;
  TemplateLayoutTweaks?: any;
  ReportTweaks?: any;
}

const tweaks: Array<AppTweak> = [

  // Embedded/Settings apps
  // Some types properties can't be set in JSON so they are overriden herre
  // siteIdentifier '*' used to indicate all sites
  {
    siteIdentifier: '*',
    appIdentifier: 'Softools.Assets',
    ReportTweaks: {
      Assets: {
        //        EditMode: 1,    // should be this but allows click into record should check capability
        ListReportFields: {
          MIMEType: { Alignment: 'center' },
          Bytes: { Alignment: 'center' },
          Uri: { Alignment: 'center' },
        }
      }
    }
  },

  {
    siteIdentifier: 'atlas',
    appIdentifier: 'WeekNumbering',
    maxSyncRecords: 500,
  },

  // Online apps. We  have appstudio support for this now so should remove

  // Force Honda KPI to operate online (SOF-6757)
  {
    siteIdentifier: 'ibmhonda',
    appIdentifier: 'KPITool',
    storageMode: StorageMode.Online,
  },

  {
    siteIdentifier: 'kpmg-fr',
    appIdentifier: 'Questions',
    AppTweaks: {
      AllowAttachments: false,
      AllowComments: false,
    },
  },

  // CCBJI.BETA
  // Set up advanced filters for demo
  // {
  //   siteIdentifier: 'ccbji',
  //   appIdentifier: 'icardapp',
  //   environments: ['beta'],
  //   AppTweaks: {
  //     FilterFieldIdentifiers: [
  //       { Identifier: 'UID' },
  //       { Identifier: 'ICardCategory' },
  //       { Identifier: 'Icard8Wastes' },
  //       { Identifier: 'Icard8StrategyPillars' },
  //       { Identifier: 'ICardID' },
  //       { Identifier: 'DepartmentCode' },
  //       { Identifier: 'Owner' },
  //     ]
  //   },
  //   ReportTweaks: {
  //     ICardsList: {
  //       Filter: [
  //         // $filter=[Project] eq false
  //         // and 5efbb3d08296d46de44ea916 missing?
  //         { Operator: 1, FieldIdentifier: 'Project', Operand: false }
  //       ]
  //     },
  //     // ProjectRegister: {
  //     //   // and 5efbaa2dabd2c53894e05647 missing?
  //     // },
  //     ICard: {
  //       Filter: [
  //         // $filter=[ExcellenceFlag] eq 'Yes'
  //         // and 5ecfe2b939fe374accfa3bfc missing?
  //         { Operator: 1, FieldIdentifier: 'ExcellenceFlag', Operand: 'Yes' }
  //       ]
  //     },
  //     ICardIdeasRO: {
  //       // [SubmitToSupervisor] eq 'Submit ICard' and [RegisterICard] ne 'Registered ICard' 
  //       // and [Date] gt datetime'2021-01-01T00:00' and [ICardID] ne ' '
  //       Filter: [
  //         { Operator: 1, FieldIdentifier: 'SubmitToSupervisor', Operand: 'Submit ICard' },
  //         { Operator: 2, FieldIdentifier: 'RegisterICard', Operand: 'Registered ICard' },
  //         { Operator: 3, FieldIdentifier: 'Date', Operand: { $date: 1609459200000 } },
  //         { Operator: 2, FieldIdentifier: 'ICardID', Operand: ' ' },
  //       ]
  //     },
  //     ICardNotApprovedRO: {
  //       // $filter=[RegisterICard] ne 'Registered ICard' 
  //       // and [SubmitToSupervisor] eq 'Submitted ICard' and [Date] gt datetime'2021-01-01T00:00'
  //       Filter: [
  //         { Operator: 2, FieldIdentifier: 'RegisterICard', Operand: 'Registered ICard' },
  //         { Operator: 1, FieldIdentifier: 'SubmitToSupervisor', Operand: 'Submitted ICard' },
  //         { Operator: 3, FieldIdentifier: 'Date', Operand: { $date: 1609459200000 } },
  //       ]
  //     },
  //     ICardRegisterTable: {
  //       Filter: [
  //         { Operator: 1, FieldIdentifier: 'RegisterICard', Operand: 'Registered ICard' },
  //         { Operator: 2, FieldIdentifier: 'ICardID', Operand: '      ' },
  //       ]
  //     },
  //     ICardRegisterTableAdmin: {
  //       Filter: [
  //         { Operator: 1, FieldIdentifier: 'RegisterICard', Operand: 'Registered ICard' },
  //         { Operator: 2, FieldIdentifier: 'ICardID', Operand: '      ' },
  //       ]
  //     },
  //     ICardBestPractices: {
  //       // $filter=[BestPractice] eq true and [ICardID] ne ' '
  //       Filter: [
  //         { Operator: 1, FieldIdentifier: 'BestPractice', Operand: true },
  //         { Operator: 2, FieldIdentifier: 'ICardID', Operand: ' ' },
  //       ]
  //     },
  //     ICardAwards: {
  //       // $filter=[RegisterICard] eq 'Registered ICard' and [FlagSection] eq 'Ticked'
  //       Filter: [
  //         { Operator: 1, FieldIdentifier: 'RegisterICard', Operand: 'Registered ICard' },
  //         { Operator: 1, FieldIdentifier: 'FlagSection', Operand: 'Ticked' },
  //       ]
  //     },
  //   }
  // },  

  {
    siteIdentifier: 'demo',
    appIdentifier: 'RAIDRegister',
    AppTweaks: {
      Cards: [{
        Identifier: 'Raid',
        Templates: [{
          TemplateIdentifier: 'RAIDCard'
        }],
        Width: 360,
        Height: 300
      }],
      NamedStyles: [{ StyleName: 'bold-black-label' }]
    },
    ReportTweaks: {
      CardReport: {
        Id: 12345,
        Identifier: 'RAIDCard',
        AppIdentifier: 'RAIDRegister',
        AllowMultiSelect: false,
        BaseFilter: '',
        Category: '',
        DisplayOrder: 1,
        IsDefault: false,
        IsHidden: false,
        SubTitle: '',
        SystemTitle: 'RAID Cards',
        Title: 'RAID Cards',
        Type: 7,
        TypeString: 'card',
        CardReport: {
          GroupCardCount: 1,
        }
      }
    },
    TemplateLayoutTweaks: {
      RAIDCard: {
        // Confidence
        31194: {
          ShortLabel: 'Conf.'
        },
        // Description
        31197: {
          ShortLabel: 'Description'
        }
      },
      Overview: {
        // Description
        7119: {
          ShortLabel: 'Description',
          NamedStyles: [{ StyleName: 'green-label' }]
        }
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'Cars',
    environments: ['staging', 'local'],
    TemplateLayoutTweaks: {
      Reverse: {
        31389: {
          SpanRows: 2
        }
      }
    },
    ReportTweaks: {
      // AS not doing contains none of yet
      NotFordNorAMC: {
        Filter: [
          { FieldIdentifier: "Car", Operator: 18, Operand: ['Ford', 'AMC'] }
        ]
      },
      FamilyCars: {
        Filter: [
          { Operator: 21, FieldIdentifier: "Seats", Operand: [4, 6] }
        ]
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'AllFields',
    environments: ['staging', 'local'],
    FieldTweaks: {
      Reference: {
        Type: 41,
        ReferenceOptions: {
          TargetAppIdentifier: 'Cars',
          ReportIdentifier: 'CarsTable'
        }
      },
    },
    ReportTweaks: {
      TemporalGrouped: {
        // not possible in AS (can't use space for date)
        Filter: [
          { Operator: 2, FieldIdentifier: 'Date', Operand: '' },
        ]
      },
    },
    TemplateLayoutTweaks: {
      References: {
        // Person
        33951: {
          Style: {
            Name: '.Person',
            Appearance: 'icon|text'
          }
        }
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'ChartApp',
    AppTweaks: {
      FilterFieldIdentifiers: [
        { Identifier: 'defaultfalse' },
        { Identifier: 'colour' }
      ]
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'AllApp',
    ReportTweaks: {
      CardReport: {
        Id: 167928,
        Identifier: 'CardReportx',
        AppIdentifier: 'AllApp',
        DisplayOrder: 1,
        Type: 7,
        TypeString: 'card',
        CardReport: {
          Card: 'AllCard',
          GroupCardCount: 2,
        }
      }
    },

    TemplateLayoutTweaks: {
      PeopleCard: {
        // "Person"
        33309: {
          Style: {
            Name: '.Dude',
            Appearance: 'icon|text'
          }
        }
      }
    }
  },

  // https://staging-demo.on.softools.net/FinancialGrid
  // Showing click to edit and readonly field formatting before AS support (SOF-12793)
  {
    siteIdentifier: 'demo',
    appIdentifier: 'FinancialGrid',
    FieldTweaks: {
      Summary_ProductSales: { ClickToEdit: true, Formatting: { Currency: 'GBP' } },
      Summary_ServicesSales: { ClickToEdit: true, Formatting: { Currency: 'GBP' } },
      Summary_Total: { Formatting: { Currency: 'GBP' } },
      ReadonlySummary_ProductSales: { Formatting: { Currency: 'GBP' } },
      ReadonlySummary_ServicesSales: { Formatting: { Currency: 'GBP' } },
      ReadonlySummary_Total: { Formatting: { Currency: 'GBP' } },
      Period: { ClickToEdit: true },
      Comment1: { ClickToEdit: true },
      Comment2: { ClickToEdit: true }
    }
  },

  {
    siteIdentifier: 'softools',
    appIdentifier: 'devsprint',
    ReportTweaks: {
      // https://team.on.softools.net/devsprint/SprintPlanner
      SprintPlanner: {
        Filter: [
          // not possible in AS (no 'between')
          {
            Operator: 21,
            FieldIdentifier: "oSprint",
            Operand: [1, 99]
          },
          {
            FieldIdentifier: "dStatus",
            Operator: 18,
            Operand: ['Done', 'Closed', 'Ready To Test']
          }
        ],
      },
      ApiAsReleaseTable: {
        // not possible in AS (can't use space for date)
        Filter: [
          { Operator: 2, FieldIdentifier: 'ReleaseDate', Operand: '' },
        ]
      },
      Zendesk: {
        Filter: [
          {
            FieldIdentifier: "dStatus",
            Operator: 18,
            Operand: ['Done', 'Closed']
          },
          {
          // not possible in AS (can't use space)
            FieldIdentifier: 'osZendeskTicket',
            Operator: 2,
            Operand: ''
          },
        ]
      },
      IntheNextRelease: {
        Filter: [
          {
            FieldIdentifier: "dStatus",
            Operator: 1,
            Operand: 'Done'
          },
          {
            // not possible in AS (not sortable/all used?)
            FieldIdentifier: 'ReleaseDateSet',
            Operator: 1,
            Operand: false
          },
        ]
      }
    },
    TemplateLayoutTweaks: {
      TicketCard: {
        // oTitle
        28271: {
          HideLabel: true,
          SpanColumns: 3,
          NamedStyle: [{ StyleName: 'card-header' }]
        },
        // pAssignee
        28272: {
          SpanRows: 2,
          Style: {
            Name: '.Assignee',
            Appearance: 'icon|text'
          }
        }
      }
    }
  },

  {
    siteIdentifier: 'softools',
    appIdentifier: 'RAID',
    FieldTweaks: {
      Checklist: {
        ListFieldPagination: true
      }
    },
    TemplateLayoutTweaks: {
      CardReportFront: {
        // NextSteps (Updated)
        28280: {
          SpanColumns: 3
        },
        // Description
        28285: {
          SpanColumns: 3
        }
      }
    }
  },

  // // https://team.on.softools.net/Deal/PipelineFilterDemo
  {
    siteIdentifier: 'softools',
    appIdentifier: 'Deal',
    ReportTweaks: {
      SupportContracts: {
        Filter: [
          { Operator: 1, FieldIdentifier: "SupportContract", Operand: 'Yes' },
          { Operator: 2, FieldIdentifier: "SupportStatus", Operand: 'Closed' }
        ]
      },
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'Package',
    ReportTweaks: {
      SoftoolsInternal: {
        TeamAppVisibilities: [{
          TeamId: '555dd633913f9f087426075e',
          Show: 1
        }]
      },
      GuestsOnly: {
        TeamAppVisibilities: [{
          TeamId: '52753880dd5f49093c37dbf8',
          Show: 1
        }]
      },
      JohnPrivate: {
        TeamAppVisibilities: [{
          UserId: 'dc33488d-83c3-45d3-8bf4-633b6f124cc2',
          Show: 1
        }]
      },
      JohnWithAnAlias: {
        TeamAppVisibilities: [{
          UserId: '520532c8-179d-428a-923f-eb279062bdaf',
          Show: 1
        }]
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'AccountingPeriod',
    AppTweaks: {
      StaticRecordId: '606eebe541b1333d59694432',
    },
    FieldTweaks: {
      CurrentPeriod: {
        IsStaticField: true
      }
    },
    TemplateTweaks: {
      Current: {
        Static: true
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'RAIDRegister',
    AppTweaks: {
      AllowAttachments: false,
      AllowComments: false,
    },
    ReportTweaks: {
      'Raids': {
        ListReportFields: {
          'Effort': {
            SummaryExpression: 'sum'
          },
          'Budget': {
            SummaryExpression: 'avg'
          }
        }
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'ListFields',
    AppTweaks: {
      Styles: [
        {
          name: 'listback',
          backgroundColour: 'lightgoldenrodyellow'
        }
      ],
      Rules: [
        // test list row styling
        {
          Filter: 'ListOfIconicFields_Text eq \'2a\'',
          TargetFields: [
            { Identifier: 'ListOfIconicFields' }
          ],
          Actions: [
            { Type: 4, NamedStyle: 'listback' },
          ],
          MultiRow: true
        },
        {
          Filter: 'ListOfIconicFields_Text eq \'2a\'',
          TargetFields: [
            { Identifier: 'ListOfIconicFields' }
          ],
          TargetSubFields: ['Harvey'],
          Actions: [
            { Type: 1 },
          ],
          MultiRow: true
        },
        {
          Filter: '$filter=[Text] eq \'hideme\'',
          TargetFields: [
            { Identifier: 'ListOfIconicFields' }
          ],
          TargetSubFields: [
            { Identifier: 'Text' },
            { Identifier: 'LongText' },
            { Identifier: 'Rag' }
          ],
          Actions: [
            { Type: 0 },
          ],
        },
        {
          Filter: '$filter=[Text] eq \'hideme\'',
          TargetFields: [
            { Identifier: 'ListOfIconicFields' }
          ],
          Actions: [
            { Type: 2 },
            { Type: 3 },
          ],
        },
        {
          Filter: '$filter=[Text] eq \'colourme\'',
          TargetFields: [
            { Identifier: 'Selections' }
          ],
          Actions: [
            { Type: 4, NamedStyle: 'lobster' },
            { Type: 2 },
            { Type: 3 },
          ],
        },
        {
          Filter: '[Text] eq \'colourme\'',
          TargetFields: [
            { Identifier: 'ListOfIconicFields' },
            { Identifier: 'Textual' }
          ],
          TargetSubFields: [{ Identifier: 'Text' }],
          Actions: [
            { Type: 4, NamedStyle: 'lobster' },
          ],
          MultiRow: true
        },
      ]
    },
  },
  {
    siteIdentifier: 'demo',
    appIdentifier: 'TimeTrack',
    AppTweaks: {
      Styles: [
        {
          name: 'yellowback',
          backgroundColour: 'goldenrod'
        },
        {
          name: 'whiteback',
          backgroundColour: 'white'
        }
      ],
    },
    FormTweaks: {
      Entry: {
        NamedStyles: [{ StyleName: 'yellowback' }]
      }
    },
    FieldTweaks: {
      'Activity': {
        NamedStyles: [{ StyleName: 'whiteback' }]
      },
      'Tasks': {
        NamedStyles: [{ StyleName: 'whiteback' }]
      },
    }
  },
  {
    siteIdentifier: 'demo',
    appIdentifier: 'ChiyodaTest',
    ReportTweaks: {
      'ResourceChart': {
        Chart: {
          ChartType: 18,
          CapacityFieldIdentifier: 'Capacity',
          CapacityLabel: 'Capacity',
          DisplayTable: false,
          IncludeVariance: true,
          VarianceLabel: 'Utilization',
          VarianceColour: 'red',
          PartialCapacity: 70,
          PartialCapacityColour: '#ccff99',
          ChartFields: [
            {
              BaseFieldIdentifier: 'CSSColours',
              Calculation: 0,
              Function: 0,
              Label: 'Colour',
              LastUpdate: 'AAAAAAA+4GQ=',
              Order: 2,
              Type: 2,
            }, {
              BaseFieldIdentifier: 'OrderNumber',
              Calculation: 0,
              Function: 0,
              Label: 'Order',
              LastUpdate: 'AAAAAAA+4GQ=',
              Order: 2,
              Type: 28,
            }
          ]
        }
      }
    }
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'ParentToDocApp',
    ReportTweaks: {
      'Tablewithdoc': {
        ListReportFields: {
          'Document': {
            FieldIdentifier: 'ChildDoc'
          }
        }
      }
    }
  },
  {
    siteIdentifier: 'demo',
    appIdentifier: 'ChildAppOne',
    AppTweaks: {
      LinkPickerReportIdentifier: 'ChildTable'
    }
  },
  {
    siteIdentifier: 'demo',
    appIdentifier: 'ChildAppWithDocument',
    ReportTweaks: {
      'tablewithdocument': {
        ListReportFields: {
          'ChildDocTitle': {
            FieldIdentifier: 'DocField'
          }
        }
      }
    }
  },
  {
    siteIdentifier: 'demo',
    appIdentifier: 'ReferenceFieldTest',
    FieldTweaks: {
      UserReference: {
        Type: 41,
        ReferenceOptions: {
          TargetAppIdentifier: 'Softools.User',
          ReportIdentifier: 'Lookup'
        }
      },
      Person: {
        Person: {
          UseReferences: true
        }
      },
      DevappReference: {
        Type: 41,
        ReferenceOptions: {
          TargetAppIdentifier: 'AllApp',
          ReportIdentifier: 'reportwithnogroup'
        }
      },
      CarReference: {
        Type: 41,
        ReferenceOptions: {
          TargetAppIdentifier: 'Cars',
          ReportIdentifier: 'Lookup'
        }
      },
      Country: {
        Type: 41,
        ReferenceOptions: {
          TargetAppIdentifier: 'Countries',
          ReportIdentifier: 'Lookup'
        }
      }
    },
  },

  {
    siteIdentifier: 'demo',
    appIdentifier: 'StyleTestBackgrounds',
    FieldTweaks: {
      'Name': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'Manufacturer': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'IncepDate': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'Completeness': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'ProblemCode': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'AIPredictedCode': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'PercentConfidence': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'DeviceName': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      'IncidentClassification': {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      ManufacturerAwarenessDate2: {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      SubmissionDate2: {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      StartOfIncident2: {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },
      EndOfIncident2: {
        Style: {
          Name: '.display-value',
          Appearance: 'value',
          Padding: '0.5rem'
        }
      },

    },
  },

  {
    siteIdentifier: 'bluememe',
    appIdentifier: 'ResourcePlan',
    ReportTweaks: {
      'ResourceChart': {
        Chart: {
          ChartType: 18,
          CapacityFieldIdentifier: 'Capacity',
          CapacityLabel: 'Capacity',
          IncludeVariance: true,
          VarianceLabel: 'Utilization',
          PartialCapacity: 70,
          ChartFields: [
            {
              BaseFieldIdentifier: 'ResourceScheduleColour',
              Calculation: 0,
              Function: 0,
              Label: 'Colour',
              LastUpdate: 'AAAAAAA+4GQ=',
              Order: 2,
              Type: 2,
            }, {
              BaseFieldIdentifier: 'ResourceScheduleNumber',
              Calculation: 0,
              Function: 0,
              Label: 'Order',
              LastUpdate: 'AAAAAAA+4GQ=',
              Order: 2,
              Type: 28,
            }
          ]
        }
      }
    }
  },
  {
    siteIdentifier: 'dyson',
    appIdentifier: 'OBAChecklistV2',
    AppTweaks: {
      UseOfflineCopy: true,
      Styles: [
        {
          name: 'PassFailRow',
          backgroundColour: 'lightgoldenrodyellow'
        }
      ],
      Rules: [
        {
          Filter: '$filter=[MasterCheckList] eq false',
          TargetFields: [
            { Identifier: 'Checklist1' },
            { Identifier: 'Checklist2' },
            { Identifier: 'Checklist3' },
            { Identifier: 'Checklist4' },
            { Identifier: 'Checklist5' },
            { Identifier: 'Checklist6' }
          ],
          Actions: ['hideadd'],
        },
        {
          Filter: '$filter=[MasterCheckList] eq false',
          TargetFields: [
            { Identifier: 'Checklist1' },
            { Identifier: 'Checklist2' },
            { Identifier: 'Checklist3' },
            { Identifier: 'Checklist4' },
            { Identifier: 'Checklist5' },
            { Identifier: 'Checklist6' }
          ],
          TargetSubFields: ['Type', 'Category', 'Description'],
          Actions: ['readonly'],
        },
        {
          Filter: '$filter=[MasterCheckList] eq false',
          TargetFields: [
            { Identifier: 'DataInput' }
          ],
          Actions: ['hideadd'],
        },
        {
          Filter: '$filter=[MasterCheckList] eq false',
          TargetFields: [
            { Identifier: 'DataInput' }
          ],
          TargetSubFields: ['Type', 'Section', 'Category', 'Description'],
          Actions: ['readonly'],
        },

        {
          Filter: '[Checklist1_Type] eq \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist1' }
          ],
          Actions: [{ style: 'PassFailRow' }],
          MultiRow: true
        },
        {
          Filter: '[Checklist1_Type] ne \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist1' }
          ],
          TargetSubFields: ['PassFail'],
          Actions: ['hidden'],
          MultiRow: true
        },

        {
          Filter: '[Checklist2_Type] eq \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist2' }
          ],
          Actions: [{ style: 'PassFailRow' }],
          MultiRow: true
        },
        {
          Filter: '[Checklist2_Type] ne \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist2' }
          ],
          TargetSubFields: ['PassFail'],
          Actions: ['hidden'],
          MultiRow: true
        },

        {
          Filter: '[Checklist3_Type] eq \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist3' }
          ],
          Actions: [{ style: 'PassFailRow' }],
          MultiRow: true
        },
        {
          Filter: '[Checklist3_Type] ne \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist3' }
          ],
          TargetSubFields: ['PassFail'],
          Actions: ['hidden'],
          MultiRow: true
        },

        {
          Filter: '[Checklist4_Type] eq \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist4' }
          ],
          Actions: [{ style: 'PassFailRow' }],
          MultiRow: true
        },
        {
          Filter: '[Checklist4_Type] ne \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist4' }
          ],
          TargetSubFields: ['PassFail'],
          Actions: ['hidden'],
          MultiRow: true
        },

        {
          Filter: '[Checklist5_Type] eq \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist5' }
          ],
          Actions: [{ style: 'PassFailRow' }],
          MultiRow: true
        },
        {
          Filter: '[Checklist5_Type] ne \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist5' }
          ],
          TargetSubFields: ['PassFail'],
          Actions: ['hidden'],
          MultiRow: true
        },

        {
          Filter: '[Checklist6_Type] eq \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist6' }
          ],
          Actions: [{ style: 'PassFailRow' }],
          MultiRow: true
        },
        {
          Filter: '[Checklist6_Type] ne \'Category Total\'',
          TargetFields: [
            { Identifier: 'Checklist6' }
          ],
          TargetSubFields: ['PassFail'],
          Actions: ['hidden'],
          MultiRow: true
        },
        {
          Filter: '[MasterCheckList] eq false',
          TargetFields: [
            { Identifier: 'DataInputSection1' },
            { Identifier: 'DataInputSection2' },
            { Identifier: 'DataInputSection3' },
            { Identifier: 'DataInputSection4' },
            { Identifier: 'DataInputSection5' },
            { Identifier: 'DataInputSection6' }
          ],
          TargetSubFields: ['Type', 'Category', 'Description'],
          Actions: ['readonly'],
        },
        {
          Filter: '[MasterCheckList] eq false',
          TargetFields: [
            { Identifier: 'DataInputSection1' },
            { Identifier: 'DataInputSection2' },
            { Identifier: 'DataInputSection3' },
            { Identifier: 'DataInputSection4' },
            { Identifier: 'DataInputSection5' },
            { Identifier: 'DataInputSection6' }
          ],
          Actions: ['hideadd'],
        },
      ]
    },
  },
];

export function getAppTweaks(siteIdentifier: string, appIdentifier: string) {
  return tweaks.find((tweak) => tweak.siteIdentifier === siteIdentifier && tweak.appIdentifier === appIdentifier);
}

/** Update application config from a tweak  */
export function patchApp(siteIdentifier: string, app: App) {
  const appTweaks = getAppTweaks(siteIdentifier, app.Identifier);
  if (appTweaks) {

    // If environments specified must include current
    if (!appTweaks.environments || (appTweaks.environments.includes(environment.build))) {
      if (appTweaks.AppTweaks) {
        Object.getOwnPropertyNames(appTweaks.AppTweaks).forEach((name) => {
          app[name] = appTweaks.AppTweaks[name];
        });
      }

      if (appTweaks.FieldTweaks) {
        Object.getOwnPropertyNames(appTweaks.FieldTweaks).forEach((fieldName) => {
          const field = app.Fields.find((f) => f.Identifier === fieldName);
          if (field) {
            const fieldTweak = appTweaks.FieldTweaks[fieldName];
            Object.assign(field, fieldTweak);
            console.warn(field);
          }
        });
      }

      if (appTweaks.FormTweaks) {
        Object.getOwnPropertyNames(appTweaks.FormTweaks).forEach((formName) => {
          const form = app.Forms.find((f) => f.Identifier === formName);
          if (form) {
            const formTweak = appTweaks.FormTweaks[formName];
            Object.assign(form, formTweak);
            console.warn(form);
          }
        });
      }

      if (appTweaks.TemplateTweaks) {
        Object.getOwnPropertyNames(appTweaks.TemplateTweaks).forEach((templateName) => {
          const template = app.Templates.find((t) => t.Identifier === templateName);
          if (template) {
            const templateTweak = appTweaks.TemplateTweaks[templateName];
            Object.assign(template, templateTweak);
            console.warn(template);
          }
        });
      }

      if (appTweaks.TemplateLayoutTweaks) {
        Object.getOwnPropertyNames(appTweaks.TemplateLayoutTweaks).forEach((templateName) => {
          const template = app.Templates.find((t) => t.Identifier === templateName);
          if (template) {
            const tweak = appTweaks.TemplateLayoutTweaks[templateName];
            for (const id in tweak) {
              if (id) {
                const layout = template.Layout.find(l => l.Id === +id);
                if (layout) {
                  Object.assign(layout, tweak[id]);
                  console.warn('Tweak layout', layout);
                }
              }
            }
          }
        });
      }

      if (appTweaks.ReportTweaks) {
        Object.getOwnPropertyNames(appTweaks.ReportTweaks).forEach((reportName) => {
          let report = app.Reports.find((r) => r.Identifier === reportName);
          if (!report) {
            report = { Identifier: reportName } as Report;
            app.Reports.push(report);
          }

          const reportTweak = { ...appTweaks.ReportTweaks[reportName] };
          if (reportTweak.Chart && report.Chart) {
            reportTweak.Chart = { ...reportTweak.Chart };
            reportTweak.Chart.ChartFields = [...(report.Chart.ChartFields || []), ...(reportTweak.Chart.ChartFields || [])];
            reportTweak.Chart = { ...report.Chart, ...reportTweak.Chart };
          }

          // Update any tweaked report fields unless we've just added a report
          // in which case they'll be set below
          if (reportTweak.ListReportFields && report.ListReportFields?.length > 0) {
            const updated: Array<ReportField> = [];
            report.ListReportFields.forEach((reportField) => {
              const reportFieldTweak = reportTweak.ListReportFields[reportField.FieldIdentifier];
              if (reportFieldTweak) {
                updated.push({ ...reportField, ...reportFieldTweak });
              } else {
                updated.push(reportField);
              }
            });

            reportTweak.ListReportFields = updated;
          }

          Object.assign(report, reportTweak);
        });
      }
    }
  }
}
