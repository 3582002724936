import { Injectable } from '@angular/core';
import { RepositoryBase, RequestHeaders } from './repositories.base';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImageRepository extends RepositoryBase {

  /**
   *
   * @param assetId
   */
  public async getImageByAssetId(assetId: string): Promise<Blob> {
    const url = `${environment.baseUrl}/Api/AssetsDownloadSas/${assetId}`;
    var fileResource = await lastValueFrom(this.http
      .get<{ Url: string }>(url, {
        headers: RequestHeaders(),
      }));

    return await lastValueFrom(this.http
      .get(fileResource.Url, {
        responseType: 'blob'
      }));
  }

  /**
   *
   * @param assetId
   */
  public async getImageByImageId(assetId: string, size: 'tiny' | 'medium' | 'full'): Promise<Blob> {

    var part = size === 'tiny' ? 'Tiny' : size === 'medium' ? 'Medium' : '';
    const url = `${environment.baseUrl}/Api/ImageSas/${assetId}/${part}`;
    var fileResource = await lastValueFrom(this.http
      .get<{ Url: string }>(url, {
        headers: RequestHeaders(),
      }));

    return await lastValueFrom(this.http
      .get(fileResource.Url, {
        responseType: 'blob'
      }));
  }
}
