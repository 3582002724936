<!-- Grid wrapper for horizontal grid field (this grid field shows horizontal stripes)-->
<div *ngIf="fieldModel?.Orientation === 1 && fieldModel?.Type === fieldTypes.GridField" class="wrapper mb-4 overflow-auto  vertical-grid">
  <!-- Vertical orientation -->
  <!-- Blank space top left square -->
  <ng-container *ngIf="!fieldModel.HideGridRowTitle">
    <div [style.grid-row]="1" [style.grid-column]="1" class="d-flex justify-content-end align-items-center border-bottom p-2">
      <fa-icon *ngIf="cornerIcon" [icon]="['fal', 'grip-horizontal']" size="2x"></fa-icon>
    </div>
  </ng-container>

  <!-- Header labels -->
  <div
    class="d-flex justify-content-start align-items-center border-bottom p-2"
    *ngFor="let gridDataSet of fieldModel.GridDataSets; let i = index; trackBy: gridDataSetTrackByFn"
    [style.grid-row]="1"
    [class.body-even]="i % 2 === 0"
    [class.body-odd]="i % 2 === 1"
  >
    <b class="st-text-theme-primary">{{ gridDataSet.Label }}</b>
  </div>

  <!-- Row labels -->
  <ng-container *ngIf="!fieldModel.HideGridRowTitle">
    <div
      *ngFor="let subFieldIdentifiers of fieldModel.SubFieldsIdentifiers; let i = index"
      class="d-flex justify-content-end align-items-center p-2 head-even"
      [style.grid-row]="i * 2 + 3"
      [style.grid-column]="1"
    >
      <b class="st-text-theme-primary text-truncate">{{ getFieldLabel(subFieldIdentifiers.Identifier) }}</b>
    </div>
  </ng-container>

  <!-- Header labels -->
  <ng-container *ngFor="let header of rowHeaders(); let h = index">
    <div
      *ngIf="header"
      class="d-flex text-truncate justify-content-start align-items-center p-2"
      [style.grid-row]="h * 2 + 2"
      [style.grid-column-start]="1"
      [style.grid-column-end]="gridFullRowEnd()"
    >
      <span class="row-header st-text-theme-primary">{{ header }}</span>
    </div>
  </ng-container>

  <!-- Data Cells -->
  <ng-container *ngFor="let set of fieldModel.GridDataSets; let i = index">
    <ng-container  *ngFor="let cell of set.GridCellIdentifiers; let j = index">
      <!-- <div [style.grid-row]="j + 2" [style.grid-column]="i + 2" class="d-flex justify-content-end align-items-center border-bottom pe-2">
              <fa-icon [icon]="['fal', 'external-link-alt']" size="1x" class="st-text-theme-primary"></fa-icon>
          </div> -->

      <div
        class="d-flex justify-content-start align-items-center p-2"
        [style.grid-row]="j * 2 + 3"
        [style.grid-column]="i + (fieldModel.HideGridRowTitle ? 1 : 2)"
        [class.body-even]="i % 2 === 0"
        [class.body-odd]="i % 2 === 1"
      >
        <ng-container *ngTemplateOutlet="fields; context: { field: getField(cell.DataCellIdentifier) }"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="fieldModel && fieldModel.Orientation === 0 && fieldModel.Type === fieldTypes.GridField" class="wrapper mb-4 overflow-auto horizontal-grid">
  <!-- Horizontal orientation -->
  <ng-container *ngIf="!fieldModel.HideGridRowTitle">
    <div [style.grid-row]="1" [style.grid-column]="1" class="d-flex justify-content-end align-items-center border-bottom p-2">
      <fa-icon *ngIf="cornerIcon" [icon]="['fal', 'grip-horizontal']" size="2x"></fa-icon>
    </div>
  </ng-container>

  <!-- Header labels -->
  <div
    class="d-flex justify-content-start align-items-center border-bottom p-2"
    *ngFor="let subFieldIdentifiers of fieldModel.SubFieldsIdentifiers; let i = index"
    [style.grid-row]="1"
    [class.body-even]="i % 2 === 0"
    [class.body-odd]="i % 2 === 1"
  >
    <b class="st-text-theme-primary">{{ getFieldLabel(subFieldIdentifiers.Identifier) }}</b>
  </div>

  <!-- Data Cells this uses auto layout for rows, only columns are set -->
  <ng-template ngFor let-set [ngForOf]="fieldModel.GridDataSets" let-i="index">
    <!-- Row Header -->
    <div
      *ngIf="set.HeaderText"
      class="d-flex justify-content-start align-items-center p-2"
      [style.grid-column-start]="1"
      [style.grid-column-end]="gridFullRowEnd()"
    >
      <span class="row-header st-text-theme-primary">{{ set.HeaderText }}</span>
    </div>

    <!-- Row labels -->
    <ng-container *ngIf="!fieldModel.HideGridRowTitle">
      <div class="d-flex justify-content-end align-items-center p-2 head-even" [style.grid-column]="1">
        <b class="st-text-theme-primary text-truncate">{{ set.Label }}</b>
      </div>
    </ng-container>

    <!-- Cell Data -->
    <ng-template ngFor let-cell [ngForOf]="set.GridCellIdentifiers" let-j="index">
      <div
        class="d-flex justify-content-start align-items-center p-2"
        [style.grid-column]="j + (fieldModel.HideGridRowTitle ? 1 : 2)"
        [class.body-even]="i % 2 === 0"
        [class.body-odd]="i % 2 === 1"
      >
        <ng-container *ngTemplateOutlet="fields; context: { field: getField(cell.DataCellIdentifier) }"></ng-container>
      </div>
    </ng-template>
  </ng-template>
</div>

<!-- Text and number fields -->
<!-- ********* WOULD BE BETTER TO ADD TYPE TO GridDataSets ************ -->
<ng-template #fields let-field="field">
  <sof-grid-cell *ngIf="field && isSimpleField(field)"
    class="w-100"
    [appModel]="appModel"
    [application]="application"
    [appIdentifiers]="appIdentifiers"
    [fieldModel]="field"
    [containerField]="fieldModel"
    [containerReadOnly]="containerReadOnly"
    [record]="record"
    [template]="template"
    [form]="form"
    [containerType]="gridContainerType"
    [elementStyles]="elementStyles"
    [forReport]="false"
    [inline]="true" 
    (activateComponent)="onActivateComponent($event)"
    >
  </sof-grid-cell>

 <!--
      validation errors will be needed if we want submit buttons in a grid - unlikely!
       [isNewRecord]="isNewRecord"
     [validationErrors]="validationErrors"

  -->
</ng-template>
