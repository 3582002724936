<ng-container *ngIf="formTemplate">

  <app-form-template-header
    *ngIf="showTemplateHeader"
    [identifier]="formTemplate.Identifier"
    [helpText]="formTemplate.HelpText"
    [iconBase64]="formTemplate.IconBase64"
    [title]="formTemplate.Title"
    [expanded]="expanded"
    [expandedHelp]="expandedHelp"
    (onToggleExpandedFormTemplate)="onToggleExpandedFormTemplate.emit($event)"
    (onToggleExpandedFormTemplateHelp)="onToggleExpandedFormTemplateHelp.emit($event)">
  </app-form-template-header>

  <ng-container *ngIf="!showTemplateHeader || expanded">
    <div class="container-fluid pb-2" *ngIf="formTemplate.IsCustom">
      <app-custom-not-supported
        i18n-headerText headerText="Custom template is not supported"
        i18n-description description="(Please contact support to migrate this enhanced custom template to standard)">
      </app-custom-not-supported>
    </div>

    <!-- The content for the body for the template -->
    <ng-content></ng-content>

  </ng-container>

</ng-container>
