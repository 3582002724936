import { AppModel } from 'app/mvc';
import { Application } from 'app/types/application';
import { Report } from './reports.interface';

export enum LookupCaptionStyle {
  Lookup = 1,
  Select,
  MultiSelect,
  SelectWithAppRecordName,
  MultiSelectWithAppRecordName,
}

export interface LookupOptions {
  appIdentifier: string;
  reportIdentifier?: string;
  searchLookupAppField?: string;
  searchValue?: any;
  multiSelect: boolean;
  /**  ids for initial selection */
  selectedIds: Array<string>;
  uiState: any;
  captionStyle?: LookupCaptionStyle;

  // Lookup app and report - internal to new lookup component
  // Should configure and make mandatory
  targetApp?: Application;
  report?: Report;
}

export const initialLookupOptions: LookupOptions = {
  appIdentifier: '',
  searchLookupAppField: '',
  searchValue: null,
  multiSelect: false,
  selectedIds: [],
  uiState: null
};
