
export interface EmbeddedVideoData {
  title: string;
  description: string;
  url: string;
}

export const initialEmbeddedVideoData: EmbeddedVideoData = {
  title: '',
  description: '',
  url: ''
};
