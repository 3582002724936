import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {

  @Input() text;
  @Input() icon;
  @Input() hideClose = false;
  @Input() title = '';
  @Input() closeTitle = '';

  // Url is currently ignored.  It was used to nanigate when close was clicked
  // If it is needed again, change the control based on whether the url is specified
  // including routerLink="{{url}}"  routerLinkActive="active" and emit a new event
  // (old code used the same event with different arguments).  Also consider the
  // online status.
  @Input() url = '';

  @Output() onBadgeClick = new EventEmitter<MouseEvent>();
  @Output() onBadgeCloseClick = new EventEmitter<MouseEvent>();

  public clicked = (e: MouseEvent): void => {
    e.stopPropagation();
    this.onBadgeClick.emit(e);
  }

  public closeClicked = (e: MouseEvent): void => {
    e.stopPropagation();
    this.onBadgeCloseClick.emit(e);
  }

}
