import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Record, logError, ElementStyles, CardReportOrientation, Field, Enums } from '@softools/softools-core';
import { AppModel, BusyModel, Causes, GlobalModel } from 'app/mvc';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { CardReportModel, IDragItem } from 'app/mvc/reports/card-report.model';
import { InjectService } from 'app/services/locator.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { ComponentBase } from 'app/softoolsui.module';
import { IPerformContext } from 'app/types/fields/app-field';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { IColumn } from '../ws-card-report.component';

interface IRange {
  first: number;
  last: number;
}

export interface ICardInfo {
  row: number;
  record: Record;
}

/** Button field definition for default click action */
const defaultClickField: Field = {
  DisplayFormatted: false,
  ExcludeFromTemplateCopy: false,
  IncludeInSearch: false,
  Identifier: 'OpenRecord',
  Label: $localize`Open Record`,
  SystemLabel: $localize`Open Record`,
  Type: Enums.FieldType.ImageActionButton,
  IsEditable: true,
  IsReadOnly: false,
  ImageActionButtonSize: Enums.ImageActionButtonSize.Medium,
  ImageActionButtonType: Enums.ImageActionButtonType.OpenRecord
}

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardDeckComponent extends ComponentBase implements OnInit, OnChanges {

  @Input() public column: IColumn;

  @Input() public groupColumnWidth: number;

  @Input() public rowHeight: number;

  @Input() public cardsPerColumn: number;

  @Input() public cardModel: CardReportModel;

  @Input() public headerStyle: ElementStyles;

  @Input() generalController: IGeneralController;

  @Input() orientation: CardReportOrientation;

  /**
   * Template indexes of cards that are showing faces other than the first
   * Managed by parent component so it persists over decks scrolling in and out
   */
  @Input() cardIndexes: Map<string, number>;

  @Output() cardTemplateChanged = new EventEmitter<{ id: string, index: number }>();

  public globalModel: GlobalModel;

  public appModel: AppModel;

  public readonly marginPx = 14;

  /** Height of header tile parts */
  public readonly headerLabelHeight = 18;
  public readonly headerValueHeight = 48;
  public readonly headerHeight = this.headerLabelHeight + this.headerValueHeight;

  public readonly visibleRowRange$ = new BehaviorSubject<IRange>(null);

  public readonly visibleCards$ = new BehaviorSubject<Array<ICardInfo>>(null);

  public readonly orientationClass$ = new BehaviorSubject<string>('vert');

  public readonly scrollableHeight$ = new BehaviorSubject(0);

  public readonly scrollableWidth$ = new BehaviorSubject(0);

  public busy = new BusyModel();

  @InjectService(RecordPersistService)
  public readonly recordPersistService: RecordPersistService;

  /**
   * Number of cards to render above/below actual range
   * This adds a bit of overhead but smooths scrolling as it exposes existing
   * cards as it moves.  The higher the value the larger the overhead and
   * the bigger the scroll range that is improved.
   */
  private readonly overlap = 3;

  @ViewChild('content', { static: true, read: ElementRef })
  private contentArea: ElementRef<HTMLElement>;

  @ViewChild('box', { static: true, read: ElementRef })
  public boxElement: ElementRef<HTMLDivElement>;

  constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardsPerColumn'] || changes['column']) {
      // Calculate visible range - forces reload
      this.calculateVisibleRange();
    }

    if (changes['orientation']) {
      switch (this.orientation) {
        case CardReportOrientation.Horizontal:
          this.orientationClass$.next('horz');
          break;
        case CardReportOrientation.Vertical:
        default:
          this.orientationClass$.next('vert');
          break;
      }
    }
  }

  public ngOnInit(): void {
    this.appModel = this.cardModel.appModel;
    this.globalModel = this.appModel.globalModel;

    this.subscribe(this.cardModel.zoom.$, () => {
      this.calculateVisibleRange();
    });

    this.subscribe(this.visibleRowRange$.pipe(
      filter(range => !!range),
      debounceTime(10),
      distinctUntilChanged((r1, r2) => (r1.first === r2.first) && (r1.last === r2.last))
    ), (range) => {
      this.reload(range).catch(e => logError(e, 'reload'));
    });

    // Watch dragging from a group and enable drop if it's not this group
    this.subscribe(this.cardModel.draggingFromGroup.$, (item: IDragItem) => {
      if (item !== undefined) {

        if (item.dragging) {
          const droppable = (this.column.starts.value !== item.value);
          this.droppable$.next(droppable);
          return;
        }
      }

      this.droppable$.next(false);
    });

    this.subscribe(this.cardModel.columnGroups.$, () => {
      this.calculateVisibleRange();
      this.reload(this.visibleRowRange$.value).catch(e => logError(e, 'invalidateDeck'));
    });

    this.calculateVisibleRange();
  }

  public groupCardX(i: number) {
    const cardWidth = this.cardModel.card.value?.Width + (this.marginPx);
    if (this.orientation === CardReportOrientation.Vertical) {
      const width = cardWidth * this.cardModel.zoom.value;
      return (Math.floor(i % this.cardsPerColumn) * width);
    } else {
      const height = (cardWidth) * this.cardModel.zoom.value;
      return (Math.floor(i / this.cardsPerColumn) * height);
    }
  }

  public groupCardY(i: number) {
    if (this.orientation === CardReportOrientation.Vertical) {
      const height = (this.rowHeight) * this.cardModel.zoom.value;
      return (Math.floor(i / this.cardsPerColumn) * height);
    } else {
      const width = (this.cardModel.card.value?.Width + (this.marginPx)) * this.cardModel.zoom.value;
      return (Math.floor(i % this.cardsPerColumn) * width);
    }
  }

  public cardClicked(record: Record) {

    // Do single/first action - todo if more than one show popup menu
    // If none specified use default open record action
    const clickActions = this.cardModel.report.value.CardReport.Actions?.OnClick;
    const field = (clickActions?.length > 0) ? clickActions[0] : defaultClickField;

    const actionField = this.appModel.app.value.createAppField(field);
    actionField.attachModel(this.appModel);

    const context: IPerformContext = {
      value: null,
      record: record,
      appModel: this.appModel,
      reportModel: this.cardModel,
      generalController: this.generalController
    };

    actionField.perform(context);
  }

  public cardtemplateIndexChanged(index: number, record: Record) {
    this.cardTemplateChanged.emit({ id: record._id, index });
  }

  public getCardTemplateIndex(record: Record) {
    return this.cardIndexes.get(record._id) ?? 0;
  }

  public scrolled($event: Event) {
    this.calculateVisibleRange();
  }

  //////////////////////////////////////////////////////////////////
  // Drag and drop

  public readonly droppable$ = new BehaviorSubject(false);

  public draggedOver($event: DragEvent) {
    $event.preventDefault();
    $event.dataTransfer.dropEffect = "move";
  }

  public dragEnter($event: DragEvent) {
    // todo use this if we want to add drop feeback - or remove
    $event.preventDefault();
  }

  public dragLeave($event: DragEvent) {
    // todo use this if we want to add drop feeback - or remove
    $event.preventDefault();
  }

  public async droppped($event: DragEvent) {
    $event.preventDefault();


    if (this.cardModel.groupField.value) {
      // Get record and sanity check with id in drag data
      const record = this.cardModel.dragRecord.value;
      const id = $event.dataTransfer.getData('text/plain');
      if (record?._id === id) {
        this.cardModel.dropRecord.value = record;

        const newValue = this.column.starts.value;
        const patch = this.cardModel.groupField.value.createPatch(record, newValue, null);
        await this.appModel.patchRecordValue(patch);

        // Update index for the moved record to give immediate feedback
        await this.cardModel.recordChanged(record);
      }
    }
  }

  public async cardDragged(record: Record) {
    this.cardModel.batch(() => {
      if (record) {
        this.cardModel.draggingFromGroup.value = { dragging: true, value: this.column.starts.value };
      } else {
        this.cardModel.draggingFromGroup.value = { dragging: false };
      }

      this.cardModel.dragRecord.value = record;
    });
  }

  //////////////////////////////////////////////////////////////////


  public trackCard(_, card: ICardInfo) {
    return card.record._id;
  }

  private async reload(range: IRange) {
    try {
      this.busy.start(Causes.loading);

      // Find current column info - messy
      const groups = this.cardModel.columnGroups.value;
      const starts = groups?.starts.find(g => g.value === this.column.starts?.value);

      if (starts?.pos < 0) {
        this.visibleCards$.next([]);
        return;
      }

      if (starts?.records) {
        const records = starts.records.slice(range.first, range.last);

        const visibleCards: Array<ICardInfo> = records.map((rec, i) => ({
          row: i + range.first,
          record: rec
        }));
        this.visibleCards$.next(visibleCards);
      } else {
        // No records in cache so load from API (normal for online mode)
        const count = range.last - range.first;
        const records = await this.cardModel.getGroupRecords(starts, range.first, count);
        if (records !== null) {
          const visibleCards: Array<ICardInfo> = records.map((rec, i) => ({
            row: i + range.first,
            record: rec
          }));
          this.visibleCards$.next(visibleCards);
        }
      }

    } finally {
      this.busy.finish(Causes.loading);
    }
  }

  private calculateVisibleRange() {
    const container = this.contentArea?.nativeElement;
    if (container) {

      if (this.orientation === CardReportOrientation.Vertical) {
        const y = container.scrollTop;
        const height = container.clientHeight;
        const totalHeight = this.column.rows * this.rowHeight * this.cardModel.zoom.value / this.cardsPerColumn;
        const cardHeight = (this.cardModel.card.value?.Height + (this.marginPx)) * this.cardModel.zoom.value;
        const perColumn = this.cardsPerColumn;

        // Calculate first and last cards; offset by overlap amount without exceeding actual rows
        const first = Math.max(0, (Math.floor((y / cardHeight) - this.overlap) * perColumn));
        const count = Math.min(((Math.ceil(height / cardHeight) + 1 + this.overlap) * perColumn),
          this.column.rows * perColumn);
        const last = first + count;

        if (this.scrollableHeight$.value !== totalHeight) {
          this.scrollableHeight$.next(totalHeight);
        }

        const current = this.visibleRowRange$.value;
        if (!current || current.first !== first || current.last !== last) {
          this.visibleRowRange$.next({ first, last: count + last });
        }

      } else {
        const x = container.scrollLeft;
        const width = container.clientWidth;
        const cardWidth = (this.cardModel.card.value?.Width + (this.marginPx)) * this.cardModel.zoom.value;
        const totalWidth = this.column.rows * cardWidth;
        const perColumn = this.cardsPerColumn;

        // Calculate first and last cards; offset by overlap amount without exceeding actual rows
        const first = Math.max(0, (Math.floor((x / cardWidth)) * perColumn) - this.overlap);
        const count = Math.min(((Math.ceil(width / cardWidth) + 1) * perColumn) + this.overlap,
          this.column.rows * perColumn);

        if (this.scrollableWidth$.value !== totalWidth) {
          this.scrollableWidth$.next(totalWidth);
        }

        const current = this.visibleRowRange$.value;
        if (!current || current.first !== first || current.last !== count + first) {
          this.visibleRowRange$.next({ first, last: count + first });
        }
      }
    }
  }
}
