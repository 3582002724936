import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { ErrorSummary, QueryParams, QueryParameters } from '../types/interfaces';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorSummariesRepository extends RepositoryBase {

  /**
   * get total count of error summaries
   */
  public async getCountAsync(queryParams?: QueryParams ): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('ErrorSummaries', '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }

  /**
   * Get summary records.
   *
   * @param count         Number of summaries requested
   * @param skip          First record number to request
   * @param queryParams   Query params for filter, sort etc. (top & skip taken from arguments)
   */
  public async getErrorSummariesAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<ErrorSummary>> {

    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveDefaultApiUrl('ErrorSummaries');
    url = this.urlResolver.appendQueryParams(url, params);
    return lastValueFrom<Array<ErrorSummary>>(this.get(url));
  }

}
