import { BehaviorSubject, interval, of } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { Model, ModelProperty } from '@softools/vertex';

export class Cause {
  constructor(public readonly message) {
  }

  public count = 0;
}

export const Causes = {
  loading: new Cause($localize`Loading`),
  indexing: new Cause($localize`Indexing`),
  deleting: new Cause($localize`Deleting`),
  counting: new Cause($localize`Counting`),
  charting: new Cause($localize`Charting`),
  matrix: new Cause($localize`Entering the Matrix`),
  workflowRunning: new Cause($localize`Running Workflow`)
};

/**
 * Model for controlling busy state.
 * An activity is recorded using the start and finish methods.  These must be paired; use try/finally
 * or equivalent to ensure the finish method is called.  The parameter is a @see Cause which represents
 * the activity in progress.  A set of standard activities are provided by @see Causes.
 * Multiple activities can occur at the same time; the @see message property describes the earliest active
 * cause and should be used to provide feedback to the user.
 */
export class BusyModel extends Model<BusyModel>  {

/**
 * Emits changes to overall busy state.  This should be used for
 * high level checks e.g. hiding components during changes.
 */
  public readonly busy$ = new BehaviorSubject(false);

  public readonly message = new ModelProperty<string>();

  private readonly causes = new Array<Cause>();

  /**
   * Emits changes to overall busy state with a delay when entering the busy state.
   * Use this in preference to @see busy$ to only show the busy status when it is
   * taking a long time, avoiding flicker and rendeting delay for short operations.
   */
  public readonly busyDelayed$ = this.busy$.pipe(distinctUntilChanged(), debounce(b => b ? interval(500) : of(0)));

  public setIndexing(indexing = true) {
    if (indexing) {
      this.start(Causes.indexing);
    } else {
      this.finish(Causes.indexing);
    }
  }

  public get loading(): boolean {
    return !!this.causes.find(c => c.message === Causes.loading.message);
  }

  public get isIndexing(): boolean {
    return !!this.causes.find(c => c.message === Causes.indexing.message);
  }

  public setLoading(loading = true) {
    if (loading) {
      this.start(Causes.loading);
    } else {
      this.finish(Causes.loading);
    }
  }

  /**
   * Multiple workflows loading events get triggered at the same time.  This method only allows one to set busy to true.
   * @param loading
   */
  public setWorkflowRunning(loading = true) {
    if (loading && this.busy$.value === false) {
      this.start(Causes.workflowRunning);
    } else if (loading === false && this.busy$.value === true) {
      this.finish(Causes.workflowRunning);
    }
  }

  public start(cause: Cause) {
    const current = this.causes.find(c => c.message === cause.message);
    if (current) {
      current.count++;
    } else {
      const copy = new Cause(cause.message);
      this.causes.push(copy);
      copy.count++;
    }

    this.message.value = this.causes[0].message;
    this.busy$.next(true);
  }

  public finish(cause: Cause) {
    const i = this.causes.findIndex(c => c.message === cause.message);
    if (i >= 0) {
      const current = this.causes[i];
      if (--current.count < 1) {
        this.causes.splice(i, 1);

        // May have changed business so update
        this.message.value = this.causes.length > 0 ? this.causes[0].message : null;
        this.busy$.next(this.causes.length > 0);
      }
    }
  }
}
