import { logError } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelEvent, ModelProperty } from '@softools/vertex';
import { InjectService } from 'app/services/locator.service';
import { PermissionsService } from 'app/services/permissions.service';
import { SiteService } from 'app/services/site-service.service';
import { CallbackToolbarAction, ToolbarAction } from 'app/softoolscore.module/types/classes';
import { Application } from 'app/types/application';
import { GlobalModel } from '../global.model';

export class HeaderModel extends Model<HeaderModel> {

  public readonly loading = new BooleanModelProperty(this, true);

  public readonly app = new ModelProperty<Application>(this).withLogging('APP');

  public readonly title = new ModelProperty<string>(this).withLogging('TITLE');

  /** Toolbar actions for the main (ellipsis) menu */
  public readonly toolbarActions = new ModelProperty<Array<ToolbarAction>>(this).withLogging('TOOLBAR ACTIONS');

  /** Toolbar actions for the settings (cog) menu */
  public readonly settingsActions = new ArrayModelProperty<ToolbarAction>(this).withLogging('SETTINGS ACTIONS');

  public readonly showBackButton = new BooleanModelProperty(this, false).withLogging('BACK BUTTON');

  public readonly back = new ModelEvent();

  @InjectService(SiteService)
  private readonly siteService: SiteService;

  @InjectService(PermissionsService)
  protected permissionsService: PermissionsService;

  public constructor(private globalModel: GlobalModel) {
    super(globalModel);

    // Echo title to doc title
    this.subscribe(this.title.$, (title) => {
      if (title !== undefined) {
        document.title = title;
      }
    });
  }

  public setSettingsActions(app?: Application) {

    const anon = app?.Anonymous;
    const actions: Array<ToolbarAction> = [];
    if (!anon) {
      actions.push(new CallbackToolbarAction('MyProfile', 'user', () => {
        this.globalModel.navigation.navigateAppHome({ appIdentifier: 'Softools.MyProfile' })
          .catch(error => logError(error, 'navProfile'));
      }));
    }

    if (this.siteService.Site?.IncludeSupportWidget) {
      actions.push(new CallbackToolbarAction('Support', 'question-circle', () => {
        this.globalModel.showSoftoolsHelp();
      }));
    }

    if (this.permissionsService.hasAppStudio) {
      actions.push(new CallbackToolbarAction('AppStudio', 'wrench', () => {
        this.globalModel.openAppStudio();
      }));

    }

    if (!anon) {
      actions.push(new CallbackToolbarAction('Log out', 'sign-in', async () => {
        await this.globalModel.logout();
      }));
    }

    this.settingsActions.value = actions;
  }

  public goBack() {
    // Fire back event, active page model should perform the back action
    this.back.fire();
  }
}
