import { FilterSpecification, ReportFilter } from 'app/filters/types';
import { FilterModel } from 'app/mvc';
import { Application } from 'app/types/application';

export class RecordSelectorFilterModel extends FilterModel {

  protected override getReportFilter(app: Application): Promise<ReportFilter> {
    // Just intialise, don't apply a saved report
    if (this.isAdvanced.value) {
      this.initialiseAdvanced(app);
    }

    return null;
  }

  public override async updateCurrentFilter(filter: ReportFilter): Promise<void> {
    // Set as current filter
    this.setFilter(filter);
  }

}
