import { Component, ViewChild, OnChanges, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { logError } from 'app/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields/editable-field-base';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-range-field-2',
  templateUrl: './range-field.component.html',
  styleUrls: ['./range-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeFieldComponent extends EditableFieldBase<number> implements OnChanges, OnInit {

  @ViewChild(InputComponent, { static: true })
  inputComponent: InputComponent;

  /** Value to display; either the field value or the current input value if the slider is moving  */
  public displayValue = 0;

  public textWidth;

  override ngOnInit() {
    super.ngOnInit();

    // Get extent of range - defaults are defined by the input element spec
    const range = ((this.fieldModel.MaxValue ?? 100) - (this.fieldModel.MinValue ?? 0));
    this.textWidth = Math.ceil(Math.log10(range + 1));
  }

  protected override onValueChanged(value: any) {
    super.onValueChanged(value);
    this.displayValue = value;
  }

  public mouseDown($event: MouseEvent) {
    this.componentClicked$.next(this);
  }

  /** User is adjusting slider, set display value */
  public inputChanged() {
    this.componentClicked$.next(this);
    if (this.inputComponent) {
      this.displayValue = +this.inputComponent.getValue();
    }
  }

  /** User has released slider to complete input, apply change immediately */
  public inputComplete() {
    if (this.inputComponent) {
      const val = +this.inputComponent.getValue();
      this.displayValue = val;
      this.updateValueAsync(val).catch((error) => logError(error, 'range inp'));;
    }
  }

  public override async activate() {
    this.inputComponent.activate();
  }

}
