import { Enums, FileUploaderSoftoolsOptions, Record } from '@softools/softools-core';
import { BooleanModelProperty, Controller } from '@softools/vertex';
import { ToolbarAction } from 'app/softoolscore.module/types/classes/toolbaraction.class';
import { DialogFileUploaderComponent } from 'app/softoolsui.module/dialog-file-uploader/dialog-file-uploader.component';
import { ExportComponent } from 'app/softoolsui.module/export.component';
import { ToolbarContext } from 'app/types/application';
import { AppZone } from 'app/types/enums';
import { AppModel, ReportModel } from '..';
import { IGeneralController } from '../common/general-controller.interface';
import { GlobalModel } from '../global.model';

/**
 * Controller for reports.
 * This is mostly a placdeholder, more functionality needs to be
 * migrated from the model class.
 */
export class ReportController extends Controller implements IGeneralController {

  public readonly globalModel: GlobalModel;

  protected readonly appModel: AppModel;

  public readonly quickFiltersPinned = new BooleanModelProperty(null)
    .withPersistence({ name: 'pin-quick-filter', defaultValue: false });

  public readonly showEditModeSwitch = new BooleanModelProperty(this, false).withLogging('Edit mode switch');

  public constructor(public reportModel: ReportModel) {
    super();
    this.appModel = reportModel.appModel;
    this.globalModel = reportModel.globalModel;
  }

  public initialise() {
    this.subscribe(this.reportModel.updateToolbar.$, () => {
      if (!this.reportModel.dualReportModel$.value) {
        this.updateToolbar(this.reportModel);
      }
    });

    this.subscribe(this.reportModel.dualReportModel$, (model) => {
      if (model) {
        this.updateToolbar(model);
      }
    });
  }

  public async archive(): Promise<void> {
    await this.focusModel.archive();
  }

  public async unarchive(): Promise<void> {
    await this.focusModel.unarchive();
  }

  public async delete(): Promise<void> {
    await this.focusModel.delete();
  }

  public async export() {
    const selection = await this.focusModel.promptForSelection();
    if (selection) {
      const config = await this.focusModel.getExportConfig();
      const result = await ExportComponent.openAsync(this.globalModel, config);
      if (result?.selectedExportType) {
        await this.focusModel.exportAppData(result, config, selection);
      }
    }
  }

  public async import() {
    const fileUploaderOptions: FileUploaderSoftoolsOptions = {
      appIdentifier: this.appModel.appContext.app.value.Identifier,
      parentAppIdentifier: this.appModel.appContext.parentApp.value?.Identifier,
      parentRecordIdentifier: this.appModel.appContext.parentRecordId.value,
    };

    await DialogFileUploaderComponent.openAsync(this.globalModel, fileUploaderOptions);
  }

  public async security() {
    await this.focusModel.security();
  }

  public async discardChanges(record: Record): Promise<void> {
    // nop
  }

  private updateToolbar(reportModel: ReportModel) {
    const archived = this.appModel.globalModel.archived.value;
    const app = this.appModel.app.value;
    const isChildApp = !!reportModel.hierarchy.value; // todo better

    const actions = [] as Array<ToolbarAction>;

    let zone: AppZone;
    switch (reportModel.report.value.Type) {
      case Enums.ReportTypes.List:
      case Enums.ReportTypes.Table:
        zone = isChildApp ? AppZone.childlistreport : AppZone.listreport;
        break;
      case Enums.ReportTypes.Chart:
        zone = isChildApp ? AppZone.childchartreport : AppZone.chartreport;
        break;
      case Enums.ReportTypes.Matrix:
        zone = AppZone.matrixreport;
        break;
      case Enums.ReportTypes.Dashboard:
        zone = AppZone.dashboardreport;
        break;
    }

    const context: ToolbarContext = {
      app,
      zone,
      report: reportModel.report.value,
      isChildApp,
      isArchived: archived,
      reportController: this
    };

    app.getReportToolbarActionModel(reportModel, actions, context);

    reportModel.toolbarActions.value = actions;
  }

  private get focusModel() {
    return this.reportModel.dualReportModel$.value ?? this.reportModel;
  }
}
