import ObjectID from 'bson-objectid';
import { Component, OnChanges, SimpleChanges, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields/editable-field-base';
import { Application } from 'app/types/application';
import { AppField } from 'app/types/fields/app-field';
import { Enums, stringCompare, logError, User, tryGetCurrentUser, Field, TrackChangeList, isDefined, Failure } from '@softools/softools-core';
import { IAppExtension } from 'app/types/app-extension';
import { ListItemAppField } from 'app/types/fields/list-item-app-field';
import { ContainerType } from 'app/softoolsui.module/fields/field-base';
import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { isNumberField, isDateField, isDecimalField, extractDecimal } from 'app/_constants';
import { LogicBlockExecutorService } from 'app/services/logic-block-executor.service';
import { InjectService } from 'app/services/locator.service';

@Component({
  selector: 'app-list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./list-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFieldComponent extends EditableFieldBase<any> implements OnInit, OnChanges {
  public sortFieldIdentifer: string = null;
  public sortFieldAsc = true;
  public sortFieldType: Enums.FieldType;

  private user: User;

  @Input() override application: Application;

  public columnFields: Array<ListItemAppField> = [];

  private _subfields: Array<{ Identifier: string }> = [];

  /** If set, defines extension to call to handle adding an item */
  public addItemExtension: IAppExtension = null;

  /** If set, defines extension to call to handle deleting an item */
  public removeItemExtension: IAppExtension<string> = null;

  public rowKeyId = 'Key';

  public listFieldContainerType = ContainerType.ListField;

  @InjectService(LogicBlockExecutorService)
  public logicBlockExecutorService: LogicBlockExecutorService;

  constructor(private recordValidationService: RecordValidationService, private changeDetection: ChangeDetectorRef) {
    super();
  }

  public skip = 0;

  public top = 25;

  public listFieldPagination = false;

  override ngOnInit(): void {

    try {
      super.ngOnInit();

      if (this.fieldModel.ListFieldParameters?.RowKey) {
        this.rowKeyId = this.fieldModel.ListFieldParameters?.RowKey;
      }

      // Allow app to change our behaviour
      this.application.initialiseFieldComponent(this);

      this.sortFieldIdentifer = this.fieldModel.DefaultSortByIdentifier;
      this.sortFieldAsc = this.fieldModel.DefaultSortOrder === 'asc';
      this.sortRows();

      this.listFieldPagination = this.fieldModel.ListFieldPagination;

    } catch (error) {
      logError(error, `list field ${this.fieldModel?.Identifier} onInit`);
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    try {
      if (!this.user) {
        this.user = tryGetCurrentUser();
      }

      super.ngOnChanges(changes);

      if (changes['record']) {
        this.sortRows();
      }


      if (changes['fieldModel'] || changes['record']) {
        this._subfields = this.fieldModel.SubFieldsIdentifiers;
        const colFields: Array<ListItemAppField> = [];
        this._subfields?.forEach((sub) => {
          const subAppField = this.application.getField(sub.Identifier) as ListItemAppField;
          if (subAppField) {
            colFields.push(subAppField);
          }
        });

        this.columnFields = colFields;

        if (changes['fieldModel'] || changes['record']) {
          // revalidate on changes
          // this is a bit inefficient but ensures we validate when rows added etc
          this.recordValidationService.validateRecord(this.record, this.application.AppFields);
        }
      }
    } catch (error) {
      logError(error, '');
    }
  }

  /** Track list rows by row key (without this NG gets confused about entries) */
  public tracker(_index: number, _item: any) {
    return _item.Key;
  }

  public get subRecords(): Array<any> {
    return this.value as Array<any> ?? [];
  }

  public get subRecordsPage(): Array<any> {
    if (this.listFieldPagination) {
      return this.subRecords.slice(this.skip, this.skip + this.top);
    } else {
      return this.subRecords;
    }
  }

  public nextPage() {
    const newSkip = this.skip + this.top;
    this.skip = newSkip + this.top < this.subRecords.length ? newSkip : this.subRecords.length - this.top;
  }

  public previousPage() {
    const newSkip = this.skip - this.top;
    this.skip = newSkip > 0 ? newSkip : 0;
  }

  public firstPage() {
    this.skip = 0;
  }

  public lastPage() {
    this.skip = this.subRecords.length - this.top;
  }

  public changeTop(newTop: number) {
    this.top = +newTop;
  }

  public get gridColumns(): string {
    return this.columnFields.map(f => {
      if (f?.IsAutoExpandable) {
        return f.IsAutoExpandable ? '1fr' : 'auto';
      } else if (f?.ColWidth) {
        return f.ColWidth;
      } else {
        return 'auto';
      }
    }).join(' ') + ' 2rem';
  }

  public get isRowAddEnabled(): boolean {
    if (this.ruleState?.isAddHidden(this.fieldModel.Identifier)) {
      return false;
    }

    if (this.getIsDisabled()) {
      return false;
    }

    if (this.fieldModel?.AddRowEnabled !== undefined) {
      return this.fieldModel.AddRowEnabled;
    } else {
      return this.fieldModel.IsEditable;
    }
  }

  public get isRowDeleteEnabled(): boolean {
    if (this.ruleState?.isDeleteHidden(this.fieldModel.Identifier)) {
      return false;
    }

    if (this.getIsDisabled()) {
      return false;
    }

    if (this.fieldModel?.DeleteRowEnabled !== undefined) {
      return this.fieldModel.DeleteRowEnabled;
    } else {
      return this.fieldModel.IsEditable;
    }
  }

  public isHidden(row: number, col: number) {
    const key = this.subRecords[row][this.rowKeyId];
    return this.ruleState?.isHidden(this._subfields[col].Identifier, key);
  }

  public rowStyle(row: number) {
    const key = this.subRecords[row][this.rowKeyId];
    return this.ruleState?.getStyle(this.fieldModel.Identifier, key);
  }

  public get fieldStyle() {
    return this.ruleState?.getStyle(this.fieldModel.Identifier);
  }

  public subFieldStyle(field: Field, row: number) {
    const key = this.subRecords[row][this.rowKeyId];
    return this.ruleState?.getStyle(field.Identifier, key);
  }

  public addRecordClicked(e: Event) {
    e.stopPropagation();
    this.addRecord().catch(error => logError(error, 'Failed to add record'));   // async

  }

  private async addRecord() {
    try {
      if (this.addItemExtension) {
        this.addItemExtension.executeAsync(this.appModel, this.record, null).catch(error => {
          logError(error, 'addItemExtension.executeAsync');
        });
      } else {
        // Normal record so patch in a tracked change update to represent the
        // additopm
        const newRecord: any = {};
        const key = this.fieldModel.ListFieldParameters?.RowKeyIsNumeric
          ? this.value?.length + 1
          : new ObjectID().toHexString();
        newRecord[this.rowKeyId] = key;
        this.columnFields.forEach((columnField) => {
          const def = this.defaultFieldValue(columnField);
          if (isDefined(def)) {
            newRecord[columnField.DataIdentifier] = def;
          }
        });

        // If a logic block is defined allow it to run and update the row
        const addLogicBlockId = this.fieldModel.ListFieldParameters?.AddRowLogicBlock;
        if (addLogicBlockId) {
          const block = this.application.LogicBlocks?.find(b => b.Identifier === addLogicBlockId);
          if (block) {
            await this.logicBlockExecutorService.execute(block, { record: this.record, changes: newRecord });
          }
        }

        const changeList = new TrackChangeList(this.rowKeyId).addAdditionalRow(newRecord);
        await this.dispatchChangeAsync(changeList);
      }
    } catch (error) {
      logError(error, '');
    }
  }

  /**
   * Handle click on delete tool
   * @param e     Click event
   * @param row   Logical Row number (in collection, not view)
   */
  public deleteRecordClicked(e: Event, row: number) {
    try {
      e.stopPropagation();

      const subRecs = this.value as Array<any>;

      if (row >= 0 && row < subRecs.length) {
        const rowval = subRecs[row];
        const key = rowval && rowval[this.rowKeyId];
        if (key) {
          if (this.removeItemExtension) {
            this.removeItemExtension.executeAsync(this.appModel, this.record, key).catch(error => {
              logError(error, 'removeItemExtension.executeAsync');
            });
          } else {
            // Normal record so patch in a tracked change update to represent the
            // removal
            const changeList = new TrackChangeList(this.rowKeyId).addRemovalKey(key);
            this.dispatchChangeAsync(changeList).catch(error => logError(error, 'Failed to dispatch'));
          }
        }
      }
    } catch (error) {
      logError(error, '');
    }
  }

  public get trashColumn(): number {
    return this.columnFields.length + 1;
  }

  private defaultFieldValue(field: ListItemAppField): any {

    if (field.DefaultValue !== undefined && !field.DefaultValueIsExpression) {
      return field.DefaultValue;
    }

    return null;
  }

  public isHeaderCenterd(subField: AppField): boolean {
    if (subField?.Type) {
      switch (subField.Type) {
        case Enums.FieldType.MultiState:
          return true;
      }
    }

    return false;
  }

  public isSortSupported(subField: AppField) {
    return subField.isSortable();
  }

  public sort(subField: AppField) {
    if (this.isSortSupported(subField)) {
      this.sortFieldIdentifer = subField.Identifier;
      this.sortFieldAsc = !this.sortFieldAsc;
      this.sortFieldType = subField.Type;

      this.sortRows();
    }
  }

  sortRows() {
    if (this.sortFieldIdentifer) {
      const subFieldIdentifier = this.sortFieldIdentifer;
      if (isDecimalField(this.sortFieldType)) {
        this.record[this.identifier]?.sort(
          (a, b) => extractDecimal(b[subFieldIdentifier]) - extractDecimal(a[subFieldIdentifier]));
      } else if (isNumberField(this.sortFieldType)) {
        this.record[this.identifier]?.sort((a, b) => b[subFieldIdentifier] - a[subFieldIdentifier]);
      } else if (isDateField(this.sortFieldType)) {
        this.record[this.identifier]?.sort((a, b) => b[subFieldIdentifier].$date - a[subFieldIdentifier].$date);
      } else {
        this.record[this.identifier]?.sort((a, b) =>
          stringCompare(a[subFieldIdentifier]?.toString() ?? '', b[subFieldIdentifier]?.toString() ?? '')
        );
      }

      if (!this.sortFieldAsc) {
        this.record[this.identifier]?.reverse();
      }

      this.refresh();
    }
  }

  public columnFieldsTrackByFn(index: number, field: AppField) {
    return field.Identifier;
  }

  public subFieldErrorsChanged(errors: Array<Failure>, subField: AppField, row: number) {
    // Replace any/all errors for this subfield and row
    const current = this.editValidationErrors$.value;
    if (current) {
      const updated = current.filter(err => err.fieldId !== subField.Identifier || err.listRow !== row);
      if (errors) {
        updated.push(...errors);
      }
      this.editValidationErrors$.next(updated);
    } else {
      this.editValidationErrors$.next(errors);
    }
  }

  public fieldErrors(errors: Array<Failure>, field: AppField) {
    return errors?.filter((err) => (err.fieldId === field.Identifier));
  }
}
