import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, Router, ActivatedRouteSnapshot, Routes, RunGuardsAndResolvers } from '@angular/router';
import { guards, appRoutes } from 'app/app.routing';
import { WSRecordUpdateComponent } from 'app/workspace.module/components/ws-record/ws-recordupdate/ws-recordupdate.component';
import { globalGuards } from './global-guards';
import { appGuards } from 'app/workspace.module/workspace-routes';
import { RecordResolver } from './record/record.resolver';
import { NewChildRecordResolver } from './record/new-child-record.resolver';
import { AppZone } from 'app/types/enums';
import { WSAppHomeComponent } from 'app/workspace.module/components/ws-apphome/ws-apphome.component';
import { PatchQueueResolver } from './apphome/patch-queue.resolver';
import { WSListReportComponent } from 'app/workspace.module/components/ws-reports/ws-listreport/ws-listreport.component';
import { ListReportQueryGuard, ChartReportQueryGuard, MatrixReportQueryGuard, DashboardReportQueryGuard } from './reports';
import { WSChartReportComponent } from 'app/workspace.module/components/ws-reports/ws-chartreport/ws-chartreport.component';
import { WSMatrixReportComponent } from 'app/workspace.module/components/ws-reports/ws-matrixreport/ws-matrixreport.component';
import { ChartDataResolver, AuthGuard } from '.';
import { WSDashboardReportComponent } from 'app/workspace.module/components/ws-reports/ws-dashboardreport/ws-dashboardreport.component';
import { HomeComponent } from 'app/home.module/components/home.component/home.component';
import { RootRouteMatcher } from './root-route-matcher';
import { APP_IDENTIFIER, REPORT_IDENTIFIER, PARENT_APP_IDENTIFIER, PARENT_RECORD_ID, RECORD_ID, FORM_IDENTIFIER, HOMEPAGE_IDENTIFIER } from 'app/_constants';
import { LegacyRoutesGuard } from './legacy-routes.guard';
import { ErrorComponent } from 'app/error.module/error/error.component';
import { IdentifiersService } from 'app/services/identifiers.service';
import { WSChildListReportComponent } from 'app/workspace.module/components/ws-reports/ws-child-listreport/ws-childlistreport.component';
import { ChildRecordResolver } from './record/child-record.resolver';
import { NewRecordResolver } from './record/newrecord.resolver';
import { UsersService } from 'app/services/users.service';
import { HomepagesService } from 'app/services/homepages.service';
import { ParentRecordResolver } from './record/parent-record.resolver';
import { RecordCursorResolver } from './record/record-cursor.resolver';
import { WSChildAppHomeComponent } from 'app/workspace.module/components/ws-apphome/ws-childapphome/ws-childapphome.component';
import { WsTableReportComponent } from 'app/workspace.module/components/ws-reports/ws-table-report/ws-table-report.component';
import { DatabaseError, logError } from '@softools/softools-core';
import { WsCardReportComponent } from 'app/workspace.module/components/ws-reports/ws-card-report/ws-card-report.component';
import { HomepageImageListAssetsGuard } from './homepage-image-list-assets.guard';


/**
 * Support Dynamic routing
 * Routing requires lists of apps, reports etc so we can compare their names, but this information can only
 * be obtained in an async context.  To do this we set up a static route that loads this guard as a wildcard.
 * In canActivate (which is async so can call app APIs) we build the real route tables and reset the router
 * config; then redirect to the original URL.  This guard should only be called once.
 * Basic concept documented at:
 * https://github.com/angular/angular/issues/11437
 */
@Injectable({ providedIn: 'root' })
export class RootRoutesGuard implements CanActivate {

  private routesLoaded = false;

  private baseWorkspaceRoute = {
    runGuardsAndResolvers: 'paramsOrQueryParamsChange' as RunGuardsAndResolvers,
    canActivate: [...new Set([
      ...guards,
      ...globalGuards,
      ...appGuards,
    ])],
  };

  /** Guards to run when AppsExistsGuard completes */
  private appsGuardChildren = [AuthGuard];

  public constructor(
    public router: Router,
    private identifiersService: IdentifiersService,
    private usersService: UsersService,
    private homepagesService: HomepagesService,
  ) {
  }

  public async canActivate(_snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (this.routesLoaded) {
      return true;
    }

    try {
      // Matcher needs users available to look up defaults
      await this.usersService.refresh();
      await this.homepagesService.refresh();
      await this.identifiersService.initialise();
    } catch (error) {

      if (error instanceof DatabaseError) {
        // Indexed DB error - this can occur if the db version is wrong
        logError(error, 'Indexed Db error');
        // load static routes so we can load error page, then navigate to it
        this.router.resetConfig(appRoutes);
        this.router.navigateByUrl(`/Error?type=indexedDb&detail=${error.message}`).catch(error => logError(error, 'Failed to navigate to error'));
        this.routesLoaded = true;
        return false;
      } else {
        logError(error, 'Error initialising route');
        throw error;
      }
    }

    // Setup routes
    const matcher = new RootRouteMatcher(this.identifiersService);
    const routes = this.createRoutes(matcher);
    this.router.resetConfig(routes);

    // Don't need to load again
    this.routesLoaded = true;

    // Redirect to original Url so new routes are used
    this.router.navigateByUrl(state.url, { replaceUrl: true }).catch(error => logError(error, 'Failed to navigate'));
    return false;
  }

  private createRoutes(matcher: RootRouteMatcher): Routes {
    return [
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.listReportDirectMatcher,
        component: WSListReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        data: {
          type: 'List', isChild: false, zone: AppZone.listreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.listReportWithAppMatcher,
        component: WSListReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        data: {
          type: 'List', isChild: false, zone: AppZone.listreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childListReportMatcher,
        component: WSChildListReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        resolve: { parentRecord: ParentRecordResolver },
        data: {
          type: 'List', isChild: true, zone: AppZone.childlistreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.chartReportDirectMatcher,
        component: WSChartReportComponent,
        resolve: { chart: ChartDataResolver },
        canActivate: [...globalGuards, ...appGuards, ChartReportQueryGuard],
        data: {
          type: 'Chart', isChild: false, zone: AppZone.app,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.chartReportWithAppMatcher,
        component: WSChartReportComponent,
        resolve: { chart: ChartDataResolver },
        canActivate: [...globalGuards, ...appGuards, ChartReportQueryGuard],
        data: {
          type: 'Chart', isChild: false, zone: AppZone.app,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childChartReportMatcher,
        component: WSChartReportComponent,
        resolve: { chart: ChartDataResolver, parentRecord: ParentRecordResolver },
        canActivate: [...globalGuards, ...appGuards, ChartReportQueryGuard],
        data: {
          type: 'Chart', isChild: true, zone: AppZone.childchartreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.matrixReportDirectMatcher,
        component: WSMatrixReportComponent,
        resolve: { chart: ChartDataResolver },
        canActivate: [...globalGuards, ...appGuards, MatrixReportQueryGuard],
        data: {
          type: 'Matrix', isChild: false, zone: AppZone.app,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.matrixReportWithAppMatcher,
        component: WSMatrixReportComponent,
        resolve: { chart: ChartDataResolver },
        canActivate: [...globalGuards, ...appGuards, MatrixReportQueryGuard],
        data: {
          type: 'Matrix', isChild: false, zone: AppZone.app,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childMatrixReportMatcher,
        component: WSMatrixReportComponent,
        resolve: { chart: ChartDataResolver, parentRecord: ParentRecordResolver },
        canActivate: [...globalGuards, ...appGuards, MatrixReportQueryGuard],
        data: {
          type: 'Matrix', isChild: true, zone: AppZone.childmatrixreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.dashboardReportDirectMatcher,
        component: WSDashboardReportComponent,
        resolve: { chart: ChartDataResolver },
        canActivate: [...globalGuards, ...appGuards, DashboardReportQueryGuard],
        data: {
          type: 'Dashboard', isChild: false, zone: AppZone.app,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.dashboardReportWithAppMatcher,
        component: WSDashboardReportComponent,
        resolve: { chart: ChartDataResolver },
        canActivate: [...globalGuards, ...appGuards, DashboardReportQueryGuard],
        data: {
          type: 'Dashboard', isChild: false, zone: AppZone.app,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.tableReportDirectMatcher,
        component: WsTableReportComponent,
        // todo replace ListReportQueryGuard (?)
        canActivate: [...globalGuards, ...appGuards],
        data: {
          type: 'Table', isChild: false, zone: AppZone.tablereport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.tableReportWithAppMatcher,
        component: WsTableReportComponent,
        // todo replace ListReportQueryGuard (?)
        canActivate: [...globalGuards, ...appGuards],
        data: {
          type: 'Table', isChild: false, zone: AppZone.tablereport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childTableReportMatcher,
        component: WsTableReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        resolve: { parentRecord: ParentRecordResolver },
        data: {
          type: 'Table', isChild: true, zone: AppZone.tablereport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.cardReportDirectMatcher,
        component: WsCardReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        data: {
          type: 'Card', isChild: false, zone: AppZone.cardreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.cardReportWithAppMatcher,
        component: WsCardReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        data: {
          type: 'Card', isChild: false, zone: AppZone.cardreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childCardReportMatcher,
        component: WsCardReportComponent,
        canActivate: [...globalGuards, ...appGuards],
        resolve: { parentRecord: ParentRecordResolver },
        data: {
          type: 'Card', isChild: false, zone: AppZone.cardreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren,
            SavedFiltersGuard: [ListReportQueryGuard]
          }
        }
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childDashboardReportMatcher,
        component: WSDashboardReportComponent,
        resolve: { chart: ChartDataResolver, parentRecord: ParentRecordResolver },
        canActivate: [...globalGuards, ...appGuards, DashboardReportQueryGuard],
        data: {
          type: 'Dashboard', isChild: true, zone: AppZone.childdashboardreport,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.homepageMatcher,
        component: HomeComponent,
        canActivate: [...globalGuards, ...appGuards, HomepageImageListAssetsGuard],
        data: {
          zone: AppZone.home,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.createRecordMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: NewRecordResolver },
        data: {
          type: 'Record', zone: AppZone.recordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.recordWithAppMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: RecordResolver },
        data: {
          type: 'Record', zone: AppZone.recordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childAppCreateRecordMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: NewChildRecordResolver },
        data: {
          type: 'Record', isChild: true, zone: AppZone.childrecordadd,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childAppRecordMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: ChildRecordResolver },
        data: {
          type: 'Record', isChild: true, zone: AppZone.childrecordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

      // Online record using cursor over list report
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.recordListCursorWithAppMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: RecordCursorResolver },
        data: {
          type: 'Record', zone: AppZone.recordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childListRecordCursorWithAppMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: RecordCursorResolver },
        data: {
          type: 'Record',
          zone: AppZone.childrecordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

      // Online record using cursor over table report
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.recordTableCursorWithAppMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: RecordCursorResolver },
        data: {
          type: 'Record', zone: AppZone.recordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childTableRecordCursorWithAppMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: RecordCursorResolver },
        data: {
          type: 'Record',
          zone: AppZone.childrecordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },


      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.appHomeMatcher,
        component: WSAppHomeComponent,
        resolve: { patchQueue: PatchQueueResolver },
        data: {
          type: 'AppHome', zone: 'apphome',
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        },
      },
      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.childAppHomeMatcher,
        component: WSChildAppHomeComponent,
        resolve: { patchQueue: PatchQueueResolver },
        data: {
          type: 'AppHome', zone: 'childapphome',
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        },
      },

      {
        ...this.baseWorkspaceRoute,
        matcher: matcher.singletonMatcher,
        component: WSRecordUpdateComponent,
        resolve: { record: RecordResolver },
        data: {
          type: 'Record', zone: AppZone.recordupdate,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        },
      },

      // Legacy routes to allow links defined in v14 (and earler v18 versions) to work
      // Matching routes are redirected by LegacyRoutesGuard.
      // ErrorComponent is a placeholder and should never be actived from these routes
      {
        path: `App/Main/Homepage/:${HOMEPAGE_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.home },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${APP_IDENTIFIER}/List/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.listreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/List/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childlistreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Edit/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/List/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childlistreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${APP_IDENTIFIER}/Chart/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.chartreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/Chart/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childchartreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Edit/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/Chart/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childchartreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${APP_IDENTIFIER}/Matrix/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.matrixreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/Matrix/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childmatrixreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Edit/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/Matrix/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childmatrixreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${APP_IDENTIFIER}/Dashboard/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.dashboardreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/Dashboard/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childdashboardreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Edit/:${PARENT_APP_IDENTIFIER}/:${PARENT_RECORD_ID}/:${APP_IDENTIFIER}/Dashboard/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.childdashboardreport },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Main/:${APP_IDENTIFIER}/:${REPORT_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.listreport },
        runGuardsAndResolvers: 'always'
      },
      // Match record update links.  Child records match the same but use query params
      {
        path: `App/Edit/:${APP_IDENTIFIER}/:${RECORD_ID}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.recordupdate },
        runGuardsAndResolvers: 'always'
      },
      {
        path: `App/Edit/:${APP_IDENTIFIER}/:${RECORD_ID}/:${FORM_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.recordupdate },
        runGuardsAndResolvers: 'always'
      },
      // Add record URLs
      {
        path: `App/Add/:${APP_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.recordadd },
        runGuardsAndResolvers: 'always'
      },

      // App homepage
      // NB This is not very useful, as the homepage behaviour is different between v14 and v18
      // To get to the default report, a report link should be used.
      {
        path: `App/Main/:${APP_IDENTIFIER}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.apphome },
        runGuardsAndResolvers: 'always'
      },

      {
        path: `Account/Verification/Confirm/:${RECORD_ID}`,
        component: ErrorComponent,
        canActivate: [LegacyRoutesGuard],
        data: { zone: AppZone.verify },
        runGuardsAndResolvers: 'always'
      },

      ...appRoutes,

      // Final fallback to home if no matching routes
      {
        ...this.baseWorkspaceRoute,
        path: '**',
        component: HomeComponent,
        canActivate: [...globalGuards],
        data: {
          zone: AppZone.home,
          childGuards: {
            AppsExistsGuard: this.appsGuardChildren
          }
        }
      },

    ];
  }
}

export const initialRootAppRoutes: Routes = [
  {
    path: '**',
    component: ErrorComponent,
    canActivate: [RootRoutesGuard]
  }
];
