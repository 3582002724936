import { Injectable } from '@angular/core';
import {
  AppDataStorageService,
  AppsService,
  HomepageStorageService,
  ImageListsStorageService,
  NotificationStorageService,
  ReportDataService,
  ReportStorageService,
  SelectListsStorageService,
  TeamsStorageService,
  UserStorageService,
  HomePageDashboardFieldsStorageService,
  ReportStateStorageService,
  RecordAccessedStorageService,
  BackgroundProcessStorageService,
  ReportDataStorageService,
  SiteStorageService,
  SiteSettingsStorageService,
  ReportViewService
} from '@softools/softools-core';
import { ChangeQueueStorageService } from 'app/services/change-queue.service';
import { ChangedRecordsStorageService } from 'app/services/changed-records-storage.service';
import { LocalValuesStorageServiceService } from 'app/services/local-values-storage-service.service';
import { UpgradeDataStorageService } from 'app/services/upgrade-data-storage.service';
import { PendingSaveImagesService } from './pending.service';

@Injectable({ providedIn: 'root' })
export class IndexedDbService {
  constructor(
    private appDataService: AppDataStorageService,
    private appsService: AppsService,
    private homeService: HomepageStorageService,
    private homePageDashboardFieldsService: HomePageDashboardFieldsStorageService,
    private imageListsService: ImageListsStorageService,
    private notificationsService: NotificationStorageService,
    private reportDataService: ReportDataService,
    private reportsService: ReportStorageService,
    private selectListsService: SelectListsStorageService,
    private teamsService: TeamsStorageService,
    private usersService: UserStorageService,
    private reportStateService: ReportDataStorageService,
    private recordAccessedStorageService: RecordAccessedStorageService,
    private pendingSaveImagesService: PendingSaveImagesService,
    private backgroundProcessStorageService: BackgroundProcessStorageService,
    private reportStateStorageService: ReportStateStorageService,
    private upgradeDataStorageService: UpgradeDataStorageService,
    private siteStorageService: SiteStorageService,
    private siteSettingsStorageService: SiteSettingsStorageService,
    private reportViewService: ReportViewService,
    private changeQueueStorageService: ChangeQueueStorageService,
    private changedRecordsStorageService: ChangedRecordsStorageService,
    private localValuesStorageServiceService: LocalValuesStorageServiceService,

  ) {

  }

  public async clear() {
    await this.appDataService.clear();
    await this.appsService.clear();
    await this.homeService.clear();
    await this.homePageDashboardFieldsService.clear();
    await this.imageListsService.clear();
    await this.notificationsService.clear();
    await this.reportDataService.clear();
    await this.reportsService.clear();
    await this.selectListsService.clear();
    await this.teamsService.clear();
    await this.usersService.clear();
    await this.reportStateService.clear();
    await this.recordAccessedStorageService.clear();
    await this.pendingSaveImagesService.clear();
    await this.backgroundProcessStorageService.clear();
    await this.reportStateStorageService.clear();
    await this.upgradeDataStorageService.clear();
    await this.siteStorageService.clear();
    await this.siteSettingsStorageService.clear();
    await this.reportViewService.clear();
    await this.changeQueueStorageService.clear();
    await this.changedRecordsStorageService.clear();
    await this.localValuesStorageServiceService.clear();
  }
}
