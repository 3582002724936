import { Injectable } from '@angular/core';
import { Record } from '@softools/softools-core';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { AccessedRecordData } from '../types';
import { RECORD_DATA_STORE } from '../indexedDb';
import { StorageServiceBase } from './storage-service-base';
import { AppService } from 'app/services/app.service';

@Injectable({ providedIn: 'root' })
export class RecordAccessedStorageService extends StorageServiceBase<Array<AccessedRecordData>, string> {

  constructor(
    private appService: AppService,
    dbContext: DatabaseContextService<Array<AccessedRecordData>>) {
    super(RECORD_DATA_STORE, dbContext);
  }

  public async appendRecordAsync(record: Record) {

    const app = this.appService.application(record.AppIdentifier);

    const storageData = await this.get(record.AppIdentifier);
    const titleFieldIdentifier = app?.TitleFieldIdentifier;
    const storageDataArray = storageData ?? [];
    const element = {
      _id: record._id,
      title: titleFieldIdentifier?.length > 0 ? record[titleFieldIdentifier] : record._id,
      hierarchy: record.Hierarchy,
    };

    // Store element's index if it is present in db
    let elementIndex;
    for (let i = 0; i < storageDataArray.length; i++) {
      if (storageDataArray[i]._id === element._id) {
        elementIndex = i;
      }
    }
    if (typeof elementIndex !== 'undefined') {
      storageDataArray.splice(elementIndex, 1);
    }
    storageDataArray.unshift(element);
    if (storageDataArray.length > 5) {
      storageDataArray.pop();
    }

    await this.save(record.AppIdentifier, storageDataArray);
  }

}
