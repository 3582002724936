import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, Router } from '@angular/router';

import { AppDataStorageService, Record, logError } from '@softools/softools-core';
import { PARENT_APP_IDENTIFIER, PARENT_RECORD_ID } from 'app/_constants/constants.route';
import { AppService } from 'app/services/app.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';

@Injectable({ providedIn: 'root' })
export class ParentRecordResolver implements Resolve<Record> {

  constructor(
    private appService: AppService,
    private appDataService: AppDataStorageService,
    private router: Router,
    // private onlineStatus: OnlineStatusService,
    private recordPersistenceService: RecordPersistService) {
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record> {

    const params: Params = route.params;
    const appIdentifier: string = params[PARENT_APP_IDENTIFIER] as string;
    let recordId = route.params[PARENT_RECORD_ID] as string;

    try {
      const app = this.appService.application(appIdentifier);

      if (!recordId && app.IsSingletonApp) {
        recordId = app.StaticRecordId;
      }

      // Get record from storage or API depending on off/online state and apply any pending changes
      const record: Record = await app.getRecordByIdAsync(recordId, { applyPatch: true });

      if (!record) {
        this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
      } else if (record.AppIdentifier !== appIdentifier) {
        logError(new Error(`Selected a ${record.AppIdentifier} record running ${appIdentifier}`), '');
        // Could do with an error place
        this.router.navigate(['/']).catch(error => logError(error, 'Failed to route to home'));
      } else {
        return record;
      }
    } catch (error) {
      console.log(`Could not load ${appIdentifier} record ${recordId}`, error);
    }

    return null;
  }
}
