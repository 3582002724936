import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { QueryParams, QueryParameters, ImportSummary, ImportSummaryRowErrors } from '../types/interfaces';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportSummariesRepository extends RepositoryBase {

  /**
   * get total count of error summaries
   */
  public async getCountAsync(queryParams?: QueryParams ): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('ImportSummaries', '$count');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }
    return lastValueFrom<number>(this.get(url));
  }

  public async getImportSummaryAsync(id: string): Promise<ImportSummary> {
    const url = this.urlResolver.resolveDefaultApiUrl('ImportSummaries', id);
    return lastValueFrom<ImportSummary>(this.get(url));
  }

  /**
   * Get summary records.
   *
   * @param count         Number of summaries requested
   * @param skip          First record number to request
   * @param queryParams   Query params for filter, sort etc. (top & skip taken from arguments)
   */
  public async getImportSummariesAsync(count: number, skip: number, queryParams: QueryParams): Promise<Array<ImportSummary>> {

    const params = new QueryParameters(queryParams);
    params.$top = count;
    params.$skip = skip;

    let url = this.urlResolver.resolveDefaultApiUrl('ImportSummaries');
    url = this.urlResolver.appendQueryParams(url, params);
    return lastValueFrom<Array<ImportSummary>>(this.get(url));
  }


  public async getErrorsAsync(summaryId: string): Promise<Array<ImportSummaryRowErrors>> {
    const url = this.urlResolver.resolveDefaultApiUrl('ImportSummaryRowErrors', summaryId);
    return lastValueFrom<Array<ImportSummaryRowErrors>>(this.get(url));
  }

  public async cancelImportAsync(id: string): Promise<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('ImportSummaries', id, 'CancelImport');
    return this.post(url).toPromise();
  }
}
