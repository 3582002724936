import { Component, Input } from '@angular/core';
import { BusyModel } from 'app/mvc';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent extends ComponentBase {

  @Input() solidBackground = false;

  @Input() busyModel: BusyModel;

  constructor() {
    super();
  }
}
