import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { OverlayImage } from '@softools/softools-core';
import { OVERLAY_IMAGE_DATA } from 'app/_constants/constants.injection-tokens';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-overlay-img',
  templateUrl: './overlay-img.component.html',
  styleUrls: ['./overlay-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayImgComponent {

  public closed$ = new BehaviorSubject(false);

  constructor(
    @Inject(OVERLAY_IMAGE_DATA) public image: OverlayImage) { }

  public onCloseFullImageClickHandler($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.closed$.next(true);
  }
}
