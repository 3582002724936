import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { logError } from 'app/core';
import { ReportModel } from 'app/mvc';
import { ComponentBase } from 'app/softoolsui.module';
import { Application } from 'app/types/application';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-ws-sharednorecords',
  templateUrl: './ws-sharednorecords.component.html',
  styleUrls: ['./ws-sharednorecords.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSSharedNoRecordsComponent extends ComponentBase implements OnInit {

  @Input() app: Application;
  @Input() reportModel: ReportModel;
  @Input() isLoading = true;
  @Input() isArchived = false;

  public showReportFilterMsg$ = new BehaviorSubject(false);
  public showBaseFilterMsg$ = new BehaviorSubject(false);
  public showSearchFilterMsg$ = new BehaviorSubject(false);

  public ngOnInit(): void {

    this.subscribe(this.reportModel.searchFilter.$, (filter) => {
      this.showSearchFilterMsg$.next(!!filter);
    });

    this.subscribe(this.reportModel.report.$, (report) => {
      const hasBase = (!!report?.BaseFilter) || (!!report?.BaseFilterId);
      this.showBaseFilterMsg$.next(hasBase);
    });

    this.subscribe(this.reportModel.filterModel.reportFilter.$, (filter) => {
      this.showReportFilterMsg$.next(filter?.Filter?.length > 0);
    });
  }

  public clearSearchClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.reportModel.setSearchTerm(null).catch(error => logError(error, 'clear search'));
  }

  public clearFilterClicked($event: MouseEvent) {
    $event.stopPropagation();
    this.reportModel.filterModel.clearFilter()
      .catch(error => logError(error, 'clear filter'));
  }
}
