import { ChartData } from '@softools/softools-core';
import { MatrixModel } from 'app/softoolsui.module/matrixreport.component/matrix-model';
import { BehaviorSubject } from 'rxjs';
import { AppDataAccessor } from './app-data-accessor';

export abstract class ChartDataAccssor extends AppDataAccessor {

  public chartData$ = new BehaviorSubject<ChartData>(null);

  /** Calculate chart data for the report */
  public abstract getChartData(reset?: boolean): Promise<ChartData>;

  public abstract loadMatrixData(matrixModel: MatrixModel): Promise<void>;
}
