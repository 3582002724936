import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import * as Quagga from 'quagga';
import { logError } from '@softools/softools-core';

export class CodeDetails {
  public constructor(public code: string, public confidence: number) { }
}

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraComponent implements OnInit, OnDestroy {

  @Output() codeCaptured = new EventEmitter<CodeDetails>();

  @Input() public autoThreshold = 70;
  @Input() public minSamplesBeforeAuto = 33;
  @Input() public videoWidth = 640;
  @Input() public videoHeight = 480;

  private captureOpen = false;

  public summary: string;

  private _results = new Map<string, number>();

  private _totalMatches = 0;

  public confidence = 0;

  public value: any;

  public autoCapture = true;

  @ViewChild('videoContainer', { static: true }) videoContainer: ElementRef;

  constructor(private changeDetector: ChangeDetectorRef) { }

  public get confidenceRgb() {
    if (this.confidence === 0) {
      return 'gray';
    } else if (this.confidence < this.autoThreshold / 3) {
      return 'red';
    } else if (this.confidence < this.autoThreshold / 2) {
      return 'amber';
    } else {
      return 'green';
    }
  }

  ngOnInit() {

    try {

      Quagga.init({
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: this.videoContainer.nativeElement
        },
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'upc_reader',
          ]
        },

      }, (err) => {
        if (err) {
          logError(err, 'initialising Quagga');
          // this.cameraClosed(false);
          return;
        }
        // this._quagga.onProcessed((data) => { if (data) { console.log(data) } });
        Quagga.onDetected((data) => this._barcodeDetected(data));
        Quagga.start();
        this.captureOpen = true;

        if (this.videoContainer) {
          const e = this.videoContainer.nativeElement.querySelector('.drawingBuffer');
          if (e) {
            e.hidden = true;
          }
        }
      });

    } catch (error) {
      logError(error, 'Barcode Camera OnInit');
    }
  }

  ngOnDestroy(): void {
    this.stop();
  }

  public stop = () => {
    if (this.captureOpen) {
      Quagga.stop();
      this.captureOpen = false;
    }
  }

  public forceCapture = () => {
    this._captured();
  }

  public reset = () => {
    this._results = new Map<string, number>();
    this._totalMatches = 0;
    this.value = null;
    this.confidence = 0;
    this.changeDetector.markForCheck();
  }

  public cancel = () => {
    this.codeCaptured.emit(null);
  }

  private _captured = () => {
    const details = new CodeDetails(this.value, this.confidence);
    this.codeCaptured.emit(details);
  }

  private _barcodeDetected = (data: Quagga.QuaggaJSResultObject) => {
    if (data && data.codeResult) {
      // could check _hasLowErrorLevel but doesn't seem to be worthwhile
      const result = data.codeResult.code;
      if (result) {
        if (this._results.has(result)) {
          this._results.set(result, this._results.get(result) + 1);
        } else {
          this._results.set(result, 0);
        }

        this._totalMatches++;

        // Iterate over the codes we've found; find the best match and
        // count the number outside the bottom 5% as they're likely to be noise
        let significantCount = 0;
        let best = 0;
        let bestMatch = '';
        this._results.forEach((matches, value) => {
          if (matches > this._totalMatches / 20) {
            significantCount += matches;
          }

          if (matches > best) {
            best = matches;
            bestMatch = value;
          }
        });

        this.confidence = best / significantCount * 100;
        this.value = bestMatch;

        this.summary = `${bestMatch} ${this.confidence}%`;
        this.changeDetector.markForCheck();

        if (this.autoCapture && this._totalMatches > this.minSamplesBeforeAuto && this.confidence > this.autoThreshold) {
          this._captured();
        }
      }
    }
  }
}
