import { concatMap, Observable, Subject, Subscription } from 'rxjs';

/**
 * Base class for Vertex MVC.
 */
export abstract class MvcBase {

  private subscriptions = new Array<Subscription>();

  public disposed$ = new Subject<void>();

  public dispose() {
    this.subscriptions.forEach(sub => {
      sub?.unsubscribe();
    });

    this.subscriptions.length = 0;

    this.disposed$.next();
  }

  /**
   * Subscribe to an observer.
   * Subscriptions made through this function are automatically released when the model
   * is disposed with the dispose method.
   * If a subscription must be cancelled early call @see unsubscribe to ensure the
   * subscription is cleaned up.
   */
  protected subscribe<TObs>(obs: Observable<TObs>, handler: (t: TObs) => void): Subscription {
    if (obs) {
      const sub = obs.subscribe(handler);
      this.subscriptions.push(sub);
      return sub;
    }
    return null;
  }

  /**
   * Subscribe to an observer using an async method.
   * @param obs 
   * @param handler 
   */
  protected subscribeAsync<TObs>(obs: Observable<TObs>, handler: (t: TObs) => Promise<void>) {
    return this.subscribe(obs.pipe(
      // concatMap waits for the promise to complete before emitting again
      concatMap(t => handler(t)),
    ), () => { });
  }

  protected unsubscribe(sub: Subscription) {
    if (sub) {
      this.subscriptions = this.subscriptions.filter(s => s !== sub);
      sub.unsubscribe();
    }
  }
}

