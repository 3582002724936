import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { contentPinnedStateAnimation, leftAsideStateAnimation, homepageHelpStateAnimation, mobileHomepageHelpStateAnimation } from 'app/_constants';
import { OnlineStatusService } from '@softools/softools-core';

import { AppService } from 'app/services/app.service';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { WSAppHomeComponent } from '../ws-apphome.component';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Component({
  selector: 'app-ws-childapphome',
  templateUrl: './ws-childapphome.component.html',
  styleUrls: ['../ws-apphome.component.scss'],
  animations: [
    contentPinnedStateAnimation,
    leftAsideStateAnimation,
    homepageHelpStateAnimation,
    mobileHomepageHelpStateAnimation,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSChildAppHomeComponent extends WSAppHomeComponent implements OnInit, OnDestroy {

  constructor(
    public override media: MediaObserver,
    route: ActivatedRoute,
    public override onlineStatus: OnlineStatusService,
    public override appService: AppService,
    navigationService: NavigationService,
    protected override permissionsService: PermissionsService,
    models: GlobalModelService,
  ) {
    super(media, route, onlineStatus, appService, navigationService, permissionsService, models);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.routerQuerySub.unsubscribe();
    this.routerQuerySub = this.route.params.subscribe(() => {
      this.routeChanged(this.route.snapshot.params);
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
