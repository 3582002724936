import { Injectable } from '@angular/core';
import { RepositoryBase } from './repositories.base';
import { empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadRepository extends RepositoryBase {

  private readonly utf8prefix = `UTF-8''`;

  /**
   * Download file data
   * @param url   Download url, either a full URL or relatative to server base URL
   */
  public download(url: string) {
    const path = url.startsWith('http') ? url : this.urlResolver.servicePath() + url;
    return this.http.get(path, this.getOptionsForBlob()).pipe(
      map((response) => {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition ? this.getFilenameFromContentDisposition(contentDisposition) : null;
        const blob = new Blob([response.body], { type: response.type.toString() });
        return {
          data: blob,
          name: filename
        };
      }),
      catchError((_) => {
        return empty();
      })
    );
  }

  private getFilenameFromContentDisposition(contentDisposition: string): string {

    // See https://tools.ietf.org/html/rfc6266

    const parts = contentDisposition.split(';').map(part => part.trim().split('=', 2));
    const type = parts.shift();   // consume "attachment"

    // If filename* is specified it is an encoded version of the filename
    // preamble is the format - we only expect (and support) URI encoded UTF8
    // Currently this seems to be consistently returned
    const extFilename = parts.find(p => p[0] === 'filename*');
    if (extFilename?.[1].startsWith(this.utf8prefix)) {
      const name = decodeURI(extFilename[1].substring(this.utf8prefix.length));
      return name;
    } else {
      // Fall back to filename - this is a simple ascii filename
      // If it contains spaces it is quoted
      const filename = parts.find(p => p[0] === 'filename');
      if (filename) {
        const name = filename[1];
        if (name.startsWith('"') && name.endsWith('"')) {
          return name.substring(1, name.length - 1);
        } else {
          return name;
        }
      }
    }

    return null;
  }
}
