import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import { RadioModule } from '../radio.component/radio.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [FilterComponent],
  imports: [
    CommonModule,
    RadioModule,
    FontAwesomeModule
  ],
  exports: [FilterComponent],
  providers: [],
})
export class FilterModule { }
