<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <div class="cardv2-header">
      <div i18n>
        Email Activity
      </div>
      <div class="small text-muted" i18n>Email feed is delayed</div>
      <div class="small text-muted"><span i18n>For more information visit our support page </span> <a
          href="https://support.softools.net/hc/en-gb/articles/8023515013149-Email-Activity-for-Users-and-Pending-users"
          target="_blank" i18n>here</a></div>
    </div>

    <mat-dialog-content>
      <div class="cardv2-body">
        <ng-container *ngIf="model.loading.busy$|async; else emailMessagesTemplate">
          <sof-spinner-icon class="st-text-theme-primary" size="2x"></sof-spinner-icon>
        </ng-container>
        <ng-template #emailMessagesTemplate>
          <ng-container *ngIf="!(model.error.$|async); else emailActivityError">
            <ng-container *ngIf="(model.emailActivity.$|async) as emailActivity">
              <ng-container *ngIf="emailActivity?.messages?.length > 0; else noMessages">
                <div *ngFor="let message of emailActivity.messages">
                <b>{{message.subject}}</b>
                <br /> <span class="small text-muted text-lowercase">from: {{message.from_email}}</span>
                <br />
                <span i18n class="small text-muted text-lowercase">on: {{message.last_event_time | date: 'short'}}</span>
                <br />
                <br />
                <span i18n>Status: {{message.status}}</span>
                <br />
                <span i18n>Open count: {{message.opens_count}}</span>
                <br />
                <span i18n>Click count: {{message.clicks_count}}</span>
                <hr />
              </div>
              <b *ngIf="emailActivity.messages.length >= 100" class="small" i18n>Omitted results</b>
              </ng-container>
            </ng-container>
            <ng-template #noMessages>
              <span i18n>No email activity found in the last 30 days</span>
            </ng-template>
          </ng-container>
          <ng-template #emailActivityError>
            <span i18n>Email Activity is busy, please try again in a few minutes</span>
          </ng-template>
        </ng-template>
      </div>
    </mat-dialog-content>

    <div class="cardv2-footer">
      <button type="button" class="close" [matDialogClose] i18n>
        Close
      </button>
    </div>
  </div>
</div>
