<div [vxWatch]="model.busy"  *ngIf="model.busy.value; else paginator"
   class="w-100 d-flex align-items-center justify-content-center">
  <sof-spinner-icon></sof-spinner-icon>
</div>
<ng-template #paginator>
  <div
  [vxWatch]="model"
  class="container-fluid text-center ps-0 pe-0">
    <app-pagination *ngIf="model.valid.value"  class="w-100 h-100"
      [isBusy]="false"
      [singleRecord]="true"
      [skip]="model.cursor.value"
      [top]="1"
      [count]="model.count.value"
      (firstRecordClicked)="firstClicked()"
      (lasttRecordClicked)="lastClicked()"
      (prevClicked)="leftClicked()"
      (nextClicked)="rightClicked()"
      >
      </app-pagination>
  </div>
</ng-template>
