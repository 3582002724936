<div class="sof-modal-container">
  <div class="cardv2-popup">
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-4">
            <mat-slide-toggle i18n [checked]="autoCapture" title="Automatically accept capture" i18n-title>Auto</mat-slide-toggle>
          </div>
          <div class="col-8" style="font-size: small">
            <label>{{ value }}</label>
          </div>
        </div>
      </div>

      <div>
        <div #videoContainer id="brownie" style="display: flex; align-content: center; justify-content: center">
          <video
            [style.width.px]="videoWidth"
            [style.height.px]="videoHeight"
            autoplay="true"
            preload="auto"
            src=""
            muted="true"
            playsinline="true"
          ></video>
        </div>
      </div>

      <div class="d-flex mt-1" style="width: 100%">
        <div
          [style.width.px]="(videoWidth * confidence) / 100"
          style="height: 4px; border: none"
          [style.background-color]="this.confidenceRgb"
          [title]="this.confidence"
        ></div>
      </div>
    </mat-card-content>
    <div class="cardv2-footer">
      <div class="buttons-grid">
        <button
          i18n-title
          title="Accept current capture result"
          type="button"
          class="primary bt1"
          style="background-color: green !important"
          (click)="forceCapture()"
        >
          <fa-icon [icon]="['fal', 'check']" [border]="false"></fa-icon>
          <span i18n>Capture</span>
        </button>

        <button i18n-title title="Reset" type="button" class="primary bt2" style="background-color: orange !important" (click)="reset()">
          <fa-icon [icon]="['fal', 'square']" [border]="false"></fa-icon>
          <span i18n>Reset</span>
        </button>

        <button
          i18n-title
          title="Cancel"
          type="button"
          (click)="cancel()"
          class="primary bt3"
          style="background-color: red !important"
          type="button"
        >
          <fa-icon [icon]="['fal', 'times']" [border]="false"></fa-icon>
          <span i18n>Cancel</span>
        </button>
      </div>
    </div>
  </div>
</div>
