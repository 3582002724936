import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { logError } from '@softools/softools-core';
import { TableReportPaginatorComponent } from '../table-report-paginator/table-report-paginator.component';

@Component({
  selector: 'app-list-report-paginator',
  templateUrl: './list-report-paginator.component.html',
  styleUrls: ['./list-report-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListReportPaginatorComponent extends TableReportPaginatorComponent {
  @Input() public top: number;
  @Input() public skip: number;
  @Input() public count: number;
  @Input() public fixedPageSize = false;
  @Input() public isBusy = false;

  public pageSizeChanged(pageSize: string | number) {
    this.reportModel.updatePageSize(+pageSize).catch(e => logError(e, 'Failed to update page size'));
  }
}
