// List field implementation

import { Failure, Record, TrackChangeList, ValidationError } from '@softools/softools-core';
import { AppField } from './app-field';
import { RecordPatch } from 'app/workspace.module/types';
import { ExecutableRule } from 'app/mvc/rules/executable-rule';

export class ListAppField extends AppField {
  public override validateRecord(record: Record, listRow?: number): Array<Failure> {
    const errors = [] as Array<Failure>;

    const value = this.getRawRecordValue(record, listRow) as Record;
    this.validate(value, errors);

    if (this.SubFieldsIdentifiers && this.application && Array.isArray(value)) {
      this.SubFieldsIdentifiers.forEach((sub) => {
        const subfield = this.application.getField(sub.Identifier);
        if (subfield) {
          for (let index = 0; index < value.length; index++) {
            const v = subfield.getRawRecordValue(record, index);
            subfield.validate(v, errors, index);
          }
        }
      });
    }

    return errors;
  }

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    // If required, list must have at least one row
    if (this.Required) {
      if (value === undefined || !Array.isArray(value) || value.length < 1) {
        errors.push({ error: ValidationError.RowsRequired, fieldId: this.Identifier, listRow });
      }
    }
  }

  public override match(rule: ExecutableRule, record: Record, callback: (matched: boolean, key: any) => void) {
    if (rule.MultiRow) {
      const rowKeyId = this.ListFieldParameters?.RowKey || 'Key';
      const array = this.getInternalRecordValue(record);
      if (array && Array.isArray(array)) {
        array.forEach((rowRecord, index) => {
          const match = rule.filter.isMatch(record, index);
          callback(match, rowRecord[rowKeyId]);
        });
      }
    } else {
      super.match(rule, record, callback);
    }
  }

  public override updatePatch(patch: RecordPatch, record: Record, local = false) {

    const rows = this.getInternalRecordValue(record) as Array<any>;
    if (rows?.length > 0) {
      const rowKeyId = this.ListFieldParameters?.RowKey || 'Key';
      const tracked = new TrackChangeList(rowKeyId);
      rows.forEach(row => {
        tracked.addAdditionalRow(row);
      });

      patch.addTrackedChange(this.Identifier, tracked);
    }
  }

  public override updateRecord(record: Record, value: any) {
    let current = this.getRawRecordValue(record) || [];
    if (value instanceof TrackChangeList) {

      // Update added/removed fields first so any changes can apply to them
      value.added.forEach(addition => {
        // Use == because key may be numeric (e.g. site properties)
        /* eslint-disable-next-line eqeqeq */
        if (!current.find(f => f[value.keyField] == addition[value.keyField])) {
          current.push(addition);
        }
      });

      value.removed.forEach(removal => {
        current = current.filter(f => removal !== f[value.keyField]);
      });

      Object.getOwnPropertyNames(value.changes).forEach(rowKey => {
        const row = value.changes[rowKey];
        // Use == because key may be numeric (e.g. site properties)
        /* eslint-disable-next-line eqeqeq */
        const entry = current.find(f => f[value.keyField] == rowKey);
        if (entry) {
          Object.getOwnPropertyNames(row).forEach(name => {
            const change = row[name];
            const subfieldId = `${this.Identifier}_${name}`;
            const subfield = this.application.getField(subfieldId);
            if (subfield) {
              const adapted = subfield.adaptValue(change, entry[name], record);
              entry[name] = adapted;
            }
          });
        }
      });

      this.storeToRecord(record, current);
      this.setBacking(record, current);

    } else if (Array.isArray(value)) {
      // should we replace here?
    }
  }

  public override compactRecord(record: Record): any | null {

    const rows = this.getInternalRecordValue(record) as Array<any>;
    if (rows) {
      this.SubFieldsIdentifiers?.forEach(i => {
        const subField = this.application.getField(i.Identifier);
        if (subField) {
          rows.forEach(row => {
            subField.compactRecord(row);
          });
        }
      });
    }

    return rows;

    // todo recursive compact on elements
    // super.compactRecord(record);
  }

  public override cloneValue(record: Record) {
    const array = this.getInternalRecordValue(record) as Array<any>;
    if (array) {
      record[this.DataIdentifier] = [...array].map((row) => ({ ...row }));
    }
  }
}
