import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MulticastHttpService {
  private requests = new Map<string, Observable<any>>();

  /**
   * Share the observable if one already exists.
   * https://rxjs-dev.firebaseapp.com/api/operators/share
   * https://blog.strongbrew.io/how-share%28%29-can-reduce-network-requests/
   * @param url
   * @param funcGet
   */
  public queueOrGet(url: string, funcGet: () => Observable<any>) {
    if (this.requests.has(url)) {
      return this.requests.get(url);
    }

    const obs = funcGet().pipe(share());
    this.requests.set(url, obs);
    return obs;
  }
}
