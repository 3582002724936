import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { invalidValue } from 'app/types/invalid-value';
import { TeamsService } from 'app/services/teams.service';
import { MappedTeam, logError } from '@softools/softools-core';

interface ITeamValue {
  val: string;
  txt: string;
}

@Component({
  selector: 'sof-team-field',
  templateUrl: './team-field.component.html',
  styleUrls: ['./team-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamFieldComponent extends EditableFieldBase<ITeamValue> {

  /** The name displayed in the input field.  This might not be a user in the list if text is being typed */
  public name = '';

  constructor(private teamsService: TeamsService) {
    super();
  }

  protected override onValueChanged(value: ITeamValue) {
    super.onValueChanged(value);

    if (value) {
      // Accept string value for settings apps
      const id: string = value.val || ((value as any));
      if (id) {
        let team = this.teamsService.getMappedTeam(id);
        if (!team) {
          team = this.teamsService.getAllMappedTeams().find(t => t.Text === value.txt);
        }

        this.name = team ? team.Text : (value.txt || '');
      }
    } else {
      this.name = '';
    }

    this.checkValid();
  }

  public override async activate() {
    this.input?.nativeElement?.focus();
  }

  protected teamList(): Array<MappedTeam> {
    return this.teamsService.getAllMappedTeams();
  }

  public get filteredTeams(): Array<MappedTeam> {
    const searchTerm = this.name.toLowerCase();
    return this.teamList().filter(team => team.Text.toLowerCase().indexOf(searchTerm) >= 0);
  }

  public autoCompleteItemSelected($event: MatAutocompleteSelectedEvent): void {
    this.dispatchTeamAsync($event.option.value).catch(error => logError(error, 'autoCompleteItemSelected'));
  }

  private async dispatchTeamAsync(name: string): Promise<void> {
    if (name) {
      // Look up value by name not id
      const team = this.teamsService.getAllMappedTeams().find(t => t.Text === name);
      const value = team ? team as any : invalidValue;
      await this.updateValueAsync(value?.Value);
    } else {
      await this.updateValueAsync(null);
    }
  }

  public checkCleared($event) {
    if ($event.code === 'Backspace' || $event.code === 'Delete') {
      if ($event.target.value.length === 0 || $event.shiftKey) {
        this.dispatchTeamAsync(null).catch(error => logError(error, 'team checkCleared'));
      }
    } else if ($event.code === 'Enter') {
      // Pick first user on enter
      const choices = this.filteredTeams;
      this.dispatchTeamAsync(choices?.length > 0 && choices[0].Text).catch(error => logError(error, 'team enter'));
    }
  }

  filteredTeamsTrackByFn(_, item: MappedTeam) {
    return item.Value;
  }
}
