import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-form-template-header',
  templateUrl: './form-template-header.component.html',
  styleUrls: ['./form-template-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTemplateHeaderComponent {

  /** Whether the form template is expanded. */
  @Input() expanded = true;

  /** Whether the form template help is expanded. */
  @Input() expandedHelp = false;

  @Input() identifier: string;
  @Input() helpText: string;
  @Input() iconBase64?: string;
  @Input() title: string;

  @Output() onToggleExpandedFormTemplate = new EventEmitter();
  @Output() onToggleExpandedFormTemplateHelp = new EventEmitter();

  constructor() { }

  public onToggleFormTemplateClickHandler($event: MouseEvent, templateIdentifier: string) {
    $event.stopPropagation();

    this.onToggleExpandedFormTemplate.emit({ Identifier: templateIdentifier });
  }

  public onToggleFormTemplateHelpClickHandler($event: MouseEvent, templateIdentifier: string) {
    $event.stopPropagation();

    this.onToggleExpandedFormTemplateHelp.emit({ Identifier: templateIdentifier });
  }
}
