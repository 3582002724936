<app-input-icon icon="search" 
    [clickable]="true"  
    [elementStyles]="elementStyles"
    (iconClicked)="openLookup($event)">
  <input #input
    class="form-control  form-control-o"
    [ngClass]="{ 'inline': inline }"
    (keydown)="keyDown($event)"
    [matAutocomplete]="auto"
    [formControl]="lookupControl" />
</app-input-icon>
<div>
  <small class="text-muted" i18n>Look up {{targetApp?.Title}} record</small>
</div>

<mat-autocomplete #auto="matAutocomplete" 
  (optionSelected)="optionSelected($event)"
  (optionActivated)="optionActivated($event)"
  >
  <mat-option *ngFor="let record of lookupOptions$ | async" [value]="record">
    <b *ngIf="lookupFieldTitleFieldIdentifier && record[lookupFieldTitleFieldIdentifier]">{{lookupTitleField.getDisplayRecordValue(record)}} </b>
    <span>{{lookupSearchField.getDisplayRecordValue(record)}}</span>
  </mat-option>
</mat-autocomplete>
