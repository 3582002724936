import { Injectable } from '@angular/core';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { ImageListsRepository } from '../repos';
import { App, Enums, ImageList } from '../types';
import { ImageListsStorageService } from './services.imagelists';
import { IMAGE_LIST_ASSET_STORE } from '../indexedDb/database-stores';
import { logError } from '../utils/log-error';

interface ListImageData {
  type: string;
  image: Array<number>;
}

/**
 * Stores image lists assets and image field assets to be used offline
 * */
@Injectable({ providedIn: 'root' })
export class ImagesListAssetStorageService {

  constructor(
    private dbContext: DatabaseContextService<ListImageData>,
    private imageListsService: ImageListsStorageService,
    public imageListRepository: ImageListsRepository) {
  }

  public async syncAllImageListAssets(apps: Array<App>) {
    // Get all images lists used in the site
    const ids = new Set<string>();
    apps.map(app => app.Fields)
      .reduce((acc, fields) => acc.concat(fields))
      .filter(field => field.Type === Enums.FieldType.ImageList)
      .forEach(imageField => ids.add(imageField.ImageListIdentifier));

    const imageLists = new Set<ImageList>();
    for (const id of ids) {
      const imageList = await this.imageListsService.getImageListAsync(id);
      if (imageList) {
        imageLists.add(imageList);
      }
    }

    await this.syncImageListAssets(Array.from(imageLists));
  }

  public async syncImageListAssets(imageLists: Array<ImageList>) {
    const assetUrls = new Array<string>();
    imageLists.forEach(imageList => {
      if (imageList && imageList.ImageListOptions) {
        imageList.ImageListOptions.map(opt => opt.ImageListAssetUri).forEach(url => assetUrls.push(url));
      }
    });

    await Promise.all(assetUrls.map(async (url) => {
      try {
        const img: Blob = await this.imageListRepository.gatImageByAssetUrl(url);
        await this.storeImageAsync(url, img);
      } catch (error) {
        logError(error, 'failed to get image');
      }
    }));
  }

  public async getImageBlobAsync(assetUrl: string): Promise<Blob> {

    const imageData = await this.dbContext.get(IMAGE_LIST_ASSET_STORE, assetUrl);
    if (imageData) {
      const restored = new Uint8Array(imageData.image);
      const buffer = restored.buffer;
      const blob = new Blob([buffer], { type: imageData.type });
      return blob;
    }

    return null;
  }

  private readImageBlob(file: Blob): Promise<ArrayBuffer> {
    const promise = new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = (ev) => {
        reject(ev);
      };

      reader.readAsArrayBuffer(file);
    });

    return promise;
  }

  /**
   *
   * @param imageKey
   * @param image
   */
  public async storeImageAsync(url: string, image: Blob): Promise<boolean> {

    const data = await this.readImageBlob(image);
    const typed = new Uint8Array(data);

    const imageData: ListImageData = {
      type: image.type,
      image: Array.from(typed)
    };

    return this.dbContext.save(IMAGE_LIST_ASSET_STORE, url, imageData);
  }
}
