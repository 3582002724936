import { Directive, Input, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { Enums } from '@softools/softools-core';

@Directive({
  selector: '[appSetGridColumnStart]'
})
export class SetGridColumnStartDirective implements AfterViewInit {

  private layout: HTMLDivElement;

  @Input() currentCol: number;
  @Input() mode;

  @HostListener('window:resize') onResize() {
    this._setGridcolStartEnd();
  }

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.layout = (<HTMLDivElement>this.el.nativeElement);
    this._setGridcolStartEnd();
  }

  private _setGridcolStartEnd() {
    this.layout.style['minWidth'] = '0'; // Fixes inapp chart not correctly resizing - Not sure why https://css-tricks.com/preventing-a-grid-blowout/
    if (this.mode === Enums.BuilderRowMode.Span) {

      // Span mode so full width layout 1/1
      this.layout.style['gridColumnStart'] = '1';
      this.layout.style['gridColumnEnd'] = '1';
      if (this.layout.parentElement.className.indexOf('spanmode') <= 0) {
        this.layout.parentElement.classList.add('spanmode'); // This directive get's evaluated first, so let's add spanmode class to make it easy for parent directive
      }

    } else if (this.layout.parentElement.clientWidth > 1366) {

      // Set col start/end for layout positioning
      this.layout.style['gridColumnStart'] = this.currentCol.toString();
      this.layout.style['gridColumnEnd'] = this.currentCol.toString();

    } else {

      // Auto grid wrap layout activated
      this.layout.style['gridColumnStart'] = '';
      this.layout.style['gridColumnEnd'] = '';
    }
  }
}
