<div *ngIf="reportModel as model" class="reports h-100 w-100" >

  <div [vxWatch]="model" class="primary-report" [ngClass]="dualReportStyle" >
    <app-chart *ngIf="(!(reportModel.busy.busy$|async) && (chartReportModel.chartConfig.value || chartReportModel.chartError.value))"
      [globalModel]="globalModel"
      [chartReportModel]="chartReportModel"
      [chartContainerId]="'chart_' + model.report.value?.Id"
      [displayChart]="model.displayChart"
      [displayMatrixTable]="model.displayMatrixTable"
      [chartConfig]='chartReportModel.chartConfig.value'
      [matrixModel]="chartReportModel.matrixModel.value"
      [chartData]="chartReportModel.chartData.$|async"
      [chartType]='model.report.value?.Chart?.ChartType'
      [reportType]='model.report.value?.Type'
      [appIdentifier]='(appModel.app.$|async)?.Identifier'
      [appIdentifiers]='appModel.appIdentifiers.$|async'
      [isArchived]='globalModel.archived.$|async'
      [parentRecordId]="reportModel?.parentRecordId.$|async"
      [chartingError]="chartReportModel.chartError.value"
      >
    </app-chart>

    <app-chart-google *ngIf="!(reportModel.busy.busy$|async) && model.report.value?.Chart?.ChartType === Enums.ChartType.googlemap"
      [reportModel]="chartReportModel"
      [appIdentifiers]="appModel.appIdentifiers.$|async"
      [chartContainerId]="'chart_' + model.report.value?.Id"
      (onGoogleChartClickthrough)="onGoogleChartClickthroughHandler($event)">
    </app-chart-google>

    <ng-container *ngIf="!(reportModel.busy.busy$|async) && (chartReportModel.chartError.$|async) === 4">
      <app-ws-sharednorecords
        [app]="appModel.app.$|async"
        [reportModel]="reportModel"
        [isLoading]="model.busy.busy$|async"
        [isArchived]="globalModel.archived.$|async">
      </app-ws-sharednorecords>
    </ng-container>

    <div  *ngIf="model.busy.busyDelayed$|async" class="primary-busy-box w-100 h-100" >
      <app-busy-indicator [busyModel]="model.busy" ></app-busy-indicator>
    </div>

  </div>

  <div  *ngIf="isDual" class="split-box" [ngClass]="dualReportStyle" >
  </div>

  <div #scroller class="secondary-report bg-white" [ngClass]="dualReportStyle">
    <ng-container *ngIf="dualReportModel as model">
      <ng-container *ngIf="model.report.value?.Type === Enums.ReportTypes.List">
        <app-busy-indicator  *ngIf="model.busy.busyDelayed$|async"
          [busyModel]="model.busy" >
        </app-busy-indicator>
        <app-listreport
          [ngClass]="dualReportStyle"
          [appModel]="appModel"
          [reportModel]="dualListReportModel"
          [filterModel]="filterModel"
          [scroller]=scroller
          [editMode]="editMode"
          [detailsFields]="dualListReportModel.listReportDetailFields.$|async"
          [showDetailsField]="dualListReportModel.showDetailFields.$|async"
          [allowMultiSelect]="dualListReportModel.allowMultiSelect.$|async"
          [fields]="(appModel.app.$|async)?.AppFields"
          [parentRecordId]="model?.parentRecordId.$|async"
          [reportIdentifier]="reportModel.reportIdentifier.$|async"
          [enableAutoLayout]="model.autoLayout.$ | async"
          [autoLayoutOffByDefault]="(reportModel.report.$|async)?.AutoLayoutOffByDefault"
          [headerSummaryExpressions]="model.summaryExpressions.$|async"
          (onRecordClick)="onRecordClickHandler($event)"
          (onFieldUpdate)="$event.fieldIsValid ? onFieldUpdateHandler($event) : null"
          >
        </app-listreport>

        <ng-container *ngIf="!(model.busy.busyDelayed$|async)">
          <ng-container *ngIf="(model.records.$ | async)?.length === 0">
            <app-ws-sharednorecords
              [app]="appModel.app.$|async"
              [reportModel]="model"
              [isLoading]="model.busy.busy$|async"
              [isArchived]="globalModel.archived.$|async">
            </app-ws-sharednorecords>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container  *ngIf="model.report.value?.Type === Enums.ReportTypes.Table">
        <app-table-report *ngIf="(isOfflineReport) || (globalModel.online.$|async); else reportoffline"
          class="secondary-report w-100 h-100"
          style="position: absolute; overflow-x: hidden;"
          [ngClass]="dualReportStyle"
          [appModel]="appModel"
          [reportModel]="dualTableReportModel"
          [selectionMode]="2"
        ></app-table-report>
      </ng-container>
    </ng-container>
  </div>

  </div>

  <ng-template #reportoffline>
    <app-report-offline></app-report-offline>
  </ng-template>
