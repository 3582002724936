import { Directive, HostListener, ElementRef, AfterViewInit, Input, OnInit } from '@angular/core';
import { logError } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { ComponentBase } from 'app/softoolsui.module';

@Directive({
  selector: '[appSetGridTemplateColumns]'
})
export class AppSetGridTemplateColumnsDirective extends ComponentBase implements OnInit, AfterViewInit {

  private grid: HTMLDivElement;

  @Input() currentCol;
  @Input() maxColAnyRow;
  @Input() trackSizing = '1fr';
  @Input() container;

  @HostListener('window:resize', []) onResize() {
    this._setTemplateColumns();
  }

  constructor(private el: ElementRef, private models: GlobalModelService) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(this.models.globalModel.layoutChanged$, () => {
      this._setTemplateColumns();
    });
  }

  ngAfterViewInit(): void {
    this.grid = (<HTMLDivElement>this.el.nativeElement);
    this._setTemplateColumns();
  }

  private _setTemplateColumns() {

    try {

      if (!this.grid || this.grid.className.indexOf('spanmode') > -1) {
        return;
      }


      // Get width.  If not set (probably because it is collapsed) use the width of the
      // containing div.  Should be the same but playing safe.
      let gridWidth = this.grid.clientWidth;
      let maxAllowedCol = this.maxColAnyRow;

      // Todo - cdk breakpoint observers instead of resize event
      // (JC: that probably wouldn't work very well as it would give us window size, not template width
      // and would likely not fire every time we need it - just like this.
      // Best solution is probably using grids with media queries in the CSS )

      // We don't always get called when the attached element has a width assigned so work our
      // way up until we find one.  This is not perfect as we might find an element that is not
      // the same width as our template but it's better than nothing.  See above.
      if (gridWidth === 0) {
        let source = this.container;
        while (source) {
          if (source.clientWidth) {
            gridWidth = source.clientWidth;
            break;
          }

          source = source.parentElement;
        }
      }

      if (gridWidth < 616) {
        maxAllowedCol = 1;
      } else if (gridWidth < 866) {
        maxAllowedCol = 2;
      } else if (gridWidth < 1116) {
        maxAllowedCol = 3;
      } else if (gridWidth < 1366) {
        maxAllowedCol = 4;
      } else if (gridWidth < 1616) {
        maxAllowedCol = 5;
      }
      if (maxAllowedCol > this.maxColAnyRow) {
        maxAllowedCol = this.maxColAnyRow;
      }
      this.grid.style['gridTemplateColumns'] = `repeat(${maxAllowedCol}, ${this.trackSizing})`;

    } catch (error) {
      logError(error, '');
    }
  }
}
