import { Comment, CommentService, logError, RecordId } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model } from '@softools/vertex';
//import { AnalyticsService } from 'app/services/analytics.service';
import { InjectService } from 'app/services/locator.service';
import { lastValueFrom } from 'rxjs';
import { BusyModel, Causes } from '.';
import { AppModel } from './app.model';
import { AttachmentsModel } from './attachments-model';

export class CommentsModel extends Model<CommentsModel> {

  private recordId: RecordId;
  private appModel: AppModel;
  private attachmentsModel: AttachmentsModel;

  public readonly comments = new ArrayModelProperty<Comment>(this);
  public readonly loading = new BusyModel();
  public readonly showComments = new BooleanModelProperty(this);
  public readonly isConfirmVisible = new BooleanModelProperty(this);

  @InjectService(CommentService)
  private readonly commentService: CommentService;

  // @InjectService(AnalyticsService)
  // private readonly analyticsService: AnalyticsService;

  public constructor(container?: Model<any>) {
    super(container);
    this.comments.logChanges = 'COMMENTS';
  }

  public clear() {
    this.comments.value = null;
  }

  public async load(appModel: AppModel, attachmentsModel: AttachmentsModel, recordId: RecordId) {

    this.recordId = recordId;
    this.appModel = appModel;
    this.attachmentsModel = attachmentsModel;

    await this.loadComments();
  }

  public async loadComments() {
    try {
      if (this.appModel.app.value.AllowComments) {
        this.loading.start(Causes.loading);
        const comments = await lastValueFrom<Array<Comment>>(this.commentService.getComments(this.appModel.identifier.value, this.recordId));
        this.comments.value = comments?.sort((a, b) => (b.Date < a.Date ? 1 : -1)) ?? [];
      }
    } catch (error) {
      logError(error, 'loadComments');
    } finally {
      this.loading.finish(Causes.loading);
    }
  }

  public async saveComment(comment: Comment) {
    await this.commentService.saveComment(this.appModel.identifier.value, this.recordId, comment).toPromise();
    await this.loadComments();
    // causes a memory leak.
    //this.analyticsService.trackComment();
  }

  public async saveCommentSnippet(filename: string, base64Data: string) {
    await this.attachmentsModel.saveAttachment(filename, base64Data);
    await this.attachmentsModel.loadAttachments();
  }

  public async deleteComment(comment: Comment) {
    await this.commentService.deleteComment(this.appModel.identifier.value, this.recordId, comment).toPromise();
    await this.loadComments();
  }

  public open() {
    this.showComments.value = true;
  }

  public close() {
    this.showComments.value = false;
  }

  public toggle() {
    this.showComments.toggle();
  }
}
