
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { SelectOption } from '@softools/softools-core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DropdownComponent {

  @Input() uiIdentifier = 'dropdown';
  @Input() labelText = '';
  @Input() selectOptions: Array<SelectOption>;
  @Input() value;
  @Input() disabled;
  @Input() required;
  @Input() multiple = false;
  @Input() hasSelectAnItem = true;

  /** Use to override the appearance of the material form fields */
  @Input() public appearance: MatFormFieldAppearance = 'standard';

  /** Use to override the label on mat design */
  @Input() public disableLabel = false;

  /** If true the field is embedded in another field (e.g. a grid) so should display in a compact mode */
  @Input() isEmbedded = false;

  @Output() validationModel = new EventEmitter();

  @ViewChild('select') set select(val) {
    this.validationModel.emit(val);
  }

  @Output() onselect = new EventEmitter();

  public optionSelected($event) {
    if (!this.disabled) {
      this.onselect.emit($event.value);
    }
  }

  selectionValueCompare(option, value) {
    return option && value && option.toString() === value.toString();
  }
}
