import { ReportQueryGuard } from 'app/guards';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class MatrixReportQueryGuard extends ReportQueryGuard {

  /**
   *
   * @param route
   * @returns {boolean} true if the route data was changed
   */
  protected override prepareRoute(route: ActivatedRouteSnapshot): boolean {
    if (!this.processQuery(route)) {
      return false;
    }

    // Use base implementation to remove top, skip
    return super.prepareRoute(route);
  }
}
