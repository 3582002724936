<header class="header-text" *ngIf="!(model.loading.$ | async)">
  <div class="header-root w-100 h-100">

    <div matRipple *ngIf="showFoldersButton$|async" class="folders p-2 pointer" i18n-title title="Folders" (click)="onPinClickHandler($event)" role="button">
      <fa-icon [icon]="['fal', 'bars']" [border]=false size="2x"></fa-icon>
    </div>

    <div matRipple *ngIf="showLaunchButton$|async" class="launchpad p-2 pointer" name="launchpadIcon" role="button" (click)="onLaunchPadClick($event)">
      <fa-icon i18n-title title="Launchpad" [icon]="['fal', 'th']" [border]=false size="2x"></fa-icon>
    </div>

    <div matRipple *ngIf="showHomeButton$|async" class="home p-2 pointer" role="button">
      <a [routerLink]="homepageLinkUrl" routerLinkActive="active" class="header-text">
        <fa-icon [icon]="['fal', 'home']" size="2x"></fa-icon>
      </a>
    </div>

    <div matRipple [@backButtonState]="(model.showBackButton.$ | async) ? 'in' : 'out'" i18n-title title="Back" class="back p-2 pointer" (click)="onGoBackClickHandler($event)" >
      <fa-icon [icon]="['fal', 'arrow-left']" [border]=false size="2x"></fa-icon>
    </div>

    <div class="breadcrumbs text-center text-nowrap text-truncate d-none d-sm-block">
      <h4 id="breadcrumb" *ngIf="model.title.$ | async" [attr.title]="model.title.$ | async" class="text-nowrap mb-0 text-truncate font-weight-light noselect">
        {{model.title.$ | async}}
      </h4>
    </div>

    <div matRipple *ngIf="showNotifcationBell$|async" class="bell px-2 pointer">
      <app-bell
        [globalModel]="globalModel"
        [notificationsModel]="notificationsModel"
        (onClearNotificationClick)="onClearNotificationClick($event)" (onClearAllNotificationClick)="onClearAllNotificationClick()"
        [isOnline]="globalModel.online.$ | async" (onDownloadClick)="onDownloadClick($event)"></app-bell>
    </div>

    <div matRipple class="cog" *ngIf="(model.settingsActions.$ | async)?.length > 0">
      <div class="d-flex">
        <div class="pointer">
          <app-header-actions icon="cog" i18n-title title="Cog Menu" [actions]="(model.settingsActions.$ | async)" [isOnline]="globalModel.online.$ | async" [showVersion]="true"
            [disableWhenOffline]="false" (onActionClick)="onActionClickHandler($event)">
          </app-header-actions>
        </div>
      </div>
    </div>

    <div matRipple class="actions" *ngIf="(model.toolbarActions.$ | async)?.length > 0">
      <div class="d-flex">
        <div class="pointer">
          <app-header-actions i18n-title title="Actions Menu" icon="ellipsis-h" iconWeight="fas" [actions]="model.toolbarActions.$ | async" [isOnline]="globalModel.online.$ | async" (onActionClick)="onActionClickHandler($event)">
          </app-header-actions>
        </div>
      </div>
    </div>
  </div>
</header>

<header *ngIf="(model.loading.$ | async)" class="muted"></header>
