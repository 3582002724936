import * as moment from 'moment';

import { OnInit, Input, ElementRef, Renderer2, OnChanges, SimpleChanges, Output, EventEmitter, Directive } from '@angular/core';
import { AppField } from 'app/types/fields/app-field';
import { Record, Form, AlignmentTypeAlias, Enums, EpochDate, SelectListOption, logError, Template, ElementStyles, Failure } from '@softools/softools-core';
import { isNumberField } from 'app/_constants';
import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { UsersService } from 'app/services/users.service';
import { AppService } from 'app/services/app.service';
import { SelectListsService } from 'app/services/select-lists.service';
import { InputChangedTrackService } from 'app/services/input-changed-track.service';
import { IRuleState } from 'app/mvc/rules/rule-state.interface';
import { AppModel, ReportModel } from 'app/mvc';

export const ATTR_VALUE = 'value';
export const ATTR_CHECKED = 'checked';
export const ATTR_MIN = 'min';
export const ATTR_MAX = 'max';
export const ATTR_ROWS = 'rows';
export const ATTR_MAXLENGTH = 'maxlength';
export const ATTT_DISABLED = 'disabled';

export const VALIDATION_CLASS = 'validation-error';

/**
 * Base component type for input and textarea
 */
@Directive()
export abstract class BaseInputComponent<TElement extends HTMLInputElement | HTMLTextAreaElement> implements OnInit, OnChanges {

  @Input() public appModel: AppModel;

  /** Report model (if the field is on a report) */
  @Input() public reportModel?: ReportModel;

  @Input() field: AppField;

  /** Data record if the input field is attached to one */
  @Input() record: Record;

  /** Current value.  If attached to a record this is updated automatically, otherwise it is set explicitly */
  @Input() value: any;

  /** If set, the field (e.g. list or grid field) that contains this field  */
  @Input() public containerField: AppField;

  /** The row number if the field is contained inside a list field */
  @Input() public listRow: number;

  /** The form containing the field */
  @Input() public form?: Form;

  /** The template containing the field (not set if on a report etc.) */
  @Input() public template: Template;

  /** Optional input type, set from field type if not specified */
  @Input() public inputType: string;

  /** Alignment string; left/ center/ right. No value will be default alignment.
   * Field/ ListReportField/ ListReportDetailField can have configured optional Alignment property via AppStudio.
   * ListReportField/ ListReportDetailField usage will override field entity alignment as below.
   */
  @Input() alignmentOverride?: AlignmentTypeAlias;

  @Input() public ruleState: IRuleState;

  /** Force disabled state if true */
  @Input() public disabled = undefined;

  @Input() public elementStyles: ElementStyles;

  @Input() public textStyle = {};

  @Output() public inputChanged = new EventEmitter<{ $event: Event, value: string }>();

  @Output() public change = new EventEmitter<Event>();

  @Output() public valueChanged = new EventEmitter<any>();

  @Output() public blurred = new EventEmitter<any>();

  @Output() public focussed = new EventEmitter<void>();

  @Output() public onInputValidation = new EventEmitter<Array<Failure>>();

  // Derived class should locate element with @ViewChild
  protected element: ElementRef<TElement>;

  private _previousValue;

  private _hasFocus = false;

  public hasValidationErrors = false;

  private hasFieldValidationErrors: boolean;

  constructor(
    private renderer: Renderer2,
    private recordValidationService: RecordValidationService,
    private appService: AppService,
    private usersService: UsersService,
    private selectListService: SelectListsService,
    private inputChangedTrackService: InputChangedTrackService
  ) {
  }

  ngOnInit(): void {

    if (this.field) {
      if (this.field.Type === Enums.FieldType.Literal) {
        this._setReadonlyAttribute();
      }

      if (this.field.Align === 'right') {
        this.renderer.addClass(this.element.nativeElement, `align-right`);
      }

      this._setDisabledAttribute();

      if (this.field.Type === Enums.FieldType.LongText && this.field.Rows > 0) {
        this._setAttributeFromFieldValue(ATTR_ROWS, this.field.Rows);
      }

      if (isNumberField(this.field.Type)) {
        this._setAttributeFromFieldValue(ATTR_MIN, this.field.MinValue);
        this._setAttributeFromFieldValue(ATTR_MAX, this.field.MaxValue);
      }

      this._setAttributeFromFieldValue(ATTR_MAXLENGTH, this.field.Length);

      this._setAttribute('data-identifier', this.field.Identifier);
      this._setAttribute('type', this._getTypeString());

      // If the field is a type of number then avoid changing values accidentally because of mouse scroll.
      if (this._getTypeString() === 'number') {
        this._setAttribute('onwheel', 'return false;');
      }

      // Set autocomplete as specified or off otherwise
      // Chrome ignores off but an unrecognised string stops it, hence "off_"
      const autocomplete = this.field.TextFieldOptions?.AutoCompleteType ?? 'off_';
      this._setAttribute('autocomplete', autocomplete);

      this.renderer.addClass(this.element.nativeElement, `field-${this.field.Type}`);

      /**
       * Firefox does not have a period or datetime field.
       */
      if (this.field.Type === Enums.FieldType.Period) {
        this._setAttribute('placeholder', 'yyyy-mm');
      }

      // We've assembled date time out of bits
      // if (this.field.Type === Enums.FieldType.DateTime) {
      //   this._setAttribute('placeholder', 'yyyy-mm-dd HH:mm:ss');
      // }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    // we dont want to update the value if focus is on the element
    // This stops the flash
    if (!this._hasFocus) {

      /** Dont display un supported fields */
      if (this._unsupported()) {
        this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
        return;
      }

      if (this.field) {
        if (changes['record'] && this.record) {
          // update value from record
          this.value = this.field.getRecordValue(this.record, this.listRow);
        }
      }

      if (this.field) {
        /** TODO: Should be set form validation service input */
        if (changes['record'] && this.record) {
          // Update validation unless on a readonly form
          if (!this.form?.ReadOnly) {
            const errorMapsForRecord = this.record._errs;
            const errorList = errorMapsForRecord?.filter((err) => err.fieldId === this.field.Identifier)
              .filter(err => this.listRow === undefined || this.listRow === err.listRow);
            this.hasValidationErrors = (errorList?.length > 0);
            this.hasValidationErrors ? this._addValidationClass() : this._removeValidationClass();
          }
        }

        if (changes['record']) {
          this._setDisabledAttribute();
        }

        if (changes['record'] || changes['value']) {
          this._setInputValueAttribute();
          this._setOrRemoveInputCheckedAttribute();
          // this._setTitleAttribute();
          this._previousValue = this._getFieldValue();
        }
      }
    }
  }

  public activate() {
    this.element?.nativeElement?.focus();
  }

  public onFocus(_event) {
    this._hasFocus = true;
    this.focussed.emit();
  }

  public onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  public onKeyDown($event: KeyboardEvent) {
    const useKey = this.reportModel?.onFieldKeyDown($event);
    if (!useKey) {
      $event.stopPropagation();

      if ($event.code === 'Enter') {
        this.onEnter($event);
      } else if ($event.code === 'Escape') {
        this.appModel.globalModel.cancelMode();
        return true;
      }
    }
    return useKey;
  }

  protected onEnter($event: KeyboardEvent) {
  }

  public onKeyPress($event: KeyboardEvent) {
    const useKey = this.reportModel?.onFieldKeyPress($event);
    if (!useKey) {
      $event.stopPropagation();
    }
    return useKey;
  }

  public onKeyUp(_event) {
    this.inputChangedTrackService.setInputDirty(this.element);
  }

  /** Handle the cases where the blur is triggered. */
  public onBlur(_$event: Event) {
    try {
      this._hasFocus = false;
      this.updateCurrentValue().catch(error => logError(error, 'Failed to update current value'));

      const value = this.element?.nativeElement.value;
      this.blurred.emit(value);
    } catch (error) {
      logError(error, `Input field blur handler ${this.field?.Identifier}`);
    }
  }

  public onChange($event: Event) {
    // this.change.emit($event);
  }

  public onInputChanged($event: Event) {
    $event.stopPropagation();
    this.checkInputValidity();
    this.inputChanged.emit({ $event, value: this.element?.nativeElement.value });
  }

  public onValueChanged(value: any) {
    this.valueChanged.emit(value);
  }

  /** Get the current value of the input */
  public getValue() {
    return this.getNativeElementInputValue();
  }

  public setValue(value: any) {
    this.value = value;
    this._setInputValueAttribute();
  }

  /**
   * Get the current record value for the field
   */
  protected _getFieldValue() {
    const value = this.value;

    if (value && typeof value === 'object' && Object.keys(value).length === 0) { return ''; }

    if (value !== undefined && value !== null) {
      return this._fieldValueFormatter(value);
    }

    return '';
  }

  _isValid(record: Record) {
    return this.recordValidationService.isFieldValid(this.field, record, this.listRow);
  }

  _removeValidationClass() {
    this.renderer.removeClass(this.element.nativeElement, VALIDATION_CLASS);
  }

  _addValidationClass() {
    this.renderer.addClass(this.element.nativeElement, VALIDATION_CLASS);
  }

  private _setDisabledAttribute() {

    if (this.disabled !== undefined) {
      if (this.disabled) {
        this._setAttribute(ATTT_DISABLED, '');
      } else {
        this.renderer.removeAttribute(this.element.nativeElement, ATTT_DISABLED);
      }
    } else {
      const readonlyForm = this.form?.ReadOnly;
      const readonlyTemplate = this.template?.IsReadOnly;
      const isDisabled = this.disabled || readonlyForm || readonlyTemplate || this.field.isDisabled(this.record, this.ruleState);
      if (isDisabled) {
        this._setAttribute(ATTT_DISABLED, '');
      } else {
        this.renderer.removeAttribute(this.element.nativeElement, ATTT_DISABLED);
      }
    }
  }

  _setReadonlyAttribute() {
    this._setAttribute('readonly', '');
  }

  _setAttribute(attr: string, value: any) {
    this.renderer.setAttribute(this.element.nativeElement, attr, value);
  }

  /**
   * Set/Remove the HTML input checked attribute to the field value
   * if it's a bit field
   */
  _setOrRemoveInputCheckedAttribute() {
    if (this.getInputType() === ATTR_CHECKED) {
      if (this._getFieldValue() === true) {
        this._setAttribute(ATTR_CHECKED, '');
      } else {
        this.renderer.removeAttribute(this.element.nativeElement, ATTR_CHECKED);
      }
    }
  }

  /**
   * Set the HTML input value to the current field value.
   * This is set on change.
   */
  _setInputValueAttribute() {
    if (this.getInputType() === ATTR_VALUE) {
      const newValue = this._getFieldValue();
      //      this.renderer.setValue(this.el.nativeElement, this._getFieldValue().toString());
      /** TODO: Needed for tests to pass, refactor those tests to use nativeElement.value
       * Or should we set?
       */

      const editable = this.field.editableValue(newValue);
      this.element.nativeElement.value = editable;
    }
  }

  _setAttributeFromFieldValue(attr: string, value: string | number) {
    /**
     * Autolayout for a text area used zero, dont set the row height if its in auto layout mode.
     */
    if (value) {
      this._setAttribute(attr, value.toString());
    }
  }

  /**
   * Read the value in the input and update if it has changed.
   * Normally this is called when it is time to update the value
   * e.g. on blur, but it can also be called explicitly to make
   * sure the latest value is available.
   */
  public async updateCurrentValue(): Promise<any> {

    // Ignore when field has validity errors
    if (this.hasFieldValidationErrors) {
      return;
    }

    const newValue = this.getNativeElementInputValue();
    if (this._previousValue !== newValue) {
      this._previousValue = newValue;

      // Validate field
      const recordToValidate = this.appModel.app.value.cloneRecord(this.record);
      this.field.storeToRecord(recordToValidate, newValue, this.listRow);
      const isValid = this._isValid(recordToValidate);

      const value = this.field.adaptValue(newValue, this._previousValue, this.record, 'input');

      if (isValid) {
        this._removeValidationClass();
      } else {
        this._addValidationClass();
      }

      if (this.record) {

        const patch = this.field.createPatch(this.record, value, this.listRow);
        await this.appModel.patchRecordValue(patch);
      }

      this.onValueChanged(value);
    }

    return newValue;
  }

  protected getNativeElementInputValue() {
    switch (this.getInputType()) {
      case ATTR_VALUE: {
        const value = this.element.nativeElement.value;
        return this._parseValue(value);
      }

      default:
        /** Leave to throw so we catch missing inputs */
        throw new Error('type not set');
    }
  }

  private _parseValue(value: any) {
    switch (this.field.Type) {
      case Enums.FieldType.Long:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Range:
      case Enums.FieldType.Number:
      case Enums.FieldType.Money: {
        if (value === '') {
          return 0; // pass through blank so we can clear records
        } else {
          const num = Number.parseFloat(value);
          return Number.isNaN(num) ? undefined : num;
        }
      }

      default:
        return value;
    }
  }

  protected getInputType(): 'value' | 'checked' {
    switch (this.field.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
      case Enums.FieldType.Literal:
      case Enums.FieldType.UrlField:
      case Enums.FieldType.Email:
      case Enums.FieldType.Long:
      case Enums.FieldType.Money:
      case Enums.FieldType.Number:
      case Enums.FieldType.Integer:
      case Enums.FieldType.Date:
      case Enums.FieldType.Period:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Time:
      case Enums.FieldType.Range:
      case Enums.FieldType.Selection:
      case Enums.FieldType.ConcealedText:
      case Enums.FieldType.Barcode:
      case Enums.FieldType.AttachmentsCount:
      case Enums.FieldType.CommentsCount:
        return ATTR_VALUE;
      case Enums.FieldType.Bit:
        return ATTR_CHECKED;

      default:
        /** Leave to throw so we catch missing inputs */
        throw new Error('type not set');
    }
  }

  private _getTextTypeString() {
    if (this.field.TextFieldOptions && this.field.TextFieldOptions.PasswordField) {
      return 'password';
    } else {
      return 'text';
    }
  }

  private _getTypeString() {

    if (this.inputType) {
      return this.inputType;
    }

    switch (this.field.Type) {
      case Enums.FieldType.Text:
      case Enums.FieldType.LongText:
        return this._getTextTypeString();
      case Enums.FieldType.Literal:
      case Enums.FieldType.Selection:
      case Enums.FieldType.Time:
      case Enums.FieldType.ConcealedText:
      case Enums.FieldType.Barcode:
        return 'text';
      case Enums.FieldType.UrlField:
        return 'url';
      case Enums.FieldType.Email:
        return 'email';
      case Enums.FieldType.Long:
      case Enums.FieldType.Money:
      case Enums.FieldType.Number:
      case Enums.FieldType.Integer:
      case Enums.FieldType.CommentsCount:
      case Enums.FieldType.AttachmentsCount:
        return 'number';
      case Enums.FieldType.Date:
        return 'text'; // currently using unformatted input with date picker
      case Enums.FieldType.Period:
        return 'month';
      case Enums.FieldType.DateTime:
        return 'datetime-local';
      case Enums.FieldType.Bit:
        return 'checkbox';
      case Enums.FieldType.Range:
        return 'range';

      default:
        console.warn('type not set', this.field);
        return null;
    }
  }

  private _unsupported(): boolean {
    try {
      return this.getInputType() === null;
    } catch {
      return true;
    }
  }


  // todo formatting should move to AppField?

  /**
   * Format fields to have the correct value
   * Dates can be in multiple formats
   * Selection lists should get the text from the select list options
   *
   * @param value
   */
  protected _fieldValueFormatter(value: string | boolean | number | object | EpochDate) {

    switch (this.field.Type) {
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
        return this._dateTypeValueFormatter(value as EpochDate);
      case Enums.FieldType.Time:
        return this.formatTime(value as number | string);
      case Enums.FieldType.Person:
      case Enums.FieldType.PersonByTeam:
        if (value !== '') {
          const user = this.usersService.getMapped(value as string);
          return user && user.Text;
        }
        return value;
      case Enums.FieldType.Image:
      case Enums.FieldType.ImageList:
      case Enums.FieldType.InAppChart:
      case Enums.FieldType.Gantt:
      case Enums.FieldType.EmbeddedVideo:
      case Enums.FieldType.ListField:
      case Enums.FieldType.GridField:
      case Enums.FieldType.Lookup:
        return 'Not Supported';
      case Enums.FieldType.Selection:
        return this._formatSelection(value as object);
      default:
        return value;
    }
  }

  protected _dateTypeValueFormatter(val: EpochDate) {

    let when: moment.Moment;
    if (val === undefined || val === null) {
      when = null;
    } else if (val && val['$date']) {
      when = moment.utc(val['$date']);
    } else if (val && (<any>val)._isAMomentObject) {
      when = <any>val;
    } else if (typeof (val) === 'string') {
      // Despite the best efforts to standardise, some cases e.g. matrix reports
      // supply a string format so attempt to convert if given a string
      when = moment.utc(val);
    } else {
      when = moment.utc(<any>val);
    }

    if (when && when.isValid()) {
      return moment
        .utc({ year: when.year(), month: when.month(), date: when.date(), h: when.hour(), m: when.minutes() })
        .format(this._getDateFormatString());
      // this.dateString = this.dateTime.format(valueFormat);
    } else {
      return '';
      /// this.dateString = null;
    }
  }

  protected _getDateFormatString() {
    switch (this.field.Type) {
      case Enums.FieldType.Date:
        return moment.localeData().longDateFormat('L');
      case Enums.FieldType.DateTime:
        return moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT');
      case Enums.FieldType.Period:
        return 'YYYY-MM';
    }

    throw new Error('Incorrect type');
  }

  protected formatTime(time: number | string) {
    if (time === null || time === undefined) { return null; }
    if (typeof time === 'string' && !Number.isSafeInteger(+time)) {
      // return unmodified for invalid values
      return time;
    } else {
      // Use a temp moment set to requested time today to format time
      const dateTime = moment.utc().hours(0).minutes(0).seconds(0).milliseconds(0).seconds(+time);
      const use24hourClock = !this.field.TimeOptions?.use12hourClock;
      return dateTime.format(use24hourClock ? 'HH:mm' : 'hh:mm A');
    }
  }

  private _formatSelection(value: object): string {

    let options: Array<SelectListOption>;

    // let selectList: SelectList;

    if (this.field.UseRecordSelectListOptions) {
      console.warn('todo: UseRecordSelectListOptions ');
    } else if (this.record) {
      const app = this.appService.application(this.record.AppIdentifier);
      options = app && app.selectionListOptions(this.field.Identifier);
    }

    if (!options) {
      const selectList = this.selectListService.get(this.field.SelectListIdentifier);
      options = selectList && selectList.SelectListOptions;
    }

    if (options) {
      switch (this.field.SelectListType) {
        case Enums.SelectListType.Select:
        case Enums.SelectListType.Radio: {
          // single selection, value is option value string
          const val = value.toString();
          const option = options.find(opt => opt.Value === val);
          return option ? option.Text : val;
        }
        case Enums.SelectListType.Listbox:
        case Enums.SelectListType.Checkbox: {
          // multi selection, value is array of option values
          const values = value as Array<{ Value: string }>;
          const selectedOptions = values.map(v => options.find(opt => opt.Value === v.Value));
          return selectedOptions.map(v => v.Value).join(', ');
        }
      }
    }

    return value.toString();
  }

  /** Check for validity based on element flags */
  private checkInputValidity() {
    this.element.nativeElement.checkValidity();

    const validity = this.element.nativeElement.validity;
    if (validity) {
      if (!validity.valid) {
        const errors = this.field.getValidityStateErrors(validity, this.listRow);
        if (errors.length > 0) {
          this.hasFieldValidationErrors = true;
          this.onInputValidation.emit(errors);
          return;
        }
      }
    }

    this.hasFieldValidationErrors = false;
    this.onInputValidation.emit(null);
  }
}
