import { Injectable } from '@angular/core';
import { logError, SiteSettings, SiteSettingsStorageService, tryGetCurrentUser } from '@softools/softools-core';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private site: SiteSettings;

  constructor(private siteStoreage: SiteSettingsStorageService) {}

  public async refresh() {
    try {
      const user = tryGetCurrentUser();
      if (!this.site && user) {
        this.site = await this.siteStoreage.getSettingsAsync();
      }
    } catch (error) {
      logError(error, 'Error refreshing site properties service.');
    }
  }

  public get Site() {
    return this.site;
  }
}
