
export enum AccessType {
  Unknown = 0,
  Allow = 1,
  ReadOnly = 2,
  Deny = 3
}

export enum SourceType {
  Unknown = 0,
  Parent = 1,
  Record = 2,
  App = 3
}

export enum ActorType {
  Unknown = 0,
  Team = 1,
  User = 2,
  Owner = 3,
  All = 4
}

export const mappedAccessTypes = [
  { Text: 'Full', Value: AccessType.Allow },
  { Text: 'None', Value: AccessType.Deny },
  { Text: 'ReadOnly', Value: AccessType.ReadOnly }
];

export const mappedSourceTypes = [
  { Text: 'App', Value: SourceType.App },
  { Text: 'Parent', Value: SourceType.Parent },
  { Text: 'Record', Value: SourceType.Record },
  { Text: 'Unknown', Value: SourceType.Unknown }
];

export const mappedActorTypes = [
  { Text: 'All', Value: ActorType.All },
  { Text: 'Team', Value: ActorType.Team },
  { Text: 'User', Value: ActorType.User }
];
