
import { Record } from './record.interface';

export interface LookupData {
  record: Record;
}

export const initialLookupData: LookupData = {
  record: {} as Record
};
