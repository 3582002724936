import { Injectable } from '@angular/core';
import { RepositoryBase } from '../repos/repositories.base';
import { Comment } from '../types/interfaces/comment.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentsRepository extends RepositoryBase {

  public getComments(appIdentifier: string, recordId: string): Observable<Array<Comment>> {

    const url = this.urlResolver.resolveAppDataCommentsUrl(appIdentifier, recordId);
    return this.get(url) as Observable<Array<Comment>>;
  }

  public createComment(appIdentifier: string, recordId: string, comment: Comment): Observable<any> {

    const url = this.urlResolver.resolveAppDataCommentsUrl(appIdentifier, recordId);
    return this.postWithType(url, 'text', { Text: comment.Text });
  }

  public deleteComment(appIdentifier: string, recordId: string, id: string): Observable<any> {

    const baseUrl = this.urlResolver.resolveAppDataCommentsUrl(appIdentifier, recordId);
    const url = `${baseUrl}/${id}`;
    return this.del(url);
  }
}
