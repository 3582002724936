<section class="report-grid-container">

  <app-folders #leftAside
    class="left-aside-area"
    [folderModel]="pageModel.folderModel"
    [selectedFolderIdentifier]="reportModel.reportIdentifier.$|async"
    [parentAppIdentifier]="reportModel.parentAppIdentifier.$|async"
    [parentRecordIdentifier]="reportModel?.parentRecordId.$|async"
    [title]="currentApp?.Title"
    [appIdentifiers]="appIdentifiers"
    [showAddNewRecord]="canCreate"
    [appIdentifier]="appIdentifier"
    [pinnedStateVal]="showAside$ | async">
  </app-folders>

  <div class="report-controls-area">
    <app-ws-sharedreportactions
      [reportModel]="reportModel"
      [filterModel]="filterModel"
      [isLoading]="reportModel.busy.busy$|async"
      [filter]="filterModel.reportFilter.$|async"
      [baseFilter]="(reportModel.report.$|async)?.BaseFilter"
      [showFilterManagement]="this"
      [isArchived]="globalModel.archived.$|async"
      [showInlineEditSwitch]="tableReportController.showEditModeSwitch.$|async"
      [isEditMode]="false"
      [recordCount]="reportModel.totalCount.$|async"
      [showSelected]="true"
      [selectedCount]="reportModel.selectedCount.$|async"
      [allRecordsSelected]="reportModel.selectionModel.all.$|async"
      [isSearchable]="appSearchable"
      [isReportOption]="false"
      [searchText]="reportModel.searchTerm.$|async"
      [subtitle]="(reportModel.report.$|async)?.SubTitle"
      (onEditModeHandler)="setEditMode($event)"
      (onArchivedRecordsBadgeClickHandler)="onArchivedRecordsBadgeClickHandler()"
      (onDeselectAllHandler)="this.reportModel.selectionModel.deselectAll()"
      (onSearchEnterClickHandler)="onSearchEnterClickHandler($event)"
      (onSearchEscClickHandler)="onSearchEscClickHandler()">
    </app-ws-sharedreportactions>
  </div>

  <div *ngIf="tableReportModel.headerTemplate.$|async as ht" class="header-area">
    <app-template
      class="header-template"
      [appModel]="appModel"
      [appIdentifiers]="appIdentifiers"
      [application]="currentApp"
      [template]="ht"
      [hideHeader]="(this.reportModel.report.$|async)?.HeaderTemplate?.HideHeader"
    >
    </app-template>
  </div>

  <div class="main-content-area report-content h-100 w-100" style="overflow-x: hidden;">
    <article class="report-article h-100 w-100" style="position: relative; overflow-x: hidden;" cdkOverlayOrigin #articleOrigin="cdkOverlayOrigin">
      <app-table-report *ngIf="(isOfflineReport$|async) || (appModel.globalModel.online.$|async); else reportoffline"
        [appModel]="appModel"
        [commentsModel]="commentsModel"
        [attachmentsModel]="attachmentsModel"
        [reportModel]="tableReportModel"
        [controller]="tableReportController"
        [selectionMode]="tableSelectionMode()"
      ></app-table-report>

      <div class="new-rec-container">
        <app-new-record-button [style.z-index]="100" [canCreate]="canCreate" [createUrl]="createUrl" [floater]="false"></app-new-record-button>
      </div>
    </article>
  </div>

  <div [@quickFilters]="quickFilterOpenState$|async" class="quick-filters">
    <app-quick-filter *ngIf="isQuickFilterVisible$|async"
      [reportModel]="reportModel"
      [filterModel]="filterModel"
      [reportController]="reportController"
      (close)="quickFilterClosed()"
      (showSaved)="quickToFilterManagement()"
    ></app-quick-filter>
  </div>

  <div [@filterMgt]="filterManageOpenState$|async" class="saved-filters">
    <app-filter-management-panel *ngIf="isFilterManagementPanelVisible$|async"
      [appModel]="appModel"
      [filterModel]="filterModel"
      [showFilterManagement]="this"
      [isOnline]="appModel.globalModel.online.$|async"
      [selectedFilter]="filterModel.reportFilter.$|async"
      [appIdentifier]="(appModel.app.$|async)?.Identifier"
      [appFields]="(appModel.app.$|async)?.AppFields  "
      [report]="reportModel.report.$|async"
      [reportFields]="(reportModel.report.$|async)?.ListReportFields"
      [isEmbeddedApp]="!appModel.app.value?.IsEmbedded">
    </app-filter-management-panel>
  </div>

  <div class="footer-area" *ngIf="(isOfflineReport$|async) || (appModel.globalModel.online.$|async)">
    <app-shared-footer
    [appModel]="appModel"
    [attachmentsModel]="attachmentsModel"
    [commentsModel]="commentsModel"
    [appIdentifier]="appIdentifier"
    [showAside]="showAside$|async"
    [isOnline]="globalModel.online.$|async"
    [showFooter]="true"
    [attachmentsOrigin]="articleOrigin"
    >
      <app-table-report-paginator *ngIf="!isSingletonApp && (this.tableReportModel.recordsCount.$ | async) > 0"
        [reportModel]="tableReportModel"
      >
      </app-table-report-paginator>
    </app-shared-footer>
  </div>

  <ng-template #reportoffline>
    <app-report-offline></app-report-offline>
  </ng-template>
