<div *ngIf="showStandardFooter()"
  class="d-flex w-100 justify-content-between text-muted small"
  [class.validation-error-text]="invalid$|async">
  <ng-container *ngIf="invalid$|async; else elseTemplate">
    <ng-container *ngIf="(firstError$|async) as error">
      <ng-container *ngIf="error.message; else codeMessages">
        <span>{{error.message}}</span>
      </ng-container>
      <ng-template #codeMessages>
        <span *ngIf="error.error === errcodes.Required" i18n>This field is required.</span>
        <span *ngIf="error.error === errcodes.BelowMinimum" i18n>The value is less than {{error.param}}.</span>
        <span *ngIf="error.error === errcodes.AboveMaximum" i18n>The value is greater than {{error.param}}.</span>
        <span *ngIf="error.error === errcodes.InvalidUrl" i18n>Please enter a valid supported URL.</span>
        <span *ngIf="error.error === errcodes.InvalidEmail" i18n>Please enter a valid Email address.</span>
        <span *ngIf="error.error === errcodes.InvalidInt" i18n>Please enter a valid integer value.</span>
        <span *ngIf="error.error === errcodes.InvalidLong" i18n>Please enter a valid long integer value.</span>
        <span *ngIf="error.error === errcodes.InvalidFloat" i18n>Please enter a valid numeric value.</span>
        <span *ngIf="error.error === errcodes.NotAUser" i18n>A valid user must be selected.</span>
        <span *ngIf="error.error === errcodes.NotATeam" i18n>A valid team must be selected.</span>
        <span *ngIf="error.error === errcodes.TooLong" i18n>Must be less than {{error.param}} characters.</span>
        <span *ngIf="error.error === errcodes.TooShort" i18n>Must be at least {{error.param}} characters.</span>
        <span *ngIf="error.error === errcodes.RowsRequired" i18n>List must have at least one row.</span>
        <span *ngIf="error.error === errcodes.ValuesMustMatch" i18n>Value must match the {{error.param}} field.</span>
        <span *ngIf="error.error === errcodes.InvalidPasswordValue" i18n>Must be a valid password: {{error.param}}.</span>
        <span *ngIf="error.error === errcodes.InvalidUsernameValue" i18n>Must be a valid username: {{error.param}}.</span>
        <span *ngIf="error.error === errcodes.InvalidTime" i18n>Must be a valid time</span>
        <span *ngIf="error.error === errcodes.Other" i18n>The value is not valid.</span>
        <span *ngIf="error.error === errcodes.Pattern" i18n>Must match the regex pattern '{{error.param}}'.</span>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #elseTemplate>
    <ng-container *ngIf="field.informationText(record)">
      <span><fa-icon [icon]="['fal', 'info-circle']" class="me-2"></fa-icon>{{field.informationText(record)}}</span>
    </ng-container>
    <ng-container *ngIf="!field.informationText(record)">
      <span *ngIf="field.MinValue !== undefined && field.MaxValue === undefined" i18n>&nbsp;Min {{field.MinValue}}</span>
      <span *ngIf="field.MinValue === undefined && field.MaxValue !== undefined" i18n>&nbsp;Max {{field.MaxValue}}</span>
      <span *ngIf="field.MinValue !== undefined && field.MaxValue !== undefined" i18n>&nbsp;Min {{field.MinValue}}/Max
        {{field.MaxValue}}</span>
      <ng-container *ngIf="isTextualField(field)">
        <span *ngIf="field.Length && !field.MinLength" i18n>&nbsp;Max {{field.Length}} Chars</span>
        <span *ngIf="!field.Length && field.MinLength" i18n>&nbsp;Min {{field.MinLength}} Chars</span>
        <span *ngIf="field.Length && field.MinLength" i18n>&nbsp;{{field.MinLength}} to {{field.Length}} Chars</span>
      </ng-container>
    </ng-container>
    </ng-template>
</div>

<div *ngIf="!showStandardFooter()" class="w-100 small">&nbsp;</div>
