import { Injectable } from '@angular/core';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { DATA_METRICS_STORE } from '../indexedDb/database-stores';
import { AppIdentifier } from 'app/types/application';
import { StorageServiceBase } from './storage-service-base';

/**
 * Values describing app data
 */
export interface AppDataMetrics {

  count?: number;

  horizon?: string;
}

/**
 * Access to app data metrics storage
 */
@Injectable({
  providedIn: 'root'
})
export class AppDataMetricsStorageService extends StorageServiceBase<AppDataMetrics, AppIdentifier> {

  constructor(dbContext: DatabaseContextService<AppDataMetrics>) {
    super(DATA_METRICS_STORE, dbContext);
  }

  public async setCountAsync(appIdentifier: AppIdentifier, count: number): Promise<boolean> {
    const metrics = await this.get(appIdentifier) || {};
    metrics.count = count;
    return this.save(appIdentifier, metrics);
  }

  public async getCountAsync(appIdentifier: AppIdentifier): Promise<number> {
    const metrics = await this.get(appIdentifier);
    return metrics ? metrics.count : 0;
  }

}
