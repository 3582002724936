import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogFileUploaderComponent } from './dialog-file-uploader.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { SofDropZoneModule } from '@softools/design';
import { FileUploaderModule } from '../file-uploader/file-uploader.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NotificationMessageModule } from '../notification-message/notification-message.module';

@NgModule({
  declarations: [DialogFileUploaderComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    SofDropZoneModule,
    FileUploaderModule,
    MatProgressBarModule,
    NotificationMessageModule
  ],
  exports: [DialogFileUploaderComponent],
  providers: []
})
export class DialogFileUploaderModule { }
