import { Injectable } from '@angular/core';
import { LookupOptions } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { RecordSelectorComponent, SelectedRecords } from '../record-selector/record-selector.component';
import { ILookupDialog } from './lookup-dialog.interface';

@Injectable({
  providedIn: 'root'
})
export class LookupDialogService implements ILookupDialog {

  constructor(private models: GlobalModelService) { }

  /**
   * Lookup records using popup record selector dialog.
   * The response is an array of record (at most one if the multi select option is not set)
   * or null if the selector is cancelled.
   *
   * @param options
   */
  public async lookup(options: LookupOptions): Promise<SelectedRecords> {
    const size = { maxHeight: '90vh', width: '100vw', maxWidth: '1000px' };
    return await this.models.globalModel.dialogAsync(RecordSelectorComponent, options, size);

  }
}
