import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { logError } from '@softools/softools-core';
import { AppModel, ChartReportModel, FilterModel, GlobalModel, ListReportModel, ReportModel, TableReportModel } from 'app/mvc';
import { RecordsReportModel } from 'app/mvc/reports/records-report.model';
import { RecordPatch } from 'app/workspace.module/types';
import { ComponentBase } from '../component-base';
import { RecordClickEvent } from '../form.component/form-base.component';

/**
 * Container for displaying a report.
 * This displays any chart report type, including both sections of a dual report.
 * We may extend to contain non-chart reports.
 */
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportComponent extends ComponentBase implements OnInit, OnChanges {

  @Input() public reportModel: ReportModel;

  /** Same as reportModel but correctly typed */
  @Input() public chartReportModel: ChartReportModel;

  @Input() public dualReportModel: RecordsReportModel;

  @Input() public filterModel: FilterModel;

  // todo can we model these internally?  Do we want to
  @Input() public isDual = false;
  @Input() public dualReportStyle = 'none';
  @Input() public isOfflineReport: boolean;
  @Input() public editMode: boolean;

  public appModel: AppModel;
  public globalModel: GlobalModel;

  public dualListReportModel: ListReportModel;
  public dualTableReportModel: TableReportModel;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.appModel = this.reportModel.appModel;
    this.globalModel = this.appModel.globalModel;

    this.subscribe(this.reportModel.report.$, (report) => {
      if (report) {
        // todo track dual
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['dualReportModel']) {
      if (this.dualReportModel instanceof ListReportModel) {
        this.dualListReportModel = this.dualReportModel as ListReportModel;
      } else {
        this.dualListReportModel = null;
      }

      if (this.dualReportModel instanceof TableReportModel) {
        this.dualTableReportModel = this.dualReportModel as TableReportModel;
      } else {
        this.dualTableReportModel = null;
      }
    }
  }

  public onRecordClickHandler(event: RecordClickEvent) {
    this.reportModel.navigateToRecord(event.record, event.row);
  }

  public onFieldUpdateHandler(patch: RecordPatch) {
    this.appModel.patchRecordValue(patch).catch(e => logError(e, 'onChange'));
  }

  public onGoogleChartClickthroughHandler(payload: string) {
    this.globalModel.navigation.navigateUrlAsync({ url: payload }).catch(error => logError(error, 'Failed to navigate'));
  }

}
