<div class="container-fluid text-center ps-0 pe-0 h-100 w-100">

  <app-pagination class="w-100 h-100"
    [skip]="reportModel?.topIndex.$|async"
    [top]="reportModel?.pageSize.$|async"
    [count]="reportModel?.recordsCount.$|async"
    [isBusy]="reportModel?.accessor?.countBusy$|async"
    [singleRecord]="false"
    [fixedPageSize]="true"
    (firstRecordClicked)="onFirstClicked()"
    (lasttRecordClicked)="onLastClicked()"
    (prevClicked)="onPrevClicked()"
    (nextClicked)="onNextClicked()"
  >
  </app-pagination>
</div>
