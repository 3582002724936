
import { Record } from './record.interface';

export interface HistoryData {
  record: Record;
  activities: Array<Activity>;
}

export const initialHistoryData: HistoryData = {
  record: {} as Record,
  activities: [] as Array<Activity>
};

export interface Activity {
  Title: string;
  Published: string;
  Changes: Array<Change>;
}

export interface Change {
  Summary: string;
}
