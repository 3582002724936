import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { OverlayConfig } from '@angular/cdk/overlay';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';


import { OverlayImgComponent } from 'app/softoolsui.module/overlay-img.component/overlay-img.component';
import { OverlaySpinnerComponent } from 'app/softoolsui.module/overlay-spinner.component/overlay-spinner.component';
import { OverlayVideoComponent } from 'app/softoolsui.module/overlay-video.component/overlay-video.component';
import { OVERLAY_IMAGE_DATA, OVERLAY_VIDEO_DATA, OVERLAY_TEXT_DATA } from 'app/_constants/constants.injection-tokens';
import { OverlayTextComponent } from 'app/softoolsui.module/overlay-text.component/overlay-text.component';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { OverlaySoftoolsConfig } from '@softools/softools-core';

const DEFAULT_OVERLAY_CONFIG: OverlaySoftoolsConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-dark-backdrop',
};

@Injectable()
export class OverlayService {

  private _overlayRef: OverlayRef;
  private _isOpen = false;
  private _backdropClickSubscription: Subscription;

  constructor(private _overlay: Overlay, private _injector: Injector) { }

  public openImg(config: OverlaySoftoolsConfig = {}): OverlayImgComponent {
    this._isOpen = true;
    const overlayConfig = this._createOverlayWithConfig(config);
    const component = this.attachContainer(this._overlayRef, overlayConfig, OverlayImgComponent);
    this._backdropClickSubscription = this._overlayRef.backdropClick().pipe(first()).subscribe(() => {
      // Call optional closed handler if supplied
      if (config?.closed) {
        config.closed();
      }
    });

    return component;
  }

  public openText(config: OverlaySoftoolsConfig = {}): OverlayTextComponent {
    this._isOpen = true;
    const overlayConfig = this._createOverlayWithConfig(config);

    const component = this.attachContainer(this._overlayRef, overlayConfig, OverlayTextComponent);

    this._backdropClickSubscription = this._overlayRef.backdropClick().pipe(first()).subscribe(() => {
      // Call optional closed handler if supplied
      if (config?.closed) {
        config.closed();
      }
    });

    return component;
  }

  public openVideo(config: OverlaySoftoolsConfig = {}): void {
    this._isOpen = true;
    const overlayConfig = this._createOverlayWithConfig(config);
    this.attachContainer(this._overlayRef, overlayConfig, OverlayVideoComponent);
  }

  public openSpinner(): void {
    // Fix for: "Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'mode: undefined'. Current value: 'mode: indeterminate'. It seems like the view has been created after its parent and its children have been dirty checked"
    setTimeout(() => {
      if (!this._isOpen) {
        this._isOpen = true;
        this._overlayRef = this.createOverlay(DEFAULT_OVERLAY_CONFIG);
        this.attachContainer(this._overlayRef, DEFAULT_OVERLAY_CONFIG, OverlaySpinnerComponent);
      }
    }, 0);
  }

  public close(): void {
    // Fix for: "Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'mode: undefined'. Current value: 'mode: indeterminate'. It seems like the view has been created after its parent and its children have been dirty checked"
    setTimeout(() => {
      if (this._isOpen && this._overlayRef) {
        this._isOpen = false;
        if (this._backdropClickSubscription) {
          this._backdropClickSubscription.unsubscribe();
        }
        this._overlayRef.dispose();
      }
    });
  }

  private _createOverlayWithConfig(config: OverlaySoftoolsConfig): OverlaySoftoolsConfig {
    const overlayConfig = { ...DEFAULT_OVERLAY_CONFIG, ...config };
    this._overlayRef = this.createOverlay(overlayConfig);
    this._overlayRef.backdropClick().subscribe(_ => this.close());
    return overlayConfig;
  }

  private attachContainer<T extends OverlayImgComponent | OverlaySpinnerComponent | OverlayVideoComponent | OverlayTextComponent>
    (overlayRef: OverlayRef, config: OverlaySoftoolsConfig, component: ComponentType<T>): T {
    const injector = this.createInjector(config);
    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createOverlay(config: OverlaySoftoolsConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }

  private createInjector(config: OverlaySoftoolsConfig): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OVERLAY_IMAGE_DATA, config.image);
    injectionTokens.set(OVERLAY_VIDEO_DATA, config.video);
    injectionTokens.set(OVERLAY_TEXT_DATA, config.text);

    return new PortalInjector(this._injector, injectionTokens);
  }

  private getOverlayConfig(config: OverlaySoftoolsConfig): OverlayConfig {
    const positionStrategy = this._overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy,
      maxHeight: '100%',
      maxWidth: '100%'
    });

    return overlayConfig;
  }

}
