import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SiteStorageService, UploadImagesStorageService } from '@softools/softools-core';
import { AuthenticationService } from 'app/auth.module/services/authentication.service';
import { tryGetCurrentUser } from '@softools/softools-core';
import { environment } from 'environments/environment';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { Router } from '@angular/router';
import { NavigationService } from 'app/services/navigation.service';
import { logError } from '@softools/softools-core';
import { AppService } from 'app/services/app.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit, OnDestroy {

  hasPendingChanges = false;
  clearTimer;

  changesInApps: Map<string, string>;

  changedRecords: Map<string, Array<string>>;

  constructor(
    private authenticationService: AuthenticationService,
    private siteService: SiteStorageService,
    private recordQueueService: RecordQueueService,
    private uploadImagesStorageService: UploadImagesStorageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    public navigationService: NavigationService) { }

  ngOnInit() {
    this.logout().catch(error => logError(error, 'Failed to logout'));    // runs async
  }

  ngOnDestroy(): void {
    if (this.clearTimer) {
      clearTimeout(this.clearTimer);
    }
  }

  private async logout() {

    try {
      const user = tryGetCurrentUser();
      if (user) {
        const appsWithChanges = this.recordQueueService.getPatches().filter(patch => patch.hasPersistableChanges);
        const count = appsWithChanges.length;

        const imageCount = await this.uploadImagesStorageService.getCount();
        if (count === 0 && imageCount === 0) {
          this.hasPendingChanges = false;
          this.cdr.detectChanges();

          // Clear storage after getting the user.
          await this.authenticationService.logout();

          const site = await this.siteService.getOrCreateSiteLoginDataResourceAsync(user.Tenant);
          if (site) {
            let hostname = window.location.protocol + '//' + window.location.hostname;
            if (hostname.indexOf('localhost')) {
              hostname += `:${window.location.port}`;
            }
            const returnUrl = hostname;
            const auth0Domain = site.OverriddenAuth0Domain ? site.OverriddenAuth0Domain : environment.auth0Domain;
            const auth0LogoutUrl = `https://${auth0Domain}/v2/logout?client_id=${site.Auth0ClientId}&returnTo=${returnUrl}`;
            window.location.href = auth0LogoutUrl;
          }
        } else {
          const allApps = await this.appService.applicationsAsync();
          this.changedRecords = new Map<string, Array<string>>();
          this.changesInApps = new Map<string, string>();
          appsWithChanges.forEach(change => {
            if (!this.changedRecords.has(change.AppIdentifier)) {
              const appTitle = allApps.find(app => app.Identifier === change.AppIdentifier)?.Title;
              this.changesInApps.set(change.AppIdentifier, appTitle);
              this.changedRecords.set(change.AppIdentifier, new Array<string>());
            }
            this.changedRecords.get(change.AppIdentifier).push(change._id);
          });

          this.hasPendingChanges = true;
          this.cdr.detectChanges();

          this.clearTimer = setTimeout(() => {
            this.logout().catch(error => logError(error, 'Failed to logout'));
          }, 2000);
        }
      } else {
        this.router.navigate(['/']).catch(error => logError(error, 'Failed to navigate'));
      }
    } catch (error) {
      logError(error, 'logout');
    }
  }

  public clearPatches() {
    this.recordQueueService.clearPatches();
  }
}
