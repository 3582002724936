<mat-chip
  selectable="false"
  (click)="clicked($event)"
  [title]="title"
  class="bg-dark text-white"
  style="margin-left: 0.5rem; margin-right: 0.5rem; user-select: none;"
>
  <span class="text-truncate text-nowrap pointer badge-width">
    <fa-icon *ngIf="icon" [icon]="['fal', icon]" size="1x" style="margin-right: 1rem" class="ps-2" role="button"></fa-icon>
    <span>{{ text }}</span>
    <ng-content></ng-content>
  </span>
  <fa-icon
    *ngIf="!hideClose"
    [icon]="['fal', 'times-circle']"
    style="margin-left: 1rem"
    [title]="closeTitle || title"
    class="ps-2 pointer"
    (click)="closeClicked($event)"
  >
  </fa-icon>
</mat-chip>
