export enum AppZone {
  sync = 'sync',
  home = 'home',
  app = 'app',
  login = 'login',
  apphome = 'apphome',
  childapp = 'childapp',
  childapphome = 'childapphome',
  recordadd = 'recordadd',
  recordupdate = 'recordupdate',
  childrecordadd = 'childrecordadd',
  childrecordupdate = 'childrecordupdate',
  licensing = 'licensing',
  listreport = 'listreport',
  childlistreport = 'childlistreport',
  tablereport = 'tablereport',

  // following are not used consistently, app is often set
  // todo set and use correct zones
  chartreport = 'chart',
  matrixreport = 'matrix',
  dashboardreport = 'dashboard',
  childchartreport = 'childchartreport',
  childmatrixreport = 'childmatrix',
  childdashboardreport = 'childdashboard',

  // The app used for account welcome emails, used to route to Softools.UserInvitation app
  verify = 'verify',

  cardreport = 'card'
}

export enum StorageMode {
  /** All data synced, available when offline */
  Offline,
  /** Data synced selectively by report settings */
  Selective,
  /** No data synced, only available when connected  */
  Online,
}
