import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { IndexedAppData, logError, RecordId } from '@softools/softools-core';
import { ArrayModelProperty, BooleanModelProperty, Model, ModelProperty, NumberModelProperty } from '@softools/vertex';
import { RecordUpdateModel } from 'app/mvc';
import { combineLatest } from 'rxjs';
import { ComponentBase } from '../component-base';

class PaginatorModel extends Model<PaginatorModel> {

  public readonly busy = new BooleanModelProperty(this);

  public readonly valid = new BooleanModelProperty(this);

  public readonly cursor = new NumberModelProperty(this, 0).withLogging('Cursor');

  public readonly count = new NumberModelProperty(this, 0).withLogging('Count');

  public readonly pageStart = new NumberModelProperty(this, 0).withLogging('Page Start');

  public readonly index = new ModelProperty<IndexedAppData>(this, null).withLogging('Index');

  public readonly recordIds = new ArrayModelProperty<string>(this).withLogging('Record Ids');
}

/**
 * Implements a record paginator over an @see IndexedAdppData index.
 */
@Component({
  selector: 'app-index-paginator',
  templateUrl: './index-paginator.component.html',
  styleUrls: ['./index-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexPaginatorComponent extends ComponentBase implements OnInit {

  @Input() public recordModel: RecordUpdateModel;

  public model = new PaginatorModel();

  /** Size of index window to request */
  private window = 100;

  public ngOnInit(): void {

    if (this.recordModel) {
      const $ = combineLatest([
        this.recordModel.indexData.$,
        this.recordModel.recordId.$
      ]);

      this.subscribe($, ([data, id]) => {
        if (data && id) {
          this.configure(data.index, id);
        }
      });
    }
  }

  public firstClicked() {
    this.navigateToRecordNumber(0).catch(error => logError(error, 'IndexPaginator.first'));
  }

  public leftClicked() {
    this.navigateToRecordNumber(this.cursor - 1).catch(error => logError(error, 'IndexPaginator.left'));
  }

  public rightClicked() {
    this.navigateToRecordNumber(this.cursor + 1).catch(error => logError(error, 'IndexPaginator.right'));
  }

  public lastClicked() {
    this.navigateToRecordNumber(this.count - 1).catch(error => logError(error, 'IndexPaginator.last'));
  }

  public get cursor(): number {
    return this.model.cursor.value;
  }

  public get count(): number {
    return this.model.count.value;
  }

  private async navigateToRecordNumber(recNumber: number): Promise<void> {
    if (this.model.recordIds.value && recNumber >= 0 && recNumber < this.count) {

      let recordId: RecordId;
      const pageStart = this.model.pageStart.value;
      if (recNumber >= pageStart && recNumber < pageStart + this.model.recordIds.value.length) {
        recordId = this.model.recordIds.value[recNumber - pageStart];
      }

      if (!recordId) {
        // load from index
        const ids = await this.model.index.value.getRecordIds(recNumber, 1);
        if (ids.length === 1) { recordId = ids[0]; }
      }

      if (recordId) {
        await this.recordModel?.loadRecord(recordId);
        this.model.cursor.set(recNumber);
      }
    }
  }

  private async configure(index: IndexedAppData, id: RecordId) {
    try {
      this.model.busy.set();
      this.model.index.value = index;

      let cursor = this.findRecord(id);
      if (cursor < 0) {
        cursor = await index.positionOf(id);
        if (cursor !== undefined) {
          this.model.pageStart.value = Math.max(cursor - this.window / 2, 0);
          this.model.recordIds.value = await index.getRecordIds(this.model.pageStart.value, this.window);

          this.model.cursor.set(cursor);
          this.model.count.set(index.length);
          this.model.valid.value = !!index;
        } else {
          this.model.cursor.set(null);
          this.model.count.set(null);
          this.model.valid.reset();
        }
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        // No matches found. This can happen if the report filter selects nothing but
        // you've gone in via a URL
        this.model.cursor.set(null);
        this.model.count.set(null);
        this.model.valid.reset();
      } else {
        logError(error, 'get record pos');
      }
    } finally {
      this.model.busy.reset();
    }
  }

  private findRecord(recordId: RecordId) {
    if (recordId && this.model.recordIds.value) {
      const pos = this.model.recordIds.value.findIndex(id => recordId === id);
      if (pos < 0) {
        return pos;
      }

      return pos + this.model.pageStart.value;
    } else {
      return -1;
    }
  }
}
