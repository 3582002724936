import { IRecordSelection } from '@softools/softools-core';
import { MessageType } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { ChartDataAccssor } from './accessors';
import { ReportModel } from './report.model';

/**
 * Base model class for reports that show an overview of record data (charts, metrix etc.) as opposed to
 * reports that show distinct records.
 */
export abstract class OverviewReportModel extends ReportModel<ChartDataAccssor> {

  /** Override selection prompt to always return all records */
  public override async promptForSelection(messages?: { all: MessageType, some: MessageType, disallowAll?: boolean }): Promise<IRecordSelection> {
    return {
      AllRecordsSelected: true,
      SelectedRecordIds: []
    };
  }

}
