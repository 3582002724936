import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Style } from '../types/interfaces';
import { RepositoryBase } from './repositories.base';
import { getCurrentUser } from './repositories.global';

@Injectable({ providedIn: 'root' })
export class NamedStylesRepository extends RepositoryBase {

  public getAll(): Promise<Array<Style>> {
    const tenant = getCurrentUser().Tenant;
    const url = this.urlResolver.resolveApiUrl('styles', tenant);
    return lastValueFrom<Array<Style>>(this.get(url));
  }
}
