import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetImageComponent } from './asset-image.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [AssetImageComponent],
  exports: [AssetImageComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class AssetImagesModule {}
