import { Injectable } from '@angular/core';
import { DatabaseContextService, StorageServiceBase, WORKFLOW_RUNNER_QUEUE } from '@softools/softools-core';


export interface WorkflowRun {
  appIdentifier: string;
  fieldId: string;
  workflowId: string;
  recordId?: string;
  running: boolean;
}

@Injectable({ providedIn: 'root' })
export class WorkflowRunnerService extends StorageServiceBase<WorkflowRun, string> {

  constructor(dbContext: DatabaseContextService<WorkflowRun>) {
    super(WORKFLOW_RUNNER_QUEUE, dbContext);
  }

  public async queue(run: WorkflowRun) {
    await this.save(run.workflowId, run);
  }

  public async update(run: WorkflowRun) {
    await this.save(run.workflowId, run);
  }

  public async count() {
    const keys = await this.keys;
    return keys.length;
  }
}
