
export interface SecurityOptions {
  teams: Array<{ text: string, value: string }>;
  users: Array<{ text: string, value: string }>;
}

export const initialSecurityOptions: SecurityOptions = {
  teams: [] as Array<{ text: string, value: string }>,
  users: [] as Array<{ text: string, value: string }>
};
