<div class="sof-modal-container">
  <div class="pointer cardv2-popup sof-small-modal" (click)="dialogRef.close()">
    <div class="cardv2-body">
      <div class="d-flex align-items-center mb-4">
        <app-icon [iconBase64]="data.IconBase64" size="5rem"></app-icon>
        <h3 class="ps-4">
          {{ data.Title }}
        </h3>
      </div>
      <p>
        {{ data.Description }}
      </p>
      <h4>
        <label i18n><b>Category</b></label>
      </h4>
      <p>
        <label>{{ data.Taxonomy }}</label>
      </p>
      <ng-container  *ngIf="data.Publisher">
        <h4>
          <label i18n><b>Publisher</b></label>
        </h4>
        <p>
          <label>{{ data.Publisher.Name }}</label>
        </p>
      </ng-container>
      <div>
        <div *ngIf="data.storageMode === storageModes.Offline">
          <h4 i18n>Offline storage mode</h4>
          <div i18n>This app stores a local copy of its data and can be used with or without an Internet connection.</div>
        </div>
        <div *ngIf="data.storageMode === storageModes.Online">
          <h4 i18n>Online storage mode</h4>
          <div i18n>This app requires an Internet connection to access data.</div>
        </div>
        <div *ngIf="data.storageMode === storageModes.Selective">
          <h4 i18n>Selective storage mode</h4>
          <div i18n>
            This app stores a local copy of its data for selected reports. The report base filters determine which data is kept locally.
            These reports and records and can be used with or without an Internet connection; others require an Internet connection.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
