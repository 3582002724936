import { AppDataStorageService, logError, logMessage, PermissionEnums, Record, RecordAccessedStorageService, RecordId } from '@softools/softools-core';
import { BooleanModelProperty, Model, ModelProperty, ProjectionModelPoperty } from '@softools/vertex';
import { ChangeDetectionService } from 'app/services/change-detection.service';
import { InjectService } from 'app/services/locator.service';
import { AppIdentifier, GetRecordOptions } from 'app/types/application';
import { RecordValidationService } from 'app/workspace.module/services/validation.service';
import { RecordPatch } from 'app/workspace.module/types';
import { AppModel } from '../app.model';
import { GlobalModel } from '../global.model';
import { BusyModel } from '../reports/busy.model';
import { PageModel } from '../page/page.model';
import { PermissionsService } from 'app/services/permissions.service';
import { HierarchyModelProperty } from '../common/hierarchy-model-property';

/**
 * Record model
 */
export class RecordModel extends Model<RecordModel> {

  public readonly globalModel: GlobalModel;

  public readonly busy = new BusyModel(this);

  /** The record being viewed or edited */
  public readonly record = new ModelProperty<Record>(this).withLogging('RECORD');

  /** The id of the record being viewed or edited
   * This only fires when the current record changes, so it can be
   * observed  instead of record to configure for the record
   */
  public readonly recordId = new ModelProperty<RecordId>(this);

  /** Indicates whether the user has permission to edit the current record,
   * taking edit permissions and record security into account.
   * Default to true so banner not shown until we have a valid value.
   */
  public readonly editable = new BooleanModelProperty(this, true).withLogging('editable');

  public readonly hierarchy = new HierarchyModelProperty(this).withLogging('HIERARCHY');

  // Projections
  public readonly isArchived = new ProjectionModelPoperty<Record, boolean>(this.record, rec => rec?.IsArchived);

  public readonly isNewRecord = new ProjectionModelPoperty<Record, boolean>(this.record,
    rec => {
      // Pass undefined if no record so it can be ignored, but ensure boolean value if record specified
      if (rec) {
        return rec._new || false;
      } else {
        return undefined;
      }
    }
  );

  @InjectService(AppDataStorageService)
  protected readonly appDataService: AppDataStorageService;

  @InjectService(RecordValidationService)
  protected readonly recordValidationService: RecordValidationService;

  @InjectService(ChangeDetectionService)
  private readonly changeDetectionService: ChangeDetectionService;

  @InjectService(RecordAccessedStorageService)
  private recordAccessedStorageService: RecordAccessedStorageService;

  @InjectService(PermissionsService)
  private readonly permissionsService: PermissionsService;

  public constructor(public appModel: AppModel, public pageModel: PageModel, container?: Model<any>) {
    super(container);
    this.globalModel = appModel.globalModel;
  }

  /** Initialise the model.  Call after construction */
  public initialise() {
    this.observeProperties();
  }

  public async recordAccessed(record: Record) {
    await this.recordAccessedStorageService.appendRecordAsync(record);
  }

  /** Load a new record into the model by id */
  public async loadRecord(id: string) {
    const app = this.appModel.app.value;

    const options: GetRecordOptions = { applyPatch: true, fallbackRemote: true };
    if (this.globalModel.archived.value) {
      options.forceRemote = true;
    }

    if (app.IsSingletonApp) {
      // Get singleton id; may be null for some settings apps but they should cope
      id = app.StaticRecordId;
      this.record.value = await this.appModel.app.value.getRecordByIdAsync(id, options);
    } else if (id) {
      const record = await this.appModel.app.value.getRecordByIdAsync(id, options);
      this.record.value = record;
      if (record) {
        await this.recordAccessed(record);
      }
    } else {
      this.record.value = null;
    }
  }

  /** Call when a record has been changed */
  public recordModified(record: Record) {
    if (record) {
      if (record._id === this.record.value?._id) {
        // clone so @inputs see as a change
        this.record.value = { ...record };
      }
    }

    this.changed();
  }

  /**
   * Configure dependent properties by observing changes updating related props.
   */
  protected observeProperties() {

    this.subscribe(this.appModel.recordPatched$, (patch: RecordPatch) => {
      this.recordPatched(patch);
    });

    this.subscribe(this.appModel.updatedRecord$, (records: Array<Record>) => {
      records.forEach((record) => {
        this.recordModified(record);
      });
    });

    this.subscribe(this.appModel.createdRecord$, (record) => {
      if (this.recordId.value === record._id) {
        this.globalModel.showSuccessToasty({
          title: '',
          message: $localize`Record Created`
        });
      }

      this.recordCreated(record);
    });

    this.subscribe(this.changeDetectionService.recordChanged$, (records: Record[]) => {
      records.forEach((record) => {
        this.recordModified(record);
      });
    });

    this.subscribe(this.appModel.recordPatching$, (patching: { patch: RecordPatch, processing: boolean }) => {
      if (patching?.patch._id === this.recordId.value && patching?.patch._new) {
        this.busy.setLoading(patching.processing);
      }
    });

    // Update record id when record changes; this will only notify if the
    // id itself changes, not just record content so can watch to know when
    // a different record is selected
    this.subscribe(this.record.$, (rec) => this.recordId.value = rec?._id);

    this.subscribe(this.record.$, (record) => {
      if (record) {
        this.selectRecord(record);
      }
    });

    this.subscribe(this.appDataService.updatedRecord$, async (records) => {
      try {
        if (records?.length > 0) {
          await this.recordsChanged(records);
        }
      } catch (error) {
        logError(error, 'recmodel record change notify');
      }
    });

    this.subscribe(this.appModel.siteModel.onRecordUpdated.$, async (record) => {
      await this.recordsChanged([record]);
    });
  }

  public recordPatched(patch: RecordPatch) {
    const app = this.appModel.app.value;
    // const dataRecord = this.dataRecord.value;
    const dataRecord = this.record.value;
    if (dataRecord && patch._id === dataRecord._id) {
      // SOF-7174 check that the patch app matches the record app (unlikely in this case)
      if (dataRecord.AppIdentifier === app.Identifier) {
        const copy = patch.updateRecord(dataRecord, app, true);
        this.recordModified(copy);

        // Update validation state
        const fields = app.AppFields;
        this.recordValidationService.validateRecord(copy, fields);
      } else {
        logMessage(`RecModel: Mistmatched app id rec '${dataRecord.AppIdentifier}' patch '${patch.AppIdentifier}'`);
      }
    }
  }

  protected recordCreated(record: Record) {
    // nop unless overriden
  }

  private async recordsChanged(records: Record[]) {
    const app = this.appModel.app.value;

    const changedApps = new Set<AppIdentifier>();
    records.forEach(record => changedApps.add(record.AppIdentifier));

    const affected = changedApps.has(app.Identifier) ||
      !!app.ChildAppsIdentifiers?.map(c => c.Identifier).find(id => changedApps.has(id));

    if (affected) {
      // Refresh counts
      await this.pageModel.folderModel.reloadChildRecordCounts();
    }
  }

  public selectRecord(record: Record) {
    if (this.appModel.app.value.capabilities?.impliedPermissions?.
      find(p => p.Code === PermissionEnums.Records.Update || p.Code === PermissionEnums.Records.All)) {
      this.editable.value = true;
    } else {
      this.editable.value = record.EditableAccessForUser &&
        this.permissionsService.hasPermission(PermissionEnums.Records.Update, PermissionEnums.Records.All);
    }

    this.setHierarchyFromRecord(record);
  }

  /** Extract the hierrchy value from the current record if we are in the context of the parent app */
  private setHierarchyFromRecord(record: Record) {
    const parentAppIdentifier = this.appModel.appContext?.parentApp.value?.Identifier;
    if (parentAppIdentifier) {
      const recordHierarchy = record?.Hierarchy;
      if (recordHierarchy?.startsWith(parentAppIdentifier + '|')) {
        this.hierarchy.value = recordHierarchy;
      } else {
        this.hierarchy.value = '';
      }
    } else {
      this.hierarchy.value = '';
    }
  }

  public async reload() {
    if (this.recordId.value) {
      await this.loadRecord(this.recordId.value);
    }
  }
}
