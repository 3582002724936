<div class="outer w-100 h-100">
  <div #content class="report-container w-100 h-100"
  [vxWatch]="cardModel.totalCount" 
  (scroll)="scrolled($event)"
  >
  <ng-container *ngIf="cardModel.totalCount.value; else noRecs"  >
    <ng-container *ngIf="columns$|async as columns" >
      <div *ngIf="vertical"
        class="h-100"
        [style.width.px]="surfaceSize$|async"
        >
          <div *ngFor="let column of columns; trackBy: trackColumn"
            class="h-100"
            style="position: absolute;"
            [style.top.px]="0"
            [style.left.px]="column.position"
            [style.width.px]="column.ungrouped ? ungroupedColumnWidth :  groupSizePx"
          >
          <app-card-deck
            [column]="column"
            [cardModel]="cardModel"
            [generalController]="generalController"
            [groupColumnWidth]="groupSizePx"
            [orientation]="orientation$|async"
            [rowHeight]="rowHeight"
            [cardsPerColumn]="column.ungrouped ? (ungroupedCardsPerColumn$|async) : (groupedCardsPerColumn$|async)"
            [cardIndexes]="cardIndexes"
            [headerStyle]="groupHeaderStyles$|async"
            (cardTemplateChanged)="cardtemplateIndexChanged($event)"
          >
          </app-card-deck>
        </div>
      </div>
      <div *ngIf="!vertical"
        class="w-100"
        [style.height.px]="surfaceSize$|async"
        >
          <div *ngFor="let column of columns; trackBy: trackColumn"
            class="w-100"
            style="position: absolute;"
            [style.left.px]="0"
            [style.top.px]="column.position"
            [style.height.px]="groupSizePx"
          >
          <app-card-deck
            [column]="column"
            [cardModel]="cardModel"
            [generalController]="generalController"
            [groupColumnWidth]="cardLayoutExtent"
            [orientation]="orientation$|async"
            [rowHeight]="rowHeight "
            [cardsPerColumn]="column.ungrouped ? ungroupedCardsPerColumn : groupedCardsPerColumn"
            [cardIndexes]="cardIndexes"
            [headerStyle]="groupHeaderStyles$|async"
            (cardTemplateChanged)="cardtemplateIndexChanged($event)"
          >
          </app-card-deck>
          </div>
        </div>
      </ng-container>
  </ng-container>
  </div>
</div>

<ng-template #noRecs>
  <app-ws-sharednorecords
    [app]="appModel.app.$|async"
    [isLoading]="cardModel.busy.busy$|async"
    [isArchived]="appModel.globalModel.archived.$|async">
  </app-ws-sharednorecords>
</ng-template>
