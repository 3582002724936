<ng-container *ngIf="modalOptions" >

  <div class="modal-body modal-body-st" *ngIf="!modalOptions.MessageType  &&  modalOptions.TextContent">
    <div [innerHTML]="modalOptions.TextContent"></div>
  </div>

  <div class="modal-body modal-body-st" *ngIf="modalOptions.MessageType === messageTypes.MakeAvailableOffline">
    <p i18n>
      If you choose to make this report available offline, Softools will store all required record data on your device.
      The report's base filter controls the data that will be stored.  You should ensure that it selects a data set
      that can fit in your device's available storage.
    </p>
    <p i18n>
      When you change this setting, you should synchronise application data to ensure the correct data is stored.
    </p>
    <p i18n>
      Do you wish to make the report available offline?
    </p>
  </div>

  <div class="modal-body modal-body-st" *ngIf="modalOptions.MessageType === messageTypes.MakUnavailableOffline">
    <p i18n>
      Selecting this option will stop Softools from storing data required for offline operation on your device.
      You will only be able to view this report when your device is connected.
    </p>
    <p i18n>
      When you change this setting, you should synchronise application data to remove unneeded data from your device.
    </p>
    <p i18n>
      Do you wish to make the report only available while online?
    </p>
  </div>
 
</ng-container>