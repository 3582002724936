import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { logMessage } from '@softools/softools-core';

@Component({
  selector: 'app-actions-bottom-sheet',
  templateUrl: './actions-bottom-sheet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsBottomSheetComponent implements OnInit {
  public actions;
  public showVersion: boolean;
  public version: string;
  public disableWhenOffline = true;
  public isOnline = false;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.actions = this.data.acts;
    this.showVersion = this.data.ver;
    this.version = this.data.verNo;
    this.disableWhenOffline = this.data.disableOff;
    this.isOnline = this.data.isOnline;
  }

  public onActionClickHandler(toolbarAction: ToolbarAction) {
    if (toolbarAction) {
      toolbarAction.perform();
    } else {
      logMessage(`Invalid toolbar action ${toolbarAction?.text}`);
    }
  }

  public close() {
    this.data.close();
  }
}
