import { LRUCacheWithDelete } from 'mnemonist';
import { Injectable } from '@angular/core';
import { Record } from '@softools/softools-core';
import { RecordId } from 'app/core';
import { Application, GetRecordOptions } from 'app/types/application';
import { RecordQueueService } from './record-queue.service';
import { AppService } from '../app.service';
import { RecordPersistService } from './record-persist.service';
import { merge } from 'rxjs';
import { BackgroundDataSyncService } from '../background-data-sync.service';

@Injectable({ providedIn: 'root' })
export class RecordCacheService {

  private readonly size = 100000;

  private readonly cache = new LRUCacheWithDelete<RecordId, Record>(this.size);

  public constructor(
    private readonly recordQueueService: RecordQueueService,
    private readonly recordPersistService: RecordPersistService,
    private readonly backgroundDataSyncService: BackgroundDataSyncService,
    private readonly appService: AppService
  ) {

    // Update cached records when updated by persistence service or detected by background sync
    const changed$ = merge(
      this.recordPersistService.recordUpdated$,
      this.recordPersistService.recordCreated$,
      this.backgroundDataSyncService.recordUpdated$
    );

    changed$.subscribe((record) => {
      if (record && this.has(record._id)) {
        this.put(record);
      }
    })


    // Update cached records when they are patched
    this.recordQueueService.patchQueued$.subscribe((patch) => {
      if (patch && this.has(patch._id)) {
        const app = this.appService.application(patch.AppIdentifier);
        if (app) {
          const record = this.get(patch._id);
          patch.updateRecord(record, app);
          this.put(record);
        }
      }
    });
  }

  /**
   * Load a record, taking from the cache if present
   * 
   * @param app     Owning app
   * @param id      Record id
   * @param options Options to use if record must be loaded (from storage or API)
   * @returns       Record or null if not found
   */
  public async load(app: Application, id: RecordId, options?: GetRecordOptions): Promise<Record> {
    const cached = this.cache.get(id);
    if (cached) {
      return cached;
    } else {
      const record = await app.getRecordByIdAsync(id, options);
      if (record) {
        this.cache.set(id, record);
      }

      return record;
    }
  }

  /** Get a cached record */
  public get(id: RecordId) {
    return this.cache.get(id);
  }

  /** Store a record in the cache */
  public put(record: Record) {
    this.cache.set(record._id, record);
  }

  /** Remove a record from the cache */
  public delete(id: RecordId) {
    this.cache.delete(id);
  }

  public has(id: RecordId): boolean {
    return this.cache.has(id);
  }

}
