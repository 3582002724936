import { OdataExpression } from './odata-expression';
import { OdataExpressionType } from './odata-expression-type';
import { IRootQuery } from './root-query';

export class OdataPropertyExpression extends OdataExpression {

  name: string;

  constructor(onode: any, root: IRootQuery, public override parent: OdataExpression) {
    super(OdataExpressionType.Property, root, parent);
    this.name = onode.name as string;

    // Record a reference to the property so we can quickly check usage
    root.addReference(this);
  }

  /** text format of a property is its name */
  public override text(): string {
    return this.name && this.name.toString();
  }

  public override expressionText() {
    return `[${this.text()}]`;
  }

  public override lookupValue(values: {}, listRow?: number): any {
    const value = this.root.getValue(this.name, values, listRow);
    return this._normalizeValue(value);
  }
}
