import { ChartData, IChartData, logError, ReportDataRepository } from '@softools/softools-core';
import { Application } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { ReportFilter } from 'app/filters/types';
import { IGroupStarts } from 'app/services/indexes/app-data-index';
import { MatrixModel } from 'app/softoolsui.module/matrixreport.component/matrix-model';
import { InjectService } from 'app/services/locator.service';
import { ChartErrorException } from 'app/exceptions';
import { ChartEnums } from 'app/services/chart';
import { ChartDataAccssor } from './chart-data-accessor';
import { Causes } from 'app/mvc';
import { OverviewReportModel } from '../overview-report.model';
import { lastValueFrom } from 'rxjs';

export class OnlineChartDataAccssor extends ChartDataAccssor {

  @InjectService(ReportDataRepository)
  private readonly reportDataRepository: ReportDataRepository;

  constructor(app: Application, protected reportModel: OverviewReportModel) {
    super(reportModel.busy, app);
  }

  public async initialise(): Promise<void> {
    try {
      const selection = this.getSelection();
      this.updateCount(selection);    // completes async
    } catch (error) {
      logError(error, 'OnlineRecordsModel initialise');
      this.count$.next(null);
    } finally {
    }
  }

  public async getChartData(): Promise<ChartData> {
    try {
      this.busy.start(Causes.charting);

      if (this.app && this.reportModel.report.value) {

        let chartData: IChartData;

        try {
          const query = this.reportModel.queryParameters();
          delete query.$skip;
          delete query.$top;

          chartData = await lastValueFrom<IChartData>(this.reportDataRepository.getHighChartsData(
            this.app.Identifier,
            this.reportModel.report.value.Identifier,
            query,
            this.reportModel.hierarchy.value,
            this.reportModel.globalModel.archived.value));

        } catch (error) {
          // Gague charts throw errors if no results. Log but throw NoRecords.  See SOF-10471.
          logError(error, 'online getChartData');
        }

        // Check for any data returned
        const hasData = chartData?.Series.filter(series => series.data?.length > 0).length > 0;
        if (!hasData) {
          throw new ChartErrorException(ChartEnums.ChartErrors.NoRecords);
        }

        this.chartData$.next(chartData);

        return chartData;
      }

      return null;
    } finally {
      this.busy.finish(Causes.charting);
    }

  }

  public async loadMatrixData(matrixModel: MatrixModel) {

    const combined = this.reportModel.filterModel.combinedFilter.value;
    const search = this.reportModel.searchFilter.value;

    const mergedFilter = (search && combined) ? ReportFilter.merge([combined, search]) : (combined || search);

    const copy = new ReportFilter(mergedFilter);
    delete copy.Top;
    delete copy.Skip;

    const chartData = await this.reportDataRepository.getHighChartsData(
      this.app.Identifier,
      this.reportModel.report.value.Identifier,
      copy.QueryParameters || {},
      this.reportModel.hierarchy.value,
      this.reportModel.globalModel.archived.value).toPromise();

    if (chartData.MatrixTableData) {
      matrixModel.importTableData(chartData.MatrixTableData, false, false);
    }
  }

  protected getSelection(first?: number, count?: number, group?: IGroupStarts) {

    const filters: Array<ReportFilter> = [];

    const combined = this.reportModel.filterModel.combinedFilter.value || this.reportModel.filterModel.filter;
    if (combined) {
      filters.push(combined);
    }

    const search = this.reportModel.searchFilter.value;
    if (search) {
      filters.push(search);
    }

    if (group) {
      const groupFilter = new ReportFilter();
      const groupField = this.app.getField(group?.groupFieldId);
      groupFilter.setSimpleFilter(groupField, group.value);
      filters.push(groupFilter);
    }

    const selection = new RecordSelection();
    Object.assign(selection, {
      start: first || 0,
      count: count || 25,
      report: this.reportModel.report.value,
      reportIdentifier: this.reportModel.reportIdentifier,
      showArchived: false,
      hierarchy: this.reportModel.hierarchy.value
    });

    if (filters.length > 0) {
      selection.filter = ReportFilter.merge(filters);
    }

    return selection;
  }


  private updateCount(selection: RecordSelection) {
    this.app.getApiViewRecordCount(selection)
      .then(count => this.count$.next(count))
      .catch(err => logError(err, 'updateCount'));
  }

}

export class ArchivedChartDataAccessor extends OnlineChartDataAccssor {
  protected override getSelection(first?: number, count?: number) {
    const selection = super.getSelection(first, count);
    selection.showArchived = true;
    return selection;
  }
}
