
import { Component, ComponentFactoryResolver, ViewChild, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';

import { ModalOptions, Enums } from '@softools/softools-core';
import { ModalContentDirective } from '../../softoolsui.module/modal.component/modalcontent.directive';
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit {

  public Enums: typeof Enums = Enums;

  public modalOptions$ = new BehaviorSubject<ModalOptions>(null);

  private _modalContentDirectiveElRef: ModalContentDirective;

  @ViewChild(ModalContentDirective) set modalContentDirective(directiveElRef: ModalContentDirective) {
    this._modalContentDirectiveElRef = directiveElRef;

    if (this._modalContentDirectiveElRef && this._modalContentDirectiveElRef.viewContainerRef) {
      this._loadComponent();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalOptions,
    private dialogRef: MatDialogRef<ModalComponent>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.modalOptions$.next(this.dialogData);
  }

  ngOnInit(): void {
    this._loadComponent();
  }

  private _loadComponent() {

    this.modalOptions$.subscribe((modal) => {

      if (modal && this._modalContentDirectiveElRef && modal.Component) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(modal.Component);
        const viewContainerRef = this._modalContentDirectiveElRef.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(factory);
        (<any>componentRef.instance).init(modal.ComponentContentOptions, modal.ComponentContentData);
        componentRef.changeDetectorRef.detectChanges();
      }
    });
  }

  public async onModalConfirmClickHandler() {
    this.dialogRef.close(true);
  }

  public async onModalCloseClickHandler() {
    this.dialogRef.close(false);
  }
}
