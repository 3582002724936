import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterTermUpdates } from 'app/filters/filter-simple-popup/filter-simple-popup.component';
import { FilterEditorUi } from 'app/filters/types';
import { FilterModel } from 'app/mvc';
import { AppField } from 'app/types/fields/app-field';

@Component({
  selector: 'app-sort-popup',
  templateUrl: './sort-popup.component.html',
  styleUrls: ['./sort-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortPopupComponent implements OnInit {

  @Input() public filterModel: FilterModel;

  @Input() public filterEditorUi: FilterEditorUi;

  @Output() public termUpdated = new EventEmitter<FilterTermUpdates>();

  public orderAscending = 'asc';
  public orderDescending = 'desc';
  public orderNone = 'none';
  public selectedSort = this.orderNone;

  public field: AppField;

  constructor() { }

  public ngOnInit(): void {
    if (this.filterEditorUi?.editFilterSpec) {
      const term = this.filterEditorUi.editFilterTerm;
      if (term.FieldIdentifier === this.filterEditorUi.editFilterSpec.sortField) {
        if (this.filterEditorUi.editFilterSpec.sortField) {
          this.selectedSort = this.filterEditorUi.editFilterSpec.sortAscending ? this.orderAscending : this.orderDescending;
        } else {
          this.selectedSort = this.orderNone;
        }
      } else {
        this.selectedSort = this.orderNone;
      }
    }
  }

  public sortChanged($event: string) {
    const updates: FilterTermUpdates = {
      fieldId: this.filterEditorUi.fieldId,
      isSort: true
    };

    switch ($event) {
      case this.orderAscending:
        updates.sortAscending = true;
        break;
      case this.orderDescending:
        updates.sortAscending = false;
        break;
      default:
        updates.fieldId = '';
        updates.sortAscending = false;
        break;
    }

    this.termUpdated.emit(updates);
  }
}
