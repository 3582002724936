
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlaySpinnerComponent } from 'app/softoolsui.module/overlay-spinner.component/overlay-spinner.component';
import { SofSpinnerModule } from '@softools/design/icons';

@NgModule({
  declarations: [OverlaySpinnerComponent],
  imports: [CommonModule, SofSpinnerModule],
  exports: [OverlaySpinnerComponent],
  providers: [],
})
export class OverlaySpinnerModule {}
