import { Injectable } from '@angular/core';
import { Report, Enums, ChartField, IndexedAppData, Record } from '@softools/softools-core';
import { ChartEnums } from '../chart.enums';
import * as moment from 'moment';
import { BaseChartDataService } from './base-chart-data.service';
import { Field } from '@softools/softools-core';
import { extractDecimal, isDateField } from 'app/_constants';
import { Application } from 'app/types/application';

interface ChartData {
  column: any;
  rows: Array<Array<any>>;
}

@Injectable({
  providedIn: 'root'
})
export class CumulativeChartDataService extends BaseChartDataService {

  private noValueText = $localize`No Value Set`;

  public async getCumulativeChartDataOptions(app: Application, appData: IndexedAppData, reportChartConfig: Report, record?: Record) {    
    const columnField = reportChartConfig.Chart.ColumnFieldIdentifier ? app.getField(reportChartConfig.Chart.ColumnFieldIdentifier) : undefined;

    // If a column field id is specified it must be a valid field
    if (reportChartConfig.Chart.ColumnFieldIdentifier && !columnField) {
      throw new Error(`No field corresponding to ColumnFieldIdentifier ${reportChartConfig.Chart.ColumnFieldIdentifier}, for report ${reportChartConfig.Identifier}`);
    }

    const chartFields = (reportChartConfig.Chart.ChartFields)
      .sort((a, b) => a.Order - b.Order)
      .map(field => ({ ...field, BaseField: app.getField(field.BaseFieldIdentifier) }));
    // const cumulativeChartFields = chartFields.filter((field) => field.ShowOnChart).sort((a, b) => a.Order - b.Order);
    const chartData = await this.getCumulativeChartData(app, appData, reportChartConfig, chartFields, record);
    const series = new Array<any>();
    const dateColumnField = columnField ? isDateField(columnField.Type) : false;
    const colorKeyValueStyles = this.chartColourService.getColorKeyValueStylesAsync(app, reportChartConfig.NamedStyles);

    chartFields.forEach((field, index) => {
      const yIndex = reportChartConfig.Chart.SingleAxis ? 0 : chartFields.indexOf(field);
      if (dateColumnField) {
        series.push({});
        series[index] = {
          data: this.getDateSeriesDataFromDictionary(chartData, field, index),
          color: this.chartColourService.getColourHexcode(field.Label, colorKeyValueStyles)
        };
      } else {
        series.push({});
        series[index] = {
          data: this.getSeriesDataFromDictionary(chartData, field, index),
          color: this.chartColourService.getColourHexcode(field.Label, colorKeyValueStyles)
        };
      }

      series[index].name = `${field.Label || field.BaseField.Label} (${ChartEnums.Calculation[field.Calculation]})`;
      series[index].yAxis = yIndex;
      series[index].type = ChartEnums.SeriesType[field.SeriesType].toString().toLowerCase() !== 'stackedcolumn' ? ChartEnums.SeriesType[field.SeriesType].toString().toLowerCase() : 'column';
      series[index].tooltip = { shared: true, useHTML: true } as Highcharts.TooltipOptions;
    });

    let categories = this.getColumnTitles(chartData, columnField);
    if (categories === 'Column Field Not Set' && record) {
      categories = chartData.map(d => d.column);
    }

    series.forEach(seriesData => {
      if (seriesData) {
        // If line chart then each data point is an array of length 2, [x, y]
        // If the x in any data point is unset then the chart line won't appear.
        const dataSet = seriesData.data;
        if (Array.isArray(dataSet[0])) {
          if (dataSet[dataSet.length - 1][0] == null || isNaN(dataSet[dataSet.length - 1][0])) {
            dataSet.pop();
          }
        }
      }
    });

    const chartSeries = series.filter((_, i) => chartFields[i].ShowOnChart);
    if (!chartSeries?.length) {
      chartSeries.push({
        data: new Array<any>()
      } as any);
    }

    const matrix = this.buildCumulativeTable(app, reportChartConfig, chartData, chartFields);
    return { Series: chartSeries, Categories: categories, MatrixTableData: matrix };
  }

  private getColumnTitles(data: ChartData[], columnField: Field) {
    if (!columnField) {
      return $localize`Column Field Not Set`;
    }
    switch (columnField.Type) {
      case Enums.FieldType.Date:
      case Enums.FieldType.DateTime:
      case Enums.FieldType.Period:
        return data.map(d => {
          if (d.column == null) {
            return this.noValueText;
          }
          return d.column.$date.toString();
        });
      case Enums.FieldType.Money:
        return data.map(d => {
          if (d.column == null) {
            return this.noValueText;
          }
          return extractDecimal(d.column).toString();
        });
      default:
        return data.map(d => {
          if (d.column == null) {
            return this.noValueText;
          }
          return d.column.toString();
        });
    }
  }

  private buildCumulativeTable(app: Application, reportChartConfig: Report, chartData: Array<ChartData>, chartFields: ChartField[]) {
    const tabledata = {
      IsCumulative: true,
      Rows: new Array<any>(),
      Columns: chartData.map(o => {
        const value = o.column ? o.column : this.noValueText;
        let fieldType;
        if (!!o.column) {
          fieldType = reportChartConfig.Chart.ColumnFieldIdentifier ? app.Fields.find(field => field.Identifier === reportChartConfig.Chart.ColumnFieldIdentifier).Type : Enums.FieldType.Text;
        } else {
          fieldType = Enums.FieldType.Text;
        }
        return {
          Value: value,
          FieldType: fieldType
        };
      }),
      TargetClickThroughReportIdentifier: ''
    };

    chartFields.forEach((field, i) => {
      const seriesData = this.getSeriesDataFromDictionary(chartData, field, i);
      const cells = seriesData.map((o, j) => {
        return {
          ListOfValues: new Array<any>({ Value: o }),
          XAxisFieldValue: tabledata.Columns[j].Value,
          YAxisFieldValue: field.Calculation === ChartEnums.Calculation.Average || field.Calculation === ChartEnums.Calculation.Total ? null : field.BaseFieldIdentifier
        };
      });

      const tablerow = {
        Label: `${field.Label || field.BaseField.Label} (${ChartEnums.Calculation[field.Calculation]})`,
        LabelFieldType: field.BaseField.Type,
        Cells: cells
      };

      tabledata.Rows.push(tablerow);
    });

    if (reportChartConfig.TargetClickThroughReportId) {
      tabledata.TargetClickThroughReportIdentifier = app.Reports.find(report => report.Id === reportChartConfig.TargetClickThroughReportId).Identifier;
    }
    return tabledata;
  }

  private getDateSeriesDataFromDictionary(chartData: Array<ChartData>, field: ChartField, fieldIndex: number): any {
    const calculation = field.Calculation;
    const fieldFunction = field.Function;
    const decimalPlaces = field.ToolTipDecimals;
    const seriesPlotValues = new Array<Array<any>>();

    chartData.forEach((data, i) => {
      const date = moment.utc(data.column ? data.column.$date : null);

      let decValue = this.getDecimalValue(chartData[i].rows[fieldIndex], calculation);
      if (decimalPlaces) {
        decValue = Number.parseFloat(decValue.toFixed(decimalPlaces));
      }

      if (fieldFunction === ChartEnums.Function.Addition && seriesPlotValues.length > 0) {
        const prevPlotIndex = seriesPlotValues.length - 1;
        decValue += seriesPlotValues[prevPlotIndex][1];
      }

      const milliseconds = Number.parseInt(date.format('x'));
      seriesPlotValues.push(new Array<any>(milliseconds || Number.NaN, decValue));
    });

    return seriesPlotValues;
  }

  private getSeriesDataFromDictionary(chartData: Array<ChartData>, field: ChartField, index: number) {
    const calculation = field.Calculation;
    const fieldFunction = field.Function;
    const decimalPlaces = field.ToolTipDecimals;
    const seriesPlotValues = new Array<number>();

    chartData.forEach((_, i) => {
      let decValue = this.getDecimalValue(chartData[i].rows[index], calculation);
      if (decimalPlaces != null) {
        decValue = +(decValue.toFixed(decimalPlaces));
      }

      if (fieldFunction === ChartEnums.Function.Addition && seriesPlotValues.length > 0) {
        const prevPlotIndex = seriesPlotValues.length - 1;
        decValue += seriesPlotValues[prevPlotIndex];
      }

      seriesPlotValues.push(decValue);
    });

    return seriesPlotValues;
  }

  private getDecimalValue(values: Array<number>, calculation: number) {
    let decValue: number;
    switch (calculation) {
      case ChartEnums.Calculation.Average:
        decValue = values.reduce((aggregate, element) => aggregate + element) / values.length;
        break;
      case ChartEnums.Calculation.Minimum:
        decValue = Math.min(...values);
        break;
      case ChartEnums.Calculation.Maximum:
        decValue = Math.max(...values);
        break;
      case ChartEnums.Calculation.Total:
      default:
        decValue = values.reduce((aggregate, element) => aggregate + element);
        break;
    }

    return decValue;
  }

  private async getCumulativeChartData(app: Application, appData: IndexedAppData, reportChartConfig: Report,
    cumulativeChartFields: ChartField[], record?: Record) {

    const data: Array<ChartData> = new Array<ChartData>();
    let columnFieldType;
    const columnField = app.getField(reportChartConfig.Chart.ColumnFieldIdentifier);

    if (reportChartConfig.Chart.ColumnFieldIdentifier) {
      if (!columnField) {
        throw new Error(`No field corresponding to ColumnFieldIdentifier ${reportChartConfig.Chart.ColumnFieldIdentifier}, for report ${reportChartConfig.Identifier}`);
      }
      columnFieldType = columnField ? columnField.Type : undefined;
    }

    const recordCallbackFunction = (rec) => {
      let columnNameValue: any = this.noValueText;

      if (reportChartConfig.Chart.ColumnFieldIdentifier) {
        columnNameValue = columnField.getRawRecordValue(rec);
      } else if (rec) {
        columnNameValue = rec['GridColumnTitleField'];
      }

      if (!data.some(d => JSON.stringify(d.column) === JSON.stringify(columnNameValue))) {
        data.push({ column: columnNameValue, rows: new Array<Array<number>>(cumulativeChartFields.length) });
        cumulativeChartFields.forEach((_, i) => {
          if (data[data.length - 1].rows[i] === undefined) {
            data[data.length - 1].rows[i] = new Array<number>();
          }
        });
      }

      cumulativeChartFields.forEach((chartField, i) => {
        const field = app.getField(chartField.BaseFieldIdentifier);
        if (field) {
          const val = field.getRawRecordValue(rec) || 0;
          const fieldValueNumeric = parseFloat(val.toString());
          data[data.findIndex(d => JSON.stringify(d.column) === JSON.stringify(columnNameValue))].rows[i].push(!isNaN(fieldValueNumeric) ? fieldValueNumeric : 0);
        }
      });
    };

    if (record) {
      const records = await this.getRecordData(app, !!record, appData, reportChartConfig, record._id, record);
      await records.forEach(recordCallbackFunction);
    } else {
      await appData.eachRecord(recordCallbackFunction);
    }


    if (reportChartConfig.Chart.OrderColumnCategories) {
      const unsetDataIndex = data.findIndex(d => d.column == null);
      let unsetData: Array<any>;
      if (unsetDataIndex > -1) {
        unsetData = data.splice(unsetDataIndex, 1);
      }
      switch (columnFieldType) {
        case Enums.FieldType.Number:
        case Enums.FieldType.Range:
        case Enums.FieldType.Integer:
        case Enums.FieldType.Long:
          data.sort((a, b) => a.column - b.column);
          break;
        case Enums.FieldType.Money:
          data.sort((a, b) => extractDecimal(a.column) - extractDecimal(b.column));
          break;
        case Enums.FieldType.Date:
        case Enums.FieldType.DateTime:
        case Enums.FieldType.Period:
          data.sort((a, b) => a.column.$date - b.column.$date);
          break;
        default:
          data.sort((a, b) => {
            if (a.column.toString() > b.column.toString()) {
              return 1;
            } else if (a.column.toString() < b.column.toString()) {
              return -1;
            }
            return 0;
          });
      }
      if (unsetData && unsetData.length > 0) {
        data.push(unsetData[0]);
      }
    }

    return data;
  }

}
