import { Directive, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SofValueFormaterDirective } from './sofValueFormater.directive';
import { Enums } from '@softools/softools-core';
import { BitFieldComponent } from './bit-field/bit-field.component';
import { ImageFieldComponent, ButtonFieldComponent } from '..';
import { TextDisplayFieldComponent } from './text-display-field/text-display-field.component';
import { IconDisplayFieldComponent } from './icon-display-field/icon-display-field.component';
import { MultiStateField2Component } from './multi-state-field/multi-state-field.component';
import { UrlFieldComponent } from './url-field/url-field.component';
import { DownloadIconFieldComponent } from './download-icon-field/download-icon-field.component';
import { ImageListFieldComponent } from './image-list-field/image-list-field.component';
import { DocumentFieldComponent } from './document-field/document-field.component';
import { RangeFieldComponent } from './range-field/range-field.component';

@Directive({
  selector: '[appDisplayField]'
})
export class DisplayFieldDirective extends SofValueFormaterDirective implements OnChanges, OnInit {

  public override ngOnInit(): void {
    try {
      super.ngOnInit();
    } catch (error) {
      console.log(error);
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    try {
      super.ngOnChanges(changes);

      if (changes['field'] && this.field) {
        switch (this.field.Type) {

          case Enums.FieldType.Bit: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BitFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.ImageList: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ImageListFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.MultiState: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MultiStateField2Component);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.Image: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ImageFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.InAppChart: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(IconDisplayFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.ImageActionButton: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ButtonFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.UrlField: {
            if (this.field.DisplayOptions && this.field.DisplayOptions.ShowAsIcon) {
              const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DownloadIconFieldComponent);
              this._addChildComponent(componentFactory);
            } else {
              const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UrlFieldComponent);
              this._addChildComponent(componentFactory);
            }
            break;
          }

          case Enums.FieldType.Document: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DocumentFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          case Enums.FieldType.Range: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RangeFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }

          default: {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TextDisplayFieldComponent);
            this._addChildComponent(componentFactory);
            break;
          }
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

}
