import { NgModule } from '@angular/core';

import { TranslationConstantsComponent } from './global-translations.component';

@NgModule({
  imports: [],
  exports: [TranslationConstantsComponent],
  declarations: [TranslationConstantsComponent],
  providers: [],
})
export class TranslationConstantsModule { }
