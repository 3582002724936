<div class="card"
  style="overflow-y: hidden;"
  [style.width.px]="card.Width"
  [style.height.px]="card.Height"
  [ngStyle]="cardCss$|async"
  [@rotateCard]="rotating$|async"
  (@rotateCard.done)="animated($event)"
  [draggable]="isDraggable()"
  (dragstart)="dragged($event)" 
  (dragend)="dragEnded($event)"
>

  <div class="card-inner w-100 pointer" 
    [style.grid-template-columns]="columnWidths"
    (click)="cardBodyClicked($event)"
  >
    <ng-container *ngFor="let layout of cardLayout">
      <div
        class="m-2 overflow-auto d-flex flex-column"
        [style.grid-row-start]="layout.RowId"
        [style.grid-row-end]="layout.EndRow"
        [style.grid-column-start]="layout.ColumnId"
        [style.grid-column-end]="layout.EndColumn"
      >

      <!--
          [commentsModel]="commentsModel"
          [attachmentsModel]="attachmentsModel"
          [recordModel]="recordModel"
          [application]="application"
          [appIdentifiers]="appIdentifiers"
          [containerType]="formContainerType"
          [validationErrors]="validationErrors"
          [ruleState]="rulesEngine" 
        -->
        <small *ngIf="!layout.HideLabel"
          class="label-line st-text-theme-primary" 
          [ngStyle]="layout.styles?.['label']?.css"
          >
          {{layout.ShortLabel ?? layout.field.Label}}
        </small>
        <app-card-app-field
          [appModel]="appModel"
          [record]="record$|async"
          [template]="template"
          [fieldModel]="layout.field"
          [forReport]="false"
          [inline]="false"
          [isOnline]="appModel?.globalModel.online.$|async"
          [elementStyles]="layout.styles"
          >
        </app-card-app-field>
      </div>
    </ng-container>
  </div>

  <div *ngIf="hasLeftMargin" class="left-margin st-text-theme-primary"></div>

  <div *ngIf="hasRightMargin" class="right-margin st-text-theme-primary">
    <!-- Slight fakery - add marginalia for home items and tweak behaviour  -->
    <fa-icon *ngIf="homeItem?.kind === 'create'"  
      [icon]="['fal', 'plus-square']" size="1x" style="margin-bottom: 0.5rem;" ></fa-icon>
    <fa-icon *ngIf="homeItem?.kind === 'recent'"  
      [icon]="['fal', 'square']" size="1x" style="margin-bottom: 0.5rem;" ></fa-icon>
    <fa-icon *ngIf="homeItem?.kind === 'change'"  
      [icon]="['fal', 'edit']" size="1x" style="margin-bottom: 0.5rem;" ></fa-icon>

    <ng-container *ngIf="homeItem?.kind !== 'recent'">
      <div *ngFor="let marginField of rightMargin"  >
        <app-card-app-field
          [appModel]="appModel"
          [generalController]="generalController"
          [record]="record$|async"
          [template]="template$|async"
          [fieldModel]="marginField.appField"
          [forReport]="false"
          [inline]="false"
          [isOnline]="appModel?.globalModel.online.$|async"
          >
        </app-card-app-field>
      </div>
    </ng-container>

    <!-- Flip button if more than one template -->
    <fa-icon *ngIf="card?.Templates?.length > 1" class="pointer" 
      [icon]="['fal', 'repeat-alt']" size="1x" style="margin-bottom: 0.5rem;"
      (click)="flipClicked($event)"
    ></fa-icon>
  </div>
</div>
