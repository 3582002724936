import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { logError, Record } from '@softools/softools-core';
import { PARENT_RECORD_ID } from 'app/_constants/constants.route';
import { AppService } from 'app/services/app.service';
import { BaseRecordCreator } from './BaseRecordCreator';
import { NavigationService } from 'app/services/navigation.service';
import { AppIdentifiers } from 'app/services/record/app-info';
import { AppDataService } from 'app/services/appdata.service';

@Injectable({ providedIn: 'root' })
export class NewChildRecordResolver extends BaseRecordCreator implements Resolve<Record> {

  constructor(
    private appService: AppService,
    appDataService: AppDataService,
    public router: Router,
    private navigation: NavigationService
  ) {
    super(appDataService);
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record> {

    const appIdentifiers = new AppIdentifiers(route.params);
    const params: Params = route.params;
    const parentRecordId = params[PARENT_RECORD_ID] as string;
    const hierarchy = `${appIdentifiers.appIdentifier}|${parentRecordId}`;

    try {
      const app = this.appService.application(appIdentifiers.childAppIdentifier);
      const record = await this.createInitialRecord(app, { hierarchy, values: route.queryParams });

      // Redirect to edit page for newly created record
      const url = this.navigation.getRecordUrl2(appIdentifiers, record._id);
      this.router.navigateByUrl(url).catch(error => logError(error, 'Failed to navigate'));
      return null;

    } catch (error) {
      console.log(`Could not create ${appIdentifiers.childAppIdentifier} under ${hierarchy}`, error);
      return null;
    }
  }
}
