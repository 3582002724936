import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Activity, ModalComponentContentData, Record } from '@softools/softools-core';
import { EpochConverter, DateFormat } from 'app/types/epoch-converter';
import { InjectService } from 'app/services/locator.service';
import { UsersService } from 'app/services/users.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryComponent {

  @InjectService(UsersService)
  private readonly userService: UsersService;

  public activities: Array<Activity>;
  public exactCreatedDate: string;
  public createdByUser: string;
  public exactUpdatedDate: string;
  public updatedByUser: string;
  public showDateStrings = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalComponentContentData) {

    const history = data.HistoryData;
    const record = history.record;

    this.activities = history.activities;

    if (record?.CreatedDate) {
      this.exactCreatedDate = EpochConverter.toDateString(record.CreatedDate, DateFormat.IsoUtc);
    }

    // Lookup create user from id (preferable as it will get current name)
    const createUser = record['CreatedByUserId'] && this.userService.getUserOrPending(record['CreatedByUserId']);
    if (createUser) {
      this.createdByUser = `${createUser.FirstName} ${createUser.LastName}`;
    } else {
      // User not found, fall back to user name in record if present
      this.createdByUser = record.CreatedByUser ? record.CreatedByUser : '-';
    }

    if (record && record.UpdatedDate) {
      this.exactUpdatedDate = EpochConverter.toDateString(record.UpdatedDate, DateFormat.IsoUtc);
    }

    // Lookup update user from id (preferable as it will get current name)
    const updateUser = record['UpdatedByUserId'] && this.userService.getUserOrPending(record['UpdatedByUserId']);
    if (updateUser) {
      this.updatedByUser = `${updateUser.FirstName} ${updateUser.LastName}`;
    } else {
      // User not found, fall back to user name in record if present
      this.updatedByUser = record.UpdatedByUser ? record.UpdatedByUser : '-';
    }
  }

  public publishDate(date: string) {
    const epoch = EpochConverter.toEpoch(date, true);
    return EpochConverter.toDateString(epoch, DateFormat.Ago);
  }

  public exactPublishDate(date: string) {
    const epoch = EpochConverter.toEpoch(date, true);
    return EpochConverter.toDateString(epoch, DateFormat.IsoUtc);
  }

  public toggleShowDateStrings() {
    this.showDateStrings = !this.showDateStrings;
  }
}
