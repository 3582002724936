import { SiteSettings } from '../types';
import { SiteSettingsRepository } from '../repos';
import { Injectable } from '@angular/core';
import { OnlineStatusService } from './online-status-service';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService } from '../indexedDb/database-context.service';
import { SITE_SETTINGS_STORE } from '../indexedDb';
import { logError } from '../utils/log-error';

@Injectable({ providedIn: 'root' })
export class SiteSettingsStorageService extends StorageServiceBase<SiteSettings> {

  constructor(
    dbContext: DatabaseContextService<SiteSettings>,
    private onlineStatus: OnlineStatusService,
    private repository: SiteSettingsRepository) {
    super(SITE_SETTINGS_STORE, dbContext);
  }

  public async getSettingsAsync(): Promise<SiteSettings> {
    try {
      const settings = await this.getOrCreateItemAsync('Settings', async () =>
        await this.getRemoteSettingAsync()
      );

      return settings;

    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async refreshSettingsAsync(): Promise<SiteSettings> {
    const settings = await this.getRemoteSettingAsync();
    this.storeSettingsAsync(settings);
    return settings;
  }

  public async getRemoteSettingAsync(): Promise<SiteSettings> {
    return this.repository.getAll().toPromise();
  }

  public saveAsync(siteid: number, changes: any): Promise<Object> {
    if (this.onlineStatus.isConnected) {
      return this.repository.save(siteid, changes).toPromise();
    }

    return null;
  }

  public storeSettingsAsync(settings: SiteSettings) {
    super.save('Settings', settings).catch(error => logError(error, 'Failed to store settings'));
  }

}
