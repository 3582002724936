import { NotificationStorageService } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { Causes } from '../reports/busy.model';
import { NotificationsModel } from './notifications.model';

export class NotificationsController {

  @InjectService(NotificationStorageService)
  private readonly _notificationsService: NotificationStorageService;

  public constructor(public model: NotificationsModel) {
  }

  /** Load background messages from server and update model */
  public async loadNotifications() {
    try {
      this.model.busy.start(Causes.loading);
      const notifications = await this._notificationsService.syncNotificationsMarkRead();
      this.model.notifications.value = notifications;
    } finally {
      this.model.busy.finish(Causes.loading);
    }
  }

  public async clearNotifications() {
    await this.model.clearNotifications();
  }

  public async removeNotification(notificationId: string) {
    await this.model.removeNotification(notificationId);
  }
}
