/**
 * Control access to a resource or code based on a timer.
 * If start returns true the operation is permitted and end *must* be called
 * when it is complete.
 * @property duration specifies the minimum delay in ms from the operation
 * completing and the next call being allowed.
 */
export class Throttle {

  /** When next call is allowed (0 is immediate) */
  private next = 0;

  private running = false;

  constructor(public readonly duration) {
  }

  public start() {

    // Disallow rentancy
    if (this.running) {
      // console.warn('re-entrant call rejected');
      return false;
    }

    const now = Date.now();
    if (this.next) {
      if (now < this.next) {
        // console.warn(`throttled call rejected, try again in ${this.next - now}`);
        return false;
      }
    }

    this.running = true;
    return true;
  }

  public end() {
    this.next = Date.now() + this.duration;
    this.running = false;
  }
}
