<tr style="border-bottom:0">
  <th class="text-nowrap col-small">
    <app-checkbox *ngIf="allowMultiSelect && showSelectAll" [checked]="selectAll" (onchecked)="onSelectAll.emit()" (onunchecked)="onDeselectAll.emit()"></app-checkbox>
  </th>
  <th
    *ngFor="let simpleFilter of  simpleFilter$|async; let i = index; trackBy: trackHeader"
    style="max-width: 16rem;"
    [attr.column-identifier]="simpleFilter.field.FieldIdentifier"
    [attr.column-priority]="simpleFilter.field.ColumnPriority"
    [attr.column-index]="allowMultiSelect ? i + 1 : i"
    [ngClass]="{
      'col-auto': simpleFilter.appField.Type === fieldTypes.DateTime
    }"
  >
      <ng-container *ngIf="i === 0 && showGroupsExpanded">
        <span class="pointer" (click)="toggleGroups.emit()">
          <fa-icon *ngIf="!groupsExpanded; else circleUp" [icon]="['fal', 'chevron-circle-down']" size="1x" class="me-2"></fa-icon>
          <ng-template #circleUp>
            <fa-icon [icon]="['fal', 'chevron-circle-up']" size="1x" class="me-2"></fa-icon>
          </ng-template>
        </span>
      </ng-container>
      <app-report-column-header
        [filterModel]="filterModel"
        [simpleFilter]="simpleFilter"
        (headerClicked)="requestFilterPopup($event, simpleFilter)">
        <app-summary-expression
          [appIdentifier]="appIdentifier"
          [fieldIdentifier]="simpleFilter.identifier"
          [headerSummaryExpressions]="headerSummaryExpressions"
          [headerFields]="headerFields"
        ></app-summary-expression>
      </app-report-column-header>
  </th>
  <th class="col-small" *ngIf="showDetailsField">
    <span class="pointer" (click)="toggleDetailsFields.emit()">
      <fa-icon *ngIf="expandAllDetailsFields" [icon]="['fal', 'chevron-circle-up']"></fa-icon>
      <fa-icon *ngIf="!expandAllDetailsFields" [icon]="['fal', 'chevron-circle-down']"></fa-icon
    ></span>
  </th>
  <th class="col-small pointer" *ngIf="showAutoLayoutPopup">
    <fa-icon
      [icon]="['fal', 'ellipsis-v']"
      [border]="true"
      class="bg-primary text-white"
      (click)="onToggleAutoLayoutClick.emit($event)"
    ></fa-icon>
  </th>
</tr>
