<app-input-icon
  icon="eye"
  [elementStyles]="elementStyles"
  [clickable]="true"
  (iconClicked)="iconClicked($event)"
  >
  <input *ngIf="concealed$|async; else bax"
    readonly
    class="form-control form-control-o text-muted"
    value="Hidden - click eye to show value" i18n-value
  >
</app-input-icon>

<ng-template #bax>
  <app-input
    class="d-block pe-0"
    [appModel]="appModel"
    [reportModel]="reportModel"
    [class.inline-control]="inline"
    [field]="fieldModel"
    [template]="template"
    [value]="hiddenValue"
    [listRow]="listRow"
    [ruleState]="ruleState"
    [containerField]="containerField"
 >
 </app-input>
</ng-template>
