import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { ReportQueryGuard } from 'app/guards';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { ReportViewService, ReportDataStorageService } from '@softools/softools-core';
import { AppService } from 'app/services/app.service';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable()
export class ListReportQueryGuard extends ReportQueryGuard implements CanActivate {

  constructor(
    public override indexedDbService: IndexedDbService,
    appService: AppService,
    reportStateService: ReportDataStorageService,
    reportViewService: ReportViewService,
    protected override router: Router,
    models: GlobalModelService
  ) {
    super(indexedDbService, appService, reportStateService, reportViewService, router, models);
  }

  public override async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    try {
      return this.useCurrentParamsWithCleanUrl(route).then((activate) => {
        if (!activate) {
          console.warn('ListReportQueryGuard rejected navigation');
        }
        return activate;
      });
    } catch (error) {
      console.warn('ListReportQueryGuard caught error', error);
      return false;
    }
  }

  protected override prepareRoute(route: ActivatedRouteSnapshot): boolean {
    // If we have query parems but top or skip are not specified, set them
    if (Object.keys(route.queryParams).length > 0 && (!route.queryParams['$top'] || !route.queryParams['$skip'])) {
      route.queryParams = { ...route.queryParams, $top: 25, $skip: 0 };
      return true;
    }

    return false;
  }
}
