
export const RefreshBarSuffix = '-refreshbar';

export enum RefreshBarTag {
  recordcreated = 'recordcreated-refreshbar',
  recordupdated = 'recordupdated-refreshbar',
  recordarchived = 'recordarchived-refreshbar',
  recordunarchived = 'recordunarchived-refreshbar',
  recorddeleted = 'recorddeleted-refreshbar',
  recordaccessrightsoverridden = 'recordaccessrightsoverridden-refreshbar'
}
