import { Injectable } from '@angular/core';
import { getCurrentUser, Record, SelectListOption } from '@softools/softools-core';
import { EmbeddedApplication } from '../embedded-application';
import { AppIdentifier, Application } from 'app/types/application';
import { ApplicationsAppConfig } from './applications-app.config';
import { RecordPatch } from 'app/workspace.module/types';
import { AppService } from 'app/services/app.service';
import { LocatorService } from 'app/services/locator.service';
import { FieldBase } from 'app/softoolsui.module/fields';
import { ListFieldComponent } from 'app/softoolsui.module/fields2/list-field/list-field.component';
import { StorageModeService } from 'app/services/storage-mode.service';
import { StandardApplication } from 'app/types/standard-application';
import { StorageMode } from 'app/types/enums';

export interface ApplicationSettings {
  Applications: Array<{
    App: Application;

    AppIdentifier: AppIdentifier;

    Name: string;

    OfflineMode: string;
  }>;
}

@Injectable({ providedIn: 'root' })
export class ApplicationsApplication extends EmbeddedApplication<ApplicationSettings> {
  // Select options
  // These currently have to match the option text (see SOF-7650)
  private readonly AutoOption = 'Use default';
  private readonly OnlineOption = 'Online only';
  private readonly OfflineOption = 'Offline';
  private readonly SelectiveOption = 'Choose which reports work offline (online by default)';

  constructor(private storageModeService: StorageModeService) {
    super(ApplicationsAppConfig);
  }

  /** Called for each (currently some) field in the application to allow behaviour modification */
  public override initialiseFieldComponent(component: FieldBase) {
    if (component.fieldModel.Identifier === 'Applications' && component instanceof ListFieldComponent) {
      // Set correct row id so patching works
      component.rowKeyId = 'AppIdentifier';
    }
  }

  public override async getRecordByIdAsync(): Promise<Record> {
    const appService = LocatorService.get(AppService);
    const user = getCurrentUser();
    const apps = appService.applications();

    const _apps = apps
      .filter((app) => !app.IsEmbedded)
      .filter((app) => !app.IsHidden && app.isAppVisibleToUser(user))
      .map((app) => ({
        App: app,
        AppIdentifier: app.Identifier,
        Name: app.Title,
        OfflineMode: this.AutoOption,
        DefaultValue: this.OfflineOption
      }));

    // Get flags = should be able to neaten this eventually...
    _apps.forEach((app) => {
      const mode = this.storageModeService.getAppStorageMode(app.AppIdentifier);
      switch (mode) {
        case StorageMode.Online:
          app.OfflineMode = this.OnlineOption;
          break;
        case StorageMode.Offline:
          app.OfflineMode = this.OfflineOption;
          break;
        case StorageMode.Selective:
          app.OfflineMode = this.SelectiveOption;
          break;
        default:
          app.OfflineMode = this.AutoOption;
          break;
      }

      const defaultValue = app.App.defaultStorageMode(user.Tenant);

      switch (defaultValue) {
        case StorageMode.Online:
          app.DefaultValue = this.OnlineOption;
          break;
        case StorageMode.Offline:
          app.DefaultValue = this.OfflineOption;
          break;
        case StorageMode.Selective:
          app.DefaultValue = this.SelectiveOption;
          break;
        default:
          app.DefaultValue = this.OfflineOption;
          break;
      }
    });

    const appSettings: ApplicationSettings = {
      Applications: [..._apps.sort((first, second) => first.Name.localeCompare(second.Name))],
    };

    return this.toRecord(appSettings);
  }

  public toRecord(appSettings: ApplicationSettings): Record {
    return (
      appSettings &&
      ({
        ...appSettings,
        AppIdentifier: this.Identifier,
        _id: 'settings',
        Hierarchy: '',
        EditableAccessForUser: true,
        CreatedDate: null,
        CreatedByUser: '',
        UpdatedDate: null,
        UpdatedByUser: '',
        QuickFilterSearchText: '',
        IsArchived: false,
      } as Record)
    );
  }

  public convert(record: Record): ApplicationSettings {
    return {
      Applications: (record as any).Applications,
    };
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {
    const record = await this.getRecordByIdAsync();
    recordPatch.updateRecord(record, this);
    return record;
  }

  public override async storeAsync(record: Record): Promise<Record> {
    const appService = LocatorService.get(AppService);

    const applications = this.convert(record).Applications;

    let changed = false;
    applications.forEach((rec) => {
      let mode: StorageMode;
      switch (rec.OfflineMode) {
        case this.OfflineOption:
          mode = StorageMode.Offline;
          break;
        case this.OnlineOption:
          mode = StorageMode.Online;
          break;
        case this.SelectiveOption:
          mode = StorageMode.Selective;
          break;
      }

      const existing = this.storageModeService.getAppStorageMode(rec.AppIdentifier);
      if (mode !== existing) {
        this.storageModeService.setAppStorageMode(rec.AppIdentifier, mode);
        changed = true;

        // update local app.
        const app = appService.application(rec.AppIdentifier);
        if (app instanceof StandardApplication) {
          app.setStorageMode();
        }
      }
    });

    if (changed) {
      this.storageModeService.store();
    }

    return record;
  }

  public override selectionListOptions(fieldId: string): Array<SelectListOption> {
    if (fieldId === 'Applications_OfflineMode') {
      return [
        {
          Id: this.OfflineOption,
          Text: this.AutoOption,
          Value: this.AutoOption,
          DisplayOrder: 1,
        },
        {
          Id: this.OfflineOption,
          Text: this.OnlineOption,
          Value: this.OnlineOption,
          DisplayOrder: 2,
        },
        {
          Id: this.SelectiveOption,
          Text: this.SelectiveOption,
          Value: this.SelectiveOption,
          DisplayOrder: 3,
        },
        {
          Id: this.OfflineOption,
          Text: this.OfflineOption,
          Value: this.OfflineOption,
          DisplayOrder: 4,
        },
      ];
    }
    return null;
  }

  public override get permissions() {
    return []; // no permissions required
  }

  public override get permitted() {
    return true; // All users can run
  }
}
