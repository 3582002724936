import { Model, ModelProperty } from '@softools/vertex';

export class PendingChangesModel extends Model<PendingChangesModel> {

  public readonly pendingChangesCount = new ModelProperty<number>(this).withLogging('CHANGES');

  public readonly pendingCreatesCount = new ModelProperty<number>(this).withLogging('CREATES');

  public readonly pendingSaveImagesCount = new ModelProperty<number>(this).withLogging('IMAGES');

  public readonly pendingDeleteImagesCount = new ModelProperty<number>(this).withLogging('DEL IMAGES');
}
