import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { QueryParams, SavedFilter, ReportDataStorageService } from '@softools/softools-core';
import { ReportFilter } from 'app/filters/types';
import { AppService } from 'app/services/app.service';
import { InjectService } from 'app/services/locator.service';
import { SavedFiltersApplication } from 'app/embedded.apps/settings.apps/saved-filters/saved-filters.app';

export class QueryGuardBase {

  @InjectService(SavedFiltersApplication)
  private readonly savedFilters: SavedFiltersApplication;

  constructor(
    public indexedDbService: IndexedDbService,
    protected appService: AppService,
    private reportStateService: ReportDataStorageService,
  ) { }

  /**
   * Get the last stored query parameters for a report request.
   *
   * If there is not saved report data we take that as an indication that the report
   * has never been visited and apply the default filter
   *
   * @param appIdentifier
   * @param reportIdentifier
   * @param forChildApp
   * @returns {Promise<QueryParams>} last query parameetrs on null if none
   */
  protected async getLastQueryParams(appIdentifier: string, reportIdentifier: string, forChildApp: boolean = false): Promise<QueryParams> {
    const report = await this.reportStateService.getReportDataAsync(appIdentifier, reportIdentifier);
    if (report) {
      const queryParamsFromStorage = report.queryParams;
      if (!forChildApp && queryParamsFromStorage && queryParamsFromStorage.hierarchy) {
        delete queryParamsFromStorage.hierarchy;
      }

      return queryParamsFromStorage;
    } else {
      // no saved report data, look up default (if any)
      const savedDefaultFilter = await this.getDefaultFilter(appIdentifier);
      if (savedDefaultFilter) {
        const filter = ReportFilter.fromSavedFilter(savedDefaultFilter);
        return filter.QueryParameters;
      }
    }

    return null;
  }

  protected async getDefaultFilter(appIdentifier: string): Promise<SavedFilter> {

    const app = this.appService.application(appIdentifier);
    if (app.isFilterAdvanced) {
      return null;
    } else {
      const filters = this.savedFilters.getAppSavedFilters(appIdentifier);

      // If we got no filters, check the app.  This allows embedded apps to include
      // thier own filters and to find filters before they have been migrated to the
      // new storage by a sync
      if (!filters.length) {
        if (app?.SavedFilters?.length > 0) {
          filters.push(...app.SavedFilters);
        }
      }

      const filter = filters?.find(f => f.IsDefault);
      if (filter?.Filter) {
        // Strip any [quoted] identifiers and $filter=
        return {
          ...filter,
          Filter: filter.Filter.replace(/\$filter=|\[|\]/g, '')
        };
      }

      return filter;
    }
  }
}
