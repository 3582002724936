<div #display class="text-container form-control form-control-o d-inline-block"
  [class.text-truncate]="forReport"
  tabindex="-1"
  [style.text-align]="textStyle['align-self']"
  [style.border]="'none'"
  [ngStyle]="textStyle"
  [class.report-text-display]="forReport"
  [style.height]="(fieldModel?.Rows && !forReport) ? fieldModel.Rows + 'rem' : 'auto'"
  [class.long-text-row-limit]="fieldModel?.Rows && !forReport"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (keypress)="onKeyPress($event)"
  [attr.data-identifier]="fieldModel?.Identifier"
>
  {{displayText$|async}}
</div>
