import { LRUCache } from 'mnemonist';
import { App, AppDataStorageService, IndexedAppData, logError, QueryParams, Record, RecordId } from '@softools/softools-core';
import { InjectService } from '../locator.service';
import { Application, IGetIndexOptions } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { ReportFilter } from 'app/filters/types';

/**
 * Index implementation for online apps.
 * This is currently only intended for paginating online record view
 * so some methods not implemented. We may extend to improve online
 * data access later.
 */
export class OnlineDataIndex implements IndexedAppData {

  public length = 0;

  protected query: QueryParams;

  private pageSize = 30;

  private indexPromise: Promise<void>;

  private pages = new LRUCache<number, Array<RecordId>>(10);

  @InjectService(AppDataStorageService)
  private appDataStorageService: AppDataStorageService;

  constructor(public app: Application, protected reportIdentifier: string, query: QueryParams, protected options?: IGetIndexOptions) {
    const filter: QueryParams = { $filter: query.$filter };
    if (query.$orderby) {
      filter.$orderby = query.$orderby;
    }
    this.query = filter;
  }

  public async indexAll(): Promise<void> {
    if (!this.indexPromise) {
      this.indexPromise = new Promise<void>((resolve, reject) => {
        this.indexAllRecords().then(() => resolve()).catch(e => reject(e));
      });
    }

    return this.indexPromise;
  }

  public async getRecordIds(start?: number, count?: number): Promise<Array<string>> {
    const chunks: Array<Array<RecordId>> = [];
    while (count > 0) {
      const page = await this.getIndexPage(start);
      const offset = start % this.pageSize;
      const used = Math.min(count, this.pageSize - offset);
      chunks.push(page.slice(offset, offset + used));
      start += used;
      count -= used;
    }

    return chunks.flat();
  }

  public getRecords(start: number, count: number): Promise<Record[]> {
    throw new Error('Method not implemented.');
  }

  public getRecordsWithGetterFunc(start: number, count: number, recordGetterFunc: (app: App, id: string) => Promise<Record>): Promise<Record[]> {
    throw new Error('Method not implemented.');
  }

  public eachRecord(callback: (record: Record, id: string, index: number) => any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private async indexAllRecords(): Promise<void> {

    const selection = new RecordSelection();
    selection.filter = ReportFilter.fromQueryParams(this.query);
    selection.hierarchy = this.options?.hierarchy;
    selection.report = this.app.getReport(this.reportIdentifier);
    selection.showArchived = this.options?.archived;
    const count = await this.app.getApiViewRecordCount(selection);

    this.length = count;

    // Load initial index page
    await this.getIndexPage(0);
  }

  private storeIndexPage(start = 0, ids: Array<RecordId>) {
    const position = start / this.pageSize;
    this.pages.set(position, ids);
  }

  private async getIndexPage(start: number): Promise<Array<RecordId>> {
    const position = Math.floor(start / this.pageSize);
    const existing = this.pages.get(position);
    if (existing) {
      return existing;
    } else {
      const t0 = performance.now();

      const pageStart = position * this.pageSize;
      const ids = await this.loadPageIds(pageStart, this.pageSize);
      this.pages.set(position, ids);

      const t1 = performance.now();
      console.log(`online indexing ${this.app.Identifier} from ${start} took ${Math.trunc(t1 - t0)} milliseconds. filter=${this.query.$filter} length=${this.length}  `);
      return ids;
    }
  }

  protected async loadPageIds(pageStart: number, pageSize: number) {
    return await this.appDataStorageService.getIndexAsync(
      this.app.Identifier, pageStart, pageSize,
      this.reportIdentifier, this.query, this.options?.hierarchy);
  }

  public async positionOf(recordId: string): Promise<number> {
    return await this.appDataStorageService.getRecordPositionAsync(
      recordId,
      this.app.Identifier,
      this.reportIdentifier,
      this.query,
      this.options?.hierarchy
    );
  }

  public onRecordCreated(record: Record) {
    // Reindex as online model tracks server state
    this.indexAllRecords().catch((error) => logError(error, 'onRecordCreated'));
  }
}
