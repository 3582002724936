import { Injectable } from '@angular/core';
import { StorageServiceBase } from './storage-service-base';
import { DatabaseContextService, REPORT_SELECTION_STATE } from '../indexedDb';

export interface ReportStateItem {
  AllSelected: boolean;
  SelectedRecordIds: Array<string>;
}

@Injectable({ providedIn: 'root' })
export class ReportStateStorageService extends StorageServiceBase<ReportStateItem, string> {
  constructor(
    dbContext: DatabaseContextService<ReportStateItem>) {
    super(REPORT_SELECTION_STATE, dbContext);
  }
}
