import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { UrlResolver, OnlineStatusService, Enums, logMessage } from '@softools/softools-core';
import { Router, ActivatedRoute } from '@angular/router';

import { WSReportBase } from 'app/workspace.module/components/ws-base/ws-reportbase';
import { contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation } from 'app/_constants';
import { IndexedDbService } from 'app/workspace.module/services/indexeddb.service';
import { AppService } from 'app/services/app.service';
import { NavigationService } from 'app/services/navigation.service';
import { PermissionsService } from 'app/services/permissions.service';
import { RouteParams } from 'app/mvc/global.model';
import { DashboardModel } from 'app/mvc';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Component({
  selector: 'app-ws-dashboardreport',
  templateUrl: './ws-dashboardreport.component.html',
  styleUrls: ['./ws-dashboardreport.component.scss'],
  animations: [contentPinnedStateAnimation, rightbarToggleContentStateAnimation, leftAsideStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSDashboardReportComponent extends WSReportBase implements OnInit, OnDestroy {

  public dashboardModel: DashboardModel;

  constructor(
    public override router: Router,
    public override indexedDbService: IndexedDbService,
    public override appService: AppService,
    public override urlresolver: UrlResolver,
    route: ActivatedRoute,
    onlineStatus: OnlineStatusService,
    navigationService: NavigationService,
    permissionsService: PermissionsService,
    models: GlobalModelService,
  ) {
    super(indexedDbService, appService, router, urlresolver, onlineStatus, route, navigationService, permissionsService, models);
    this.dashboardModel = new DashboardModel(models, this.globalModel, models.launchpadModel);
  }

  public isCustomDashboard(): boolean {
    const report = this.reportModel.report.value;
    return report && !report.Dashboard;
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.dashboardModel.initialize();
  }

  protected override async routed(params: RouteParams) {

    await super.routed(params);

    if (params.reportIdentifier) {
      const report = this.appModel.app.value.Reports.find(rep => rep.Identifier === params.reportIdentifier);
      if (report?.Type === Enums.ReportTypes.Dashboard) {
        this.dashboardModel.setDashboard(report.Dashboard);
      } else {
        logMessage(`Invalid dashboard in route ${params.reportIdentifier}`);
        this.dashboardModel.setDashboard(null);
      }
    }

    this.processRouteParams();

    // Model reload not being seen
    await this.refresh();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
