<div class="sof-modal-container">
<div class="cardv2-popup sof-small-modal">
  <div class="cardv2-header d-flex">
    <div class="flex-grow-1" i18n="@@ConfirmDelete">Confirm delete</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
  </div>
  <div class="cardv2-body">
    <p class="prompt-text" i18n="@@ConfirmDeletePrompt">Are you sure you want to delete this item?</p>
  </div>
  <div class="cardv2-footer">
    <button type="button" class="primary" (click)="confirm()" i18n="@@Yes">Yes</button>
    <button type="button" class="close" (click)="cancel()" i18n="@@No">No</button>
  </div>
</div>
</div>
