import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WSBase } from './ws-base';
import { WSRecordBase } from './ws-recordbase';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [WSBase, WSRecordBase],
})
export class WsBaseModule { }
