import { Injector, ProviderToken, Type } from '@angular/core';

/**
 * Provide access to Injectable objects
 */
export class LocatorService {

  public static injector: Injector;

  public static get<T>(t: ProviderToken<T>) {
    return LocatorService.injector.get(t);
  }
}

/**
 * Inject specified service into a property
 * @param token  Injection token.
 */
export function InjectService<T>(token: ProviderToken<T>) {
  return function (target: Object, propertyKey: string) {

    const getter = function () {
      const key = `__injected_${propertyKey}`;
      if (!target[key]) {
        target[key] = LocatorService.get(token);
      }

      return target[key];
    };

    Object.defineProperty(target, propertyKey, {
      get: getter
    });
  };
}
