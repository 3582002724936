import { RepositoryBase } from './repositories.base';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, of } from 'rxjs';
import { User, Permission, QueryParams, AddUserRequest, TrackChangeList } from '../types';
import { HttpErrorResponse } from '@angular/common/http';
import { tryGetCurrentUser } from './repositories.global';
import { environment } from 'environments/environment';
import { logError } from '../utils';

export interface Invitation {
  EmailAddresses: Array<string>;
  Permissions: Array<Permission>;
  TeamNames?: Array<string>;
  TeamIds?: Array<string>;
}

export interface EmailActivity {
  messages: Array<{
    subject: string,
    status: string,
    opens_count: number,
    clicks_count: number,
    last_event_time: Date,
    from_email: string
  }>
};

export interface InviteResponse {
  /** Successfully sent email addresses */
  Sucessful: Array<string>;
  Errors: Array<{
    /** Pseudo-identifier indicating what failed */
    FieldIdentifier: string;

    /** Pseudo-label indicating what failed */
    FieldLabel: string;

    /** Error message */
    Message: string;

    /** The failed email address */
    ErrorDetails: string;
  }>;
}

export interface UserInvitationInfo {
  Key: string;
  Tenant: string;
  Username?: string;
  FirstName?: string;
  LastName?: string;
  Password?: string;
  ConfirmPassword?: string;
  UsernameNotRequired: boolean;

  // Track invitation key as Key is overloaded
  // This field is not used on the server
  InvitationKey?: string;
}

export interface ExportUsersJobResult {
  guid: string;
}

export interface CreateUserResult {
  EntityId: number;
  Errors: Array<any>;
  RecordId: string;
}

@Injectable({ providedIn: 'root' })
export class UsersRepository extends RepositoryBase {
  public getSorted() {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', 'GetSorted');
    return this.get(url);
  }

  public getForMyTeams(): Observable<Array<User>> {
    const user = tryGetCurrentUser();
    if (user) {
      const url = this.urlResolver.resolveDefaultApiUrl('Users', `GetMyTeamUsers/${user.Id}`);
      return this.get(url);
    }

    // Not logged in so can't have team users
    return of([]);
  }

  /** Get a single user by id */
  public getUserAsync(id: string): Promise<User> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', id);
    return lastValueFrom<User>(this.get(url));
  }

  /**
   * Get all users, including inactive (closed, unverified)
   */
  public async getAllUsersAsync(queryParamters?: QueryParams): Promise<Array<User>> {
    let url = this.urlResolver.resolveDefaultApiUrl('Users', 'GetAll');
    if (queryParamters) {
      url = this.urlResolver.appendQueryParams(url, queryParamters);
    }
    return lastValueFrom<Array<User>>(this.get(`${url}`));
  }

  public async getCount(queryParamters?: QueryParams): Promise<number> {
    let url = this.urlResolver.resolveDefaultApiUrl('Users', '$count');
    if (queryParamters) {
      url = this.urlResolver.appendQueryParams(url, queryParamters);
    }
    return lastValueFrom<number>(this.get(`${url}`));
  }

  /**
   * Get users that have changed since a specified time
   * @param when Time to check as a Unix seconds-since-1970 numeric timestamp
   * @throws HttpErrorResponse if a network error occurs
   * @throws SyntaxError if the returned data is invalid JSON
   */
  public async usersChangedSince(when: number): Promise<Array<User>> {
    const url = `${environment.baseUrl}/Api/Users/since/${when}`;
    const response = await lastValueFrom<string>(this.get(url, 'text'));

    try {
      const users: Array<User> = JSON.parse(response);
      return users;
    } catch (error) {
      logError(error, `User poll: Parse error '${response?.slice(-20)}'`)
      return [];
    }
  }

  public save(userid: string, changes: any, privateMode: boolean = false) {
    let url = this.urlResolver.resolveDefaultApiUrl('Users', userid);
    if (privateMode) {
      url += '/Private';
    }
    return this.patchAsync(url, JSON.stringify(changes));
  }

  /**
   * Create a new user
   * @param user Initial user data
   * @returns created user id
   */
  public create(user: User | AddUserRequest): Observable<CreateUserResult> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users');

    // The post API requires NotificationMethods to be a tracked change list
    if (user.NotificationMethods && Array.isArray(user.NotificationMethods)) {
      const trackedNotifications = new TrackChangeList();
      user.NotificationMethods.forEach((m) => {
        trackedNotifications.addChange(m.toString(), { Value: m.toString() });
      });

      user = { ...user, NotificationMethods: trackedNotifications as any };
    }

    return this.post(url, user);
  }

  /**
   * Updates softools user account's LastLogin
   */
  public postLastLogin() {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', 'LastLogin');
    return this.post(url);
  }

  /** */
  public async confirmEmail(key: string): Promise<void> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', `ConfirmEmail/${key}`);
    return lastValueFrom<void>(this.post(url));
  }

  /** Invite users to softools using their email addresses */
  public async inviteAsync(invitation: Invitation): Promise<InviteResponse> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', 'Invite');
    return lastValueFrom<InviteResponse>(this.post(url, invitation));
  }

  public async getInvitation(key?: string): Promise<UserInvitationInfo> {
    const suffix = key ? `Invitation/Key/${key}` : 'Invitation/Current';
    const url = this.urlResolver.resolveDefaultApiUrl('Users', suffix);
    return lastValueFrom<UserInvitationInfo>(this.get(url));
  }

  public async verifyUser(response: UserInvitationInfo): Promise<void> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', 'VerifyUser');
    return lastValueFrom<void>(this.post(url, response));
  }

  public async resendVerificationEmail(userid: string): Promise<void> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', `${userid}/RequestVerification`);
    return lastValueFrom<void>(this.post(url));
  }

  public async getAllInactiveUsersAsync(): Promise<Array<User>> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', 'GetAll');
    const filter = '$filter=IsAccountClosed%20eq%20true&$orderby=Username%20asc';
    return lastValueFrom<Array<User>>(this.get(`${url}?${filter}`));
  }

  /**
   * Deactivate a single user
   * @param id   user id
   */
  public async deactivateUserAsync(id: string): Promise<Object | HttpErrorResponse> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', id);
    return this.delete(url).toPromise();
  }

  public async deactivateUsersAsync(ids: Array<string>): Promise<void> {
    if (ids && ids.length > 0) {
      const url = this.urlResolver.resolveDefaultApiUrl('Users', 'BulkDeactivate');
      // v14 filters by $filter=(IsAccountClosed%20eq%20false but that shouldn't be needed
      const body = { UserIds: ids };
      return this.post(url, body).toPromise();
    }
  }

  /**
   * Reactivate a single user
   * @param id   user id
   */
  public async reactivateUserAsync(id: string): Promise<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', id);
    return this.post(`${url}/Reactivate`).toPromise();
  }

  public async reactivateUsersAsync(ids: Array<string>): Promise<void> {
    // todo if 1 (or a small number?) of ids are specified, use delete instead for immediate result
    if (ids && ids.length > 0) {
      const url = this.urlResolver.resolveDefaultApiUrl('Users', 'BulkReactivate');
      const body = { UserIds: ids };
      return this.post(url, body).toPromise();
    }
  }

  public async exportUsersAsync(ids: Array<string>, queryParams?: QueryParams): Promise<ExportUsersJobResult> {
    // todo if 1 (or a small number?) of ids are specified, use delete instead for immediate result
    let url = this.urlResolver.resolveDefaultApiUrl('Users', 'Export');
    if (queryParams) {
      url = this.urlResolver.appendQueryParams(url, queryParams);
    }

    const body = { UserIds: ids };
    return lastValueFrom<ExportUsersJobResult>(this.post(url, body));
  }

  public async importUsersAsync(data: string): Promise<any> {
    const url = this.urlResolver.resolveDefaultApiUrl('Users', 'PatchFromCsvWithData');
    return this.patch(url, data).toPromise();
  }

  /**
   * Updates softools user account's LastAccess
   */
  public postLastAccess() {
    return this.post(`${environment.baseUrl}/api/session/user/lastaccess`);
  }

/** Get API key for the active user */
  public async getApiKey(): Promise<string> {
    const user = tryGetCurrentUser();
    const url = this.urlResolver.resolveDefaultApiUrl(`Users/${user.Id}/ApiKey`);
    return this.get(url, 'text').toPromise();
  }

/** Refresh API key for the active user */
  public async refreshApiKey(): Promise<string> {
    const user = tryGetCurrentUser();
    const url = this.urlResolver.resolveDefaultApiUrl(`Users/${user.Id}/RefreshApiKey`);
    return this.postWithType(url, 'text').toPromise();
  }

  public async getEmailActivity(userId: string): Promise<EmailActivity> {
    let url = this.urlResolver.resolveDefaultApiUrl('Users', 'Activity', userId);
    return lastValueFrom<EmailActivity>(this.get(`${url}`));
  }
}
