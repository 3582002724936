import { NavigationExtras, Router } from '@angular/router';
import { Report } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { NavigationService } from 'app/services/navigation.service';
import { AppIdentifiers } from 'app/services/record/app-info';

/**
 * Handles navigation to app pages
 */
export class NavigationController {

  @InjectService(Router)
  private readonly router: Router;

  @InjectService(NavigationService)
  private readonly navigationService: NavigationService;

  /**
   * Navigate to an app (default report)
   * @param target Navigation parameters
   * @returns Promise resolving to navigation success
   */
  public async navigateApp(
    target: {
      appIdentifier: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {
    const url = this.navigationService.getDefaultReportUrl(target.appIdentifier);
    return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
  }

  /**
   * Navigate to an app homepage.
   * @param target Navigation parameters
   * @returns Promise resolving to navigation success
   */
  public async navigateAppHome(
    target: {
      appIdentifier: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {
    const url = this.navigationService.getAppHomeUrl(target.appIdentifier);
    return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
  }

  /**
   * Navigate to an app homepage in child context.
   * @param target Navigation parameters
   * @returns Promise resolving to navigation success
   */
  public async navigateChildAppHome(
    target: {
      appIdentifiers: AppIdentifiers,
      parentRecordId: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {
    const url = this.navigationService.getChildAppHomeOrBaseUrl(target.appIdentifiers, target.parentRecordId);
    return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
  }

  public async navigateRecordAsync(
    target: {
      appIdentifiers?: AppIdentifiers,
      appIdentifier?: string,
      recordId: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {
    if (target.appIdentifier) {
      const url = this.navigationService.getRecordUrl(target.appIdentifier, target.recordId);
      return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
    } else if (target.appIdentifiers) {
      const url = this.navigationService.getRecordUrl2(target.appIdentifiers, target.recordId);
      return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
    } else {
      return false;
    }
  }

  public async navigateChildRecordAsync(
    target: {
      appIdentifiers: AppIdentifiers,
      recordId: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {
    const url = this.navigationService.getRecordUrl2(target.appIdentifiers, target.recordId);
    return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
  }


  /**
   * Navigate to a record create page.
   * @param target Navigation parameters.
   * @returns Promise resolving to navigation success.
   *
   * Target can specify appIdentifier to create a top level record, or
   * appIdentifiers and parentRecordId to create in a child context.
   */
  public async navigateRecordCreateAsync(
    target: {
      appIdentifier?: string,
      appIdentifiers?: AppIdentifiers,
      parentRecordId?: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {

    if (target.appIdentifier) {
      const url = this.navigationService.getRecordUrl(target.appIdentifier, 'craete');
      return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
    } else if (target.appIdentifiers && target.parentRecordId) {
      const url = this.navigationService.getCreateChildRecordUrl(target.appIdentifiers, target.parentRecordId);
      return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
    } else {
      return false;
    }
  }

  /**
   * Navigate to a report
   * @param target
   * @returns Promise resolving to navigation success.
   *
   * Target can specify report abd appIdentifier to go to a top level report .
   * (The appIdentifier is required because it's not correctly specfified in the Report tyoe).
   * OR
   * appIdentifiers and parentRecordId to navigate to report in a child context.
   * report can be specified to go to a specific report, otherwise the default report is used.
   */
  public async navigateReportAsync(
    target: {
      appIdentifier?: string,
      report?: Report,
      appIdentifiers?: AppIdentifiers,
      parentRecordId?: string,
      navigationExtras?: NavigationExtras
    }
  ): Promise<boolean> {

    if (target.appIdentifiers && target.parentRecordId) {
      const url = this.navigationService.getChildAppReportFolderUrl2(target.appIdentifiers, target.parentRecordId, target.report);
      return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
    } else if (target.report && target.appIdentifier) {
      const url = this.navigationService.getReportUrl(target.appIdentifier, target.report.Identifier, target.report.Type);
      return this.navigateUrlAsync({ url, navigationExtras: target.navigationExtras });
    } else {
      return false;
    }
  }

  /** Navigate to an error page */
  public async navigateErrorAsync(type: string) {
    const url = '/Error';
    return this.navigateUrlAsync({
      url, navigationExtras: {
        queryParams: { type }
      }
    });
  }

  /** Navigate to a URL */
  public async navigateUrlAsync(
    target: {
      url: string
      navigationExtras?: NavigationExtras
    }): Promise<boolean> {
    return await this.router.navigateByUrl(target.url, target.navigationExtras);
  }
}
