<div
  [@flyInOut]="(launchpadModel.show.$|async) ? 'in' : 'out'"
  class="fixed-top fixed-bottom text-white content-wrapper bg-dark"
  (click)="$event.stopPropagation()"
  (touchmove)="$event.stopPropagation()"
  (@flyInOut.done)="flyInOutEnd($event)"
>
  <div class="p-2 container h-100">
    <div class="controls d-flex">
      <app-search
        #search
        class="text-black ms-2"
        [searchText]="launchpadModel.search.$|async"
        (onSearch)="onSearchHandler($event)"
        (onEnter)="onSearchEnterHandler()"
        (onEscape)="onSearchEscHandler()"
      >
      </app-search>
      <div class="flex-fill"></div>
      <div *ngIf="settingsAllowed" title="Settings Apps" i18n-title (click)="toggleSettings()" class="h-100 cog pointer">
        <fa-icon [icon]="[(launchpadModel.showSettings.$|async) ? 'fas' : 'fal', 'cog']" size="2x" class="menu-icon text-white me-2 h-100"></fa-icon>
      </div>
      <app-categories-filter
        class="ms-4"
        [launchpadModel]="launchpadModel"
        (catChecked)="catCheckedHandler($event, true)"
        (catUnchecked)="catCheckedHandler($event, false)"
      ></app-categories-filter>
    </div>
    <ng-container *ngIf="(launchpadModel.apps.$|async)?.length === 0">
      <div *ngIf="launchpadModel.showSettings.$|async" class="m-2 pointer" (click)="toggleSettings()">
        <span i18n>Switch to Apps</span><fa-icon [icon]="['fal', 'th']" class="ms-1"></fa-icon>
      </div>
      <div *ngIf="!(launchpadModel.showSettings.$|async)" class="m-2 pointer" (click)="toggleSettings()">
        <span i18n>Switch to Settings</span><fa-icon [icon]="['fal', 'cog']" class="ms-1"></fa-icon>
      </div>
    </ng-container>
    <div class="apps grid-scroller">
      <div class="grid">
        <ng-container *ngFor="let app of launchpadModel.apps.$|async">
          <a [routerLink]="getAppUrlLink(app)" class="m-2" (click)="close()">
            <app-tile
              [title]="app.Title ? app.Title : '&nbsp;'"
              [description]="app.Description ? app.Description : '&nbsp;'"
              [category]="app.Taxonomy ? app.Taxonomy : '&nbsp;'"
              [imageUrl]="app.LogoImageUri"
              [iconBase64]="app.IconBase64"
              [color]="app.TaxonomyColor"
            ></app-tile>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="launchpadModel.show.$|async" id="backdrop" class="fixed-top fixed-bottom" (click)="onBackdropClick()"></div>
