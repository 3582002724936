<div class="m-2 d-flex" [class.vertical-layout]="!horizontal" [class.horizontal-layout]="horizontal"
  [style.height.rem]="fieldHeight">
  <ng-container *ngIf="uploading$|async; then uploading else normal"></ng-container>
</div>

<ng-template #uploading>
  <div [class.grid-layout]="horizontal" class="w-100">
    <div class="fa-stack multi-icon" [class.pointer]="enabled$|async" style="grid-column: 1;"
      [class.icon-block]="!horizontal" [style.position-icon-horizontal]="horizontal" [style.transform]="iconTransform">
      <fa-icon [icon]="['fas', 'file']" size="6x" class="doc-icon fa-stack-6x"></fa-icon>
      <fa-icon [icon]="['fal', 'spinner-third']" [spin]="true" size="2x" class="fa-stack-2x icon-colour"
        style="top: 2.5rem; left: 1rem">
      </fa-icon>
    </div>

    <div class="h-100" [class.mx-2]="horizontal">
      <p class="mt-1" i18n>Uploading ...</p>
    </div>
  </div>
</ng-template>

<ng-template #normal>
  <ng-container *ngIf="assetId; then assetIcon else noAssetIcon"></ng-container>
</ng-template>

<ng-template #assetIcon>
  <div [class.grid-layout]="horizontal" class="w-100" (click)="onDocClickHandler($event)">
    <div class="fa-stack pointer multi-icon" style="grid-column: 1;" [style.width.rem]="iconWidth"
      [class.icon-block]="!horizontal" [style.position-icon-horizontal]="horizontal" [style.transform]="iconTransform"
      [style.horiz-fix-widh]="horizontal" (drop)="fileDropped($event)" (dragover)="fileDraggedOver($event)"
      >
      <fa-icon *ngIf="!unknownType" [icon]="iconSpec" size="6x" class="doc-icon"></fa-icon>
      <fa-icon *ngIf="unknownType" [icon]="['fal', 'file']" size="6x" class="doc-icon fa-stack-6x"></fa-icon>
      <fa-icon *ngIf="unknownType" [icon]="['fal', 'cloud-download-alt']" size="2x" class="fa-stack-2x doc-icon"
        style="top: 2.5em; left: 1em"></fa-icon>
    </div>
    <div class="h-100" style="grid-column: 2;" [class.mx-2]="horizontal">
      <p style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" [title]="filename">
        {{filename}}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #noAssetIcon>
  <div [class.grid-layout]="horizontal">
    <div class="fa-stack multi-icon" [class.pointer]="enabled$|async" [style.transform]="iconTransform"
      [style.width.rem]="iconWidth" [matTooltip]="isDisabled() ? noDocUploadedText : null"
      (drop)="!isDisabled() ? fileDropped($event) : null" (dragover)="!isDisabled() ? fileDraggedOver($event) : null"
      (click)="!isDisabled() && onDocClickHandler($event)">
      <fa-icon *ngIf="!fieldModel.IsExpression" [icon]="['fas', 'file']" size="6x" class="doc-icon fa-stack-6x">
      </fa-icon>
      <fa-icon *ngIf="!fieldModel.IsExpression" [icon]="['fal', 'cloud-upload-alt']" size="2x"
        class="fa-stack-2x icon-colour" style="top: 2.5em; left: 1em">
      </fa-icon>
      <fa-icon *ngIf="fieldModel.IsExpression" [icon]="['fal', 'file']" size="6x" class="doc-icon fa-stack-6x">
      </fa-icon>
      <fa-icon *ngIf="forReport && isDisabled()" [icon]="['fal', 'cloud-download-alt']" size="2x"
        class="fa-stack-2x doc-icon" style="top: 2.5em; left: 1em"></fa-icon>
      <fa-icon *ngIf="isDisabled()" [icon]="['fal', 'times']" size="3x" class="fa-stack-2x"
        style="top: 2.25em; left: 1.25em; color: red">
      </fa-icon>
    </div>
    <p class="mt-1">&nbsp;</p>
  </div>
</ng-template>

<app-popup-container [isOpen]="showSelectionPopup" (closed)="selectorClosed()">
  <ng-template>

    <div class="assetDetailPopup p-4 rounded-4">
      <ng-container *ngIf="assetId; then assetPopup else noAssetPopup"> </ng-container>
    </div>
  </ng-template>
</app-popup-container>

<ng-template #noAssetPopup>
  <h4 class="caption" i18n>Upload Document</h4>
  <div class="detail">
    <p i18n>Please select a file to upload</p>
  </div>
  <ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-template>

<ng-template #assetPopup>

  <h4 class="caption">Document</h4>

  <div *ngIf="assetId" class="icon-area">
    <fa-icon [icon]="iconSpec" size="6x" class="doc-icon">
    </fa-icon>
  </div>

  <div class="detail">
    <b class="filename-label" i18n>File Name</b>
    <p class="filename" [title]="filename">{{filename}}</p>
    <b class="type-label" i18n>File Type</b>
    <ng-container *ngTemplateOutlet="mimeText"></ng-container>
    <b class="size-label" i18n>File Size</b>
    <span class="size">{{size}}</span>
  </div>

  <ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-template>

<ng-template #buttons>

  <input type="file" accept="*/*" hidden #imageFileSelector (change)="onFileSelectedHandler($event)" />
  <ng-container *ngIf="!deleteMode; else confirmDeleteTemplate">
    <div class="button1-area">
      <button type="button" title="Browse for file to upload" i18n-title class="primary" [disabled]="!(enabled$|async)"
        (click)="imageFileSelector.click()">
        <fa-icon [icon]="['fal', 'cloud-upload-alt']" [border]="false" size="lg"></fa-icon>
        <span i18n>Upload</span>
      </button>
    </div>

    <div *ngIf="assetId" class="button2-area">
      <button type="button" class="primary" title="Download" i18n-title (click)="downloadClicked($event)">
        <fa-icon [icon]="['fal', 'cloud-download-alt']" [border]="false" size="lg"></fa-icon>
        <span i18n>Download</span>
      </button>
    </div>

    <div *ngIf="assetId && (enabled$|async)" class="button3-area">
      <button type="button" class="primary" title="Delete file" i18n-title [disabled]="!(enabled$|async)"
        (click)="deleteMode = !deleteMode">
        <fa-icon [icon]="['fal', 'trash']" [border]="false" size="lg"></fa-icon>
        <span i18n>Delete</span>
      </button>
    </div>

    <div [ngClass]="{ 'button4-area': assetId, 'button2-area': !assetId }">
      <button type="button" class="close" title="Close" i18n-title (click)="selectorClosed($event)">
        <fa-icon [icon]="['fal', 'times']" [border]="false" size="lg"></fa-icon>
        <span i18n>Close</span>
      </button>
    </div>
  </ng-container>

  <ng-template #confirmDeleteTemplate>
    <div *ngIf="assetId && (enabled$|async)" class="button2-area">
      <button type="button" class="primary" style="background-color: red!important;" title="Delete file" i18n-title [disabled]="!(enabled$|async)"
        (click)="trashClicked(); $event.stopPropagation()">
        <fa-icon [icon]="['fal', 'check']" [border]="false" size="lg"></fa-icon>
        <span i18n>Delete</span>
      </button>
    </div>

    <div class="button3-area">
      <button type="button" class="primary" style="background-color: green!important;" title="Cancel delete" i18n-title [disabled]="!(enabled$|async)"
        (click)="deleteMode = !deleteMode">
        <fa-icon [icon]="['fal', 'times']" [border]="false" size="lg"></fa-icon>
        <span i18n>Cancel</span>
      </button>
    </div>

    <div class="button4-area">
      <button type="button" class="close" title="Close" i18n-title (click)="selectorClosed($event)">
        <fa-icon [icon]="['fal', 'times']" [border]="false" size="lg"></fa-icon>
        <span i18n>Close</span>
      </button>
    </div>
  </ng-template>



</ng-template>

<ng-template #mimeText>
  <span *ngIf="mimeType === 'application/msword'" class="type" i18n>Word Document</span>
  <span *ngIf="mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="type" i18n>
    Word Document (OpenXML)
  </span>
  <span *ngIf="mimeType === 'application/pdf'" class="type" i18n>PDF Document</span>
  <span *ngIf="mimeType === 'text/csv'" class="type" i18n>Comma Separated Data</span>
  <span *ngIf="mimeType === 'image/png'" class="type" i18n>PNG (Portable Network Graphics) Image</span>
  <span *ngIf="mimeType === 'image/jpeg'" class="type" i18n>JPEG Image</span>
  <span *ngIf="mimeType === 'text/plain'" class="type" i18n>Text</span>
  <span *ngIf="mimeType === 'application/vnd.ms-excel'" class="type" i18n>Excel Spreadsheet</span>
  <span *ngIf="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="type" i18n>Excel
    Spreadsheet (OpenXML)</span>
  <span *ngIf="mimeType === 'application/vnd.ms-powerpoint'" class="type" i18n>PowerPoint Presentation</span>
  <span *ngIf="mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'" class="type" i18n>
    PowerPoint Presentation  (OpenXML)
  </span>
  <span *ngIf="mimeType === 'text/xml'" class="type" i18n>XML</span>
  <span *ngIf="mimeType === 'application/xml'" class="type" i18n>XML</span>
  <span *ngIf="mimeType === 'application/zip'" class="type" i18n>ZIP Archive</span>
  <span *ngIf="mimeType === 'video/mpeg'" class="type" i18n>MPEG Video</span>
  <span *ngIf="mimeType === 'video/ogg'" class="type" i18n>OGG video</span>
  <span *ngIf="mimeType === 'video/mp2t'" class="type" i18n>MPEG transport stream</span>
  <span *ngIf="mimeType === 'video/webm'" class="type" i18n>WEBM Video</span>
  <span *ngIf="unknownType" class="type" i18n>Unknown</span>
</ng-template>
