<ng-container *ngIf="model; else noModelTemplate">

  <div *ngIf="!model" i18n>Matrix Model not set</div>
  <div class="table-responsive">
    <table class="table w-100 table-bordered" *ngIf="model">
    <thead>
      <tr>
        <th class="text-nowrap text-truncate ">&nbsp;</th>
        <th class="text-nowrap text-truncate st-text-theme-primary" *ngFor="let columnModel of model.columns">
          <div class="w-100 h-100 d-flex justify-content-center px-2">
            <sof-matrix-cell-field
              [value]="model.columnHeaderValue(columnModel)"
              [fieldModel]="model.xAxisField"
              [forReport]="true"
            >
            </sof-matrix-cell-field>
          </div>
        </th>
        <th *ngIf="model.showOverallTotalColumn" class="st-text-theme-primary d-flex justify-content-center" i18n>Overall Total</th>
        <th *ngIf="model.showRowTotalColumn" class="st-text-theme-primary d-flex justify-content-center" i18n>Total</th>
        <th *ngIf="model.showRowCountTotalColumn" class="st-text-theme-primary d-flex justify-content-center" i18n>Total Count</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of model.rows; let r=index">
        <th scope="row" class="text-truncate st-text-theme-primary px-2" >
          <sof-matrix-cell-field *ngIf="model.yAxisField; else rowValue"
            [value]="model.rowHeaderValue(row)"
            [fieldModel]="model.yAxisField"
            [forReport]="true"
          >
          </sof-matrix-cell-field>
          <ng-template #rowValue>
            <div *ngIf="!model.yAxisField" >
              {{model.rowHeaderValue(row)}}
            </div>
          </ng-template>
        </th>
        <td class="text-truncate" *ngFor="let cell of model.rowCells(r); let i=index"
          [ngClass]="model.cellClass(cell)"
          >
          <ng-container *ngIf="cell && model.showCountInCells">
            <div class="d-flex justify-content-center"
              [ngClass]="{ 'pointer': clickThroughReportIdentifier !== null }"
              (click)="clickThroughTotal(cell)">
              {{cell.count}}
            </div>
          </ng-container>
          <ng-container *ngIf="cell && !model.showCountInCells">
            <div *ngFor="let ref of cell.references"
              class="d-flex"
              [ngClass]="{ 'pointer': clickThroughReportIdentifier !== null }"
              (click)="clickThroughReportIdentifier && clickThrough(ref)">
              <sof-matrix-cell-field *ngIf="model.detailsField; else countCell"
                [value]="ref.value"
                [fieldModel]="model.detailsField"
                [forReport]="true"
              >
              </sof-matrix-cell-field>
              <ng-template #countCell>
                <div>{{ref.value}}</div>
              </ng-template>
            </div>
          </ng-container>
        </td>
        <td *ngIf="model.showOverallTotalColumn"></td>
        <td *ngIf="model.showRowTotalColumn || model.showRowCountTotalColumn"
              [ngClass]="{ 'pointer': clickThroughReportIdentifier !== null && !isCumulative }"
              (click)="clickthroughRowTotal(row)">
          <div class="d-flex justify-content-center">{{model.rowTotal(r)}}</div>
        </td>
      </tr>
      <tr *ngIf="model.showTotalsRow">
        <th scope="row" class="st-text-theme-primary d-flex justify-content-end">
          <div *ngIf="model.showOverallTotalRow" i18n>Overall Total</div>
          <div *ngIf="model.showColumnTotalRow" i18n="@@totalHeading">Total</div>
          <div *ngIf="model.showColumnCountTotalRow"
            i18n="Heading when total displays total of counts@@totalCountHeading">Total Count</div>
        </th>
        <td *ngFor="let total of model.columnTotals; let i=index">
          <div class="d-flex justify-content-center"
            [ngClass]="{ 'pointer': clickThroughReportIdentifier !== null }"
            (click)="clickthroughColumnTotal(model.columns[i])">
              {{total}}
          </div>
        </td>
        <td><div class="d-flex justify-content-center">{{model.grandTotal}}</div></td>
      </tr>
    </tbody>
    </table>
  </div>
</ng-container>

<ng-template #noModelTemplate>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <h2>
      <sof-spinner-icon class="st-text-theme-primary"></sof-spinner-icon>
      <span class="text-muted ms-2" i18n>Entering the Matrix...</span>
    </h2>
  </div>
</ng-template>
