import { Injectable } from '@angular/core';

import { NotesRepository } from '../repos/repositories.notesrepository';

@Injectable({ providedIn: 'root' })
export class NotesService {

  constructor(
    public notesRepository: NotesRepository
  ) { }

  public async getNotes(appIdentifier: string, recordId: string, fieldId: string): Promise<any> {
    return await this.notesRepository.getData(appIdentifier, recordId, fieldId).toPromise();
  }

  public async patchNotes(appIdentifier: string, recordId: string, fieldId: string, changes: {}) {
    return await this.notesRepository.patchData(appIdentifier, recordId, fieldId, changes).toPromise();
  }

  public createPatchChanges(_fieldIdentifier: string, notesText: string) {
    // NB unlike nprmal patch payloads, the notes patch is always called 'Notes' in the current API
    return { Notes: { 'added': [{ 'Id': null, 'Text': notesText, 'IsPersisted': false }], 'changes': {}, 'removed': [] } };
  }
}
