<div [vxWatch]="model"  class="d-flex justify-content-around mt-2">
  <div class="multistate-container" *ngFor="let state of model.states.value"
    [class.selected]="isSelected(state)" >
    <div  
      class='multistate' 
      [ngClass]="state.classes"
      [class.pointer]="enabled$|async"
      (click)="clicked($event, state)"
    >
    </div>
  </div>
</div>
