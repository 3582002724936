<div class="position-fixed w-100 h-100 d-flex flex-column text-center bg-white p-4"
  style="padding-top: 10% !important; top: 0; overflow: auto;">
  <h1 *ngIf="!hasPendingChanges" class="display-4" i18n>
    Logging out
  </h1>
  <h1 *ngIf="hasPendingChanges" class="display-4" i18n>
    Syncing changes before logging out
  </h1>
  <p *ngIf="hasPendingChanges" class="font-weight-bold" i18n>
    Below are the Apps and the records which need completing before you can logout. <br />
    Click on each App (or record) to correct any data validation issues before logging out <br />
  </p>
  <p>
  </p>
  <ng-container *ngIf="hasPendingChanges">
    <div id="recordList" style="min-height: 100px">
      <div *ngFor="let appIdentifier of changedRecords.keys()">
        <a class="header" [routerLink]="navigationService.getAppHomeUrl(appIdentifier)">{{ changesInApps.get(appIdentifier) }}</a><br />
        <ul class="centered-list">
          <li *ngFor="let recordId of changedRecords.get(appIdentifier)">
            <a [routerLink]="navigationService.getRecordUrl(appIdentifier,recordId)">{{ recordId }}</a><br />
          </li>
        </ul>
      </div>
    </div>
    <p class="font-weight-bold" i18n>
      Alternatively, click the button below to remove any unsaved data and log out<br />
    </p>
    <button style="min-height: 3rem;" class="primary align-self-center fit-content"
      (click)="clearPatches()">Clear unsaved data</button>
    <div *ngIf="hasPendingChanges" class="font-weight-bold mt-4" i18n>
      For more information about this process, please click
      <a href="https://support.softools.net/hc/en-gb/articles/360011348477-Logging-Out" target="_blank">here</a>
      for help. <br />
      You will not be allowed to log out (or view the above help article) if you do not have an internet connection
    </div>
  </ng-container>
  <p i18n>
    This could take a few minutes!
  </p>
  <p>
    <img src="assets/icons/softoolsF.gif" style="width: 2.6rem;" />
  </p>
</div>
