import { Failure, ValidationError } from '@softools/softools-core';
import { validEmailExpression } from 'app/softoolsui.module/validation/patterns';
import { TextualAppField } from './textual-app-field';

export class EmailAppField extends TextualAppField {

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    super.validate(value, errors, listRow);
    if (!this._isValidEmail(value)) {
      errors.push({ error: ValidationError.InvalidEmail, fieldId: this.Identifier, listRow });
    }
  }

  private _isValidEmail(value: string): boolean {
    if (value && !validEmailExpression.test(value)) {
      return false;
    }
    return true;
  }

}
