import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild } from '@angular/core';
import { EditableFieldBase } from 'app/softoolsui.module/fields';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-email-field',
  templateUrl: './email-field.component.html',
  styleUrls: ['./email-field.component.scss', '../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailFieldComponent extends EditableFieldBase<string> implements AfterViewInit {

  @ViewChild(InputComponent, { static: false }) public inputComponent: InputComponent;

  private activeWhenReady = false;

  public get emailUrl(): string {
    const value = this.fieldValue;
    return value ? `mailto:${value}` : null;
  }

  public override async activate() {
    this.activeWhenReady = true;
  }

  public override async deactivate() {
    await this.inputComponent?.updateCurrentValue();
  }

  public ngAfterViewInit() {
    if (this.activeWhenReady) {
      this.activeWhenReady = false;
      this.inputComponent?.activate();
    }
  }
}
