import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SiteStorageService, SiteGlobalBasicDataResource, logError } from '@softools/softools-core';
import * as Color from 'color';


@Injectable()
export class ThemeGuard implements CanActivate {

  constructor(private siteService: SiteStorageService) { }

  async canActivate(): Promise<boolean> {
    // Check for user as ThemeGuard canActivate hit on logout, we'd be unauthorised at this point
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      try {
        const siteGlobalBasicData: SiteGlobalBasicDataResource = await this.siteService.getSiteGlobalBasicDataAsync();

        const base = (/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(siteGlobalBasicData.DefaultTheme)) ? siteGlobalBasicData.DefaultTheme : '#4a7090';

        const primary = new Color(base, 'rgb');

        const lum = primary.luminosity();
        const fadeRatio = 0.4 * (Math.acos(lum) / (Math.PI / 2));
        const faded = primary.desaturate(0.2).lighten(fadeRatio);
        const extraFaded = faded.lighten(0.5);

        const primaryContrast = primary.isDark() ? 'white' : 'black';
        const fadedContrast = faded.isDark() ? 'white' : 'black';
        const extraFadedContrast = extraFaded.isDark() ? 'white' : 'black';

        document.documentElement.style.setProperty('--bs-primary', base);
        document.documentElement.style.setProperty('--faded', faded.hex());
        document.documentElement.style.setProperty('--extra-faded', extraFaded.hex());
        document.documentElement.style.setProperty('--primary-contrast', primaryContrast);
        document.documentElement.style.setProperty('--faded-contrast', fadedContrast);
        document.documentElement.style.setProperty('--extra-faded-contrast', extraFadedContrast);

        // // hack for temp theming
        // const primary = localStorage.getItem('--primary');
        // if (primary) {
        //   document.documentElement.style.setProperty('--primary', primary);
        // }

      } catch (error) {
        logError(error, '');
      }
    }
    return true;
  }
}
