import { Injectable } from '@angular/core';
import { OnlineStatusService, SiteStorageService } from '@softools/softools-core';

import { logError, SiteLoginDataResource, tryGetCurrentUser } from '@softools/softools-core';
import { LOGIN_LANGUAGE } from 'app/_constants/constants.keys';
import { environment } from 'environments/environment';

import Auth0Lock from 'auth0-lock';
import * as auth0_fr from 'app/auth.module/components/login/fr';
import * as auth0_de from 'app/auth.module/components/login/de';
import * as auth0_es from 'app/auth.module/components/login/es';
import * as auth0_ja from 'app/auth.module/components/login/ja';
import * as auth0_tr from 'app/auth.module/components/login/tr';
import { HttpClient } from '@angular/common/http';
import { GlobalModelService } from 'app/mvc/common/global-model.service';
import { AuthorisationService } from 'app/auth.module/services/authorisation.service';
import { TenantService } from './tenant.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReLoginService {
  constructor(private siteService: SiteStorageService, private http: HttpClient,
    private onlineStatusService: OnlineStatusService,
    private models: GlobalModelService,
    private authorisationService: AuthorisationService,
    private tenantService: TenantService
  ) { }

  private auth0Lock: Auth0Lock;

  public offline: boolean;
  public loginEnabled = true;

  public loginShowing = false;

  public async authorise(tenant: string, returnUrl: string) {
    if (!this.loginShowing && tryGetCurrentUser()) {
      this.loginShowing = true;
      // We always want to get latest SiteLoginDataResource here.  SiteResyncGuard will then take care of subsequent site version updates for settings etc.
      // Error 400 even though value is returned?
      const site = await this.siteService.updateSiteLoginDataResourceAsync(tenant);

      const loginEnabled = site.Auth0ClientId && site.Auth0ClientId.length > 0;
      if (!loginEnabled) {
        return;
      }

      const auth0Domain = await this.siteService.getAuth0Domain(tenant);
      const options = this.getLockOptions(site, returnUrl);

      if (!this.auth0Lock) {
        this.auth0Lock = new Auth0Lock(site.Auth0ClientId, auth0Domain, options);
      }

      if (!this.authorisationService.isValidAuthToken()) {
        try {
          if (await this.refresh()) {
            this.loginShowing = false;
            this.auth0Lock.hide();
          } else {
            this.loginShowing = true;
            this.auth0Lock.show();
          }
        } catch (ex) {
          // would throw if the token has been compromised
          logError(ex, 'Failed to get a refresh token');
          this.auth0Lock.show();
        }
      }
    }
  }

  public async refresh(): Promise<boolean> {
    // if we have a fresh token then try and get a new access token.
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      const tenant = this.tenantService.tenant();
      const refreshTokenResponse: any = await firstValueFrom(
        await this.http.post(`${environment.baseUrl}/api/auth0/refresh`, {
          RefreshToken: refreshToken,
          SiteIdentifier: tenant,
        }));
      this.models.globalModel.setAccessTokens(refreshTokenResponse.expires_in, refreshTokenResponse.access_token, refreshTokenResponse.refresh_token);
      this.onlineStatusService.isServerReachable = true;
      this.models.globalModel.authenticated.value = true;
      return true;
    }

    return false;
  }

  private getLockOptions(site: SiteLoginDataResource, returnUrl: string): any {

    const language = this.siteLanguage();

    const protocalAndHost = `${window.location.protocol}//${window.location.hostname}`;

    let redirectUrl = window.location.port
      ? `${protocalAndHost}:${window.location.port}/Account/Callback`
      : `${protocalAndHost}/Account/Callback`;
    if (returnUrl && returnUrl !== '/') {
      redirectUrl += `?returnUrl=${encodeURIComponent(returnUrl)}`;
    }
    const options = {
      closable: false,
      allowAutocomplete: false,
      configurationBaseUrl: `${environment.auth0Cdn}`,
      loginAfterSignUp: true,
      language: language,
      languageDictionary: {
        title: '',
      },
      auth: {
        redirectUrl: redirectUrl,
        responseType: 'code',
        params: {
          scope: 'openid email profile offline_access', // Learn about scopes: https://auth0.com/docs/scopes
        },
        audience: environment.apiAudiance,
      },
      allowSignUp: false,
      theme: {
        logo: site.ImageURI && site.ImageURI.startsWith('/Asset/') ? `${environment.baseUrl}${site.ImageURI}` : site.ImageURI,
        primaryColor: `${site.DefaultTheme}`,
      },
    };

    switch (options.language) {
      case 'fr':
        (<any>options).dict = auth0_fr;
        break;
      case 'de':
        (<any>options).dict = auth0_de;
        break;
      case 'ja':
        (<any>options).dict = auth0_ja;
        break;
      case 'es':
        (<any>options).dict = auth0_es;
        break;
      case 'tr':
        (<any>options).dict = auth0_tr;
        break;
    }

    return options;
  }

  public siteLanguage() {
    const language = window?.navigator?.language ?? localStorage.getItem(LOGIN_LANGUAGE);

    if (language) {
      if (language.toLowerCase().indexOf('fr') >= 0) {
        return 'fr';
      } else if (language.toLowerCase().indexOf('de') >= 0) {
        return 'de';
      } else if (language.toLowerCase().indexOf('ja') >= 0) {
        return 'ja';
      } else if (language.toLowerCase().indexOf('es') >= 0) {
        return 'es';
      } else if (language.toLowerCase().indexOf('tr') >= 0) {
        return 'tr';
      }
    }
    return 'en';
  }

  public loginClosed() {
    this.loginShowing = false;
  }
}
