import { Record, ILookupValue } from '@softools/softools-core';
import { Application } from 'app/types/application';


export class ApplicationValueLookup implements ILookupValue {
  constructor(private app: Application) { }
  getValue(name: string, values: any, listRow?: number) {
    const field = this.app.getField(name);
    if (field) {
      return field.getRecordValue(values as Record, listRow);
    } else {
      return values[name];
    }
  }
}
