import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexPaginatorComponent } from './index-paginator.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PaginationModule } from '../pagination/pagination.module';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';
import { SofSpinnerModule } from 'app/design/icons';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    PaginationModule,
    MvcDirectivesModule,
    SofSpinnerModule
  ],
  declarations: [IndexPaginatorComponent],
  exports: [
    IndexPaginatorComponent,
  ]
})
export class IndexPaginatorModule { }
