import { Enums, LookupFieldMapping, ImagaFieldButtonMode, Field, LogicBlockIdentifer, IFormatting, Style, IActionButtonConfig } from '@softools/softools-core';
import { AlignmentTypeAlias, GridDataSet } from '@softools/softools-core';

/**
 * Base for fields - provides concrete implementation of Field
 */
export class AppFieldBase implements Field {

  AutoNumber: boolean;
  AutoGenerated: boolean;
  CreatedByUserId?: number;
  CreatedDate?: string;

  /** If true field is suitable for sort, group, etc.*/
  Sortable?: boolean;

  DefaultSortByIdentifier?: string;
  DefaultSortOrder?: string;

  DefaultValue?: string;
  DefaultValueIsExpression?: boolean;

  /** Optional user-supplied field description  */
  Description?: string;

  /**  If true the a formatted value should be displayed instead.
   * Conventionally this is contained in the record using an id
   * with a _Formatted suffix.  Sometimes this may be used
   * for other purposes so only fields that have DisplayFormatted true
   * should use the formatted value.
   *
   * For fields in a grid this flag is set on the subfield entry, not
   * each individual cell field.
   */
  DisplayFormatted: boolean;

  ExcludeFromTemplateCopy: boolean;
  Expression?: string;

  /** Identifies the field.
   * While this should be unique within a scope, the same id can appear
   *  more than once if they have different @see ParentFieldIdentifier values
   */
  Identifier: string;

  IncludeInSearch: boolean;
  // IsAToZField: boolean;
  IsBackingField?: boolean;
  IsEditable: boolean;
  IsImmutable?: boolean;
  IsStaticField?: boolean;
  IsReadOnly: boolean;
  IsTitleField?: boolean;
  IsUserIdentifier?: boolean;

  /** If true, field cannot be filtered, sorted or grouped.
   * This is primarily for settings apps that show synthetic fields that the server APIs don't understand
   * in query expressions.  No reason to expose for standard apps as they always have valid fields.
   */
  Unfilterable?: boolean;

  Label: string;
  ModifiedByUserId?: number;
  ModifiedDate?: string;
  ParentFieldIdentifier?: string;
  Required?: boolean;
  SelectListIdentifier?: string;
  SelectListType?: Enums.SelectListType;
  SelectListSubType?: number;

  /** If true select list options are taken from a record field named by SelectListIdentifier */
  UseRecordSelectListOptions?: boolean;
  ShowCalendarEvent?: boolean;
  SubType?: number;
  SystemLabel: string;
  Type: number;
  InAppChartReportIdentifier?: string;
  ImageListIdentifier?: string;
  ImageListSize?: number;
  GridDataSets?: Array<GridDataSet>;
  SubFieldsIdentifiers?: Array<{
    Identifier: string;
  }>;
  Orientation?: number;
  HeaderText?: string;
  HideGridRowTitle?: boolean;
  /** Comma separated list of formats */
  BarcodeFormats?: string;
  BarcodeValidCode?: string;
  BarcodeValidCodeRegex?: string;
  /** Display order for grid subfields */
  GridDisplayOrder?: number;

  /** App id of app for lookup field */
  LookupFieldApp?: string;
  LookupFieldMappings?: Array<LookupFieldMapping>;
  LookupSearchFieldIdentifier?: string;

  ImageActionButtonSize?: Enums.ImageActionButtonSize;
  ImageActionButtonImageUri?: string;
  ImageActionButtonType?: Enums.ImageActionButtonType;
  ImageActionButtonUrl?: string;
  ImageActionButtonUrlNewTab?: boolean;
  ImageActionButtonChildAppIdentifier?: string;
  ImageActionButtonTriggerWorkflowId?: number;

  /** Additional action button config - currently just for record copy */
  ImageActionButtonConfig?: IActionButtonConfig;

  /** If set the field is hidden unless the specified record value is truthy.
   * This is currently not supported by AppStudio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  ShowWhenValueTrue?: string;
  /** If set, specifies a number of items in a field (e.g. a selection list) before it
   * alters to a more compact display format.  If not set, a field specific default is used.
   * This is currently not supported by AppStudio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  CompactItemsThreshold?: number;
  /** If set and true, the field label text is displayed styled as a header
   * This is currently not supported by AppStudio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  LabelAsHeader?: boolean;
  /** If set, specifies additional prompt text to be displayed with the field.
   * Not all field types support this.
   * This is currently not supported by AppStudio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  SubText?: string;
  /** If set on a list field, sets whether the row delete function is on or off.
   * If not specified, delete is enabled when the field is editable.
   * This is currently not supported by AppStudio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  DeleteRowEnabled?: boolean;
  /** If set on a list field, sets whether the add row  function is on or off.
   * If not specified, add is enabled when the field is editable.
   * This is currently not supported by AppStudio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  AddRowEnabled?: boolean;
  /** If set on a list field, it will use pagination. Not in AS yet
   * So far only tested with read-only fields.
   * This is currently not suppoerted by app studio or zero code apps, but may
   * be set programatically in embedded applets.
   */
  ListFieldPagination?: boolean;
  /**
   * If set Min/Max value for range and number fields
   */
  MinValue?: number;
  MaxValue?: number;
  /**
   * If set this is the number of rows for a long text field
   */
  Rows?: number;
  /** If set this is the max length of a text field type */
  Length?: number;
  /** If set this is the min length of a text field type */
  MinLength?: number;

  /** Optional configuration of a list field; Used to expand the list field columns, 1fr or auto should be set on CSS grid. 1fr is used when set to true */
  IsAutoExpandable?: boolean;

  /** Optional configuration; Used to set the width of a column on a list field CSS grid */
  ColWidth?: boolean;

  /** Optional configuration of a list field; always undefined for other field types */
  ListFieldParameters?: {
    RowKey?: string;

    RowKeyIsNumeric?: boolean;

    /** If defined, a logic block that is run when a row is added to the list field */
    AddRowLogicBlock?: LogicBlockIdentifer;
  };

  /** Alignment string; left/ center/ right. No value will be default alignment. */
  Alignment?: AlignmentTypeAlias;

  /** Optional list of styles to apply to the field  */
  NamedStyles?: Array<{ StyleName: string }>;

  /**
   * Optional Additional style  to apply to the field
   */
  Style?: Style;

  DisplayOptions?: {
    ShowAsIcon?: boolean;
    Icon?: string;
    LabelPosition?: string;
  };

  /** If defined, the field is only local to a device, and is not persisted to the server */
  Local?: {
    /** If true, the field should be persisted locally on the device  */
    Persist?: boolean;

    /** If defined and the field supports lazy loading, this logic block is used to get the field value when needed */
    LazyGetValueLogicBlock?: LogicBlockIdentifer;
  };

  Button?: {
    FormIdentifier?: string;
  }

  FreehandDrawingCanvasSize: string;

  Width: string;

  /** Optional additional parameters for text fields */
  TextFieldOptions?: {
    /** If set field is a password field */
    PasswordField?: boolean;

    /** If set, specifies the autocomplete mode for the field.  If not specified, 'off' is used */
    AutoCompleteType?: string;
  };

  /** Logic blocks to execute for the field */
  LogicBlocks?: {
    /** Default action for field e.g. button pressed */
    Default?: LogicBlockIdentifer;
  };

  /** Optional additional parameters for image fields */
  ImageOptions?: {
    buttonMode?: ImagaFieldButtonMode;
    inlineWidth?: number;
    formWidth?: number;
  };

  /** Optional additional parameters for time fields */
  TimeOptions?: {
    /** Default is 24 hour clock, set to display 12 hours with AM/PM */
    use12hourClock?: boolean;
  };

  ReferenceOptions: {
    TargetAppIdentifier: string;

    /** Table Report for lookup */
    ReportIdentifier: string;
  };

  AggregateConfig?: {
    ReferenceFieldIdentifier?: string;
    SummaryExpression: string;

    // todo probably not same as generated by AS, and format different
    Filter?: string;
  };

  /**
   * Optional additional parameters for lookup fields.
   * NB Some lookup specific properties are defined at the top level
   */
  Lookup?: {

    /**
     * Report to use for selectinb the lookup record.  This must be
     * a table or list report in the target app specified by LookupFieldApp.
     */
    ReportIdentifier?: string;
  };

  /** Person/PersonByTeam field options */
  Person?: {

    /** Hint to use reference fields for implementation */
    UseReferences?: boolean;
  };

  /**
   * Formatting information.  If present the field is displayed
   * using this formatting information.  The exact interpretations
   * depend on the field type and it's state.
   */
  Formatting?: IFormatting;

  /**
   * Display date/time as local date/time
   */
  DisplayAsLocal?: boolean;

  /**
   * If true then image field data will be synced to work offline.
   */
  AvailableOffline?: boolean;

  /**  Optional fixed regex pattern string */
  Pattern?: string;

  /** If true, field is disabled until clicked on */
  ClickToEdit?: boolean;
}
