import { combineLatest, distinctUntilChanged } from 'rxjs';
import { AppDataRepository, AppDataStorageService, BackgroundProcessStorageService, Enums, initialModalComponentContentData, ModalComponentContentData, Record, SyncParameters, TemplatedReport, TemplatedReportsRepository, tryGetCurrentUser } from '@softools/softools-core';
import { MvcBase } from '@softools/vertex';
import { AppModel } from '../app.model';
import { GlobalModel } from '../global.model';
import { ToolbarAction } from 'app/softoolscore.module/types/classes';
import { ToolbarContext } from 'app/types/application';
import { AppZone, StorageMode } from 'app/types/enums';
import { IGeneralController } from '../common/general-controller.interface';
import { RecordCopyComponent, RecordCopyParameters } from 'app/workspace.module/components/ws-record/record-copy/record-copy.component';
import { InjectService } from 'app/services/locator.service';
import { MessageType } from 'app/softoolsui.module/message-dialog/message-dialog.component';
import { OverlayService } from 'app/workspace.module/services/overlay.service';
import { ExportComponent, ExportComponentConfig } from 'app/softoolsui.module/export.component';
import { NavigationService } from 'app/services/navigation.service';
import { UsersService } from 'app/services/users.service';
import { TeamsService } from 'app/services/teams.service';
import { SecurityComponent, SecurityComponentConfig } from 'app/softoolsui.module/security.component/security.component';
import { HistoryComponent } from 'app/softoolsui.module/history.component/history.component';
import { AppService } from 'app/services/app.service';
import { DataSyncService } from 'app/workspace.module/services/data-sync.service';
import { StorageModeService } from 'app/services/storage-mode.service';
import { PageModel } from '../page/page.model';
import { RecordUpdateModel } from './record-update.model';

/**
 * Controller for record update pages
 */
export class RecordUpdateController extends MvcBase implements IGeneralController {

  public globalModel: GlobalModel;

  public appModel: AppModel;

  @InjectService(AppDataRepository)
  private readonly appDataRepository: AppDataRepository;

  @InjectService(AppDataStorageService)
  private readonly appDataService: AppDataStorageService;

  @InjectService(OverlayService)
  private readonly overlayService: OverlayService;

  @InjectService(TemplatedReportsRepository)
  private templatedReportsRepository: TemplatedReportsRepository;

  @InjectService(BackgroundProcessStorageService)
  private backgroundProcessStorageService: BackgroundProcessStorageService;

  @InjectService(NavigationService)
  private readonly navigationService: NavigationService;

  @InjectService(UsersService)
  private usersService: UsersService;

  @InjectService(TeamsService)
  private readonly teamsService: TeamsService;

  @InjectService(AppService)
  private readonly appService: AppService;

  @InjectService(DataSyncService)
  private readonly dataSyncService: DataSyncService;

  @InjectService(StorageModeService)
  private readonly storageModeService: StorageModeService;

  constructor(private recordModel: RecordUpdateModel, public pageModel: PageModel) {
    super();

    this.appModel = recordModel.appModel;
    this.globalModel = recordModel.appModel.globalModel;
  }

  /** Initialise the controller.  Call after construction */
  public initialise() {
    this.observeProperties();
  }

  protected observeProperties() {
    // Rebuild action menu when the record changes or any other state
    // that would affect it
    this.subscribe(combineLatest([
      this.recordModel.record.$,
      this.globalModel.archived.$.pipe(distinctUntilChanged()),
    ]), ([record, archived]) => {
      this.setToolbarActions(record, archived);
    });
  }

  private setToolbarActions(record: Record, archived: boolean) {
    const app = this.appModel.app.value;
    if (app && record) {
      const isChildApp = record.Hierarchy?.length > 0;
      const actions = [] as Array<ToolbarAction>;

      const context: ToolbarContext = {
        app,
        zone: isChildApp ? AppZone.childrecordupdate : AppZone.recordupdate,
        record,
        isChildApp,
        isArchived: archived,
        recordUpdateController: this,
        generalController: this
      };

      app.getRecordToolbarActionModel(this.recordModel, actions, context);

      this.recordModel.toolbarActions.value = actions;
    }
  }

  /** Archive the current record */
  public async archive() {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      const accepted = await this.globalModel.showMessageDialogAsync({
        Type: MessageType.ConfirmArchive,
        Application: this.appModel.app.value,
        Count: 1
      });

      if (accepted) {
        await this.appDataRepository.archive(app.Identifier, rec._id).toPromise();
        const record = await this.appDataService.getApiRecordByIdAsync(app, rec._id);
        // todo should update storage when in offline mode
        this.recordModel.recordModified(record);
      }
    } catch (error) {
      this.globalModel.showErrorToasty({
        title: '',
        message: error.Message
      });
    }
  }

  public async unarchive(): Promise<void> {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      const accepted = await this.globalModel.showMessageDialogAsync({
        Type: MessageType.ConfirmUnarchive,
        Application: this.appModel.app.value,
        Count: 1
      });

      if (accepted) {
        await this.appDataRepository.unarchive(app.Identifier, rec._id).toPromise();
        const record = await this.appDataService.getApiRecordByIdAsync(app, rec._id);
        // todo should update storage when in offline mode
        this.recordModel.recordModified(record);
      }
    } catch (error) {
      this.globalModel.showErrorToasty({
        title: '',
        message: error.Message
      });
    }
  }

  public async delete(): Promise<void> {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      const accepted = await this.globalModel.showMessageDialogAsync({
        Type: MessageType.ConfirmDeleteAppData,
        Application: this.appModel.app.value,
        Count: 1
      });


      if (accepted) {
        await this.appDataRepository.deleteArchived(app.Identifier, rec._id).toPromise();

        // navigate out as ths record is gone
        const url = this.navigationService.getDefaultReportUrl(app.Identifier);
        await this.globalModel.navigation.navigateUrlAsync({ url });

        this.globalModel.showSuccessToasty({
          title: '',
          message: $localize`Record deleted`
        });
      }
    } catch (error) {
      this.globalModel.showErrorToasty({
        title: '',
        message: error.Message
      });
    }
  }

  /** Get record copy parameters then perform copy */
  public async copy() {
    const params: RecordCopyParameters = {
      appModel: this.appModel,
      id: this.recordModel.recordId.value,
      hierarchy: this.recordModel.hierarchy.value,
      appIdentifier: this.appModel.app.value.Identifier,
      appIdentifiers: this.appModel.appIdentifiers.value
    };

    await this.globalModel.dialogAsync(RecordCopyComponent, params);
  }

  public async export(): Promise<void> {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      const exportTypes: Array<Enums.ExportType> = [Enums.ExportType.csv];

      this.overlayService.openSpinner();
      const templatedReports: Array<TemplatedReport> = await this.templatedReportsRepository.getRecordTemplatedReports(app.Identifier).toPromise();
      this.overlayService.close();

      if (templatedReports.length > 0) {
        exportTypes.push(Enums.ExportType.templatedReportExport);
        exportTypes.push(Enums.ExportType.templatedReportExportAsPdf);
      }

      const result = await ExportComponent.openAsync(this.globalModel,
        new ExportComponentConfig(exportTypes, templatedReports));

      if (result?.selectedExportType) {
        let response = null;

        switch (result.selectedExportType) {
          case Enums.ExportType.csv:
            const excludedColumns = result.excludeReadOnlyFields
              ? this.appModel.appContext.app.value.AppFields
                .filter(f => f?.IsReadOnly)
                .map(f => f.Identifier)
              : [];
            response = await this.appDataRepository.exportRecordLevel(app.Identifier, rec._id, excludedColumns, result.excludeReadOnlyFields).toPromise();
            break;

          case Enums.ExportType.templatedReportExport:
          case Enums.ExportType.templatedReportExportAsPdf:
            const asPdf = result.selectedExportType === Enums.ExportType.templatedReportExportAsPdf;
            const templatedReport = templatedReports.filter(r => r.Id === result.selectedTemplatedReportId)[0];
            response = await this.templatedReportsRepository.exportRecordLevel(app.Identifier, templatedReport.Id, templatedReport.Title, templatedReport.IsSystemDefaultReport, rec._id, asPdf).toPromise();
            break;
        }

        if (response?.ProcessId && result.filename?.length > 0) {
          await this.backgroundProcessStorageService.save(response.ProcessId, { filename: result.filename });
        }
      }
    } catch (error) {
      this.globalModel.showErrorToasty({
        title: '',
        message: error.Message
      });
    }
  }

  public async import(): Promise<void> {
    // nop for now
  }

  /** Manage security for the current record */
  public async security() {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      const rights = await this.appDataRepository.getAccessRights(app.Identifier, rec._id);
      const mappedUsers = this.usersService.getAllMapped();
      const mappedPendingUsers = this.usersService.getPendingMapped();
      const mappedTeams = this.teamsService.getAllMappedTeams();
      const config = new SecurityComponentConfig(
        [...mappedUsers, ...mappedPendingUsers],
        mappedTeams,
        rights || []
      );

      const result = await SecurityComponent.openAsync(this.globalModel, config);
      const patch = result?.patch;

      if (patch?.trackedChanges?.size > 0) {
        patch.AppIdentifier = app.Identifier;
        patch._id = rec._id;

        await this.appDataRepository.updateAccessRights(app.Identifier, rec._id, result.patch.getDelta());
        this.recordModel.recordPatched(patch);

        this.globalModel.showSuccessToasty({
          title: '',
          message: $localize`Record security updated`
        });
      }

    } catch (error) {
      this.globalModel.showErrorToasty({
        message: error.Message
      });
    }
  }

  /** Show history for the current record */
  public async history() {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      this.overlayService.openSpinner();
      const activities = await this.appDataRepository.getHistory(app.Identifier, rec._id).toPromise();
      this.overlayService.close();

      const componentContentData: ModalComponentContentData = {
        ...initialModalComponentContentData,
        HistoryData: { record: rec, activities }
      };

      await this.globalModel.dialogAsync(HistoryComponent, componentContentData);
    } catch (error) {
      this.globalModel.showErrorToasty({
        message: error.Message
      });
    }
  }

  /** Sync child data for the current app */
  public async syncChildData() {
    try {
      const app = this.appModel.app.value;
      const rec = this.recordModel.record.value;

      const user = tryGetCurrentUser();

      const syncParams: SyncParameters = {
        PageSize: 9999, // todo
        Hierarchy: `${app.Identifier}|${rec._id}`
      };

      const childApps = app.ChildAppsIdentifiers.map(i => this.appService.application(i.Identifier));

      // Resync all apps except online only
      const promises = childApps
        .filter(a => this.storageModeService.getAppStorageMode(a.Identifier) !== StorageMode.Online)
        .map(child => this.dataSyncService.resyncAppData(child, user, syncParams.Hierarchy));

      // Wait for completion
      if (promises.length > 0) {
        await Promise.all(promises);
      }

      // Refresh counts for all child apps including online
      await this.pageModel.folderModel.reloadChildRecordCounts();

    } catch (error) {
      this.globalModel.showErrorToasty({
        title: '',
        message: error.Message
      });
    }
  }


  public async discardChanges(record: Record): Promise<void> {
    // nop
  }
}
