import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [],
  exports: [],
  providers: []

})
export class LayoutsModule {
  static forRoot(): ModuleWithProviders<LayoutsModule> {
    return {
      ngModule: LayoutsModule
    };
  }
}
