import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { ReportField, Enums, stringCompare, logError, Report, IFilterTerm } from '@softools/softools-core';
import { FormBase } from '../form.component/form-base.component';
import { FieldFilters } from 'app/filters/types/field-filters';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  MessageDialogData,
  MessageType
} from '../message-dialog/message-dialog.component';
import { ReportFilter } from '../../filters/types';
import { AlignmentTypeAlias, HeaderSummaryExpression } from '@softools/softools-core';
import { HeaderReportFieldModel } from '../listreport.component/listreport.component';
import { ReportModel, FilterModel } from 'app/mvc';
import { AppService } from 'app/services/app.service';
import { Application } from 'app/types/application';
import { FilterEditorUi } from 'app/filters/types/filter-editor-ui';
import { IShowFilterManagement } from 'app/workspace.module/types/show-filter-management.interface';
import { ComponentBase } from '..';
import { BehaviorSubject } from 'rxjs';

class FilterColumnInfo {
  constructor(public isSimple: boolean, public singleValue = '') { }
}

@Component({
  // We have to use an attribute selector as angular generated elements ended up inside the
  // table element which throws layout.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-filterheader]',
  templateUrl: './filterheader.component.html',
  styleUrls: ['./filterheader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterheaderComponent extends ComponentBase implements OnInit, OnChanges {

  public fieldTypes = Enums.FieldType;

  @Input() appIdentifier = '';
  @Input() allowMultiSelect = true;
  @Input() formBase: FormBase;
  @Input() parentFieldIdentifier: string = null;
  @Input() filter: ReportFilter;
  @Input() filterModel: FilterModel;
  @Input() reportModel: ReportModel;
  @Input() selectAll: boolean;
  @Input() showAutoLayoutPopup: boolean;
  @Input() showDetailsField: boolean;
  @Input() expandAllDetailsFields = false;
  /** Overlay reference for positioning filter popup */
  @Input() filterEditOrigin: CdkOverlayOrigin;
  @Input() headerFields = [] as Array<HeaderReportFieldModel>;
  @Input() headerSummaryExpressions: Array<HeaderSummaryExpression>;
  @Input() showGroupsExpanded = false;
  @Input() groupsExpanded = false;

  @Input() showFilterManagement: IShowFilterManagement;

  @Output() onSelectAll = new EventEmitter();
  @Output() onDeselectAll = new EventEmitter();
  @Output() onToggleAutoLayoutClick = new EventEmitter();
  @Output() toggleDetailsFields = new EventEmitter();
  @Output() toggleGroups = new EventEmitter<boolean>();

  public app: Application;

  public simpleFilter$ = new BehaviorSubject<Array<FieldFilters>>([]);

  constructor(private appService: AppService) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(this.reportModel.report.$, (report) => {
      const simpleFilters = this.getSimpleFilters(report);
      this.simpleFilter$.next(simpleFilters);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['appIdentifier']) {
      this.app = this.appService.application(this.appIdentifier);
    }
  }

  public get showSelectAll() {
    return this.app.capabilities?.noSelectAll !== true;
  }

  public getFieldLabel(fieldIdentifier: any) {
    this.formBase.getFieldLabel(fieldIdentifier);
  }

  public getFieldFilterInfo(field: ReportField): FilterColumnInfo {
    const comparisons = this.filterModel.parsedFilter.comparisons.get(
      field.FieldIdentifier
    );
    if (comparisons && comparisons.length === 1) {
      return new FilterColumnInfo(true, comparisons[0].value);
    } else {
      return new FilterColumnInfo(false);
    }
  }

  // Tracking function for the items.  If we don't use this, NG has to recreate the entire
  // chunk of DOM when we switch state to include the popup.  As well as being inefficient
  // this causes us to lose focus from the input field as it gets destroyed
  public trackHeader(index) {
    return index;
  }

  public isFilteringSupported(simpleField: FieldFilters): boolean {
    // Can't filter from header if it's a complex filter
    // May want to remvoe the first check and add feedback below...
    // return // this.filterControl.filterSpec.isSimple &&
    return simpleField?.appField.isFilterable();
  }

  public requestFilterPopup(event: Event, fieldFilters: FieldFilters) {
    event?.stopPropagation();

    const app = this.reportModel.appModel.app.value;
    if (app.isFilterAdvanced) {
      // only shows sort so check field sortable
      if (!fieldFilters.appField.isFieldSortable()) {
        return;
      }
    }

    const fieldId = fieldFilters.field.FieldIdentifier;

    const filterSpec = this.filterModel.filterSpec;

    if (this.isFilteringSupported(fieldFilters)) {
      const terms = this.filterModel.filterSpec
        .flatten()
        .filter(t => t.FieldIdentifier === fieldId);
      if (terms.length > 1) {
        this.showTooComplex().catch(error => logError(error, ''));
      } else {
        const term: IFilterTerm =
          terms.length === 1
            ? terms[0]
            : {
              id: 0,
              FieldIdentifier: fieldId,
              Operator: 0,
              Operand: null,
              displayOperand: null
            };

        const th = (event.target as HTMLElement).closest(
          'th'
        ) as HTMLTableHeaderCellElement;
        if (th) {
          const ui = new FilterEditorUi(
            true,   // show popup
            this.filterEditOrigin,   // cdk origin
            th.offsetLeft,
            0,  // th.offsetHeight,
            th.offsetWidth,
            filterSpec, term, fieldId,
          );

          this.reportModel.showFilterEditor(ui);
        }
      }
    }
  }

  public headerItem(identifier: string) {
    return this.headerFields.find(h => h.FieldIdentifier === identifier);
  }

  public isFilterParticipant(fieldSummary: FieldFilters): boolean {
    return fieldSummary.operator !== null;
  }

  public getHeaderAlignment(
    fieldType: Enums.FieldType,
    alignment: AlignmentTypeAlias
  ): AlignmentTypeAlias {
    return this.formBase.getHeaderAlignment(fieldType, alignment);
  }

  private async showTooComplex() {
    const msg: MessageDialogData = { Type: MessageType.ComplexFilterMessage };
    await this.reportModel.globalModel.showMessageDialogAsync(msg);
    this.showFilterManagement.showFilterManagementPanel(true);
  }

  private getSimpleFilters(report: Report) {
    const reportFields = report?.ListReportFields || [];
    const sorted = reportFields
      .filter((f) => !f.Hidden)
      // sort by display order, then id so order consistent if same
      .sort((a, b) => a.DisplayOrder - b.DisplayOrder || stringCompare(a.FieldIdentifier, b.FieldIdentifier))
      .map(rf => {
        const field = this.app.getField(rf.FieldIdentifier);
        if (!field) {
          console.warn(`Field ${rf.FieldIdentifier} not found in app fields`);
        }
        return new FieldFilters(
          rf,
          field,
          field?.Type,
          null, null
        );
      })
      ;

    return sorted;
  }
}
