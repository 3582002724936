<app-input-icon icon="envelope" [url]="emailUrl" >
  <a *ngIf="!editMode" target="_blank" 
    (click)="$event.stopPropagation()" 
    [href]="emailUrl">
    {{value}}
  </a>
  <app-input *ngIf="editMode"
    class="d-block pe-0"
    [appModel]="appModel"
    [reportModel]="reportModel"
    [class.inline-control]="inline"
    [textStyle]="textStyle"
    [field]="fieldModel"
    [template]="template"
    [form]="form"
    [record]="record"
    [value]="value"
    [listRow]="listRow"
    [ruleState]="ruleState"
    [containerField]="containerField"
  >
  </app-input>
</app-input-icon>
