import { Injectable } from '@angular/core';
import { UsersApplication } from './settings.apps/users-app';
import { TeamsApplication } from './settings.apps/teams-app';
import { MyProfileApp } from './settings.apps/my-profile.app';
import { ErrorSummariesApplication } from './settings.apps/error-summaries-app';
import { SiteSettingsApplication } from './settings.apps/site-settings-app';
import { SelectListsApplication } from './settings.apps/select-list.app';
import { ImportSummariesApplication } from './settings.apps/import-summaries-app';

import { ExportSummariesApplication } from './settings.apps/export-summaries-app';
import { AssetsApplication } from './settings.apps/assets-app';
import { ImageListAssetsApplication } from './settings.apps/image-list-assets-app';
import { ImageListApplication } from './settings.apps/image-list.app';
import { ApplicationsApplication } from './settings.apps/applications-app';
import { UserVerificationApp } from './security/user-verification-app';
import { PendingUsersApplication } from './settings.apps/pending-users.app';
import { NamedStylesApplication } from './settings.apps/named-styles/named-styles.app';
import { SavedFiltersApplication } from './settings.apps/saved-filters/saved-filters.app';
import { NotificationsApplication } from './settings.apps/notifications/notifications.app';

@Injectable({ providedIn: 'root' })
export class EmbeddedAppsService {

  public constructor(
    public siteSettings: SiteSettingsApplication,
    public usersApp: UsersApplication,
    public teamsApp: TeamsApplication,
    public myProfileApp: MyProfileApp,
    public userVerificationApp: UserVerificationApp,
    public assets: AssetsApplication,
    public imageListAssets: ImageListAssetsApplication,
    public errorsApp: ErrorSummariesApplication,
    public importsApp: ImportSummariesApplication,
    public exportsApp: ExportSummariesApplication,
    public selectListApp: SelectListsApplication,
    public imageListApp: ImageListApplication,
    public applicationsApplication: ApplicationsApplication,
    public pendingUsersApplication: PendingUsersApplication,
    public namedStylesApplication: NamedStylesApplication,
    public savedFiltersApplication: SavedFiltersApplication,
    public notificationsApplication: NotificationsApplication
  ) { }

  public getEmbeddedApps() {
    return [
      this.siteSettings,
      this.usersApp,
      this.teamsApp,
      this.myProfileApp,
      this.userVerificationApp,
      this.assets,
      this.imageListAssets,
      this.errorsApp,
      this.importsApp,
      this.exportsApp,
      this.selectListApp,
      this.imageListApp,
      this.applicationsApplication,
      this.pendingUsersApplication,
      this.namedStylesApplication,
      this.savedFiltersApplication,
      this.notificationsApplication
    ];
  }
}
