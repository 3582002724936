<div class="d-flex w-100 range">
  <app-input  #rangeinput
    class="flex-grow-1 align-self-center readonly-normal-appearance w-100 me-1 no-control-chrome d-block"
    [appModel]="appModel"
    [reportModel]="reportModel"
    [class.inline-control]="inline"
    [field]="fieldModel"
    [template]="template"
    [form]="form"
    [record]="record"
    [value]="value"
    [listRow]="listRow"
    [ruleState]="ruleState"
    [containerField]="containerField"
    [disabled]="isDisabled() || !this.editMode"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"
    (mouseDown)="mouseDown($event)"
    (inputChanged)="inputChanged()"
    (change)="inputComplete()"
  ></app-input>

  <!--
    (focus)="onFocus($event)"

   -->
  <span class="me-1 align-self-center range-text-value"
   [style.min-width.rem]="textWidth"
   [ngStyle]="textStyle"
  >
    {{displayValue}}
  </span>
</div>
