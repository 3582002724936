import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookiesService {

  get IsCompatible() {
    return this.areCookiesEnabled && this.areThirdPartyCookiesEnabled;
  }

  /**
   * @returns true if cookies are enabled
   */
  get areCookiesEnabled() {
    // navigator.cookieEnabled is supported for our browser matrix https://developer.mozilla.org/en-US/docs/Web/API/Navigator/cookieEnabled
    return navigator.cookieEnabled;
  }

  /**
   * @returns true if third party cookies are enabled
   */
  get areThirdPartyCookiesEnabled() {
    // CANNOT DETECT THIRD PARTY COOKIES

    // document.cookie = 'ThirdPartyCookie=yes;domain=auth.softools.net';
    // if (document.cookie.indexOf('ThirdPartyCookie=') > -1) {
    //   return true;
    // }

    return true;
  }
}
