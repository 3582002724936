import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupDialogService } from './lookup-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { lookupServiceToken } from './lookup-dialog.interface';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
  ],
  providers: [
    { provide: lookupServiceToken, useClass: LookupDialogService }
  ]
})
export class LookupDialogServiceModule { }
