import { Failure, logError, ValidationError } from '@softools/softools-core';
import { validUrlExpression } from 'app/softoolsui.module/validation/patterns';
import { TextualAppField } from './textual-app-field';
import { DownloadInfo } from '../download-info.interface';
import { IPerformContext } from './app-field';

export class UrlAppField extends TextualAppField {

  public override validate(value: any, errors: Array<Failure>, listRow?: number): void {
    super.validate(value, errors, listRow);
    if (!this._isValidUrl(value)) {
      errors.push({ error: ValidationError.InvalidUrl, fieldId: this.Identifier, listRow });
    }
  }

  private _isValidUrl(value: string): boolean {
    if (value && !validUrlExpression.test(value)) {
      return false;
    }
    return true;
  }

  public override async perform(context: IPerformContext): Promise<boolean> {

    // Use configured URL if specified, or current value otherwise
    const url = this.ImageActionButtonUrl ?? context.value;
    if (url) {
      if (this.ImageActionButtonUrlNewTab) {
        window.open(url, '_blank');
      } else {
        const info: DownloadInfo = { url };
        this.appModel.globalModel.downloadNamedFileAsync(info).catch(e => logError(e, 'downloadNamedFile'));
      }

      return true;
    }

    return false;
  }

  public override defaultIcon() {
    return 'cloud-download-alt';
  }
}
