import { Routes } from '@angular/router';
import { CookieCheckGuard, LoggedInGuard, CompatibilityGuard, AuthGuard, AppsExistsGuard, ThemeGuard } from 'app/guards';
import { AppZone } from './types/enums';
import { LogoutComponent } from './logout/logout.component';
import { Auht0CallbackComponent } from './auht0-callback/auht0-callback.component';
import { PatchQueueCacheGuard } from './guards/patch-queue-cache.guard';
import { IdpCallbackComponent } from './auht0-callback/idp-callback.component';
import { LoginComponent } from './auth.module';
import { ErrorComponent } from './error.module/error/error.component';
import { SyncComponent } from './sync.module/sync/sync.component';
import { IncompatibleComponent } from './incompatible.module/incompatible.component';
import { LicenseConfirmationComponent } from './licensing.module/license-confirmation/license-confirmation.component';
import { SignupLoggedOutGuard } from './guards/signup-logged-out.guard';
import { TeamsGuard } from './guards/teams.guard';

export const guards = [CookieCheckGuard, CompatibilityGuard, AuthGuard, AppsExistsGuard, PatchQueueCacheGuard];

export const appRoutes: Routes = [
  {
    path: 'Logout',
    component: LogoutComponent,
    canActivate: [PatchQueueCacheGuard]
  },
  {
    path: 'Account/Callback',
    component: Auht0CallbackComponent,
  },
  {
    path: 'Account/Idp/Callback',
    component: IdpCallbackComponent,
  },
  {
    path: 'Error',
    component: ErrorComponent,
  },
  {
    path: 'Sync',
    component: SyncComponent,
    canActivate: [...guards, ThemeGuard, TeamsGuard],
    data: { zone: AppZone.sync },
  },
  {
    path: 'Incompatible',
    component: IncompatibleComponent,
  },
  {
    path: 'Signup',
    component: LicenseConfirmationComponent,
    canActivate: [...guards, ThemeGuard],
    data: { zone: AppZone.licensing },
  },
  {
    path: 'login', // needs to be lower case for Auth0
    component: LoginComponent,
    canActivate: [CookieCheckGuard, LoggedInGuard],
    data: { zone: AppZone.login },
  },
  {
    path: 'Account/Login',
    component: LoginComponent,
    canActivate: [CookieCheckGuard, SignupLoggedOutGuard],
    data: { zone: AppZone.login },
  }
];
