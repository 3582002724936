import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';
import { QuickFilterFieldComponent } from './quick-filter-field.component';
import { QuickFilterComponent } from './quick-filter.component';

@NgModule({
  declarations: [
    QuickFilterComponent,
    QuickFilterFieldComponent
  ],
  exports: [
    QuickFilterComponent,
    QuickFilterFieldComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
    FontAwesomeModule,
    MvcDirectivesModule,
  ]
})
export class QuickFiltersModule {

}