import { HomepageStorageService, logError } from '@softools/softools-core';
import { InjectService } from 'app/services/locator.service';
import { FolderGroup, FolderType, NavigationItem, RootFolders } from 'app/softoolsui.module/folders.component/folder';
import { getHomepageUrl } from 'app/_constants/constants.route';
import { FolderController } from './folder.controller';

export class HomeFolderController extends FolderController {

  @InjectService(HomepageStorageService)
  private readonly homeService: HomepageStorageService;

  public override initialise() {
    super.initialise();
    this.configure().catch(e => logError(e, ''));
  }

  private async configure(): Promise<void> {
    const root = new RootFolders();
    await this.addHomepagesAsync(root);
    this.folderModel.appHomeUrl.value = null;
    this.folderModel.folderGroups.value = root.FolderGroups;
  }

  private async addHomepagesAsync(root: RootFolders): Promise<void> {
    const folderGroup = new FolderGroup(FolderType.Homepages);
    root.FolderGroups.push(folderGroup);

    const homepages = await this.homeService.getHomepages();
    homepages.forEach((homepage) => {
      const pageItem: NavigationItem = {
        Identifier: homepage.Identifier,
        Title: homepage.Title,
        Type: 'home',
        SecondaryType: 'home',
        HasAdd: false,
        Url: getHomepageUrl(homepage.Identifier)
      };

      folderGroup.Items.push(pageItem);
    });
  }

}
