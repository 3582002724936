import { Injectable } from '@angular/core';
import { Record, ErrorSummary, ErrorSummariesRepository } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '../embedded-application';
import { EpochConverter } from 'app/types/epoch-converter';
import { RecordPatch } from 'app/workspace.module/types';

import App from './error-summaries.config.json';
import { AppCapabilities, ToolbarContext } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { PermissionsService } from 'app/services/permissions.service';
import { ReportModel } from 'app/mvc';
import { CallbackToolbarAction, ToolbarAction } from 'app/softoolscore.module/types/classes/toolbaraction.class';

@Injectable({ providedIn: 'root' })
export class ErrorSummariesApplication extends OnlineEmbeddedApp<ErrorSummary> {

  constructor(
    private repository: ErrorSummariesRepository,
    private permissionsService: PermissionsService,
  ) {
    super(App.App);
  }

  public toRecord(summary: ErrorSummary): Record {
    return summary && {
      _id: summary.Id,
      AppIdentifier: summary.AppIdentifier,
      ParentAppIdentifier: summary.ParentAppIdentifier,
      RecordId: summary.RecordId,
      Error: summary.Error,
      OccurrenceDate: EpochConverter.toEpoch(summary.OccurrenceDate),
      TaskName: summary.TaskName,
      Hierarchy: '',
      EditableAccessForUser: false,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: summary.Error,
      IsArchived: false,
    } as Record;
  }

  public convert(record: Record): ErrorSummary {
    const values = record as any;
    const summary: ErrorSummary = {
      Id: record._id,
      AppIdentifier: record.AppIdentifier,
      ParentAppIdentifier: values.ParentAppIdentifier,
      RecordId: values.RecordId,
      Error: values.Error,
      OccurrenceDate: EpochConverter.toDateString(values.OccurrenceDate),
      TaskName: values.TaskName
    };

    return summary;
  }

  public override async totalCount(): Promise<number> {
    return this.repository.getCountAsync();
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const summaries = await this.repository.getErrorSummariesAsync(selection.count, selection.start, queryParams);
    return summaries.map(summary => this.toRecord(summary));
  }

  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const summaries = await this.repository.getErrorSummariesAsync(1, +cursor - 1, queryParams);
    return summaries.map(summary => this.toRecord(summary)).pop();
  }

  public upsertAsync(_recordPatch: RecordPatch): Promise<Record> {
    throw new Error('Method not implemented.');
  }

  public override get capabilities(): AppCapabilities {
    return {
      canCreate: false,
      canEdit: false,
      canView: false,
      canSort: true,
      canGroup: false,  // server API currently doesn't support grouping.
    };
  }

  public override get permissions() {
    return this.permissionsService.errorSummaryPermissions;
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new CallbackToolbarAction('Refresh', 'sync', () => {
      reportModel.reload();
    }));
  }
}
