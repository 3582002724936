<div class="sof-modal-container">
  <div class="cardv2-popup sof-small-modal">
    <ng-container *ngIf="modalOptions$ | async as opts">
      <!-- //<div [class]="opts.Wide ? 'modal-dialog modal-lg modal-dialog-centered' : 'modal-dialog modal-dialog-centered'"> -->

      <app-modal-header [modalOptions]="modalOptions$ | async"></app-modal-header>
      <app-modal-message [modalOptions]="modalOptions$ | async"></app-modal-message>

      <div class="cardv2-body">
        <ng-template appModalContentDirective></ng-template>
      </div>

      <ng-container *ngIf="opts.Type === Enums.ModalType.info">
        <div class="cardv2-footer">
          <button type="button" class="close" (click)="onModalCloseClickHandler()" i18n>Close</button>
        </div>
      </ng-container>
      <ng-container *ngIf="opts.Type !== Enums.ModalType.info">
        <div class="cardv2-footer">
          <button
            type="button"
            class="primary"
            *ngIf="!opts.HideConfirm && opts.Type === Enums.ModalType.confirm"
            (click)="onModalConfirmClickHandler()"
            i18n
          >
            Yes
          </button>
          <button type="button" class="close" (click)="onModalCloseClickHandler()" i18n>No</button>
        </div>
      </ng-container>
      <!-- </div> -->
    </ng-container>
  </div>
</div>
