import { Dashboard, DashboardCell } from '@softools/softools-core';
import { Model, ModelProperty } from '@softools/vertex';
import { AppIdentifier } from 'app/types/application';
import { AppModel } from './app.model';
import { GlobalModelService } from './common/global-model.service';
import { GlobalModel } from './global.model';
import { LaunchpadModel } from './headers/launchpad.model';

export class DashboardModel extends Model<DashboardModel> {

  public readonly dashboard = new ModelProperty<Dashboard>().withLogging('DASHBOARD');

  /** App models indexed by id for all apps referenced by dashboard cells */
  private appModels = new Map<AppIdentifier, AppModel>();

  constructor(private models: GlobalModelService, private globalModel: GlobalModel, private launchpadModel: LaunchpadModel, container?: Model<any>) {
    super(container);
  }

  public initialize() {

    this.subscribe(this.dashboard.$, (dashboard) => {
      this.dashboardSelected(dashboard);
    });
  }

  public setDashboard(dashboard: Dashboard) {
    this.dashboard.value = dashboard;
  }

  private dashboardSelected(dashboard: Dashboard) {

    const appModels = new Map<AppIdentifier, AppModel>();
    dashboard?.DashboardCells.map(cell => cell.AppIdentifier).forEach((id) => {
      if (id && !appModels.has(id)) {
        const appModel = new AppModel(this.globalModel, this.launchpadModel, this.models.siteModel);
        appModel.initialise();
        appModel.loadApp(id);
        appModels.set(id, appModel);
      }
    });

    this.appModels = appModels;
  }

  public getCellAppModel(cell: DashboardCell) {
    return this.appModels.get(cell.AppIdentifier);
  }

}
