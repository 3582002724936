import ObjectID from 'bson-objectid';
import App from './image-lists-app.config.json';

import { Record, ImageList, RecordId, IndexedAppData, ImageListsRepository, SelectListOption, ImageListAssetRepository } from '@softools/softools-core';
import { OnlineEmbeddedApp } from '../embedded-application';
import { ImageListsService } from 'app/services/image-lists.service';
import { LocatorService } from 'app/services/locator.service';
import { RecordPatch } from 'app/workspace.module/types/index';
import { ToolbarContext, GetRecordOptions } from 'app/types/application';
import { RecordSelection } from 'app/types/record-selection';
import { ToolbarAction } from 'app/softoolscore.module/types/classes/index';
import { Injectable } from '@angular/core';
import { PermissionsService } from 'app/services/permissions.service';
import { RecordPersistService } from 'app/services/record/record-persist.service';
import { RecordModel, ReportModel } from 'app/mvc';

class Cache<T> {

  public cached = new Array<T>();

  public first = 0;

  public count = 0;

  public orderBy: string;

  public orderDesc?: boolean;

  public filter?: string;
}



@Injectable({ providedIn: 'root' })
export class ImageListApplication extends OnlineEmbeddedApp<ImageList> {

  private imageAssets: Array<SelectListOption> = [];

  private cache = new Cache<Record>();

  constructor(
    private repository: ImageListsRepository,
    private assetRepository: ImageListAssetRepository,
    private permissionsService: PermissionsService,
    private imageListsService: ImageListsService,
    protected recordPersistService: RecordPersistService,
  ) {
    super(App.App);
  }

  public override async initialise(): Promise<void> {
    const assets = await this.assetRepository.getAssetsAsync(1000, 0, null);

    this.imageAssets = assets.map((asset, index) => ({
      Id: asset.Id,
      Text: asset.Name,
      Value: `/ImageListAsset/${asset.Id}`,
      DisplayOrder: index,
    }));
  }

  public toRecord(list: ImageList): Record {
    return list && {
      ...list,
      _id: list.Id,
      ImageListOptions: list.ImageListOptions.map(opt => ({
        Key: opt.Id,
        Title: opt.Title,
        Value: opt.Value,
        ImageListAssetUri: opt.ImageListAssetUri,
        Asset: opt.ImageListAssetUri,
        DisplayOrder: opt.DisplayOrder,
      })),
      AppIdentifier: this.Identifier,
      Hierarchy: '',
      EditableAccessForUser: true,
      CreatedDate: null,
      CreatedByUser: '',
      UpdatedDate: null,
      UpdatedByUser: '',
      QuickFilterSearchText: list.Name,
      IsArchived: false,
    } as Record;
  }

  public override async totalCount(): Promise<number> {
    const lists = this.imageListsService.getAll();
    return lists.length;
  }

  public override async getApiViewRecordCount(selection: RecordSelection): Promise<number> {
    selection = new RecordSelection(selection); // clone to mutate
    delete selection.showArchived;
    const queryParams = selection.queryParamsForCount(true);
    return this.repository.getCountAsync(queryParams);
  }

  public override async eachRecord(callback: (record: Record) => any): Promise<void> {
    const lists = this.imageListsService.getAll();
    lists.forEach(list => {
      callback(this.toRecord(list));
    });
  }

  public override async getRecordByIdAsync(id: RecordId, options?: GetRecordOptions): Promise<Record> {

    // Look up any pending patch if we're going to need it
    const pendingPatch = options?.applyPatch && this.recordPersistService.getPatch(id);

    // If patch is new, it's not been persisted so convert to record and return
    if (pendingPatch?._new) {
      return pendingPatch.toRecord(this);
    }

    const list = await this.repository.getImageListAsync(id);
    const record = this.toRecord(list);

    if (record && pendingPatch) {
      pendingPatch.updateRecord(record, this);
    }

    return record;

    // const imageListsService = LocatorService.get(ImageListsService);
    // return this.toRecord(await imageListsService.getById(id));
  }

  public override async getIndexedRecordRange(index: IndexedAppData, start: number, count: number): Promise<Array<Record>> {
    const records = await index.getRecordsWithGetterFunc(start, count, async (_app, id) => {
      return this.getRecordByIdAsync(id);
    });
    return records;
  }

  public override async getApiRecordRange(selection: RecordSelection): Promise<Array<Record>> {
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });
    const orderBy = selection.filter?.OrderBy;
    const orderDesc = selection.filter?.IsOrderDescending || false;

    if (selection.start === this.cache.first &&
      selection.count === this.cache.count &&
      orderBy === this.cache.orderBy &&
      orderDesc === this.cache.orderDesc &&
      queryParams.$filter === this.cache.filter) {
      return this.cache.cached;
    }

    const lists = await this.repository.getImageListsAsync(selection.count, selection.start, queryParams);
    const records = lists.map(list => this.toRecord(list));

    this.cache.first = selection.start;
    this.cache.count = selection.count;
    this.cache.orderBy = orderBy;
    this.cache.orderDesc = orderDesc;
    this.cache.cached = records;
    this.cache.filter = queryParams.$filter;

    return records;
  }

  public override async getRecordCursor(selection: RecordSelection, cursor: string): Promise<Record> {

    delete selection.showArchived;
    const queryParams = selection.queryParams({ stripBrackets: true, supressArchived: true });

    const offset = +cursor - 1;
    if (offset >= this.cache.first && offset < this.cache.count) {
      return this.cache.cached[offset - this.cache.first];
    }

    const lists = await this.repository.getImageListsAsync(1, +cursor - 1, queryParams);
    return lists.map(list => this.toRecord(list)).pop();
  }


  public override validateRecordId(recordId: RecordId): RecordId | boolean {
    if (!recordId || !ObjectID.isValid(recordId)) {
      const newObjectId = new ObjectID();
      recordId = newObjectId.toHexString();
    }
    return recordId;
  }

  public async upsertAsync(recordPatch: RecordPatch): Promise<Record> {

    if (recordPatch._new) {
      // Post format is really ugly
      // Fields are passed as normal properties except the options list which is a tracked
      // change list.  Create a record in that shape.
      const list = {
        ...recordPatch.changes,
        Id: recordPatch._id,
        ImageListOptions: recordPatch.trackedChanges.get('ImageListOptions')
      } as any;

      return this.repository.createAsync(list) as any;

    } else {
      // todo this looks redundant
      const list = this.imageListsService.getById(recordPatch._id);
      if (list) {
        recordPatch.updateObject(list, this);
      }

      // Apply patch
      const changes = recordPatch.getDelta();
      await this.repository.save(recordPatch._id, changes).toPromise();

      // Unfortunately the patch API doesn't return the updated user and fixing
      // that would mean unravelling dependenceies so for now query current state
      const updatedList = await this.repository.getImageListAsync(recordPatch._id);
      return this.toRecord(updatedList);
    }
  }

  public override async storeAsync(record: Record): Promise<Record> {
    const imageListsService = LocatorService.get(ImageListsService);
    const list = record as any;   // todo convert properly
    const stored = await imageListsService.storeAsync(list);
    return this.toRecord(stored);
  }

  public override getReportToolbarActionModel(reportModel: ReportModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await reportModel.refresh();
    }));
  }

  public override getRecordToolbarActionModel(recordModel: RecordModel, actions: Array<ToolbarAction>, context: ToolbarContext) {
    actions.push(new ToolbarAction('Refresh', 'sync', async () => {
      await recordModel.reload();
    }));
  }

  public override async refresh(): Promise<boolean> {
    await this.imageListsService.refresh('force');
    return true;
  }

  public override selectionListOptions(fieldId: string): Array<SelectListOption> {
    if (fieldId === 'ImageListOptions_ImageListAssetUri') {
      return this.imageAssets;
    }
    return null;
  }

  public override get permissions() {
    return this.permissionsService.listPermissions;
  }

}
