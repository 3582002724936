import { Injectable } from '@angular/core';
import { User, MappedPendingUser, MappedUser, UserStorageService, OnlineStatusService, tryGetCurrentUser, PendingUser, IUserRefreshChanges } from '@softools/softools-core';

/**
 * Indication of when to access the server during a refresh operation.
 * 'force' alwasys loads server data
 * 'init' loads from server if no data is present
 */
export type RefreshType = 'force' | 'init';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private usersByIdentifier: Map<string, User>;
  private pendingUsersByIdentifier: Map<string, PendingUser>;
  private mappedUsersByIdentifier: Map<string, MappedUser>;
  private mappedUsersForMyTeamByIdentifier: Map<string, MappedUser>;
  private mappedPendingUsersByIdentifier: Map<string, MappedPendingUser>;
  private _cachedLanguage: string;

  constructor(private userStorageService: UserStorageService, private onlineStatus: OnlineStatusService) {}

  /**
   * Refresh user data held by this service.  The users held in storage are loaded and indexed,
   * and optionally reloaded from the server if needed and/or possible.
   * This is be called by a guard in an async context so that code can access the collections
   * efficiently and synchronously.  Normal code should not need to call @see refresh directly.
   *
   * @param refreshType   Controls whether data is loaded from the server.
   *  'init' loads if no users found, 'force' always updates from server provided we are online.
   */
  public async refresh(refreshType?: RefreshType): Promise<Array<User>> {
    // Don't reinitialise if we already have (See SOF-7792)
    if (refreshType === 'init' && this.usersByIdentifier) {
      return Array.from(this.usersByIdentifier.values());
    }

    // Load users and create alternative view as MappedUser
    this.usersByIdentifier = new Map<string, User>();
    this.pendingUsersByIdentifier = new Map<string, PendingUser>();
    this.mappedUsersByIdentifier = new Map<string, MappedUser>();
    this.mappedUsersForMyTeamByIdentifier = new Map<string, MappedUser>();
    this.mappedPendingUsersByIdentifier = new Map<string, MappedPendingUser>();

    let users = await this.userStorageService.getUsers();
    let teamUsers = await this.userStorageService.getUsersForMyTeams();
    let pendingUsers = await this.userStorageService.getPendingUsers();

    // Load from server if needed
    if ((refreshType === 'init' && (!users || users.length === 0)) || refreshType === 'force') {
      if (!this.onlineStatus.isOnline) {
        users = await this.userStorageService.syncUsersAsync();
        teamUsers = await this.userStorageService.syncUsersForMyTeamsAsync();
        pendingUsers = await this.userStorageService.syncPendingUsersAsync();
      }
    }

    if (users?.length > 0) {
      users.forEach((user) => {
        this.usersByIdentifier.set(user.Id, user);
        this.mappedUsersByIdentifier.set(user.Id, this._toMapped(user));
      });
    }

    if (teamUsers?.length > 0) {
      teamUsers.forEach((user) => {
        this.mappedUsersForMyTeamByIdentifier.set(user.Id, this._toMapped(user));
      });
    }

    if (pendingUsers?.length > 0) {
      pendingUsers.forEach((user) => {
        this.pendingUsersByIdentifier.set(user.Id.toString(), user);
        this.mappedPendingUsersByIdentifier.set(user.Id.toString(), this._toPendingMapped(user));
      });
    }

    return users;
  }

  /**
   * Poll the server for any changed users.
   * If any changes are reported, the user collections are updated and the
   * changed users returned.
   */
  public async checkUpdates(): Promise<IUserRefreshChanges> {
    const updates = await this.userStorageService.refreshStorageAsync();
    const userUpdates = updates.users;
    const pendingUserUpdates = updates.pendingUsers;

    if (userUpdates && userUpdates.length > 0) {
      userUpdates.forEach((user) => {
        this.usersByIdentifier.set(user.Id, user);
        this.mappedUsersByIdentifier.set(user.Id, this._toMapped(user));
        if (this.pendingUsersByIdentifier.has(user.Id)) {
          this.pendingUsersByIdentifier.delete(user.Id);
          this.mappedPendingUsersByIdentifier.delete(user.Id);
        }
      });
    }

    if (pendingUserUpdates && pendingUserUpdates.length > 0) {
      pendingUserUpdates.forEach((user) => {
        this.pendingUsersByIdentifier.set(user.Id.toString(), user);
        this.mappedPendingUsersByIdentifier.set(user.Id.toString(), this._toPendingMapped(user));
      });
    }

    return updates;
  }

  public getUser(identifier: string): User {
    this.checkInitialised();
    const user = this.usersByIdentifier.get(identifier);
    return user;
  }

  public getPendingUser(identifier: string): PendingUser {
    this.checkInitialised();
    return this.pendingUsersByIdentifier.get(identifier);
  }

  /**
   * Get the user or pending user asssociated with the user id.
   * @param identifier
   */
  public getUserOrPending(identifier: string): User | PendingUser {
    this.checkInitialised();
    const user = this.usersByIdentifier.get(identifier);

    return user ? user : this.pendingUsersByIdentifier.get(identifier);
  }

  public getAll(): Array<User> {
    this.checkInitialised();
    return Array.from(this.usersByIdentifier.values());
  }

  public getMapped(identifier: string): MappedUser {
    this.checkInitialised();
    return this.mappedUsersByIdentifier.get(identifier);
  }

  public getAllMapped(): Array<MappedUser> {
    this.checkInitialised();
    return Array.from(this.mappedUsersByIdentifier.values());
  }

  public getPendingMapped(): Array<MappedPendingUser> {
    this.checkInitialised();
    return Array.from(this.mappedPendingUsersByIdentifier.values());
  }

  public getMappedByName(name: string): MappedUser {
    this.checkInitialised();
    return this.getAllMapped().find((mapped) => mapped.Text === name);
  }

  public getMappedById(id: string): MappedUser | MappedPendingUser {
    this.checkInitialised();
    let user: MappedUser | MappedPendingUser = this.getAllMapped().find((mapped) => mapped.Value === id);
    if (!user) {
      user = this.getPendingMapped().find((mapped) => mapped.Value === id);
    }
    return user;
  }

  public getAllMappedForMyTeams(): Array<MappedUser> {
    this.checkInitialised();
    return Array.from(this.mappedUsersForMyTeamByIdentifier.values());
  }

  /** Get the logged in user's default homepage identifier */
  public getCurrentUserDefaultHomepage(): string {
    const currentUser = tryGetCurrentUser();
    if (currentUser) {
      // Use stored user by preference as this gets the current value
      // not a snapshot at login time
      const user = this.getUserOrPending(currentUser.Id) || currentUser;
      return user.DefaultHomepageIdentifier;
    }

    return '';
  }

  public saveUser(user: User) {
    this.usersByIdentifier.set(user.Id, user);
    this.mappedUsersByIdentifier.set(user.Id, this._toMapped(user));
  }

  private checkInitialised() {
    if (!this.usersByIdentifier) {
      throw new Error('UsersService is not initialised.  Check guards have been called');
    }
  }

  private _toMapped(user: User): MappedUser {
    return new MappedUser(user.Id, `${user.FirstName} ${user.LastName}`, user.Email, user);
  }

  private _toPendingMapped(user: PendingUser): MappedPendingUser {
    return new MappedPendingUser(user.Id.toString(), `${user.FirstName} ${user.LastName}`, user.Email, user);
  }
}
