import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { CardReportOrientation, ElementStyles, logError, Point, Style } from '@softools/softools-core';
import { AppModel, GlobalModel } from 'app/mvc';
import { IGeneralController } from 'app/mvc/common/general-controller.interface';
import { CardReportController } from 'app/mvc/reports/card-report.controller';
import { CardReportModel } from 'app/mvc/reports/card-report.model';
import { ComponentBase } from 'app/softoolsui.module';
import { IColumn } from 'app/workspace.module/components/ws-reports/ws-card-report/ws-card-report.component';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss']
})
export class CardReportComponent extends ComponentBase implements OnInit {

  @Input() cardModel: CardReportModel;

  @Input() reportController: CardReportController;

  @ViewChild('content', { static: true })
  private contentArea: ElementRef<HTMLElement>;

  public globalModel: GlobalModel;

  public appModel: AppModel;

  public generalController?: IGeneralController;

  public readonly scrollPosition$ = new BehaviorSubject<Point>({ x: 0, y: 0 });

  /** Size of the scrolling area in pixels (width or height depending on orientation) */
  public readonly surfaceSize$ = new BehaviorSubject(0);

  public rowHeight: number;

  public cardLayoutWidth;

  public cardLayoutHeight;

  /** Size of a group in pixels - width for vertical orientation, height for horizontal  */
  public groupSizePx;

  /** Column info.  Must always have a value as we use ngIf to catpure value */
  public columns$ = new BehaviorSubject<Array<IColumn>>([]);

  public readonly marginPx = 14;

  public ungroupedColumnWidth;

  public groupHeaderStyles$ = new BehaviorSubject<ElementStyles>(null);

  public cardLayoutExtent = 0;

  public ungroupedCardsPerColumn: number;

  public groupedCardsPerColumn: number;

  public groupedCardsPerColumn$ = new BehaviorSubject(0);

  public ungroupedCardsPerColumn$ = new BehaviorSubject(0);

  /** Template indexes of cards that are showing faces other than the first */
  public cardIndexes = new Map<string, number>();

  public orientation$ = new BehaviorSubject<CardReportOrientation>(CardReportOrientation.Vertical);

  public vertical = true;

  private defaultHeaderStyle: Style = {
    Name: '.default-header',
    Element: 'component',
    BackgroundColour: '#fafafa'
  };

  constructor() {
    super();
  }

  public ngOnInit(): void {

    this.globalModel = this.cardModel.globalModel;
    this.appModel = this.cardModel.appModel;
    this.generalController = this.reportController;

    // todo watch report
    this.subscribe(this.cardModel.report.$, (report) => {
      if (report) {
        const styles = this.appModel.getNamedStyles({
          target: 'card',
          names: report.CardReport?.HeaderStyles,
          additionalStyles: [this.defaultHeaderStyle]
        });
        this.groupHeaderStyles$.next(styles);

        this.groupedCardsPerColumn = this.cardModel.report.value.CardReport.GroupCardCount ?? 1;

        // Reset cards to first template
        this.cardIndexes.clear();
      }
    });

    this.subscribe(this.cardModel.totalCount.$, (count) => {
      // layout cards... this will become more complex and must respond to size changes etc
      this.calculateLayout().catch(e => logError(e, 'layout'));
    });

    this.subscribe(this.scrollPosition$.pipe(debounceTime(100)), (pos) => {
      this.calculateLayout().catch(e => logError(e, 'layout'));
    });

    this.subscribe(this.cardModel.columnGroups.$, (grouping) => {
      if (grouping) {
        this.calculateLayout().catch(e => logError(e, 'layout'));
      }
    });

    this.subscribe(this.cardModel.card.$, (card) => {
      const app = this.appModel.app.value;
      if (card && app) {
        this.rowHeight = card.Height + this.marginPx;
        this.cardLayoutWidth = card.Width + (this.marginPx * 2 + 8);
        this.cardLayoutHeight = card.Height + (this.marginPx * 2 + 8);
        this.ungroupedColumnWidth = this.cardLayoutWidth;    // temp
      }
    });

    this.subscribe(this.cardModel.scrollPosition.$, (pos) => {
      this.scrollPosition$.next(pos);
    });

    this.subscribe(this.cardModel.zoom.$, () => {
      this.calculateLayout().catch(e => logError(e, 'layout'));
    });


    this.subscribe(this.globalModel.layoutChanged$, () => {
      this.calculateLayout().catch(e => logError(e, 'layout'));
    });
  }

  public trackColumn(_, column: IColumn) {
    // If we're looking at column values, use the value to stop
    // re-redndering as we scroll.  If it's undefined use the
    // column refrernce so it changes when we filter
    return column.starts?.value ?? column;
  }

  public cardtemplateIndexChanged($event: { id: string, index: number }) {
    if ($event.index) {
      this.cardIndexes.set($event.id, $event.index);
    } else {
      this.cardIndexes.delete($event.id);
    }
  }

  @HostListener('window:resize')
  public resize() {
    this.calculateLayout().catch(e => logError(e, 'layout'));
  }

  public scrolled($event) {
    const x = this.contentArea?.nativeElement?.scrollLeft;
    const y = this.contentArea?.nativeElement?.scrollTop;
    this.cardModel.scrollPosition.value = { x, y };
  }

  private contentSize() {
    const element = this.contentArea?.nativeElement;
    if (element) {
      const width = element.offsetParent?.clientWidth ?? element.clientWidth;
      const height = element.offsetParent.clientHeight ?? element.clientHeight;
      return { width, height };
    } else {
      return { width: 0, height: 0 };
    }
  }

  private async calculateLayout() {
    const count = this.cardModel.totalCount.value;
    if (count) {
      const { width, height } = this.contentSize();

      if (width !== undefined && height !== undefined) {

        this.setOrientation();

        const zoom = this.cardModel.zoom.value;
        const effectiveWidth = width / zoom;
        const effectiveHeight = height / zoom;

        const groupAxisSize = this.vertical ? effectiveWidth : effectiveHeight;

        const cardsPerColumn = this.groupedCardsPerColumn;

        this.groupedCardsPerColumn$.next(cardsPerColumn);
        const windowHeightInCards = Math.ceil((effectiveHeight / cardsPerColumn) / this.rowHeight);

        // Get size of a card
        this.cardLayoutExtent = this.vertical ? this.cardLayoutWidth : this.cardLayoutHeight;

        // Get size of display group
        if (this.vertical) {
          this.groupSizePx = this.cardLayoutExtent * cardsPerColumn * zoom;
        } else {
          // Assumes simple horizontal, need to do horizontal alt
          this.groupSizePx = (this.cardLayoutExtent + 18 + 48 + 55) * cardsPerColumn * zoom;
        }

        const isGrouped = this.cardModel.showGroupHeaders.value;

        let numGroups = this.cardModel.groupCount.value;
        const widthAfterGroupColumns = isGrouped ? groupAxisSize - (this.cardLayoutWidth * numGroups) : (groupAxisSize);

        const ungroupedCardsPerColumn = Math.max(Math.floor(widthAfterGroupColumns / this.cardLayoutWidth), 1);
        this.ungroupedCardsPerColumn = ungroupedCardsPerColumn;
        this.ungroupedCardsPerColumn$.next(ungroupedCardsPerColumn);

        const pos = this.scrollPosition$.value ?? { x: 0, y: 0 };
        const groupScrollOffset = this.vertical ? pos.x : pos.y;

        const firstGroupIndex = Math.floor(groupScrollOffset / this.groupSizePx);

        this.cardModel.batch(() => {
          this.cardModel.groupedCardsPerColumn.value = this.groupedCardsPerColumn;
          this.cardModel.ungroupedCardsPerColumn.value = this.ungroupedCardsPerColumn;
          this.cardModel.groupPageSize.value = cardsPerColumn * windowHeightInCards;
          this.cardModel.pageSize.value = ungroupedCardsPerColumn * windowHeightInCards;
        });

        if (!isGrouped) {
          const ungroupedStarts = this.cardModel.columnGroups.value?.starts.find(s => s.value === undefined);
          const rows = Math.ceil(count / ungroupedCardsPerColumn);

          this.ungroupedColumnWidth = width;
          this.surfaceSize$.next(width);

          const col: IColumn = {
            ungrouped: true,
            position: 0,
            starts: ungroupedStarts,
            rows: rows
          };

          this.columns$.next([col]);
        } else {
          const columns = new Array<IColumn>();
          let position = 0;

          for (let index = firstGroupIndex; index < this.cardModel.columnGroups.value?.starts.length; ++index) {
            const start = this.cardModel.columnGroups.value?.starts[index];

            if (start) {
              const ungrouped = start.value === undefined;
              const groupSize = this.groupSizePx;
              if ((position - groupSize) < groupAxisSize) {
                const col: IColumn = {
                  ungrouped,
                  position: position + firstGroupIndex * this.groupSizePx,
                  starts: start,
                  rows: Math.ceil(ungrouped ? (this.cardModel.totalCount.value / ungroupedCardsPerColumn) : (start.count / this.groupedCardsPerColumn))
                };
                columns.push(col);
              }

              position += groupSize;
            }
          }

          this.ungroupedColumnWidth = Math.min(
            groupAxisSize - position,
            this.ungroupedCardsPerColumn * this.cardLayoutWidth
          ) * zoom;

          if (columns.length > 0) {
            this.columns$.next(columns);

            const surface = ((numGroups * this.groupSizePx));
            this.surfaceSize$.next(surface);
          }
        }
      } else {
        // If we don't have a size yet set an empty collection
        this.columns$.next([]);
        this.surfaceSize$.next(0);
      }
    }
  }

  private setOrientation() {
    const report = this.cardModel.report.value;
    if (this.cardModel.showGroupHeaders.value) {
      // Grouped so orient swimlanes according to config
      const orientation = report.CardReport?.Orientation ?? CardReportOrientation.Vertical;
      this.vertical = orientation === CardReportOrientation.Vertical;
      this.orientation$.next(orientation);
    } else {
      // Ungrouped always uses vertical orientation
      const orientation = CardReportOrientation.Vertical;
      this.vertical = orientation === CardReportOrientation.Vertical;
      this.orientation$.next(orientation);
    }
  }


}
