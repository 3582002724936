import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NamedStylesApplication } from 'app/embedded.apps/settings.apps/named-styles/named-styles.app';
import { InjectService } from 'app/services/locator.service';
import { NamedStylesService } from 'app/services/named-styles.service';
import { BaseGuard } from './base.guard';

@Injectable({ providedIn: 'root' })
export class NamedStylesGuard extends BaseGuard implements CanActivate {

  @InjectService(NamedStylesApplication)
  private readonly namedStylesApp: NamedStylesApplication;

  @InjectService(NamedStylesService)
  private readonly namedStylesService: NamedStylesService;

  public async canActivate(): Promise<boolean> {
    const styles = await this.namedStylesApp.getAllStyles();
    this.namedStylesService.store(styles);
    return true;
  }
}
