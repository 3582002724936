import { logError } from '@softools/softools-core';
import { FolderGroup, RootFolders } from 'app/softoolsui.module/folders.component/folder';
import { RecordSelection } from 'app/types/record-selection';
import { combineLatest } from 'rxjs';
import { AppHomeModel } from '..';
import { AppContextModel } from '../app/app-context.model';
import { FolderModel } from './folder.model';
import { ReportFolderController } from './report-folder.controller';

export class AppHomeFolderController extends ReportFolderController {

  constructor(protected homeModel: AppHomeModel, folderModel: FolderModel) {
    super(homeModel.appModel, null, folderModel);
  }

  protected override configure(apps: AppContextModel) {
    super.configure(apps);
    if (apps) {
      this.addReports(apps.app.value, this.folderModel.folderGroups.value[0]);
    }
  }
}

export class ChildAppHomeFolderController extends AppHomeFolderController {

  constructor(homeModel: AppHomeModel, folderModel: FolderModel) {
    super(homeModel, folderModel);
  }

  public override initialise() {
    super.initialise();

    this.subscribe(this.appModel.appContext.$, (apps: AppContextModel) => {
      if (apps) {
        this.configure(apps);
      }
    });

    this.subscribe(combineLatest([this.appModel.parentApp.$, this.appModel.parentRecord.$]),
      async ([parentApp, parentRecord]) => {
        // Bit of a kludge - this is pointing at the child app
        const ai = this.appModel.appIdentifiers.value?.pop();
        if (ai) {
          const root = await this.createRecordFolder(parentApp, ai, parentRecord);
          this.folderModel.folderGroups.value = root.FolderGroups;
        }
      });
  }

  protected override initialiseHome() {
    this.subscribe(this.appModel.appIdentifiers.$, (appIdentifiers) => {
      if (appIdentifiers) {
        const parentAppIds = this.appModel.appIdentifiers.value.pop();
        const [, parentRecordId] = this.reportModel?.hierarchy.value.split('|') || [null, null];
        const home = this.navigationService.getChildAppHomeOrBaseUrl(parentAppIds, parentRecordId);
        this.folderModel.appHomeUrl.value = home;
      }
    });
  }

  protected override initialiseCount(): void {

    this.subscribe(this.appModel.appContext.$, async (appContext: AppContextModel) => {
      if (appContext?.parentApp.value) {
        try {
          this.folderModel.countBusy.value = true;
          const selection = new RecordSelection();
          if (appContext.hierarchyDepth > 1) {
            const parent = await appContext.parentRecord.value;
            selection.hierarchy = parent?.Hierarchy;
          }

          const count = await appContext.parentApp.value.getViewRecordCountAsync(selection);
          this.folderModel.count.value = count;
        } finally {
          this.folderModel.countBusy.value = false;
        }
      }
    });
  }

  protected override async configure(apps: AppContextModel) {

    const appIdentifiers = apps.appIdentifiers.value;
    const parentRecordId = apps.parentRecordId.value;
    const parentApp = apps.parentApp.value;

    const root = new RootFolders();

    const group: FolderGroup = this.createAppHeaderFolder(root, apps.parentApp.value);

    // todo this is right for top level but revisit for grandchild
    // group.NewItemUrl = this.navigationService.getListCreateRecordUrl(appIdentifiers.pop(), null /*, parentRecordId*/);

    // this.folderModel.batch(() => {
    //   this.folderModel.appHomeUrl.value = homeUrl;
    //   this.folderModel.folderGroups.value = root.FolderGroups;
    // });

    const queryParameters = this.reportModel?.filterModel.combinedFilter.value?.QueryParameters;
    const record = this.reportModel?.parentRecord.value;
    if (record) {
      const visibleForms = await parentApp.getVisibleForms(record, record._new);
      this.addFoldersFromForms(group, appIdentifiers.pop(), parentRecordId, queryParameters, visibleForms);

      // Add child app folders unless on a new record
      if (!record._new) {
        // const reportIdentifier = this.appContextService.SelectedReport?.Identifier;
        const reportIdentifier = this.reportModel?.reportIdentifier.value;

        const childGroup = await this.addChildAppsAsync(root, appIdentifiers.pop(), parentApp, parentRecordId, reportIdentifier);

        // Update counts asynchronously
        childGroup.SubFolders.forEach(sub => {

          const item = sub.HeaderItem;
          if (item) {
            const selection = new RecordSelection();
            selection.hierarchy = `${item.ParentAppIdentifier}|${item.ParentRecordId}`;
            selection.showArchived = this.appModel.globalModel.archived.value;

            const childApp = this.appService.application(item.AppIdentifier);
            childApp.totalCount(selection).then(count => {
              item.ChildCount = count;
              this.folderModel.childAppUpdatecount.value++;
            }).catch(error => logError(error, 'Failed to get child total count'));
          }
        });
      }
    }

    this.folderModel.folderGroups.value = [...root.FolderGroups];
  }

  // protected override addReports(app: Application, appFolderGroup: FolderGroup): void {
  // }  

  protected override configureHomeUrl(apps: AppContextModel): string {

    const appIdentifiers = this.appModel.appIdentifiers.value;
    if (appIdentifiers) {
      const parentAppIds = this.appModel.appIdentifiers.value.pop();
      const [, parentRecordId] = this.reportModel?.hierarchy.value.split('|') || [null, null];
      return this.navigationService.getChildAppHomeOrBaseUrl(parentAppIds, parentRecordId);
    }

    return '';
  }
}
