import { Injectable } from '@angular/core';
import { FilterModel } from 'app/mvc';
import { Application } from 'app/types/application';
import { OverviewReportModel } from '../overview-report.model';
import { RecordsReportModel } from '../records-report.model';
import { AccessorFactory } from './accesssor-factory';
import { ChartDataAccssor } from './chart-data-accessor';
import { OfflineCardDataAccessor } from './offline-card.data.accessor';
import { OfflineChartDataAccssor } from './offline-chart-data-accessor';
import { OfflineRecordDataAccssor } from './offline-record-data-accessor';
import { ArchivedCardRecordDataAccessor, OnlineCardDataAccssor } from './online-card.data.accessor';
import { ArchivedChartDataAccessor, OnlineChartDataAccssor } from './online-chart-data-accessor';
import { ArchivedRecordDataAccessor, OnlineRecordDataAccssor } from './online-record-data-accessor';
import { RecordDataAccessor } from './record-data-accessor';

// Injected in AppModule using token AccessorFactory
@Injectable()
export class AccessorFactoryService extends AccessorFactory {

  public override createChartAccessor(app: Application, model: OverviewReportModel): ChartDataAccssor {
    if (model.globalModel.archived.value) {
      return new ArchivedChartDataAccessor(app, model);
    } else {
      if (app.isOfflineReport(model.reportIdentifier.value)) {
        return new OfflineChartDataAccssor(app, model);
      } else {
        return new OnlineChartDataAccssor(app, model);
      }
    }
  }

  public override createRecordAccessor(app: Application, model: RecordsReportModel, filterModel: FilterModel): RecordDataAccessor {
    if (model.globalModel.archived.value) {
      return new ArchivedRecordDataAccessor(app, model, filterModel);
    } else {
      if (app.isOfflineReport(model.reportIdentifier.value)) {
        return new OfflineRecordDataAccssor(app, model, filterModel);
      } else {
        return new OnlineRecordDataAccssor(app, model, filterModel);
      }
    }
  }

  public override createCardAccessor(app: Application, model: RecordsReportModel, filterModel: FilterModel): RecordDataAccessor {

    if (model.globalModel.archived.value) {
      return new ArchivedCardRecordDataAccessor(app, model, filterModel);
    } else {
      if (app.isOfflineReport(model.reportIdentifier.value)) {
        return new OfflineCardDataAccessor(app, model, filterModel);
      } else {
        return new OnlineCardDataAccssor(app, model, filterModel);
      }
    }
  }

}
