import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Field, Failure, ValidationError } from '@softools/softools-core';

@Component({
  selector: 'app-validation-error-display',
  templateUrl: './validation-error-display.component.html',
  styleUrls: ['./validation-error-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorDisplayComponent {

  @Input() public errors: Array<Failure>;

  @Input() public serverError?: string;

  @Input() public fields: Array<Field>;

  public expanded = false;

  public errcodes = ValidationError;

  constructor() { }

  public fieldName(error: Failure): string {
    const field = this.fields.find(f => f.Identifier === error.fieldId);
    return (field && field.Label) || error.fieldId;
  }

  public expandBar = ($event: Event) => {
    $event.stopPropagation();
    this.expanded = true;
  }

  public collapseBar = ($event: Event) => {
    $event.stopPropagation();
    this.expanded = false;
  }
}
