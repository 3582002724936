import { Record } from '@softools/softools-core';
import { Model, ModelProperty } from '@softools/vertex';
import { AppIdentifiers } from 'app/services/record/app-info';
import { InjectService } from 'app/services/locator.service';
import { AppService } from 'app/services/app.service';
import { Application } from 'app/types/application';
import { RouteParams } from '../global.model';

/**
 * Desription of the hierarchy context an app is running in.
 */
export class AppContextModel extends Model<AppContextModel> {

  public readonly appIdentifiers = new ModelProperty<AppIdentifiers>(this);

  public readonly app = new ModelProperty<Application>(this);

  public readonly parentApp = new ModelProperty<Application>(this);

  public readonly childApp = new ModelProperty<Application>(this);

  public readonly parentRecordId = new ModelProperty<string>(this);

  public readonly hierarchy = new ModelProperty<string>(this);

  public readonly parentRecord = new ModelProperty<Promise<Record>>(this)
    .withLogging('ParentRecord')
    .withLazyLoad(() => {
      return this.parentApp?.value.getRecordByIdAsync(this.parentRecordId.value);
    });

  @InjectService(AppService)
  private readonly appService: AppService;

  public setIdentifiers(appIdentifiers: AppIdentifiers) {
    this.batch(() => {
      this.appIdentifiers.value = appIdentifiers;
      this.app.value = appIdentifiers?.visibleAppIdentifier ? this.appService.application(appIdentifiers.visibleAppIdentifier) : null;
      this.parentApp.value = appIdentifiers?.parentAppIdentifier ? this.appService.application(appIdentifiers.parentAppIdentifier) : null;
      this.childApp.value = appIdentifiers?.childAppIdentifier ? this.appService.application(appIdentifiers.childAppIdentifier) : null;
    });
  }

  public async routed(params: RouteParams) {
    this.batch(() => {
      this.hierarchy.value = params.hierarchy;
      this.setIdentifiers(params.appIdentifiers);
      this.parentRecordId.value = params.parentRecordId;
    });
  }

  public get hierarchyDepth() {
    return this.appIdentifiers.value?.ancestorAppIds.length ?? 0;
  }

}
