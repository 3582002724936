import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBase } from 'app/softoolsui.module/form.component/form-base.component';
import { Record, logError } from '@softools/softools-core';
import { DetailsField } from '@softools/softools-core';
import { AppField } from 'app/types/fields/app-field';
import { ContainerType } from 'app/softoolsui.module/fields';
import { AppModel } from 'app/mvc';

@Component({
  selector: 'app-listreportdetails',
  templateUrl: './listreportdetails.component.html',
  styleUrls: ['./listreportdetails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListreportdetailsComponent extends FormBase implements OnInit {

  @Input() public override appModel: AppModel;
  @Input() public reportIdentifier: string;
  @Input() public detailsFields: Array<DetailsField> = [];
  @Input() public record = {} as Record;
  @Input() public inlineEdit = false;

  /** Set to true if details pane is not yet visible to the user. */
  @Input() public isHidden = false;

  public fieldsById = new Map<string, AppField>();

  public detailsContainerType = ContainerType.Form;   // treat as form unless we need to distinguish

  constructor() {
    super();
  }

  ngOnInit() {
    try {

      this.detailsFields.sort((d1, d2) => (d1.DisplayOrder - d2.DisplayOrder));

      // Get fields for detail fields
      const app = this.application;
      this.detailsFields.forEach(detail => {
        const field = app.getField(detail.FieldIdentifier);
        if (field) {
          this.fieldsById.set(field.Identifier, field);
        }
      });
    } catch (error) {
      logError(error, '');
    }
  }

  public getLabel(detail: DetailsField): string {
    const field = this.fieldsById.get(detail.FieldIdentifier);
    return field && field.Label;
  }

}
