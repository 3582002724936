import { ChangeDetectionStrategy, Component } from '@angular/core';
import { tryGetCurrentUser } from '@softools/softools-core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CookiePolicyComponent {
  constructor(public modelService: GlobalModelService) { }

  public accept() {
    this.modelService.globalModel.acceptCookiePolicy();
  }

  public logoutDisabled() {
    return tryGetCurrentUser() ? false : true;
  }
}
