import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupHeaderComponent } from './group-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [GroupHeaderComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    GroupHeaderComponent
  ]
})
export class GroupHeaderModule { }
