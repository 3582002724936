import { PermissionEnums } from '../enums';

export interface PermissionSet {
  name: string;
  label: string;    // should be i18n
  codes: Array<number>;
}

export const noPermissions: PermissionSet = {
  name: 'None',
  label: 'None',
  codes: []
};

export const minimalPermissions: PermissionSet = {
  name: 'Minimal',
  label: 'Minimal',
  codes: [
    PermissionEnums.Records.Create,
    PermissionEnums.Records.Update,
    PermissionEnums.Records.Archive,
    PermissionEnums.Records.Tag,
    PermissionEnums.Records.Copy,
    PermissionEnums.Records.Export,
    PermissionEnums.Records.Subscribe,

    PermissionEnums.Reports.Export,
    PermissionEnums.Reports.QuickEdit,
    PermissionEnums.Reports.Tag,
    PermissionEnums.Reports.Subscribe,
    PermissionEnums.Reports.SavePersonalFilters,
    PermissionEnums.Reports.ShowArchived,

    PermissionEnums.Settings.Subscribe,
    PermissionEnums.ActivityStream.All,
  ]
};

export const basicTasksPermissions: PermissionSet = {
  name: 'BasicTasks',
  label: 'Basic Tasks',
  codes: [
    PermissionEnums.Records.All,

    PermissionEnums.Reports.Export,
    PermissionEnums.Reports.QuickEdit,
    PermissionEnums.Reports.Tag,
    PermissionEnums.Reports.Copy,
    PermissionEnums.Reports.Template,
    PermissionEnums.Reports.Link,
    PermissionEnums.Reports.Subscribe,
    PermissionEnums.Reports.SavePersonalFilters,
    PermissionEnums.Reports.SaveTeamFilters,
    PermissionEnums.Reports.ShowArchived,

    PermissionEnums.Settings.Subscribe,
    PermissionEnums.ActivityStream.All,
  ]
};

export const advancedTasksPermissions: PermissionSet = {
  name: 'AdvancedTasks',
  label: 'Advanced Tasks',
  codes: [
    PermissionEnums.Reports.All,
  ]
};

export const basicAppBuilderPermissions: PermissionSet = {
  name: 'BasicAppBuilder',
  label: 'Basic App Builder',
  codes: [
    PermissionEnums.AppStudio.AddApp,
    PermissionEnums.AppStudio.Homepages,
    PermissionEnums.AppStudio.HomepagesAdd,
    PermissionEnums.AppStudio.HomepagesDelete,
    PermissionEnums.AppStudio.ConfigureBasic,
    PermissionEnums.AppStudio.ConfigureDelete,
    PermissionEnums.AppStudio.Fields,
    PermissionEnums.AppStudio.FieldsAdd,
    PermissionEnums.AppStudio.FieldsDelete,
    PermissionEnums.AppStudio.Templates,
    PermissionEnums.AppStudio.TemplatesAdd,
    PermissionEnums.AppStudio.TemplatesDelete,
    PermissionEnums.AppStudio.Forms,
    PermissionEnums.AppStudio.FormsAdd,
    PermissionEnums.AppStudio.FormsDelete,
    PermissionEnums.AppStudio.Reports,
    PermissionEnums.AppStudio.ReportsAdd,
    PermissionEnums.AppStudio.ReportsDelete,
  ]
};

export const advancedAppBuilderPermissions: PermissionSet = {
  name: 'AdvancedAppBuilder',
  label: 'Advanced App Builder',
  codes: [
    PermissionEnums.AppStudio.All,
  ]
};

export const manageUsersPermissions: PermissionSet = {
  name: 'ManageUsers',
  label: 'Manage Users',
  codes: [
    PermissionEnums.Settings.UsersTeamsSelf,
    PermissionEnums.Settings.UsersTeamsUpdate,
    PermissionEnums.Settings.UsersTeamsCreate,
    PermissionEnums.Settings.UsersTeamsDelete
  ]
};

export const manageSettingsPermissions: PermissionSet = {
  name: 'ManageSettings',
  label: 'Manage Settings',
  codes: [
    PermissionEnums.Settings.Lists,
    // PermissionEnums.Settings.Lookups,
    PermissionEnums.Settings.SiteResources,
    PermissionEnums.Settings.Other,
    PermissionEnums.Settings.Subscribe,
    PermissionEnums.Settings.SetLoginImage,
    PermissionEnums.Settings.Imports,
    PermissionEnums.Settings.TeamsAll,
    PermissionEnums.Settings.Exports,
    PermissionEnums.Settings.ExportsAll,
    PermissionEnums.Settings.ImportsCancel,
    PermissionEnums.Settings.ImportsAll,
    PermissionEnums.Settings.SetExportPolicy,
    PermissionEnums.Settings.ImportsAll,
  ]
};

export const allSettingsPermissions: PermissionSet = {
  name: 'AllSettings',
  label: 'All Settings',
  codes: [
    PermissionEnums.Settings.All,
  ]
};

export const permissionSets = [
  noPermissions,
  minimalPermissions,
  basicTasksPermissions,
  advancedTasksPermissions,
  basicAppBuilderPermissions,
  advancedAppBuilderPermissions,
  manageUsersPermissions,
  manageSettingsPermissions,
  allSettingsPermissions,
];
