import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFieldComponent } from './list-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SofPlusIconModule } from '@softools/design/icons';
import { Fields2Module } from '../fields2.module';
import { ScalarFieldsModule } from 'app/softoolsui.module/fields/scalar-fields/scalar-fields.module';
import { MatListModule } from '@angular/material/list';
import { FieldsModule } from 'app/softoolsui.module/fields.component/fields.module';
import { PaginationModule } from 'app/softoolsui.module/pagination/pagination.module';
import { ListCellFieldComponent } from './list-cell-field.component';



@NgModule({
  declarations: [
    ListFieldComponent,
    ListCellFieldComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    FontAwesomeModule,
    SofPlusIconModule,
    FieldsModule,
    Fields2Module,
    ScalarFieldsModule,
    PaginationModule
  ],
  exports: [
    ListFieldComponent
  ]
})
export class ListFieldModule { }
