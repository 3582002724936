import { Directive, ElementRef, HostListener, Input, NgZone, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { first, groupBy, mergeMap, toArray, skipLast } from 'rxjs/operators';
import { from } from 'rxjs';
import { AutolayoutStorageService, HeaderSummaryExpression } from '@softools/softools-core';
import { ComponentBase } from 'app/softoolsui.module/component-base';
import { GlobalModel } from 'app/mvc';

const SHOW_VALUE = 'table-cell';
const HIDE_VALUE = 'none';
const COLUMN_IDENTIFIER = 'column-identifier';
const COLUMN_PRIORITY = 'column-priority';

/**
 * When applied to a table, automatically removes columns so it fits in the available space
 *
 * Rows in the table that should not be modified (e.g. sub-headers) should be indicated with a
 * 'noautolayout' class.
 */
@Directive({
  selector: '[appAutoLayout]',
  exportAs: 'appAutoLayout',
})
export class AutoLayoutDirective extends ComponentBase implements OnChanges, OnInit {
  private table: HTMLTableElement;
  private hiddenHeaders: Array<HTMLElement> = [];
  private hiddenIdentifiers: Set<string> = new Set<string>();

  /**
   * This is a composite key for the report and app identifier,
   * We dont want to have extra OnChanges events with more inputs.
   */
  @Input() tableKey = '';
  @Input() enabled = true;
  @Input() runOnInit = true;
  @Input() globalModel: GlobalModel;

  // layout on update
  @Input() headerSummaryExpressions: Array<HeaderSummaryExpression>;

  @Input() set showColumn(fieldIdentifeir: string) {
    if (fieldIdentifeir && fieldIdentifeir.length > 0) {
      const elements = this._layoutElements();
      if (elements) {
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          if (element.getAttribute(COLUMN_IDENTIFIER) === fieldIdentifeir) {
            this.showByHeaderElement(element);
            this.hiddenIdentifiers.delete(fieldIdentifeir);
            this._persistHiddenElements();
          }
        }
      }
    }
  }

  @Input() set hideColumn(fieldIdentifeir: string) {
    if (fieldIdentifeir && fieldIdentifeir.length > 0) {
      const elements = this._layoutElements();
      if (elements) {
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          if (element.getAttribute(COLUMN_IDENTIFIER) === fieldIdentifeir) {
            this.hideByHeaderElement(element);
            this.hiddenIdentifiers.add(fieldIdentifeir);
            this._persistHiddenElements();
          }
        }
      }
    }
  }

  @HostListener('window:resize') onResize() {
    if (this.enabled) {
      this._showAllHidden();
      this.layout();
    }
  }

  constructor(private el: ElementRef, private ngZone: NgZone,
    private autolayoutStorageService: AutolayoutStorageService) {
    super();
  }

  public ngOnInit(): void {
    this.table = <HTMLTableElement>this.el.nativeElement;

    if (this.runOnInit) {
      this.layout();
    }

    this.subscribe(this.globalModel.layoutChanged$, () => {
      this._showAllHidden();
      this.layout();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enabled'] && !changes['enabled'].firstChange && changes['enabled'].currentValue !== changes['enabled'].previousValue) {
      this.layout();
    }

    if (changes['enabled'] && !changes['enabled'].isFirstChange() && !changes['enabled'].currentValue) {
      this._showAllHidden();
    }

    // headerSummaryExpressions are run in a web worker so we need to update the layout when these change.
    if (changes['headerSummaryExpressions']) {
      this.layout();
    }
  }

  /** Reapply the layout e.g. after a DOM change. */
  public refreshLayout() {
    // Refresh after all micro tasks have completed.
    this.onResize();
  }

  private layout() {
    this.ngZone.onStable.pipe(first()).subscribe(() => {
      if (this.enabled && this.table) {
        this.hiddenIdentifiers.clear();
        this._persistHiddenElements();

        const headers = this.orderedByColPriority();

        const source = from(headers);

        // hasScrollBar is an expensive call.
        // Multiple html elements have the same COLUMN_IDENTIFIER set.
        // Because we know that all matching COLUMN_IDENTIFIER's need to be removed per hasScrollBar call its more perfomant to group
        // Grouping by COLUMN_IDENTIFIER reduces the reflow in the browser
        const grouped = source.pipe(
          groupBy((element) => element.getAttribute(COLUMN_IDENTIFIER)),
          // return each item in group as array
          mergeMap((group) => group.pipe(toArray())),
          skipLast(1)
        );

        const sub = grouped.subscribe(
          (elements) => {
            if (this.hasScrollBar()) {
              for (let index = 0; index < elements.length; index++) {
                const element = elements[index];
                this.hiddenHeaders.push(element);
                this.hiddenIdentifiers.add(element.getAttribute(COLUMN_IDENTIFIER));
                this.hideByHeaderElement(element);
              }
            }
          },
          (_) => { },
          () => {
            this._persistHiddenElements();
          }
        );

        sub.unsubscribe();

      } else {
        this._showAll();
      }
    });
  }

  private _persistHiddenElements() {
    this.autolayoutStorageService.store(this.tableKey, [...this.hiddenIdentifiers.keys()]);
  }

  private _showAll() {
    this.hiddenHeaders.forEach((element) => {
      this.showByHeaderElement(element);
    });
  }

  private _showAllHidden() {
    const elements = this._layoutElements();
    if (elements) {
      for (let h = 0; h < elements.length; ++h) {
        const header = elements[h];
        this.showByHeaderElement(header);
      }
    }
    this.hiddenHeaders = [];
  }

  private orderedByColPriority(): Array<HTMLElement> {
    const headers = [];
    const elements = this._layoutElements();
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        if (element.hasAttribute(COLUMN_PRIORITY)) {
          headers.push(elements[i]);
        }
      }

      headers.sort((a: HTMLElement, b: HTMLElement) => {
        return parseInt(a.getAttribute(COLUMN_PRIORITY)) - parseInt(b.getAttribute(COLUMN_PRIORITY));
      });

      headers.reverse();
    }

    return headers;
  }

  private hasScrollBar() {
    return this.table.parentElement.clientWidth < this.table.scrollWidth;
  }

  private hideByHeaderElement(element: HTMLElement) {
    element.style.display = HIDE_VALUE;
    this._setColumnVisiblilty(element, HIDE_VALUE);
  }

  private showByHeaderElement(element: HTMLElement) {
    element.style.display = SHOW_VALUE;
    this._setColumnVisiblilty(element, SHOW_VALUE);
  }

  private _setColumnVisiblilty(row: HTMLElement, display: any) {
    if (!row.classList.contains('noautolayout')) {
      row.style.display = display;
    }
  }

  private _layoutElements(): NodeListOf<HTMLElement> {
    return this.table?.querySelectorAll(`[${COLUMN_IDENTIFIER}]`);
  }
}
