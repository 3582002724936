import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RecordPatch } from 'app/workspace.module/types';
import { RecordQueueService } from 'app/services/record/record-queue.service';
import { APP_IDENTIFIER } from 'app/_constants';

@Injectable()
export class PatchQueueResolver implements Resolve<any> {

  constructor(private queue: RecordQueueService) {
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Array<RecordPatch>> {
    const appIdentifier = route.params[APP_IDENTIFIER] as string;
    return this.queue.getPatchesForApp(appIdentifier);
  }
}
