import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WSListReportComponent } from './components/ws-reports/ws-listreport/ws-listreport.component';
import { WSChartReportComponent } from './components/ws-reports/ws-chartreport/ws-chartreport.component';
import { WSDashboardReportComponent } from './components/ws-reports/ws-dashboardreport/ws-dashboardreport.component';
import { WSMatrixReportComponent } from './components/ws-reports/ws-matrixreport/ws-matrixreport.component';
import { WSAppHomeComponent } from './components/ws-apphome/ws-apphome.component';
import { WSSharedReportActionsComponent } from './components/ws-shared/ws-sharedreportactions/ws-sharedreportactions.component';
import { WSChildListReportComponent } from 'app/workspace.module/components/ws-reports/ws-child-listreport/ws-childlistreport.component';
import {
  AppsExistsGuard,
  SelectListsExistsGuard,
  UsersGuard,
  ImageListsExistsGuard,
  ChartDataResolver,
  ReportQueryGuard,
  ThemeGuard,
} from 'app/guards';
import { ListReportModule } from '../softoolsui.module/listreport.component/listreport.module';
import { SharedFooterModule } from '../softoolsui.module/shared/shared-footer.component/share-footer.module';
import { MatrixReportModule } from '../softoolsui.module/matrixreport.component/matrixreport.module';
import { CheckboxModule } from '../softoolsui.module/checkbox.component/checkbox.module';
import { BadgeModule } from '../softoolsui.module/badge.component/badge.module';
import { SearchModule } from '../softoolsui.module/search.component/search.module';
import { FilterModule } from '../softoolsui.module/filter.component/filter.module';
import { ModalModule } from '../softoolsui.module/modal.component/modal.module';
import { FoldersModule } from '../softoolsui.module/folders.component/folders.module';
import { LaunchPadModule } from '../softoolsui.module/launchpad.component/launchpad.module';
import { ChartModule } from '../softoolsui.module/chart.component/chart.module';
import { DashboardModule } from '../softoolsui.module/dashboardreport.component/dashboardreport.module';
import { FormModule } from '../softoolsui.module/form.component/form.module';
import { FiltersModule } from '../filters/filters.module';
import { OverlaySpinnerModule } from 'app/softoolsui.module/overlay-spinner.component/overlay-spinner.module';
import { OverlayImgModule } from 'app/softoolsui.module/overlay-img.component/overlay-img.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { OverlayVideoModule } from 'app/softoolsui.module/overlay-video.component/overlay-video.module';
import { PopupContainerModule } from '../softoolsui.module/popup-container/popup-container.module';
import { FormHeaderModule } from '../softoolsui.module/form.component/form-header/form-header.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalOverlayModule } from '../softoolsui.module/cdk-global-overlay.directive/cdk-global-overlay.module';
import { ListReportQueryGuard, ChartReportQueryGuard, MatrixReportQueryGuard, DashboardReportQueryGuard } from 'app/guards/reports';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RecordResolver } from '../guards/record/record.resolver';
import { NewRecordResolver } from 'app/guards/record/newrecord.resolver';
import { SiteSettingsGuard } from 'app/guards/settings/settings.guard';
import { HomeGuard } from 'app/guards/homepages.guard';
import { FloatingSupportWidgetModule } from '../softoolsui.module/floatingsupportwidget/floatingSupportWidget.module';
import { CustomNotSupportedModule } from 'app/softoolsui.module/customnotsupported.component/customnotsupported.module';
import { ChartGoogleModule } from 'app/softoolsui.module/chart-google.component/chart-google.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecordUpdateModule } from '../softoolsui.module/record-update/record-update.module';
import { HomeItemCardModule } from './components/ws-apphome/home-item-card/home-item-card.module';
import { SearchAutoCompleteModule } from 'app/softoolsui.module/search-autocomplete/search-autocomplete.module';
import { BaseGuard } from 'app/guards/base.guard';
import { OverlayTextModule } from 'app/softoolsui.module/overlay-text.component/overlay-text.module';
import { HomeRecordCardsComponent } from './components/ws-apphome/home-record-cards/home-record-cards.component';
import { PatchQueueResolver } from 'app/guards/apphome/patch-queue.resolver';
import { InfoDialogComponent } from './components/ws-apphome/InfoDialog/InfoDialog.component';
import { routes } from './workspace-routes';
import { WsBaseModule } from './components/ws-base/ws-base.module';
import { UserInvitePopupComponent } from './components/user-invite-popup/user-invite-popup.component';
import { WSRecordUpdateComponent } from './components/ws-record/ws-recordupdate/ws-recordupdate.component';
import { WsValidationBarComponent } from './components/ws-shared/ws-validation-bar/ws-validation-bar.component';
import { RecordCopyComponent } from './components/ws-record/record-copy/record-copy.component';
import { FilterIconModule, SofPlusIconModule, SofSpinnerModule } from '@softools/design/icons';
import { NewRecordButtonComponent } from 'app/softoolsui.module/new-record-button/new-record-button.component';
import { IndexPaginatorModule } from 'app/softoolsui.module/index-paginator/index-paginator.module';
import { PaginationModule } from 'app/softoolsui.module/pagination/pagination.module';
import { AssetImagesModule } from 'app/softoolsui.module/asset-image/asset-images.module';
import { WSChildAppHomeComponent } from './components/ws-apphome/ws-childapphome/ws-childapphome.component';
import { WsTableReportComponent } from './components/ws-reports/ws-table-report/ws-table-report.component';
import { TableReportModule } from 'app/softoolsui.module/table-report/table-report.module';
import { WsSharedModule } from './components/ws-shared/ws-shared.module';
import { TemplateModule } from './components/template/template.module';
import { RecordSelectorModule } from 'app/softoolsui.module/record-selector/record-selector.module';
import { BusyIndicatorModule } from 'app/softoolsui.module/busy-indicator/busy-indicator.module';
import { WsReportComponent } from './components/ws-reports/ws-report/ws-report.component';
import { LoginComponent } from 'app/auth.module';
import { ErrorComponent } from 'app/error.module/error/error.component';
import { SyncComponent } from 'app/sync.module/sync/sync.component';
import { SoftoolsCommonModule } from 'app/common/common.module';
import { IncompatibleComponent } from 'app/incompatible.module/incompatible.component/incompatible.component';
import { LicenseConfirmationComponent } from 'app/licensing.module/license-confirmation/license-confirmation.component';
import { IconModule } from 'app/softoolsui.module/icon/icon.module';
import { ReportModule } from 'app/softoolsui.module/report/report.module';
import { ReportOfflineModule } from './components/ws-shared/report-offline/report-offline.module';
import { CardModule } from 'app/softoolsui.module/card/card.module';
import { WsCardReportComponent } from './components/ws-reports/ws-card-report/ws-card-report.component';
import { Fields2Module } from 'app/softoolsui.module/fields2/fields2.module';
import { ZoomWidgetComponent } from './components/zoom-widget/zoom-widget.component';
import { CardDeckComponent } from './components/ws-reports/ws-card-report/card-deck/card-deck.component';
import { CardReportComponent } from 'app/card-report/card-report.component';
import { MvcDirectivesModule } from 'app/mvc/directives/mvc-directives.module';
import { QuickFiltersModule } from './components/quick-filter/quick-filters.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ListReportModule,
    TableReportModule,
    RecordUpdateModule,
    SharedFooterModule,
    MatrixReportModule,
    HomeItemCardModule,
    CheckboxModule,
    BadgeModule,
    SearchModule,
    FilterModule,
    FiltersModule,
    ModalModule,
    PopupContainerModule,
    OverlaySpinnerModule,
    OverlayImgModule,
    OverlayTextModule,
    OverlayVideoModule,
    FoldersModule,
    LaunchPadModule,
    ChartModule,
    ChartGoogleModule,
    DashboardModule,
    FormModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatChipsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormHeaderModule,
    OverlayModule,
    GlobalOverlayModule,
    PopupContainerModule,
    FlexLayoutModule,
    AssetImagesModule,
    FloatingSupportWidgetModule,
    RecordSelectorModule,
    CustomNotSupportedModule,
    SearchAutoCompleteModule,
    WsBaseModule,
    WsSharedModule,
    IndexPaginatorModule,
    PaginationModule,
    TemplateModule,
    RouterModule.forChild(routes),
    FontAwesomeModule,
    SofPlusIconModule,
    FilterIconModule,
    BusyIndicatorModule,
    SofSpinnerModule,
    SoftoolsCommonModule,
    ReportModule,
    IconModule,
    ReportOfflineModule,
    CardModule,
    Fields2Module,
    DragDropModule,
    MvcDirectivesModule,
    QuickFiltersModule
  ],
  declarations: [
    WSAppHomeComponent,
    WSChildAppHomeComponent,
    WSListReportComponent,
    WSChartReportComponent,
    WSDashboardReportComponent,
    WSMatrixReportComponent,
    WsTableReportComponent,
    WSSharedReportActionsComponent,
    WSChildListReportComponent,
    HomeRecordCardsComponent,
    InfoDialogComponent,
    UserInvitePopupComponent,

    WSRecordUpdateComponent,
    WsValidationBarComponent,
    RecordCopyComponent,
    NewRecordButtonComponent,

    WsReportComponent,

    LoginComponent,
    ErrorComponent,
    SyncComponent,
    IncompatibleComponent,
    LicenseConfirmationComponent,
    WsCardReportComponent,
    CardReportComponent,
    ZoomWidgetComponent,
    CardDeckComponent
  ],
  providers: [
    AppsExistsGuard,
    SelectListsExistsGuard,
    UsersGuard,
    ImageListsExistsGuard,
    ThemeGuard,
    ChartDataResolver,
    ReportQueryGuard,
    ListReportQueryGuard,
    ChartReportQueryGuard,
    MatrixReportQueryGuard,
    DashboardReportQueryGuard,
    RecordResolver,
    NewRecordResolver,
    PatchQueueResolver,
    SiteSettingsGuard,
    HomeGuard,
    BaseGuard,
    WSRecordUpdateComponent,
    WsValidationBarComponent,
    RecordCopyComponent,
    WSAppHomeComponent,
  ]
})
export class WorkspaceModule { }
