<div
class="group-header faded-background noselect w-100 mx-1 pointer group-layout"
[class.group-summary-layout]="showSummaryRow"
[class.group-no-summary-layout]="!showSummaryRow"
[style.height.px]="rowHeight"
(click)="click($event, group)"
>
  <div class="group-icon d-flex align-items-center">
    <fa-icon [icon]="['fal', 'layer-group']" size="2x" class="me-2"
    [style.width.px]="32"
    style="transform: scale(0.8);"
  ></fa-icon>
  </div>

  <div class="details-row d-flex align-items-center">
    <b>{{groupFieldLabel}}: {{headerText}}</b>
    <small *ngIf="showCount" class="mx-2">({{group.count}})</small>
  </div>

  <div *ngIf="showSummaryRow" class="summary-row d-flex flex-row">
    <div *ngFor="let summary of summaryExpressionsForGroup "  >
      <div i18n *ngIf="summary.expression.summaryExpression === 'sum'"  class="summary-item me-4" >
        Total {{summary.expression.fieldIdentifier}}: {{summary.result}}
      </div>
      <div i18n *ngIf="summary.expression.summaryExpression === 'avg'"  class="summary-item me-4" >
        Average {{summary.expression.fieldIdentifier}}: {{summary.result}}
      </div>
      <div i18n *ngIf="summary.expression.summaryExpression === 'min'"  class="summary-item me-4" >
        Minimum {{summary.expression.fieldIdentifier}}: {{summary.result}}
      </div>
      <div i18n *ngIf="summary.expression.summaryExpression === 'max'"  class="summary-item me-4" >
        Maximum {{summary.expression.fieldIdentifier}}: {{summary.result}}
      </div>
    </div>
  </div>
</div>
