import { Injectable } from '@angular/core';
import { ChangedRecord, CHANGED_RECORDS_APP_INDEX, CHANGED_RECORDS_STORE, DatabaseContextService, StorageServiceBase } from '@softools/softools-core';
import { AppIdentifier } from 'app/types/application';

@Injectable({ providedIn: 'root' })
export class ChangedRecordsStorageService extends StorageServiceBase<ChangedRecord, string> {

  constructor(dbContext: DatabaseContextService<ChangedRecord>) {
    super(CHANGED_RECORDS_STORE, dbContext);
  }

  public async saveChangeAsync(change: ChangedRecord) {
    await this.save(change.Id, change);
  }

  public async getChangeAsync(changeIdentifier: string): Promise<ChangedRecord> {
    return await this.get(changeIdentifier);
  }

  public async getAllChangesAsync(count?: number, appIdentifier?: AppIdentifier): Promise<Array<ChangedRecord>> {

    if (appIdentifier) {
      const changes: Array<ChangedRecord> = [];

      await this.dbContext.openCursorAsync(
        CHANGED_RECORDS_STORE,
        CHANGED_RECORDS_APP_INDEX,
        [appIdentifier], (change) => {
          changes.push(change);
          return changes.length <= count;
        });

      return changes;
    } else {
      return await this.getAll(count);
    }
  }
}
