import { Component, Input, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Dashboard, DashboardCell, Enums } from '@softools/softools-core';
import { DashboardModel } from 'app/mvc/dashboard-model';
import { AppIdentifiers } from 'app/services/record/app-info';

@Component({
  selector: 'app-dashboardreport',
  templateUrl: './dashboardreport.component.html',
  styleUrls: ['./dashboardreport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardReportComponent implements OnChanges {

  public rows: Array<{ CellCount: number, DashboardCells: Array<DashboardCell> }>;
  public colCount: number;

  private _rowCount: number;

  @Input() public dashboard: Dashboard;
  @Input() dashboardModel: DashboardModel;
  @Input() appIdentifiers: AppIdentifiers;
  @Input() parentAppId: string;
  @Input() parentRecordId: string;

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['dashboard']) {
      this._setDashboardInfo();
    }
  }


  private _setDashboardInfo(): void {
    if (this.dashboard && typeof (this.dashboard.LayoutType) !== 'undefined') {
      this._setRowColCounts();
      this._setRowCols();
    }
  }

  private _setRowCols(): any {
    let dashboardCellIndex = 0;
    this.rows = new Array<{ CellCount: number, DashboardCells: Array<DashboardCell> }>();
    for (let rowIndex = 0; rowIndex < this._rowCount; rowIndex++) {
      const cells: Array<DashboardCell> = new Array<DashboardCell>();
      for (let colIndex = 0; colIndex < this.colCount; colIndex++) {
        const cell = this.dashboard.DashboardCells[dashboardCellIndex];
        if (!cell.HideCell) {
          cells.push(cell);
        }
        dashboardCellIndex++;
      }
      this.rows.push({ CellCount: cells.length, DashboardCells: cells });
    }
  }

  private _setRowColCounts(): void {
    switch (this.dashboard.LayoutType) {
      case Enums.LayoutType.OneByOne:
        this.colCount = 1;
        this._rowCount = 1;
        break;
      case Enums.LayoutType.OneByTwo:
        this.colCount = 1;
        this._rowCount = 2;
        break;
      case Enums.LayoutType.OneByThree:
        this.colCount = 1;
        this._rowCount = 3;
        break;
      case Enums.LayoutType.TwoByOne:
        this.colCount = 2;
        this._rowCount = 1;
        break;
      case Enums.LayoutType.TwoByTwo:
        this.colCount = 2;
        this._rowCount = 2;
        break;
      case Enums.LayoutType.TwoByThree:
        this.colCount = 2;
        this._rowCount = 3;
        break;
      case Enums.LayoutType.ThreeByOne:
        this.colCount = 3;
        this._rowCount = 1;
        break;
      case Enums.LayoutType.ThreeByTwo:
        this.colCount = 3;
        this._rowCount = 2;
        break;
      case Enums.LayoutType.ThreeByThree:
        this.colCount = 3;
        this._rowCount = 3;
        break;
    }
  }

  public getCellAppModel(cell: DashboardCell) {
    return this.dashboardModel?.getCellAppModel(cell);
  }
}
