import { Component, EventEmitter, Output, ChangeDetectionStrategy, Input } from '@angular/core';
import { AttachmentPopupComponent } from '../attachment-popup.component';
import { FileAttachment, FilePatch, logError, toBase64 } from '@softools/softools-core';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { AttachmentsModel, GlobalModel } from 'app/mvc';
import { DownloadInfo } from 'app/types/download-info.interface';

@Component({
  selector: 'app-file-attachment-popup',
  templateUrl: './file-attachment-popup.component.html',
  styleUrls: ['./file-attachment-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileAttachmentPopupComponent extends AttachmentPopupComponent {

  @Input() content: FileAttachment[];

  @Output() previewAttachment = new EventEmitter<FileAttachment>();

  @Input() override model: AttachmentsModel;

  public editId = -1;
  public editDesc = false;
  public editTitle = false;
  public width = 13;

  constructor() {
    super();
  }

  public isPreviewable(attachment: FileAttachment): boolean {
    const mimeType = attachment.MIMEType ? attachment.MIMEType.toLowerCase() : '';
    const isPreviewable = mimeType
      && (mimeType === 'image/png' || mimeType === 'image/gif' || mimeType === 'image/jpg' || mimeType === 'image/jpeg' ||
        mimeType === 'image/jpe' || mimeType === 'image/bmp' || mimeType === 'image/svg' || mimeType === 'image/tiff' ||
        mimeType === 'application/octet-stream');
    return isPreviewable;
  }

  public getAttachmentIconName(mimeType: string): IconName {
    if (!mimeType) {
      return 'file-image';
    }
    switch (mimeType.toLowerCase()) {
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'file-excel';

      case 'application/pdf':
        return 'file-pdf';

      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'file-word';

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return 'file-powerpoint';

      case 'image/png':
      case 'image/gif':
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/jpe':
      case 'image/bmp':
      case 'image/svg':
      case 'image/tiff':
        return 'file-image';

      case 'application/octet-stream':
        return 'file-alt';

      default:
        return 'file';
    }
  }

  public previewClickHandler(attachment: FileAttachment): void {
    this.previewAttachment.emit(attachment);
  }

  public downloadFileClicked($event: MouseEvent, item: FileAttachment) {
    try {
      $event.stopPropagation();
      $event.preventDefault();

      const info: DownloadInfo = { url: `/Download/${item.ParentId}/${item.Id}`, filename: item.Title };
      const globalModel = this.recordModel.appModel.globalModel;
      globalModel.downloadNamedFileAsync(info).catch(e => logError(e, 'downloadNamedFile'));
    } catch (error) {
      logError(error, 'downloadFileClicked');
    }
  }

  public getFileSize(bytes: number) {
    let size = bytes;
    let power = 0;
    do {
      size = size / 1000;
      power += 1;
    } while (size >= 1000);

    switch (power) {
      case 1:
        return `${size.toFixed(0)}KB`;

      case 2:
        return `${size.toFixed(0)}MB`;

      case 3:
        return `${size.toFixed(2)}GB`;
    }

    return '';
  }

  public editTitleClicked(id: number) {
    this.editId = id;
    this.editDesc = false;
    this.editTitle = true;
    this.text = this.content[id].Title;
  }

  public editDescriptionClicked(id: number) {
    this.editId = id;
    this.editDesc = true;
    this.editTitle = false;
    this.text = this.content[id].Description;
    if (this.text) { this.width = this.text.length; }
    this.width = this.width > 13 ? this.width : 13;
  }

  public async patchFile() {
    try {
      let out: FilePatch;
      if (this.editDesc) {
        out = { Id: this.content[this.editId].Id, Description: this.text.valueOf() } as FilePatch;
      } else {
        out = { Id: this.content[this.editId].Id, Title: this.text.valueOf() } as FilePatch;
      }

      await this.model.patchAttachment(out);
      this.editId = -1;
      this.editDesc = false;
      this.editTitle = false;

    } catch (error) {
      logError(error, 'patchFile');
    }
  }

  public async confirmDelete(): Promise<void> {
    this.model.isConfirmVisible.value = false;
    await this.model.deleteAttachment(this.tempDeleteAttachment as FileAttachment);
    this.tempDeleteAttachment = null;
  }

  public async save(file: File) {
    try {
      if (file && file.size < 30000000) {
        const data = await toBase64(file);
        await this.model.saveAttachment(file.name, data);
      } else {
        if (!file) {
          this.toasties.error($localize`Cannot save attachment`);
        } else {
          this.toasties.error($localize`File size must be less than 30mbs`);
        }
      }
    } catch (error) {
      this.toasties.error($localize`Cannot save attachment`);
      logError(error, 'save attachment');
    }
  }
}
