import { PermissionEnums, tryGetCurrentUser } from '@softools/softools-core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PermissionsService {

  public sitePermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.Lists,
    PermissionEnums.Settings.SetLoginImage,
    PermissionEnums.Settings.SetExportPolicy,
    PermissionEnums.Settings.SetShowRegistration,
    PermissionEnums.Settings.SetGDPRSignup,
    PermissionEnums.Settings.SetLicensingSignup,
    // PermissionEnums.Settings.SetSecurityPolicy,
    PermissionEnums.Settings.AcceptSecurityPolicy,
  ];

  public userPermisions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.UsersTeamsSelf,
    PermissionEnums.Settings.UsersTeamsCreate,
    PermissionEnums.Settings.UsersTeamsUpdate,
    PermissionEnums.Settings.UsersTeamsDelete
  ];

  public teamPermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.UsersTeamsSelf,
    PermissionEnums.Settings.UsersTeamsCreate,
    PermissionEnums.Settings.UsersTeamsUpdate,
    PermissionEnums.Settings.UsersTeamsDelete,
  ];

  public listPermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.Lists,
  ];

  public assetPermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.SiteResources,
  ];

  public errorSummaryPermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.ErrorSummaries,
    PermissionEnums.Settings.ErrorSummariesAll,
  ];

  public exportSummaryPermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.ExportsAll,
    PermissionEnums.Settings.Exports,
  ];

  public importSummaryPermissions = [
    PermissionEnums.Settings.All,
    PermissionEnums.Settings.ImportsAll,
    PermissionEnums.Settings.Imports,
  ];

  public get hasAppStudio(): boolean {
    const user = tryGetCurrentUser();

    if (user) {
      const permissions = user.Permissions.find(p => p.Code >= 5000 && p.Code < 6000);

      return permissions !== undefined;
    }

    return false;
  }

  public get applicationPermissions() {
    return [];  // no permissions required
  }

  public get hasAnySettings(): boolean {
    return [
      this.sitePermissions,
      this.userPermisions,
      this.teamPermissions,
      this.listPermissions,
      this.assetPermissions,
      this.errorSummaryPermissions,
      this.exportSummaryPermissions,
      this.importSummaryPermissions,
      this.applicationPermissions
    ].some(permissions => this.hasPermission(...permissions));
  }

  public hasPermission(...codes: Array<PermissionEnums.Settings | number>): boolean {
    try {
      // No permission specified so access granted
      if (!codes || codes.length === 0) {
        return true;
      }

      const user = tryGetCurrentUser();
      if (user) {
        const match = user.Permissions.map(p => p.Code).find(code => codes.find(c => c === code) > 0);
        return match > 0;
      } else {
        return false;   // no user, no permission
      }
    } catch (error) {
      // swallow errors, getCurrentUser throws if logged out
      return false;
    }
  }
}
