import { Injectable } from '@angular/core';
import { GlobalModelService } from 'app/mvc/common/global-model.service';

@Injectable({ providedIn: 'root' })
export class ToastyService {

  constructor(private models: GlobalModelService) { }

  /** Display an error message @deprecated Use global model directly */
  public error(message: string, title = 'Error') {
    this.models.globalModel.showErrorToasty({ message, title });
  }

  /** Display an info message @deprecated Use global model directly */
  public info(message: string, title = 'Information') {
    this.models.globalModel.showInfoToasty({ message, title });
  }

  /** Display a success message @deprecated Use global model directly */
  public success(message: string, title = 'Success') {
    this.models.globalModel.showSuccessToasty({ message, title });
  }

  /** Display a validation message @deprecated Use global model directly */
  public validationMessage(
    message: string = $localize`Please check all required data fields are complete`,
    title = $localize`Data Entry`) {
    this.models.globalModel.showWarningToasty({ message, title });
  }
}
